import React, { useState, } from 'react';
import { Paper, Typography, Modal, Grid, Button, Checkbox } from '@material-ui/core';
import { makeStyles } from '@material-ui/core';
import { withRouter } from 'react-router-dom';
import moment from 'moment';
import Img from 'react-cool-img';
import truck from '../../../../img/geeteezoomzoom.gif';
import trucksad from '../../../../img/geeteesad.png';
import GetAppIcon from '@material-ui/icons/GetApp';
import CloseIcon from '@material-ui/icons/Close';

const useStyles = makeStyles((theme) => ({
    content: {
        padding: '32px',
        paddingRight: '230px',
        overflow: 'auto'
    },
    sidebar: {
        position: 'fixed',
        right: 0,
        top: '64px',
        maxWidth: '256px',
        width: '100%',
        padding: '16px',
        minHeight: '100vh',
        zIndex: '1000',
    },
    expand: {
        position: 'fixed',
        right: 0,
        top: '444px',
        transform: 'rotate(90deg)',
        marginLeft: 'auto',
        transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.shortest,
        }),
    },
    expandOpen: {
        position: 'fixed',
        right: 253,
        top: '444px',
        transform: 'rotate(270deg)',
    },
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        //overflow: "scroll",
    },
    scroll: {
        maxHeight: '800px',
        overflowY: "scroll",
    },
    downloadModal: {
        marginRight: '60px',
        display: 'flex',
        alignItems: 'flex-start',
        justifyContent: 'center',
        paddingBottom: '100px',
        marginTop: '50px',
    },
    paper: {
        backgroundColor: 'rgba(1,1,1,0.9)',
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
    },
    dlpaper: {
        width: '390px',
     
        margin: '5px 5px 5px 5px',
    },
    form: {
        padding: theme.spacing(4),
        width: '600px',
    },
    card: {
        padding: theme.spacing(3),
        display: 'flex',
        height: '100%',
    },
    row: {
        display: 'flex',
        alignItems: 'center',
        wordWrap: 'anywhere',
    },

    weatherTitle: {
        display: 'flex',
        justifyContent: 'center',
        marginBottom: '16px',
    },

    end: {
        justifyContent: 'flex-end',
    },

    temp: {
        justifyContent: 'center',
        border: '4px solid white',
        borderRadius: '64px',
    },

    weatherCard: {
        backgroundColor: 'rgb(59, 98, 177)',
        boxShadow: 'inset 0px 0px 2px 2px rgba(0,0,0,0.3)',
    },
    downloadImg: {
        height: '225px',
        maxWidth: '300px'
    },
}));

const PhotoDownloadModal = (props) => {

    const classes = useStyles();

    const [rerender, setRerender] = useState(0);

    return(
        <React.Fragment>
            <Modal
                open={props.openDownloadModal}
                onClose={props.handleCloseDownloadModal}
                className={classes.downloadModal}
            >
                <Grid className={classes.scroll}>
                    <Grid container style={{backgroundColor: 'white', position: 'fixed', height: '75px', width: '800px', borderBottom: '2px solid', zIndex: '1000'}}>
                        <Grid item xs={6}>
                            <Typography style={{margin: '23px 15px 15px 15px', fontWeight: 500}}>
                                {props.filteredDownloadData.length !== props.downloadData.length ? props.filteredDownloadData.length + "/" + props.downloadData.length +  " Photos Selected" : props.filteredDownloadData.length +  " Photos Selected"}
                            </Typography>
                        </Grid>
                        <Grid item xs={4}>
                            <Button style={{float: 'right', margin: '15px 15px 15px 15px',}} variant='contained' startIcon={<GetAppIcon />} onClick={props.handleDownload}>Download Selected</Button>
                        </Grid>
                        <Grid item xs={2}>
                            <Button style={{float: 'right', margin: '15px 30px 15px 15px',}} variant='contained' startIcon={<CloseIcon />} onClick={props.handleCloseDownloadModal}>Close</Button>
                        </Grid>
                    </Grid>
                
                    <Grid>  
                        <Paper style={{width: '800px', paddingTop: '75px', display: 'flex', flexWrap: 'wrap'}}> 
                            {props.downloadData.map((photo, index) => {
                                return (
                                    <Grid key={photo.docId + photo.photoURLs} >
                                        <Paper className={classes.dlpaper} style={(props.filteredDownloadData.map(x => (x.photoURLs + "_" + x.docId)).includes(photo.photoURLs + "_" + photo.docId) ) 
                                        ? {borderStyle: 'solid', borderColor:'#FFBF00'} : {borderStyle: 'solid', borderColor:'#d3d3d3', backgroundColor: '#d3d3d3'}}>
                                            <Grid container >
                                                <Grid item xs={10} style={{display: 'flex', justifyContent: 'center', margin: '5px 0px 5px 0px', position: 'relative', left: '25px' }}>
                                                    <Img
                                                    style={(props.filteredDownloadData.map(x => (x.photoURLs + "_" + x.docId)).includes(photo.photoURLs + "_" + photo.docId) ) ? {} : {filter: 'grayscale(100%)'}}
                                                    className={classes.downloadImg}
                                                    error={trucksad}
                                                    placeholder={truck}
                                                    src={photo.photoURLs.toString()}
                                                    alt="Photo"
                                                    />
                                                    
                                                </Grid>
                                                
                                                <Grid item xs={2}>     
                                                    <Checkbox 
                                                    style={{position: 'relative', left: '10px'}}
                                                    //must be better way to rerender modal than this (rerender + 1)
                                                    onChange={(e) => {props.handleDownloadCheckBox(photo, index); setRerender(rerender + 1)}}
                                                    checked={(props.filteredDownloadData.map(x => (x.photoURLs + "_" + x.docId)).includes(photo.photoURLs + "_" + photo.docId) ) ? true : false}
                                                    />
                                                </Grid>
                                                <Grid item>
                                                    <Typography style={{marginLeft:'10px', marginBottom:'10px'}}><small>{photo.employee} - {photo.origin.charAt(0).toUpperCase() + photo.origin.slice(1)} - {(photo.origin === "unit" || photo.origin === "defect" || photo.origin === "job") ? moment.utc(photo.date).format('YYYY/MM/DD')
                                                    : (photo.origin === "checklist") ? moment.unix(photo.date).format('YYYY/MM/DD') : (photo.origin === "delivery") ? photo.date : "Unknown Date"}</small></Typography>

                                                 

                                                </Grid>
                                            </Grid>
                                        </Paper>
                                    </Grid>
                                )
                            })}
                        </Paper>
                    </Grid> 
                </Grid>
            </Modal>
        </React.Fragment>
    )

}

export default withRouter(PhotoDownloadModal)