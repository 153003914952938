import React, { useState } from 'react';
import MaterialTable  from '@material-table/core'
import { TableIcons } from '../../../../constants/tableIcons';
import { MenuItem, Select, TextField } from '@material-ui/core';
import { useProduct } from '../../../../providers/ProductContext';

const ProductTable = (props) => {

    const { products, loading } = useProduct();

    const [unitList, setUnitList] = useState({})
    const [localProducts, setLocalProducts] = useState({})

    //sets localProducts
    React.useEffect(() => {
        setLocalProducts(props.products)
    }, [props.order, props.products])

    //retrieve all unit combinations used in component. set in unitList
    React.useEffect(() => {
        if (!loading){
            let unitListContainer = unitList
            let productNameContainer = props.products.map((product) => {return (product.productName)})
            
            for(let j = 0; j < productNameContainer.length; j++){
                let productIndex = products.findIndex(x => x.name === productNameContainer[j])
                unitListContainer = {...unitListContainer, [productNameContainer[j]]: {}}
                
                for(let k = 0; k < products[productIndex].variants.length; k++){
                    unitListContainer[productNameContainer[j]] = {...unitListContainer[productNameContainer[j]], [products[productIndex].variants[k].id]: [products[productIndex].variants[k].unit] }
                
                    if(products[productIndex].variants[k].priceList?.length > 0){
                        for(let l = 0; l < products[productIndex].variants[k].priceList.length; l++){
                            unitListContainer[productNameContainer[j]][[products[productIndex].variants[k].id]] = 
                                [...unitListContainer[productNameContainer[j]][[products[productIndex].variants[k].id]], products[productIndex].variants[k].priceList[l].unit]
                        }
                    }
                    setUnitList(unitListContainer)
                }
            }
        }
    }, [props.product, loading]) // eslint-disable-line react-hooks/exhaustive-deps

    const filterUnits = (data) => {
        let container = []
        for(const [key] of Object.entries(unitList) ){
            if (key === data.productName){
                for(const [key2, value2] of Object.entries(unitList[key]) ){
                    if (key2 === data.variant.id){
                        value2.map((unit, index) => {
                            return container.push(unit)
                        })
                    }
                }
            }
        }
        return(
            container
        )
    }
    
    const findUnitPrice = (data) => {
        let productIndex = products.findIndex(x => x.name === data.productName)
        let variantIndex = products[productIndex].variants.findIndex(y => y.id === data.variant.id)

        if(data.price.unit === products[productIndex].variants[variantIndex].unit){
            return products[productIndex].variants[variantIndex].price
        }
        else{
            for(let i = 0; i < products[productIndex].variants[variantIndex].priceList?.length; i++){
                if(products[productIndex].variants[variantIndex].priceList[i].unit === data.price.unit){
                    return products[productIndex].variants[variantIndex].priceList[i].price
                }
            }
            console.warn("Error occured, price not found.")
        }
        return 0;
    }

    const updateOrderVariable = (newData, oldData) => {
        return new Promise((resolve, reject) => {
            let container = localProducts
            let errorContainer = []
            let unitListContainer = filterUnits(newData);
            let productIndex = container.findIndex(x=> x.productName === newData.productName)

            //validations
            let checkcount = 0;
            for(let i = 0; i < unitListContainer.length; i++){
                if(newData.price.unit !== unitListContainer[i]){
                    checkcount ++
                }
            }
            if (checkcount >= unitListContainer.length){
                errorContainer.push("Please select a unit.")
            }
            if(newData.qty <= 0){
                errorContainer.push("Qty amount must be more than 0.")
            }

            //error alert + reject and return
            if(errorContainer.length >= 1){
                let errorString = ''
                for(let i = 0; i < errorContainer.length; i++){
                    errorString += errorContainer[i] + '\n'
                }
                alert(errorString)
                reject();
                return;
            }
            //update data
            else{
                container.splice(productIndex, 1, newData)
                newData.price ? container[productIndex].price.price = findUnitPrice(newData) : container.variant.price = findUnitPrice(newData);
                setLocalProducts([...container])

                //ENSURE CLOUD FUNCTION UPDATE ORDER WILL NOT FIRE WHEN TETSING UPDATING ******************************  CREATE TEST ORDER TO WORK WITH
                props.updateOrder({ ...props.order, order: { ...props.order.order, products: [...container] } })
                resolve();
                return;
            }
          });
    }

    return (
        <MaterialTable
            style={{ color: '#707070', padding: '16px', margin: '8px', minHeight: 'calc(100% - 16px)' }}
            data={localProducts}
            title={props.title}
            columns={[
                { 
                    title: 'Product', 
                    field: 'productName',
                    editable: 'never'
                },
                {
                    title: 'Type',
                    field: 'variant',
                    render: (rowData) => {
                        return (<div>{Object.values(rowData.variant.variantTypes).sort().join(' ')}</div>)
                    },
                    editComponent: (props) => {
                        return (
                            <div>
                                <Select
                                    value={props.value}
                                    renderValue={value => Object.values(value.variantTypes).sort().join(' ')}
                                    onChange={(event) => {
                                        props.onChange(event.target.value)
                                    }}
                                >
                                    {products.filter(x => x.docId === props.rowData.product).map((product) => {
                                            return (
                                                product.variants.map((variant, index) => {
                                                    return (
                                                        <MenuItem key={index} value={variant} >{Object.values(variant.variantTypes).sort().join(' ')}</MenuItem>
                                                    )
                                                })
                                            )
                                        })
                                    }
                                </Select>
                            </div>
                        )
                    }
                },
                { 
                    title: 'Quantity', 
                    field: 'qty', 
                    editComponent: (props) => {
                        return (
                            <React.Fragment>
                                <TextField
                                value={props.value}
                                type={'number'}
                                onChange={(event) => {props.onChange(event.target.value)}}
                                >
                                    {props.value}
                                </TextField>
                            </React.Fragment>
                        )
                    },
                },
                {
                    title: 'Unit',
                    field: 'price.unit',
                    render: (rowData) => {
                        return (
                            <span>
                                {'price' in rowData
                                    ? `${rowData.price.unit}`
                                    : `${rowData.variant.unit}`}
                            </span>
                        )
                    },
                    
                    editComponent: (props) => {
                        return (
                            <React.Fragment>
                                <Select
                                    defaultValue={props.value}
                                    onChange={(event) => {
                                        props.onChange(event.target.value)
                                    }}
                                >
                                    {filterUnits(props.rowData).map((unit, index) => {
                                        return (
                                            <MenuItem key={index} value={unit}>{unit}</MenuItem>
                                        )
                                    })}
                                </Select>
                            </React.Fragment>
                        )
                    } 
                },
                {
                    title: 'Unit Price', 
                    field: 'variant.price',
                    render: (rowData) => {
                        return (
                            <span>{
                                'price' in rowData
                                    ? `$${rowData.price.price.toFixed(2)} `
                                    : `$${rowData.variant.price.toFixed(2)} `
                            }
                            </span>
                        )
                    },
                    editComponent: (props) => {
                        return (
                            <span>{
                                findUnitPrice(props.rowData).toFixed(2)
                            }
                            </span>
                        )
                    } 
                },
                {
                    title: 'Total Price', 
                    field: 'variant.price', 
                    render: (rowData) => {
                        return (
                            <span>{
                                'price' in rowData
                                    ? `$${(rowData.price.price * rowData.qty).toFixed(2)} `
                                    : `$${(rowData.variant.price * rowData.qty).toFixed(2)} `
                            }
                            </span>
                        )
                    },
                    editComponent: (props) => {
                        return (
                            <span>{
                                    (findUnitPrice(props.rowData) * props.rowData.qty).toFixed(2)
                            }
                            </span>
                        )
                    } 
                },
            ]}
            isLoading={props.loading}
            icons={TableIcons}
            options={{
                'paging': false,
                'headerStyle': { color: '#707070' },
                'search': false,
                'thirdSortClick': false,
                actionsColumnIndex: -1,
            }}

            editable={{
                isEditHidden: rowData => props.order.order.status === 'COMPLETED' || props.order.order.status === 'DISPATCHED',
                onRowUpdate:(newData, oldData) => updateOrderVariable(newData, oldData)   
            }}
          
        />
    )
}

export default ProductTable