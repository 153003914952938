import React from 'react';
import {Button, Divider, Grid, IconButton, Paper, TextField, Typography, makeStyles} from '@material-ui/core'
import PublishIcon from '@material-ui/icons/Publish';
import MenuBookIcon from '@material-ui/icons/MenuBook';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { withRouter } from 'react-router-dom';
import Dropzone from 'react-dropzone'
import ClearIcon from '@material-ui/icons/Clear';

const useStyles = makeStyles((theme) => ({
    btnGroup:{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-end',
    },
    card: {
        padding: theme.spacing(4),
        width: '100%',
        height: '100%',
    },
    content: {
        maxWidth: '500px',
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        margin: 'auto',
    },
    fileInput: {
        '&:hover': {
            cursor: 'pointer',
          },
        display:'flex',
        alignItems: 'center',
        border: '1px solid #bdbdbd',
        borderRadius:'4px',
        margin:'8px',
        marginTop:'16px',
        //backgroundColor: '#f2f2f2',
    },
    fileInputAccept: {
        display:'flex',
        alignItems: 'center',
        border: '0px',
        borderRadius:'4px',
        margin:'8px',
        marginTop:'16px',
        backgroundColor: '#56a84b',
        color:'white',
    },
    paper: {
        width:'100%',
        maxWidth: '500px',
        padding: theme.spacing(2),
    },
    row:{
        display: 'flex',
        alignItems:'center',
    },
    title: {
        display:'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginBottom: '32px',
    },
    text: {
        color: '#707070',
    },
    backButton: {
        marginBottom: '20px',
        marginTop: '20px',
    },
}));

const PermitsForm = (props) => {

    const classes=useStyles();

    return (
        <Grid className={classes.content}>
            <Grid className={classes.backButton}>
                <Button variant="contained" startIcon={<ArrowBackIcon />}
                    onClick={() => props.history.goBack()} >Back</Button>
            </Grid>
            <form onSubmit={props.handleSubmit}>
                <Paper className={classes.paper}>
                    <Grid container spacing={2}>
                        <Grid item sm={12} className={classes.title}>
                            <MenuBookIcon style={{marginRight:'8px'}}/>
                            <Typography variant="h4" align="center">New Permits</Typography>
                        </Grid>
                        <Grid item sm={12}>
                            <Typography>Permit Name*</Typography>
                            <TextField required size="small" fullWidth variant='outlined' name="name" onChange={props.handleChange} value={props.permitsForm.name}/>
                        </Grid>
                        <Grid item sm={12}>
                            <Typography>Permit Use/Description*</Typography>
                            <TextField component="pre" required multiline rows={3} size="small" fullWidth variant='outlined' name="description" onChange={props.handleChange} value={props.permitsForm.description}/>
                        </Grid>

                        {props.permitsForm.aliases.map((alias, index) => {
                            return(
                                <React.Fragment key={index}>
                                    <Grid item sm={10} key={index}>
                                        <Typography>Alternate Name {index+1}</Typography>
                                        <TextField id={index.toString()} size="small" fullWidth variant='outlined' name="aliases" value={alias} onChange={props.handleChange}/>
                                    </Grid>
                                    <Grid item sm={2} key={index+"b"} className={classes.btnGroup}>
                                        <Button 
                                            style={{minWidth:'0px'}}
                                            fullWidth 
                                            variant={index === 0 ? "contained" : "outlined"} 
                                            onClick={()=>index === 0 ? props.addAlias() : props.removeAlias(index) }>
                                            {index === 0 ? "+" : "-"}
                                        </Button>
                                    </Grid>
                                </React.Fragment>
                            )
                        })}

                        <Grid item sm={12}>
                            <Divider/>
                        </Grid>

                        <Grid item sm={6}>
                            <Typography>Emergency Phone #</Typography>
                            <TextField type='tel' size="small" fullWidth variant='outlined' name="emergencyPhone" onChange={props.handleChange} value={props.permitsForm.emergencyPhone}/>
                        </Grid>
                        <Grid item sm={6}>
                            <Typography>Safety Phone #</Typography>
                            <TextField type='tel' size="small" fullWidth variant='outlined' name="safetyPhone" onChange={props.handleChange} value={props.permitsForm.safetyPhone}/>
                        </Grid>

                        <Grid item sm={12}>
                            <Divider/>
                        </Grid>

                        <Grid item sm={12}>
                            <Typography>Link to Permit Origin</Typography>
                            <TextField size="small" fullWidth variant='outlined' name="manufacturerPage" onChange={props.handleChange} value={props.permitsForm.manufacturerPage}/>
                        </Grid>

                        <Grid item sm={6} className={classes.row}>
                            <Typography>Date Permit Last Updated</Typography>
                        </Grid>
                        <Grid item sm={6} className={classes.row} style={{justifyContent:'flex-end'}}>
                            <TextField type='date' size="small" variant='outlined' name="dateUpdated" onChange={props.handleChange} value={props.permitsForm.dateUpdated}/>
                        </Grid>

                        <Grid item sm={12} className={props.permitsPDF?.name ? classes.fileInputAccept : classes.fileInput}>
                            <Dropzone accept={{'application/pdf': ['.pdf', '.PDF']}} onDrop={props.handleFileUpload}>
                                {({ getRootProps, getInputProps }) => (
                                    <Grid container item xs={12}justifyContent='center' {...getRootProps()} >
                                        <input {...getInputProps()} style={{ display: 'none' }} />
                
                                        <PublishIcon style={{color:'grey', marginRight:'20px'}}/>

                                        <Typography variant='h6'>
                                            {props.permitsPDF?.name ? props.substringText(props.permitsPDF?.name) : "Upload Permit(.pdf)"}
                                        </Typography>
                                    </Grid>
                                )}
                            </Dropzone>

                            {props.permitsPDF?.name ?
                                <IconButton component='span' style={{ marginLeft: '100px', color:'black' }} onClick={() => {props.setPermitsPDF()}}>
                                    <ClearIcon />
                                </IconButton>
                            : null}
                        </Grid>

                        <Grid item sm={12}>
                            <Typography type='url' align="center" style={{color:'red'}}>{props.error}</Typography>
                        </Grid>

                        <Grid item sm={12}>
                            <Button fullWidth variant="contained" size="large" disabled={props.submitting} type="submit">{props.submitting ? "Submitting..." : "Create Permit"}</Button>
                        </Grid>
                    </Grid>
                </Paper>
            </form>
        </Grid>
    )
}

export default withRouter(PermitsForm);