import React, { useEffect, useState } from 'react';
import { Button, Grid, makeStyles, FormControlLabel, Checkbox, Divider, Typography,
Table, TableCell, TableRow, TableHead, TableBody, IconButton,Paper } from '@material-ui/core';
import { withRouter } from 'react-router';
import MaterialTable from '@material-table/core';
import { useCustomer } from '../../../providers/CustomerContext'
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import NotInterestedIcon from '@material-ui/icons/NotInterested';
//import IconButton from '@material-ui/core/IconButton';
//import ClearIcon from '@material-ui/icons/Clear';
import { useAuth } from '../../../providers/AuthContext';
import AddBoxIcon from '@material-ui/icons/AddBox';
import PrintIcon from '@material-ui/icons/Print';
import { TableIcons } from '../../../constants/tableIcons';
import _ from 'lodash';
import OpenInBrowserIcon from '@material-ui/icons/OpenInBrowser';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import { Skeleton } from '@material-ui/lab';

const useStyles = makeStyles((theme) => ({
  card: {
    padding: theme.spacing(4),
    width: '100%',
    height: '100%',
  },
  content: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
  },
  grid: {
    marginTop: theme.spacing(2),
    maxWidth: '1200px',
  },
  table: {
    width: '100%',
    maxWidth: '900px',
    margin: '16px',
    display: 'flex',
  },
  tile: {
    width: '300px',
    height: '300px',
  },
  tileContent: {
    display: 'flex',
    justifyContent: 'space-evenly',
    alignItems: 'center',
    flexDirection: 'column',
    width: '100%',
    height: '100%',
    padding: theme.spacing(4),
  },
  tileIcon: {
    color: 'rgba(0, 0, 0, 0.54)',
    width: '100px',
    height: '100px',
  },

  tileText: {
    fontSize: '1.10rem',
  },
}));

const CustomerHome = (props) => {

  const { customers, error, getCustomers } = useCustomer();
  const { roles } = useAuth();
  const classes = useStyles();
  const tableRef = React.useRef();

  const [customerData, setCustomerData] = useState("customerDataCustomerHome" in sessionStorage ? JSON.parse(sessionStorage.getItem("customerDataCustomerHome")) : [])
  const [showInactive, setShowInactive] = useState("customerShowInactive" in sessionStorage ? JSON.parse(sessionStorage.getItem("customerShowInactive")) : true)
  const [showActive, setShowActive] = useState("customerShowActive" in sessionStorage ? JSON.parse(sessionStorage.getItem("customerShowActive")) : true)
  const [showParents, setShowParents] = useState("customerShowParent" in sessionStorage ? JSON.parse(sessionStorage.getItem("customerShowParent")) : false)
  const [pageLoading, setPageLoading] = useState(true)

  //grabs inital data
  useEffect(() => {
    if(!customers.length > 0){
      getCustomers()
    }
    // eslint-disable-next-line
  },[])

  //filters data
  useEffect(() => {
    setPageLoading(true) 
    
    if(customers.length > 0){
      let container = _.cloneDeep(customers)

      if(!showInactive && showActive && !showParents){
        container = customers.filter(customer => customer.isActive)
      } else if(showInactive && !showActive && !showParents){
        container = customers.filter(customer => !customer.isActive)
      } else if(!showInactive && showActive && showParents){
        container = customers.filter(customer => customer.isActive &&( (customer.isParent && !isChild(customer)) || (_.isUndefined(customer.isParent) && !isChild(customer))))
      } else if(showInactive && !showActive && showParents){
        container = customers.filter(customer => !customer.isActive &&( (customer.isParent && !isChild(customer)) || (_.isUndefined(customer.isParent) && !isChild(customer))))
      } else if(showInactive && showActive && showParents){
        container = customers.filter(customer => ( (customer.isParent && !isChild(customer)) || (_.isUndefined(customer.isParent) && !isChild(customer))))
      } 

      container = container.sort((a, b) => a.name.toLowerCase() > b.name.toLowerCase())
      sessionStorage.setItem("customerDataCustomerHome", JSON.stringify(container)) 
      setCustomerData(container)
      setPageLoading(false) 
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[customers, showInactive, showActive, showParents])

  const isChild = (customer) => {
      if(customers.filter(custy => custy?.addressBook && custy.addressBook.includes(customer.docId)).length > 0)
      {
          return true
      } else {
          return false
      }
  }

  const handleOnSearchChange = (value) => {
    sessionStorage.setItem("customerSearchBarValue", JSON.stringify(value));
    tableRef.current.onPageChange({}, 0);
    sessionStorage.setItem("customerPageValue", JSON.stringify(0));
  }

  const handleOnPageChange = (pageNum) => {
    sessionStorage.setItem("customerPageValue", JSON.stringify(pageNum));
  }

  const handleShowInactive = () => {
    sessionStorage.setItem('customerShowInactive', !showInactive)
    setShowInactive(!showInactive);
    tableRef.current.onPageChange({}, 0);
    sessionStorage.removeItem("customerPageValue");
  }

  const handleShowActive = () => {
    sessionStorage.setItem('customerShowActive', !showActive)
    setShowActive(!showActive);
    tableRef.current.onPageChange({}, 0);
    sessionStorage.removeItem("customerPageValue");
  }

  const handleShowParents = () => {
    sessionStorage.setItem('customerShowParent', !showParents)
    setShowParents(!showParents);
    tableRef.current.onPageChange({}, 0);
    sessionStorage.removeItem("customerPageValue");
  }

  const tileClickHandler = (path) => {
    props.history.push({
      pathname: 'customers/' + path,
    })
  };

  const goToChild =(docId) => {
    props.history.push(`/dashboard/customers/form?docId=${docId}`)
}

  const handlePrint = () => {
    props.history.push({
        pathname: '/dashboard/customers/printing/table/',
        state: { data: customerData, customerData: customerData }
    })
  }
  
  return (
  <div className={classes.content}>
    {!pageLoading && !error ?
      <div >
        <Grid container spacing={2} className={classes.table}>
          <Grid item xs={12}>
            <Button variant='contained' onClick={() => tileClickHandler('new')} startIcon={<AddBoxIcon/>}
                    style={roles.includes('Create|Update|Delete Customers')  ? {visibility: 'visible'} : {visibility: 'hidden'}}>
              Create New Customer
            </Button>
            <Button variant="contained" disabled={props.loading} onClick={handlePrint} style={{marginLeft:'10px'}} startIcon={<PrintIcon/>} > Print Customers </Button>
          </Grid>
          <Grid item xs={12}>
            <FormControlLabel
              control={<Checkbox style={{marginLeft:'20px'}} />}
              label="Show Inactive"
              checked={showInactive}
              onChange={() => handleShowInactive()} />
            <FormControlLabel
              control={<Checkbox style={{marginLeft:'20px'}} />}
              label="Show Active"
              checked={showActive}
              onChange={() => handleShowActive()} />
            <FormControlLabel
              control={<Checkbox style={{marginLeft:'20px'}} />}
              label="Show Only Parent Customers"
              checked={showParents}
              onChange={() => handleShowParents()} />
          <Grid container item xs={12} justifyContent='center'>
            {showInactive && showActive ? <Typography justifyContent='center' variant='caption'>Showing All Customers</Typography> : null}
            {!showInactive && showActive ? <Typography justifyContent='center' variant='caption'>Showing Active Customers</Typography> : null}
            {showInactive && !showActive ? <Typography justifyContent='center' variant='caption'>Showing Inactive Customers</Typography> : null}
            {!showInactive && !showActive ? <Typography justifyContent='center' variant='caption'>Showing All Customers</Typography> : null}
          </Grid>
          <Divider style={{marginBottom:'10px'}}/>
          </Grid>
          
          <Grid item xs={12} style={{ width: '100%' }}>
            <MaterialTable
              tableRef={tableRef}
              columns={[
                { title: 'Name', field: 'name' },
                { title: 'Job Number', field: 'jobNumber' },
                { title: 'Address',  field: 'fullStreet' },
                { title: 'Is Parent?', field:'isParent', render: rowData => isChild(rowData) ? <NotInterestedIcon style={{color:'red'}}/> : rowData.isParent && !_.isUndefined(rowData.isParent) && !isChild(rowData) ? <CheckCircleOutlineIcon style={{color:'green'}}/> : _.isUndefined(rowData.isParent) ? <HelpOutlineIcon style={{color:'grey'}}/> : <NotInterestedIcon style={{color:'red'}}/>},
                { title: 'Active', field: 'isActive', render: rowData => rowData.isActive === false || _.isUndefined(rowData.isActive) ? <NotInterestedIcon style={{color:'red'}}/> : <CheckCircleOutlineIcon style={{color:'green'}}/>},
              ]}
              data={customerData}
              isLoading={pageLoading}
              onSearchChange={ (value) => {handleOnSearchChange(value) }}
              onPageChange={ (pageNum) => {handleOnPageChange(pageNum) }}
              style={{minWidth: '800px'}}
              title='Customers'
              actions={[
                rowData => ({
                  icon: TableIcons.OpenInNew,
                  tooltip: 'Show Customers',
                  onClick: (event, rowData) => {
                    props.history.push({
                      pathname: '/dashboard/customers/form',
                      search: "?" + new URLSearchParams({ docId: rowData.docId }).toString(),
                    })
                  }
                })
              ]}
              detailPanel={[
                rowData => ({
                    disabled:  !_.isUndefined(rowData.addressBook) && rowData.addressBook.length > 0 ? false : true,
                    tooltip: !_.isUndefined(rowData.addressBook) && rowData.addressBook.length > 0 && 'Show Children',
                    icon: () => !_.isUndefined(rowData.addressBook) && rowData.addressBook.length > 0 ?<small><ArrowForwardIosIcon style={{ fontSize: '12px' }} /></small>:null,
                    render: () => <Paper style={
                      
                      {overflowY:'scroll', maxHeight:'400px', backgroundColor:'#f2f2f0'}}>
                    <Table style={{width:'100%'}} aria-label="simple table" size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell>Name</TableCell>
                            <TableCell>Job Number</TableCell>
                            <TableCell>Address</TableCell>
                            <TableCell>Active</TableCell>
                            <TableCell></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rowData.addressBook.length > 0 ? customers.filter(custy =>
                        rowData.addressBook.includes(custy.docId)).map((addr, index) => (
                            <TableRow key={index}>
                                <TableCell component="th" scope="row">
                                    {addr.name}
                                </TableCell>
                                <TableCell >{addr.jobNumber}</TableCell>
                                <TableCell >{addr.fullStreet}</TableCell>
                                <TableCell >{addr.isActive === false || _.isUndefined(addr.isActive) ? <NotInterestedIcon style={{color:'red'}}/> : <CheckCircleOutlineIcon style={{color:'green'}}/>}</TableCell>
                                <TableCell align="right">
                                    <IconButton variant='contained' onClick={()=>goToChild(addr.docId)}><OpenInBrowserIcon/></IconButton>
                                </TableCell>
                            </TableRow>
                        )):
                        <Grid container justifyContent='center'>
                            <Grid item xs={6} style={{margin:'16px'}}><Typography variant="caption">No Data Found..</Typography></Grid>
                        </Grid>
                        }
                    </TableBody>
                </Table></Paper>,
                }),
            ]}
              onRowClick={(event, rowData, togglePanel) => {
                if( !_.isUndefined(rowData.addressBook) && rowData.addressBook.length > 0)
                  togglePanel();
              }}
              options={{
                thirdSortClick: false,
                searchText: "customerSearchBarValue" in sessionStorage ? JSON.parse(sessionStorage.getItem("customerSearchBarValue")) : null,
                toolbar: true,
                paging: true,
                initialPage: "customerPageValue" in sessionStorage ? JSON.parse(sessionStorage.getItem("customerPageValue")) : 0,
                pageSize: 10,
                //pageSizeOptions: false,
                actionsColumnIndex: -1,
                search: true,
                emptyRowsWhenPaging: false,
                rowStyle: rowData => {
                  if ((rowData.isActive === false && _.isUndefined(rowData.isActive))) {
                      return { backgroundColor: '#d3d3d3' };
                  }
                  

                  return {whiteSpace: 'pre-wrap',};
                }
              }}
            />
          </Grid>
        </Grid>
      </div>
      : 
      <Grid>
        <Skeleton variant='rect' width={'45vw'} height={'76vh'} style={{marginTop: '160px'}}/>
      </Grid>
      }
    </div>
)}

export default withRouter(CustomerHome);