import React from 'react';
import { Button, Grid, Paper, TextField, Typography, makeStyles, MenuItem, Select } from '@material-ui/core'
import MenuBookIcon from '@material-ui/icons/MenuBook';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { withRouter } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
    btnGroup: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-end',
    },
    card: {
        padding: theme.spacing(2),
        width: '100%',
        height: '100%',
    },
    content: {
        display: 'flex',
        width: '100%',
        maxWidth: '500px',
        flexDirection: 'column',
        margin: 'auto',
    },
    fileInput: {
        display: 'flex',
        alignItems: 'center',
        border: '1px solid #bdbdbd',
        borderRadius: '4px',
        margin: '8px',
        marginTop: '16px',
        backgroundColor: '#f2f2f2',
    },
    fileInputAccept: {
        display: 'flex',
        alignItems: 'center',
        border: '0px',
        borderRadius: '4px',
        margin: '8px',
        marginTop: '16px',
        backgroundColor: '#56a84b',
        color: 'white',
    },
    paper: {
        width: '100%',
        maxWidth: '500px',
        padding: theme.spacing(2),
    },
    row: {
        display: 'flex',
        alignItems: 'center',
    },
    title: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginBottom: '32px',
    },
    text: {
        color: '#707070',
    },
    backButton: {
        marginBottom: '20px',
        marginTop: '20px',
    },
}));

const EquipmentForm = (props) => {

    const classes = useStyles();

    const equipmentClasses = [
        'Tractor Truck', 'Tandem Truck', 'Grain Bulker', 'Truck Plow', 'Side Dump Trailer', 'End Dump Truck', 'Sanding Truck',
        'Tandem Trailer', 'Labour', 'Lowbed', 'Shop Labour', 'Bobcat', 'Geotec', 'Concrete Products', 'Subcontractor - Truck Plow', 'Subcontractor - Loader',
        'Subcontractor - Trucking', 'Belly Dump Trailer', 'Mechanic', 'Dispatch', 'Office Staff', 'Lawn/Shop Equipment',
        'Subcontractor - Bobcat', 'Equipment Moves', 'Triple End Dump', 'Tandem End Dump', 'Trailer - Bobcat',
        'Loader', 'Mobile Fueling Unit', 'Compaction Equipment', 'Quik-Grip Granite', 'Single Axle Dump', 'Field Equipment',
        'Grader','Trailer','Excavator','Loader Backhoe','Dozer','Compaction Equipment Self Propelled', 'Water Truck', 'Passenger', 'Truck', 'truck', 'passenger', 'loader', 'trailer', 'tractor'
    ];

    return (
        <Grid className={classes.content}>
            <Grid className={classes.backButton}>
                <Button variant="contained" startIcon={<ArrowBackIcon />}
                    onClick={() => props.history.goBack()} >Back</Button>
            </Grid>
            <form onSubmit={props.handleSubmit}>
                <Paper className={classes.paper}>
                    <Grid container spacing={2}>
                        <Grid item sm={12} className={classes.title}>
                            <MenuBookIcon style={{ marginRight: '8px' }} />
                            <Typography variant="h4" align="center">New Equipment</Typography>
                        </Grid>
                        <Grid item sm={6}>
                            <Typography>Unit Number*</Typography>
                            <TextField required size="small" fullWidth variant='outlined' name="unitNumber" onChange={props.handleChange} value={props.equipmentForm.unitNumber} />
                        </Grid>
                        <Grid item sm={6}>
                            <Typography>Odometer Units*</Typography>
                            <TextField required size="small" fullWidth variant='outlined' name="odoUnits" onChange={props.handleChange} value={props.equipmentForm.odoUnits} />
                        </Grid>
                        <Grid item sm={12}>
                            <Typography>License Plate*</Typography>
                            <TextField required size="small" fullWidth variant='outlined' name="licensePlate" onChange={props.handleChange} value={props.equipmentForm.licensePlate} />
                        </Grid>
                        <Grid item sm={4}>
                            <Typography>Model</Typography>
                            <TextField required size="small" fullWidth variant='outlined' name="model" onChange={props.handleChange} value={props.equipmentForm.model} />
                        </Grid>
                        <Grid item sm={4}>
                            <Typography>Make</Typography>
                            <TextField required size="small" fullWidth variant='outlined' name="make" onChange={props.handleChange} value={props.equipmentForm.make} />
                        </Grid>
                        <Grid item sm={4}>
                            <Typography>Year</Typography>
                            <TextField required size="small" fullWidth variant='outlined' name="year" onChange={props.handleChange} value={props.equipmentForm.year} />
                        </Grid>

                        <Grid item sm={12}>
                            <Typography>Equipment Classification*</Typography>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={props.equipmentForm.class}
                                onChange={props.handleChange}
                                name="class"
                                fullWidth
                                variant='outlined'
                                size="small"
                            >
                                {equipmentClasses.sort().map((equipment, index) => {
                                            return (
                                                <MenuItem key={index} value={equipment}>{equipment}</MenuItem>
                                            )
                                        })}
                            </Select>
                        </Grid>

                        <Grid item sm={12}>
                            <Typography>Description*</Typography>
                            <TextField component="pre" required multiline rows={3} size="small" fullWidth variant='outlined' name="description" onChange={props.handleChange} value={props.equipmentForm.description}
                            placeholder={props.equipmentForm.year + ' ' + props.equipmentForm.make + ' ' + props.equipmentForm.model + ' ' + props.equipmentForm.licensePlate.replace(/\s/g, '')}
                            />
                        </Grid>

                        <Grid item sm={12}>
                            <Typography type='url' align="center" style={{ color: 'red' }}>{props.error}</Typography>
                        </Grid>

                        <Grid item sm={12}>
                            <Button fullWidth variant="contained" size="large" disabled={props.submitting} type="submit">{props.submitting ? "Submitting..." : "Create Equipment"}</Button>
                        </Grid>
                    </Grid>
                </Paper>
            </form>
        </Grid>
    )
}

export default withRouter(EquipmentForm);