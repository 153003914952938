export const updateObject = (oldObject, updatedProperties) => {
    return {
        ...oldObject,
        ...updatedProperties
    };
};

export const toPrice = (price) => {
    let newPrice = price.toString();

    for (let i = newPrice.length - 3; i > 0; i -= 3) {
        newPrice = newPrice.slice(0, i) + "," + newPrice.slice(i, newPrice.length)
    }

    return newPrice;
}

export const toPriceSquare = (price) => {
    let newPrice = price.toString();

    newPrice = "$" + toPrice(newPrice.slice(0, newPrice.length - 2)) + '.' + newPrice.slice(newPrice.length - 2, newPrice.length)

    return newPrice;
}

export const clamp = (value, min, max) => {

    let returnValue = value;

    if (returnValue < min)
        returnValue = min;
    else if (returnValue > max)
        returnValue = max;

    return returnValue;
}