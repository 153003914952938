import React from 'react';
import { Button, makeStyles, Grid } from '@material-ui/core';
import { PDFViewer, StyleSheet} from '@react-pdf/renderer';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { withRouter, } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        justifyContent: 'center',
        width: '100%',
    },
    content: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        maxWidth: '1200px',
        margin: 'auto',
    },
    buttonRow: {
        marginBottom: '20px',
        marginTop: '20px',
    },
    checkRow: {
        margin: theme.spacing(1),
        display: 'flex',
        justifyContent: 'space-evenly',
    },
}));

// Create styles
const styles = StyleSheet.create({
    viewer: {
        width: '100%',
        maxWidth: '1200px',
        height: '85vh',
    },
});

const InvoicePDF = (props) => {
    const classes = useStyles();

    return (
        <div className={classes.root}>

            <div className={classes.content}>
                <Grid container className={classes.buttonRow}>
                    <Grid item xs={2}>
                        <Button 
                            variant='contained'
                            onClick={()=> { 
                                props.history.goBack()
                            }}
                            startIcon={<ArrowBackIcon />}
                        >
                                Back
                        </Button>
                    </Grid>
                    <Grid item xs={10}>
                        <Grid container justifyContent='flex-end'>
                            <Button
                                variant='contained'
                                disabled={!props.customer}
                                style={{marginRight: '5px'}}
                                onClick={()=> {  props.naivgateToCustomer(props.customer?.docId) }}
                            >
                                Go to Customer
                            </Button>

                            <Button 
                                variant='contained'
                                style={{marginRight: '5px'}}
                                onClick={()=> { props.setCompletionModalOpen(true) }}
                            >
                                Complete Invoice
                            </Button>   
                        </Grid>
                    </Grid>
                </Grid>

                <PDFViewer style={styles.viewer} key={'key'} children={props.myDoc} />
                
            </div>
        </div>
    )
};

export default withRouter(InvoicePDF);