import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router'
import moment from 'moment'
import { firestore } from '../../../firebase/firebase'
import { makeStyles, Button, Grid } from '@material-ui/core';
import MaterialTable from '@material-table/core';
import { TableIcons } from '../../../constants/tableIcons'
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { Skeleton } from '@material-ui/lab';

const useStyles = makeStyles((theme) => ({
    content: {
        display: 'flex',
        width: '100%',
        maxWidth: '792px',
        flexDirection: 'column',
        margin: 'auto',
    },
    sidebar: {
        position: 'fixed',
        right: 0,
        top: '64px',
        maxWidth: '256px',
        width: '100%',
        padding: '16px',
        zIndex: '1000',
        overflowY: 'auto',
        minHeight: "calc(100vh - 64px)",
        maxHeight: "calc(100vh - 64px)",
    },
    backButton: {
        marginBottom: '20px',
        marginTop: '20px',
    },
}));

const EquipmentReport = (props) => {

    const classes = useStyles();

    const [equipmentData, setEquipmentData] = useState(JSON.parse(sessionStorage.getItem("equipmentData")) || []);
    const [errorMessage, setErrorMessage] = useState(null)
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        let unsubscribe = firestore.collection('unitDescriptions').onSnapshot(
            querySnapshot => {

                const equipmentResponse = querySnapshot.docs.map((doc) => {
                    return {
                        ...doc.data(),
                        'docId': doc.id,
                    }
                })
                setEquipmentData(equipmentResponse);
                sessionStorage.setItem("equipmentData", JSON.stringify(equipmentResponse))
                setLoading(false);
            },
            error => {
                setErrorMessage("Unable to load equipment data.");
                setLoading(false);
                console.log(error.message);
                console.log(errorMessage)
            }
        )
        return () => { unsubscribe();  }
        // eslint-disable-next-line
    }, []);


    return (
        <div className={classes.content}>
            {
                loading
                    ? <Skeleton variant='rect' width={'100vw'} height={'100vh'}/>
                    : 
                    <Grid>
                        <Grid className={classes.backButton}>
                            <Button variant="contained" startIcon={<ArrowBackIcon />}
                                onClick={() => props.history.goBack()} >Back</Button>
                        </Grid>
                        <MaterialTable
                            data={equipmentData}
                            title={"Equipment Inventory"}
                            icons={TableIcons}
                            columns={[
                                { title: 'Unit Number', field: 'unitNumber' },
                                { title: 'Description', field: 'description' },
                                { title: 'Date Added', render: rowData => rowData.dateCreated ? moment.unix(rowData.dateCreated).format('YYYY-MM-DD') : '' },
                            ]}
                            actions={[
                                {
                                    icon: TableIcons.OpenInNew,
                                    tooltip: "Show Equipment",
                                    onClick: (event, rowData) => props.history.push({
                                        pathname: '/dashboard/equipment/form',
                                        search: "?" + new URLSearchParams({ docId: rowData.docId }).toString(),
                                    })
                                }
                            ]}
                            localization={{
                                header: {
                                    actions: 'Actions'
                                }
                            }}
                            options={{
                                toolbar: true,
                                paging: false,
                                actionsColumnIndex: -1,
                                search: true,
                                emptyRowsWhenPaging: false,
                                rowStyle: {
                                    whiteSpace: 'pre-wrap',
                                },
                            }}
                        />
                    </Grid>
            }
        </div>
    )
}

export default withRouter(EquipmentReport);