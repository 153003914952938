import React from 'react';
import { Button, Grid,  Paper, Modal, Backdrop, Fade, Typography, } from '@material-ui/core';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';

const GrainOrderDeleteModal = ({ deleteData, setDeleteData, handleDeleteOrder }) => {

    return (
        <>
            <Modal
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    outline: 0,
                }}
                open={deleteData.open}
                onClose={() => { setDeleteData({pending: [], confirmed: [], current: 0, open: false}) }}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}>
                <Fade in={deleteData.open}>
                    <Paper style={{ padding: '16px', maxWidth:'700px' }}>
                        <Grid container direction='column' spacing={2}>

                            {deleteData.pending?.length > 1 &&
                                <Grid item>
                                    <Typography align='left' style={{fontSize: '12px'}}>
                                        {`${deleteData.current + 1} of ${deleteData.pending?.length}`}
                                    </Typography>
                                </Grid>
                            }

                            <Grid item>
                                <Typography align='center'>
                                    {`This will delete document number ${deleteData.pending[deleteData.current]?.geeTeeOrderNumber} , are you sure?`}
                                </Typography>
                            </Grid>

                            <Grid container justifyContent='flex-end'>
                                <Grid item>
                                    <Button
                                        startIcon={<CloseIcon/>} 
                                        style={{color:'white',backgroundColor:'red', marginRight: '10px'}}
                                        variant='contained'
                                        onClick={(e) => { 
                                            setDeleteData({pending: [], confirmed: [], current: 0, open: false})
                                        }}
                                    >
                                        Cancel All
                                    </Button>

                                    <Button
                                        startIcon={<CloseIcon/>} 
                                        style={{marginRight: '10px'}}
                                        variant='contained'
                                        onClick={(e) => { 
                                            if(deleteData.current + 1 < deleteData.pending.length){
                                                setDeleteData((previousData) => {
                                                    //{pending: [], confirmed: [], current: 1, open: false})
                                                    return {...previousData, current: previousData.current + 1};
                                                })
                                            }
                                            else{
                                                deleteData.confirmed.length > 0 && handleDeleteOrder([...deleteData.confirmed])
                                                setDeleteData({pending: [], confirmed: [], current: 1, open: false})
                                            }
                                        }}
                                    >
                                        Skip
                                    </Button>

                                    <Button
                                        startIcon={<CheckIcon/>}
                                        variant='contained'
                                        onClick={(e) => {
                                                if(deleteData.current + 1 < deleteData.pending.length){
                                                    setDeleteData((previousData) => {
                                                        return {...previousData, 
                                                                confirmed: [...previousData.confirmed, previousData.pending[previousData.current]], 
                                                                current: previousData.current + 1
                                                            };
                                                    })
                                                }
                                                else{
                                                    handleDeleteOrder([...deleteData.confirmed, deleteData.pending[deleteData.pending.length -1]])
                                                    setDeleteData({pending: [], confirmed: [], current: 0, open: false})
                                                }
                                            }
                                        }
                                    >
                                        Confirm
                                    </Button>
                                </Grid>
                            </Grid>

                        </Grid>
                    </Paper>
                </Fade>
            </Modal>
        </>

    )
}

export default (GrainOrderDeleteModal)