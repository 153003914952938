import React from 'react';
import { Paper, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    checkout: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        padding: theme.spacing(3),
        marginTop: theme.spacing(8),
        maxWidth: '800px',
    },

    header: {
        marginBottom: theme.spacing(2),
    },

    root: {
        display: 'flex',
        justifyContent: 'center',
        width: '100%',
    }
}));

const CheckoutComplete = (props) => {

    const MuiClasses = useStyles();
    
    return (
        <div className={MuiClasses.root}>
            <Paper className={MuiClasses.checkout}>
                <Typography variant='h6' className={MuiClasses.header}>Your order has been confirmed.</Typography>
                <Typography variant='body1'>
                    A confirmation email regarding your order will be sent to the provided email shortly.<br /><br />
                Orders are typically shipped out within 1-2 business days. If you have any questions or concerns regarding your order,
                feel free to contact us by phone at <strong>204-444-3069</strong>, or by email at <strong>info@geetee.ca</strong>.
            </Typography>
            </Paper>
        </div>
    )
}

export default CheckoutComplete;