import React, { useState, useEffect } from 'react';
import moment from 'moment'
import MaterialTable from '@material-table/core';
import { firestore } from '../../../../firebase/firebase';
import { useMediaQuery, Grid, Typography } from '@material-ui/core';
import { TableIcons } from '../../../../constants/tableIcons';
import { makeStyles } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import { withRouter} from 'react-router-dom';
import _ from 'lodash';
import NewTrainingForm from './Training/NewTrainingForm';
//icons
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';

const useStyles = makeStyles((theme) => ({
    content: {
        width: '100%'
    },
}));

const TrainingTab = (props) => {
    const classes = useStyles();
    const theme = useTheme();
    const smallScreen = useMediaQuery(theme.breakpoints.down('sm'));

    useEffect(() => {
    }, [smallScreen]);

    const [timesheetData, setTimesheetData] = useState([]);
    const [loading, setLoading] = useState(false)
    const [userData] = useState(props.userData || [])
    const [searchBarValue, setSearchBarValue] = useState(userData.displayName+"SearchBarValue" in sessionStorage ? JSON.parse(sessionStorage.getItem(userData.displayName+"SearchBarValue")) : null)
    const [page, setPage] = useState(userData.displayName+"PageValue" in sessionStorage ? JSON.parse(sessionStorage.getItem(userData.displayName+"PageValue")) : 0)

    useEffect(() => {
        let unsubscribe = firestore.collection('employeeTrainings').where('uuid', '==', userData.uuid).orderBy('completedDate').onSnapshot(querySnapshot => {

            let timesheetResponse = querySnapshot.docs.map((doc) => {
                return {
                    ...doc.data(),
                    'docId': doc.id,
                    'date': moment.utc(doc.data().completedDate).format('YYYY-MM-DD'),
                }
            })

            setTimesheetData(timesheetResponse
                //sort by date newest to oldest
                .sort((a, b) => {
                    return new Date(b.date) - new Date(a.date);
                })
                )
            setLoading(false);
            setPage(userData.displayName+"PageValue" in sessionStorage ? JSON.parse(sessionStorage.getItem(userData.displayName+"PageValue")) : 0)
        })

        return () => { unsubscribe(); }
    }, [userData]) // eslint-disable-line react-hooks/exhaustive-deps


    const handleOnSearchChange = (value) => {
        setSearchBarValue(value);  
        sessionStorage.setItem(userData.displayName+"SearchBarValue", JSON.stringify(value));
    
        setPage(0);  
        sessionStorage.setItem(userData.displayName+"PageValue", JSON.stringify(0));
      }
    
    const handleOnPageChange = (pageNum) => {
        setPage(pageNum);     
        sessionStorage.setItem(userData.displayName+"PageValue", JSON.stringify(pageNum));
        
    }
    
    return (
        <div className={classes.content}>
            <Grid container>
                <NewTrainingForm userData={userData}/>
            </Grid>

            {!loading && timesheetData.length === 0 ? <div>No Trainings Found</div> :
            <MaterialTable

                columns={[
                    { title: 'Title', field: 'title' },
                    { title: 'Completed', field: 'date', defaultSort: 'desc' },
                    { title: 'Duration', field: 'duration' },
                ]}
                data={timesheetData}
                title={`${userData.displayName.substring(0, userData.displayName.indexOf(" "))}'s Trainings`}
                isLoading={loading}
                icons={TableIcons}
                onSearchChange={ (value) => {handleOnSearchChange(value) }}
                onPageChange={ (pageNum) => {handleOnPageChange(pageNum) }}
                options={{         
                    toolbar: true,
                    paging: true,
                    initialPage: page,
                    pageSizeOptions: false,
                    pageSize: 12,
                    actionsColumnIndex: -1,
                    search: true,
                    emptyRowsWhenPaging: false,
                    thirdSortClick: false,
                    searchText: searchBarValue,
                    rowStyle: rowData => {
                        if (rowData.timesheetID === "Unsubmitted") {
                            return { backgroundColor: '#fff2f4' };
                        }
                        return {};
                    }
                }}
                detailPanel={[
                    rowData => ({
                        tooltip: _.isUndefined(rowData.certificate?.url) ? null:'Show Certificate',
                        icon: () => <small><ArrowForwardIosIcon style={{ fontSize: '12px' }} /></small>,
                        disabled: _.isUndefined(rowData.certificate?.url),
                        render: () =>
                            <React.Fragment>
                                <Grid container>
                                    <Grid item xs={12}>
                                        <Typography variant='h6' style={{ marginBottom: '10px' }}>Certificate</Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        {rowData.certificate.type === 'application/pdf' ?
                                            <iframe src={rowData.certificate.url} title={rowData.title+'  Certificate'} width="100%" height="600px" />
                                            :
                                            <img src={rowData.certificate.url} alt={rowData.certificate.name} style={{ width: '500px', height: 'auto' }} />
                                        }
                                    </Grid>
                                </Grid>
                            </React.Fragment>,
                    }),
                ]}
            />}
        </div>
    )
}

export default withRouter(TrainingTab)