import { List, ListItem, ListItemText, makeStyles, Divider } from "@material-ui/core";
import { Link } from 'react-router-dom'

const useStyles = makeStyles((theme) => ({
    listItem: {
        paddingLeft: 24,
        flexShrink: 0,
        whiteSpace: 'nowrap',
        overFlowX: 'auto'
    },
}));

export const SubDrawer = (props) => {
    const classes = useStyles();
    return (
        <List>
            {props.items.map((item, index) => (
                item.title === 'DIVIDER' ? (
                    <Divider key={index} />
                ) : (
                    <ListItem
                        className={classes.listItem}
                        onClick={() => {
                            if (item.items) {
                                props.openSubSubDrawer(<SubDrawer items={item.items} level={item.level} />);
                            } else {
                                props.onClick();
                            }
                        }}
                        component={!item.items && Link}
                        to={item.link}
                        button
                        key={index}
                    >
                        {item.icon}&nbsp;
                        <ListItemText primary={item.title} />
                    </ListItem>
                )
            ))}
        </List>
    );
}
