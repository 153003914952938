import React, { useState } from 'react';
import {Grid, makeStyles, Button, Typography, Paper, Dialog, DialogContent, DialogTitle,
        DialogActions, DialogContentText} from '@material-ui/core';
import { withRouter } from 'react-router';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

const useStyles = makeStyles((theme) => ({
    content: {
        display: 'flex',
        width: '100%',
        maxWidth: '1200px',
        margin: 'auto',
    },
    backButton: {
        marginBottom: '20px',
        marginTop: '20px',
    },
    radioGroup: {
      border: '1px solid black', 
      padding: '10px',
      marginBottom: '10px',
    },
    rowGroup: {
      paddingTop: '10px', 
      paddingLeft: '20px', 
      paddingRight: '20px',
      paddingBottom: '10px'
    },
    paper: {
      width: '100%',
      //backgroundColor: 'rgb(252, 252, 249)',
      padding: theme.spacing(2),
  },
  }));
  
const InvoiceCard = (props) => {
    const classes = useStyles();

    const [deleteOpen, setDeleteOpen] = useState(false)

    return (  
      <Grid container className={classes.content}>     
        <Grid container className={classes.backButton}>
          <Grid item xs={3}>
            <Button variant="contained" onClick={() => props.history.goBack()} startIcon={<ArrowBackIcon/>}>Back</Button>
          </Grid>

          <Grid item xs={9}>
            <Grid container justifyContent='flex-end'>
                <Button 
                  variant="contained" 
                  onClick={() => setDeleteOpen(true) }  
                >
                  Delete Invoice
                </Button>
            </Grid>
          </Grid>

        </Grid>

        <Grid container>
          <Paper className={classes.paper}>
            {/* Delete Modal */}
            <Dialog
                open={deleteOpen}
                onClose={() => { setDeleteOpen(false) }}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{"Delete Invoice?"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Are you sure you want to delete this Invoice?
                        This will unpost all associated tickets.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => { setDeleteOpen(false) }} variant="contained" color="secondary">
                        Cancel
                    </Button>
                    <Button onClick={() => { props.handleDeleteInvoice() }} variant="contained" style={{ backgroundColor: 'red', color: 'white' }} autoFocus>
                        Confirm
                    </Button>
                </DialogActions>
            </Dialog>

            <Typography align='left' variant='h4'>{`Invoice ID: ${props.invoiceData.invoiceId}`}</Typography>

            <Grid item xs={12}>
                <Grid item xs={12} style={{display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop:'50px'}}>
                    <iframe
                        key={props.invoiceData.invoiceId}
                        src={props.invoiceData.invoiceURL}
                        title="PDF Viewer"
                        width="1000px"
                        height="800px"
                    />
                </Grid>
            </Grid>

          </Paper>
        </Grid>
      </Grid>
    )
  }
  
  export default withRouter(InvoiceCard);