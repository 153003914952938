import React, {useState, useEffect, useRef, useCallback, useMemo} from 'react';
import { makeStyles } from '@material-ui/core';
import { Grid, Card, Button, Snackbar } from '@material-ui/core';
import { withRouter } from 'react-router-dom';
import _ from 'lodash';
//import { useAuth } from '../../../providers/AuthContext'
import { firestore, functions } from '../../../firebase/firebase';
import useAsyncError from '../UI/AsyncError/AsyncError';
import AddBoxIcon from '@material-ui/icons/AddBox';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { useAuth } from '../../../providers/AuthContext';
import moment from 'moment';

import GrainOrderTicketModal from './GrianOrdersComponents/GrainOrderTicketModal';
import GrainOrderEmailModal from './GrianOrdersComponents/GrainOrderEmailModal';
import GrainOrderDeleteModal from './GrianOrdersComponents/GrainOrderDeleteModal';

import DataGridTable from '../UI/DataGridTable/DataGridTable';
import GrainButtonList from './GrainButtonList';
import GrainOrdersCard from './GrainOrdersCard';

const useStyles = makeStyles((theme) => ({
    content: {
        display: 'flex',
        width: '100%',
        flexDirection: 'column',
        maxWidth: '90vw',
        margin: 'auto',
        marginTop: '20px',
    },
    paper: {
        //borderRadius: '0px',
        margin: '0px',
        width: '100%',
        maxWidth: '90vw',
        padding: '16px',
       // backgroundColor: 'rgb(252, 252, 249)',
    },
    createButton: {
        marginBottom: '20px',
        marginTop: '20px',
    },
}));

const GrainOrdersReport = (props) => {
    const classes = useStyles();
    const throwAsyncError = useAsyncError();
    const { userSettings, updateUserSettings } = useAuth();

    const [tableExpanded, setTableExpanded] = useState("grainTableExpanded" in sessionStorage ? JSON.parse(sessionStorage.getItem("grainTableExpanded")) : true)
    const [loading, setLoading] = useState(false)
    const [saving, setSaving] = useState(false)

    const [rowsSelected, setRowsSelected] = useState([])
    const rowsSelectedRef = useRef([])

    //holds docId's of documents that have been altered  
    const [alteredDocsData, setAlteredDocsData] = useState([])
    const alteredDocsDataRef = useRef([])

    //modal data put somewhere else? or memoize
    const [deleteData, setDeleteData] = useState({pending: [], confirmed: [], current: 0, open: false})
    const [emailModal, setEmailModal] = useState(false)
    const [ticketModal, setTicketModal] = useState(false)

    //states that handle table alterations (when not pulling new data)
    const [deletedRows, setDeletedRows] = useState([])
    const [addedRows, setAddedRows] = useState({})
    const pageRef = useRef()

    //used to pass data down to child ? set with below state, then this value is passed down via use effect ----------- USED FOR AUTOFOCUS
    const addedValueRef = useRef({})
    const [addedValue, setAddedValue] = useState({})

    const [globalGrainOrders, setGlobalGrainOrders] = useState(_.cloneDeep(props.globalGrainOrdersRef.current))
    const saveStateRef = useRef(_.cloneDeep(props.globalGrainOrdersRef.current))

    //can be replaced with rowsSelected[0] ?
    //const [currentGrainDocId, setCurrentGrainDocId] = useState(null)

    const newGrainOrder = functions.httpsCallable('createGrainOrder');
    //const getTicketNumber = functions.httpsCallable('createTicket');

    const defaultColumnData = useMemo(() => (
        [
            {   title: 'GeeTee Order#', 
                field: 'geeTeeOrderNumber', 
                columnType: 'typography',
                uid: true,
                width: 'small',
            },
            {   title: 'Order#', 
                field: 'orderNumber',
                columnType: 'textfield',
                uid: true,
            },
            {   title: 'Status', 
                field: 'orderStatus', 
                columnType: 'autocomplete',
                errorable: true,
                options: {data: 'props.statuses', displayFields: ['status'], field: 'orderStatus'},
            },
            {   title: 'Ticket Date', 
                field: 'ticketDate', 
                columnType: 'date',
                secondarySort: 'asc',
            },
            {   title: 'Equipment', 
                field: 'equipment', 
                columnType: 'select',
                orderByColumn: 'asc',
                errorable: true,
                options: {data: 'props.units', displayFields: ['unitNumber', 'description'], field: 'equipment'},
                route: '/dashboard/equipment',
                width: 'small',
            },
            {   title: 'Order Priority',
                field: 'orderPriority',
                columnType: 'autocomplete',
                tertiarySort: 'asc',
                errorable: true,
                options: {data: 'props.priorities', displayFields: ['priority'], field: 'orderPriority'},
                width: 'small',
            },
            {   title: 'Operator', 
                field: 'operator', 
                columnType: 'select',
                errorable: true,
                options: {data: 'props.users', displayFields: ['displayName', 'department'], field: 'operator'},
                route: '/dashboard/users',
            },
            {   title: 'Customer', 
                field: 'customer', 
                columnType: 'select',
                errorable: true,
                options: {data: 'props.customers', displayFields: ['name'], field: 'customer'},
                route: '/dashboard/customers',
            },
            {   title: 'Origin', 
                field: 'origin',
                columnType: 'autocomplete',
                errorable: false,
                options: {data: 'props.placesRef.current', displayFields: ['origin'], field: 'origin'},
            },
            {   title: 'Destination', 
                field: 'destination',
                columnType: 'autocomplete',
                errorable: false,
                options: {data: 'props.placesRef.current', displayFields: ['destination'], field: 'destination'},
            },
            {   title: 'Material',
                field: 'material',
                columnType: 'autocomplete',
                errorable: false,
                options: {data: 'props.materialsRef.current', displayFields: ['material'], field: 'material'},
            },
            {   title: 'Instructions', 
                field: 'instructions',
                columnType: 'textfield',
            },
            {   title: 'Order Type',
                field: 'orderType',
                columnType: 'autocomplete',
                errorable: true,
                options: {data: 'props.orderTypes', displayFields: ['orderType'], field: 'orderType'},
                width: 'small',
            },
        ]
    // eslint-disable-next-line react-hooks/exhaustive-deps
    ), []);

    // //FOR TESTING PURPOSES ONLY
    // useEffect(() => {
    //     console.log('rowsSelectedRef.current', rowsSelectedRef.current)
    //     console.log('rowsSelected', rowsSelected)
    // }, [rowsSelected])

    //retrieves up to date for columns, sorts and returns columns
    const retrieveColumnOptionsData = (columns) => {
        //console.log('retrieveColumnOptionsData')
        let container = _.cloneDeep(columns)

        container.forEach((column) => {
            if(column.columnType === 'autocomplete' || column.columnType === 'select'){
                //Eval runs code based on the string, genarl secuirty risk - if any other possibilities replace
                switch(column.options.data) {
                    case "props.units":
                        column.options.data = eval(column.options.data).sort((a, b) => a.unitNumber?.toLowerCase() > b.unitNumber?.toLowerCase())
                      break;
                    case "props.users":
                        column.options.data = eval(column.options.data).filter(x=> x.employment?.active).sort((a, b) => a.displayName?.toLowerCase() > b.displayName?.toLowerCase())
                      break;
                    case "props.customers":
                        column.options.data = eval(column.options.data).sort((a, b) => a.name?.toLowerCase() > b.name?.toLowerCase())
                        break;
                    default:
                        column.options.data = eval(column.options.data)
                  } 
            }
        });
        return container
    }
    
    //retrieve from user settings if available
    const [columns, setColumns] = useState(!_.isUndefined(userSettings['grainOrdersColumns']) ? retrieveColumnOptionsData(userSettings['grainOrdersColumns']) : retrieveColumnOptionsData(defaultColumnData))

    //rerender of table on change of addedRows then passes down docId of added doc for focus
    useEffect(() => { setAddedValue(addedValueRef.current) }, [addedRows])

    const confirmSave = async () => {
        //console.log('confirm save')

        setSaving(true)
        let batch = firestore.batch();
        let globalContainer = globalGrainOrders;

        //creates db doc refs and updates state var
        alteredDocsDataRef.current.forEach((docId) => {
            const docRef = firestore.collection('grainOrders').doc(docId);
            let container = globalGrainOrders.find(x=> x.docId === docId)

            //if saveState doesnt have both equipment and operator (otherwise skip)
            if(saveStateRef.current.equipment === '' || saveStateRef.current.operator === ''){
                container = props.checkForAssignedEquipmentOperator(container)
            }

            globalContainer.splice(globalGrainOrders.findIndex(x=> x.docId === docId), 1, container)
            batch.set(docRef, container);
        })

        batch.commit()
        .then(async () => {
            //sets to state after pushing to db
            setGlobalGrainOrders([...globalContainer])
            saveStateRef.current = _.cloneDeep([...globalContainer])

            //checks for new rows to be added
            let addedRowsContainer = [];
            alteredDocsDataRef.current.forEach((docId) => {
                let docContainer = globalGrainOrders.find(x=> x.docId === docId);
                if(docContainer.geeTeeOrderNumber === 'New'){ addedRowsContainer.push(docContainer) }
            })

            //if there are docs that needs geeTeeOrderNumbers
            if(addedRowsContainer.length > 0){
                let addedRowsMapContainer = _.cloneDeep(addedRows)

                //gets proper grainNumbers for each new order
                await newGrainOrder({
                    sandbox: process.env.NODE_ENV === 'development',
                    dataArray: addedRowsContainer,
                }).then((res) => {
                    res.data.forEach((data) => {
                        //allGrainOrders
                        globalContainer.splice(globalContainer.findIndex(x=> x.docId === data.docId), 1, data)

                        //addedRows
                        for (const [key, value] of Object.entries(addedRows)) {
                            if(value.find(x=> x.docId === data.docId) !== undefined){
                                addedRowsMapContainer[key].splice(addedRowsMapContainer[key].findIndex(x=> x.docId === data.docId), 1, data)
                            }
                        }
                    })
                    
                    setAddedRows(addedRowsMapContainer)
                    setGlobalGrainOrders([...globalContainer])
                    saveStateRef.current = _.cloneDeep([...globalContainer])
                })
            }

            setAlteredDocsData([])
            alteredDocsDataRef.current = []
            setSaving(false)
        })
        .catch((e) => {
          throwAsyncError(new Error(e.message, e));
          setSaving(false)
        });
    };

    const cancelSave = () => {
        //console.log('cancel save')

        let addedRowsRemoveContainer = [];
        let addedRowsMapContainer = _.cloneDeep(addedRows)
        let rowsSelectedContainer = _.cloneDeep(rowsSelectedRef.current)

        alteredDocsDataRef.current.forEach((docId) => {
            let docContainer = globalGrainOrders.find(x=> x.docId === docId);
            if(docContainer.geeTeeOrderNumber === 'New'){ addedRowsRemoveContainer.push(docContainer) }
        })

        //rows selected
        addedRowsRemoveContainer.forEach((data) => { 
            if(rowsSelectedContainer.includes(data.docId)){
                rowsSelectedContainer.splice(rowsSelectedContainer.findIndex(x=> x === data.docId), 1)
            }
        })

        //added rows
        addedRowsRemoveContainer.forEach((data) => {
            for (const [key, value] of Object.entries(addedRows)) {
                if(value.find(x=> x.docId === data.docId) !== undefined){
                    addedRowsMapContainer[key].splice(addedRowsMapContainer[key].findIndex(x=> x.docId === data.docId), 1)
                }
            }
        })

        if(!_.isEqual(rowsSelectedContainer, rowsSelectedRef.current)){
            setRowsSelected(rowsSelectedContainer)
            rowsSelectedRef.current = rowsSelectedContainer
        }
        
        if(!_.isEqual(addedRowsMapContainer, addedRows)){ 
            setAddedRows(addedRowsMapContainer) 
        }

        setAlteredDocsData([])
        alteredDocsDataRef.current = []

        //need to ensure data gets sorted before setting global here, causing change tabs bug
        setGlobalGrainOrders(_.cloneDeep(saveStateRef.current))
    }

    //callback to be used within memoized component
    const updateAlteredDocs = useCallback((docIdArray, newState) => {
        //console.log('updateAlteredDocs', docIdArray, newState)

        let container = _.cloneDeep(alteredDocsDataRef.current);
        
        docIdArray.forEach((docId) => {
            if(!container.includes(docId) && 
                !_.isEqual(newState.find(x=> x.docId === docId), saveStateRef.current.find(x=> x.docId === docId))){

                container.push(docId)
            }
            else if(container.includes(docId) && 
                    _.isEqual(newState.find(x=> x.docId === docId), saveStateRef.current.find(x=> x.docId === docId))){

                container.splice(container.findIndex(x=> x === docId), 1)
            }
        })

        if(!_.isEqual(container, alteredDocsDataRef.current)){
            setAlteredDocsData(container)
            alteredDocsDataRef.current = container;
        }
    }, []);

    //can be set as a callback and passed to child / REMOVED
    const onCellChange = (value, field, docId) => {
        //console.log('onCellChange')
        
        try{
            setGlobalGrainOrders((previousValues) => { 
                let container = _.cloneDeep(previousValues)
                container[container.findIndex(x=> x.docId === docId)][field] = value;
                //inside set to have access to latest globalGrainOrder data
                updateAlteredDocs([docId], [...container])
                return container
            })
        }
        catch(e){
            throwAsyncError(new Error(e.message, e));
        }
    }

    //state within globalGrainOrders will not have up to date values THIS AND ONCELLCHANGE CAN BE THE SAME FUNCTION
    const handleSetCurrentGrainOrderCallback = useCallback((changesObj, docId) => {
        //console.log('handleSetCurrentGrainOrderCallback')

        setGlobalGrainOrders((previousValues) => { 
            let container = _.cloneDeep(previousValues)
            for (const [key, value] of Object.entries(changesObj)) { container[container.findIndex(x=> x.docId === docId)][key] = value }
            //inside set to have access to latest globalGrainOrder data
            updateAlteredDocs([docId], container)
            return container;
        })
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[], );

    const onPageChange = (page) => {
        pageRef.current = page;
    }

    const onNewRow = (rowData, copied) => {
        //console.log('rowData', rowData)

        try{
            setLoading(true)
            let rowContainer = _.cloneDeep(rowData)
            let allGrainContainer = _.cloneDeep(globalGrainOrders)
            let addedRowsContainer = _.cloneDeep(addedRows)

            rowContainer.forEach((row) => {
                //add any additional values here
                let query = firestore.collection('grainOrders').doc()
                row.orderStatus = 'active';
                if(props.pathType !== 'all'){ row.orderType = props.pathType; }
                row.docId = query.id
                row.geeTeeOrderNumber = 'New';
                row.dateCreated = moment().valueOf();
                row.associatedTickets = [];
                if(copied){ row.orderNumber = '' }

                //check if the field is a related one, and that the opposite value is not present
                if((row.equipment || row.operator) && ((row.equipment && !row.operator) || (!row.equipment && row.operator))){
                    let currentValue = (row.equipment && !row.operator) ? 'equipment' : 'operator'
                    let oppositeValue = (row.equipment && !row.operator) ? 'operator' : 'equipment'

                    let newValue = currentValue === 'equipment'
                    ? {operator: props.users.filter(x=> x.commonEquipment?.fullUnit?.docId === row[currentValue]).pop()?.docId }
                    : {equipment: props.users.find(x=> x.docId === row[currentValue])?.commonEquipment?.fullUnit?.docId } 

                    if(newValue[oppositeValue]){ row[oppositeValue] = newValue[oppositeValue] }
                }

                for(let [key, value] of Object.entries(props.initialgrainOrder)){
                    if(_.isUndefined(row[key])){ row[key] = value; }
                }
            })

            addedValueRef.current = rowContainer.map((row) => { return row.docId });
            setGlobalGrainOrders([...allGrainContainer, ...rowContainer])
            addedRowsContainer[pageRef.current] = [...addedRowsContainer[pageRef.current] || [], ...rowContainer]
            setAddedRows(addedRowsContainer)
            updateAlteredDocs(rowContainer.map((row) => {return row.docId}), [...allGrainContainer, ...rowContainer])
            setLoading(false)
        }
        catch(e){
            throwAsyncError(new Error(e.message, e));
        }
    }

    const handleDeleteOrder = (documents) => {
        //console.log('handleDeleteOrder', documents)

        try{
            let batch = firestore.batch();
            let container = _.cloneDeep(globalGrainOrders)
            let alteredDocsContainer = _.cloneDeep(alteredDocsDataRef.current)
            let addedRowContainer= _.cloneDeep(addedRows)
            let rowsSelectedRefContainer= _.cloneDeep(rowsSelectedRef.current)

            documents.forEach((data) => {
                let query = firestore.collection('grainOrders').doc(data.docId)
                batch.delete(query);
                container.splice(container.findIndex(x=> x.docId === data.docId), 1)
                
                //if(currentGrainDocId === data.docId){ setCurrentGrainDocId(null) }

                //manipulates altered docs
                if(alteredDocsContainer.includes(data.docId)){ alteredDocsContainer.splice(alteredDocsContainer.findIndex(x=> x === data.docId), 1) }

                //manipulates data for added row
                if(addedRowContainer[pageRef.current]?.map((obj) => ( obj.docId )).includes( data.docId )){ 
                    addedRowContainer[pageRef.current].splice(addedRowContainer[pageRef.current].findIndex(x=> x.docId === data.docId), 1) 
                }

                //manipulates row selected
                if(rowsSelectedRefContainer.includes( data.docId )){ 
                    rowsSelectedRefContainer.splice(rowsSelectedRefContainer.findIndex(x=> x === data.docId), 1) 
                }
            })

            batch.commit()
            .then(() => { 
                //needs to happen before other states are set
                setDeletedRows([...deletedRows || [], ...documents.map((doc => (doc.docId)) )])

                setGlobalGrainOrders([...container])

                //removes any added data docs that may be present
                if(!_.isEqual(addedRowContainer, addedRows)){
                    setAddedRows(addedRowContainer)
                }

                //removes any alteredData docs that may be present
                if(!_.isEqual(alteredDocsContainer, alteredDocsDataRef.current)){
                    setAlteredDocsData(alteredDocsContainer)
                    alteredDocsDataRef.current = alteredDocsContainer
                }
                
                //alters rowsSelected if there are differences
                if(!_.isEqual(rowsSelectedRefContainer, rowsSelectedRef.current)){
                    setRowsSelected(rowsSelectedRefContainer)
                    rowsSelectedRef.current = rowsSelectedRefContainer
                    //grainButtonsRef.current.handleRowsSelected(rowsSelectedRefContainer)
                }

            })
        }
        catch(e){
            throwAsyncError(new Error(e.message, e));
        }
    }

    //changes format to mimic default columns (options data as string), sets to user settings
    const onColumnChange = (columns) => {
        //console.log('onColumnChange')
        let container = _.cloneDeep(columns)

        container.forEach((column) => {
            if(column.columnType === 'autocomplete' || column.columnType === 'select'){
                //get string from default columns
                column.options.data = defaultColumnData.find(x=> x.field === column.field).options.data;
            }
        })

        updateUserSettings('grainOrdersColumns', container)
    }

    return (
        <Grid className={classes.content}>
            <GrainOrderDeleteModal 
                deleteData={deleteData}
                setDeleteData={setDeleteData}
                handleDeleteOrder={handleDeleteOrder}
            />

            <GrainOrderEmailModal 
                emailModal={emailModal}
                setEmailModal={setEmailModal}
                users={props.users}
                rowDocIds={rowsSelectedRef.current}
                globalGrainOrders={globalGrainOrders}
                //needs to be updated to be set up as a list of possible operators
                //operator={}
                handleSendOperatorInstructionsEmail={props.handleSendOperatorInstructionsEmail}
            />

            <GrainOrderTicketModal 
                modalOpen={ticketModal}
                setModalOpen={setTicketModal}
                handleSendToTickets={props.handleSendToTickets}
                rowDocIds={rowsSelectedRef.current}
                globalGrainOrders={globalGrainOrders}
                setGlobalGrainOrders={setGlobalGrainOrders}
            />

            <Grid container>
                <Grid item md={2} xs={12}>
                    <Button 
                        className={classes.createButton}
                        variant='contained'
                        startIcon={<AddBoxIcon/>}
                        onClick={(e) => {  props.history.push({ pathname: 'grains/new',}) }}
                    >
                        Create New Grain Order
                    </Button>
                </Grid>

                <Grid item md={10} xs={12}>                     
                    <GrainButtonList 
                        rowsSelected={rowsSelectedRef.current}
                        globalGrainOrders={globalGrainOrders}
                        handlePrintOrder={props.handlePrintOrder}
                        setDeleteData={setDeleteData}
                        onNewRow={onNewRow}
                        emailModal={emailModal}
                        setEmailModal={setEmailModal}
                        setTicketModal={setTicketModal}
                    />
                </Grid>
            </Grid>

            <Grid container >
                <Grid item sm={12} xs={12}>
                    <Card className={classes.paper}>
                        <Grid >
                            <DataGridTable
                                globalGrainOrders={globalGrainOrders}
                                columns={columns}
                                setColumns={setColumns}
                                loading={loading}
                                orderTypes={props.orderTypes}
                                pathType={props.pathType}
                                onActionClick={props.tileClickHandler}
                                handleDblClick={props.handleDblClick}
                                onColumnChange={(columns) => { onColumnChange(columns) }}
                                onCellChange={onCellChange}
                                onNewRow={onNewRow}
                                deletedRows={deletedRows}
                                addedRows={addedRows}
                                pageRef={pageRef}
                                onPageChange={onPageChange}
                                addedValue={addedValue}
                                tableExpanded={tableExpanded}
                                userSettings={userSettings}
                                updateUserSettings={updateUserSettings}

                                rowsSelected={rowsSelected}
                                setRowsSelected={setRowsSelected}
                                rowsSelectedRef={rowsSelectedRef}

                                setAddedRows={setAddedRows}
                            />

                        </Grid>
                        
                        <Grid container style={{marginTop: '10px', marginBottom: '10px'}}>
                            <Button 
                                fullWidth 
                                variant='outlined' 
                                onClick={() => { 
                                    sessionStorage.setItem("grainTableExpanded", JSON.stringify(!tableExpanded));
                                    setTableExpanded(!tableExpanded) }}>{tableExpanded ? <ExpandLessIcon /> : <ExpandMoreIcon />
                                }
                            </Button>
                        </Grid>

                        {!tableExpanded &&
                            <GrainOrdersCard 
                                globalGrainOrders={globalGrainOrders}
                                currentGrainOrder={globalGrainOrders.find(x=> x.docId === rowsSelected[0]) || null} 
                                handleSetCurrentGrainOrderCallback={handleSetCurrentGrainOrderCallback}
                                path={rowsSelected[0]}
                                loading={props.loading}
                                handlePrintOrder={props.handlePrintOrder}
                                handleSendOperatorInstructionsEmail={props.handleSendOperatorInstructionsEmail}
                                priorities={props.priorities}
                                statuses={props.statuses}
                                users={props.users}
                                customers={props.customers}
                                units={props.units}
                                usersLoading={props.usersLoading}
                                unitsLoading={props.unitsLoading}
                                per={props.perRef.current}
                                places={props.placesRef.current}
                                materials={props.materialsRef.current}
                                orderTypes={props.orderTypes}
                            />
                        }
                    </Card>
                </Grid>
            </Grid>
        
            <Snackbar
                open={alteredDocsData.length > 0}
                message={saving ? 'Saving...' : 'Save Document?'}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center'
                }}
                action={
                    <React.Fragment>
                        {saving
                            ? null
                            : <React.Fragment>
                                <Button variant='text' color='primary' onClick={cancelSave} style={{ marginLeft: '32px', marginRight: '8px' }}>Cancel</Button>
                                <Button variant='contained' onClick={confirmSave} >Confirm</Button>
                            </React.Fragment>}
                    </React.Fragment>
                }
            />
        </Grid>

    )
}

export default withRouter(GrainOrdersReport)