import React from 'react';
import { Button, makeStyles } from '@material-ui/core';
import { PDFViewer, Font, Page, Text, View, Document, StyleSheet } from '@react-pdf/renderer';
import moment from 'moment';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { withRouter } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        justifyContent: 'center',
        width: '100%',
    },
    content: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        maxWidth: '1200px',
        margin: 'auto',
    },
    buttonRow: {
        marginBottom: '20px',
        marginTop: '20px',
    },
    checkRow: {
        margin: theme.spacing(1),
        display: 'flex',
        justifyContent: 'space-evenly',
    },
}));

Font.register({
    family: 'Oswald',
    src: 'https://fonts.gstatic.com/s/oswald/v13/Y_TKV6o8WovbUd3m_X9aAA.ttf'
});

// Create styles
const styles = StyleSheet.create({
    page: {
        flexDirection: 'column',
        fontFamily: 'Helvetica',
        padding: 20,
    },
    section: {
        marginBottom: 10,
    },
    dTable: {
        display: "table",
        width: "auto",
        border: '1 solid black',

        marginBottom: 10,
    },
    dTableRow: {
        margin: "auto",
        flexDirection: "row"
    },
    dTableCol: {
        flexGrow: 1,
    },
    dTableCell: {
        margin: "auto",
        marginTop: 5,
        marginBottom: 5,
        fontSize: 10,
    },
    table: {
        border: '1 solid black',
        marginBottom: 10,
        padding: 10,
        flexGrow: 1,
        fontSize: 14,
        flexDirection: 'column',
        justifyContent: 'space-between',
    },
    tableRow: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginTop: 2,
        marginBottom: 2,
    },
    tableText: {
        fontSize: 12,
    },
    tableTextSmall: {
        fontSize: 10,
    },
    tableTextBold: {
        fontSize: 12,
        fontFamily: 'Helvetica-Bold',
        fontWeight: 'bold',
    },
    tableTextItalic: {
        fontSize: 12,
        fontStyle: 'italic'
    },
    tableTextBoldItalic: {
        fontSize: 12,
        fontStyle: 'italic',
        fontFamily: 'Helvetica-Bold',
        textDecoration: 'underline',
    },
    summary: {
        flexDirection: 'row',
    },
    viewer: {
        width: '100%',
        maxWidth: '1200px',
        height: '85vh',
    },
});

// Create Document Component
const UserAbstractPermissionPDF = (props) => {
    const classes = useStyles();
    let today = moment(new Date()).format('dddd MMMM Do, YYYY');

    return (
        <div className={classes.root}>
            <div className={classes.content}>

                <div className={classes.buttonRow}>
                    <Button 
                        variant='contained'  
                        onClick={()=> {props.history.goBack()}}
                    
                        startIcon={<ArrowBackIcon />}
                    >
                        Back
                        </Button>
                </div>

                <PDFViewer style={styles.viewer}>
                    <Document>
                        <Page size="A4" style={styles.page} wrap>
                            <Text style={{...styles.tableText, marginBottom:'10px'}}>{today}</Text>
                            <Text style={{...styles.tableTextBold,}}>Motor Vehicle Branch</Text>
                            <Text style={{...styles.tableText,}}>1076 Portage Avenue</Text>
                            <Text style={{...styles.tableText,}}>Winnipeg, Manitoba</Text>
                            <Text style={{...styles.tableText,}}>R3G 0S1</Text>
                            <Text style={{...styles.tableText, marginBottom:'100px'}}></Text>
                            <Text style={{textAlign: 'center', fontFamily: 'Brush', fontSize: '26pt', marginBottom:'50px'}}>Gee Tee Holdings Inc.</Text>

                            <Text style={{textAlign: 'left', fontSize:'14px', marginBottom:'10px'}}>Attention: Driver Abstract Department</Text>
                            <Text style={{textAlign: 'left', fontSize:'12px', marginLeft: '60px', marginBottom:'6px'}}>I, {props.userData.displayName}</Text>
                            <View style={styles.summary} wrap={false}>
                                <Text style={{textAlign: 'left', fontSize:'12px', marginLeft: '60px', marginBottom:'80px'}}>Do hereby authorize: <Text style={styles.tableTextBoldItalic}>Gee Tee Holdings Inc.</Text></Text> 
                            </View>    
                            <View style={styles.summary} wrap={false}>
                                <Text style={{textAlign: 'left', fontSize:'12px', marginLeft: '60px', marginBottom:'24px'}}>to obtain a copy of my Driver's Abstract.</Text>
                            </View>       

                            <View style={{...styles.summary, marginTop: '30px'}} wrap={false}>
                                <Text style={{...styles.tableTextBold, textAlign: 'center'}}>Signed: ____________________________________________________________</Text>
                            </View>
                            <View style={{...styles.summary, marginTop: '4px', marginBottom:'8px'}} wrap={false}>
                                <Text style={{...styles.tableTextBold, textAlign: 'center'}}>{props.userData.displayName}</Text>
                            </View>  
                            <View style={styles.summary} wrap={false}>
                                <View style={{ ...styles.table, marginLeft: '50px', marginRight: '50px' }}>
                                    <View style={styles.tableRow}>
                                        <Text style={styles.tableText}>Driver's License: </Text>
                                        <Text style={styles.tableText}>{props.userData.driversLicenseNum}</Text>
                                    </View>     
                                    <View style={styles.tableRow}>
                                        <Text style={styles.tableText}>Birthdate: </Text>
                                        <Text style={styles.tableText}>{moment(props.userData.birthday).format('dddd MMMM Do, YYYY')}</Text>
                                    </View>     
                                </View>      
                            </View>  
                            <View style={styles.summary} wrap={false}>
                                <Text style={{textAlign: 'left', fontSize:'14px', marginBottom:'10px'}}>Thank you.</Text>
                            </View>      
                        </Page>
                    </Document>
                </PDFViewer>
            </div>
        </div>
    )
};

export default withRouter(UserAbstractPermissionPDF);