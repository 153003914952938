import React from 'react';
import moment from 'moment';
import { Button, Checkbox, FormControlLabel, makeStyles } from '@material-ui/core';
import { PDFViewer, Font, Page, Text, View, Document, StyleSheet } from '@react-pdf/renderer';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { withRouter } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        justifyContent: 'center',
        width: '100%',
    },
    content: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        maxWidth: '1200px',
        margin: 'auto',
    },
    buttonRow: {
        marginBottom: '20px',
        marginTop: '20px',
    },
    checkRow: {
        margin: theme.spacing(1),
        display: 'flex',
        justifyContent: 'space-evenly',
    },
}));

Font.register({
    family: 'Oswald',
    src: 'https://fonts.gstatic.com/s/oswald/v13/Y_TKV6o8WovbUd3m_X9aAA.ttf'
});

// Create styles
const styles = StyleSheet.create({
    page: {
        flexDirection: 'column',
        fontFamily: 'Helvetica',
        padding: 20,
    },
    section: {
        marginBottom: 10,
    },
    dTable: {
        display: "table",
        width: "auto",
        border: '1 solid black',

        marginBottom: 10,
    },
    dTableRow: {
        margin: "auto",
        flexDirection: "row"
    },
    dTableCol: {
        flexGrow: 1,
    },
    dTableCell: {
        margin: "auto",
        marginTop: 5,
        marginBottom: 5,
        fontSize: 10,
    },
    table: {
        border: '1 solid black',
        padding: 10,
        flexGrow: 1,
        fontSize: 14,
        flexDirection: 'column',
        justifyContent: 'space-between',
    },
    tableRow: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginTop: 2,
        marginBottom: 2,
    },
    tableText: {
        fontSize: 12,
    },
    tableTextSmall: {
        fontSize: 10,
    },
    tableTextBold: {
        fontSize: 12,
        fontFamily: 'Helvetica-Bold',
        fontWeight: 'bold',
    },
    tableTextBoldUnderline: {
        fontSize: 12,
        fontFamily: 'Helvetica-Bold',
        fontWeight: 'bold',
        textDecoration: 'underline'
    },
    summary: {
        flexDirection: 'row',
    },
    viewer: {
        width: '100%',
        maxWidth: '1200px',
        height: '85vh',
    },
});

// Create Document Component
const OrderPDF = (props) => {
    const [showDeliveryInfo, setShowDeliveryInfo] = React.useState(true);
    const [showNotes, setShowNotes] = React.useState(true);

    const [skills, setSkills] = React.useState([
        {name: 'Gravel', value: 'gravel'},
        {name: 'Salt', value: 'salt'},
        {name: 'Snow', value: 'snow'},
        {name: 'Asphalt', value: 'asphalt'},
        {name: 'Highway Driving Canada', value: 'highwayCAN'},
        {name: 'Highway Driving USA', value: 'highwayUSA'},
        {name: 'Shop Work', value: 'shopWork'},
        {name: 'Trains', value: 'trains'},
        {name: 'Tandem Trucks', value: 'tandemTrucks'},
        {name: 'Grader', value: 'grader'},
        {name: 'Front End Loader', value: 'frontEndLoader'},
        {name: 'Pickup and Delivery', value: 'pickupDelivery'},
    ])

    const [prefs, setPrefs] = React.useState([
        {name: 'Days', value: 'days'},
        {name: 'Nights', value: 'nights'},
        {name: 'Weekends', value: 'weekends'},
        {name: 'Seasonal', value: 'seasonal'},
        {name: 'On Spare List', value: 'spareList'},
    ])

    React.useEffect(() => {

    }, [setSkills, setPrefs]);

    const classes = useStyles();
    let today = moment(new Date()).format('dddd MMMM Do, YYYY');

    /**
     * @param title - Table Header
     * @param columns - Generates table column header and its internal data. Can pass either an objects key to iterate or a function.
     * @param data - The table data passed (as an array of objects)
     * @param components - React components passed as special rendering for additional rows 
     */
    const Table = (props) => {

        let columnWidth = 100 / props.columns.length + '%';

        return (
            <View style={styles.dTable}>
                <Text style={styles.dTableRow}>{props.title}</Text>

                <View style={styles.dTableRow}>
                    {props.columns.map((column, index) => {
                        return (
                            <View style={{ ...styles.dTableCol, width: columnWidth }} key={index}>
                                <Text style={{ ...styles.dTableCell, fontSize: 12 }}>{column.title}</Text>
                            </View>
                        )
                    })}
                </View>

                {props.data.map((data, index) => {
                    return (
                        <View key={'tablerow' + props.title + index}>
                            <View style={styles.dTableRow} wrap={false}>
                                {props.columns.map((column, index) => {

                                    //Use render function if exists, otherwise render data.
                                    let cellData = column.render ? column.render(data) : data[column.field];

                                    return (
                                        <View style={{ ...styles.dTableCol, width: columnWidth }} key={index}>
                                            <Text style={styles.dTableCell}>{cellData}</Text>
                                        </View>
                                    )
                                })}
                            </View>
                        </View>
                    )
                })}
            </View>
        )
    }

    return (
        <div className={classes.root}>
            <div className={classes.content}>

                <div className={classes.buttonRow}>
                    <Button 
                        variant='contained' 
                        onClick={()=> {props.history.goBack()}}
                        startIcon={<ArrowBackIcon />}
                    >
                        Back
                    </Button>
                </div>
                
                <div className={classes.checkRow}>
                    <FormControlLabel
                        control={<Checkbox checked={showDeliveryInfo} onChange={() => setShowDeliveryInfo(!showDeliveryInfo)} />}
                        label="Show Contacts Table" />
                    <FormControlLabel
                        control={<Checkbox checked={showNotes} onChange={() => setShowNotes(!showNotes)} />}
                        label="Show Notes" />
                </div>

                <PDFViewer style={styles.viewer}>
                    <Document>
                        <Page size="A4" style={styles.page} wrap>

                            <View style={styles.section}>
                                <View style={{...styles.tableRow, margin:'4px'}}>
                                    <Text style={{...styles.tableText,fontSize: '14px'}}>Employee Report</Text>
                                    <Text style={{...styles.tableText,fontSize: '14px'}}>{today}</Text>
                                </View>
                            </View>
                            <View style={{...styles.summary, margin:'0px'}} wrap={false}>
                                <View style={{ ...styles.table, margin:'0px'}}>
                                    <Text style={{...styles.tableTextBold, alignSelf:'center', fontSize:'14px', padding:'0px'}}>{props.userData.displayName}</Text>
                                    {props.userData.employment.type === 'Subcontractor' ?
                                    <View style={{...styles.tableRow, margin:'4px'}}>
                                        <Text style={{...styles.tableTextBold, }}>GST #:</Text>
                                        <Text style={{...styles.tableText, }}>{props.userData.employment.gstNum}</Text>
                                        <Text style={{...styles.tableTextBold, }}>Workers Compensation #:</Text>
                                        <Text style={{...styles.tableText, }}>{props.userData.employment.wcbNum}</Text>
                                    </View>:null}
                                </View>
                            </View>
                            <View style={styles.summary} wrap={false}>
                                <View style={{ ...styles.table, margin:'0px', top:'-1px' }}>
                                    <View style={{...styles.tableRow, marginBottom:'8px'}}>
                                        <Text style={styles.tableTextBold}>Employee Active? {props.userData.employment.active ? "[x]" : "[ ]"}</Text>
                                        <Text style={styles.tableText}></Text>
                                        <Text style={{...styles.tableTextBold, }}>Employee or Subcontractor:</Text>
                                        <Text style={{...styles.tableText, }}>{props.userData.employment.type}</Text>
                                    </View>
                                    <View style={styles.tableRow}>
                                        <Text style={styles.tableTextBoldUnderline}>Employment Dates</Text>
                                        <Text style={styles.tableText}></Text>
                                        <Text style={styles.tableTextBold}></Text>
                                        <Text style={styles.tableTextBold}></Text>
                                        <Text style={styles.tableTextBold}></Text>
                                        <Text style={styles.tableTextBold}>Address:</Text>
                                        <Text style={styles.tableText}>{props.userData.address.street}</Text>
                                    </View>
                                    <View style={styles.tableRow}>
                                        <Text style={styles.tableText}>Start Date: {moment(props.userData.employment.startDate).format('MMM D YYYY')}</Text>
                                        <Text style={styles.tableText}></Text>
                                        <Text style={styles.tableTextBold}></Text>
                                        <Text style={styles.tableTextBold}></Text>
                                        <Text style={styles.tableText}>{props.userData.address.city}, {props.userData.address.province}</Text>
                                    </View>
                                    <View style={{...styles.tableRow, marginBottom:'16px'}}>
                                        <Text style={styles.tableText}>End Date:  {!props.userData.employment.active ? 
                                        moment(props.userData.employment.endDate).format('MMM D YYYY') : "Still Employed"}</Text>
                                        <Text style={styles.tableText}></Text>
                                        <Text style={styles.tableTextBold}></Text>
                                        <Text style={styles.tableTextBold}></Text>
                                        <Text style={styles.tableText}>{props.userData.address.postalCode}</Text>
                                    </View>
                                    <View style={{...styles.tableRow}}>
                                        <Text style={styles.tableTextBoldUnderline}>Personal Information</Text>
                                    </View>
                                    <View style={{...styles.tableRow}}>
                                        <Text style={styles.tableTextBold}>Birthday:</Text>
                                        <Text style={styles.tableText}>{moment(props.userData.birthday).format('MMM D YYYY')}</Text>
                                    </View>
                                    <View style={{...styles.tableRow}}>
                                        <Text style={styles.tableTextBold}>Driver's License Number:</Text>
                                        <Text style={styles.tableText}>{props.userData.driversLicenseNum ? props.userData.driversLicenseNum : 'N/A'}</Text>
                                    </View>
                                    <View style={{...styles.tableRow, marginBottom: '16px'}}>
                                        <Text style={styles.tableTextBold}>License Type:</Text>
                                        <Text style={styles.tableText}>{props.userData.driversLicenseType ? props.userData.driversLicenseType : 'N/A'}</Text>
                                    </View>
                     
                                    <View style={{...styles.tableRow}}>
                                        <Text style={styles.tableTextBoldUnderline}>Payroll Information</Text>
                                    </View>
                                    <View style={{...styles.tableRow}}>
                                        <Text style={styles.tableTextBold}>Classification:</Text>
                                        <Text style={styles.tableText}>{props.userData.payroll.class}</Text>
                                    </View>
                                    <View style={{...styles.tableRow}}>
                                        <Text style={styles.tableTextBold}>Pay Rate:</Text>
                                        <Text style={styles.tableText}>${props.userData.payroll.payRate}</Text>
                                    </View>
                                    <View style={{...styles.tableRow}}>
                                        <Text style={styles.tableTextBold}>Vacation Pay Rate:</Text>
                                        <Text style={styles.tableText}>{props.userData.payroll.vacationRate}%</Text>
                                    </View>
                                    <View style={{...styles.tableRow, marginBottom:'10px'}}>
                                        <Text style={styles.tableTextBold}>Payroll Frequency:</Text>
                                        <Text style={styles.tableText}>{props.userData.payroll.payFrequency}</Text>
                                    </View>

                                    <View style={{...styles.tableRow}}>
                                        <Text style={styles.tableTextBoldUnderline}>Benefits</Text>
                                    </View>
                                    <View style={{...styles.tableRow}}>
                                        <Text style={styles.tableTextBold}>Insurance:</Text>
                                        <Text style={styles.tableText}>{props.userData.benefits.insurance}</Text>
                                    </View>
                                    <View style={{...styles.tableRow}}>
                                        <Text style={styles.tableTextBold}>Insurance Effective Date:</Text>
                                        <Text style={styles.tableText}>{props.userData.benefits.effectiveDate ? 
                                        moment(props.userData.benefits.effectiveDate).format('MMM D YYYY') : 'N/A'}</Text>
                                    </View>
                                    <View style={{...styles.tableRow, marginBottom:'16px'}}>
                                        <Text style={styles.tableTextBold}>Premium:</Text>
                                        <Text style={styles.tableText}>${props.userData.benefits.premium}</Text>
                                    </View>
                                    
                                    

                                    
                                    
                                    
                                    
                                    
                                    {showNotes
                                        ? <View style={{ ...styles.dTableCol, width: '100%', backgroundColor: '#ddd' }}>
                                            <Text style={{ ...styles.dTableCell, justifyContent: 'center' }}>Notes</Text>
                                            <Text style={{ ...styles.dTableCell, width: '100%' }}>{props.userData.notes}</Text>
                                        </View>
                                        : null}
                                </View>

                            </View>

                            <View style={styles.summary} wrap={false}>
                                <View style={{ ...styles.table,  top:'-2px' }}>
                                <View style={{...styles.tableRow}}>
                                        <Text style={styles.tableTextBoldUnderline}>Work Skills</Text>
                                    </View>
                                {skills.map((skill, index) => {
                                        return (
                                            <View key={index} style={{...styles.tableRow, marginBottom:'2px'}}>
                                                <Text style={styles.tableText}>{skill.name} {props.userData.skills[skill.value] ? "[x]" : "[ ]"}</Text>
                                            </View>
                                    )})} 
                                </View>
                                <View style={{ ...styles.table,  top:'-2px'  }}>
                                <View style={{...styles.tableRow}}>
                                        <Text style={styles.tableTextBoldUnderline}>Work Preferences</Text>
                                    </View>
                                {prefs.map((skill, index) => {
                                        return (
                                            <View key={index} style={{...styles.tableRow, marginBottom:'2px'}}>
                                                <Text style={styles.tableText}>{skill.name} {props.userData.preferences[skill.value] ? "[x]" : "[ ]"}</Text>
                                            </View>
                                    )})} 
                                    <View style={{...styles.tableRow, marginBottom:'2px'}}>
                                        <Text style={styles.tableText}>Full Time {props.userData.preferences.fullPart === 'Full Time' ? "[x]" : "[ ]"}</Text>
                                    </View> 
                                    <View style={{...styles.tableRow, marginBottom:'2px'}}>
                                        <Text style={styles.tableText}>Part Time {props.userData.preferences.fullPart === 'Part Time' ? "[x]" : "[ ]"}</Text>
                                    </View> 

                                </View>
                            </View>
                            

                            
                           


                            
                        </Page>
                        {showDeliveryInfo 
                                ?
                        <Page size="A4" style={styles.page} wrap>
                                <Table
                                    columns={[
                                        { title: 'Contact', field: 'name' },
                                        { title: 'Phone #', field: 'phone' },
                                        { title: 'Phone Description', field: 'description' },
                                        { title: 'Email Address', field: 'email' },
                                    ]}
                                    data={props.userData.contacts}
                                    title={"Contacts"} />
                                
                        </Page>: null}
                    </Document>
                </PDFViewer>
            </div>
        </div>
    )
};

export default withRouter(OrderPDF);