import * as actionTypes from '../actions/actionTypes'
import { updateObject } from '../utility'
import moment from 'moment';
import { firestore } from '../../firebase/firebase'

//Make sure reducers have an initialState 
const today = new Date();
const nextAvailableDeliverDate = async () => {
    const week = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
    
    try {
        const querySnapshot = await firestore.collection('shopSettings').where('__name__', '==', 'shopSettings').get();
        
        const response = querySnapshot.docs.map(doc => doc.data());
        const operatingHours = response[0].operatingHours;
        let nextAvailableDay = moment();

        // Get today's operating hours
        let todayOperatingHours = operatingHours[week[nextAvailableDay.day()]];

        // Adjust closeTime to include today's date for proper comparison
        let closeTimeToday = moment(todayOperatingHours.closeTime, 'HH:mm').set({
            year: nextAvailableDay.year(),
            month: nextAvailableDay.month(),
            date: nextAvailableDay.date()
        });
        // Check if today is closed or after closing time
        if (todayOperatingHours.closed || nextAvailableDay.isAfter(closeTimeToday)) {
            nextAvailableDay = nextAvailableDay.add(1, 'days');
            
            let loopCount = 0;
            // Loop until we find the next available day
            while (operatingHours[week[nextAvailableDay.day()]].closed) {
                nextAvailableDay = nextAvailableDay.add(1, 'days');
                loopCount++;
                
                if (loopCount > 7) {
                    console.warn('Next available delivery date not found');
                    return null;
                }
            }
        }

        // Returning the formatted date
        return nextAvailableDay.format('YYYY-MM-DD');
    } catch (error) {
        console.error('Error fetching shop settings:', error);
        return null;
    }
};

const initialState = {
    //access this state in your components using the name you 
    // set in index.js (you did set it in combineReducers, right?)
    // checkout.state.contact 
    // or checkout.state.loading

    checkoutForm: {
        firstName: {
            name: 'given-name',
            autoComplete: 'given-name',
            elementType: 'input',
            elementConfig: {
                type: 'text',
                id: "firstname",
                inputProps: {autoComplete: 'given-name'},
                label: 'First Name',
                required: true,
            },
            value: '',
        },
        lastName: {
            name: 'family-name',
            autoComplete: 'family-name',
            elementType: 'input',
            elementConfig: {
                type: 'text',
                id: "lastname",
                autoComplete: 'family-name',
                inputProps: {autoComplete: 'family-name'},
                label: 'Last Name',
                required: true,
            },
            value: '',
        },
        email: {
            name: 'email',
            autoComplete: 'email',
            elementType: 'input',
            elementConfig: {
                type: 'email',
                id: "email",
                autoComplete: 'email',
                inputProps: {autoComplete: 'email'},
                label: 'Email',
                required: false,
            },
            value: '',
        },
        phone: {
            name: 'tel-national',
            autoComplete: 'tel-national',
            elementType: 'input',
            elementConfig: {
                type: 'tel',
                id: "phone",
                autoComplete: 'tel-national',
                inputProps: {autoComplete: 'tel-national'},
                label: 'Phone Number',
                required: true,
            },
            value: '',
        },
        address: {
            name: 'street-address',
            autoComplete: 'street-address',
            elementType: 'input',
            elementConfig: {
                type: 'text',
                id: "billingaddress",
                autoComplete: 'address-line1',
                inputProps: {autoComplete: 'address-line1'},
                label: 'Billing Address',
                required: true,
            },
            value: '',
        },
        postalCode: {
            name: 'postal-code',
            autoComplete: 'postal-code',
            elementType: 'input',
            elementConfig: {
                type: 'text',
                id: "postalcode",
                autoComplete: 'postal-code',
                inputProps: {autoComplete: 'postal-code'},
                label: 'Postal Code',
                required: true,
            },
            value: '',
        },
        country: {
            name: 'country',
            autoComplete: 'country',
            elementType: 'input',
            elementConfig: {
                type: 'text',
                label: 'Country',
                id: "country",
                autoComplete: 'country-name',
                inputProps: {autoComplete: 'country-name'},
                required: true,
            },
            value: 'Canada',
        },
        province: {
            name: 'address-level1',
            autoComplete: 'address-level1',
            elementType: 'input',
            elementConfig: {
                type: 'text',
                id: "province",
                autoComplete: 'address-level1',
                inputProps: {autoComplete: 'address-level1'},
                label: 'Province',
                required: true,
            },
            value: 'Manitoba',
        },
        city: {
            name: 'address-level2',
            autoComplete: 'address-level2',
            elementType: 'input',
            elementConfig: {
                type: 'text',
                id: "city",
                autoComplete: 'address-level2',
                inputProps: {autoComplete: 'address-level2'},
                label: 'City',
                required: true,
            },
            value: '',
        },
        notes: {
            elementType: 'textfield',
            elementConfig: {
                type: 'text',
                label: '',
                required: false,
            },
            value: '',
        },
        deliverDate: {
            elementType: 'input',
            elementConfig: {
                type: 'date',
                label: '',
                required: true,
                inputProps: { min: today.toISOString().slice(0, 10) },
            },
            value: moment(nextAvailableDeliverDate()).format('YYYY-MM-DD')
            //if today is in the dates closed then choose next available date
            ,
        },
        deliverTime: {
            elementType: 'select',
            elementConfig: {
                label: '',
                required: true,
                menuitems: ['AM', 'PM'],
            },
            value: moment().format('HH') > '11' ? 'PM' : 'AM',
        },        
        confirmEmail: {
            elementType: 'input',
            elementConfig: {
                type: 'confirmEmail',
                id: "confirmemail",
                autoComplete: 'email',
                inputProps: { autoComplete: 'email' },
                label: 'Confirm Email',
                required: false,
            },
            value: '',
        },
        preferredContactMethod: {
            elementType: 'radio',
            elementConfig: {
                label: 'Preferred Contact Method',
                fullWidth: true,
                variant: 'outlined',
                required: true,
                options: [{
                    value: 'Email',
                    label: 'Email',
                },
                {
                    value: 'Text Message',
                    label: 'Text Message',

                }],
            },
            value: 'Email',
        }
    },
    checkoutLoading: false,
    formSubmitted: false,
    formValid: false,

    generateDisabled: false,
    tokenError: false,
    processingPayment: false,
    //paymentComplete: sessionStorage.getItem('paymentComplete') || false,
    paymentComplete: false,
    paymentFailed: false,
};



const setNextAvailableDeliverDate = (state, action) => {
    const week = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', ];
    let datesClosed = {};
    firestore.collection('shopSettings').where('__name__', '==', 'datesClosed').get()
        .then((querySnapshot) => {
            var response = (querySnapshot.docs.map((doc) => {
                return {
                    ...doc.data(),
                }
            }));
            datesClosed = response[0].dates;
        })
   
    firestore.collection('shopSettings').where('__name__', '==', 'shopSettings').get()
        .then((querySnapshot) => {
            var response = (querySnapshot.docs.map((doc) => {
                return {
                    ...doc.data(),
                }
            }));

            let operatingHours = response[0].operatingHours;

            let nextAvailableDay = moment()
            // Get today's operating hours
            let todayOperatingHours = operatingHours[week[nextAvailableDay.day()]];

            // Adjust closeTime to include today's date for proper comparison
            let closeTimeToday = moment(todayOperatingHours.closeTime, 'HH:mm').set({
                year: nextAvailableDay.year(),
                month: nextAvailableDay.month(),
                date: nextAvailableDay.date()
            });
            // Check if today is closed or after closing time
            if (todayOperatingHours.closed || nextAvailableDay.isAfter(closeTimeToday)
            || 
            //if today is a key in datesClosed
            datesClosed[moment().format('YYYY-MM-DD')] !== undefined){

                nextAvailableDay = moment().add(1,'days');
                let loopCount = 0;
                
                while(operatingHours[week[moment(nextAvailableDay).day()]].closed === true || datesClosed[moment(nextAvailableDay).format('YYYY-MM-DD')] !== undefined){
                    nextAvailableDay = moment(nextAvailableDay).add(1,'days');

                    if(loopCount > 7) { console.warn('Next available delivery date not found'); return}
                    loopCount++
                }
                state.checkoutForm.deliverDate.value = moment(nextAvailableDay).format('YYYY-MM-DD')
                state.checkoutForm.deliverTime.value = 'AM'
            } else {
                state.checkoutForm.deliverDate.value = moment(nextAvailableDay).format('YYYY-MM-DD')
                //state.checkoutForm.deliverTime.value = 'AM'
            }
    });
        return {
            ...state,
            deliverDate: {
                ...state.checkoutForm.deliverDate,
                value: state.checkoutForm.deliverDate.value
            },
            deliverTime: {
                ...state.checkoutForm.deliverTime,
                value: state.checkoutForm.deliverTime.value
            },
        };
}

//Needs to load 
const validateCheckout = (state, action) => {

    let isValid = true;

    const emailPattern = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;
    const phonePattern = /[0-9]{3}-[0-9]{3}-[0-9]{4}/;

    Object.keys(state.checkoutForm).map((key, value) => {
        if (state.checkoutForm[key].value === '')
            isValid = false;
        if (state.checkoutForm[key].elementConfig.type === 'email')
            isValid = emailPattern.test(state.checkoutForm[key].value) && isValid;
        if (state.checkoutForm[key].elementConfig.type === 'phone') {
            isValid = phonePattern.test(state.checkoutForm[key].value) && isValid;
        }

        return isValid;
    })

    return {
        ...state,
        formValid: isValid,
    };
}

const resetCheckoutForm = (state, action) => {
    return {
        ...state,
        checkoutForm: {
            ...state.checkoutForm,
            notes: {
                ...state.checkoutForm.notes,
                value: ''
            },
            deliverDate: {
                ...state.checkoutForm.deliverDate,
                value: moment().format('YYYY-MM-DD')
            },
            deliverTime: {
                ...state.checkoutForm.deliverDate,
                value: moment().format('HH') > moment('12', 'HH').format('HH') ? 'PM' : 'AM'
            }
        }
    }
}

const completePayProcessing = (state, action) => {

    if (action.paymentComplete) {
        Object.keys(state.checkoutForm).forEach((key) => {
            state.checkoutForm[key].value = '';
        })

        state.checkoutForm['country'].value = 'Canada';
        state.checkoutForm['province'].value = 'Manitoba';

        state.paymentComplete = true;
        sessionStorage.setItem('paymentComplete', JSON.stringify(state.paymentComplete))
        state.formValid = false;
    }
    else {
        state.paymentFailed = true;
    }

    return {
        ...state,
        formValid: state.formValid,
        paymentComplete: state.paymentComplete,
        processingPayment: false,
        paymentFailed: state.paymentFailed,
    }
}

const checkoutReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.GET_CHECKOUT:
            return updateObject(state, {});
        case actionTypes.UPDATE_CHECKOUT:
            return updateObject(state, { checkoutForm: action.checkoutForm });
        case actionTypes.VALIDATE_CHECKOUT:
            return updateObject(state, validateCheckout(state, action));
        case actionTypes.RESET_CHECKOUT:
            return updateObject(state, { paymentComplete: false, processingPayment: false, formValid: false });
        case actionTypes.CREATE_PAYMENT:
            return updateObject(state, { checkoutLoading: action.loading, forumSubmitted: action.formSubmitted });
        case actionTypes.BEGIN_PAYMENT:
            return updateObject(state, { processingPayment: action.processingPayment });
        case actionTypes.COMPLETE_PAYMENT:
            return updateObject(state, completePayProcessing(state, action));
        case actionTypes.RESET_CHECKOUTFORM:
            return updateObject(state, resetCheckoutForm(state, action));
        case actionTypes.SET_NEXT_AVAILABLE_DELIVERDATE:
            return updateObject(state, setNextAvailableDeliverDate(state, action));
        case actionTypes.PAYMENT_FAILED:
            return updateObject(state, { paymentFailed: action.didPaymentFail });
        case actionTypes.SET_TOKEN:
            return updateObject(state, { token: action.token, tokenError: action.error })
        case actionTypes.SET_DISABLED:
            return updateObject(state, { generateDisabled: action.generateDisabled })
        default:
            return state;
    }
};

export default checkoutReducer