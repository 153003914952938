import React, { useEffect, useState } from 'react';
import moment from 'moment'
import { firestore } from '../../../../firebase/firebase'
import { withRouter } from 'react-router-dom';
import JobCard from '../../../components/Reports/Jobs/JobCard'
import { useUser } from '../../../../providers/UserContext';

const Job = (props) => {
    const [jobData, setJobData] = useState({})
    const [checklistData, setChecklistData] = useState({})
    const [formData, setFormData] = useState([])
    const [unitData, setUnitData] = useState([])
    const [loading, setLoading] = useState(true)
    const [errorMessage, setErrorMessage] = useState('')

    const { users, usersLoading, getUsers } = useUser();

    useEffect(() => {
        getUsers();
    }, []) // eslint-disable-line react-hooks/exhaustive-deps


    useEffect(() => {
        let path = new URLSearchParams(props.location.search).get("docId");

        firestore.collection('jobs').doc(path).get()
            .then(documentSnapshot => {
                let date = documentSnapshot.data().date
                if (date < 9999999999) date *= 1000
                const jobResponse = {
                    ...documentSnapshot.data(),
                    'docId': documentSnapshot.id,
                    'date': moment.utc(date).format('dddd, MMM DD, YYYY'),
                }

                setJobData(jobResponse)

                firestore.collection('checklists').where("jobID", "==", jobResponse.docId).get()
                    .then(querySnapshot => {
                        const checklistResponse = querySnapshot.docs.map((doc) => {
                            return {
                                ...doc.data(),
                                'docId': doc.id,
                            }
                        })
                        setChecklistData(checklistResponse)
                    })

                firestore.collection('documents').where("jobID", "==", jobResponse.docId).get()
                    .then(querySnapshot => {
                        const formResponse = querySnapshot.docs.map((doc) => {
                            return {
                                ...doc.data(),
                                'docId': doc.id,
                            }
                        })
                        setFormData(formResponse)
                    })

                firestore.collection('units').where("timesheetID", "==", jobResponse.timesheetID).get()
                    .then(querySnapshot => {

                        const unitResponse = querySnapshot.docs
                            .filter((unitDoc) => jobResponse.units.includes(unitDoc.id))
                            .map((doc) => {

                                return {
                                    ...doc.data(),
                                    'docId': doc.id,
                                }
                            })
                        setUnitData(unitResponse)
                    })

            }).catch(e => {
                setErrorMessage(e.message);
            }).finally(() => setLoading(false))

    }, [props.location.search])

    function updateUnit(newData) {
        return firestore.collection('units').doc(newData.docId).update(newData).then(() => {
            let newUnitData = [...unitData];
            let unitIndex = unitData.findIndex((unit) => unit.docId === newData.docId);
            newUnitData[unitIndex] = { ...newData };
            setUnitData(newUnitData);
        })
    }

    return (
        < JobCard
            loading={loading}
            jobData={jobData}
            checklistData={checklistData}
            unitData={unitData}
            userData={users}
            formData={formData}
            usersLoading={usersLoading}
            error={errorMessage}
            updateUnit={updateUnit}
        />
    )

}

export default withRouter(Job)