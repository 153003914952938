import React from 'react';
import moment from 'moment';
import { Button, Checkbox, FormControlLabel, makeStyles } from '@material-ui/core';
import { PDFViewer, Font, Page, Text, View, Document, StyleSheet } from '@react-pdf/renderer';
import _ from 'lodash';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { withRouter } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        justifyContent: 'center',
        width: '100%',
    },
    content: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        maxWidth: '1200px',
        margin: 'auto',
    },
    buttonRow: {
        marginBottom: '20px',
        marginTop: '20px',
    },
    checkRow: {
        margin: theme.spacing(1),
        display: 'flex',
        justifyContent: 'space-evenly',
    },
}));

Font.register({
    family: 'Oswald',
    src: 'https://fonts.gstatic.com/s/oswald/v13/Y_TKV6o8WovbUd3m_X9aAA.ttf'
});

// Create styles
const styles = StyleSheet.create({
    page: {
        flexDirection: 'column',
        fontFamily: 'Helvetica',
        padding: 20,
    },
    section: {
        marginBottom: 10,
    },
    dTable: {
        display: "table",
        width: "auto",
        border: '1 solid black',

        marginBottom: 10,
    },
    dTableRow: {
        margin: "auto",
        flexDirection: "row"
    },
    dTableCol: {
        flexGrow: 1,
    },
    dTableCell: {
        margin: "auto",
        marginTop: 5,
        marginBottom: 5,
        fontSize: 10,
    },
    table: {
        border: '1 solid black',
        marginBottom: 10,
        padding: 10,
        flexGrow: 1,
        fontSize: 14,
        flexDirection: 'column',
        justifyContent: 'space-between',
    },
    tableRow: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginTop: 2,
        marginBottom: 2,
    },
    tableText: {
        fontSize: 12,
    },
    tableTextSmall: {
        fontSize: 10,
    },
    tableTextBold: {
        fontSize: 12,
        fontFamily: 'Helvetica-Bold',
        fontWeight: 'bold',
    },
    summary: {
        flexDirection: 'row',
    },
    viewer: {
        width: '100%',
        maxWidth: '1200px',
        height: '85vh',
    },
});

// Create Document Component
const OrderSelectedPDF = (props) => {
    const [showCheckout, setShowCheckout] = React.useState(true);
    const [showShippingInfo, setShowShippingInfo] = React.useState(true);
    const [showDeliveryInfo, setShowDeliveryInfo] = React.useState(true);
    const [showPickupInfo, setShowPickupInfo] = React.useState(true);
    const [showNotes, setShowNotes] = React.useState(true);


    const classes = useStyles();

    if (props.orderData.date < 9999999999) props.timesheetData.date *= 1000


    /**
     * @param title - Table Header
     * @param columns - Generates table column header and its internal data. Can pass either an objects key to iterate or a function.
     * @param data - The table data passed (as an array of objects)
     * @param components - React components passed as special rendering for additional rows 
     */
    const Table = (props) => {

        let columnWidth = 100 / props.columns.length + '%'; 

        return (
            <View style={styles.dTable}>
                <Text style={styles.dTableRow}>{props.title}</Text>

                <View style={styles.dTableRow}>
                    {props.columns.map((column, index) => {
                        return (
                            <View style={{ ...styles.dTableCol, width: columnWidth }} key={index}>
                                <Text style={{ ...styles.dTableCell, fontSize: 12 }}>{column.title}</Text>
                            </View>
                        )
                    })}
                </View>

                {props.data.map((data, index) => {
                    return (
                        <View key={'tablerow' + props.title + index}>
                            <View style={styles.dTableRow} wrap={false}>
                                {props.columns.map((column, index) => {

                                    //Use render function if exists, otherwise render data.
                                    let cellData = column.render ? column.render(data) : data[column.field];

                                    return (
                                        <View style={{ ...styles.dTableCol, width: columnWidth }} key={index}>
                                            <Text style={styles.dTableCell}>{cellData}</Text>
                                        </View>
                                    )
                                })}
                            </View>
                        </View>
                    )
                })}
            </View>
        )
    }

    return (
        <div className={classes.root}>
            <div className={classes.content}>

                <div className={classes.buttonRow}>
                    <Button variant='contained' onClick={()=> { props.history.goBack() }} startIcon={<ArrowBackIcon/>}>Back</Button>
                </div>

                <div className={classes.checkRow}>
                    <FormControlLabel
                        control={<Checkbox checked={showCheckout} onChange={() => setShowCheckout(!showCheckout)} />}
                        label="Show Checkout" />
                    <FormControlLabel
                        control={<Checkbox checked={showShippingInfo} onChange={() => setShowShippingInfo(!showShippingInfo)} />}
                        label="Show Shipping Info" />
                    <FormControlLabel
                        control={<Checkbox checked={showDeliveryInfo} onChange={() => setShowDeliveryInfo(!showDeliveryInfo)} />}
                        label="Show Delivery Info" />
                    <FormControlLabel
                        control={<Checkbox checked={showPickupInfo} onChange={() => setShowPickupInfo(!showPickupInfo)} />}
                        label="Show Pickup Info" />
                    <FormControlLabel
                        control={<Checkbox checked={showNotes} onChange={() => setShowNotes(!showNotes)} />}
                        label="Show Notes" />
                </div>

                <PDFViewer style={styles.viewer}>
                    <Document>
                        {props.orderData.map((order, index) => {
                            
                        const deliverables = order !== null && order?.order.products.filter(product => (product.shippingMethod === 'Delivery'))
                        const pickupables = order !== null && order?.order.products.filter(product => (product.shippingMethod === 'Pickup'))

                        return(
                        <Page size="A4" style={styles.page} wrap>

                            <View style={styles.section}>
                                <Text>Order #{order?.order.orderID}</Text>
                                
                            </View>
                            <View style={styles.tableText}>
                                <Text>BOL #{order?.bol}</Text>
                                <Text>Operator: {order?.operator}</Text>
                                <Text>Truck: Unit #{order?.truckNumber?.unitNumber} - {order?.truckNumber?.description}</Text>
                            </View>
                            <View style={styles.summary} wrap={false}>
                                <View style={{ ...styles.table, marginRight: 5 }}>
                                    <View style={styles.tableRow}>
                                        <Text style={styles.tableTextBold}>Customer Details</Text>
                                    </View>
                                    <View style={styles.tableRow}>
                                        <Text style={styles.tableText}>Name</Text>
                                        <Text style={styles.tableText}>{order.customer.givenName + " " + order.customer.familyName}</Text>
                                    </View>
                                    <View style={styles.tableRow}>
                                        <Text style={styles.tableText}>Email</Text>
                                        <Text style={styles.tableText}>{order.customer?.email}</Text>
                                    </View>
                                    <View style={styles.tableRow}>
                                        <Text style={styles.tableText}>Phone #</Text>
                                        <Text style={styles.tableText}>{order.customer?.phone}</Text>
                                    </View>
                                    <View style={styles.tableRow}>
                                        <Text style={styles.tableText}>Address   </Text>
                                        <Text style={styles.tableText}>{order.customer?.addressLines}</Text>
                                    </View>
                                    <View style={styles.tableRow}>
                                        <Text style={styles.tableText}></Text>
                                        <Text style={styles.tableText}>{order.customer?.city}, {order.customer?.province}</Text>
                                    </View>
                                    <View style={styles.tableRow}>
                                        <Text style={styles.tableText}></Text>
                                        <Text style={styles.tableText}>{order.customer?.postalCode}</Text>
                                    </View>
                                </View>

                                <View style={{ ...styles.table, marginLeft: 5 }}>
                                    <View style={styles.tableRow}>
                                        <Text style={styles.tableTextBold}>Payment Details</Text>
                                    </View>
                                    <View style={styles.tableRow}>
                                        <Text style={styles.tableText}>Transaction Time</Text>
                                        <Text style={styles.tableText}>{moment.utc(order.payment.created_at).format('ddd, MMM D, YYYY, h:mm a')}</Text>
                                    </View>
                                    <View style={styles.tableRow}>
                                        <Text style={styles.tableText}>Payment Method</Text>
                                        <Text style={styles.tableText}>{order.payment.card_details.card.card_type}</Text>
                                    </View>
                                    <View style={styles.tableRow}>
                                        <Text style={styles.tableText}>Last Four Digits</Text>
                                        <Text style={styles.tableText}>{order.payment.card_details.card.last_4}</Text>
                                    </View>
                                    <View style={styles.tableRow}>
                                        <Text style={styles.tableText}>Card Type</Text>
                                        <Text style={styles.tableText}>{order.payment.card_details.card.card_brand}</Text>
                                    </View>
                                    <View style={styles.tableRow}>
                                        <Text style={styles.tableText}>Expiry Date</Text>
                                        <Text style={styles.tableText}>{order?.payment?.paymentHandler
                                            ? order.payment.card_details.card.exp_date.substr(0, 2) + "/" + order.payment.card_details.card.exp_date.substr(2, 3)
                                            : order.payment.card_details.card.exp_month + "/" + order.payment.card_details.card.exp_year}</Text>
                                    </View>
                                </View>

                            </View>
                            <View style={styles.summary} wrap={false}>
                                <View style={{ ...styles.table }}>
                                    <View style={styles.tableRow}>
                                        <Text style={styles.tableTextBold}>Delivery Details</Text>
                                    </View>
                                    <View style={styles.tableRow}>
                                        <Text style={styles.tableText}>Delivery Date</Text>
                                        <Text style={styles.tableText}>{(order.order.deliverDate ? moment(order.order.deliverDate, "YYYY-MM-DD").format('ddd, MMM D, YYYY') : "No Date")} {(order.order.deliverTime || "")}</Text>
                                    </View>
                                    <View style={styles.tableRow}>
                                        <Text style={styles.tableText}>Delivery Address</Text>
                                        <Text style={styles.tableText}>{order.customer.destination.location}</Text>
                                    </View>
                                    {showNotes
                                        ? <View style={{ ...styles.dTableCol, width: '100%', backgroundColor: '#ddd' }}>
                                            <Text style={{ ...styles.dTableCell, justifyContent: 'center' }}>Notes</Text>
                                            <Text style={{ ...styles.dTableCell, width: '100%' }}>{order.order.notes}</Text>
                                        </View>
                                        : null}
                                </View>

                            </View>
                            {/* {showShippingInfo && typeof order.order.fees.shippingInformation !== 'undefined'
                                ? <Table
                                    columns={[
                                        { title: 'Truck', render: rowData => { counter++; return (counter) } },
                                        { title: 'Slot 1', render: rowData => { return (rowData.slot1.name) } },
                                        { title: 'Quantity', render: rowData => { return (rowData.slot1.qty) } },
                                        { title: 'Slot 2', render: rowData => { return (rowData.slot2.name) } },
                                        { title: 'Quantity', render: rowData => { return (rowData.slot2.qty) } },
                                    ]}
                                    data={order.order.fees.shippingInformation.trucks}
                                    title={"Shipping Information"} />
                                : null} */}

                            {showDeliveryInfo && deliverables.length !== 0
                                ? <Table
                                    columns={[
                                        { title: 'Product', field: 'productName' },
                                        {
                                            title: 'Type', render: rowData => {
                                                //wanted it to show up as 1/4" down instead of down 1/4"
                                                const variants = Object.fromEntries(new Map(Object.entries(rowData.variant.variantTypes).sort()))
                                                //use flatMap to add a space between values (ex. 1/4" down vs 1/4"down)
                                                return <span>{Object.values(variants).flatMap(type => `${type} `)}</span>
                                            }
                                        },
                                        { title: 'Quantity', field: 'qty' },
                                        { title: 'Unit', render: rowData => { return (rowData.price.unit) } },
                                        { title: 'Unit Price', render: rowData => { return (rowData.price.price.toFixed(2)) } },
                                        { title: 'Total Price', render: rowData => { return ((rowData.price.price * rowData.qty).toFixed(2)) } },
                                    ]}
                                    data={deliverables}
                                    title={"Delivery"} />
                                : null}
                            {showPickupInfo && pickupables.length !== 0
                                ? <Table
                                    columns={[
                                        { title: 'Product', field: 'productName' },
                                        {
                                            title: 'Type', render: rowData => {
                                                //wanted it to show up as 1/4" down instead of down 1/4"
                                                const variants = Object.fromEntries(new Map(Object.entries(rowData.variant.variantTypes).sort()))
                                                //use flatMap to add a space between values (ex. 1/4" down vs 1/4"down)
                                                return <span>{Object.values(variants).flatMap(type => `${type} `)}</span>
                                            }
                                        },
                                        { title: 'Quantity', field: 'qty' },
                                        { title: 'Unit', render: rowData => { return (rowData.price.unit) } },
                                        { title: 'Unit Price', render: rowData => { return (rowData.price.price.toFixed(2)) } },
                                        { title: 'Total Price', render: rowData => { return ((rowData.price.price * rowData.qty).toFixed(2)) } },
                                    ]}
                                    data={pickupables}
                                    title={"Pickup"} />
                                : null}


                            {showCheckout
                                ? <View style={styles.summary} wrap={false}>
                                    <View style={{ ...styles.table }}>
                                        <View style={styles.tableRow}>
                                            <Text style={styles.tableTextBold}>Checkout</Text>
                                        </View>
                                        <View style={styles.tableRow}>
                                            <Text style={styles.tableText}>Material</Text>
                                            <Text style={styles.tableText}>${order.order.fees.subTotal.toFixed(2)}</Text>
                                        </View>
                                        <View style={styles.tableRow}>
                                            <Text style={styles.tableText}>Shipping</Text>
                                            <Text style={styles.tableText}>${order.order.fees.shipping.toFixed(2)}</Text>
                                        </View>
                                        <View style={styles.tableRow}>
                                            <Text style={styles.tableText}>Subtotal</Text>
                                            <Text style={styles.tableText}>${(order.order.fees.subTotal + order.order.fees.shipping).toFixed(2)}</Text>
                                        </View>
                                        <View style={styles.tableRow}>
                                            <Text style={styles.tableText}>Discounts</Text>
                                            <Text style={styles.tableText}>${order.order.fees.coupons.toFixed(2)}</Text>
                                        </View>
                                        {!_.isEmpty(order.order.fees.couponCode) &&
                                            <View style={styles.tableRow}>
                                                <Text style={styles.tableText}>Coupon</Text>
                                                <Text style={styles.tableTextBold}>{order.order.fees.couponCode.code.toUpperCase()}</Text>
                                                <Text style={styles.tableTextSmall}>{order.order.fees.couponCode.description}</Text>
                                            </View>}

                                        <View style={styles.tableRow}>
                                            <Text style={styles.tableText}>Subtotal</Text>
                                            <Text style={styles.tableText}>${(order.order.fees.shipping + order.order.fees.discSubTotal).toFixed(2)}</Text>
                                        </View>
                                        <View style={styles.tableRow}>
                                            <Text style={styles.tableText}>Carbon Fee</Text>
                                            <Text style={styles.tableText}>${order.order.fees.cartTaxes['Carbon Fees'].toFixed(2)}</Text>
                                        </View>
                                        <View style={styles.tableRow}>
                                            <Text style={styles.tableText}>GST</Text>
                                            <Text style={styles.tableText}>${order.order.fees.cartTaxes['GST'].toFixed(2)}</Text>
                                        </View>
                                        <View style={styles.tableRow}>
                                            <Text style={styles.tableText}>PST</Text>
                                            <Text style={styles.tableText}>${order.order.fees.cartTaxes['PST'].toFixed(2)}</Text>
                                        </View>
                                        <View style={styles.tableRow}>
                                            <Text style={styles.tableTextBold}>Total</Text>
                                            <Text style={styles.tableTextBold}>${(order?.payment?.paymentHandler ? parseFloat(order?.order?.fees?.total) : order?.order?.fees?.total / 100).toFixed(2)}</Text>
                                        </View>
                                    </View>
                                </View>
                                : null}
                        </Page>
                        )})}
                    </Document>
                </PDFViewer>
            </div>
        </div>
    )
};

export default withRouter(OrderSelectedPDF);