import React, { useEffect, useState } from 'react';
import { Grid, makeStyles, Button } from '@material-ui/core';
import { withRouter } from 'react-router';
import { TableIcons } from '../../../constants/tableIcons';
import MaterialTable from '@material-table/core';
import AddBoxIcon from '@material-ui/icons/AddBox';
import { firestore } from '../../../firebase/firebase';
import { Skeleton } from '@material-ui/lab';


const useStyles = makeStyles((theme) => ({

  card: {
    padding: theme.spacing(4),
    width: '100%',
    height: '100%',
  },

  content: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    //padding: theme.spacing(2),
  },

  grid: {
    marginTop: theme.spacing(2),
    maxWidth: '1600px',
  },

  img: {
    width: '64px',
    height: '64px',
    border: '1px solid #0000004a',
    borderRadius: '8px',
  },

  table: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
  },

  tile: {
    width: '300px',
    height: '300px',
  },

  tileContent: {
    display: 'flex',
    justifyContent: 'space-evenly',
    alignItems: 'center',
    flexDirection: 'column',
    width: '100%',
    height: '100%',
    padding: theme.spacing(4),
  },

  tileIcon: {
    color: 'rgba(0, 0, 0, 0.54)',
    width: '100px',
    height: '100px',
  },

  tileText: {
    fontSize: '1.10rem',
  },
  btn: {
    marginBottom: '20px',
    marginTop: '20px',
},
}));

const ChecklistHome = (props) => {

  const [searchBarValue, setSearchBarValue] = useState("checklistSearchBarValue" in sessionStorage ? JSON.parse(sessionStorage.getItem("checklistSearchBarValue")) : null)
  const [checklists, setChecklists] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  //const [saving, setSaving] = useState(false);

  const classes = useStyles();

  const tileClickHandler = (path, searchParams) => {
    props.history.push({
      pathname: "checklists/" + path,
      search: searchParams && "?" + new URLSearchParams({ docId: searchParams }).toString(),
    })
  };

  useEffect(() => {
    setLoading(true)
    firestore.collection('premadeChecklists').get()
      .then(querySnapshot => {
        const checklistResponse = querySnapshot.docs.map((doc) => {
          return {
            ...doc.data(),
            'docId': doc.id,
          }
        })
        setChecklists(checklistResponse);
        setLoading(false);
      })
      .catch(e => {
        setError("Unable to Process");
        setLoading(false);
      })
  }, []);

  return (
    <div className={classes.content}>
      {loading
      ? 
        <Skeleton variant='rect' width={'51vw'} height={'35vh'} style={{marginTop: '75px'}}/>
      : 
        <Grid>
          <Grid className={classes.btn} >
              <Button variant="contained"
              startIcon={<AddBoxIcon/>} onClick={() => {tileClickHandler('checklist', 'new')}}>Create New Checklist</Button>
          </Grid> 
            {error !== '' && <p>{error}</p>}
            <Grid item sm={12} xs={12} md={12} className={classes.table}>
              <MaterialTable
                icons={TableIcons}
                actions={[
                  {
                    icon: TableIcons.OpenInNew,
                    tooltip: 'Show ' + props.title,
                    onClick: (event, rowData) => { tileClickHandler('checklist', rowData.docId) }
                  },
                ]}
                columns={[
                  { title: 'Title', field: 'title' },
                  { title: 'Description', field: 'description', width:'100%' },
                  { title: 'Active', render: (rowData) => rowData.active ? 'Yes' : 'No' },
                  { title: 'Requires Timesheet', render: (rowData) => rowData.requiresTimesheet ? 'Yes' : 'No' },
                ]}
                data={checklists}
                loading={loading}
                title={"Checklists"}
                pathname='/dashboard/checklists/checklist'
                onSearchChange={ (value) => {setSearchBarValue(value);  sessionStorage.setItem("checklistSearchBarValue", JSON.stringify(value))}}
                options={{
                  toolbar: true,
                  paging: true,
                  pageSizeOptions: false,
                  pageSize: 5,
                  actionsColumnIndex: -1,
                  search: true,
                  emptyRowsWhenPaging: false,
                  searchText: searchBarValue,
                  rowStyle: {
                    whiteSpace: 'pre-wrap',
                  },
                }}
              />
            </Grid>
            
        </Grid>
      }
    </div>
  )
}

export default withRouter(ChecklistHome);