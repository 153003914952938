import React, { useState } from 'react';
import MaterialTable from '@material-table/core';
import { TableIcons } from '../../../../constants/tableIcons';
import { withRouter } from 'react-router-dom';
import moment from 'moment';
import Img from 'react-cool-img';
import truck from '../../../../img/geeteezoomzoom.gif';
import trucksad from '../../../../img/geeteesad.png';

import PhotoViewModal from './PhotoViewModal';

const PhotosTable = (props) => {

    const [searchBarValue, setSearchBarValue] = useState("photoSearchBarValue" in sessionStorage ? JSON.parse(sessionStorage.getItem("photoSearchBarValue")) : null)
    const [open, setOpen] = useState(false);
    const [rowSelected, setRowSelected] = useState({});
    const [checklist, setChecklist] = useState([]);

    const handleOpen = (rowdata) => {
        setRowSelected(rowdata)
        setOpen(true);

        if (rowdata.origin === 'checklist'){
        
            switch (rowdata.serviceType) {
                case 'Cleaning':
                    setChecklist (
                        [
                            ['pavement' in rowdata.checkedItems, 'All snow is cleaned to pavement where reasonably possible'],
                            ['curb' in rowdata.checkedItems, 'All Curbs are exposed'],
                            ['driveway' in rowdata.checkedItems, 'All Driveway approaches are clear'],
                            ['loadingdock' in rowdata.checkedItems, 'All loading docks are clear, if applicable'],
                            ['piles' in rowdata.checkedItems, 'Snow is piled in a designated area, which doesn’t obstruct a roadway, sidewalk, fire hydrant or entrance or exit from building'],
                            ['damage' in rowdata.checkedItems, 'Check this box if damaged occurred during cleaning and take picture',],
                            ['inaccessible' in rowdata.checkedItems, 'Check box if there was somewhere that couldn’t be cleared and take picture of reason'],
                        ]
                    )
                    
                    break;
                case 'Sanding':
                    setChecklist(
                        [
                            ['approaches' in rowdata.checkedItems, 'Approaches sanded'],
                            ['aisles' in rowdata.checkedItems, 'Main aisles and walking paths sanded'],
                            ['docks' in rowdata.checkedItems, 'Loading docks sanded if applicable'],
                            ['damage' in rowdata.checkedItems, 'Check box if any damaged occurred during sanding and take pictures'],
                            ['inaccessible' in rowdata.checkedItems, 'Check box if there was somewhere that couldn’t be sanded and take picture of reason'],
                        ]
                    )
                    break;
                case 'Shovelling':
                    setChecklist (
                        [
                            ['pavement' in rowdata.checkedItems, 'All snow is cleaned to pavement where reasonably possible'],
                            ['leeway' in rowdata.checkedItems, 'Snow is cleaned at least 1-2 feet from edge of sidewalk'],
                            ['salted' in rowdata.checkedItems, 'If needed sand or other ice melting products applied on icy areas'],
                            ['emergency' in rowdata.checkedItems, 'All emergency exits are clear of snow'],
                            ['inaccessible' in rowdata.checkedItems, 'Check this box If any area was unable to be  cleaned, take picture of area'],
                        ]
                    )
                    break;
                default:
                    break;
            }
        }
    }

    const handleClose = () => {
        setOpen(false);
        setRowSelected({})
    }

    return(
        <React.Fragment>
            <PhotoViewModal 
                rowSelected={rowSelected}
                checklist={checklist}
                open={open}
                handleClose={handleClose}
                handleDownloadSingle={props.handleDownloadSingle}
                photosClickHandler={props.photosClickHandler}
            />

            <MaterialTable
                tableRef={props.tableRef}
                columns={[
                    { title: 'Photo', field: 'photoURLs', 
                    render: rowdata => <Img
                        style={{ width: '100px', height: '100px' }}
                        error={trucksad}
                        placeholder={truck}
                        src={Array.isArray(rowdata.photoURLs) ? rowdata.photoURLs[0] : rowdata.photoURLs}
                        //error={errorImage}
                        alt="Photo"
                        //onClick={() => {handleOpen(rowdata);}}
                        />
                    
                    },
                    { title: 'Date Created', field: 'date', render: rowData => {
                        //utc
                        if(rowData.origin === "unit" || rowData.origin === "defect" || rowData.origin === "job")
                        return moment.utc(rowData.date).format('YYYY-MM-DD')

                        //unix
                        if(rowData.origin === "checklist")
                        return moment.unix(rowData.date).format('YYYY-MM-DD')

                        //string 
                        if(rowData.origin === "delivery")
                        return (rowData.date)
                    } 
                    },
                    { title: 'Type', field: 'origin', render: rowData => {
                        return (rowData.origin.charAt(0).toUpperCase() + rowData.origin.slice(1));
                    }
                    },
                    { title: 'Employee', field: 'employee' },
                ]}
                title='Photos'
                data={props.filteredPhotoData}
                icons={TableIcons}
                isLoading={props.loading}
                onSearchChange={ (value) => {setSearchBarValue(value);  sessionStorage.setItem("photoSearchBarValue", JSON.stringify(value));}}
                actions={[
                    {
                        icon: TableIcons.OpenInNew,
                        tooltip: 'View Photo Origin',
                        onClick: (event, rowData) => props.photosClickHandler(rowData.docId, rowData.origin),
                    },
                ]} 
                onPageChange={ (pageNum) => { sessionStorage.setItem("photoReportPageValue", JSON.stringify(pageNum)) }}
                options={{
                    pageSizeOptions: [5, 10, 25],
                    pageSize: "photoReportRowsPerPage" in sessionStorage ? JSON.parse(sessionStorage.getItem("photoReportRowsPerPage")) : 10,
                    initialPage: "photoReportPageValue" in sessionStorage ? JSON.parse(sessionStorage.getItem("photoReportPageValue")) : 0,
                    toolbar: true,
                    columnsButton: true,
                    paging: true,
                    sorting: true,
                    thirdSortClick: false,
                    searchText: searchBarValue,
                }}
                onRowsPerPageChange={(num) => {
                    sessionStorage.setItem("photoReportRowsPerPage", JSON.parse(num))
                }}
                onRowClick={(event, rowData) => {
                    handleOpen(rowData);
                }}
            />

        </React.Fragment>
    )

}

export default withRouter(PhotosTable)