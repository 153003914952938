import React, { useContext, useState, useEffect } from "react"
import { firestore } from "../firebase/firebase"

const CouponContext = React.createContext();

export function useCoupon() {
  return useContext(CouponContext);
}

export function CouponProvider({ children }) {

  const [coupons, setCouponData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');

  useEffect(() => {
    let unsubscribe = firestore.collection("coupons").onSnapshot(querySnapshot => {
      let couponResponse = querySnapshot.docs.map((doc) => {
        return {
          ...doc.data(),
          'docId': doc.id,
        }
      })
      couponResponse = couponResponse.filter(coupon => coupon.isSale === false)
      setLoading(false);
      setCouponData(couponResponse);
      //sessionStorage.setItem("couponData", JSON.stringify(couponResponse));
    },
      error => {
        setLoading(false);
        setError(error.message);
        console.log(error.message);
      }
    )
    return () => { unsubscribe(); }
  }, [])

  const value = {
    coupons,
    loading,
    error,
  }

  return (
    <CouponContext.Provider value={value}>
      {children}
    </CouponContext.Provider>
  )
}