import React, { useState, useEffect, useCallback, useRef } from 'react';
import { withRouter } from 'react-router'
import { firestore } from '../../../firebase/firebase'
import WorkOrderCard from '../../components/WorkOrders/WorkOrderCard'
import { makeStyles, Grid, Snackbar, Button } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import _ from 'lodash'
import { useUnitDescription } from '../../../providers/UnitDescriptionContext'
import moment from 'moment';
import { storage, removeFile } from '../../../firebase/firebase';
import { uploadString, getDownloadURL, uploadBytes } from "firebase/storage";
import { useAuth } from '../../../providers/AuthContext';

const useStyles = makeStyles((theme) => ({
    content: {
        display: 'flex',
        width:'100%',
        padding: '32px',
        marginBottom:'30px',
        justifyContent:'center',
        marginTop: '40px',
    },
}));

const WorkOrder = (props) =>  {
    const classes = useStyles();

    const { units, unitLoading, getUnits } = useUnitDescription();
    const { currentUser } = useAuth();

    const customerSig = useRef()
    const userSig = useRef()

    const [workOrderData, setWorkOrderData] = useState({});
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState("");
    const [edit, setEdit] = useState(false)
    const [saving, setSaving] = useState(false);
    const [saveState, setSaveState] = useState({});
    const [deleting, setDeleting] = useState(false);
    const [itemIndex, setItemIndex] = useState(null)

    const [unitAutocompleteValue, setUnitAutocompleteValue] = useState({})
    const [unitAutocompleteSaveState, setUnitAutocompleteSaveState] = useState({})

    let path = new URLSearchParams(props.location.search).get("docId");

    const initialRepair = {
        description: '',
        timeSpentRepairing: '',
        notes: '',
        completed: false,
    }
    const [repairForm, setRepairForm] = useState({...initialRepair});

    const initialPart = {
        partDescription: '',
        partNumber: '',
        added: false,
        quantity: 1,
        photoUrls: [],
    }
    const [partForm, setPartForm] = useState({ ...initialPart });

    //listener to the doc for changes
    useEffect(() => {
        const path = new URLSearchParams(props.location.search).get("docId");

        let unsubscribe = firestore.collection('workOrders').doc(path).onSnapshot((doc) => {
            if(doc.exists){
                setWorkOrderData({
                    ...doc.data(),
                });
                setSaveState({
                    ...doc.data(),
                });
                setLoading(false);
            }else{
                setError("Work Order does not exist");
                setLoading(false);
            }
        })
        return () => { unsubscribe(); }
        //eslint-disable-next-line
    }, []);

    //gets initial data
    useEffect(() => {
        if(units.length <= 0){ getUnits()  }
        firestore.collection('workOrders').where('__name__', "==", path).get()
        .then(querySnapshot => {
            const dataResponse = querySnapshot.docs.map((doc) => {
                return {
                    ...doc.data(),
                    'docId': path,
                }
            })
            //save docId to workOrderData
            firestore.collection('workOrders').doc(path).update({docId: path})

            //if dateCompleted is not not a number (SO A NUMBER.)
            if(!isNaN(dataResponse[0].dateCompleted) && dataResponse[0].dateCompleted !== ''){
                let newDateCompleted = moment(dataResponse[0].dateCompleted).format('MMMM Do, YYYY, h:mm a')
                firestore.collection('workOrders').doc(path).update({dateCompleted: newDateCompleted})
            }

            setWorkOrderData(dataResponse[0]);
            setSaveState(dataResponse[0]);
            setLoading(false);

        })
        .catch(e => {
            setError("Work Order not found.");
            setLoading(false);
            console.log(e.message);
        })
    //eslint-disable-next-line
    }, [path]);

    //sets autocomplete values when units and workOrderData is loaded in
    useEffect(() => {
        if(workOrderData.vehicle?.unitDocId && !unitLoading){
            let unit = units[units.findIndex(unit => unit.docId === workOrderData.vehicle.unitDocId)]
            setUnitAutocompleteSaveState(unit)
            setUnitAutocompleteValue(unit)
        }
    //eslint-disable-next-line
    }, [units, workOrderData])

    const updateWorkOrderData = useCallback((e) => {
            setWorkOrderData(e)
        },
        [],
    );


    const handlePartPhotoChanges = (workOrderContainer, saveStateContainer) => {
        return new Promise(async function (resolve) {
            const storageRef = storage.ref();
            let existingArray = [];
            let removeArray = [];
            let uploadArray = [];
            let resolutionTotal = 0;
            let resolutionCount = 0;

            workOrderContainer.partsNeeded.forEach((part, index) => {
                part.photoUrls.forEach((file, i) => {
                    if(typeof file === "object"){
                        uploadArray.push({file: file, partIndex: index, photoIndex: i})
                    }
                    else if(typeof file === "string"){
                        existingArray.push(file)
                    }
                })
            })

            saveStateContainer.partsNeeded.forEach((part) => {
                part.photoUrls.forEach((file) => {
                    if( !existingArray.includes(file) ){
                        removeArray.push(file)
                    }
                })
            })

            resolutionTotal += (removeArray.length + uploadArray.length)
            if(resolutionTotal === 0){ resolve(workOrderContainer) }

            uploadArray.forEach(async (obj) => {
                const filePath = storageRef.child(`WorkOrders/${path}/partPhotos/${obj.partIndex}${obj.photoIndex}${moment().valueOf()}_${obj.file.name}`);
                await uploadBytes(filePath, obj.file).then(async () => {
                    await getDownloadURL(filePath).then((url) => {
                        workOrderContainer.partsNeeded[obj.partIndex].photoUrls.splice(obj.photoIndex, 1, url)
                        resolutionCount++
                        if(resolutionCount === resolutionTotal){ resolve(workOrderContainer) }
                    })
                })
            })

            removeArray.forEach(async (file) => {
                await removeFile(file).then(() => { 
                    resolutionCount++ 
                    if(resolutionCount === resolutionTotal){ resolve(workOrderContainer) }
                })
            })
        })
    }

    const handleSigChanges = async (workOrderParent, saveStateParent, childValue) => {
        return new Promise(async function (resolve) {
            const storageRef = storage.ref();

            //added/changed
            if(workOrderParent[childValue] !== saveStateParent[childValue]
                && ((saveStateParent[childValue] === "" && workOrderParent[childValue] !== "")
                || (saveStateParent[childValue] !== "" && workOrderParent[childValue] !== "")) ){

                //dont need to, will be replaced ? (same path)
                if(workOrderParent[childValue] !== saveStateParent[childValue] && saveStateParent[childValue] !== ""){
                    childValue === 'sigURL' ?  await removeFile(saveState.customer.sigURL) : await removeFile(saveState.userSignature)
                }

                const photoRef = childValue === 'sigURL' ? storageRef.child('WorkOrders/' + path + '/customerSignature.png') : storageRef.child('WorkOrders/' + path + '/employeeSignature.png');
                const dataURL = childValue === 'sigURL' ? customerSig.current.getCanvas().toDataURL() : userSig.current.getCanvas().toDataURL();
                await uploadString(photoRef, dataURL, 'data_url').then(async (snapshot) => {
                    await getDownloadURL(snapshot.ref).then((url) => {
                        resolve(url);
                    }).catch((e) => setError(e));
                }).catch((e) => setError(e));
            }
            //removed
            else if(workOrderParent[childValue] !== saveStateParent[childValue]
                && (saveStateParent[childValue] !== "" && workOrderParent[childValue] === "") ){

                let container = childValue === 'sigURL' ? saveState.customer.sigURL : saveState.userSignature
                 await removeFile(container).then(() => { resolve(""); })
                
            }
            //no changes
            else{
                resolve(saveStateParent[childValue]);
            }
        })
    }

    const confirmSave = async (providedData) => {
        setSaving(true);
        let workOrderContainer = !_.isUndefined(providedData) ? providedData : _.cloneDeep(workOrderData)

        const updatePartPhotos = new Promise(async (resolve) => {
            await handlePartPhotoChanges(workOrderContainer, saveState).then((value) => { 
                workOrderContainer.partsNeeded = value.partsNeeded;
                resolve() 
            })
        })

        const updateUserSig = new Promise(async (resolve) => {
            await handleSigChanges(workOrderContainer, saveState, 'userSignature').then((value) => { 
                workOrderContainer.userSignature = value;
                resolve() 
            })
        })

        const updateCustomerSig = new Promise(async (resolve) => {
            await handleSigChanges(workOrderContainer.customer, saveState.customer, 'sigURL').then((value) => {
                workOrderContainer.customer.sigURL = value;
                resolve();
            })
        })

        await Promise.all([updatePartPhotos, updateUserSig, updateCustomerSig]).then(async () => { 
            firestore.collection('workOrders').doc(path).update(workOrderContainer)
            .then(() => {
                setUnitAutocompleteSaveState(unitAutocompleteValue)
                setSaveState(workOrderContainer);
                setWorkOrderData(workOrderContainer)
                setSaving(false)
            })
            .catch((e) => setError(e));
        })
    };

    const cancelSave = () => {
        if(edit){
            saveState.customer.sigURL === "" && customerSig.current.clear()
            saveState.userSignature === "" && userSig.current.clear()
        }
        setUnitAutocompleteValue(unitAutocompleteSaveState)
        setWorkOrderData(saveState);
    };

    const handleDeleteWorkOrder  = async () => {
        setDeleting(true)

        firestore.collection('workOrders').doc(workOrderData.docId).delete()
        .then(async () => {

            workOrderData.customer.sigURL !== "" && await removeFile(workOrderData.customer.sigURL)
            workOrderData.userSignature !== "" && await removeFile(workOrderData.userSignature)

            if(workOrderData.partsNeeded.filter(x=> x.photoUrls?.length > 0).length > 0){

                workOrderData.partsNeeded.forEach((part) => {
                    part.photoUrls.forEach(async (file) => {
                        await removeFile(file)
                    })
                })
            }

            setDeleting(false)
            console.log("work order successfully deleted!");
            props.history.replace({pathname: '/dashboard/workOrders'})
        }).catch((error) => {
            setDeleting(false)
            console.error("Error removing Work Order: ", error);
        });
    }

    const handleUnitUpdate = (unit) => {
        setUnitAutocompleteValue(unit)
        let container = _.cloneDeep(workOrderData)

        container.vehicle = {
            ...container.vehicle,
            unitNumber: '',
            year: '',
            make: '',
            mileage: '',
            vin: '',
            licensePlate: '',
            cabCardNumber: '',
            PONumber: '',
            safetyDue: false,
            safetyDueDate: null,
            unitDocId: '',
        }

        if (unit === null || unit === undefined || unit === ''){
            updateWorkOrderData(container)
        }
        if(typeof unit === 'string'){
            container.vehicle.unitNumber = unit
            updateWorkOrderData(container)
        }
        else{
            let fields = ['unitNumber', 'year', 'make', 'licensePlate']      //milage, vin, cabCardNumber, and PONumber not in unitDescriptions?
            fields.forEach((field) => {if(unit[field] && unit[field] !== ''){ container.vehicle[field] = unit[field]} })
            container.vehicle['unitDocId'] = unit.docId
            updateWorkOrderData(container)
        }
    }

    const handleAddAdditionalInfo = (type) => {
        if(type === 'New Repair'){
            setWorkOrderData((prevForm) => ({
                ...prevForm,
                repairsNeeded: [...prevForm.repairsNeeded, { ...repairForm }],
            }));
            setRepairForm({ ...initialRepair });
        }
        else if(type === 'Edit Repair'){
            let repairsNeededContainer = _.cloneDeep(workOrderData.repairsNeeded)
            repairsNeededContainer.splice(itemIndex, 1, repairForm)

            setWorkOrderData((prevForm) => ({
                ...prevForm,
                repairsNeeded: [...repairsNeededContainer],
            }));
            setRepairForm({ ...initialRepair });
        }
        else if(type === 'New Part'){
            setWorkOrderData((prevForm) => ({
                ...prevForm,
                partsNeeded: [...prevForm.partsNeeded, { ...partForm }],
            }));
            setPartForm({ ...initialPart });
        }
        else if(type === 'Edit Part'){
            let partNeededContainer = _.cloneDeep(workOrderData.partsNeeded)
            partNeededContainer.splice(itemIndex, 1, partForm)

            setWorkOrderData((prevForm) => ({
                ...prevForm,
                partsNeeded: [...partNeededContainer],
            }));
            setRepairForm({ ...initialPart });
        }
        else{
            console.warn('Error adding additonal info.')
        }
    };

    const handleRemoveAdditionalInfo = (type, index) => {
        if(type === 'Repair'){
            setWorkOrderData((prevForm) => ({
                ...prevForm,
                repairsNeeded: prevForm.repairsNeeded.filter((repair, i) => i !== index),
            }));
        }
        else if(type === 'Part'){
            setWorkOrderData((prevForm) => ({
                ...prevForm,
                partsNeeded: prevForm.partsNeeded.filter((part, i) => i !== index),
            }));
        }
        else{
            console.warn('Error removing additonal info.')
        }
    };

    const handleChangeAdditionalInfo = (type, event) => {
        if(type === 'Repair'){
            setRepairForm({ ...repairForm, [event.target.name]: event.target.value });
        }
        else if(type === 'Part'){
            setPartForm({ ...partForm, [event.target.name]: event.target.value });
        }
        else{
            console.warn('Error changing additonal info.')
        }
    };

    const handleUpdate = (parent, child, newValue) => {
        updateWorkOrderData({ ...workOrderData, [parent]: {...workOrderData[parent], [child]: newValue } })
    }

    const handleSafetyDueDateUpdate = (date) => {
        updateWorkOrderData({ ...workOrderData, vehicle: {...workOrderData.vehicle, safetyDueDate: moment(date).valueOf() } })
    }

    const handleDeletePhoto = async (index) => {
        let localPhotoArrayContainer = _.cloneDeep(partForm)
        if(typeof localPhotoArrayContainer.photoUrls[index] === "string"){ 
            await removeFile(localPhotoArrayContainer.photoUrls[index]) 
        }
        localPhotoArrayContainer.photoUrls.splice(index, 1)
        setPartForm(localPhotoArrayContainer)
    };
    
    const handleCompleteWorkOrder = () => {
        //setLoading(true)
        setTimeout(() => {
            confirmSave({...workOrderData, completed: true, dateCompleted: moment(new Date()).format('MMMM Do, YYYY, h:mm a'), userAuthorizedCompletion: currentUser.uid});
            //setLoading(false)
        }, 400);
    }
    const handleIncompleteWorkOrder = () => {
        //setLoading(true)
        setTimeout(() => {
            confirmSave({...workOrderData, completed: false, dateCompleted: '', userAuthorizedCompletion: currentUser.uid});
            //setLoading(false)
        }, 400);
    }

    return (
        <Grid>
            {loading || deleting
            ?
            <Grid className={classes.content}>
                <Skeleton variant='rect' width={'50vw'} height={'85vh'}/>
            </Grid>
            :
            <Grid>
                <WorkOrderCard
                    error={error}
                    setWorkOrderData={setWorkOrderData}
                    workOrderData={workOrderData}
                    saveState={saveState}
                    setSaveState={setSaveState}
                    cancelSave={cancelSave}
                    confirmSave={confirmSave}
                    updateWorkOrderData={updateWorkOrderData}
                    saving={saving}
                    handleDeleteWorkOrder={handleDeleteWorkOrder}
                    unitAutocompleteValue={unitAutocompleteValue}
                    setUnitAutocompleteValue={setUnitAutocompleteValue}
                    handleUnitUpdate={handleUnitUpdate}
                    unitAutocompleteSaveState={unitAutocompleteSaveState}
                    units={units}
                    unitLoading={unitLoading}
                    getUnits={getUnits}
                    handleAddAdditionalInfo={handleAddAdditionalInfo}
                    handleRemoveAdditionalInfo={handleRemoveAdditionalInfo}
                    handleChangeAdditionalInfo={handleChangeAdditionalInfo}
                    repairForm={repairForm}
                    setRepairForm={setRepairForm}
                    initialRepair={initialRepair}
                    initialPart={initialPart}
                    handleUpdate={handleUpdate}
                    handleSafetyDueDateUpdate={handleSafetyDueDateUpdate}
                    partForm={partForm}
                    setPartForm={setPartForm}
                    customerSig={customerSig}
                    userSig={userSig}
                    itemIndex={itemIndex}
                    setItemIndex={setItemIndex}
                    edit={edit}
                    setEdit={setEdit}
                    handleCompleteWorkOrder={handleCompleteWorkOrder}
                    handleIncompleteWorkOrder={handleIncompleteWorkOrder}
                    handleDeletePhoto={handleDeletePhoto}
                />
            <Snackbar
                open={!_.isEqual(workOrderData, saveState)}
                message={saving ? 'Saving...' : 'Save Document?'}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center'
                }}
                action={
                    <React.Fragment>
                        {saving
                            ? null
                            : <React.Fragment>
                                <Button variant='text' color='primary' onClick={cancelSave} style={{ marginLeft: '32px', marginRight: '8px' }}>Cancel</Button>
                                <Button variant='contained' onClick={() => { confirmSave(); }}>Confirm</Button>
                            </React.Fragment>}
                    </React.Fragment>
                } />
            </Grid> }

        </Grid>
    )
}

export default withRouter(WorkOrder);