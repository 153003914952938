import React from 'react';
import Grid from '@material-ui/core/Grid'
import { Button, TextField } from '@material-ui/core';
import Note from './Note';


const AddNote = (props) => {
    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <TextField label='New Note' disabled={props.newNoteInbound} fullWidth value={props.noteText} onChange={props.textChanged}></TextField>
            </Grid>
            <Grid item>
                <Button disabled={props.newNoteInbound} variant='contained' onClick={props.addNote}>ADD NOTE</Button>
            </Grid>

            {props.newNoteInbound &&
                <Grid item xs={12}>
                    <Note loading />
                </Grid>
            }
        </Grid>

    )
}
export default (AddNote)
