import React, { useState } from 'react';
import { firestore } from '../../../firebase/firebase'
import { storage } from '../../../firebase/firebase'
import { withRouter } from 'react-router-dom';
import PermitsForm from '../../components/Permits/PermitsForm';
import moment from 'moment';

const PermitsNew = (props) => {

    const initialPermitsForm = {
        name: '',
        description: '',
        aliases: [''],
        manufacturerPage: '',
        dateCreated: '',
        dateUpdated: moment(Date.now()).format('yyyy-MM-DD'),
        pdfURL: '',
        emergencyPhone: '',
        safetyPhone: '',
    }

    const [permitsForm, setPermitsForm] = useState(initialPermitsForm);
    const [permitsPDF, setPermitsPDF] = useState({});
    const [submitting, setSubmitting] = useState(false);
    const [error, setError] = useState("");

    const handleChange = (event) => {
        switch (event.target.name) {
            //Handle value as array 
            case "aliases":
                //Dont call splice in the hook set method to conform to hook rules
                let aliases = permitsForm.aliases;
                aliases.splice(event.target.id, 1, event.target.value)
                setPermitsForm({ ...permitsForm, aliases: aliases });
                break;
            default:
                setPermitsForm({ ...permitsForm, [event.target.name]: event.target.value });
                break;
        }
    }

    const addAlias = () => {
        let aliases = permitsForm.aliases;
        aliases.push('');
        setPermitsForm({ ...permitsForm, aliases: aliases });
    }

    const removeAlias = (index) => {
        let aliases = permitsForm.aliases;
        aliases.splice(index, 1);
        setPermitsForm({ ...permitsForm, aliases: aliases });
    }

    const handleFileUpload = (acceptedFiles) => {
        const file = acceptedFiles[0];
        if (file) {
            setPermitsPDF(file);
            setPermitsForm({ ...permitsForm, pdfURL: file.name});
        }
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        createPermit();
    }

    const createPermit = async () => {

        setSubmitting(true);

        let newPermitsForm = permitsForm;

        newPermitsForm.dateCreated = Date.now() / 1000;
        newPermitsForm.archived = false;

        //Create reference to .pdf
        const storageRef = storage.ref();
        let docId = firestore.collection('permits').doc().id;
        const pdfRef = storageRef.child('Permits/' + permitsPDF.name + '_' + docId);

        pdfRef.put(permitsPDF).then((ref) => {
            pdfRef.getDownloadURL().then((url) => {
                newPermitsForm.pdfURL = url;
                newPermitsForm.docId = docId;
                newPermitsForm.dateUpdated = new Date(newPermitsForm.dateUpdated);
                newPermitsForm.dateUpdated = new Date(newPermitsForm.dateUpdated.getTime() + (newPermitsForm.dateUpdated.getTimezoneOffset() * 60000)) / 1000;

                firestore.collection('permits').doc(docId).set(newPermitsForm)
                    .then(() => {
                        setPermitsPDF()
                        setPermitsForm(initialPermitsForm);
                        setSubmitting(false);
                        setError('');
                    })
            })
        })
            .catch((e) => {
                setSubmitting(false);
                console.log(e)
                setError("Unable to create Material Safety Data Sheet, please try again");
            });
    }

    //substring text to use ... if too long
    const substringText = (text) => {
        if(text.length > 20){
            return text.substring(0, 20) + '...'
        } else {
            return text
        }
    }

    return (
        <React.Fragment>
            <PermitsForm
                permitsForm={permitsForm} permitsPDF={permitsPDF} setPermitsPDF={setPermitsPDF}
                addAlias={addAlias} removeAlias={removeAlias}
                handleChange={handleChange} handleSubmit={handleSubmit}
                handleFileUpload={handleFileUpload}
                submitting={submitting} error={error} 
                substringText={substringText}
            />
        </React.Fragment>
    )
}

export default withRouter(PermitsNew);