import * as actionTypes from '../actions/actionTypes'
import { updateObject } from '../utility'

const initialState = {
    customers: [],
    loading: false,
    city: '',
    name: '',
    postalCode: '',
    province: '',
    streetName: '',
    streetNumber: '',
}

const customersReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.BEGIN:
            return updateObject(state, { loading: action.loading });
        case actionTypes.GET_CUSTOMERS:
            return updateObject(state, { customers: action.customers, loading: action.loading });
        case actionTypes.ADD_CUSTOMER:
            //TODO: ADD CUSTOMER
            return updateObject(state, state);

        default:
            return state;
    }
};

export default customersReducer;