import React, { useEffect, useState } from 'react';
import { Button, Grid, makeStyles, Typography, Dialog, DialogActions, DialogContent, DialogTitle, TextField, Divider } from '@material-ui/core';
import { withRouter } from 'react-router';
import { firestore } from '../../../firebase/firebase';
import MaterialTable from '@material-table/core';
import { TableIcons } from '../../../constants/tableIcons';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import AddBoxIcon from '@material-ui/icons/AddBox';
import EditIcon from '@material-ui/icons/Edit';
import moment from 'moment';
import { DatePicker } from '@material-ui/pickers';
import truck from '../../../img/geeteezoomzoom.gif';

const useStyles = makeStyles((theme) => ({

    card: {
        padding: theme.spacing(4),
        width: '100%',
        height: '100%',
      },
    
      content: {
        display: 'flex',
        justifyContent: 'center',
        width: '100%',
      },
    
      grid: {
        marginTop: theme.spacing(2),
        maxWidth: '1200px',
      },
    
      table: {
        width: '100%',
        maxWidth: '900px',
        margin: '16px',
        display: 'flex',
      },
    
      tile: {
        width: '300px',
        height: '300px',
      },
    
      tileContent: {
        display: 'flex',
        justifyContent: 'space-evenly',
        alignItems: 'center',
        flexDirection: 'column',
        width: '100%',
        height: '100%',
        padding: theme.spacing(4),
      },
    
      tileIcon: {
        color: 'rgba(0, 0, 0, 0.54)',
        width: '100px',
        height: '100px',
      },
    
      tileText: {
        fontSize: '1.10rem',
      },
      new: {
        backgroundColor:'green', 
        color:'white', 
        paddingLeft:'10px', 
        paddingRight:'10px', 
        borderRadius:'6px', 
        margin:'2px',
        fontWeight:'bold'
      },
      change: {
        backgroundColor:'blue', 
        color:'white', 
        paddingLeft:'10px', 
        paddingRight:'10px', 
        borderRadius:'6px', 
        margin:'2px',
        fontWeight:'bold'
      },
      bug: {
        backgroundColor:'red', 
        color:'white', 
        paddingLeft:'10px', 
        paddingRight:'10px', 
        borderRadius:'6px', 
        margin:'2px',
        fontWeight:'bold'
      },
      fix: {
        backgroundColor:'dodgerblue', 
        color:'white', 
        paddingLeft:'10px', 
        paddingRight:'10px', 
        borderRadius:'6px', 
        margin:'2px',
        fontWeight:'bold'
      },
    }));

const ChangelogHome = (props) => {

  const [logData, setLogData] = useState();
  const [dataLoading, setDataLoading] = useState();
  const [dataError, setDataError] = useState();
  const [selectedLog, setSelectedLog] = useState('')
  const [selectedLog2, setSelectedLog2] = useState({})
  const [saving, setSaving] = useState(false);
  const [error, setError] = useState('');
  const [delModal, setDelModal] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [newModal, setNewModal] = useState(false);
  const [features, setFeatures] = useState('')
  const [fixes, setFixes] = useState('')
  const [changes, setChanges] = useState('')
  const [bugs, setBugs] = useState('')
  const [date, setDate] = useState(new Date());
  const [changelog, setChangelog] = useState({})

    const openDelModal = (docId) => {
        setSelectedLog(docId)
        setDelModal(true)
    }
    const openNewModal = () => {
        setNewModal(true)
    }
    const closeDelModal = () => {
        setSelectedLog('')
        setDelModal(false)
    }
    const openEditModal = (rowData) => {
        setSelectedLog2(rowData)
        let featstring = ''
        let changestring = ''
        let fixstring = ''
        let bugstring = ''
        if(rowData.features) { rowData.features.forEach((feature, index) => {

            featstring= featstring + feature + ','
          })}
          setFeatures(featstring)
        if(rowData.changes) { rowData.changes.forEach((change, index) => {
            changestring = changestring + change + ','
          })}
          setChanges(changestring)
          if(rowData.fixes) { rowData.fixes.forEach((fix, index) => {
            fixstring = fixstring + fix + ','
        })}
        setFixes(fixstring)
          if(rowData.bugs) { rowData.bugs.forEach((bug, index) => {
            bugstring = bugstring + bug + ','
          })}
          setBugs(bugstring)
        setEditModal(true)
    }
    const closeNewModal = () => {
        setFeatures('')
        setFixes('')
        setChanges('')
        setBugs('')
        setNewModal(false)
    }
    const closeEditModal = () => {
        setSelectedLog2({})
        setFeatures('')
        setFixes('')
        setChanges('')
        setBugs('')
        setEditModal(false)
    }
    const handleDelete = (docId) => {
        setSaving(true)
        firestore.collection('changelog').doc(selectedLog)
            .delete()
            .then(() => {
                setSaving(false)
            })
            .catch((e) => {setError(e); console.log(error);});
        closeDelModal()
        setSaving(true)
        setTimeout(() => {setSaving(false)}, 5 * 1000);
    }
    const handleUpdate = (docId) => {
        setSaving(true)
       
        firestore.collection('changelog').doc(selectedLog2.docId)
            .update({
                'features': features.length > 0 ? features.split(',').filter(filt => filt.length > 0) : [],
                'changes': changes.length > 0 ?  changes.split(',').filter(filt => filt.length > 0) : [],
                'fixes': fixes.length > 0 ?  fixes.split(',').filter(filt => filt.length > 0) : [],
                'bugs': bugs.length > 0 ?  bugs.split(',').filter(filt => filt.length > 0) : [],
                'docId': selectedLog2.docId,
            })
            .then(() => {
                setSaving(false)
                closeEditModal()
                setSaving(true)
                setTimeout(() => {setSaving(false)}, 3 * 1000);
            })
            .catch((e) => {setError(e); console.log(e);});
    }

    const handleNew = () => {
       
        setSaving(true)
      
   
        firestore.collection('changelog').add(changelog)
        .then(() => {
            setSaving(false)
            closeNewModal();
            setSaving(true)
            setTimeout(() => {setSaving(false)}, 3 * 1000);
        }).catch((e) => {
            console.log(e)
            setSaving(false)
        });
   
        
    }

  useEffect(() => {
    setDate(new Date().toUTCString());  setChangelog({...changelog, 
        'dateCreated': new Date()
    });
  }, []) // eslint-disable-line react-hooks/exhaustive-deps
  useEffect(() => {
      
    firestore.collection('changelog').get()
      .then(querySnapshot => {
        const logsResponse = querySnapshot.docs.map((doc) => {
          return {
            ...doc.data(),
            'docId': doc.id,
            'date': doc.data().dateCreated.toDate(),
            'features': doc.data().features ? doc.data().features : null,
            'changes': doc.data().changes ? doc.data().changes : null,
            'fixes': doc.data().fixes ? doc.data().fixes : null,
            'bugs': doc.data().bugs ? doc.data().bugs : null,
          }
        })
        setLogData(logsResponse.sort((a, b) => { return a.date < b.date ? 1 : -1 }));
        setDataLoading(false);
      })
      .catch(e => {
        setDataError("Unable to Process");
        setDataLoading(false);
        console.log(e.message);
      })
  }, [saving]);

  const classes = useStyles();


  return !dataLoading && !dataError &&
    <div className={classes.content}>
        <Dialog fullWidth
            keepMounted
            open={delModal}
            onClose={closeDelModal}
        >
            <DialogTitle id="alert-dialog-title">Delete Changelog</DialogTitle>

            <DialogContent>
                <Typography>Are you sure you want to delete this changelog?</Typography>
            </DialogContent>
            <DialogActions>
                <Button variant="contained" onClick={() => handleDelete()}>Delete</Button>
                <Button variant="contained" color="secondary" onClick={() => closeDelModal()}>Cancel</Button>
            </DialogActions>
        </Dialog>
        <Dialog fullWidth
            keepMounted
            open={newModal}
            onClose={closeNewModal}
        >
            <DialogTitle id="alert-dialog-title">Create New Changelog for <DatePicker
                                fullWidth
                                value={moment(date).format('MMM D YYYY')}
                                onChange={(event) => {setDate(new Date(event).toUTCString());  setChangelog({...changelog, 
                                    'dateCreated': new Date(event)
                                });}} /></DialogTitle>

            <DialogContent>
            <Typography>Seperate entries with a comma (,)</Typography>
                <Typography>Features</Typography>
            <TextField
                id="outlined-multiline-static"
                variant="outlined"
                multiline
                fullWidth
                rows={4}
                value={features}
                onChange={(event) => {setFeatures(event.target.value);  setChangelog({...changelog, 
                    'features': event.target.value.length > 0 ? event.target.value.split(',').filter(filt => filt.length > 0) : []
                });}}
                
                />
   
                <Typography>Changes</Typography>
            <TextField
                id="outlined-multiline-static"
                variant="outlined"
                multiline
                fullWidth
                rows={4}
                onChange={(event) => {setChanges(event.target.value);  setChangelog({...changelog, 
                    'changes': event.target.value.length > 0 ? event.target.value.split(',').filter(filt => filt.length > 0) : []
                });}}
            
                value={changes}
                />
        
                <Typography>Fixes</Typography>
            <TextField
                id="outlined-multiline-static"
                variant="outlined"
                multiline
                fullWidth
                rows={4}
                onChange={(event) => {setFixes(event.target.value);  setChangelog({...changelog, 
                    'fixes': event.target.value.length > 0 ? event.target.value.split(',').filter(filt => filt.length > 0) : []
                });}}
              
                value={fixes}
                />
               <Typography>Bugs</Typography>
            <TextField
                id="outlined-multiline-static"
                variant="outlined"
                multiline
                fullWidth
                onChange={(event) => { setBugs(event.target.value);  setChangelog({...changelog, 
                    'bugs': event.target.value.length > 0 ? event.target.value.split(',').filter(filt => filt.length > 0) : []
                });}}
                rows={4}
               
                value={bugs}
                />
            </DialogContent>
            <DialogActions>
                <Button variant="contained" disabled={saving} onClick={() => handleNew()}>Create</Button>
                <Button variant="contained" disabled={saving} color="secondary" onClick={() => closeNewModal()}>Cancel</Button>
            </DialogActions>
        </Dialog>
        <Dialog fullWidth
            keepMounted
            open={editModal}
            onClose={closeEditModal}
        >
            <DialogTitle id="alert-dialog-title">Edit Changelog - {moment(selectedLog2.date).format('MMMM D, YYYY')}</DialogTitle>

            <DialogContent>
            <Typography>Seperate entries with a comma (,)</Typography>
                <Typography>Features</Typography>
            <TextField
                id="outlined-multiline-static"
                variant="outlined"
                multiline
                fullWidth
                rows={4}
                value={features}
                onChange={(event) => {setFeatures(event.target.value);}}
               
                />
   
                <Typography>Changes</Typography>
            <TextField
                id="outlined-multiline-static"
                variant="outlined"
                multiline
                fullWidth
                rows={4}
                onChange={(event) => {setChanges(event.target.value);}}
               
                value={changes}
                />
        
                <Typography>Fixes</Typography>
            <TextField
                id="outlined-multiline-static"
                variant="outlined"
                multiline
                fullWidth
                rows={4}
                onChange={(event) => {setFixes(event.target.value);}}
                
                value={fixes}
                />
               <Typography>Bugs</Typography>
            <TextField
                id="outlined-multiline-static"
                variant="outlined"
                multiline
                fullWidth
                onChange={(event) => { setBugs(event.target.value);}}
                rows={4}
                
                value={bugs}
                />
            </DialogContent>
            <DialogActions>
                <Button variant="contained" disabled={saving} onClick={() => handleUpdate()}>Save</Button>
                <Button variant="contained" disabled={saving} color="secondary" onClick={() => closeEditModal()}>Cancel</Button>
            </DialogActions>
        </Dialog>
      <Grid container spacing={2} className={classes.table}>
        <Grid item xs={12}>
          <Button startIcon={<AddBoxIcon/>} variant='contained' onClick={() => openNewModal()} style={{marginRight:'5px'}}>
            Write New Changelog
          </Button>
        </Grid>
        <Grid item xs={12}>
        </Grid>

        
        <Grid item xs={12} style={{ width: '100%' }}>
          <MaterialTable
              columns={[
                { title: 'Date', field: 'date' , render: rowData => {
                    return moment(rowData.date).format('MMMM D, YYYY')
                }},
                { title: 'Features', render: rowData => {
                    return rowData.features ? rowData.features.length : '0'
                    }
                },
                { title: 'Changes', render: rowData => {
                    return rowData.changes ? rowData.changes.length : '0'
                    }
                },
                { title: 'Fixes', render: rowData => {
                    return rowData.fixes ? rowData.fixes.length : '0'
                    }
                },
                { title: 'Bugs', render: rowData => {
                    return rowData.bugs ? rowData.bugs.length : '0'
                    }
                },
                { title: 'Edit', render: rowData => {
                    return <Button variant="contained"
                    onClick={() => openEditModal(rowData)}
                    ><EditIcon/></Button>
                }},
                { title: 'Delete', render: rowData => {
                    return <Button variant="contained" style={{width:'2px', backgroundColor:'red', color:'white'}}
                    onClick={() => openDelModal(rowData.docId)}
                    ><DeleteForeverIcon/></Button>
                }}
              ]}
              title='Update Logs'
              data={logData}
              icons={TableIcons}
              isLoading={dataLoading}
              options={{
                  pageSizeOptions: [5, 10, 25],
                  pageSize: 10,
                  toolbar: true,
                  paging: true,
                  detailPanelType: "single",
                  sorting: true,
                  thirdSortClick: false,
              }}
              onRowClick={(event, rowData) => {
                  //handleOpen(rowData);
              }}
              detailPanel={({ rowData }) => {
                return (
                  <div
                    style={{
                      textAlign: "left",
                    backgroundColor:'lightGrey',
                    padding:'4px'
                    }}
                  >
                    Update Log for {moment(rowData.date).format('MMMM D, YYYY')}
                    <img src={truck} style={{width:'48px'}} alt="truck"></img>
                    <Divider></Divider>
                    <Typography variant='body2'>
                        <br></br>
                    {rowData.features ? rowData.features.map((feature, index) => {
                      return <React.Fragment key={index}><small className={classes.new}>N E W</small>{feature}<br></br></React.Fragment>
                    }) : null}
                    {rowData.changes ? rowData.changes.map((change, index) => {
                      return <React.Fragment key={index}><small className={classes.change}>C H A N G E</small>{change}<br></br></React.Fragment>
                    }) : null}
                    {rowData.fixes ? rowData.fixes.map((fix, index) => {
                        return <React.Fragment key={index}><small className={classes.fix}>F I X</small>{fix}<br></br></React.Fragment>
                    }): null}
                    {rowData.bugs ? rowData.bugs.map((bug, index) => {
                        return <React.Fragment  key={index}><small className={classes.bug}>B U G</small>{bug}<br></br></React.Fragment>
                    }):null}
                    </Typography>
                    <br></br>
                  </div>
                );
              }}
          />
        </Grid>
      </Grid>
    </div>
  
}

export default withRouter(ChangelogHome);