import React, { useState, useEffect } from 'react';
import moment from 'moment'
import MaterialTable  from '@material-table/core';
import { TableIcons } from '../../../../constants/tableIcons';
import { firestore } from '../../../../firebase/firebase'
import DefectOptions from '../../../components/Reports/Defects/DefectOptions'
import { Grid, Paper, Typography, IconButton } from '@material-ui/core';
import ReportSelect from '../../../components/Reports/ReportSelect'
import { makeStyles, useMediaQuery } from '@material-ui/core';
import _ from 'lodash'
import clsx from 'clsx';
import InfoIcon from '@material-ui/icons/Info';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import { useUser } from '../../../../providers/UserContext'
import { useTheme } from '@material-ui/core/styles';
import Collapse from '@material-ui/core/Collapse';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Skeleton } from '@material-ui/lab';


const useStyles = makeStyles((theme) => ({
    content: {
        padding: '32px',
        paddingRight: '230px',
        overflow: 'auto'
    },
    sidebar: {
        position: 'fixed',
        right: 0,
        top: '64px',
        maxWidth: '256px',
        width: '100%',
        padding: '16px',
        minHeight: '100vh',
        zIndex: '1000',
    },
    expand: {
        position: 'fixed',
        right: 0,
        top: '444px',
        transform: 'rotate(90deg)',
        marginLeft: 'auto',
        transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.shortest,
        }),
    },
    expandOpen: {
        position: 'fixed',
        right: 253,
        top: '444px',
        transform: 'rotate(270deg)',
    },
}));


const DefectReport = (props) => {
    const { users, getUsers } = useUser();

    useEffect(() => {
        getUsers();
    }, []) // eslint-disable-line react-hooks/exhaustive-deps
    const classes = useStyles();
    const theme = useTheme();
    const smallScreen = useMediaQuery(theme.breakpoints.down('sm'));
    useEffect(() => {

    }, [smallScreen]);
    const [startDate, setStartDate] = useState("startDate" in sessionStorage ? moment(JSON.parse(sessionStorage.getItem("startDate"))) : moment());
    const [endDate, setEndDate] = useState("endDate" in sessionStorage ? moment(JSON.parse(sessionStorage.getItem("endDate"))) : moment());
    const [checkboxState, setChecked] = React.useState({
        unsubmitted: false,
        unseen: true,
        viewed: true,
        printed: true,
    });
    const [defectData, setDefectData] = useState(JSON.parse(sessionStorage.getItem("defectData")) || []);
    const [errorMessage, setErrorMessage] = useState(null)
    const [loading, setLoading] = useState(false)
    //autocomplete is employees
    const [autoCompleteValue, setAutoCompleteValue] = useState("defectAutoComplete" in sessionStorage ? JSON.parse(sessionStorage.getItem("defectAutoComplete")) : "")
    const [searchState, setSearch] = useState({
        type: JSON.parse(sessionStorage.getItem("defectOptionsSearchType")) || 'unitNumber',
        value: JSON.parse(sessionStorage.getItem("defectOptionsSearchValue")) || '',
    });
    const [searchBarValue, setSearchBarValue] = useState("defectSearchBarValue" in sessionStorage ? JSON.parse(sessionStorage.getItem("defectSearchBarValue")) : null)

    const checkboxHandler = (event) => {
        setChecked({ ...checkboxState, [event.target.name]: event.target.checked });
    };

    const startDateHandler = date => {
        setStartDate(date)
        sessionStorage.setItem("startDate", JSON.stringify(date))
    }
    const endDateHandler = date => {
        setEndDate(date)
        sessionStorage.setItem("endDate", JSON.stringify(date))
    }

    const searchHandler = (event) => {
        setSearch({ ...searchState, [event.target.name]: event.target.value })
        if(event.target.name === "type") sessionStorage.setItem("defectOptionsSearchType", JSON.stringify(event.target.value))
        if(event.target.name === "value") sessionStorage.setItem("defectOptionsSearchValue", JSON.stringify(event.target.value))
    }

    const clearSearch = () => {
        setSearch({ ...searchState, value: '' });
        sessionStorage.removeItem("defectOptionsSearchValue");
    }

    const defectClickHandler = path => {
        props.defectClickHandler(path);
    }

    async function dateCompat() {
        let query = firestore.collection('defectReports')
        let uid = users.find(user => user.displayName === autoCompleteValue)?.uuid

        if (uid) query = query.where('uid', '==', uid)
        query.orderBy('submitTime')

        const oldDate = query.where("submitTime", ">=", startDate.startOf('day').toDate()).where("submitTime", "<=", endDate.endOf('day').toDate()).get()
        const newDate = query.where("submitTime", ">=", startDate.startOf('day').utc().valueOf()).where("submitTime", "<=", endDate.endOf('day').utc().valueOf()).get()

        const [oldDateSnapshot, newDateSnapshot] = await Promise.all([newDate, oldDate]).catch((err) => console.log(err))

        const oldDateArray = oldDateSnapshot.docs
        const newDateArray = newDateSnapshot.docs

        return _.concat(oldDateArray, newDateArray)
    }

    const generateHandler = () => {
        sessionStorage.removeItem("timesheetData");
        sessionStorage.removeItem("timesheetUnitData");
        sessionStorage.removeItem("jobData");
        sessionStorage.removeItem("defectData");
	    sessionStorage.removeItem("unitData");
        sessionStorage.removeItem("defectData");
        sessionStorage.removeItem("deliveryData");
      	sessionStorage.removeItem("deliveryUnitData");
        sessionStorage.removeItem("covidData");
       	sessionStorage.removeItem("checklistData");
        sessionStorage.removeItem("photoData");
        sessionStorage.removeItem("filteredPhotoData");
        sessionStorage.removeItem("REACT_COOL_IMG");
        sessionStorage.removeItem("filteredOrderData");
        
        setErrorMessage(null);
        if (endDate.isBefore(startDate)) {
            setErrorMessage('Start Date later than End Date!')
            return
        }
        if(endDate.diff(startDate, 'd') > 365){
            setErrorMessage('Query cannot be more than a one year period.')
            return
        }

        setLoading(true);

        dateCompat().then(querySnapshot => {
            let defectResponse = querySnapshot.map((doc) => {

                return {
                    ...doc.data(),
                    'docId': doc.id,

                }
            })

            if (searchState.value !== "") {
                switch (searchState.type) {
                    case 'unitNumber':
                        defectResponse = defectResponse.filter(defect => defect?.unitNumber.includes(searchState.value))
                        break;
                    case 'address':
                        defectResponse = defectResponse.filter(defect => defect?.address.includes(searchState.value))
                        break;
                    default:
                        break;
                }
            }

            setDefectData(defectResponse)

            setLoading(false);
            sessionStorage.setItem("defectData", JSON.stringify(defectResponse))
        }).catch(e => {

            setDefectData([]);
            sessionStorage.removeItem("defectData");
            setLoading(false);
            switch(e.name){
                case 'QuotaExceededError':
                    setErrorMessage("Query size too big for browser storage.");
                    console.log(e.message);
                    break;
                default:
                    setErrorMessage("Unable to process.");
                    console.log(e.message);
            }
        });

    }
    const [expanded, setExpanded] = useState(JSON.parse(sessionStorage.getItem("reportExpanded")) || true);
    const handleExpandClick = () => {
        if(expanded)
            setExpanded(false);
        else
            setExpanded(true);    
        sessionStorage.setItem("reportExpanded", JSON.stringify(expanded))
    };
    const error = [checkboxState.unseen, checkboxState.viewed, checkboxState.printed].filter((v) => v).length < 1;
    function mergeData() {
        return defectData.map(x => {
            const user = users.find((user) => user.uuid === x.uid)
            x.uid = _.isUndefined(user) ? x.uid : user.displayName;
            
            return {
                ...x,
                
            }
        });

    }
    return (
        <React.Fragment>
            <div className={classes.content}
            style={expanded ? {paddingRight: '260px' } : {paddingRight: smallScreen ? '12px' : '230px' }} >
                {loading === false ?
                <MaterialTable
                    columns={[
                        { title: 'Unit #', field: 'unitNumber' },
                        { title: 'Trailer ID', field: 'trailerID', render: rowData => rowData.trailerID !== "" ? rowData.trailerID : "N/A" },
                        { title: 'Employee', field: 'uid' },
                        {
                            title: 'Time Submit', field: 'submitTime',  render: rowData => {
                                return moment.unix(rowData.submitTime.seconds).format('YYYY-MM-DD h:mm a')
                                
                            }
                        },
                        { title: 'Amount of Defects', field: 'defects', render: rowData => {
                            return rowData.defects.length
                        }},
                    ]}
                    data={mergeData()}
                    title='Defect Reports'
                    icons={TableIcons}
                    isLoading={loading}
                    onSearchChange={ (value) => {setSearchBarValue(value);  sessionStorage.setItem("defectSearchBarValue", JSON.stringify(value))}}
                    onRowsPerPageChange={(num) => {sessionStorage.setItem("defectReportRowsPerPage", JSON.parse(num))}}
                    actions={[
                        {
                            icon: TableIcons.OpenInNew,
                            tooltip: 'View Defect Report',
                            onClick: (event, rowData) => defectClickHandler(rowData['docId']),
                        },
                    ]}
                    onPageChange={ (pageNum) => { sessionStorage.setItem("defectReportPageValue", JSON.stringify(pageNum)) }}
                    options={{
                        pageSize: "defectReportRowsPerPage" in sessionStorage ? JSON.parse(sessionStorage.getItem("defectReportRowsPerPage")) : 25,
                        initialPage: "defectReportPageValue" in sessionStorage ? JSON.parse(sessionStorage.getItem("defectReportPageValue")) : 0,
                        toolbar: true,
                        paging: true,
                        columnsButton: true,
                        searchText: searchBarValue,
                    }}
                    detailPanel={[
                        rowData => ({
                          disabled: rowData.additionalDetails ? false : true,
                          tooltip: rowData.additionalDetails ? 'Show Additional Details' : null,
                          icon: () => rowData.additionalDetails ? <InfoIcon /> : null,
                          openIcon: () => rowData.additionalDetails ? <InfoOutlinedIcon />: null,
                          render: () => rowData.additionalDetails && 
                                <React.Fragment>
                                <Grid item xs={6}>
                                    <Typography style={{ lineHeight: 1.5, paddingLeft: '15px' }} variant='h6'>Additional Details</Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography style={{ lineHeight: 1, padding: '15px' }} variant='body1' align='left'>{rowData.additionalDetails}</Typography>
                                </Grid>
                                </React.Fragment>
                        })
                    ]}           
                />
                : <Skeleton variant='rect' width={'80vw'} height={'160vh'}/>}
                {smallScreen ?
                <div>
                    <IconButton
                        className={clsx(classes.expand, {
                            [classes.expandOpen]: expanded,
                        })}
                        
                        onClick={handleExpandClick}
                        aria-expanded={expanded}
                        aria-label="show more"
                        >
                        <ExpandMoreIcon style={{fontSize: '50px'}} />
                    </IconButton>
                    <Collapse orientation="horizontal" in={expanded} timeout="auto" unmountOnExit>
                        <Paper className={classes.sidebar}>
                            <ReportSelect
                                reportType={props.reportType}
                                onchange={props.onchange}
                            />
                            <DefectOptions
                                checkboxState={checkboxState} handler={checkboxHandler}
                                searchState={searchState} searchHandler={searchHandler}
                                clearSearch={clearSearch}
                                startDate={startDate} startDateHandler={startDateHandler}
                                endDate={endDate} endDateHandler={endDateHandler}
                                searchValue={autoCompleteValue} searchValueChanged={setAutoCompleteValue}
                                generateHandler={generateHandler}
                                errorMsg={errorMessage}
                                loading={loading}
                                error={error}
                            />
                        </Paper>
                    </Collapse>
                </div>
                :
                <Paper className={classes.sidebar}>
                    <ReportSelect
                        reportType={props.reportType}
                        onchange={props.onchange}
                    />
                    <DefectOptions
                        checkboxState={checkboxState} handler={checkboxHandler}
                        searchState={searchState} searchHandler={searchHandler}
                        clearSearch={clearSearch}
                        startDate={startDate} startDateHandler={startDateHandler}
                        endDate={endDate} endDateHandler={endDateHandler}
                        searchValue={autoCompleteValue} searchValueChanged={setAutoCompleteValue}
                        generateHandler={generateHandler}
                        errorMsg={errorMessage}
                        loading={loading}
                        error={error}
                    />
                </Paper> }
            </div>
        </React.Fragment>
    )
}

export default DefectReport