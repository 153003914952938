import { React, useState } from 'react';
import { Grid, makeStyles, Button } from '@material-ui/core';
import { withRouter } from 'react-router';
import MaterialTable from '@material-table/core';
import { useAuth } from '../../../providers/AuthContext';
import { useProduct } from '../../../providers/ProductContext'
import Img from 'react-cool-img';
import truck from '../../../img/geeteezoomzoom.gif';
import trucksad from '../../../img/geeteesad.png';
import AddBoxIcon from '@material-ui/icons/AddBox';
import { TableIcons } from '../../../constants/tableIcons';

const useStyles = makeStyles((theme) => ({

  card: {
    padding: theme.spacing(4),
    width: '100%',
    height: '100%',
  },

  content: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
  },

  grid: {
    marginTop: theme.spacing(2),
    maxWidth: '1200px',
  },

  img: {
    width: '64px',
    height: '64px',
    border: '1px solid #0000004a',
    borderRadius: '8px',
  },

  table: {
    display: 'flex',
    justifyContent: 'center',
  },

  tile: {
    width: '300px',
    height: '300px',
  },

  tileContent: {
    display: 'flex',
    justifyContent: 'space-evenly',
    alignItems: 'center',
    flexDirection: 'column',
    width: '100%',
    height: '100%',
    padding: theme.spacing(4),
  },

  tileIcon: {
    color: 'rgba(0, 0, 0, 0.54)',
    width: '100px',
    height: '100px',
  },

  tileText: {
    fontSize: '1.10rem',
  },
  btn: {
    marginBottom: '20px',
    marginTop: '20px',
},
}));

const ProductHome = (props) => {

  const [searchBarValue, setSearchBarValue] = useState("productSearchBarValue" in sessionStorage ? JSON.parse(sessionStorage.getItem("productSearchBarValue")) : null)
  const [page, setPage] = useState("productPageValue" in sessionStorage ? JSON.parse(sessionStorage.getItem("productPageValue")) : 0)

  const { products, loading, error } = useProduct();
  const { roles } = useAuth();

  const classes = useStyles();

  const tileClickHandler = (path, searchParams) => {
    props.history.push({
      pathname: "products/" + path,
      search: searchParams && "?" + new URLSearchParams({ docId: searchParams }).toString(),
    })
  };

  const handleOnSearchChange = (value) => {
    setSearchBarValue(value);  
    sessionStorage.setItem("productSearchBarValue", JSON.stringify(value));

    setPage(0);  
    sessionStorage.setItem("productPageValue", JSON.stringify(0));
  }

  const handleOnPageChange = (pageNum) => {
    setPage(pageNum);  
    sessionStorage.setItem("productPageValue", JSON.stringify(pageNum));
  }

  return (
    <div className={classes.content}>
      <Grid>
        <Grid item xs={12} className={classes.btn}>
          <Button variant='contained' onClick={() => tileClickHandler('product', 'new')} startIcon={<AddBoxIcon/>} 
                  style={roles.includes('Create|Update|Delete Equipment')  ? {visibility: 'visible'} : {visibility: 'hidden'}}>
           Create New Product
          </Button>
        </Grid>

        {!loading && !error
          ? <Grid item sm={12} xs={12} className={classes.table}>
            <MaterialTable

              columns={[
                { title: '', render: (rowData) => <Img className={classes.img} error={trucksad} placeholder={truck} src={rowData.photoURL} alt={rowData.name + ' Photo'}/>},
                { title: 'Name', field: 'name', defaultSort: 'asc' },
                { title: 'Slug', field: 'slug' },
                { title: 'Category', field: 'category' },
                { title: 'Popularity', field: 'popularity', width: '1%' },
                { title: 'In Stock', field: 'inStock', width: '1%', render: (rowData) => rowData.inStock ? 'Yes' : 'No' },
                { title: 'Hidden on store', field: 'hideOnStore', render: (rowData) => rowData.hideOnStore ? 'Yes' : 'No' },
              ]}
              data={products.filter(product => !product?.archived)}
              title={"Product Manger"}
              pathname='/dashboard/products/product'
              onSearchChange={ (value) => {handleOnSearchChange(value) }}
              onPageChange={ (pageNum) => {handleOnPageChange(pageNum) }}
              actions={[
                rowData => ({
                  icon: TableIcons.OpenInNew,
                  tooltip: 'Show Product',
                  onClick: (event, rowData) => {
                    props.history.push({
                      pathname: '/dashboard/products/product',
                      search: "?" + new URLSearchParams({ docId: rowData.docId }).toString(),
                    })
                  }
                })
              ]}
              options={{
                toolbar: true,
                paging: true,
                initialPage: page,
                pageSizeOptions: false,
                actionsColumnIndex: -1,
                search: true,
                emptyRowsWhenPaging: false,
                thirdSortClick: false,
                searchText: searchBarValue,
                rowStyle: {
                    whiteSpace: 'pre-wrap',
                },
              }}
            />
          </Grid>
          : null}
      </Grid>
    </div>
  )
}

export default withRouter(ProductHome);