import { firestore } from '../../firebase/firebase'
import * as actionTypes from './actionTypes'
import _ from 'lodash'

export const addToCart = (product, variant, qty, shippingMethod, price) => {
    return {
        type: actionTypes.ADD_TO_CART,
        product: product,
        variant: variant,
        qty: qty,
        shippingMethod: shippingMethod,
        price: price,
    }
}

export const resetCart = () => {
    return {
        type: actionTypes.RESET_CART,
    }
}

export const incrQtyCart = (index) => {
    return {
        type: actionTypes.INCR_QTY_CART,
        index: index,
    }
}

export const decrQtyCart = (index) => {
    return {
        type: actionTypes.DECR_QTY_CART,
        index: index,
    }
}

export const calcShipping = () => {
    return {
        type: actionTypes.CALC_SHIPPING,
    }
}

export const removeFromCart = (index) => {
    return {
        type: actionTypes.REMOVE_FROM_CART,
        index: index,
    }
}

export const setSubtotal = () => {
    return {
        type: actionTypes.SET_SUBTOTAL
    }
}

export const taxFetch = () => {
    return {
        type: actionTypes.FETCH_TAX,
        taxesLoading: true,
    }
}

export const taxSuccess = (taxes) => {
    return {
        type: actionTypes.GET_TAX,
        taxes: taxes.salesTax,
        carbonFee: Number(taxes.carbonFee),
        fuelSurcharge: Number(taxes.fuelSurcharge),
        brickFee: taxes.brickFee,
        baseDEFAULT: Number(taxes.cartFees.baseDEFAULT),
        baseSTONEWALL: Number(taxes.cartFees.baseSTONEWALL),
        deliveryMinimum: Number(taxes.cartFees.deliveryMinimum), 
        deliveryLowRate: taxes.cartFees.deliveryLowRate, 
        deliveryMidRate: taxes.cartFees.deliveryMidRate, 
        deliveryHighRate: taxes.cartFees.deliveryHighRate, 
        taxesLoading: false,
    };
}

export const setError = () => {

}

export const getTax = () => {
    return dispatch => {
        //dispatch(taxFetch());

        firestore.collection('shopSettings').doc('taxes').get()
            .then(doc => {
                dispatch(taxSuccess(doc.data()))
            });
    }
}

//optional paramaters list (list of coupon DocId's), includes(bool) filter sales to only include or exclude list
export const getSales = (saleList, include) => {
    return dispatch => {
        firestore.collection('coupons').where("isSale", "==", true).get()
        .then(querySnapshot => {
            let sales = querySnapshot.docs.map((doc) => {
                return {
                    ...doc.data(),
                    'docId': doc.id,
                }
            })

            if(saleList && saleList.length > 0 && !_.isUndefined(include)){
                sales = include ? sales.filter(sale => saleList.includes(sale.docId)) : sales.filter(sale => !saleList.includes(sale.docId))
            }

            dispatch(salesSuccess(sales));
        })
    }
}


export const salesSuccess = (sales) => {
    return {
        type: actionTypes.GET_SALES,
        sales: sales,
        loading: false,
    };
}

export const setCoupon = (couponCode) => {
    return dispatch => {
        firestore.collection('coupons').where("code", "==", couponCode).get()
            .then(querySnapshot => {
                const coupons = querySnapshot.docs.map((doc) => {
                    return doc.data()
                })
                if (coupons.length > 0 && (!('active' in coupons[0]) || coupons[0].active))
                    dispatch(setCouponSuccess(coupons[0]));
                else
                    dispatch(setCouponSuccess([]));
            })
    }
}

export const setGiftCard = (giftCardCode) => {
    return dispatch => {
        firestore.collection('giftCards').where("code", "==", giftCardCode).get()
            .then(querySnapshot => {
                const giftCards = querySnapshot.docs.map((doc) => {
                    return doc.data()
                })
                if (giftCards.length > 0)
                    dispatch(setGiftCardSuccess(giftCards[0]));
                else
                    dispatch(setGiftCardSuccess([]));
            })
    }
}

export const removeCoupon = () => {
    return {
        type: actionTypes.REMOVE_COUPON,
    }
}

export const setCouponSuccess = (coupon) => {
    return {
        type: actionTypes.SET_COUPON,
        coupon: coupon,
        loading: false,
    };
}

export const setGiftCardSuccess = (giftCard) => {
    return {
        type: actionTypes.SET_GIFT_CARD,
        giftCard: giftCard,
        loading: false,
    };
}

export const setCoordinates = (destination, distance) => {
    return {
        type: actionTypes.SET_COORDINATES,
        customerLocation: destination,
        distance: distance,
    }
}

export const setAddress = (destination, distance, address) => {
    return {
        type: actionTypes.SET_ADDRESS,
        searchboxCoordinates: destination,
        distance: distance,
        customerAddress: address,
    }
}

export const setDistance = (destination, inputType, address) => {
    return dispatch => {
        const google = window.google ? window.google : {};

        if (google.maps) {
            let distanceMatrix = new google.maps.DistanceMatrixService();

            const getDistanceMatrix = (data) => new Promise((resolve, reject) => {
                distanceMatrix.getDistanceMatrix(data, (response, status) => {
                    if (status === 'OK') {
                        resolve(response)
                    } else {
                        console.warn('Was unable to retrieve location data.')
                        reject(response);
                    }
                })
            });
          
      //      getDistanceMatrix({
       //         destinations: [destination],
        //        origins2: [{ lat: 50.1352, lng: -97.3274 }],
        //        travelMode: "DRIVING",
       //     })
            getDistanceMatrix({
                destinations: [destination, destination],
                origins: [{ lat: 49.904415, lng: -96.932431 }, "166 Main St, Stonewall, MB R0C 2Z0"],
                travelMode: "DRIVING",
            }).then(distance => {
                switch (inputType) {
                    case "INPUT_SEARCHBOX":
                        dispatch(setAddress(destination, distance, address))
                        break;
                    case "INPUT_MARKER":
                        dispatch(setCoordinates(destination, distance))
                        break;
                    default:
                        break;
                }
            })
        }
    }
}

export const clearIsAdded = () => {
    return {
        type: actionTypes.CLEAR_IS_ADDED,
    }
}

export const confirmBulkPricing = () => {
    return {
        type: actionTypes.CONFIRM_BULK_PRICING,
    }
}
