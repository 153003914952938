import React, {useState, useEffect} from 'react';
import { firestore } from '../../../../firebase/firebase'
import { withRouter } from 'react-router-dom';
import ChecklistCard from '../../../components/Reports/Checklists/ChecklistCard'
import { Skeleton } from '@material-ui/lab';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    content: {
        display: 'flex',
        width: '100%',
        padding: '32px',
        justifyContent: 'center',
        marginTop: '40px',
    },
}));

const Checklist = (props) => {
    const [checklistData, setChecklistData] = useState({});
    const [userData, setUserData] = useState({});
    const [loading, setLoading] = useState(true);
    const [errorMessage, setErrorMessage] = useState(false);
    let path = new URLSearchParams(props.location.search).get("docId");
    const classes = useStyles();

    useEffect(() => {   
        setLoading(true)
        firestore.collection('checklists').where('__name__', "==", path).get()
            .then(querySnapshot => {
                const checklistResponse = querySnapshot.docs.map((doc) => {
                    return {
                        ...doc.data(),
                        'docId': doc.id,
                    }
                })
  
                firestore.collection('users').where("uuid", "==", checklistResponse[0].uid).get()
                    .then(querySnapshot => {
                        const userResponse = querySnapshot.docs.map((doc) => {
                            return {
                                ...doc.data(),
                                'docId': doc.id,
                            }
                        })
                        setUserData(userResponse[0]);
                    })
                    .catch(e => {
                        setErrorMessage("Unable to Process")
                        setLoading(false)
                        console.log(e.message);
                    })


                    if(checklistResponse[0].jobID){
                        firestore.collection('jobs').where("uid", "==", checklistResponse[0].uid).where('__name__', "==", checklistResponse[0].jobID).get()
                            .then(querySnapshot => {
                                const jobResponse = querySnapshot.docs.map((doc) => {
                                    return {
                                        ...doc.data(),
                                        'docId': doc.id,
                                    }
                                })
                                
                                let response = checklistResponse.map((checklist) => {
                                        let job = jobResponse.find(job => job.docId === checklist.jobID && job.uid === checklist.uid);
                                        return {
                                            ...checklist,
                                            'customer': job?.customer,
                                            'address': job?.address,
                                        }

                                })
                                setLoading(false);
                                setChecklistData(response[0]);

                            })
                            .catch(e => {
                                setErrorMessage("Unable to Process")
                                setLoading(false)
                                console.log(e.message);
                            })
                    }else{
                        setLoading(false);
                        setChecklistData(checklistResponse[0]);
                    }
            })
            .catch(e => {
                setErrorMessage("Unable to Process");
                setLoading(false);
                console.log(e.message);
            })
    }, [path]) // eslint-disable-line react-hooks/exhaustive-deps
    

    return (
        loading
        ? 
        <div className={classes.content}>
            <Skeleton variant='rect' width={'42vw'} height={'90vh'}/>
        </div>
        : 
        <ChecklistCard 
            checklistData={checklistData} 
            userData={userData} 
            errorMessage={errorMessage} 
            loading={loading} 
        />
    )
}

export default withRouter(Checklist)