import React, { useState, useCallback } from 'react';
import { firestore } from '../../../firebase/firebase'
import { withRouter } from 'react-router';
import GiftCardsCard from '../../components/GiftCards/GiftCardsCard';
import { useEffect } from 'react';
import moment from 'moment';

const GiftCardNew = (props) => {

    const initialGiftCard = {
        code: '',
        description: '',
        totalValue: 0,
        currentValue: 0,
    }



    const [giftCard, setGiftCard] = useState(initialGiftCard);
    const [currentCodes, setCurrentCodes] = useState([]);

    useEffect(() => {
        firestore.collection('giftCards').get()
        .then(querySnapshot => {
            const giftCardResponse = querySnapshot.docs.map((doc) => {
                return {
                    ...doc.data(),
                    'docId': doc.id,
                }
            })
            setCurrentCodes(giftCardResponse.map((card) => card.code));
        })
        .catch(e => {
            console.log(e.message);
        })
    }, []);

    //this creates a code ive decided instead of UUID because it needs to be user friendly
    const createCode = () => {
        let code = 'GEETEE-';
        let possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
        for (let i = 0; i < 9; i++) {
            code += possible.charAt(Math.floor(Math.random() * possible.length));
        }
        if (currentCodes.includes(code)) {
            createCode();
        }
        return code;
    }

    const updateGiftCard = useCallback((e) => {setGiftCard(e)},[],);

    const createForm = async (event, docId) => {
        event.preventDefault();

        firestore.collection('giftCards').doc(docId).set({
            ...giftCard,
            'currentValue': giftCard.totalValue,
            'dateCreated': moment().format('MM/DD/YYYY')
        })
            .then(() => {
                setGiftCard(initialGiftCard);
                props.history.replace({pathname: '/dashboard/giftCards/'})
        })
        
        .catch((e) => {
            console.log(e)
        });
    }

    return (
        <React.Fragment>
            <GiftCardsCard
                type={'New'}
                giftCard={giftCard}
                updateGiftCard={updateGiftCard}
                createForm={createForm}
                createCode={createCode}
            />
        </React.Fragment>
    )
}

export default withRouter(GiftCardNew);