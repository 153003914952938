import React, { useContext, useState, useEffect } from "react"
import { firestore } from "../firebase/firebase"

const SalesEquipmentContext = React.createContext();

export function useSalesEquipment() {
    return useContext(SalesEquipmentContext);
}

export function SalesEquipmentProvider({ children }) {

    const [salesEquipment, setSalesEquipment] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');

    useEffect(() => {
        let unsubrscibe = firestore.collection("equipmentSales").onSnapshot(querySnapshot => {
            let salesEquipmentResponse = querySnapshot.docs.map((doc) => {
                return {
                    ...doc.data(),
                    'docId': doc.id,
                }
            })

            setLoading(false);
            setSalesEquipment(salesEquipmentResponse);
            //sessionStorage.setItem("salesEquipmentData", JSON.stringify(salesEquipmentResponse));
        },
            error => {
                setLoading(false);
                setError(error.message);
                console.log(error.message);
            }
        )
        return () => { unsubrscibe(); }
    }, [])

    const value = {
        salesEquipment: salesEquipment,
        loading,
        error,
    }

    return (
        <SalesEquipmentContext.Provider value={value}>
            {children}
        </SalesEquipmentContext.Provider>
    )
}