import React from 'react'
import { Checkbox, FormControlLabel, Grid } from "@material-ui/core"

const VariantsList = (props) => {

    //const [showVariants, setShowVariants] = React.useState(false)

    //let affectedVariants = props.product?.variants?.filter(variant => props.productArray.includes(variant.id))
    //let allVariants = affectedVariants?.length === props.product?.variants?.length;
    
    return (
        <Grid container>

            <Grid item xs={11} style={{ display: 'flex', alignItems: 'center' }}>
                <FormControlLabel
                    style={{ marginLeft: '8px' }}
                    label={props.product?.hideOnStore ? props.product?.docId + ' | HIDDEN ON STORE' : props.product?.name}
                    control={
                        <Checkbox
                           
                            checked={props.productArray.includes(props.product.docId)}
                            disabled={props.product.hideOnStore}
                            onChange={() => {
                                //let newProductsAffected = [...props.productArray];
                                
                                //props.product.variants.forEach(variant => {
                                //    let index = newProductsAffected.findIndex(id => id === variant.id)
                                //    index > -1
                                 //       ? allVariants && props.updateProducts(props.product.docId)
                                 //       : !allVariants &&  props.updateProducts(props.product.docId)
                                //})
                                props.updateProducts(props.product.docId)
                               
                            }}
                        />}
                />
            </Grid>

          
        </Grid>
    )
}

export default VariantsList;