import React, { useState, useEffect, useRef, useCallback, useMemo} from 'react';
import { Grid, IconButton, Paper, Table, TableHead, TableBody, TableRow, TableCell, TablePagination, makeStyles,TableContainer, Box, Tab, Typography } from '@material-ui/core';
import _ from 'lodash';
import moment from 'moment';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import TabContext from '@material-ui/lab/TabContext';
import TabList from '@material-ui/lab/TabList';
import TabPanel from '@material-ui/lab/TabPanel';
import { ContextMenu, MenuItem as ContextMenuItem, ContextMenuTrigger } from "react-contextmenu";

import DataGridTableTitle from './DataGridTableTitle';
import DataGridTableTableHeader from './DataGridTableTableHeader';
import DataGridTableNewRow from './DataGridTableNewRow';
//import DataGridRow from './DataGridRow';

import DataGridTableAutocomplete from './Cells/DataGridTableAutocomplete';
import DataGridTableDatePicker from './Cells/DataGridTableDatePicker';
import DataGridTableTextfield from './Cells/DataGridTableTextfield';
import DataGridTableTypography from './Cells/DataGridTableTypography';
import DataGridTableErrorableTextfield from './Cells/DataGridTableErrorableTextfield';

const useStyles = makeStyles((theme) => ({
    multiRowSelect: {
        borderBottom: '1px solid #767676',
        '&:hover': {
            cursor: 'copy',
          },
          backgroundColor: '#959595',
    },
    tableHeader: {
        padding: '15px',
    },
    root: {
        userSelect: 'none',
        height: 'inherit',
        display: 'inlineBlock',
    },
    loading: {
        userSelect: 'none',
        pointerEvents: 'none',
        opacity: 0.5,
        zIndex: 99999,
        backgroundColor: 'black',
        height: 'inherit',
        display: 'inlineBlock',
    },
    cellBorder: {
        borderBottom: '1px solid #767676', 
        borderRight: '1px solid #767676',
    },
    table: {
        display: 'flex',
        justifyContent: 'center',
    },
    selectableContextItems: {
        fontSize: '16px', 
        margin: '5px',
        padding: '5px',
        '&:hover': {
            cursor: 'pointer',
            boxShadow: '5px 5px 10px 2px rgb(0 0 0 / 0.2)'
          },
    },
}));

const DataGridTable = (props) => {
    const classes = useStyles();

    const isMounted = useRef(false)
    const focus = useRef()

    const filterLength = useRef()
    const mouseDownTimer = useRef()
    const firstRowSelection = useRef()

    const [mouseDown, setMouseDown] = useState(false)
    const [filteredData, setFilteredData] = useState([])
    const [searchValue, setSearchValue] = useState("grainOrdersReportSearchValues" in sessionStorage 
        ? JSON.parse(sessionStorage.getItem("grainOrdersReportSearchValues")) 
        : {value: '', column: 'all', condition: 'contains'});
    const [page, setPage] = useState(0)
    const [rowsPerPage, setRowsPerPage] = useState(!_.isUndefined(props.userSettings['grainRowsPerPage']) ? props.userSettings['grainRowsPerPage'] : 20)
    const [tabSelected, setTabSelected] = useState("grainOrdersReportTabValue" in sessionStorage 
        ? JSON.parse(sessionStorage.getItem("grainOrdersReportTabValue")) 
        : {status: 'all', type: props.pathType || 'all'});
    const [order, setOrder] = useState(!_.isUndefined(props.columns.filter(x => !_.isUndefined(x.orderByColumn))[0]) ? props.columns.filter(x => !_.isUndefined(x.orderByColumn))[0].orderByColumn : 'asc');
    const [orderBy, setOrderBy] = useState(!_.isUndefined(props.columns.filter(x => !_.isUndefined(x.orderByColumn))[0]) ? props.columns.filter(x => !_.isUndefined(x.orderByColumn))[0].field : '');
    //used to update context component, access passed data in contextMenu we need to use the onShow callback to set the data and set here
    const [context, setContext] = useState(null)

    //sets up mouse up (for handling adding multiple rows) and keyboard down for loading
    useEffect(() => {
        window.addEventListener('mouseup', handleMouseUpEvent);
        window.addEventListener('keydown', globalKeyboardPressEvent);
        return function cleanup() {
            window.removeEventListener('mouseup', handleMouseUpEvent ) 
            window.removeEventListener('keydown', globalKeyboardPressEvent ) 
        };
        //eslint-disable-next-line
    },[])

    //filters data based on search and order/orderBy
    useEffect(() => {
        sortData(tabSelected, searchValue);
    }, [order, orderBy, page, rowsPerPage])  // eslint-disable-line react-hooks/exhaustive-deps
    
    //sets focus on new row
    useEffect(() => {
        if(isMounted.current){
            let currentDoc = `${props.addedValue[props.addedValue.length -1]} ${findAddedValue(props.addedValue[props.addedValue.length -1])}`
            let focusCell = document.getElementById(currentDoc)
            focusCell?.focus()
        } else {
           isMounted.current = true;
        }
    }, [props.addedValue]) // eslint-disable-line react-hooks/exhaustive-deps

    //pass page changes up to parent (page makes most sense here on table, maybe move?)
    useEffect(() => { !_.isUndefined(props.onPageChange) && props.onPageChange(page) }, [page,])  // eslint-disable-line react-hooks/exhaustive-deps
    
    const findAddedValue = (docId) => {
        let document = props.globalGrainOrders.find(x=> x.docId === docId)
        let returnValue = '';

        props.columns.forEach((column) => {
            if(!_.isUndefined(document)){
                for (const [key] of Object.entries(document)) {
                    if(key === column.field && (_.isUndefined(column.active) || column.active) && column.columnType !== 'typography'){
                        returnValue = key;
                        break;
                    }
                    if(returnValue !== ''){ break; }
                }
            }
            if(returnValue !== ''){return returnValue}
        })
        return returnValue;
    }

    //memoize with [sorted grain orders, search value, orderBy]
    const sortData = (tabSelectedObj, searchValueObj) => {
        props.setAddedRows({})
        let copy = _.cloneDeep(props.globalGrainOrders);

        // //filter for order type
        if(tabSelectedObj.type === 'deck'){
            let container = props.globalGrainOrders?.filter((order) => ( order.orderType === 'deck'))
            copy = [...container]
        } else if(tabSelectedObj.type === 'geetee'){
            let container = props.globalGrainOrders?.filter((order) => ( order.orderType === 'geetee'))
            copy = [...container]
        }else if(tabSelectedObj.type === 'wtl'){ 
            let container = props.globalGrainOrders?.filter((order) => ( order.orderType === 'wtl' ))
            copy = [...container]
        }

        //filter for order status
        if(tabSelectedObj.status === 'active'){
            let container = copy?.filter((order) => ( order.orderStatus === 'active'))
            copy = [...container]
        } else if(tabSelectedObj.status === 'complete'){
            let container = copy?.filter((order) => ( order.orderStatus === 'complete'))
            copy = [...container]
        }else if(tabSelectedObj.status === 'cancelled'){
            let container = copy?.filter((order) => ( order.orderStatus === 'cancelled' ))
            copy = [...container]
        }

        //filter for search params
        if(searchValueObj.value !== ''){
            if(searchValueObj.column === 'all'){
                let culmination = []
                props.columns.forEach((column) => {
                    let container = copy.filter(x => { 
                        if(searchValueObj.condition === 'contains'){
                            return getCheckValue(x, column.field, column)?.toString().toLowerCase().includes(searchValueObj.value.toString().toLowerCase())
                        }
                        else{
                            return !getCheckValue(x, column.field, column)?.toString().toLowerCase().includes(searchValueObj.value.toString().toLowerCase()) 
                        }
                    })
                    culmination = culmination.concat(container)
                })
                copy = ([...new Set(culmination)])
            }
            else{ 
                let columnValue = props.columns.find(x=> x.field === searchValueObj.column)
                copy = copy.filter(x => { 
                    if(searchValueObj.condition === 'contains'){
                        return getCheckValue(x, columnValue.field, columnValue)?.toString().toLowerCase().includes(searchValueObj.value.toString().toLowerCase()) 
                    }
                    else{
                        return !getCheckValue(x, columnValue.field, columnValue)?.toString().toLowerCase().includes(searchValueObj.value.toString().toLowerCase()) 
                    }
                })
            }
        }
        
        //order/orderBy filter
        if(orderBy !== ''){ 
            copy = copy.sort((a, b) => {
                let sortValue = 0

                //unassigned sort (should always be at the top)
                let first = checkForEquipmentOperator(a)
                let second = checkForEquipmentOperator(b)
                if((first && second) || (!first && !second)){ sortValue = 0 }
                else if( first && !second ){ sortValue = 1 }
                else{ sortValue = -1 }

                //primary sort
                if(sortValue === 0){ 
                    sortValue = customSort(order, getCheckValue(a, orderBy), getCheckValue(b, orderBy))
                }
                
                //secondary sort
                if(sortValue === 0){ 
                    let secondarySortColumn = props.columns.filter(x => !_.isUndefined(x.secondarySort))[0].field
                    sortValue = customSort('asc', getCheckValue(a, secondarySortColumn), getCheckValue(b, secondarySortColumn)) 
                }
                //tertiary sort
                if(sortValue === 0){ 
                    let tertiarySortColumn = props.columns.filter(x => !_.isUndefined(x.tertiarySort))[0].field
                    sortValue = customSort('asc', getCheckValue(a, tertiarySortColumn), getCheckValue(b, tertiarySortColumn)) 
                }
                return sortValue
            })
        } 

        //set length before pagination
        filterLength.current = _.cloneDeep(copy.length);

        //pagination
        if(rowsPerPage > 0){  copy = copy.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)   }

        //ensures page is within range
        if((filterLength.current !== 0 && page !== 0) && page * rowsPerPage + rowsPerPage > filterLength.current ){
            for(let i = page; i >= 0; i--){
                if(filterLength.current > i * rowsPerPage && filterLength.current < i * rowsPerPage + rowsPerPage){
                    setPage(i);
                    break;
                }
            }
        }
        setFilteredData([...copy])
    }

    const customSort = (type, value1, value2) => {
        //undefined checks
        if((_.isUndefined(value1) || value1 === '')  && (!_.isUndefined(value2) && value2 !== '')){ return -1 }
        else if((!_.isUndefined(value1) && value1 !== '') && (_.isUndefined(value2) || value2 === '')){ return 1 }
        else if((_.isUndefined(value1) || value1 === '') && (_.isUndefined(value2) || value2 === '')) { return 0 }

        if(type === 'asc'){
            if(value1 > value2){ return 1}
            else if(value1 < value2){ return -1}
            else{ return 0 }
        }
        else{   //'desc
            if(value1 > value2){ return -1}
            else if(value1 < value2){ return 1}
            else{ return 0 }
        }
    }

    const handleSortDebouncer = _.debounce(function (tabSelected, searchData) {
        sortData(tabSelected, searchData);
    }, 400);

    const handleSearchChange = useCallback((value, type,) => {
        setSearchValue((previousValues) => { 
            let container = {...previousValues, [type]: value}
            sessionStorage.setItem("grainOrdersReportSearchValues", JSON.stringify(container));
            focus.current = undefined;
            handleSortDebouncer( tabSelected, container);
            return container;
        })
    }, [tabSelected])

    const handleChangePage = (e, newPage) => {
        setPage(newPage);
        focus.current = undefined;
    };

    const handleChangeRowsPerPage = (e) => {
        props.updateUserSettings('grainRowsPerPage', parseInt(e.target.value))
        setRowsPerPage(parseInt(e.target.value));
        setPage(0);
        focus.current = undefined;
    };

    const getCheckValue = (data, field, column) => {
        if(!data || !field){ return null }
        let columnContainer = !_.isUndefined(column) ? column : props.columns.find(x=> x.field === field)
        let returnValue = null;

        switch(columnContainer.columnType) {
            case 'autocomplete':
                let autocompleteFoundValue = columnContainer.options.data?.find(x=> x.docId === data[field])
                if( !_.isUndefined(autocompleteFoundValue) ){ returnValue = autocompleteFoundValue[columnContainer.options.displayFields[0]] }
                else{ returnValue = data[field] }
                break;
            case 'select':
                let selectFoundValue = columnContainer.options.data?.find(x=> x.docId === data[field])
                if( !_.isUndefined(selectFoundValue) ){ returnValue = selectFoundValue[columnContainer.options.displayFields[0]] }
                else{ returnValue = data[field] }
                break;
            case 'date':
                returnValue = moment(data[field]).format('YYYY-MM-DD').valueOf()
                break;
            default:
                returnValue = data[field]
                break;
          } 
          //console.log('returnValue', typeof returnValue === 'number' ? returnValue : returnValue?.toString().toLowerCase())
          return typeof returnValue === 'number' ? returnValue : returnValue?.toString().toLowerCase()
    }

    //to home ?
    const checkForEquipmentOperator = (data) => {
        if((data.equipment !== '' && data.equipment !== undefined && data.equipment !== null) 
            && (data.operator !== '' && data.operator !== undefined && data.operator !== null)){
            return true;
        }
        return false;
    }

    //check for unique tickets, returns true if orderNumber is unique
    const handleCheckUnique = (value, docId) => {
        if( !value ){ return true }
        let confimation = true;
        props.globalGrainOrders.filter(x=> x.docId !== docId).forEach((data) => { if(data.orderNumber === value){ confimation = false; }  })
        return confimation;
    }

    const globalKeyboardPressEvent = (e) => { props.loading && e.preventDefault() }

    const handleMouseUpEvent = () => {
        if (mouseDownTimer.current) {
            clearTimeout(mouseDownTimer.current);
            mouseDownTimer.current = null;
            setMouseDown(false);
            document.body.style.cursor = 'auto';
        }
    };

    const handleMouseDownEvent = (e, rowDocId, index) => {
        if (mouseDownTimer.current) return;

        mouseDownTimer.current = setTimeout(() => {
            firstRowSelection.current = rowDocId
            document.body.style.cursor = 'copy';
            props.setRowsSelected([rowDocId])
            props.rowsSelectedRef.current = [rowDocId]
            setMouseDown(true);
        }, 50);
    };

    const handleMouseEnterEvent = (e, rowDocId, index) => {
        if(mouseDown){
            let latestRowSelectedIndex = filteredData.concat(props.addedRows[page] || []).filter(x=> !props.deletedRows.includes(x.docId)).findIndex(x=> x.docId === firstRowSelection.current)
            let smaller = _.clamp(index >= latestRowSelectedIndex ? latestRowSelectedIndex : index, 0, filteredData.concat(props.addedRows[page] || []).filter(x=> !props.deletedRows.includes(x.docId)).length -1);
            let larger = _.clamp(index >= latestRowSelectedIndex ? index : latestRowSelectedIndex, 0, filteredData.concat(props.addedRows[page] || []).filter(x=> !props.deletedRows.includes(x.docId)).length -1);
            let newSelectedRows = filteredData.concat(props.addedRows[page] || []).filter(x=> !props.deletedRows.includes(x.docId)).slice(smaller, larger +1).map((row) => { return row.docId })
           props.setRowsSelected(newSelectedRows)
           props.rowsSelectedRef.current = newSelectedRows
        }
    };
   
    const handleCellUpdateCallback = useCallback((value, field, docId) => {
        let valueContainer = !_.isUndefined(value) ? value : '';
        !_.isUndefined(props.onCellChange) && props.onCellChange(valueContainer, field, docId)
        //eslint-disable-next-line
    }, []);

    const handleDblClickCallback  = useCallback((column, docId) => {
        props.handleDblClick(column, docId, props.columns)
    }, []);  // eslint-disable-line react-hooks/exhaustive-deps
    
    const handleRowClickCallback = useCallback((e, rowDocId) => { 
        props.setRowsSelected([rowDocId]) 
        props.rowsSelectedRef.current = [rowDocId]
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const handleTableChange = (newValue, tabKey) => {
        let tabChanges = {...tabSelected, [tabKey]: newValue}
        setTabSelected(tabChanges);
        sessionStorage.setItem("grainOrdersReportTabValue", JSON.stringify(tabChanges));
        sortData(tabChanges, searchValue);
    };

    const handleContextSelectItem = (e, d, passedCondition) => {
        let container = {value: getCheckValue(d, d.column.field, d.column), column: d.column.field, condition: passedCondition}
        setSearchValue(container)
        sortData(tabSelected, container);
    }

    const sortedRows = useMemo(() => {
        //console.log('sorting rows')
        return (filteredData.concat(props.addedRows[page] || []).filter(x=> !props.deletedRows.includes(x.docId)))
    }, [filteredData, props.addedRows, props.deletedRows, page]);

    return(
        <Grid className={props.loading ? classes.loading : classes.root}>

            <Grid item sm={12} xs={12} className={classes.table}>
                <TabContext value={tabSelected.type}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <TabList onChange={(e, newValue) => { handleTableChange(newValue, 'type') }}>
                            <Tab label={"All"} value="all" />
                            <Tab label={"Deck"} value="deck" />
                            <Tab label={"Geetee"} value="geetee" />
                            <Tab label={"WTL"} value="wtl" />
                        </TabList>
                    </Box>
                    <TabPanel value="all"></TabPanel>
                    <TabPanel value="deck"></TabPanel>
                    <TabPanel value="geetee"></TabPanel>
                    <TabPanel value="wtl"></TabPanel>
                </TabContext>
            </Grid>

            <Grid item sm={12} xs={12} className={classes.table}>
                <TabContext value={tabSelected.status}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <TabList onChange={(e, newValue) => { handleTableChange(newValue, 'status') }}>
                            <Tab label={"All"} value="all" />
                            <Tab label={"Active"} value="active" />
                            <Tab label={"Complete"} value="complete" />
                            <Tab label={"Cancelled"} value="cancelled" />
                        </TabList>
                    </Box>
                    <TabPanel value="all"></TabPanel>
                    <TabPanel value="active"></TabPanel>
                    <TabPanel value="complete"></TabPanel>
                    <TabPanel value="cancelled"></TabPanel>
                </TabContext>
            </Grid>

            <Paper>
                <Grid container className={classes.tableHeader}>
                    <DataGridTableTitle 
                        searchValue={searchValue}
                        handleSearchChange={handleSearchChange}
                        columns={props.columns}
                        setColumns={props.setColumns}
                        rowsSelected={props.rowsSelected}
                        page={page}
                        setPages={setPage}
                        title={props.title}
                        updateSort={sortData}
                        tabSelected={tabSelected}
                    />
                </Grid>

                <TableContainer style={props.tableExpanded ? {height: '55vh'} : {height: '40vh'}}>    
                    <Table stickyHeader>     
                        <TableHead>
                            <DataGridTableTableHeader 
                                //detailsPanelBool={detailsPanelBool}
                                action={props.onActionClick}
                                columns={props.columns}
                                setColumns={props.setColumns}
                                order={order}
                                setOrder={setOrder}
                                orderBy={orderBy}
                                setOrderBy={setOrderBy}
                            />
                        </TableHead>

                        <TableBody>
                            {sortedRows.map((data, dataIndex) => {
                                //conditional data that needs to be updated per row
                                let grainIndex = props.globalGrainOrders.findIndex(x=> x.docId === data.docId)
                                let uniqueTicketNumber = handleCheckUnique(props.globalGrainOrders[grainIndex].orderNumber, data.docId)

                                return (
                                    // <DataGridRow 
                                    // />

                                    <TableRow
                                        id={data.docId}
                                        key={data.docId}
                                        style={props.rowsSelected.includes(data.docId) ? {backgroundColor: '#D1FFbD', } : 
                                                !checkForEquipmentOperator(props.globalGrainOrders[grainIndex]) ? {backgroundColor: '#ADD8E6', } : {}}
                                        onClick={(e) => { !props.loading && handleRowClickCallback(e, data.docId) }}
                                        onMouseEnter={(e) => { !props.loading && handleMouseEnterEvent(e, data.docId, dataIndex) }}
                                    >
                            
                                        <TableCell 
                                            key={data.docId + dataIndex}
                                            className={classes.multiRowSelect} 
                                            onMouseDown={(e) => { !props.loading && handleMouseDownEvent(e, data.docId) }}
                                        />

                                            {props.columns.filter(x=> !_.isUndefined(x.active) ? x.active : true).map((column, columnIndex) => {
                                                //console.log('column', column)
                                                return (
                                                    column.columnType === 'autocomplete' || column.columnType === 'select'
                                                    ?       
                                                        <ContextMenuTrigger
                                                            id={'contextMenu'}
                                                            collect={() => { return {...data, column}} }
                                                            renderTag={TableCell}
                                                            attributes={{className: classes.cellBorder}}
                                                        >
                                                            <DataGridTableAutocomplete 
                                                                handleCellUpdateCallback={handleCellUpdateCallback}
                                                                //cellClickCallback={cellClickCallback}
                                                                handleDblClickCallback={handleDblClickCallback}

                                                                docId={data.docId}

                                                                options={column.options}
                                                                field={column.field}
                                                                type={column.columnType}

                                                                //unique to autocompletes for now (should be this isntead of multiple different types of cells when refactor change over)
                                                                errorable={column.errorable}

                                                                //uidCheckData={props.checkValues}
                                                                uid={column.uid}

                                                                key={`${props.globalGrainOrders[grainIndex].docId} ${column.field}`}
                                                                id={`${props.globalGrainOrders[grainIndex].docId} ${column.field}`}
                                                                data={props.globalGrainOrders[grainIndex][column.field]}
                                                            />
                                                        </ContextMenuTrigger>
                                                    :
                                                    column.columnType === 'date' 
                                                    ?
                                                        <ContextMenuTrigger
                                                            id={'contextMenu'}
                                                            collect={() => { return {...data, column}} }
                                                            renderTag={TableCell}
                                                            attributes={{className: classes.cellBorder}}
                                                        >
                                                            <DataGridTableDatePicker 
                                                                handleCellUpdateCallback={handleCellUpdateCallback}
                                                                //cellClickCallback={cellClickCallback}

                                                                docId={data.docId}

                                                                options={column.options}
                                                                field={column.field}
                                                                type={column.columnType}

                                                                //uidCheckData={props.checkValues}
                                                                uid={column.uid}

                                                                key={`${props.globalGrainOrders[grainIndex].docId} ${column.field}`}
                                                                id={`${props.globalGrainOrders[grainIndex].docId} ${props.columns[columnIndex].field}`}
                                                                data={props.globalGrainOrders[grainIndex][column.field]}
                                                            />
                                                        </ContextMenuTrigger>
                                                    :
                                                    column.columnType === 'typography'
                                                    ?
                                                        <ContextMenuTrigger
                                                            id={'contextMenu'}
                                                            collect={() => { return {...data, column}} }
                                                            renderTag={TableCell}
                                                            attributes={{className: classes.cellBorder}}
                                                        >
                                                            <DataGridTableTypography
                                                                handleCellUpdateCallback={handleCellUpdateCallback}
                                                                //cellClickCallback={cellClickCallback}

                                                                docId={data.docId}

                                                                options={column.options}
                                                                field={column.field}
                                                                type={column.columnType}

                                                                //uidCheckData={props.checkValues}
                                                                uid={column.uid}

                                                                key={`${props.globalGrainOrders[grainIndex].docId} ${column.field}`}
                                                                id={`${props.globalGrainOrders[grainIndex].docId} ${props.columns[columnIndex].field}`}
                                                                data={props.globalGrainOrders[grainIndex][column.field]}
                                                            />
                                                        </ContextMenuTrigger>
                                                    : 
                                                    column.columnType === 'textfield' && column.uid === true   
                                                    ?        
                                                        <ContextMenuTrigger
                                                            id={'contextMenu'}
                                                            collect={() => { return {...data, column}} }
                                                            renderTag={TableCell}
                                                            attributes={{className: classes.cellBorder}}
                                                        >
                                                            <DataGridTableErrorableTextfield 
                                                                handleCellUpdateCallback={handleCellUpdateCallback}
                                                                //cellClickCallback={cellClickCallback}

                                                                docId={data.docId}
                                                                field={column.field}

                                                                //uidCheckData={props.globalGrainOrders}
                                                                uniqueTicketNumber={uniqueTicketNumber}

                                                                uid={column.uid}

                                                                key={`${props.globalGrainOrders[grainIndex].docId} ${column.field}`}
                                                                id={`${props.globalGrainOrders[grainIndex].docId} ${props.columns[columnIndex].field}`}
                                                                data={props.globalGrainOrders[grainIndex][column.field]}
                                                            />
                                                        </ContextMenuTrigger>
                                                    : //column.columnType === 'textfield' && column.uid === false  
                                                        <ContextMenuTrigger
                                                            id={'contextMenu'}
                                                            collect={() => { return {...data, column}} }
                                                            renderTag={TableCell}
                                                            attributes={{className: classes.cellBorder}}
                                                        >
                                                            <DataGridTableTextfield 
                                                                handleCellUpdateCallback={handleCellUpdateCallback}
                                                                //cellClickCallback={cellClickCallback}

                                                                docId={data.docId}

                                                                options={column.options}
                                                                field={column.field}
                                                                type={column.columnType}

                                                                //uidCheckData={props.checkValues}
                                                                uid={column.uid}

                                                                key={`${props.globalGrainOrders[grainIndex].docId} ${column.field}`}
                                                                id={`${props.globalGrainOrders[grainIndex].docId} ${props.columns[columnIndex].field}`}
                                                                data={props.globalGrainOrders[grainIndex][column.field]}
                                                            />
                                                        </ContextMenuTrigger>
                                                    )
                                            })}

                                            <TableCell style={{borderBottom: '1px solid #767676', }} >
                                                <Grid container justifyContent='flex-end'>
                                                    <IconButton 
                                                        size='small'
                                                        onClick={(e) => { props.onActionClick('grains/form', data.docId, {grainOrderData: sortedRows}) }}
                                                    >
                                                        <OpenInNewIcon/>
                                                    </IconButton>
                                                </Grid>
                                            </TableCell>
                                
                                    </TableRow>
                                )
                            })}

                            <DataGridTableNewRow
                                //cellClickCallback={cellClickCallback}
                                columns={props.columns}
                                onNewRow={props.onNewRow}                                   
                                loading={props.loading}
                            />

                        </TableBody>
                    </Table>
                </TableContainer>

                <TablePagination
                    rowsPerPageOptions={[20, 30, 50]}
                    count={filterLength.current || 0}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    component="div"
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />

                <ContextMenu id={'contextMenu'} onShow={(e) => { setContext(e.detail.data) }}>
                    <Paper style={{padding: '2px'}}>
                        <ContextMenuItem onClick={(e, d)=> { handleContextSelectItem(e, d, 'contains') }} >
                            <Typography className={classes.selectableContextItems}>
                                {`Contains "${getCheckValue(context, context?.column?.field, context?.column)}"`}
                            </Typography>
                        </ContextMenuItem>
                        <ContextMenuItem onClick={(e, d)=> { handleContextSelectItem(e, d, 'doesNotContain') }} >
                            <Typography className={classes.selectableContextItems}>
                                {`Doesnt Contain "${getCheckValue(context, context?.column?.field, context?.column)}"`}
                            </Typography>
                        </ContextMenuItem>
                    </Paper>
                </ContextMenu>

            </Paper>
        </Grid>
    )
}

export default DataGridTable;