import React, { useEffect, useState} from 'react';
import { Button, Grid, LinearProgress, withStyles} from '@material-ui/core';
import { firestore, storage } from '../../../../firebase/firebase';
import _ from 'lodash';
const { createWorker } = require('tesseract.js');

// Create styles
const BorderLinearProgress = withStyles((theme) => ({
    root: {
      height: 10,
      borderRadius: 5,
      border: '1px solid white',
    },
    colorPrimary: {
      backgroundColor: 'grey',
    },
    bar: {
      borderRadius: 5,
      backgroundColor: '#FFBF00',
    },
  }))(LinearProgress);

const TesseractImage =  (props) => {
    const [progress, setProgress] = useState(0);
    const [message, setMessage] = useState('');


    useEffect(() => {
        
    }, [progress, props.deliveryData]);


    const performOCR = async () => {
        const worker = await createWorker('eng', 1, {
            logger: (m) => { setProgress(m.progress * 100) }, // Add logger here
        });
        setMessage('Initializing...')
        let pdfList = props.deliveryData?.pdfUrls || [];
        const uploadPromises = [];
        for (const url of props.photoUrls) {
    
            const substring = url.substring(url.indexOf('Deliveries%2F') + 'Deliveries%2F'.length, url.indexOf('?'));
    
            if (pdfList.every(url2 => !url2.includes(substring))) {
                setMessage('Performing OCR on photo...')
                console.log('PDF Not found, beginning OCR process...');
                const { data: { pdf } } = await worker.recognize(url, { pdfTitle: "Delivery Photo OCR" }, { pdf: true });
                setMessage('Uploading converted PDF to Firebase...')
                var file = new Blob([new Uint8Array(pdf)], { type: 'application/pdf' });
    
                const storageRef = storage.ref();
                const pdfRef = storageRef.child(`Deliveries/${props.deliveryData.docId}/${substring}.pdf`);
    
                // Push the promise for upload to the array
                uploadPromises.push(pdfRef.put(file).then((snapshot) => {
                    console.log('Uploaded a blob or file!');
                    return pdfRef.getDownloadURL().then((url3) => {
                        pdfList.push(url3);
                    });
                }));
            } else {
                setMessage('PDF already exists, skipping...')
                console.log('PDF already exists, skipping OCR process...');
            }
        }
    
        // Wait for all uploadPromises to resolve
        await Promise.all(uploadPromises);
    
        setMessage('Updating Firestore...')
        await firestore.collection('deliveries').doc(props.deliveryData.docId).update({
            pdfUrls: pdfList
        });
        setMessage('Finishing OCR Process...')
        await worker.terminate();
        setMessage('OCR Complete.')
    };
    

    //performOCR();
    


  return (
    <Grid container xs={12}>
        <Grid item xs={12} style={{marginBottom:'10px'}}>
            <BorderLinearProgress variant="determinate" value={progress} />
        </Grid>
        <Grid item xs={12} style={{marginBottom:'10px'}}>
            <small>{message}</small>
        </Grid>
        <Grid container xs={12}>
            <Grid item xs={12}>
                <Button variant='contained' onClick={()=>performOCR()}>Perform OCR</Button>
            </Grid>
        </Grid>
        <Grid container xs={12}>
            
                
                    {props.deliveryData?.photoURLs.map((url, index) => {
                        
                        return (
                            <Grid index={index} item xs={3} style={{padding:'10px', marginRight:'14px'}}>
                                <img src={url} alt={'Delivery Photo #'+index+1} style={{width:'200px'}}/>
                                <Button variant="contained"
                                    disabled={_.isUndefined(props.deliveryData.pdfUrls) || !props.deliveryData?.pdfUrls.find(url2 => url2.includes(url.substring(url.indexOf('Deliveries%2F') + 'Deliveries%2F'.length, url.indexOf('?'))))}
                                    onClick={() => {
                                        window.open(//get url from pdfUrls that matches the substring of the photoURL
                                            props.deliveryData?.pdfUrls.find(url2 => url2.includes(url.substring(url.indexOf('Deliveries%2F') + 'Deliveries%2F'.length, url.indexOf('?'))))
                                            
                                            , '_blank');
                                    }}
                                >View OCR PDF</Button>
                            </Grid>
                        )
                    })}
           
        </Grid>
    </Grid>
  );
};

export default TesseractImage;
