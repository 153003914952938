import React, { useEffect } from 'react';
import { Backdrop, Button, Checkbox, Divider, Fade, Grid, IconButton, makeStyles, 
    Modal, Paper, TextField, Typography, Tab, Box } from '@material-ui/core';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';
import VariantTile from './VariantTile';
import { useCoupon } from '../../../providers/CouponsProvider'
import { useAuth } from '../../../providers/AuthContext';
import _ from 'lodash';
import { Alert, TabContext, TabList, TabPanel } from '@material-ui/lab';

const useStyles = makeStyles((theme) => ({
    card: {
        padding: theme.spacing(2),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        height: '100%',
        width: '100%',
        margin: 'auto',
        maxWidth: '1000px',
        [theme.breakpoints.down('xs')]: {
            padding: theme.spacing(1),
        }

    },

    row: {
        display: 'flex',
        alignItems: 'center',
    },

    underline: {
        '&::after': {
            border: '1px solid black'
        }
    }
}));

const VariantCard = (props) => {
    const [newVariants, setNewVariants] = React.useState({});
    const [selectedVariants, setSelectedVariants] = React.useState([]);
    const [variantSet, setNewVariantSet] = React.useState({});
    const [checked, setChecked] = React.useState([]);
    const [remVariants, setRemVariants] = React.useState({});
    const [remVariantIndexes, setRemVariantIndexes] = React.useState([]);
    const [open, setOpen] = React.useState(false);
    const { coupons } = useCoupon();
    const classes = useStyles();
    const { roles } = useAuth();

    const [tabSelected, setTabSelected] = React.useState('active');

    const markAttrForRemoval = (key, attribute) => {
        let updatedVariants = { ...remVariants };

        if (!(key in updatedVariants))
            updatedVariants[key] = [];

        let index = updatedVariants[key].indexOf(attribute);

        if (index === -1)
            updatedVariants[key].push(attribute)
        else {
            updatedVariants[key].splice(index, 1);

            if (updatedVariants[key].length === 0)
                delete updatedVariants[key]
        }

        if (!updatedVariants[key] || (updatedVariants[key] && !_.isEqual(updatedVariants[key].sort(), variantSets[key].sort()))) {
            setRemVariants(updatedVariants);
            updateRemVariantIndexes(updatedVariants);
        }
        else {
            delete updatedVariants[key]
            setRemVariants(updatedVariants);
        }
    }

    const markAttrForAddition = (key, attribute) => {
        let updatedVariants = { ...newVariants }

        if (!variantSets[key].includes(attribute) && !(key in updatedVariants && updatedVariants[key].includes(attribute))) {
            if (!(key in updatedVariants))
                updatedVariants[key] = [];

            updatedVariants[key].push(attribute)
        }

        setNewVariants(updatedVariants);
    }

    const modifyAddedAttributes = (key, attribute) => {
        let updatedVariants = { ...newVariants };

        if (!(key in updatedVariants))
            updatedVariants[key] = [];

        let index = updatedVariants[key].indexOf(attribute);

        if (index === -1)
            updatedVariants[key].push(attribute)
        else {
            updatedVariants[key].splice(index, 1);

            if (updatedVariants[key].length === 0)
                delete updatedVariants[key]
        }

        setNewVariants(updatedVariants);
    }

    const handleOpen = () => {
        setOpen(true);
        initializeSelectedVariants();
    }

    const handleClose = () => {
        setOpen(false);
        setSelectedVariants([]);
    }

    const initializeSelectedVariants = () => {
        let updatedVariants = [];
        let allValues = [];
        let newVariantSets = { ...variantSets };

        Object.keys(newVariantSets).forEach(key => {
            if (newVariants[key])
                newVariantSets[key] = newVariantSets[key].concat(newVariants[key])
        })

        setNewVariantSet(newVariantSets);

        const genPermutations = (args) => {
            let r = [], max = args.length - 1;

            const merge = (arr, i) => {
                for (let j = 0, l = args[i].length; j < l; j++) {
                    let a = arr.slice(0);

                    a.push(args[i][j]);

                    if (i === max)
                        r.push(a);
                    else
                        merge(a, i + 1);
                }
            }
            merge([], 0);

            return r;
        }

        allValues = genPermutations(Object.values(newVariantSets));

        allValues.forEach(val => {
            let newVal = {};

            Object.keys(variantSets).forEach((key, index) => {
                newVal[key] = val[index]
            })

            let compare = props.product.variants.filter(variant => _.isEqual(variant.variantTypes, newVal))

            if (compare.length === 0)
                updatedVariants.push(newVal);
        })

        setChecked(new Array(updatedVariants.length).fill(true, 0, updatedVariants.length));
        setSelectedVariants(updatedVariants);
    }

    const generateNewVariants = () => {

        let generatedVariants = [];

        for (let i = 0; i < checked.length; i++)
            checked[i] && generatedVariants.push(selectedVariants[i]);

        props.createVariant(generatedVariants, variantSet);
        setNewVariants({})
        handleClose();
    }

    const updateRemVariantIndexes = (remVariants) => {

        let newIndexes = []

        props.product.variants.forEach((variant, index) => {
            let variantRemoved = false;

            Object.keys(variant.variantTypes).forEach(key => {
                if (!variantRemoved && remVariants[key] && remVariants[key].includes(variant.variantTypes[key])) {
                    newIndexes.push(index);
                    variantRemoved = true;
                }
            })
        })

        setRemVariantIndexes(newIndexes);
    }

    const handleRemoveVariants = () => {
        let newVariants = [...props.product.variants];

        for (let i = remVariantIndexes.length - 1; i >= 0; i--) {
            newVariants.splice(remVariantIndexes[i], 1);
        }

        props.updateData({ ...props.product, variants: newVariants })
        setRemVariants({});
        setRemVariantIndexes([]);
    }

    const removeVariantType = (key) => {
        let productVariants = [...props.product.variantTypes]
        let variants = [...props.product.variants];

        let keyIndex = productVariants.indexOf(key);

        if (keyIndex !== -1)
            productVariants.splice(keyIndex, 1);

        variants.forEach((variant, index) => {
            delete variant.variantTypes[key];
        })

        variants.forEach((variant, index) => {
            for (let j = variants.length - 1; j > index; j--) {
                if (_.isEqual(variant.variantTypes, variants[j].variantTypes))
                    variants.splice(j, 1)
            }
        })

        props.updateData({ ...props.product, 'variantTypes': productVariants, 'variants': variants })
    }

    let variantSets = {};

    const handleTabChange = (event, newValue) => {
        setTabSelected(newValue);
      };

    props.product.variantTypes.forEach((variantType) => {

        let variantSet = [];

        props.product.variants.forEach((variant) => variantType in variant.variantTypes && variantSet.push(variant.variantTypes[variantType]));

        variantSet = Array.from(new Set(variantSet));

        variantSets[variantType] = variantSet;
    })

    function generateCombinations(variantSets) {
        const keys = Object.keys(variantSets);
        const values = Object.values(variantSets);
    
        function helper(index, currentCombination) {
            if (index === keys.length) {
                combinations.push(currentCombination.slice());
                return;
            }
    
            const currentValues = values[index];
    
            for (let i = 0; i < currentValues.length; i++) {
                currentCombination.push(currentValues[i]);
                helper(index + 1, currentCombination);
                currentCombination.pop();
            }
        }
    
        const combinations = [];
        helper(0, []);
        return combinations;
    }

    useEffect(() => {   
        
    }, [tabSelected])
    
    
    const allCombinations = generateCombinations(variantSets);

    //filter out existing variants but keep them in an Object with the proper keys
    const existingVariantsObj = props.product.variants

    const filteredCombinationsObj = allCombinations.map(combination => {
        let obj = {};
        Object.keys(variantSets).forEach((key, index) => {
            //obj[key] = combination[index];
            
            let resultMap = {};
            Object.keys(variantSets).forEach((key, index) => {
                resultMap[key] = combination[index];
            })

            obj['variantTypes'] = resultMap;
        
            obj['inStock'] = false;
            obj['hidden'] = false;
            obj['photoURL'] = ""
            obj['price'] = 0.00
            obj['shipOption'] = ['Pickup', 'Delivery']
            obj['unit'] = ''
            obj['swatchPhotoURL'] =""
            obj['priceList'] = []

        })
        return obj;
    }).filter(combination => {
        return !existingVariantsObj.some(existingVariant => {
            return _.isEqual(existingVariant.variantTypes, combination.variantTypes)
        })
       
    })

    

    return (
        <React.Fragment>
            <Grid item md={12} xs={12} style={{ marginTop: '16px',  }}>
                <Paper className={classes.card}>
                    <Grid container spacing={2} style={{ display: 'flex', alignItems: 'center', marginBottom: '32px' }}>
                        {
                            props.product.variantTypes
                                .filter((variant) => variant !== "NOVARIANT")
                                .map((variant, index) => {
                                    let key = props.product.variantTypes[index];

                                    return (
                                        <React.Fragment key={index}>
                                            <Grid item xs={3}>
                                                <TextField
                                                    value={key}
                                                    disabled={!roles.includes('Create|Update|Delete Products') }
                                                    label={"Variant " + index}
                                                    variant='outlined'
                                                    fullWidth
                                                    required
                                                    size='small'
                                                    onChange={(event) => {

                                                        //adds variant to variant type
                                                        let variantTypes = [...props.product.variantTypes];
                                                        variantTypes[index] = event.target.value;
                                                        //props.updateData({ ...props.product, 'variantTypes': variantTypes })
                                                        
                                                        // //adding new vairant type in each existing variant? why??
                                                        // props.product.variants.forEach((variant, index2) => {
                                                        //     variantTypes[index] = event.target.value;  
                                                        //     variant.variantTypes[variantTypes[index]] = variant.variantTypes[key]; 
                                                        //     delete variant.variantTypes[key];
                                                        //     //props.updateData({ ...props.product, 'variants': props.product.variants })
                                                        // })
                                                        
                                                        //let variantTypes2 = [...variantTypes];
                                                      
                                                        let uniq = [...new Set(variantTypes)];
                                                        //console.log("uniq", uniq)
                                                       
                                                        props.updateData({ ...props.product, 'variantTypes': uniq })
                                                        //props.updateData({ ...props.product, 'variants': props.product.variants })
                                                    }}
                                                />
                                            </Grid>

                                            <Grid item xs={8}>
                                                <div style={{
                                                    border: '1px solid grey',
                                                    borderColor: '#bdbdbd',
                                                    borderRadius: '4px',
                                                    display: 'flex',
                                                    flexDirection: 'column',
                                                    flexFlow: 'wrap',
                                                    minWidth: '100%'
                                                }}>
                                                    <div>
                                                        {variantSets[key].sort().map((val) => {
                                                            return < Button
                                                                key={val}
                                                                disabled={!_.isEmpty(newVariants) || !roles.includes('Create|Update|Delete Products')}
                                                                style={{ margin: '4px', whiteSpace: 'nowrap', textTransform: 'none' }}
                                                                variant={key in remVariants && remVariants[key].includes(val) ? 'contained' : 'outlined'}
                                                                size='small'
                                                                onClick={() => markAttrForRemoval(key, val)}
                                                            >
                                                                {val}
                                                                <RemoveCircleOutlineIcon style={{ marginLeft: '4px', color: 'black' }} />
                                                            </Button>
                                                        })}
                                                    </div>

                                                    <div>
                                                        {key in newVariants && newVariants[key].sort().map((val) => {
                                                            return < Button
                                                                key={val}
                                                                disabled={!roles.includes('Create|Update|Delete Products')}
                                                                style={{
                                                                    margin: '4px',
                                                                    whiteSpace: 'nowrap',
                                                                    textTransform: 'none',
                                                                    backgroundColor: '#86d761'
                                                                }}
                                                                variant={key in remVariants && remVariants[key].includes(val) ? 'contained' : 'outlined'}
                                                                size='small'
                                                                onClick={() => modifyAddedAttributes(key, val)}
                                                            >
                                                                {val}
                                                                <RemoveCircleOutlineIcon style={{ marginLeft: '4px',  color: 'black' }} />
                                                            </Button>
                                                        })}
                                                    </div>

                                                </div>
                                                <TextField
                                                    disabled={!_.isEmpty(remVariants) || key.trim() === "" || !roles.includes('Create|Update|Delete Products')}
                                                    style={{ marginTop: '16px' }}
                                                    label={`Add new ${key}`}
                                                    onKeyPress={(event) => {
                                                        if (event.key === "Enter") {
                                                            if (event.target.value.trim() !== "") {
                                                                markAttrForAddition(key, event.target.value)
                                                                event.target.value = ""
                                                            }
                                                        }
                                                    }}
                                                    onBlur={(event) => {
                                                        if (event.target.value.trim() !== "") {
                                                            markAttrForAddition(key, event.target.value)
                                                            event.target.value = ""
                                                        }
                                                    }}
                                                    variant='outlined'
                                                    fullWidth
                                                    size='small'
                                                />
                                            </Grid>

                                            <Grid item xs={1}>
                                                <IconButton
                                                    size='small'
                                                    style={{ color: 'black'}}
                                                    disabled={
                                                        (index === 0 && props.product.variantTypes.length === 1) ||
                                                        (props.product.variantTypes.length <= 1 && props.product.variants.length > 1) ||
                                                        (!_.isEmpty(remVariants) || !_.isEmpty(newVariants)) || !roles.includes('Create|Update|Delete Products')}
                                                    onClick={(event) => { removeVariantType(key) }}
                                                >
                                                    <RemoveCircleOutlineIcon />
                                                </IconButton>
                                            </Grid>
                                        </React.Fragment>
                                    )
                                })
                        }

                        {(_.isEmpty(remVariants)) && <React.Fragment>
                            <Grid item xs={props.saveState.variantTypes.length < props.product.variantTypes.length ? 4 : 12} className={classes.row}>
                                <IconButton
                                    style={{ color: 'black'}}
                                    disabled={!roles.includes('Create|Update|Delete Products')}
                                    onClick={(event) => {
                                        let variantTypes = [...props.product.variantTypes];

                                        variantTypes.push("");

                                        props.updateData({ ...props.product, 'variantTypes': variantTypes })
                                    }}
                                >
                                    <AddCircleOutlineIcon />
                                </IconButton>
                                <Typography>Add new Variant Type</Typography>
                            </Grid>
                            {props.saveState.variantTypes.length < props.product.variantTypes.length &&
                                <Grid item xs={8}>
                                    <Alert severity='warning'>Adding a new Variant Type will overwrite old variants.</Alert>
                                </Grid>
                            }
                        </React.Fragment>
                        }

                        {!_.isEmpty(remVariants) &&
                            <React.Fragment>
                                <Grid item xs={12}>
                                    <Alert severity='error'>
                                        {`This action will remove ${remVariantIndexes.length} variants, continue?`}
                                    </Alert>
                                </Grid>

                                <Grid item xs={6} className={classes.row}>
                                    <Button
                                        fullWidth
                                        disabled={!roles.includes('Create|Update|Delete Products')}
                                        variant='outlined'
                                        size='large'
                                        onClick={() => setRemVariants({})}
                                    >
                                        Cancel
                                </Button>
                                </Grid>

                                <Grid item xs={6} className={classes.row}>
                                    <Button
                                        fullWidth
                                        disabled={!roles.includes('Create|Update|Delete Products')}
                                        variant='contained'
                                        size='large'
                                        onClick={() => handleRemoveVariants()}
                                    >
                                        Remove Variants
                        </Button>
                                </Grid>
                            </React.Fragment>}

                        {!_.isEmpty(newVariants) &&
                            <React.Fragment>
                                <Grid item xs={6} className={classes.row}>
                                    <Button
                                        fullWidth
                                        variant='outlined'
                                        disabled={!roles.includes('Create|Update|Delete Products')}
                                        size='large'
                                        onClick={() => setNewVariants({})}
                                    >
                                        Cancel
                                    </Button>
                                </Grid>

                                <Grid item xs={6} className={classes.row}>
                                    <Button
                                        fullWidth
                                        variant='contained'
                                        disabled={!roles.includes('Create|Update|Delete Products')}
                                        size='large'
                                        onClick={() => handleOpen(true)}
                                    >
                                        Create Variants
                                    </Button>
                                </Grid>
                            </React.Fragment>}

                        <Grid item xs={12}>
                            <Divider />
                            <Divider />
                            <Divider />
                        </Grid>
                    </Grid>

                    <Grid item sm={12} xs={12} className={classes.table}>
                    <TabContext value={tabSelected}>
                        <Box sx={{ borderBottom: 1, borderColor: 'divider', minWidth:'1000px'}}>
                        <TabList onChange={handleTabChange}>
                            <Tab label={"Active Variants"} value="active" />
                            <Tab label={"Unused Variants"} disabled={filteredCombinationsObj.length <= 0 } value="unused" />
                        </TabList>
                        </Box>
                        <TabPanel value="active">
                        <Grid container spacing={2}>
                        {
                            props.product.variants.map((variant, index) => {
                                return (
                                    <VariantTile
                                        key={index}
                                        index={index}
                                        variant={variant}
                                        coupons={coupons}
                                        product={props.product}
                                        updateData={props.updateData}
                                        saveState={props.saveState} />
                                )
                            })
                        }
                        </Grid>
                        </TabPanel>
                        <TabPanel value="unused">
                        <Grid container spacing={2}>
                        {
                            filteredCombinationsObj.length > 0 && filteredCombinationsObj.map((variant, index) => {
                                return (
                                    <VariantTile
                                        key={index}
                                        index={index}
                                        variant={variant}
                                        coupons={coupons}
                                        product={props.product}
                                        updateData={props.updateData}
                                        saveState={props.saveState} />
                                )
                            })
                        }
                    </Grid>
                        </TabPanel>
                    </TabContext>
                    </Grid>

                </Paper>
            </Grid >

            <Modal
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    outline: 0,
                }}
                open={open}
                onClose={() => { setOpen(false) }}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}>
                <Fade in={open}>
                    <Paper style={{ maxWidth: '400px', padding: '16px', maxHeight: '80vh', overflowY: 'auto' }}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Typography variant='h6'>Select which variants to create.</Typography>
                            </Grid>
                            <Grid item xs={12}>
                                {selectedVariants.map((variant, index) => {
                                    return <Grid item style={{ display: 'flex', alignItems: 'center' }}>
                                        <Checkbox
                                            checked={checked[index]}
                                            onClick={() => {
                                                let newChecked = [...checked];
                                                newChecked[index] = !newChecked[index];
                                                setChecked(newChecked);
                                            }}
                                        />
                                        <Typography>{Object.values(variant).join(", ")}</Typography>
                                    </Grid>
                                })}
                            </Grid>
                            <Grid item xs={6}>
                                <Button
                                    fullWidth
                                    variant='outlined'
                                    onClick={() => handleClose()}>
                                    Cancel
                                    </Button>
                            </Grid>
                            <Grid item xs={6}>
                                <Button
                                    fullWidth
                                    variant='contained'
                                    onClick={() => generateNewVariants()}>
                                    Create
                                </Button>
                            </Grid>
                        </Grid>
                    </Paper>
                </Fade>
            </Modal>
        </React.Fragment >
    )
}

export default VariantCard;