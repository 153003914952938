import React from 'react';
import { Button, makeStyles } from '@material-ui/core';
import { PDFViewer, Font, Page, Text, View, Document, StyleSheet } from '@react-pdf/renderer';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { withRouter } from 'react-router-dom';
import moment from 'moment';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        justifyContent: 'center',
        width: '100%',
    },
    content: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        maxWidth: '1200px',
        margin: 'auto',
    },
    buttonRow: {
        marginBottom: '20px',
        marginTop: '20px',
    },
    checkRow: {
        margin: theme.spacing(1),
        display: 'flex',
        justifyContent: 'space-evenly',
    },
}));

Font.register({
    family: 'Oswald',
    src: 'https://fonts.gstatic.com/s/oswald/v13/Y_TKV6o8WovbUd3m_X9aAA.ttf'
});

// Create styles
const styles = StyleSheet.create({
    page: {
        flexDirection: 'column',
        fontFamily: 'Helvetica',
        padding: 20,
    },
    section: {
        marginBottom: 10,
    },
    dTable: {
        display: "table",
        width: "auto",
        border: '1 solid black',

        marginBottom: 10,
    },
    dTableRow: {
        margin: "auto",
        flexDirection: "row"
    },
    dTableCol: {
        flexGrow: 1,
    },
    dTableCell: {
        margin: "auto",
        marginTop: 5,
        marginBottom: 5,
        fontSize: 10,
    },
    table: {
        border: '1 solid black',
        marginBottom: 10,
        padding: 10,
        flexGrow: 1,
        fontSize: 14,
        flexDirection: 'column',
        justifyContent: 'space-between',
    },
    subTable: {
        marginBottom: 10,
        flexGrow: 1,
        fontSize: 14,
        flexDirection: 'column',
        justifyContent: 'space-between',
    },
    tableRow: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginTop: 2,
        marginBottom: 2,
    },
    tableText: {
        fontSize: 12,
        marginBottom: '1px',
    },
    tableTextSmall: {
        fontSize: 10,
    },
    tableTextBold: {
        fontSize: 12,
        fontFamily: 'Helvetica-Bold',
        fontWeight: 'bold',
        marginBottom: '10px',
    },
    tableTextBoldUnderline: {
        fontSize: 12,
        fontFamily: 'Helvetica-Bold',
        fontWeight: 'bold',
        textDecoration: 'underline',
        marginBottom: '10px',
    },
    summary: {
        flexDirection: 'row',
    },
    viewer: {
        width: '100%',
        maxWidth: '1200px',
        height: '85vh',
    },
    tireRect: {
        width: '80px',
        height: '50px',
        margin: '0px 5px 5px 5px',
        border: '1 solid black',
        textAlign: 'center',
    },
    spacerRect: {
        width: '80px',
        height: '50px',
        margin: '0px 5px 5px 5px',
    },
    vehicleBackTableRow: {
        flexDirection: "row",
        display: 'flex',
        justifyContent: 'center',
    },
    vehicleFrontTableRow: {
        flexDirection: "row",
        display: 'flex',
        justifyContent: 'flex-start',
    },
    vehicleBackTireContainer: {
        position: 'relative',
        left: '50px',
    },
    vehicleFrontTireContainer: {
        position: 'relative',
        left: '100px',
    },
    divider: {
        borderTop: '1 solid black',
        margin: '10px 0px 5px 0px'
    },
    checkbox: {
        width: '10px',
        height: '10px',
        background: 'white',
        border: '1 solid black',
      },
      radio: {
        width: '10px',
        height: '10px',
        background: 'white',
        borderRadius: '50%',
        border: '1 solid black',
      },
      userEntered: {
        width: '200px',
        height: '20px',
        background: 'white',
        border: '1 solid black',
      },
});

const ChecklistPDF = (props) => {

    const classes = useStyles();
    /**
     * @param title - Table Header
     * @param columns - Generates table column header and its internal data. Can pass either an objects key to iterate or a function.
     * @param data - The table data passed (as an array of objects)
     * @param components - React components passed as special rendering for additional rows 
    */

    return (
        <div className={classes.root}>
            <div className={classes.content}>

                <div className={classes.buttonRow}>
                    <Button 
                        variant='contained'
                        onClick={()=> { props.history.goBack() }}
                        startIcon={<ArrowBackIcon />}
                        >
                            Back
                        </Button>
                </div>

                <PDFViewer style={styles.viewer}>
                    <Document>
                        <Page size="A4" style={styles.page} wrap>  

                            <View style={{...styles.tableRow, margin:'4px'}}>
                                <Text style={{...styles.tableText, maxWidth:'450px', fontSize: '18px'}}>{props.checklist.title}</Text>
                                <Text style={styles.tableText}>{moment().format('MMMM do, YYYY')}</Text>
                            </View>



                            {/* description here? */}

                                <View style={{flexDirection: 'column', }}>
                                    {Object.keys(props.checklist.items).sort((a, b) => { return props.checklist.items[a].position > props.checklist.items[b].position ? 1 : -1 }).map((key, index) => {
                                        return(
                                            <View style={{ ...styles.table, marginRight: 0 }}>
                                                <View style={styles.tableRow}>
                                                    <Text style={{...styles.tableTextBoldUnderline, maxWidth:'300px', }}>{key}</Text>

                                                    {props.checklist.items[key].type === 'Radio Button' ?
                                                    <Text style={styles.tableText}>Select or enter only one of the following</Text>
                                                    :
                                                    <Text style={styles.tableText}>Select or enter all that apply</Text>
                                                    }
                                                </View>
                             
                                                {props.checklist.items[key].items.map((item, index) => {  
                                                    return (
                                                        <View style={styles.summary} wrap={false}>
                                                            { item.style !== 'photoUpload' ?
                                                            <View style={{ ...styles.subTable, marginRight: 0 }}>
                                                                <View style={styles.tableRow}>
                                                                    <Text style={{...styles.tableText, maxWidth: '300px'}}>{item.name}</Text>
                                                                    
                                                                    {(item.style === 'default' && props.checklist.items[key].type === 'Checkbox') ?
                                                                        <Text style={styles.checkbox}></Text>
                                                                    :
                                                                        (item.style === 'default' && props.checklist.items[key].type === 'Radio Button') ?
                                                                        <Text style={styles.radio}></Text>
                                                                        :
                                                                            <Text style={styles.userEntered}></Text>
                                                                    }
                                                                </View>
                                                            </View>
                                                            : null
                                                            }
                                                        </View>
                                                        )
                                                    })
                                                }  
                                            </View>
                                        )
                                    })
                                    }
                                </View>                             
                        </Page>
                    </Document>
                </PDFViewer>
            </div>
        </div>
    )
};

export default withRouter(ChecklistPDF);