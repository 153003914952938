import React from 'react';
import { Button, Checkbox, FormControlLabel, makeStyles } from '@material-ui/core';
import { PDFViewer, Font, Page, Text, View, Document, StyleSheet } from '@react-pdf/renderer';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { withRouter } from 'react-router';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        justifyContent: 'center',
        width: '100%',
    },
    content: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        maxWidth: '1200px',
        margin: 'auto',
    },
    buttonRow: {
        marginBottom: '20px',
        marginTop: '20px',
    },
    checkRow: {
        margin: theme.spacing(1),
        display: 'flex',
        justifyContent: 'space-evenly',
    },
}));

Font.register({
    family: 'Oswald',
    src: 'https://fonts.gstatic.com/s/oswald/v13/Y_TKV6o8WovbUd3m_X9aAA.ttf'
});

// Create styles
const styles = StyleSheet.create({
    page: {
        flexDirection: 'column',
        fontFamily: 'Helvetica',
        padding: 20,
    },
    section: {
        marginBottom: 10,
    },
    dTable: {
        display: "table",
        width: "auto",
        border: '1 solid black',

        marginBottom: 10,
    },
    dTableRow: {
        margin: "auto",
        flexDirection: "row"
    },
    dTableCol: {
        flexGrow: 1,
    },
    dTableCell: {
        margin: "auto",
        marginTop: 5,
        marginBottom: 5,
        fontSize: 10,
    },
    table: {
        border: '1 solid black',
        marginBottom: 10,
        padding: 10,
        flexGrow: 1,
        fontSize: 14,
        flexDirection: 'column',
        justifyContent: 'space-between',
    },
    tableRow: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginTop: 2,
        marginBottom: 2,
    },
    tableText: {
        fontSize: 12,
    },
    tableTextSmall: {
        fontSize: 10,
    },
    tableTextBold: {
        fontSize: 12,
        fontFamily: 'Helvetica-Bold',
        fontWeight: 'bold',
    },
    tableTextItalic: {
        fontSize: 12,
        fontStyle: 'italic'
    },
    summary: {
        flexDirection: 'row',
    },
    viewer: {
        width: '100%',
        maxWidth: '1200px',
        height: '85vh',
    },
});

// Create Document Component
const CustomerPDF = (props) => {
    const [showCommunication, setShowCommunication] = React.useState(true);
    const [showNotes, setShowNotes] = React.useState(true);


    const classes = useStyles();

    /**
     * @param title - Table Header
     * @param columns - Generates table column header and its internal data. Can pass either an objects key to iterate or a function.
     * @param data - The table data passed (as an array of objects)
     * @param components - React components passed as special rendering for additional rows 
     */
    const Table = (props) => {

        let columnWidth = 100 / props.columns.length + '%';

        return (
            <View style={styles.dTable}>
                <Text style={styles.dTableRow}>{props.title}</Text>

                <View style={styles.dTableRow}>
                    {props.columns.map((column, index) => {
                        return (
                            <View style={{ ...styles.dTableCol, width: columnWidth }} key={index}>
                                <Text style={{ ...styles.dTableCell, fontSize: 12 }}>{column.title}</Text>
                            </View>
                        )
                    })}
                </View>

                {props.data.map((data, index) => {
                    return (
                        <View key={'tablerow' + props.title + index}>
                            <View style={styles.dTableRow} wrap={false}>
                                {props.columns.map((column, index) => {

                                    //Use render function if exists, otherwise render data.
                                    let cellData = column.render ? column.render(data) : data[column.field];

                                    return (
                                        <View style={{ ...styles.dTableCol, width: columnWidth }} key={index}>
                                            <Text style={styles.dTableCell}>{cellData}</Text>
                                        </View>
                                    )
                                })}
                            </View>
                        </View>
                    )
                })}
            </View>
        )
    }

    return (
        <div className={classes.root}>
            <div className={classes.content}>

                <div className={classes.buttonRow}>
                    <Button 
                        variant='contained' 
                        onClick={()=> {props.history.goBack()}}
                        startIcon={<ArrowBackIcon />}
                    >
                        Back
                    </Button>
                </div>
                <div className={classes.checkRow}>
                    <FormControlLabel
                        control={<Checkbox checked={showCommunication} onChange={() => setShowCommunication(!showCommunication)} />}
                        label="Show Contacts" />
                    <FormControlLabel
                        control={<Checkbox checked={showNotes} onChange={() => setShowNotes(!showNotes)} />}
                        label="Show Notes" />
                </div>

                <PDFViewer style={styles.viewer}>
                    <Document>
                        <Page size="A4" style={styles.page} wrap>

                            <View style={styles.section}>
                                <Text>{props.customerData.name}</Text>
                                <Text style={styles.tableTextItalic}>{props.customerData.website}</Text>
                            </View>
                            
                            <View style={{ ...styles.tableRow, marginRight: 8 }}>
                                <Text style={styles.tableText}></Text>
                                <Text style={styles.tableText}>{props.customerData.customerType ? props.customerData.customerType : ""}</Text>
                            </View>

                            <View style={styles.summary} wrap={false}>
                                <View style={{ ...styles.table, marginRight: 0 }}>
                                    <View style={styles.tableRow}>
                                        <Text style={styles.tableTextBold}>GST Registration Number</Text>
                                        <Text style={styles.tableText}>{props.customerData.gstRegNum === '' ? "N/A" : props.customerData.gstRegNum}</Text>
                                    </View>
                                    <View style={styles.tableRow}>
                                        <Text style={styles.tableTextBold}>Retail Sales Tax Number</Text>
                                        <Text style={styles.tableText}>{props.customerData.retailSalesTaxNum === '' ? "N/A" : props.customerData.retailSalesTaxNum}</Text>
                                    </View>
                                </View>
                            </View>

                            <View style={styles.summary} wrap={false}>
                                <View style={{ ...styles.table, marginRight: 0 }}>
                                    <View style={styles.tableRow}>
                                        <Text style={styles.tableTextBold}>Customer Details</Text>
                                    </View>
                                    <View style={styles.tableRow}>
                                        <Text style={styles.tableText}>Name</Text>
                                        <Text style={styles.tableText}>{props.customerData.name}</Text>
                                    </View>
                                    <View style={styles.tableRow}>
                                        <Text style={styles.tableText}>Mailing Address   </Text>
                                        <Text style={styles.tableText}>{props.customerData.streetNumber} {props.customerData.streetName}</Text>
                                    </View>
                                    <View style={styles.tableRow}>
                                        <Text style={styles.tableText}></Text>
                                        <Text style={styles.tableText}>{props.customerData.city}, {props.customerData.province}</Text>
                                    </View>
                                    <View style={styles.tableRow}>
                                        <Text style={styles.tableText}></Text>
                                        <Text style={styles.tableText}>{props.customerData.postalCode ? props.customerData.postalCode : ""}</Text>
                                    </View>
                                    <View style={styles.tableRow}>
                                        <Text style={styles.tableText}>Delivery Address   </Text>
                                        <Text style={styles.tableText}>{props.customerData.deliveryAddress.streetNumber} {props.customerData.deliveryAddress.streetName}</Text>
                                    </View>
                                    <View style={styles.tableRow}>
                                        <Text style={styles.tableText}></Text>
                                        <Text style={styles.tableText}>{props.customerData.deliveryAddress.city}, {props.customerData.deliveryAddress.province}</Text>
                                    </View>
                                    <View style={styles.tableRow}>
                                        <Text style={styles.tableText}></Text>
                                        <Text style={styles.tableText}>{props.customerData.deliveryAddress.postalCode ? props.customerData.deliveryAddress.postalCode : ""}</Text>
                                    </View>
                                    <View style={styles.summary} wrap={false}>
                                    
                                    </View>
                                    
                                </View>
                                

                            </View>
                            
                      

                            
                            {showCommunication && props.customerData.contacts
                                ? <Table
                                    columns={[
                                        { title: 'Phone', field: 'phone' },
                                        { title: 'Description', field: 'description' },
                                        { title: 'Contact', field: 'name' },
                                        { title: 'Email Address', field: 'email' },
                                    ]}
                                    data={props.customerData.contacts ? props.customerData.contacts : null}
                                    title={"Contacts"} />
                                : null}
                            {showNotes ? <View style={styles.summary} wrap={false}>
                                <View style={{ ...styles.table }}>
                                    
                                    
                                        <View style={{ ...styles.dTableCol, width: '100%', backgroundColor: '#ddd' }}>
                                            <Text style={{ ...styles.dTableCell, justifyContent: 'center' }}>Customer Notes</Text>
                                            <Text style={{ ...styles.dTableCell, width: '100%' }}>{props.customerData.notes}</Text>
                                        </View>
                                        
                                </View>

                            </View>: null}

                           
                        </Page>

                    </Document>
                </PDFViewer>
            </div>
        </div>
    )
};

export default withRouter(CustomerPDF);