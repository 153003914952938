import React, { useState, useEffect } from 'react';
import { Card, CardActionArea, CardContent, CardMedia, Checkbox, FormControlLabel, MenuItem,
     Grid, IconButton, InputAdornment, TextField, Typography, makeStyles, Button } from '@material-ui/core';
import AssessmentOutlinedIcon from '@material-ui/icons/AssessmentOutlined';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import { isUndefined } from 'lodash';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import DeleteIcon from '@material-ui/icons/Delete';
import amber from '@material-ui/core/colors/amber';
import PriceBuilder from './PriceBuilder';
import CouponSelector from './CouponSelector';
import { useAuth } from '../../../providers/AuthContext';
import AddCircleIcon from '@material-ui/icons/AddCircle';

const useStyles = makeStyles((theme) => ({
    imageSwatch: {
        display: 'flex',
        position: 'absolute',
        right: 0,
        bottom: 0,
        zIndex: 100,
        width: '64px',
        height: '64px',
        border: '2px solid gray',
        borderRadius: '128px',
        backgroundColor: 'white',
        textAlign: 'center',
    },

    photo: {
        height: '150px',
        padding: '0px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'relative',
    },
}));

const VariantTile = (props) => {

    const classes = useStyles();
    const [variant, setVariant] = useState({ ...props.variant });
    const [showTile, setShowTile] = useState(false);
    const [openCoupons, setOpenCoupons] = React.useState(false);


    const { roles } = useAuth();
    const handleOpenCoupons = () => {
        setOpenCoupons(true);
    };

    const handleCloseCoupons = () => {
        setOpenCoupons(false);
    };
    useEffect(() => {
        setVariant(props.variant)
    }, [props.variant])

    const unitList = [
        'Each',
        'Bag',
        'Yard',
        'Pallet',
        'Trailer Load',
        'Tandem Load',
        'FT',
    ];

    //check if variant exists in props.product.variants
    function variantExists(variant) {
        return  props.product.variants.some((v) => v.variantTypes === variant.variantTypes);
    }


    return (
        <Grid container spacing={2} style={{ marginBottom: '16px' }}>
            <CouponSelector
                open={openCoupons}
                close={handleCloseCoupons}
                products={variant}
                coupons={props.coupons}
                updateData={props.updateData} />
            <Grid item xs={1} style={{ backgroundColor: variantExists(variant)?amber[700]:'grey', borderTopLeftRadius: '24px', color:'black' }}>
                <IconButton
                    style={{ color: 'black'}}
                    //disabled={!variantExists(variant)}
                    onClick={() => {
                        setShowTile(!showTile);
                    }}>
                    {!showTile ? <KeyboardArrowRightIcon style={{ color: 'black'}} /> : <KeyboardArrowDownIcon style={{ color: 'black'}}/>}
                </IconButton>
            </Grid>
            
            <Grid item xs={10} style={{ backgroundColor: variantExists(variant)?amber[600]:'#9c9c9c', paddingLeft: '16px', display: 'flex' }}>
                {props.product.variantTypes.length > 0
                    ? <Grid container spacing={1} >
                        {props.product.variantTypes.map((variantType, index2) => {
                            return (
                                <Grid item md={3} xs={3} className={classes.row} key={index2}>
                                    {variantType === "NOVARIANT"
                                        ? <Typography style={{ color: 'black'}}>Default</Typography>
                                        : variant.variantTypes[variantType] && <React.Fragment>
                                            <Typography style={{ color: 'black'}}>{variantType}:</Typography>
                                            <Typography style={{ color: 'black'}}>{variant.variantTypes[variantType]}</Typography>
                                        </React.Fragment>
                                    }
                                </Grid>
                            )
                        })}
                    </Grid>
                    : null}
            </Grid>

            <Grid item xs={1} style={{ backgroundColor: variantExists(variant)?amber[700]:'grey', borderTopRightRadius: '24px' }}>
                <IconButton
                    style={{ color: 'black'}}
                    disabled={props.product.variants.length <= 1 || !roles.includes('Create|Update|Delete Products')}
                    onClick={() => {
                        if(variantExists(variant)){
                            let variants = [...props.product.variants];
                            variants.splice(props.index, 1);

                            props.updateData({ ...props.product, 'variants': variants })
                        }
                        else {
                            let variants = [...props.product.variants];
                            variants.push(variant);
                            props.updateData({ ...props.product, 'variants': variants })
                        }
                    }}>
                    {!variantExists(variant)?<AddCircleIcon style={{ color: 'black'}}/>:<DeleteIcon style={{ color: 'black'}}/>}
                </IconButton>
            </Grid>

            {showTile &&
                <Grid
                    item
                    container
                    xs={12}
                    style={{
                        boxShadow: 'inset 0px 2px 4px 1px rgba(194,194,194,0.59)',
                        marginBottom: '16px',
                        //backgroundColor: '#f9f8f6',
                        borderBottomLeftRadius: '4px',
                        borderBottomRightRadius: '4px',
                        padding: '16px',
                    }}
                >

                    <Grid item sm={3}>
                        <Card className={classes.photo}>
                            <CardActionArea className={classes.photo} style={{ display: 'initial' }} onClick={() => document.getElementById('upload-photo' + props.index).click()/*Find an alternative*/}>
                                <CardContent className={classes.photo}>
                                    <input
                                        style={{ display: 'none' }}
                                        type="file"
                                        disabled={!roles.includes('Create|Update|Delete Products') || !variantExists(variant)}
                                        id={"upload-photo" + props.index}
                                        accept="image/*"
                                        onChange={(event) => {
                                            let changedVariants = [...props.product.variants];
                                            changedVariants[props.index].photoURL = event.target.files[0];

                                            props.updateData({ ...props.product, 'variants': changedVariants })
                                        }
                                        } />
                                    {props.variant.photoURL === ''
                                        ? <Typography>Variant Image</Typography>
                                        : <img alt="" src={!isUndefined(props.variant.photoURL) && props.variant.photoURL instanceof File ? URL.createObjectURL(props.variant.photoURL) : props.variant.photoURL} className={classes.image} />
                                    }

                                </CardContent>
                            </CardActionArea>
                            <CardActionArea className={classes.imageSwatch} onClick={() => document.getElementById('upload-swatchphoto' + props.index).click()/*Find an alternative*/}>
                                <CardContent style={{ padding: 0 }}>
                                    <input
                                        style={{ display: 'none' }}
                                        type="file"
                                        disabled={!roles.includes('Create|Update|Delete Products') || !variantExists(variant)}
                                        id={"upload-swatchphoto" + props.index}
                                        accept="image/*"
                                        onChange={(event) => {
                                            let changedVariants = [...props.product.variants];
                                            changedVariants[props.index].swatchPhotoURL = event.target.files[0];

                                            props.updateData({ ...props.product, 'variants': changedVariants })
                                        }
                                        } />
                                    <CardMedia
                                        style={{ height: '100%', width: '100%', borderRadius: '128px' }}
                                        component={isUndefined(props.variant.swatchPhotoURL) || props.variant.swatchPhotoURL === "" ? 'div' : 'img'}
                                        image={!isUndefined(props.variant.swatchPhotoURL) && props.variant.swatchPhotoURL instanceof File ? URL.createObjectURL(props.variant.swatchPhotoURL) : props.variant.swatchPhotoURL}>
                                        {isUndefined(props.variant.swatchPhotoURL) || props.variant.swatchPhotoURL === ""
                                            ? <p>Add Swatch</p>
                                            : null}
                                    </CardMedia>
                                </CardContent>
                            </CardActionArea>
                        </Card>
                    </Grid>

                    <Grid item md={3} style={{ display: 'flex', flexDirection: 'column', paddingLeft: '16px' }}>
                        <Grid item md={12} style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                            <Typography>Price</Typography>
                            <TextField
                                type="number"
                                disabled={variant.price === -1 || !roles.includes('Create|Update|Delete Products')}
                                value={variant.price}
                                error={isNaN(Number.parseFloat(variant.price))}
                                fullWidth
                                required
                                onChange={(event) => setVariant({ ...variant, 'price': parseFloat(event.target.value) })}
                                onBlur={(event) => {
                                    let changedVariants = [...props.product.variants];
                                    changedVariants[props.index].price = variant.price;
                                    props.updateData({ ...props.product, 'variants': changedVariants })
                                }}
                                inputProps={{
                                    step: 0.01
                                }}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <AttachMoneyIcon />
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </Grid>

                        <Grid item md={12} style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                            <Typography>Unit</Typography>
                            <TextField //style={{width:'100%'}}
                            value={variant.unit}
                            fullWidth
                            select
                            required
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <AssessmentOutlinedIcon />
                                    </InputAdornment>
                                ),
                            }}
                            disabled={!roles.includes('Create|Update|Delete Products')}
                            onChange={(event) => {
                                setVariant({ ...variant, 'unit': event.target.value })
                            }}
                            onBlur={(event) => {
                                let changedVariants = [...props.product.variants];
                                changedVariants[props.index].unit = variant.unit;
                                props.updateData({ ...props.product, 'variants': changedVariants })
                            }}
                            >   
                                
                                <MenuItem value={variant.unit}>
                                    {variant.unit}
                                </MenuItem>
                               
                              
                                {unitList.map((unit, index) => {
                                    return (
                                        <MenuItem key={index} value={unit}>{unit}</MenuItem>
                                    )
                                }
                                )}
                            </TextField>
                            {/*<TextField
                                value={variant.unit}
                                fullWidth
                                disabled={!roles.includes('Create|Update|Delete Products')}
                                onChange={(event) => {
                                    setVariant({ ...variant, 'unit': event.target.value })
                                }}
                                onBlur={(event) => {
                                    let changedVariants = [...props.product.variants];
                                    changedVariants[props.index].unit = variant.unit;
                                    props.updateData({ ...props.product, 'variants': changedVariants })
                                }}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <AssessmentOutlinedIcon />
                                        </InputAdornment>
                                    ),
                                }}
                            />*/}
                        </Grid>
                    </Grid>

                    <Grid item md={3} style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', justifyContent: 'center', paddingLeft: "24px" }}>
                        <FormControlLabel
                            label='Call for Price'
                            labelPlacement='end'
                            disabled={!roles.includes('Create|Update|Delete Products')}
                            control={
                                <Checkbox
                                    checked={variant.price === -1}
                                    onClick={(event) => {
                                        setVariant({ ...variant, 'price': event.target.checked ? -1 : 0 })
                                        let changedVariants = [...props.product.variants];
                                        changedVariants[props.index].price = event.target.checked ? -1 : 0;
                                        props.updateData({ ...props.product, 'variants': changedVariants })
                                    }
                                    }
                                />}
                        />
                        <FormControlLabel
                            label='In Stock'
                            disabled={!roles.includes('Create|Update|Delete Products')}
                            labelPlacement='end'
                            control={
                                <Checkbox
                                    checked={variant.inStock}
                                    onClick={(event) => {
                                        setVariant({ ...variant, 'inStock': event.target.checked })
                                        let changedVariants = [...props.product.variants];
                                        changedVariants[props.index].inStock = event.target.checked;
                                        props.updateData({ ...props.product, 'variants': changedVariants })
                                    }
                                    }
                                />}
                        />
                        <FormControlLabel
                            label='Hide On Store'
                            disabled={!roles.includes('Create|Update|Delete Products')}
                            labelPlacement='end'
                            control={
                                <Checkbox
                                    checked={variant.hidden}
                                    onClick={(event) => {
                                        setVariant({ ...variant, 'hidden': event.target.checked })
                                        let changedVariants = [...props.product.variants];
                                        changedVariants[props.index].hidden = event.target.checked;
                                        props.updateData({ ...props.product, 'variants': changedVariants })
                                    }
                                    }
                                />}
                        />
                    </Grid>

                    <Grid item md={3} style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', paddingLeft: '32px' }}>
                        <Typography>Shipping Options</Typography>
                        <FormControlLabel
                            label='Pickup'
                            disabled={!roles.includes('Create|Update|Delete Products')}
                            control={
                                <Checkbox
                                    checked={variant.shipOption.includes('Pickup')}
                                    onClick={() => {
                                        let shipOptions = [...variant.shipOption]
                                        shipOptions.includes("Pickup")
                                            ? shipOptions.splice(shipOptions.indexOf("Pickup"), 1)
                                            : shipOptions.push("Pickup")
                                        setVariant({ ...variant, 'shipOption': shipOptions })

                                        let changedVariants = [...props.product.variants];
                                        changedVariants[props.index].shipOption = shipOptions;
                                        props.updateData({ ...props.product, 'variants': changedVariants })
                                    }}
                                />}
                        />
                        <FormControlLabel
                            label='Delivery'
                            disabled={!roles.includes('Create|Update|Delete Products')}
                            control={
                                <Checkbox
                                    checked={variant.shipOption.includes('Delivery')}
                                    onClick={() => {
                                        let shipOptions = [...variant.shipOption]
                                        shipOptions.includes("Delivery")
                                            ? shipOptions.splice(shipOptions.indexOf("Delivery"), 1)
                                            : shipOptions.push("Delivery")
                                        setVariant({ ...variant, 'shipOption': shipOptions })

                                        let changedVariants = [...props.product.variants];
                                        changedVariants[props.index].shipOption = shipOptions;
                                        props.updateData({ ...props.product, 'variants': changedVariants })
                                    }
                                    }
                                />}
                        />
                        <Button variant="contained" color="primary" disabled={!roles.includes('Create|Update|Delete Products') || !variantExists(variant)}
                        onClick={() => {
                            handleOpenCoupons()
                        }}>Manage Coupons</Button>
                    </Grid>

                    <Grid container>
                        <Grid item md={8} style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                            <PriceBuilder
                                title='Additional Prices'
                                labelKey="Unit"
                                labelValue="Price"
                                unitList={unitList}
                                value={props.variant.priceList}
                                setValue={(value) => {
                                    let changedVariants = [...props.product.variants];
                                    changedVariants[props.index].priceList = value;
                                    props.updateData({ ...props.product, 'variants': changedVariants })
                                }} />
                        </Grid>
                    </Grid>
                </Grid >
            }
        </Grid>
    )
}

export default VariantTile;