import React, { useContext, useState } from "react"
import { firestore } from "../firebase/firebase"

const UserContext = React.createContext()

export function useUser() {
  return useContext(UserContext);
}

export function UserProvider({ children }) {

  const [users, setUsers] = useState([]);
  const [usersLoading, setUsersLoading] = useState(true);
  const [error, setError] = useState('');

  function getUsers() {
    //Check if customers exist on local storage

      //Query customer collection for all docs
      firestore.collection("users").get()
        .then(querySnapshot => {
          const userResponse = querySnapshot.docs.map((doc) => {
            return {
              ...doc.data(),
              'docId': doc.id,
            }
          })

          setUsers(userResponse, setUsersLoading(false));
        },
          error => {
            setUsersLoading(false);
            setError(error.message);
            console.log(error.message);
          }
        )
    
 

    //console.log("Units exist on local storage")
  }

  function getUser(uuid) {
    return users?.filter(user => user.uuid === uuid)
  }

  const value = {
    users,
    usersLoading,
    error,
    getUsers,
    getUser,
  }

  return (
    <UserContext.Provider value={value}>
      {children}
    </UserContext.Provider>
  )
}