import React from 'react';
import { Typography } from '@material-ui/core'
import Skeleton from '@material-ui/lab/Skeleton'

const PaperTitle = (props) => {
    const darkMode = JSON.parse(window.localStorage.getItem('colorMode')) === 'dark' ? true : false;
    return (
        <Typography variant="h4" style={{ color: darkMode?'#f0f0f0':'#707070' }}>
            {props.loading ? <Skeleton width={props.width} /> : props.title}
        </Typography>
    )
}
export default PaperTitle