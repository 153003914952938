import React, {useState, useRef} from 'react';
import { makeStyles, Select, Modal, Paper } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import DatePicker from '../../UI/DatePicker/DatePicker';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Autocomplete } from '@material-ui/lab';
import Checkbox from '@material-ui/core/Checkbox';
import { FormControlLabel, FormGroup } from '@material-ui/core';
import { Circle, GoogleMap, DrawingManager, useLoadScript  } from '@react-google-maps/api';

const libraries = ['drawing'];

const useStyles = makeStyles((theme) => ({
    detailsPanel: {
        padding: theme.spacing(2),
        paddingTop: theme.spacing(14),
        height: '100vh',
        right: 0,
        zIndex: 12,
        overflowY: 'auto',
    },
    btnGroup: {
        width: '100%',
    },
    filterItem: {
        marginTop: '4px',
        marginBottom: '4px',
    },
    search: {
        display: 'flex',
        boxShadow: 'inset 0px 0px 2px 2px rgba(207,207,207,0.49)',
        //backgroundColor: '#fafafa',
        borderRadius: '4px',
        alignItems: 'center',
        justifyContent: 'center',
        padding: theme.spacing(2),
    },
}));

const PhotoOptions = (props) => {
    const classes = useStyles();
    const mapRef = useRef()

    const { isLoaded, loadError } = useLoadScript({
        googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
        libraries,
    });   

    const [open, setOpen] = useState(false);
    const [typedValue, setTypedValue] = useState('');
    const [rerender, setRerender] = useState(1);
    const [customerOpen, setCustomerOpen] = useState(false);
    const [customerTypedValue, setCustomerTypedValue] = useState('');
    //hold current map position
    const [currentPosition, setCurrentPosition] = useState({ lat: 49.901956,  lng: -96.932431 });

    //Error checking on googlemaps api
    if (loadError) return "Error";
    if (!isLoaded) return "";

    const handleChangeCenter = () => { 
        setCurrentPosition(mapRef.current.state.map.getCenter().toJSON()); 
    }

    const filterCustomers = () => {
        let customersContainer = props.customers.map((customer) => customer.name ?? '')
        return ([...new Set(customersContainer)])
    }

    const onCircleComplete = (circle) => {
        let newValues = {...props.locationFilterData, location: {...props.locationFilterData.location, 
            center: {lat: circle.center.lat(), lng: circle.center.lng()}, radius: circle.radius}}

        props.setLocationFilterData(newValues)
        sessionStorage.setItem("geolocationPhotoData", JSON.stringify({...newValues, modalOpen: false}));

        circle.setMap(null);
    }

    return (
    <Grid container spacing={2}>
        {/* maps modal */}
        <Modal
            open={props.locationFilterData.modalOpen}
            onClose={() => { props.setLocationFilterData({...props.locationFilterData, modalOpen: false})}}
            style={{display:'flex', alignItems:'center', justifyContent:'center'}}
        >
            <Paper style={{padding:'10px', margin:'20px', width: '60vw', height: '60vh'}}>
                <GoogleMap
                    ref={mapRef}
                    mapContainerStyle={
                        {
                            width: "100%",
                            height: "100%",
                        }
                    }
                    options={{
                        fullscreenControl: false,
                        streetViewControl: false,
                        mapTypeControl: false,
                        rotateControl: false,
                        zoomControl: false,
                        mapTypeId: 'hybrid',
                        clickableIcons: false,
                    }}

                    zoom={9}
                    center={currentPosition}
                    onDragEnd={handleChangeCenter}
                >
                    { props.locationFilterData.location.center !== null &&
                        <Circle
                            center={props.locationFilterData.location.center}
                            radius={props.locationFilterData.location.radius}
                            options={{
                                clickable: false,
                                fillOpacity: .4,
                            }}
                        />
                    }

                    <DrawingManager 
                        onCircleComplete={onCircleComplete}
                        options={
                            {
                                drawingControlOptions: {
                                    position: window.window.google.maps.ControlPosition.TOP_CENTER,
                                    drawingModes: ['circle'],
                                    zIndex: 6
                                },
                            }
                        }
                    />
                </GoogleMap>
            </Paper>
        </Modal>

        <Grid item sm={12}>
            <Grid container className={classes.search}>
                <Grid item sm={5}>
                    <Typography>Search By</Typography>
                </Grid>
                <Grid item sm={7}>
                        <Select
                            name="type"
                            value={props.searchState.type}
                            onChange={props.searchStateHandler}
                            native>
                            <option value="employee">Employee</option>
                            <option value="customer">Customer</option>
                        </Select>
                    </Grid>

                < Grid item sm={12}>  
                    {props.searchState.type === 'employee'      
                    ? <Autocomplete
                        open={open}
                        onOpen={() => {
                            setOpen(true);
                        }}
                        onClose={() => {
                            setOpen(false);
                        }}
                        loading={props.userLoading}
                        label="Search"
                        id="autocomplete"
                        autoComplete
                        autoHighlight
                        inputValue={typedValue}
                        onInputChange={(event, newValue) => { setTypedValue(newValue); sessionStorage.setItem("photoAutoComplete", JSON.stringify(newValue)); }}
                        value={props.searchValue}
                        onChange={(event, newValue) => { props.searchValueChanged(newValue) }}
                        options={props.users.filter(x=> x.employment?.active).map((user) => user.displayName ?? '').sort((a, b) => a.toLowerCase() > b.toLowerCase())}
                        getOptionSelected={(option, value) => option?.displayName === value?.displayName}
                        getOptionLabel={(option) => option}
                        renderInput={(params) => (
                        <TextField
                            {...params}
                            label="Employee"
                            variant="outlined"
                            InputProps={{
                                ...params.InputProps,
                                endAdornment: (
                                    <React.Fragment>
                                        {props.userLoading ? <CircularProgress color="inherit" size={20} /> : null}
                                        {params.InputProps.endAdornment}
                                    </React.Fragment>
                                ),
                            }}
                        />
                        )}
                        />
                    : <Autocomplete
                        open={customerOpen}
                        onOpen={() => {
                            setCustomerOpen(true);
                        }}
                        onClose={() => {
                            setCustomerOpen(false);
                        }}
                        loading={props.customerLoading}
                        label="Search"
                        id="customerAutocomplete"
                        autoComplete
                        autoHighlight
                        inputValue={customerTypedValue}
                        onInputChange={(event, newValue) => { setCustomerTypedValue(newValue); sessionStorage.setItem("photoCustomerAutoComplete", JSON.stringify(newValue)); }}
                        value={props.customerSearchValue}
                        onChange={(event, newValue) => { props.customerSearchValueChanged(newValue) }}
                        options={filterCustomers().sort((a, b) => a.toLowerCase() > b.toLowerCase())}
                        getOptionSelected={(option, value) => option?.name === value?.name}
                        getOptionLabel={(option) => option}
                        renderInput={(params) => (
                        <TextField
                            {...params}
                            label="Customer"
                            variant="outlined"
                            InputProps={{
                                ...params.InputProps,
                                endAdornment: (
                                    <React.Fragment>
                                        {props.customerLoading ? <CircularProgress color="inherit" size={20} /> : null}
                                        {params.InputProps.endAdornment}
                                    </React.Fragment>
                                ),
                            }}
                        />
                        )}
                        />
                    }
                </Grid>
            </Grid>
        </Grid>

        <Grid item sm={12}>
            <Grid container className={classes.search}>
                <Grid item sm={12}>
                    <Typography>
                        Filter Origin
                    </Typography>
                </Grid>
                <FormGroup>
                    <Grid item sm={12}>
                        <FormControlLabel
                            control={<Checkbox 
                                checked = {props.ignoreList.includes('job') ? false : true}
                                onChange ={(event) => {props.handleChecklistChange(event); setRerender(rerender + 1)}} 
                                id={"job"}
                                />}
                            label="Jobs"
                        />
                    </Grid>
                    <Grid item sm={12}>
                        <FormControlLabel
                            control={<Checkbox 
                                checked = {props.ignoreList.includes('defect') ? false : true} 
                                onChange ={(event) => {props.handleChecklistChange(event); setRerender(rerender + 1)}} 
                                id={"defect"}
                                />}
                            label="Defects"
                        />
                    </Grid>
                    <Grid item sm={12}>
                        <FormControlLabel
                            control={<Checkbox 
                                checked = {props.ignoreList.includes('unit') ? false : true} 
                                onChange ={(event) => {props.handleChecklistChange(event); setRerender(rerender + 1)}} 
                                id={"unit"}
                                />}
                            label="Units"
                        />
                    </Grid>
                    <Grid item sm={12}>
                        <FormControlLabel
                            control={<Checkbox 
                                checked = {props.ignoreList.includes('delivery') ? false : true} 
                                onChange ={(event) => {props.handleChecklistChange(event); setRerender(rerender + 1)}} 
                                id={"delivery"}
                                />}
                            label="Deliveries"
                        />
                    </Grid>
                    <Grid item sm={12}>
                        <FormControlLabel
                            control={<Checkbox 
                                checked = {props.ignoreList.includes('checklist') ? false : true} 
                                onChange ={(event) => {props.handleChecklistChange(event); setRerender(rerender + 1)}}  
                                id={"checklist"}/>}
                            label="Checklists"
                        />
                    </Grid>
                </FormGroup>
            </Grid>
        </Grid>

        <Grid item sm={12}>
            <FormControlLabel
                label="Filter by Location"
                control={
                    <Checkbox 
                        variant="contained" 
                        checked={props.locationFilterData.filterBool}
                        onClick={(e) => {
                            props.setLocationFilterData({...props.locationFilterData, filterBool: e.target.checked})
                            sessionStorage.setItem("geolocationPhotoData", JSON.stringify({...props.locationFilterData, filterBool: e.target.checked, modalOpen: false}));
                        }}>
                    </Checkbox>
                }
            />

            <Button 
                variant="contained" 
                fullWidth 
                disabled={props.loading}
                onClick={(e) => { 
                    props.setLocationFilterData({...props.locationFilterData, modalOpen: true}) 
                }} 
            > Choose Location</Button>
            <Typography><small>*Not all photos have geolocation data available</small></Typography>
                
        </Grid>                      

        <Grid item sm={12}>
            <Grid container className={classes.search}>
                <Grid item sm={12} className={classes.filterItem}>
                    <DatePicker
                        label="Start Date"
                        value={props.startDate}
                        onChange={props.startDateHandler}
                    />
                </Grid>

                <Grid item sm={12} className={classes.filterItem}>
                    <DatePicker
                        label="End Date"
                        value={props.endDate}
                        onChange={props.endDateHandler}
                    />
                </Grid>
            </Grid>
        </Grid>

            {/*Error Message*/}
            <Grid item sm={12}>
                {(props.error && <Typography>{props.error}</Typography>)}
            </Grid>

            <Grid item sm={12} className={classes.btnGroup}>
                <Button variant="contained" fullWidth disabled={props.loading} onClick={props.handleGeneratePhotos} > Generate </Button>
            </Grid>

            <Grid item sm={12} className={classes.btnGroup}>
                <Button variant="contained" fullWidth disabled={props.loading} onClick={props.handleOpenDownloadModal} > Download Table </Button>
            </Grid>

            {/*Loading bar*/}
            <Grid item sm={12} style={props.progress === 0 || props.progress === 100 ? {visibility: 'hidden'} : {visibility: 'visible'}}>
                <CircularProgress variant="determinate" style={props.progress < 99 ? {color: "orange"} : {color: "green"}} value={props.progress} />
                <small>{props.loadingText}</small>
            </Grid>

    </Grid>
    
    )
}

export default PhotoOptions;