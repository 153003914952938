import React, { useState } from 'react';
import { TextField, IconButton, InputAdornment, CircularProgress } from '@material-ui/core';
import SendIcon from '@material-ui/icons/Send';

const ReplyBar = (props) => {

    const [reply, setReply] = useState('')

    return (
        <div>
            <TextField
                    variant='outlined'
                    label={props.contacts.filter(contact=>contact.contactPhone===props.selectedContact)[0]?.unsubscribed ? 'Contact is unsubscribed' : 'Reply'}
                    placeholder='Reply'
                    style={{width:'100%', marginTop:'4px'}}
                    value={reply}
                    disabled={props.sending || props.selectedContact === '' ||
                    props.contacts.filter(contact=>contact.contactPhone===props.selectedContact)[0]?.unsubscribed}
                    onChange={(e) => setReply(e.target.value)}
                    inputProps={{
                        maxLength: 159,
                        
                    }}
                    InputProps={{
                        endAdornment: < InputAdornment position='end' > {props.sending ? <CircularProgress/> :
                        <IconButton disabled={reply.length <= 0 || props.sending || props.selectedContact === '' || props.contacts.filter(contact=>contact.contactPhone===props.selectedContact)[0]?.unsubscribed}
                        onClick={(e) => {props.sendSMS(reply, props.selectedContact);
                        props.setSending(true)
                        //timeout to allow for sms to be sent before grabbing again
                        setTimeout(() => {
                            
                            props.setSending(false);
                            setReply('');
                        }, 1000);
                        }}>
                            <SendIcon/>
                        </IconButton>
                    }
                        </InputAdornment>
                    }}
                />
        </div>
    )
}

export default ReplyBar;