import React from 'react';
import { Image, View, Text, StyleSheet, Font } from '@react-pdf/renderer';
import header from '../../../../../img/InvoiceHeader.png';

Font.register({
    family: 'Oswald',
    src: 'https://fonts.gstatic.com/s/oswald/v13/Y_TKV6o8WovbUd3m_X9aAA.ttf'
});

// Create styles
const styles = StyleSheet.create({
    header: {
        width: '100%',
        height: '100%',
    },
    tableText: {
        fontSize: 12,
        marginBottom: '1px',
        fontFamily: 'Helvetica',
    },
    tableTextBold: {
        fontSize: 12,
        marginBottom: '1px',
        fontFamily: 'Helvetica-Bold',
        fontWeight: 'bold',
    },
    rightAlignedText: {
        alignItems: 'flex-end',
    },
});

const PDFHeader = (props) => {
    return (
        <div style={{ marginBottom: '15px', marginTop: '-25px' }}>
            <Image src={header} key={props.iframeKey} cache={true} fixed={true} />
            <View style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginTop:'4px' }}>
                <View style={{ flexDirection: 'column' }} />
                <View style={{ flexDirection: 'column', ...styles.rightAlignedText }}>
                    <Text style={styles.tableText}>61035 PR 207 Sunnyside, MB R5R 0C1</Text>
                    <Text style={styles.tableText}>Phone (204) 444-3069</Text>
                    <Text style={styles.tableText}>geeteeholdings.com</Text>
                    <Text style={styles.tableText}>admin@geetee.ca</Text>
                </View>
            </View>
        </div>
    );
};

export default PDFHeader;
