import React, { useState } from 'react';
import SafetyForm from '../../components/Safety/SafetyForm'
import { firestore } from '../../../firebase/firebase'
import { storage } from '../../../firebase/firebase'
import { withRouter } from 'react-router-dom';

const SafetyNew = (props) => {

    const initialSafetyForm = {
        name: '',
        description: '',
        aliases: [''],
        manufacturerPage: '',
        dateCreated: '',
        dateUpdated: '',
        pdfURL: '',
        emergencyPhone: '',
        safetyPhone: '',
    }

    const [safetyForm, setSafetyForm] = useState(initialSafetyForm);
    const [safetyPDF, setSafetyPDF] = useState({});
    const [submitting, setSubmitting] = useState(false);
    const [error, setError] = useState("");

    const handleChange = (event) => {
        switch (event.target.name) {
            //Handle value as array 
            case "aliases":
                //Dont call splice in the hook set method to conform to hook rules
                let aliases = safetyForm.aliases;
                aliases.splice(event.target.id, 1, event.target.value)
                setSafetyForm({ ...safetyForm, aliases: aliases });
                break;
            default:
                setSafetyForm({ ...safetyForm, [event.target.name]: event.target.value });
                break;
        }
    }

    const addAlias = () => {
        let aliases = safetyForm.aliases;
        aliases.push('');
        setSafetyForm({ ...safetyForm, aliases: aliases });
    }

    const removeAlias = (index) => {
        let aliases = safetyForm.aliases;
        aliases.splice(index, 1);
        setSafetyForm({ ...safetyForm, aliases: aliases });
    }

    const handleFileUpload = (acceptedFiles) => {
        const file = acceptedFiles[0];
        if (file) {
            setSafetyPDF(file);
            setSafetyForm({ ...safetyForm, pdfURL: file.name});
        }
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        createSafetyForm();
    }


    const createSafetyForm = async () => {

        setSubmitting(true);

        let newSafetyForm = safetyForm;

        newSafetyForm.dateCreated = Date.now() / 1000;
        newSafetyForm.archived = false;

        //Create reference to .pdf
        const storageRef = storage.ref();
        let docId = firestore.collection('safety').doc().id;
        const pdfRef = storageRef.child('Safety/' + safetyPDF.name + '_' + docId);

        pdfRef.put(safetyPDF).then((ref) => {
            pdfRef.getDownloadURL().then((url) => {
                newSafetyForm.pdfURL = url;
                newSafetyForm.docId = docId;
                newSafetyForm.dateUpdated = new Date(newSafetyForm.dateUpdated);
                newSafetyForm.dateUpdated = new Date(newSafetyForm.dateUpdated.getTime() + (newSafetyForm.dateUpdated.getTimezoneOffset() * 60000)) / 1000;

                firestore.collection('safety').doc(docId).set(newSafetyForm)
                    .then(() => {
                        setSafetyPDF()
                        setSafetyForm(initialSafetyForm);
                        setSubmitting(false);
                        setError('');
                    })
            })
        })
            .catch((e) => {
                setSubmitting(false);
                console.log(e)
                setError("Unable to create safety form, please try again");
            });
    }
    
    //substring text to use ... if too long
    const substringText = (text) => {
        if(text.length > 20){
            return text.substring(0, 20) + '...'
        } else {
            return text
        }
    }

    return (
        <React.Fragment>
            <SafetyForm
                safetyForm={safetyForm} safetyPDF={safetyPDF} setSafetyPDF={setSafetyPDF}
                addAlias={addAlias} removeAlias={removeAlias}
                handleChange={handleChange} handleSubmit={handleSubmit}
                handleFileUpload={handleFileUpload}
                submitting={submitting} error={error} 
                substringText={substringText}
            />
        </React.Fragment>
    )
}

export default withRouter(SafetyNew);