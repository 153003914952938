import React from 'react';
import { Grid, makeStyles, Button } from '@material-ui/core';
import { withRouter } from 'react-router';
import MaterialTable  from '@material-table/core'
import { TableIcons } from '../../../constants/tableIcons';
import AddBoxIcon from '@material-ui/icons/AddBox';
import moment from 'moment';

const useStyles = makeStyles((theme) => ({
    card: {
      padding: theme.spacing(4),
      width: '100%',
      height: '100%',
    },
    content: {
        display: 'flex',
        paddingTop: '32px',
        paddingLeft: '32px',
        width: '100%',
        [theme.breakpoints.down('xs')]: {
            paddingLeft: '5px',
            paddingTop: '3px',
            width: '100%',
        }
    },
    sidebar: {
        position: 'fixed',
        right: 0,
        top: '64px',
        maxWidth: '256px',
        width: '100%',
        padding: '16px',
        zIndex: '1000',
        overflowY: 'auto',
        minHeight: "calc(100vh - 64px)",
        maxHeight: "calc(100vh - 64px)",
    },
    sidebarSpacer: {
        marginLeft: '32px',
        maxWidth: '256px',
        width: '100%',
        [theme.breakpoints.down('xs')]: {
            marginLeft: '0px',
            maxWidth: '0px',
            width: '0%',
        }
    },
    grid: {
      marginTop: theme.spacing(2),
      maxWidth: '1200px',
    },
    table: {
      width: '100%',
      maxWidth: '900px',
      margin: '16px',
      display: 'flex',
    },
    tile: {
      width: '300px',
      height: '300px',
    },
    tileContent: {
      display: 'flex',
      justifyContent: 'space-evenly',
      alignItems: 'center',
      flexDirection: 'column',
      width: '100%',
      height: '100%',
      padding: theme.spacing(4),
    },
    tileIcon: {
      color: 'rgba(0, 0, 0, 0.54)',
      width: '100px',
      height: '100px',
    },
    tileText: {
      fontSize: '1.10rem',
    },
    btn: {
      marginBottom: '20px',
      marginTop: '4px',
  },
  }));

const InvoiceReport = (props) => {
    const classes = useStyles();

    return (
        <div >
            <Grid container className={classes.table}>
                <Grid item xs={12}>
                    <Button variant='contained' onClick={() => props.tileClickHandler('new')} className={classes.btn} startIcon={<AddBoxIcon/>}>
                    Create New Invoice
                    </Button>
                </Grid>
                
                <Grid item xs={12} style={{ width: '100%' }}>
                    <MaterialTable
                        style={{}}
                        columns={[
                            { title: 'ID', field: 'invoiceId' },
                            { title: 'Created On', render: rowData => moment(rowData.createdOn).format("YYYY-MM-DD") },
                            ]}
                        data={props.invoiceData}
                        isLoading={props.loading}
                        title={"Invoices"}
                        pathname='/dashboard/invoicing/form'
                        onSearchChange={ (value) => { props.handleOnSearchChange(value) }}
                        onPageChange={ (pageNum) => { props.handleOnPageChange(pageNum) }}
                        icons={TableIcons}
                        actions={[
                            {
                                icon: TableIcons.OpenInNew,
                                tooltip: 'View Invoice',
                                onClick: (event, rowData) => props.history.push({
                                    pathname: '/dashboard/invoicing/form',
                                    search: "?" + new URLSearchParams({ docId: rowData.docId }).toString(),
                                })
                            },
                        ]}
                        options={'options' in props
                            ? props.options
                            : {
                                toolbar: true,
                                pageSize: 20,
                                pageSizeOptions: false,
                                paging: true,
                                initialPage: props.page,
                                actionsColumnIndex: -1,
                                search: true,
                                emptyRowsWhenPaging: false,
                                thirdSortClick: false,
                                searchText: props.searchBarValue,
                                rowStyle: {
                                    whiteSpace: 'pre-wrap',
                                },
                            }}
                    />
                </Grid>
            </Grid>
        </div>
    )
}

export default withRouter(InvoiceReport);