import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import UnitPDF from '../../../components/Reports/Units/UnitPDF';
import { Skeleton } from '@material-ui/lab';
import { makeStyles, Typography } from '@material-ui/core';
import { firestore } from '../../../../firebase/firebase'

const useStyles = makeStyles((theme) => ({
    content: {
        display: 'flex',
        width: '100%',
        padding: '32px',
        justifyContent: 'center',
        marginTop: '40px',
    },
}));

function UnitPrint(props) {
    const [unitData, setUnitData] = useState([]);
    const [userData, setUserData] = useState([]);
    const [loading, setLoading] = useState([]);
    const [errorMessage, setErrorMessage] = useState('');
    
    const classes = useStyles();
    useEffect(() => {
        setLoading(true);

        let path = new URLSearchParams(props.location.search).get("docId");

        firestore.collection('units').where('__name__', "==", path).get()
        .then(querySnapshot => {
            const unitResponse = querySnapshot.docs.map((doc) => {
                return {
                    ...doc.data(),
                    'docId': doc.id,
                }
            })
            setUnitData(unitResponse[0]);
            
            firestore.collection('users').where("uuid", "==", unitResponse[0].uid).get()
                .then(querySnapshot => {
                    const userResponse = querySnapshot.docs.map((doc) => {
                        return {
                            ...doc.data(),
                            'docId': doc.id,
                        }
                    })
                    setUserData(userResponse[0]);
                    setLoading(false)
                })

                .catch(e => {
                    setErrorMessage("Unable to Process")
                    setLoading(false)
                    console.log(e.message);
                })
        })
        .catch(e => {
            setErrorMessage("Unable to Process")
            setLoading(false)
            console.log(e.message);
        })
     
        
    }, [props.location.search]);

    useEffect(() => {
    
    }
    , [unitData])

    return (
        loading
            ? 
            <div className={classes.content}>
                <Skeleton variant='rect' width={'63vw'} height={'80vh'} />
                <Typography variant='h5'>{errorMessage}</Typography>
            </div>
            : 
            <UnitPDF
                unitData={unitData} userData={userData}/>
    )
}

export default withRouter(UnitPrint)