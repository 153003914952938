import React, { useState, useEffect } from 'react';
import { Card, CardActionArea, Grid, makeStyles, Typography } from '@material-ui/core';
import { withRouter } from 'react-router';
import { firestore } from '../../../firebase/firebase';
import FindInPageIcon from '@material-ui/icons/FindInPage';
import NoteAddIcon from '@material-ui/icons/NoteAdd';
import ReportTable from '../../components/Reports/ReportTable/ReportTable'

const useStyles = makeStyles((theme) => ({

  card: {
      padding: theme.spacing(4),
      width: '100%',
      height: '100%',
  },

  content: {
      display: 'flex',
      justifyContent: 'center',
      width: '100%',
  },

  grid: {
    marginTop: theme.spacing(2),
    maxWidth: '1200px',
  },

  table: {
    display:'flex',
    justifyContent:'center',
  },

  tile: {
    width: '300px',
    height: '300px',
  },

  tileContent: {
    display:'flex',
    justifyContent:'space-evenly',
    alignItems:'center',
    flexDirection:'column',
    width: '100%',
    height: '100%',
    padding: theme.spacing(4),
  },

  tileIcon: {
    color: 'rgba(0, 0, 0, 0.54)',
    width:'100px',
    height: '100px',
  },

  tileText:{
    fontSize: '1.10rem',
  },
}));

const FormHome = (props) =>  {

  const [formData, setFormData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const classes = useStyles();

  useEffect(() => {
    firestore.collection('formTemplates').orderBy('dateCreated').limit(5).get()
    .then(querySnapshot => {
        const formResponse = querySnapshot.docs.map((doc) => {
            return {
                ...doc.data(),
                'docId': doc.id,
            }
        })
        setFormData(formResponse);
        setLoading(false);
    })
    .catch(e => {
        setError("Unable to Process");
        setLoading(false);
        console.log(e.message);
    })
  }, []);

  const tileClickHandler = (path) => {
    props.history.push({
        pathname: 'forms/' + path,
    })
  };

  return (
    <div className={classes.content}>
      <Grid container spacing={4} className={classes.grid}>
        <Grid item sm={6} xs={12} style={{display:'flex', justifyContent: 'flex-end'}}>
          <Card className={classes.tile}>
            <CardActionArea className={classes.tileContent} onClick={()=>tileClickHandler('new')}>
              <NoteAddIcon className={classes.tileIcon}/>
              <Typography className={classes.tileText}>Create New Form</Typography>
            </CardActionArea>
          </Card>
        </Grid>
        <Grid item sm={6} xs={12}>
          <Card className={classes.tile}>
            <CardActionArea className={classes.tileContent} onClick={()=>tileClickHandler('reporting')}>
              <FindInPageIcon className={classes.tileIcon}/>
              <Typography className={classes.tileText}>Search Forms</Typography>
            </CardActionArea>
          </Card>
        </Grid>

        {!loading && !error
        ? <Grid item sm={12} xs={12} className={classes.table}>
            <ReportTable
                columns = {[
                  { title: 'Name', field: 'title'},
                ]}
                data = {formData}
                title = {"Newest Forms"}
                pathname = '/dashboard/forms/form'
            />
          </Grid>
        : null}
      </Grid>
    </div>
  )
}

export default withRouter(FormHome);