import React, { useState } from 'react';
import EquipmentForm from '../../components/Equipment/EquipmentForm'
import { firestore } from '../../../firebase/firebase'
import { withRouter } from 'react-router-dom';

const EquipmentNew = (props) =>  {

    const initialEquipmentForm = {
        unitNumber: '',
        licensePlate: '',
        make: '',
        model: '',
        year: '',
        class: '',
        equipmentType: '',
        description: '',
        odoUnits: '',
    }

    const [equipmentForm, setEquipmentForm] = useState(initialEquipmentForm);
    const [submitting, setSubmitting] = useState(false);
    const [error, setError] = useState("");

    const handleChange = (event) => {
        setEquipmentForm({...equipmentForm, [event.target.name]: event.target.value});
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        createEquipmentForm();
    }

    const createEquipmentForm = async () => {

        setSubmitting(true);

        let newEquipmentForm = equipmentForm;

        newEquipmentForm.dateCreated = Date.now()/1000;
        newEquipmentForm.archived = false;
        
        firestore.collection('unitDescriptions').add(newEquipmentForm)
            .then(() => {
            setEquipmentForm (initialEquipmentForm);
            setSubmitting(false);
            setError('');
        })
        .catch((e) => { 
            setSubmitting(false);
            console.log(e)
            setError("Unable to create new Equipment, Error: " + e);
        });
    }
        
    return (
        <React.Fragment>
            <EquipmentForm
                equipmentForm={equipmentForm}
                handleChange={handleChange} handleSubmit={handleSubmit} 
                submitting={submitting} error={error}
            />
        </React.Fragment>
    )
}

export default withRouter(EquipmentNew);