import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core';
import moment from 'moment'
import { Button, Grid, IconButton, Paper, Snackbar, TextField, Typography, Box, useMediaQuery, 
        Dialog, DialogActions, DialogContent, DialogTitle, DialogContentText } from '@material-ui/core';
import { withRouter } from 'react-router-dom';
import _ from 'lodash'
import EditIcon from '@material-ui/icons/Edit';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import PropTypes from 'prop-types';
import ReportTable from '../ReportTable/ReportTable'
import NoteIcon from '@material-ui/icons/Note';
import PrintIcon from '@material-ui/icons/Print';
import PersonIcon from '@material-ui/icons/Person';
import ContactPhoneIcon from '@material-ui/icons/ContactPhone';
import CommentIcon from '@material-ui/icons/Comment';
import { useTheme } from '@material-ui/core/styles';
import WorkIcon from '@material-ui/icons/Work';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
//tabs
import InformationTab from '../Customers/InformationTab';
import CommunicationsTab from '../Customers/CommunicationsTab';
import { useAuth } from '../../../../providers/AuthContext';

const useStyles = makeStyles((theme) => ({
    btnGroup: {
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
    },
    tabBar: {
        width: '100%',
        maxWidth: '1000px',
        zIndex: '998'
    },
    tabs:{
        [theme.breakpoints.up("md")]: {
          margin:'auto'
        }
    },
    alias: {
        display: 'flex',
        marginTop: '8px',
        marginBottom: '8px',
        alignItems: 'center',
    },
    card: {
        padding: theme.spacing(4),
        display: 'flex',
        alignItems: 'center',
        height: '100%',
    },
    content: {
        display: 'flex',
        width: '100%',
        flexDirection: 'column',
        maxWidth: '1000px',
        margin: 'auto',
    },
    paper: {
        borderRadius: '0px',
        margin: '0px',
        marginBottom:'20px',
        width: '100%',
        maxWidth: '1000px',
        //backgroundColor: 'rgb(252, 252, 249)',
    },
    row: {
        display: 'flex',
        alignItems: 'center',
    },
    backButton: {
        marginBottom: '20px',
        marginTop: '20px',
    },
}));

const CustomerCard = (props) => {
    function TabPanel(props) {
        const { children, value, index, ...other } = props;
      
        return (
          <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
          >
            {value === index && (
              <Box style={{margin:'20px'}}>
                {children}
              </Box>
            )}
          </div>
        );
      }
      
      TabPanel.propTypes = {
        children: PropTypes.node,
        index: PropTypes.any.isRequired,
        value: PropTypes.any.isRequired,
      };
      
      function a11yProps(index) {
        return {
          id: `simple-tab-${index}`,
          'aria-controls': `simple-tabpanel-${index}`,
        };
    }

    const classes = useStyles();
    const theme = useTheme();
    const smallScreen = useMediaQuery(theme.breakpoints.down('sm'));    
    const [edit, setEdit] = useState(false);
    const [value, setValue] = React.useState(JSON.parse(sessionStorage.getItem('customerTab'))|| 1);
    const [deleteOpen, setDeleteOpen] = useState(false);

    const { roles } = useAuth();

    const handleEdit = () => {
        if (edit)
            props.cancelSave();

        setEdit(!edit);
    }
    
    const handleChange = (event, newValue) => {
        setValue(newValue);
        sessionStorage.setItem("customerTab", JSON.stringify(newValue))
      };
      
    const handleDeleteOpen = () => {
        setDeleteOpen(true);
    }

    const handleDeleteClose = () => {
        setDeleteOpen(false);
    }
    
    useEffect(() => {
    }, [smallScreen]);

    return (
        <div>
            {_.isUndefined(props.customerData) ? null :
            <Grid className={classes.content}>

                <Grid container>
                    <Grid className={classes.backButton} item xs={10}>
                        <Button variant="contained" onClick={() => props.history.goBack()} startIcon={<ArrowBackIcon/>}>Back</Button>
                    </Grid>

                    {!props.customerData.isActive && _.isEqual(props.customerData, props.saveState) ?
                        <Grid className={classes.backButton} item xs={2}>
                            <Button
                                variant='contained'
                                style={{backgroundColor:'red', color:'white', }}
                                onClick={(event) => { handleDeleteOpen() }}
                            >
                                Delete Customer
                            </Button>
                        </Grid>
                    : null
                    }
                </Grid>

                <Paper className={classes.paper}>  
                    <div className={classes.content}>
                        <AppBar className={classes.tabBar} position="static" elevation={0} >
                            <Tabs value={value} onChange={handleChange} 
                            centered={false} variant={smallScreen ? "scrollable" : "fullWidth"} 
                            scrollButtons={"on"}  
                            style={{margin:'0px'}}>
                                
                            <IconButton onClick={(e) => props.history.push("/dashboard/customers/reporting/printing/?docId=" + props.customerData?.docId)} title="Print Customer"><PrintIcon /></IconButton>
                            <Tab label={<div><PersonIcon style={{verticalAlign: 'middle'}} /> Information </div>} {...a11yProps(0)} />
                            <Tab label={<div><ContactPhoneIcon style={{verticalAlign: 'middle'}} /> Communications </div>} {...a11yProps(1)} />
                            <Tab label={<div><WorkIcon style={{verticalAlign: 'middle'}} /> Jobs </div>}  {...a11yProps(2)} />
                            <Tab label={<div><CommentIcon style={{verticalAlign: 'middle'}} /> Notes </div>}  {...a11yProps(3)} />
                            <IconButton onClick={handleEdit} style={edit ? { color: 'black' } : roles.includes('Create|Update|Delete Customers')  ? {visibility: 'visible'} : {visibility: 'hidden'}} title="Edit Customer"><EditIcon /></IconButton>
                            </Tabs>
                        </AppBar>
                    </div>

                    <TabPanel value={value} index={2}>
                        <CommunicationsTab
                            edit={edit}
                            customerData={props.customerData} 
                            saveState={props.saveState}
                            jobData={props.jobData} 
                            unitJobData={props.unitJobData}
                            loading={props.loading}
                            cancelSave={props.cancelSave} 
                            saveCustomerData={props.saveCustomerData}
                            updateCustomerData={props.updateCustomerData} 
                            saving={props.saving}
                            confirmSave={props.confirmSave} 
                            setCustomerData={props.setCustomerData} 
                            setSaveState={props.setSaveState}
                            error={props.error} 
                        />
                    </TabPanel>

                    <TabPanel value={value} index={4}>
                        <Paper className={classes.paper}>
                        <Typography variant="h5">Notes <NoteIcon></NoteIcon></Typography> 
                            <div style={{border: '1px dashed #d3d3d3', padding: '10px'}}>
                                {edit ?
                                <TextField
                                    label="Notes"
                                    multiline
                                    minRows={10}
                                    value={props.customerData.notes ? props.customerData.notes : ""}
                                    style={{width: '100%'}}
                                    variant="outlined"
                                    onChange={(event) => { props.updateCustomerData({ ...props.customerData, 'notes': event.target.value }) }}
                                /> : 
                                props.customerData.notes ? props.customerData.notes : "No Notes to Display" }
                            </div>
                        </Paper>
                    </TabPanel>
                    <TabPanel value={value} index={3}>
                    {edit
                        ? null
                        : <React.Fragment>
                            {props.jobData.length > 0
                                ? <Grid item sm={12} xs={12}>
                                    <ReportTable
                                        columns={[
                                            { title: 'Customer', field: 'customer' },
                                            { title: 'Address', field: 'address' },
                                            {
                                                title: 'Time In', field: 'timeIn', render: rowData => {
                                                    if (rowData.timeIn < 9999999999) rowData.timeIn *= 1000
                                                    return moment(rowData.timeIn).format('YYYY-MM-DD h:mm a')
                                                }
                                            },
                                            {
                                                title: 'Time Out', field: 'timeOut', render: rowData => {
                                                    if (rowData.timeOut < 9999999999) rowData.timeOut *= 1000
                                                    return moment(rowData.timeOut).format('YYYY-MM-DD h:mm a')
                                                }
                                            },
                                            {
                                                title: 'Job Time', render: rowData => {
                                                    if (rowData.timeIn < 9999999999) rowData.timeIn *= 1000
                                                    if (rowData.timeOut < 9999999999) rowData.timeOut *= 1000
                                                    return ((rowData.timeOut - rowData.timeIn) / 3600000).toFixed(2)
                                                }
                                            },
                                        ]}
                                        data={props.jobData}
                                        title={"Job Details"}
                                        pathname='/dashboard/reporting/timesheets/job'
                                    />
                                </Grid>
                                : null
                            }
                            {props.unitJobData.length > 0
                                ? <Grid item sm={12} xs={12}>
                                    <ReportTable
                                        columns={[
                                            { title: 'Unit Number', field: 'unitNumber' },
                                            { title: 'Greased', render: rowData => rowData.greased ? "Yes" : "No" },
                                            { title: 'Net Mileage', render: rowData => (rowData.mileageOut - rowData.mileageIn).toFixed(2) },
                                            { title: 'Defects', render: rowData => rowData.defectID !== "" ? "Yes" : "No" },
                                        ]}
                                        data={props.unitJobData}
                                        title={"Job Related Units"}
                                        pathname='/dashboard/reporting/timesheets/unit'
                                    />
                                </Grid>
                                : null}

                            {props.deliveryData.length > 0
                                ? <Grid item sm={12} xs={12}>
                                    <ReportTable
                                        columns={[
                                            { title: 'Ticket #', field: 'ticket' },
                                            { title: 'Customer', field: 'customer' },
                                            { title: 'From', field: 'from' },
                                            { title: 'To', field: 'to' },
                                            { title: 'Material', field: 'material' },
                                            { title: 'Quantity', field: 'quantity' },
                                        ]}
                                        data={props.deliveryData}
                                        title={"Delivery Details"}
                                        pathname='/dashboard/reporting/timesheets/delivery'
                                    />
                                </Grid>
                                : null}

                            {props.unitDeliveryData.length > 0
                                ? <Grid item sm={12} xs={12}>
                                    <ReportTable
                                        columns={[
                                            { title: 'Unit Number', field: 'unitNumber' },
                                            { title: 'Greased', render: rowData => rowData.greased ? "Yes" : "No" },
                                            { title: 'Net Mileage', render: rowData => (rowData.mileageOut - rowData.mileageIn).toFixed(2) },
                                            { title: 'Defects', render: rowData => rowData.defectID !== "" ? "Yes" : "No" },
                                        ]}
                                        data={props.unitDeliveryData}
                                        title={"Delivery Related Units"}
                                        pathname='/dashboard/reporting/timesheets/unit'
                                    />
                                </Grid>
                                : null}
                        </React.Fragment>
                    }
                    </TabPanel>
                    <TabPanel value={value} index={1}>
                        <InformationTab
                            edit={edit}
                            customerData={props.customerData} 
                            saveState={props.saveState}
                            jobData={props.jobData} 
                            unitJobData={props.unitJobData}
                            loading={props.loading}
                            cancelSave={props.cancelSave} 
                            saveCustomerData={props.saveCustomerData}
                            updateCustomerData={props.updateCustomerData} 
                            saving={props.saving}
                            confirmSave={props.confirmSave} 
                            setCustomerData={props.setCustomerData} 
                            setSaveState={props.setSaveState}
                            error={props.error} 
                        />
                    </TabPanel>

                {/* deleting customer modal */}
                <Paper className={classes.paper}>
                    <Dialog
                        open={deleteOpen}
                        onClose={handleDeleteClose}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                    >
                        <DialogTitle id="alert-dialog-title">{"Delete User?"}</DialogTitle>
                        <DialogContent>
                            <DialogContentText id="alert-dialog-description">
                                Are you sure you want to delete this customer?
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleDeleteClose} variant="contained" color="secondary">
                                No
                            </Button>
                            <Button onClick={(event) => {
                                    props.deleteCustomer(); 
                                    handleDeleteClose()}
                                } 
                                variant="contained" 
                                style={{ backgroundColor: 'red', color: 'white' }} 
                                autoFocus
                            >
                                Yes
                            </Button>
                        </DialogActions>
                    </Dialog>
                </Paper>

                {/* saving modal */}
                <Snackbar
                    open={!_.isEqual(props.customerData, props.saveState)}
                    message={props.saving ? 'Saving...' : 'Save Document?'}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center'
                    }}
                    action={
                        <React.Fragment>
                            {props.saving
                                ? null
                                : <React.Fragment>
                                    <Button variant='text' color='primary' onClick={props.cancelSave} style={{ marginLeft: '32px', marginRight: '8px' }}>Cancel</Button>
                                    <Button variant='contained' onClick={props.confirmSave}>Confirm</Button>
                                </React.Fragment>}
                        </React.Fragment>
                    } />

                </Paper>
            </Grid>}
        </div>
    )
}

export default withRouter(CustomerCard)