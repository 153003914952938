import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import { Grid, Typography, Button,  TextField, Divider, Checkbox, FormControlLabel } from '@material-ui/core'
import _ from 'lodash';
import { Autocomplete } from '@material-ui/lab';


const DeliveryModal = (props) => {

    const [open, setOpen] = useState(false)

    const handleCustomerChange = () => {
        if(props.newCustomer){
            props.setNewCustomer(false)
        }
        else{
            props.setNewCustomer(true)
        }
    }

    const handleChangeJob = (e) => {
        if(e.target.checked){
            let container = props.currentDelivery.units ? props.currentDelivery.units : [];
            container.push(e.target.name)
            props.setCurrentDelivery({...props.currentDelivery, units: [...container] });
        }
        else{
            let container = props.currentDelivery.units ? props.currentDelivery.units : [];
            container.splice(container.indexOf(e.target.name), 1);
            props.setCurrentDelivery({...props.currentDelivery, units: [...container] });
        }
    };

    const handleSelectAllJob = (e) => {
        if(e.target.checked){
            let unitDocIds = props.unitData.map((unit) => {return unit.docId})
            props.setCurrentDelivery({...props.currentDelivery, units: unitDocIds });
        }
        else{
            props.setCurrentDelivery({...props.currentDelivery, units: [] });
        }
    }

    return (
        <Grid>
            {!props.newCustomer ?
            <Grid container spacing={2}>
                <Grid item xs={5}>
                    <Typography style={{marginTop: '8px'}}>Customer: </Typography>
                </Grid>

                <Grid item xs={5}>
                    <Autocomplete
                        open={open}
                        onOpen={() => {
                            setOpen(true);
                        }}
                        onClose={() => {
                            setOpen(false);
                        }}
                        id="autocomplete"
                        inputValue={props.deliveryCustomerAutocomplete}
                        onInputChange={(event, newValue) => { 
                            props.setDeliveryCustomerAutocomplete(newValue) 
                        }}
                        clearOnBlur={false}
                        value={null}
                        onChange={(event, newValue) => { 
                            newValue != null &&
                            props.setCurrentDelivery({...props.currentDelivery, customer: newValue.name}) 
                        }}
                        options={props.customers}
                        getOptionLabel={(option) => option.name}
                        renderOption={(option) =>( 
                            props.customers.filter(customer => customer.docId === option.docId).map((customerObj) => {
                                return(
                                    <Grid key={option.docId} >
                                        <Typography>{customerObj.name}</Typography>
                                        <small>{ customerObj.streetNumber + ' ' + customerObj.streetName}</small>
                                    </Grid>
                                )
                            })
                        )}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                style={{ maxWidth: '300px', width: '100%' }}
                                size='small'
                                variant="outlined"
                                InputProps={{
                                    ...params.InputProps,
                                    endAdornment: (
                                        <div>
                                            {params.InputProps.endAdornment}
                                        </div>
                                    ),
                                }}
                            />
                        )}
                    />
                </Grid>

                <Grid item xs={2}>
                    <Button 
                        size="small"
                        variant='contained'
                        onClick={() => handleCustomerChange()}
                    >
                        New
                    </Button>
                </Grid>
            </Grid>
            :
            <Grid container spacing={2}>
                <Grid item xs={5}>
                    <Typography style={{marginTop: '8px'}}>
                        Customer Name: 
                    </Typography>
                </Grid>
                <Grid item xs={5}>
                    <TextField 
                        variant="outlined" 
                        size="small" 
                        value={props.currentCustomer.name ? props.currentCustomer.name : ''} 
                        onChange={(e) => { props.setCurrentCustomer({...props.currentCustomer, name: e.target.value}) }}
                    />
                </Grid>
                <Grid item xs={2}>
                    <Button 
                        size="small"
                        variant='contained'
                        onClick={() => handleCustomerChange()}
                    >
                        Existing
                    </Button>
                </Grid>

                <Grid item xs={5}>
                    <Typography style={{marginTop: '8px'}}>
                        Street Name: 
                    </Typography>
                </Grid>
                <Grid item xs={7}>
                    <TextField 
                        variant="outlined" 
                        size="small" 
                        value={props.currentCustomer.streetName ? props.currentCustomer.streetName : ''} 
                        onChange={(e) => { props.setCurrentCustomer({...props.currentCustomer, streetName: e.target.value}) }}
                    />
                </Grid>

                <Grid item xs={5}>
                    <Typography style={{marginTop: '8px'}}>
                        Street Number: 
                    </Typography>
                </Grid>
                <Grid item xs={7}>
                    <TextField 
                        variant="outlined" 
                        size="small" 
                        value={props.currentCustomer.streetNumber ? props.currentCustomer.streetNumber : ''} 
                        onChange={(e) => { props.setCurrentCustomer({...props.currentCustomer, streetNumber: e.target.value}) }}
                    />
                </Grid>

                <Grid item xs={5}>
                    <Typography style={{marginTop: '8px'}}>
                        City: 
                    </Typography>
                </Grid>
                <Grid item xs={7}>
                    <TextField 
                        variant="outlined" 
                        size="small" 
                        value={props.currentCustomer.city ? props.currentCustomer.city : ''} 
                        onChange={(e) => { props.setCurrentCustomer({...props.currentCustomer, city: e.target.value}) }}
                    />
                </Grid>

                <Grid item xs={5}>
                    <Typography style={{marginTop: '8px'}}>
                        Postal Code: 
                    </Typography>
                </Grid>
                <Grid item xs={7}>
                    <TextField 
                        variant="outlined" 
                        size="small" 
                        value={props.currentCustomer.postalCode ? props.currentCustomer.postalCode : ''} 
                        onChange={(e) => { props.setCurrentCustomer({...props.currentCustomer, postalCode: e.target.value}) }}
                    />
                </Grid>

                <Grid item xs={5} >
                    <Typography style={{marginTop: '8px'}}>
                        Province: 
                    </Typography>
                </Grid>
                <Grid item xs={7} >
                    <TextField 
                        variant="outlined" 
                        size="small" 
                        value={props.currentCustomer.province ? props.currentCustomer.province : ''} 
                        onChange={(e) => { props.setCurrentCustomer({...props.currentCustomer, province: e.target.value}) }}
                    />
                </Grid>

                <Grid item xs={5} >
                    <Typography style={{marginTop: '8px'}}>
                        Job Number: 
                    </Typography>
                </Grid>
                <Grid item xs={7} >
                    <TextField 
                        variant="outlined" 
                        size="small" 
                        value={props.currentCustomer.jobNumber ? props.currentCustomer.jobNumber : ''} 
                        onChange={(e) => { props.setCurrentCustomer({...props.currentCustomer, jobNumber: e.target.value}) }}
                    />
                </Grid>

            </Grid>
            }

            <Divider style={{marginTop: '10px', marginBottom:'10px'}}/>

            <Grid container spacing={2}>
                <Grid item xs={5}>
                    <Typography 
                        style={{marginTop: '8px'}}
                    >
                        Ticket Number: 
                    </Typography>
                </Grid>
                <Grid item xs={7}>
                    <TextField 
                        variant="outlined" 
                        size="small" 
                        value={props.currentDelivery.ticket ? props.currentDelivery.ticket : ''} 
                        onChange={(e) => { props.setCurrentDelivery({...props.currentDelivery, ticket: e.target.value})  }}/>
                </Grid>

                <Grid item xs={5}>
                    <Typography 
                        style={{marginTop: '8px'}}
                    >
                        From: 
                    </Typography>
                </Grid>
                <Grid item xs={7}>
                    <TextField 
                        variant="outlined" 
                        size="small" 
                        value={props.currentDelivery.from ? props.currentDelivery.from : ''} 
                        onChange={(e) => { props.setCurrentDelivery({...props.currentDelivery, from: e.target.value}) }}/>
                </Grid>

                <Grid item xs={5}>
                    <Typography 
                        style={{marginTop: '8px'}}
                    >
                        To: 
                    </Typography>
                </Grid>
                <Grid item xs={7}>
                    <TextField 
                        variant="outlined" 
                        size="small" 
                        value={props.currentDelivery.to ? props.currentDelivery.to : ''} 
                        onChange={(e) => { props.setCurrentDelivery({...props.currentDelivery, to: e.target.value}) }}/>
                </Grid>

                <Grid item xs={5}>
                    <Typography 
                        style={{marginTop: '8px'}}
                    >
                        Material: 
                    </Typography>
                </Grid>
                <Grid item xs={7}>
                    <TextField 
                        variant="outlined" 
                        size="small" 
                        value={props.currentDelivery.material ? props.currentDelivery.material : ''} 
                        onChange={(e) => { props.setCurrentDelivery({...props.currentDelivery, material: e.target.value}) }}/>
                </Grid>

                <Grid item xs={5}>
                    <Typography 
                        style={{marginTop: '8px'}}
                    >
                        Quantity: 
                    </Typography>
                </Grid>
                <Grid item xs={7}>
                    <TextField 
                        variant="outlined" 
                        size="small" 
                        value={props.currentDelivery.quantity ? props.currentDelivery.quantity : ''} 
                        onChange={(e) => { props.setCurrentDelivery({...props.currentDelivery, quantity: e.target.value}) }}
                    />
                </Grid>
            </Grid>

            <Divider style={{marginTop: '10px', marginBottom:'10px'}}/>

            <Grid container>
                <Grid item xs={6} style={{marginTop: '10px'}}>
                    <Typography>Add units To Delivery</Typography>
                </Grid>
                <Grid item xs={6}>
                    <FormControlLabel
                            label={"Select All Units"}
                            control={
                                <Checkbox 
                                    checked={props.currentDelivery.units ? _.isEqual(props.unitData.map((unit) => {return unit.docId}).sort(), props.currentDelivery.units.sort()) : false} 
                                    onChange={(e) => { handleSelectAllJob(e) }}
                                />
                            }
                        />
                </Grid>
            </Grid>

            <Divider style={{marginTop: '10px', marginBottom:'10px'}}/>

            {
                props.unitData.map((unit) => {
                    return (
                        <FormControlLabel
                            key={unit.docId}
                            style={{margin: '5px'}}
                            control={
                                <Checkbox 
                                    checked={props.currentDelivery.units ? props.currentDelivery.units.includes(unit.docId) : false} 
                                    name={unit.docId} />}
                                    label={unit.unitNumber}
                                    onChange={(e) => { handleChangeJob(e) }} 
                                />
                        
                    )
                })
            }
        </Grid>
    )
}
export default withRouter(DeliveryModal)