import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router'
import { firestore } from '../../../firebase/firebase'
import { makeStyles } from '@material-ui/core';
import MaterialTable from '@material-table/core';
import { TableIcons } from '../../../constants/tableIcons'
import { Skeleton } from '@material-ui/lab';

const useStyles = makeStyles((theme) => ({
    content: {
        display: 'flex',
        width: '100%',
        padding: '32px',
        justifyContent: 'center'
    },

    sidebar: {
        position: 'fixed',
        right: 0,
        top: '64px',
        maxWidth: '256px',
        width: '100%',
        padding: '16px',
        zIndex: '1000',
        overflowY: 'auto',
        minHeight: "calc(100vh - 64px)",
        maxHeight: "calc(100vh - 64px)",
    },
}));

const FormReport = (props) => {

    const classes = useStyles();

    const [formData, setFormData] = useState(JSON.parse(sessionStorage.getItem("formData")) || []);
    const [errorMessage, setErrorMessage] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        let unsubscribe = firestore.collection('formTemplates').onSnapshot(
            querySnapshot => {

                const formResponse = querySnapshot.docs.map((doc) => {
                    return {
                        ...doc.data(),
                        'docId': doc.id,
                    }
                })
                setFormData(formResponse);
                sessionStorage.setItem('formData', JSON.stringify(formResponse))
                setLoading(false);
            },
            error => {
                setErrorMessage("Unable to load form data.");
                setLoading(false);
                console.log(error.message);
                console.log(errorMessage)
            }
        )
        return () => { unsubscribe();}
        // eslint-disable-next-line
    }, []);

    return (
        <div className={classes.content}>
            {
                loading
                    ? 
                    <Skeleton variant='rect' width={'100vw'} height={'100vh'}/>
                    : 
                    <MaterialTable
                        data={formData}
                        title={"All Forms"}
                        icons={TableIcons}
                        columns={[
                            { title: 'Name', field: 'title' },
                        ]}
                        actions={[
                            {
                                icon: TableIcons.OpenInNew,
                                tooltip: "Show Form",
                                onClick: (event, rowData) => props.history.push({
                                    pathname: '/dashboard/forms/form',
                                    search: "?" + new URLSearchParams({ docId: rowData.docId }).toString(),
                                })
                            }
                        ]}
                        localization={{
                            header: {
                                actions: 'Actions'
                            }
                        }}
                        options={{
                            toolbar: true,
                            paging: false,
                            actionsColumnIndex: -1,
                            search: true,
                            emptyRowsWhenPaging: false,
                            rowStyle: {
                                whiteSpace: 'pre-wrap',
                            },
                        }}
                    />
            }
        </div>
    )
}

export default withRouter(FormReport);