import React from 'react';
import { GoogleMap, useLoadScript } from '@react-google-maps/api';

const libraries = ["places"];

const Map = (props) => {

    const [map, setMap] = React.useState({});

    //Google Maps API setup
    const { isLoaded, loadError } = useLoadScript({
        googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
        libraries,
    });

    const mapContainerStyle = {
        width: '100%',
        height: props.height,
    };

    //Error checking on googlemaps api
    if (loadError) return "Error";
    if (!isLoaded) return "";

    return (
        <GoogleMap
            mapContainerStyle={mapContainerStyle}
            mapTypeId='satellite'
            center={props.center}
            zoom={props.zoom}
            onLoad={map => {
                setMap(map);
                if (props.elements) {
                    const bounds = new window.google.maps.LatLngBounds();
                    props.elements.forEach((element) => bounds.extend(element.props.position));
                    map.fitBounds(bounds);
                }
            }}

            onBoundsChanged={() => {
                if (map) {
                    let zoom = map.getZoom();

                    //21 is the current zoom cap for satellite view
                    if (zoom > 21 && map.mapTypeId === 'satellite')
                        map.setZoom(21);
                }
            }}
        >
            {props?.elements}
        </GoogleMap>
    )
}

export default Map;