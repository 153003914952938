import * as actionTypes from '../actions/actionTypes'
import { updateObject } from '../utility'
import _, { clone, isNull, isUndefined, isEmpty } from 'lodash';
import { firestore } from '../../firebase/firebase'

//Make sure reducers have an initialState 
const initialState = {

    cart: JSON.parse(sessionStorage.getItem('cart')) || [],
    customerAddress: JSON.parse(sessionStorage.getItem("customerAddress")) || "",
    searchboxCoordinates: JSON.parse(sessionStorage.getItem('searchboxCoordinates')) || { lat: 49.904415, lng: -96.932431 },
    customerLocation: JSON.parse(sessionStorage.getItem("customerLocation")) || { lat: 49.904415, lng: -96.932431 },
    validAddress: JSON.parse(sessionStorage.getItem('validAddress')) || false,
    validLocation: JSON.parse(sessionStorage.getItem('validLocation')) || false,
    validDeliveryDate: JSON.parse(sessionStorage.getItem('validDeliveryDate')) || false,
    alertActive: JSON.parse(sessionStorage.getItem('alertActive')) || false,
    alertMsg: JSON.parse(sessionStorage.getItem('alertMsg')) || 'For updated shipping costs, \'Enter your Address\' or select your location on the map.',
    alertSev: JSON.parse(sessionStorage.getItem('alertSev')) || 'info',
    distance: JSON.parse(sessionStorage.getItem('distance')) || {},
    cartFees: JSON.parse(sessionStorage.getItem('cartFees')) || {
        subTotal: 0.00,
        discSubTotal: 0.00,
        total: 0.00,
        shipping: 0.00,
        cartTaxes: {},
        coupons: 0.00,
        giftCardApplied: 0.00,
        giftCardRemains:0.00,
        giftCards: [],
        // giftCards: 0.00,
        discount: 0.00,
        rates: {}
    },

    coupons: {
        sales: [],
        couponError: '',
        inputCoupon: JSON.parse(sessionStorage.getItem('inputCoupon')) || {},
    },

    giftCards: {
        cardList: JSON.parse(sessionStorage.getItem('giftCardList')) || [],
        cardError: '',
    },

    appliedDiscountCodes: [],
    taxes: {},
    carbonFee: 0,
    fuelSurcharge: 0,
    taxesLoading: true,
    error: false,
    errorMsg: '',
    isAddingToCart: false,
    addedToCart: false,
    bulkPricingShowModal: false,
    bulkPricingModalConfirmed: false,
}

function clamp(value, min, max) {
    let retVal = value
    if (value < min)
        retVal = min;
    else if (value > max)
        retVal = max
    return retVal;
}

const addToCart = (state, action) => {
    if (
        _.isEmpty(action.product) ||
        _.isEmpty(action.variant) ||
        !_.isString(action.qty) ||
        action.shippingMethod === "" ||
        !action.variant.inStock ||
        _.isUndefined(action.price)
    ) return state;

    let item;
    let itemIndex;

    item = state.cart.find(x =>
        x.product.docId === action.product.docId &&
        x.variant.id === action.variant.id &&
        x.shippingMethod === action.shippingMethod &&
        x.price.unit === action.price.unit)

    if (item) {
        itemIndex = state.cart.findIndex(x =>
            x.product.docId === action.product.docId &&
            x.variant.id === action.variant.id &&
            x.shippingMethod === action.shippingMethod &&
            x.price.unit === action.price.unit)
        item.qty = parseFloat(item.qty) + parseFloat(action.qty);
        state.cart[itemIndex] = item;
        if (item.qty >= 18 && !state.bulkPricingModalConfirmed)
            state.bulkPricingShowModal = true;
    }
    else {
        const itemToAdd = {
            'product': clone(action.product),
            'variant': clone(action.variant),
            'qty': clone(action.qty),
            'shippingMethod': clone(action.shippingMethod),
            'price': clone(action.price)
        };
        state.cart = state.cart.concat(itemToAdd);
    }

    sessionStorage.setItem('cart', JSON.stringify(state.cart));
    state.addedToCart = true;
    state.isAddingToCart = false;

    return {
        ...state,
        cart: state.cart,
        addedToCart: state.addedToCart,
        isAddingToCart: state.isAddingToCart,
        bulkPricingShowModal: state.bulkPricingShowModal
    };
}

const removeFromCart = (state, action) => {
    if (isNaN(action.index) ||
        isNull(action.index) ||
        isUndefined(action.index) ||
        isUndefined(state.cart))
        return state;

    //requiresMaterial check if theres no other materials in cart, cancel remove
    let materialRequired;
    let lastMaterial;
    let item = state.cart[action.index]
    let isDeliverMaterial = item.product.metadata.includes('isMaterial') && item.shippingMethod === 'Delivery';

    if (isDeliverMaterial) {
        materialRequired = state.cart.filter(item => item.product.metadata.includes('requiresMaterial') && item.shippingMethod === 'Delivery').length > 0;

        if (materialRequired) {
            lastMaterial = state.cart.filter(item => item.product.metadata.includes('isMaterial') && item.shippingMethod === 'Delivery').length === 1;

            if (lastMaterial) {
                return {
                    ...state,
                    error: true,
                    errorMsg: 'Cannot delete ' + item.product.name + ' as an item in your cart requires a delivered material',
                }
            }
        }
    }

    if (action.index > state.cart.length) return state;
    state.cart = state.cart.filter((_, i) => i !== action.index)
    sessionStorage.setItem('cart', JSON.stringify(state.cart));
    return {
        ...state,
        cart: state.cart,
        error: false,
        errorMsg: '',
    };
}

const resetCart = (state, action) => {

    sessionStorage.setItem('cart', JSON.stringify([]));
    sessionStorage.setItem('customerAddress', JSON.stringify(""))
    sessionStorage.setItem('customerLocation', JSON.stringify({ lat: 49.904415, lng: -96.932431 }));
    sessionStorage.setItem('searchboxCoordinates', JSON.stringify({ lat: 49.904415, lng: -96.932431 }));
    sessionStorage.setItem('validAddress', JSON.stringify(false));
    sessionStorage.setItem('validDeliveryDate', JSON.stringify(false));
    sessionStorage.setItem('validLocation', JSON.stringify(false));
    sessionStorage.setItem('alertActive', JSON.stringify(false));
    sessionStorage.setItem('distance', JSON.stringify({}));
    sessionStorage.setItem('bulkPricingModalConfirmed', JSON.stringify(false));
    sessionStorage.setItem('customerAddress', JSON.stringify(""));
    sessionStorage.setItem('giftCardList', JSON.stringify([]));
    sessionStorage.setItem('cartFees', JSON.stringify({
        subTotal: 0.00,
        discSubTotal: 0.00,
        total: 0.00,
        shipping: 0.00,
        cartTaxes: {},
        coupons: 0.00,
        discount: 0.00,
        giftCardApplied: 0.00,
        giftCardRemains:0.00,
        giftCards: {},
        //giftCards: 0.00,
        rates: {},
    }));
    sessionStorage.setItem('coupons', JSON.stringify({
        sales: [],
        couponError: '',
        inputCoupon: {},
    }));
    sessionStorage.setItem('inputCoupon', JSON.stringify({}));
    // sessionStorage.setItem('giftCards', JSON.stringify({
    //     inputCard: {},
    //     cardError: '',
    //     sales: [],
    // }));
    sessionStorage.setItem('inputCard', JSON.stringify({}));

    return {
        ...state,
        cart: [],
        customerAddress: "",
        customerLocation: { lat: 49.904415, lng: -96.932431 },
        searchboxCoordinates: { lat: 49.904415, lng: -96.932431 },
        validLocation: false,
        validAddress: false,
        validDeliveryDate: false,
        alertActive: false,
        distance: {},
        cartFees: {
            subTotal: 0.00,
            discSubTotal: 0.00,
            total: 0.00,
            shipping: 0.00,
            cartTaxes: {},
            coupons: 0.00,
            giftCardApplied: 0.00,
            giftCardRemains:0.00,
            giftCards: {},
            //giftCards: 0.00,
            discount: 0.00,
            rates: {},
        },
        coupons: {
            sales: [],
            couponError: '',
            inputCoupon: {},
        },
        giftCards: {
            cardList: [],
            cardError: '',
        },
        // giftCards: {
        //     inputCard: {},
        //     cardError: '',
        //     sales: [],
        // },
        appliedDiscountCodes: [],
    }
}

const incrQtyCart = (state, action) => {
    if (isNaN(action.index) ||
        isNull(action.index) ||
        isUndefined(action.index) ||
        isUndefined(state.cart))
        return state;
    
    if (action.index > state.cart.length) return state;

    var tempCart = clone(state.cart);
    tempCart[action.index].qty = parseFloat(tempCart[action.index].qty) +  (tempCart[action.index].price.unit.toLowerCase() === 'bag' || tempCart[action.index].price.unit.toLowerCase() === 'per bag' ? 1 
        : parseFloat(tempCart[action.index].product.qtyStep));
    

    sessionStorage.setItem('cart', JSON.stringify(tempCart));
    return { cart: tempCart };
}

const decrQtyCart = (state, action) => {
    if (isNaN(action.index) ||
        isNull(action.index) ||
        isUndefined(action.index) ||
        isUndefined(state.cart))
        return state;

    if (action.index > state.cart.length) return state;

    var tempCart = clone(state.cart);
    var newQty = parseFloat(tempCart[action.index].qty) - (tempCart[action.index].price.unit.toLowerCase() === 'bag' || tempCart[action.index].price.unit.toLowerCase() === 'per bag' ? 1 
    : parseFloat(tempCart[action.index].product.qtyStep));

    tempCart[action.index].qty = clamp(newQty, tempCart[action.index].product.qtyMin, Number.MAX_SAFE_INTEGER)

    sessionStorage.setItem('cart', JSON.stringify(tempCart));
    return { cart: tempCart };
}

const setSubtotal = (state, action) => {
    //Begin by getting a subtotal/carbon fees of all items in the cart
    let subTot = state.cart.reduce((sum, prod) => { return sum + prod.price.price * prod.qty }, 0.00);
    let discountSubTotal = 0.0;
    let discCarbonTot = 0.0;
    let palletFee = 0.00;
    let totalPallets = 0;
    let discount = 0.00;
    let discountApplied = false;
    let appliedDiscounts = [];
    let taxes = 0.0;
    let total = 0.0;
    let totalGiftCardDiscount = 0.00
    let totalGiftCardRemains = 0.00;

    const PALLETFEE = 35;
    
    //gets total of all applied gift cards
    if(state.giftCards.cardList.length > 0){
        state.giftCards.cardList.forEach((card) => {
            totalGiftCardDiscount += card.currentValue
        })
    }
    //Calculate the relevant taxes
    if (!_.isEmpty(state.taxes)) {
        let taxType = Object.keys(state.taxes);
        let taxTemp = {};
        taxType.forEach((val) => {
            taxTemp[val] = 0.0;
        })
        //Get the price & tax of each item and add it to the subtotal
        state.cart.forEach((product) => {

            let discountedPrice = product.price.price;
            let allDiscounts = [...state.coupons.sales];
            let totalPercentageDiscount = 0;
            let totalFlatDiscount = 0;
            let salesCouponsDiscount = 0;

            const MAX_PERCENTAGE = 10; //Max percentage off of a product price a customer can get. (With the exception of the special discount)

            !_.isEmpty(state.coupons.inputCoupon) && allDiscounts.push(state.coupons.inputCoupon)

            //loop through all sales and apply the total discount to the product
            allDiscounts.forEach(discount => {
                if (discount.global === true || discount.productsAffected.includes(product.variant.id) || (discount.category.onlyCategory === true && discount.category.value === product.product.category)) {
                    let discountApplicable = true;

                    if ('tags' in discount) {
                        if ('minQty' in discount.tags && (product.price.unit.toLowerCase() !== 'yard' || product.qty < discount.tags.minQty || product.shippingMethod !== "Delivery") ){
                            discountApplicable = false;
                        }
                            

                        if ('deliveryOnly' in discount.tags && product.shippingMethod !== "Delivery")
                           { discountApplicable = false;}
                    }

                    if (discountApplicable) {
                        switch (discount.discountType) {
                            case 'percentage':
                                totalPercentageDiscount += discount.discount;
                                break;
                            case 'flat':
                                totalFlatDiscount += discount.discount;
                                break;
                            default:
                                break;
                        }

                        discountApplied = true;
                        !appliedDiscounts.includes(discount) && appliedDiscounts.push(discount);
                    }
                }
            })

            //Add up the discount from percentage discount and flat rate discount
            salesCouponsDiscount = (product.price.price * (totalPercentageDiscount / 100)) + totalFlatDiscount;

            //Cap the discount from sales and coupons at 10%
            if (salesCouponsDiscount > product.price.price * (MAX_PERCENTAGE / 100)) {
                salesCouponsDiscount = product.price.price * (MAX_PERCENTAGE / 100)
            }

            //Calculate a special discount for 4 way mix/sandy loam/clay fill
            let specialDiscount = 0;
            //Special discount only applies if qty > 10 and is in yards
            if (product.qty >= 10 && product.price.unit.toLowerCase() === 'yard') {
                if(product.product?.discount && Number(product.product?.discount) > 0){
                    if(product.product?.discountType && product.product?.discountType === '%'){
                        let discountAsDecimal = (Number(product.product?.discount) / 100)
                        specialDiscount = Math.round((discountAsDecimal ) * product.price.price)
                    } else if(product.product?.discount && product.product?.discountType === '$' ) {
                        specialDiscount = Math.round((Number(product.product?.discount) / product.qty) * 100) / 100

                    } else {
                        let discountAsDecimal = (Number(product.product?.discount) / 100)
                        specialDiscount = Math.round((discountAsDecimal ) * product.price.price)
                    }
                }
            }
            //If a product uses the special discount, and the special discount > salesCouponDiscount, 
            //cap the discount at the special discount amount instead of MAX_PERCENTAGE
            if (specialDiscount > salesCouponsDiscount)
                discountedPrice -= specialDiscount
            else
                discountedPrice -= salesCouponsDiscount

            //Apply a pallet fee to pallet items via its unit
            if (product.price.unit.toLowerCase() === "pallet") {
                palletFee += PALLETFEE * product.qty;
            }
            else if (product.product.shippingClass === 'brick') {
                palletFee += PALLETFEE;
            }
            discountSubTotal += discountedPrice * product.qty;

            //only apply carbon fee to delivered items
            if (product.product.name !== 'Digital Gift Card') discCarbonTot += discountedPrice * state.carbonFee * product.qty;

            //Calclate the tax (with carbon tax) for each item in the cart
            product.product.taxClass.forEach((tax) => {
                taxTemp[tax] += state.taxes[tax] * product.qty * discountedPrice;
                taxTemp[tax] += Math.round(state.taxes[tax] * state.carbonFee * product.qty * discountedPrice * 100) / 100;
                taxTemp[tax] = Math.round(taxTemp[tax] * 100) / 100
            });

            if (product.price.unit.toLowerCase() === "pallet")
                totalPallets += parseInt(product.qty);
            else if (product.product.shippingClass === 'brick')
                totalPallets++;

        });

        discCarbonTot += (state.cartFees.shippingWithoutFuelSurcharge || 0.00) * state.carbonFee
        discCarbonTot = Math.round(discCarbonTot * 100) / 100;
        discountSubTotal = Math.round(discountSubTotal * 100) / 100
        state.cartFees.shipping = Math.round(state.cartFees.shipping * 100) / 100
        if (palletFee > 0) {
            state.cartFees.palletFee = {};
            state.cartFees.palletFee.palletStatus = "CHARGED";
            palletFee = palletFee + (state.taxes["GST"] * palletFee);
            state.cartFees.palletFee.totalFee = Math.round(palletFee * 100) / 100;
            state.cartFees.palletFee.totalPallets = totalPallets;
            state.cartFees.palletFee.remainingPallets = state.cartFees.palletFee.totalPallets;
            state.cartFees.palletFee.remainingPalletFee = state.cartFees.palletFee.totalFee;
            total += state.cartFees.palletFee.totalFee;
        }
        else
            delete state.cartFees.palletFee
        if (discountApplied) {
            discount = Math.round((subTot - discountSubTotal) * 100) / 100;
        }

        //Update the total with any discounts and clamp to prevent negative totals
        total += clamp((discountSubTotal), 0, Number.MAX_SAFE_INTEGER)

        //Shipping added independantly of discounts
        total += state.cartFees.shipping;

        //always charge gst on shipping
        taxTemp['GST'] += state.cartFees.shipping * state.carbonFee * state.taxes['GST']
        taxTemp['GST'] += state.cartFees.shipping * state.taxes['GST']

        taxes = taxTemp;
        taxes['Carbon Fees'] = discCarbonTot;
        //taxes['Fuel Surcharge'] = discFuelSurchargeTot;

        //Add taxes to the total
        Object.entries(taxTemp).forEach(([key, value]) => {
            //value = Math.round(value*100)/100;
            total += value;
        })
    }

    //remove giftCardDiscount to the total
    let totalAfterGC = Number(total).toFixed(2) - Number(totalGiftCardDiscount).toFixed(2)

    if(totalAfterGC <= 0.00 ){  
        totalGiftCardRemains = Number(Math.abs(totalAfterGC).toFixed(2))
    }
    else{
        totalGiftCardRemains = 0.00
    }

    //calculate the values being removed from each gift card
    let giftCardListContainer = state.giftCards.cardList
    let totalContainer = total

    giftCardListContainer.forEach((card) => {
        totalContainer -= card.currentValue

        if(totalContainer > 0){
            card.remainingValue = 0
        }else{
            card.remainingValue = Number(Math.abs(totalContainer).toFixed(2))
        }
    })

    //add to state giftCards object as its own property
    state.giftCards.cardList = giftCardListContainer;

    //material subtotal
    state.cartFees.subTotal = Math.round(subTot * 100) / 100;
    //material - coupons
    state.cartFees.discSubTotal = discountSubTotal;
    //total of coupons
    state.cartFees.coupons = discount;
    //final total
    state.cartFees.total = total;
    //total of gift card
    state.cartFees.giftCardApplied = Number(Math.abs(Number(totalGiftCardRemains) - Number(totalGiftCardDiscount)).toFixed(2))
    state.cartFees.giftCards = state.giftCards.cardList;
    state.cartFees.giftCardRemains = Number(totalGiftCardRemains);
    //all discount codes (both coupon and sales) applied
    state.appliedDiscountCodes = appliedDiscounts;
    //the taxes and carbon fee
    state.cartFees.cartTaxes = taxes;
    //the current rate of taxes / fees
    state.cartFees.rates = {
        GST: state.taxes.GST,
        PST: state.taxes.PST,
        carbonFee: state.carbonFee,
        fuelSurcharge: state.fuelSurcharge,
        brickFee: state.brickFee,
        baseDEFAULT: state.baseDEFAULT,
        baseSTONEWALL: state.baseSTONEWALL,
        deliveryMinimum: state.deliveryMinimum,
        deliveryLowRate: state.deliveryLowRate,
        deliveryMidRate: state.deliveryMidRate,
        deliveryHighRate: state.deliveryHighRate,
    }

    sessionStorage.setItem('cartFees', JSON.stringify(state.cartFees));

    return {
        ...state,
        cartFees: Object.assign({}, state.cartFees),
    };
}


const calcShipping = (state) => {
    let taxInfo = {};
    let taxArray = [];
    firestore.collection('shopSettings').where('__name__', '==', 'taxes').get()
    //.then(snapshot =>  taxInfo = snapshot.data())
        .then((querySnapshot) => {
            var response = (querySnapshot.docs.map((doc) => {
                return {
                    ...doc.data(),
                }
            }));

            //taxInfo = response[0];
       
            taxInfo = response[0]
            //response.forEach((tax) => {taxArray.push(tax)})
            taxArray.push(taxInfo)
      
    });
    if (isUndefined(state.distance) ||
        isUndefined(state.distance.rows) ||
        isUndefined(state.distance.rows[0]) ||
        isUndefined(state.distance.rows[0].elements[0]) ||
        isEmpty(state.distance.rows[0].elements[0]) 
        // ||
        // isEmpty(state.cart) ||
        // isUndefined(state.cart)
            )
        return state;

    let distanceMatrix = state.distance.rows[0].elements[0];
    let distanceMatrixStonewall = state.distance.rows[1].elements[0];
    let duration = distanceMatrix.duration.value / 60;
    let distance = distanceMatrix.distance.value / 1000; //converts to KM
    let durationStonewall = distanceMatrixStonewall.duration.value / 60;
    let distanceStonewall = distanceMatrixStonewall.distance.value / 1000;
    let baseShip = 0;
    let baseShipStonewall = 0;
    let loads = [];
    let loadsStonewall = [];
    let totalShipping = 0;
    let totalShippingStonewall = 0;
    let hasBricks = false;
    let hasMaterial = false;
    let hasMaterialSTONEWALL = false;
    let trucks = [];
    let trucksStonewall = [];

    //Constants
    const BASE = Number(state.baseDEFAULT) || 25.75; //25, +3% 05/05/2021
    const BASE_STONEWALL = Number(state.baseSTONEWALL) || 61.00;
    const LOW_DISTANCE = 20; //20KM
    const HIGH_DISTANCE = 40; //40KM
    const LOW_RATE = Number(state.deliveryLowRate) || 1.2875;  //1.25, +3% 05/05/2021
    const MID_RATE = Number(state.deliveryMidRate) || 1.4935;  //1.45, +3% 05/05/2021
    const HIGH_RATE = Number(state.deliveryHighRate) || 1.6995; //1.65, +3% 05/05/2021
    const MINIMUM_CHARGE = Number(state.deliveryMinimum) || 65.00; 
    /*
    CHANGED TO 65.00 TO MATCH THE DASHBOARD 04/27/2023
    CHANGED TO 60.00 FROM 57.50 05/05/2021 DEREK AT REQUEST OF DALLAS)
    */
    
    //TODO: USE FIRESTORE SETTINGS TO SET THESE VALUES
    const MAX_TRUCK_LOAD = 12;
    const MAX_TRAILER_LOAD = 20;
    const MAX_TRUCK_ADJUSTED_LOAD = 15; //Adjusted max load size for a tandem truck
    const MAX_TRAILER_ADJUSTED_LOAD = 30; //Adjusted max load size for a trailer
    const BRICK_FEE = Number(state.brickFee) || 75;

    const FUEL_SURCHARGE = Number(state.fuelSurcharge) || 0.10;
    
    if (distance <= LOW_DISTANCE)
        //baseShip = LOW_RATE;
        baseShip = BASE + ((duration*2)*LOW_RATE)
    if (distance > LOW_DISTANCE && distance <= HIGH_DISTANCE)
        //baseShip = MID_RATE;
        baseShip = BASE + ((duration*2)*MID_RATE)
    if (distance > HIGH_DISTANCE)
        //baseShip = HIGH_RATE;
        baseShip = BASE + ((duration*2)*HIGH_RATE)

    if (distanceStonewall <= LOW_DISTANCE)
        //baseShipStonewall = LOW_RATE;
        baseShipStonewall = BASE_STONEWALL + ((durationStonewall*2)*LOW_RATE)
    if (distanceStonewall > LOW_DISTANCE && distanceStonewall <= HIGH_DISTANCE)
        //baseShipStonewall = MID_RATE;
        baseShipStonewall = BASE_STONEWALL + ((durationStonewall*2)*MID_RATE)
    if (distanceStonewall > HIGH_DISTANCE)
        //baseShipStonewall = HIGH_RATE;
        baseShipStonewall = BASE_STONEWALL + ((durationStonewall*2)*HIGH_RATE)

    //baseShip = BASE + (baseShip * duration * 2);

    //baseShipStonewall = BASE_STONEWALL + (baseShipStonewall * durationStonewall * 2);

    if (baseShip < MINIMUM_CHARGE)
    {
        baseShip = MINIMUM_CHARGE;

    }
    
    
    if (baseShipStonewall < MINIMUM_CHARGE)
    {
        baseShipStonewall = MINIMUM_CHARGE;
  
    }
        
    
    let sortedCart = [...state.cart]
    //Determine how many yards exist
    sortedCart.sort((item1, item2) => item2.qty - item1.qty).forEach((item) => {
        switch (item.product.shippingClass) {
            case 'brick':
                if (item.shippingMethod !== 'Pickup')
                    hasBricks = true;
                break;
            default:
                if (item.qty > 0 && item.shippingMethod !== 'Pickup' && item.product.metadata.includes('isMaterial') && item.price.unit.toLowerCase() === 'yard' 
                 ) {
                    if(item.product.docId === 'limestone' && item.price.unit === 'Trailer Load'){
                        let remainingSTONEWALL = parseFloat(item.qty);
                        let usesAdjustedLoadSTONEWALL = false;

                        hasMaterialSTONEWALL = true;

                        while (remainingSTONEWALL > 0) {
                            let load = 0;
                            let loadType;
    
                            if (usesAdjustedLoadSTONEWALL) {
                                if (remainingSTONEWALL > MAX_TRUCK_ADJUSTED_LOAD)
                                    load = remainingSTONEWALL > MAX_TRAILER_ADJUSTED_LOAD ? MAX_TRAILER_ADJUSTED_LOAD : remainingSTONEWALL
                                else
                                    load = remainingSTONEWALL > MAX_TRUCK_ADJUSTED_LOAD / 2 ? MAX_TRUCK_ADJUSTED_LOAD / 2 : remainingSTONEWALL
    
                                loadType = remainingSTONEWALL > MAX_TRUCK_ADJUSTED_LOAD ? "Trailer Load" : "Tandem Load"
                            }
                            else {
                                if (remainingSTONEWALL > MAX_TRUCK_LOAD)
                                    load = remainingSTONEWALL > MAX_TRAILER_LOAD ? MAX_TRAILER_LOAD : remainingSTONEWALL;
                                else
                                    load = remainingSTONEWALL > MAX_TRUCK_LOAD / 2 ? MAX_TRUCK_LOAD / 2 : remainingSTONEWALL
    
                                loadType = remainingSTONEWALL > MAX_TRUCK_LOAD ? "Trailer Load" : "Tandem Load"
                            }
    
                            loadsStonewall.push({
                                loadType: loadType,
                                name: item.product.name + " " + Object.values(item.variant.variantTypes).filter(variant => variant !== "NOVARIANT").join(", "),
                                qty: load
                            });
    
                            remainingSTONEWALL -= load;
                        }
                    }
                    if(((item.product.docId !== 'limestone' && item.price.unit !== 'Trailer Load') || (item.product.docId === 'limestone' && item.price.unit !== 'Trailer Load'))){
                        let remaining = parseFloat(item.qty);
                        let usesAdjustedLoad = false;
    
                        hasMaterial = true;
    
                        //Mulches/Four Way Mix can fit more per volume and are adjusted loads
                        if (item.product.category === 'Mulch' || item.product.docId === 'fourWayMix') {
                            usesAdjustedLoad = true;
                        }
    
                        while (remaining > 0) {
                            let load = 0;
                            let loadType;
    
                            if (usesAdjustedLoad) {
                                if (remaining > MAX_TRUCK_ADJUSTED_LOAD)
                                    load = remaining > MAX_TRAILER_ADJUSTED_LOAD ? MAX_TRAILER_ADJUSTED_LOAD : remaining
                                else
                                    load = remaining > MAX_TRUCK_ADJUSTED_LOAD / 2 ? MAX_TRUCK_ADJUSTED_LOAD / 2 : remaining
    
                                loadType = remaining > MAX_TRUCK_ADJUSTED_LOAD ? "Trailer Load" : "Tandem Load"
                            }
                            else {
                                if (remaining > MAX_TRUCK_LOAD)
                                    load = remaining > MAX_TRAILER_LOAD ? MAX_TRAILER_LOAD : remaining;
                                else
                                    load = remaining > MAX_TRUCK_LOAD / 2 ? MAX_TRUCK_LOAD / 2 : remaining
    
                                loadType = remaining > MAX_TRUCK_LOAD ? "Trailer Load" : "Tandem Load"
                            }
    
                            loads.push({
                                loadType: loadType,
                                name: item.product.name + " " + Object.values(item.variant.variantTypes).filter(variant => variant !== "NOVARIANT").join(", "),
                                qty: load
                            });
    
                            remaining -= load;
                        }
                    }
                    
                }
                else if (item.qty > 0 && item.shippingMethod !== 'Pickup' && item.price.unit === "Trailer Load") {
                    for (let i = 0; i < item.qty; i++)
                        if(item.product.docId === 'limestone'){
                            loadsStonewall.push({
                                loadType: "Trailer Load",
                                name: item.product.name + " " + Object.values(item.variant.variantTypes).filter(variant => variant !== "NOVARIANT").join(", "),
                                qty: item.product.category === 'Mulch' || item.product.docId === 'fourWayMix' ? MAX_TRAILER_ADJUSTED_LOAD : MAX_TRAILER_LOAD
                            });
                        }
                        else{
                            loads.push({
                            loadType: "Trailer Load",
                            name: item.product.name + " " + Object.values(item.variant.variantTypes).filter(variant => variant !== "NOVARIANT").join(", "),
                            qty: item.product.category === 'Mulch' || item.product.docId === 'fourWayMix' ? MAX_TRAILER_ADJUSTED_LOAD : MAX_TRAILER_LOAD
                        });
                    }
                }
                break;
        }
    });

    //loads.length > 0 && trucks.push({ slot1: {}, slot2: {} });

    /* Four types of trucks to keep track of:
    *  1-12 yards single product
    *  13-20 yards single product
    *  6/6 split less than 
    */

    let numFullTandems = 0;
    let numSplitTandems = 0;
    let numTrailers = 0;

    let numFullTandemsSTONEWALL = 0;
    let numSplitTandemsSTONEWALL = 0;
    let numTrailersSTONEWALL = 0;


    loads.forEach((load, index) => {

        let truck = {};

        switch (load.loadType) {
            case "Trailer Load":
                truck.type = 'Trailer';
                truck.load = load;
                numTrailers++;
                break;
            case "Tandem Load":
                //Search for a tandem truck with a spare slot
                let spareTrucks = trucks.filter(truck => truck.type === "Tandem Truck" && _.isEmpty(truck.slot2))

                if (spareTrucks.length > 0) {
                    spareTrucks[0].slot2 = load;
                    spareTrucks[0].splitProducts = spareTrucks[0].slot1.name !== spareTrucks[0].slot2.name //Charge 2x cartage for truck with split products

                    if (spareTrucks[0].splitProducts) {
                        numSplitTandems++
                        numFullTandems--
                    }
                }
                //Generate a new truck
                else {
                    truck.type = 'Tandem Truck';
                    truck.slot1 = load;
                    truck.slot2 = {};
                    truck.splitProducts = false;

                    numFullTandems++;
                }
                break;
            default:
                break;
        }

        !_.isEmpty(truck) && trucks.push(truck)
    })

    loadsStonewall.forEach((load, index) => {

        let truck = {};

        switch (load.loadType) {
            case "Trailer Load":
                truck.type = 'Trailer';
                truck.load = load;
                numTrailersSTONEWALL++;
                break;
            case "Tandem Load":
                //Search for a tandem truck with a spare slot
                let spareTrucks = trucksStonewall.filter(truck => truck.type === "Tandem Truck" && _.isEmpty(truck.slot2))

                if (spareTrucks.length > 0) {
                    spareTrucks[0].slot2 = load;
                    spareTrucks[0].splitProducts = spareTrucks[0].slot1.name !== spareTrucks[0].slot2.name //Charge 2x cartage for truck with split products

                    if (spareTrucks[0].splitProducts) {
                        numSplitTandemsSTONEWALL++
                        numFullTandemsSTONEWALL--
                    }
                }
                //Generate a new truck
                else {
                    truck.type = 'Tandem Truck';
                    truck.slot1 = load;
                    truck.slot2 = {};
                    truck.splitProducts = false;

                    numFullTandemsSTONEWALL++;
                }
                break;
            default:
                break;
        }

        !_.isEmpty(truck) && trucksStonewall.push(truck)
    })
    
    if (hasMaterial || loads.length > 0)
        totalShipping = baseShip * ((numSplitTandems * (numSplitTandems === 1 ? 1.5 : 1.0)) + (numTrailers * 1.5) + numFullTandems);
        totalShipping += parseFloat(6.00);
        //WE ADD ARBITRARY $6.00 TO THE TOTAL SHIPPING COST BECAUSE I FORGET WHY BUT IT'S NECESSARY OOPS
        
        
    if (hasMaterialSTONEWALL || loadsStonewall.length > 0)
        totalShippingStonewall = baseShipStonewall * ((numSplitTandemsSTONEWALL * (numSplitTandemsSTONEWALL === 1 ? 1.5 : 1.0)) + (numTrailersSTONEWALL * 1.5) + numFullTandemsSTONEWALL);
    if (hasBricks)
        totalShipping += BRICK_FEE;

    if (totalShipping < MINIMUM_CHARGE && totalShipping > 0.00) {
        totalShipping = MINIMUM_CHARGE;
    }

    if (totalShippingStonewall < MINIMUM_CHARGE && totalShippingStonewall > 0.00) {
        totalShippingStonewall = MINIMUM_CHARGE;
    }
    
    totalShipping = 
    //if item is delivery, not limestone and not trailer load
    state.cart.filter(item => ((item.shippingMethod === "Delivery" && item.product.docId !== 'limestone' && item.price.unit !== 'Trailer Load') 
    || (item.shippingMethod === "Delivery" && item.product.docId === 'limestone' && item.price.unit !== 'Trailer Load') 
    || (item.shippingMethod === "Delivery")
    ) ).length > 0 ? totalShipping : Math.round(totalShipping / 5) * 5;


 
    totalShippingStonewall = state.cart.filter(item => item.shippingMethod === "Delivery" && item.product.docId === 'limestone' && item.price.unit === 'Trailer Load').length > 0 ? totalShippingStonewall : Math.round(totalShippingStonewall / 5) * 5;
    


    //FINAL CHECK ON SHIPPING IF THEYRE REQUIRED
    totalShippingStonewall = state.cart.filter(item => item.shippingMethod === "Delivery" && item.product.docId === 'limestone' && item.price.unit === 'Trailer Load').length > 0 ? totalShippingStonewall-3.33 : 0;
    totalShipping = state.cart.filter(item => (item.shippingMethod === "Delivery" && item.product.docId !== 'limestone' && item.price.unit !== 'Trailer Load') 
    || (item.shippingMethod === "Delivery" && item.product.docId === 'limestone' && item.price.unit !== 'Trailer Load')  || (item.shippingMethod === "Delivery" && item.product.docId !== 'limestone' && item.price.unit === 'Trailer Load')).length > 0 ? totalShipping : 0;
    
    //CALC FUEL SURCHARGE
    state.taxes['Fuel Surcharge'] = (totalShipping + totalShippingStonewall) * FUEL_SURCHARGE;
    let fuelSurcharge = (totalShipping + totalShippingStonewall) * FUEL_SURCHARGE;

    //combine shipping costs if applicable
    state.cart.filter(item => item.shippingMethod === "Delivery" && item.product.docId === 'limestone' && item.price.unit === 'Trailer Load').length > 0 ? totalShipping += totalShippingStonewall : totalShipping += 0;
    //add fuel surcharge to shpping total
    totalShipping = state.cart.filter(item => item.shippingMethod === "Delivery" && item.product.docId !== 'limestone' && item.price.unit !== 'Trailer Load').length > 0 ? totalShipping += ((totalShipping) * FUEL_SURCHARGE) : totalShipping;

    let shippingInformation = {
        trucks: trucks.concat(trucksStonewall),
        numFullTrucks: numSplitTandems + numSplitTandemsSTONEWALL,
        numHalfTrucks: numFullTandems + numFullTandemsSTONEWALL,
        numTrailers: numTrailers + numTrailersSTONEWALL,
        brickFee: hasBricks ? BRICK_FEE : 0
    }

    sessionStorage.setItem('cartFees', JSON.stringify({ ...state.cartFees, shipping: totalShipping, shippingInformation: shippingInformation }));
   
    return {
        cartFees: {
            ...state.cartFees,
            shipping: totalShipping,
            shippingWithoutFuelSurcharge: totalShipping - fuelSurcharge,
            shippingStonewall: state.cart.filter(item => item.shippingMethod === "Delivery" && item.product.docId === 'limestone' && item.price.unit === 'Trailer Load').length > 0 ? totalShippingStonewall : 0,
            shippingInformation: shippingInformation,            
            // GSTPercent: taxInfo.GST,
            // PSTPercent: taxInfo.PST,
            // FuelSurchargePercent: taxInfo.fuelSurcharge,
            // CarbonTaxPercent: taxInfo.carbonFee,
            fuelSurcharge: Number(fuelSurcharge.toFixed(2)),
        },

    };
}

const setAddress = (state, action) => {

    let distanceResponse = setDistance(action.distance, "INPUT_SEARCHBOX")

    sessionStorage.setItem('customerAddress', JSON.stringify(action.customerAddress));

    if (distanceResponse.validAddress) {
        sessionStorage.setItem('customerLocation', JSON.stringify(action.searchboxCoordinates));
        sessionStorage.setItem('distance', JSON.stringify(distanceResponse.distance));
        sessionStorage.setItem('searchboxCoordinates', JSON.stringify(action.searchboxCoordinates));
        sessionStorage.setItem('validAddress', JSON.stringify(distanceResponse.validAddress));
        sessionStorage.setItem('validLocation', JSON.stringify(distanceResponse.validLocation));
        sessionStorage.setItem('alertActive', JSON.stringify(true));
        sessionStorage.setItem('alertMsg', JSON.stringify(distanceResponse.alertMsg));
        sessionStorage.setItem('alertSev', JSON.stringify(distanceResponse.alertSev));
    }

    return {
        ...state,
        customerAddress: action.customerAddress,
        customerLocation: action.searchboxCoordinates,
        distance: distanceResponse.distance,
        searchboxCoordinates: action.searchboxCoordinates,
        validAddress: distanceResponse.validAddress,
        validLocation: distanceResponse.validLocation,
        alertActive: true,
        alertMsg: distanceResponse.alertMsg,
        alertSev: distanceResponse.alertSev,
    };
}

const setCoordinates = (state, action) => {

    let distanceResponse = setDistance(action.distance, "INPUT_MARKER")

    if (distanceResponse.validAddress) {
        sessionStorage.setItem('customerLocation', JSON.stringify(action.searchboxCoordinates));
        sessionStorage.setItem('distance', JSON.stringify(distanceResponse.distance));
        sessionStorage.setItem('validLocation', JSON.stringify(distanceResponse.validLocation));

        sessionStorage.setItem('alertActive', JSON.stringify(true));
        sessionStorage.setItem('alertMsg', JSON.stringify(distanceResponse.alertMsg));
        sessionStorage.setItem('alertSev', JSON.stringify(distanceResponse.alertSev));
    }

    return {
        ...state,
        customerLocation: action.customerLocation,
        distance: distanceResponse.distance,
        validLocation: distanceResponse.validLocation,
    
        alertActive: true,
        alertMsg: distanceResponse.alertMsg,
        alertSev: distanceResponse.alertSev,
    };
}
const setDistance = (distance, inputType) => {
    let response = {
        distance: {},
        alertMsg: "",
        alertSev: "",
    }

    if (distance.rows) {
        if (distance.rows[0].elements[0].status !== 'ZERO_RESULTS' && distance.rows[0].elements[0].distance.value / 1000 < 100) {

            switch (inputType) {
                case "INPUT_SEARCHBOX":
                    response.validAddress = true;
                    response.validLocation = true;
                    break;
                case "INPUT_MARKER":
                    response.validLocation = true;
                    break;
                default:
                    break;
            }

            response.distance = distance;
            response.alertMsg = 'Location successfully updated, shipping costs have been adjusted.';
            response.alertSev = 'success';
        }
        else {
            if (distance.rows[0].elements[0].status === 'ZERO_RESULTS')
                response.alertMsg = 'We are unable to deliver to that location, please contact us for more information.'
            else
                response.alertMsg = 'We are unable to deliver to that location as it is outside of our delivery range.'

            response.alertSev = 'warning';

            switch (inputType) {
                case "INPUT_SEARCHBOX":
                    response.validAddress = false;
                    response.validLocation = false;
                    break;
                case "INPUT_MARKER":
                    response.validLocation = false;
                    break;
                default:
                    break;
            }
        }
    }

    return {
        ...response
    };
}

const getSales = (state, action) => {

    let sales = [];

    if (action.sales)
        sales = action.sales;
    return {
        ...state,
        coupons: {
            ...state.coupons,
            sales: sales,
        },
    }
}

const setGiftCard = (state, action) => {

    let newGiftCard = {};
    let cardListContainer = [...state.giftCards.cardList];
    let cardError = "";

    //ensure cannot use same gift card twice (here to have access to current state)
    if (!_.isEmpty(action.giftCard) && !state.giftCards.cardList.map((card) => {return card.code}).includes(action.giftCard.code) ){
        newGiftCard = action.giftCard;
        cardListContainer = [...cardListContainer, newGiftCard]; 
        sessionStorage.setItem('giftCardList', JSON.stringify(cardListContainer));
    }
    else {
        newGiftCard = {}
        //sessionStorage.setItem('giftCardList', JSON.stringify(newGiftCard));
        cardError = "Invalid gift card code";
    }

    return {
        ...state,
        giftCards: {
            ...state.giftCards,
            cardList: cardListContainer,
            cardError: cardError,
            //inputGiftCard: newGiftCard,
        }
    }
}

const setCoupon = (state, action) => {

    let newCoupon = {};
    let couponError = "";

    if (!_.isEmpty(action.coupon)) {
        newCoupon = action.coupon;
        sessionStorage.setItem('inputCoupon', JSON.stringify(newCoupon));
    }
    else {
        newCoupon = {}
        sessionStorage.setItem('inputCoupon', JSON.stringify(newCoupon));
        couponError = "Invalid coupon code";
    }

    return {
        ...state,
        coupons: {
            ...state.coupons,
            couponError: couponError,
            inputCoupon: newCoupon,
        }
    }
}

const removeCoupon = (state, action) => {

    if (isUndefined(state.coupons.inputCoupon))return state;

    //if (action.index > _.size(state.coupons.inputCoupon)) return state;
    //state.coupons.inputCoupon = state.coupons.inputCoupon.filter((_, i) => i !== action.index)

    sessionStorage.setItem('inputCoupon', JSON.stringify({}));

    return {
        ...state,
        coupons: {
            ...state.coupons,
            inputCoupon: {},
        }
    };
}

const confirmBulkPricing = (state, action) => {

    //sessionStorage.setItem("bulkPricingModalConfirmed", JSON.stringify(true));

    return {
        ...state,
        bulkPricingModalConfirmed: true,
        bulkPricingShowModal: false
    }
}

const cartReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.ADD_TO_CART:
            return updateObject(state, addToCart(state, action));
        case actionTypes.REMOVE_FROM_CART:
            return updateObject(state, removeFromCart(state, action));
        case actionTypes.RESET_CART:
            return updateObject(state, resetCart(state, action));
        case actionTypes.INCR_QTY_CART:
            return updateObject(state, incrQtyCart(state, action));
        case actionTypes.DECR_QTY_CART:
            return updateObject(state, decrQtyCart(state, action));
        case actionTypes.SET_SUBTOTAL:
            return updateObject(state, setSubtotal(state, action));
        case actionTypes.FETCH_TAX:
            return updateObject(state, { taxesLoading: action.taxesLoading });
        case actionTypes.GET_TAX:
            return updateObject(state, 
                { taxesLoading: action.taxesLoading, 
                    taxes: action.taxes, 
                    carbonFee: action.carbonFee, 
                    fuelSurcharge: action.fuelSurcharge, 
                    brickFee: action.brickFee, 
                    baseDEFAULT: action.baseDEFAULT,
                    baseSTONEWALL: action.baseSTONEWALL,
                    deliveryMinimum: action.deliveryMinimum, 
                    deliveryLowRate: action.deliveryLowRate, 
                    deliveryMidRate: action.deliveryMidRate, 
                    deliveryHighRate: action.deliveryHighRate, 

                });
        case actionTypes.CALC_SHIPPING:
            return updateObject(state, calcShipping(state, action));
        case actionTypes.GET_SALES:
            return updateObject(state, getSales(state, action));
        case actionTypes.SET_GIFT_CARD:
            return updateObject(state, setGiftCard(state, action));
        case actionTypes.SET_COUPON:
            return updateObject(state, setCoupon(state, action));
        case actionTypes.REMOVE_COUPON:
            return updateObject(state, removeCoupon(state, action));
        case actionTypes.SET_ADDRESS:
            return updateObject(state, setAddress(state, action))
        case actionTypes.SET_COORDINATES:
            return updateObject(state, setCoordinates(state, action))
        case actionTypes.CLEAR_IS_ADDED:
            return updateObject(state, { isAddingToCart: false, addedToCart: false });
        case actionTypes.CONFIRM_BULK_PRICING:
            return updateObject(state, confirmBulkPricing(state, action));
        default:
            return state;
    }
};

export default cartReducer