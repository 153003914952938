import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import ChecklistReportPDF from '../../../components/Reports/Checklists/ChecklistReportPDF'
import { Skeleton } from '@material-ui/lab';
import { makeStyles } from '@material-ui/core';
import { firestore } from '../../../../firebase/firebase'

const useStyles = makeStyles((theme) => ({
    content: {
        display: 'flex',
        width: '100%',
        padding: '32px',
        justifyContent: 'center',
        marginTop: '40px',
    },
}));

function ChecklistTablePrint(props) {
    const [checklistData, setChecklist] = useState({});
    const [loading, setLoading] = useState([]);
    const [userData, setUserData] = useState({});

    const classes = useStyles();
    useEffect(() => {
        setLoading(true)
        let user = props.location.state.uid
        firestore.collection('users').where("uuid", "==", user).get() 
            .then(querySnapshot => {
                const userResponse = querySnapshot.docs.map((doc) => {
                    return {
                        ...doc.data(),
                        'docId': doc.id,
                    }
                })
                setUserData(userResponse[0]);
                setChecklist(props.location.state)
                setLoading(false)
            })
            .catch(e => {
                console.log(e.message);
            })
    }, [props.location.state])

    


    return (
        loading
            ? 
            <div className={classes.content}>
                <Skeleton variant='rect' width={'63vw'} height={'80vh'} />
            </div>
            : 
            <React.Fragment>
                <ChecklistReportPDF
                checklistData={checklistData}
                userData={userData} />
            </React.Fragment>
    )
}

export default withRouter(ChecklistTablePrint)