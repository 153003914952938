import React, { useState } from 'react';
import { Button, Card, CardActionArea, CardContent, Fade, Grid, makeStyles, Modal, Paper, TextField, Typography } from '@material-ui/core';
import { withRouter } from 'react-router';
import moment from 'moment'
import { firestore, uploadFile, removeFile } from '../../../../firebase/firebase'
import { useSalesEquipment } from '../../../../providers/SalesEquipmentContext';
import MaterialTable from '@material-table/core';
import { v4 as uuidv4 } from 'uuid'
import { TableIcons } from '../../../../constants/tableIcons';
import { isString } from 'lodash';
import { useAuth } from '../../../../providers/AuthContext';
import { Skeleton } from '@material-ui/lab';
import AddBoxIcon from '@material-ui/icons/AddBox';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';

const useStyles = makeStyles((theme) => ({

    card: {
        padding: theme.spacing(4),
        width: '100%',
        height: '100%',
    },

    content: {
        display: 'flex',
        justifyContent: 'center',
        width: '100%',
    },

    grid: {
        marginTop: theme.spacing(2),
        maxWidth: '1200px',
    },

    table: {
        display: 'flex',
        justifyContent: 'center',
    },

    fullImage: {
        maxWidth: '100%',
        maxHeight: '100%',
    },

    img: {
        maxWidth: "150px",
        maxHeight: '150px',
    },

    newImage: {
        position: 'absolute',
        maxWidth: '100%',
        maxHeight: '100%',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)'
    },
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    photoTile: {
        display: 'flex',
        width: '100%',
        paddingTop: '100%',
        position: 'relative',
    },
    paper: {
        padding: theme.spacing(2),
        width: '100%',
        maxWidth: '700px'
    },

    tile: {
        width: '300px',
        height: '300px',
    },

    tileContent: {
        display: 'flex',
        justifyContent: 'space-evenly',
        alignItems: 'center',
        flexDirection: 'column',
        width: '100%',
        height: '100%',
        padding: theme.spacing(4),
    },

    tileIcon: {
        color: 'rgba(0, 0, 0, 0.54)',
        width: '100px',
        height: '100px',
    },

    tileText: {
        fontSize: '1.10rem',
    },
    btn: {
        marginBottom: '20px',
        marginTop: '20px',
    },
}));

const SalesEquipmentHome = (props) => {
    const { salesEquipment, loading } = useSalesEquipment();
    const [saving, setSaving] = useState(false);
    const [docID, setDocID] = useState('');
    const [open, setOpen] = useState(false);
    const [deleteOpen, setDeleteOpen] = useState(false);
    const [searchBarValue, setSearchBarValue] = useState("salesEquipmentSearchBarValue" in sessionStorage ? JSON.parse(sessionStorage.getItem("salesEquipmentSearchBarValue")) : null)
    
    const { roles } = useAuth();
    const [equipment, setEquipment] = useState({
        docId: uuidv4(),
        photoURL: "",
        name: "",
        description: "",
        price: "",
    })

    const classes = useStyles();

    //const tileClickHandler = (path) => {
    //    props.history.push({
    //        pathname: 'equipment/' + path,
    //    })
    //};

    const editProduct = (docId) => {
        setEquipment(salesEquipment.filter((equipment) => equipment.docId === docId)[0])
        setOpen(true)
    }

    const handleClose = () => {
        if (!saving) {
            setOpen(false);
            setEquipment({
                docId: uuidv4(),
                photoURL: "",
                name: "",
                price: "",
            });
        }
    }

    const handleDelete = async (docId) => {
        setSaving(true);
        setDeleteOpen(false);
        handleClose();
        if(equipment?.photoURL){
            await removeFile(salesEquipment.filter((equipment) => equipment.docId === docId)[0].photoURL)
        }
        firestore.collection('equipmentSales').doc(docId).delete().then(() => {
            setSaving(false)
            handleClose();
            setDocID('');
            setDeleteOpen(false);
        }).catch((e) => {
            console.log(e)
            setSaving(false)
            setDeleteOpen(false);
        })
    }

    const createEquipment = async (event) => {
        event.preventDefault();
        setSaving(true);

        if (equipment.docId)
            await removeFile(equipment.photoURL)

        let path = 'SalesEquipment/' + equipment.docId + '/' + uuidv4();

        if (!isString(equipment.photoURL)) {
            equipment.photoURL = equipment.photoURL instanceof File && await uploadFile(path, equipment.photoURL)
            equipment.photoURL = equipment.photoURL.url
        }

        if (!('dateCreated' in equipment))
            equipment.dateCreated = moment().unix();

        firestore.collection('equipmentSales').doc(equipment.docId)
            .set(equipment)
            .then(() => {
                setSaving(false)
                handleClose();
            }).catch((e) => {
                console.log(e)
                setSaving(false)
            });
    }

    return (
        <div className={classes.content}>
            <Grid>
                {!loading ?
                    <div>
                    <Grid className={classes.btn} >
                        <Button variant="contained" disabled={!roles.includes('Create|Update|Delete Products')}
                        startIcon={<AddBoxIcon/>} onClick={() => { setOpen(true)}}>Create New Equipment</Button>
                    </Grid> 

                     <Grid item sm={12} xs={12} className={classes.table}>
                        <MaterialTable
                            columns={[
                                { title: '', render: rowData => rowData.photoURL ? <img className={classes.img} src={rowData.photoURL} alt="" /> : "" },
                                { title: 'Name', field: 'name' },
                                { title: 'Date Added', type: "date", render: rowData => rowData.dateCreated ? moment.unix(rowData.dateCreated).format('YYYY-MM-DD') : '' },
                                { title: 'Price', field: 'price', type: 'currency' },
                                { title: 'Sold?', render: rowData => rowData.sold ? "Yes" : "No" },
                                { title: 'Date Sold', type: "date", render: rowData => rowData.sellDate ? moment.unix(rowData.dateCreated).format('YYYY-MM-DD') : "" },
                            ]}
                            data={salesEquipment}
                            icons={TableIcons}
                            title={"Newest Equipment"}
                            onSearchChange={ (value) => {setSearchBarValue(value);  sessionStorage.setItem("salesEquipmentSearchBarValue", JSON.stringify(value))}}
                            localization={{
                                header: {
                                    actions: 'Actions'
                                }
                            }}
                            actions={[
                                {
                                    icon: TableIcons.OpenInNew,
                                    tooltip: 'Show Details',
                                    onClick: (event, rowData) => {editProduct(rowData.docId); setDocID(rowData.docId)}
                                },
                                {
                                    icon: TableIcons.Delete,
                                    tooltip: 'Delete Equipment',
                                    onClick: (event, rowData) => {setDeleteOpen(true); setDocID(rowData.docId)}
                                },
                            ]}
                            options={{
                                toolbar: true,
                                paging: true,
                                actionsColumnIndex: -1,
                                pageSizeOptions: false,
                                pageSize: 5,
                                search: true,
                                emptyRowsWhenPaging: false,
                                searchText: searchBarValue,
                                rowStyle: {
                                    whiteSpace: 'pre-wrap',
                                },
                            }}
                        />
                    </Grid>
                    </div>
                    : null}
            </Grid>
            <Modal
                open={deleteOpen}
                onClose={() => setDeleteOpen(false)}
                className={classes.modal}>
                <Fade in={deleteOpen}>
             
                        <Paper className={classes.paper}>
                            <Grid container spacing={1}>
                                <Grid item xs={12}>
                                    <Typography variant='h6' align='center'>Are you sure you want to delete this equipment?</Typography>
                                </Grid>
                                <Grid item sm={8}>
                                   
                                </Grid>
                                <Grid item sm={2}>
                                    <Button variant="contained" color="primary" onClick={() => handleDelete(docID)}>Yes</Button>
                                </Grid>
                                <Grid item sm={2}>
                                    <Button variant="contained" color="secondary" onClick={() => setDeleteOpen(false)}>No</Button>
                                </Grid>
                            </Grid>
                        </Paper>
            
                </Fade>
            </Modal>
            <Modal
                open={open}
                onClose={() => {handleClose(); setDocID('');}}
                className={classes.modal}>
                <Fade in={open}>
                    <form onSubmit={(event) => createEquipment(event)}>
                        <Paper className={classes.paper}>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <Typography variant='h6' align='center'>Create New Equipment for Sale</Typography>
                                </Grid>
                                <Grid item sm={6}>
                                    <Card className={classes.photoBack}>
                                        <CardActionArea style={{ display: 'initial' }} onClick={() => document.getElementById('upload-photo1').click()/*Find an alternative*/}>
                                            <CardContent className={classes.photoTile}>
                                                <input
                                                    style={{ display: 'none' }}
                                                    type="file"
                                                    disabled={!roles.includes('Create|Update|Delete Products')}
                                                    name="image"
                                                    id="upload-photo1"
                                                    accept="image/*"
                                                    onChange={(event) => setEquipment({ ...equipment, 'photoURL': event.target.files[0] })} />
                                                {equipment.photoURL === ''
                                                    ? <div className={classes.newImage}><Typography>Equipment Image</Typography></div>
                                                    : <img src={equipment.photoURL instanceof File ? URL.createObjectURL(equipment.photoURL) : equipment.photoURL} alt="" className={classes.newImage} />
                                                }
                                            </CardContent>
                                        </CardActionArea>
                                    </Card>
                                </Grid>
                                <Grid item sm={6}>
                                    <TextField
                                        label="Equipment Name"
                                        fullWidth
                                        variant='outlined'
                                        required
                                        disabled={!roles.includes('Create|Update|Delete Products')}
                                        value={equipment.name}
                                        onChange={(event) => setEquipment({ ...equipment, 'name': event.target.value })} />
                                    <TextField
                                        style={{ marginTop: '16px' }}
                                        label="Description"
                                        multiline
                                        disabled={!roles.includes('Create|Update|Delete Products')}
                                        rows={5}
                                        fullWidth
                                        variant='outlined'
                                        value={equipment.description}
                                        onChange={(event) => setEquipment({ ...equipment, 'description': event.target.value })} />
                                    <TextField
                                        style={{ marginTop: '16px' }}
                                        label="Price"
                                        type='number'
                                        disabled={!roles.includes('Create|Update|Delete Products')}
                                        inputProps={{
                                            step: 0.01
                                        }}
                                        fullWidth
                                        required
                                        variant='outlined'
                                        value={equipment.price}
                                        onChange={(event) => setEquipment({ ...equipment, 'price': event.target.value })} />
                                    <div style={{ height: '100%', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                        {saving && <Skeleton variant='rect'></Skeleton>}
                                    </div>

                                </Grid>
                                <Grid item xs={6}>
                                    <Button
                                        fullWidth
                                        onClick={() => handleClose()}
                                        variant='outlined'>
                                        Cancel
                                    </Button>
                                </Grid>
                                <Grid item xs={6}>
                                    <Button
                                        type='submit'
                                        disabled={!roles.includes('Create|Update|Delete Products')}
                                        fullWidth
                                        variant='contained'>
                                        Create Equipment
                                    </Button>
                                </Grid>
                                <Grid item xs={12}>
                                    <Button
                                        disabled={!roles.includes('Create|Update|Delete Products')}
                                        fullWidth
                                        startIcon={<DeleteForeverIcon />}
                                        onClick={() => setDeleteOpen(true)}
                                        style={{backgroundColor:'red', color:'white'}}
                                        variant='contained'>
                                        Delete
                                    </Button>
                                </Grid>
                            </Grid>
                        </Paper>
                    </form>
                </Fade>
            </Modal>
        </div>
    )
}

export default withRouter(SalesEquipmentHome);