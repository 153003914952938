import { Typography, Paper, Grid, IconButton, Divider } from '@material-ui/core'
import { Skeleton } from '@material-ui/lab'
import _ from 'lodash'
import React, { useState } from 'react'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';

const CheckoutCard = (props) => {

    const [discountCodeOpen, setDiscountCodeOpen] = useState(false)

    return (
        (props.loading || !props.order) ? <Skeleton></Skeleton> :

            <Paper elevation={2} style={{ padding: "16px", margin: "8px", color: '#707070' }}>
                <Typography variant='h6' >{`Checkout - Order # ${props.order.order.orderID}`}</Typography>

                <Grid container xs={12}>
                    <Grid item xs={6}>
                        <Typography style={{ lineHeight: 2, marginLeft:'10px'  }} variant='body1'>Material:</Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography align={"right"} style={{ lineHeight: 2 }} variant='body1'>${props.order.order.fees.subTotal.toFixed(2)}</Typography>
                    </Grid>

                    <Grid item xs={6}>
                        {props.order.order.fees.coupons > 0 && <Typography style={{ lineHeight: 2, marginLeft:'10px' }} variant='body1'>Discounts:</Typography>}
                    </Grid>
                    <Grid item xs={6}>
                        {props.order.order.fees.coupons > 0 && <Typography align={"right"} style={{ lineHeight: 2 }} variant='body1'>${props.order.order.fees.coupons.toFixed(2)}</Typography>}
                    </Grid>

                    <Grid item xs={6}>
                        {!_.isEmpty(props.order.order.fees.appliedDiscountCodes) && <Typography style={{ lineHeight: 2, marginLeft:'10px' }} variant='body1'>Discounts Applied:</Typography>}
                    </Grid>
                    <Grid item xs={6}>
                        {!_.isEmpty(props.order.order.fees.appliedDiscountCodes) &&
                        <Grid container justifyContent='flex-end'>
                            <IconButton 
                                onClick={() => {setDiscountCodeOpen(!discountCodeOpen)}}
                                size='small'
                            >
                                {discountCodeOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                            </IconButton>
                        </Grid>
                        }
                        {(discountCodeOpen && !_.isEmpty(props.order.order.fees.appliedDiscountCodes))
                            && props.order.order.fees.appliedDiscountCodes.map((val) => {
                                return (<Typography noWrap align={"right"} style={{ lineHeight: 2, marginLeft:'10px',}} variant='body1'>{val.code}</Typography>)
                            })
                        }
                    </Grid>
                    <Grid item xs={6}>
                        {props.order.order.fees.coupons > 0 && <Typography style={{ lineHeight: 2, fontWeight: 'bold'  }} variant='body1'>After Discount:</Typography>}
                    </Grid>
                    <Grid item xs={6}>
                        {props.order.order.fees.coupons > 0 && <Typography align={"right"} style={{ lineHeight: 2,fontWeight: 'bold' }} variant='body1'><del><small style={{color:'grey'}}>${props.order.order.fees.subTotal.toFixed(2)}</small></del>&nbsp;${(props.order.order.fees.discSubTotal).toFixed(2)}</Typography>}
                    </Grid>

                    <Grid item xs={6}>
                        {props.order.order.fees?.shippingWithoutFuelSurcharge > 0 && <Typography style={{ lineHeight: 2, marginLeft:'10px'  }} variant='body1'>Shipping:</Typography>}
                    </Grid>
                    <Grid item xs={6}>
                        {props.order.order.fees?.shippingWithoutFuelSurcharge > 0 && <Typography align={"right"} style={{ lineHeight: 2 }} variant='body1'>${props.order.order.fees.shippingWithoutFuelSurcharge.toFixed(2)}</Typography>}
                    </Grid>

                    <Grid item xs={6}>
                        {props.order.order.fees?.fuelSurcharge > 0 && <Typography style={{ lineHeight: 2, marginLeft:'10px'  }} variant='body1'>Fuel Surcharge:</Typography>}
                    </Grid>
                    <Grid item xs={6}>
                        {props.order.order.fees?.fuelSurcharge > 0 && <Typography align={"right"} style={{ lineHeight: 2 }} variant='body1'>${props.order.order.fees.fuelSurcharge.toFixed(2)}</Typography>}
                    </Grid>

                    <Grid item xs={6}>
                        {props.order.order.fees.shipping > 0 && <Typography style={{ lineHeight: 2, fontWeight: 'bold' }} variant='body1'>Total Shipping:</Typography>}
                    </Grid>
                    <Grid item xs={6}>
                        {props.order.order.fees.shipping > 0 && <Typography align={"right"} style={{ lineHeight: 2, fontWeight: 'bold' }} variant='body1'>${props.order.order.fees.shipping.toFixed(2)}</Typography>}
                    </Grid>

                    <Grid item xs={6}>
                        {props.order.order.fees.cartTaxes['Carbon Fees'] > 0 && <Typography style={{ lineHeight: 2, marginLeft:'10px'  }} variant='body1'>Carbon Fee:</Typography>}
                    </Grid>
                    <Grid item xs={6}>
                        {props.order.order.fees.cartTaxes['Carbon Fees'] > 0 && <Typography align={"right"} style={{ lineHeight: 2 }} variant='body1'>${props.order.order.fees.cartTaxes['Carbon Fees'].toFixed(2)}</Typography>}
                    </Grid>

                    <Grid item xs={6}>
                        {props.order.order.fees.palletFee && <Typography style={{ lineHeight: 2, marginLeft:'10px'  }}>Pallet Deposit:</Typography>}
                    </Grid>
                    <Grid item xs={6}>
                        {props.order.order.fees.palletFee && <Typography align={"right"} style={{ lineHeight: 2 }} variant='body1'>${props.order.order.fees.palletFee.totalFee.toFixed(2)}</Typography>}
                    </Grid>

                    <Grid item xs={6}>
                        {props.order.order.fees.cartTaxes['GST'] > 0 && <Typography style={{ lineHeight: 2, marginLeft:'10px'  }} variant='body1'>GST:</Typography>}
                    </Grid>
                    <Grid item xs={6}>
                        {props.order.order.fees.cartTaxes['GST'] > 0 && <Typography align={"right"} style={{ lineHeight: 2 }} variant='body1'>${props.order.order.fees.cartTaxes['GST'].toFixed(2)}</Typography>}
                    </Grid>

                    <Grid item xs={6}>
                        {props.order.order.fees.cartTaxes['PST'] > 0 && <Typography style={{ lineHeight: 2, marginLeft:'10px'  }} variant='body1'>PST:</Typography>}
                    </Grid>
                    <Grid item xs={6}>
                        {props.order.order.fees.cartTaxes['PST'] > 0 && <Typography align={"right"} style={{ lineHeight: 2 }} variant='body1'>${props.order.order.fees.cartTaxes['PST'].toFixed(2)}</Typography>}
                    </Grid>

                    <Grid item xs={6}>
                        {props.order.order.fees.giftCardApplied > 0 && <Typography style={{ lineHeight: 2, fontWeight: 'bold' }} variant='body1'>Gift Card Applied:</Typography>}
                    </Grid>
                    <Grid item xs={6}>
                        {props.order.order.fees.giftCardApplied > 0 && <Typography align={"right"} style={{ lineHeight: 2, fontWeight: 'bold' }} variant='body1'>${props.order.order.fees.giftCardApplied.toFixed(2)}</Typography>}
                    </Grid>
                    <Grid item xs={6}>
                        <Typography style={{ lineHeight: 2, fontWeight: 'bold' }} variant='body1'>Total:</Typography>
                    </Grid>
                    <Grid item xs={6}>
                        { props.order.order.fees.giftCardApplied > 0 
                        ?
                        <Typography align={"right"} style={{ lineHeight: 2,fontWeight: 'bold' }} variant='body1'><del><small style={{color:'grey'}}>${(Number(props.order.order.fees.total)).toFixed(2) }</small></del>&nbsp;${(Number(props.order.order.fees.total).toFixed(2) - Number(props.order.order.fees.giftCardApplied).toFixed(2)).toFixed(2)}</Typography>
                        :
                        <Typography align={"right"} style={{ lineHeight: 2, fontWeight: 'bold' }} variant='body1'>${(props.order?.payment?.paymentHandler ? parseFloat(props.order?.order?.fees?.total) : props.order?.order?.fees?.total / 100).toFixed(2)}</Typography>
                        }
                    </Grid>

                    {props.order.order.refund && <React.Fragment>
                    
                    <Grid item xs={12}><Divider/></Grid>

                    <Grid item xs={6}>
                        <Typography style={{ lineHeight: 2, fontWeight: 'bold' }} variant='body1'>Refunded:</Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography align={"right"} style={{ lineHeight: 2, fontWeight: 'bold' }} variant='body1'>${props.order.order.refundAmount}</Typography>
                    </Grid>

                    <Grid item xs={6}>
                        <Typography style={{ lineHeight: 2, fontWeight: 'bold' }} variant='body1'>Remaining:</Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography align={"right"} style={{ lineHeight: 2, fontWeight: 'bold' }} variant='body1'>${(Number(props.order.order.fees.total) - Number(props.order.order.refundAmount)).toFixed(2)}</Typography>
                    </Grid>
                    </React.Fragment> }
                </Grid>

            </Paper>)
}

export default CheckoutCard