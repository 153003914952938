import React, {useState, useEffect} from 'react';
import { firestore } from '../../../../firebase/firebase'
import { withRouter } from 'react-router-dom';
import UnitCard from '../../../components/Reports/Units/UnitCard'
import { Skeleton } from '@material-ui/lab';
import { makeStyles } from '@material-ui/core';
import { useUser } from '../../../../providers/UserContext';

const useStyles = makeStyles((theme) => ({
    content: {
        display: 'flex',
        width: '100%',
        padding: '32px',
        justifyContent: 'center',
        marginTop: '40px',
    },
}));

const Unit = (props) => {
    const [unitData, setUnitData] = useState({});
    //const [userData, setUserData] = useState({});
    const [loading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState(false);

    const { users, usersLoading, getUsers } = useUser();
    let path = new URLSearchParams(props.location.search).get("docId");
    useEffect(() => {
        getUsers();
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    const classes = useStyles();

    useEffect(() => {    
        //get units and users
        setLoading(true)

        firestore.collection('units').where('__name__', "==", path).get()
            .then(querySnapshot => {
                const unitResponse = querySnapshot.docs.map((doc) => {
                    return {
                        ...doc.data(),
                        'docId': doc.id,
                    }
                })
                setUnitData(unitResponse[0]);
                
                setLoading(false)
            })
            .catch(e => {
                setErrorMessage("Unable to Process")
                setLoading(false)
                console.log(e.message);
            })

    }, [path]) // eslint-disable-line react-hooks/exhaustive-deps

    return (
        loading
        ? 
        <div className={classes.content}>
            <Skeleton variant='rect' width={'42vw'} height={'135vh'}/> 
        </div>
        : 
        <UnitCard
            unitData={unitData} 
            userData={users}
            usersLoading={usersLoading}
            errorMessage={errorMessage}
            loading={loading}
        />
    )
}

export default withRouter(Unit)

/*
class Unit extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            jobData: {},
            userData: {},
            loading: true,
            errorMessage: false,
        }
    }

    getUnit() {
        this.setState({ loading: true })

        let path = new URLSearchParams(this.props.location.search).get("docId");

        firestore.collection('units').where('__name__', "==", path).get()
            .then(querySnapshot => {
                const unitResponse = querySnapshot.docs.map((doc) => {
                    return {
                        ...doc.data(),
                        'docId': doc.id,
                    }
                })
                this.setState({ unitData: unitResponse[0] })
                
                firestore.collection('users').where("uuid", "==", unitResponse[0].uid).get()
                    .then(querySnapshot => {
                        const userResponse = querySnapshot.docs.map((doc) => {
                            return {
                                ...doc.data(),
                                'docId': doc.id,
                            }
                        })
                        this.setState({userData: userResponse[0]})
                        this.setState({ loading: false })
                    })

                    .catch(e => {
                        this.setState({ setErrorMessage: "Unable to Process" });
                        this.setState({ loading: false })
                        console.log(e.message);
                    })
            })
            .catch(e => {
                this.setState({ setErrorMessage: "Unable to Process" });
                this.setState({ loading: false })
                console.log(e.message);
            })
    }

    componentDidMount() {
        this.getUnit();
    }

    componentWillUnmount() {
        this.setState(
            {
                unitData: [],
                userData: {},
                loading: false,
                errorMessage: false,
            })
    }

    render() {
        return (
            this.state.loading 
            ? 
            <Skeleton></Skeleton> 
            : 
            <UnitCard unitData={this.state.unitData} userData={this.state.userData}/>
        )
    }
}

export default withRouter(Unit)
*/