import React, { useEffect, useState } from 'react';
import { Button, Grid, makeStyles } from '@material-ui/core';
import { withRouter } from 'react-router';
import { firestore } from '../../../../firebase/firebase';
import AddBoxIcon from '@material-ui/icons/AddBox';
import MaterialTable from '@material-table/core';
import { useAuth } from '../../../../providers/AuthContext';
import { useUser } from '../../../../providers/UserContext'
import { TableIcons } from '../../../../constants/tableIcons';

const useStyles = makeStyles((theme) => ({

    card: {
        padding: theme.spacing(4),
        width: '100%',
        height: '100%',
      },
    
      content: {
        display: 'flex',
        justifyContent: 'center',
        width: '100%',
      },
    
      grid: {
        marginTop: theme.spacing(2),
        maxWidth: '1200px',
      },
    
      table: {
        width: '100%',
        maxWidth: '800px',
        margin: '16px',
        display: 'flex',
      },
    
      tile: {
        width: '300px',
        height: '300px',
      },
    
      tileContent: {
        display: 'flex',
        justifyContent: 'space-evenly',
        alignItems: 'center',
        flexDirection: 'column',
        width: '100%',
        height: '100%',
        padding: theme.spacing(4),
      },
    
      tileIcon: {
        color: 'rgba(0, 0, 0, 0.54)',
        width: '100px',
        height: '100px',
      },
    
      tileText: {
        fontSize: '1.10rem',
      },
      btn: {
        marginBottom: '20px',
        marginTop: '4px',
    },
    }));

const RolesHome = (props) => {

  const { users, getUsers } = useUser();
  const { roles } = useAuth();

  const [roleData, setRoleData] = useState();
  const [dataLoading, setDataLoading] = useState();
  const [dataError, setDataError] = useState();
  const [searchBarValue, setSearchBarValue] = useState("roleSearchBarValue" in sessionStorage ? JSON.parse(sessionStorage.getItem("roleSearchBarValue")) : null)

  useEffect(() => {
    getUsers();
  }, []) // eslint-disable-line react-hooks/exhaustive-deps
  useEffect(() => {
    firestore.collection('roles').get()
      .then(querySnapshot => {
        const rolesResponse = querySnapshot.docs.map((doc) => {
          return {
            ...doc.data(),
            'docId': doc.id,
          }
        })
        setRoleData(rolesResponse);
        setDataLoading(false);
      })
      .catch(e => {
        setDataError("Unable to Process");
        setDataLoading(false);
        console.log(e.message);
      })
  }, []);

  const tileClickHandler = (path, searchParams) => {
    props.history.push({
      pathname: "roles/" + path,
      search: searchParams && "?" + new URLSearchParams({ docId: searchParams }).toString(),
    })
  };

  const classes = useStyles();

  return !dataLoading && !dataError &&
    <div className={classes.content}>
      <Grid container className={classes.table}>
        <Grid item xs={12}>
          <Button variant='contained' onClick={() => tileClickHandler('role', 'new')} style={{marginRight:'5px'}} 
                  disabled={!roles.includes('Create|Update|Delete User Roles')}  startIcon={<AddBoxIcon/>} className={classes.btn}>
            Create New Role
          </Button>
        </Grid>
        <Grid item xs={12}>
        </Grid>

        
        <Grid item xs={12} style={{ width: '100%' }}>
          <MaterialTable
              columns={[
                { title: 'Title', field: 'title' },
                { title: 'Users', render: rowData => {
                    return users.filter(user => user?.role === rowData.title && user.employment?.active).length
                } },
                { title: 'Permissions', render: rowData => {
                    return rowData.permissions.length
                    }
                },
              ]}
              data={roleData}
              title='Account Roles'
              onSearchChange={ (value) => {setSearchBarValue(value); sessionStorage.setItem("roleSearchBarValue", JSON.stringify(value))}}
              actions={[
                rowData => ({
                  icon: TableIcons.OpenInNew,
                  tooltip: 'Show Account Role',
                  onClick: (event, rowData) => {
                    props.history.push({
                      pathname: '/dashboard/users/roles/role',
                      search: "?" + new URLSearchParams({ docId: rowData.docId }).toString(),
                    })
                  }
                })
              ]}
          
              options={{
                toolbar: true,
                paging: true,
                pageSizeOptions: false,
                pageSize: 5,
                actionsColumnIndex: -1,
                search: true,
                emptyRowsWhenPaging: false,
                thirdSortClick: false,
                searchText: searchBarValue,
                rowStyle: {
                    whiteSpace: 'pre-wrap',
                },
              }}
            />
        </Grid>
      </Grid>
    </div>
  
}

export default withRouter(RolesHome);