import React from 'react';
import { IconButton, makeStyles, Divider } from '@material-ui/core';
import { TableIcons } from '../../../../constants/tableIcons';
import MaterialTable from '@material-table/core';
import MediaViewer from '../../UI/MediaViewer/MediaViewer'
import { withRouter } from 'react-router-dom';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import moment from 'moment'
import Img from 'react-cool-img';

import truck from '../../../../img/geeteezoomzoom.gif';


const useStyles = makeStyles((theme) => ({

    root: {
        '& .MuiTable-root': {
            '& tr': {
                '& td': {
                    '&:last-child': {
                        '& div': {
                            justifyContent: 'flex-end',
                        },
                    },
                },
            },
        },
    },
}));

const ReportTable = (props) => {
   
    const [open, setOpen] = React.useState(false);
    const [photos, setPhotos] = React.useState([]);
    //const [searchValue, setSearchValue] = React.useState("jobsSearchBarValue" in sessionStorage ? JSON.parse(sessionStorage.getItem("jobsSearchBarValue")) : null)
    
    const classes = useStyles();
    const imageClick = (photo) => {
        let photos= []
        photos.push(photo)
        setPhotos(photos)
        setOpen(true)
      } 

    const PhotoRow = ({ photos }) => {
        const resultsRender = [];
        
        for (var i = 0; i < photos.length; i += 5) {
            resultsRender.push(
            <React.Fragment key={i}>
            {<tr>
                {photos.slice(i, i + 5)
                .map((image, index) => (  
                    
                    
                        <td key={index} style={index === 0 ? { width: '1px', paddingLeft: '60px'} : {width: '1px'}}>
                            
                            <Img key={index} id={'img-'+index}
                              style={{width: '100px', height: '100px' }}
                              placeholder={truck}
                              src={image}
                              //error={errorImage}
                              alt={index}
                              onClick={() => imageClick(image)}
                            />
                        </td>
                ))}
                </tr>}
            </React.Fragment>
            );
        }

        return (
      
                      
                        <tr>{resultsRender}</tr>
                      
     
            );
    };
    return (
        <div className={classes.root}>
            <MaterialTable
                style={{}}
                columns={props.columns}
                data={props.data}
                title={props.title}
                isLoading={props.isLoading}
                icons={TableIcons}
                editable={props.editable}
                onSearchChange={props.onSearchChange}
                onRowsPerPageChange={props.onRowsPerPageChange}
                onPageChange={props.onPageChange}
                actions={[
                    rowData => ({
                        icon: TableIcons.PhotoIcon,
                        tooltip: 'View Photos',
                        onClick: (event, rowData) => { setPhotos(rowData.photoURLs); setOpen(true); },
                        hidden: rowData.photoURLs === undefined || rowData.photoURLs === null || Object.values(rowData.photoURLs)?.length === 0
                    }),
                    {
                        icon: TableIcons.OpenInNew,
                        tooltip: 'Show ' + props.title,
                        onClick: (event, rowData) => props.history.push({
                            pathname: props.pathname,
                            search: "?" + new URLSearchParams({ docId: rowData.docId }).toString(),
                        })
                    },
                ]}
                /*components={{
                    Row: (props) => (
                        <React.Fragment>
                            <MTableBodyRow {...props} style={{backgroundColor: 'grey'}} />
                            {props.data.notes
                                ? <tr style={{ borderBottom: '1px solid rgba(224, 224, 224, 1)' }}>
                                    <td style={{ padding: '16px', paddingLeft: '60px' }}><b>Notes: </b></td>
                                    <td colSpan="100" style={{ padding: '16px' }}>{props.data.notes}</td>
                                </tr>
                                : null}
                        </React.Fragment>
                    )
                }}*/
                detailPanel={[
                    rowData => ({
                        disabled: rowData?.notes || rowData?.photoURLs?.length > 0 || rowData?.checklist?.length > 0 ? false : true,
                        tooltip: rowData?.notes || rowData?.photoURLs?.length > 0 || rowData?.checklist?.length > 0 ? 'Show Details': null,
                        icon: () => rowData?.notes || rowData?.photoURLs?.length > 0 || rowData?.checklist?.length > 0 ? <small><ArrowForwardIosIcon style={{ fontSize: '15px' }} /></small> : null,
                        //openIcon: () => rowData.notes ? <CommentOutlinedIcon /> : null,
                        render: () =>
                            <div  style={{backgroundColor: '#FFBF00', color: 'black'}}>
                                
                               <table>
                                    <tbody>
                                        {rowData?.notes && rowData.notes.length > 0 ?
                                        <tr style={{ borderBottom: '1px solid rgba(224, 224, 224, 1)' }}>
                                            <td style={{ padding: '16px', paddingLeft: '60px'}}><b>Notes: </b></td>
                                            <td colSpan="100" style={{ padding: '16px' }}>{rowData.notes ? rowData.notes : "N/A"}</td>
                                        </tr> : null}
                                        
                                    
                                    </tbody>
                                </table>

                                <table style={{width:'100%'}}>
                                    <tbody>
                                        {rowData?.photoURLs && rowData.photoURLs.length > 0 ? <React.Fragment>
                                        <tr style={{ borderBottom: '1px solid rgba(224, 224, 224, 1)' }}>
                                            <td style={{ padding: '16px', paddingLeft: '60px'}}><b>Photos: </b></td>
                                        </tr>
                                        
                                            <PhotoRow photos={rowData.photoURLs}/>
                                        </React.Fragment>  : null }
                                    </tbody>
                                </table>

                                <table style={{width:'100%'}}>
                                    <tbody>
                                    {rowData?.checklist && rowData?.checklist.length > 0 ? 
                                        <React.Fragment><tr>
                                            <td colSpan="10"><Divider /></td>
                                        </tr> 
                                        <tr>
                                        <td style={{ paddingLeft: '60px'}}><b>Checklists </b></td>
                                        </tr>
                                        <tr>
                                            <td colSpan="1" style={{ padding: '16px', paddingLeft: '60px' }}><b>Service Type</b></td>
                                            <td colSpan="1" style={{ padding: '16px' }}><b>Service Reason</b></td>
                                            <td colSpan="1" style={{ padding: '16px' }}><b>Sand Amount</b></td>
                                            <td colSpan="1" style={{ padding: '16px' }}><b>Date</b></td>
                                            <td colSpan="1" style={{ padding: '16px' }}><b>Equipment</b></td>
                                            <td colSpan="1" style={{ padding: '16px' }}><b>Link</b></td>
                                        </tr></React.Fragment>
                                        : null}
                                   
                                        {rowData?.checklist && rowData?.checklist?.length > 0 ? 
                                        rowData.checklist.map((checklist, index) => {
                                            return <tr key={index}>
                                            <td colSpan="1" style={{ padding: '16px', paddingLeft: '60px' }}>{checklist.serviceType}</td>
                                            <td colSpan="1" style={{ padding: '16px' }}>{checklist.serviceReason}</td>
                                            <td colSpan="1" style={{ padding: '16px' }}>{checklist.sandAmount ? checklist.sandAmount : "None"}</td>
                                            <td colSpan="1" style={{ padding: '16px' }}>{checklist.date < 9999999999 ? moment(checklist.date *= 1000).format('YYYY-MM-DD') : moment(checklist.date).format('YYYY-MM-DD')}</td>
                                            <td colSpan="1" style={{ padding: '16px' }}>{checklist.equipmentType ? Object.values(checklist.equipmentType).join(", ") : "N/A"}</td>
                                            <td colSpan="1" style={{ padding: '16px' }}><IconButton color="secondary"  onClick={(event) => 
                                            props.history.push({
                                                pathname: "/dashboard/reporting/timesheets/checklist",
                                                search: "?" + new URLSearchParams({ docId: checklist.docId }).toString(),
                                            })}><TableIcons.OpenInNew/></IconButton></td>
                                        </tr>
                                            //<img key={index} style={{height: '300px', width: '300px'}} src={image} alt={index} />
                                            
                                        })
                                        
                                        : null}
                                    </tbody>
                                </table>
                            </div>
                    })
                ]}
                localization={{
                    header: {
                        actions: 'Actions'
                    }
                }}
                onRowClick={(event, rowData, togglePanel) => {
                    if (rowData?.notes !== "" || (rowData.photoURLs && rowData?.photoURLs?.length > 0) || (rowData.checklist && rowData?.checklist?.length > 0) ) {
                        togglePanel();
                    } else {
                        return null;
                    }
                }}
                options={'options' in props
                    ? props.options
                    : {
                        toolbar: true,
                        paging: true,
                        actionsColumnIndex: -1,
                        search: true,
                        emptyRowsWhenPaging: false,
                        thirdSortClick: false,
                        //searchText: searchValue,
                        rowStyle: {
                            whiteSpace: 'pre-wrap',
                        },
                    }}
            />
            {photos.length > 0 ? <MediaViewer open={open} handleClose={() => setOpen(false)} photoURLs={photos} /> : null}

        </div >
    )
}

export default withRouter(ReportTable);