import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core';
import moment from 'moment'
import { Button, Checkbox, Chip, FormControlLabel, Grid, Paper, TextField, Typography } from '@material-ui/core';
import { withRouter } from 'react-router-dom';
import InlineMediaViewer from '../../UI/MediaViewer/InlineMediaViewer'
import Map from '../../UI/Map/Map'
import { InfoWindow, Marker } from '@react-google-maps/api';
import ReportTable from '../ReportTable/ReportTable'
import _ from 'lodash'
import { getCoordinates, hasInCoordinate, hasOutCoordinate } from '../../../utility/utility'
import Notes from '../../../containers/Notes/Notes';
import { firestore } from '../../../../firebase/firebase';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import { TableIcons } from '../../../../constants/tableIcons';
import MaterialTable from '@material-table/core';


const useStyles = makeStyles((theme) => ({
    card: {
        padding: theme.spacing(4),
        display: 'flex',
        alignItems: 'center',
        height: '100%',
    },
    mapCheckbox: {
        position: 'absolute',
        bottom: '40px',
        zIndex: '1000',
        backgroundColor: 'black',
        color: 'white',
        left: '32px',
        paddingRight: '8px',
        borderRadius: '4px',
    },
    content: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        maxWidth: '792px',
        margin: 'auto',
    },
    paper: {
        padding: theme.spacing(2),
        maxWidth: '792px',
        width: '100%',
        //backgroundColor: 'rgb(252, 252, 249)',
        justifyContent: 'center',
        alignItems: 'center',
    },
    row: {
        display: 'flex',
        alignItems: 'center',
        height: '100%',
    },
    backButton: {
        marginBottom: '20px',
        marginTop: '20px',
    },
}));


const JobCard = (props) => {

    const classes = useStyles();

    const [coordinateData, showCoordinateData] = useState(false);
    const [user, setUser] = useState('')

    let coordinates = getCoordinates(props.jobData);

    let mapElements = [];

    let date = props.jobData.date
    let timeIn = props.jobData.timeIn
    let timeOut = props.jobData.timeOut


    if (date < 9999999999) date *= 1000
    if (timeIn < 9999999999) timeIn *= 1000
    if (timeOut < 9999999999) timeOut *= 1000


    if (hasInCoordinate(coordinates)) {
        mapElements.push(<Marker key={0} icon="https://maps.google.com/mapfiles/ms/icons/blue-dot.png" title="Clock In" position={coordinates.inCoordinate} />)
        if (coordinateData) {
            mapElements.push(
                <InfoWindow
                    key={2}
                    position={{ lat: coordinates.inCoordinate.lat + 0.00005, lng: coordinates.inCoordinate.lng }}>
                    <React.Fragment>
                        <p>Job Start {coordinates.inCoordinate.lat.toFixed(4)},{coordinates.inCoordinate.lng.toFixed(4)}</p>
                        <p>Coord Start Time: {moment(props.jobData.inCoordinate.time).format('MMM D h:mm a')}</p>
                        <p>User Submitted Time: {moment(timeIn).format('MMM D h:mm a')}</p>
                    </React.Fragment>
                </InfoWindow>)
        }
    }
    if (hasOutCoordinate(coordinates)) {
        mapElements.push(<Marker key={1} icon="https://maps.google.com/mapfiles/ms/icons/red-dot.png" title="Clock Out" position={coordinates.outCoordinate} />)
        if (coordinateData) {
            mapElements.push(
                <InfoWindow
                    key={3}
                    position={{ lat: coordinates.outCoordinate.lat + 0.00005, lng: coordinates.outCoordinate.lng }}>
                    <React.Fragment>
                        <p>Job End {coordinates.outCoordinate.lat.toFixed(4)},{coordinates.outCoordinate.lng.toFixed(4)}</p>
                        <p>Coord End Time: {moment(props.jobData.outCoordinate.time).format('MMM D h:mm a')}</p>
                        <p>User Submitted Time: {moment(timeOut).format('MMM D h:mm a')}</p>
                    </React.Fragment>
                </InfoWindow>)
        }
    }

    let jobCard = null;

    useEffect(() => {
        const user = props.userData.filter(user => user.uuid === props.jobData.uid)
        if (user.length > 0) {
            setUser(user[0])
        }
    }, [props.userData, props.jobData.uid])

    const handleNextJob = () => {
        let index = props.location.state.jobList.findIndex(x => x.docId === props.jobData.docId)
        if(index < props.location.state.jobList.length - 1){
            props.history.push({
                pathname:`/dashboard/reporting/timesheets/job`,
                search: `?docId=${props.location.state.jobList[index + 1].docId}`,
                state: {jobList: props.location.state.jobList}
            })
        }
    }

    const handlePrevJob = () => {
        let index = props.location.state.jobList.findIndex(x => x.docId === props.jobData.docId)
        if(index > 0){
            props.history.push({
                pathname:`/dashboard/reporting/timesheets/job`,
                search: `?docId=${props.location.state.jobList[index - 1].docId}`,
                state: {jobList: props.location.state.jobList}
            })
        }
    }


    if (!_.isUndefined(props.jobData)) {

        jobCard = (
            <Grid className={classes.content}>
                <Grid className={classes.backButton}>
                    <Button variant="contained" startIcon={<ArrowBackIcon />}
                        onClick={() => props?.location?.state && props.location.state.jobList.length ? 
                            props.history.push({
                                pathname: '/dashboard/reporting/timesheets',
                                search: "?" + new URLSearchParams({ docId: props.jobData.timesheetID }).toString()})
                            : props.history.goBack()}>Back</Button>
                    {props?.location?.state && props.location.state.jobList?.length > 1 && <React.Fragment>
                    <Button variant="contained" color="secondary" disabled={
                        props.location.state.jobList.findIndex(x => x.docId === props.jobData.docId) === 0
                    } style={{marginLeft:'5px'}} onClick={() => handlePrevJob()} startIcon={<ArrowBackIcon/>}>Previous</Button>
                    <Button variant="contained" color="secondary" disabled={
                        props.location.state.jobList.findIndex(x => x.docId === props.jobData.docId) === props.location.state.jobList.length - 1
                    } style={{marginLeft:'5px'}} onClick={() => handleNextJob()} startIcon={<ArrowForwardIcon/>}>Next</Button>
                    </React.Fragment>
                    }
                </Grid>
                <Paper className={classes.paper}>
                    <Grid container spacing={2}>
                        <Grid item xs={6} style={{ marginBottom: '32px' }}>
                            <Grid container>
                                <Grid item xs={12} className={classes.row}>
                                    <Typography variant='h4'>Job &nbsp;</Typography>
                                    <Chip color="primary" label={props.jobData.jobType ? props.jobData.jobType.toUpperCase() : "NO TYPE"} />
                                </Grid>
                            </Grid>

                            <Typography variant='h6'>{user?.displayName}</Typography>
                            <Typography variant='h6'>{props.jobData?.date}</Typography>
                        </Grid>

                        <Grid item xs={6} className={classes.row} style={{ justifyContent: 'flex-end' }}>
                            <Button 
                                variant='contained' 
                                onClick={()=> {
                                    props.history.push({
                                        pathname: '/dashboard/reporting/timesheets',
                                        search: "?" + new URLSearchParams({ docId: props.jobData.timesheetID }).toString(),
                                    }) }
                                }
                            >
                                View Related Timesheet
                            </Button>
                        </Grid>

                        <Grid item xs={6}>
                            <Paper className={classes.card}>
                                <Grid container spacing={2}>
                                    <Grid item xs={4} className={classes.row}>
                                        <Typography variant='body1'>Customer:</Typography>
                                    </Grid>
                                    <Grid item xs={8} className={classes.row}>
                                        <Typography variant='body1'>{props.jobData.customer}</Typography>
                                    </Grid>
                                    <Grid item xs={4} className={classes.row}>
                                        <Typography variant='body1'>Job Number:</Typography>
                                    </Grid>
                                    <Grid item xs={8} className={classes.row}>
                                        <Typography variant='body1'>{props.jobData.jobNumber}</Typography>
                                    </Grid>
                                    <Grid item xs={4} className={classes.row}>
                                        <Typography variant='body1'>Address:</Typography>
                                    </Grid>
                                    <Grid item xs={8} className={classes.row}>
                                        <Typography variant='body1'>{props.jobData.address}</Typography>
                                    </Grid>
                                </Grid>
                            </Paper>
                        </Grid>

                        <Grid item xs={6}>
                            <Paper className={classes.card}>
                                <Grid container spacing={2}>
                                    <Grid item xs={4} className={classes.row}>
                                        <Typography variant='body1'>Time In:</Typography>
                                    </Grid>
                                    <Grid item xs={8} className={classes.row}>
                                        <TextField fullWidth value={moment(timeIn).format('YYYY-MM-DD h:mm a')} />
                                    </Grid>
                                    <Grid item xs={4} className={classes.row}>
                                        <Typography variant='body1'>Time Out:</Typography>
                                    </Grid>
                                    <Grid item xs={8} className={classes.row}>
                                        <TextField fullWidth value={moment(timeOut).format('YYYY-MM-DD h:mm a')} />
                                    </Grid>
                                </Grid>
                            </Paper>
                        </Grid>
                        <Grid item xs={12}>
                            <Notes
                                notes={props.jobData.notes}
                                timesheetLoading={props.loading}
                                path={firestore.collection('jobs').doc(props.jobData.docId).collection('notes')}
                                user={user?.uuid}
                            />
                        </Grid>
                        

                        {mapElements.length > 0 ?
                            <Grid item xs={12} style={{ position: 'relative' }}>
                                <Map
                                    height={'400px'}
                                    zoom={18}
                                    elements={mapElements}
                                />
                                <FormControlLabel
                                    className={classes.mapCheckbox}
                                    control={<Checkbox style={{color:'white'}} checked={coordinateData} onChange={() => showCoordinateData(!coordinateData)} />}
                                    label='Show Coordinate Data' />
                            </Grid>
                            : null}

                        {props.unitData.length > 0 ?
                            <Grid item md={12} xs={12} className={classes.root}>
                                <ReportTable
                                    columns={[
                                        { title: 'Unit Number', field: 'unitNumber' },
                                        { title: 'Trailer ID', field: 'trailerID', editable: 'never' },
                                        { title: 'Fuel Added', field: 'fuelAdded', editable: 'never' },
                                        { title: 'Greased', render: rowData => rowData.greased ? "Yes" : "No" },
                                        { title: 'Net Mileage', render: rowData => (rowData.mileageOut - rowData.mileageIn).toFixed(2) },
                                        { title: 'Defects', render: rowData => rowData.defectID !== "" ? "Yes" : "No" },
                                    ]}
                                    editable={{
                                        //onRowAdd: (newData) => props.createUnit(newData),
                                        onRowUpdate: (newData, oldData) => props.updateUnit(newData, oldData),
                                        //onRowDelete: (oldData) => props.deleteDelivery(oldData),
                                    }}
                                    data={props.unitData}
                                    title={"Unit Details"}
                                    pathname='/dashboard/reporting/timesheets/unit'
                                    loading={props.loading}
                                />
                            </Grid>
                            : null}

                        {props.checklistData.length > 0 ?
                            <Grid item md={12} xs={12} className={classes.root}>
                                <MaterialTable
                                    columns={[
                                        {
                                            title: 'Date', render: rowData => {
                                                if (rowData.date < 9999999999) rowData.date *= 1000
                                                return moment(date).format('YYYY-MM-DD h:mm a')
                                            }
                                        },
                                        { title: 'Service Type', field: 'serviceType' },
                                        { title: 'Service Reason', field: 'serviceReason' },
                                        { title: 'Sand Amount', render: rowData => rowData.sandAmount === "" ? "none" : rowData.sandAmount },
                                    ]}
                                    data={props.checklistData}
                                    title={"Checklist Details"}
                                    //pathname='/dashboard/reporting/timesheets/checklist'
                                    loading={props.loading}
                                    actions={[
                                    {
                                        icon: TableIcons.OpenInNew,
                                        tooltip: 'Show Checklist',
                                        onClick: (event, rowData) => props.history.push({
                                            pathname: '/dashboard/reporting/timesheets/checklist',
                                            search: "?" + new URLSearchParams({ docId: rowData.docId }).toString(),
                                            state: { checklistList: props.checklistData, fromJob: true }
                                        })
                                    }]}
                                />
                            </Grid>
                            : null}
                        {props.formData.length > 0 ?
                            <Grid item md={12} xs={12} className={classes.root}>
                                <ReportTable
                                    columns={[
                                        { title: 'Form', field: 'name' },
                                    ]}
                                    data={props.formData}
                                    title={"Forms"}
                                    pathname='/dashboard/reporting/documents'
                                    loading={props.loading}
                                />
                            </Grid>
                            : null}

                        <Grid item xs={12}>
                            <InlineMediaViewer photoURLs={props.jobData.photoURLs} />
                        </Grid>
                    </Grid>
                </Paper>
            </Grid>
        )
    }

    return (
        <div>
            {jobCard}
        </div>
    )
}

export default withRouter(JobCard)