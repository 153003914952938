import React, { memo } from 'react';
import { Typography, makeStyles, } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    columnTitles: {
        fontSize: '14px',
    },
    rowContent: {
        width: '90%',
        fontSize: '14px',
        fontWeight: 600,
        // padding: '5px',
        // margin: '5px'
    },
}));

const DataGridTableTypography = ({data, id, style}) => {
    const classes = useStyles();

    //console.log('DataGridTableTypography rendered', docId)

    return(
        <>
            <Typography
                id={id}
                className={classes.rowContent}
            >
                {data}
            </Typography>
        </>
    )
}

export default memo(DataGridTableTypography);