import React, {useState, useCallback} from 'react';
//import { makeStyles } from '@material-ui/core';
import { Grid, Paper, Typography, Divider, Checkbox, TextField, Snackbar, Button } from '@material-ui/core';
import { withRouter } from 'react-router'
import { makeStyles } from '@material-ui/core';
import _ from 'lodash'
import { firestore } from '../../../firebase/firebase'

const useStyles = makeStyles((theme) => ({

    content: {
        display: 'flex',
        width: '100%',
        flexDirection: 'column',
        maxWidth: '1000px',
        margin: 'auto',
    },
    paper: {
        borderRadius: '0px',
        margin: '0px',
        marginBottom:'20px',
        width: '80%',
        maxWidth: '1000px',
    },
    header: {
        display: 'flex',
        justifyContent: 'center',
        marginBottom:'10px',
    },
    card: {
        display: 'flex',
        justifyContent: 'center',
    },
    label: {
        lineHeight: 2,
    },
    checkbox: {
        lineHeight: 2,
        padding:'0px',
        marginTop: '4px',
        marginBottom: '4px',
    },
}));

const WheelBrakesTab = (props) => {
    const classes = useStyles();

    const [equipmentData, setEquipmentData] = useState(props.equipmentData);
    const [error, setError] = useState("");
    const [saving, setSaving] = useState(false);
    const [saveState, setSaveState] = useState(props.saveState);

    let path = new URLSearchParams(props.location.search).get("docId");

    const updateEquipmentData = useCallback((e) => {
            setEquipmentData(e)
        },
        [],
    );

    const confirmSave = () => {
        props.setEquipmentData(equipmentData);
        props.setSaveState(equipmentData);
        setSaving(true);
        firestore.collection('unitDescriptions').doc(path)
            .update(equipmentData)
            .then(() => {
                setSaveState(equipmentData);
                setSaving(false)
            })
            .catch((e) => {setError(e); console.log(error);});
    };

    const cancelSave = () => {
        setEquipmentData(saveState);
    };

    return(
        <Grid container style={{justifyContent: 'center'}}>
            <Paper className={classes.paper}>
                
                <Grid container spacing={2} >
                    {/* WHEELS */}
                    <Grid item xs={12} >
                        <Typography variant= 'h5' className={classes.header}>Wheels</Typography>
                        <hr></hr>
                        <Grid container>
                            <Grid item xs={4} className={classes.card}>
                                <Typography variant= 'h6' className={classes.header} style={{ textDecoration: 'underline'}}>Front</Typography>
                            </Grid>
                            <Grid item xs={4} className={classes.card}>
                            </Grid>
                            <Grid item xs={4} className={classes.card}>
                                <Typography variant= 'h6' className={classes.header} style={{ textDecoration: 'underline'}}>Rear</Typography>
                            </Grid>
                        </Grid>

                        <Grid container>
                            <Grid item xs={4} className={classes.card}>
                                {props.edit 
                                    ?<TextField
                                    size="small"
                                    value={equipmentData.wheels ? (equipmentData.wheels.frontHubMake ? equipmentData.wheels.frontHubMake : "") : ""}
                                    onChange={(event) => {equipmentData.wheels 
                                        ? updateEquipmentData({...equipmentData, wheels: {...equipmentData.wheels, 'frontHubMake': event.target.value}}) 
                                        : updateEquipmentData({...equipmentData, wheels: { 'frontHubMake': event.target.value}}) 
                                    }}
                                    />
                                    : <Typography className={classes.label}>
                                        {equipmentData.wheels ? (equipmentData.wheels.frontHubMake ? equipmentData.wheels.frontHubMake : "") : ""}
                                    </Typography>
                                }
                            </Grid>
                            <Grid item xs={4} className={classes.card}>
                                <Typography className={classes.label}>Hub Make</Typography>
                            </Grid>
                            <Grid item xs={4} className={classes.card}>
                                {props.edit 
                                    ?<TextField
                                    size="small"
                                    value={equipmentData.wheels ? (equipmentData.wheels.rearHubMake ? equipmentData.wheels.rearHubMake : "") : ""}
                                    onChange={(event) => {equipmentData.wheels 
                                        ? updateEquipmentData({...equipmentData, wheels: {...equipmentData.wheels, 'rearHubMake': event.target.value}}) 
                                        : updateEquipmentData({...equipmentData, wheels: { 'rearHubMake': event.target.value}}) 
                                    }}
                                    />
                                    : <Typography className={classes.label}>
                                        {equipmentData.wheels ? (equipmentData.wheels.rearHubMake ? equipmentData.wheels.rearHubMake : "") : ""}
                                    </Typography>
                                }
                            </Grid>
                        </Grid>
   
                        <Grid container>
                            <Grid item xs={4} className={classes.card}>
                                {props.edit 
                                    ?<TextField
                                    size="small"
                                    value={equipmentData.wheels ? (equipmentData.wheels.frontHubModelNum ? equipmentData.wheels.frontHubModelNum : "") : ""}
                                    onChange={(event) => {equipmentData.wheels 
                                        ? updateEquipmentData({...equipmentData, wheels: {...equipmentData.wheels, 'frontHubModelNum': event.target.value}}) 
                                        : updateEquipmentData({...equipmentData, wheels: { 'frontHubModelNum': event.target.value}}) 
                                    }}
                                    />
                                    : <Typography className={classes.label}>
                                        {equipmentData.wheels ? (equipmentData.wheels.frontHubModelNum ? equipmentData.wheels.frontHubModelNum : "") : ""}
                                    </Typography>
                                }
                            </Grid>
                            <Grid item xs={4} className={classes.card}>
                                <Typography className={classes.label}>Hub Model #</Typography>
                            </Grid>
                            <Grid item xs={4} className={classes.card}>
                                {props.edit 
                                    ?<TextField
                                    size="small"
                                    value={equipmentData.wheels ? (equipmentData.wheels.rearHubModelNum ? equipmentData.wheels.rearHubModelNum : "") : ""}
                                    onChange={(event) => {equipmentData.wheels 
                                        ? updateEquipmentData({...equipmentData, wheels: {...equipmentData.wheels, 'rearHubModelNum': event.target.value}}) 
                                        : updateEquipmentData({...equipmentData, wheels: { 'rearHubModelNum': event.target.value}}) 
                                    }}
                                    />
                                    : <Typography className={classes.label}>
                                        {equipmentData.wheels ? (equipmentData.wheels.rearHubModelNum ? equipmentData.wheels.rearHubModelNum : "") : ""}
                                    </Typography>
                                }
                            </Grid>
                        </Grid>

                        <Grid container>
                            <Grid item xs={4} className={classes.card}>
                                {props.edit 
                                    ?<TextField
                                    size="small"
                                    value={equipmentData.wheels ? (equipmentData.wheels.frontHubOilType ? equipmentData.wheels.frontHubOilType : "") : ""}
                                    onChange={(event) => {equipmentData.wheels 
                                        ? updateEquipmentData({...equipmentData, wheels: {...equipmentData.wheels, 'frontHubOilType': event.target.value}}) 
                                        : updateEquipmentData({...equipmentData, wheels: { 'frontHubOilType': event.target.value}}) 
                                    }}
                                    />
                                    : <Typography className={classes.label}>
                                        {equipmentData.wheels ? (equipmentData.wheels.frontHubOilType ? equipmentData.wheels.frontHubOilType : "") : ""}
                                    </Typography>
                                }
                            </Grid>
                            <Grid item xs={4} className={classes.card}>
                                <Typography className={classes.label}>Hub Oil Type</Typography>
                            </Grid>
                            <Grid item xs={4} className={classes.card}>
                                {props.edit 
                                    ?<TextField
                                    size="small"
                                    value={equipmentData.wheels ? (equipmentData.wheels.rearHubOilType ? equipmentData.wheels.rearHubOilType : "") : ""}
                                    onChange={(event) => {equipmentData.wheels 
                                        ? updateEquipmentData({...equipmentData, wheels: {...equipmentData.wheels, 'rearHubOilType': event.target.value}}) 
                                        : updateEquipmentData({...equipmentData, wheels: { 'rearHubOilType': event.target.value}}) 
                                    }}
                                    />
                                    : <Typography className={classes.label}>
                                        {equipmentData.wheels ? (equipmentData.wheels.rearHubOilType ? equipmentData.wheels.rearHubOilType : "") : ""}
                                    </Typography>
                                }
                            </Grid>
                        </Grid>

                        <Grid container>
                            <Grid item xs={4} className={classes.card}>
                                <Checkbox 
                                className={classes.checkbox} 
                                disabled={props.edit ? false : true}
                                checked={equipmentData.wheels ? (equipmentData.wheels.frontUsesSynthetic === true ? true : false) : false}
                                onClick={(event) => {equipmentData.wheels 
                                    ? updateEquipmentData({...equipmentData, wheels: {...equipmentData.wheels, 'frontUsesSynthetic': event.target.checked}}) 
                                    : updateEquipmentData({...equipmentData, wheels: { 'frontUsesSynthetic': event.target.checked}}) 
                                }}
                                />
                            </Grid>
                            <Grid item xs={4} className={classes.card}>
                                <Typography className={classes.label}>Uses Synthetic Oil</Typography>
                            </Grid>
                            <Grid item xs={4} className={classes.card}>
                                <Checkbox 
                                className={classes.checkbox} 
                                disabled={props.edit ? false : true}
                                checked={equipmentData.wheels ? (equipmentData.wheels.rearUsesSynthetic === true ? true : false) : false}
                                onClick={(event) => {equipmentData.wheels 
                                    ? updateEquipmentData({...equipmentData, wheels: {...equipmentData.wheels, 'rearUsesSynthetic': event.target.checked}}) 
                                    : updateEquipmentData({...equipmentData, wheels: { 'rearUsesSynthetic': event.target.checked}}) 
                                }}
                                />
                            </Grid>
                        </Grid>

                        <Grid container>
                            <Grid item xs={12}>
                                <Divider style={{margin: '20px 0px 10px 0px'}}/>
                            </Grid>
                        </Grid>

                        <Grid container>
                            <Grid item xs={4} className={classes.card}>
                            </Grid>
                            <Grid item xs={4} className={classes.card}>
                                <Typography variant= 'h6' className={classes.header} style={{ textDecoration: 'underline'}}>Outter</Typography>
                            </Grid>
                            <Grid item xs={4} className={classes.card}>
                            </Grid>
                        </Grid>

                        <Grid container>
                            <Grid item xs={4} className={classes.card}>
                                {props.edit 
                                    ?<TextField
                                    size="small"
                                    value={equipmentData.wheels ? (equipmentData.wheels.frontOutterBearingNum ? equipmentData.wheels.frontOutterBearingNum : "") : ""}
                                    onChange={(event) => {equipmentData.wheels 
                                        ? updateEquipmentData({...equipmentData, wheels: {...equipmentData.wheels, 'frontOutterBearingNum': event.target.value}}) 
                                        : updateEquipmentData({...equipmentData, wheels: { 'frontOutterBearingNum': event.target.value}}) 
                                    }}
                                    />
                                    : <Typography className={classes.label}>
                                        {equipmentData.wheels ? (equipmentData.wheels.frontOutterBearingNum ? equipmentData.wheels.frontOutterBearingNum : "") : ""}
                                    </Typography>
                                }
                            </Grid>
                            <Grid item xs={4} className={classes.card}>
                                <Typography className={classes.label}>Bearing #</Typography>
                            </Grid>
                            <Grid item xs={4} className={classes.card}>
                                {props.edit 
                                    ?<TextField
                                    size="small"
                                    value={equipmentData.wheels ? (equipmentData.wheels.rearOutterBearingNum ? equipmentData.wheels.rearOutterBearingNum : "") : ""}
                                    onChange={(event) => {equipmentData.wheels 
                                        ? updateEquipmentData({...equipmentData, wheels: {...equipmentData.wheels, 'rearOutterBearingNum': event.target.value}}) 
                                        : updateEquipmentData({...equipmentData, wheels: { 'rearOutterBearingNum': event.target.value}}) 
                                    }}
                                    />
                                    : <Typography className={classes.label}>
                                        {equipmentData.wheels ? (equipmentData.wheels.rearOutterBearingNum ? equipmentData.wheels.rearOutterBearingNum : "") : ""}
                                    </Typography>
                                }
                            </Grid>
                        </Grid>

                        <Grid container>
                            <Grid item xs={4} className={classes.card}>
                                {props.edit 
                                ?<TextField
                                 size="small"
                                 value={equipmentData.wheels ? (equipmentData.wheels.frontOutterRacesNum ? equipmentData.wheels.frontOutterRacesNum : "") : ""}
                                 onChange={(event) => {equipmentData.wheels 
                                    ? updateEquipmentData({...equipmentData, wheels: {...equipmentData.wheels, 'frontOutterRacesNum': event.target.value}}) 
                                    : updateEquipmentData({...equipmentData, wheels: { 'frontOutterRacesNum': event.target.value}}) 
                                }}
                                />
                                : <Typography className={classes.label}>
                                    {equipmentData.wheels ? (equipmentData.wheels.frontOutterRacesNum ? equipmentData.wheels.frontOutterRacesNum : "") : ""}
                                  </Typography>
                                }
                            </Grid>
                            <Grid item xs={4} className={classes.card}>
                                <Typography className={classes.label}>Races #</Typography>
                            </Grid>
                            <Grid item xs={4} className={classes.card}>
                                {props.edit 
                                    ?<TextField
                                    size="small"
                                    value={equipmentData.wheels ? (equipmentData.wheels.rearOutterRacesNum ? equipmentData.wheels.rearOutterRacesNum : "") : ""}
                                    onChange={(event) => {equipmentData.wheels 
                                        ? updateEquipmentData({...equipmentData, wheels: {...equipmentData.wheels, 'rearOutterRacesNum': event.target.value}}) 
                                        : updateEquipmentData({...equipmentData, wheels: { 'rearOutterRacesNum': event.target.value}}) 
                                    }}
                                    />
                                    : <Typography className={classes.label}>
                                        {equipmentData.wheels ? (equipmentData.wheels.rearOutterRacesNum ? equipmentData.wheels.rearOutterRacesNum : "") : ""}
                                    </Typography>
                                }
                            </Grid>
                        </Grid>

                        <Grid container>
                            <Grid item xs={4} className={classes.card}>
                                {props.edit 
                                ?<TextField
                                 size="small"
                                 value={equipmentData.wheels ? (equipmentData.wheels.frontOutterSealNum ? equipmentData.wheels.frontOutterSealNum : "") : ""}
                                 onChange={(event) => {equipmentData.wheels 
                                    ? updateEquipmentData({...equipmentData, wheels: {...equipmentData.wheels, 'frontOutterSealNum': event.target.value}}) 
                                    : updateEquipmentData({...equipmentData, wheels: { 'frontOutterSealNum': event.target.value}}) 
                                }}
                                />
                                : <Typography className={classes.label}>
                                    {equipmentData.wheels ? (equipmentData.wheels.frontOutterSealNum ? equipmentData.wheels.frontOutterSealNum : "") : ""}
                                  </Typography>
                                }
                            </Grid>
                            <Grid item xs={4} className={classes.card}>
                                <Typography className={classes.label}>Seal #</Typography>
                            </Grid>
                            <Grid item xs={4} className={classes.card}>
                                {props.edit 
                                    ?<TextField
                                    size="small"
                                    value={equipmentData.wheels ? (equipmentData.wheels.rearOutterSealNum ? equipmentData.wheels.rearOutterSealNum : "") : ""}
                                    onChange={(event) => {equipmentData.wheels 
                                        ? updateEquipmentData({...equipmentData, wheels: {...equipmentData.wheels, 'rearOutterSealNum': event.target.value}}) 
                                        : updateEquipmentData({...equipmentData, wheels: { 'rearOutterSealNum': event.target.value}}) 
                                    }}
                                    />
                                    : <Typography className={classes.label}>
                                        {equipmentData.wheels ? (equipmentData.wheels.rearOutterSealNum ? equipmentData.wheels.rearOutterSealNum : "") : ""}
                                    </Typography>
                                }
                            </Grid>
                        </Grid>

                        <Grid container>
                            <Grid item xs={12}>
                                <Divider style={{margin: '20px 0px 10px 0px'}}/>
                            </Grid>
                        </Grid>

                        <Grid container>
                            <Grid item xs={4} className={classes.card}>
                            </Grid>
                            <Grid item xs={4} className={classes.card}>
                                <Typography variant= 'h6' className={classes.header} style={{ textDecoration: 'underline'}}>Inner</Typography>
                            </Grid>
                            <Grid item xs={4} className={classes.card}>
                            </Grid>
                        </Grid>

                        <Grid container>
                            <Grid item xs={4} className={classes.card}>
                                {props.edit 
                                    ?<TextField
                                    size="small"
                                    value={equipmentData.wheels ? (equipmentData.wheels.frontInnerBearingNum ? equipmentData.wheels.frontInnerBearingNum : "") : ""}
                                    onChange={(event) => {equipmentData.wheels 
                                        ? updateEquipmentData({...equipmentData, wheels: {...equipmentData.wheels, 'frontInnerBearingNum': event.target.value}}) 
                                        : updateEquipmentData({...equipmentData, wheels: { 'frontInnerBearingNum': event.target.value}}) 
                                    }}
                                    />
                                    : <Typography className={classes.label}>
                                        {equipmentData.wheels ? (equipmentData.wheels.frontInnerBearingNum ? equipmentData.wheels.frontInnerBearingNum : "") : ""}
                                    </Typography>
                                }
                            </Grid>
                            <Grid item xs={4} className={classes.card}>
                                <Typography className={classes.label}>Bearing #</Typography>
                            </Grid>
                            <Grid item xs={4} className={classes.card}>
                                {props.edit 
                                    ?<TextField
                                    size="small"
                                    value={equipmentData.wheels ? (equipmentData.wheels.rearInnerBearingNum ? equipmentData.wheels.rearInnerBearingNum : "") : ""}
                                    onChange={(event) => {equipmentData.wheels 
                                        ? updateEquipmentData({...equipmentData, wheels: {...equipmentData.wheels, 'rearInnerBearingNum': event.target.value}}) 
                                        : updateEquipmentData({...equipmentData, wheels: { 'rearInnerBearingNum': event.target.value}}) 
                                    }}
                                    />
                                    : <Typography className={classes.label}>
                                        {equipmentData.wheels ? (equipmentData.wheels.rearInnerBearingNum ? equipmentData.wheels.rearInnerBearingNum : "") : ""}
                                    </Typography>
                                }
                            </Grid>
                        </Grid>

                        <Grid container>
                            <Grid item xs={4} className={classes.card}>
                                {props.edit 
                                    ?<TextField
                                    size="small"
                                    value={equipmentData.wheels ? (equipmentData.wheels.frontInnerRacesNum ? equipmentData.wheels.frontInnerRacesNum : "") : ""}
                                    onChange={(event) => {equipmentData.wheels 
                                        ? updateEquipmentData({...equipmentData, wheels: {...equipmentData.wheels, 'frontInnerRacesNum': event.target.value}}) 
                                        : updateEquipmentData({...equipmentData, wheels: { 'frontInnerRacesNum': event.target.value}}) 
                                    }}
                                    />
                                    : <Typography className={classes.label}>
                                        {equipmentData.wheels ? (equipmentData.wheels.frontInnerRacesNum ? equipmentData.wheels.frontInnerRacesNum : "") : ""}
                                    </Typography>
                                }
                            </Grid>
                            <Grid item xs={4} className={classes.card}>
                                <Typography className={classes.label}>Races #</Typography>
                            </Grid>
                            <Grid item xs={4} className={classes.card}>
                                {props.edit 
                                    ?<TextField
                                    size="small"
                                    value={equipmentData.wheels ? (equipmentData.wheels.rearInnerRacesNum ? equipmentData.wheels.rearInnerRacesNum : "") : ""}
                                    onChange={(event) => {equipmentData.wheels 
                                        ? updateEquipmentData({...equipmentData, wheels: {...equipmentData.wheels, 'rearInnerRacesNum': event.target.value}}) 
                                        : updateEquipmentData({...equipmentData, wheels: { 'rearInnerRacesNum': event.target.value}}) 
                                    }}
                                    />
                                    : <Typography className={classes.label}>
                                        {equipmentData.wheels ? (equipmentData.wheels.rearInnerRacesNum ? equipmentData.wheels.rearInnerRacesNum : "") : ""}
                                    </Typography>
                                }
                            </Grid>
                        </Grid>

                        <Grid container>
                            <Grid item xs={4} className={classes.card}>
                                {props.edit 
                                    ?<TextField
                                    size="small"
                                    value={equipmentData.wheels ? (equipmentData.wheels.frontInnerSealNum ? equipmentData.wheels.frontInnerSealNum : "") : ""}
                                    onChange={(event) => {equipmentData.wheels 
                                        ? updateEquipmentData({...equipmentData, wheels: {...equipmentData.wheels, 'frontInnerSealNum': event.target.value}}) 
                                        : updateEquipmentData({...equipmentData, wheels: { 'frontInnerSealNum': event.target.value}}) 
                                    }}
                                    />
                                    : <Typography className={classes.label}>
                                        {equipmentData.wheels ? (equipmentData.wheels.frontInnerSealNum ? equipmentData.wheels.frontInnerSealNum : "") : ""}
                                    </Typography>
                                }
                            </Grid>
                            <Grid item xs={4} className={classes.card}>
                                <Typography className={classes.label}>Seal #</Typography>
                            </Grid>
                            <Grid item xs={4} className={classes.card}>
                                {props.edit 
                                    ?<TextField
                                    size="small"
                                    value={equipmentData.wheels ? (equipmentData.wheels.rearInnerSealNum ? equipmentData.wheels.rearInnerSealNum : "") : ""}
                                    onChange={(event) => {equipmentData.wheels 
                                        ? updateEquipmentData({...equipmentData, wheels: {...equipmentData.wheels, 'rearInnerSealNum': event.target.value}}) 
                                        : updateEquipmentData({...equipmentData, wheels: { 'rearInnerSealNum': event.target.value}}) 
                                    }}
                                    />
                                    : <Typography className={classes.label}>
                                        {equipmentData.wheels ? (equipmentData.wheels.rearInnerSealNum ? equipmentData.wheels.rearInnerSealNum : "") : ""}
                                    </Typography>
                                }
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Paper>

            {/* BRAKES */}
            <Grid container style={{justifyContent: 'center'}}>
                <Paper className={classes.paper}>
                    <Grid item xs={12}>
                        <Typography variant= 'h5' className={classes.header}>Brakes</Typography>
                        <hr></hr>
                        <Grid container>
                            <Grid item xs={4} className={classes.card}>
                                <Typography variant= 'h6' className={classes.header} style={{ textDecoration: 'underline'}}>Left</Typography>
                            </Grid>
                            <Grid item xs={4} className={classes.card}>
                            </Grid>
                            <Grid item xs={4} className={classes.card}>
                                <Typography variant= 'h6' className={classes.header} style={{ textDecoration: 'underline'}}>Right</Typography>
                            </Grid>
                        </Grid>

                        <Grid container>
                            <Grid item xs={4} className={classes.card}>
                                {props.edit 
                                    ?<TextField
                                    size="small"
                                    value={equipmentData.brakes ? (equipmentData.brakes.leftSlackAdjustors ? equipmentData.brakes.leftSlackAdjustors : "") : ""}
                                    onChange={(event) => {equipmentData.brakes 
                                        ? updateEquipmentData({...equipmentData, brakes: {...equipmentData.brakes, 'leftSlackAdjustors': event.target.value}}) 
                                        : updateEquipmentData({...equipmentData, brakes: { 'leftSlackAdjustors': event.target.value}}) 
                                    }}
                                    />
                                    : <Typography className={classes.label}>
                                        {equipmentData.brakes ? (equipmentData.brakes.leftSlackAdjustors ? equipmentData.brakes.leftSlackAdjustors : "") : ""}
                                    </Typography>
                                }
                            </Grid>
                            <Grid item xs={4} className={classes.card}>
                                <Typography className={classes.label}>Slack Adjusters</Typography>
                            </Grid>
                            <Grid item xs={4} className={classes.card}>
                                {props.edit 
                                    ?<TextField
                                    size="small"
                                    value={equipmentData.brakes ? (equipmentData.brakes.rightSlackAdjustors ? equipmentData.brakes.rightSlackAdjustors : "") : ""}
                                    onChange={(event) => {equipmentData.brakes 
                                        ? updateEquipmentData({...equipmentData, brakes: {...equipmentData.brakes, 'rightSlackAdjustors': event.target.value}}) 
                                        : updateEquipmentData({...equipmentData, brakes: { 'rightSlackAdjustors': event.target.value}}) 
                                    }}
                                    />
                                    : <Typography className={classes.label}>
                                        {equipmentData.brakes ? (equipmentData.brakes.rightSlackAdjustors ? equipmentData.brakes.rightSlackAdjustors : "") : ""}
                                    </Typography>
                                }
                            </Grid>
                        </Grid>

                        <Grid container>
                            <Grid item xs={4} className={classes.card}>
                                {props.edit 
                                    ?<TextField
                                    size="small"
                                    value={equipmentData.brakes ? (equipmentData.brakes.leftLiningsHardware ? equipmentData.brakes.leftLiningsHardware : "") : ""}
                                    onChange={(event) => {equipmentData.brakes 
                                        ? updateEquipmentData({...equipmentData, brakes: {...equipmentData.brakes, 'leftLiningsHardware': event.target.value}}) 
                                        : updateEquipmentData({...equipmentData, brakes: { 'leftLiningsHardware': event.target.value}}) 
                                    }}
                                    />
                                    : <Typography className={classes.label}>
                                        {equipmentData.brakes ? (equipmentData.brakes.leftLiningsHardware ? equipmentData.brakes.leftLiningsHardware : "") : ""}
                                    </Typography>
                                }
                            </Grid>
                            <Grid item xs={4} className={classes.card}>
                                <Typography className={classes.label}>Linings/Hardware</Typography>
                            </Grid>
                            <Grid item xs={4} className={classes.card}>
                                {props.edit 
                                    ?<TextField
                                    size="small"
                                    value={equipmentData.brakes ? (equipmentData.brakes.rightLiningsHardware ? equipmentData.brakes.rightLiningsHardware : "") : ""}
                                    onChange={(event) => {equipmentData.brakes 
                                        ? updateEquipmentData({...equipmentData, brakes: {...equipmentData.brakes, 'rightLiningsHardware': event.target.value}}) 
                                        : updateEquipmentData({...equipmentData, brakes: { 'rightLiningsHardware': event.target.value}}) 
                                    }}
                                    />
                                    : <Typography className={classes.label}>
                                        {equipmentData.brakes ? (equipmentData.brakes.rightLiningsHardware ? equipmentData.brakes.rightLiningsHardware : "") : ""}
                                    </Typography>
                                }
                            </Grid>
                        </Grid>

                        <Grid container>
                            <Grid item xs={4} className={classes.card}>
                                {props.edit 
                                    ?<TextField
                                    size="small"
                                    value={equipmentData.brakes ? (equipmentData.brakes.leftCams ? equipmentData.brakes.leftCams : "") : ""}
                                    onChange={(event) => {equipmentData.brakes 
                                        ? updateEquipmentData({...equipmentData, brakes: {...equipmentData.brakes, 'leftCams': event.target.value}}) 
                                        : updateEquipmentData({...equipmentData, brakes: { 'leftCams': event.target.value}}) 
                                    }}
                                    />
                                    : <Typography className={classes.label}>
                                        {equipmentData.brakes ? (equipmentData.brakes.leftCams ? equipmentData.brakes.leftCams : "") : ""}
                                    </Typography>
                                }
                            </Grid>
                            <Grid item xs={4} className={classes.card}>
                                <Typography className={classes.label}>Cams</Typography>
                            </Grid>
                            <Grid item xs={4} className={classes.card}>
                                {props.edit 
                                    ?<TextField
                                    size="small"
                                    value={equipmentData.brakes ? (equipmentData.brakes.rightCams ? equipmentData.brakes.rightCams : "") : ""}
                                    onChange={(event) => {equipmentData.brakes 
                                        ? updateEquipmentData({...equipmentData, brakes: {...equipmentData.brakes, 'rightCams': event.target.value}}) 
                                        : updateEquipmentData({...equipmentData, brakes: { 'rightCams': event.target.value}}) 
                                    }}
                                    />
                                    : <Typography className={classes.label}>
                                        {equipmentData.brakes ? (equipmentData.brakes.rightCams ? equipmentData.brakes.rightCams : "") : ""}
                                    </Typography>
                                }
                            </Grid>
                        </Grid>

                        <Grid container>
                            <Grid item xs={4} className={classes.card}>
                                {props.edit 
                                    ?<TextField
                                    size="small"
                                    value={equipmentData.brakes ? (equipmentData.brakes.leftCamBushings ? equipmentData.brakes.leftCamBushings : "") : ""}
                                    onChange={(event) => {equipmentData.brakes 
                                        ? updateEquipmentData({...equipmentData, brakes: {...equipmentData.brakes, 'leftCamBushings': event.target.value}}) 
                                        : updateEquipmentData({...equipmentData, brakes: { 'leftCamBushings': event.target.value}}) 
                                    }}
                                    />
                                    : <Typography className={classes.label}>
                                        {equipmentData.brakes ? (equipmentData.brakes.leftCamBushings ? equipmentData.brakes.leftCamBushings : "") : ""}
                                    </Typography>
                                }
                            </Grid>
                            <Grid item xs={4} className={classes.card}>
                                <Typography className={classes.label}>Cam Bushings</Typography>
                            </Grid>
                            <Grid item xs={4} className={classes.card}>
                                {props.edit 
                                    ?<TextField
                                    size="small"
                                    value={equipmentData.brakes ? (equipmentData.brakes.rightCamBushings ? equipmentData.brakes.rightCamBushings : "") : ""}
                                    onChange={(event) => {equipmentData.brakes 
                                        ? updateEquipmentData({...equipmentData, brakes: {...equipmentData.brakes, 'rightCamBushings': event.target.value}}) 
                                        : updateEquipmentData({...equipmentData, brakes: { 'rightCamBushings': event.target.value}}) 
                                    }}
                                    />
                                    : <Typography className={classes.label}>
                                        {equipmentData.brakes ? (equipmentData.brakes.rightCamBushings ? equipmentData.brakes.rightCamBushings : "") : ""}
                                    </Typography>
                                }
                            </Grid>
                        </Grid>

                        <Grid container>
                            <Grid item xs={4} className={classes.card}>
                                {props.edit 
                                    ?<TextField
                                    size="small"
                                    value={equipmentData.brakes ? (equipmentData.brakes.leftDrumNum ? equipmentData.brakes.leftDrumNum : "") : ""}
                                    onChange={(event) => {equipmentData.brakes 
                                        ? updateEquipmentData({...equipmentData, brakes: {...equipmentData.brakes, 'leftDrumNum': event.target.value}}) 
                                        : updateEquipmentData({...equipmentData, brakes: { 'leftDrumNum': event.target.value}}) 
                                    }}
                                    />
                                    : <Typography className={classes.label}>
                                        {equipmentData.brakes ? (equipmentData.brakes.leftDrumNum ? equipmentData.brakes.leftDrumNum : "") : ""}
                                    </Typography>
                                }
                            </Grid>
                            <Grid item xs={4} className={classes.card}>
                                <Typography className={classes.label}>Drum #</Typography>
                            </Grid>
                            <Grid item xs={4} className={classes.card}>
                                {props.edit 
                                    ?<TextField
                                    size="small"
                                    value={equipmentData.brakes ? (equipmentData.brakes.rightDrumNum ? equipmentData.brakes.rightDrumNum : "") : ""}
                                    onChange={(event) => {equipmentData.brakes 
                                        ? updateEquipmentData({...equipmentData, brakes: {...equipmentData.brakes, 'rightDrumNum': event.target.value}}) 
                                        : updateEquipmentData({...equipmentData, brakes: { 'rightDrumNum': event.target.value}}) 
                                    }}
                                    />
                                    : <Typography className={classes.label}>
                                        {equipmentData.brakes ? (equipmentData.brakes.rightDrumNum ? equipmentData.brakes.rightDrumNum : "") : ""}
                                    </Typography>
                                }
                            </Grid>
                        </Grid>

                        <Grid container>
                            <Grid item xs={12}>
                                <Divider style={{margin: '20px 0px 10px 0px'}}/>
                            </Grid>
                        </Grid>

                        <Grid container>
                            <Grid item xs={6} className={classes.card}>
                                <Typography className={classes.label} >Steering Axle:</Typography>
                            </Grid>
                            <Grid item xs={6} className={classes.card}>
                                {props.edit
                                    ?<TextField
                                    size="small"
                                    value={equipmentData.brakes ? (equipmentData.brakes.steeringAxle ? equipmentData.brakes.steeringAxle : "") : ""}
                                    onChange={(event) => {equipmentData.brakes 
                                        ? updateEquipmentData({...equipmentData, brakes: {...equipmentData.brakes, 'steeringAxle': event.target.value}}) 
                                        : updateEquipmentData({...equipmentData, brakes: { 'steeringAxle': event.target.value}}) 
                                    }}
                                    />
                                    :<Typography className={classes.label}>
                                        {equipmentData.brakes ? (equipmentData.brakes.steeringAxle ? equipmentData.brakes.steeringAxle : "") : ""}
                                    </Typography>
                                }
                            </Grid>

                            <Grid container>
                                <Grid item xs={6} className={classes.card}>
                                    <Typography className={classes.label} >Rear Axle:</Typography>
                                </Grid>
                                <Grid item xs={6} className={classes.card}>
                                    {props.edit
                                        ?<TextField
                                        size="small"
                                        value={equipmentData.brakes ? (equipmentData.brakes.rearAxle ? equipmentData.brakes.rearAxle : "") : ""}
                                        onChange={(event) => {equipmentData.brakes 
                                            ? updateEquipmentData({...equipmentData, brakes: {...equipmentData.brakes, 'rearAxle': event.target.value}}) 
                                            : updateEquipmentData({...equipmentData, brakes: { 'rearAxle': event.target.value}}) 
                                        }}
                                        />
                                        :<Typography className={classes.label}>
                                            {equipmentData.brakes ? (equipmentData.brakes.rearAxle ? equipmentData.brakes.rearAxle : "") : ""}
                                        </Typography>
                                    }
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Paper>
            </Grid>
            <Snackbar
                //disableAutoFocus={true}
                //disableEnforceFocus={true}
                open={!_.isEqual(equipmentData, saveState)}
                message={saving ? 'Saving...' : 'Save Document?'}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center'
                }}
                action={
                    <React.Fragment>
                        {saving
                            ? null
                            : <React.Fragment>
                                <Button variant='text' color='primary' onClick={cancelSave} style={{ marginLeft: '32px', marginRight: '8px' }}>Cancel</Button>
                                <Button variant='contained' onClick={confirmSave}>Confirm</Button>
                            </React.Fragment>
                        }
                    </React.Fragment>
                } />
        </Grid>
    )
}
export default withRouter(WheelBrakesTab)