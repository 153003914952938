import * as actionTypes from '../actions/actionTypes'
import { updateObject } from '../utility'

const initialState = {
    equipInv: [],
    loading: false,
    added: false,
    description: '',
    number: '',
}

const equipmentInvReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.BEGIN:
            return updateObject(state, { loading: action.loading });
        case actionTypes.GET_EQUIP_INV:
            return updateObject(state, { equipInv: action.equipInv, loading: action.loading });
        case actionTypes.ADD_UNIT:
            //TODO: ADD UNIT
            return updateObject(state, state);
        case actionTypes.UPDATE_DESCRIPTION:
            return updateObject(state, { description: action.description });
        case actionTypes.UPDATE_NUMBER:
            return updateObject(state, { number: action.number });
        default:
            return state;
    }
};

export default equipmentInvReducer;