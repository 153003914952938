import React, { memo } from 'react';
import { makeStyles, } from '@material-ui/core';
import _ from 'lodash';

import ErrorableTextfield from '../CellComponents/ErrorableTextfield';

const useStyles = makeStyles((theme) => ({
    rowContent: {
        width: '90%',
        fontSize: '14px',
        fontWeight: 600,
    },
}));

const DataGridTableTextfield= ({data, id, field, docId, handleCellUpdateCallback, cellClickCallback, style}) => {
    const classes = useStyles();

    let valueContainer = data;

    // let debouncedHandleCellChange = _.debounce(function (value) {
    //     let valueContainer2 = !_.isUndefined(value) ? value : '';
    //     valueContainer = valueContainer2
    //     handleCellUpdateCallback(valueContainer2, field, docId)
    // }, 1000);

    //console.log('DataGridTableTextfield rendered', docId)

    return(
        <>
            <ErrorableTextfield
                inputProps={{ className: classes.rowContent }}
                className={classes.rowContent}
                id={id}
                value={valueContainer || ''}
                error={false}
                // onClick={() => {
                //     cellClickCallback(`${docId} ${field}`) 
                // }}
                onChange={(e) => { 
                    let valueContainer2 = !_.isUndefined(e.target.value) ? e.target.value : '';
                    valueContainer = valueContainer2
                    handleCellUpdateCallback(valueContainer2, field, docId)

                    //valueContainer = e.target.value
                    //debouncedHandleCellChange(e.target.value);
                }}
            />
        </>
    )
}

export default memo(DataGridTableTextfield)