import React, { useState, useEffect} from 'react'
import { Typography, TextField  } from '@material-ui/core';

const ErrorableTextfield = (props) => {

    const [internalValue, setInternalValue] = useState(props.value)
    useEffect(() => { setInternalValue(props.value) }, [props.value])

    return (
        <>
            <TextField 
                {...props}
                value={internalValue}
                onChange={(e)=> {
                    setInternalValue(e.target.value);
                    props.onChange(e);
                }}
                error={props.error}
            />
            {props.error && <Typography style={{fontSize: '12px', color: '#F44336'}}>Value is not unique</Typography> }
        </>
    );
    }
  export default ErrorableTextfield;