import * as actionTypes from '../actions/actionTypes'
import { updateObject } from '../utility'

//Make sure reducers have an initialState 
const initialState = {
    //access this state in your components using the name you 
    // set in index.js (you did set it in combineReducers, right?)
    // careets.state.careers 
    // or career.state.loading

    careers: [],
    selectedCareer: "Driver",

    resumeForm: {
        name: {
            elementType: 'input',
            elementConfig: {
                type: 'text',
                label: 'Full Name',
                required: true,
            },
            value: '',
        },
        email: {
            elementType: 'input',
            elementConfig: {
                type: 'email',
                label: 'Email',
                required: true,
            },
            value: '',
        },
        phone: {
            elementType: 'input',
            elementConfig: {
                type: 'phone',
                label: 'Phone Number (204-555-5555)',
                required: true,
                inputProps: { pattern: "[0-9]{3}-[0-9]{3}-[0-9]{4}" },
            },
            value: '',
        },
        coverLetter: {
            elementType: 'textfield',
            elementConfig: {
                type: 'text',
                label: 'Cover Letter',
                required: true,
            },
            value: '',
        },
        resume: {
            elementType: 'textfield',
            elementConfig: {
                type: 'text',
                label: 'Resume',
                required: true,
            },
            value: '',
        },
    },
    formSubmitted: false,
    careerLoading: false,
    successMessage: "",
};

const submitResume = (state, action) => {

    for (const [key] of Object.entries(state.resumeForm))
        state.resumeForm[key].value = '';

    return{
        careerLoading: action.loading, 
        formSubmitted: action.formSubmitted, 
        successMessage: action.successMessage,
        resumeForm: state.resumeForm,
    }
}

const careersReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.BEGIN:
            return updateObject(state, { careerLoading: action.careerLoading });
        case actionTypes.GET_CAREERS: 
            return updateObject(state, { careers: action.careers, careerLoading: action.careerLoading });
        case actionTypes.SET_CAREER: 
            return updateObject(state, { selectedCareer: action.selectedCareer, successMessage: ''});
        case actionTypes.UPDATE_RESUME: 
            return updateObject(state, { resumeForm: action.resumeForm});
        case actionTypes.SUBMIT_RESUME:
            return updateObject(state, submitResume(state, action));
        default:
            return state;
    }
};

export default careersReducer;