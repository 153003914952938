export const GET_PRODUCT = 'GET_PRODUCT';
export const GET_PRODUCTS = 'GET_PRODUCTS';
export const GET_FEATURED_PRODUCTS = 'GET_FEATURED_PRODUCTS';
export const FETCH_FEATURED_PRODUCTS = 'FETCH_FEATURED_PRODUCTS';
export const FETCH_PRODUCT = 'FETCH_PRODUCT';
export const FETCH_PRODUCTS = 'FETCH_PRODUCTS';
export const CREATE_PRODUCT = 'CREATE_PRODUCT';
export const EDIT_PRODUCT = 'EDIT_PRODUCT';
export const DELETE_PRODUCT = 'DELETE_PRODUCT';
export const FETCH_EQUIPMENT = 'FETCH_EQUIPMENT';
export const GET_EQUIPMENT = 'GET_EQUIPMENT';

//SERVICE ACTIONS
export const GET_SERVICES = 'GET_SERVICES';

export const BEGIN = 'BEGIN';

//CAREER ACTIONS
export const GET_CAREERS = 'GET_CAREERS';
export const SET_CAREER = 'SET_CAREER';
export const UPDATE_RESUME = 'UPDATE_RESUME';
export const SUBMIT_RESUME = 'SUBMIT_RESUME';

//CART ACTIONS
export const ADD_TO_CART = 'ADD_TO_CART';
export const REMOVE_FROM_CART = 'REMOVE_FROM_CART';
export const RESET_CART = 'RESET_CART';

export const INCR_QTY_CART = 'INCR_QTY_CART';
export const DECR_QTY_CART = 'DECR_QTY_CART';

export const SET_SHIP_METHOD = 'SET_SHIP_METHOD';

export const SET_SUBTOTAL = 'SET_SUBTOTAL';
export const CALC_SHIPPING = 'CALC_SHIPPING';

export const FETCH_TAX = 'FETCH_TAX';
export const GET_SALES = 'GET_SALES';
export const GET_INPUT_COUPON = 'GET_INPUT_COUPON';
export const SET_COUPON = 'SET_COUPON';
export const REMOVE_COUPON = 'REMOVE_COUPON';
export const SET_GIFT_CARD = 'SET_GIFT_CARD';


export const GET_TAX = 'GET_TAX';

export const CLEAR_IS_ADDED = 'CLEAR_IS_ADDED';
export const CONFIRM_BULK_PRICING = 'CONFIRM_BULK_PRICING';

//CHECKOUT ACTIONS
export const GET_CHECKOUT = 'GET_CHECKOUT';
export const CREATE_PAYMENT = 'CREATE_PAYMENT';
export const UPDATE_CHECKOUT = 'UPDATE_CHECKOUT';
export const VALIDATE_CHECKOUT = 'VERIFY_CHECKOUT';
export const RESET_CHECKOUT = 'RESET_CHECKOUT';
export const GENERATE_TOKEN = 'GENERATE_TOKEN';
export const SET_TOKEN = 'SET_TOKEN';
export const SET_DISABLED = 'SET_DISABLED'

export const SET_ADDRESS = 'SET_ADDRESS';
export const SET_COORDINATES = 'SET_COORDINATES';
export const SET_DISTANCE = 'SET_DISTANCE';

export const BEGIN_PAYMENT = 'BEGIN_PAYMENT';
export const COMPLETE_PAYMENT = 'COMPLETE_PAYMENT';
export const RESET_CHECKOUTFORM = 'RESET_CHECKOUTFORM'
export const SET_NEXT_AVAILABLE_DELIVERDATE = 'SET_NEXT_AVAILABLE_DELIVERDATE'

export const PAYMENT_FAILED = 'PAYMENT_FAILED';

//actionTypes for Users(Staff)
export const GET_USERS = 'GET_USERS';
export const ADD_USER = 'ADD_USER';
export const UPDATE_USERNAME = 'UPDATE_USERNAME';
export const UPDATE_EMAIL = 'UPDATE_EMAIL';
export const UPDATE_ADMIN = 'UPDATE_ADMIN';
export const SET_OPEN = 'SET_OPEN';
export const SET_USER = 'SET_USER';

//actionTypes for Reports
export const GET_TIMESHEETS = 'GET_TIMESHEETS';
export const GET_DELIVERIES = 'GET_DELIVERIES';
export const GET_JOBS = 'GET_JOBS';
export const GET_DEFECTS = 'GET_DEFECTS';
export const GET_TRAININGS = 'GET_TRAININGS';
export const UPDATE_DATE = 'UPDATE_DATE';
export const SET_REPORT_TYPE = 'SET_REPORT_TYPE'

//actionTypes for Customers
export const GET_CUSTOMERS = 'GET_CUSTOMERS';
export const ADD_CUSTOMER = 'ADD_CUSTOMER';
export const UPDATE_CITY = 'UPDATE_CITY';
export const UPDATE_NAME = 'UPDATE_NAME';
export const UPDATE_POSTAL_CODE = 'UPDATE_POSTAL_CODE';
export const UPDATE_PROVINCE = 'UPDATE_PROVINCE';
export const UPDATE_STREET_NUMBER = 'UPDATE_STREET_NUMBER';
export const UPDATE_STREET_NAME = 'UPDATE_STREET_NAME';

//actionTypes for Equipment(Units)
export const GET_EQUIP_INV = 'GET_EQUIP_INV';
export const ADD_UNIT = 'ADD_UNIT';
export const UPDATE_DESCRIPTION = 'UPDATE_DESCRIPTION';
export const UPDATE_NUMBER = 'UPDATE_NUMBER';


export const INCR_QTY = 'INCR_QTY';
export const DECR_QTY = 'DECR_QTY';
export const SET_QTY = 'SET_QTY';
export const SET_ERROR = 'SET_ERROR';
export const UPDATE_CONTACT = 'UPDATE_CONTACT';
export const ADD_CONTACT = 'ADD_CONTACT';
export const SET_PRICE = 'SET_PRICE';

export const SET_CURRENT_VARIANT = 'SET_CURRENT_VARIANT';

export const ORDERS_FETCH = 'ORDERS_FETCH';
export const ORDERS_SUCCESS = 'ORDERS_SUCCESS';
export const ORDERS_FAIL = 'ORDERS_FAIL';
