import React, { useState, useEffect, useRef } from 'react'
import { Typography, Paper, Grid, Divider, Button, Modal, TextField, Backdrop, Fade, CircularProgress,
Badge} from '@material-ui/core'
import { Skeleton } from '@material-ui/lab'
import EmailIcon from '@material-ui/icons/Email';
import SendIcon from '@material-ui/icons/Send';
import moment from 'moment';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import PhoneAndroidIcon from '@material-ui/icons/PhoneAndroid';
import { functions, firestore } from '../../../../firebase/firebase';
import DoneIcon from '@material-ui/icons/Done';
import _, { isEmpty } from 'lodash';

const CustomerCard = (props) => {
    const emailCustomer = functions.httpsCallable('sendCustomEmail');
    const addContact = functions.httpsCallable('addSimpleTextContact');
    const sendText = functions.httpsCallable('sendText');
    const grabAPIMessages = functions.httpsCallable('fetchConversations');

    const [open, setOpen] = useState(false);
    const [openTexts, setOpenTexts] = useState(false);
    const [messageContent, setMessageContent] = useState('');
    const [messageUser, setMessageUser] = useState('Gee Tee');
    const [messageSubject, setMessageSubject] = useState('Regarding your order');
    const [sendingEmail, setSendingEmail] = useState(false);
    const [emailError, setEmailError] = useState('');
    const [success, setSuccess] = useState(false);

    const [unreadMessageCount, setUnreadMessageCount] = useState(0)
    const [sending, setSending] = useState(false); 
    const [reply, setReply ] = useState('');
    const [loading, setLoading] = useState(false)
    const [contactExists , setContactExists] = useState(false);
    const [contact, setContact] = useState({});
    const [conversation, setConversation] = useState([])

    const gridRef = useRef(null);
    const date = moment().format('MMMM Do YYYY, h:mm:ss a');
    const allMessages = useRef([])

    //gets contact data and validates db conversation with api
    useEffect(() => {
        handleFetchContact()
        validateConversation()
        //eslint-disable-next-line
    }, [])

    //run handleFetchContact and validateConversation every 3 seconds
    useEffect(() => {
        const interval = setInterval(() => {
            //TEMP DISABLED FOR NOW SO I CAN READ CLOUD FUNC LOGS
            //handleFetchContact()
            //validateConversation()
        }, 3000);
        return () => clearInterval(interval);
        //eslint-disable-next-line
    }, [])

    //create snapshot listener and sets inital conversation data
    useEffect(() => {
        let unsubscribe = firestore.collection('messages').where('contactPhone', '==', props.order.customer.phone.replace(/\s/g, '').replace(/-/g, '').replace(/\(/g, '').replace(/\)/g, '')).onSnapshot((snapshot) => {

            let messageContainer = []
            snapshot.forEach((doc) => {
                messageContainer.push(doc.data())
            })
            allMessages.current = messageContainer
            setConversation(messageContainer)
        });
        return () => { unsubscribe(); }
        //eslint-disable-next-line
    }, [])

    //filters for unread
    useEffect(() => {
        if(conversation.length > 0){
            let unread = 0;
            conversation.forEach((message) => {
                if(!message.read && !_.isUndefined(message.read)){
                    unread++;
                }
            })
            setUnreadMessageCount(unread)
        }
        //eslint-disable-next-line
    }, [conversation])
    
    useEffect(() => {}, [props.orderData])

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        setReply('')
    };
    
    const handleCloseTexts = () => {
        setOpenTexts(false);
    };

    const handleOpenTexts = () => {
        setOpenTexts(true);
        
        //for each message, mark as read
        conversation.forEach((message) => {
            if(!message.read){
                markRead(message)
            }
        })
    };

    const handleAfterModalOpen = () => {
        // Wait for the modal to fully open before scrolling to the bottom
        const element = gridRef.current;
        element.scrollTop = element.scrollHeight;
    };

    const markRead = async (message) => {
       firestore.collection('messages').doc(message.id).update({read: true})
    }

    //format phone number to look like (xxx) xxx-xxxx
    const formatPhone = (phone) => {
        let formatted = phone
        if(phone.length === 10){
            formatted = `(${phone.slice(0,3)}) ${phone.slice(3,6)}-${phone.slice(6,10)}`
        }
        return formatted
    }

    // validates if all contacts api messages are on the db
    const validateConversation = async () =>  {
        let batch = firestore.batch();
        let convoDbContainer = []

        firestore.collection('messages').where('contactPhone', '==', props.order.customer.phone.replace(/\s/g, '').replace(/-/g, '').replace(/\(/g, '').replace(/\)/g, '')).get()
        .then((querySnapshot) => {
            convoDbContainer = querySnapshot.docs.map((doc) => {
                return { ...doc.data()}
            });
            grabAPIMessages({
                sandbox: process.env.NODE_ENV === 'development',
                phone: props.order.customer.phone.replace(/\s/g, '').replace(/-/g, '').replace(/\(/g, '').replace(/\)/g, '')
            }).then((res) => {
                let convos = res.data?.content || []
                convos.forEach((message) => {
                    if(!convoDbContainer.map((message) => { return message.id }).includes(message.id)){
                        convoDbContainer.push(message)
                        let query = firestore.collection('messages').doc(message.id)
                        batch.set(query, message);
                    }
                })
            }).finally(() => { 
                batch.commit()
            }).catch((err) => {
                console.warn(err)
            })
        }).catch((err) => {
            console.warn(err)
        })
    }

    const addMessage = (message) => {
        firestore.collection('messages').doc(message.id).set(message, { merge: true })
    } 

    const sendSMS = async (phoneMessage) => {
        setSending(true)
        await sendText({
            sandbox: process.env.NODE_ENV === 'development',
            phone: props.order.customer.phone.replace(/\s/g, '').replace(/-/g, '').replace(/\(/g, '').replace(/\)/g, ''),
            message: phoneMessage,
        }).then((res) => {
            let message = {
                id: res.data.id,
                text: phoneMessage,
                contactPhone: props.order.customer.phone.replace(/\s/g, '').replace(/-/g, '').replace(/\(/g, '').replace(/\)/g, ''),
                directionType: 'MT',
                timestamp: moment().valueOf(),
                read: true,
                delivered: false,
                sending: true,
            }

            addMessage(message)
                        //wait 5 seconds then update messages delivered to true and sending to false
            //sorry lando but this is happening here lol
            setTimeout(() => {
                firestore.collection('messages').doc(message.id).update({ delivered: true, sending: false })
            }, 5000)
            setReply('')
            setSending(false)
        }).catch((err) => {
            console.warn(err)
            setReply('')
            setSending(false)
        })
    }

    const handleFetchContact = async () => {
        //use firebase smsContacts
        if(!_.isUndefined(props.order.customer.phone) && !isEmpty(props.order.customer.phone)){
            let phone = props.order.customer.phone.replace(/\s/g, '').replace(/-/g, '').replace(/\(/g, '').replace(/\)/g, '')
            const contactsRef = firestore.collection('smsContacts').doc(phone)
            const snapshot = await contactsRef.get()
            if (snapshot.exists) {
                setContactExists(true);
                setContact(snapshot.data())
            } else {
                setContactExists(false);
            }
        }
    }

    //adds a contact to both fb and simpleTexting
    const handleAddContact = async () => {
        setLoading(true);
        let userContainer = {
            firstName: props.order.customer.givenName,
            lastName: props.order.customer.familyName,
            contactPhone: props.order.customer.phone.replace(/\s/g, '').replace(/-/g, '').replace(/\(/g, '').replace(/\)/g, ''),
            unsubscribed: false,
        }
        addContact({
            sandbox: process.env.NODE_ENV === 'development',
            firstName: props.order.customer.givenName,
            lastName: props.order.customer.familyName,
            phone: props.order.customer.phone.replace(/\s/g, '').replace(/-/g, '').replace(/\(/g, '').replace(/\)/g, ''),
        }).then(() => {
            let contactContainer = _.cloneDeep(contact)
            contactContainer.push(userContainer)
            setContact(contactContainer)
            setLoading(false);
        }).catch((err) => {
            console.warn(err)
            setLoading(false);
        })
    }

    const handleSend = async () => {
        if(!sendingEmail)
       { setSendingEmail(true);
        emailCustomer({
            sandbox: process.env.NODE_ENV === 'development',
            messageContent: messageContent,
            messageUser: messageUser,
            messageDateTime: date,
            subject: messageSubject,
            email: props.order.customer.email
        }).then((res) => {
            setSuccess(res.data.success)
            setSendingEmail(false);
            if(res.data.success)
            {
                //setTimeout for 3 seconds
                setTimeout(() => {
                    setSuccess(false);
                }
                , 3000);
            }
        }).catch((err) => {
            console.log(err)
            setEmailError(err);
            setSendingEmail(false);
        })}
    }

    return (
        props.loading || !props.order ? <Skeleton></Skeleton> :
            (props.order && <Paper elevation={2} style={{ padding: "16px", margin: "8px", color: '#707070' }}>
                <Grid container spacing={2}>
                    <Grid container>
                        <Grid item xs={12}>
                            <Typography style={{ lineHeight: 2 }} noWrap variant='h6'>Customer Details</Typography>
                        </Grid>
                        <Grid item xs={4} >
                            <Typography style={{ lineHeight: 2 }} noWrap variant='body1'>Name:</Typography>
                        </Grid>
                        <Grid item xs={8}>
                            {!props.edit?<Typography style={{ lineHeight: 2 }} align='right' variant='body1'>{props.order.customer.givenName + " " + props.order.customer.familyName}</Typography>
                            :
                            <Grid container>
                                <Grid item xs={6}>
                                    <TextField 
                                    variant='outlined'
                                    label='First Name'
                                    
                                    value={props.order.customer.givenName}
                                    onChange={(event) => props.updateOrder({ ...props.order, customer: { ...props.order.customer, givenName: event.target.value } })}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <TextField 
                                    variant='outlined'
                                    label='Last Name'
                                    
                                    value={props.order.customer.familyName}
                                    onChange={(event) => props.updateOrder({ ...props.order, customer: { ...props.order.customer, familyName: event.target.value } })}
                                    />
                                </Grid>
                            </Grid>
                            }
                        
                        
                        </Grid>
                        <Grid item xs={4} >
                            <Typography style={{ lineHeight: 2 }} noWrap variant='body1'>Email:</Typography>
                        </Grid>
                        <Grid item xs={8}>
                            {!props.edit?<Typography style={{ lineHeight: 2 }} align='right' variant='body1'>{props.order.customer.email}</Typography>:
                            <TextField 
                                variant='outlined'
                                label='Email'
                                fullWidth
                                value={props.order.customer.email}
                                onChange={(event) => props.updateOrder({ ...props.order, customer: { ...props.order.customer, email: event.target.value } })}
                                />
                            }
                        </Grid>
                        <Grid item xs={4} >
                            <Typography style={{ lineHeight: 2 }} noWrap variant='body1'>Phone:</Typography>
                        </Grid>
                        <Grid item xs={8}>
                        {!props.edit?<Typography style={{ lineHeight: 2 }} align='right' variant='body1'>{props.order.customer.phone}</Typography>
                            :<TextField 
                            variant='outlined'
                            label='Phone'
                            fullWidth
                            value={props.order.customer.phone}
                            onChange={(event) => props.updateOrder({ ...props.order, customer: { ...props.order.customer, phone: event.target.value } })}
                            />
                        }
                        </Grid>
                        <Grid item xs={4} >
                            <Typography style={{ lineHeight: 2 }} noWrap variant='body1'>Address:</Typography>
                        </Grid>
                        <Grid container item xs={8}>
                            <Grid item xs={12}>
                            {!props.edit?<Typography style={{ lineHeight: 2 }} variant='body1' align='right'>{props.order.customer.addressLines[0]}</Typography>
                            
                            :
                            <TextField 
                                    variant='outlined'
                                    label='Address'
                                    fullWidth
                                    value={props.order.customer.addressLines[0]}
                                    onChange={(event) => props.updateOrder({ ...props.order, customer: { ...props.order.customer, addressLines: [event.target.value] } })}
                                    />
                            }
                            </Grid>
                            <Grid item xs={12}>
                            {!props.edit?<Typography style={{ lineHeight: 2 }} variant='body1' align='right'>{props.order.customer.city + ", " + props.order.customer.province}</Typography>
                            :
                            <Grid container>
                                <Grid item xs={6}>
                                    <TextField 
                                    variant='outlined'
                                    label='City'
                                    value={props.order.customer.city}
                                    onChange={(event) => props.updateOrder({ ...props.order, customer: { ...props.order.customer, city: event.target.value } })}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <TextField 
                                    variant='outlined'
                                    label='Province'
                                    value={props.order.customer.province}
                                    onChange={(event) => props.updateOrder({ ...props.order, customer: { ...props.order.customer, province: event.target.value } })}
                                    />
                                </Grid>
                            </Grid>
                            }
                            </Grid>
                            <Grid item xs={12}>
                            {!props.edit?<Typography style={{ lineHeight: 2 }} variant='body1' align='right'>{props.order.customer.postalCode}</Typography>
                            
                            :
                            <TextField 
                            variant='outlined'
                            label='Postal Code'
                            fullWidth
                            value={props.order.customer.postalCode}
                            onChange={(event) => props.updateOrder({ ...props.order, customer: { ...props.order.customer, postalCode: event.target.value } })}
                            />
                            }
                            </Grid>
                        </Grid>
                        <Grid item xs={4} >
                            <Typography style={{ lineHeight: 2 }} noWrap variant='body1'>Contact Method:</Typography>
                        </Grid>
                        <Grid item xs={8}>
                            <Typography style={{ lineHeight: 2 }} align='right' variant='body1'>{props.order.customer?.contactMethod ? props.order.customer.contactMethod : 'Email'}</Typography>
                        </Grid>
                        <Grid container item xs={12}>
                            <Divider style={{margin:'5px', width:'100%'}}/>
                        </Grid>
                        <Grid container item xs={6}>
                            <Button disabled={!props.order.customer.geeteeEmailSent || sendingEmail || ( _.isUndefined(props.order.customer.contactMethod))} startIcon={<EmailIcon/>} variant='contained' color='primary' style={{margin:'5px'}} onClick={() => handleOpen()}>
                                {sendingEmail ? <CircularProgress/> : 'Email Customer'}
                            </Button>
                            {!props.order.customer.geeteeEmailSent && _.isUndefined(props.order.customer.contactMethod) ? <Typography style={{marginLeft:'10px', color:'red'}}><small>Confirmation Email hasn't been recieved.</small></Typography> : null}
                            {props.order.customer.contactMethod === 'Text Message' ? <Typography style={{marginLeft:'10px', color:'blue'}}><small>Customer contact prefers Text Message.</small></Typography> : null}
                        </Grid>
                        <Grid container item xs={6} justifyContent='flex-end'>
                        {contactExists ?<Badge  color="secondary" max={10} badgeContent={unreadMessageCount} ><Button disabled={!props.order.customer.phone || !contactExists || contact.unsubscribed} startIcon={<PhoneAndroidIcon/>} variant='contained' color='primary' style={{margin:'5px'}} onClick={() => handleOpenTexts()}>
                                {sending ? <CircularProgress/> : 'Text Customer'}
                            </Button></Badge> :
                            <Button disabled={!props.order.customer.phone || loading} startIcon={loading ? <CircularProgress/> : <PhoneAndroidIcon/>} variant='contained' color='primary' style={{margin:'5px'}} onClick={() => handleAddContact()}>Add SMS Contact</Button>}
                            
                            
                        </Grid>
                        <Grid container><Grid item xs={6}></Grid>
                        {contact.unsubscribed ? <Grid item xs={6}><Typography style={{marginLeft:'10px', color:'red'}}><small>Customer has texted STOP.</small></Typography></Grid> : null}
                        {!props.order.customer.phone ? <Grid item xs={6}><Typography style={{marginLeft:'10px', color:'red'}}><small>No Phone Number on file.</small></Typography></Grid> : null}
                        </Grid>
                        
                    </Grid>
                </Grid>

                {/* sms modal */}
                <Modal
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        outline: 0,
                    }}
                    open={openTexts}
                    ref={() => { handleAfterModalOpen() }}
                    onClose={() => { handleCloseTexts() }}
                    closeAfterTransition
                    BackdropComponent={Backdrop}
                    BackdropProps={{
                        timeout: 500,
                    }}>
                    <Fade in={openTexts} >
                        <Paper style={{ padding: '16px', maxWidth:'900px', minWidth:'800px' }}>
                            <Grid container spacing={2} style={{marginBottom:'10px'}}>
                                <Grid item xs={12}>
                                    <Typography variant='h6' style={{ marginRight: '8px' }}>Send Text Message</Typography>
                                </Grid>
                            </Grid>

                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <Typography style={{fontSize:'20pt', marginLeft:'5px'}}>
                                        {props.order.customer.givenName} {props.order.customer.familyName}
                                    </Typography>
                                    <Typography variant={'h6'} style={{ marginLeft:'5px'}}>
                                        {formatPhone(props.order.customer.phone.replace(/\s/g, '').replace(/-/g, '').replace(/\(/g, '').replace(/\)/g, ''))}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography style={{fontSize:'10pt', marginLeft:'5px'}}>
                                        Order #{props.order.order.orderID}
                                    </Typography>
                                </Grid>
                            </Grid>
                                    
                            <Divider/>

                            <Grid container spacing={2}>
                                <Grid item  ref={gridRef} xs={12} style={{margin:'10px', backgroundColor:'#333',overflowY: 'scroll', minHeight:'250px', maxHeight:'400px'}}>
                                    <div style={{ borderRadius:'10px', padding:'10px'}}>
                                    {conversation.length > 0 ? 
                                        conversation.map((message, index) => {
                                            return (
                                                <Grid item xs={12} key={index}>
                                                    <div style={message.directionType === 'MO' ? 
                                                        {backgroundColor:'#3db8ff', margin:'1% 50% 1% 1%', borderRadius:'10px', padding:'10px',}
                                                        //DELIVERED
                                                        :(!message.sending && message.delivered) ? {backgroundColor:'#FFBF00', margin:'1% 1% 1% 50%', borderRadius:'10px', padding:'10px'}
                                                            //FAILED
                                                            : (!message.sending && !message.delivered) ? {backgroundColor:'black', color:'red', margin:'1% 1% 1% 50%', borderRadius:'10px', padding:'10px', opacity:'50%'} 
                                                                //SENDING
                                                                : {backgroundColor:'#FFBF00', margin:'1% 1% 1% 50%', borderRadius:'10px', padding:'10px', opacity:'50%'}
                                                    }>

                                                        <Typography style={{fontSize:'16px'}}><b>{message.directionType === 'MO' ? message.contactPhone : 'Gee Tee'}:</b></Typography>
                                                        <Typography>{message.text}</Typography>
                                                        <Divider style={{width:'10%'}}/>
                                                        {(message.directionType === 'MO') ?<Typography variant="caption"><i>Recieved {moment(message.timestamp).format('MMM Do @ hh:mm a')}</i></Typography>:null}
                                                        {(message.sending && !message.delivered && message.directionType === 'MT') ?<Typography variant="caption"><Grid container><Grid item xs={11}><i>Sending...</i></Grid> <Grid container item xs={1} justifyContent="flex-end"><CircularProgress size={10} color='secondary'/></Grid></Grid></Typography>:null}
                                                        {(!message.sending && !message.delivered&& message.directionType === 'MT') ?<Typography variant="caption"><i>Failed to send</i></Typography>:null}
                                                        {(!message.sending && message.delivered && message.directionType === 'MT') ?<Typography variant="caption"><Grid container><Grid item xs={10}><i>Delivered {moment(message.timestamp).format('MMM Do @ hh:mm a')}</i></Grid> <Grid container item xs={2} justifyContent='flex-end'><DoneIcon style={{fontSize:'14px'}}/></Grid></Grid></Typography>:null}
                                                    </div>
                                                </Grid>
                                            )

                                        }) 
                                    :
                                    <div style={{ margin:'1% 1% 1% 50%', borderRadius:'10px', padding:'10px', color:'grey'}}>
                                        <Divider style={{width:'50%'}}/>
                                        <Typography >No messages found...</Typography>
                                        <Typography >Send a text to start the conversation.</Typography>
                                        <Divider style={{width:'50%'}}/>
                                    </div>}
                                    </div>
                                </Grid>
                            
                                <TextField 
                                    variant='outlined'
                                    label='Reply'
                                    placeholder='Reply'
                                    style={{width:'100%'}}
                                    value={reply}
                                    disabled={sending || contact.unsubscribed}
                                    onChange={(e) => setReply(e.target.value)}
                                    inputProps={{
                                        maxLength: 159,
                                        
                                    }}
                                />
                            </Grid>

                            <Divider style={{margin:'10px'}}/>

                            <Grid container spacing={2}>
                                <Grid item xs={3}>
                                    <Button 
                                        disabled={sending} 
                                        variant='contained' 
                                        color='secondary' 
                                        onClick={() => handleCloseTexts()}
                                    >
                                        Cancel
                                    </Button>
                                </Grid>
                                <Grid item xs={6}>
                                    {success ? <Typography align='center' variant={'h6'} style={{color:'green', marginLeft:'10px'}}>Successfully Sent!</Typography>: null}
                                </Grid> 
                                <Grid container item xs={3} justifyContent="flex-end">
                                    <Button 
                                        disabled={sending || contact.unsubscribed} 
                                        variant='contained' 
                                        color='primary' 
                                        startIcon={sending || success ? null : <SendIcon/>} 
                                        style={success ? {backgroundColor:'green'} : { }} 
                                        onClick={() => { sendSMS(reply) }}
                                    >
                                        {sending ? <CircularProgress color='secondary' /> : success ? <CheckCircleIcon style={{color:'white'}}/> : 'Send'}
                                    </Button>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography style={{marginLeft:'10px', color:'red'}}><small>{emailError}</small></Typography>
                                </Grid>
                            </Grid>
                        </Paper>
                    </Fade>
                </Modal>


                {/* email modal */}
                <Modal
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        outline: 0,
                    }}
                    open={open}
                    onClose={handleClose}
                    closeAfterTransition
                    BackdropComponent={Backdrop}
                    BackdropProps={{
                        timeout: 500,
                    }}>
                <Fade in={open}>
                    <Paper style={{ padding: '16px', maxWidth:'700px' }}>
                        <Grid container spacing={2} style={{marginBottom:'20px'}}>
                            <Grid item xs={12}>
                                <Typography variant='h6' style={{ marginRight: '8px' }}>Send Email</Typography>
                            </Grid>
                            

                        </Grid>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <TextField 
                                    label='From'
                                    variant='outlined'
                                    value={messageUser}
                                    onChange={(event) => setMessageUser(event.target.value)}

                                />

                            </Grid>
                            <Grid item xs={12}>
                                <Typography style={{fontSize:'10pt', marginLeft:'5px'}}>
                                    Order #{props.order.order.orderID}
                                </Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Typography style={{fontSize:'10pt', marginLeft:'5px'}}>
                                    To: <b>{props.order.customer.email}</b>
                                </Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Typography style={{fontSize:'10pt', color:'grey', marginLeft:'5px'}}>
                                    {date}
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                            <TextField 
                                    label='Subject'
                                    variant='outlined'
                                    fullWidth
                                    value={messageSubject}
                                    onChange={(event) => setMessageSubject(event.target.value)}

                                />
                            </Grid>
                            <Grid item xs={12}>
                                {/*<TextField 
                                    label='Message'
                                    variant='outlined'
                                    multiline
                                    fullWidth
                                    minRows={6}
                                    //maxRows={4}
                                    value={messageContent}
                                    onChange={(event) => setMessageContent(event.target.value)}

                                />*/}
                                <ReactQuill theme="snow" value={messageContent} onChange={setMessageContent} style={{marginBottom:'10px'}} />

                            </Grid>

                        </Grid>
                        <Divider style={{margin:'10px'}}/>
                        <Grid container spacing={2}>
                            <Grid item xs={3}>
                                <Button disabled={sendingEmail} variant='contained' color='secondary' onClick={() => handleClose()}>Cancel</Button>
                            </Grid>
                            <Grid item xs={6}>
                                {success ? <Typography align='center' variant={'h6'} style={{color:'green', marginLeft:'10px'}}>Successfully Sent!</Typography>: null}
                            </Grid> 
                            <Grid container item xs={3} justifyContent="flex-end">
                                <Button disabled={sendingEmail} variant='contained' color='primary' startIcon={sendingEmail || success ? null : <SendIcon/>} style={success ? {backgroundColor:'green'} : { }} onClick={() => handleSend()}>
                                {sendingEmail ? <CircularProgress color='secondary' /> : success ? <CheckCircleIcon style={{color:'white'}}/> : 'Send'}
                                </Button>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography style={{marginLeft:'10px', color:'red'}}><small>{emailError}</small></Typography>
                            </Grid>
                        </Grid>
                    </Paper>
                    </Fade>
                </Modal>
            </Paper>
           
            )
    )
}
export default CustomerCard

