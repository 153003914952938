import piexif from "piexifjs";

//converts the exif GPS coordinates from DMS to DD
const convertDMStoDD = (parsedExif) => {
    parsedExif.GPScoordinates = {};
    if(!parsedExif.GPSLatitude || !parsedExif.GPSLongitude || !parsedExif.GPSLatitudeRef || !parsedExif.GPSLongitudeRef){ return parsedExif;}
    let values = {GPSLatitude: 'lat', GPSLongitude: 'lng'}

    Object.entries(values).forEach(([key, value]) => {
        //for each set of arrays in GPSLatitude, calculate DD value and add it into a total
        parsedExif[key].forEach((pairing, index) => {
            switch(index) {
                case 0: //degrees
                    parsedExif.GPScoordinates[value] = (Number(pairing[0]) / Number(pairing[1]))
                    break;
                case 1: //minutes
                    parsedExif.GPScoordinates[value] += ((Number(pairing[0]) / Number(pairing[1])) / 60 )
                    break;
                case 2: //seconds
                    parsedExif.GPScoordinates[value] += ((Number(pairing[0]) / Number(pairing[1])) / 3600 )
                break;
                default:
                    throw new Error('GPS conversion failed, pairings were not expected.')
                }        
        })

        //ref to determine positive or negaitve
        if (key === 'GPSLatitude' && parsedExif.GPSLatitudeRef === "S") {parsedExif.GPScoordinates[value] = parsedExif.GPScoordinates[value] * -1; }
        if (key === 'GPSLongitude' && parsedExif.GPSLongitudeRef === "W") { parsedExif.GPScoordinates[value] = parsedExif.GPScoordinates[value] * -1; }
    })

    //removed unused exif values
    delete parsedExif.GPSLatitude;
    delete parsedExif.GPSLongitude;
    delete parsedExif.GPSLatitudeRef;
    delete parsedExif.GPSLongitudeRef;

    return parsedExif;
}

//takes efix values and puts them into an readable object
const parseToReadableObject = (exif) => {
    let newObj = {}
    for (const ifd in exif) {
        if (ifd === 'thumbnail') {
            const thumbnailData = exif[ifd] === null ? "null" : exif[ifd];
            newObj.thumbnail = thumbnailData
        } 
        else{
            for (const tag in exif[ifd]) {
                newObj[piexif.TAGS[ifd][tag]['name']] = exif[ifd][tag]
            }
        }
    }
    return newObj;
}

const retrieveEFIX = async (urlArray) => {
    const promises = [];

    urlArray.forEach((url) => {
        promises.push(
            new Promise(async (resolve, reject) => {
                const data = await fetch(url);
                const blob = await data.blob();
                let reader = new FileReader();
                reader.readAsDataURL(blob);
                reader.onloadend = () => { resolve({...convertDMStoDD(parseToReadableObject(piexif.load(reader.result))), url: url}) };
                reader.onerror = reject;
            })
        )
    })

    return Promise.all(promises)
};

export default retrieveEFIX