//import _, {isUndefined} from 'lodash'
import React from 'react';
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import Skeleton from '@material-ui/lab/Skeleton'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'

export function getCoordinates(object) {
    /*Gets the In/Out coordinates from the passed object
      Coordinates are currently stored in two formats:
        Object:{
            inLat,
            inLong,
            outLat,
            outLng,
        }
        or the newer format
        Object:{
            inCoordinate:{
                latitude,
                longitude,
            },
            outCoordinate:{
                latitude,
                longitude,
            }
        }*/

    let coordinates = {};

    if ("inLat" in object || "inLong" in object || "outLat" in object || "outLong" in object) {
        coordinates = {
            inCoordinate: {
                lat: "inLat" in object && object.inLat !== 0 ? object.inLat : null,
                lng: "inLong" in object && object.inLong !== 0 ? object.inLong : null,
            },
            outCoordinate: {
                lat: "outLat" in object && object.outLat !== 0 ? object.outLat : null,
                lng: "outLong" in object && object.outLong !== 0 ? object.outLong : null,
            }
        }
    }
    else {
        coordinates = {
            inCoordinate: {
                lat: "inCoordinate" in object ? object.inCoordinate?.latitude : null,
                lng: "inCoordinate" in object ? object.inCoordinate?.longitude : null,
            },
            outCoordinate: {
                lat: "outCoordinate" in object ? object.outCoordinate?.latitude : null,
                lng: "outCoordinate" in object ? object.outCoordinate?.longitude : null,
            }
        }
    }

    return coordinates;
}

export function hasInCoordinate(coordinates) {
    return (
        coordinates.inCoordinate.lat !== null &&
        coordinates.inCoordinate.lng !== null &&
        !isNaN(coordinates.inCoordinate.lat) &&
        !isNaN(coordinates.inCoordinate.lng)
    );
}

export function hasOutCoordinate(coordinates) {
    return (
        coordinates.outCoordinate.lat !== null &&
        coordinates.outCoordinate.lng !== null &&
        !isNaN(coordinates.outCoordinate.lat) &&
        !isNaN(coordinates.outCoordinate.lng)
    );
}

export function getChecklistType(checklist) {
    let fullChecklist = null;

    switch (checklist.serviceType) {
        case 'Cleaning':
            fullChecklist = (
                [
                    ['pavement' in checklist.checkedItems, 'All snow is cleaned to pavement where reasonably possible'],
                    ['curb' in checklist.checkedItems, 'All Curbs are exposed'],
                    ['driveway' in checklist.checkedItems, 'All Driveway approaches are clear'],
                    ['loadingdock' in checklist.checkedItems, 'All loading docks are clear, if applicable'],
                    ['piles' in checklist.checkedItems, 'Snow is piled in a designated area, which doesn’t obstruct a roadway, sidewalk, fire hydrant or entrance or exit from building'],
                    ['damage' in checklist.checkedItems, 'Check this box if damaged occurred during cleaning and take picture',],
                    ['inaccessible' in checklist.checkedItems, 'Check box if there was somewhere that couldn’t be cleared and take picture of reason'],
                ]
            )
            break;
        case 'Sanding':
            fullChecklist = (
                [
                    ['approaches' in checklist.checkedItems, 'Approaches sanded'],
                    ['aisles' in checklist.checkedItems, 'Main aisles and walking paths sanded'],
                    ['docks' in checklist.checkedItems, 'Loading docks sanded if applicable'],
                    ['damage' in checklist.checkedItems, 'Check box if any damaged occurred during sanding and take pictures'],
                    ['inaccessible' in checklist.checkedItems, 'Check box if there was somewhere that couldn’t be sanded and take picture of reason'],
                ]
            )
            break;
        case 'Shovelling':
            fullChecklist = (
                [
                    ['pavement' in checklist.checkedItems, 'All snow is cleaned to pavement where reasonably possible'],
                    ['leeway' in checklist.checkedItems, 'Snow is cleaned at least 1-2 feet from edge of sidewalk'],
                    ['salted' in checklist.checkedItems, 'If needed sand or other ice melting products applied on icy areas'],
                    ['emergency' in checklist.checkedItems, 'All emergency exits are clear of snow'],
                    ['inaccessible' in checklist.checkedItems, 'Check this box If any area was unable to be  cleaned, take picture of area'],
                ]
            )
            break;
        default:
            break;
    }

    return fullChecklist;
}

/**
 * @function getForm Gets the most recent version of a form
 * @param form Gets the most recent form
 */
export function getForm(form) {

    let newForm = {}

    if (form && form.version)
        newForm = form.version[Math.max(Object.keys(form.version))]

    return newForm;
}

export const generateCardInputs = (label, input, loading) => {
    return <React.Fragment>
        <Grid item sm={3}>
            {loading ? <Skeleton /> : <Typography style={{ fontSize: '0.9rem' }}>{label}</Typography>}
        </Grid>
        <Grid item sm={9}>
            {loading ? <Skeleton /> : input}
        </Grid>
    </React.Fragment>
}



export function generateCardSelect(label, value, items, changed, loading) {
    return <React.Fragment>
        <Grid item sm={6}>
            {loading ? <Skeleton /> : <Typography style={{ fontSize: '0.9rem' }}>{label}</Typography>}
        </Grid>
        <Grid style={{ display: 'flex', justifyContent: 'flex-end' }} item sm={6}>
            {loading ? <Skeleton /> :
                <Select
                    value={value}
                    onChange={changed}
                    fullWidth
                >
                    {Object.entries(items).map(([value, text], index) => {
                        return <MenuItem key={index} value={value}>{text}</MenuItem>
                    })}

                </Select>}
        </Grid>
    </React.Fragment>
}

export function generateCardData(label, value, loading) {
    return <React.Fragment>
        <Grid item sm={8}>
            <Typography style={{ fontSize: '0.9rem' }}>{label}</Typography>
        </Grid>
        <Grid style={{ display: 'flex', justifyContent: 'flex-end' }} item sm={4}>
            {loading ? <Skeleton /> : <Typography style={{ fontSize: '0.9rem' }}>{value}</Typography>}
        </Grid>
    </React.Fragment>
}