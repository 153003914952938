import React, { useEffect, useState } from 'react';
//import { firestore } from '../../../firebase/firebase'
import { withRouter } from 'react-router-dom';
//import _ from 'lodash'
import { Skeleton } from '@material-ui/lab';
import { makeStyles } from '@material-ui/core';
import { useCustomer } from '../../../providers/CustomerContext';
import { useUser } from '../../../providers/UserContext';
import { useUnitDescription } from '../../../providers/UnitDescriptionContext'

import GrainPDF from '../../components/Grain/GrainPDF';

const useStyles = makeStyles((theme) => ({
    content: {
        display: 'flex',
        width: '100%',
        padding: '32px',
        justifyContent: 'center',
        marginTop: '105px',
    },
}));

function GrainPrint(props) {
    //const [grainData, setGrainData] = useState(props.location.state.rows);
    const [loading, setLoading] = useState([]);
    //const [errorMessage, setErrorMessage] = useState("");

    const { customers, getCustomers } = useCustomer();
    const { getUsers, users } = useUser();
    const { units, getUnits } = useUnitDescription();

    const classes = useStyles();

    //let path = new URLSearchParams(props.location.search).get("docId");

    useEffect(() => {
        setLoading(true);
        //let order = {};

        if(users.length === 0){
            getUsers()
        }
        if(customers.length === 0){
            getCustomers()
        }
        if(units.length === 0){
            getUnits()
        }

        // firestore.collection('grainOrders').where('__name__', "==", path).get()
        //     .then(querySnapshot => {
        //         //process & store timesheet data
        //         const grainResponse = querySnapshot.docs.map((doc) => {
        //             return {
        //                 ...doc.data(),
        //                 'docId': doc.id,
        //             }
        //         })

        //         //filter

        //         if (_.isEmpty(grainResponse)) return;
        //         setGrainData(grainResponse[0]);
        //         setLoading(false);
        //     })
        //     .catch(e => {
        //         setErrorMessage("Unable to Process");
        //         setLoading(false)
        //     });

        setLoading(false);
        // eslint-disable-next-line
    }, [props.location.search]);

    return (
        loading
            ? 
            <div className={classes.content}>
                <Skeleton variant='rect' width={'63vw'} height={'80vh'} />
            </div>
            : 
            <GrainPDF
                users={users}
                customers={customers}
                units={units}
                grainData={props.location.state.rows}
                //errorMessage={errorMessage} 
            />
    )
}

export default withRouter(GrainPrint)