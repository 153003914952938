import React from 'react'
import { makeStyles } from '@material-ui/core';
import { Checkbox, FormControlLabel } from '@material-ui/core';

import Map from '../../UI/Map/Map';

const useStyles = makeStyles((theme) => ({
    card: {
        padding: theme.spacing(4),
        width: '100%',
        height: '100%',
    },

    mapCheckbox: {
        position: 'absolute',
        bottom: '40px',
        zIndex: '1000',
        backgroundColor: 'white',
        left: '32px',
        paddingRight: '8px',
        borderRadius: '4px',
    },

    text: {
        color: '#707070',
    },
}));

const TimeMap = (props) => {
    const classes = useStyles()
    return (
        <React.Fragment>
            <Map
                height={'400px'}
                zoom={18}
                elements={props.mapElements}
            />
            <FormControlLabel
                className={classes.mapCheckbox}
                control={<Checkbox checked={props.coordinateData} onChange={props.showCoordinateData} />}
                label='Show Coordinate Data' />
        </React.Fragment>
    )
}
export default TimeMap