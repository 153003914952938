import React, { useEffect, useState } from 'react';
import { Button, Paper, TextareaAutosize, Typography, Grid, makeStyles, IconButton, Tooltip, Modal, Tab, Box, TextField,
} from '@material-ui/core';
import TabContext from '@material-ui/lab/TabContext';
import TabPanel from '@material-ui/lab/TabPanel';
import TabList from '@material-ui/lab/TabList';
import { firestore, storage } from '../../../firebase/firebase';
import moment from 'moment'
import _ from 'lodash'
import { useAuth } from '../../../providers/AuthContext'
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import DeleteIcon from '@material-ui/icons/Delete';
import NoteAddIcon from '@material-ui/icons/NoteAdd';
import Dropzone from 'react-dropzone'
import PublishIcon from '@material-ui/icons/Publish';
import ClearIcon from '@material-ui/icons/Clear';
import useAsyncError from '../../components/UI/AsyncError/AsyncError';

const useStyles = makeStyles((theme) => ({

  card: {
    padding: theme.spacing(4),
    width: '100%',
    height: '100%',
  },
  new: {
    backgroundColor:'green',
    color:'white',
    paddingLeft:'10px',
    paddingRight:'10px',
    borderRadius:'6px',
    margin:'2px',
    fontWeight:'bold'
  },
  change: {
    backgroundColor:'blue',
    color:'white',
    paddingLeft:'10px',
    paddingRight:'10px',
    borderRadius:'6px',
    margin:'2px',
    fontWeight:'bold'
  },
  bug: {
    backgroundColor:'red',
    color:'white',
    paddingLeft:'10px',
    paddingRight:'10px',
    borderRadius:'6px',
    margin:'2px',
    fontWeight:'bold'
  },
  fix: {
    backgroundColor:'dodgerblue',
    color:'white',
    paddingLeft:'10px',
    paddingRight:'10px',
    borderRadius:'6px',
    margin:'2px',
    fontWeight:'bold'
  },
  modal: {
    width: '500px',
    padding: '16px',
    margin: '0 auto',
    marginTop: '50vh',
  },
  table: {
    display: 'flex',
    justifyContent: 'center',
    marginBottom: '2px',
  },
  paper: {
    //borderRadius: '0px',
    margin: '0px',
    width: '100%',
    maxWidth: '90vw',
    padding: '16px',
   // backgroundColor: 'rgb(252, 252, 249)',
  },
  fileInput: {
    '&:hover': {
        cursor: 'pointer',
      },
    display:'flex',
    alignItems: 'center',
    border: '1px solid #bdbdbd',
    borderRadius:'4px',
    margin:'8px',
    marginTop:'16px',
    //backgroundColor: '#f2f2f2',
  },
  fileInputAccept: {
      display:'flex',
      alignItems: 'center',
      border: '0px',
      borderRadius:'4px',
      margin:'8px',
      marginTop:'16px',
      backgroundColor: '#56a84b',
      color:'white',
  },
}));
const AppMessages = (props) => {
  const classes = useStyles();
  const { roles } = useAuth();
  const throwAsyncError = useAsyncError();
  const [messages, setMessages] = useState([]);
  const [deleteMessages, setDeleteMessages] = useState([])
  const [deletePDFs, setDeletePDFs] = useState([])
  const [embedModal, setEmbedModal] = useState({
    open: false,
    docId: '',
    tab: 'pdf',
    pdf: null,
    url: null,
    color: '0xFFFFFFFF'
  })
  const [error, setError] = useState('');
  const [saving, setSaving] = useState(false);
  const [confirmMessage, setConfirmMessage] = useState('');

  React.useEffect(() => {
  }, [roles ])

  useEffect(() => {
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  //gets all home message data
  useEffect(() => {
    firestore.collection('home').get()
    .then(querySnapshot => {
      let snapshot = querySnapshot.docs.map((doc) => {
        return {
          ...doc.data(),
          'docId': doc.id,
        }
      })

      //sorts welcome message to front of the array
      let welcomeMessage = snapshot.find(x=> x.docId === 'welcomeMessage')
      snapshot.splice(snapshot.findIndex(x=> x.docId === 'welcomeMessage'), 1)
      snapshot.unshift(welcomeMessage)
      setMessages(snapshot)
     
    })
    .catch(e => {
      setError("Unable to Process");
      console.log(e.message);
    })
  }, []);

  const handleAddMessage = () => {
    let container = _.cloneDeep(messages)
    container.push({
        color: '0xFFFFFFFF',
        pdf: {},
        url: '',
        message: '',
    })
    setMessages(container)
  }

  const handleChangeMessage = (event, index) => {
    let container = _.cloneDeep(messages)
    container[index].message = event.target.value
    setMessages(container)
  };

  const handleChangeMessageDetails = (event, index, key) => {
    let container = _.cloneDeep(messages)
    let message = container[index]
    message = {...message, [key]: '0xFF'+event.target.value.replace('#','')}
    container.splice(index, 1, message)
    setMessages(container)
  };

  const handleDeleteMessage = (docId) => {
    let container = _.cloneDeep(messages)
    let index = container.findIndex(x=> x.docId === docId)

    //if message has a pdf attatched, add to pdf remove list
    if(typeof container[index].pdf?.url === 'string'){ setDeletePDFs([...deletePDFs, container[index].pdf?.url]) }

    //update messages
    container.splice(index, 1)
    setMessages(container)

    //add to mesages remove list
    setDeleteMessages([...deleteMessages, docId])
  }

  //substring text to use ... if too long
  const substringText = (text) => {
    if(text.length > 20){
        return text.substring(0, 20) + '...'
    } else {
        return text
    }
  }

  const handleOpenModal = (message) => {
    let container = {...embedModal, open: true, docId: message.docId}
    if(!_.isUndefined(message.pdf)){container.pdf = message.pdf }
    if(!_.isUndefined(message.url)){ container.url = message.url}
    setEmbedModal(container)
  }

  const handleCloseModal = () => {
    setEmbedModal({...embedModal, open: false, pdf: null, url: null })
  }

  const handleUpdateURL = (newValue, docId) => {
    let container = _.cloneDeep(messages)
    let index = messages.findIndex(x=> x.docId === docId)

    //adds pdf to remove list if it is uploaded, and if not already present in delete pdfs
    if(typeof container[index].pdf?.url === 'string' && !deletePDFs.includes(container[index].pdf.url)){ 
      let deletePDFsContainer = _.cloneDeep(deletePDFs)
      deletePDFsContainer.push(container[index].pdf.url) 
      setDeletePDFs(deletePDFsContainer)
    }

    container[index].url = newValue
    container[index].pdf = null;
    setMessages(container)
    setEmbedModal({...embedModal, url: newValue, pdf: null})
  }

  const handleFileUpload = (acceptedFiles, docId) => {
    const file = acceptedFiles[0];
    if (file) {
      let container = _.cloneDeep(messages)
      let index = container.findIndex(x=> x.docId === docId)
      container[index].pdf = { url: file, name: file.name };
      container[index].url = null

      setMessages(container)
      setEmbedModal({...embedModal, pdf: { url: file, name: file.name }, url: null})
    }
  };

  const handleFileRemove = (docId) => {
    let container = _.cloneDeep(messages)
    let index = container.findIndex(x=> x.docId === docId)

    //add to deletePDFs if it has been uploaded
    if(typeof container[index].pdf?.url === 'string'){ setDeletePDFs([...deletePDFs, container[index].pdf.url]) }

    container[index].pdf = null;
    container.splice(index, 1, container[index])
    setMessages(container)
    setEmbedModal({...embedModal, pdf: null})
  }

  const handleUploadPDF = async (storageRef, url, storageName) => {
    const path = storageRef.child(`HomeMessage/${moment().valueOf()}_${storageName}`);
    await path.put(url);
    const returnURL = await path.getDownloadURL();

    return returnURL;
  }

  const handleMessageSubmit = async () => {
    setSaving(true);

    let batch = firestore.batch();
    const storageRef = storage.ref();
    let messagesContainer = _.cloneDeep(messages)

    for (let [index, message] of messagesContainer.entries()) {

      //check for files to upload, uploads and returns path, removes url
      if (message.pdf?.url instanceof File) { message.pdf.url = await handleUploadPDF(storageRef, message.pdf.url, message.pdf.name) }

      //sets up query data, sets docIds
      if (message.docId) {
        let query = firestore.collection('home').doc(message.docId);
        batch.set(query, message);
      }
      else {
        let query = firestore.collection('home').doc();
        batch.set(query, message);
        messagesContainer[index].docId = query.id;
      }
    }

    //deletes removed pdfs
    deletePDFs.forEach((url) =>{
      const fileRef = storage.refFromURL(url); 
      console.log(fileRef)
      fileRef.delete()
    })

    //deletes messages
    deleteMessages.forEach((docId) => {
      let query = firestore.collection('home').doc(docId)
      batch.delete(query);
    })

    batch.commit()
    .then(() => {
      setMessages(messagesContainer)
      setDeleteMessages([])
      setDeletePDFs([])
      setSaving(false);
      setConfirmMessage('Success!');

      setTimeout(() => {                 
        setConfirmMessage('');
      }, 3000);
    })
    .catch((e) => {
      throwAsyncError(new Error(e.message, e));
    });
  };

  return (
    <React.Fragment>

      {/* Link Document Modal */}
      <Modal
        open={embedModal.open}
        onClose={() => handleCloseModal()}
      >
        <Paper className={classes.modal}>
          
          <Grid item xs={12} className={classes.table}>
            <TabContext value={embedModal.tab}>
              <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                  <TabList onChange={(e, newValue) => { setEmbedModal({...embedModal, tab: newValue }) }}>
                      <Tab label={"PDF"} value="pdf" />
                      <Tab label={"URL"} value="url" />
                  </TabList>
              </Box>
              <TabPanel value="pdf"></TabPanel>
              <TabPanel value="url"></TabPanel>
            </TabContext>
          </Grid>

          <Grid item xs={12}>
            {embedModal.tab === 'pdf'
              ?
                <Grid>  
                  {/* input button */}
                  <Grid item sm={12} className={embedModal.pdf?.name ? classes.fileInputAccept : classes.fileInput}>
                    <Dropzone accept={{'application/pdf': ['.pdf', '.PDF']}}
                    onDrop={(file) => { handleFileUpload(file, embedModal.docId) }}>
                    {({ getRootProps, getInputProps }) => (
                        <Grid container item xs={12}justifyContent='center' {...getRootProps()} >
                            <input {...getInputProps()} style={{ display: 'none' }} />
    
                            <PublishIcon style={{color:'grey', marginRight:'20px'}}/>

                            <Typography variant='h6'>
                                {embedModal.pdf?.name ? substringText(embedModal.pdf?.name) : "Upload Completed Form (.pdf)"}
                            </Typography>
                        
                        </Grid>
                        
                    )}
                    
                    </Dropzone>
                      {embedModal.pdf?.name
                      ? 
                        <IconButton component='span' style={{ marginLeft: '100px', color:'black' }} onClick={() => {handleFileRemove(embedModal.docId)}}>
                          <ClearIcon />
                        </IconButton>
                      :
                        null
                      }
                </Grid>

                </Grid>  
              : //url
                <Grid>
                      <TextField 
                        value={embedModal.url}
                        onChange={(e)=> { handleUpdateURL(e.target.value, embedModal.docId)}}
                        fullWidth
                        variant='outlined'
                        size='small'
                        style={{margin: '8px', marginRight: '10px', marginTop: '10px'}}
                      />
                </Grid> 
            }
          </Grid>

          <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '12px' }}>
              <Button variant='contained' onClick={() => handleCloseModal()}>Okay</Button>
          </div>
        </Paper>
      </Modal>

      {((props.preview && props.roles) ? props.roles.includes('Update App Message') : roles.includes('Update App Message')) ?
      <Grid item xs={12}>
        <Paper style={{ display: 'flex', alignItems: 'flex-start', flexDirection: 'column', maxWidth: '100%', padding: '16px' }}>
          <Grid container style={{ display: 'initial' }}>

            <Grid container>
              <Grid item xs={6}>
                <Typography variant='h5'>
                  App Messages
                </Typography>
                </Grid>
            
                <Grid item xs={6}>
                  <Grid container justifyContent='flex-end'>
                    <IconButton
                      onClick={() => {handleAddMessage() }}
                      size='small'
                    >
                      <Tooltip title="Add Message">
                        <AddCircleOutlineIcon />
                      </Tooltip>
                    </IconButton>
                  </Grid>
                </Grid>
            </Grid>

            {messages.map((message, index) => {
              return (
                
                <Grid container key={index}>
                  
                  <Grid item xs={10} md={11}>
                      <TextareaAutosize style={{ fontWeight:'bold', maxWidth: '100%', minWidth: '200px', width: '100%', margin: '5px', backgroundColor:message.docId === 'welcomeMessage'?'white':message?.color?.toString().replace('0xFF', '#')}}
                        id="welcomeMessage"
                        variant='outlined'
                        defaultValue={message?.message}
                        key={message.docId}
                        onChange={(event)=> {!props.preview && handleChangeMessage(event, index)}} 
                      />
                  </Grid>
                  <Grid item xs={2} md={1}>
                    <Grid container justifyContent='flex-end'>

                      {message.docId !== 'welcomeMessage' &&
                        <Grid>
                          <input type="color" id="color" name="color"
                            onChange={(e) => {
                                handleChangeMessageDetails(e, index, 'color')
                            }}
                            value={message?.color.toString().replace('0xFF', '#')}
                        />

                          <IconButton
                            size='small'
                            onClick={(e)=> {
                              handleOpenModal(message)
                            }}
                          >
                            <Tooltip title="Link Document">
                              <NoteAddIcon/>
                            </Tooltip>
                          </IconButton>

                          <IconButton
                            size='small'
                            onClick={(e)=> {
                              handleDeleteMessage(message.docId)
                            }}
                          >
                            <Tooltip title="Delete Message">
                              <DeleteIcon/>
                            </Tooltip>
                          </IconButton>
                        </Grid>
                      }
                    </Grid>
                  </Grid>
              </Grid>
              )
            })}

            <Grid item xs={12}>
              <Button 
                variant='contained' 
                onClick={!props.preview && handleMessageSubmit}
                disabled={saving || confirmMessage !== ''}
                style={confirmMessage !== '' ? {marginTop: '20px', marginBottom: '20px', color:'white', backgroundColor:'green'} : {marginTop: '20px', marginBottom: '20px'}}
              >
                {saving ? "Saving..." : confirmMessage !== '' ?  confirmMessage : "Save All Changes"}
              </Button>
            </Grid>
            <Grid item xs={12}>
              <Typography style={{ color: 'red' }}>{error ? "" : error}</Typography>
              {/* <Typography>{saving ? "" : confirmMessage}</Typography> */}
            </Grid>
          </Grid>
        </Paper>
      </Grid>:null}
    </React.Fragment>


  );
}


export default AppMessages;