import React, { useState, useEffect, useCallback } from 'react';
import FormFillable from '../../components/Forms/FormFillable'
import { withRouter } from 'react-router';
import { firestore } from '../../../firebase/firebase';
import { useAuth } from '../../../providers/AuthContext'
import { getForm } from '../../utility/utility'

const Form = (props) => {

  const [formData, setFormData] = useState([]);
  const [submitting, setSubmitting] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const { currentUser } = useAuth();



  useEffect(() => {

    let path = new URLSearchParams(props.location.search).get("docId");

    firestore.collection('formTemplates').where('__name__', "==", path).get()
      .then(querySnapshot => {
        const formResponse = querySnapshot.docs.map((doc) => {
          return {
            ...doc.data(),
            'docId': doc.id,
          }
        })
        setFormData({
          ...getForm(formResponse[0]),
          'version': Math.max(Object.keys(formResponse[0].version)),
          docId: formResponse[0].docId,
        });
        setLoading(false);
      })
      .catch(e => {
        setError("Unable to Process");
        setLoading(false);
        console.log(e.message);
      })
  }, [props.location.search, currentUser]);

  const updateForm = useCallback(
    (e) => {
      setFormData(e);
    },
    []
  );

  const handleSubmit = (event) => {
    event.preventDefault();
    createForm();
  }

  const createForm = () => {

    let answers = {
      sections: formData.sections.map((section, sectionIndex) => {
        return {
          components:
            section.components.map((component, componentIndex) => {
              return component.type === 'input' ? component.value : null
            })
        }
      })
    };

    let filledForm = {
      answers: answers,
      archived: false,
      dateCreated: Date.now() / 1000,
      form: formData.docId,
      uid: currentUser.uid,
      version: formData.version,
    }

  

    setSubmitting(true);

    firestore.collection('forms').add(filledForm)
      .then(() => {
        setSubmitting(false);
        setError('');
      })
      .catch((e) => {
        setSubmitting(false);
        console.log(e)
        setError("Unable to create form, please try again");
      });
  }

  return (
    !loading && !error
      ? <FormFillable
        formData={formData}
        handleSubmit={handleSubmit}
        updateForm={updateForm}
        submitting={submitting} />
      : null
  )
}

export default withRouter(Form);