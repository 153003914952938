import React, { useState, useEffect } from 'react';
import { Grid, makeStyles, Button } from '@material-ui/core';
import { withRouter } from 'react-router';
import { firestore } from '../../../firebase/firebase';
import MaterialTable  from '@material-table/core'
import { TableIcons } from '../../../constants/tableIcons';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import AddBoxIcon from '@material-ui/icons/AddBox';

import { useAuth } from '../../../providers/AuthContext';
import moment from 'moment'

const useStyles = makeStyles((theme) => ({
    card: {
      padding: theme.spacing(4),
      width: '100%',
      height: '100%',
    },
    content: {
      display: 'flex',
      justifyContent: 'center',
      width: '100%',
    },
    grid: {
      marginTop: theme.spacing(2),
      maxWidth: '1200px',
    },
    table: {
      width: '100%',
      maxWidth: '900px',
      margin: '16px',
      display: 'flex',
    },
    tile: {
      width: '300px',
      height: '300px',
    },
    tileContent: {
      display: 'flex',
      justifyContent: 'space-evenly',
      alignItems: 'center',
      flexDirection: 'column',
      width: '100%',
      height: '100%',
      padding: theme.spacing(4),
    },
    tileIcon: {
      color: 'rgba(0, 0, 0, 0.54)',
      width: '100px',
      height: '100px',
    },
    tileText: {
      fontSize: '1.10rem',
    },
    btn: {
      marginBottom: '20px',
      marginTop: '4px',
  },
  }));

const PDFHome = (props) => {

  const [safetyData, setSafetyData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [searchBarValue, setSearchBarValue] = useState("pdfSearchBarValue" in sessionStorage ? JSON.parse(sessionStorage.getItem("pdfSearchBarValue")) : null)
  const [page, setPage] = useState("pdfPageValue" in sessionStorage ? JSON.parse(sessionStorage.getItem("pdfPageValue")) : 0)

  const { roles } = useAuth();
  const classes = useStyles();

  useEffect(() => {
    firestore.collection('pdfforms').orderBy('dateCreated').get()
      .then(querySnapshot => {
        const safetyResponse = querySnapshot.docs.map((doc) => {
          return {
            ...doc.data(),
            'docId': doc.id,
          }
        })
        setSafetyData(safetyResponse);
        setLoading(false);
      })
      .catch(e => {
        setError("Unable to Process");
        setLoading(false);
        console.log(e.message);
      })
  }, []);

  const handleOnSearchChange = (value) => {
    setSearchBarValue(value);  
    sessionStorage.setItem("pdfSearchBarValue", JSON.stringify(value));

    setPage(0);  
    sessionStorage.setItem("pdfPageValue", JSON.stringify(0));
  }

  const handleOnPageChange = (pageNum) => {
    setPage(pageNum);  
    sessionStorage.setItem("pdfPageValue", JSON.stringify(pageNum));
  }

  const tileClickHandler = (path) => {
    props.history.push({
      pathname: 'pdfforms/' + path,
    })
  };

  return (
    <div className={classes.content}>
      <Grid container className={classes.table}>
        <Grid item xs={12}>
          <Button variant='contained' 
                  onClick={() => tileClickHandler('newdocreport')} className={classes.btn} style={{marginRight: '10px'}} startIcon={<AddBoxIcon/>}>
            Create Document Report
          </Button>

          <Button variant='contained' style={roles.includes('Create|Update|Delete Forms')  ? {visibility: 'visible'} : {visibility: 'hidden'}} 
                  onClick={() => tileClickHandler('new')} className={classes.btn} startIcon={<AddBoxIcon/>}>
            Create New PDF Form
          </Button>
        </Grid>

        {!loading && !error
          ? <Grid item xs={12} style={{ width: '100%' }}>
           
            <MaterialTable
                style={{}}
                columns={[
                    { title: 'Name', field: 'name' },
                    { title: 'Uploaded', render: rowData => rowData.dateCreated < 9999999999 ? moment(moment(rowData.dateCreated) * 1000).format('YYYY-MM-DD') : moment(rowData.dateCreated).format('YYYY-MM-DD')},
                    { title: 'Last Updated', 
                      render: rowData => rowData.dateUpdated < 9999999999 
                        ? 
                          !isNaN(rowData.dateUpdated) ? moment(moment(rowData.dateUpdated) * 1000).format('YYYY-MM-DD') : ''
                        : 
                          !isNaN(rowData.dateUpdated) ? moment(rowData.dateUpdated).format('YYYY-MM-DD') : ''
                      }
                  ]}
                data={safetyData}
                title={"PDF Forms"}
                pathname='/dashboard/pdfforms/form'
                onSearchChange={ (value) => {handleOnSearchChange(value) }}
                onPageChange={ (pageNum) => {handleOnPageChange(pageNum) }}
                icons={TableIcons}
                actions={[
                    rowData => ({
                        icon: PictureAsPdfIcon,
                        tooltip: 'View PDF Form',
                        onClick: (event, rowData) => {  window.open(rowData.pdfURL, "_blank") },
                    }),
                    {
                        icon: TableIcons.OpenInNew,
                        tooltip: 'View Form Info',
                        onClick: (event, rowData) => props.history.push({
                            pathname: '/dashboard/pdfforms/form',
                            search: "?" + new URLSearchParams({ docId: rowData.docId }).toString(),
                        })
                    },
                ]}
                options={'options' in props
                    ? props.options
                    : {
                        toolbar: true,
                        pageSize: 20,
                        pageSizeOptions: false,
                        paging: true,
                        initialPage: page,
                        actionsColumnIndex: -1,
                        search: true,
                        emptyRowsWhenPaging: false,
                        thirdSortClick: false,
                        searchText: searchBarValue,
                        rowStyle: {
                            whiteSpace: 'pre-wrap',
                        },
                    }}
            />

          </Grid>
          : null
        }
      </Grid>
    </div>
  )
}

export default withRouter(PDFHome);