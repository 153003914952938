import React from 'react';
import { Button, makeStyles } from '@material-ui/core';
import { PDFViewer, Font, Page, Text, View, Document, StyleSheet, Image } from '@react-pdf/renderer';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { withRouter } from 'react-router-dom';
import moment from 'moment';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        justifyContent: 'center',
        width: '100%',
    },
    content: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        maxWidth: '1200px',
        margin: 'auto',
    },
    buttonRow: {
        marginBottom: '20px',
        marginTop: '20px',
    },
    checkRow: {
        margin: theme.spacing(1),
        display: 'flex',
        justifyContent: 'space-evenly',
    },
}));

Font.register({
    family: 'Oswald',
    src: 'https://fonts.gstatic.com/s/oswald/v13/Y_TKV6o8WovbUd3m_X9aAA.ttf'
});

// Create styles
const styles = StyleSheet.create({
    page: {
        flexDirection: 'column',
        fontFamily: 'Helvetica',
        padding: 20,
    },
    section: {
        marginBottom: 10,
    },
    dTable: {
        display: "table",
        width: "auto",
        border: '1 solid black',
        textAlign: 'left',
        marginBottom: 10,
      },
      dTableRow: {
        margin: "auto",
        flexDirection: "row",
        textAlign: 'left',
      },
      dTableCol: {
        flexGrow: 1,
        textAlign: 'left',
      },
      dTableCell: {
        margin: "auto",
        marginTop: 5,
        marginBottom: 5,
        fontSize: 10,
        textAlign: 'left',
      },
    table: {
        border: '1 solid black',
        marginBottom: 10,
        padding: 10,
        flexGrow: 1,
        fontSize: 14,
        flexDirection: 'column',
        //justifyContent: 'space-between',
    },
    subTable: {
        marginBottom: 10,
        flexGrow: 1,
        fontSize: 14,
        flexDirection: 'column',
        justifyContent: 'space-between',
    },
    tableRow: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginTop: 2,
        marginBottom: 2,
    },
    tableText: {
        fontSize: 12,
        marginBottom: '1px',
    },
    tableTextSmall: {
        fontSize: 10,
    },
    tableTextBold: {
        fontSize: 12,
        fontFamily: 'Helvetica-Bold',
        fontWeight: 'bold',
        marginBottom: '1px',
    },
    tableTextBoldUnderline: {
        fontSize: 12,
        fontFamily: 'Helvetica-Bold',
        fontWeight: 'bold',
        textDecoration: 'underline',
        marginBottom: '10px',
    },
    summary: {
        flexDirection: 'row',
    },
    viewer: {
        width: '100%',
        maxWidth: '1200px',
        height: '85vh',
    },
    tireRect: {
        width: '80px',
        height: '50px',
        margin: '0px 5px 5px 5px',
        border: '1 solid black',
        textAlign: 'center',
    },
    spacerRect: {
        width: '80px',
        height: '50px',
        margin: '0px 5px 5px 5px',
    },
    vehicleBackTableRow: {
        flexDirection: "row",
        display: 'flex',
        justifyContent: 'center',
    },
    vehicleFrontTableRow: {
        flexDirection: "row",
        display: 'flex',
        justifyContent: 'flex-start',
    },
    vehicleBackTireContainer: {
        position: 'relative',
        left: '50px',
    },
    vehicleFrontTireContainer: {
        position: 'relative',
        left: '100px',
    },
    divider: {
        borderTop: '1 solid black',
        margin: '10px 0px 5px 0px'
    },
      radio: {
        width: '10px',
        height: '10px',
        background: 'white',
        borderRadius: '50%',
        border: '1 solid black',
      },
      userEntered: {
        width: '200px',
        height: '20px',
        background: 'white',
        border: '1 solid black',
      },
      checkbox: {
        width: '10px',
        height: '10px',
        background: 'black',
        border: '1 dashed black',
        margin: "auto",
        marginTop: 5,
        marginBottom: 5,
        fontSize: 10,
      },
      checkboxChecked: {
        width: '10px',
        height: '10px',
        background: 'black',
        border: '4 solid black',
        margin: "auto",
        marginTop: 5,
        marginBottom: 5,
        fontSize: 10,
      }
});

const WorkOrderPDF = (props) => {

    const classes = useStyles();
    /**
     * @param title - Table Header
     * @param columns - Generates table column header and its internal data. Can pass either an objects key to iterate or a function.
     * @param data - The table data passed (as an array of objects)
     * @param components - React components passed as special rendering for additional rows 
    */
       /**
     * @param title - Table Header
     * @param columns - Generates table column header and its internal data. Can pass either an objects key to iterate or a function.
     * @param data - The table data passed (as an array of objects)
     * @param components - React components passed as special rendering for additional rows 
     */
    
       // eslint-disable-next-line no-unused-vars
       const Table = (props) => {

        let columnWidth = 100 / props.columns.length + '%';
        let noteWidth = (100 / props.columns.length) * (props.columns.length - 1) + "%";


        return (
            <View style={styles.dTable}>
                <Text style={styles.dTableRow}>{props.title}</Text>

                <View style={styles.dTableRow}>
                    {props.columns.map((column, index) => {
                        return (
                            <View style={{ ...styles.dTableCol, width: columnWidth }} key={index}>
                                <Text style={{ ...styles.dTableCell, fontSize: 10 }}>{column.title}</Text>
                            </View>
                        )
                    })}
                </View>

                {props.data.map((data, index) => {
                    return (
                        <View key={'tablerow' + props.title + index}>
                            <View style={styles.dTableRow} wrap={false}>
                                {props.columns.map((column, index) => {

                                    //Use render function if exists, otherwise render data.
                                    let cellData = column.render ? column.render(data) : data[column.field];
                             
                                    return (
                                        <View style={{ ...styles.dTableCol, width: column.field === 'completed' ? '0%' : columnWidth }} key={index}>
                                            <Text style={styles.dTableCell}>{cellData}</Text>
                                        </View>
                                    )
                                })}
                            </View>

                            {'notes' in data && data.notes !== ""
                                ? <View wrap={false} style={{ ...styles.dTableRow, borderTop: '1 solid black', borderBottom: index + 1 === props.data.length ? ' ' : '1 solid black' }} key={index + 'notes'}>
                                    <View style={{ ...styles.dTableCol, width: columnWidth, backgroundColor: '#ddd' }}>
                                        <Text style={{ ...styles.dTableCell, justifyContent: 'center' }}>Notes</Text>
                                    </View>
                                    <View style={{ ...styles.dTableCol, width: noteWidth, marginLeft: 2.5 }}>
                                        <Text style={{ ...styles.dTableCell, width: '100%' }}>{data.notes}</Text>
                                    </View>
                                </View>
                                : null}
                        </View>
                    )
                })}
            </View>
        )
    }

    return (
        <div className={classes.root}>
            <div className={classes.content}>

                <div className={classes.buttonRow}>
                    <Button 
                        variant='contained'
                        onClick={()=> { props.history.goBack() }}
                        startIcon={<ArrowBackIcon />}
                        >
                            Back
                        </Button>
                </div>

                <PDFViewer style={styles.viewer}>
                    <Document>
                        <Page size="A4" style={styles.page} wrap>  

                            <View style={{...styles.tableRow, margin:'4px', borderBottom:'1px solid black'}}>
                                <Text style={{...styles.tableText, maxWidth:'450px', fontSize: '18px'}}>Work Order {props.workOrder?.orderID?"#"+props.workOrder.orderID:"Information"}</Text>
                                
                            </View>

  
                            <View style={{...styles.tableRow, marginBottom:'2px'}}>
                                <Text style={styles.tableText}>Assigned Tech(s)</Text>
                                <Text style={styles.tableTextBold}>{props.workOrder.assignedUser}</Text>
                            </View>
                            {
                                    props.workOrder?.secondaryTechs && props.workOrder?.secondaryTechs?.length > 0 ? props.workOrder.secondaryTechs?.map((tech, index) => {
                                        return (
                                            <View key={index} style={{...styles.tableRow, marginBottom:'2px'}}>
                                                <Text style={styles.tableText}></Text>
                                                <Text style={styles.tableTextBold}>{tech}</Text>
                                            </View>
                                        )
                                    }) : null
                                }
                            <View style={{...styles.tableRow, marginBottom:'2px'}}>
                                <Text style={styles.tableText}>Date Created</Text>
                                <Text style={styles.tableTextBold}>{moment(props.workOrder.dateCreated).format('MMMM Do, YYYY')}</Text>
                            </View>
                            <View style={{...styles.tableRow, marginBottom:'2px'}}>
                                <Text style={styles.tableText}>Date Completed</Text>
                                <Text style={styles.tableTextBold}>{props.workOrder.dateCompleted ? moment(props.workOrder.dateCompleted).format('MMMM Do, YYYY, h:mm a') : ''}</Text>
                            </View>
                                    
                     

                            <View style={styles.summary} wrap={false}>
                                <View style={{ ...styles.table, marginRight: 5 }}>
                                    <Text style={{...styles.tableTextBold,borderBottom:'1px solid black'}}>Vehicle Information</Text>
                                    <View style={styles.tableRow}>
                                        <Text style={styles.tableText}>Unit #</Text>
                                        <Text style={styles.tableTextBold}>{props.workOrder.vehicle?.unitNumber}</Text>
                                    </View>
                                    <View style={styles.tableRow}>
                                        <Text style={styles.tableText}>Year</Text>
                                        <Text style={styles.tableTextBold}>{props.workOrder.vehicle?.year}</Text>
                                    </View>
                                    <View style={styles.tableRow}>
                                        <Text style={styles.tableText}>Make</Text>
                                        <Text style={styles.tableTextBold}>{props.workOrder.vehicle?.make}</Text>
                                    </View>
                                    <View style={styles.tableRow}>
                                        <Text style={styles.tableText}>Mileage</Text>
                                        <Text style={styles.tableTextBold}>{props.workOrder.vehicle?.mileage}</Text>
                                    </View>
                                    <View style={styles.tableRow}>
                                        <Text style={styles.tableText}>VIN #</Text>
                                        <Text style={styles.tableTextBold}>{props.workOrder.vehicle?.vin}</Text>
                                    </View>
                                    <View style={styles.tableRow}>
                                        <Text style={styles.tableText}>License Plate</Text>
                                        <Text style={styles.tableTextBold}>{props.workOrder.vehicle?.licensePlate}</Text>
                                    </View>
                                    <View style={styles.tableRow}>
                                        <Text style={styles.tableText}>Cab Card #</Text>
                                        <Text style={styles.tableTextBold}>{props.workOrder.vehicle?.cabCardNumber}</Text>
                                    </View>
                                    <View style={styles.tableRow}>
                                        <Text style={styles.tableText}>PO #</Text>
                                        <Text style={styles.tableTextBold}>{props.workOrder.vehicle?.PONumber}</Text>
                                    </View>
                                    <View style={styles.tableRow}>
                                        <Text style={styles.tableText}>Safety Due</Text>
                                        <Text style={styles.tableTextBold}>{props.workOrder.vehicle?.safetyDueDate !== null ? moment(props.workOrder.vehicle?.safetyDueDate).format('YYYY-MM-DD') : '' }</Text>
                                    </View>
                                    
                                </View>   
                                <View style={{ ...styles.table, marginLeft: 5 }}>
                                    <Text style={{...styles.tableTextBold,borderBottom:'1px solid black'}}>Customer Information</Text>
                                    <View style={styles.tableRow}>
                                        <Text style={styles.tableText}>Company</Text>
                                        <Text style={styles.tableTextBold}>{props.workOrder.customer?.companyName}</Text>
                                    </View>
                                    <View style={styles.tableRow}>
                                        <Text style={styles.tableText}>Contact Name</Text>
                                        <Text style={styles.tableTextBold}>{props.workOrder.customer?.contactName}</Text>
                                    </View>
                                    <View style={styles.tableRow}>
                                        <Text style={styles.tableText}>Contact Phone</Text>
                                        <Text style={styles.tableTextBold}>{props.workOrder.customer?.contactPhone}</Text>
                                    </View>
                                    {props.workOrder.customer?.sigURL && props.workOrder.customer.sigURL !== ''?
                                    <View style={styles.tableRow}>
                                        <Text style={styles.tableText}>Customer Signature</Text>
                                        <Image source={ {uri: props.workOrder.customer.sigURL} } style={{width: '130px', height:'100px', borderBottom:'1px solid black'}} />
                                    </View>:null}

                                    
                                </View>  
                            </View>   
                            {props.workOrder?.notes !== '' 
                                ? <View style={styles.summary} wrap={false}>
                                    <View style={{ ...styles.table }}>
                                        
                                        <View style={styles.tableRow}>
                                            <Text style={styles.tableText}>Work Order Notes</Text>
                                        </View>
                                        <View style={styles.tableRow}>
                                            <Text style={{ ...styles.tableText, fontSize: 10 }}>{props.workOrder.notes}</Text>
                                        </View>
                                    </View>
                                </View>
                                : null}   

                            {props.workOrder.repairsNeeded?.length > 0
                                ? 
                                <View style={styles.dTable}>
                                    <Text style={styles.dTableRow}>Repairs</Text>

                                    <View style={styles.dTableRow}>
                                        <View style={{ ...styles.dTableCol, width: "10%" }}>
                                            <Text style={styles.dTableCell}>Complete</Text>
                                        </View>
                                        <View style={{ ...styles.dTableCol, width: "10%" }}>
                                            <Text style={styles.dTableCell}>Hours</Text>
                                        </View>
                                        <View style={{ ...styles.dTableCol, width: "50%", marginRight:'5px'  }}>
                                            <Text style={{ ...styles.dTableCell, width: '100%' }}>Description</Text>
                                        </View>
                                        <View style={{ ...styles.dTableCol, width: "30%" }}>
                                            <Text style={{ ...styles.dTableCell, width: '100%' }}>Tech</Text>
                                        </View>
                                    </View>
                                    <View style={{ backgroundColor: 'black', height: 6 }} />
                     
                                    {props.workOrder.repairsNeeded.map((repair, index) => {
                                        return (
                                            <View key={'repair' + index}>
                                                <View style={{...styles.dTableRow, borderBottom: index + 1 === props.workOrder.repairsNeeded.length || repair.notes !=="" ? '' : '1px solid black'}} wrap={false}>
                                                    <View style={{ ...styles.dTableCol, width: "10%" }}>
                                                        <Text style={repair.completed ? styles.checkboxChecked : styles.checkbox}></Text>
                                                    </View>
                                                    <View style={{ ...styles.dTableCol, width: "10%" }}>
                                                        <Text style={styles.dTableCell}>{repair.timeSpentRepairing}</Text>
                                                    </View>
                                                    <View style={{ ...styles.dTableCol, width: "50%", marginRight:'5px' }}>
                                                        <Text style={{ ...styles.dTableCell, width: '100%' }}>{repair.description}</Text>
                                                    </View>
                                                    <View style={{ ...styles.dTableCol, width: "30%" }}>
                                                        <Text style={{ ...styles.dTableCell, width: '100%' }}>{ props.getUserDisplayName(repair?.uuid)}</Text>
                                                    </View>
                                                </View>
                                                {'notes' in repair && repair.notes !== ""
                                                    ? <View wrap={false} style={{ ...styles.dTableRow, borderBottom: index + 1 === props.workOrder.repairsNeeded.length ? ' ' : '1 solid black' }} key={index + 'notes'}>
                                                        <View style={{ ...styles.dTableCol, width: "10%", backgroundColor: '#ddd' }}>
                                                            <Text style={{ ...styles.dTableCell, justifyContent: 'center' }}>Notes</Text>
                                                        </View>
                                                        <View style={{ ...styles.dTableCol, width: "89%", marginLeft: 2.5 }}>
                                                            <Text style={{ ...styles.dTableCell, width: '100%' }}>{repair.notes}</Text>
                                                        </View>
                                                    </View>
                                                    : null}
                                            </View>
                                        )
                                    })}
                                    <View style={{justifyContent:'flex-end',  backgroundColor:'lightgrey'}}>
                                        <Text style={{...styles.tableRow, fontSize:'12px', marginLeft:'80%'}}>Total Hours: {props.workOrder.totalHours}</Text>
                                    </View>
                                </View>
                                : null}     
                                {props.workOrder.partsNeeded?.length > 0 && (
                                    <View style={styles.dTable}>
                                        <Text style={styles.dTableRow}>Parts</Text>

                                        {/* Table Header */}
                                        <View style={styles.dTableRow}>
                                            <View style={{ ...styles.dTableCol, width: "10%" }}>
                                                <Text style={styles.dTableCell}>Part #</Text>
                                            </View>
                                            <View style={{ ...styles.dTableCol, width: "10%" }}>
                                                <Text style={styles.dTableCell}>QTY</Text>
                                            </View>
                                            <View style={{ ...styles.dTableCol, width: "75%" }}>
                                                <Text style={{ ...styles.dTableCell, width: '100%' }}>Description</Text>
                                            </View>
                                        </View>
                                        
                                        {/* Divider */}
                                        <View style={{ backgroundColor: 'black', height: 6 }} />

                                        {/* Parts List */}
                                        {props.workOrder.partsNeeded.map((part, index) => (
                                            <View key={'part' + index} style={styles.dTableRow} wrap={true}>
                                                <View style={{ ...styles.dTableCol, width: "12%" }}>
                                                    <Text style={styles.dTableCell}>{part.partNumber}</Text>
                                                </View>
                                                <View style={{ ...styles.dTableCol, width: "8%" }}>
                                                    <Text style={styles.dTableCell}>{part.quantity}</Text>
                                                </View>
                                                <View style={{ ...styles.dTableCol, width: "75%" }}>
                                                    <Text style={styles.dTableCell}>{part.partDescription}</Text>
                                                </View>

                                                {/* Notes Section */}
                                                {'notes' in part && part.notes !== "" && (
                                                    <View style={styles.dTableRow} wrap={false}>
                                                        <View style={{ ...styles.dTableCol, width: "10%", backgroundColor: '#ddd' }}>
                                                            <Text style={{ ...styles.dTableCell, justifyContent: 'center' }}>Notes</Text>
                                                        </View>
                                                        <View style={{ ...styles.dTableCol, width: "89%", marginLeft: 2.5 }}>
                                                            <Text style={{ ...styles.dTableCell, width: '100%' }}>{part.notes}</Text>
                                                        </View>
                                                    </View>
                                                )}
                                            </View>
                                        ))}
                                    </View>
                                )}
                                {props.workOrder.userSignature!==""?     
                                <View style={{...styles.tableRow, position: 'absolute', bottom: 0, left: 0, right: 0, borderBottom: '1px solid black', flexDirection: 'row', alignItems: 'flex-end', justifyContent: 'space-between', padding: 10}}>
                                    <Text style={{...styles.tableText, fontSize: '14px', marginBottom: 0}}>Employee Signature</Text>
                                    <Image source={{uri: props.workOrder.userSignature}} style={{width: '200px', height: '120px', marginBottom: -32}} />
                                </View>:null}
                        </Page>
                        
                    </Document>
                </PDFViewer>
            </div>
        </div>
    )
};

export default withRouter(WorkOrderPDF);