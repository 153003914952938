import React, { useState, useEffect } from 'react';
import { Grid, makeStyles, Button, Paper, Typography, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField,
 Table, TableContainer, TableRow, TableBody, TableCell, TableHead, IconButton, MenuItem, Select, FormControl, Divider } from '@material-ui/core';
import { withRouter } from 'react-router';
import { firestore } from '../../../firebase/firebase';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import AddBoxIcon from '@material-ui/icons/AddBox';
import EditIcon from '@material-ui/icons/Edit';
import Skeleton from '@material-ui/lab/Skeleton';
import { useAuth } from '../../../providers/AuthContext';
import _ from 'lodash';
import { Autocomplete } from '@material-ui/lab';
import AddIcon from '@material-ui/icons/Add';

const useStyles = makeStyles((theme) => ({

    card: {
      padding: theme.spacing(2),
      width: '100%',
      height: '100%',
    },
  
    content: {
      display: 'flex',
      width: '100%',
      flexDirection: 'column',
      maxWidth: '1000px',
      margin: 'auto',
    },
  
    grid: {
      marginTop: theme.spacing(2),
      maxWidth: '1000px',
    },
  
    table: {
      width: '100%',
      maxWidth: '1000px',
      display: 'flex',
    },
  
    tile: {
      width: '300px',
      height: '300px',
    },
  
    tileContent: {
      display: 'flex',
      justifyContent: 'space-evenly',
      alignItems: 'center',
      flexDirection: 'column',
      width: '100%',
      height: '100%',
      padding: theme.spacing(4),
    },
  
    tileIcon: {
      color: 'rgba(0, 0, 0, 0.54)',
      width: '100px',
      height: '100px',
    },
  
    tileText: {
      fontSize: '1.10rem',
    },
    btn: {
      marginBottom: '20px',
      marginTop: '20px',
  },
  }));

const CategoryHome = (props) => {

  const classes = useStyles();
  const { roles } = useAuth();

  const [categoryData, setCategoryData] = useState([]);
  const [productsData, setProductsData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [saving, setSaving] = useState(false);
  const [error, setError] = useState('');

  const [autocompleteOpen, setAutocompleteOpen] = useState(false)
  const [autocompleteValue, setAutocompleteValue] = useState({})
  const [editCategories, setEditCategories] = useState([])
  const [newProductsCategories, setNewProductsCategories] = useState([])

  const [categoryName, setCategoryName] = useState('');
  const [oldName, setOldName] = useState('');
  const [newModal, setNewModal] = useState(false);
  const [delModal, setDelModal] = useState(false);
  const [catIndex, setCatIndex] = useState(0);
  const [edit, setEdit] = useState(false);

  const safeArray = [
  ]

  useEffect(() => {
  }, [categoryData, productsData]);
  
    useEffect(() => {
      setLoading(true)
      firestore.collection('productCategories').get()
        .then(querySnapshot => {
          const categoriesResponse = querySnapshot.docs.map((doc) => {
            return {
              ...doc.data(),
              'docId': doc.id,
            }
          })
          setCategoryData(Array.from(categoriesResponse[0].categories));
        })
        .catch(e => {
          setError("Unable to Process");
          setLoading(false);
          console.log(e.message);
        })
  
        firestore.collection('products').get()
        .then(querySnapshot => {
          const productResponse = querySnapshot.docs.map((doc) => {
            return {
              ...doc.data(),
              'docId': doc.id,
            }
          })
          setProductsData(productResponse);
          setLoading(false);
        })
        .catch(e => {
          setError("Unable to Process");
          setLoading(false);
          console.log(e.message);
        })
    }, [saving]);

  const closeNewModal = () => {
      setNewModal(false)
      setEdit(false)
      setNewProductsCategories([])
      setEditCategories([])
      setAutocompleteValue({})
  }
  const openNewModal = (type, index, cat) => {
      setNewModal(true)
      if(type === 'create'){
        setEdit(false); 
        setCatIndex(0); 
        setCategoryName('')
      }
      else{
        setEdit(true); 
        setCatIndex(index); 
        setCategoryName(cat); 
        setOldName(cat);
        setNewProductsCategories(productsData.filter(product=> product.category === cat))
      }
  }
  const openDelModal = (index) => {
      setCatIndex(index)
      setDelModal(true)
  }
  const closeDelModal = () => {
      setCatIndex(0)
      setDelModal(false)
  }

  const handleChangeName = (event) => {
    var newArray = categoryData
    
    setCategoryName(event.target.value);
    if(newArray.includes(event.target.value)){
      setError(event.target.value + " is already used!")
    } else {
      setError('')
    }
  }

  const handleDelete = (index) => {
      setSaving(true)
      if (catIndex > -1) {
          firestore.collection('products')
              .where('category', '==', categoryData[catIndex]).get().then(snapshot => {
                  const promises = [];
                  snapshot.forEach(doc => {
                      promises.push(doc.ref.update({
                          category: 'Landscape & Hardscape',
                      }));
                  });
                  return Promise.all(promises)
              })
              .catch(error => {
                  console.log(error);
                  return null;
              });
          categoryData.splice(catIndex, 1);
          firestore.collection('productCategories').doc('categories')
          .update({
              'categories': categoryData,
          })
          .then(() => {
              //setSaveState(userData);
              setSaving(false)
          })
          .catch((e) => {setError(e); console.log(error);});
      }
      closeDelModal()
      setSaving(true)
      setTimeout(() => {setSaving(false)}, 5 * 1000);
      //setSaving(false)
    
  }

  //save function
  const handleNew = (name) => {
    setSaving(true)

    const batch = firestore.batch();
    let productContainer = _.cloneDeep(productsData)
    let newArray = _.cloneDeep(categoryData)

    if(edit){
      let catArray = name !== oldName ? productsData.filter(product=> product.category === oldName) : [];   //will grab old products if there is a new name
      let oldFromCatArray = [];

      var index = newArray.indexOf(oldName);
      if (index !== -1) { newArray[index] = name;}

      newProductsCategories.forEach((product) =>{
        if(product.category === oldName && !productsData.filter(product=> product.category === oldName).find(p=> p.docId === product.docId)){
          catArray.push(product)
        }
        else if(product.category !== oldName){
          oldFromCatArray.push(product)
        }
      })

      catArray.forEach((product) => {
        productContainer.splice(productContainer.findIndex(p => p.docId === product.docId), 1, {...product, category: name})
        const docRef = firestore.collection('products').doc(product.docId);
        batch.update(docRef, { category: name });
      })

      oldFromCatArray.forEach((product) => {
        productContainer.splice(productContainer.findIndex(p => p.docId === product.docId), 1, product)
        const docRef = firestore.collection('products').doc(product.docId);
        batch.update(docRef, { category: product.category });
      })
    }

    else{
      newArray.push(name)
      
      newProductsCategories.forEach((product) =>{
        productContainer.splice(productContainer.findIndex(p => p.docId === product.docId), 1, {...product, category: name})
        const docRef = firestore.collection('products').doc(product.docId);
        batch.update(docRef, { category: name });
      })
    }

      firestore.collection('productCategories').doc('categories')
      .update({
          'categories': newArray,
      })
      .then(() => {
        batch.commit()
        .then(() => {
            setCategoryData([...newArray])
            setProductsData([...productContainer])
        })
        .catch((e) => {
          console.log(e);
        });
        setSaving(false)
      })
      .catch((e) => {setError(e); console.log(error);});

      closeNewModal()
      setCategoryName('')
      setTimeout(() => {setSaving(false)}, 5 * 1000);


    //OLD
      // var newArray = categoryData

      // //check if editing or not
      // switch(edit){
      //     case true: //if editing
      //         //replace old name with new name
      //         var index = newArray.indexOf(oldName);

      //         if (index !== -1) { newArray[index] = name;}

      //         firestore.collection('products').where('category', '==', oldName).get()
      //         .then(snapshot => {
      //                 const promises = [];
      //                 snapshot.forEach(doc => {
      //                   promises.push(doc.ref.update({
      //                       category: name,
      //                   }));
      //                 });
                      
      //                 //compare promises with new category data update any changes to reflect here
      //                   //if checked where wasnt before add to promises : category: name,
      //                   //else push to promises : category: '',

      //                 return Promise.all(promises)
      //             })
      //             .catch(error => {
      //                 console.log(error);
      //                 return null;
      //             });
      //         break;

      //     case false: //if not editing
      //         //push new name
      //         newArray.push(name)
      //         break;
      //     default: 
      //         break;
      // }

      // setCategoryData(newArray)
      // firestore.collection('productCategories').doc('categories')
      //     .update({
      //         'categories': categoryData,
      //     })
      //     .then(() => {
      //         //setSaveState(userData);
      //         setSaving(false)
      //     })
      //     .catch((e) => {setError(e); console.log(error);});

      // closeNewModal()
      // setCategoryName('')
      // setSaving(true)
      // setTimeout(() => {setSaving(false)}, 5 * 1000);

  }

  return (
    <div className={classes.content}>
      <Grid>
        <Grid className={classes.btn} >
            <Button variant="contained" disabled={!roles.includes('Create|Update|Delete Products')}
            startIcon={<AddBoxIcon/>} onClick={() => {openNewModal('create', 0, ''); }}>Create Category</Button>
        </Grid> 

          <React.Fragment>
            
            <Dialog fullWidth
              keepMounted
              open={newModal}
              onClose={closeNewModal}
            >
              <DialogTitle id="alert-dialog-title">{edit ? 'Edit Category' : 'Create New Category'}</DialogTitle>
              
              <DialogContent>
                  {/* {productsData.filter(product => product.category === oldName).length > 0  ? <DialogContentText style={{color:'red'}}>Editing the {oldName} category will update {productsData.filter(product => product.category === oldName).length} products in this category. </DialogContentText> : null} */}
                  {error !== ''  ? <DialogContentText style={{color:'red'}}>{error}</DialogContentText> : null}
                  
                  <Grid container>
                      <Grid item xs={12}>
                        <TextField fullWidth name="newCatName" value={categoryName} placeholder="Category" onChange={(event) => handleChangeName(event)}></TextField>
                      </Grid>

                      <Grid container style={{marginTop: '25px', marginBottom: '25px'}}>
                        <Grid item xs={12}>
                          <Typography style={{ marginBottom: '10px'}}>Add product to this category:</Typography>
                        </Grid>
                      
                        <Grid item xs={11}>
                          <Autocomplete
                              open={autocompleteOpen}
                              onOpen={() => {
                                  setAutocompleteOpen(true);
                              }}
                              onClose={() => {
                                setAutocompleteOpen(false);
                              }}
                              loading={loading}
                              label="Search"
                              id="autocomplete"
                              autoComplete
                              autoHighlight
                              fullWidth
                              value={autocompleteValue}
                              onChange={(event, newValue) => { setAutocompleteValue(newValue) }}
                              options={productsData}
                              getOptionSelected={(option, value) => option.name === value.name || {}} 
                              getOptionLabel={(option) => option.name ? option.name : '' }
                              renderOption={(option) =>( 
                                <Grid >
                                    <Typography>{option.name}</Typography>
                                </Grid>
                            )}
                              renderInput={(params) => (
                              <TextField
                                  {...params}
                                  label="Product"
                                  variant="outlined"
                                  InputProps={{
                                      ...params.InputProps,
                                      endAdornment: (
                                          <React.Fragment>
                                              {params.InputProps.endAdornment}
                                          </React.Fragment>
                                      ),
                                  }}
                              />
                              )}

                          />
                        </Grid>
                     
                        <Grid item xs={1} justifyContent='flex-end'>
                          <IconButton 
                            onClick={() => { 
                              let container = _.cloneDeep(autocompleteValue)
                              container.category = oldName
                              setNewProductsCategories([...newProductsCategories, container])
                              setAutocompleteValue({})
                            }}
                          >
                              <AddIcon/>
                          </IconButton>
                        </Grid>
                    </Grid>

                    <Divider></Divider>

                    <Grid container >
                        {newProductsCategories.sort((a, b) => { return a.name > b.name ? 1 : -1 }).map((product, index) => (
                            !_.isUndefined(product) && 
                                <React.Fragment key={index}>
    
                                  <Grid container style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                                      <Grid item xs={6}>
                                        <Typography noWrap style={{lineHeight: 4}}>{product.name}:</Typography>
                                      </Grid>

                                      <Grid item xs={5}>
                                        { editCategories.includes(product.docId) ?
                                          <FormControl fullWidth>
                                              <Select
                                                value={product.category}
                                                variant='outlined'
                                                labelId="label"
                                                onChange={(event)=>{ 
                                                  let container = _.cloneDeep(newProductsCategories)
                                                  container[index].category = event.target.value 
                                                  setNewProductsCategories([...container])
                                                }}
                                              >
                                                {categoryData.map((cateogry, index) => {
                                                    return (
                                                        <MenuItem key={index} value={cateogry}>{cateogry}</MenuItem>
                                                    )
                                                })}
                                              </Select>
                                          </FormControl>
                                      
                                        : null
                                        }
                                      </Grid>

                                      <Grid item xs={1}> 
                                              <IconButton
                                                onClick={() => { 
                                                  let container = editCategories
                                                  editCategories.includes(product.docId) ? container.splice(editCategories.findIndex(cat=> cat === product.docId), 1) : container.push(product.docId)
                                                  setEditCategories([...container])
                                                 }}
                                              >
                                                <EditIcon style={ editCategories.includes(product.docId) ? {color: "black"} : {color: "darkGrey"}}/>
                                              </IconButton>
                                      </Grid>

                                    </Grid>
                                </React.Fragment>
                        ))}
                    </Grid>
                  </Grid>
              </DialogContent>

              <DialogActions>
                  <Button variant="contained" disabled={error !== ''} onClick={() => handleNew(categoryName)}>Save</Button>
                  <Button variant="contained" color="secondary" onClick={() => closeNewModal()}>Cancel</Button>
              </DialogActions>
          </Dialog>

          <Dialog fullWidth
              keepMounted
              open={delModal}
              onClose={closeDelModal}
          >
              <DialogTitle id="alert-dialog-title">Delete Category</DialogTitle>

              <DialogContent>
                  <Typography>Are you sure you want to delete this category?</Typography>
                  {edit ? productsData.filter(product => product.category === oldName).length > 0 ? <DialogContentText style={{color:'red'}}>Deleting {oldName} will throw {productsData.filter(product => product.category === oldName).length} products into the Landscape & Hardscape category! </DialogContentText> : null : null}
              </DialogContent>
              <DialogActions>
                  <Button variant="contained" onClick={() => handleDelete()}>Delete</Button>
                  <Button variant="contained" color="secondary" onClick={() => closeDelModal()}>Cancel</Button>
              </DialogActions>
          </Dialog>
          </React.Fragment> 


        <Grid container className={classes.table}>
            {saving || loading
            ? 
              <Skeleton variant='rect' width={'100vw'} height={'80vh'} />
            : 
            <Grid item style={{ width: '100%' }}>
              <Paper className={classes.card}>
                  <Grid>
                      <Grid item xs={9}>
                          <Typography variant="h6">Category Manager</Typography>
                      </Grid> 

                  </Grid> 
              
                  <TableContainer component={Paper} >
                      <Table sx={{ minWidth: 650 }} aria-label="simple table">
                          <TableHead>
                          <TableRow>
                              <TableCell>Category Title</TableCell>
                              <TableCell align="right">Products</TableCell>
                              <TableCell align="right">Edit Category</TableCell>
                              <TableCell align="right">Delete Category</TableCell>
                          </TableRow>
                          </TableHead>
                          <TableBody>
                          {categoryData.map((cat, index) => (
                              <TableRow
                              key={index}
                              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                              >
                              <TableCell component="th" scope="row">
                                  {cat}
                              </TableCell>
                              <TableCell align="right"> {productsData.filter(product => product.category === cat).length} </TableCell>

                              <TableCell align="right">
                                <Button 
                                  variant="contained"
                                  onClick={() => {openNewModal('edit', index, cat);   }}
                                  disabled={safeArray.includes(cat) || !roles.includes('Create|Update|Delete Products')}
                                >
                                    <EditIcon/>
                                </Button>
                              </TableCell>

                              <TableCell align="right">
                                <Button 
                                  variant="contained" 
                                  style={!safeArray.includes(cat) && roles.includes('Create|Update|Delete Products') ? {width:'2px', backgroundColor:'red', color:'white'} : {width:'2px', backgroundColor:'lightGrey', color:'grey'}}
                                  onClick={() => openDelModal(index)}
                                  disabled={safeArray.includes(cat) || !roles.includes('Create|Update|Delete Products')}
                                >
                                    <DeleteForeverIcon/>
                                </Button>
                              </TableCell>
                          
                              </TableRow>
                          ))}
                          </TableBody>
                      </Table>
                  </TableContainer>
                  <Typography style={{margin:'5px'}}><small>Products without any defined Category: {productsData.filter(product => product.category === '').length}</small></Typography>
              </Paper>
            </Grid>
            }
          </Grid>
      </Grid>
    </div>
  )
}

export default withRouter(CategoryHome);