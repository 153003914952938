import React, { useState, memo } from 'react';
import { makeStyles } from '@material-ui/core';
import { Grid, TextField,} from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';


const useStyles = makeStyles((theme) => ({
    container: {
        marginTop: '10px',
        marginBottom: '10px',
    },
    selectRow: {
        padding: '5px',
    },
}));

const GrainOrderLocationAutocompletes = ({ handleSetParentValues, docId, places,  origin, destination, }) => {

    const classes = useStyles();

    const [autocompletes, setAutocompletes] = useState({
        origin: {open: false},
        destination: {open: false},
    })

    return (
        <>
            <Grid container className={classes.container}>
                <Grid item xs={6}>

                    <Autocomplete
                        open={ autocompletes.origin.open }
                        onOpen={() => { setAutocompletes((previousValues) => { return {...previousValues, origin: {...previousValues.origin, open: true}} }) }}
                        onClose={() => { setAutocompletes((previousValues) => { return {...previousValues, origin: {...previousValues.origin, open: false}} }) }}
                        label="Search"
                        id="originAutocomplete"
                        key="originAutocomplete"
                        className={classes.selectRow}
                        autoComplete
                        autoHighlight
                        disableClearable
                        fullWidth
                        size='small'
                        freeSolo={true}
                        inputValue={origin || ''}
                        onInputChange={(event, newValue, reason) => { 
                            if(reason === 'input') { 
                                handleSetParentValues({origin: newValue}, docId)
                                //handleSetCurrentGrainOrder({...currentGrainOrder, origin: newValue}) 
                            }
                        }}
                        value={origin || ''}
                        onChange={(event, newValue) => { 
                            handleSetParentValues({origin: newValue}, docId)
                            //handleSetCurrentGrainOrder({...currentGrainOrder, origin: newValue}) 
                        }}
                        options={places.map((place) => place ?? '').sort((a, b) => a.toLowerCase() > b.toLowerCase())}
                        getOptionSelected={(option, value) => option === value}
                        getOptionLabel={(option) => option}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label="Origin (From)"
                                variant="outlined"
                                InputProps={{
                                    ...params.InputProps,
                                    endAdornment: (
                                        <React.Fragment>
                                            {params.InputProps.endAdornment}
                                        </React.Fragment>
                                    ),
                                }}
                            />
                        )}
                    />
                </Grid>

                <Grid item xs={6}>

                    <Autocomplete
                        open={autocompletes.destination.open}
                        onOpen={() => { setAutocompletes((previousValues) => { return {...previousValues, destination: {...previousValues.destination, open: true}} }) }}
                        onClose={() => { setAutocompletes((previousValues) => { return {...previousValues, destination: {...previousValues.destination, open: false}} }) }}
                        label="Search"
                        id="destinationAutocomplete"
                        className={classes.selectRow}
                        autoComplete
                        autoHighlight
                        disableClearable
                        fullWidth
                        size='small'
                        freeSolo={true}
                        inputValue={destination || ''}
                        onInputChange={(event, newValue, reason) => { 
                            if(reason === 'input') {
                                handleSetParentValues({destination: newValue}, docId)
                                //handleSetCurrentGrainOrder({...currentGrainOrder, destination: newValue})
                            }
                        }}
                        value={destination || ''}
                        onChange={(event, newValue) => { 
                            handleSetParentValues({destination: newValue}, docId)
                            //handleSetCurrentGrainOrder({...currentGrainOrder, destination: newValue}) 
                        }}
                        options={places.map((place) => place ?? '').sort((a, b) => a.toLowerCase() > b.toLowerCase())}
                        getOptionSelected={(option, value) => option === value}
                        getOptionLabel={(option) => option}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label="Destination (To)"
                                variant="outlined"
                                InputProps={{
                                    ...params.InputProps,
                                    endAdornment: (
                                        <React.Fragment>
                                            {params.InputProps.endAdornment}
                                        </React.Fragment>
                                    ),
                                }}
                            />
                        )}
                    />
                </Grid>
            </Grid>
        </>

    )
}

export default memo(GrainOrderLocationAutocompletes)