import React, { useState, useEffect, useRef } from 'react';
import { withRouter } from 'react-router'
import { Typography, Grid, Divider, Paper, IconButton, TextField,
    InputAdornment, CircularProgress, Badge, Modal, Button, } from '@material-ui/core';
import moment from 'moment';

import DoneIcon from '@material-ui/icons/Done';
import PersonIcon from '@material-ui/icons/Person';
import BlockIcon from '@material-ui/icons/Block';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import DeleteIcon from '@material-ui/icons/Delete';
import _ from 'lodash';
import ReplyBar from './ReplyBar';

import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

const ConversationsManager = (props) => {
    const gridRef = useRef(null);
    const [sending, setSending] = useState(false)
    const [currentSelection, setCurrentSelection] = useState({})
    const [pageNumber , setPageNumber] = useState(1)
    // eslint-disable-next-line no-unused-vars
    const [pageSize, setPageSize] = useState(25)
    // const [anchorEl, setAnchorEl] = useState(null);

    // const handleClick = (event) => {
    //   setAnchorEl(event.currentTarget);
    // };
  
    // const handleClose = () => {
    //   setAnchorEl(null);
    // };

    if(props.conversations){
        props.conversations.sort((a, b) => {
            return moment(a.timestamp) - moment(b.timestamp)
        })
    }

    useEffect(() => {
        if (gridRef.current) {
            gridRef.current.scrollTop = gridRef.current.scrollHeight;
        }
    }, [props.conversations])

    //format phone number to look like (xxx) xxx-xxxx
    const formatPhone = (phone) => {
        let formatted = phone
        if(phone && phone.length === 10){
            formatted = `(${phone.slice(0,3)}) ${phone.slice(3,6)}-${phone.slice(6,10)}`
        } else {
            formatted = 'Invalid Phone Number'
        }
        return formatted
    }

    //substring message to have ... if too long
    const formatMessage = (message) => {
        let formatted = message
        let num = 50
        if(message && message.length > num){
            formatted = `${message.slice(0,num)}...`
        } else if (!message || message?.length < 0) {
            formatted = 'Invalid Message'
        }
        return formatted
    }

    //get number of unread messages
    const getUnread = (contact) => {  
        return props.allUnreadMessages.filter(convo => convo.contactPhone === contact && convo.directionType === 'MO' && !convo.read).length
    }

    const paginateArray = (array, pageNumber, pageSize) => {
        const startIndex = (pageNumber - 1) * pageSize;
        const endIndex = startIndex + pageSize;
        return array.slice(startIndex, endIndex);
    };

    //order contacts by most recent message
    const orderContacts = () => {
        let ordered = props.contacts
        //take any contact that doesnt have a contactPhone and set their contactPhone to whatever their phone is
        ordered.filter(contact => _.isUndefined(contact.contactPhone)).forEach(contact => {
            contact.contactPhone = contact.phone
        })
        if(props.lastMessages.length > 0)
        {ordered
            .sort((a, b) => {
            let aLast = props.lastMessages.filter(message => message?.contactPhone === a?.contactPhone)[0]
            let bLast = props.lastMessages.filter(message => message?.contactPhone === b?.contactPhone)[0]
            return moment(bLast?.timestamp) - moment(aLast?.timestamp)
        })}

        //put contacts with no messages at the bottom
        ordered = ordered.sort((a, b) => {
            let aLast = props.lastMessages.filter(message => message?.contactPhone === a?.contactPhone)[0]
            let bLast = props.lastMessages.filter(message => message?.contactPhone === b?.contactPhone)[0]
            if(!aLast && bLast){
                return 1
            } else if(aLast && !bLast){
                return -1
            } else {
                return 0
            }
        })
        return ordered
    }

    const totalPages = Math.ceil(orderContacts().length / pageSize);

    const paginatedData = paginateArray(orderContacts(), pageNumber, pageSize);

    const handleNextPage = () => {
        if (pageNumber < totalPages) {
            setPageNumber(pageNumber + 1);
        }
      };
    
      const handlePreviousPage = () => {
        if (pageNumber > 1) {
          setPageNumber(pageNumber - 1);
        }
      };

    useEffect(() => {
    }, [pageNumber, pageSize])

    //handle click on contact
    const handleContactClick = (contact) => {
        props.setSelectedContact(contact)
        sessionStorage.setItem('selectedSMSContact', JSON.stringify(contact))
        props.fetchConvosFromPhone(contact); 
        //for all unread messages, set read to true
        props.allUnreadMessages.forEach(convo => {
            if(convo.contactPhone === contact && convo.directionType === 'MO' && !convo.read){
                convo.read = true
                props.markRead(convo)
            }
        })
        if (gridRef.current) {
            gridRef.current.scrollTop = gridRef.current.scrollHeight;
        }
    }

    return (
        <Grid container spacing={2}>
            {/* add new contact modal */}
            <Modal
                open={props.newContactModal}
                onClose={()=>props.toggleNewContactModal()}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
                style={{display:'flex', alignItems:'center', justifyContent:'center'}}
            >

                <Paper style={{width:'100%', maxWidth:'500px', padding:'10px', margin:'20px',}}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Typography variant="h6">Add Contact</Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                label="First Name"
                                variant="outlined"
                                fullWidth
                                required
                                value={props.newName}
                                onChange={(e)=>props.setNewName(e.target.value)}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                label="Last Name"
                                variant="outlined"
                                fullWidth
                                required
                                value={props.newLastName}
                                onChange={(e)=>props.setNewLastName(e.target.value)}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                label="Phone Number"
                                variant="outlined"
                                fullWidth
                                required
                                value={props.newNumber}
                                onChange={(e)=>props.setNewNumber(e.target.value)}
                                InputProps={{
                                    startAdornment: <InputAdornment position="start">+1</InputAdornment>,
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} style={{display:'flex', justifyContent:'flex-end'}}>
                            <Button 
                                variant='contained' 
                                color='secondary' 
                                onClick={()=>props.toggleNewContactModal()} 
                                style={{margin:'10px'}}
                            >
                                Cancel
                            </Button>
                            <Button 
                                variant='contained' 
                                disabled={props.newName === '' || props.newLastName === '' || props.newNumber === '' || props.processing} 
                                onClick={()=> { props.handleAddContact() }} 
                                style={{margin:'10px'}}
                            >
                                {props.processing ? 'Processing...' : 'Add'}
                            </Button>
                        </Grid>
                    </Grid>
                </Paper>
            </Modal>

            {/* confirmation modal */}
            <Modal
                open={props.confirmationModal}
                onClose={() => { props.setConfirmationModal(false)}}
                style={{display:'flex', alignItems:'center', justifyContent:'center'}}
            >
                <Paper style={{padding:'10px', margin:'20px',}}>
                    <div style={{ display: 'flex', justifyContent: 'center', marginBottom: '16px' }}>
                        <Typography>{props.confirmationModalType === 'message' ? 'Are you sure you want to delete this message?' : 'Are you sure you want to delete this conversation?' }</Typography>
                    </div>

                    <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '12px' }}>
                        <Button variant='contained' color="secondary" onClick={() => { props.setConfirmationModal(false)}}>Cancel</Button>
                        <Button 
                            variant='contained' 
                            style={{ marginLeft: '8px' }} 
                            disabled={props.processing}
                            onClick={() => { 
                                // eslint-disable-next-line no-lone-blocks
                                {props.confirmationModalType === 'message' ? props.hideMessage(currentSelection) : props.handleRemoveContact(currentSelection)}
                            }}
                        >
                            {props.processing ? 'Processing...' : 'Confirm'}
                        </Button>
                    </div>
                </Paper>
            </Modal>

            <Paper style={{width:'100%', maxWidth:'1600px', padding:'10px', margin:'20px',}}>
                <Grid container>
                    <Grid item xs={9}>
                        <Typography variant="h6">Message Center{props.selectedContact === '' ? '' : ' | '+formatPhone(props.selectedContact)}</Typography>
                    </Grid>
                    <Grid container item xs={3}  justifyContent='flex-end'>
                        <Button variant='contained' onClick={()=>props.toggleNewContactModal()} startIcon={<PersonAddIcon/>}>Add Contact</Button>
                    </Grid>
                </Grid>
                
                <Divider style={{margin:'10px'}}/>

                <Grid container>
                    <Grid item  ref={gridRef} xs={9} style={{backgroundColor:'#333',overflowY: 'scroll', minHeight:'720px', maxHeight:'720px'}}>
                        <div style={{ margin:'10px', borderRadius:'10px', padding:'10px'}}>
                            {props.loading ? 
                                <Grid item xs={12} style={{ display: 'flex', alignItems: 'center', flexDirection: 'column',}}>
                                    <CircularProgress size={300}/><br/><Typography variant='h6' color='primary'>Loading...</Typography>
                                </Grid> 
                            : null}

                            {!props.loading && props.conversations && props.conversations.length > 0 && props.conversations.filter(convo => convo.hidden === true).length !== props.conversations.length ? 
                                props.conversations.map((convo, index) => {
                                    return (
                                        !convo.hidden ? 
                                            <Grid item xs={12} key={index}>
                                                <div  style={convo.directionType === 'MO' ? 
                                                    {backgroundColor:'#3db8ff', margin:'1% 50% 1% 1%', borderRadius:'10px', padding:'10px', wordWrap: 'break-word'}
                                                    //DELIVERED
                                                    :(!convo.sending && convo.delivered) ? {backgroundColor:'#FFBF00', margin:'1% 1% 1% 50%', borderRadius:'10px', padding:'10px', wordWrap: 'break-word'}
                                                        //FAILED
                                                        : (!convo.sending && !convo.delivered) ? {backgroundColor:'black', color:'red', margin:'1% 1% 1% 50%', borderRadius:'10px', padding:'10px', opacity:'50%', wordWrap: 'break-word'} 
                                                            //SENDING
                                                            : {backgroundColor:'#FFBF00', margin:'1% 1% 1% 50%', borderRadius:'10px', padding:'10px', opacity:'50%', wordWrap: 'break-word'}
                                                }>

                                                    <Grid container>
                                                        <Grid item xs={6}>
                                                            <Typography style={{fontSize:'16px', color: (!convo.sending && !convo.delivered && convo.directionType === 'MT') ? 'red':'black'}}><b>{convo.directionType === 'MO' ? convo.contactPhone : 'Gee Tee'}:</b></Typography>
                                                        </Grid>
                                                        <Grid container xs={6} justifyContent='flex-end'>
                                                            <IconButton 
                                                                size='small' 
                                                                onClick={(e)=> { 
                                                                    setCurrentSelection(convo.id); 
                                                                    props.setConfirmationModalType('message')
                                                                    props.setConfirmationModal(true); 
                                                                }}
                                                            >
                                                                <DeleteIcon style={{color: (!convo.sending && !convo.delivered && convo.directionType === 'MT') ? 'red':'black'}}/>
                                                            </IconButton>
                                                        </Grid>
                                                    </Grid>

                                                    <Typography style={{color: (!convo.sending && !convo.delivered && convo.directionType === 'MT') ? 'red':'black'}}>{convo.text}</Typography>
                                                    <Divider style={{width:'10%'}}/>
                                                    {(convo.directionType === 'MO') ?<Typography variant="caption" style={{color: (!convo.sending && !convo.delivered && convo.directionType === 'MT') ? 'red':'black'}}><i>Recieved {moment(convo.timestamp).format('MMM Do YYYY @ hh:mm a')}</i></Typography>:null}
                                                    {(convo.sending && !convo.delivered && convo.directionType === 'MT') ?<Typography variant="caption" style={{color: (!convo.sending && !convo.delivered && convo.directionType === 'MT') ? 'red':'black'}}><Grid container><Grid item xs={11}><i>Sending...</i></Grid> <Grid container item xs={1} justifyContent="flex-end"><CircularProgress size={10} color='secondary'/></Grid></Grid></Typography>:null}
                                                    {(!convo.sending && !convo.delivered&& convo.directionType === 'MT') ?<Typography variant="caption" style={{color: (!convo.sending && !convo.delivered && convo.directionType === 'MT') ? 'red':'black'}}><i>Failed to send {moment(convo.timestamp).format('MMM Do YYYY @ hh:mm a')}</i></Typography>:null}
                                                    {(!convo.sending && convo.delivered && convo.directionType === 'MT') ?<Typography variant="caption" style={{color: (!convo.sending && !convo.delivered && convo.directionType === 'MT') ? 'red':'black'}}><Grid container><Grid item xs={10}><i>Delivered {moment(convo.timestamp).format('MMM Do YYYY @ hh:mm a')}</i></Grid> <Grid container item xs={2} justifyContent='flex-end'><DoneIcon style={{fontSize:'14px'}}/></Grid></Grid></Typography>:null}
                                                </div>
                                            </Grid>
                                        : null
                                    )

                                })
                            : !props.loading && 
                                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', borderRadius:'10px', padding:'10px', color:'grey'}}>
                                    <Divider style={{width:'50%'}}/>
                                    <Typography >No messages found...</Typography>
                                    <Typography >Select a contact or send a text to get started.</Typography>
                                    <Divider style={{width:'50%'}}/>
                                </div>
                            }
                        </div>
                    </Grid>

                    <Grid item xs={3} style={{overflowY: 'scroll', maxHeight:'720px'}}>
                        {!props.loading && props.contacts && paginatedData.map((contact, index) => {
                            return (
                                <div 
                                    key={index} 
                                    style={props.selectedContact === contact.contactPhone ? {border:'4px solid', backgroundColor: !contact?.unsubscribed?('') : ('pink'),margin:'5px', padding:'4px', borderRadius:'10px'} : {margin:'5px', padding:'4px'}} 
                                    onClick={!props.loading ? () =>  { handleContactClick(contact.contactPhone) } : null}
                                >
                                    <Grid container>
                                        <Grid item xs={2}>{contact?.unsubscribed ? <BlockIcon/> : <PersonIcon/>}</Grid>
                                        <Grid container item xs={8}>
                                            <Grid item xs={12}>
                                                <Typography variant="body1" style={props.selectedContact === contact.contactPhone ?{ fontWeight:'bold'}:{}}>{formatPhone(contact.contactPhone)}</Typography>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Typography variant="caption" style={props.selectedContact === contact.contactPhone ?{ fontWeight:'bold'}:{}}>{contact.firstName} {contact.lastName}</Typography>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Divider/>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Typography variant="body2" style={props.selectedContact === contact.contactPhone ?{ fontWeight:'bold'}:{}}><b>{props.lastMessages.filter(msg=>msg?.contactPhone===contact.contactPhone)[0]?.directionType === 'MO' ? 'Them: ' : props.lastMessages.filter(msg=>msg?.contactPhone===contact.contactPhone)[0]?.directionType === 'MT' ? 'You: ' : null}</b> {props.lastMessages.filter(msg=>msg?.contactPhone===contact.contactPhone)[0] ? formatMessage(props.lastMessages.filter(msg=>msg?.contactPhone===contact.contactPhone)[0].text) : null}</Typography>
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={1}>
                                            <Badge color="secondary" max={10} badgeContent={ getUnread(contact.contactPhone) } />
                                        </Grid>
                                        <Grid item xs={1}>

                                            {/* MENU HERE */}
                                            {/* <div>
                                                <IconButton size='small' onClick={(e)=> { handleClick(e) }}><MoreVertIcon/></IconButton>
                                                <Menu
                                                    id="simple-menu"
                                                    anchorEl={anchorEl}
                                                    keepMounted
                                                    variant='selectedMenu'
                                                    open={Boolean(anchorEl)}
                                                    onClose={handleClose}
                                                >
                                                    <MenuItem onClick={() => { handleClose() }}>Delete Conversation</MenuItem>
                                                </Menu>
                                            </div> */}

                                            <IconButton 
                                                size='small' 
                                                onClick={(e)=> { 
                                                    setCurrentSelection(contact); 
                                                    props.setConfirmationModalType('conversation');
                                                    props.setConfirmationModal(true)
                                                }}
                                            >
                                                <DeleteIcon/>
                                            </IconButton>
                                                        
                                        </Grid>
                                    </Grid>
                                    <Divider style={{marginBottom:'-10px', marginTop:'10px'}}/>
                                </div>
                            )})
                        }

                            {!props.loading && props.contacts && props.contacts.length === 0 ? <div style={{ margin:'5px', padding:'4px', color:'grey'}}>
                            <Typography >No contacts found...</Typography>
                            <Divider style={{width:'50%'}}/>
                        </div> : null}
                    </Grid>

                    <Grid item xs={8}>
                    {!props.loading && 
                        <ReplyBar sending={sending} setSending={setSending} contacts={props.contacts} selectedContact={props.selectedContact} sendSMS={props.sendSMS}/>
                        }
                    </Grid>
                    <Grid item xs={1} style={{justifyContent:'flex-end'}}>
                        
                    </Grid>
                    <Grid item xs={1} style={{justifyContent:'flex-end'}}>
                        <Button variant="contained" onClick={handlePreviousPage} disabled={pageNumber === 1}>
                            <ArrowBackIcon/>
                        </Button>
                    </Grid>
                    <Grid item xs={1} style={{justifyContent:'flex-end'}}>
                        <Typography>Page {pageNumber} of {totalPages}</Typography>
                    </Grid>
                    <Grid item xs={1} style={{justifyContent:'flex-end'}}>
                        <Button variant="contained"onClick={handleNextPage} disabled={pageNumber === totalPages}>
                            <ArrowForwardIcon/>
                        </Button>
                    </Grid>
                </Grid>
            </Paper>

        </Grid>
    )
}

export default withRouter(ConversationsManager);