import React, { useState, useEffect, useCallback } from 'react';
import { withRouter } from 'react-router'
import { firestore } from '../../../firebase/firebase'
import CouponManager from '../../components/CouponManager/CouponManager'
import _, { cloneDeep } from 'lodash'
import { makeStyles } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';

import { useCoupon } from '../../../providers/CouponsProvider'

const useStyles = makeStyles((theme) => ({
    content: {
        display: 'flex',
        width: '100%',
        padding: '32px',
        justifyContent: 'center'
    },
}));

const Coupon = (props) => {

    const classes = useStyles();

    const { coupons, error } = useCoupon();
    const [coupon, setCoupon] = useState({});
    const [couponLoading, setCouponLoading] = useState(true);
    const [initialCoupon] = useState({
        code: "",
        description: "",
        active: false,
        discount: 0,
        productsAffected: [],
        discountType: "percentage",
        global: false,
        isSale: false,
        category: {
            value: '',
            onlyCategory: false,
        },
    })

    //Save and Editing coupon management
    const [saving, setSaving] = useState(false);
    const [saveState, setSaveState] = useState({});
    const [categories, setCategories] = useState([])

    let path = new URLSearchParams(props.location.search).get("docId");

    const updateData = useCallback((e) => {
        setCoupon(e)
    },
        [],
    );

    const saveData = async () => {
        setSaving(true);
        if (path === "new")
            firestore.collection('coupons').add(coupon)
                .then(() => {
                    setSaveState(_.cloneDeep(coupon));
                    setSaving(false)

                    //props.history.goBack();
                }).catch((e) => {
                    console.log(e)
                    setSaving(false)
                });
        else
            firestore.collection('coupons').doc(coupon.docId)
                .update(coupon)
                .then(() => {
                    setSaveState(_.cloneDeep(coupon));
                    setSaving(false)
                }).catch((e) => {
                    console.log(e)
                    setSaving(false)
                });
    };

    const cancelSave = () => {
        setCoupon(cloneDeep(saveState));
    };

    useEffect(() => {
        setCouponLoading(true);
        firestore.collection('productCategories').get()
        .then(querySnapshot => {
            const cateogryResponse = querySnapshot.docs.map((doc) => {
              return {
                ...doc.data(),
                'docId': doc.id,
              }
            })

           setCategories(cateogryResponse[0]);
           setCouponLoading(false);
        }).catch((e) => {
           console.log(e)
           setCouponLoading(false);
        });
    }, [])

    useEffect(() => {
        setCouponLoading(true);
        if (path === 'new') {
            setCoupon(cloneDeep(initialCoupon));
            setSaveState(cloneDeep(initialCoupon));
            setCouponLoading(false);
        }
        else {
            let foundCoupon = coupons.find(coupon => coupon.docId === path);

            setCoupon(cloneDeep(foundCoupon));
            setSaveState(cloneDeep(foundCoupon));
            setCouponLoading(false);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [path])

    return (
        <div className={classes.content}>
            {couponLoading || _.isUndefined(coupon)
                ? 
                <Skeleton variant='rect' width={'100vw'} height={'100vh'}/>
                : 
                <CouponManager
                    coupon={coupon} coupons={coupons}
                    saveState={saveState} cancelSave={cancelSave} saving={saving} confirmSave={saveData}
                    updateData={updateData} error={error} 
                    categories={categories}
                />}
        </div>
    )
}

export default withRouter(Coupon);