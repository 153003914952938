import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { Grid, Typography, Radio, Divider, Checkbox, FormControlLabel, Select, MenuItem, InputLabel, TextField } from '@material-ui/core'
import _ from 'lodash';
import { firestore } from '../../../../../firebase/firebase'

const ChecklistModal = (props) => {

    const [loading, setLoading] = useState(false)
    const [checklists, setChecklists] = useState([])
    const [selectedChecklist, setSelectedChecklist] = useState({})

    useEffect(() => {
        setLoading(true)
        firestore.collection('premadeChecklists').get()
        .then(querySnapshot => {
            const checklistResponse = querySnapshot.docs.map((doc) => {
                return {
                    ...doc.data(),
                }
            })
            setChecklists(checklistResponse)
            setLoading(false)
        })
        .catch(e => {
            console.log(e.message);
        })

    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    //sets selectedChecklist (used for title) on modal open 
    useEffect(() => {
        if(props.checklistModalOpenInEdit && props.checklistModalOpenInEdit === true){
            setSelectedChecklist(checklists[checklists.findIndex(checklist => checklist.title === props.currentChecklist.title)])
        }
        else{
            setSelectedChecklist({})
        }
    }, [props.checklistModal]) // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <Grid>
     
            <Grid container spacing={2}>
                <Grid item xs={5}>
                    <Typography style={{marginTop: '8px'}}>Checklist: </Typography>
                </Grid>

                <Grid item xs={7}>
                    <InputLabel id="label">Choose Checklist</InputLabel>
                        <Select 
                            fullWidth 
                            labelId="label" 
                            id="select"
                            value={selectedChecklist} 
                            onChange={(e) => { 
                                if(props.checklistModalOpenInEdit){
                                    if(props.currentChecklist?.jobID){
                                        props.setCurrentChecklist({
                                            ...e.target.value,
                                            docId: props.currentChecklist.docId,
                                            date: props.currentChecklist.date,
                                            created: props.currentChecklist.created,
                                            timesheetID: props.currentChecklist.timesheetID,
                                            uid: props.currentChecklist.uid,
                                            serviceType: e.target.value.title,
                                            photoURLS: props.currentChecklist.photoURLS,
                                            location: props.currentChecklist.location,
                                            current: props.currentChecklist.current,
                                            jobID: props.currentChecklist.jobID,
                                            address: props.currentChecklist.address,
                                            customer: props.currentChecklist.customer,
                                        })
                                        setSelectedChecklist(e.target.value)
                                    }
                                    else{
                                        props.setCurrentChecklist({
                                            ...e.target.value,
                                            docId: props.currentChecklist.docId,
                                            date: props.currentChecklist.date,
                                            created: props.currentChecklist.created,
                                            timesheetID: props.currentChecklist.timesheetID,
                                            uid: props.currentChecklist.uid,
                                            serviceType: e.target.value.title,
                                            photoURLS: props.currentChecklist.photoURLS,
                                            location: props.currentChecklist.location,
                                            current: props.currentChecklist.current,
                                        })
                                        setSelectedChecklist(e.target.value)
                                    }
                                }
                                else{
                                    props.setCurrentChecklist(e.target.value)
                                    setSelectedChecklist(e.target.value)
                                }
                            }}
                        >
                            <MenuItem value={{}}>None</MenuItem>
                            {!loading 
                            ?
                                checklists.map((checklist, index) => {
                                    return (
                                        <MenuItem key={index} value={checklist}>{checklist.title}</MenuItem>
                                    )
                                })
                            : <MenuItem value={{}}>Loading</MenuItem>}
                        
                    </Select>
                </Grid>

            </Grid>

            <Divider style={{marginTop: '10px', marginBottom:'20px'}}/>

            <Grid container spacing={2}>
                {props.currentChecklist?.title ?
                <Grid item xs={12}>
                    {
                        Object.keys(props.currentChecklist.items).sort((a, b) => { return props.currentChecklist.items[a].position > props.currentChecklist.items[b].position ? 1 : -1 }).map((key, index) => {
                            return (
                                <Grid container key={key}>
                                    <Grid item xs={12}>
                                        <Typography style={{marginTop: '8px'}}>{key}</Typography>
                                        <Divider style={{marginTop: '10px', marginBottom:'10px'}}/>
                                    </Grid>
                                    
                                    {
                                        props.currentChecklist.items[key].items.map((item, index) => {
                                            return (
                                                
                                                props.currentChecklist.items[key].type === 'Radio Button' ? 
                                                    <Grid item xs={12} key={index}>
                                                        <FormControlLabel 
                                                            key={index} 
                                                            control={<Radio checked={props.currentChecklist.items[key].selectedValue === item.name} 
                                                            onChange={(e) => {
                                                                let newChecklist = _.cloneDeep(props.currentChecklist)
                                                                newChecklist.items[key].selectedValue = newChecklist.items[key].items[index].name
                                                                props.setCurrentChecklist(newChecklist)
                                                            }}
                                                            style={{ margin: '10px' , paddingTop: '5px'}}/>} 
                                                            disabled={item.style === 'photoUpload'} 
                                                            label={item.name} 
                                                        /> 

                                                        {item.style==='userEnteredText' && item.checked ? 
                                                            <TextField 
                                                                style={{width: '100%'}}
                                                                value={item.enteredText} 
                                                                onChange={(e) => {
                                                                    let newChecklist = _.cloneDeep(props.currentChecklist)
                                                                    newChecklist.items[key].items[index].enteredText = e.target.value
                                                                    props.setCurrentChecklist(newChecklist)
                                                                }}
                                                                 /> 
                                                        : null}
                                                    </Grid>
                                                 :
                                                 <Grid item xs={12} key={index}>
                                                    <FormControlLabel key={index} control={<Checkbox onChange={() => {
                                                        //check off the checkbox in the selectedChecklist
                                                        let newChecklist = _.cloneDeep(props.currentChecklist)
                                                        newChecklist.items[key].items[index].checked = !newChecklist.items[key].items[index].checked
                                                        props.setCurrentChecklist(newChecklist)
                                                    }}
                                                    checked={item.checked} disabled={item.style==='photoUpload'} style={{ margin: '10px' , paddingTop: '5px'}}/>} label={item.name} /> 
                                                    {item.style==='userEnteredText' && item.checked ? <TextField style={{width: '100%'}} value={item.enteredText} 
                                                    onChange={(e) => {
                                                        let newChecklist = _.cloneDeep(props.currentChecklist)
                                                        newChecklist.items[key].items[index].enteredText = e.target.value
                                                        props.setCurrentChecklist(newChecklist)
                                                    }}
                                                    /> : null}
                                                </Grid>
                                            )
                                        })
                                    }
                                    
                                </Grid>
                            )
                        })
                    }
                </Grid>
                :null}
            </Grid>

            <Divider style={{marginTop: '10px', marginBottom:'10px'}}/>

            <Grid container>
                <Grid item xs={12} style={{marginTop: '10px'}}>
                    <Typography>Add Checklist To Job</Typography>
                </Grid>
            </Grid>

            <Divider style={{marginTop: '10px', marginBottom:'10px'}}/>

            {
                props.jobData?.map((job) => {
                    return (
                        <FormControlLabel
                            key={job.docId}
                            style={{margin: '5px'}}
                            control={
                                <Checkbox 
                                    checked={props.currentChecklist?.jobID ? props.currentChecklist?.jobID.includes(job.docId) : false} 
                                    name={job.docId} />}
                                    label={job.customer}
                                    onChange={() => { 
                                        props.setCurrentChecklist({...props.currentChecklist,
                                        jobID: job.docId,
                                        customer: job.customer,
                                        address: job.address,
                                    })
                                }} 
                                />
                        
                    )
                })
            }
        </Grid>
    )
}
export default withRouter(ChecklistModal)