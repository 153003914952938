import React, { useEffect, useState } from "react";
import {
    Button, Checkbox, Divider, Grid,
    Paper, Typography, Modal, TextField, FormControlLabel
} from '@material-ui/core';
import { useCustomer } from '../../../providers/CustomerContext';
import { withRouter } from 'react-router'


const CustomerSelector = (props) => {
    const { customers, getCustomers } = useCustomer();
    const [open] = useState(false);
    const [selectedCustomers, setSelectedCustomers] = useState(props.callbackList || []);
    const [searchValue, setSearchValue] = useState('');

    useEffect(() => {
        getCustomers();
        //eslint-disable-next-line
    }, []);

    useEffect(() => {
        
    }, [open, selectedCustomers, searchValue]);
    

    const handleSelect = (e) => {
        if (selectedCustomers.length === 0) {
            setSelectedCustomers([...selectedCustomers, e.target.value]);
        } else {
            if (selectedCustomers.includes(e.target.value)) {
                setSelectedCustomers(selectedCustomers.filter(c => c !== e.target.value));
            } else {
                setSelectedCustomers([...selectedCustomers, e.target.value]);
            }
        }

        
    }

    const handleFinish = () => {
        //merge the selected customers with the callback list
        props.setCallbackList(selectedCustomers);
        props.updateCustomerData({ ...props.currentCustomer, 'addressBook': selectedCustomers })
        props.handleClose();
    }
    
    return (


                <Modal style={{maxWidth:'750px',padding:'10px',
                margin:'10% auto auto auto', }}
                    open={props.open}
                    onClose={() => {props.handleClose(); setSelectedCustomers([])}}
                    aria-labelledby="simple-modal-title"
                    aria-describedby="simple-modal-description"
                >
                    <Paper style={{
                        padding:'10px',
                        minHeight:'660px',
                        maxHeight:'660px',
                        
                        
                    }}>
             
                        <Grid container>
                            <Grid item xs={12}>
                                <Typography variant='h6'>Select Customers</Typography>

                                <Divider />

                                <Grid container>
                                    <Grid item xs={12}>
                                        
                                        <TextField
                                            fullWidth
                                            label='Search'
                                            variant='outlined'
                                            value={searchValue}
                                            inputProps={{ style: { fontSize: 14 } }}
                                            onChange={(e) => setSearchValue(e.target.value)}
                                        />
                                        <Divider />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={
                                                        //every visible customer is selected
                                                        customers.sort((a, b) => a.name.localeCompare(b.name))
                                                        .filter(custy => custy.docId !== props.currentCustomer.docId)
                                                        .filter(custy => !custy.isParent)
                                                        //filter out any customers that are already in a master customer
                                                        .filter(custy => !customers.filter(c => c.isParent && c.docId !== props.currentCustomer.docId).map(c => c.addressBook).flat().includes(custy.docId))
                                                        .filter(c => c.name.toLowerCase().includes(searchValue.toLowerCase()) || c.fullStreet.toLowerCase().includes(searchValue.toLowerCase()))
                                                        .every(c => selectedCustomers.includes(c.docId))

                                                    }
                                                    onChange={(e) => {
                                                        if (e.target.checked) {
                                                            setSelectedCustomers([...selectedCustomers, ...customers.sort((a, b) => a.name.localeCompare(b.name))
                                                                .filter(custy => custy.docId !== props.currentCustomer.docId)
                                                                .filter(custy => !custy.isParent)
                                                                //filter out any customers that are already in a master customer
                                                                .filter(custy => !customers.filter(c => c.isParent && c.docId !== props.currentCustomer.docId).map(c => c.addressBook).flat().includes(custy.docId))
                                                                .filter(c => c.name.toLowerCase().includes(searchValue.toLowerCase()) || c.fullStreet.toLowerCase().includes(searchValue.toLowerCase()))
                                                                .map(c => c.docId)])
                                                        } else {
                                                            setSelectedCustomers(selectedCustomers.filter(c => !customers.sort((a, b) => a.name.localeCompare(b.name))
                                                                .filter(custy => custy.docId !== props.currentCustomer.docId)
                                                                .filter(custy => !custy.isParent)

                                                                //filter out any customers that are already in a master customer
                                                                .filter(custy => !customers.filter(c => c.isParent && c.docId !== props.currentCustomer.docId).map(c => c.addressBook).flat().includes(custy.docId))
                                                                .filter(c => c.name.toLowerCase().includes(searchValue.toLowerCase()) || c.fullStreet.toLowerCase().includes(searchValue.toLowerCase()))
                                                                .map(c => c.docId).includes(c)))
                                                        }
                                                    }}
                                                    color="primary"
                                                />
                                            }
                                            label={"Select "+
                                            customers.sort((a, b) => a.name.localeCompare(b.name))
                                                        .filter(custy => custy.docId !== props.currentCustomer.docId)
                                                        .filter(custy => !custy.isParent)
                                                        //filter out any customers that are already in a master customer
                                                        .filter(custy => !customers.filter(c => c.isParent && c.docId !== props.currentCustomer.docId).map(c => c.addressBook).flat().includes(custy.docId))
                                                        .filter(c => c.name.toLowerCase().includes(searchValue.toLowerCase()) || c.fullStreet.toLowerCase().includes(searchValue.toLowerCase()))
                                                        .length
                                            +" Customers"}
                                        />
                                    

                                        <Divider style={{margin:'4px'}}/>
                                    </Grid>
                                    <Grid item xs={12} style={{overflowY:'scroll', height:'450px',}}>
                                        {customers.sort((a, b) => a.name.localeCompare(b.name))
                                        .filter(custy => custy.docId !== props.currentCustomer.docId)
                                        .filter(custy => !custy.isParent)
                                        //filter out any customers that are already in a master customer
                                        .filter(custy => !customers.filter(c => c.isParent && c.docId !== props.currentCustomer.docId).map(c => c.addressBook).flat().includes(custy.docId))
                                        .filter(c => c.name.toLowerCase().includes(searchValue.toLowerCase()) || c.fullStreet.toLowerCase().includes(searchValue.toLowerCase()))
                                        .map(c => (
                                            <Grid item xs={12}>
                                                <Typography variant='body1'>
                                            <Checkbox
                                                        checked={selectedCustomers.includes(c.docId)}
                                                        onChange={handleSelect}
                                                        value={c.docId}
                                                        color="primary"
                                                        disabled={c.isParent}
                                                    />
                                                
                                               
                                            
                                            <b>{c.name}</b> - {c.fullStreet} <i>{c.isParent ? '      This Customer is a Master':null}</i></Typography>
                                            </Grid>
                                        ))}

                                        
                                    </Grid>
                                    <Grid item xs={12}>
                                        

                                        <Divider style={{margin:'5px'}} />
                                    </Grid>

                                    <Grid item xs={6}>
                                        

                                        <Button onClick={()=>handleFinish()} variant="contained">Select {selectedCustomers.length} Customer(s)</Button>
                                    </Grid>
                                    <Grid container justifyContent='flex-end' item xs={6}>
                                        

                                        <Button variant="contained" color='secondary' onClick={() => {props.handleClose(); setSelectedCustomers([])}}>Cancel</Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>

                    </Paper>
                </Modal>

    );
}

export default withRouter(CustomerSelector);