import React, { useState } from 'react';
import { Button, makeStyles } from '@material-ui/core';
import { PDFViewer, Font, Page, Text, View, Document, StyleSheet } from '@react-pdf/renderer';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { withRouter } from 'react-router-dom';
import moment from 'moment';

import PayrollHeader from './PDFComponents/PayrollHeader';
import PayrollSummary from './PDFComponents/PayrollSummary';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        justifyContent: 'center',
        width: '100%',
    },
    content: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        maxWidth: '1200px',
        margin: 'auto',
    },
    buttonRow: {
        marginBottom: '20px',
        marginTop: '20px',
    },
    checkRow: {
        margin: theme.spacing(1),
        display: 'flex',
        justifyContent: 'space-evenly',
    },
    
}));

Font.register({
    family: 'Oswald',
    src: 'https://fonts.gstatic.com/s/oswald/v13/Y_TKV6o8WovbUd3m_X9aAA.ttf'
});

// Create styles
const styles = StyleSheet.create({
    page: {
        flexDirection: 'column',
        fontFamily: 'Helvetica',
        padding: 20,
    },
    section: {
        marginBottom: 10,
    },
    dTable: {
        display: "table",
        width: "auto",
        border: '1 solid black',

        marginBottom: 10,
    },
    dTableRow: {
        margin: "auto",
        flexDirection: "row"
    },
    dTableCol: {
        flexGrow: 1,
    },
    dTableCell: {
        margin: "auto",
        marginTop: 5,
        marginBottom: 5,
        fontSize: 10,
    },
    table: {
        border: '1 solid black',
        marginBottom: 10,
        padding: 10,
        flexGrow: 1,
        fontSize: 14,
        flexDirection: 'column',
        justifyContent: 'space-between',
    },
    subTable: {
        marginBottom: 10,
        flexGrow: 1,
        fontSize: 14,
        flexDirection: 'column',
        justifyContent: 'space-between',
    },
    tableRow: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginTop: 2,
        marginBottom: 2,
    },
    tableText: {
        fontSize: 10,
        marginBottom: '1px',
        textAlign: 'center',
    },
    tableTextSmall: {
        fontSize: 10,
        textAlign: 'center',
    },
    tableTextBold: {
        fontSize: 10,
        fontFamily: 'Helvetica-Bold',
        fontWeight: 'bold',
        marginBottom: '10px',
        textAlign: 'center',
    },
    tableTextBoldUnderline: {
        fontSize: 12,
        fontFamily: 'Helvetica-Bold',
        fontWeight: 'bold',
        textDecoration: 'underline',
        marginBottom: '10px',
        textAlign: 'center',
    },
    summary: {
        flexDirection: 'row',
    },
    viewer: {
        width: '100%',
        maxWidth: '1200px',
        height: '85vh',
        marginBottom: '20px',
    },
    checkbox: {
        width: '10px',
        height: '10px',
        background: 'white',
        border: '1 solid black',
    },
    radio: {
    width: '10px',
    height: '10px',
    background: 'white',
    borderRadius: '50%',
    border: '1 solid black',
    },
    userEntered: {
    width: '200px',
    height: '20px',
    background: 'white',
    border: '1 solid black',
    },
    header: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    margin: '4px',  
    },
    detailsCell: {
        width: '14.28%',
        minWidth: '14.28%',
        alignItems: 'center',
    },
    FFCell: {
        width: '50%',
        minWidth: '50%',
    },


});

const PayrollPDF = (props) => {

    const classes = useStyles();
    /**
     * @param title - Table Header
     * @param columns - Generates table column header and its internal data. Can pass either an objects key to iterate or a function.
     * @param data - The table data passed (as an array of objects)
     * @param components - React components passed as special rendering for additional rows 
    */

    const [ticketData] = useState(props.ticketData)
    const [payrollDetails] = useState(props.payrollDetails)
    const [user] = useState(props.users[0])
    const [columns] = useState(props.columns)

    const formattedTickets = props.ticketData.reduce(
        (accumulator, ticket) => {
            //find the week that date is presented in (isoWeek so week is not dervied from local machine)
            //console.log(moment(ticket.date).startOf('isoWeek').format('YYYY-MM-DD'));

            let formattedWeek = moment(ticket.date).startOf('isoWeek').format('YYYY-MM-DD')
            return {...accumulator, [formattedWeek]: [...accumulator[formattedWeek] || [], ticket]} 
        },[],
    );

    //used to calculate totalTime, regularTime and overTime
    const calcTimeValues = (dataArray) => {
        let totalTime = dataArray.reduce((accumulator, currentValue) => (Number(accumulator) + Number(currentValue.payrollUnits || 0.00)).toFixed(2), 0,)
        let regularTime = totalTime > 50.00 ? 50.00 : totalTime;
        let overtime = totalTime - regularTime;

        return {totalTime: totalTime, regularTime: regularTime, overtime: overtime}
    }

    const tableContent = (
        <View style={{marginBottom: '30px'}} >
            <View >
                <View style={{flexDirection: 'row'}}>
                    {columns.map((column, columnIndex) => (
                        <Text key={columnIndex} style={{...styles.tableTextBoldUnderline, width: `${column.width}%`}}>
                            {column.header}
                        </Text>
                    ))}
                </View>
            </View>
            <View>
                {Object.entries(formattedTickets).map(([key, value], rateIndex) => (
                    <View key={rateIndex}>
                        {value.map((ticket, ticketIndex) => (
                            <View key={ticketIndex} style={ticketIndex % 2 === 0 ? {flexDirection: 'row', backgroundColor: 'grey'} : {flexDirection: 'row',}}>
                                {columns.map((column, columnIndex) => {
                                    switch(column.field) {
                                        case 'date':
                                            return <Text style={{...styles.tableText, width: `${column.width}%`}} key={ticketIndex + columnIndex}> {moment(ticket.date).format('YYYY-MM-DD')} </Text>
                                        case 'notes':
                                            return <Text style={{...styles.tableText, width: `${column.width}%`}} key={ticketIndex + columnIndex}> {ticket.notes} </Text>    
                                        case 'ticket':
                                            return <Text style={{...styles.tableText, width: `${column.width}%`}} key={ticketIndex + columnIndex}> {ticket.ticket} </Text>     
                                        case 'payrollUnits':
                                            return <Text style={{...styles.tableText, width: `${column.width}%`}} key={ticketIndex + columnIndex}> {Number(ticket.payrollUnits || 0).toFixed(2)} </Text>
                                        case 'payrollRate':
                                            return <Text style={{...styles.tableText, width: `${column.width}%`}} key={ticketIndex + columnIndex}> {`$${Number(ticket.payrollRate || 0).toFixed(2)}`} </Text>    
                                        case 'payroll':
                                            return <Text style={{...styles.tableText, width: `${column.width}%`}} key={ticketIndex + columnIndex}> {`$${Number(ticket.payroll || 0).toFixed(2)}`} </Text>    
                                        default:
                                            console.warn('Field not available within render switch.')  
                                            return null;
                                    }
                                })}
                            </View>
                        ))}

                        {/* week totals */}
                        <View style={{flexDirection: 'row', position: 'relative', marginTop: '30px'}}>
                            <View style={{flexDirection: 'column', margin: 'auto', border: '1px solid black', backgroundColor: '#d3d3d3', padding: '5px', minWidth: '200px'}}>
                                <View style={{flexDirection: 'row', justifyContent: 'space-between'}}>
                                    <Text style={{...styles.tableTextBold, }}>{`Total Time:`}</Text>
                                    <Text style={{...styles.tableTextBold, }}>{`${calcTimeValues(value).totalTime}`}</Text>
                                </View>

                                <View style={{flexDirection: 'row', justifyContent: 'space-between'}}>
                                    <Text style={{...styles.tableTextBold, }}>{`Regular Time:`}</Text>
                                    <Text style={{...styles.tableTextBold, }}>{`${calcTimeValues(value).regularTime}`}</Text>
                                </View>
                                <View style={{flexDirection: 'row', justifyContent: 'space-between'}}>
                                    <Text style={{...styles.tableTextBold, }}>{`Overtime > 50 hrs:`}</Text>
                                    <Text style={{...styles.tableTextBold, }}>{`${calcTimeValues(value).overtime}`}</Text>
                                </View>
                            </View>

                            <View style={{...styles.detailsCell, flexDirection: 'column', position: 'absolute', right: 0}}>
                                <Text style={{...styles.tableTextBoldUnderline}}>{`$${value.reduce((accumulator, currentValue) => (Number(accumulator) + Number(currentValue.payroll || 0.00)).toFixed(2), 0,)}`}</Text>
                            </View>
                        </View>
                    </View>
                ))}
            </View>
        </View>
    )

    return (
        <div className={classes.root}>
            <div className={classes.content}>

                <div className={classes.buttonRow}>
                    <Button 
                        variant='contained'
                        onClick={()=> { props.history.goBack() }}
                        startIcon={<ArrowBackIcon />}
                        >
                            Back
                        </Button>
                </div>

                <PDFViewer style={styles.viewer}>
                    <Document>
                        <Page size="A4" style={styles.page} wrap> 

                            <PayrollHeader 
                                ticketData={ticketData}
                                user={user}
                            />

                            {tableContent}

                            <PayrollSummary 
                                ticketData={ticketData}
                                payrollDetails={payrollDetails}
                                calcTimeValues={calcTimeValues}
                            />

                        </Page>
                    </Document>
                </PDFViewer>
            </div>
        </div>
    )
};

export default withRouter(PayrollPDF);