import React, { useState, useEffect, useCallback } from 'react';
import { withRouter } from 'react-router'
import { firestore } from '../../../firebase/firebase'
import EquipmentCard from '../../components/Equipment/EquipmentCard'
import { Grid, makeStyles } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import _ from 'lodash'

const useStyles = makeStyles((theme) => ({
    content: {
        display: 'flex', 
        width:'100%', 
        padding: '32px', 
        justifyContent:'center',
        marginTop: '40px',
    },
}));

const Equipment = (props) => {

    const [equipmentData, setEquipmentData] = useState({});
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState("");
    const [saving, setSaving] = useState(false);
    const [saveState, setSaveState] = useState({});

    const classes = useStyles();

    const updateEquipmentData = useCallback((e) => {
        setEquipmentData(e)
    },
        [],
    );
    
    let path = new URLSearchParams(props.location.search).get("docId");
    const confirmSave = () => {
        setSaving(true);
        firestore.collection('unitDescriptions').doc(path)
            .update(equipmentData)
            .then(() => {
                setSaveState(equipmentData);
                setSaving(false)
            }).catch((e) => setError(e));
    };

    const cancelSave = () => {
        setEquipmentData(saveState);
    };
    
    useEffect(() => {
        firestore.collection('unitDescriptions').where('__name__', "==", path).get()
            .then(querySnapshot => {
                const equipmentResponse = querySnapshot.docs.map((doc) => {
                    return {
                        ...doc.data(),
                        'docId': doc.id,
                        'year': doc.data().year ? doc.data().year : '',
                        'equipmentType': doc.data().equipmentType ? doc.data().equipmentType : '',
                        'model': doc.data().model ? doc.data().model : '',
                        'make': doc.data().make ? doc.data().make : '',
                        'features': doc.data().features ? doc.data().features : '',
                        'mnpNum': doc.data().mnpNum ? doc.data().mnpNum : '',
                        'serialNum': doc.data().serialNum ? doc.data().serialNum : '',
                        'aliasUnitNum': doc.data().aliasUnitNum ? doc.data().aliasUnitNum : '',
                        'tare': doc.data().tare ? doc.data().tare : '',
                        'class': doc.data().class ? doc.data().class : '',
                        'isOwned': doc.data().isOwned ? doc.data().isOwned : true,
                        'isActive': !_.isUndefined(doc.data().isActive) ? doc.data().isActive : true,
                        'owner': doc.data().owner ? doc.data().owner : 'Gee Tee Holdings Inc.',
                        'marketValue': doc.data().marketValue ? doc.data().marketValue : '',
                        'purchasePrice': doc.data().purchasePrice ? doc.data().purchasePrice : '',
                        'insuredValue': doc.data().insuredValue ? doc.data().insuredValue : '',
                        'purchasedFrom': doc.data().purchasedFrom ? doc.data().purchasedFrom : '',
                        'purchaseDate': doc.data().purchaseDate ? doc.data().purchaseDate : '1990/01/01',
                        'leasedFinancedFrom': doc.data().leasedFinancedFrom ? doc.data().leasedFinancedFrom : '',
                        'chargeRate': doc.data().chargeRate ? doc.data().chargeRate : '',
                    }
                })
                setEquipmentData(equipmentResponse[0]);
                setSaveState(equipmentResponse[0]);
                firestore.collection('unitDescriptions').doc(path)
                    .update(equipmentResponse[0])
                setLoading(false);
            })
            .catch(e => {
                setError("Unit Description not found.");
                setLoading(false);
                console.log(e.message);
            })
    }, [path]);

    return (
        <Grid>
            {loading
                ? 
                <Grid className={classes.content}>
                    <Skeleton variant='rect' width={'50vw'} height={'83vh'}/>
                </Grid>
                : 
                <Grid>
                    <EquipmentCard
                        error={error}
                        equipmentData={equipmentData} saveState={saveState} setSaveState={setSaveState} setEquipmentData={setEquipmentData}
                        cancelSave={cancelSave} confirmSave={confirmSave}
                        updateEquipmentData={updateEquipmentData} saving={saving} 
                    />
                </Grid>}
        </Grid>
    )
}

export default withRouter(Equipment);