import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import { withRouter } from 'react-router-dom';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import {auth, firestore, storage} from '../../../../firebase/firebase';
import {Drawer, AppBar, Toolbar, List, CssBaseline, Typography, Divider, IconButton, ListItem, ListItemIcon, ListItemText, Collapse,
    ListSubheader, Grid, Box, Tooltip, TextField, Badge, useMediaQuery, FormControlLabel, Switch, Modal, Fade, Button, Backdrop, Paper, Chip
} from '@material-ui/core';
import { Link } from 'react-router-dom'
import { useAuth } from '../../../../providers/AuthContext';
import _ from 'lodash';
import Dropzone from 'react-dropzone';
import moment from 'moment';
import { SubDrawer } from './SubDrawers/SubDrawer';
import { useHRSettings } from '../../../../providers/HRSettingsProvider';

import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import Brightness4Icon from '@material-ui/icons/Brightness4';
import Brightness7Icon from '@material-ui/icons/Brightness7';
import HomeIcon from '@material-ui/icons/Home';
import AssessmentIcon from '@material-ui/icons/Assessment';
import PermContactCalendarIcon from '@material-ui/icons/PermContactCalendar';
import ShopIcon from '@material-ui/icons/Shop';
import PeopleIcon from '@material-ui/icons/People';
import LocalShippingIcon from '@material-ui/icons/LocalShipping';
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';
import AddIcon from '@material-ui/icons/Add';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import LocalAtmIcon from '@material-ui/icons/LocalAtm';
import MenuBookIcon from '@material-ui/icons/MenuBook';
import SettingsApplicationsIcon from '@material-ui/icons/SettingsApplications';
import ImportantDevicesIcon from '@material-ui/icons/ImportantDevices';
import ContactMailIcon from '@material-ui/icons/ContactMail';
//import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import ListAltIcon from '@material-ui/icons/ListAlt';
import PhoneAndroidIcon from '@material-ui/icons/PhoneAndroid';
import BuildIcon from '@material-ui/icons/Build';
import BugReportIcon from '@material-ui/icons/BugReport';
import GrainIcon from '@material-ui/icons/Grain';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import PublishIcon from '@material-ui/icons/Publish';
import GavelIcon from '@material-ui/icons/Gavel';
//import HealingIcon from '@material-ui/icons/Healing';
//import SubjectIcon from '@material-ui/icons/Subject';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const drawerWidth = 232;
const drawerWidthClosed = 72;

const useStyles = makeStyles((theme) => ({
    backdrop: {
        zIndex: 999,
    },
    root: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%'
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    appBarShift: {
        marginLeft: drawerWidth,
        //width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    menuButton: {
        marginRight: 36,
    },
    hide: {
        display: 'none',
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
        overFlowX: 'auto'
    },
    drawerOpen: {
        width: drawerWidth,
        paddingTop: '64px',
        whiteSpace: 'nowrap',
        boxShadow: '0px 0px 10px 0px rgba(0,0,0,0.5)',
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    drawerClose: {
        paddingTop: '64px',
        whiteSpace: 'nowrap',
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        width: 0,
        overflow: "auto",
        scrollbarWidth: "none", // Hide the scrollbar for firefox
        '&::-webkit-scrollbar': {
            display: 'none', // Hide the scrollbar for WebKit browsers (Chrome, Safari, Edge, etc.)
        },
        '&-ms-overflow-style:': {
            display: 'none', // Hide the scrollbar for IE
        },
        alignItems: 'flex-start',
        [theme.breakpoints.up('sm')]: {
            width: drawerWidthClosed,
        },
    },
    expand: {
        transform: 'rotate(-90deg)',
        marginLeft: 'auto',
        transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.shortest,
        }),
    },
    expandOpen: {
        transform: 'rotate(0deg)',
    },
    subDrawerOpen: {
        zIndex: 999,
        //backgroundColor: 'whitesmoke',
        left: drawerWidth,
        width: drawerWidth,
        paddingTop: '64px',
        boxShadow: '0px 0px 10px 0px rgba(0,0,0,0.5)',
        transition: theme.transitions.create(['left', 'width'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    subDrawerClose: {
        zIndex: 999,
        //backgroundColor: 'whitesmoke',
        left: 0,
        width: 0,
        paddingTop: '64px',
        transition: theme.transitions.create(['left', 'width'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    subSubDrawerOpen: {
        zIndex: 998,
        //backgroundColor: 'whitesmoke',
        left: drawerWidth*2,
        width: drawerWidth*1.03,
        paddingTop: '64px',
        boxShadow: '0px 0px 10px 0px rgba(0,0,0,0.5)',
        transition: theme.transitions.create(['left', 'width'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    subSubDrawerClose: {
        zIndex: 998,
        //backgroundColor: 'whitesmoke',
        left: 0,
        width: 0,
        paddingTop: '64px',
        transition: theme.transitions.create(['left', 'width'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    subSubSubDrawerOpen: {
        zIndex: 997,
        //backgroundColor: 'whitesmoke',
        left: drawerWidth*3,
        width: drawerWidth*1.03,
        paddingTop: '64px',
        boxShadow: '0px 0px 10px 0px rgba(0,0,0,0.5)',
        transition: theme.transitions.create(['left', 'width'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    subSubSubDrawerClose: {
        zIndex: 997,
        //backgroundColor: 'whitesmoke',
        left: 0,
        width: 0,
        paddingTop: '64px',
        transition: theme.transitions.create(['left', 'width'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    toolbar: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: theme.spacing(0, 1),
        // necessary for content to be below app bar
        ...theme.mixins.toolbar,
    },
    content: {
        width: '100%',
    },
    listItem: {
        paddingLeft: 24,
    },
    container: {
        display: 'flex',
        width: '100%',
        paddingTop: '64px',
    },
    previewDrawerOpen: {
        width: drawerWidth,
        whiteSpace: 'nowrap',
        position: 'relative',
    },
    previewDrawerClose: {
        whiteSpace: 'nowrap',
        position: 'relative',
        width: 0,
        overflow: 'hidden',
        [theme.breakpoints.up('sm')]: {
            width: drawerWidthClosed,
        },
    },
    fileInput: {
        '&:hover': {
            cursor: 'pointer',
          },
        display:'flex',
        alignItems: 'center',
        border: '1px solid #bdbdbd',
        borderRadius:'4px',
        margin:'8px',
        marginTop:'16px',
        //backgroundColor: '#f2f2f2',
    },
    fileInputAccept: {
        display:'flex',
        alignItems: 'center',
        border: '0px',
        borderRadius:'4px',
        margin:'8px',
        marginTop:'16px',
        backgroundColor: '#56a84b',
        color:'white',
    },
}));
const SideDrawer = (props) => {
    const classes = useStyles();
    const { loading, userInfo, roles, currentUser, userSettings, updateUserSettings } = useAuth();
    const {settings, types, subtypes, hrLoading } = useHRSettings();
    const theme = useTheme();
    
    const [alwaysOpen, setAlwaysOpen] = React.useState(!_.isUndefined(userSettings['sidebarAlwaysOpen']) ? userSettings['sidebarAlwaysOpen'] : false);
    const [open, setOpen] = React.useState(alwaysOpen);
    const [openSubDrawer, setOpenSubDrawer] = React.useState(false);
    const [openSubSubDrawer, setOpenSubSubDrawer] = React.useState(false);
    const [openSubSubSubDrawer, setOpenSubSubSubDrawer] = React.useState(false);
    const [subDrawer, setSubDrawer] = React.useState("");
    const [subSubDrawer, setSubSubDrawer] = React.useState("");
    const [subSubSubDrawer, setSubSubSubDrawer] = React.useState("");
    
    const [resumeBadge, setResumeBadge] = React.useState(0);
    const [unapprovedBadge, setUnapprovedBadge] = React.useState(0);
    const [unreadBadge, setUnreadBadge] = React.useState(0);

    const [shopExpanded, setShopExpanded] = useState(!_.isUndefined(userSettings['shopExpanded']) ? userSettings['shopExpanded'] : true);
    const [demoExpanded, setDemoExpanded] = useState(!_.isUndefined(userSettings['demoExpanded']) ? userSettings['demoExpanded']  : true);
    const [generalExpanded, setGeneralExpanded] = useState(!_.isUndefined(userSettings['generalExpanded']) ? userSettings['generalExpanded']  : true);
    const [adminExpanded, setAdminExpanded] = useState(!_.isUndefined(userSettings['adminExpanded']) ? userSettings['adminExpanded']  : true);
    
    const [today, setDate] = React.useState(new Date()); // Save the current date to be able to trigger an update

    const locale = 'en';
    const smallScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const day = today.toLocaleDateString(locale, { weekday: 'long' });
    const date = ` ${day}, ${today.toLocaleDateString(locale, { month: 'long' })} ${today.getDate()}\n\n`;
    const hour = today.getHours();
    const wish = `Good ${(hour < 12 && 'Morning') || (hour < 17 && 'Afternoon') || 'Evening'}${!loading ? userInfo?.firstName ? ' ' + userInfo?.firstName : '' : ''}! `;
    const time = today.toLocaleTimeString(locale, { hour: 'numeric', hour12: true, minute: 'numeric' });

    //sets color mode to users settings
    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => { props.setColorMode(!_.isUndefined(userSettings['colorMode']) ? userSettings['colorMode'] : 'light') }, [])
    
    React.useEffect(() => {
        
    }, [loading, roles, alwaysOpen, smallScreen, settings, hrLoading])// eslint-disable-line react-hooks/exhaustive-deps

    //badge for approved
    React.useEffect(() => {
        if(!loading)
            unapprovedBadgeHandler();
    }, [loading, roles]);// eslint-disable-line react-hooks/exhaustive-deps

    //badge for resumes
    React.useEffect(() => {
        const unsubscribe = firestore.collection('resumes').where('viewed', '==', false)
        .onSnapshot(querySnapshot => {
            setResumeBadge(querySnapshot.docs.length)
        });
        return () => { unsubscribe(); }
    }, []);

    //badge for messages
    React.useEffect(() => {
        const unsubscribe = firestore.collection('messages').where('read', '==', false).where('directionType', '==', 'MO')
        .onSnapshot(querySnapshot => {
            setUnreadBadge(querySnapshot.docs.length)
        });

        return () => { unsubscribe(); }
    }, []);

    //sets date
    React.useEffect(() => {
        const timer = setInterval(() => { // Creates an interval which will update the current data every minute
        // This will trigger a rerender every component that uses the useDate hook.
        setDate(new Date());
        }, 10 * 1000);
        return () => {
        clearInterval(timer); // Return a funtion to clear the timer so that it will stop being called on unmount
        }
    }, []);

    const logoutHandler = () => {
        auth.signOut().then(() => {
            props.history.push('/dashboard')
        });
    };

    const defaultClickHandler = () => {
        setOpen(alwaysOpen);
        setOpenSubDrawer(false);
        setOpenSubSubDrawer(false);
        setOpenSubSubSubDrawer(false);
        setSubDrawer("");
        setSubSubDrawer("");
        setSubSubSubDrawer("");
    }

    const subDrawerClickHandler = (component) => {
        createItems();
        if(_.isEqual(component, subDrawer)){
            setOpenSubDrawer(false);
            setOpenSubSubDrawer(false);
            setOpenSubSubSubDrawer(false);
            setSubDrawer("");
            setSubSubDrawer("");
            setSubSubSubDrawer("");
        }
        else{
            setOpen(true);
            setOpenSubDrawer(true);
            setOpenSubSubDrawer(false);
            setOpenSubSubSubDrawer(false);
            setSubDrawer(component);
            setSubSubDrawer("");
            setSubSubSubDrawer("");
       }
    }

    const subSubDrawerClickHandler = (component) => {
        if (_.isEqual(component, subSubDrawer)) {
            setOpenSubSubDrawer(false);
            setSubSubDrawer("");
            setOpenSubSubSubDrawer(false);
            setSubSubSubDrawer("");
        } else {
            setOpen(true);
            setOpenSubDrawer(true);
            setOpenSubSubDrawer(true);
            setSubSubDrawer(component);
        }
    }

    const subSubSubDrawerClickHandler = (component) => {
        if (_.isEqual(component, subSubSubDrawer)) {
            setOpenSubSubSubDrawer(false);
            setSubSubSubDrawer("");
        } else {
            setOpen(true);
            setOpenSubDrawer(true);
            setOpenSubSubDrawer(true);
            setOpenSubSubSubDrawer(true);
            setSubSubSubDrawer(component);
        }
    }

    const unapprovedBadgeHandler = () => {
        //grab all docs where viewed == false
        let amount = 0

        if(roles.includes('Approve Office Timesheets')) {
            firestore.collection('timesheets').where('approved', '==', false).where('timesheetID', '!=', 0).where('timesheetType', '==', 'Office').get()
            .then(querySnapshot => {
                const response = querySnapshot.docs.map((doc) => {
                    return {
                        ...doc.data(),
                        'docId': doc.id,
                    }
                })
                amount += response.length
                setUnapprovedBadge(amount)

            })
        }
        if(roles.includes('Approve Shop Timesheets')) {
            firestore.collection('timesheets').where('approved', '==', false).where('timesheetID', '!=', 0).where('timesheetType', '==', 'Shop').get()
            .then(querySnapshot => {
                const response = querySnapshot.docs.map((doc) => {
                    return {
                        ...doc.data(),
                        'docId': doc.id,
                    }
                })
                amount += response.length
                setUnapprovedBadge(amount)

            })
        }
        if(roles.includes('Approve Gravel Timesheets')) {

            firestore.collection('timesheets').where('approved', '==', false).where('timesheetID', '!=', 0).where('timesheetType', '==', 'Gravel').get()
            .then(querySnapshot => {
                const response = querySnapshot.docs.map((doc) => {
                    return {
                        ...doc.data(),
                        'docId': doc.id,
                    }
                })
                amount += response.length
                setUnapprovedBadge(amount)

            })
        }
        if(roles.includes('Approve Construction Timesheets')) {
            firestore.collection('timesheets').where('approved', '==', false).where('timesheetID', '!=', 0).where('timesheetType', '==', 'Construction').get()
            .then(querySnapshot => {
                const response = querySnapshot.docs.map((doc) => {
                    return {
                        ...doc.data(),
                        'docId': doc.id,
                    }
                })
                amount += response.length
                setUnapprovedBadge(amount)

            })
        }
        if(roles.includes('Approve Grain Timesheets')) {
            firestore.collection('timesheets').where('approved', '==', false).where('timesheetID', '!=', 0).where('timesheetType', '==', 'Grain').get()
            .then(querySnapshot => {
                const response = querySnapshot.docs.map((doc) => {
                    return {
                        ...doc.data(),
                        'docId': doc.id,
                    }
                })
                amount += response.length
                setUnapprovedBadge(amount)

            })
        }
        if(roles.includes('Approve Snow Timesheets')) {
            firestore.collection('timesheets').where('approved', '==', false).where('timesheetID', '!=', 0).where('timesheetType', '==', 'Snow').get()
            .then(querySnapshot => {
                const response = querySnapshot.docs.map((doc) => {
                    return {
                        ...doc.data(),
                        'docId': doc.id,
                    }
                })
                amount += response.length
                setUnapprovedBadge(amount)
            })
        }
        setUnapprovedBadge(amount)
    }

    const handleMode = (colorMode) => {
        if(props.colorMode === 'light'){
            props.setColorMode('dark')
            updateUserSettings('colorMode', 'dark')
            //window.localStorage.setItem("colorMode", JSON.stringify('dark'))
        } else {
            props.setColorMode('light')
            updateUserSettings('colorMode', 'light')
            //window.localStorage.setItem("colorMode", JSON.stringify('light'))
        }
    }

    //TO USER SETTINGS
    const handleSwitch = () => {
        if(!alwaysOpen){
            setOpen(open)
            //window.localStorage.setItem("sidebarAlwaysOpen", JSON.stringify(true))
            updateUserSettings('sidebarAlwaysOpen', true)
            setAlwaysOpen(true)
        } else {
            setOpen(false)
            setOpenSubDrawer(false)
            setOpenSubSubDrawer(false)
            setOpenSubSubSubDrawer(false)
            //window.localStorage.setItem("sidebarAlwaysOpen", JSON.stringify(false))
            updateUserSettings('sidebarAlwaysOpen', false)
            setAlwaysOpen(false)
        }
    };

    const handleExpandClick = (hook) => {
        switch(hook) {
            case "admin":
                if(adminExpanded){
                    setAdminExpanded(false);
                    //localStorage.setItem("adminExpanded", JSON.stringify(false))
                    updateUserSettings('adminExpanded', false)
                }
                else{
                    setAdminExpanded(true);
                    //localStorage.setItem("adminExpanded", JSON.stringify(true))
                    updateUserSettings('adminExpanded', true)
                }
              break;
            case "general":
                if(generalExpanded){
                    setGeneralExpanded(false);
                    //localStorage.setItem("generalExpanded", JSON.stringify(false))
                    updateUserSettings('generalExpanded', false)
                }
                else{
                    setGeneralExpanded(true);
                    //localStorage.setItem("generalExpanded", JSON.stringify(true))
                    updateUserSettings('generalExpanded', true)
                }
              break;
            case "shop":
                if(shopExpanded){
                    setShopExpanded(false);
                    //localStorage.setItem("shopExpanded", JSON.stringify(false))
                    updateUserSettings('shopExpanded', false)
                }
                else{
                    setShopExpanded(true);
                    //localStorage.setItem("shopExpanded", JSON.stringify(true))
                    updateUserSettings('shopExpanded', true)
                }
                break;
            case "demo":
                if(demoExpanded){
                    setDemoExpanded(false);
                    //localStorage.setItem("demoExpanded", JSON.stringify(false))
                    updateUserSettings('demoExpanded', false)
                }
                else{
                    setDemoExpanded(true);
                    //localStorage.setItem("demoExpanded", JSON.stringify(true))
                    updateUserSettings('demoExpanded', true)
                }
                break;
            default:
              console.warn("Expand Click Error")
          }
    }

    const defaultBugReportData = {
        open: false,
        text: null,
        files: [],
        saving: false,
        confirmation: false,
    }

    const [bugReportData, setBugReportData] = useState(defaultBugReportData)

    const handleFileUpload = (acceptedFiles) => {
        setBugReportData({...bugReportData, files: [...bugReportData.files, ...acceptedFiles]})
    }

    const handleSendBugReport = async () => {
        setBugReportData({...bugReportData, saving: true})

        const storageRef = storage.ref();
        let urls = [];
        let docRef = firestore.collection("bugReports").doc()

        for (const file of bugReportData.files) {
            const path = storageRef.child(`BugReports/${docRef.id}/${file.name}`);
            await path.put(file)
            const url = await path.getDownloadURL()
            urls.push(url)
        }

        let bugReportObj = {
            date: moment().valueOf(),
            description: bugReportData.text,
            docId: docRef.id,
            os: navigator.userAgent,
            photoUrls: urls,
            sandbox: process.env.NODE_ENV === 'development',
            user: currentUser.uid,
            version: 'N/A'
        }

        docRef.set(bugReportObj)
        .then(() => {
            setBugReportData({...bugReportData, confirmation: true, saving: true})
            setTimeout(() => {
                setBugReportData(defaultBugReportData);
            }, 1500)
        });
    }

    ///create items list from types
    let hrItems = []
    const createItems = () => {
        ///create list using types
       types.forEach(type => {
            let items = []
            subtypes.sort(
                (a, b) => a.order - b.order
            ).forEach(subtype => {
                if(subtype.parentValue === type.value && !subtype.hasParentFolder && !subtype.hidden && !subtype.hasSubfolders){
                    items.push({title: subtype.name, icon: null, link: subtype.override?subtype.overrideValue:`/dashboard/hr/${type.value}/${subtype.value}`, level: 2})
                } else if(subtype.parentValue === type.value && !subtype.hasParentFolder && !subtype.hidden && subtype.hasSubfolders){
                    let subItems = []
                    subtypes.sort(
                        (a, b) => a.order - b.order
                    ).forEach(subSubtype => {
                        if(subSubtype.parentFolder === subtype.value && !subSubtype.hidden){
                            subItems.push({title: subSubtype.name, icon: null, link: subSubtype.override?subSubtype.overrideValue:`/dashboard/hr/${type.value}/${subSubtype.value}`, level: 3})
                        }
                    })
                    items.push({title: subtype.name, icon: null, items: subItems, level: 2})
                }
            })
            hrItems.push({title: type.name, icon: null, items: items, level: 1})
        })

        //if GENERAL[3]'s items is empty add hrItems to it
        if(GENERAL[3].items.length === 0){
            GENERAL[3].items = hrItems
        }
    }


    let GENERAL = [
        { title: 'Home', icon: <HomeIcon />, link: '/dashboard', level: 1 },
        { title: 'Reports', icon: <AssessmentIcon />, link: '/dashboard/reporting' , roles: 'Access Reports Section', level: 1 },
        { title: 'Employees', icon: <PeopleIcon />, link: '/dashboard/employees', roles: 'Create|Update|Delete Employees', level: 1,
            items: [
                { title: 'Add Employee', icon: <AddIcon/>, link: '/dashboard/users/new', roles: 'Create|Update|Delete Employees', },
                { title: 'DIVIDER', icon: null, link: null, roles: 'Create|Update|Delete Employees', },
                { title: 'Employee Manager', icon: null, link: '/dashboard/users', roles: 'Create|Update|Delete Employees' },
                { title: 'Account Roles', icon: null, link: '/dashboard/users/roles' },
                { title: 'User Rates', icon: null, link: '/dashboard/users/rates' },
                { title: 'DIVIDER', icon: null, link: null },
                { title: 'Send Mass Email', icon: null, link: '/dashboard/users/memos' },
            ]
        },
        { title: 'Human Resources', icon: <GavelIcon />, link: '/dashboard/hr', level: 1,
            items: hrItems
        },
        { title: 'Equipment', icon: <LocalShippingIcon />, link: '/dashboard/equipment', roles: 'Create|Update|Delete Equipment', level: 1,},
        { title: 'Work Orders', icon: <BuildIcon />, link: '/dashboard/workorders', roles: 'Create|Update|Delete Work Orders', level: 1,},
        { title: 'Customers', icon: <PermContactCalendarIcon />, link: '/dashboard/customers', roles: 'Create|Update|Delete Customers',level: 1,},
        { title: 'Forms & More', icon: <ListAltIcon />, link: '/dashboard/forms', roles: 'Create|Update|Delete Forms', level: 1,
            items: [
                { title: 'Checklists', icon: null, link: '/dashboard/checklists' },
                { title: 'PDF Forms', icon: null, link: '/dashboard/pdfforms' },
            ] 
        },
        { title: 'Message Center',level: 1, icon: <Badge badgeContent={unreadBadge} color={"primary"} max={99}><PhoneAndroidIcon /></Badge>, 
            link: '/dashboard/store/conversations', roles: 'Manage Message Center',  },
        { title: 'Permits', icon: <AssignmentTurnedInIcon />, link: '/dashboard/permits', roles: 'Create|Update|Delete Permits',level: 1, },
        { title: 'Resumes',level: 1, icon: <Badge badgeContent = {resumeBadge} color ="error" max={99} ><ContactMailIcon /></Badge>, 
            link: '/dashboard/resumes', roles: 'Manage Resumes', 
        },
    ]

    const ADMIN = [
        { title: 'Time Approval',level: 1, icon: 
            <Badge badgeContent={unapprovedBadge} color={unapprovedBadge > 0 ? "error" : "primary"} max={999} showZero>
                <AssignmentTurnedInIcon />
            </Badge>, link: '/dashboard/approval/timesheets'
        },
        { title: 'Truck Schedule', icon: <CalendarTodayIcon />, link: '/dashboard/Scheduler/view', level: 1,},
        { title: 'Bug Reports', icon: <BugReportIcon />, link: '/dashboard/tech/bugreports', level: 1, },
    ]

    const SHOP = [
        { title: 'Online Orders', icon: <ShopIcon />, link: '/dashboard/store/orders', roles: 'Create|Update|Delete Orders',level: 1,
            items: [
                { title: 'Create New Order', icon: <AddIcon/>, link: '/dashboard/store/orders/newSquare', level: 2, },
                { title: 'DIVIDER', icon: null, link: null , level: 2,},
                { title: 'Order Reports', icon: null, link: '/dashboard/store/orders' , level: 2,},
                { title: 'Order Quotes', icon: null, link: '/dashboard/quotes', level: 2, },
            ]
         },
        { title: 'Products', icon: <LocalAtmIcon />, link: '/dashboard/store/products', roles: 'Create|Update|Delete Products',level: 1,
            items: [
                { title:'Product Manager', icon: null, link: '/dashboard/products', level: 2, },
                { title:'Category Manager', icon: null, link: '/dashboard/products/categories', level: 2, },
                { title:'Coupon Manager', icon: null, link: '/dashboard/coupons', level: 2, },
                { title:'Gift Card Manager', icon: null, link: '/dashboard/giftCards', level: 2, },
                { title:'Used Equipment', icon: null, link: '/dashboard/store/equipment', level: 2, },
            ]
         },
        { title: 'Grain/Deck Orders', icon: <GrainIcon />, link: '/dashboard/store/grainreports',level: 1,
            items: [
                { title:'Deck/Equipment', icon: null, link: {pathname: '/dashboard/grains', state: 'deck'}, level: 2, },
                { title:'GeeTee Orders', icon: null, link: {pathname: '/dashboard/grains', state: 'geetee'} , level: 2,},
                { title:'WTL Orders', icon: null, link: {pathname: '/dashboard/grains', state: 'wtl'}, level: 2, },
                { title: 'DIVIDER', icon: null, link: null },
                { title:'All', icon: null, link: {pathname: '/dashboard/grains', state: 'all'}, level: 2, },
            ]
         },
         { title: 'Tickets/Invoices', icon: <MenuBookIcon />, link: '/dashboard/store/grainreports',level: 1,
            items: [
                { title:'Ticket Entry', icon: null, link: '/dashboard/tickets', level: 2, },
                { title:'Rates', icon: null, link: '/dashboard/rates', level: 2, },
                { title:'Invoicing', icon: null, link: '/dashboard/invoicing', level: 2, },
                { title:'Payroll', icon: null, link: '/dashboard/payroll', level: 2, },
            ]
         },
        { title: 'Settings', icon: <SettingsApplicationsIcon />, link: '/dashboard/store/settings', roles: 'Manage Shop Settings',level: 1,
            items: [
                { title:'Business Settings', icon: null, link: '/dashboard/store/businessSettings', level: 2, },
                { title:'Shop Settings', icon: null, link: '/dashboard/store/shopSettings', level: 2, },
                { title:'Zone Settings', icon: null, link: '/dashboard/store/zoneSettings', level: 2, },
                { title:'HR Folder Settings', icon: null, link: '/dashboard/hr/settings', level: 2, },
            ]
         },
    ]

    //const TECH = [
    //    { title: 'Tech', icon: <AssignmentTurnedInIcon />, link: '/dashboard/Tech', level: 1,},
    //]

    return (
        <React.Fragment>
            {/* bug report modal */}
            <Modal
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    outline: 0,
                }}
                open={bugReportData.open}
                onClose={() => { setBugReportData({...bugReportData, open: false}) }}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}>
                <Fade in={bugReportData.open}>
                    <Paper style={{ padding: '16px', minWidth: '400px', maxWidth:'700px' }}>
                        <Grid container direction='column' spacing={2}>
                            <Grid item>
                                <Typography align='center'>
                                    Please describe the problem you are having:
                                </Typography>
                            </Grid>

                            <Grid item>
                                <TextField
                                    fullWidth
                                    placeholder='Report Description'
                                    multiline
                                    value={bugReportData.text}
                                    onChange={(e)=> {setBugReportData({...bugReportData, text: e.target.value})}}
                                />

                            </Grid>

                            <Grid container  style={{marginBottom: '20px'}}>
                                <Grid item sm={12} className={props.msdsPDF?.name ? classes.fileInputAccept : classes.fileInput}>

                                     <Dropzone onDrop={handleFileUpload}>
                                        {({ getRootProps, getInputProps }) => (
                                            <Grid container item xs={12}justifyContent='center' {...getRootProps()} >
                                                <input {...getInputProps()} style={{ display: 'none' }} />
                        
                                                <PublishIcon style={{color:'grey', marginRight:'20px'}}/>

                                                <Typography variant='h6'>
                                                    {"Upload File(s)"}
                                                </Typography>
                                            </Grid>
                                        )}
                                    </Dropzone>
                                </Grid>
                                
                                {bugReportData.files.map((file, index) => {
                                        return (
                                            <Grid container justifyContent='center' key={index} style={{marginBottom: '5px'}}>
                                                <Chip
                                                    label={file.name}
                                                    style={{backgroundColor: '#39E75F'}}
                                                    onDelete={() => { 
                                                        let container = _.cloneDeep(bugReportData)
                                                        container.files.splice(index, 1)
                                                        setBugReportData(container)
                                                     }}
                                                />
                                            </Grid>
                                        )
                                    })}
                            </Grid>

                            <Grid container justifyContent='flex-end'>
                                <Grid item>
                                    <Button
                                        startIcon={<CloseIcon/>} 
                                        variant='contained'
                                        disabled={bugReportData.saving || bugReportData.confirmation}
                                        style={bugReportData.saving ? {marginRight: '10px'} : {color:'white',backgroundColor:'red', marginRight: '10px'}}
                                        onClick={(e) => { setBugReportData(defaultBugReportData); }}
                                    >
                                        Cancel
                                    </Button>

                                    <Button
                                        startIcon={<CheckIcon/>}
                                        variant='contained'
                                        disabled={bugReportData.saving || bugReportData.confirmation}
                                        style={bugReportData.confirmation ? {backgroundColor: 'green'} : null}
                                        onClick={(e) => {
                                            handleSendBugReport();
                                        }}
                                    >
                                        {bugReportData.confirmation ? "Sent!"  : "Send"}
                                    </Button>
                                </Grid>
                            </Grid>

                        </Grid>
                    </Paper>
                </Fade>
            </Modal>

            <div style={{ width: '100%', maxWidth: '73px' }} />

            <CssBaseline />

            <Box sx={{ flexGrow: 1 }}>
                <AppBar
                    position="fixed"
                    className={clsx(classes.appBar, {
                        [classes.appBarShift]: open,
                    })}
                >
                    <Toolbar>
                        <IconButton
                            color="inherit"
                            aria-label="open drawer"
                            onClick={() => { setOpen(!open); setOpenSubDrawer(false); setOpenSubSubDrawer(false); setOpenSubSubSubDrawer(false); }}
                            edge="start"
                            className={classes.menuButton}

                        >
                            {open ? <ChevronLeftIcon /> : <MenuIcon />}
                        </IconButton>

                        {!smallScreen ?
                        <Grid container spacing={4}>
                            <Grid container item spacing={2}>
                                <Grid container item spacing={3}>
                                    <Typography noWrap variant="h6"  component="div" sx={{ flexGrow: 2 }} style={{flexGrow: '2'}}>
                                        Gee Tee Dashboard {process.env.NODE_ENV === 'development' && "(DEV MODE)"}
                                    </Typography>

                                </Grid>
                                <Grid container item spacing={3}>
                                    <Typography noWrap style={{paddingRight:'15px'}}>{time}</Typography>
                                    <Typography>{date}</Typography>
                                </Grid>
                                <Grid container item spacing={3}>
                                    <Typography>{wish}</Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                        :
                        <Grid container spacing={2}>
                            <Grid container item spacing={2}>
                                <Grid container item spacing={3}>
                                    <Typography noWrap  component="div" sx={{ flexGrow: 2 }} style={{flexGrow: '2'}}>
                                        Gee Tee {process.env.NODE_ENV === 'development' && "(DEV)"}
                                    </Typography>

                                </Grid>
                                <Grid container item spacing={3}>
                                    <Typography noWrap style={{paddingRight:'10px'}}><small>{time}</small></Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                        }

                        <Grid container justifyContent='flex-end' spacing={2}>
                        {!smallScreen ?  <Grid item>
                                <Grid container>
                                    <Tooltip title={"Report a problem"}>
                                        <IconButton sx={{ ml: 1 }} onClick={() => setBugReportData({...bugReportData, open: true})} color="secondary">
                                            {<BugReportIcon />}
                                        </IconButton>
                                    </Tooltip>
                                </Grid>
                            </Grid>:null}

                            <Grid item>
                                <Tooltip title={props.colorMode === 'dark' ? "Enable Light Mode" : "Enable Dark Mode"}>
                                    <IconButton sx={{ ml: 1 }} onClick={() => handleMode(props.colorMode)} color="secondary">
                                        {props.colorMode === 'dark' ? <Brightness7Icon /> : <Brightness4Icon />}
                                    </IconButton>
                                </Tooltip>
                            </Grid>

                            <Grid item>
                                {!smallScreen ?
                                    <FormControlLabel 
                                        control={
                                            <Switch 
                                                color='secondary'
                                                checked={alwaysOpen}
                                                onChange={() => handleSwitch()}
                                            />
                                        } 
                                        label={<Typography style={{fontSize: '14px'}}>{alwaysOpen === true ? 'Sidebar Always Open' : 'Sidebar Always Closed'}</Typography>} 
                                        style={{marginTop: '5px'}}
                                    />
                                : null
                                }
                            </Grid>

                        </Grid>


                    </Toolbar>
                </AppBar>
            </Box>

            <div className={classes.container}>
                <Drawer
                    open
                    variant="permanent"
                    style={{zIndex: '1000'}}
                    className={open ?
                        props.preview ? classes.previewDrawerOpen : classes.drawerOpen
                        : props.preview ? classes.previewDrawerClose : classes.drawerClose }

                    classes={{ paper: open ?
                        props.preview ? classes.previewDrawerOpen : classes.drawerOpen
                        : props.preview ? classes.previewDrawerClose : classes.drawerClose }}
                >
                { ( roles.includes("Approve Office Timesheets") || roles.includes("Approve Shop Timesheets") || roles.includes("Approve Snow Timesheets")
                || roles.includes("Approve Construction Timesheets") || roles.includes("Approve Gravel Timesheets") || roles.includes("Approve Grain Timesheets" ))
                ?
                <React.Fragment>
                    <List
                    subheader={
                    <ListSubheader style={{cursor: 'pointer'}}
                        onClick={() => { handleExpandClick("admin") }}
                    >
                        Admin
                        <IconButton
                            className={clsx(classes.expand, { [classes.expandOpen]: adminExpanded, })}
                            onClick={ () => {handleExpandClick("admin")}}
                            aria-expanded={adminExpanded}
                            aria-label="show more"
                        >
                            <ExpandMoreIcon />
                        </IconButton>
                        </ListSubheader>}
                    >
                    <Collapse in={adminExpanded} timeout="auto">
                        {
                            ADMIN.map((item, index) => {
                                return (
                                    roles.includes(item?.roles) || _.isUndefined(item.roles) ?
                                    <ListItem
                                        onClick={!item.items ? () => defaultClickHandler():subDrawerClickHandler(<SubDrawer level={item.level} items={item?.items}/>)}
                                        className={classes.listItem}
                                        component={Link}
                                        to={item.link}
                                        button
                                        key={index}
                                    >
                                        <ListItemIcon title={item.title}>{item.icon}</ListItemIcon>
                                        <ListItemText primary={item.title} />
                                    </ListItem>:null
                                )
                            })
                        }     
                    </Collapse>

                    </List>

                    <Divider /></React.Fragment>
                    : null}

                    <List
                        subheader={
                        <ListSubheader
                            style={{cursor: 'pointer'}}
                            onClick={() => { handleExpandClick("general")}}
                        >
                            General
                            <IconButton
                                className={clsx(classes.expand, { [classes.expandOpen]: generalExpanded, })}
                                onClick={ () => {handleExpandClick("general")}}
                                aria-expanded={generalExpanded}
                                aria-label="show more"
                            >
                            <ExpandMoreIcon />
                            </IconButton>
                        </ListSubheader>}
                    >
                    <Collapse in={generalExpanded} timeout="auto">
                        {
                            GENERAL.map((item, index) => {
                                return (
                                    roles.includes(item?.roles) || _.isUndefined(item.roles) ?
                                    item.title === 'DIVIDER' ? 
                                    <Divider/>
                                    :
                                    <ListItem
                                        onClick={() => !item.items ? defaultClickHandler():subDrawerClickHandler(<SubDrawer level={item.level} items={item?.items}/>)}
                                        className={classes.listItem}
                                        component={!item.items && Link}
                                        to={item.link}
                                        button
                                        key={index}
                                    >
                                        <ListItemIcon title={item.title}>{item.icon}</ListItemIcon>
                                        <ListItemText primary={item.title} />
                                    </ListItem>:null
                                )
                            })
                        }     

                    </Collapse>
                    </List>

                    <Divider />

                    {(roles.includes("Create|Update|Delete Orders") || roles.includes("Create|Update|Delete Products") || roles.includes("Manage Shop Settings"))
                    ?
                    <List
                        subheader={
                            <ListSubheader
                                style={{cursor: 'pointer'}}
                                onClick={() => { handleExpandClick("shop")}}
                            >
                                Shop
                                {open ?
                                <IconButton
                                    className={clsx(classes.expand, {[classes.expandOpen]: shopExpanded, })}
                                    onClick={ () => {handleExpandClick("shop")}}
                                    aria-expanded={shopExpanded}
                                    aria-label="show more"
                                >
                                    <ExpandMoreIcon />
                                </IconButton>
                                : null}
                            </ListSubheader>
                        }
                    >

                    <Collapse in={shopExpanded} timeout="auto">
                        {
                            SHOP.map((item, index) => {
                                return (
                                    roles.includes(item?.roles) || _.isUndefined(item.roles) ?
                                    item.title === 'DIVIDER' ? 
                                    <Divider/>
                                    :
                                    <ListItem
                                        onClick={() => !item.items ? defaultClickHandler():subDrawerClickHandler(<SubDrawer level={item.level} items={item?.items}/>)}
                                        className={classes.listItem}
                                        component={!item.items && Link}
                                        to={item.link}
                                        button
                                        key={index}
                                    >
                                        <ListItemIcon title={item.title}>{item.icon}</ListItemIcon>
                                        <ListItemText primary={item.title} />
                                    </ListItem>:null
                                )
                            })
                        }     
                        </Collapse>
                    </List>
                    : null }

                    <Divider />

                    <List>{currentUser.email === "derekmilleryoung@gmail.com" || currentUser.email === "landryjrdn@gmail.com" ?
                        <ListItem
                            onClick={() => defaultClickHandler()}
                            className={classes.listItem}
                            component={Link}
                            to={'/dashboard/Tech'}
                            button
                        >
                            <ListItemIcon title='Tech'> <ImportantDevicesIcon /></ListItemIcon>
                            <ListItemText primary={'Tech'} />
                        </ListItem> : null}
                    </List>

                    <List>
                        <ListItem
                            className={classes.listItem}
                            onClick={logoutHandler}
                            button
                        >
                            <ListItemIcon title='Log Out'> <ExitToAppIcon /></ListItemIcon>
                            <ListItemText primary={'Log Out'} />
                        </ListItem>
                    </List>
                </Drawer>

                
                <Drawer
                    open
                    variant="permanent"
                    classes={{ paper: openSubDrawer ? classes.subDrawerOpen : classes.subDrawerClose }}>
                    {subDrawer !== "" && React.cloneElement(subDrawer, { onClick: defaultClickHandler, openSubSubDrawer: subSubDrawerClickHandler })}
                </Drawer>
                <Drawer
                    open
                    variant="permanent"
                    classes={{ paper: openSubSubDrawer ? classes.subSubDrawerOpen : classes.subSubDrawerClose }}>
                    {subSubDrawer !== "" && React.cloneElement(subSubDrawer, { onClick: defaultClickHandler, openSubSubDrawer: subSubSubDrawerClickHandler })}
                </Drawer>
                <Drawer
                    open
                    variant="permanent"
                    classes={{ paper: openSubSubSubDrawer ? classes.subSubSubDrawerOpen : classes.subSubSubDrawerClose }}>
                    {subSubSubDrawer !== "" && React.cloneElement(subSubSubDrawer, { onClick: defaultClickHandler })}
                </Drawer>
                <main className={classes.content}>
                    {props.children}
                </main>
            </div>

        </React.Fragment >
    );
}
export default withRouter(SideDrawer);