import React, { useState, useEffect, useCallback } from 'react';
import { withRouter } from 'react-router'
import { firestore } from '../../../firebase/firebase'
import PDFCard from '../../components/PDFs/PDFCard'
import { makeStyles, Grid } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import moment from 'moment';

const useStyles = makeStyles((theme) => ({
    content: {
        display: 'flex', 
        width:'100%', 
        padding: '32px', 
        justifyContent:'center',
        marginTop: '40px',
    },
}));

const PDF = (props) =>  {

    const classes = useStyles();

    const [safetyData, setSafetyData] = useState({});
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState("");
    const [saving, setSaving] = useState(false);
    const [saveState, setSaveState] = useState({});

    const updateSafetyData = useCallback((e) => {
            setSafetyData(e)
        },
        [],
    );
    const handleCheckboxChange = (e) => {
        setSafetyData({
            ...safetyData,
            [e.target.name]: e.target.checked
        })
    }

    const saveSafetyData = () => {
        setSaving(true);
        let safetyDataContainer = {...safetyData, dateUpdated: moment().valueOf()}
        firestore.collection('pdfforms').doc(safetyData.docId).update(safetyDataContainer)
            .then(() => {
                setSaveState(safetyData);
                setSaving(false)
            }).catch((e) => setError(e));
    };

    const cancelSave = () => {
        setSafetyData(saveState);
    };

    useEffect(() => {

        let path = new URLSearchParams(props.location.search).get("docId");

        firestore.collection('pdfforms').where('__name__', "==", path).get()
        .then(querySnapshot => {
            const safetyResponse = querySnapshot.docs.map((doc) => {
                return {
                    ...doc.data(),
                    'docId': doc.id,
                    'emails': doc.data().emails ? doc.data().emails : [],
                }
            })
            setSafetyData(safetyResponse[0]);
            setSaveState(safetyResponse[0]);
            setLoading(false);
        })
        .catch(e => {
            setError("PDF Form not found.");
            setLoading(false);
            console.log(e.message);
        })
    }, [props.location.search]);
        
    return (
        <div>
            {loading 
            ? 
            <Grid className={classes.content}>
                <Skeleton variant='rect' width={'52vw'} height={'35vh'}></Skeleton>
            </Grid>
            : 
            <React.Fragment>
                <PDFCard 
                    error={error} handleCheckboxChange={handleCheckboxChange}
                    safetyData={safetyData} saveState={saveState} 
                    cancelSave={cancelSave} saveSafetyData={saveSafetyData} 
                    updateSafetyData={updateSafetyData} saving={saving}
                    setSaveState={setSaveState}
                />
            </React.Fragment>
            }    
        </div>
    )
}

export default withRouter(PDF);