import React, { memo } from 'react';
import {  makeStyles, } from '@material-ui/core';
import _ from 'lodash';

import ErrorableTextfield from '../CellComponents/ErrorableTextfield';

const useStyles = makeStyles((theme) => ({
    columnTitles: {
        fontSize: '14px',
    },
    rowContent: {
        width: '90%',
        fontSize: '14px',
        fontWeight: 600,
        // padding: '5px',
        // margin: '5px'
    },
}));

const DataGridTableErrorableTextfield = ({handleCellUpdateCallback, cellClickCallback, docId, field, uniqueTicketNumber, uid, key, id, data}) => {
    const classes = useStyles();

    let valueContainer = data;

    // const handleCheckUnique = (value, field, data) => {
    //     if( value === '' || _.isUndefined(value)){ return false }
        
    //     let confimation = false;
    //     let dataContainer = data.filter(x=> x.docId !== docId )
    //     dataContainer.forEach((item) => { if(item[field] === value){ confimation = true; };})
    //     return confimation;
    // }

    // const [error, setError] = useState(handleCheckUnique(data, field, uidCheckData))

    // useEffect(() => {
    //     setError(handleCheckUnique(data, field, uidCheckData))
    // }, [data])  // eslint-disable-line react-hooks/exhaustive-deps

    return(
        <>
            <ErrorableTextfield
                inputProps={{ className: classes.rowContent }}
                className={classes.rowContent}
                id={id}
                error={!uniqueTicketNumber}
                value={valueContainer || ''}
                field={field}

                // onClick={() => {
                //     cellClickCallback(`${docId} ${field}`) 
                // }}
                onChange={(e) => { 
                    let valueContainer2 = !_.isUndefined(e.target.value) ? e.target.value : '';
                    valueContainer = valueContainer2
                    handleCellUpdateCallback(valueContainer2, field, docId)
                    //setError(handleCheckUnique(e.target.value, field, uidCheckData))
                    
                    //valueContainer = e.target.value
                    //debouncedHandleCellChangeCallback(e.target.value)
                }}
            />
        </>
    )
}

export default memo(DataGridTableErrorableTextfield)