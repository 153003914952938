import { Grid, makeStyles, Paper, Typography } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    paper: {
        padding: 24,

    },
    root: {
        display: 'flex',
        width: '100%',
        justifyContent: 'center',
    },
    grid: {
        width: '100%',
        maxWidth: '960px',
    }
}));

const MetaTagCard = (props) => {

    const classes = useStyles();

    return <div className={classes.root}>
        <Grid container spacing={2} className={classes.grid}>
            <Grid item xs={12}>
                <Paper className={classes.paper}>
                    <Typography variant='h6'>Search Optimization</Typography>
                    <Typography variant='body1'>Adding meta tags to a product makes it easier to search for a product on the store.</Typography>
                    <Typography variant='body1'>For example, adding "Rock" to limestone's meta tags allows searches for rock to also show limestone.</Typography>
                </Paper>
            </Grid>
            <Grid item xs={12}>
                <Paper className={classes.paper}>

                </Paper>
            </Grid>
        </Grid>
    </div>
}

export default MetaTagCard;