import React from 'react';
import { makeStyles, ButtonGroup } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import DatePicker from '../../UI/DatePicker/DatePicker';
import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
import ClearIcon from '@material-ui/icons/Clear';

const useStyles = makeStyles((theme) => ({
    btnGroup: {
        width: '100%',
    },

    filterItem: {
        marginTop: '4px',
        marginBottom: '4px',
    },

    search: {
        display: 'flex',
        boxShadow: 'inset 0px 0px 2px 2px rgba(207,207,207,0.49)',
        //backgroundColor: '#fafafa',
        borderRadius: '4px',
        alignItems: 'center',
        justifyContent: 'center',
        padding: theme.spacing(2),
    },
}));

const OrderOptions = (props) => {

    const classes = useStyles();
    let smallScreen = props.smallScreen;

    return (
        <Grid container spacing={2}>
            <Grid item sm={12}>
                <Grid container className={classes.search}>
                    <Grid item sm={12}>
                        <TextField
                            label="Customer"
                            size="small"
                            value={props.customerSearch}
                            onChange={(event)=> {
                                props.setCustomerSearch(event.target.value)
                                sessionStorage.setItem("orderCustomerSearch", JSON.stringify(event.target.value))
                            }}
                            name="customer"
                            InputProps={props.customerSearch.length > 0 ? {
                                endAdornment: <IconButton onClick={(event) => {props.setCustomerSearch(''); sessionStorage.removeItem("orderCustomerSearch");}}><ClearIcon /></IconButton>
                            } : null} />
                    </Grid>
                </Grid>
            </Grid>

            <Grid item sm={12}>
                <Grid container className={classes.search}>
                    <Grid item sm={12}>
                        <TextField
                            label="Order #"
                            size="small"
                            value={props.orderNumSearch}
                            onChange={(event)=> {
                                props.setOrderNumSearch(event.target.value)
                                sessionStorage.setItem("orderOrderNumSearch", JSON.stringify(event.target.value))
                            }}
                            name="orderNumber"
                            InputProps={props.orderNumSearch.length > 0 ? {
                                endAdornment: <IconButton onClick={(event) => {props.setOrderNumSearch(''); sessionStorage.removeItem("orderOrderNumSearch")}}><ClearIcon /></IconButton>
                            } : null} />
                    </Grid>
                    <Grid item sm={12}>
                        <TextField
                            label="Unit #"
                            size="small"
                            value={props.unitNumSearch}
                            onChange={(event)=> {
                                props.setUnitNumSearch(event.target.value)
                                sessionStorage.setItem("orderUnitNumSearch", JSON.stringify(event.target.value))
                            }}
                            name="unitNumber"
                            InputProps={props.unitNumSearch.length > 0 ? {
                                endAdornment: <IconButton onClick={(event) => {props.setUnitNumSearch(''); sessionStorage.removeItem("orderUnitNumSearch")}}><ClearIcon /></IconButton>
                            } : null} />
                    </Grid>
                </Grid>
            </Grid>

            <Grid item sm={12}>
                <Grid container className={classes.search}>
                    <Grid item sm={12}>
                        <TextField
                            label="Delivery Location"
                            size="small"
                            value={props.deliveryLocationSearch}
                            onChange={(event)=> {
                                props.setDeliveryLocationSearch(event.target.value)
                                sessionStorage.setItem("orderDeliveryLocationSearch", JSON.stringify(event.target.value))
                            }}
                            name="deliveryLocation"
                            InputProps={props.customerSearch.length > 0 ? {
                                endAdornment: <IconButton onClick={(event) => {props.setDeliveryLocationSearch(''); sessionStorage.removeItem("orderDeliveryLocationSearch")}}><ClearIcon /></IconButton>
                            } : null} />
                    </Grid>
                </Grid>
            </Grid>


            <Grid item sm={12}>
                <Grid container className={classes.search}>

                    <Grid item sm={12} className={classes.filterItem}>
                        <DatePicker
                            label="Start Date"
                            value={props.startDate}
                            onChange={props.startDateHandler}
                        />
                    </Grid>

                    <Grid item sm={12} className={classes.filterItem}>
                        <DatePicker
                            label="End Date"
                            value={props.endDate}
                            onChange={props.endDateHandler}
                        />
                    </Grid>

                    <Typography style={{ marginBottom: '8px', marginTop: '8px' }}>Search Date Type</Typography>

                    <ButtonGroup fullWidth >
                        <Button value="submit" color={props.dateType === "submit" ? "primary" : "secondary"}
                            variant={props.dateType === "submit" ? "contained" : "outlined"}
                            onClick={() => props.dateTypeHandler("submit")}>Submitted</Button>
                        <Button value="prefer" color={props.dateType === "prefer" ? "primary" : "secondary"}
                            variant={props.dateType === "prefer" ? "contained" : "outlined"}
                            onClick={() => props.dateTypeHandler("prefer")}>Preferred</Button>
                    </ButtonGroup>
                </Grid>
            </Grid>

            <Grid item sm={12}>
                {(props.errorMsg && <Typography>{props.errorMsg}</Typography>)}
            </Grid>
            <Grid item sm={12} className={classes.btnGroup}>
                <Button variant="contained" fullWidth disabled={props.loading} onClick={props.generateHandler} >Generate Orders</Button>
            </Grid>
            {!smallScreen ?<Grid item sm={12} className={classes.btnGroup}>
                <Button variant="contained" fullWidth disabled={props.loading} onClick={() => props.openMapModal()} >View Delivery Map</Button>
            </Grid>:null}

        </Grid>
    )
}

export default OrderOptions;