import React, { useState, useCallback } from 'react';
import FormBuilder from '../../components/Forms/FormBuilder'
import { firestore } from '../../../firebase/firebase'
import { Skeleton } from '@material-ui/lab';

const FormNew = (props) =>  {

    const initialForm = {
        sections: [],
    }

    const [form, setForm] = useState(initialForm);
    const [title, setTitle] = useState("");
    const [submitting, setSubmitting] = useState(false);
    const [error, setError] = useState(false);

    const updateForm = useCallback(
        (e) => {
            setForm(e);
        },
        []
    );

    const updateTitle = (e) => {
        setTitle(e);
    };

    const handleSubmit = (event) => {
        createForm();
    }

    const createForm = () => {

        setSubmitting(true);

        let newForm = {
            dateCreated: Date.now()/1000,
            archived: false,
            version: {1: form},
            title: title,
        };

        firestore.collection('formTemplates').add(newForm)
        .then(() => {
            setForm ({
                title: '',
                sections: [],
            });
            setSubmitting(false);
            setError('');
            window.location.reload(false);
        })
        .catch((e) => { 
            setSubmitting(false);
            console.log(e)
            setError("Unable to create form, please try again");
        });
    }
        
    return (
        submitting 
        ? <Skeleton variant='rect' width={'100vw'} height={'100vh'}/>
        : <FormBuilder
        form={form} title={title}
        error={error}
        submitting={submitting} submit={handleSubmit} 
        updateForm={updateForm} updateTitle={updateTitle}/>
    )
}

export default FormNew;