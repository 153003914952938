import React, { useState, useEffect } from 'react';
import { Grid, makeStyles,  } from '@material-ui/core';
import { withRouter } from 'react-router'
import { firestore } from '../../../firebase/firebase'
import { TableIcons } from '../../../constants/tableIcons';
import MaterialTable from '@material-table/core';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import NotInterestedIcon from '@material-ui/icons/NotInterested';
import _ from 'lodash';
import Skeleton from '@material-ui/lab/Skeleton';
import moment from 'moment';


const useStyles = makeStyles((theme) => ({

    card: {
      padding: theme.spacing(4),
      width: '100%',
      height: '100%',
    },
  
    content: {
      display: 'flex',
      justifyContent: 'center',
      width: '100%',
    },
  
    grid: {
      marginTop: theme.spacing(2),
      maxWidth: '1200px',
    },
  
    table: {
      width: '100%',
      maxWidth: '900px',
      margin: '16px',
      display: 'flex',
    },
  
    tile: {
      width: '300px',
      height: '300px',
    },
  
    tileContent: {
      display: 'flex',
      justifyContent: 'space-evenly',
      alignItems: 'center',
      flexDirection: 'column',
      width: '100%',
      height: '100%',
      padding: theme.spacing(4),
    },
  
    tileIcon: {
      color: 'rgba(0, 0, 0, 0.54)',
      width: '100px',
      height: '100px',
    },
  
    tileText: {
      fontSize: '1.10rem',
    },
  }));


const ResumeHome = (props) => {

    const [resumeData, setResumeData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [searchBarValue, setSearchBarValue] = useState("resumeSearchBarValue" in sessionStorage ? JSON.parse(sessionStorage.getItem("resumeSearchBarValue")) : null)
    const [page, setPage] = useState("resumePageValue" in sessionStorage ? JSON.parse(sessionStorage.getItem("resumePageValue")) : 0)

    const classes = useStyles();

    useEffect(() => {
      const promises = [];
        //creating the query
        firestore.collection('resumes').get()
        .then(querySnapshot => {
          querySnapshot.forEach(doc => {
            if(_.isUndefined(doc.data().viewed)){
                promises.push(doc.ref.update({
                    viewed: true,
                }));
            }
            
        });
        return Promise.all(promises).then(() =>{
        //setting query response to response var
        var response = querySnapshot.docs.map((doc) => {
            return {
                //retireving all previous doc data (...) and all current doc data (.data())
                ...doc.data(),
                //adding our own data to the doc
                'docId': doc.id,
            }
        })
        setResumeData(response);
        }).then(() => {
          setLoading(false);
        })}
        )}, []);

        const handleOnSearchChange = (value) => {
          setSearchBarValue(value);  
          sessionStorage.setItem("resumeSearchBarValue", JSON.stringify(value));
      
          setPage(0);  
          sessionStorage.setItem("resumePageValue", JSON.stringify(0));
        }
      
        const handleOnPageChange = (pageNum) => {
          setPage(pageNum);  
          sessionStorage.setItem("resumePageValue", JSON.stringify(pageNum));
        }
    
        const titleCase = (str) => {
          str = str.toLowerCase().split(' ');
          for (var i = 0; i < str.length; i++) {
            str[i] = str[i].charAt(0).toUpperCase() + str[i].slice(1); 
          }
          return str.join(' ');
        }

return (
    <div className={classes.content}>
        <Grid container spacing={2} className={classes.table}>
            <Grid item xs={12} style={{ width: '100%' }}>
              {!loading 
              ?
              <MaterialTable
                  style={{}}
                  data={resumeData}
                  title={"Resumes"}
                  columns={[
                      { title: 'Name', field: 'name' , render: rowData => {
                        return rowData.name ? titleCase(rowData.name) : null
                    }},
                      { title: 'Email', field: 'email' },
                      { title: 'Position', field: 'position'},
                      { title: 'Date Uploaded', field: 'dateCreated',
                          render: rowData => 
                            rowData.dateCreated !== undefined ? moment(rowData.dateCreated).format('YYYY-MM-DD') : "NA"
                      },
                      { title: 'Viewed', width:'0%', defaultSort:'asc',
                      field: 'viewed', render: rowData => rowData.viewed === false ? <NotInterestedIcon style={{color:'red'}}/> : <CheckCircleOutlineIcon style={{color:'green'}}/>},
                      ]}
                  icons={TableIcons}
                  onSearchChange={ (value) => {handleOnSearchChange(value) }}
                  onPageChange={ (pageNum) => {handleOnPageChange(pageNum) }}
                  actions={[
                      {
                          icon: TableIcons.OpenInNew,
                          tooltip: 'View Resume Info',
                          onClick: (event, rowData) => props.history.push({
                              pathname: '/dashboard/resumes/resume',
                              search: "?" + new URLSearchParams({ docId: rowData.docId }).toString(),
                          })
                      }
                  ]}

                  options={{
                      toolbar: true,
                      paging: true,
                      initialPage: page,
                      pageSizeOptions: false,
                      actionsColumnIndex: -1,
                      search: true,
                      pageSize: 10,
                      emptyRowsWhenPaging: false,
                      thirdSortClick: false,
                      searchText: searchBarValue,
                      rowStyle: {
                          whiteSpace: 'pre-wrap',
                      },
                  }}

              />
              :
              <Grid>
                <Skeleton variant='rect' width={'46vw'} height={'86vh'} />
              </Grid>
              }

            </Grid>
        </Grid>
    </div>
    )
}

export default withRouter(ResumeHome);