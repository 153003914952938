import { React, useEffect, useState } from 'react';
import { Grid, makeStyles } from '@material-ui/core';
import { withRouter } from 'react-router';
import MaterialTable from '@material-table/core';
import { firestore } from '../../../firebase/firebase';
import { TableIcons } from '../../../constants/tableIcons';

const useStyles = makeStyles((theme) => ({

  card: {
    padding: theme.spacing(4),
    width: '100%',
    height: '100%',
  },
  content: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    padding: '32px',
  },
  grid: {
    marginTop: theme.spacing(2),
    maxWidth: '1200px',
  },
  table: {
    display: 'flex',
    justifyContent: 'center',
  },
  btn: {
    marginBottom: '20px',
    marginTop: '20px',
},
}));

const QuotesHome = (props) => {
    const classes = useStyles();

    const [quoteData, setQuoteData] = useState([])
    const [loading, setLoading] = useState(true)
    const [searchBarValue, setSearchBarValue] = useState("quotesSearchBarValue" in sessionStorage ? JSON.parse(sessionStorage.getItem("quotesSearchBarValue")) : null)
    const [page, setPage] = useState("quotesPageValue" in sessionStorage ? JSON.parse(sessionStorage.getItem("quotesPageValue")) : 0)

    //gets quotes data
    useEffect(() => {
    firestore.collection('orderDrafts').get()
    .then(querySnapshot => {
        const response = querySnapshot.docs.map((doc) => {
            return {
                ...doc.data(),
                'docId': doc.id,
            }
        })
        setQuoteData(response)
        setLoading(false)
    })
    }, [])

    const handleOnSearchChange = (value) => {
        setSearchBarValue(value);  
        sessionStorage.setItem("quotesSearchBarValue", JSON.stringify(value));
        setPage(0);  
        sessionStorage.setItem("quotesPageValue", JSON.stringify(0));
    }

    const handleOnPageChange = (pageNum) => {
        setPage(pageNum);  
        sessionStorage.setItem("quotesPageValue", JSON.stringify(pageNum));
    }

    return (
        <div className={classes.content}>
            <Grid>
                <Grid item sm={12} xs={12} className={classes.table}>
                    <MaterialTable
                        columns={[
                        { title: 'Name', render: (rowData) => {
                                let fullName = `${rowData.customerDetails.familyName} ${rowData.customerDetails.givenName}`
                                return fullName;
                            },
                            customFilterAndSearch: (term, rowData) => { 
                                return (`${rowData.customerDetails.familyName} ${rowData.customerDetails.givenName}`.indexOf(term) !== -1)
                            }
                        },
                        { title: 'Created', field: 'createdAt' },
                        ]}
                        data={quoteData}
                        title={"Quotes Report"}
                        isLoading={loading}
                        onSearchChange={ (value) => { handleOnSearchChange(value) }}
                        onPageChange={ (pageNum) => { handleOnPageChange(pageNum) }}
                        actions={[
                            () => ({
                                icon: TableIcons.OpenInNew,
                                tooltip: 'Show Quote',
                                onClick: (event, rowData) => {
                                props.history.push({
                                    pathname: '/dashboard/store/orders/new/',
                                    state: {draftId: rowData.docId},
                                })
                                }
                            })
                        ]}
                        options={{
                            toolbar: true,
                            paging: true,
                            initialPage: page,
                            pageSize: 10,
                            pageSizeOptions: [],
                            actionsColumnIndex: -1,
                            search: true,
                            emptyRowsWhenPaging: false,
                            thirdSortClick: false,
                            searchText: searchBarValue,
                            rowStyle: {
                                whiteSpace: 'pre-wrap',
                            },
                        }}
                    />
                </Grid>
            </Grid>
        </div>
    )
}

export default withRouter(QuotesHome);