import React, { useState, useEffect } from 'react';
import { CircularProgress, makeStyles } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import DatePicker from '../../UI/DatePicker/DatePicker';
import { Autocomplete } from '@material-ui/lab';

import { useUser } from '../../../../providers/UserContext'
import { useForm } from '../../../../providers/DocumentContext';

const useStyles = makeStyles((theme) => ({
    detailsPanel: {
        padding: theme.spacing(2),
        paddingTop: theme.spacing(14),
        height: '100vh',
        right: 0,
        zIndex: 12,
        overflowY: 'auto',
    },

    btnGroup: {
        width: '100%',
    },

    filterItem: {
        marginTop: '4px',
        marginBottom: '4px',
    },

    search: {
        display: 'flex',
        boxShadow: 'inset 0px 0px 2px 2px rgba(207,207,207,0.49)',
        //backgroundColor: '#fafafa',
        borderRadius: '4px',
        alignItems: 'center',
        justifyContent: 'center',
        padding: theme.spacing(2),
    },
}));

const DocumentOptions = (props) => {
    const { users, userLoading, getUsers } = useUser();
    const { forms, formLoading, getForms } = useForm();
    const [open, setOpen] = useState(false);
    const [open2, setOpen2] = useState(false);
    const classes = useStyles();
    //Search Autocomplete Values
    const [typedValue, setTypedValue] = useState('')
    const [typedValue2, setTypedValue2] = useState('')

    useEffect(() => {
        getUsers()
        getForms()
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <Grid container spacing={2}>
            <Grid item sm={12}>
                <Grid container className={classes.search}>
                    <Grid item sm={12}>
                        <Typography variant="h6" style={{ marginBottom: '10px' }}>Filters</Typography>
                        <Typography variant="caption" style={{ marginBottom: '10px' }}>Use these to filter data in Real-Time</Typography>
                    </Grid>
                        < Grid item sm={12}>
                            <Autocomplete style={{marginBottom:'10px'}}
                                open={open2}
                                onOpen={() => {
                                    setOpen2(true);
                                }}
                                onClose={() => {
                                    setOpen2(false);
                                }}
                                loading={formLoading}
                                label="Search"
                                id="autocomplete"
                                autoComplete
                                autoHighlight
                                inputValue={typedValue2}
                                onInputChange={(event, newValue) => { setTypedValue2(newValue); sessionStorage.setItem("documentAutoComplete2", JSON.stringify(newValue)); }}
                                value={props.searchValue2}
                                onChange={(event, newValue) => { props.searchValueChanged2(newValue)}}
                                options={forms.map((form) => form.name ?? '').sort((a, b) => a.toLowerCase() > b.toLowerCase())}
                                getOptionSelected={(option, value) => option?.name === value?.name}
                                getOptionLabel={(option) => option}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="Document"
                                        variant="outlined"
                                        InputProps={{
                                            ...params.InputProps,
                                            endAdornment: (
                                                <React.Fragment>
                                                    {formLoading ? <CircularProgress color="inherit" size={20} /> : null}
                                                    {params.InputProps.endAdornment}
                                                </React.Fragment>
                                            ),
                                        }}
                                    />
                                )}

                            />

                        </Grid>
                        < Grid item sm={12}>
                            <Autocomplete
                                open={open}
                                onOpen={() => {
                                    setOpen(true);
                                }}
                                onClose={() => {
                                    setOpen(false);
                                }}
                                loading={userLoading}
                                label="Search"
                                id="autocomplete"
                                autoComplete
                                autoHighlight
                                inputValue={typedValue}
                                onInputChange={(event, newValue) => { setTypedValue(newValue); sessionStorage.setItem("documentAutoComplete", JSON.stringify(newValue)); }}
                                value={props.searchValue}
                                onChange={(event, newValue) => { props.searchValueChanged(newValue)}}
                                options={users.filter(x=> x.employment?.active).map((user) => user.displayName ?? '').sort((a, b) => a.toLowerCase() > b.toLowerCase())}
                                getOptionSelected={(option, value) => option?.displayName === value?.displayName}
                                getOptionLabel={(option) => option}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="Employee"
                                        variant="outlined"
                                        InputProps={{
                                            ...params.InputProps,
                                            endAdornment: (
                                                <React.Fragment>
                                                    {userLoading ? <CircularProgress color="inherit" size={20} /> : null}
                                                    {params.InputProps.endAdornment}
                                                </React.Fragment>
                                            ),
                                        }}
                                    />
                                )}

                            />

                        </Grid>
                    
                </Grid>
            </Grid>

            <Grid item sm={12}>
                <Grid container className={classes.search}>
                    <Grid item sm={12} className={classes.filterItem}>
                        <DatePicker
                            label="Start Date"
                            value={props.startDate}
                            onChange={props.startDateHandler}
                        />
                    </Grid>

                    <Grid item sm={12} className={classes.filterItem}>
                        <DatePicker
                            label="End Date"
                            value={props.endDate}
                            onChange={props.endDateHandler}
                        />
                    </Grid>
                </Grid>
            </Grid>

            <Grid item sm={12}>
                {(props.errorMsg && <Typography>{props.errorMsg}</Typography>)}
            </Grid>
            <Grid item sm={12} className={classes.btnGroup}>
                <Button variant="contained" fullWidth disabled={props.loading} onClick={props.generateHandler} > Generate </Button>
            </Grid>

        </Grid>
    )
}

export default DocumentOptions;