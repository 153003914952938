import React, { useState, useEffect } from 'react';
import { Grid, makeStyles, Button } from '@material-ui/core';
import { withRouter } from 'react-router';
import { firestore } from '../../../firebase/firebase';
import MaterialTable from '@material-table/core';
import moment from 'moment'
import AddBoxIcon from '@material-ui/icons/AddBox';
import { TableIcons } from '../../../constants/tableIcons';

const useStyles = makeStyles((theme) => ({

  card: {
    padding: theme.spacing(4),
    width: '100%',
    height: '100%',
  },

  content: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
  },

  grid: {
    marginTop: theme.spacing(2),
    maxWidth: '1200px',
  },

  table: {
    display: 'flex',
    justifyContent: 'center',
  },

  tile: {
    width: '300px',
    height: '300px',
  },

  tileContent: {
    display: 'flex',
    justifyContent: 'space-evenly',
    alignItems: 'center',
    flexDirection: 'column',
    width: '100%',
    height: '100%',
    padding: theme.spacing(4),
  },

  tileIcon: {
    color: 'rgba(0, 0, 0, 0.54)',
    width: '100px',
    height: '100px',
  },

  tileText: {
    fontSize: '1.10rem',
  },
  btn: {
    marginBottom: '20px',
    marginTop: '20px',
},
}));

const SafetyHome = (props) => {

  const [safetyData, setSafetyData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [searchBarValue, setSearchBarValue] = useState("safetySearchBarValue" in sessionStorage ? JSON.parse(sessionStorage.getItem("safetySearchBarValue")) : null)

  const classes = useStyles();

  useEffect(() => {
    firestore.collection('safety').orderBy('dateCreated').limit(5).get()
      .then(querySnapshot => {
        const safetyResponse = querySnapshot.docs.map((doc) => {
          return {
            ...doc.data(),
            'docId': doc.id,
          }
        })
        setSafetyData(safetyResponse);
        setLoading(false);
      })
      .catch(e => {
        setError("Unable to Process");
        setLoading(false);
        console.log(e.message);
      })
  }, []);

  const tileClickHandler = (path) => {
    props.history.push({
      pathname: 'safety/' + path,
    })
  };

  return (
    <div className={classes.content}>

      <Grid>
        <Grid item xs={12} className={classes.btn}>
          <Button variant='contained' onClick={() => tileClickHandler('new')} startIcon={<AddBoxIcon/>}>
            Create New Safety Data Sheet
          </Button>
        </Grid>

        {!loading && !error
          ? <Grid item sm={12} xs={12} className={classes.table}>
            <MaterialTable
              columns={[
                { title: 'Name', field: 'name' },
                { title: 'Known As', render: rowData => rowData.aliases.join("\n") },
                { title: 'Emergency #', field: 'emergencyPhone' },
                { title: 'Last Updated', render: rowData => moment.unix(rowData.dateUpdated).format('YYYY-MM-DD') },
              ]}
              data={safetyData}
              onSearchChange={ (value) => {setSearchBarValue(value);  sessionStorage.setItem("safetySearchBarValue", JSON.stringify(value))}}
              style={{minWidth: '800px'}}
              title={"Safety Data Sheets"}
              actions={[
                rowData => ({
                  icon: TableIcons.OpenInNew,
                  tooltip: 'Show Newest Safety Data Sheets',
                  onClick: (event, rowData) => {
                    props.history.push({
                      pathname: '/dashboard/safety/form',
                      search: "?" + new URLSearchParams({ docId: rowData.docId }).toString(),
                    })
                  }
                })
              ]}
              options={{
                toolbar: true,
                paging: true,
                pageSizeOptions: false,
                pageSize: 5,
                actionsColumnIndex: -1,
                search: true,
                emptyRowsWhenPaging: false,
                thirdSortClick: false,
                searchText: searchBarValue,
                rowStyle: {
                    whiteSpace: 'pre-wrap',
                },
              }}
            />
          </Grid>
          : null
        }
      </Grid>
    </div>
  )
}

export default withRouter(SafetyHome);