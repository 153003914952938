import React, { useState, useEffect } from 'react';
import moment from 'moment'
import MaterialTable  from '@material-table/core';
import { TableIcons } from '../../../../constants/tableIcons';
import { firestore } from '../../../../firebase/firebase'
import { Grid, Paper, Typography, IconButton } from '@material-ui/core';
import ReportSelect from '../../../components/Reports/ReportSelect'
import { makeStyles, useMediaQuery } from '@material-ui/core';
import _ from 'lodash'
import clsx from 'clsx';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import { useUser } from '../../../../providers/UserContext'
import { useTheme } from '@material-ui/core/styles';
import Collapse from '@material-ui/core/Collapse';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Skeleton } from '@material-ui/lab';
import TrainingOptions from '../../../components/Reports/Trainings/TrainingOptions';

const useStyles = makeStyles((theme) => ({
    content: {
        padding: '32px',
        paddingRight: '230px',
        overflow: 'auto'
    },
    sidebar: {
        position: 'fixed',
        right: 0,
        top: '64px',
        maxWidth: '256px',
        width: '100%',
        padding: '16px',
        minHeight: '100vh',
        zIndex: '1000',
    },
    expand: {
        position: 'fixed',
        right: 0,
        top: '444px',
        transform: 'rotate(90deg)',
        marginLeft: 'auto',
        transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.shortest,
        }),
    },
    expandOpen: {
        position: 'fixed',
        right: 253,
        top: '444px',
        transform: 'rotate(270deg)',
    },
}));


const TrainingsReport = (props) => {
    const { users, getUsers } = useUser();

    useEffect(() => {
        getUsers();
    }, []) // eslint-disable-line react-hooks/exhaustive-deps
    const classes = useStyles();
    const theme = useTheme();
    const smallScreen = useMediaQuery(theme.breakpoints.down('sm'));
    useEffect(() => {

    }, [smallScreen]);
    const [startDate, setStartDate] = useState("startDate" in sessionStorage ? moment(JSON.parse(sessionStorage.getItem("startDate"))) : moment());
    const [endDate, setEndDate] = useState("endDate" in sessionStorage ? moment(JSON.parse(sessionStorage.getItem("endDate"))) : moment());
    const [trainingData, setTrainingData] = useState(JSON.parse(sessionStorage.getItem("trainingData")) || []);
    const [errorMessage, setErrorMessage] = useState(null)
    const [loading, setLoading] = useState(false)
    const [expanded, setExpanded] = useState(JSON.parse(sessionStorage.getItem("reportExpanded")) || true);
    const [autoCompleteValue, setAutoCompleteValue] = useState("trainingAutoComplete" in sessionStorage ? JSON.parse(sessionStorage.getItem("trainingAutoComplete")) : "")
    const [autoCompleteValue2, setAutoCompleteValue2] = useState("trainingAutoComplete2" in sessionStorage ? JSON.parse(sessionStorage.getItem("trainingAutoComplete2")) : "")
    const [searchState, setSearch] = useState({
        type: JSON.parse(sessionStorage.getItem("trainingOptionsSearchType")) || 'unitNumber',
        value: JSON.parse(sessionStorage.getItem("documentOptionsSearchValue")) || '',
    });
    const [searchBarValue, setSearchBarValue] = useState("trainingSearchBarValue" in sessionStorage ? JSON.parse(sessionStorage.getItem("trainingSearchBarValue")) : null)

    const startDateHandler = date => {
        setStartDate(date)
        sessionStorage.setItem("startDate", JSON.stringify(date))
    }
    const endDateHandler = date => {
        setEndDate(date)
        sessionStorage.setItem("endDate", JSON.stringify(date))
    }

    const searchHandler = (event) => {
        setSearch({ ...searchState, [event.target.name]: event.target.value })
        if(event.target.name === "type") sessionStorage.setItem("trainingOptionsSearchType", JSON.stringify(event.target.value))
        if(event.target.name === "value") sessionStorage.setItem("trainingOptionsSearchValue", JSON.stringify(event.target.value))
    }

    const clearSearch = () => {
        setSearch({ ...searchState, value: '' });
        sessionStorage.removeItem("trainingOptionsSearchValue");
    }

    async function dateCompat() {
        let query = firestore.collection('employeeTrainings');
        let uid = users.find(user => user.displayName === autoCompleteValue)?.uuid;
        let name = autoCompleteValue2;
    
        if (uid) query = query.where('uuid', '==', uid);
        if (name) query = query.where('title', '==', name);
        query = query.orderBy('completedDate');
    
        // Format start and end dates to match 'YYYY-MM-DD' format
        const startDateString = startDate.startOf('day').format('YYYY-MM-DD');
        const endDateString = endDate.endOf('day').format('YYYY-MM-DD');
    
        const oldDate = query
            .where("completedDate", ">=", startDateString)
            .where("completedDate", "<=", endDateString)
            .get();
        
        const newDate = query
            .where("completedDate", ">=", startDate.startOf('day').utc().valueOf())
            .where("completedDate", "<=", endDate.endOf('day').utc().valueOf())
            .get();
    
        const [oldDateSnapshot, newDateSnapshot] = await Promise.all([newDate, oldDate]).catch((err) => console.log(err));
    
        const oldDateArray = oldDateSnapshot.docs;
        const newDateArray = newDateSnapshot.docs;
    
        return _.concat(oldDateArray, newDateArray);
    }

    const generateHandler = () => {
        sessionStorage.removeItem("timesheetData");
        sessionStorage.removeItem("timesheetUnitData");
        sessionStorage.removeItem("jobData");
	    sessionStorage.removeItem("unitData");
        sessionStorage.removeItem("defectData");
        sessionStorage.removeItem("deliveryData");
      	sessionStorage.removeItem("deliveryUnitData");
        sessionStorage.removeItem("covidData");
       	sessionStorage.removeItem("checklistData");
        sessionStorage.removeItem("photoData");
        sessionStorage.removeItem("filteredPhotoData");
        sessionStorage.removeItem("REACT_COOL_IMG");
        sessionStorage.removeItem("filteredOrderData");
        sessionStorage.removeItem("trainingData");
        setErrorMessage(null);
        if (endDate.isBefore(startDate)) {
            setErrorMessage('Start Date later than End Date!')
            return
        }
        if(endDate.diff(startDate, 'd') > 365){
            setErrorMessage('Query cannot be more than a one year period.')
            return
        }
        setLoading(true);
        dateCompat().then(querySnapshot => {
            let documentResponse = querySnapshot.map((doc) => {

                return {
                    ...doc.data(),
                    'docId': doc.id,
                    'date': moment.utc(doc.data().completedDate).format('YYYY-MM-DD'),
                }
            })
            documentResponse = documentResponse.filter((doc) => doc.pdfURL !== '');
            setTrainingData(documentResponse)
            setLoading(false);
            sessionStorage.setItem("trainingData", JSON.stringify(documentResponse))
        }).catch(e => {

            setTrainingData([]);
            sessionStorage.removeItem("trainingData");
            setLoading(false);
            switch(e.name){
                case 'QuotaExceededError':
                    setErrorMessage("Query size too big for browser storage.");
                    console.log(e.message);
                    break;
                default:
                    setErrorMessage("Unable to process.");
                    console.log(e.message);
            }
        });
    }

    const handleExpandClick = () => {
        if(expanded)
            setExpanded(false);
        else
            setExpanded(true);    
        sessionStorage.setItem("reportExpanded", JSON.stringify(expanded))
    };

    function mergeData()  { 
        return trainingData.map(x => {
            const user = users.find((user) => user.uuid === x.uuid)
            x.uuid = _.isUndefined(user) ? x.uuid : user.displayName;
            return {
                ...x,
            } 
        });
    }

    function checkData() {
        let data = mergeData();
        if (!autoCompleteValue && !autoCompleteValue2) {
          return data; // Return all data if both values are empty
        }
      
        if (autoCompleteValue && autoCompleteValue2) {
          data = data.filter((doc) => doc.uuid === autoCompleteValue && doc.title === autoCompleteValue2); // Filter based on both values
        } else if (autoCompleteValue) {
          data = data.filter((doc) => doc.uuid === autoCompleteValue); // Filter based on autoCompleteValue
        } else if (autoCompleteValue2) {
          data = data.filter((doc) => doc.title === autoCompleteValue2); // Filter based on autoCompleteValue2
        }
    
        return data;
      }

    useEffect(() => {
    }, [autoCompleteValue2, autoCompleteValue])

    return (
        <React.Fragment>
            <div className={classes.content}
            style={expanded ? {paddingRight: '260px' } : {paddingRight: smallScreen ? '12px' : '230px' }} >
                {loading === false ?
                <MaterialTable
                    columns={[
                        { title: 'Title', field: 'title' },
                        { title: 'Employee', field: 'uuid' },
                        { title: 'Completed', field: 'date', defaultSort: 'desc' },
                        { title: 'Duration', field: 'duration' },
                    ]}
                    data={checkData()}
                    title='Trainings'
                    icons={TableIcons}
                    isLoading={loading}
                    onSearchChange={ (value) => {setSearchBarValue(value);  sessionStorage.setItem("trainingSearchBarValue", JSON.stringify(value))}}
                    onRowsPerPageChange={(num) => {sessionStorage.setItem("trainingReportRowsPerPage", JSON.parse(num))}}
                    onPageChange={ (pageNum) => { sessionStorage.setItem("trainingReportPageValue", JSON.stringify(pageNum)) }}
                    options={{
                        pageSize: "trainingReportRowsPerPage" in sessionStorage ? JSON.parse(sessionStorage.getItem("trainingReportRowsPerPage")) : 20,
                        initialPage: "trainingReportPageValue" in sessionStorage ? JSON.parse(sessionStorage.getItem("trainingReportPageValue")) : 0,
                        toolbar: true,
                        paging: true,
                        columnsButton: true,
                        searchText: searchBarValue,
                    }}
                    detailPanel={[
                        rowData => ({
                            tooltip: _.isUndefined(rowData.certificate?.url) ? null:'Show Certificate',
                            icon: () => <small><ArrowForwardIosIcon style={{ fontSize: '12px' }} /></small>,
                            disabled: _.isUndefined(rowData.certificate?.url),
                            render: () =>
                                <React.Fragment>
                                    <Grid container>
                                        <Grid item xs={12}>
                                            <Typography variant='h6' style={{ marginBottom: '10px' }}>Certificate</Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            {rowData.certificate.type === 'application/pdf' ?
                                                <iframe src={rowData.certificate.url} title={rowData.title+'  Certificate'} width="100%" height="600px" />
                                                :
                                                <img src={rowData.certificate.url} alt={rowData.certificate.name} style={{ width: '500px', height: 'auto' }} />
                                            }
                                        </Grid>
                                    </Grid>
                                </React.Fragment>,
                        }),
                    ]}       
                />
                : <Skeleton variant='rect' width={'80vw'} height={'160vh'}/>}
                {smallScreen ?
                <div>
                    <IconButton
                        className={clsx(classes.expand, {
                            [classes.expandOpen]: expanded,
                        })}
                        
                        onClick={handleExpandClick}
                        aria-expanded={expanded}
                        aria-label="show more"
                        >
                        <ExpandMoreIcon style={{fontSize: '50px'}} />
                    </IconButton>
                    <Collapse orientation="horizontal" in={expanded} timeout="auto" unmountOnExit>
                        <Paper className={classes.sidebar}>
                            <ReportSelect
                                reportType={props.reportType}
                                onchange={props.onchange}
                            />
                            <TrainingOptions
                                searchState={searchState} searchHandler={searchHandler}
                                clearSearch={clearSearch}
                                startDate={startDate} startDateHandler={startDateHandler}
                                endDate={endDate} endDateHandler={endDateHandler}
                                searchValue={autoCompleteValue} searchValueChanged={setAutoCompleteValue}
                                searchValue2={autoCompleteValue2} searchValueChanged2={setAutoCompleteValue2}
                                generateHandler={generateHandler}
                                errorMsg={errorMessage}
                                loading={loading}
                            />
                        </Paper>
                    </Collapse>
                </div>
                :
                <Paper className={classes.sidebar}>
                    <ReportSelect
                        reportType={props.reportType}
                        onchange={props.onchange}
                    />
                    <TrainingOptions
                        searchState={searchState} searchHandler={searchHandler}
                        clearSearch={clearSearch}
                        startDate={startDate} startDateHandler={startDateHandler}
                        endDate={endDate} endDateHandler={endDateHandler}
                        searchValue={autoCompleteValue} searchValueChanged={setAutoCompleteValue}
                        searchValue2={autoCompleteValue2} searchValueChanged2={setAutoCompleteValue2}
                        generateHandler={generateHandler}
                        errorMsg={errorMessage}
                        loading={loading}
                    />
                </Paper> }
            </div>
        </React.Fragment>
    )
}

export default TrainingsReport