import React, { useState, useEffect } from 'react';
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import { IconButton, makeStyles, Typography, TextField } from '@material-ui/core';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import { Cancel, Edit, Save } from '@material-ui/icons';
import Skeleton from '@material-ui/lab/Skeleton';

const useStyles = makeStyles((theme) => ({
    card: {
        padding: '8px',
        width: '100%',
    },
    paper: {
        maxWidth: '100%',
        margin: `${theme.spacing(1)}px auto`,
        padding: theme.spacing(2),
    },

    message: {
        width: '100%',
    }

}));

const Note = (props) => {
    const classes = useStyles()
    const [editMode, setEditMode] = useState(false)
    const [noteText, setNoteText] = useState(props.note)

    const handleSave = (text) => {
        props.editNote(text)
    }

    useEffect(() => {
        setNoteText(props.note)
    }, [props.note])

    return (
        <Paper className={classes.paper}>
            {props.loading ?
                <Grid container>
                    <Grid container item alignItems='baseline' direction='row' xs={12} spacing={2}>
                        <Grid item>
                            <Skeleton>
                                <Typography>{props.user ?? 'No Name'}</Typography>
                            </Skeleton>
                        </Grid>
                        <Grid item>
                            <Skeleton >
                                <Typography style={{ fontSize: '12px' }}>{props.timestamp ?? 'No date information available'}</Typography>
                            </Skeleton>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Skeleton >
                            <TextField>{props.note ?? 'No note text provided'}</TextField>
                        </Skeleton>
                    </Grid>
                </Grid>

                :
                <Grid container direction='column' spacing={1} wrap="nowrap">
                    <Grid container item spacing={2} direction='row' >
                        <Grid container item direction='column' xs={6}  >
                            <Grid item>
                                <Typography noWrap>{props.user}</Typography>
                            </Grid>
                            <Grid item >
                                <Typography noWrap style={{ fontSize: '12px' }}>{props.timestamp}</Typography>
                            </Grid>
                        </Grid>

                        <Grid container item direction='row' xs={6} justify='flex-end'>
                            {!editMode && props.editNote &&

                                < Grid item >
                                    <IconButton onClick={() => {
                                        setEditMode(!editMode)
                                        setNoteText(props.note)
                                    }}>
                                        <Edit />
                                    </IconButton>
                                </Grid>
                            }
                            {props.editNote && editMode &&

                                <Grid item>
                                    <IconButton onClick={() => {
                                        handleSave(noteText)
                                        setEditMode(!editMode)
                                    }}>
                                        <Save />
                                    </IconButton>
                                </Grid>
                            }
                            {props.editNote && editMode &&
                                <Grid item>
                                    <IconButton onClick={() => setEditMode(!editMode)}>
                                        <Cancel />
                                    </IconButton>
                                </Grid>

                            }
                            {props.deleteNote &&
                                <Grid item xs={2}>
                                    <IconButton onClick={props.deleteNote}><DeleteOutlineIcon /></IconButton>
                                </Grid>
                            }
                        </Grid>
                    </Grid>

                    {editMode ?
                        <Grid item xs={12} zeroMinWidth>
                            <TextField fullWidth multiline variant='outlined' size='small' value={noteText} onChange={(event) => setNoteText(event.target.value)}></TextField>
                        </Grid>
                        :
                        <Grid item xs={12} className={classes.message}>

                            <Typography style={{ wordWrap: 'break-word' }}>{props.note || "<No Text>"}</Typography>

                        </Grid>
                    }
                </Grid>}
        </Paper >
    )
}
export default (Note)