export const GiftCardEmail = (data) => {
    console.log("Gift Card Image URL:", data.giftCardImage);
    let email = `
    <body lang=EN-US link="#0563C1" vlink="#954F72" style='tab-interval:.5in;
word-wrap:break-word'>

<div align="left">
<table class="MsoTableGrid" style="border-collapse: collapse; border: none; mso-border-alt: solid windowtext .5pt; mso-yfti-tbllook: 1184; mso-padding-alt: .25in .5in .25in .5in;" border="1" cellspacing="0" cellpadding="0">
<tbody>
<tr style="mso-yfti-irow: 0; mso-yfti-firstrow: yes;">
<td style="width: 100%; border: none; background: #FFC000; padding: 1pt .5in 1pt .5in;" colspan="2" valign="bottom">
</td>
</tr>
<tr style="mso-yfti-irow: 1; height: 27.0pt;">
<td style="width: 100%; border: none; background: white; mso-background-themecolor: background1; padding: .25in .5in .25in .5in; height: 27.0pt;" colspan="2" valign="top">
<p class="MsoNormal"><span style="font-size: 14.0pt; font-family: 'Arial',sans-serif; color: #3b3838; mso-themecolor: background2; mso-themeshade: 64; mso-style-textfill-fill-color: #3B3838; mso-style-textfill-fill-themecolor: background2; mso-style-textfill-fill-alpha: 100.0%; mso-style-textfill-fill-colortransforms: lumm=25000;">Your Gee Tee Holdings Digital Gift Card has arrived!</span></p>

<hr />

<p class="MsoNormal"><span style="font-size: 12.0pt; font-family: 'Arial',sans-serif; color: #3b3838; mso-themecolor: background2; mso-themeshade: 64; mso-style-textfill-fill-color: #3B3838; mso-style-textfill-fill-themecolor: background2; mso-style-textfill-fill-alpha: 100.0%; mso-style-textfill-fill-colortransforms: lumm=25000;">Your Gift Card Code: <b>${data.giftCard.code}</b> </span></p>
<img src="${data.giftCardImage.url}" alt="Gift Card Image" style="width: auto; height: auto;">

<p class="MsoNormal"><span style="font-size: 10.0pt; font-family: 'Arial',sans-serif; color: #3b3838; mso-themecolor: background2; mso-themeshade: 64; mso-style-textfill-fill-color: #3B3838; mso-style-textfill-fill-themecolor: background2; mso-style-textfill-fill-alpha: 100.0%; mso-style-textfill-fill-colortransforms: lumm=25000;">This gift card is redeemable in our Online Store only and not applicable on any purchase at any of our physical locations.</span></p>

</td>
</tr>
<tr style="mso-yfti-irow: 2; mso-yfti-lastrow: yes; height: 12.75pt;">
<td style="width: 700px; border: none; background: #FFC000; padding: .2in .5in .2in .5in; height: 12.75pt;" valign="top" width="363">
<p class="MsoNormal" style="line-height: 115%;"><strong><span style="font-size: 18.0pt; line-height: 115%; font-family: 'Helvetica',sans-serif; color: #3b3838; mso-themecolor: background2; mso-themeshade: 64; mso-style-textfill-fill-color: #3B3838; mso-style-textfill-fill-themecolor: background2; mso-style-textfill-fill-alpha: 100.0%; mso-style-textfill-fill-colortransforms: lumm=25000;">Gee Tee Holdings Inc.</span></strong></p>
<p>204-444-3069 | 61035 MB - HWY 207</p>
</td>
</tr>
</tbody>
</table>
</div>

</body>

    `

    return email;
}