import React from 'react';
import { withRouter, useHistory } from 'react-router'
import { makeStyles, Grid, Typography, IconButton, Paper } from '@material-ui/core';
import RefreshIcon from '@material-ui/icons/Refresh';;


const useStyles = makeStyles((theme) => ({
    content: {
        display: 'flex', 
        width:'100%', 
        justifyContent:'center',
        padding: '32px', 
        margin: '32px',
    },

}));

const ErrorFallBack = (props) =>  {

    const classes = useStyles();
    const history = useHistory();

    return (
        <Grid className={classes.content}>
            <Paper style={{padding: '32px', margin: '32px'}}>
                <Grid container justifyContent='center' style={{marginBottom: '10px'}}>
                    <Typography variant='h4'>Something went wrong.</Typography>
                </Grid>

                <Grid container justifyContent='center' style={{marginBottom: '10px'}}>
                    <Typography>Try refreshing the page.</Typography>
                </Grid>

                <Grid container justifyContent='center'>
                    <IconButton
                        style={{backgroundColor: '#ffc107', color: 'black'}}
                        onClick={(e) => { history.go(0) }}
                    >
                        <RefreshIcon/>
                    </IconButton>
                </Grid>
            </Paper>
        </Grid>    
    )
}

export default withRouter(ErrorFallBack);