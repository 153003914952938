import React, { useState, useEffect, memo } from 'react';
import { makeStyles } from '@material-ui/core';
import { Grid, Typography, TextField, CircularProgress} from '@material-ui/core';
import _ from 'lodash';
import { Autocomplete } from '@material-ui/lab';


const useStyles = makeStyles((theme) => ({
    selectRow: {
        padding: '5px',
    },
}));

const GrainOrderEquipmentAutocompletes = ({ handleSetParentValues, users, unitLoading, docId, units, operator, equipment, trailer1, trailer2, }) => {
    const classes = useStyles();

    const [autocompletes, setAutocompletes] = useState({
        equipment: {open: false, value: ''},
        trailer1: {open: false, value: ''},
        trailer2: {open: false, value: ''},
    })

    useEffect(() => {
        if(equipment !== autocompletes.equipment.value){ handleSetAutocompleteValue(equipment, 'equipment', units) }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [equipment])

    useEffect(() => {
        if(trailer1 !== autocompletes.trailer1.value){ handleSetAutocompleteValue(trailer1, 'trailer1', units) }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [trailer1])
    
    useEffect(() => {
        if(equipment !== autocompletes.trailer2.value){ handleSetAutocompleteValue(trailer2, 'trailer2', units) }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [trailer2])

    const handleSetAutocompleteValue = (value, field, findFrom) => {
        setAutocompletes((previousValues) => {
            let find = findFrom.find(x=> x.docId === value)
            previousValues[field].value = value !== "" && !_.isUndefined(find) ? find : value;

            return {...previousValues}
        }) 
    }
    
    return (
        <>
            <Grid container>
                <Grid item xs={4}>
                    <Autocomplete
                        open={autocompletes.equipment.open}
                        onOpen={() => { setAutocompletes((previousValues) => { return {...previousValues, equipment: {...previousValues.equipment, open: true}} }) }}
                        onClose={() => { setAutocompletes((previousValues) => { return {...previousValues, equipment: {...previousValues.equipment, open: false}} }) }}
                        loading={unitLoading}
                        label="Equipment"
                        size='small'
                        id="autocomplete"
                        autoComplete
                        autoHighlight
                        //disableClearable
                        //freeSolo={true}
                        className={classes.selectRow}
                        fullWidth
                        value={autocompletes.equipment.value}
                        onChange={(event, newValue) => { 
                            let newValueContainer = newValue === null ? '' : newValue;

                            let localGrainDataContainer = { equipment: newValueContainer !== '' ? newValueContainer?.docId : newValueContainer }
                            let autocompletesContainer = _.cloneDeep(autocompletes)
                            autocompletesContainer.equipment.value = newValueContainer;

                            //check if operator is not present
                            if(( !operator && newValueContainer !== '' )){
                                let userContainer = users.filter(x=> x.commonEquipment?.fullUnit?.docId === newValueContainer?.docId).pop()?.docId
                                if(!_.isUndefined(userContainer)){  
                                    localGrainDataContainer.operator = userContainer 
                                    autocompletesContainer.operator.value = userContainer;
                                }
                            }

                            setAutocompletes(autocompletesContainer)
                            handleSetParentValues(localGrainDataContainer, docId)
                        }}
                        options={units || {}}
                        getOptionSelected={(option, value) => option.unitNumber === value?.unitNumber || {}}
                        getOptionLabel={(option) => option.unitNumber ? '#' + option.unitNumber + ' ' + option.description : ''}
                        renderOption={(equipment) =>(
                            <Grid >
                                <Typography>{'#' + equipment.unitNumber + ' ' + equipment.description}</Typography>
                            </Grid>
                        )}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label="Equipment"
                                variant="outlined"
                                size='small'
                                InputProps={{
                                    ...params.InputProps,
                                    endAdornment: (
                                        <React.Fragment>
                                            {unitLoading ? <CircularProgress color="inherit" size={20} /> : null}
                                            {params.InputProps.endAdornment}
                                        </React.Fragment>
                                    ),
                                }}
                            />
                        )}
                    />

                </Grid>

                <Grid item xs={4}>
                    <Autocomplete
                        open={autocompletes.trailer1.open}
                        onOpen={() => { setAutocompletes((previousValues) => { return {...previousValues, trailer1: {...previousValues.trailer1, open: true}} }) }}
                        onClose={() => { setAutocompletes((previousValues) => { return {...previousValues, trailer1: {...previousValues.trailer1, open: false}} }) }}
                        loading={unitLoading}
                        label="Trailer 1"
                        size='small'
                        id="autocomplete"
                        autoComplete
                        autoHighlight
                        //disableClearable
                        //freeSolo={true}
                        className={classes.selectRow}
                        fullWidth
                        value={autocompletes.trailer1.value}
                        onChange={(event, newValue) => { 
                            let newValueContainer = newValue === null ? '' : newValue;
                            handleSetParentValues({trailer1: newValueContainer !== '' ? newValueContainer.docId : newValueContainer}, docId)
                            setAutocompletes((previousValues) => { return {...previousValues, trailer1: {...previousValues.trailer1, value: newValueContainer}} })
                        }}
                        options={units || {}}
                        getOptionSelected={(option, value) => option?.unitNumber === value?.unitNumber || {}}
                        getOptionLabel={(option) => option.unitNumber ? '#' + option.unitNumber + ' ' + option.description : '' }
                        renderOption={(unit) =>(
                            <Grid >
                                <Typography>{'#' + unit.unitNumber + ' ' + unit.description}</Typography>
                            </Grid>
                        )}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label="Trailer 1"
                                variant="outlined"
                                size='small'
                                InputProps={{
                                    ...params.InputProps,
                                    endAdornment: (
                                        <React.Fragment>
                                            {unitLoading ? <CircularProgress color="inherit" size={20} /> : null}
                                            {params.InputProps.endAdornment}
                                        </React.Fragment>
                                    ),
                                }}
                            />
                        )}
                    />
                </Grid>

                <Grid item xs={4}>
                    <Autocomplete
                        open={autocompletes.trailer2.open}
                        onOpen={() => { setAutocompletes((previousValues) => { return {...previousValues, trailer2: {...previousValues.trailer2, open: true}} }) }}
                        onClose={() => { setAutocompletes((previousValues) => { return {...previousValues, trailer2: {...previousValues.trailer2, open: false}} }) }}
                        loading={unitLoading}
                        label="Trailer 2"
                        size='small'
                        id="autocomplete"
                        autoComplete
                        autoHighlight
                        //disableClearable
                        //freeSolo={true}
                        className={classes.selectRow}
                        fullWidth
                        value={autocompletes.trailer2.value}
                        onChange={(event, newValue) => { 
                            let newValueContainer = newValue === null ? '' : newValue;
                            handleSetParentValues({trailer2: newValueContainer !== '' ? newValueContainer.docId : newValueContainer}, docId)
                            setAutocompletes((previousValues) => { return {...previousValues, trailer2: {...previousValues.trailer2, value: newValueContainer}} })
                            //handleSetCurrentGrainOrder({ ...currentGrainOrder, trailer2: newValue.docId }) 
                        }}
                        options={units || {}}
                        getOptionSelected={(option, value) => option?.unitNumber === value?.unitNumber || {}}
                        getOptionLabel={(option) => option.unitNumber ? '#' + option.unitNumber + ' ' + option.description : '' }
                        renderOption={(unit) =>(
                            <Grid >
                                <Typography>{'#' + unit.unitNumber + ' ' + unit.description}</Typography>
                            </Grid>
                        )}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label="Trailer 2"
                                variant="outlined"
                                size='small'
                                InputProps={{
                                    ...params.InputProps,
                                    endAdornment: (
                                        <React.Fragment>
                                            {unitLoading ? <CircularProgress color="inherit" size={20} /> : null}
                                            {params.InputProps.endAdornment}
                                        </React.Fragment>
                                    ),
                                }}
                            />
                        )}
                    />
                </Grid>
            </Grid>
        </>

    )
}

export default memo(GrainOrderEquipmentAutocompletes)