import React, { useState, useEffect, memo } from 'react';
import moment from 'moment';
import { Card, Typography, Grid, Button, Divider } from '@material-ui/core';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import { Skeleton } from '@material-ui/lab';
import { firestore } from '../../../../firebase/firebase'

const OrderSummary = ({smallScreen}) => {

    const [measurement, setMeasurement] = useState(sessionStorage.getItem("orderCurrentMeasurement") ? JSON.parse(sessionStorage.getItem("orderCurrentMeasurement")) : ("Yearly"));
    const [loading, setLoading] = useState(false)
    const [startDate, setStartDate] = useState(moment());
    const [endDate, setEndDate] = useState(moment());
    const [selectedYear, setSelectedYear] = useState(sessionStorage.getItem("orderSummaryYear") ? JSON.parse(sessionStorage.getItem("orderSummaryYear")) : new Date().getUTCFullYear());
    const [selectedMonth, setSelectedMonth] = useState(sessionStorage.getItem("orderSummaryMonth") ? JSON.parse(sessionStorage.getItem("orderSummaryMonth")) : new Date().getUTCMonth() + 1);
    const [selectedDay, setSelectedDay] = useState(sessionStorage.getItem("orderSummaryDay") ? JSON.parse(sessionStorage.getItem("orderSummaryDay")) : new Date().getDate());
    const [maxDays, setMaxDays] = useState(sessionStorage.getItem("orderMaxDays") ? JSON.parse(sessionStorage.getItem("orderMaxDays")) : (0));
    const [orders, setOrders] = useState([])

    // program to check leap year
    function checkLeapYear(year) {
        //three conditions to find out the leap year
        if (((0 === year % 4) && (0 !== year % 100)) || (0 === year % 400)) {
            return true
        } else {
            return false
        }
    }

    //order listener to get data
    useEffect(() => {
        // snapshot listener for orders collection
        const unsubscribe = firestore.collection('orders').onSnapshot(
            snapshot => {
                if (!snapshot.empty) {
                    const orders = snapshot.docs.map(doc => ({
                        id: doc.id,
                        ...doc.data()
                    }))
                    setOrders(orders)
                } else {
                    setOrders([]) // Handle empty case
                }
            },
            error => {
                console.error("Error fetching orders: ", error)
            }
        )
        return () => {
            unsubscribe()
        }
    }, [])

    const maxDaysHandler = () => {
        switch (selectedMonth) {
            case 2:
                checkLeapYear(selectedYear) ? setMaxDays(29) : setMaxDays(28)
                break;
            case 4:
                setMaxDays(30)
                break;
            case 6:
                setMaxDays(30)
                break;
            case 9:
                setMaxDays(30)
                break;
            case 11:
                setMaxDays(30)
                break;
            default:
                setMaxDays(31)
                break;
        }
        sessionStorage.setItem("orderMaxDays", JSON.parse(maxDays))
    }

    let totalSales = 0
    let calcTotalDeliveries = 0
    let calcTotalPickups = 0
    //let totalDeliverySales = 0

    useEffect(() => {
        switch (measurement) {
            case 'Yearly':
                setStartDate(moment(new Date(selectedYear + '/01/01 00:00:00')))
                setEndDate(moment(new Date(selectedYear + '/12/31 23:59:59')))
                //setSelectedDay(2)
                sessionStorage.getItem("orderSummaryDay") ? setSelectedDay(JSON.parse(sessionStorage.getItem("orderSummaryDay"))) : setSelectedDay(1);

                break;
            case 'Monthly':
                setStartDate(moment(new Date(selectedYear + '/' + selectedMonth + '/01 00:00:00')))
                setEndDate(moment(new Date(selectedYear + '/' + selectedMonth + '/' + maxDays + " 23:59:59")))
                //setSelectedDay(2)
                sessionStorage.getItem("orderSummaryDay") ? setSelectedDay(JSON.parse(sessionStorage.getItem("orderSummaryDay"))) : setSelectedDay(1);
                maxDaysHandler()
                break;
            case 'Daily':
                setStartDate(moment(new Date(selectedYear + '/' + selectedMonth + '/' + (selectedDay ) + " 00:00:00")))
                setEndDate(moment(new Date(selectedYear + '/' + selectedMonth + '/' + (selectedDay) + " 23:59:59")))
                maxDaysHandler()
                
                if(selectedDay > maxDays){
                    setSelectedDay(maxDays);
                    sessionStorage.setItem("orderSummaryDay", JSON.stringify(maxDays));
                }
                break;
            default:
                break;
        }
        // eslint-disable-next-line
    }, [measurement, selectedYear, selectedMonth, selectedDay, maxDays])

    const handleMeasurementChange = (event) => {
        setLoading(true)
        setMeasurement(event.currentTarget.value)
        sessionStorage.setItem("orderCurrentMeasurement", JSON.stringify(event.currentTarget.value))
        setLoading(false)
    }

    const handleNext = () => {
        switch (measurement) {
            case "Yearly":
                setSelectedYear(selectedYear + 1)
                sessionStorage.setItem("orderSummaryYear", JSON.stringify(selectedYear + 1));
                break;
            case "Monthly":
                setSelectedMonth(selectedMonth < 12 ? selectedMonth + 1 : 1)
                //if last month go to next year
                if (selectedMonth === 12) {
                    setSelectedYear(selectedYear + 1)
                    sessionStorage.setItem("orderSummaryYear", JSON.stringify(selectedYear + 1));
                }
                maxDaysHandler()
                sessionStorage.setItem("orderSummaryMonth", JSON.stringify(selectedMonth < 12 ? selectedMonth + 1 : selectedMonth));
                break;
            case "Daily":
                setSelectedDay(selectedDay < maxDays ? selectedDay + 1 : selectedDay)
                sessionStorage.setItem("orderSummaryDay", JSON.stringify((selectedDay < maxDays + 1 ? selectedDay + 1 : selectedDay)));
                //if last day of month, go to next month
                if (selectedDay === maxDays) {
                    setSelectedMonth(selectedMonth < 12 ? selectedMonth + 1 : 1)
                    //if last day of the year go to next year
                    if (selectedMonth === 12) {
                        setSelectedYear(selectedYear + 1)
                        sessionStorage.setItem("orderSummaryYear", JSON.stringify(selectedYear + 1));
                    }
                    maxDaysHandler()
                    sessionStorage.setItem("orderSummaryMonth", JSON.stringify(selectedMonth < 12 ? selectedMonth + 1 : selectedMonth));
                    setSelectedDay(1)
                    sessionStorage.setItem("orderSummaryDay", JSON.stringify(1));
                }
                break;
            default:
                break;
        }
    }
    const handlePrevious = () => {
        switch (measurement) {
            case "Yearly":
                setSelectedYear(selectedYear - 1)
                sessionStorage.setItem("orderSummaryYear", JSON.stringify(selectedYear - 1));
                break;
            case "Monthly":
                setSelectedMonth(selectedMonth > 1 ? selectedMonth - 1 : 12)
                //if first month go to previous year
                if (selectedMonth === 1) {
                    setSelectedYear(selectedYear - 1)
                    sessionStorage.setItem("orderSummaryYear", JSON.stringify(selectedYear - 1));
                }
                maxDaysHandler()
                sessionStorage.setItem("orderSummaryMonth", JSON.stringify(selectedMonth > 1 ? selectedMonth - 1 : selectedMonth));
                break;
            case "Daily":
                selectedDay === 1 ? setSelectedDay(selectedDay) : setSelectedDay(selectedDay > 1 ? selectedDay - 1 : selectedDay)
                sessionStorage.setItem("orderSummaryDay", JSON.stringify((selectedDay === 1 ? selectedDay : selectedDay > 1 ? selectedDay - 1 : selectedDay )));
                //if first day of month, go to previous month
                if (selectedDay === 1) {
                    setSelectedMonth(selectedMonth > 1 ? selectedMonth - 1 : 12)
                    //if first day of the year go to previous year
                    if (selectedMonth === 1) {
                        setSelectedYear(selectedYear - 1)
                        sessionStorage.setItem("orderSummaryYear", JSON.stringify(selectedYear - 1));
                    }
                    maxDaysHandler()
                    sessionStorage.setItem("orderSummaryMonth", JSON.stringify(selectedMonth > 1 ? selectedMonth - 1 : selectedMonth));
                    setSelectedDay(maxDays)
                    sessionStorage.setItem("orderSummaryDay", JSON.stringify(maxDays));
                }
                break;
            default:
                break;
        }
    }

    let filteredOrders = orders.filter(order => {
        return order?.payment?.created_at &&
        moment(order.payment.created_at).isAfter(moment(startDate)) &&
        moment(order.payment.created_at).isBefore(moment(endDate)) &&
        order.order.refund !== 'FULL'
    })

    if (filteredOrders.length > 0) {
        filteredOrders.forEach(order => {
            let deliveryOrder = false
            let pickupOrder = false
            for (let i = 0; i < order.order.products.length; i++) {
                switch (order.order.products[i].shippingMethod) {
                    case "Delivery":
                        deliveryOrder = true
                        break;
                    case "Pickup":
                        pickupOrder = true
                        break;
                    default:
                        break;
                }
            }
            deliveryOrder && calcTotalDeliveries++;
            pickupOrder && calcTotalPickups++;
        })

        /* totalDeliverySales = filteredOrders.reduce((acc, order) => {
             let hasDelivery = false; 
     
             for (let i = 0; !hasDelivery && i < order.order.products.length; i++) {
                 if (order.order.products[i].shippingMethod === "Delivery") {
                     hasDelivery = true
                     calcTotalDeliveries++;
                 }
             }
     
             return hasDelivery ? parseFloat(order.order.fees.total / 100).toFixed(2) : 0 
         }) */


         filteredOrders.forEach((order) => {
            if (order.payment) {
                let totalFee = parseFloat(order.order.fees.total);
                let refundAmount = order.order.refundAmount ? parseFloat(order.order.refundAmount) : 0;
        
                if (order.order.refund && (order.order.refund === 'PARTIAL' || order.order.refund === 'FULL')) {
                    // Adjust the fee if there is a paymentHandler
                    totalFee = order.payment.paymentHandler ? totalFee : totalFee / 100;
                    // Subtract refundAmount if any
                    totalSales += refundAmount ? totalFee - refundAmount : totalFee;
                } else {
                    // If no refund, just adjust based on paymentHandler
                    totalSales += order.payment.paymentHandler ? totalFee : totalFee / 100;
                }
            }
        });
    }

    return (
        <React.Fragment>
            <Card style={{ padding: '16px' }}>
                <Grid container spacing={2}>
                    <Grid item md={7} sm={12} style={{ display: 'flex', alignItems: 'center' }}>
                        <Grid item xs={12}>
                            {!smallScreen ? <Typography variant="h6">Summary:</Typography> : <Typography>Summary:</Typography> }
                        </Grid>
                        <div style={{ display: 'flex', justifyContent: 'center', marginLeft: '16px', alignItems: 'center' }}>
                            <Button
                                variant='outlined'
                                size={smallScreen ? "small" : "medium"}
                                onClick={handlePrevious}>
                                <NavigateBeforeIcon />
                            </Button>
                            <Typography variant='h6' style={{ marginLeft: '8px', marginRight: '8px' }}>
                                {measurement === "Yearly" ? selectedYear :
                                    measurement === "Monthly" ? selectedYear + "-" + selectedMonth :
                                        measurement === "Daily" ? selectedYear + "-" + selectedMonth + "-" + (selectedDay) : null}
                            </Typography>
                            <Button
                                variant='outlined'
                                size={smallScreen ? "small" : "medium"}
                                onClick={handleNext}>
                                <NavigateNextIcon />
                            </Button>
                        </div>
                    </Grid>

                    <Grid item md={5} sm={12} style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                        <div>
                            <Button
                                disabled={loading}
                                style={{ margin: '5px' }}
                                size={"small"}
                                value="Yearly"
                                variant="contained"
                                color={measurement !== "Yearly" ? "secondary" : "primary"}
                                onClick={handleMeasurementChange}
                            >Yearly</Button>
                            <Button
                                disabled={loading}
                                style={{ margin: '5px' }}
                                size={"small"}
                                value="Monthly"
                                variant="contained"
                                color={measurement !== "Monthly" ? "secondary" : "primary"}
                                onClick={handleMeasurementChange}
                            >Monthly</Button>
                            <Button
                                disabled={loading}
                                style={{ margin: '5px' }}
                                size={"small"}
                                value="Daily"
                                variant="contained"
                                color={measurement !== "Daily" ? "secondary" : "primary"}
                                onClick={handleMeasurementChange}
                            >Daily</Button>
                        </div>
                    </Grid>
                </Grid>

                <Divider style={smallScreen ? { margin: '5px' } : { margin: '10px' }}></Divider>

                <Grid container spacing={2}>
                    
                    <Grid item xs={6}>
                        {smallScreen ? null : <Typography noWrap variant={smallScreen ? null : 'h6'}>Sales Details</Typography>}
                    </Grid>
                    <Grid item xs={6}>
                    {smallScreen ? null :<Typography noWrap variant={smallScreen ? null : 'h6'}>Delivery Details</Typography>}
                    </Grid>
                    <Grid item xs={smallScreen ? 3 : 3} >
                        <Typography noWrap variant={smallScreen ? null : 'body1'}>{smallScreen?'Deliveries:':'Total Deliveries:'}</Typography>
                    </Grid>
                    <Grid item xs={smallScreen ? 2 : 3}>
                        {loading || filteredOrders.length <=0 ?
                        <Skeleton variant="text" width="30%" style={{marginLeft:'75%'}}></Skeleton> :
                        <Typography align='right' variant={smallScreen ? null : 'body1'}>{calcTotalDeliveries}</Typography>}
                    </Grid>
                    <Grid item xs={smallScreen ? 3 : 3} >
                        <Typography noWrap variant={smallScreen ? null : 'body1'}>{smallScreen?'Orders:':'Total Orders:'}</Typography>
                    </Grid>
                    <Grid item xs={smallScreen ? 2 : 3}>
                        {loading || filteredOrders.length <=0 ?
                        <Skeleton variant="text" width="30%" style={{marginLeft:'75%'}}></Skeleton> :
                        <Typography align='right' variant={smallScreen ? null : 'body1'}>{filteredOrders.length}</Typography>}
                    </Grid>
                    <Grid item xs={smallScreen ? 3 : 3} >
                        <Typography noWrap variant={smallScreen ? null : 'body1'}>{smallScreen?'Pickups:':'Total Pickups:'}</Typography>
                    </Grid>
                    <Grid item xs={smallScreen ? 2 : 3}>
                        {loading || filteredOrders.length <=0 ?
                        <Skeleton variant="text" width="30%" style={{marginLeft:'75%'}}></Skeleton> :
                        <Typography align='right' variant={smallScreen ? null: 'body1'}>{calcTotalPickups}</Typography>}
                    </Grid>
                    {!smallScreen ?
                        <React.Fragment>
                        <Grid item xs={smallScreen ? 3 : 3} >
                            <Typography noWrap variant={smallScreen ? null : 'body1'}><b>Total Sales:</b></Typography>
                        </Grid>
                        <Grid item xs={smallScreen ? 2 : 3}>
                        {loading || filteredOrders.length <=0 ?
                            <Skeleton variant="text" width="60%" style={{marginLeft:'40%'}}></Skeleton> :
                            <Typography style={{ color: '#009136' }} align='right' variant={smallScreen ? null : 'body1'}><b>{loading ? "Calculating.." : '$' + totalSales.toFixed(2)}</b></Typography>
                            }
                        </Grid>
                        </React.Fragment>
                        : null}
                </Grid>
                {smallScreen ?
                    <Grid container spacing={2}>
                        <Grid item xs={smallScreen ? 4 : 3} >
                            <Typography noWrap variant={smallScreen ? null : 'body1'}><b>Total Sales:</b></Typography>
                        </Grid>
                        <Grid item xs={smallScreen ? 2 : 3}>
                            {loading || filteredOrders.length <=0 ?
                            <Skeleton variant="text" width="60%" style={{marginLeft:'40%'}}></Skeleton> :
                            <Typography style={{ color: '#009136' }} align='right' variant={smallScreen ? null : 'body1'}><b>{loading ? "Calculating.." : '$' + totalSales.toFixed(2)}</b></Typography>
                            }
                        </Grid>
                    </Grid> : null }
            </Card>
        </React.Fragment >
    );
};

export default memo(OrderSummary);