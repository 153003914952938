import { Grid, Typography, Modal, Divider, Paper} from '@material-ui/core'
import React, { useEffect } from 'react';
import { Marker, MarkerClusterer} from '@react-google-maps/api';
import { GoogleMap, useLoadScript } from '@react-google-maps/api';
import truck_COMPLETE from '../../../../img/geeteetruckCOMPLETE.png';
import truck_PROCESSING from '../../../../img/geeteePROCESSING.gif';
import truck_CANCELLED from '../../../../img/geeteeCANCELLED.png';
import appLogo from '../../../../img/geeteeapp.png';
const libraries = ["places"];

const OrdersMap = (props) => {

    const [map, setMap] = React.useState({});
    const markers = [];

    //Google Maps API setup
    const { isLoaded, loadError } = useLoadScript({
        googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
        libraries,
    });

    const mapContainerStyle = {
        width: '100%',
        height: '750px',
    };

    let filteredOrders = !props.showComplete && !props.showCancelled ? props.orderData?.filter(order => order?.order?.status !== 'COMPLETED' && order?.order?.status !== 'CANCELLED')  
    : props.showComplete && !props.showCancelled ? props.orderData?.filter(order => order?.order?.status !== 'CANCELLED') : !props.showComplete && props.showCancelled ? props.orderData?.filter(order => order?.order?.status !== 'COMPLETED')
    : props.orderData

    useEffect(() => {

        // eslint-disable-next-line
    }, [props.showComplete, props.showCancelled])
    //Error checking on googlemaps api
    if (loadError) return "Error";
    if (!isLoaded) return "";
    
    

    filteredOrders.filter(order => order?.order?.products?.filter(product => (product.shippingMethod === 'Delivery')).length > 0 && order.customer.destination.coordinates.latitude !== '' && order.customer.destination.coordinates.longitude !== '')
    .forEach((order, index) => { 
        markers.push(
            <Marker key={index} position={{ lat: order.customer.destination.coordinates.lat, lng: order.customer.destination.coordinates.lng }}
                icon={order.order.status === 'COMPLETED' ? {url: truck_COMPLETE, labelOrigin: new window.google.maps.Point(25,5)} : order.order.status === 'PROCESSING' ? {url: truck_PROCESSING, labelOrigin: new window.google.maps.Point(25,5)}  : order.order.status === 'CANCELLED' ? {url: truck_CANCELLED, labelOrigin: new window.google.maps.Point(25,5)}  : {url: truck_PROCESSING, labelOrigin: new window.google.maps.Point(25,5) } }
                title={`Order #${order.order?.orderID?.toString()}`} 
                label={{
                    text: `Order #${order.order?.orderID?.toString()}`,
                    color: '#fff',
                    fontSize: '12px',
                    fontWeight: 'bold',
                    fontFamily: 'Roboto',
                }}
                onClick={() => {
                    new window.google.maps.InfoWindow({
                        content: `<div>
                            <h3>Order #${order.order?.orderID?.toString()}</h3>
                            <p>$${order.order.fees.total}</p>
                            <p>${order.order.status}</p>
                        </div>`,
                        maxWidth: '300px',
                        maxHeight: '300px',
                        position: { lat:  Number(order.customer.destination.coordinates.lat), lng: Number(order.customer.destination.coordinates.lng) },
                        pixelOffset: { width: 0, height: -20 },
                        zIndex: 100,
                    }).open(map);
                }}
            />
        )
    }
    )


    return (
        <Modal
        open={props.mapModal}
        onClose={() => props.setMapModal(false)}
        style={{alignItems: 'center', display: 'flex', justifyContent: 'center'}}
        >
            <Paper style={{width:'75%', padding:'25px'}}>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <Typography variant="h6" id="modal-title">Order Map</Typography>
                        <Divider />
                    </Grid>
                        
                    <Grid item xs={12}>
             <GoogleMap
                mapContainerStyle={mapContainerStyle}
                mapTypeId='satellite'
                center={{ lat: 49.904521, lng: -96.932222 }}
                zoom={18}
                onLoad={map => {
                    setMap(map);
                    if (markers) {
                        const bounds = new window.google.maps.LatLngBounds();
                        markers.forEach((element) => bounds.extend(element.props.position));
                        map.fitBounds(bounds);
                    }
                }}

                onBoundsChanged={() => {
                    if (map) {
                        let zoom = map.getZoom();

                        //21 is the current zoom cap for satellite view
                        if (zoom > 21 && map.mapTypeId === 'satellite')
                            map.setZoom(21);
                    }
                }}
            >
                
                <Marker position={{ lat: 49.904521, lng: -96.932222 }}
                icon={{url: appLogo, labelOrigin: new window.google.maps.Point(24,5)}} title="Gee Tee Home" label="Gee Tee"
                onClick={() => {
                    new window.google.maps.InfoWindow({
                        content: `<div>
                            <img src=${appLogo}></img>
                            <h3>Gee Tee Holdings</h3>
                            <p>Complete: ${filteredOrders.filter(order => order?.order?.products?.filter(product => (product.shippingMethod === 'Delivery')).length > 0).filter(
                                order => order?.order?.status === 'COMPLETED').length}</p>
                            <p>Processing: ${filteredOrders.filter(order => order?.order?.products?.filter(product => (product.shippingMethod === 'Delivery')).length > 0).filter(
                                order => order?.order?.status === 'PROCESSING').length}</p>
                            <p>Cancelled: ${filteredOrders.filter(order => order?.order?.products?.filter(product => (product.shippingMethod === 'Delivery')).length > 0).filter(
                                order => order?.order?.status === 'CANCELLED').length}</p>
                            <p>Total: ${filteredOrders.filter(order => order?.order?.products?.filter(product => (product.shippingMethod === 'Delivery')).length > 0).length}</p>
                        </div>`,
                        maxWidth: '300px',
                        maxHeight: '300px',
                        position: { lat: 49.904521, lng: -96.932222  },
                        pixelOffset: { width: 0, height: -20 },
                        zIndex: 100,
                    }).open(map);
                }}
                />
                <MarkerClusterer
                    averageCenter
                    enableRetinaIcons
                    minimumClusterSize={2}
                    gridSize={60}
                    >
                    {clusterer =>
                        markers.map((marker, i) => (
                        <Marker key={i} position={marker.props.position} clusterer={clusterer}      
                            icon={marker.props.icon}
                            title={marker.props.title} 
                            label={marker.props.label}
                            onClick={marker.props.onClick}
                        />
                        ))
                    }
                    </MarkerClusterer>
                </GoogleMap>
            </Grid>
            </Grid> 
        </Paper>
    </Modal>

    )

}
export default OrdersMap