import React, { useState } from 'react'
import { Grid, Paper, Button, makeStyles, TextField, Typography, InputAdornment, IconButton, Divider  } from '@material-ui/core'
import DeleteIcon from '@material-ui/icons/Delete';
import { Skeleton } from '@material-ui/lab'


const useStyles = makeStyles((theme) => ({
    card: {
        padding: theme.spacing(2),
        width: '100%',
    },

}));

const TimesheetAdditionalCosts = (props) => {

    const classes = useStyles()

    const [newCost, setNewCost] = useState({
        'name': '',
        'cost': '0',
        'address': '',
        'job': '',
    });

    React.useEffect(() => {

    }, [props.timesheet, ])

    
    const handleRemoveCost = (index) => {
        props.setAdditionalCostsLoading(true)

        let containerCosts = props.timesheet.additionalCosts ? props.timesheet.additionalCosts : [];
        containerCosts.splice(index, 1)
        props.updateTimesheetField({'additionalCosts': containerCosts})
        setTimeout(() => {
            props.setAdditionalCostsLoading(false)
        },  100);
    }
        
    const handleAddCost = () => {

        let containerCosts = props.timesheet.additionalCosts ? props.timesheet.additionalCosts : [];
        containerCosts.splice(containerCosts.length, 0, newCost)
        props.updateTimesheetField({'additionalCosts': containerCosts})

        setNewCost({
            'name': '',
            'cost': '0',
            'address': '',
            'job': '',
        })
    }

    return (
        <Paper className={classes.card}>
            <Grid container direction='column' spacing={1}>
 
                <Grid item xs={12}>
                    <Typography variant='h5'>Additional Costs</Typography>
                </Grid>
                <Divider style={{marginBottom:'20px', width:'75%'}}></Divider>

                <Grid container spacing={1} style={{marginBottom:'10px'}}>
                    <Grid item xs={2}>
                        <TextField label='Name' disabled={props.additionalCostsLoading} InputLabelProps={{ shrink: true }}
                         placeholder="Cost Name" value={newCost.name} helperText={newCost.name.length <=0 ? "Enter the name of the cost" : null}  
                                    onChange={(event) => setNewCost({...newCost, 'name': event.target.value})}></TextField>
                    </Grid>
                    <Grid item xs={2}>
                        <TextField label='Cost' disabled={props.additionalCostsLoading} placeholder="0.00" value={newCost.cost} helperText={newCost.cost <=0 ? "Enter the cost" : null}  
                                    onChange={(event) => setNewCost({...newCost, 'cost': event.target.value})}
                                    InputProps={{type: "number", startAdornment: <InputAdornment position="start">$</InputAdornment>}}
                                    ></TextField>
                    </Grid>
                    <Grid item xs={2}>
                        <TextField label='Job' disabled={props.additionalCostsLoading} InputLabelProps={{ shrink: true }}
                            placeholder="Job" value={newCost.job} helperText={newCost.job.length <=0 ? "Enter the job the cost came from" : null}  
                                        onChange={(event) => setNewCost({...newCost, 'job': event.target.value})}></TextField>
                    </Grid>
                    <Grid item xs={2}>
                        <TextField label='Address' disabled={props.additionalCostsLoading} InputLabelProps={{ shrink: true }}
                            placeholder="Address" value={newCost.address} helperText={newCost.address.length <=0 ? "Enter the address the cost came from" : null}  
                                        onChange={(event) => setNewCost({...newCost, 'address': event.target.value})}></TextField>
                    </Grid>
                    <Grid item xs={2}>
                        <Button disabled={props.additionalCostsLoading || newCost.name.length <=0 || newCost.cost <= 0} variant={'contained'} style={{float: 'right', top:'10px'}} 
                                onClick={() => handleAddCost()} >Add Cost</Button>
                    </Grid>

                </Grid>
                <Divider style={{width:'50%'}}></Divider>

                    { props.timesheet.additionalCosts ?
                        <React.Fragment>
                        {props.timesheet.additionalCosts.map((cost, index) => {
                            return(
                                <Grid key={index} container spacing={1} style={{margin: '4px 5px 5px 20px'}}>
                                    <Grid item xs={2}>
                                        <TextField defaultValue={props.timesheet.additionalCosts[index].name} label="Name" size={'small'} required variant={"outlined"}
                                                    onBlur={(event) => {
                                                        let container = [...props.timesheet.additionalCosts];
                                                        container[index].name = event.target.value;
                                                        props.updateTimesheetField({ ...props.timesheet, 'additionalCosts': container })
                                                    }}>
                                            
                                        </TextField>
                                    </Grid>
                                    <Grid item xs={2}>
                                        <TextField defaultValue={props.timesheet.additionalCosts[index].cost} label="Cost" required size={'small'} variant={"outlined"}
                                                    InputProps={{
                                                        startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                                        type: "number", 
                                                    }}
                                                    onBlur={(event) => {
                                                        let container = [...props.timesheet.additionalCosts];
                                                        container[index].cost = event.target.value;
                                                        props.updateTimesheetField({ ...props.timesheet, 'additionalCosts': container })
                                                    }}>
                                        </TextField>
                                    </Grid>
                                    <Grid item xs={2}>
                                        <TextField defaultValue={props.timesheet.additionalCosts[index].job} label="Job" size={'small'} variant={"outlined"}
                                                    onBlur={(event) => {
                                                        let container = [...props.timesheet.additionalCosts];
                                                        container[index].name = event.target.value;
                                                        props.updateTimesheetField({ ...props.timesheet, 'additionalCosts': container })
                                                    }}>
                                            
                                        </TextField>
                                    </Grid>
                                    <Grid item xs={2}>
                                        <TextField defaultValue={props.timesheet.additionalCosts[index].address} label="Address" size={'small'} variant={"outlined"}
                                                    onBlur={(event) => {
                                                        let container = [...props.timesheet.additionalCosts];
                                                        container[index].name = event.target.value;
                                                        props.updateTimesheetField({ ...props.timesheet, 'additionalCosts': container })
                                                    }}>
                                            
                                        </TextField>
                                    </Grid>
                                    <Grid item xs={2}>
                                        <IconButton style={{bottom:'5px'}} onClick={(event) => {handleRemoveCost(index)} }>
                                            <DeleteIcon></DeleteIcon>
                                        </IconButton>
                                    </Grid>
                                </Grid>
                                )
                            })
                        }
                        </React.Fragment>
                        : props.additionalCostsLoading ?
                        <Skeleton variant='rect' width={'70vw'} height={'8vh'} style={{marginTop: '16px', borderRadius: '5px'}}/>
                        : !props.additionalCostsLoading ?
                        <React.Fragment>
                            
                            <Grid container>
                            <Divider/>
                            <Typography style={{margin:'5px' }} variant='body1'>No additional costs found.</Typography>
                            </Grid>
                            <Divider/>
                         
                        </React.Fragment> : <Skeleton variant='rect' width={'70vw'} height={'8vh'} style={{marginTop: '16px', borderRadius: '5px'}}/>
                    }  
          
            </Grid>
        </Paper>
    )
}
export default TimesheetAdditionalCosts