import React, {useEffect, useState, useCallback } from 'react';
import { makeStyles } from '@material-ui/core';
import { withRouter } from 'react-router';
import { firestore } from '../../../firebase/firebase';
import { Skeleton } from '@material-ui/lab';
import { useAuth } from '../../../providers/AuthContext';
import { useUser } from '../../../providers/UserContext';
import moment from 'moment';
import _ from 'lodash';

import RatesCard from '../../components/Rates/RatesCard';

const useStyles = makeStyles((theme) => ({

  card: {
    padding: theme.spacing(4),
    width: '100%',
    height: '100%',
  },
  content: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    padding: '32px',
  },
  grid: {
    marginTop: theme.spacing(2),
    maxWidth: '1200px',
  },
  table: {
    display: 'flex',
    justifyContent: 'center',
  },
  btn: {
    marginBottom: '20px',
    marginTop: '20px',
},
}));

const Rates = (props) => {
    const classes = useStyles();
    const { currentUser } = useAuth(); 
    const { getUsers, users } = useUser();

    const [ratesForm, setRatesForm] = useState({})
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState("");
    const [saving, setSaving] = useState(false);
    const [saveState, setSaveState] = useState({});

    const initialRateForm = {
        name: '',
        description: '',
        rate: '',
        dateCreated: null,
        dateUpdated: null,
        uidLastUpdated: null,
    }

    //gets initial data
    useEffect(() => {
        getUsers();

        if(path !== 'new'){
            firestore.collection('rates').where('__name__', "==", path).get()
            .then(querySnapshot => {
                const ratesResponse = querySnapshot.docs.map((doc) => {
                    return {
                        ...doc.data(),
                        'docId': doc.id,
                    }
                })
                setRatesForm(ratesResponse[0]);
                setSaveState(ratesResponse[0]);
                setLoading(false);
            })
            .catch(e => {
                setError("Rate not found.");
                setLoading(false);
                console.log(e.message);
            })
        }
        else{
            setRatesForm(initialRateForm)
            setSaveState(initialRateForm);
            setLoading(false);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.location.search]);

    let path = new URLSearchParams(props.location.search).get("docId");

    const setRatesFormCallback = useCallback((e) => {
            setRatesForm(e)
        },
        [],
    );

    const saveRatesForm = (e) => {
        e.preventDefault();
        
        setSaving(true);
        let container = _.cloneDeep(ratesForm)
        container.rate = Number(container.rate).toFixed(2)
        container.dateCreated = moment().format('YYYY-MM-DD');
        container.dateUpdated = moment().format('YYYY-MM-DD');
        container.uidLastUpdated = currentUser.uid;

        firestore.collection('rates').add(container)
            .then((res) => {
                props.history.replace({
                    pathname: '/dashboard/rates/form',
                    search: "?" + new URLSearchParams({ docId: res.id }).toString(),
                })
                setTimeout(() => { setSaving(false)  }, 500)
            }).catch((e) => setError(e));
    };

    const updateRatesForm = () => {
        setSaving(true);
        let container = _.cloneDeep(ratesForm)
        container.dateUpdated = moment().format('YYYY-MM-DD');
        container.uidLastUpdated = currentUser.uid;

        firestore.collection('rates').doc(container.docId)
            .update(container)
            .then(() => {
                setSaveState(container);
                setTimeout(() => { setSaving(false)  }, 500)
            }).catch((e) => setError(e));
    };

    const cancelSave = () => {
        setRatesForm(saveState);
    };

    const deleteRate = () => {
        firestore.collection('rates').doc(ratesForm.docId).delete()
        .then(() => {
            props.history.push({
                pathname: '/dashboard/rates',
            })
        }).catch((e) => setError(e));
    }

    return (
        <div>
        {loading 
            ? 
            <div className={classes.content}>
                <Skeleton variant='rect' width={'40vw'} height={'45vh'}/>
            </div>
            : 
            <React.Fragment>
                <RatesCard 
                    loading={loading}
                    saving={saving}
                    error={error}

                    path={path}
                    setRatesFormCallback={setRatesFormCallback}
                    saveRatesForm={saveRatesForm}
                    cancelSave={cancelSave}
                    updateRatesForm={updateRatesForm}
                    deleteRate={deleteRate}

                    saveState={saveState}
                    ratesForm={ratesForm}
                    users={users}
                /> 
            </React.Fragment>
        }
    </div>
    )
}

export default withRouter(Rates);