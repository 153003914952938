import React from 'react';
import { makeStyles } from '@material-ui/core';
import { Grid, Paper, Typography } from '@material-ui/core';
import { withRouter } from 'react-router-dom';
import MaterialTable from '@material-table/core';
import moment from 'moment'

const useStyles = makeStyles((theme) => ({
    card: {
        padding: theme.spacing(4),
        width: '100%',
        height: '100%',
    },
    content: {
        display: 'flex',
        justifyContent: 'center',
    },
    mapCheckbox: {
        position: 'absolute',
        bottom: '40px',
        zIndex: '1000',
        backgroundColor: 'white',
        left: '32px',
        paddingRight: '8px',
        borderRadius: '4px',
    },
    paper: {
        maxWidth: '1200px',
        padding: theme.spacing(4),
        margin: theme.spacing(4),
        //backgroundColor: 'rgb(252, 252, 249)',
    },
    text: {
        color: '#707070',
    },
}));

const CovidCard = (props) => {

    const classes = useStyles()

    return (
        <div className={classes.content}>
            <Paper className={classes.paper}>
                <Grid container spacing={2}>
                    <Grid style={{ marginBottom: '32px' }} item sm={8} xs={12}>
                        <Typography variant='h5'>{props.userName}</Typography>
                        <Typography variant='h6'>{moment(props.covidData.submitTime).format('MMM D YYYY, h:mm a')}</Typography>
                        <Typography variant='h6'>{props.covidData.symptoms ? "SYMPTOMS REPORTED" : "No Symptoms"}</Typography>
                        <Typography variant='subtitle1'>{props.errorMessage}</Typography>
                    </Grid>

                    <Grid item xs={12} sm={12}>
                        <MaterialTable
                            columns={[
                                { title: 'question', field: 'item' },
                                { title: 'checked', align: 'right', render: rowData => <span style={{ width: 40, borderRadius: '50%' }}>{rowData.checked ? "Yes" : "No"}</span> },
                            ]}
                            title='Have you experienced any of the following symptoms?'
                            data={props.covidData.questions}
                            options={{ search: false, header: false, paging: false }}
                        />
                    </Grid>

                </Grid>
            </Paper>
        </div>
    )
}

export default withRouter(CovidCard)