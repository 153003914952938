import React from 'react';
import { makeStyles, CircularProgress,  } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import DatePicker from '../UI/DatePicker/DatePicker';
import TextField from '@material-ui/core/TextField';
import { Autocomplete } from '@material-ui/lab';
import moment from 'moment';

const useStyles = makeStyles((theme) => ({
    btnGroup: {
        width: '100%',
    },

    filterItem: {
        marginTop: '4px',
        marginBottom: '4px',
    },

    search: {
        display: 'flex',
        boxShadow: 'inset 0px 0px 2px 2px rgba(207,207,207,0.49)',
        //backgroundColor: '#fafafa',
        borderRadius: '4px',
        alignItems: 'center',
        justifyContent: 'center',
        padding: theme.spacing(2),
    },
}));

const InvoiceOptions = (props) => {

    const classes = useStyles();

    return (
        <Grid container spacing={2}>
            <Grid item sm={12}>
                <Grid container className={classes.search}>
                    <Grid item sm={12}>
                        <Typography>Search By:</Typography>
                    </Grid>
                    
                    <Grid item sm={12}>
                        <Autocomplete
                            open={props.customerOpen}
                            onOpen={() => { props.setCustomerOpen(true); }}
                            onClose={() => { props.setCustomerOpen(false); }}
                            loading={props.customerLoading}
                            label="Customer"
                            size='small'
                            id="autocomplete"
                            autoComplete
                            autoHighlight
                            //disableClearable
                            fullWidth
                            value={props.options.customer}
                            onChange={(event, newValue) => {  props.handleChangeOrderOption('customer', newValue) }}
                            options={props.customers.sort((a, b) => a.name.localeCompare(b.name)) || {}}
                            getOptionLabel={(option) => option.name ? option.name + ' - ' + option.streetNumber + ' ' + option.streetName : ''  }
                            getOptionSelected={(option, value) =>  option.name === value.name || {} }
                            renderOption={(option) =>( 
                                props.customers.filter(customer => customer.docId === option.docId).map((customerObj) => {
                                    return(
                                        <Grid key={option.docId} >
                                            <Typography>{customerObj.name}</Typography>
                                            <small>{ customerObj.streetNumber + ' ' + customerObj.streetName}</small>
                                        </Grid>
                                    )
                                })
                            )}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label="Customer"
                                    variant="outlined"
                                    size='small'
                                    InputProps={{
                                        ...params.InputProps,
                                        endAdornment: (
                                            <React.Fragment>
                                                {props.loading ? <CircularProgress color="inherit" size={20} /> : null}
                                                {params.InputProps.endAdornment}
                                            </React.Fragment>
                                        ),
                                    }}
                                />
                            )}
                        />
                    </Grid>
                </Grid>
            </Grid>


            <Grid item sm={12}>
                <Grid container className={classes.search}>

                    <Grid item sm={12} className={classes.filterItem}>
                        <DatePicker
                            label="Start Date"
                            value={props.options.startDate}
                            onChange={(e, date) => { props.handleChangeOrderOption('startDate', moment(date).valueOf()) }}
                        />
                    </Grid>

                    <Grid item sm={12} className={classes.filterItem}>
                        <DatePicker
                            label="End Date"
                            value={props.options.endDate}
                            onChange={(e, date) => { props.handleChangeOrderOption('endDate', moment(date).valueOf()) }}
                        />
                    </Grid>

                </Grid>
            </Grid>

            <Grid item sm={12}>
                {(props.errorMsg && <Typography>{props.errorMsg}</Typography>)}
            </Grid>
            <Grid item sm={12} className={classes.btnGroup}>
                <Button variant="contained" fullWidth disabled={props.loading} onClick={props.generateHandler} > Generate Invoices </Button>
            </Grid>

        </Grid>
    )
}

export default InvoiceOptions;