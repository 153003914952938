import React, { useEffect, useState } from 'react';
import { Grid, makeStyles,Button, Paper, Typography, IconButton, TextField, CircularProgress, Divider, Snackbar } from '@material-ui/core';
import { withRouter } from 'react-router';
import { useUser } from '../../../providers/UserContext';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import { Skeleton } from '@material-ui/lab';
import { Autocomplete } from '@material-ui/lab';
import { firestore } from '../../../firebase/firebase';
import AddBoxIcon from '@material-ui/icons/AddBox';
import DeleteIcon from '@material-ui/icons/Delete';
import _ from 'lodash'

const useStyles = makeStyles((theme) => ({
    content: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        maxWidth: '792px',
        margin: 'auto',
    },
    skeletonContent: {
        display: 'flex',
        justifyContent: 'center',
        width: '100%',
        marginTop: '75px',
    },
    backButton: {
        marginBottom: '20px',
        marginTop: '20px',
    },
    paper: {
        borderRadius: '0px',
        margin: '0px',
        width: '792px',
        height: 'auto',
    }, 
    table: {
        margin: '15px',
    }, 
    
}));

const UserRates = (props) => {
    const classes = useStyles();
    const { users, userLoading, getUsers } = useUser();

    const [filteredUsers, setFilteredUsers] = useState([])
    const [loading, setLoading] = useState(true);
    const [currentUser, setCurrentUser] = useState(null);
    //const [typedValue, setTypedValue] = useState()
    const [open, setOpen] = useState(false);
    const [saveState, setSaveState] = useState()
    const [saving, setSaving] = useState(false)

    useEffect(() => {
        getUsers();
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    //filters and sorts users for use across the page
    useEffect(() => {
        setFilteredUsers(users.filter(x=> x.employment?.active).sort((a, b) => a.displayName?.toLowerCase() > b.displayName?.toLowerCase()))
    }, [users])

    useEffect(() => {
        if((currentUser === null || currentUser === undefined) && (saveState === null || saveState === undefined)){
            setCurrentUser(_.cloneDeep(filteredUsers[0]));
            setSaveState(_.cloneDeep(filteredUsers[0]));
        }
    }, [filteredUsers]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if(currentUser !== null || currentUser !== undefined){ setLoading(false) }
    }, [currentUser]) // eslint-disable-line react-hooks/exhaustive-deps

    const handleChangeUserByArrow = (value) => {
        let newUser = filteredUsers[filteredUsers.findIndex(user => {return user.uuid === currentUser.uuid}) + value]
        setCurrentUser(_.cloneDeep(newUser))
        setSaveState(_.cloneDeep(newUser))
    }

    const updateRate = (event, field, index) => {
        let newUserData = {...currentUser}; 
        newUserData.rates[index][field] = event.target.value;
        setCurrentUser(newUserData);
    }

    const createRate = () =>  {
        let newRate = {
                name: '',
                per: '',
                rate: '',
                typeofrate: "Payroll Rate",
                
        }
        let newUserData = {...currentUser}; 
        newUserData.rates ? newUserData.rates.push({...newRate}) : newUserData.rates = [newRate]
        setCurrentUser(newUserData);
    }

    const deleteRate = (index) => {
        let newUserData = {...currentUser}; 
        newUserData.rates.splice(index, 1);
        setCurrentUser(newUserData);
    }

    const confirmSave = () => {
        setSaving(true);
        console.log('saving')
        firestore.collection('users').doc(currentUser.docId).update(currentUser).then(() => {
            setSaveState(_.cloneDeep(currentUser));
            getUsers();
            setSaving(false)
        })
        .catch((e) => {console.log(e);});
    };

    const cancelSave = () => {
        setCurrentUser(_.cloneDeep(saveState));
    };

  return (
    <div>
        {loading
        ? 
        <Grid className={classes.skeletonContent}>
            <Skeleton variant='rect' width={'40vw'} height={'80vh'}/>
        </Grid>
        : 
        <Grid className={classes.content}>
            <Grid className={classes.backButton}>
                <Button onClick={() => props.history.goBack()}
                variant={"contained"} startIcon={<ArrowBackIcon/>}>Back</Button>
            </Grid>
            
            <Paper className={classes.paper}>
                <Grid container>
                    <Grid container>
                        <Grid item xs={4}> 
                            <Typography variant="h6" style={{margin: '20px', }}>{currentUser?.firstName + " " + currentUser?.lastName}</Typography>
                        </Grid>

                        <Grid item xs={8}>
                            <Grid container style={{display: 'flex', justifyContent: 'flex-end', marginRight: '50px', marginTop: '10px'}}> 
                                <Grid>
                                    <IconButton 
                                    disabled = {filteredUsers.findIndex(user => {return user.uuid === currentUser?.uuid}) === 0 || !_.isEqual(currentUser, saveState)}
                                    onClick={() => handleChangeUserByArrow(-1)}
                                    >
                                        <ArrowBackIcon/>
                                    </IconButton>
                                </Grid>

                                <Grid >
                                    <Autocomplete
                                    open={open}
                                    onOpen={() => {
                                        setOpen(true);
                                    }}
                                    onClose={() => {
                                        setOpen(false);
                                    }}
                                    loading={loading}
                                    label="Search"
                                    id="autocomplete"
                                    style={{minWidth: '200px'}}
                                    autoComplete
                                    disableClearable
                                    disabled={!_.isEqual(currentUser, saveState)}
                                    autoHighlight
                                    //inputValue={typedValue}
                                    //onInputChange={(event, newValue) => { setTypedValue(newValue); }}
                                    value={currentUser?.displayName ?? ''}
                                    onChange={(event, newValue) => { 
                                        let newUser = filteredUsers.find(user => user.displayName === newValue)
                                        setCurrentUser(_.cloneDeep(newUser)); 
                                        setSaveState(_.cloneDeep(newUser));
                                    }}
                                    options={filteredUsers.map((user) => user.displayName ?? '')}
                                    getOptionSelected={(option, value) => option?.displayName === value?.displayName}
                                    getOptionLabel={(option) => option}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            //label="Users"
                                            variant="outlined"
                                            InputProps={{
                                                ...params.InputProps,
                                                endAdornment: (
                                                    <React.Fragment>
                                                        {userLoading ? <CircularProgress color="inherit" size={20} /> : null}
                                                        {params.InputProps.endAdornment}
                                                    </React.Fragment>
                                                ),
                                            }}
                                        />
                                        )}
                                        />
                                </Grid>

                                <Grid>
                                <IconButton 
                                    disabled = {filteredUsers.findIndex(user => {return user.uuid === currentUser?.uuid}) === (filteredUsers.length - 1) || !_.isEqual(currentUser, saveState)}
                                    onClick={() => handleChangeUserByArrow(1)}
                                    >
                                        <ArrowForwardIcon/>
                                    </IconButton>
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid container className={classes.table} style={{marginTop: '25px'}}>
                            <Grid item xs={6}>
                                <Grid container>
                                    <Typography variant='h6'>
                                        Rates
                                    </Typography>
                                </Grid>
                            </Grid>

                            <Grid item xs={6}>
                                <Grid container style={{display: 'flex', justifyContent: 'flex-end'}}>
                                    <Button 
                                        variant='contained' 
                                        onClick={() => createRate()} 
                                        startIcon={<AddBoxIcon/>} 
                                    >   
                                        Add
                                    </Button>
                                </Grid>
                            </Grid>

                            <Grid item xs={12}>
                                <Grid container style={{marginTop: '20px',}}>
                                    <Grid item xs={3}>
                                        <Typography style={{fontWeight: '500'}}>Type</Typography>
                                    </Grid>
                                    <Grid item xs={3}>
                                        <Typography style={{fontWeight: '500'}}>Name</Typography>
                                    </Grid>
                                    <Grid item xs={3}>
                                        <Typography style={{fontWeight: '500'}}>Per</Typography>
                                    </Grid>
                                    <Grid item xs={2}>
                                        <Typography style={{fontWeight: '500'}}>Rate</Typography>
                                    </Grid>
                                    <Grid item xs={1}>
                                        <Typography style={{fontWeight: '500', float:'right'}}>Action</Typography>
                                    </Grid>
                                </Grid>
                                <Divider style={{marginTop: '10px', marginBottom: '10px'}}></Divider>

                                <Grid container>
                                    {currentUser?.rates?.map((rate, index) => {
                                        return (
                                            <Grid item xs={12} key={index}>
                                                <Grid container style={{marginTop: '20px',}}>
                                                    <Grid item xs={3}>
                                                        <Typography >{rate.typeofrate}</Typography>
                                                    </Grid>
                                                    <Grid item xs={3}>
                                                        <TextField
                                                            onChange={(event) => updateRate(event, 'name', index)} 
                                                            style={{width: '80%'}}
                                                            value={rate.name}
                                                        ></TextField>
                                                    </Grid>
                                                    <Grid item xs={3}>
                                                        <TextField
                                                            onChange={(event) => updateRate(event, 'per', index)} 
                                                            style={{width: '80%'}}
                                                            value={rate.per}
                                                        ></TextField>
                                                    </Grid>
                                                    <Grid item xs={2}>
                                                        <TextField
                                                            onChange={(event) => updateRate(event, 'rate', index)}  
                                                            style={{width: '80%'}}
                                                            value={rate.rate}
                                                        ></TextField>
                                                    </Grid>
                                                    <Grid item xs={1}>
                                                        <IconButton 
                                                            onClick={(event) => deleteRate(index)} 
                                                            style={{float:'right', marginRight: '5px', padding: '1px'}}
                                                        >    
                                                            <DeleteIcon />
                                                        </IconButton>
                                                    </Grid>
                                                </Grid>
                                                <Divider style={{marginTop: '5px', marginBottom: '5px'}}></Divider>
                                            </Grid>
                                        )
                                        })
                                    }
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Paper>
            <Snackbar
                open={!_.isEqual(currentUser, saveState)}
                message={saving ? 'Saving...' : 'Save Document?'}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center'
                }}
                action={
                    <React.Fragment>
                        {saving
                            ? null
                            : <React.Fragment>
                                <Button variant='text' color='primary' onClick={() => cancelSave()} style={{ marginLeft: '32px', marginRight: '8px' }}>Cancel</Button>
                                <Button variant='contained' onClick={() => confirmSave()}>Confirm</Button>
                            </React.Fragment>}
                    </React.Fragment>
                } />
        </Grid> }
    </div>
  )
}

export default withRouter(UserRates);