import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import CustomerTablePDF from '../../components/Reports/Customers/CustomerTablePDF';
import { Skeleton } from '@material-ui/lab';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    content: {
        display: 'flex',
        width: '100%',
        padding: '32px',
        justifyContent: 'center',
        marginTop: '40px',
    },
}));

function CustomerTablePrint(props) {
    const [customerData, setCustomer] = useState({});
    const [loading, setLoading] = useState([]);

    const classes = useStyles();

    useEffect(() => {
        setLoading(true);

        let data = props.location.state.data.sort((a, b) => { return a.name > b.name ? 1 : -1 })
        setCustomer(data)
        //setFullData(props.location.state)
        setLoading(false)
        
    }, [props.location.state]);

    return (
        loading
            ? 
            <div className={classes.content}>
                <Skeleton variant='rect' width={'63vw'} height={'80vh'} />
            </div>
            :
            <React.Fragment>
                <CustomerTablePDF
                    customerData={customerData}
                />
            </React.Fragment> 
    )
}

export default withRouter(CustomerTablePrint)