import React, { useState, useEffect } from 'react';
import { Grid, makeStyles } from '@material-ui/core';
import { withRouter } from 'react-router';
import { firestore } from '../../../firebase/firebase';
import PermitsTable from '../../components/Permits/PermitsTable';

const useStyles = makeStyles((theme) => ({

  card: {
    padding: theme.spacing(4),
    width: '100%',
    height: '100%',
  },

  content: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
  },

  grid: {
    marginTop: theme.spacing(2),
    maxWidth: '1200px',
  },

  table: {
    display: 'flex',
    justifyContent: 'center',
  },

  tile: {
    width: '300px',
    height: '300px',
  },

  tileContent: {
    display: 'flex',
    justifyContent: 'space-evenly',
    alignItems: 'center',
    flexDirection: 'column',
    width: '100%',
    height: '100%',
    padding: theme.spacing(4),
  },

  tileIcon: {
    color: 'rgba(0, 0, 0, 0.54)',
    width: '100px',
    height: '100px',
  },

  tileText: {
    fontSize: '1.10rem',
  },
  btn: {
    marginBottom: '20px',
    marginTop: '20px',
},
}));

const PermitsHome = (props) => {

  const [permitsData, setPermitsData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [searchBarValue, setSearchBarValue] = useState("permitsSearchBarValue" in sessionStorage ? JSON.parse(sessionStorage.getItem("permitsSearchBarValue")) : null)

  const classes = useStyles();

  useEffect(() => {
    firestore.collection('permits').orderBy('dateCreated').get()
      .then(querySnapshot => {
        const permitsResponse = querySnapshot.docs.map((doc) => {
          return {
            ...doc.data(),
            'docId': doc.id,
          }
        })
        setPermitsData(permitsResponse);
        setLoading(false);
      })
      .catch(e => {
        setError("Unable to Process");
        setLoading(false);
        console.warn(e.message);
      })
  }, []);

  const tileClickHandler = (path) => {
    props.history.push({
      pathname: 'permits/' + path,
    })
  };

  return (
    <div className={classes.content}>
      <Grid>
        <PermitsTable 
            loading={loading}
            error={error}
            permitsData={permitsData}
            searchBarValue={searchBarValue}
            setSearchBarValue={setSearchBarValue}
            tileClickHandler={tileClickHandler}
        />
      </Grid>
    </div>
  )
}

export default withRouter(PermitsHome);