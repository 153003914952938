import React from 'react';
import { makeStyles } from '@material-ui/core';
import moment from 'moment'
import { Button, Grid, Paper, Typography } from '@material-ui/core';
import { withRouter } from 'react-router-dom';
import InlineMediaViewer from '../../UI/MediaViewer/InlineMediaViewer'
import MaterialTable from '@material-table/core'
import { TableIcons } from '../../../../constants/tableIcons'
import _ from 'lodash'
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

const useStyles = makeStyles((theme) => ({
    card: {
        padding: theme.spacing(4),
        display: 'flex',
        alignItems: 'center',
        height: '100%',
    },
    content: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        maxWidth: '792px',
        margin: 'auto',
    },
    paper: {
        padding: theme.spacing(2),
        maxWidth: '792px',
        width: '100%',
        //backgroundColor: 'rgb(252, 252, 249)',
        justifyContent: 'center',
        alignItems: 'center',
    },
    row: {
        display: 'flex',
        alignItems: 'center',
        height: '100%',
    },
    backButton: {
        marginBottom: '20px',
        marginTop: '20px',
    },
}));


const DefectCard = (props) => {

    const classes = useStyles();
    
    let defectCard = null;

    if (!_.isUndefined(props.defectData)) {

        defectCard = (
            <Grid className={classes.content}>
                <Grid className={classes.backButton}>
                    <Button variant="contained" startIcon={<ArrowBackIcon />}
                       onClick={() => props.history.goBack()} >Back</Button>
                </Grid>
                <Paper className={classes.paper}>
                    <Grid container spacing={2}>
                        <Grid item xs={6} style={{ marginBottom: '32px' }}>
                            <Grid container>
                                <Grid item xs={12} className={classes.row}>
                                    <Typography variant='h4'>Defect</Typography>
                                </Grid>
                            </Grid>

                            <Typography variant='h6'>{props.userData[0]?.displayName}</Typography>
                            <Typography variant='h6'>{moment.unix(props.defectData?.submitTime?.seconds).format('YYYY-MM-DD h:mm a')}</Typography>
                        </Grid>

                        <Grid item xs={6} className={classes.row} style={{ justifyContent: 'flex-end' }}>
                            <Button 
                                variant='contained' 
                                onClick={()=> {
                                    props.history.push({
                                        pathname: '/dashboard/reporting/timesheets/unit',
                                        search: "?" + new URLSearchParams({ docId: props.unitData.docId }).toString(),
                                    }) }
                                }
                            >
                                View Related Unit
                            </Button>
                        </Grid>

                        <Grid item xs={6}>
                            <Paper className={classes.card}>
                                <Grid container spacing={2}>
                                    <Grid item xs={6} className={classes.row}>
                                        <Typography variant='body1'>Trailer ID:</Typography>
                                    </Grid>
                                    <Grid item xs={6} className={classes.row}>
                                        <Typography variant='body1'>{props.defectData.trailerID ? props.defectData.trailerID : "N/A"}</Typography>
                                    </Grid>
                                    <Grid item xs={6} className={classes.row}>
                                        <Typography variant='body1'>Unit Number:</Typography>
                                    </Grid>
                                    <Grid item xs={6} className={classes.row}>
                                        <Typography variant='body1'>{props.defectData.unitNumber ? props.defectData.unitNumber : ""}</Typography>
                                    </Grid>
                                </Grid>
                            </Paper>
                        </Grid>

                        <Grid item xs={6}>
                            <Paper className={classes.card}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} className={classes.row}>
                                        <Typography variant='body1'><strong>Notes</strong></Typography>
                                    </Grid>
                                    <Grid item xs={12} className={classes.row}>
                                        <Typography>{props.defectData.additionalDetails ? props.defectData.additionalDetails : ""}</Typography>
                                    </Grid>
                                </Grid>
                            </Paper>
                        </Grid>

                        <Grid item xs={12}>
                            <MaterialTable
                                columns={[
                                    { title: 'Key', field: 'key' },
                                    { title: 'Item', field: 'item' },
                                    { title: 'Defects', render: rowData => rowData.noDefect ? "No" : "Yes" },
                                ]}

                                data={props.defectData.defects}
                                title={'Pre Trip Items'}
                                icons={TableIcons}
                                localization={{
                                    header: {
                                        actions: 'Actions'
                                    }
                                }}
                                options={{
                                    padding: 'dense',
                                    toolbar: true,
                                    paging: false,
                                    actionsColumnIndex: -1,
                                    search: false,
                                    emptyRowsWhenPaging: false,
                                }}
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <InlineMediaViewer photoURLs={props.defectData.photoURLs} />
                        </Grid>
                    </Grid>
                </Paper>
            </Grid>
        )
    }

    return (
        <div className={classes.content}>
            {defectCard}
        </div>
    )
}

export default withRouter(DefectCard)