import React from 'react';
import { Grid, Select, MenuItem, Typography} from '@material-ui/core';
import { withRouter } from 'react-router'
import { makeStyles } from '@material-ui/core';  

const useStyles = makeStyles((theme) => ({

    content: {
        display: 'flex',
        width: '100%',
        flexDirection: 'column',
        maxWidth: '1000px',
        margin: 'auto',
    },
    rlSpacing: {
        paddingRight: theme.spacing(1),
        paddingLeft: theme.spacing(1),
    },
    layoutRL: {
       height: '22px',
    },
    layoutSideVehicle: {
        position: 'relative',
        justifyContent: 'center',
    },
    layoutFrontVehicle: {
        position: 'relative',
    },
    layoutRect: {
        display: 'flex',
        width: '80px',
        height: '50px',
        margin: '0px 5px 5px 5px',
    },
}));

const PassengerLayout = (props) => {

    const classes = useStyles();
                                
    return(
        <Grid>
            <Grid container style={{justifyContent: 'center'}} >
                <Grid >
                    <Grid container>
                        <Typography className={classes.layoutRect}></Typography>
                    </Grid>
                </Grid>
                <Grid >
                    <Grid container>
                        <Typography className={classes.layoutRect}></Typography>
                    </Grid>
                </Grid>
                <Grid >
                    <Grid container>
                        <Typography className={classes.layoutRect}></Typography>
                    </Grid>
                </Grid>
            </Grid>

            <Grid container style={{justifyContent: 'center'}}>
                <Grid >
                    {/* rightFront */}
                    <Select
                        id="rightFront"
                        onChange={(event) => props.equipmentData.tires 
                            ? props.equipmentData.tires.attachment 
                                ? props.updateEquipmentData({...props.equipmentData, tires: {...props.equipmentData.tires, attachment : {...props.equipmentData.tires.attachment, 'rightFront':  event.target.value} }})
                                : props.updateEquipmentData({...props.equipmentData, tires: {...props.equipmentData.tires, attachment : { 'rightFront':  event.target.value} }})
                            :  props.updateEquipmentData({...props.equipmentData, tires: {attachment : { 'rightFront':  event.target.value} }})
                        }
                        className={classes.layoutRect}
                        style={{backgroundColor: props.assignColor("attachment","rightFront")}}
                        disabled={props.edit ? false : true}
                        value={props.equipmentData.tires ? (props.equipmentData.tires.attachment ? (props.equipmentData.tires.attachment.rightFront ? props.equipmentData.tires.attachment.rightFront : "") : ""): ""}
                    >
                        <MenuItem name='poor' key="poor" value='poor' className={classes.layoutRect} style={{background: 'red'}}></MenuItem>
                        <MenuItem name='fair' key="fair" value='fair' className={classes.layoutRect} style={{background: '#00D8D8'}}></MenuItem>
                        <MenuItem name='good' key="good" value='good' className={classes.layoutRect} style={{background: 'yellow'}}></MenuItem>
                        <MenuItem name='verygood' key="verygood" value='verygood' className={classes.layoutRect} style={{background: '#4DED30'}}></MenuItem>
                    </Select>
                </Grid>
                <Grid >
                    <Grid container>
                        <Typography className={classes.layoutRect}></Typography>
                    </Grid>
                </Grid>
                <Grid >
                    {/* rightRear */}
                    <Select
                        id="rightRear"
                        onChange={(event) => props.equipmentData.tires 
                            ? props.equipmentData.tires.attachment 
                                ? props.updateEquipmentData({...props.equipmentData, tires: {...props.equipmentData.tires, attachment : {...props.equipmentData.tires.attachment, 'rightRear':  event.target.value} }})
                                : props.updateEquipmentData({...props.equipmentData, tires: {...props.equipmentData.tires, attachment : { 'rightRear':  event.target.value} }})
                            :  props.updateEquipmentData({...props.equipmentData, tires: {attachment : { 'rightRear':  event.target.value} }})
                        }
                        className={classes.layoutRect}
                        style={{backgroundColor: props.assignColor("attachment","rightRear")}}
                        disabled={props.edit ? false : true}
                        value={props.equipmentData.tires ? (props.equipmentData.tires.attachment ? (props.equipmentData.tires.attachment.rightRear ? props.equipmentData.tires.attachment.rightRear : "") : ""): ""}
                    >
                        <MenuItem name='poor' key="poor" value='poor' className={classes.layoutRect} style={{background: 'red'}}></MenuItem>
                        <MenuItem name='fair' key="fair" value='fair' className={classes.layoutRect} style={{background: '#00D8D8'}}></MenuItem>
                        <MenuItem name='good' key="good" value='good' className={classes.layoutRect} style={{background: 'yellow'}}></MenuItem>
                        <MenuItem name='verygood' key="verygood" value='verygood' className={classes.layoutRect} style={{background: '#4DED30'}}></MenuItem>
                    </Select>
                </Grid>
            </Grid>
            
            {/* veritcal spacers */}
            <Grid container>
                <Typography className={classes.layoutRect}></Typography>
            </Grid>
            <Grid container>
                <Typography className={classes.layoutRect}></Typography>
            </Grid>

            <Grid container style={{justifyContent: 'center'}}>
                <Grid >
                    {/* leftFront */}
                    <Select
                        id="leftFront"
                        onChange={(event) => props.equipmentData.tires 
                            ? props.equipmentData.tires.attachment 
                                ? props.updateEquipmentData({...props.equipmentData, tires: {...props.equipmentData.tires, attachment : {...props.equipmentData.tires.attachment, 'leftFront':  event.target.value} }})
                                : props.updateEquipmentData({...props.equipmentData, tires: {...props.equipmentData.tires, attachment : { 'leftFront':  event.target.value} }})
                            :  props.updateEquipmentData({...props.equipmentData, tires: {attachment : { 'leftFront':  event.target.value} }})
                        }
                        className={classes.layoutRect}
                        style={{backgroundColor: props.assignColor("attachment","leftFront")}}
                        disabled={props.edit ? false : true}
                        value={props.equipmentData.tires ? (props.equipmentData.tires.attachment ? (props.equipmentData.tires.attachment.leftFront ? props.equipmentData.tires.attachment.leftFront : "") : ""): ""}
                    >
                        <MenuItem name='poor' key="poor" value='poor' className={classes.layoutRect} style={{background: 'red'}}></MenuItem>
                        <MenuItem name='fair' key="fair" value='fair' className={classes.layoutRect} style={{background: '#00D8D8'}}></MenuItem>
                        <MenuItem name='good' key="good" value='good' className={classes.layoutRect} style={{background: 'yellow'}}></MenuItem>
                        <MenuItem name='verygood' key="verygood" value='verygood' className={classes.layoutRect} style={{background: '#4DED30'}}></MenuItem>
                    </Select>
                </Grid>
                <Grid >
                    <Grid container>
                        <Typography className={classes.layoutRect}></Typography>
                    </Grid>
                </Grid>
                <Grid >
                    {/* leftRear */}
                    <Select
                        id="leftRear"
                        onChange={(event) => props.equipmentData.tires 
                            ? props.equipmentData.tires.attachment 
                                ? props.updateEquipmentData({...props.equipmentData, tires: {...props.equipmentData.tires, attachment : {...props.equipmentData.tires.attachment, 'leftRear':  event.target.value} }})
                                : props.updateEquipmentData({...props.equipmentData, tires: {...props.equipmentData.tires, attachment : { 'leftRear':  event.target.value} }})
                            :  props.updateEquipmentData({...props.equipmentData, tires: {attachment : { 'leftRear':  event.target.value} }})
                        }
                        className={classes.layoutRect}
                        style={{backgroundColor: props.assignColor("attachment","leftRear")}}
                        disabled={props.edit ? false : true}
                        value={props.equipmentData.tires ? (props.equipmentData.tires.attachment ? (props.equipmentData.tires.attachment.leftRear ? props.equipmentData.tires.attachment.leftRear : "") : ""): ""}
                    >
                        <MenuItem name='poor' key="poor" value='poor' className={classes.layoutRect} style={{background: 'red'}}></MenuItem>
                        <MenuItem name='fair' key="fair" value='fair' className={classes.layoutRect} style={{background: '#00D8D8'}}></MenuItem>
                        <MenuItem name='good' key="good" value='good' className={classes.layoutRect} style={{background: 'yellow'}}></MenuItem>
                        <MenuItem name='verygood' key="verygood" value='verygood' className={classes.layoutRect} style={{background: '#4DED30'}}></MenuItem>
                    </Select>
                </Grid>
            </Grid>

            <Grid container style={{justifyContent: 'center'}}>
                <Grid >
                    <Grid container>
                        <Typography className={classes.layoutRect}></Typography>
                    </Grid>
                </Grid>
                <Grid >
                    <Grid container>
                        <Typography className={classes.layoutRect}></Typography>
                    </Grid>
                </Grid>
                <Grid >
                    <Grid container>
                        <Typography className={classes.layoutRect}></Typography>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )
}

export default withRouter(PassengerLayout)


