import React, { useState, useEffect } from 'react';
import MaterialTable from '@material-table/core';
import moment from 'moment';
import { firestore } from '../../../../firebase/firebase';
import { Button, useMediaQuery, Modal, Paper } from '@material-ui/core';
import { TableIcons } from '../../../../constants/tableIcons';
//not used, users should be loaded by now from reporting component
import { makeStyles } from '@material-ui/core';
import { useUser } from '../../../../providers/UserContext';
import { useTheme } from '@material-ui/core/styles';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import { withRouter} from 'react-router-dom';
import NewDocReport from '../../PDFs/NewDocReport';


const useStyles = makeStyles((theme) => ({
    content: {
        width: '100%'
    },
}));

const UserReportTable = (props) => {
    const { getUsers, users } = useUser();
    //can be used to get users from database, should be neccessary. 
    const [open, setOpen] = useState(false);

    const classes = useStyles();
    const theme = useTheme();
    const smallScreen = useMediaQuery(theme.breakpoints.down('sm'));
    useEffect(() => {

    }, [smallScreen]);

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const [documentData, setDocumentData] = useState([]);
    const [loading, setLoading] = useState(false)
    const [userData] = useState(props.userData || [])

    useEffect(() => {
        //only call get users once
        if(users.length <= 0){
           getUsers();
        }
        let unsubscribe = firestore.collection('documents').where('uid', '==', userData.uuid).orderBy('date').onSnapshot(querySnapshot => {

            //process & store timesheet data
            let documentResponse = querySnapshot.docs.map((doc) => {
                return {
                    ...doc.data(),
                    'docId': doc.id,
                    'dateSubmit' : doc.data().date.toDate().toDateString(),
                    'timeSubmit': doc.data().date.toDate().toLocaleTimeString(),
                    'fullTime' : moment(doc.data().date.toDate().toDateString() +' '+ doc.data().date.toDate().toLocaleTimeString()).format('MMM Do YYYY h:mm a'),
                    
                }
            })
            setDocumentData(documentResponse)
            setLoading(false);
        })

        return () => { unsubscribe(); }
    }, [getUsers, userData]) // eslint-disable-line react-hooks/exhaustive-deps


    const documentClickHandler = (path) => {
        props.history.push({
            pathname: '/dashboard/reporting/documents',
            search: "?" + new URLSearchParams({ docId: path }).toString(),

        })
    }
    return (
                <div className={classes.content}>
                    <Modal
                        aria-labelledby="simple-modal-title"
                        aria-describedby="simple-modal-description"
                        className={classes.modal}
                        onClose={handleClose}
                        open={open}
                    >
                        <Paper style={{ maxWidth:'400px', maxHeight:'700px', margin: '50px auto auto auto' }}>
                            <NewDocReport />
                        </Paper>

                    </Modal>
                    <Button style={{marginBottom:'10px'}} variant="contained" onClick={()=>handleOpen()}>New Document Report</Button>
                    <MaterialTable
                        style={{marginBottom:'10px'}}
                        columns={[
                            { title: 'Document', field: 'name' },
                            { title: 'Date Submit', field: 'fullTime', orderBy:'desc' },
                        ]}
                        data={documentData.filter(doc => doc.name.includes('Report'))}
                        title={`Reports`}
                        isLoading={loading}
                        icons={TableIcons}
                        actions={[
                            {
                                icon: OpenInNewIcon,
                                tooltip: 'View Document',
                                onClick: (event, rowData) => documentClickHandler(rowData['docId']),
                            },
                        ]}
                        options={{
                            pageSizeOptions: [5, 15, 20, 50, 100],
                            doubleHorizontalScroll: true,
                            pageSize: 5,
                            toolbar: true,
                            paging: true,
                            sorting: true,
                            thirdSortClick: false,
                            rowStyle: rowData => {
                                if (rowData.timesheetID === "Unsubmitted") {
                                    return { backgroundColor: '#fff2f4' };
                                }

                                return {};
                            }
                        }}
                    />
                    <MaterialTable
                    style={{marginBottom:'10px'}}
                        columns={[
                            { title: 'Document', field: 'name' },
                            { title: 'Date Submit', field: 'fullTime', orderBy:'desc' },
                        ]}
                        data={documentData.filter(doc => doc.name.includes('Near Miss'))}
                        title={`Near Miss Forms`}
                        isLoading={loading}
                        icons={TableIcons}
                        actions={[
                            {
                                icon: OpenInNewIcon,
                                tooltip: 'View Document',
                                onClick: (event, rowData) => documentClickHandler(rowData['docId']),
                            },
                        ]}
                        options={{
                            pageSizeOptions: [5, 15, 20, 50, 100],
                            doubleHorizontalScroll: true,
                            pageSize: 5,
                            toolbar: true,
                            paging: true,
                            sorting: true,
                            thirdSortClick: false,
                            rowStyle: rowData => {
                                if (rowData.timesheetID === "Unsubmitted") {
                                    return { backgroundColor: '#fff2f4' };
                                }

                                return {};
                            }
                        }}
                        />
                        <MaterialTable
                        style={{marginBottom:'10px'}}
                        columns={[
                            { title: 'Document', field: 'name' },
                            { title: 'Date Submit', field: 'fullTime', orderBy:'desc' },
                        ]}
                        data={documentData}
                        title={`All`}
                        isLoading={loading}
                        icons={TableIcons}
                        actions={[
                            {
                                icon: OpenInNewIcon,
                                tooltip: 'View Document',
                                onClick: (event, rowData) => documentClickHandler(rowData['docId']),
                            },
                        ]}
                        options={{
                            pageSizeOptions: [5, 15, 20, 50, 100],
                            doubleHorizontalScroll: true,
                            pageSize: 5,
                            toolbar: true,
                            paging: true,
                            sorting: true,
                            thirdSortClick: false,
                            rowStyle: rowData => {
                                if (rowData.timesheetID === "Unsubmitted") {
                                    return { backgroundColor: '#fff2f4' };
                                }

                                return {};
                            }
                        }}
                    />
                </div>
    )
}

export default withRouter(UserReportTable)