import React, { useState } from 'react';
import { createTheme, ThemeProvider } from '@material-ui/core/styles';
import { amber } from '@material-ui/core/colors';
import { TextField, Typography, Grid } from '@material-ui/core';
import { Button } from '@material-ui/core';
import classes from './SignIn.module.css'
import logo from '../../../img/geetee.png';
import { firestore, functions } from '../../../firebase/firebase';

import { useAuth } from '../../../providers/AuthContext';

const customTheme = createTheme({
    overrides: {
        MuiOutlinedInput: {
            root: {
                '&$focused $notchedOutline': {
                    borderColor: amber[500],
                },
                '&:hover $notchedOutline': {
                    borderColor: amber[500],
                },
            },
            notchedOutline: {
                borderColor: 'grey'
            },
            input: {
                color: "white"
            },
        },
        MuiFormLabel: {
            root: {
                color: amber[500],

                '&$focused ': {
                    color: amber[500],
                },
            },
        },

    }
});

const SignIn = (props) => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState(null);
    const [requiresTwoFactor, setRequiresTwoFactor] = useState(false);
    const [requiresPhoneNumber, setRequiresPhoneNumber] = useState(false);
    const [docId, setDocId] = useState('');
    const [phone, setPhone] = useState('');

    const [step, setStep] = useState(0);

    const sendCode = functions.httpsCallable('sendMFA');
    const [sending, setSending] = useState(false);

    const [code, setCode] = useState('');

    const { login } = useAuth();

    const sendSMS = async (code, phonenum) => {
        setSending(true)
        await sendCode({
            sandbox: process.env.NODE_ENV === 'development',
            phone: phonenum.replace(/\s/g, '').replace(/-/g, '').replace(/\(/g, '').replace(/\)/g, ''),
            code: code
        }).then((res) => {

            setSending(false)
        }).catch((err) => {
            console.warn(err)
            setSending(false)
        })
    }

    async function handleSignIn(event, email, password) {
        //let roles = []

        // firestore.collection('roles').get()
        //     .then(async querySnapshot => {
        //         roles = querySnapshot.docs.map((doc) => {
        //             return {
        //                 ...doc.data(),
        //                 'docId': doc.id,
        //             }
        //         })
        //     })
        //     .catch((err) => {
        //         console.warn(err)
        //         setError("Error processing, please try again");
        //     });


        firestore.collection('users').get()
            .then(async querySnapshot => {
                const response = querySnapshot.docs.map((doc) => {
                    return {
                        ...doc.data(),
                        'docId': doc.id,
                    }
                })
                
                let userIndex = response.findIndex(user => user.email.toLowerCase() === email.toLowerCase())
                let user = userIndex !== -1 ? response[userIndex] : null
                setDocId(user.docId)

                //let role = roles.filter(role=>role.title === response[userIndex].role)[0]

                if(user.mfaEnabled && user.phone !== "" && code === ""){
                    setRequiresTwoFactor(true)
                    setStep(2);
                    //console.log('Your code is: '+ await generateCode(response[userIndex].docId))

                    setPhone(user.phone)
                    await generateCode(user.docId, user.phone)
                    
                    return
                }

                if(user.mfaEnabled && (user.phone === null || user.phone === "") && phone === ""){
                    setRequiresPhoneNumber(true)
                    setStep(1);
                    return
                }

                if (
                    user !== null &&
                    user.employment.active !== false &&
                    (user.role !== "" && user.role !== "Employee")
                  ) {
                    try {
                      setError("");
                  
                      if (requiresTwoFactor && code === "") {
                        setError("Two-factor code is required");
                        return;
                      }

                      if(requiresTwoFactor && code !== "" && phone !== ""){
                        let checkCode
                        let checkDate
                        await firestore.collection('users').doc(docId).get().then(async (doc) => {
                            checkCode = doc.data().mfaCode
                            checkDate = doc.data().mfaCodeExpires
                        }).catch((err) => {
                            setError("CANT GRAB DATA?");
                            return;
                        });

                        //if now is before the checkDate and code is the same as checkCode
                        if(new Date() < checkDate.toDate() && code === checkCode){
                            await firestore.collection('users').doc(docId).update({
                                'mfaCode': null,
                                'mfaCodeExpires': null
                            })
                            await login(email, password);
                        }
                        else{
                            setError("Invalid code");
                            setCode("")
                            return;
                        }

                      }

                    if(requiresPhoneNumber && !requiresTwoFactor && phone !== ""){
                        await firestore.collection('users').doc(docId).update({phone: phone})
                        setRequiresPhoneNumber(false)
                        setStep(0)
                        return;
                    }

                    if (requiresTwoFactor && phone === "") {
                        setError("You require an SMS capable phone number to login");
                        return;
                    }
                    else {
                        await login(email, password);
                    }
                    } catch (error) {
                      setError(error.message || "An error occurred");
                    }
                  } else {
                    setError("User does not have permissions to enter");
                    return;
                  }
            }).catch((err) => {
                console.warn(err)
                setError("Error processing, please try again");

        })
    };

    const onChangeHandler = (event) => {
        const { name, value } = event.currentTarget;

        if (name === 'userEmail') {
            setEmail(value);
        }

        else if(name === 'userCode'){
            setCode(value);
        }

        else if(name === 'userPhone'){
            setPhone(value);
        }

        else if (name === 'userPassword') {
            setPassword(value);
        }
    };

    const generateCode = async (docId, phonenum) =>{
        const characters = '0123456789';
        const codeLength = 6;

        let code = '';
        // Generate a random 6-digit code
        for (let i = 0; i < codeLength; i++) {
            const randomIndex = Math.floor(Math.random() * characters.length);
            code += characters[randomIndex];
        }

        //sendSMS(code, phonenum);

        await firestore.collection('users').doc(docId).update({
            'mfaCode': code,
            'mfaCodeExpires': new Date(new Date().getTime() + 15*60000)
        })
        sendSMS(code, phonenum);

        return code;
    }

    return (
        // <Aux>
        <div className={classes.Container}>
            <img src={logo} className={classes.Logo} alt='Logo'></img>
            <div>
                <ThemeProvider theme={customTheme}>
                    {!requiresTwoFactor && !requiresPhoneNumber &&<React.Fragment>
                    <TextField
                        type="email"
                        name="userEmail"
                        value={email}
                        id="userEmail"
                        onChange={(event) => onChangeHandler(event)}
                        onKeyPress={(event) => event.key === "Enter" && handleSignIn("", email, password)}
                        label="Email"
                        margin="normal"
                        variant="outlined"
                        fullWidth
                    />
                    <br />
                    <TextField
                        color="primary"
                        type="password"
                        name="userPassword"
                        value={password}
                        id="userPassword"
                        onChange={(event) => onChangeHandler(event)}
                        onKeyPress={(event) => event.key === "Enter" && handleSignIn("", email, password)}
                        label="Password"
                        margin="normal"
                        variant="outlined"
                        fullWidth
                    />
                    <br /></React.Fragment>}
                    {requiresTwoFactor && !requiresPhoneNumber &&
                    <React.Fragment>
                    <TextField
                        color="primary"
                        name="userCode"
                        value={code}
                        id="userCode"
                        onChange={(event) => onChangeHandler(event)}
                        label="6 Digit Code"
                        margin="normal"
                        variant="outlined"
                        fullWidth
                    />
                    <br />
                    </React.Fragment>}
                    {requiresPhoneNumber &&
                    <React.Fragment>
                    <TextField
                        color="primary"
                        name="userPhone"
                        value={phone}
                        id="userPhone"
                        onChange={(event) => onChangeHandler(event)}
                        label="Phone Number"
                        margin="normal"
                        variant="outlined"
                        fullWidth
                    />
                    <Typography style={{color:'white'}} gutterBottom><small>Please supply a phone number to recieve your Gee Tee ID Code.</small></Typography>
                    <br />
                    
                    </React.Fragment>}
                </ThemeProvider>
                <Grid container >
                    <Grid container item justifyContent='center' xs={12}>
                        <Button style={{marginRight:'10px'}}
                        variant="contained" color="primary" onClick={(event) => { handleSignIn(event, email, password) }}>
                        
                        {step === 0 ? 'Sign In' : step === 1 ? 'Submit Phone Number' : 'Submit Code'}
                    
                        </Button>
                        {step === 2 && <Button variant="contained" color="primary" disabled={sending} onClick={(event) => { sendSMS(code, phone) }}> Resend Code </Button>}
                    </Grid>
                </Grid>

                
                <br />

                {error !== null && <div className={classes.ErrorMsg}>{error}</div>}


            </div>
        </div >

        /* </Aux> */
    );
};

export default SignIn;