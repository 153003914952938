import React from 'react'
import ReportTable from '../../Reports/ReportTable/ReportTable'

const FormTable = (props) => {
    return (
        <ReportTable
            
            columns={[
                { title: 'Form', field: 'name' },
            ]}
            data={props.formData}
            title={"Forms"}
            pathname='/dashboard/reporting/documents'
        />
    )
}
export default FormTable
