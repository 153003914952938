import React, { useEffect} from 'react';
import {Button, Divider, Grid, IconButton, Paper, TextField, Typography, makeStyles,
FormControlLabel, Checkbox, Chip} from '@material-ui/core'
import PublishIcon from '@material-ui/icons/Publish';
import MenuBookIcon from '@material-ui/icons/MenuBook';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { withRouter } from 'react-router-dom';
import Dropzone from 'react-dropzone'
import ClearIcon from '@material-ui/icons/Clear';

const useStyles = makeStyles((theme) => ({
    btnGroup:{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-end',
    },
    card: {
        padding: theme.spacing(4),
        width: '100%',
        height: '100%',
    },
    content: {
        maxWidth: '500px',
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        margin: 'auto',
    },
    fileInput: {
        '&:hover': {
            cursor: 'pointer',
          },
        display:'flex',
        alignItems: 'center',
        border: '1px solid #bdbdbd',
        borderRadius:'4px',
        margin:'8px',
        marginTop:'16px',
        //backgroundColor: '#f2f2f2',
    },
    fileInputAccept: {
        display:'flex',
        alignItems: 'center',
        border: '0px',
        borderRadius:'4px',
        margin:'8px',
        marginTop:'16px',
        backgroundColor: '#56a84b',
        color:'white',
    },
    paper: {
        width:'100%',
        maxWidth: '500px',
        padding: theme.spacing(2),
    },
    row:{
        display: 'flex',
        alignItems:'center',
    },
    title: {
        display:'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginBottom: '32px',
    },
    text: {
        color: '#707070',
    },
    backButton: {
        marginBottom: '20px',
        marginTop: '20px',
    },
}));

const PDFForm = (props) => {

    const classes=useStyles();
    const [newEmail, setNewEmail] = React.useState("");
    const [emails, setEmails] = React.useState(props.safetyForm.emails || []);
    const [error, setError] = React.useState("");

    const addEmail = () => {
        props.updateSafetyData({ ...props.safetyForm, 'emails': emails })
        if(props.safetyForm?.emails.includes(newEmail.toLowerCase())){
            setError("Email already exists!")
        } else {
            emails.push(newEmail.toLowerCase())
            setNewEmail("")
            props.updateSafetyData({ ...props.safetyForm, 'emails': emails })
        }
        
    }

    const deleteEmail = (email) => {
        //setTags(props.product.tags)
        const index = emails.indexOf(email);
        if (index > -1) {
            emails.splice(index, 1);
        }
        props.updateSafetyData({ ...props.safetyForm, 'emails': emails })

    }

    useEffect(() => {
        if(newEmail === "")
            setError("")
    }, [props.safetyForm, setEmails, newEmail])

    return (
        <Grid className={classes.content}>
                <Grid className={classes.backButton}>
                    <Button variant="contained" startIcon={<ArrowBackIcon />}
                       onClick={() => props.history.goBack()} >Back</Button>
                </Grid>
            <form onSubmit={props.handleSubmit}>
                <Paper className={classes.paper}>
                    <Grid container spacing={2}>
                        <Grid item sm={12} className={classes.title}>
                            <MenuBookIcon style={{marginRight:'8px'}}/>
                            <Typography variant="h4" align="center">New PDF Form</Typography>
                        </Grid>
                        <Grid item sm={12}>
                            <Typography>Form Name*</Typography>
                            <TextField required size="small" fullWidth variant='outlined' name="name" onChange={props.handleChange} value={props.safetyForm.name}/>
                        </Grid>
                        <Grid item sm={12}>
                            <Typography>Form Use/Description*</Typography>
                            <TextField component="pre" required multiline rows={3} size="small" fullWidth variant='outlined' name="description" onChange={props.handleChange} value={props.safetyForm.description}/>
                        </Grid>

                        <Grid item sm={12}>
                            <Divider/>
                        </Grid>

                        <Grid item sm={12}>
                        <FormControlLabel
                            control={
                            <Checkbox
                                checked={props.safetyForm.gathersWeatherData}
                                onChange={props.handleCheckboxChange}
                                name="gathersWeatherData"
                                color="primary"
                            />
                            }
                            label="Form Grabs Weather Data"
                        />
                        </Grid>

                        <Grid item sm={12}>
                        <FormControlLabel
                            control={
                            <Checkbox
                                checked={props.safetyForm.hasPhotos}
                                onChange={props.handleCheckboxChange}
                                name="hasPhotos"
                                color="primary"
                            />
                            }
                            label="Allow Photo(s) to be attached?"
                        />
                        </Grid>

                        <Grid item sm={12}>
                        <FormControlLabel
                            control={
                            <Checkbox
                                checked={props.safetyForm.appearsInEmployeePage}
                                onChange={props.handleCheckboxChange}
                                name="appearsInEmployeePage"
                                color="primary"
                            />
                            }
                            label="Store submit forms in Employee page?"
                        />
                        </Grid>

                        <Grid item sm={12}>
                        <FormControlLabel
                            control={
                            <Checkbox
                                checked={props.safetyForm.invisibleInApp ? props.safetyForm.invisibleInApp : false}
                                onChange={props.handleCheckboxChange}
                                name="invisibleInApp"
                                color="primary"
                            />
                            }
                            label="Form invisible in app?"
                        />
                        </Grid>

                        <Grid item sm={12}>
                        <FormControlLabel
                            control={
                            <Checkbox
                                checked={props.safetyForm.sendsEmail}
                                onChange={props.handleCheckboxChange}
                                name="sendsEmail"
                                color="primary"
                            />
                            }
                            label="Send Email(s) on submit?"
                        />
                        </Grid>

                        <Grid item sm={12}>
                            <Typography>Email(s) to send on submit*</Typography>
                            <Divider/>
                            <TextField
                                placeholder={"Add new Email"}
                                disabled={!props.safetyForm.sendsEmail}
                                type="email"
                                name="emailenter"
                                value={newEmail || ""}
                                onChange={(event) => setNewEmail(event.target.value)}
                                onKeyPress={(ev) => {
                                    if (ev.key === 'Enter' && newEmail !== "") {
                                        addEmail()
                                        ev.preventDefault();
                                    }
                                }}
                                />
                                {error &&
                        <p style={{color:"red"}}><small>{error}</small></p>}
                        <Typography></Typography>
                        {props.safetyForm?.emails && props.safetyForm?.emails.length > 0 ? props.safetyForm.emails.map((email, index) =>  {
                                return (
                                    <Chip
                                    key={index}
                                    size="small"
                                    style={{padding:'4px', margin:'4px'}}
                                    label={email}
                                    disabled={!props.safetyForm.sendsEmail}
                                    onDelete={(event) => deleteEmail(email)}
                                    color="secondary"
                                    />
                                )
                            })
                            : <p><small>No emails available.</small></p>}
                        </Grid>

                        <Grid item sm={12}>
                            <Divider/>
                        </Grid>

                        <Grid item sm={12} className={props.safetyPDF?.name ? classes.fileInputAccept : classes.fileInput}>
                            <Dropzone accept={{'application/pdf': ['.pdf', '.PDF']}} onDrop={props.handleFileUpload}>
                                {({ getRootProps, getInputProps }) => (
                                    <Grid container item xs={12}justifyContent='center' {...getRootProps()} >
                                        <input {...getInputProps()} style={{ display: 'none' }} />
                
                                        <PublishIcon style={{color:'grey', marginRight:'20px'}}/>

                                        <Typography variant='h6'>
                                            {props.safetyPDF?.name ? props.substringText(props.safetyPDF?.name) : "Upload PDF Form (.pdf)"}
                                        </Typography>
                                    </Grid>
                                )}
                            </Dropzone>

                            {props.safetyPDF?.name ?
                                <IconButton component='span' style={{ marginLeft: '100px', color:'black' }} onClick={() => {props.setSafetyPDF()}}>
                                    <ClearIcon />
                                </IconButton>
                            : null}
                        </Grid>

                        <Grid item sm={12}>
                            <Typography type='url' align="center" style={{color:'red'}}>{props.error}</Typography>
                        </Grid>

                        <Grid item sm={12}>
                            <Button fullWidth variant="contained" size="large" disabled={props.submitting} type="submit">{props.submitting ? "Submitting..." : "Create PDF Form"}</Button>
                        </Grid>
                    </Grid>
                </Paper>
            </form>
        </Grid>
    )
}

export default withRouter(PDFForm);