import { useState } from 'react';
import { Backdrop, Button, Divider, Fade, Grid, Modal, Paper, TextField, Typography } from "@material-ui/core";
import LoadingSpinner from '../../../../../Public/components/LoadingSpinner/LoadingSpinner';

const PalletModal = (props) => {

    const [numPallets, setNumPallets] = useState(0);
    const order = props.orderData || {};

    return order &&
        <Modal
            style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                outline: 0,
            }}
            open={props.open}
            onClose={props.onClose}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }}>
            <Fade in={props.open}>
                <form onSubmit={(event) => props.onConfirm(event, numPallets)}>
                    <Paper style={{ padding: '16px', maxWidth: '450px' }}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Typography variant='h6'>Refund Pallet Deposit</Typography>
                                <Typography>Enter the number of pallets returned by the client for a full or partial refund.</Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Divider />
                            </Grid>
                            <Grid item xs={8} sm={5}>
                                <Typography>Total Pallet Fees:</Typography>
                            </Grid>
                            <Grid item xs={4} sm={1} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                <Typography>${order.fees.palletFee.totalFee.toFixed(2)}</Typography>
                            </Grid>
                            <Grid item xs={8} sm={5}>
                                <Typography>Total Pallets on Order:</Typography>
                            </Grid>
                            <Grid item xs={4} sm={1} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                <Typography>{order.fees.palletFee.totalPallets}</Typography>
                            </Grid>
                            <Grid item xs={8} sm={5}>
                                <Typography>Fee Refunded:</Typography>
                            </Grid>
                            <Grid item xs={4} sm={1} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                <Typography>${(order.fees.palletFee.totalFee - order.fees.palletFee.remainingPalletFee).toFixed(2)}</Typography>
                            </Grid>
                            <Grid item xs={8} sm={5}>
                                <Typography>Returned Pallets:</Typography>
                            </Grid>
                            <Grid item xs={4} sm={1} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                <Typography>{order.fees.palletFee.totalPallets - order.fees.palletFee.remainingPallets}</Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Divider />
                            </Grid>
                            <Grid item xs={8} sm={5}>
                                <Typography>Outstanding Fee:</Typography>
                            </Grid>
                            <Grid item xs={4} sm={1} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                <Typography>${order.fees.palletFee.remainingPalletFee.toFixed(2)}</Typography>
                            </Grid>
                            <Grid item xs={8} sm={5}>
                                <Typography>Remaining Pallets:</Typography>
                            </Grid>
                            <Grid item xs={4} sm={1} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                <Typography>{order.fees.palletFee.remainingPallets}</Typography>
                            </Grid>
                            <Grid item xs={8} style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '16px' }}>
                                <TextField
                                    InputLabelProps={{ shrink: true }}
                                    label="Returned Pallets"
                                    size='small'
                                    required
                                    type='number'
                                    value={numPallets}
                                    onChange={(event) => setNumPallets(event.target.value)}
                                    fullWidth>
                                </TextField>
                            </Grid>
                            <Grid item xs={4} style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '16px' }}>
                                {props.loading
                                    ? <LoadingSpinner />
                                    : <Button
                                        variant='contained'
                                        type='submit'
                                        value='submit'>
                                        Refund
                                </Button>}
                            </Grid>
                            <Grid item xs={12}>
                                <Typography style={{ color: 'red' }}>{props.error}</Typography>
                            </Grid>
                        </Grid>
                    </Paper>
                </form>
            </Fade>
        </Modal>
}

export default PalletModal;