import React, { useState, useEffect, useCallback } from 'react';
import { withRouter } from 'react-router'
import { firestore } from '../../../firebase/firebase'
import ResumeCard from '../../components/Resume/ResumeCard'
import { makeStyles, Grid } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';

const useStyles = makeStyles((theme) => ({
    content: {
        display: 'flex',
        width: '100%',
        padding: '32px',
        justifyContent: 'center',
        marginTop: '42px',
    },
}));

const Resume = (props) =>  {

    const classes = useStyles();

    const [resumeData, setResumeData] = useState({});
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState("");
    const [saving, setSaving] = useState(false);
    const [saveState, setSaveState] = useState({});

    const updateResumeData = useCallback((e) => {
        setResumeData(e)
    },
    [],
    );

    const saveResumeData = () => {
        setSaving(true);
        firestore.collection('resumes').doc(resumeData.docId)
            .update(resumeData)
            .then(() => {
                setSaveState(resumeData);
                setSaving(false)
            }).catch((e) => setError(e));
    };

    const cancelSave = () => {
        setResumeData(saveState);
    };


    useEffect(() => {
        let path = new URLSearchParams(props.location.search).get("docId");

        firestore.collection('resumes').where('__name__', "==", path).get()
        .then(querySnapshot => {
            const safetyResponse = querySnapshot.docs.map((doc) => {
                return {
                    ...doc.data(),
                    'docId': doc.id,
                }
            })
            if (safetyResponse[0].viewed === false) {
                firestore.collection('resumes').doc(path).update({ 'viewed': true }).then(() => {
                    setResumeData({ ...safetyResponse[0], 'viewed': true })
                })
            } else {
                setResumeData(safetyResponse[0]);
            }
            })
            //let data load resumeData before displaying (should use async await instead?)
            setTimeout(() => {
                setLoading(false);
            }, 250);

    }, 
    // eslint-disable-next-line
    [props.location.search]
    );


return (
    <div>
        {loading 
        ? 
        <Grid className={classes.content}>
            <Skeleton variant='rect' width={'51vw'} height={'30vh'}/>
        </Grid>
        : 
        <React.Fragment>
            <ResumeCard 
                error={error}
                resumeData={resumeData} 
                cancelSave={cancelSave} saveResumeData={saveResumeData} 
                updateResumeData={updateResumeData} saving={saving}
            />
            </React.Fragment>
        }
    </div>
    )

}

export default withRouter(Resume);