import React from 'react';
import {Dialog, DialogTitle, Grid, Typography} from '@material-ui/core';
import LoadingSpinner from '../../LoadingSpinner/LoadingSpinner';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    dialog: {
        margin: theme.spacing(2),
    },

    grid: {
        display: 'flex',
        alignItems: 'center',
    }
}));

const ProcessingDialog = (props) => {

    const MuiClasses = useStyles();

    return (
        <Dialog
            className={MuiClasses.dialog}
            disableBackdropClick
            open={props.open}>
            <DialogTitle>
                <Grid container className={MuiClasses.grid}>
                    <Grid item xs={10}>
                        <Typography variant='h4'>
                            Processing Payment
                        </Typography>
                    </Grid>
                    <Grid item xs={2}>
                        <Typography variant='h4'>
                        <LoadingSpinner/>
                        </Typography>
                    </Grid>
                </Grid>
            </DialogTitle>
        </Dialog>
    )
};

export default ProcessingDialog;