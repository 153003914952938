import React, { useState, useEffect, useCallback } from 'react';
import { withRouter } from 'react-router'
import { firestore } from '../../../../firebase/firebase'
import RoleCard from '../../../components/Users/Roles/RoleCard'
import { makeStyles, Grid, } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import { useUser } from '../../../../providers/UserContext'

import _, { cloneDeep } from 'lodash'


const useStyles = makeStyles((theme) => ({
    content: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        maxWidth: '792px',
        margin: 'auto',
    },
    skeletonContent: {
        display: 'flex',
        justifyContent: 'center',
        width: '100%',
        marginTop: '75px',
    },
}));

const Role = (props) =>  {

    const [roleData, setRoleData] = useState({});
    const [roles, setRoles] = useState({});
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState("");
    const [saving, setSaving] = useState(false);
    const [saveState, setSaveState] = useState({});
    const [typedValue, setTypedValue] = useState('');
    const [userSelected, setUserSelected] = useState('');

    const [initialRole] = useState({
        title: "",
        permissions: [],
    })

    const classes = useStyles();
    const { users, getUsers } = useUser();

    const updateRoleData = useCallback((e) => {
            setRoleData(e)
        },
        [],
    );

    useEffect(() => {
        getUsers();
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    let path = new URLSearchParams(props.location.search).get("docId");
    const handleAddUser =() => {
        setSaving(true)
        firestore.collection('users')
            .where('displayName', '==', typedValue).get().then(snapshot => {
                const promises = [];
                snapshot.forEach(doc => {
                    promises.push(doc.ref.update({
                        role: roleData.title,
                    }));
                });
                return Promise.all(promises)
            }).then(() => {
                setSaving(false)
                setUserSelected('');
                setTypedValue('');
            })
            .catch(error => {
                console.log(error);
                return null;
            });

    }

    const handleRemoveUser = () => {
        setSaving(true)
        firestore.collection('users')
            .where('docId', '==', userSelected).get().then(snapshot => {
                const promises = [];
                snapshot.forEach(doc => {
                    promises.push(doc.ref.update({
                        role: '',
                    }));
                });
                return Promise.all(promises)
            }).then(()=>{
                setSaving(false)
            })
            .catch(error => {
                console.log(error);
                return null;
            });
        //setSaving(false)
        //setUserSelected('');

    }

    const confirmSave = () => {
        setSaving(true);
        if (path === "new")
            firestore.collection('roles').add(roleData)
                .then(() => {
                    setSaveState(_.cloneDeep(roleData));
                    setSaving(false)
                    props.history.goBack();
                }).catch((e) => {
                    console.log(e)
                    setSaving(false)
                });
        else
            firestore.collection('roles').doc(path)
                .update(roleData)
                .then(() => {
                    setSaveState(roleData);
                    setSaving(false)
                })
                .catch((e) => setError(e));
    };

    const cancelSave = () => {
        setRoleData(saveState);
    };


    useEffect(() => {
        if (path === 'new') {
            firestore.collection('roles').get()
            .then(querySnapshot => {
                setRoles(querySnapshot.docs.map((doc) => {
                    return {
                        ...doc.data(),
                        'docId': path,
                    }
                }))
            })
            .catch(e => {
                setError("Roles not found.");
                console.log(e.message);
            })
            setRoleData(cloneDeep(initialRole));
            setSaveState(cloneDeep(initialRole));
            setLoading(false);
        }
        else {
            firestore.collection('roles').get()
            .then(querySnapshot => {
                setRoles(querySnapshot.docs.map((doc) => {
                    return {
                        ...doc.data(),
                        'docId': path,
                    }
                }))
            })
            .catch(e => {
                setError("Roles not found.");
                console.log(e.message);
            })
            firestore.collection('roles').where('__name__', "==", path).get()
            .then(querySnapshot => {
                const roleResponse = querySnapshot.docs.map((doc) => {
                    return {
                        ...doc.data(),
                        'docId': path,
                    }
                })
                setRoleData(roleResponse[0]);
                setSaveState(roleResponse[0]);
                firestore.collection('roles').doc(path)
                    .update(roleResponse[0])
                setLoading(false);
            })
            .catch(e => {
                setError("Role not found.");
                setLoading(false);
                console.log(e.message);
            })
        }
    }, [path, initialRole, saving, typedValue, userSelected]);



    return (
        <div>
            {loading || saving
            ?
            <Grid className={classes.skeletonContent}>
                <Skeleton variant='rect' width={'40vw'} height={'80vh'}/>
            </Grid>
            :
            <Grid className={classes.content}>
                <RoleCard
                error={error}  setRoleData={setRoleData} typedValue={typedValue} setTypedValue={setTypedValue} roles={roles}
                roleData={roleData} saveState={saveState}  setSaveState={setSaveState} userSelected={userSelected} setUserSelected={setUserSelected}
                cancelSave={cancelSave} confirmSave={confirmSave} path={path === 'new' ? 'new' : null} handleAddUser={handleAddUser} handleRemoveUser={handleRemoveUser}
                updateRoleData={updateRoleData} saving={saving} setSaving={setSaving} users={users}/>

            </Grid> }
        </div>
    )
}

export default withRouter(Role);