import React, { useContext, useState, useEffect } from "react"
import { firestore } from '../firebase/firebase'
import { auth } from "../firebase/firebase"

const AuthContext = React.createContext()

export function useAuth() {
  return useContext(AuthContext)
}

export function AuthProvider({ children }) {

  const [currentUser, setCurrentUser] = useState({});
  const [userSettings, setUserSettings] = useState({})
  const [userInfo, setUserInfo] = useState({});
  const [loading, setLoading] = useState(false);
  const [roles, setRoles] = useState([]);
  
  function signup(email, password) {
    return auth.createUserWithEmailAndPassword(email, password)
  }

  function login(email, password) {
    return auth.signInWithEmailAndPassword(email, password);
  }

  function logout() {
    return auth.signOut()
  }

  function resetPassword(email) {
    return auth.sendPasswordResetEmail(email)
  }

  function updateEmail(email) {
    return currentUser.updateEmail(email)
  }

  function updatePassword(password) {
    return currentUser.updatePassword(password)
  }

  const updateUserSettings = (field, value) => {
    firestore.collection('userSettings').doc(currentUser.uid)
    .set({[field]: value}, { merge: true })
    .then(() => { setUserSettings({...userSettings, [field]: value} || {}) })
    .catch((e) => {console.warn(e);});
  }

  //then chain to get userSettings data
  async function loadData(user) {
    try {
      //user collection
      const querySnapshot = await firestore
        .collection('users')
        .where('email', '==', user?.email)
        .get();
      const userData = querySnapshot.docs.map((doc) => {
        const data = doc.data();
        return {
          ...data,
          role: data.role || 'Employee',
          firstName: data.firstName || data.displayName.substring(0, data.displayName.indexOf(' ')),
          lastName: data.lastName || data.displayName.substring(data.displayName.indexOf(' ') + 1),
        };
      })[0];

      //userSettings collection
      await firestore.collection('userSettings').doc(user.uid).get()
      .then((doc) => { setUserSettings( doc.data() || {} ); })

      //roles collection
      const permSnapshot = await firestore
      .collection('roles')
      .where('title', '==', userData?.role)
      .get();
      const roleData = permSnapshot.docs.map((doc) => {
        const data = doc.data();
        return {
          permissions: data.permissions || [],
        };
      })[0];

      setUserInfo((prevUserInfo) => ({
        ...prevUserInfo,
        ...userData,
        permissions: roleData.permissions,
      }));
      setRoles(roleData.permissions);
      setCurrentUser(user);
    } catch (error) {
      console.error('Error loading user data:', error);
    }
  }

  useEffect(() => {
    setLoading(true);
  
    const unsubscribe = auth.onAuthStateChanged(user => {
      if (user !== null) {
        
        loadData(user);
        
      } else {
        setCurrentUser(null);
      }
  
      setLoading(false); // Move setLoading inside the callback
  
    });
  
    return unsubscribe;
    //eslint-disable-next-line
  }, []);

  const value = {
    currentUser,
    userInfo,
    loading,
    roles,
    login,
    signup,
    logout,
    resetPassword,
    updateEmail,
    updatePassword,

    updateUserSettings,
    userSettings,
  }

  return (
    <AuthContext.Provider value={value}>
      {children}
    </AuthContext.Provider>
  )
}