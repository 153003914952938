import React, {  useRef, useEffect, memo } from 'react'
import { Typography, Grid, TableCell, TableRow, makeStyles, TableSortLabel, Link, useMediaQuery } from '@material-ui/core';
import _  from 'lodash';


const useStyles = makeStyles((theme) => ({
    columnHeaders: {
        '&:hover': {
            textDecoration: 'none',
            color: 'grey',
          },
        textDecoration: 'none',
        color: 'black',
    },
    detailsSpacer: {
        width: '65px',
    },
    tableHeader: {
        padding: '15px',
    },
    rowContent: {
        width: '90%',
        fontSize: '14px',
        // padding: '5px',
        // margin: '5px'
    },
    root: {
        userSelect: 'none',
    },
    columnTitles: {
        fontSize: '14px',
    },
    dndAble: {
        '&:hover': {
            cursor: 'grab',
          },
    }
}));


const DataGridTableColumnHeader = ({columns, setColumns, order, setOrder, orderBy, setOrderBy, detailsPanelBool, action}) => {
    const classes = useStyles();
    const smallScreen = useMediaQuery('(max-width:1500px)');

    useEffect(() => {}, [smallScreen]);
    
    const dragItem = useRef();
    const dragOverItem = useRef();


    const handleDragStart = (e, position) => {
        dragItem.current = position;
    };

    const handleDragEnter = (e, position) => {
        dragOverItem.current = position;
    };

    const handleDragOver = (e) => {
        e.preventDefault()
    }
      
    const handleDragDrop = (e) => {
        let columnsContainer = _.cloneDeep(columns)
        const draggedOverContainer = columnsContainer[dragItem.current];
        columnsContainer.splice(dragItem.current, 1);
        columnsContainer.splice(dragOverItem.current, 0, draggedOverContainer);
        dragItem.current = null;
        dragOverItem.current = null;
        setColumns(columnsContainer)
    }

    const handleSortColumn = (newIndex, field) => {
        setOrder(orderBy === field && order === 'asc' ? 'desc' : 'asc');
        setOrderBy(field);

        let columnsContainer = _.cloneDeep(columns)
        let oldColumn = columnsContainer.filter(x=> !_.isUndefined(x.orderByColumn))[0]
        let oldIndex = columnsContainer.findIndex(x=> x.field === oldColumn.field)

        if(oldColumn.field === field){
            if(oldColumn.orderByColumn === 'asc'){ columnsContainer[oldIndex].orderByColumn = 'desc'; }
            else{ columnsContainer[oldIndex].orderByColumn = 'asc'; }
        }
        else{
            delete columnsContainer[oldIndex].orderByColumn;
            columnsContainer[newIndex].orderByColumn = 'asc';
        }
        setColumns(columnsContainer);
    };

    //console.log('table headers')

    const returnColumnStyle = (column) => {
        if(column.width === 'small' && smallScreen){ return { wordBreak: 'break-all', msWordBreak: 'break-all', minWidth: '90px'} }
        if(column.width !== 'small' && smallScreen){ return { wordBreak: 'break-all', msWordBreak: 'break-all',  minWidth: '150px' } }
        if(column.width === 'small' && !smallScreen){ return { wordBreak: 'break-all', msWordBreak: 'break-all',  width: '90px' } }
        if(column.width !== 'small' && !smallScreen){ return { wordBreak: 'break-all', msWordBreak: 'break-all',  minWidth: '90px' } }
        return null;
    }

    return (
        <>
            <TableRow >
                {/* details cell */}
                {/* {detailsPanelBool &&
                    <TableCell className={classes.detailsSpacer}/>
                } */}

                {/* multiRowSelect */}
                <TableCell style={{width: '25px', wordBreak: 'break-all', msWordBreak: 'break-all'}} />

                {/* column titles */}
                {columns.filter(x=> !_.isUndefined(x.active) ? x.active : true).map((column, index) => {
                    return (
                        <TableCell
                            className={classes.dndAble}
                            key={index}
                            draggable={true}
                            onDragStart={(e) =>{ handleDragStart(e, index) }}
                            onDragEnter={(e) =>{ handleDragEnter(e, index) }}
                            onDragOver={(e) => { handleDragOver(e) }}
                            onDragEnd={(e) => { handleDragDrop(e) }}
                            style={ returnColumnStyle(column) }
                        >

                            <Link
                                component="button"
                                onClick={(e) => { handleSortColumn(index, column.field) }}
                                className={classes.columnHeaders}
                            >
                                <Grid container>
                                    <Typography className={classes.columnTitles} >{column.title}</Typography>
                                    <TableSortLabel
                                        hideSortIcon={true}
                                        active={orderBy === column.field}
                                        direction={orderBy === column.field ? order : 'asc'}
                                    />
                                </Grid>
                            </Link>            
                        </TableCell>
                    )
                })}

                {/* action cell */}
                <TableCell style={{width: '75px', wordBreak: 'break-all', msWordBreak: 'break-all'}} >
                    <Grid container justifyContent='flex-end'>
                        <Typography className={classes.columnTitles}>Action</Typography>
                    </Grid>
                </TableCell>
                
            </TableRow>
        </>
    )
}
  export default memo(DataGridTableColumnHeader);