import React, { useState, useEffect } from 'react';
import { Chip, makeStyles } from '@material-ui/core';
import {
    Button, ButtonGroup, Card, CardActionArea, CardContent, Checkbox, Divider, FormControl, FormControlLabel, Grid,
    InputLabel, Paper, MenuItem, Modal, Select, TextField, Typography, useMediaQuery, InputAdornment,
} from '@material-ui/core';
import _ from 'lodash';
import { useTheme } from '@material-ui/core/styles';
import DeleteIcon from '@material-ui/icons/Delete';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { firestore } from '../../../firebase/firebase'
import Tooltip from '@material-ui/core/Tooltip';
import BurstModeIcon from '@material-ui/icons/BurstMode';
import ProductPreview from './ProductPreview';
import ProductSelector from './UniversalProductSelector';
import { useAuth } from '../../../providers/AuthContext';
import { withRouter } from 'react-router';

const useStyles = makeStyles((theme) => ({
    card: {
        padding: theme.spacing(2),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        height: '100%',
        [theme.breakpoints.down('xs')]: {
            padding: theme.spacing(1),
        }
    },
    image: {
        height: '100%',
        width: '100%',
        objectFit: 'scale-down',
    },
    modal: {
        width: '500px',
        padding: '16px',
        margin: '0 auto',
        marginTop: '50vh',
        backgroundColor: 'rgb(49, 49, 49)',
    },
    modalInput: {
        '& input': {
            color: 'white',
        },
    },
    headerPhoto: {
        height: '100%',
        width: '100%',
    },
    row: {
        display: 'flex',
        alignItems: 'center',
    },
    content: {
        display: 'flex',
        width: '100%',
        flexDirection: 'column',
        maxWidth: '1000px',
        margin: 'auto',
    },
    backButton: {
        marginBottom: '20px',
        marginTop: '20px',
    },
}));

const ProductCard = (props) => {

    const classes = useStyles();
    const { roles } = useAuth();

    const [modalOpen, setModalOpen] = useState(false);
    const [newCategory, setNewCategory] = useState('');
    const [categoryError, setCategoryError] = useState('');
    const [categoryManageMode, setCategoryManageMode] = useState('create');
    const theme = useTheme();
    const smallScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const [product, setProduct] = useState({ ...props.product });
    const [fullProduct, setFullProduct] = useState(props.product);
    const [open, setOpen] = React.useState(false);
    const [previewOpen, setPreviewOpen] = React.useState(false);
    const [newTag, setNewTag] = React.useState("");
    const [tags, setTags] = React.useState(props.product.tags || []);
    const [error, setError] = React.useState("");
    const [openProducts, setOpenProducts] = React.useState(false);

    const [similarProducts, setSimilarProducts] = React.useState(props.product.similarProducts || []);

    const handleOpenProducts = () => {
        setOpenProducts(true);
    };

    const handleCloseProducts = () => {
        updateSimilarProducts()
        setOpenProducts(false);
    };
    /* const [currentIndex, setCurrentIndex] = useState(0)

    const currentIndexHandler = (num) => {
        setCurrentIndex(num)
    } */

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };
    const handleClickPreviewOpen = () => {
        setPreviewOpen(true);
    };

    const handleClickPreviewClose = () => {
        setPreviewOpen(false);
    };
    const handleDeleteClick = () => {
        props.product.docId === "fourWayMix" || props.product.docId === "screenedSandyLoam" || props.product.docId === "clayFill" ?
            console.log("You cannot delete this item")
            :
            firestore.collection('products').doc(props.product.docId).delete().then(() => {
                console.log("Product successfully deleted!");
                props.history.replace({pathname: '/dashboard/products'})
            }).catch((error) => {
                console.error("Error removing product: ", error);
            });
    }

    useEffect(() => {
        setFullProduct(props.product)
        let splitProduct = { ...props.product }
        delete splitProduct.variants;

        setProduct(splitProduct);
        if(newTag === "")
            setError("")
    }, [props.product, setTags, newTag])

    const blurHandler = () => {
        props.updateData(Object.assign({}, props.product, product))
    }

    const addTags = () => {
        props.updateData({ ...props.product, 'tags': tags })
        if(props.product?.tags.includes(newTag.toLowerCase())){
            setError("Tag already exists!")
        } else {
            //setTags(props.product.tags)
            tags.push(newTag.toLowerCase())
            setNewTag("")
            props.updateData({ ...props.product, 'tags': tags })
            //setTags(props.product.tags)
        }
        
    }

    const deleteTag = (tag) => {
        //setTags(props.product.tags)
        const index = tags.indexOf(tag);
        if (index > -1) {
            tags.splice(index, 1);
        }
        props.updateData({ ...props.product, 'tags': tags })

    }

    const updateSimilarProducts = () => {
        props.updateData({...props.product, 'similarProducts': similarProducts})
    }


    //TODO: Connect this?
    //let swatchPhotoURL = props.swatchPhotoURL


    const setShipOption = (option) => {
        let shipOption = [...props.product.shipOption]
        let index = shipOption.indexOf(option);
        index !== -1 ? shipOption.splice(index, 1) : shipOption.push(option)
        props.updateData({ ...props.product, 'shipOption': shipOption })
    }

    const handleCategoryClick = () => {
        switch (categoryManageMode) {
            case 'create': {
                if (props.productCategories.includes(newCategory)) {
                    setCategoryError("Category already exists");
                }
                else if (newCategory === "") {
                    setCategoryError("Category can not be empty");
                }
                else {
                    let newCategories = [...props.productCategories];
                    newCategories.push(newCategory);
                    props.createCategory(newCategories);

                    setCategoryError("");
                    setModalOpen(false);
                }
                break;
            }
            case 'remove': {
                break;
            }
            default:
                break;
        }
    }
    return (
        <React.Fragment>
            
            <ProductSelector
                open={openProducts}
                close={handleCloseProducts}
                initialProducts={similarProducts}
                setSelectedProducts={setSimilarProducts}
                updateSimilarProducts={updateSimilarProducts}
            />
            
            <Dialog
                fullWidth
                maxWidth="lg"
                open={previewOpen}
                onClose={handleClickPreviewClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogContent style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start', overflow: 'hidden' }}>
                    <Typography variant='h6'>Product Preview</Typography>
                    <Button onClick={handleClickPreviewClose} variant="contained" color="primary">
                        Close
                    </Button>
                </DialogContent>
                <DialogContent>
                    {/*  <Typography>Variants</Typography>
                    
                    <ButtonGroup>
                    {fullProduct.variants.sort().map((option, index) => {
                        return (
                        <Button variant="contained" key={index} color={currentIndex === index ? "primary" : "secondary"} onClick={() => currentIndexHandler(index)}>
                            {index === 0 ? "Default" : index+1}</Button>
                            
                        )})}
                        
                    </ButtonGroup> */}

                    <ProductPreview
                        product={fullProduct}
                    /*  currentVariant={fullProduct.variants[currentIndex]} */
                    />
                </DialogContent>

            </Dialog>

        <Grid className={classes.content}>
            <Grid className={classes.backButton}>
                    <Button variant="contained" onClick={() => props.history.goBack()} startIcon={<ArrowBackIcon/>}>Back</Button>
            </Grid>

                <Paper className={classes.card}>
                    <Dialog
                        open={open}
                        onClose={handleClose}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                    >
                        <DialogTitle id="alert-dialog-title">{"Delete Product?"}</DialogTitle>
                        <DialogContent>
                            <DialogContentText id="alert-dialog-description">
                                Are you absolutely sure you want to delete this product?
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleClose} variant="contained" color="secondary">
                                No
                            </Button>
                            <Button onClick={handleDeleteClick} variant="contained" style={{ backgroundColor: 'red', color: 'white' }} autoFocus>
                                Yes
                            </Button>
                        </DialogActions>
                    </Dialog>

                    <Grid container spacing={smallScreen ? 1 : 4}>
                        <Grid item md={8} xs={12} className={classes.groupedInfo}>
                        </Grid>
                        <Grid item md={4} xs={12} style={{ position: 'relative' }}>
                            <Tooltip title="CAREFUL">

                                <ButtonGroup fullWidth aria-label="contained primary button group">
                                    <Button variant="contained" color="primary"
                                        onClick={handleClickPreviewOpen} startIcon={<BurstModeIcon />}>
                                        Preview
                                    </Button>
                                    <Button variant="contained" color="primary" style={roles.includes('Create|Update|Delete Products') ? { color: 'white', backgroundColor: 'red' } : {}}
                                        onClick={handleClickOpen} startIcon={<DeleteIcon />}
                                        disabled={props.product.docId === "fourWayMix" || props.product.docId === "screenedSandyLoam" || props.product.docId === "clayFill" || !roles.includes('Create|Update|Delete Products')}>
                                        Delete
                                    </Button>

                                </ButtonGroup>


                            </Tooltip>
                        </Grid>
                        <Grid item md={8} xs={12} className={classes.groupedInfo}>
                            <TextField
                                style={smallScreen ? { marginBottom: '1px' } : { marginBottom: '8px' }}
                                label='Product Name'
                                value={product.name}
                                required
                                disabled={!roles.includes('Create|Update|Delete Products')}
                                variant='outlined'
                                onChange={(event) => { setProduct({ ...product, 'name': event.target.value }) }}
                                onBlur={() => blurHandler()}
                                inputProps={smallScreen ? { style: { fontSize: '18px' } } : { style: { fontSize: '2.125rem' } }} />
                        </Grid>
 

                        <Grid item md={4} xs={6} className={classes.groupedInfo} style={{ position: 'relative' }}>
                            <FormControl disabled={!roles.includes('Create|Update|Delete Products')} fullWidth variant='outlined'>
                                <InputLabel>Category</InputLabel>
                                <Select
                                    required
                                    disabled={!roles.includes('Create|Update|Delete Products')}
                                    value={product.category}
                                    label="Category"
                                    onChange={event => {
                                        if (event.nativeEvent.target.getAttribute('name') !== 'create')
                                            setProduct({ ...product, 'category': event.target.value })
                                        else
                                            setModalOpen(true);
                                    }}
                                    onBlur={() => blurHandler()}>

                                    {props.productCategories.sort().map((category, index) => {
                                        return <MenuItem key={'category' + index} value={category}>{category}</MenuItem>
                                    })}
                                </Select>
                            </FormControl>

                            <Modal
                                open={modalOpen}
                                onClose={() => setModalOpen(false)}>
                                <Paper className={classes.modal}>
                                    <div style={{ display: 'flex', justifyContent: 'center', marginBottom: '16px' }}>
                                        <ButtonGroup>
                                            <Button color='primary' variant={categoryManageMode === 'create' ? 'contained' : 'outlined'} onClick={() => setCategoryManageMode('create')}>Create Category</Button>
                                            <Button color='primary' variant={categoryManageMode === 'remove' ? 'contained' : 'outlined'} onClick={() => setCategoryManageMode('remove')}>Remove Category</Button>
                                        </ButtonGroup>
                                    </div>
                                    <div style={{ display: 'flex', alignItems: 'center', marginBottom: '12px' }}>
                                        {categoryManageMode === 'create'
                                            ? <React.Fragment>
                                                <Typography style={{ color: 'white', minWidth: '200px' }}>Enter a new Category:</Typography>
                                                <TextField
                                                    className={classes.modalInput}
                                                    size='small'
                                                    variant='outlined'
                                                    value={newCategory}
                                                    onChange={(event) => setNewCategory(event.target.value)}
                                                    fullWidth
                                                />
                                            </React.Fragment>
                                            : <React.Fragment>
                                                <Typography style={{ color: 'white', minWidth: '200px' }}>Remove a Category:</Typography>
                                                <Select
                                                    value={props.product.category}
                                                    label="Category"
                                                    onChange={(event) => setNewCategory(event.target.value)}>

                                                    {props.productCategories.sort().map((category, index) => {
                                                        return <MenuItem key={'category' + index} value={category}>{category}</MenuItem>
                                                    })}
                                                </Select>
                                            </React.Fragment>
                                        }
                                    </div>
                                    <Typography style={{ color: 'red' }}>{categoryError}</Typography>
                                    <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '12px' }}>
                                        <Button variant='text' color='primary' onClick={() => setModalOpen(false)}>Cancel</Button>
                                        <Button variant='contained' style={{ marginLeft: '8px' }} onClick={() => handleCategoryClick()}>Create</Button>
                                    </div>
                                </Paper>
                            </Modal>
                        </Grid>
                        <Grid item md={12} xs={12} className={classes.groupedInfo}>
                            <TextField
                                style={smallScreen ? { marginBottom: '1px' } : { marginBottom: '8px' }}
                                label='URL Slug'
                                fullWidth
                                value={product.slug}
                                placeholder={product.name.replace(/ /g, '-').toLowerCase()}
                                required
                                disabled={!roles.includes('Create|Update|Delete Products')}
                                variant='outlined'
                                onChange={(event) => { setProduct({ ...product, 'slug': event.target.value.replace(/ /g, '-').toLowerCase() }) }}
                                onBlur={() => blurHandler()}
                                 />
                        </Grid>

                        <Grid item md={2} xs={4} style={{ display: 'flex', flexDirection: 'column' }} className={classes.groupedInfo}>
                            <InputLabel>Discount Type</InputLabel>
                            <FormControl disabled={!roles.includes('Create|Update|Delete Products')} variant='outlined'>
                                <Select value={product?.discountType || '%'} onChange={(event) => { setProduct({ ...product, 'discountType': event.target.value }) }}
                                onBlur={() => blurHandler()} >
                                <MenuItem value={'%'}>Percent</MenuItem>
                                <MenuItem value={'$'}>Dollars</MenuItem>
                                </Select>
                            </FormControl>   
                        </Grid>
                        <Grid item md={8} xs={12} style={{ display: 'flex', flexDirection: 'column' }} className={classes.groupedInfo}> 
                            <InputLabel>Product Discount for 10 or more Yards  <small>(Only applicable to products with yards as an option)</small></InputLabel>
                            <FormControl disabled={!roles.includes('Create|Update|Delete Products')} variant='outlined'>
                                
                                <TextField type='number' inputProps={{ style: { fontSize: '1.125rem' } }} variant="outlined" value={product?.discount || 0} 
                                    InputProps={{
                                        startAdornment: <InputAdornment position="start">{product?.discountType || '%'}</InputAdornment>,
                                      }}
                                    onChange={(event) => { setProduct({ ...product, 'discount': event.target.value }) }}
                                    onBlur={() => blurHandler()} />
                            </FormControl>
                        </Grid>

                        <Grid item md={8} xs={12} style={{ display: 'flex', flexDirection: 'column' }} className={classes.groupedInfo}>
                            <TextField
                                style={{ marginBottom: '16px' }}
                                label='Short Description'
                                value={product.descriptionShort}
                                fullWidth
                                disabled={!roles.includes('Create|Update|Delete Products')}
                                onChange={(event) => { setProduct({ ...product, 'descriptionShort': event.target.value }) }}
                                onBlur={() => blurHandler()} />
                            <TextField
                                style={{ marginTop: '16px' }}
                                label='Full Description'
                                value={product.description}
                                required
                                multiline
                                disabled={!roles.includes('Create|Update|Delete Products')}
                                variant='outlined'
                                fullWidth
                                onChange={(event) => { setProduct({ ...product, 'description': event.target.value }) }}
                                onBlur={() => blurHandler()} />
                        </Grid>

                        <Grid item md={4} xs={12} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                            <Card className={classes.headerPhoto}>
                                <CardActionArea className={classes.headerPhoto} style={{ display: 'initial' }} onClick={() => document.getElementById('basePhoto').click()/*Find an alternative*/}>
                                    <CardContent className={classes.headerPhoto}>
                                        <input
                                            style={{ display: 'none' }}
                                            type="file"
                                            id={"basePhoto"}
                                            disabled={!roles.includes('Create|Update|Delete Products')}
                                            accept="image/*"
                                            onChange={(event) => { props.updateData({ ...props.product, 'photoURL': event.target.files[0] }) }
                                            } />
                                        {props.product.photoURL === ''
                                            ? <Typography>Base Image</Typography>
                                            : <img alt="" src={!_.isUndefined(props.product.photoURL) && props.product.photoURL instanceof File ? URL.createObjectURL(props.product.photoURL) : props.product.photoURL} className={classes.image} />
                                        }
                                    </CardContent>
                                </CardActionArea>
                            </Card>
                        </Grid>

                        <Grid item md={4} xs={12}>
                            <FormControlLabel
                                label='Hide Product on Store'
                                disabled={!roles.includes('Create|Update|Delete Products')}
                                control={
                                    <Checkbox
                                        checked={props.product.hideOnStore}
                                        onChange={() => {
                                            props.updateData({ ...props.product, 'hideOnStore': !props.product.hideOnStore })
                                        }}
                                    />}
                            />
                        </Grid>

                        <Grid item md={4} xs={12} className={classes.groupedInfo}>
                            <TextField
                                label="Display Unit"
                                disabled={!roles.includes('Create|Update|Delete Products')}
                                value={product.displayUnit}
                                fullWidth
                                onChange={(event) => { setProduct({ ...product, 'displayUnit': event.target.value }) }}
                                onBlur={() => blurHandler()} />
                        </Grid>

                        <Grid item xs={12} className={classes.groupedInfo}>
                            <Grid container spacing={2}>
                                <Grid item md={4} xs={12} style={{ display: 'flex', flexDirection: 'column' }}>
                                    <Typography>Shipping Options</Typography>
                                    <FormControlLabel
                                        label='Pickup'
                                        disabled={!roles.includes('Create|Update|Delete Products')}
                                        control={
                                            <Checkbox
                                                onChange={() => setShipOption("Pickup")}
                                                checked={props.product.shipOption.includes('Pickup')}
                                            />}
                                    />
                                    <FormControlLabel
                                        label='Delivery'
                                        disabled={!roles.includes('Create|Update|Delete Products')}
                                        control={
                                            <Checkbox
                                                onChange={() => setShipOption("Delivery")}
                                                checked={props.product.shipOption.includes('Delivery')}
                                            />}
                                    />
                                </Grid>
                                <Grid item md={4} xs={12} style={{ display: 'flex', flexDirection: 'column' }}>
                                    <Typography>Quantity</Typography>
                                    <TextField
                                        type='number'
                                        disabled={!roles.includes('Create|Update|Delete Products')}
                                        error={isNaN(Number.parseFloat(props.product.qtyMin))}
                                        value={product.qtyMin}
                                        required
                                        label="Minimum"
                                        inputProps={{
                                            step: 0.01
                                        }}
                                        onChange={(event) => {
                                            setProduct({ ...product, 'qtyMin': parseFloat(event.target.value) })
                                        }}
                                        onBlur={() => blurHandler()}
                                    />
                                    <TextField
                                        type='number'
                                        disabled={!roles.includes('Create|Update|Delete Products')}
                                        error={isNaN(Number.parseFloat(props.product.qtyStep))}
                                        value={product.qtyStep}
                                        required
                                        label="Step"
                                        inputProps={{
                                            step: 0.01
                                        }}
                                        onChange={(event) => {
                                            setProduct({ ...product, 'qtyStep': parseFloat(event.target.value) })
                                        }}
                                        onBlur={() => blurHandler()}
                                    />
                                </Grid>
                                <Grid item md={4} xs={12} style={{ display: 'flex', flexDirection: 'column' }}>
                                    <Typography>Applicable Taxes</Typography>
                                    <FormControlLabel
                                        label='GST'
                                        disabled={!roles.includes('Create|Update|Delete Products')}
                                        control={
                                            <Checkbox
                                                checked={props.product.taxClass.includes('GST')}
                                                onChange={() => {
                                                    let taxClass = [...props.product.taxClass];
                                                    let taxIndex = taxClass.indexOf('GST');
                                                    taxIndex !== -1 ? taxClass.splice(taxIndex, 1) : taxClass.push('GST');

                                                    props.updateData({ ...props.product, 'taxClass': taxClass })
                                                }
                                                }
                                            />}
                                    />
                                    <FormControlLabel
                                        label='PST'
                                        disabled={!roles.includes('Create|Update|Delete Products')}
                                        control={
                                            <Checkbox
                                                checked={props.product.taxClass.includes('PST')}
                                                onChange={() => {
                                                    let taxClass = [...props.product.taxClass];
                                                    let taxIndex = taxClass.indexOf('PST');
                                                    taxIndex !== -1 ? taxClass.splice(taxIndex, 1) : taxClass.push('PST');

                                                    props.updateData({ ...props.product, 'taxClass': taxClass })
                                                }
                                                }
                                            />}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid item xs={6}>
                            <FormControl
                                fullWidth
                                disabled={!roles.includes('Create|Update|Delete Products')}
                                variant='outlined'>
                                <InputLabel>Shipping Class</InputLabel>
                                <Select
                                    value={product.shippingClass}
                                    label="ShippingClass"
                                    onChange={event => { setProduct({ ...product, 'shippingClass': event.target.value }) }}
                                    onBlur={() => blurHandler()}>
                                    <MenuItem value={'standard'}>Standard</MenuItem>
                                    <MenuItem value={'brick'}>Per Brick</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>

                        <Grid item xs={6}>
                            <FormControl
                                fullWidth
                                variant='outlined'
                                disabled={props.product.variantTypes.includes('NOVARIANT') || !roles.includes('Create|Update|Delete Products')}>
                                <InputLabel>Price Shown on Product Card</InputLabel>
                                <Select
                                    value={props.product.showPrice}
                                    label="Price Shown on Product Card"
                                    onChange={event => { props.updateData({ ...props.product, 'showPrice': event.target.value }) }}>
                                    <MenuItem name='create' value={-1} style={{ marginBottom: '8px' }}>
                                        Show Lowest Price
                                    </MenuItem>
                                    <Divider />
                                    {props.product.variants.map((variant, index) => {
                                        return <MenuItem key={'variant' + index} value={index}>{Object.values(variant.variantTypes).join(', ')} | ${variant.price}</MenuItem>
                                    })}

                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={6}>
                            Tags:
                            
                            {props.product?.tags && props.product?.tags.length > 0 ? props.product.tags.map((tag, index) =>  {
                                return (
                                    <Chip
                                    key={index}
                                    style={{padding:'4px', margin:'4px'}}
                                    label={tag}
                                    disabled={!roles.includes('Create|Update|Delete Products')}
                                    onDelete={(event) => deleteTag(tag)}
                                    color="primary"
                                    />
                                )
                            })
                            : <p><small>No tags available.</small></p>}
                            <Grid>
                                <TextField
                                placeholder={"Add new tag"}
                                disabled={!roles.includes('Create|Update|Delete Products')}
                                value={newTag || ""}
                                onChange={(event) => setNewTag(event.target.value)}
                                onKeyPress={(ev) => {
                                    if (ev.key === 'Enter' && newTag !== "") {
                                        addTags()
                                        ev.preventDefault();
                                    }
                                }}
                                />
                                {error &&
                        <p style={{color:"red"}}><small>{error}</small></p>}
                            </Grid>
                            
                        </Grid>

                        <Grid item md={12} xs={12} >
                            <div style={{ display: 'flex', border: '1px solid rgba(0, 0, 0, 0.23)', borderRadius: '8px', alignItems: 'center', justifyContent: 'space-between', paddingRight: '8px' }}>
                                <Button variant="contained" color="primary"
                                disabled={!roles.includes('Create|Update|Delete Products')}
                                    style={{
                                        borderTopLeftRadius: '8px',
                                        borderBottomLeftRadius: '8px',
                                        borderTopRightRadius: '0px',
                                        borderBottomRightRadius: '0px'
                                    }}
                                    onClick={() => {
                                        handleOpenProducts()
                                    }}>
                                    Similar Products
                                </Button>
                                {props.product?.similarProducts ?
                                <Typography><small>This product has {props?.product?.similarProducts?.length} similar products.</small></Typography> :
                                <Typography><small>This product has 0 similar products.</small></Typography>    }
                                    
                            </div>
                            <Typography><small>Similar Products will appear under the Customers Also Purchased section in the Shop.</small></Typography>
                        </Grid>
                    </Grid>
                </Paper>
            </Grid>
        </React.Fragment>
    )
}

export default withRouter(ProductCard);