import React, { useState, useEffect } from 'react';
import { Grid } from '@material-ui/core';
import { withRouter } from 'react-router';
import { firestore, removeFile } from '../../../firebase/firebase';
import Skeleton from '@material-ui/lab/Skeleton';
import useAsyncError from '../../components/UI/AsyncError/AsyncError';

import InvoiceCard from '../../components/Invoice/InvoiceCard';

const Invoice = (props) => {
  
  const throwAsyncError = useAsyncError();

  const [internalLoading, setInternalLoading] = useState(true)
  const [invoiceData, setInvoiceData] = useState({})

  useEffect(() => {
    let path = new URLSearchParams(props.location.search).get("docId");

    firestore.collection('invoices').where('__name__', "==", path).get()
    .then(querySnapshot => {
        const safetyResponse = querySnapshot.docs.map((doc) => {
            return {
                ...doc.data(),
                'docId': doc.id,
            }
        })
        setInvoiceData(safetyResponse[0]);
        setInternalLoading(false);
    })
    .catch(e => {
        throwAsyncError(new Error(e.message, e));
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.location.search]);

  const handleDeleteInvoice = () => {
    let path = new URLSearchParams(props.location.search).get("docId");
    let batch = firestore.batch();

    invoiceData.tickets.forEach((ticket) => {
      let query = firestore.collection('deliveries').doc(ticket)
      batch.update(query, { invoicePayDate: null });
    })

    let invoiceQuery = firestore.collection('invoices').doc(path)
    batch.delete(invoiceQuery);

    if("invoiceData" in sessionStorage){
      let newInvoiceData = JSON.parse(sessionStorage.getItem("invoiceData"))
      newInvoiceData.splice(newInvoiceData.findIndex(x=> x.docId === path), 1)
      sessionStorage.setItem("invoiceData", JSON.stringify(newInvoiceData));
    }

    batch.commit()
      .then(async () => {
        await removeFile(invoiceData.invoiceURL).then(() => {
          props.history.replace({pathname: '/dashboard/invoicing',})
        })

      })
      .catch((e) => {
        throwAsyncError(new Error(e.message, e));
      });
  }

  return (
    <Grid>
        {internalLoading 
        ?
            <Skeleton></Skeleton>
        :      
            <Grid>
              <InvoiceCard 
                invoiceData={invoiceData}
                handleDeleteInvoice={handleDeleteInvoice}
              />
            </Grid>
        }

    </Grid>
  )
}

export default withRouter(Invoice);