/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router'
import ConversationManager from '../../../components/Orders/Conversations/ConversationManager';
import {firestore, functions } from '../../../../firebase/firebase'
import _ from 'lodash';
import moment from 'moment';
import Meta from '../../../../providers/Meta'

const Conversations = (props) => {
    const [loading, setLoading] = useState(true)
    const [currentConversation, setCurrentConversation] = useState([])
    const [lastMessages, setLastMessages] = useState([])
    const [allUnreadMessages, setAllUnreadMessages] = useState([])

    const [selectedContact, setSelectedContact] = useState(() => {
        const savedContact = sessionStorage.getItem("selectedSMSContact");
        if (savedContact) {
          try {
            return JSON.parse(savedContact);
          } catch (error) {
            console.error("Error parsing JSON from sessionStorage:", error);
            return '';
          }
        }
        return '';
      });
    const [contacts, setContacts] = useState([])

    const [newNumber, setNewNumber] = useState("")
    const [newName, setNewName] = useState("")
    const [newLastName, setNewLastName] = useState("")
    const [newContactModal, setNewContactModal] = useState(false)

    const [confirmationModal, setConfirmationModal] = useState(false)
    const [confirmationModalType, setConfirmationModalType] = useState('')
    const [processing, setProcessing] = useState(false)

    const [allConversations, setAllConversations] = useState([])
    //const allMessages = useRef([])

    //const fetchConvos = functions.httpsCallable('fetchConversations');
    const sendText = functions.httpsCallable('sendText');
    const fetchAllContacts = functions.httpsCallable('fetchContacts');
    const grabAPIMessages = functions.httpsCallable('fetchConversations');
    const addContact = functions.httpsCallable('addSimpleTextContact');
    const deleteContact = functions.httpsCallable('deleteSimpleTextContact');

    /* I dont think this is needed since fetch runs when the listener is triggered
    useEffect(() => {
        const interval = setInterval(() => {
          fetchContacts(allConversations);
        }, 300000); // 5 minutes
      
        return () => clearInterval(interval);
        //eslint-disable-next-line
      }, []);
      */

    useEffect(() => {
        if(selectedContact !== ''){ validateConversations() }
        //eslint-disable-next-line
    }, [selectedContact])

          //goes through all messages and sets any missing from api to db
    const validateConversations = () =>{
        let batch = firestore.batch();
        let convoDbContainer = []

        firestore.collection('messages').where('contactPhone','==',selectedContact).get()
        .then((querySnapshot) => {
            convoDbContainer = querySnapshot.docs.map((doc) => {
                return { ...doc.data(),}
            });
            grabAPIMessages({
                sandbox: process.env.NODE_ENV === 'development',
                phone: selectedContact
            }).then((res) => {
                let convos = res.data?.content || []
                convos.forEach((message) => {
                    if(!convoDbContainer.map((message) => { return message.id }).includes(message.id)){
                        convoDbContainer.push({...message, read: false})
                        let query = firestore.collection('messages').doc(message.id)
                        batch.set(query, {...message, read: false});
                    }
                })
            }).finally(() => { 
                //if batch isnt empty
                batch.commit()
            }).catch((err) => {
                console.warn(err)
            })
        }).catch((err) => {
            console.warn(err)
        })
    }

    
    useEffect(() => {
        const currentYear = new Date().getFullYear();
        const startOfYear = new Date(`${currentYear}-01-01T00:00:00.000Z`);
        const endOfYear = new Date(`${currentYear + 1}-01-01T00:00:00.000Z`);
    
        const startOfYearString = startOfYear.toISOString();
        const endOfYearString = endOfYear.toISOString();
    
        const unsubscribe = firestore.collection('messages')
            .where('timestamp', '>=', startOfYearString)
            .where('timestamp', '<', endOfYearString)
            .orderBy('timestamp', 'desc')
            .onSnapshot(snapshot => {
                if (!snapshot.empty) {
                    const newMessages = snapshot.docs.map(doc => doc.data());
                    setAllConversations(newMessages);
                    fetchContacts(newMessages);
                } else {
                    console.log("No messages found for the current year.");
                }
            }, error => {
                console.error("Error fetching messages: ", error);
            });
    
        return () => {
            unsubscribe();
        };
    }, []);

    //filters through all messages and gets lastMessage, all unread messages, and sets currentConversation if applicable
    useEffect(() => {
        if (allConversations.length > 0 && contacts.length > 0) {
            let lastMessageContainer = [];
            let unreadMessageContainer = [];
    
            contacts.forEach((contact) => {
                let contactMessages = allConversations.filter((message) => message.contactPhone === contact.contactPhone);
                if (contact.contactPhone === selectedContact) { 
                    setCurrentConversation(contactMessages); 
                }
    
                let currentLastRead = null;
    
                contactMessages.forEach((message) => {
                    if (currentLastRead === null || moment(currentLastRead.timestamp).isBefore(moment(message.timestamp))) {
                        currentLastRead = message;
                    }
                    if (message.read === false) { 
                        unreadMessageContainer.push(message); 
                    }
                });
    
                if (currentLastRead) {
                    lastMessageContainer.push(currentLastRead);
                }
            });
    
            setAllUnreadMessages([...unreadMessageContainer]);
            setLastMessages([...lastMessageContainer]);
            setLoading(false);
        }
        //eslint-disable-next-line
    }, [allConversations, contacts]);

    const toggleNewContactModal = () => {
        setNewContactModal(!newContactModal)
        setNewNumber("")
        setNewName("")
        setNewLastName("")
    }

    //filters contacts conversations from allMessages
    const fetchConvosFromPhone = async (phone) =>  {
        setCurrentConversation(allConversations.filter((message) => { return message.contactPhone === phone }))
    }


    //adds a contact to both fb and simpleTexting
    const handleAddContact = async () => {
        setProcessing(true)
        let userContainer = {
            firstName: newName,
            lastName: newLastName,
            contactPhone: newNumber.replace(/\s/g, '').replace(/-/g, '').replace(/\(/g, '').replace(/\)/g, ''),
            unsubscribed: false,
        }
        addContact({
            sandbox: process.env.NODE_ENV === 'development',
            firstName: newName,
            lastName: newLastName,
            phone: newNumber.replace(/\s/g, '').replace(/-/g, '').replace(/\(/g, '').replace(/\)/g, ''),
        }).then((res) => {

            //then if user doesnt already exist
            let contactContainer = _.cloneDeep(contacts)
            contactContainer.push(userContainer)
            setContacts(contactContainer)
            setProcessing(false)
            toggleNewContactModal();

        }).catch((err) => {
            console.warn(err)
            setProcessing(false)
            toggleNewContactModal();
        })
    }

    const handleRemoveContact = (contact) => {
        setProcessing(true)
        deleteContact({
            sandbox: process.env.NODE_ENV === 'development',
            phone: contact.contactPhone,
        })
        .then((res) => {

            let contactsConatiner = _.cloneDeep(contacts)
            let index = contactsConatiner.findIndex(x => contact.contactPhone === x.contactPhone)
            contactsConatiner.splice(index, 1)
            setContacts(contactsConatiner)

            //SHOULD HAVE BEEN HANDLED BY LISTENER ???
            // let conversationContainer = _.cloneDeep(allConversations)
            // conversationContainer.forEach((x, index)=> {
            //     if(x.contactPhone === contact.contactPhone_){
            //         conversationContainer.splice(index, 1)
            //     }
            // })
            // setAllConversations(conversationContainer)

            setCurrentConversation([])
            setSelectedContact('')
            setConfirmationModal(false); 
            setProcessing(false)

        }).catch((err) => {
            console.warn(err)
            setProcessing(false)
        })
    }

    const markRead = async (message) => {
        firestore.collection('messages').doc(message.id).update({read: true})
    }

    //get number of unread messages
    const getUnread = () => {  
        return allUnreadMessages.filter(convo => convo.directionType === 'MO' && !convo.read).length
    }
    
    // Helper function to split array into chunks
    const chunkArray = (array, size) => {
        const result = [];
        for (let i = 0; i < array.length; i += size) {
        result.push(array.slice(i, i + size));
        }
        return result;
    };
    const BATCH_SIZE = 10; // Firestore 'in' query limit

    const fetchContacts = async (allConversations) => {
        try {
            
            const contactsRef = firestore.collection('smsContacts');
            //get all unique contacts from allMessages
            const uniqueContacts = [...new Set(allConversations.map(message => message.contactPhone))];
            const contactChunks = chunkArray(uniqueContacts, BATCH_SIZE);
            const contactsPromises = contactChunks.map(chunk => 
                contactsRef.where('contactPhone', 'in', chunk).get()
              );
            console.log(uniqueContacts)

            const contactsSnapshot = await Promise.all(contactsPromises);
            const contacts = []
            contactsSnapshot.forEach(snapshot => {
                snapshot.forEach(doc => {
                  contacts.push(doc.data());
                });
              });

            //const contactsSnapshot = await contactsRef.get();
            
            const existingPhones = contacts.map(contact => contact.contactPhone);
            let apiContacts = [];

            contacts.forEach(async (contact) => {   
                if(_.isUndefined(contact.contactPhone) && contact?.phone.includes("+1")){
                    let newphone = contact.phone.replace('+1', '')
                    await contactsRef.doc(contact.phone).update({ contactPhone: newphone });
                }
                else if(_.isUndefined(contact.contactPhone) && contact?.phone.startsWith('10')){
                    let newphone = contact.phone.slice(2)
                    await contactsRef.doc(contact.phone).update({ contactPhone: newphone });
                }
                else if(_.isUndefined(contact.contactPhone) && contact?.phone.startsWith('1')){
                    let newphone = contact.phone.slice(1)
                    await contactsRef.doc(contact.phone).update({ contactPhone: newphone });
                }
                else if(contact?.contactPhone?.startsWith('1')){
                    let newphone = contact.contactPhone.slice(1)
                    await contactsRef.doc(contact.phone).update({ contactPhone: newphone });
                }
                else if(_.isUndefined(contact.contactPhone) && contact?.phone.length < 10){
                    await contactsRef.doc(contact.phone).delete();
                }
            })


            const res = await fetchAllContacts({
                sandbox: process.env.NODE_ENV === 'development',
                type: 'all',
            });
      
            const convos = res.data.content;
        
            convos.forEach(convo => {
                apiContacts.push(convo);
            });
      
            for (const apiContact of apiContacts) {
                if (!existingPhones.includes(apiContact.contactPhone)) {
                const newContact = {
                    firstName: apiContact.firstName || "Unnamed",
                    lastName: apiContact.lastName || "Unnamed",
                    contactPhone: apiContact.contactPhone,
                    unsubscribed: false,
                };
                await contactsRef.doc(apiContact.contactPhone).set(newContact, { merge: true });
                contacts.push(newContact);
                }
            }
      
            setContacts(contacts);
        } catch (err) {
          console.warn(err);
        }
      };

    //merges or adds message to collection
    const addMessage = (message) => {
        firestore.collection('messages').doc(message.id).set(message, { merge: true })
    } 

    const sendSMS = async (phoneMessage, contact) => {
        await sendText({
            sandbox: process.env.NODE_ENV === 'development',
            phone: contact,
            message: phoneMessage,
        }).then((res) => {
            let message = {
                id: res.data.id,
                text: phoneMessage,
                contactPhone: contact,
                directionType: 'MT',
                timestamp: moment().valueOf(),
                read: true,
                delivered: false,
                sending: true,
            }

            addMessage(message) //should be caught by outgoing hook?
            //wait 5 seconds then update messages delivered to true and sending to false
            //sorry lando but this is happening here lol
            setTimeout(() => {
                firestore.collection('messages').doc(message.id).update({ delivered: true, sending: false })
            }, 5000)
    
        }).catch((err) => {
            console.warn(err)
        })
    }

    //adds hidden bool to message
    const hideMessage = async (messageId) => {
        setProcessing(true)
        await firestore.collection('messages').doc(messageId).update({hidden: true})
        // should copy allConversations, update this message and set to allConversations
        setConfirmationModal(false); 
        setProcessing(false)
    }

    return (
        <div>
            <Meta title={getUnread() <= 0 ? 'Gee Tee | Text Inbox' : `Gee Tee | Text Inbox (${getUnread()})`}/>
            <ConversationManager 
                conversations={currentConversation} markRead={markRead}
                lastMessages={lastMessages} allUnreadMessages={allUnreadMessages}
                data={currentConversation} contacts={contacts} handleAddContact={handleAddContact}
                newNumber={newNumber} setNewNumber={setNewNumber} newName={newName}
                setNewName={setNewName} newLastName={newLastName} setNewLastName={setNewLastName}
                newContactModal={newContactModal} toggleNewContactModal={toggleNewContactModal}
                loading={loading}
                setConversations={setCurrentConversation}
                sendSMS={sendSMS} fetchConvosFromPhone={fetchConvosFromPhone} 
                selectedContact={selectedContact} setSelectedContact={setSelectedContact}
                confirmationModal={confirmationModal} setConfirmationModal={setConfirmationModal}
                confirmationModalType={confirmationModalType} setConfirmationModalType={setConfirmationModalType}
                hideMessage={hideMessage} handleRemoveContact={handleRemoveContact}
                processing={processing} setProcessing={setProcessing}
            />
        </div>
    )
}

export default withRouter(Conversations);