import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { Grid, Typography, Button, TextField, Divider, Modal, Paper } from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab';
import moment from 'moment';
import { makeStyles, CircularProgress } from '@material-ui/core';
import { firestore } from '../../../../../firebase/firebase'
import { DateTimePicker } from '../../../../components/UI/DatePicker/DatePicker';

const useStyles = makeStyles((theme) => ({
    content: {
        padding: '32px',
        paddingRight: '230px',
        overflow: 'auto'
    },

    sidebar: {
        position: 'fixed',
        right: 0,
        top: '64px',
        maxWidth: '256px',
        width: '100%',
        padding: '16px',
        zIndex: '1000',
        overflowY: 'auto',
        minHeight: "calc(100vh - 64px)",
        maxHeight: "calc(100vh - 64px)",
    },
    sidebarSpacer: {
        marginLeft: '32px',
        maxWidth: '256px',
        width: '100%',
    },
    expand: {
        position: 'fixed',
        right: 0,
        top: '444px',
        transform: 'rotate(90deg)',
        marginLeft: 'auto',
        transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.shortest,
        }),
    },
    expandOpen: {
        position: 'fixed',
        right: 253,
        top: '444px',
        transform: 'rotate(270deg)',
    },
    modal: {
        width: '500px',
        padding: '16px',
        margin: '0 auto',
        marginTop: '40vh',
    },
}));

const CreateNewTimesheetModal = (props) => {

    const classes = useStyles();

    const createNewDefault = {
        user: props.defaultUser || {},
        timesheetType: '',
        inTime: '',
        timesheetID: 0,
    }
    const [createNewData, setCreateNewData] = useState(createNewDefault)
    const [createNewUidAutocomplete, setCreateNewUidAutoComplete] = useState(false)
    //const [createNewUidTypedValue, setNewCreateNewUidTypedValue] = useState('')
    const [createNewTimesheetTypeAutocomplete, setCreateNewTimesheetTypeAutoComplete] = useState(false)
    //const [createNewTimesheetTypeTypedValue, setNewCreateNewTimesheetTypeTypedValue] = useState('')
    const [createNewErrror, setCreateNewError] = useState('')
    const [processing, setProcessing] = useState(false)
    const [createNewModal, setCreateNewModal] = useState(props.open)

    //takes in open props from parent
    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => {setCreateNewModal(props.open) }, [props.open === true])

    const handleCreateTimesheet = () => {
        let docId = ''
        if(createNewData.user.uuid !== '' && createNewData.user.uuid !== undefined && createNewData.timesheetType !== '' && createNewData.inTime !== ''){
            setProcessing(true)
            setCreateNewError('')
            let newDoc = {uid: createNewData.user.uuid, timesheetType: createNewData.timesheetType, date: moment(createNewData.inTime).startOf('day').valueOf(), submitted: false,
                          inTime: moment(createNewData.inTime).valueOf(), approved: false, timesheetID: 0, timesheetStatus: 'unseen',
                        offHours:"", skipLunch: "na",}

            firestore.collection('timesheets').add(newDoc)
            .then(function(docRef) {
                //get docId
                docId= docRef.id

                //alter data to emulate assignment of data on pull (uid specifically)
                //let container = [...timesheetData, {...newDoc, docId: docRef.id, date: moment.utc(newDoc.date).format('YYYY-MM-DD'), uid: (users.filter(user => user.uuid === newDoc.uid)[0].displayName), operatorHours: 0.00 }]
                //setTimesheetData(container)
                //sessionStorage.setItem("timesheetData", JSON.stringify(container));

                // callback with this container ^
                props.onCreateCallback({...newDoc, docId: docRef.id, date: moment.utc(newDoc.date).format('YYYY-MM-DD'), uid: (props.users.filter(user => user.uuid === newDoc.uid)[0].displayName), operatorHours: 0.00 })

                setCreateNewData(createNewDefault)

                setCreateNewModal(false)
                props.onCloseCallback();

                setProcessing(false)
            }).finally(() => {
                //go to timesheet
                //get docId of new timesheet

                props.timesheetClickHandler(docId)

            })
            .catch((e) => {
                console.log(e);
                setCreateNewModal(false)
                props.onCloseCallback();
                setProcessing(false)
            });
        }
        else{
            setCreateNewError('** Please Input into all Fields **')
        }
    }


    return (
        <Modal
            open={createNewModal}
            onClose={() => { setCreateNewModal(false); props.onCloseCallback(); }}
        >
            <Paper className={classes.modal}>
                <Grid>
                    <Grid style={{display: 'flex', justifyContent: 'center'}}> 
                        <Typography variant='h6'>Create New Timesheet</Typography>
                    </Grid>

                    <Divider style={{marginBottom: '20px'}}/>

                    <Grid container >
                        <Grid item xs={6}>
                            <Typography style={{marginTop: '25px'}}>Employee:</Typography>
                        </Grid>
                        <Grid item xs={6} style={{marginTop: '10px'}}>
                            <Autocomplete
                                open={createNewUidAutocomplete}
                                onOpen={() => {
                                    setCreateNewUidAutoComplete(true)
                                }}
                                onClose={() => {
                                    setCreateNewUidAutoComplete(false)
                                }}
                                loading={props.userLoading}
                                label="Search"
                                id="uidAutocomplete"
                                autoComplete
                                autoHighlight
                                freeSolo={true}
                                clearOnBlur={true}
                                clearOnEscape={true}
                                //inputValue={createNewUidTypedValue}
                                // onInputChange={(event, newValue) => { 
                                //     setNewCreateNewUidTypedValue(newValue)
                                // }}
                                value={createNewData.user ?? ''}
                                onChange={(event, newValue) => { 
                                    setCreateNewData({...createNewData, user: newValue !== null ? newValue : ''});
                                }}
                                options={props.users ?? ''}
                                getOptionSelected={(option, value) => option?.uuid === value?.uuid}
                                getOptionLabel={(option) => option.displayName ?? ''}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="Employee"
                                        variant="outlined"
                                        InputProps={{
                                            ...params.InputProps,
                                            endAdornment: (
                                                <React.Fragment>
                                                    {props.userLoading ? <CircularProgress color="inherit" size={20} /> : null}
                                                    {params.InputProps.endAdornment}
                                                </React.Fragment>
                                            ),
                                        }}
                                    />
                                )}
                            />
                        </Grid>
                    </Grid>

                    <Grid container >
                        <Grid item xs={6}>
                            <Typography style={{marginTop: '25px'}}>Timesheet Type:</Typography>
                        </Grid>
                        <Grid item xs={6} style={{marginTop: '10px'}}>
                            <Autocomplete
                                open={createNewTimesheetTypeAutocomplete}
                                onOpen={() => {
                                    setCreateNewTimesheetTypeAutoComplete(true)
                                }}
                                onClose={() => {
                                    setCreateNewTimesheetTypeAutoComplete(false)
                                }}
                                label="Search"
                                id="timesheetTypeAutocomplete"
                                autoComplete
                                autoHighlight
                                freeSolo={true}
                                clearOnBlur={true}
                                clearOnEscape={true}
                                //inputValue={createNewTimesheetTypeTypedValue}
                                // onInputChange={(event, newValue) => { 
                                //     setNewCreateNewTimesheetTypeTypedValue(newValue.toString())
                                // }}
                                value={createNewData.timesheetType ?? ''}
                                onChange={(event, newValue) => {
                                    setCreateNewData({...createNewData, timesheetType: newValue !== null ? newValue.toString() : '' });
                                }}
                                options={props.timesheetType ?? ''}
                                getOptionSelected={(option, value) => option === value}
                                getOptionLabel={(option) => option ?? ''}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="Timesheet Type"
                                        variant="outlined"
                                        InputProps={{
                                            ...params.InputProps,
                                            endAdornment: (
                                                <React.Fragment>
                                                    {params.InputProps.endAdornment}
                                                </React.Fragment>
                                            ),
                                        }}
                                    />
                                )}
                            />
                        </Grid>
                    </Grid>

                    <Grid container style={{marginBottom: '40px'}}>
                        <Grid item xs={6}>
                            <Typography style={{marginTop: '25px'}}>Time In:</Typography>
                        </Grid>
                        <Grid item xs={6} style={{marginTop: '10px'}}>
                            <DateTimePicker
                                value={createNewData.inTime > 0 ? createNewData.inTime : null}
                                onChange={(date) => {
                                    setCreateNewData({...createNewData, inTime: date.valueOf()})
                                }}
                            />
                        </Grid>
                    </Grid>

                    <Grid container justifyContent="flex-end">
                        <Button 
                            variant='contained'
                            style={{marginRight: '10px', backgroundColor: 'red', color: 'white'}}
                            onClick={() =>{
                                setCreateNewModal(false)
                                props.onCloseCallback();
                                setCreateNewData(createNewDefault)
                                setCreateNewError('')
                            }}
                        >
                            Cancel
                        </Button>
                        <Button 
                            disabled={processing}
                            variant='contained'
                            onClick={() =>{handleCreateTimesheet()}}
                        >
                            {processing ? "Processing..."  : "Submit"}
                        </Button>
                    </Grid>
                    <Grid container justifyContent="flex-end">
                        <Typography style={{color: 'red'}}><small>{createNewErrror}</small></Typography>
                    </Grid>

                </Grid>
            </Paper>
        </Modal>
    )
}
export default withRouter(CreateNewTimesheetModal)