import React, {useEffect, useState} from 'react';
import { Divider, makeStyles, TextField, Typography } from '@material-ui/core';
import { Grid, IconButton, Paper, Checkbox, FormGroup, FormControlLabel, Snackbar, Button,
    Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle} from '@material-ui/core';
import { withRouter } from 'react-router-dom';
import _ from 'lodash'
import EditIcon from '@material-ui/icons/Edit';
import PaperTitle from '../../Orders/OrderPage/PaperTitle';
import { firestore } from '../../../../firebase/firebase';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import { useAuth } from '../../../../providers/AuthContext';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';

const useStyles = makeStyles((theme) => ({

    btnGroup: {
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
    },

    alias: {
        display: 'flex',
        marginTop: '8px',
        marginBottom: '8px',
        alignItems: 'center',
    },

    card: {
        padding: theme.spacing(4),
        display: 'flex',
        alignItems: 'center',
        height: '100%',
    },

    content: {
        display: 'flex',
        justifyContent: 'center',
        width: '100%',
    },

    paper: {
        padding: '20px',
        borderRadius: '0px',
        margin: '0px',
        marginBottom:'20px',
        width: '100%',
        maxWidth: '1000px',
        //backgroundColor: 'rgb(252, 252, 249)',
    },

    row: {
        display: 'flex',
        alignItems: 'center',
    },
    backButton: {
        marginBottom: '20px',
        marginTop: '20px',
    },
}));

const RoleCard = (props) => {
    const classes = useStyles();
    const { roles } = useAuth();

    const [edit, setEdit] = useState(props.path === 'new' ? true : false);
    const [saving, setSaving] = useState(false);
    const [error, setError] = useState('');
    const [nameError, setNameError] = useState('');

    const [categoryOpen, setCategoryOpen] = useState([])

    const [delModal, setDelModal] = useState(false);

    const openDelModal = () => {
        setDelModal(true)
    }
    const closeDelModal = () => {
        setDelModal(false)
    }

    useEffect(() => {
    }, [saving, props.users, props.roleData]);

    let roleCard = null;
    let userTable = null;

    const handleEdit = () => {
        if (edit)
            props.cancelSave();

        setEdit(!edit);
    }

    const safeRoles = [
        "Admin"
    ]

    const handleDelete = () => {
        setSaving(true)
        firestore.collection('users')
            .where('role', '==', props.roleData.title).get().then(snapshot => {
                const promises = [];
                snapshot.forEach(doc => {
                    promises.push(doc.ref.update({
                        role: '',
                    }));
                });
                return Promise.all(promises)
            })
            .catch(error => {
                console.log(error);
                return null;
            });
            firestore.collection('roles').doc(props.roleData.docId).delete().then(() => {
                console.log("Role successfully deleted!");
                props.history.replace({pathname: '/dashboard/users/roles'})
            }).catch((error) => {
                console.error("Error removing role: ", error);
            });
    }

    const handlePerms = (event) =>{
        setSaving(true)
        var permArray = props.roleData.permissions
        if(permArray.includes(event.target.name.toString())){
            if (permArray.indexOf(event.target.name.toString()) > -1) {
                permArray.splice(permArray.indexOf(event.target.name.toString()), 1);
                firestore.collection('roles').doc(props.roleData.docId)
                .update({
                    'permissions': permArray,
                })
                .then(() => {
                    //setSaveState(userData);
                    setSaving(false)
                })
                .catch((e) => {setError(e); console.log(error);});
            }
        } else {
            permArray.push(event.target.name.toString())
            firestore.collection('roles').doc(props.roleData.docId)
                .update({
                    'permissions': permArray,
                })
                .then(() => {
                    //setSaveState(userData);
                    setSaving(false)
                })
                .catch((e) => {setError(e); console.log(error);});
        }
    }

    const handleChange = (event) => {
        var rolesArray = []
        props.roles.forEach((role) => {
            return rolesArray.push(role.title)
        })
        if(rolesArray.includes(event.target.value)){
            setNameError(event.target.value + ' is already taken!')
            props.updateRoleData({ ...props.roleData, 'title': event.target.value })

        } else {
            setNameError('')
            props.updateRoleData({ ...props.roleData, 'title': event.target.value })
        }
    }

    const handleExpandCollapseCateogry = (category) => {
        if(!categoryOpen.includes(category)){
            setCategoryOpen([...categoryOpen, category])
        }
        else{
            let container = _.cloneDeep(categoryOpen)
            container.splice(categoryOpen.findIndex(x=> x === category), 1)
            setCategoryOpen(container)
        }
    }

    const permsWCategories = [
        {category: 'Payroll', values: [
            { 'payrollApproval' : false, 'name': 'Approve Payroll Timesheets',},
        ]},
        {category: 'Timesheet', values: [
            { 'officeApproval' : false, 'name': 'Approve Office Timesheets',},
            { 'constructionApproval' : false, 'name': 'Approve Construction Timesheets' },
            { 'shopApproval' : false, 'name': 'Approve Shop Timesheets' },
            { 'driverApproval' : false, 'name': 'Approve Gravel Timesheets' },
            { 'grainApproval' : false, 'name': 'Approve Grain Timesheets' },
            { 'snowApproval' : false, 'name': 'Approve Snow Timesheets' },
            { 'timesheetCRUD' : false, 'name': 'Create|Update|Delete Timesheets' },
        ]},
        {category: 'Store', values: [
            { 'productsCRUD' : false, 'name': 'Create|Update|Delete Products' },
            { 'ordersCRUD' : false, 'name': 'Create|Update|Delete Orders' },
            { 'orderCRUD' : false, 'name': 'Create|Update|Delete Orders' },
            { 'shopSettings' : false, 'name': 'Manage Shop Settings' },
            { 'smsCRUD' : false, 'name': 'Manage Message Center' },
        ]},
        {category: 'Employee', values: [
            { 'employeesCRUD' : false, 'name': 'Create|Update|Delete Employees' },
            { 'roleCRUD' : false, 'name': 'Create|Update|Delete User Roles' },
            { 'resumeCRUD' : false, 'name': 'Manage Resumes' },
            { 'userViewPersonal' : false, 'name': 'View User Personal Info' },
            { 'userViewEmployment' : false, 'name': 'View User Employment Info' },
        ]},
        {category: 'Human Resources', values: [
            { 'hrCRUD' : false , 'name': 'Create|Update|Delete Human Resource Documents'},
        ]},
        {category: 'Misc', values: [
            { 'equipmentCRUD' : false , 'name': 'Create|Update|Delete Equipment'},
            { 'customerCRUD' : false, 'name': 'Create|Update|Delete Customers' },
            { 'formCRUD' : false, 'name': 'Create|Update|Delete Forms' },
            { 'permitCRUD' : false, 'name': 'Create|Update|Delete Permits' },
            { 'workOrderCRUD' : false, 'name': 'Create|Update|Delete Work Orders' },
            // { 'demoAccess' : false, 'name': 'Access Demo Section' },
            { 'appMessage' : false, 'name': 'Update App Message' },
            { 'reportAccess' : false, 'name': 'Access Reports Section' },
        ]},
    ]

    if (!_.isUndefined(props.roleData)) {
        roleCard = (
            <Paper className={classes.paper}>
                <Grid container>
                    <Grid item xs={4}>
                        {!edit ?
                        <PaperTitle title={props.roleData.title}></PaperTitle>
                        :
                        <React.Fragment><TextField label='Role Title' variant='outlined' value={props.roleData.title}
                        onChange={(event) => { handleChange(event) }}
                        inputProps={{ style: { fontSize: '1.4rem' } }} />
                        <Typography style={{color:'red'}}>{nameError}</Typography></React.Fragment>
                        }
                    </Grid>

                    <Grid item xs={4}>

                        <IconButton disabled={safeRoles.includes(props.roleData.title) || props.path === 'new' || !roles.includes('Create|Update|Delete User Roles')} onClick={handleEdit} style={edit ? { color: 'black', } : {}} title="Edit Role"><EditIcon /></IconButton>
                        {edit ?<Button startIcon={<DeleteForeverIcon/>} variant="contained" style={!safeRoles.includes(props.roleData.title) && props.path !== 'new' && roles.includes('Create|Update|Delete User Roles')
                            ? { backgroundColor:'red', color:'white'} : { backgroundColor:'lightGrey', color:'grey'}}
                            onClick={() => openDelModal()}
                            disabled={safeRoles.includes(props.roleData.title) || props.path === 'new' || !roles.includes('Create|Update|Delete User Roles')}
                            > Delete</Button>:null}
                    </Grid>
                    <Grid item xs={4}>

                    </Grid>
                </Grid>

                <Grid container>
                    <Grid item xs={12} style={{marginBottom: '20px',}}>
                        <Divider></Divider>
                    </Grid>

                </Grid>

                <Grid container>
                    <Typography variant="h5" style={{marginBottom: '20px',}}>
                        {props.path === 'new' ? "Permissions can't be changed until the Role is created." : null}
                    </Typography>

                    <Grid container>
                        {permsWCategories.map((item) => (
                            <Grid container>
                                <Grid container style={{borderBottom: '1px solid #e5e5e5'}}>
                                    <Grid item xs={6}>
                                        <Typography variant='h6'>{item.category} Permissions</Typography>
                                    </Grid>

                                    <Grid item xs={6}>
                                        <Grid container justifyContent='flex-end'>
                                            <IconButton
                                                onClick={()=> { handleExpandCollapseCateogry(item.category) }}
                                            >
                                                {categoryOpen.includes(item.category) ? <ExpandLessIcon/> : <ExpandMoreIcon/> }
                                            </IconButton>
                                        </Grid>
                                    </Grid>
                                </Grid>

                                {categoryOpen.includes(item.category) &&
                                    <Grid item xs={12}>
                                        {item.values.map((perm, index) => (
                                            <Grid item md={6} key={index}>
                                                <FormGroup>
                                                    <FormControlLabel disabled={safeRoles.includes(props.roleData.title) || props.path === 'new' || !roles.includes('Create|Update|Delete User Roles') } control={<Checkbox name={perm.name} checked={props.roleData.permissions.includes(perm.name)}
                                                    onChange={(event) => handlePerms(event)} />} label={perm.name} />
                                                </FormGroup>
                                            </Grid>
                                        ))}
                                    </Grid>
                                }
                            </Grid>
                        ))}
                    </Grid>
                </Grid>
            </Paper>


        )
    }

    if (!_.isUndefined(props.roleData) && props.path !== 'new') {
        userTable = (
            <Paper className={classes.paper}>
                <Grid container>
                    <Grid item md={10}>
                        <PaperTitle title="Users"></PaperTitle>
                    </Grid>

                </Grid>

                <Divider style={{marginBottom:'10px'}}></Divider>
                <Grid container>
                    <Grid container>
                        {props.users.filter(user => user.role === props.roleData.title && user.employment?.active).length === 0 ?
                        <Grid item md={6}>
                            <Typography>No Users Found</Typography>
                        </Grid>: null}
                        {props.users.filter(user => user.role === props.roleData.title && user.employment?.active).map((user, index) => (
                                <Grid item md={3} key={index} style={{margin:'1px'}}>
                                    <Typography>{user.displayName}</Typography>
                                </Grid>
                            ))}
                    </Grid>
                </Grid>
            </Paper>
        )
    }
    return (
        <React.Fragment>
            <Grid container>
                <Grid item xs={12} className={classes.backButton}>
                    <Button onClick={() => props.history.goBack()}
                    variant={"contained"} startIcon={<ArrowBackIcon/>}>Back</Button>
                </Grid>
            </Grid>

            {props.users.length > 0 ? <Dialog fullWidth
                keepMounted
                open={delModal}
                onClose={closeDelModal}
            >
                <DialogTitle id="alert-dialog-title">Delete Role</DialogTitle>

                <DialogContent>
                    <Typography>Are you sure you want to delete the {props.roleData.title} role?</Typography>
                    {edit ? props.users.filter(user => user.role === props.roleData.title).length > 0 ? <DialogContentText style={{color:'red'}}>Deleting {props.roleData.title} will leave {props.users.filter(user => user.role === props.roleData.title).length} users without a defined Role! </DialogContentText> : null : null}
                </DialogContent>
                <DialogActions>
                    <Button variant="contained" onClick={() => handleDelete()}>Delete</Button>
                    <Button variant="contained" color="secondary" onClick={() => closeDelModal()}>Cancel</Button>
                </DialogActions>
            </Dialog> : null}

            <div className={classes.content}>
                {roleCard}
            </div>
            <div className={classes.content}>
                {userTable}
            </div>
            
            <Snackbar
                open={!_.isEqual(props.roleData, props.saveState)}
                message={props.saving ? 'Saving...' : 'Save Role?'}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center'
                }}
                action={
                    <React.Fragment>
                        {props.saving
                            ? null
                            : <React.Fragment>
                                <Button variant='text' color='primary' onClick={props.cancelSave} style={{ marginLeft: '32px', marginRight: '8px' }}>Cancel</Button>
                                <Button variant='contained' disabled={nameError !== ''} onClick={props.confirmSave}>Confirm</Button>
                            </React.Fragment>}
                    </React.Fragment>
                } />

        </React.Fragment>
    )
}

export default withRouter(RoleCard)