import React from 'react';
import { withRouter } from 'react-router-dom';
import { Grid, Typography, Divider, Checkbox, FormControlLabel, TextField, Select, MenuItem   } from '@material-ui/core'
import _ from 'lodash';


const UnitModal = (props) => {

    const handleChange = (event) => {
        if(event.target.checked){
            let container = props.currentUnitJobs;
            container.push(event.target.name)
            props.setCurrentUnitJobs([...container]);
        }
        else{
            let container = props.currentUnitJobs;
            container.splice(container.indexOf(event.target.name), 1);
            props.setCurrentUnitJobs([...container]);
        }
    };

    const handleSelectAll = (event) => {
        if(event.target.checked){
            let container = props.jobData.map((job) => {return job.docId})
            props.setCurrentUnitJobs([...container])
        }
        else{
            props.setCurrentUnitJobs([]);
        }
    }

    return (
        <Grid>
            <Grid container spacing={2}>

                <Grid item xs={6}>
                    <Typography 
                        style={{marginTop: '8px'}}
                    >
                        Unit Number: 
                    </Typography>
                </Grid>
                <Grid item xs={6}>
                    <TextField 
                        variant="outlined" 
                        size="small" 
                        value={props.currentUnit.unitNumber ? props.currentUnit.unitNumber : ''} 
                        onChange={(e) => {props.setCurrentUnit({...props.currentUnit, unitNumber: e.target.value})}}
                    />
                </Grid>

                <Grid item xs={6}>
                    <Typography 
                        style={{marginTop: '8px'}}
                    >
                        Trailer ID: 
                    </Typography>
                </Grid>
                <Grid item xs={6}>
                    <TextField 
                        variant="outlined" 
                        size="small" 
                        value={props.currentUnit.trailerID ? props.currentUnit.trailerID : ''} 
                        onChange={(e) => {props.setCurrentUnit({...props.currentUnit, trailerID: e.target.value})}}
                    />
                </Grid>

                <Grid item xs={6}>
                    <Typography 
                        style={{marginTop: '8px'}}
                    >
                        Fuel Added: 
                    </Typography>
                </Grid>
                <Grid item xs={6}>
                    <TextField 
                        variant="outlined" 
                        size="small" 
                        value={props.currentUnit.fuelAdded ? props.currentUnit.fuelAdded : ''} 
                        onChange={(e) => {props.setCurrentUnit({...props.currentUnit, fuelAdded: e.target.value})}}
                    />
                </Grid>

                <Grid item xs={6}>
                    <Typography 
                        style={{marginTop: '8px'}}
                    >
                        Greased: 
                    </Typography>
                </Grid>
                <Grid item xs={5}>

                
                <Select fullWidth labelId="label" id="select" value={props.currentUnit.greased} variant='outlined'
                onChange={(e) => {props.setCurrentUnit({...props.currentUnit, greased: e.target.value})}}
                >
                    <MenuItem value={true}>Yes</MenuItem>
                    <MenuItem value={false}>No</MenuItem>
                </Select>

                </Grid>

                <Grid item xs={6}>
                    <Typography 
                        style={{marginTop: '8px'}}
                    >
                        Mileage In: 
                    </Typography>
                </Grid>
                <Grid item xs={6}>
                    <TextField 
                        variant="outlined" 
                        size="small" 
                        value={props.currentUnit.mileageIn ? props.currentUnit.mileageIn : ''} 
                        onChange={(e) => {props.setCurrentUnit({...props.currentUnit, mileageIn: e.target.value})}}
                    />
                </Grid>

                <Grid item xs={6}>
                    <Typography 
                        style={{marginTop: '8px'}}
                    >
                        Mileage Out:
                    </Typography>
                </Grid>
                <Grid item xs={6}>
                    <TextField 
                        variant="outlined" 
                        size="small" 
                        value={props.currentUnit.mileageOut ? props.currentUnit.mileageOut : ''} 
                        onChange={(e) => {props.setCurrentUnit({...props.currentUnit, mileageOut: e.target.value})}}
                    />
                </Grid>

            </Grid>
            <Divider style={{marginTop: '10px', marginBottom:'10px'}}/>

            <Grid container>
                <Grid item xs={6} style={{marginTop: '10px'}}>
                    <Typography>Add Jobs to Unit</Typography>
                </Grid>
                <Grid item xs={6}>
                    <FormControlLabel
                            label={"Select All Jobs"}
                            control={
                                <Checkbox 
                                    checked={_.isEqual(props.jobData.map((job) => {return job.docId}).sort(), props.currentUnitJobs.sort())} 
                                    onChange={handleSelectAll}/>
                                }   
                    />
                </Grid>
            </Grid>

            <Divider style={{marginTop: '5px'}}/>
            {
                props.jobData.map((job) => {
                    return (
                        <FormControlLabel
                            key={job.docId}
                            style={{margin: '5px'}}
                            label={job.customer + ' - ' + job.address}
                            control={
                                <Checkbox 
                                    checked={props.currentUnitJobs.includes(job.docId)} 
                                    onChange={handleChange} 
                                    name={job.docId} 
                                />
                            }
                        />
                        
                    )
                })
            }
        </Grid>
    )
}
export default withRouter(UnitModal)