import React, { useContext, useState } from "react"
import { firestore } from "../firebase/firebase"

const UnitDescriptionContext = React.createContext()

export function useUnitDescription() {
  return useContext(UnitDescriptionContext);
}

export function UnitDescriptionProvider({ children }) {

  const [units, setUnits] = useState([]);
  const [unitsLoading, setUnitsLoading] = useState(true);
  const [error, setError] = useState('');

  function getUnits() {
    //Check if customers exist on local storage
    if (units.length <= 0) {

      //Query customer collection for all docs
      firestore.collection("unitDescriptions").get()
        .then(querySnapshot => {
          const unitResponse = querySnapshot.docs.map((doc) => {
            return {
              ...doc.data(),
              docId: doc.id
            }
          })

          setUnitsLoading(false);
          setUnits(unitResponse);
          //sessionStorage.setItem("unitDescData", JSON.stringify(unitResponse));
        },
          error => {
            setUnitsLoading(false);
            setError(error.message);
            console.log(error.message);
          }
        )
    }
    else
        setUnitsLoading(false);

    
  }

  function getUnit(unitNumber) {
    return units?.filter(unit => unit.unitNumber === unitNumber)
  }

  const value = {
    units,
    unitsLoading,
    error,
    getUnits,
    getUnit,
  }

  return (
    <UnitDescriptionContext.Provider value={value}>
      {children}
    </UnitDescriptionContext.Provider>
  )
}