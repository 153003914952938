import React from 'react';
import { makeStyles } from '@material-ui/core';
import moment from 'moment'
import { Grid, Button, } from '@material-ui/core';
import { withRouter } from 'react-router-dom';
import MaterialTable from '@material-table/core';
import { TableIcons } from '../../../constants/tableIcons';
import AddBoxIcon from '@material-ui/icons/AddBox';

const useStyles = makeStyles((theme) => ({
    card: {
        padding: theme.spacing(4),
        width: '100%',
        height: '100%',
      },
    
      content: {
        display: 'flex',
        justifyContent: 'center',
        width: '100%',
      },
    
      grid: {
        marginTop: theme.spacing(2),
        maxWidth: '1200px',
      },
    
      table: {
        display: 'flex',
        justifyContent: 'center',
      },
    
      tile: {
        width: '300px',
        height: '300px',
      },
    
      tileContent: {
        display: 'flex',
        justifyContent: 'space-evenly',
        alignItems: 'center',
        flexDirection: 'column',
        width: '100%',
        height: '100%',
        padding: theme.spacing(4),
      },
    
      tileIcon: {
        color: 'rgba(0, 0, 0, 0.54)',
        width: '100px',
        height: '100px',
      },
    
      tileText: {
        fontSize: '1.10rem',
      },
      btn: {
        marginBottom: '20px',
        marginTop: '20px',
    },
}));

const RatesTable = (props) => {

    const classes = useStyles();

    return (
        <React.Fragment>
            <Grid item xs={12} className={classes.btn}>
                <Button variant='contained' onClick={() => props.tileClickHandler('form', 'new')} startIcon={<AddBoxIcon/>}>
                    Create New Rate
                </Button>
            </Grid>
            {!props.loading
            ? 
                <Grid item sm={12} xs={12} className={classes.table}>
                    <MaterialTable
                    columns={[
                        { title: 'Name', field: 'name' },
                        { title: 'Rate',  render: rowData => `$ ${Number(rowData.rate)?.toFixed(2)}`},
                        { title: 'Created On', render: rowData => moment(rowData.dateCreated).format('YYYY-MM-DD') },
                        { title: 'Updated On', render: rowData => moment(rowData.dateUpdated).format('YYYY-MM-DD') },
                    ]}
                    data={props.ratesData}
                    onSearchChange={ (value) => {props.setSearchBarValue(value);  sessionStorage.setItem("ratesSearchBarValue", JSON.stringify(value))}}
                    style={{minWidth: '800px'}}
                    title={"Rates"}
                    actions={[
                        rowData => ({
                        icon: TableIcons.OpenInNew,
                        tooltip: 'Show Newest Rates',
                        onClick: (event, rowData) => {
                            props.history.push({
                            pathname: '/dashboard/rates/form',
                            search: "?" + new URLSearchParams({ docId: rowData.docId }).toString(),
                            })
                        }
                        })
                    ]}
                    options={{
                        toolbar: true,
                        paging: true,
                        pageSizeOptions: false,
                        pageSize: 10,
                        actionsColumnIndex: -1,
                        search: true,
                        emptyRowsWhenPaging: false,
                        thirdSortClick: false,
                        searchText: props.searchBarValue,
                        rowStyle: {
                            whiteSpace: 'pre-wrap',
                        },
                    }}
                    />
                </Grid>
            : null
            }
        </React.Fragment>
    )
}

export default withRouter(RatesTable)