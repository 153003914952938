import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Divider, IconButton, makeStyles, TextField, Typography, MenuItem } from '@material-ui/core';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';
import { useAuth } from '../../../providers/AuthContext';

const useStyles = makeStyles((theme) => ({

    grid: {
        display: 'grid',
        gridTemplateColumns: '30% 30% 30% 10%',
    },

    row: {
        display: 'flex',
        alignItems: 'center',
    },
}))

/**
 * 
 * @param title Header title for the ListBuilder
 * @param labelKey Label for the Key TextField
 * @param labelValue Label for the Value TextField
 * @param baseValue If specified, sets a mandatory key/value that can't be deleted
 * @param value The Map manipulated by the ListBuilder
 * @param setValue The Setter for the value used by the ListBuilder to Manipulate the value
 * 
 */
const PriceBuilder = (props) => {

    const [priceList, setPriceList] = React.useState([]);

    useEffect(() => {
        if (props.value) {
            setPriceList([...props.value]);
        }
    }, [props.value])

    const handleBlur = () => { props.setValue([...priceList]); }
    const handleClick = (newPriceList) => { props.setValue(newPriceList); }

    const classes = useStyles();
    const { roles } = useAuth();

    return (
        <div data-test='component-pricelist'>
            <div className={classes.row}>
                <Typography>{props.title}</Typography>
                <IconButton
                style={{ color: '#000'}}
                    data-test='component-pricelist-addbutton'
                    disabled={!roles.includes('Create|Update|Delete Products')}
                    onClick={() => {
                        let newPriceList = [...priceList];
                        newPriceList.push({ unit: "", qty: "", price: "" })

                        setPriceList(newPriceList);
                        handleClick(newPriceList);
                    }}>
                    <AddCircleOutlineIcon />
                </IconButton>
            </div>

            <div >
                <Divider />
            </div>

            {priceList.map((price, index) => {
                return <div className={classes.grid} key={index} data-test='component-inputs'>
                    <div style={{ padding: '8px' }}>
                        {/*<TextField
                            data-test="textfield-unit"
                            required
                            fullWidth
                            disabled={!roles.includes('Create|Update|Delete Products')}
                            label='Unit'
                            value={price.unit}
                            onChange={(event) => {
                                let newPriceList = [...priceList];
                                newPriceList[index].unit = event.target.value
                                setPriceList(newPriceList)
                            }}
                            onBlur={() => handleBlur()}
                        />*/}
                        <TextField
                            value={price.unit}
                            data-test="textfield-unit"
                            fullWidth
                            select
                            required
                            label='Unit'
                            disabled={!roles.includes('Create|Update|Delete Products')}
                            onChange={(event) => {
                                let newPriceList = [...priceList];
                                newPriceList[index].unit = event.target.value
                                setPriceList(newPriceList)
                            }}
                            onBlur={() => handleBlur()}
                            >   
                                
                                <MenuItem value={price.unit}>
                                    {price.unit}
                                </MenuItem>
                               
                                
                                {props.unitList.map((unit, index) => {
                                    return (
                                        <MenuItem key={index} value={unit}>{unit}</MenuItem>
                                    )
                                }
                                )}
                            </TextField>
                    </div>
                    <div style={{ padding: '8px' }}>
                        <TextField
                            data-test='textfield-qty'
                            required
                            fullWidth
                            disabled={!roles.includes('Create|Update|Delete Products')}
                            label='Quantity'
                            value={price.qty}
                            onChange={(event) => {
                                let newPriceList = [...priceList];
                                newPriceList[index].qty = event.target.value
                                setPriceList(newPriceList)
                            }}
                            onBlur={() => handleBlur()}
                        />
                    </div>
                    <div style={{ padding: '8px' }}>
                        <TextField
                            data-test='textfield-price'
                            type='number'
                            required
                            fullWidth
                            label="Price"
                            disabled={!roles.includes('Create|Update|Delete Products')}
                            value={price.price}
                            error={isNaN(Number.parseFloat(price.price))}
                            inputProps={{
                                step: 0.01
                            }}
                            onChange={(event) => {
                                let newPriceList = [...priceList];
                                newPriceList[index].price = parseFloat(event.target.value)
                                setPriceList(newPriceList)
                            }}
                            onBlur={() => handleBlur()}
                        />
                    </div>
                    <div className={classes.row}>
                        <IconButton
                            data-test='button-remove' disabled={!roles.includes('Create|Update|Delete Products')}
                            onClick={() => {
                                let newPriceList = [...priceList];
                                newPriceList.splice(index, 1);
                                setPriceList(newPriceList);
                                handleClick(newPriceList);
                            }}>
                            <RemoveCircleOutlineIcon />
                        </IconButton>
                    </div>
                </div>
            })}
        </div>
    )
}

PriceBuilder.propTypes = {
    value: PropTypes.array.isRequired,
    setValue: PropTypes.func.isRequired
}

export default PriceBuilder;