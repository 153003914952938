import React, {useState } from 'react';
//import { makeStyles } from '@material-ui/core';
import { Grid, Paper, Typography, Snackbar, Button, IconButton, Dialog, DialogContent, DialogActions, DialogTitle, DialogContentText } from '@material-ui/core';
import { withRouter } from 'react-router'
import { makeStyles } from '@material-ui/core';
import _ from 'lodash'
import { firestore, removeFile } from '../../../firebase/firebase'
import { storage } from '../../../firebase/firebase'
import Dropzone from 'react-dropzone'
import ClearIcon from '@material-ui/icons/Clear';
import PublishIcon from '@material-ui/icons/Publish';
import moment from 'moment';
//import { Document, Page } from 'react-pdf';
import DeleteIcon from '@material-ui/icons/Delete';
//import ArrowBackIcon from '@material-ui/icons/ArrowBack';
//import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import useAsyncError from '../UI/AsyncError/AsyncError';

const useStyles = makeStyles((theme) => ({

    content: {
        display: 'flex',
        width: '100%',
        flexDirection: 'column',
        maxWidth: '1000px',
        margin: 'auto',
    },
    paper: {
        padding: '20px',
        borderRadius: '0px',
        margin: '0px',
        marginBottom:'20px',
        width: '80%',
        maxWidth: '1000px',
    },
    header: {
        display: 'flex',
        justifyContent: 'center',
        marginBottom:'10px',
    },
    card: {
        display: 'flex',
        justifyContent: 'center',
    },
    label: {
        lineHeight: 2,
    },
    checkbox: {
        lineHeight: 2,
        padding:'0px',
        marginTop: '4px',
        marginBottom: '4px',
    },
    fileInput: {
        '&:hover': {
            cursor: 'pointer',
          },
        display:'flex',
        alignItems: 'center',
        border: '1px solid #bdbdbd',
        borderRadius:'4px',
        margin:'8px',
        marginTop:'16px',
        //backgroundColor: '#f2f2f2',
    },
    fileInputAccept: {
        display:'flex',
        alignItems: 'center',
        border: '0px',
        borderRadius:'4px',
        margin:'8px',
        marginTop:'16px',
        backgroundColor: '#56a84b',
        color:'white',
    },
}));

const RegistrationTab = (props) => {
    const classes = useStyles();

    const [equipmentData, setEquipmentData] = useState(props.equipmentData);
    const [error, setError] = useState("");
    const [saving, setSaving] = useState(false);
    const [saveState, setSaveState] = useState(props.saveState);

    //const [submitting, setSubmitting] = useState(false);

    const [newRegistrationPDF, setNewRegistrationPDF] = useState(null)
    //const [pageNumbers, setPageNumbers] = useState([])
    //const [numOfPages, setNumOfPages] = useState([])
    const [deleteData, setDeleteData] = useState(null)

    const throwAsyncError = useAsyncError();

    let path = new URLSearchParams(props.location.search).get("docId");


    const confirmSave = async () => {
        try{
        let container = _.cloneDeep(equipmentData)
        setSaving(true);

        if(newRegistrationPDF !== null){
            let newReg = await uploadRegistration()
            let index = container.registrations.findIndex(x=> x.pdfUrl === newReg.name)
            container.registrations.splice(index, 1, newReg)
        }

        console.log('container', container)
        props.setEquipmentData(container);
        props.setSaveState(container);
        firestore.collection('unitDescriptions').doc(path)
            .update(container)
            .then(() => {
                setSaveState(container);
                setNewRegistrationPDF(null)
                setSaving(false)
            })
        }
        catch(e){
            throwAsyncError(new Error(e.message, e));
        }
    };

    const cancelSave = () => {
        if(equipmentData.registrations?.filter(x=> x.pdfUrl === newRegistrationPDF?.name && newRegistrationPDF !== null).length > 0){
            setNewRegistrationPDF(null)
        }
        setEquipmentData(saveState);
    };

    const handleFileUpload = (acceptedFiles) => {
        const file = acceptedFiles[0];
        if (file) {
            setNewRegistrationPDF(file);
            setEquipmentData({...equipmentData, registrations: [...equipmentData.registrations || [], {pdfUrl: file.name}]})
        }
    }

    const uploadRegistration = async () => {
        try{
            //setSubmitting(true);
            let regForm = {};

            const storageRef = storage.ref();
            const pdfRef = storageRef.child('Registrations/' + newRegistrationPDF.name + '_' + moment().valueOf() + '_' + path);

            await pdfRef.put(newRegistrationPDF).then(async (ref) => {
                await pdfRef.getDownloadURL().then((url) => {
                    regForm.pdfURL = url;
                    regForm.docId = path;
                    regForm.name = newRegistrationPDF.name

                    //setSubmitting(false);
                })
            })
            return regForm;
        }
        catch(e){
            throwAsyncError(new Error(e.message, e));
        }
    }

    //substring text to use ... if too long
    const substringText = (text) => {
        if(text.length > 20){
            return text.substring(0, 20) + '...'
        } else {
            return text
        }
    }

    const handleDeleteClick = async (pdfURL) => {
        try{
            setSaving(true);
            await removeFile(pdfURL)
            .then(() => {
                let container = _.cloneDeep(equipmentData)
                let registrationIndex = container.registrations.findIndex(x=> x.pdfURL === pdfURL)
                container.registrations.splice(registrationIndex, 1)

                console.log('container', container)

                props.setEquipmentData(container);
                props.setSaveState(container);
                firestore.collection('unitDescriptions').doc(path)
                    .update(container)
                    .then(() => {
                        setSaveState(container);
                        setNewRegistrationPDF(null)
                        setSaving(false)
                    })
                    .catch((e) => {
                        setError(e); 
                        setSaving(false)
                        console.log(error);
                    });
            })
        }
        catch(e){
            throwAsyncError(new Error(e.message, e));
        }
    }

   // function onDocumentLoadSuccess({ numPages }, url) {
   //     setNumOfPages((prev) => { return({...prev, [url]: numPages}) });
    //    setPageNumbers((prev) => { return({...prev, [url]: 1}) });
   // }

    return(
        <Grid container style={{justifyContent: 'center'}}>
            {/* DELETE DIALOG */}
            <Dialog
                open={deleteData !== null}
                onClose={() => { setDeleteData(null) }}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{"Delete Document?"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Are you sure you want to delete this document?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => {setDeleteData(null)}} variant="contained" color="secondary">
                        No
                    </Button>
                    <Button onClick={() => { handleDeleteClick(deleteData) }} variant="contained" style={{ backgroundColor: 'red', color: 'white' }} autoFocus>
                        Yes
                    </Button>
                </DialogActions>
            </Dialog>



            <Paper className={classes.paper}>
                <Grid container spacing={2} >
                    
                {/* if editing */}
                <Grid item sm={12} className={newRegistrationPDF?.name ? classes.fileInputAccept : classes.fileInput}>
                    <Dropzone accept={{'application/pdf': ['.pdf', '.PDF']}} onDrop={handleFileUpload}>
                        {({ getRootProps, getInputProps }) => (
                            <Grid container item xs={12}justifyContent='center' {...getRootProps()} >
                                <input {...getInputProps()} style={{ display: 'none' }} />
        
                                <PublishIcon style={{color:'grey', marginRight:'20px'}}/>

                                <Typography variant='h6'>
                                    {newRegistrationPDF?.name ? substringText(newRegistrationPDF?.name) : "Upload Document (.pdf)"}
                                </Typography>
                            </Grid>
                        )}
                    </Dropzone>

                    {newRegistrationPDF?.name ?
                        <IconButton component='span' style={{ marginLeft: '100px', color:'black' }} onClick={() => {setNewRegistrationPDF(null)}}>
                            <ClearIcon />
                        </IconButton>
                    : null}
                </Grid>

                <Grid item sm={12}>
                    <Typography type='url' align="center" style={{color:'red'}}>{props.error}</Typography>
                </Grid>

                    {equipmentData.registrations?.filter(x=> x.pdfUrl !== newRegistrationPDF?.name || newRegistrationPDF == null).map((reg, i) => {
                        return (
                            <Grid container justifyContent='center' key={i} style={{marginBottom: '25px', }}>
                                <Paper style={{backgroundColor:'lightGrey', paddingRight: '40px', paddingLeft: '40px'}}>
                                    <Grid container>
                                        <Grid item xs={11}>
                                            <Typography style={{marginTop: '10px'}}>{reg.name}</Typography>
                                        </Grid>

                                        <Grid item xs={1}>
                                            <Grid container justifyContent='flex-end'>
                                                <IconButton>
                                                    <DeleteIcon
                                                        onClick={(e)=> { setDeleteData(reg.pdfURL) }}
                                                    />
                                                </IconButton>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                              
    
                                    <Grid item xs={12}>
                                        <Grid item xs={12} style={{display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop:'50px'}}>
                                            <iframe
                                                src={reg.pdfURL}
                                                title="PDF Viewer"
                                                width="1000px"
                                                height="800px"
                                            />
                                        </Grid>
                                    </Grid>
                                    
                                </Paper>
                            </Grid>
                        )
                    })}
                </Grid>
            </Paper>

            <Snackbar
                //disableAutoFocus={true}
                //disableEnforceFocus={true}
                open={!_.isEqual(equipmentData, saveState)}
                message={saving ? 'Saving...' : 'Save Document?'}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center'
                }}
                action={
                    <React.Fragment>
                        {saving
                            ? null
                            : <React.Fragment>
                                <Button variant='text' color='primary' onClick={cancelSave} style={{ marginLeft: '32px', marginRight: '8px' }}>Cancel</Button>
                                <Button variant='contained' onClick={confirmSave}>Confirm</Button>
                            </React.Fragment>
                        }
                    </React.Fragment>
                } />
        </Grid>
    )
}
export default withRouter(RegistrationTab)