import React, { useEffect} from 'react';
import { makeStyles } from '@material-ui/core';
import DatePicker from '../../UI/DatePicker/DatePicker';
import {  TextField, Typography, Button, Grid } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    btnGroup: {
        width: '100%',
    },

    filterItem: {
        marginTop: '4px',
        marginBottom: '4px',
    },

    search: {
        display: 'flex',
        boxShadow: 'inset 0px 0px 2px 2px rgba(207,207,207,0.49)',
        //backgroundColor: '#fafafa',
        borderRadius: '4px',
        alignItems: 'center',
        justifyContent: 'center',
        padding: theme.spacing(2),
    },
}));

const ChecklistOptions = (props) => {
    const classes = useStyles();
    const [customerValue, setCustomerValue] = React.useState('');
    const [addressValue, setAddressValue] = React.useState('');
    const [employeeValue, setEmployeeValue] = React.useState('');
    const [serviceTypeValue, setServiceTypeValue] = React.useState('');


    useEffect(() => {
       
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <Grid container spacing={2}>

            <Grid item sm={12}>

                <Grid container className={classes.search}>
                    <Grid item sm={12} className={classes.filterItem}>
                        <DatePicker
                            label="Start Date"
                            value={props.startDate}
                            onChange={props.startDateHandler}
                        />
                    </Grid>

                    <Grid item sm={12} className={classes.filterItem}>
                        <DatePicker
                            label="End Date"
                            value={props.endDate}
                            onChange={props.endDateHandler}
                        />
                    </Grid>

                   
                </Grid>
            </Grid>

            <Grid container className={classes.search}>
                <Typography variant="h6">Filters</Typography>
                    <Grid item sm={12} className={classes.filterItem}>
                        <TextField variant="outlined"
                            label="Service Type"
                            value={serviceTypeValue}
                            onChange={(e) => {
                                setServiceTypeValue(e.target.value);
                            }}
                            onBlur={(e) => {
                                props.handleSearchFilters('serviceType',e.target.value);
                            }}
                            onKeyDown={(e) => e.key === "Enter" && e.target.blur()} 
                        />
                    </Grid>
                    <Grid item sm={12} className={classes.filterItem}>
                        <TextField variant="outlined"
                            label="Employee"
                            value={employeeValue}
                            onChange={(e) => {
                                setEmployeeValue(e.target.value);
                            }}
                            onBlur={(e) => {
                                props.handleSearchFilters('employee',e.target.value);
                            }}
                            onKeyDown={(e) => e.key === "Enter" && e.target.blur()} 
                        />
                    </Grid>
                    <Grid item sm={12} className={classes.filterItem}>
                        <TextField variant="outlined"
                            label="Customer"
                            value={customerValue}
                            onChange={(e) => {
                                setCustomerValue(e.target.value);
                            }}
                            onBlur={(e) => {
                                props.handleSearchFilters('customer',e.target.value);
                            }}
                            onKeyDown={(e) => e.key === "Enter" && e.target.blur()} 
                        />
                    </Grid>
                    <Grid item sm={12} className={classes.filterItem}>
                        <TextField variant="outlined"
                            label="Address"
                            value={addressValue}
                            onChange={(e) => {
                                setAddressValue(e.target.value);
                            }}
                            onBlur={(e) => {
                                props.handleSearchFilters('address',e.target.value);
                            }}
                            onKeyDown={(e) => e.key === "Enter" && e.target.blur()} 
                        />
                    </Grid>

                   
                </Grid>

            <Grid item sm={12}>
                {(props.errorMsg && <Typography>{props.errorMsg}</Typography>)}
            </Grid>

            <Grid item sm={12} className={classes.btnGroup}>
                <Button variant="contained" fullWidth disabled={props.loading} onClick={props.generateHandler} > Generate </Button>
            </Grid>
   
            <Grid item sm={12} className={classes.btnGroup}>
                <Button variant="contained" fullWidth disabled={props.loading} onClick={props.handlePrint} > Print Table </Button>
            </Grid>

            <Grid item sm={12} className={classes.btnGroup}>
                <Button variant="contained" fullWidth disabled={props.loading} onClick={props.handlePrintAll} > Print All Visible </Button>
            </Grid>


        </Grid>
    )
}

export default ChecklistOptions;