import React, { useState } from 'react';
import { firestore } from '../../../firebase/firebase'
import { storage } from '../../../firebase/firebase'
import { withRouter } from 'react-router-dom';
import MSDSForm from '../../components/MSDS/MSDSForm';

const MSDSNew = (props) => {

    const initialMsdsForm = {
        name: '',
        description: '',
        aliases: [''],
        manufacturerPage: '',
        dateCreated: '',
        dateUpdated: '',
        pdfURL: '',
        emergencyPhone: '',
        safetyPhone: '',
    }

    const [msdsForm, setMsdsForm] = useState(initialMsdsForm);
    const [msdsPDF, setMsdsPDF] = useState({});
    const [submitting, setSubmitting] = useState(false);
    const [error, setError] = useState("");

    const handleChange = (event) => {
        switch (event.target.name) {
            //Handle value as array 
            case "aliases":
                //Dont call splice in the hook set method to conform to hook rules
                let aliases = msdsForm.aliases;
                aliases.splice(event.target.id, 1, event.target.value)
                setMsdsForm({ ...msdsForm, aliases: aliases });
                break;
            default:
                setMsdsForm({ ...msdsForm, [event.target.name]: event.target.value });
                break;
        }
    }

    const addAlias = () => {
        let aliases = msdsForm.aliases;
        aliases.push('');
        setMsdsForm({ ...msdsForm, aliases: aliases });
    }

    const removeAlias = (index) => {
        let aliases = msdsForm.aliases;
        aliases.splice(index, 1);
        setMsdsForm({ ...msdsForm, aliases: aliases });
    }

    const handleFileUpload = (acceptedFiles) => {
        const file = acceptedFiles[0];
        if (file) {
            setMsdsPDF(file);
            setMsdsForm({ ...msdsForm, pdfURL: file.name});
        }
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        createMSDS();
    }

    const createMSDS = async () => {

        setSubmitting(true);

        let newMsdsForm = msdsForm;

        newMsdsForm.dateCreated = Date.now() / 1000;
        newMsdsForm.archived = false;

        //Create reference to .pdf
        const storageRef = storage.ref();
        let docId = firestore.collection('msds').doc().id;
        const pdfRef = storageRef.child('MSDS/' + msdsPDF.name + '_' + docId);

        pdfRef.put(msdsPDF).then((ref) => {
            pdfRef.getDownloadURL().then((url) => {
                newMsdsForm.pdfURL = url;
                newMsdsForm.docId = docId;
                newMsdsForm.dateUpdated = new Date(newMsdsForm.dateUpdated);
                newMsdsForm.dateUpdated = new Date(newMsdsForm.dateUpdated.getTime() + (newMsdsForm.dateUpdated.getTimezoneOffset() * 60000)) / 1000;

                firestore.collection('msds').doc(docId).set(newMsdsForm)
                    .then(() => {
                        setMsdsPDF();
                        setMsdsForm(initialMsdsForm);
                        setSubmitting(false);
                        setError('');
                    })
            })
        })
            .catch((e) => {
                setSubmitting(false);
                console.log(e)
                setError("Unable to create Material Safety Data Sheet, please try again");
            });
    }
        
    //substring text to use ... if too long
    const substringText = (text) => {
        if(text.length > 20){
            return text.substring(0, 20) + '...'
        } else {
            return text
        }
    }

    return (
        <React.Fragment>
            <MSDSForm
                msdsForm={msdsForm} msdsPDF={msdsPDF} setMsdsPDF={setMsdsPDF}
                addAlias={addAlias} removeAlias={removeAlias}
                handleChange={handleChange} handleSubmit={handleSubmit}
                handleFileUpload={handleFileUpload}
                submitting={submitting} error={error} 
                substringText={substringText}
            />
        </React.Fragment>
    )
}

export default withRouter(MSDSNew);