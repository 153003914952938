import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import _ from 'lodash'
import { Skeleton } from '@material-ui/lab';
import { makeStyles } from '@material-ui/core';
import { firestore } from './../../../firebase/firebase'

import EquipmentPDF from '../../components/Equipment/EquipmentPDF';

const useStyles = makeStyles((theme) => ({
    content: {
        display: 'flex',
        width: '100%',
        padding: '32px',
        justifyContent: 'center',
        marginTop: '105px',
    },
}));

function EquipmentPrint(props) {
    const [equipmentData, setEquipmentData] = useState({});
    const [loading, setLoading] = useState([]);
    const [errorMessage, setErrorMessage] = useState("");

    const classes = useStyles();

    useEffect(() => {
        setLoading(true);
   
        //let order = {};

        let path = new URLSearchParams(props.location.search).get("docId");

        firestore.collection('unitDescriptions').where('__name__', "==", path).get()
            .then(querySnapshot => {
                const userResponse = querySnapshot.docs.map((doc) => {
                    return {
                        ...doc.data(),
                        'docId': doc.id,
                    }
                })

                if (_.isEmpty(userResponse)) return;

                
                setEquipmentData(userResponse[0]);
                setLoading(false);
            })
            .catch(e => {
                setErrorMessage("Unable to Process");
                setLoading(false)
            });
    }, [props.location.search]);
    

    return (
        loading
            ? 
            <div className={classes.content}>
                <Skeleton variant='rect' width={'63vw'} height={'80vh'} />
            </div>
            : 
            <EquipmentPDF
                equipmentData={equipmentData}
                errorMessage={errorMessage} 
            />
    )
}

export default withRouter(EquipmentPrint)