import React from 'react';
import { Grid, Select, MenuItem} from '@material-ui/core';
import { withRouter } from 'react-router'
import { makeStyles } from '@material-ui/core';  

const useStyles = makeStyles((theme) => ({

    content: {
        display: 'flex',
        width: '100%',
        flexDirection: 'column',
        maxWidth: '1000px',
        margin: 'auto',
    },
    rlSpacing: {
        paddingRight: theme.spacing(1),
        paddingLeft: theme.spacing(1),
    },
    layoutRL: {
       height: '22px',
    },
    layoutSideVehicle: {
        position: 'relative',
        justifyContent: 'center',
    },
    layoutFrontVehicle: {
        position: 'relative',
    },
    layoutRect: {
        display: 'flex',
        width: '80px',
        height: '50px',
        margin: '0px 5px 5px 5px',
    },
}));

const TractorLayout = (props) => {

    const classes = useStyles();
                                
    return(
        <Grid>
            <Grid container className={classes.layoutSideVehicle} style={{left: '50px'}}>
                <Grid>
                    {/* backRightOutterFront */}
                    <Select
                        id="backRightOutterFront"
                        onChange={(event) => props.equipmentData.tires 
                            ? props.equipmentData.tires.vehicle 
                                ? props.updateEquipmentData({...props.equipmentData, tires: {...props.equipmentData.tires, vehicle : {...props.equipmentData.tires.vehicle, 'backRightOutterFront':  event.target.value} }})
                                : props.updateEquipmentData({...props.equipmentData, tires: {...props.equipmentData.tires, vehicle : { 'backRightOutterFront':  event.target.value} }})
                            :  props.updateEquipmentData({...props.equipmentData, tires: {vehicle : { 'backRightOutterFront':  event.target.value} }})
                        }
                        className={classes.layoutRect}
                        style={{backgroundColor: props.assignColor("vehicle","backRightOutterFront")}}
                        disabled={props.edit ? false : true}
                        value={props.equipmentData.tires ? (props.equipmentData.tires.vehicle ? (props.equipmentData.tires.vehicle.backRightOutterFront ? props.equipmentData.tires.vehicle.backRightOutterFront : "") : ""): ""}
                    >
                        <MenuItem name='poor' key="poor" value='poor' className={classes.layoutRect} style={{background: 'red'}}></MenuItem>
                        <MenuItem name='fair' key="fair" value='fair' className={classes.layoutRect} style={{background: '#00D8D8'}}></MenuItem>
                        <MenuItem name='good' key="good" value='good' className={classes.layoutRect} style={{background: 'yellow'}}></MenuItem>
                        <MenuItem name='verygood' key="verygood" value='verygood' className={classes.layoutRect} style={{background: '#4DED30'}}></MenuItem>
                    </Select>
                </Grid>
                <Grid >
                    {/* backRightOutterRear */}
                    <Select
                        id="backRightOutterRear"
                        onChange={(event) => props.equipmentData.tires 
                            ? props.equipmentData.tires.vehicle 
                                ? props.updateEquipmentData({...props.equipmentData, tires: {...props.equipmentData.tires, vehicle : {...props.equipmentData.tires.vehicle, 'backRightOutterRear':  event.target.value} }})
                                : props.updateEquipmentData({...props.equipmentData, tires: {...props.equipmentData.tires, vehicle : { 'backRightOutterRear':  event.target.value} }})
                            :  props.updateEquipmentData({...props.equipmentData, tires: {vehicle : { 'backRightOutterRear':  event.target.value} }})
                        }
                        className={classes.layoutRect}
                        style={{backgroundColor: props.assignColor("vehicle","backRightOutterRear")}}
                        disabled={props.edit ? false : true}
                        value={props.equipmentData.tires ? (props.equipmentData.tires.vehicle ? (props.equipmentData.tires.vehicle.backRightOutterRear ? props.equipmentData.tires.vehicle.backRightOutterRear : "") : ""): ""}
                    >
                        <MenuItem name='poor' key="poor" value='poor' className={classes.layoutRect} style={{background: 'red'}}></MenuItem>
                        <MenuItem name='fair' key="fair" value='fair' className={classes.layoutRect} style={{background: '#00D8D8'}}></MenuItem>
                        <MenuItem name='good' key="good" value='good' className={classes.layoutRect} style={{background: 'yellow'}}></MenuItem>
                        <MenuItem name='verygood' key="verygood" value='verygood' className={classes.layoutRect} style={{background: '#4DED30'}}></MenuItem>
                    </Select>     
                </Grid>
            </Grid>

            <Grid container className={classes.layoutSideVehicle} style={{left: '50px'}}>
                <Grid >
                    {/* backRightInnerFront */}
                    <Select
                        id="backRightInnerFront"
                        onChange={(event) => props.equipmentData.tires 
                            ? props.equipmentData.tires.vehicle 
                                ? props.updateEquipmentData({...props.equipmentData, tires: {...props.equipmentData.tires, vehicle : {...props.equipmentData.tires.vehicle, 'backRightInnerFront':  event.target.value} }})
                                : props.updateEquipmentData({...props.equipmentData, tires: {...props.equipmentData.tires, vehicle : { 'backRightInnerFront':  event.target.value} }})
                            :  props.updateEquipmentData({...props.equipmentData, tires: {vehicle : { 'backRightInnerFront':  event.target.value} }})
                        }
                        className={classes.layoutRect}
                        style={{backgroundColor: props.assignColor("vehicle","backRightInnerFront")}}
                        disabled={props.edit ? false : true}
                        value={props.equipmentData.tires ? (props.equipmentData.tires.vehicle ? (props.equipmentData.tires.vehicle.backRightInnerFront ? props.equipmentData.tires.vehicle.backRightInnerFront : "") : ""): ""}
                    >
                        <MenuItem name='poor' key="poor" value='poor' className={classes.layoutRect} style={{background: 'red'}}></MenuItem>
                        <MenuItem name='fair' key="fair" value='fair' className={classes.layoutRect} style={{background: '#00D8D8'}}></MenuItem>
                        <MenuItem name='good' key="good" value='good' className={classes.layoutRect} style={{background: 'yellow'}}></MenuItem>
                        <MenuItem name='verygood' key="verygood" value='verygood' className={classes.layoutRect} style={{background: '#4DED30'}}></MenuItem>
                    </Select>
                </Grid>
                <Grid >
                    {/* backRightInnerRear */}
                    <Select
                        id="backRightInnerRear"
                        onChange={(event) => props.equipmentData.tires 
                            ? props.equipmentData.tires.vehicle 
                                ? props.updateEquipmentData({...props.equipmentData, tires: {...props.equipmentData.tires, vehicle : {...props.equipmentData.tires.vehicle, 'backRightInnerRear':  event.target.value} }})
                                : props.updateEquipmentData({...props.equipmentData, tires: {...props.equipmentData.tires, vehicle : { 'backRightInnerRear':  event.target.value} }})
                            :  props.updateEquipmentData({...props.equipmentData, tires: {vehicle : { 'backRightInnerRear':  event.target.value} }})
                        }
                        className={classes.layoutRect}
                        style={{backgroundColor: props.assignColor("vehicle","backRightInnerRear")}}
                        disabled={props.edit ? false : true}
                        value={props.equipmentData.tires ? (props.equipmentData.tires.vehicle ? (props.equipmentData.tires.vehicle.backRightInnerRear ? props.equipmentData.tires.vehicle.backRightInnerRear : "") : ""): ""}
                    >
                        <MenuItem name='poor' key="poor" value='poor' className={classes.layoutRect} style={{background: 'red'}}></MenuItem>
                        <MenuItem name='fair' key="fair" value='fair' className={classes.layoutRect} style={{background: '#00D8D8'}}></MenuItem>
                        <MenuItem name='good' key="good" value='good' className={classes.layoutRect} style={{background: 'yellow'}}></MenuItem>
                        <MenuItem name='verygood' key="verygood" value='verygood' className={classes.layoutRect} style={{background: '#4DED30'}}></MenuItem>
                    </Select>
                </Grid>
            </Grid>

            <Grid className={classes.layoutFrontVehicle} style={{left: '100px'}}>
                <Grid >
                    {/* frontRight */}
                    <Select
                        id="frontRight"
                        onChange={(event) => props.equipmentData.tires 
                            ? props.equipmentData.tires.vehicle 
                                ? props.updateEquipmentData({...props.equipmentData, tires: {...props.equipmentData.tires, vehicle : {...props.equipmentData.tires.vehicle, 'frontRight':  event.target.value} }})
                                : props.updateEquipmentData({...props.equipmentData, tires: {...props.equipmentData.tires, vehicle : { 'frontRight':  event.target.value} }})
                            :  props.updateEquipmentData({...props.equipmentData, tires: {vehicle : { 'frontRight':  event.target.value} }})
                        }
                        className={classes.layoutRect}
                        style={{backgroundColor: props.assignColor("vehicle","frontRight")}}
                        disabled={props.edit ? false : true}
                        value={props.equipmentData.tires ? (props.equipmentData.tires.vehicle ? (props.equipmentData.tires.vehicle.frontRight ? props.equipmentData.tires.vehicle.frontRight : "") : ""): ""}
                    >
                        <MenuItem name='poor' key="poor" value='poor' className={classes.layoutRect} style={{background: 'red'}}></MenuItem>
                        <MenuItem name='fair' key="fair" value='fair' className={classes.layoutRect} style={{background: '#00D8D8'}}></MenuItem>
                        <MenuItem name='good' key="good" value='good' className={classes.layoutRect} style={{background: 'yellow'}}></MenuItem>
                        <MenuItem name='verygood' key="verygood" value='verygood' className={classes.layoutRect} style={{background: '#4DED30'}}></MenuItem>
                    </Select>
                </Grid>
                <Grid >
                    {/* frontLeft */}
                    <Select
                        id="frontLeft"
                        onChange={(event) => props.equipmentData.tires 
                            ? props.equipmentData.tires.vehicle 
                                ? props.updateEquipmentData({...props.equipmentData, tires: {...props.equipmentData.tires, vehicle : {...props.equipmentData.tires.vehicle, 'frontLeft':  event.target.value} }})
                                : props.updateEquipmentData({...props.equipmentData, tires: {...props.equipmentData.tires, vehicle : { 'frontLeft':  event.target.value} }})
                            :  props.updateEquipmentData({...props.equipmentData, tires: {vehicle : { 'frontLeft':  event.target.value} }})
                        }
                        className={classes.layoutRect}
                        style={{backgroundColor: props.assignColor("vehicle","frontLeft")}}
                        disabled={props.edit ? false : true}
                        value={props.equipmentData.tires ? (props.equipmentData.tires.vehicle ? (props.equipmentData.tires.vehicle.frontLeft ? props.equipmentData.tires.vehicle.frontLeft : "") : ""): ""}
                    >
                        <MenuItem name='poor' key="poor" value='poor' className={classes.layoutRect} style={{background: 'red'}}></MenuItem>
                        <MenuItem name='fair' key="fair" value='fair' className={classes.layoutRect} style={{background: '#00D8D8'}}></MenuItem>
                        <MenuItem name='good' key="good" value='good' className={classes.layoutRect} style={{background: 'yellow'}}></MenuItem>
                        <MenuItem name='verygood' key="verygood" value='verygood' className={classes.layoutRect} style={{background: '#4DED30'}}></MenuItem>
                    </Select>
                </Grid>
            </Grid>

            <Grid container className={classes.layoutSideVehicle} style={{left: '50px'}}>
                <Grid >
                    {/* backLeftInnerFront */}
                    <Select
                        id="backLeftInnerFront"
                        onChange={(event) => props.equipmentData.tires 
                            ? props.equipmentData.tires.vehicle 
                                ? props.updateEquipmentData({...props.equipmentData, tires: {...props.equipmentData.tires, vehicle : {...props.equipmentData.tires.vehicle, 'backLeftInnerFront':  event.target.value} }})
                                : props.updateEquipmentData({...props.equipmentData, tires: {...props.equipmentData.tires, vehicle : { 'backLeftInnerFront':  event.target.value} }})
                            :  props.updateEquipmentData({...props.equipmentData, tires: {vehicle : { 'backLeftInnerFront':  event.target.value} }})
                        } 
                        className={classes.layoutRect}
                        style={{backgroundColor: props.assignColor("vehicle","backLeftInnerFront")}}
                        disabled={props.edit ? false : true}
                        value={props.equipmentData.tires ? (props.equipmentData.tires.vehicle ? (props.equipmentData.tires.vehicle.backLeftInnerFront ? props.equipmentData.tires.vehicle.backLeftInnerFront : "") : ""): ""}
                    >
                        <MenuItem name='poor' key="poor" value='poor' className={classes.layoutRect} style={{background: 'red'}}></MenuItem>
                        <MenuItem name='fair' key="fair" value='fair' className={classes.layoutRect} style={{background: '#00D8D8'}}></MenuItem>
                        <MenuItem name='good' key="good" value='good' className={classes.layoutRect} style={{background: 'yellow'}}></MenuItem>
                        <MenuItem name='verygood' key="verygood" value='verygood' className={classes.layoutRect} style={{background: '#4DED30'}}></MenuItem>
                    </Select>
                </Grid>
                <Grid >
                    {/* backLeftInnerRear */}
                    <Select
                        id="backLeftInnerRear"
                        onChange={(event) => props.equipmentData.tires 
                            ? props.equipmentData.tires.vehicle 
                                ? props.updateEquipmentData({...props.equipmentData, tires: {...props.equipmentData.tires, vehicle : {...props.equipmentData.tires.vehicle, 'backLeftInnerRear':  event.target.value} }})
                                : props.updateEquipmentData({...props.equipmentData, tires: {...props.equipmentData.tires, vehicle : { 'backLeftInnerRear':  event.target.value} }})
                            :  props.updateEquipmentData({...props.equipmentData, tires: {vehicle : { 'backLeftInnerRear':  event.target.value} }})
                        } 
                        className={classes.layoutRect}
                        style={{backgroundColor: props.assignColor("vehicle","backLeftInnerRear")}}
                        disabled={props.edit ? false : true}
                        value={props.equipmentData.tires ? (props.equipmentData.tires.vehicle ? (props.equipmentData.tires.vehicle.backLeftInnerRear ? props.equipmentData.tires.vehicle.backLeftInnerRear : "") : ""): ""}
                    >
                        <MenuItem name='poor' key="poor" value='poor' className={classes.layoutRect} style={{background: 'red'}}></MenuItem>
                        <MenuItem name='fair' key="fair" value='fair' className={classes.layoutRect} style={{background: '#00D8D8'}}></MenuItem>
                        <MenuItem name='good' key="good" value='good' className={classes.layoutRect} style={{background: 'yellow'}}></MenuItem>
                        <MenuItem name='verygood' key="verygood" value='verygood' className={classes.layoutRect} style={{background: '#4DED30'}}></MenuItem>
                    </Select>     
                </Grid>
            </Grid>

            <Grid container className={classes.layoutSideVehicle} style={{left: '50px'}}>
                <Grid >
                    {/* backLeftOutterFront */}
                    <Select
                        id="backLeftOutterFront"
                        onChange={(event) => props.equipmentData.tires 
                            ? props.equipmentData.tires.vehicle 
                                ? props.updateEquipmentData({...props.equipmentData, tires: {...props.equipmentData.tires, vehicle : {...props.equipmentData.tires.vehicle, 'backLeftOutterFront':  event.target.value} }})
                                : props.updateEquipmentData({...props.equipmentData, tires: {...props.equipmentData.tires, vehicle : { 'backLeftOutterFront':  event.target.value} }})
                            :  props.updateEquipmentData({...props.equipmentData, tires: {vehicle : { 'backLeftOutterFront':  event.target.value} }})
                        } 
                        className={classes.layoutRect}
                        style={{backgroundColor: props.assignColor("vehicle","backLeftOutterFront")}}
                        disabled={props.edit ? false : true}
                        value={props.equipmentData.tires ? (props.equipmentData.tires.vehicle ? (props.equipmentData.tires.vehicle.backLeftOutterFront ? props.equipmentData.tires.vehicle.backLeftOutterFront : "") : ""): ""}
                    >
                        <MenuItem name='poor' key="poor" value='poor' className={classes.layoutRect} style={{background: 'red'}}></MenuItem>
                        <MenuItem name='fair' key="fair" value='fair' className={classes.layoutRect} style={{background: '#00D8D8'}}></MenuItem>
                        <MenuItem name='good' key="good" value='good' className={classes.layoutRect} style={{background: 'yellow'}}></MenuItem>
                        <MenuItem name='verygood' key="verygood" value='verygood' className={classes.layoutRect} style={{background: '#4DED30'}}></MenuItem>
                    </Select>
                </Grid>
                <Grid >
                    {/* backLeftOutterRear */}
                    <Select
                        id="backLeftOutterRear"
                        onChange={(event) => props.equipmentData.tires 
                            ? props.equipmentData.tires.vehicle 
                                ? props.updateEquipmentData({...props.equipmentData, tires: {...props.equipmentData.tires, vehicle : {...props.equipmentData.tires.vehicle, 'backLeftOutterRear':  event.target.value} }})
                                : props.updateEquipmentData({...props.equipmentData, tires: {...props.equipmentData.tires, vehicle : { 'backLeftOutterRear':  event.target.value} }})
                            :  props.updateEquipmentData({...props.equipmentData, tires: {vehicle : { 'backLeftOutterRear':  event.target.value} }})
                        }  
                        className={classes.layoutRect}
                        style={{backgroundColor: props.assignColor("vehicle","backLeftOutterRear")}}
                        disabled={props.edit ? false : true}
                        value={props.equipmentData.tires ? (props.equipmentData.tires.vehicle ? (props.equipmentData.tires.vehicle.backLeftOutterRear ? props.equipmentData.tires.vehicle.backLeftOutterRear : "") : ""): ""}
                    >
                        <MenuItem name='poor' key="poor" value='poor' className={classes.layoutRect} style={{background: 'red'}}></MenuItem>
                        <MenuItem name='fair' key="fair" value='fair' className={classes.layoutRect} style={{background: '#00D8D8'}}></MenuItem>
                        <MenuItem name='good' key="good" value='good' className={classes.layoutRect} style={{background: 'yellow'}}></MenuItem>
                        <MenuItem name='verygood' key="verygood" value='verygood' className={classes.layoutRect} style={{background: '#4DED30'}}></MenuItem>
                    </Select>
                </Grid>
            </Grid>
        </Grid>
    )
}

export default withRouter(TractorLayout)


