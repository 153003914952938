import React, { useEffect } from 'react';
import { withRouter } from 'react-router'
import { makeStyles, Grid, Button } from '@material-ui/core';
import MaterialTable from '@material-table/core';
import { TableIcons } from '../../../constants/tableIcons'
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { Skeleton } from '@material-ui/lab';

import { useUser } from '../../../providers/UserContext'

const useStyles = makeStyles((theme) => ({
    content: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        maxWidth: '792px',
        margin: 'auto',
    },
    backButton: {
        marginBottom: '20px',
        marginTop: '20px',
    },
    sidebar: {
        position: 'fixed',
        right: 0,
        top: '64px',
        maxWidth: '256px',
        width: '100%',
        padding: '16px',
        zIndex: '1000',
        overflowY: 'auto',
        minHeight: "calc(100vh - 64px)",
        maxHeight: "calc(100vh - 64px)",
    },
}));

const UserReport = (props) => {

    const classes = useStyles();

    const { users, loading, getUsers } = useUser();

    useEffect(() => {
        getUsers();
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <Grid className={classes.content}>
            {loading 
                ? 
                <Skeleton variant='rect' width={'100vw'} height={'100vh'}/>
                : 
                <Grid>
                    <Grid className={classes.backButton}>
                        <Button variant="contained" startIcon={<ArrowBackIcon />}
                            onClick={() => props.history.goBack()} >Back</Button>
                    </Grid>
                    <MaterialTable
                        data={users}
                        title={"All Users"}
                        icons={TableIcons}
                        columns={[
                            { title: 'Name', render: rowData => rowData.displayName ? rowData.displayName : rowData.lastName + ', ' + rowData.firstName },
                            { title: 'Email', field: 'email' },
                            { title: 'Is Admin?', field: 'admin' },
                        ]}
                        actions={[
                            {
                                icon: TableIcons.OpenInNew,
                                tooltip: "Show User",
                                onClick: (event, rowData) => props.history.push({
                                    pathname: '/dashboard/users/form',
                                    search: "?" + new URLSearchParams({ docId: rowData.docId }).toString(),
                                })
                            }
                        ]}
                        localization={{
                            header: {
                                actions: 'Actions'
                            }
                        }}
                        options={{
                            toolbar: true,
                            paging: false,
                            actionsColumnIndex: -1,
                            search: true,
                            emptyRowsWhenPaging: false,
                            rowStyle: {
                                whiteSpace: 'pre-wrap',
                            },
                        }} />
                    </Grid>
            }
            
        </Grid>
    )
}

export default withRouter(UserReport);