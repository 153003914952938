import React, { useContext, useState } from "react"
import { firestore } from "../firebase/firebase"

const UnitContext = React.createContext()

export function useUnit() {
    return useContext(UnitContext);
}

export function UnitProvider({ children }) {

    const [unit, setUnit] = useState({});
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');

    function getUnit(docId) {
        firestore.collection("units").doc(docId).get()
            .then(documentSnapshot => {
                const unitResponse = {
                    ...documentSnapshot.data(),
                    'docId': documentSnapshot.id
                }

                setLoading(false);
                setUnit(unitResponse)
                return {
                    data: unitResponse,
                    error: false,
                    errorMsg: '',
                };
            },
                error => {
                    setLoading(false);
                    setError(error.message)
                    return {
                        data: null,
                        error: false,
                        errorMsg: error.message,
                    };
                }
            )
    }

    const value = {
        unit,
        loading,
        error,
        getUnit,
    }

    return (
        <UnitContext.Provider value={value}>
            {children}
        </UnitContext.Provider>
    )
}