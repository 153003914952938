import React, { useState, useEffect } from 'react';
import { Grid, makeStyles, Button } from '@material-ui/core';
import { withRouter } from 'react-router';
import { firestore } from '../../../firebase/firebase';
import MaterialTable  from '@material-table/core'
import { TableIcons } from '../../../constants/tableIcons';
import AddBoxIcon from '@material-ui/icons/AddBox';

//import { useAuth } from '../../../providers/AuthContext';
//import moment from 'moment'

const useStyles = makeStyles((theme) => ({
    card: {
      padding: theme.spacing(4),
      width: '100%',
      height: '100%',
    },
    content: {
      display: 'flex',
      justifyContent: 'center',
      width: '100%',
    },
    grid: {
      marginTop: theme.spacing(2),
      maxWidth: '1200px',
    },
    table: {
      width: '100%',
      maxWidth: '900px',
      margin: '16px',
      display: 'flex',
    },
    tile: {
      width: '300px',
      height: '300px',
    },
    tileContent: {
      display: 'flex',
      justifyContent: 'space-evenly',
      alignItems: 'center',
      flexDirection: 'column',
      width: '100%',
      height: '100%',
      padding: theme.spacing(4),
    },
    tileIcon: {
      color: 'rgba(0, 0, 0, 0.54)',
      width: '100px',
      height: '100px',
    },
    tileText: {
      fontSize: '1.10rem',
    },
    btn: {
      marginBottom: '20px',
      marginTop: '4px',
  },
  }));

const GiftCardsHome = (props) => {

  const [giftCardData, setGiftCardData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [searchBarValue, setSearchBarValue] = useState("giftCardSearchBarValue" in sessionStorage ? JSON.parse(sessionStorage.getItem("giftCardSearchBarValue")) : null)
  const [page, setPage] = useState("giftCardPageValue" in sessionStorage ? JSON.parse(sessionStorage.getItem("giftCardPageValue")) : 0)

  const classes = useStyles();

  useEffect(() => {
    firestore.collection('giftCards').get()
      .then(querySnapshot => {
        const giftCardResponse = querySnapshot.docs.map((doc) => {
          return {
            ...doc.data(),
            'docId': doc.id,
          }
        })
        setGiftCardData(giftCardResponse);
        setLoading(false);
      })
      .catch(e => {
        setError("Unable to Process");
        setLoading(false);
        console.log(e.message);
      })
  }, []);

  const handleOnSearchChange = (value) => {
    setSearchBarValue(value);  
    sessionStorage.setItem("giftCardSearchBarValue", JSON.stringify(value));

    setPage(0);  
    sessionStorage.setItem("giftCardPageValue", JSON.stringify(0));
  }

  const handleOnPageChange = (pageNum) => {
    setPage(pageNum);  
    sessionStorage.setItem("giftCardPageValue", JSON.stringify(pageNum));
  }


  return (
    <div className={classes.content}>
      <Grid container className={classes.table}>
        <Grid item xs={12}>
            
            <Button 
                variant='contained' 
                onClick={() => {
                    props.history.push({
                        pathname: '/dashboard/giftCards/new',
                        search: "?" + new URLSearchParams({ docId: null }).toString(),
                    })
                }} 
                className={classes.btn} 
                style={{marginRight: '10px'}} 
                startIcon={<AddBoxIcon/>}
            >
                Create new Gift Card
            </Button>

        </Grid>

        {!loading && !error
          ? <Grid item xs={12} style={{ width: '100%' }}>
           
            <MaterialTable
                style={{}}
                columns={[
                    { title: 'Name', field: 'name' },
                    { title: 'Code', field: 'code' },
                    { title: 'Value', field: 'totalValue', render: rowData => <div><b>${Number(rowData.currentValue).toFixed(2)}/${Number(rowData.totalValue).toFixed(2)}</b></div> },
                    { title: 'Date Created', field: 'dateCreated' },
                  ]}
                data={giftCardData}
                title={"Gift Cards"}
                pathname='/dashboard/giftCards/form'
                onSearchChange={ (value) => {handleOnSearchChange(value) }}
                onPageChange={ (pageNum) => {handleOnPageChange(pageNum) }}
                icons={TableIcons}
                actions={[
                    
                    {
                        icon: TableIcons.OpenInNew,
                        tooltip: 'View Gift Card',
                        onClick: (event, rowData) => props.history.push({
                            pathname: '/dashboard/giftCards/form',
                            search: "?" + new URLSearchParams({ docId: rowData.docId }).toString(),
                        })
                    }
                ]}
                options={'options' in props
                    ? props.options
                    : {
                        toolbar: true,
                        pageSize: 20,
                        pageSizeOptions: false,
                        paging: true,
                        initialPage: page,
                        actionsColumnIndex: -1,
                        search: true,
                        emptyRowsWhenPaging: false,
                        thirdSortClick: false,
                        searchText: searchBarValue,
                        rowStyle: {
                            whiteSpace: 'pre-wrap',
                        },
                    }}
            />

          </Grid>
          : null
        }
      </Grid>
    </div>
  )
}

export default withRouter(GiftCardsHome);