import React, { useEffect, useState } from 'react';
import firebase from 'firebase/compat/app'
import auth from '../../../firebase/firebase'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import { makeStyles, Typography, TextField } from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';
import Note from '../../components/Notes/Note';
import { useUser } from '../../../providers/UserContext'
import AddNote from '../../components/Notes/AddNote';
//import EditIcon from '@material-ui/icons/Edit';

const useStyles = makeStyles((theme) => ({
    card: {
        padding: theme.spacing(2),
        width: '100%',
    },
    paper: {
        maxWidth: '100%',
        margin: `${theme.spacing(1)}px auto`,
        padding: theme.spacing(2),
    },

}));
const Notes = (props) => {
    const { users, usersLoading, getUsers } = useUser();

    const [notes, setNotes] = useState([])
    const [noteText, setNoteText] = useState('')
    const [noteLoading, setNoteLoading] = useState(false);
    const [addingNote, setAddingNote] = useState(false);
    //const [edit, setEdit] = useState(false);

    function addNote() {
        const newNote = props.path;
        setAddingNote(true)
        return newNote.add(
            { 'uid': auth.currentUser.uid, 'timestamp': firebase.firestore.FieldValue.serverTimestamp(), 'note': noteText }
        ).then(() => {
            setAddingNote(false)
            setNoteText('')
        })
    }

    function editNote(docId, text) {
        const editNote = props.path.doc(docId);
        setNoteLoading(true);
        editNote.update(
            {
                'uid': auth.currentUser.uid,
                'timestamp': firebase.firestore.FieldValue.serverTimestamp(),
                'note': text
            }
        ).then(() => {
            setNoteLoading(false);
        });
    }

    const noteTextChanged = (event) => {
        setNoteText(event.target.value);
    };

    function deleteNote(docId) {
        const deleteNote = props.path.doc(docId)
        return deleteNote.delete()
    }


    useEffect(() => {
        getUsers();
    }, []) // eslint-disable-line react-hooks/exhaustive-deps


    useEffect(() => {
        const notesListener = props.path.onSnapshot((querySnapshot) => {
            if (querySnapshot.metadata.hasPendingWrites) {
                return;
            } else {
                const queryNotes = querySnapshot.docs.map((doc) => {
                    return {
                        ...doc.data(),
                        'docId': doc.id,
                    }
                })
                setNotes(queryNotes)
            }
        })
        return function cleanup() { notesListener(); };
    }, [props.path])


    const classes = useStyles()
    return (
        <Paper className={classes.card}>
            <Grid container direction='column' spacing={2}>
                <Grid item>
                    <AddNote
                        addNote={() => addNote()}
                        noteText={noteText}
                        textChanged={noteTextChanged}
                        newNoteInbound={addingNote}
                    />
                </Grid>
                <Grid container direction='column' item>
                    {usersLoading ?
                        <Skeleton style={{ width: '100%', height: '100px' }}></Skeleton>
                        :
                        notes.sort((a, b) => b.timestamp?.seconds - a.timestamp?.seconds).map((note, index) => {
                            const d = new Date();
                            d.setTime(note?.timestamp?.seconds * 1000)
                            return (
                                <Grid item key={index}>
                                    <Note
                                        loading={noteLoading}
                                        timestamp={d?.toLocaleString()}
                                        user={users.find(user => user.uuid === note?.uid)?.displayName}
                                        note={note.note ?? '<none>'}
                                        deleteNote={() => deleteNote(note.docId)}
                                        editNote={(text) => editNote(note.docId, text)}
                                    />
                                </Grid>
                            )
                        })
                    }
                    
                    {props.notes && !usersLoading && !props.timesheetLoading &&
                        <Grid item xs={12}>
                            {/*
                            <Note //legacy note
                                timestamp={"submitted on timesheet"}
                                user={users.find(user => user.uuid === props.user)?.displayName}
                                note={props.notes}
                                deleteNote={null}
                                editNote={true} 
                                />*/}
                            <Paper className={classes.paper}>

                                <Grid container>
                                    <Grid container item direction='column' xs={12}  >
                                        <Grid item>
                                            <Typography noWrap>{users.find(user => user.uuid === props.user)?.displayName}</Typography>
                                        </Grid>
                                        <Grid item >
                                            <Typography noWrap style={{ fontSize: '12px' }}>Submitted on Timesheet</Typography>
                                        </Grid>
                                    </Grid>

                                    <Grid item xs={12} zeroMinWidth style={{margin : '2px 0px 2px 0px'}}>
                                        <TextField fullWidth multiline variant='outlined' size='small' value={props.notes} 
                                                onChange={((event) => props.setTimesheet({...props.timesheet, notes: event.target.value}))}></TextField>
                                    </Grid>
                                </Grid>

                            </Paper>
                        </Grid>
                            
                    }
                </Grid>

            </Grid>
        </Paper >
    )
}
export default Notes