import React from 'react';
import { makeStyles } from '@material-ui/core';
import { Button, Snackbar, Grid } from '@material-ui/core';
import { withRouter } from 'react-router-dom';
import _ from 'lodash'
import VariantCard from './VariantCard';
import ProductCard from './ProductCard';


const useStyles = makeStyles((theme) => ({
    content: {
        display: 'flex',
        justifyContent: 'center',
        width: '100%',
    },
    paper: {
        padding: theme.spacing(4),
        maxWidth: '900px',
        width: '100%',
        backgroundColor: 'rgb(252, 252, 249)',
    },
}));

const ProductManager = (props) => {

    const classes = useStyles();

    const handleSave = (event) => {
        event.preventDefault();
        props.confirmSave();
    }

    return (!_.isUndefined(props.product) &&
        <div className={classes.content}>
            <Grid>
                <form onSubmit={handleSave}>

                    <ProductCard
                        product={props.product}
                        productCategories={props.productCategories}
                        updateData={props.updateData}
                        saveState={props.saveState}
                        />

                    <VariantCard
                        product={props.product}
                        updateData={props.updateData}
                        saveState={props.saveState}
                        createVariant={props.createVariant} />

                    <Snackbar
                        open={!_.isEqual(props.product, props.saveState)}
                        message={props.saving ? 'Saving...' : 'Save Document?'}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'center'
                        }}
                        action={
                            <React.Fragment>
                                {props.saving
                                    ? null
                                    : <React.Fragment>
                                        <Button variant='text' color='primary' onClick={props.cancelSave} style={{ marginLeft: '32px', marginRight: '8px' }}>Cancel</Button>
                                        <Button variant='contained' type='submit'>Confirm</Button>
                                    </React.Fragment>}
                            </React.Fragment>
                        }
                    />
                </form>
            </Grid>
        </div>
    )
}

export default withRouter(ProductManager)