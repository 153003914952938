import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import TimesheetSelectedPDF from '../../../components/Reports/Timesheets/TimesheetSelectedPDF'
import { Skeleton } from '@material-ui/lab';
import { Button, LinearProgress, withStyles, Grid  } from '@material-ui/core';
import { firestore } from '../../../../firebase/firebase'
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

const BorderLinearProgress = withStyles((theme) => ({
    root: {
      height: 10,
      borderRadius: 5,
      border: '1px solid white',
    },
    colorPrimary: {
      backgroundColor: 'grey',
    },
    bar: {
      borderRadius: 5,
      backgroundColor: '#FFBF00',
    },
  }))(LinearProgress);

function TimesheetSelectedPrint(props) {
    const [timesheetData, setTimesheet] = useState({});
    const [loading, setLoading] = useState([]);
    const [fullData, setFullData] = useState({})
    const [value, setValue] = useState(0);
    const [message, setMessage] = useState('');

    useEffect(() => {
        setLoading(true);
        setValue(0);
    
        const fetchData = async () => {
            if (props.location.state.data.length === 0) {
                setLoading(false);
                setValue(100);
                setMessage('No Timesheets Selected');
            } else {
                let data = props.location.state.data;
    
                for (const item of data) {
                    setValue((data.indexOf(item) + 1) / data.length * 100);
                    setMessage(`Loading Timesheet ${data.indexOf(item) + 1} of ${data.length}: Updating Print Status...`);
    
                    item.unitData = [];
                    item.deliveryData = [];
                    item.checklistData = [];
                    item.jobData = [];
    
                    await firestore.collection('timesheets').doc(item.docId).update({ 'timesheetStatus': 'printed' });
                    item.timesheetStatus = 'printed';
                    setMessage(`Loading Timesheet ${data.indexOf(item) + 1} of ${data.length}: Fetching Units...`);
                    const unitQuerySnapshot = await firestore.collection('units').where("timesheetID", "==", item.docId).get();
                    const unitResponse = unitQuerySnapshot.docs.map((doc) => ({
                        ...doc.data(),
                        'docId': doc.id,
                    }));
                    item.unitData = unitResponse;
    
                    setMessage(`Loading Timesheet ${data.indexOf(item) + 1} of ${data.length}: Fetching Notes...`);
                    const timesheetNotesQuerySnapshot = await firestore.collection('timesheets').doc(item.docId).collection('notes').get();
                    const timesheetNotes = timesheetNotesQuerySnapshot.docs.map((doc) => ({
                        ...doc.data(),
                        'docId': doc.id,
                    }));
                    item.timesheetNotes = timesheetNotes;
    
                    setMessage(`Loading Timesheet ${data.indexOf(item) + 1} of ${data.length}: Fetching Jobs...`);
                    const jobQuerySnapshot = await firestore.collection('jobs').where("timesheetID", "==", item.docId).get();
                    const jobResponse = jobQuerySnapshot.docs.map((doc) => ({
                        ...doc.data(),
                        'docId': doc.id,
                    }));
                    item.jobData = jobResponse;
    
                    let jobIDs = jobResponse.map((job) => job.docId);
                    let remainingJobs = jobIDs.length;
                    let checklists = [];
                    setMessage(`Loading Timesheet ${data.indexOf(item) + 1} of ${data.length}: Fetching Checklists...`);
    
                    while (remainingJobs > 0) {
                        const queryLimit = 10;
                        let queryStartPos = remainingJobs - queryLimit >= 0 ? remainingJobs - queryLimit : 0;
    
                        const checklistQuery = await firestore.collection('checklists').where("jobID", "in", jobIDs.slice(queryStartPos, remainingJobs)).get();
    
                        checklists = checklists.concat(checklistQuery.docs.map((doc) => ({ ...doc.data() })));
    
                        remainingJobs -= queryLimit;
                    }
    
                    item.checklistData = checklists;
                    setMessage(`Loading Timesheet ${data.indexOf(item) + 1} of ${data.length}: Fetching Deliveries...`);
                    const deliveryQuerySnapshot = await firestore.collection('deliveries').where("timesheetID", "==", item.docId).orderBy('ticket').get();
                    const deliveryResponse = deliveryQuerySnapshot.docs.map((doc) => ({
                        ...doc.data(),
                        'docId': doc.id,
                    }));
                    item.deliveryData = deliveryResponse;
                    setMessage(`Loading Timesheet ${data.indexOf(item) + 1} of ${data.length}: Finalizing...`);
    
                    if (data[data.length - 1].docId === item.docId) {
                        setValue(100);
                        setMessage('Preparing PDF...');
                        setTimesheet(data);
                        setFullData(data);
                        setLoading(false);
                    }
                }
            }
        };
    
        fetchData();
    }, [props.location.state]);


  
    return (
        loading
            ? 
            <Grid container style={{
                display: 'flex',
                width: '100%',
                padding: '32px',
                justifyContent: 'center',
                marginTop: '20px',
            }}>
                <Grid item xs={12} style={{marginBottom:'10px'}}>
                    <BorderLinearProgress variant="determinate" value={value} />
                </Grid>
                <Grid item xs={12} style={{marginBottom:'10px'}}>
                    <small>{message}</small>
                </Grid>
                <Grid item xs={12}>
                    <Skeleton variant='rect' width={'90vw'} height={'80vh'} />
                </Grid>
                
            </Grid>
            : 
            props.location.state.data.length === 0 ? <React.Fragment>
            <h1>No Timesheets Selected</h1><Button variant='contained' onClick={(e) => {props.history.goBack()}} startIcon={<ArrowBackIcon/>}>Back</Button></React.Fragment> :
            <TimesheetSelectedPDF
                timesheetData={timesheetData}
                fullData={fullData} />
    )
}

export default withRouter(TimesheetSelectedPrint)