import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import TimesheetTablePayrollPDF from '../../../components/Reports/Timesheets/TimesheetTablePayrollPDF'
import { Skeleton } from '@material-ui/lab';
import { makeStyles } from '@material-ui/core';
import { useUser } from '../../../../providers/UserContext';

const useStyles = makeStyles((theme) => ({
    content: {
        display: 'flex',
        width: '100%',
        padding: '32px',
        justifyContent: 'center',
        marginTop: '40px',
    },
}));

function TimesheetTablePayrollPrint(props) {
    const [timesheetData, setTimesheet] = useState({});
    const [loading, setLoading] = useState([]);
    const [fullData, setFullData] = useState({})
    const { getUsers, users } = useUser();
    const classes = useStyles();

    useEffect(() => {
        setLoading(true);
        if(users.length <= 0){
            getUsers();
        }
        let data = props.location.state.data
        setTimesheet(data)
        setFullData(props.location.state)
        setLoading(false)
        
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.location.state]);

    return (
        loading
            ? 
            <div className={classes.content}>
                <Skeleton variant='rect' width={'63vw'} height={'80vh'} />
            </div>
            : 
            <TimesheetTablePayrollPDF
                timesheetData={timesheetData}
                users={users}
                fullData={fullData} />
    )
}

export default withRouter(TimesheetTablePayrollPrint)