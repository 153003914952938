import React, { useState, useEffect } from 'react';
import { Checkbox, Dialog, DialogContent, DialogTitle, FormControlLabel, TextField, makeStyles, Grid, Select, Typography, MenuItem } from "@material-ui/core"
import VariantsList from '../CouponManager/VariantsList';
import _ from 'lodash';

const useStyles = makeStyles((theme) => ({
    dialogCard: {
        padding: theme.spacing(4),
        maxWidth: '650px',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        minHeight: '95vh',
        overflowX: 'hidden',
        [theme.breakpoints.down('xs')]: {
            padding: theme.spacing(1),
            height: '90%',
        }
    },
}));

const ProductSelector = (props) => {
    const classes = useStyles();

    const [searchValue, setSearchValue] = useState('')
    const [filteredProducts, setFilteredProducts] = useState([])

    useEffect(() => {
        setFilteredProducts(props.products.filter((product) => {return (product.name.toLowerCase().includes(searchValue.toLowerCase()))}))
    }, [searchValue, props.products])

    return <Dialog
        classes={{paper: classes.dialogCard,}}
        keepMounted
        open={props.open}
        onClose={props.close}
    >
        <DialogTitle id="alert-dialog-title">Select products to apply the coupon to:</DialogTitle>

        <Grid container >
            <Grid item xs={12} md={5}>
                <FormControlLabel 
                    label="Affects All Products" 
                    style={{marginTop: '15px', width: '100%'}}
                    control={
                        <Checkbox
                            disabled={props.coupon.category.onlyCategory}
                            checked={props.coupon.global}
                            onChange={() => { props.updateData({ ...props.coupon, 'global': !props.coupon.global, 'productsAffected': [] }) }}
                            label="Affects All Products"
                        />
                    } />
            </Grid>
            
            <Grid item xs={12} md={7}  >
                <FormControlLabel 
                    //label="Affects Category:" 
                    style={{marginTop: '15px', width: '100%',}}
                    control={
                        <Grid container direction='row' wrap='nowrap'>
                          
                                <Checkbox
                                    disabled={props.coupon.global}
                                    checked={props.coupon.category.onlyCategory}
                                    onChange={() => { 
                                        props.updateData({ ...props.coupon, category: {onlyCategory: !props.coupon.category?.onlyCategory, value: ''}, 'productsAffected': [] })
                                    }}
                                />
       
                                <Typography 
                                    noWrap 
                                    style={props.coupon.global ? {lineHeight: 2, marginTop: '5px', color: 'grey'} : {lineHeight: 2, marginTop: '5px',}}
                                >
                                    Affects Only Category:
                                </Typography>
                          
                            <Select
                                disabled={props.coupon.global || !props.coupon.category.onlyCategory}
                                style={props.smallScreen ? {marginLeft: '15px', width: '250px'} : {marginLeft: '15px',  width: '125px'}}
                                value={props.coupon.category.value ? props.coupon.category.value : ''}
                                onClick={(event)=> {
                                    event.target.value !== undefined && props.updateData({ ...props.coupon, category: { ...props.coupon.category, value: event.target.value}, 'productsAffected': [] })          
                                }}
                            >
                                <MenuItem key={-1} value={''}></MenuItem>
                                {props.categories.categories && props.categories.categories.sort().map((cateogry, index) => {
                                    return (
                                        <MenuItem key={index} value={cateogry}>{cateogry}</MenuItem>
                                    )
                                })}
                            </Select>
                        </Grid>
                    } />
            </Grid>
        </Grid>
        
        <Grid container justifyContent='flex-end' xs={12} md={12}>
            <TextField
                    label={"Search"}
                    size='small'
                    style={{float: 'right', marginBottom: '5px'}}
                    onChange={(e)=> { setSearchValue(e.target.value)}}
                    value={searchValue}
            />
        </Grid>

        <DialogContent style={{ width: '100%' }}>
            {filteredProducts.sort((a, b) => { return a.name > b.name ? 1 : -1 }).map((product, index) => (
                !_.isUndefined(product) && 
                    <React.Fragment key={index}>
                        <VariantsList
                        coupon={props.coupon}
                        product={product}
                        updateData={props.updateData} />
                    </React.Fragment>
            ))}
        </DialogContent>
    </Dialog>
}

export default ProductSelector;