import React, { useState, memo } from 'react';
import {Grid, makeStyles, Typography, Paper, TextField, CircularProgress, FormControl, RadioGroup, FormControlLabel, Radio, Button,
  Divider, IconButton, useTheme, useMediaQuery, 
 } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import _ from 'lodash';
import moment from 'moment';
import DatePicker from '../UI/DatePicker/DatePicker';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';

const useStyles = makeStyles((theme) => ({
  content: {
      display: 'flex',
      width: '100%',
      flexDirection: 'column',
      maxWidth: '90vw',
      margin: 'auto',
      marginTop: '20px',
      marginBottom: '20px',
  },
  modal: {
    width: '500px',
    padding: '16px',
    margin: '0 auto',
    marginTop: '20vh'
  },
  list: {
    borderBottom: '1px solid grey',
    minHeight: '400px', 
    maxHeight: '50vh',
    marginBottom: '5px',
    overflow: 'auto',
  },
}));

const TicketFilter = ({customers, loading, internalLoading, ticketEntryData, handleChangeCurrentTicket, filterValues, handleSetFilterValues, handleGenerateTicketData,
  saveState, generating }) => {

    const classes = useStyles();
    const theme = useTheme();
    const smallScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const medScreen = useMediaQuery(theme.breakpoints.down('md'));

    //holds open state for autocompletes
    const [autoCompletesOpen, setAutoCompletesOpen] = useState({
      findTicket: false,
      findDate: false,
      findContact: false,
    })

    //FOR TESTING PURPOSES ONLY
    //console.log('ticket filter rerendered')

    const handleAutoCompleteOpenClose = (key, bool) => { setAutoCompletesOpen({...autoCompletesOpen, [key]: bool}) }

    return (
      <div className={classes.content}>
        <Paper >
          <Grid container style={{padding: '10px'}}>
            <Grid container>
              <Grid item xs={6} md={6} lg={6}>
                <Grid container>
                  {/* <Typography  style={{marginTop: '5px', marginRight: '5px'}}>Current Ticket:</Typography> */}

                  <IconButton
                      variant="outlined"
                      disabled={ 
                        _.isEmpty(saveState) || ticketEntryData.findIndex(x=> x.docId === saveState.docId) === -1 ||
                        ticketEntryData.findIndex(x=> x.docId === saveState.docId) - 1 < 0
                      }
                      onClick={() => {
                        handleChangeCurrentTicket(ticketEntryData[ticketEntryData.findIndex(x=> x.docId === saveState.docId) - 1]) 
                      }}
                  >
                      <NavigateBeforeIcon />
                  </IconButton>

                  <Autocomplete
                    open={autoCompletesOpen['findTicket']}
                    onOpen={() => { handleAutoCompleteOpenClose('findTicket', true); }}
                    onClose={() => { handleAutoCompleteOpenClose('findTicket', false); }}
                    loading={internalLoading}
                    label="Current Ticket"
                    size='small'
                    id="autocomplete"
                    autoComplete
                    autoHighlight
                    disableClearable
                    style={{minWidth: '200px',}}
                    value={saveState}
                    onChange={(event, newValue) => {
                      handleChangeCurrentTicket(newValue)
                      handleSetFilterValues({...filterValues, ticketAutocompleteValue: newValue})
                      //setTicketAutocompleteValue(newValue)
                      sessionStorage.setItem("ticketFindByTicketFilter", JSON.stringify(newValue)) 
                    }}
                    options={ticketEntryData?.map((ticket) => ticket ?? {})}
                    getOptionSelected={(option, value) => option?.ticket?.toString() === value?.ticket?.toString() || {}}
                    getOptionLabel={(option) => option.ticket}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label="Current Ticket"
                            variant="outlined"
                            size='small'
                            InputProps={{
                                ...params.InputProps,
                                endAdornment: (
                                    <React.Fragment>
                                        {internalLoading ? <CircularProgress color="inherit" size={20} /> : null}
                                        {params.InputendAdornment}
                                    </React.Fragment>
                                ),
                            }}
                        />
                    )}
                  />

                  <IconButton
                      variant="outlined"
                      disabled={ 
                        _.isEmpty(saveState) || ticketEntryData.findIndex(x=> x.docId === saveState.docId) === -1 ||
                        ticketEntryData.findIndex(x=> x.docId === saveState.docId) + 1 > (ticketEntryData.length -1)
                      }
                      onClick={() => {
                        handleChangeCurrentTicket(ticketEntryData[ticketEntryData.findIndex(x=> x.docId === saveState.docId) + 1]) 
                      }}
                  >
                      <NavigateNextIcon />
                  </IconButton>
                </Grid>
              </Grid>

                
              <Grid item xs={6} md={6} lg={6}>
                <Grid container justifyContent='flex-end'>
                  <Typography style={{fontWeight: 700, marginTop: '5px', marginRight: '5px'}}>
                    {ticketEntryData.length > 0 ? `${ticketEntryData.length} Tickets Generated.` : "Generate Tickets Below"}
                  </Typography>
                </Grid>
              </Grid>

            </Grid>

            <Divider style={{width:'100%', marginBottom: '10px', marginTop: '10px'}}/>
          
            <Grid item xs={12} md={12} lg={6}>
              <Grid container>
                  <Grid container style={{marginBottom: '5px', marginTop: '5px'}}>
                    <Typography style={{marginTop: '5px', marginRight: '5px'}}>Filter By Contact:</Typography>
                      <Autocomplete
                        open={autoCompletesOpen['findContact']}
                        onOpen={() => { handleAutoCompleteOpenClose('findContact', true); }}
                        onClose={() => { handleAutoCompleteOpenClose('findContact', false); }}
                        loading={internalLoading}
                        label="Contact"
                        size='small'
                        id="autocomplete"
                        autoComplete
                        autoHighlight
                        style={{width: '500px'}}
                        value={filterValues.contactAutocompleteValue}
                        onChange={(event, newValue) => { 
                          handleSetFilterValues({...filterValues, contactAutocompleteValue: newValue})
                          sessionStorage.setItem("ticketFindByCustomerFilter", JSON.stringify(newValue)) 
                        }}
                        options={customers.sort((a, b) => a.name.localeCompare(b.name)) || {}}
                        getOptionLabel={(option) => option.name ? option.name + ' - ' + option.streetNumber + ' ' + option.streetName : ''  }
                        getOptionSelected={(option, value) =>  option?.name === value?.name}
                        renderOption={(option) =>( 
                            customers.filter(customer => customer.docId === option.docId).map((customerObj) => {
                                return(
                                    <Grid key={option.docId} >
                                        <Typography>{customerObj.name}</Typography>
                                        <small>{ customerObj.streetNumber + ' ' + customerObj.streetName}</small>
                                    </Grid>
                                )
                            })
                        )}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label="Contact"
                                variant="outlined"
                                size='small'
                                InputProps={{
                                    ...params.InputProps,
                                    endAdornment: (
                                        <React.Fragment>
                                            {internalLoading ? <CircularProgress color="inherit" size={20} /> : null}
                                            {params.InputProps.endAdornment}
                                        </React.Fragment>
                                    ),
                                }}
                            />
                        )}
                    />
                  </Grid>
                      
                  <Grid container >
                    <Typography  style={{marginTop: '5px', marginRight: '5px'}}>Filter by Date Range:</Typography>
                    <DatePicker 
                      style={{marginRight: '5px'}}
                      value={filterValues.dateRange?.start || moment().valueOf()} 
                      onChange={(date) => { 
                        handleSetFilterValues({...filterValues, dateRange: {...filterValues.dateRange, start: date}}) 
                        sessionStorage.setItem("ticketFilterDateRange", JSON.stringify({...filterValues.dateRange, start: date})) 
                      }}
                    />

                    <DatePicker 
                      style={{marginRight: '5px'}}
                      value={filterValues.dateRange?.end || moment().valueOf()} 
                      onChange={(date) => { 
                        handleSetFilterValues({...filterValues, dateRange: {...filterValues.dateRange, end: date}}) 
                        sessionStorage.setItem("ticketFilterDateRange", JSON.stringify({...filterValues.dateRange, end: date})) 
                      }}
                    />
                  </Grid>

              </Grid>
            </Grid>

            <Grid item xs={12} md={12} lg={6}>
              <Grid container justifyContent={(medScreen || smallScreen) ? 'flex-start' : 'flex-end'}>
                <FormControl>
                  <RadioGroup
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="row-radio-buttons-group"
                    value={filterValues.currentRadio}
                    onChange={(e)=> {
                      handleSetFilterValues({...filterValues, currentRadio: e.target.value})
                      //setCurrentRadio(e.target.value)
                      sessionStorage.setItem("ticketRadioFilter", JSON.stringify(e.target.value)) 
                    }}
                  >
                    <FormControlLabel value="all" control={<Radio />} label="All Tickets" />
                    <FormControlLabel value="notInvoiced" control={<Radio />} label="Not Invoiced" />
                    <FormControlLabel value="notPayrolled" control={<Radio />} label="Not Payrolled" />
                    <FormControlLabel value="invoicedAndPayrolled" control={<Radio />} label="Invoiced and Payrolled" />
                  </RadioGroup>
                </FormControl>
              </Grid>

              {/* filter by order type */}
              <Grid container justifyContent={(medScreen || smallScreen) ? 'flex-start' : 'flex-end'}>
                <FormControl>
                  <RadioGroup
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="row-radio-buttons-group"
                    value={filterValues.orderType}
                    onChange={(e)=> {
                      handleSetFilterValues({...filterValues, orderType: e.target.value})
                      //setCurrentRadio(e.target.value)
                      sessionStorage.setItem("ticketOrderTypeRadio", JSON.stringify(e.target.value)) 
                    }}
                  >
                    <FormControlLabel value="all" control={<Radio />} label="All Tickets" />
                    <FormControlLabel value="deck" control={<Radio />} label="Deck/Equipment" />
                    <FormControlLabel value="geetee" control={<Radio />} label="GeeTee" />
                    <FormControlLabel value="wtl" control={<Radio />} label="WTL" />
                  </RadioGroup>
                </FormControl>
              </Grid>
            </Grid>

            <Grid container justifyContent='flex-end' style={{marginTop: '20px',}}>
              <Button 
                variant='contained'
                disabled={generating}
                onClick={()=> { handleGenerateTicketData() }}
              >
                {generating ? "Generating" : "Generate"}
              </Button>
            </Grid>

          </Grid>
        </Paper>
      </div>
    )
  }
  
  export default memo(TicketFilter);