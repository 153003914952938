import React from 'react';
import { Grid, makeStyles, Button, useMediaQuery } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    content: {
        display: 'flex',
        width: '100%',
        flexDirection: 'column',
        maxWidth: '90vw',
        margin: 'auto',
        marginTop: '20px',
    },
    paper: {
        //borderRadius: '0px',
        margin: '0px',
        width: '100%',
        maxWidth: '90vw',
        padding: '16px',
       // backgroundColor: 'rgb(252, 252, 249)',
    },
    table: {
        display: 'flex',
        justifyContent: 'center',
    },
    createButton: {
        marginBottom: '20px',
        marginTop: '20px',
    },
}));

const GrainButtonList = (props) => {

    const classes = useStyles();
    const theme = useTheme();
    const smallScreen = useMediaQuery(theme.breakpoints.down('sm'));

    //console.log('GrainButtonList rerendered')

    return(
        <Grid>
            <Grid container  justifyContent={smallScreen ? 'flex-start' :'flex-end'} >
                <Button
                    className={classes.createButton}
                    style={{marginRight: '5px'}}
                    variant='contained'
                    disabled={true}
                    onClick={(e) => {  
                        //props.history.push({ pathname: 'grains/new',}) 
                    }}
                >
                    Dispatch Screen
                </Button>

                <Button
                    className={classes.createButton}
                    style={{marginRight: '5px', marginLeft: '5px'}}
                    variant='contained'
                    disabled={true}
                    onClick={(e) => { 
                        //handleSendToDispatch() 
                    }}
                >
                    Send to Dispatch
                </Button>

                <Button
                    className={classes.createButton}
                    style={{marginRight: '5px', marginLeft: '5px'}}
                    variant='contained'
                    disabled={ props.rowsSelected.length === 0 }
                    onClick={(e) => {
                        props.onNewRow(props.rowsSelected.map((docId) => { return props.globalGrainOrders.find(x=> x.docId === docId)}), true)
                    }}
                >
                    Copy Order
                </Button>

                <Button
                    className={classes.createButton}
                    style={{marginRight: '5px', marginLeft: '5px'}}
                    variant='contained'
                    disabled={ props.rowsSelected.length === 0}
                    onClick={(e) => { props.handlePrintOrder(props.rowsSelected, props.globalGrainOrders) }}
                >
                    Print Order
                </Button>

                <Button
                    className={classes.createButton}
                    style={{marginRight: '5px', marginLeft: '5px'}}
                    variant='contained'
                    disabled={ props.rowsSelected.length === 0 }
                    onClick={(e) => { 
                        props.setEmailModal(true) 
                    }}
                >
                    Email Order Instructions
                </Button>

                <Button
                    className={classes.createButton}
                    style={{marginLeft: '5px'}}
                    variant='contained'
                    disabled={ props.rowsSelected.length === 0}
                    onClick={(e) => {
                        props.setTicketModal(true)
                    }}
                >
                    Send Data to Invoice
                </Button>

                <Button
                    className={classes.createButton}
                    style={{marginLeft: '5px'}}
                    variant='contained'
                    disabled={ props.rowsSelected.length === 0 }
                    onClick={(e) => {
                        props.setDeleteData({ pending: [...props.rowsSelected.map((docId) => { return props.globalGrainOrders.find(x=> x.docId === docId)})], confirmed: [], current: 0,  open: true })
                    }}
                >
                    Delete Orders
                </Button>
            </Grid>
        </Grid>
    )
}

export default GrainButtonList;