import { Typography, Paper, Grid, Divider } from '@material-ui/core'
import { Skeleton } from '@material-ui/lab'

const PalletCard = (props) => {
    return (
        (props.loading || !props.order) ? <Skeleton></Skeleton> :

            <Paper elevation={2} style={{ padding: "16px", margin: "8px", color: '#707070' }}>
                <Typography variant='h6' >Pallet Details</Typography>

                <Grid container direction='row' justifyContent='space-between' spacing={2}>
                    <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Divider />
                            </Grid>
                            <Grid item xs={8} sm={5}>
                                <Typography>Total Pallet Fees:</Typography>
                            </Grid>
                            <Grid item xs={4} sm={1} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                <Typography style={{fontWeight:'bold'}}>${props.order.order.fees?.palletFee.totalFee.toFixed(2)}</Typography>
                            </Grid>
                            <Grid item xs={8} sm={5}>
                                <Typography>Total Pallets on Order:</Typography>
                            </Grid>
                            <Grid item xs={4} sm={1} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                <Typography style={{fontWeight:'bold'}}>{props.order.order.fees?.palletFee.totalPallets}</Typography>
                            </Grid>
                            <Grid item xs={8} sm={5}>
                                <Typography>Fee Refunded:</Typography>
                            </Grid>
                            <Grid item xs={4} sm={1} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                <Typography style={{fontWeight:'bold'}}>${(props.order.order.fees?.palletFee.totalFee - props.order.order.fees?.palletFee.remainingPalletFee).toFixed(2)}</Typography>
                            </Grid>
                            <Grid item xs={8} sm={5}>
                                <Typography>Returned Pallets:</Typography>
                            </Grid>
                            <Grid item xs={4} sm={1} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                <Typography style={{fontWeight:'bold'}}>{props.order.order.fees?.palletFee.totalPallets - props.order.order.fees?.palletFee.remainingPallets}</Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Divider />
                            </Grid>
                            <Grid item xs={8} sm={5}>
                                <Typography>Outstanding Fee:</Typography>
                            </Grid>
                            <Grid item xs={4} sm={1} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                <Typography style={{fontWeight:'bold'}}>${props.order.order.fees?.palletFee.remainingPalletFee.toFixed(2)}</Typography>
                            </Grid>
                            <Grid item xs={8} sm={5}>
                                <Typography>Remaining Pallets:</Typography>
                            </Grid>
                            <Grid item xs={4} sm={1} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                <Typography style={{fontWeight:'bold'}}>{props.order.order.fees?.palletFee.remainingPallets}</Typography>
                            </Grid>
                </Grid>
                </Grid>
            </Paper>)
}

export default PalletCard