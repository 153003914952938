///component for creating a new training
import React, {useState} from 'react';
import { Grid, Button, Typography, Paper, TextField, withStyles, LinearProgress, Divider } from '@material-ui/core';
import Dropzone from 'react-dropzone';
import PublishIcon from '@material-ui/icons/Publish';
import ClearIcon from '@material-ui/icons/Clear';
import { firestore, storage } from '../../../../../firebase/firebase';

// Create styles
const BorderLinearProgress = withStyles((theme) => ({
    root: {
      height: 10,
      borderRadius: 5,
      border: '1px solid white',
    },
    colorPrimary: {
      backgroundColor: 'grey',
    },
    bar: {
      borderRadius: 5,
      backgroundColor: '#FFBF00',
    },
  }))(LinearProgress);

const NewTrainingForm = (props) => {

    const [newCertificate, setNewCertificate] = useState(null);
    const [uploading, setUploading] = useState(false);
    const [uploadProgress, setUploadProgress] = useState(0);
    const [newTraining, setNewTraining] = useState({
        title: '',
        completedDate: '',
        duration: '',
        certificate: {},
    });

    const substringText = (text) => {
        if(text.length > 20){
            return text.substring(0, 20) + '...'
        } else {
            return text
        }
    }

    const createNewTraining = async () => {
        // create new document in employeeTrainings collection
        const storageRef = storage.ref();
        const newTrainingRef = firestore.collection("employeeTrainings").doc();
        const newTrainingData = {
            title: newTraining.title,
            completedDate: newTraining.completedDate,
            duration: newTraining.duration,
            //certificate: {url: newCertificate.url, name: newCertificate.name, type: newCertificate.type},
            uuid: props.userData.uuid,
        }
        await newTrainingRef.set(newTrainingData);

        // upload certificate to storage
        if (newCertificate?.file) {
            setUploading(true)
            const path = storageRef.child(`users/${props.userData.uuid}/certificate/${newCertificate.name}`);
            const uploadTask = path.put(newCertificate.file);
            uploadTask.on(
                "state_changed",
                (snapshot) => {
                    // Track progress here
                    const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                    setUploadProgress(progress);
                    console.log(`Upload is ${progress}% done`);
                },
                (error) => {
                    // Handle errors here
                    console.error("Error uploading file:", error);
                    setUploading(false)
                },
                async () => {
                    // Upload completed successfully
                    const url = await path.getDownloadURL();
                    await newTrainingRef.update({ certificate: { url: url, name: newCertificate.name, type: newCertificate.type } });
                    setUploading(false)
                    setTimeout(() => { setUploadProgress(0) }, 3000);
                }
            );
        }

        setNewTraining({ title: '', completedDate: '', duration: '', certificate: {} });
        setNewCertificate();
    }

    const handleCertificateUpload = async (acceptedFiles) => {
        const file = acceptedFiles[0];
        if (file) {
            setNewCertificate({ file:file, name: file.name, type:file.type });
        }
    };
    
    return(
        <Paper style={{padding:'10px', width:'100%', marginBottom:'10px'}}>
        <Grid container>
            <Grid item xs={12}>
                <Typography variant="h6" style={{marginBottom: '10px'}}>Add new completed Training</Typography>
            </Grid>
            <Grid item xs={6}>
                <Typography style={{ lineHeight: 2 }} noWrap variant='body1'>Title:</Typography>
            </Grid>
            <Grid item xs={6}>
                <TextField 
                    id="outlined-basic"
                    //variant="outlined"
                    placeholder='Training Title'
                    onChange={(e) => setNewTraining({ ...newTraining, title: e.target.value })}
                    value={newTraining.title}
                    fullWidth
                />
            </Grid>
            <Grid item xs={6}>
                <Typography variant="body1" style={{marginBottom: '10px'}}>Completed Date:</Typography>
            </Grid>
            <Grid item xs={6}>
                <TextField
                    id="outlined-basic"
                    type="date"
                    onChange={(e) => setNewTraining({ ...newTraining, completedDate: e.target.value })}
                    value={newTraining.completedDate}
                    fullWidth
                />
            </Grid>
            <Grid item xs={6}>
                <Typography variant="body1" style={{marginBottom: '10px'}}>Duration:</Typography>
            </Grid>
            <Grid item xs={6}>
                <TextField
                    id="outlined-basic"
                    placeholder='2 Hours, 30 Minutes, 2 Days, etc.'
                    onChange={(e) => setNewTraining({ ...newTraining, duration: e.target.value })}
                    value={newTraining.duration}
                    fullWidth
                />
            </Grid>
        </Grid>
        <Grid container>
            <Grid item xs={12}>
                <Typography variant="h6" style={{marginBottom: '10px'}}>Certificate</Typography>
            </Grid>
            <Grid item xs={12}>
            <Dropzone accept={{'image/jpeg': ['.jpg', '.jpeg'], 'image/png': ['.png', '.PNG'], 'application/pdf': ['.pdf'],}}
                onDrop={handleCertificateUpload}>
                {({ getRootProps, getInputProps }) => (
                    <Grid container item xs={12}justifyContent='center' {...getRootProps()}  style={{border:'2px dashed lightGrey', minHeight:'100px'}}>
                        <input {...getInputProps()} style={{ display: 'none' }} />

                        <PublishIcon style={{color:'grey', marginRight:'20px'}}/>

                        <Typography variant='h6'>
                            {newCertificate?.name ? substringText(newCertificate?.name) : "Upload Certificate Image/PDF"}
                        </Typography>
                    </Grid>
                )}
            </Dropzone> 
            {uploading && <Grid item xs={12} style={{marginBottom:'10px'}}>
                <BorderLinearProgress variant="determinate" value={uploadProgress} />
            </Grid>}
            {newCertificate?.name ?<Button disabled={uploading} size='small' variant='contained' style={{ color:'black' }} onClick={() => {setNewCertificate()}}>
                <ClearIcon /> Clear Certificate
            </Button>:null}     
        </Grid>  
        </Grid>
        <Divider/>
        <Button style={{marginTop:'10px'}} variant="contained" disabled={uploading} onClick={
            () => {
                createNewTraining();
            }
        }>Add Training</Button>
    </Paper>
    )

}

export default NewTrainingForm;