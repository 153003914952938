import React from 'react';
import { Checkbox, Dialog, DialogContent, DialogTitle, Divider, FormControlLabel, makeStyles, Grid } from "@material-ui/core";
import { firestore } from '../../../firebase/firebase';
import _ from 'lodash';

const useStyles = makeStyles((theme) => ({
    dialogCard: {
        padding: theme.spacing(4),
        maxWidth: '650px',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        maxHeight: '700px',
        [theme.breakpoints.down('xs')]: {
            padding: theme.spacing(1),
            maxHeight: '90%',
        }
    },
}));

const CouponSelector = (props) => {

    const classes = useStyles();
    return <Dialog
        classes={{
            paper: classes.dialogCard,
        }}
        keepMounted
        open={props.open}
        onClose={props.close}
    >
        <DialogTitle id="alert-dialog-title">Select coupons that should apply to this product:</DialogTitle>

        <div style={{ width: '100%' }}>
            <Divider style={{ marginTop: '8px', marginBottom: '16px' }} />
        </div>
        <DialogContent style={{ width: '100%' }}>
            {props.coupons.sort((a, b) => { return a.code > b.code ? 1 : -1 }).map((coupon, index) => (
                !_.isUndefined(coupon) && 
                <Grid item xs={11} style={{ display: 'flex', alignItems: 'center' }} key={index}>
                    <FormControlLabel
                        style={{ marginLeft: '8px' }}
                        label={coupon.code.toUpperCase() + ": " + coupon.description}
                        control={
                            <Checkbox
                                //disabled={props.coupon.global}
                                checked={coupon.productsAffected.includes(props.products.id)}
                                
                                onChange={() => {
                                    let newProductsAffected = [...coupon.productsAffected];

                                    let index = newProductsAffected.findIndex(id => id === props.products.id)
                                    index > -1
                                        ? newProductsAffected.splice(index, 1)
                                        : newProductsAffected.push(props.products.id)

                                    firestore.collection('coupons').doc(coupon.docId).update({'productsAffected': newProductsAffected})
                                }}
                            />}
                    />
                </Grid>
            ))}
        </DialogContent>
    </Dialog>
}

export default CouponSelector;