import React, { useState } from 'react';
import Aux from '../../../hoc/AuxComponent';
import { REPORT_TYPES } from '../../../constants/reportTypes'
import TimesheetReport from './TimesheetReport/TimesheetReport';
import JobReport from './JobReport/JobReport';
import ChecklistReport from './ChecklistReport/ChecklistReport';
import DefectReport from './DefectReports/Defect'
import UnitReport from './UnitReport/UnitReport';
import DeliveryReport from './DeliveryReport/DeliveryReport';
import PhotosReport from './PhotosReport/PhotosReport';
import DocumentsReport from './DocumentsReport/DocumentsReport';
import TrainingsReport from './TrainingReport/TrainingsReport';
import { withRouter } from 'react-router-dom';


const Reporting = (props) => {
    //const [reportType, setReportType] = useState(REPORT_TYPES.TIMESHEET)

    const [reportType, setReportType] = useState(JSON.parse(sessionStorage.getItem("reportType")) || REPORT_TYPES.TIMESHEET);
    const dropdownChanged = (event) => {

        setReportType(event.target.value);
        sessionStorage.setItem("reportType", JSON.stringify(event.target.value))
       
    }

    const displayReport = () => {
        switch (reportType) {
            case REPORT_TYPES.TIMESHEET:
                return <TimesheetReport timesheetClickHandler={timesheetClickHandler}
                    reportType={reportType}
                    onchange={(event) => dropdownChanged(event)} />
            case REPORT_TYPES.JOB:
                return <JobReport jobClickHandler={jobClickHandler}
                    reportType={reportType}
                    onchange={(event) => dropdownChanged(event)} />
            case REPORT_TYPES.DELIVERY:
                return <DeliveryReport deliveryClickHandler={deliveryClickHandler}
                    reportType={reportType}
                    onchange={(event) => dropdownChanged(event)} />
            case REPORT_TYPES.COVID:
                return <ChecklistReport checklistClickHandler={checklistClickHandler}
                    reportType={reportType}
                    onchange={(event) => dropdownChanged(event)} />
            case REPORT_TYPES.DEFECT:
                return <DefectReport defectClickHandler={defectClickHandler}
                    reportType={reportType}
                    onchange={(event) => dropdownChanged(event)} />
            case REPORT_TYPES.UNIT:
                return <UnitReport unitClickHandler={unitClickHandler}
                    reportType={reportType}
                    onchange={(event) => dropdownChanged(event)} />
            case REPORT_TYPES.PHOTOS:
                return <PhotosReport photosClickHandler={photosClickHandler}
                    reportType={reportType}
                    onchange={(event) => dropdownChanged(event)} />
            case REPORT_TYPES.DOCUMENTS:
                return <DocumentsReport documentsClickHandler={documentsClickHandler}
                    reportType={reportType}
                    onchange={(event) => dropdownChanged(event)} />
            case REPORT_TYPES.TRAINING:
                return <TrainingsReport trainingsClickHandler={trainingsClickHandler}
                    reportType={reportType}
                    onchange={(event) => dropdownChanged(event)} />
            default:
                return <div>Please select a report</div>
        }
    }

    const timesheetClickHandler = (path) => {
        props.history.push({
            pathname: '/dashboard/reporting/timesheets',
            search: "?" + new URLSearchParams({ docId: path }).toString(),
        })
    }

    const jobClickHandler = (path) => {
        props.history.push({
            pathname: '/dashboard/reporting/timesheets/job',
            search: "?" + new URLSearchParams({ docId: path }).toString(),
        })
    }
    const unitClickHandler = (path) => {
        props.history.push({
            pathname: '/dashboard/reporting/timesheets/unit',
            search: "?" + new URLSearchParams({ docId: path }).toString(),
        })
    }
    const deliveryClickHandler = (path) => {
        props.history.push({
            pathname: '/dashboard/reporting/timesheets/delivery',
            search: "?" + new URLSearchParams({ docId: path }).toString(),
        })
    }
    const checklistClickHandler = (path) => {
        props.history.push({
            pathname: '/dashboard/reporting/timesheets/checklist',
            search: "?" + new URLSearchParams({ docId: path }).toString(),
        })
    }
    const defectClickHandler = (path) => {
        props.history.push({
            pathname: '/dashboard/reporting/timesheets/defect',
            search: "?" + new URLSearchParams({ docId: path }).toString(),
        })
    }   
    const photosClickHandler = (path, origin) => {
        props.history.push({
            pathname: '/dashboard/reporting/timesheets/' + origin,
            search: "?" + new URLSearchParams({ docId: path }).toString(),
        })
    }
    const documentsClickHandler = (path) => {
        props.history.push({
            pathname: '/dashboard/reporting/documents',
            search: "?" + new URLSearchParams({ docId: path }).toString(),
        })
    }

    const trainingsClickHandler = (path) => {
        props.history.push({
            pathname: '/dashboard/reporting/trainings',
            search: "?" + new URLSearchParams({ docId: path }).toString(),
        })
    }

    return (
        <Aux>
            {displayReport()}
        </Aux>
    );

}

export default withRouter(Reporting);