import React, {useState, useEffect} from 'react';
import { firestore } from '../../../../firebase/firebase'
import { withRouter } from 'react-router-dom';
import DeliveryCard from '../../../components/Reports/Deliveries/DeliveryCard'
import { Skeleton } from '@material-ui/lab';
import { makeStyles } from '@material-ui/core';


const useStyles = makeStyles((theme) => ({
    content: {
        display: 'flex',
        width: '100%',
        padding: '32px',
        justifyContent: 'center',
        marginTop: '40px',
    },
}));

const Delivery = (props) => {
    const [deliveryData, setDeliveryData] = useState({});
    const [userData, setUserData] = useState({});
    const [unitData, setUnitData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [errorMessage, setErrorMessage] = useState(false);
    let path = new URLSearchParams(props.location.search).get("docId");
    const classes = useStyles();

    useEffect(() => {
        setLoading(true);
    
        const path = new URLSearchParams(props.location.search).get("docId");
    
        // Create a reference to the "deliveries" collection
        const deliveriesRef = firestore.collection('deliveries');
    
        // Set up the listener for changes in the "deliveries" collection
        const unsubscribe = deliveriesRef.doc(path).onSnapshot(async (snapshot) => {
            if (!snapshot.exists) {
                setErrorMessage("No document found");
                setLoading(false);
                return;
            }
    
            const deliveryResponse = {
                ...snapshot.data(),
                docId: snapshot.id,
            };
    
            setDeliveryData(deliveryResponse);
    
            try {
                // Fetch user data
                const userSnapshot = await firestore.collection('users').where("uuid", "==", deliveryResponse.uid).get();
                if (!userSnapshot.empty) {
                    const userData = userSnapshot.docs.map(doc => ({
                        ...doc.data(),
                        docId: doc.id,
                    }))[0];
                    setUserData(userData);
                } else {
                    setUserData(null);
                }
    
                // Fetch unit data
                const unitSnapshot = await firestore.collection('units').where("timesheetID", "==", deliveryResponse.timesheetID).get();
                const unitData = unitSnapshot.docs.map(doc => ({
                    ...doc.data(),
                    docId: doc.id,
                }));
                setUnitData(unitData);
    
            } catch (error) {
                console.error("Error fetching data:", error);
                setErrorMessage("Unable to fetch some data");
            } finally {
                setLoading(false);
            }
        }, (error) => {
            console.error("Error fetching delivery data:", error);
            setErrorMessage("Unable to Process");
            setLoading(false);
        });
    
        // Clean up function to unsubscribe from the listener when the component unmounts or when the dependency array changes
        return () => unsubscribe();
    }, [path]); // eslint-disable-line react-hooks/exhaustive-deps
    

    return (
        loading 
        ? 
        <div className={classes.content}>
            <Skeleton variant='rect' width={'41vw'} height={'95vh'}/>
        </div>
        : 
        <DeliveryCard deliveryData={deliveryData} userData={userData} unitData={unitData} errorMSG={errorMessage} />
    )
}

export default withRouter(Delivery)