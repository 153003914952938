import React from 'react';
import MaterialTable  from '@material-table/core'
import { TableIcons } from '../../../../constants/tableIcons';
import { useHistory } from 'react-router-dom';

const GiftCardTable = (props) => {
    const history = useHistory();
    console.log(props)
    return (
        <MaterialTable
            style={{ color: '#707070', padding: '16px', margin: '8px', minHeight: 'calc(100% - 16px)' }}
            data={props.order.giftCardList}
            title={props.title}
            columns={[
                {
                    title: 'Code',
                    field: 'code',
                    render: rowData => <b>{rowData.code}</b>
                },
                { 
                    title: 'Total Value', 
                    field: 'totalValue',
                    render: rowData => `$${rowData.totalValue}`                
                },

            ]}
            isLoading={props.loading}
            icons={TableIcons}
            actions={[
                {
                    icon: TableIcons.OpenInNew,
                    tooltip: 'View Gift Card',
                    onClick: (event, rowData) => {
                        history.push({
                            pathname: '/dashboard/giftCards/form',
                            search: "?" + new URLSearchParams({ docId: rowData.code }).toString(),
                        })
                    },
                },
            ]} 
            options={{
                'paging': false,
                'headerStyle': { color: '#707070' },
                'search': false,
                'thirdSortClick': false,
                actionsColumnIndex: 0,
            }}
          
        />
    )
}

export default GiftCardTable