import React, {useEffect, useState} from 'react';
import {Button, Divider, Select, MenuItem, Grid, Paper, TextField, Typography, makeStyles, FormControl} from '@material-ui/core'
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import { firestore } from '../../../firebase/firebase';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { withRouter } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
    btnGroup:{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-end',
    },
    card: {
        width: '100%',
        height: '100%',
        padding: theme.spacing(2),
        display: 'flex',
        alignItems: 'center',
    },
    content: {
        maxWidth: '1000px',
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        margin: 'auto',
    },
    fileInput: {
        display:'flex',
        alignItems: 'center',
        border: '1px solid #bdbdbd',
        borderRadius:'4px',
        margin:'8px',
        marginTop:'16px',
        backgroundColor: '#f2f2f2',
    },
    fileInputAccept: {
        display:'flex',
        alignItems: 'center',
        border: '0px',
        borderRadius:'4px',
        margin:'8px',
        marginTop:'16px',
        backgroundColor: '#56a84b',
        color:'white',
    },
    image: {
        width:'100%',
        height: '200px',
        objectFit: 'contain',
    },
    photoBack: {
        display: 'flex',
        height:'200px',
        maxHeight: '200px',
        width:'100%',
        backgroundColor: 'rgba(0,0,0,.05)'
    },
    photoTile: {
        display:'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height:'100%',
    },
    paper: {
        width:'100%',
        maxWidth: '1000px',
        padding: theme.spacing(2),
    },
    row:{
        display: 'flex',
        alignItems:'center',
    },
    title: {
        display:'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginBottom: '32px',
    },
    text: {
        color: '#707070',
    },
    backButton: {
        marginBottom: '20px',
        marginTop: '20px',
    },
}));

const UserForm = (props) => {

    const classes=useStyles();
    const [roles, setRoles] = useState({});
    const [rolesLoading, setRolesLoading] = useState({});

    useEffect(() => {
        setRolesLoading(true);
        firestore.collection('roles').get()
          .then(querySnapshot => {
            setRoles(querySnapshot.docs.map((doc) => {
              return {
                ...doc.data(),
                'docId': doc.id,
              }
            }))
            setRolesLoading(false);
          })
          .catch(e => {
            setRolesLoading(false);
            console.log(e.message);
          })
      }, []);

    return (
        <Grid className={classes.content}>
                <Grid className={classes.backButton}>
                    <Button variant="contained" startIcon={<ArrowBackIcon />}
                       onClick={() => props.history.goBack()} >Back</Button>
                </Grid>
            <form onSubmit={props.handleSubmit}>
                <Paper className={classes.paper}>
                    <Grid container spacing={2}>
                        <Grid item sm={12} className={classes.title}>
                            <PersonAddIcon style={{marginRight:'8px'}}/>
                            <Typography variant="h4" align="center">New Employee</Typography>
                        </Grid>
                        <Grid item sm={6}>
                            <Typography>First Name  <small style={{color:'red'}}>*(Required)</small></Typography>
                            <TextField required size="small" fullWidth variant='outlined' name="firstName" onChange={props.handleChange} value={props.userForm.firstName}/>
                        </Grid>
                        <Grid item sm={6}>
                            <Typography>Last Name  <small style={{color:'red'}}>*(Required)</small></Typography>
                            <TextField required size="small" fullWidth variant='outlined' name="lastName" onChange={props.handleChange} value={props.userForm.lastName}/>
                        </Grid>
                        <Grid item sm={6}>
                            <Typography>Email <small style={{color:'red'}}>*(Required)</small></Typography>
                            <TextField component="pre" required type='email' size="small" fullWidth variant='outlined' name="email" onChange={props.handleChange} value={props.userForm.email}/>
                            
                        </Grid>

                        <Grid item sm={6}>
                            <Typography>Role <small style={{color:'red'}}>*(Required)</small></Typography>
                            <Select fullWidth
                                        style={{height:'40px'}}
                                        required
                                        name="role"
                                        value={props.userForm.role}
                                        variant={"outlined"}
                                        onChange={props.handleChange}
                                        label="Role"
                                       
                                    >
                                        <MenuItem value='None'>
                                            <em>None</em>
                                        </MenuItem>    
                                    {!rolesLoading ?    
                                        roles.map((role, index) => {
                                            return <MenuItem key={index} value={role.title}>{role.title}</MenuItem>  
                                        })
                                    
                                    : null} 
                                    </Select>
                        </Grid>

                        <Grid item sm={12}>
                            <Typography>Department <small style={{color:'red'}}>*(Required)</small></Typography>
                            <FormControl fullWidth className={classes.formControl}>
                                                       
                                <Select
                                    native
                                    fullWidth
                                    required
                                    name="department"
                                    style={{height:'40px'}}
                                    variant={"outlined"}
                                    value={props.userForm.department}
                                    onChange={props.handleChange}
                                >
                                <option value="None">None</option>   
                                <option value={"Office"}>Office</option>
                                <option value={"Driver/Trucker"}>Driver/Trucker</option>
                                <option value={"Shop"}>Shop</option>
                                <option value={"Construction"}>Construction</option>
                                <option value={"Grain"}>Grain</option>
                                </Select>
                            </FormControl>
                        </Grid>

                        <Grid item sm={12}>
                            <Typography>Phone Number</Typography>
                            <TextField component="pre" type='phone' size="small" fullWidth variant='outlined' name="phone" onChange={props.handleChange} value={props.userForm.phone}/>
                        </Grid>

                        <Grid item sm={12}>
                            <small>All fields marked with * are required.</small>
                            <Divider/>
                        </Grid>
{/*
                        <Grid item sm={12}>
                            <Typography>Drivers License (Required for Drivers)</Typography>
                        </Grid>
                    
                        <Grid item sm={6}>
                            <Card className={classes.photoBack}>
                                <CardActionArea style={{display:'initial'}} onClick={()=>document.getElementById('upload-photo1').click()}>
                                    <CardContent className={classes.photoTile}>
                                        <input style={{display:'none'}} type="file" name="driversFront" id="upload-photo1" accept="image/*" onChange={props.handleFileUpload}/>
                                        {props.userForm.driversFront === ''
                                            ? <Typography>Front Image</Typography>
                                            : <img src={props.userForm.driversFront ? URL.createObjectURL(props.userForm.driversFront) : ''} alt="" className={classes.image}/>
                                        }
                                    </CardContent>
                                </CardActionArea>
                            </Card>
                        </Grid>
                    
                        <Grid item sm={6}>
                            <Card className={classes.photoBack}>
                                <CardActionArea style={{display:'initial'}} onClick={()=>document.getElementById('upload-photo2').click()}>
                                    <CardContent className={classes.photoTile}>
                                        <input style={{display:'none'}} type="file" name="driversBack" id="upload-photo2" accept="image/*" onChange={props.handleFileUpload}/>
                                        {props.userForm.driversBack === ''
                                            ? <Typography>Back Image</Typography>
                                            : <img alt="" src={props.userForm.driversBack ? URL.createObjectURL(props.userForm.driversBack) : ''} className={classes.image}/>
                                        }
                                        
                                    </CardContent>
                                </CardActionArea>
                            </Card>
                        </Grid>
                        
                        <Grid item sm={12}>
                            
                            <Divider/>
                        </Grid>*/}

                        <Grid item sm={12}>
                            <Typography type='url' align="center" style={{color:'red'}}>{props.error}</Typography>
                        </Grid>

                        <Grid item sm={12}>
                            <Button fullWidth variant="contained" size="large" disabled={props.submitting || props.error || props.success} 
                            style={props.success ? {color:'white', backgroundColor:'green'} : {}}
                            type="submit">{props.submitting ? "Submitting..." : props.success ? "Employee Successfully Created!" : "Create Employee"} </Button>
                        </Grid>
                    </Grid>
                </Paper>
            </form>
        </Grid>
    )
}

export default withRouter(UserForm);