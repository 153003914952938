import React, {useEffect, useState, useCallback} from 'react';
import { Divider, makeStyles, Dialog, DialogTitle, DialogContent, DialogActions, DialogContentText } from '@material-ui/core';
import { Checkbox, Snackbar, FormControlLabel, Grid, Paper, Typography, TextField, FormControl, Select, InputAdornment,
RadioGroup, FormLabel, Radio, Button, TableCell, TableHead, TableRow, Table, TableContainer, TableBody, IconButton } from '@material-ui/core';
import { withRouter } from 'react-router-dom';
import _ from 'lodash'
import { firestore } from '../../../../firebase/firebase'
import moment from 'moment';
import AddIcon from '@material-ui/icons/Add';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import EditIcon from '@material-ui/icons/Edit';

const useStyles = makeStyles((theme) => ({

    btnGroup: {
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
    },

    tabBar: {
        width: '100%',
        maxWidth: '1000px',
        
    },

    tabs:{
        [theme.breakpoints.up("md")]: {
          margin:'auto'
        }
        
    },

    alias: {
        display: 'flex',
        marginTop: '8px',
        marginBottom: '8px',
        alignItems: 'center',
    },

    card: {
        padding: theme.spacing(4),
        display: 'flex',
        alignItems: 'center',
        height: '100%',
    },

    content: {
        display: 'flex',
        justifyContent: 'center',
        width: '100%',
    },

    paper: {
        padding: '20px',
        borderRadius: '0px',
        margin: '10px',
        width: '100%',
        maxWidth: '1000px',
        //backgroundColor: 'rgb(252, 252, 249)',
    },

    row: {
        display: 'flex',
        alignItems: 'center',
    },
}));

const EmploymentTab = (props) => {
    const classes = useStyles();
    const [userData, setUserData] = useState(props.userData);
    const [error, setError] = useState("");
    const [saving, setSaving] = useState(false);
    const [saveState, setSaveState] = useState(props.saveState);

    const [open, setOpen] = React.useState(false);
    const [deleteOpen, setDeleteOpen] = React.useState(false);
    const [rateInfo, setRateInfo] = React.useState({});
    const [oldRate, setOldRate] = React.useState({});
    const [editing, setEditing] = React.useState(false);

    let path = new URLSearchParams(props.location.search).get("docId");

    const updateUserData = useCallback((e) => {
            setUserData(e)
        },
        [],
    );

    const handleClickOpen = () => { 
        setEditing(false);
        setRateInfo({name: '', rate: 0, per: '', typeofrate: ''})
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };


    const handleEditOpen = (rate) => {
        setRateInfo(rate)
        setOldRate(rate)
       
        setOpen(true);
        setEditing(true);

    };

    const handleDeleteOpen = (rate) => {

        setDeleteOpen(true);
        setRateInfo(rate)
    };

    const handleDeleteClose = () => {
        setDeleteOpen(false);
    };

    
    const handleDelete = (event) => {
        const oldRates = props.userData.rates
        const index = oldRates.indexOf(rateInfo)
        if (index > -1) {
            oldRates.splice(index, 1);
          }
        let newRates = oldRates
        props.updateUserData({ ...props.userData, rates: newRates })
        firestore.collection('users').doc(props.userData.docId)
            .update(props.userData)
        setRateInfo({name: '', rate: 0, per: '', typeofrate: ''})
    }

    const handleSubmit = (event) => {
        
        const oldRates = props.userData.rates
        let newRates = oldRates
        setRateInfo({ ...rateInfo, 'id': oldRates.length })
        newRates.push(rateInfo)
        props.updateUserData({ ...props.userData, rates: newRates })
        firestore.collection('users').doc(props.userData.docId)
            .update(props.userData)
        setRateInfo({name: '', rate: 0, per: '', typeofrate: ''})

    }

    const handleEdit = (event) => {        

        const oldRates = props.userData.rates  

        const index = oldRates.indexOf(oldRate)    
        if (index > -1) {
            oldRates.splice(index, 1);
          }
        let newRates = oldRates

        newRates.push(rateInfo)

        props.updateUserData({ ...props.userData, rates: newRates })

        firestore.collection('users').doc(props.userData.docId)
            .update(props.userData)
        setRateInfo({name: '', rate: 0, per: '', typeofrate: ''})
    }

    const confirmSave = () => {
        props.setUserData(userData);
        props.setSaveState(userData);
        setSaving(true);
        firestore.collection('users').doc(path)
            .update(userData)
            .then(() => {
                setSaveState(userData);
                setSaving(false)
            })
            .catch((e) => {setError(e); console.log(error);});

    };

    const cancelSave = () => {
        setUserData(saveState);
    };
    const [classifications, setClassifications] = React.useState(['Driver', 
    'Mechanic',
    'Office Staff', 
    'Loader Operator',
    'Grader Operator',
    'Subcontractor - Trucking', 
    'Subcontractor - Other', 
    'Office Manager',
    'Backhoe Operator', 
    'Labourer', 
    'Machine Operator', 
    'Dozer Operator', 
    'Drill Operator',
    'Crusher Operator', 
    'Crushing Crew', 
    'Trainee', 
    'Material Testing/Quality Control', 
    'Employee', 
    'Supplies',]);

    const [skills, setSkills] = React.useState([
        {name: 'Gravel', value: 'gravel'},
        {name: 'Salt', value: 'salt'},
        {name: 'Snow', value: 'snow'},
        {name: 'Asphalt', value: 'asphalt'},
        {name: 'Highway Driving Canada', value: 'highwayCAN'},
        {name: 'Highway Driving USA', value: 'highwayUSA'},
        {name: 'Shop Work', value: 'shopWork'},
        {name: 'Trains', value: 'trains'},
        {name: 'Tandem Trucks', value: 'tandemTrucks'},
        {name: 'Grader', value: 'grader'},
        {name: 'Front End Loader', value: 'frontEndLoader'},
        {name: 'Pickup and Delivery', value: 'pickupDelivery'},
    ])

    useEffect(() => {

    }, [setSkills, setClassifications]);


    return (
        <React.Fragment>
            <Grid container spacing={2}>
            <Dialog open={deleteOpen} onClose={handleClose}>
                <DialogTitle>Delete Rate</DialogTitle>
                <DialogContent>
                <Typography>Are you sure you want to delete rate: {rateInfo.name}?</Typography>
                    <DialogContentText>
                        This is NOT reversable!
                    </DialogContentText>
                    
                    <DialogActions>
                        <Button variant="contained" color="secondary" size="small" onClick={handleDeleteClose}>
                            Cancel
                        </Button>
                        <Button variant="contained" color="primary" size="small" onClick={handleDelete}>
                            Delete
                        </Button>
                    </DialogActions>
                </DialogContent>
            </Dialog>
            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>{!editing ? "Add" : "Edit"} Rate</DialogTitle>
                <DialogContent>
                <DialogContentText>
                {!editing ? "Please enter the details of the new rate." : "Edit the current rate."}
                </DialogContentText>
                <TextField
                    margin="dense"
                    id="name"
                    label="Name"
                    fullWidth
                    value={rateInfo.name}
                    error={rateInfo.name === ''}
                    helperText={rateInfo.name === '' ? "Rate requires a name": ''}
                    onChange={(event) => { setRateInfo({ ...rateInfo, 'name': event.target.value }) }}
                />
                <Grid item xs={12}>
                    <Typography style={{ fontSize: 12 }}>Type of Rate  </Typography>
                    <Select                                                                                             
                        onChange={(event) => { setRateInfo({ ...rateInfo, 'typeofrate': event.target.value }) }}
                        value={rateInfo.typeofrate}   
                        id="typeofrate"
                        size="small" fullWidth                         
                    >
                     <option value=""></option>
                     <option value="Payroll Rate">Payroll Rate</option>
                    </Select>
                </Grid>
                <Grid container spacing={2}> 
                    <Grid item xs={6}> 
                        <TextField
                            margin="dense"
                            id="rate"
                            label="payroll rate"
                            value={rateInfo.rate}
                            type="decimal"
                            onChange={(event) => { setRateInfo({ ...rateInfo, 'rate': event.target.value }) }}
                        />
                    </Grid>
                    <Grid item xs={6}> 
                        <TextField
                            margin="dense"
                            id="per"
                            label="Per"
                            value={rateInfo.per}
                            onChange={(event) => { setRateInfo({ ...rateInfo, 'per': event.target.value }) }}
                        />
                    </Grid>       
                </Grid>
                </DialogContent>
                <DialogActions>
                <Button variant="contained" color="secondary" size="small" onClick={handleClose}>
                    Cancel
                </Button>
                <Button disabled={rateInfo.name === ''} variant="contained" color="primary" size="small" onClick={!editing ? handleSubmit : handleEdit}>
                    Submit
                </Button>
                </DialogActions>
            </Dialog>


                <Grid container>
                    <Grid container item xs={6} >
                        <Paper className={classes.paper} >
                            <Grid container spacing={2}>
                                <Typography variant="h5">Employment</Typography>
                            </Grid>
                            <hr></hr>
                            <Grid container spacing={2}>
                                <Grid container>
                                    <Grid item xs={6} >
                                        <Typography style={{ lineHeight: 2 }} noWrap variant='body1'>Start Date:</Typography>
                                    </Grid>
                                    <Grid item xs={6}>
                                        {!props.edit ?
                                        <Typography style={{ lineHeight: 2 }} align='right' variant='body1'>{props.userData.employment && props.userData.employment.startDate ? moment(props.userData.employment.startDate).format('MMM D YYYY') : '01/01/1990'}</Typography>
                                        :
                                        <TextField
                                        style={{maxWidth:'150px'}}
                                        value={moment(userData.employment.startDate).format('YYYY-MM-DD')}
                                        size="small"
                                        type="date"
                                        onChange={(event) => { updateUserData({ ...userData, 'employment':{ ...userData.employment, 'startDate': event.target.value } }) }}
                                        />
                                        
                                        }
                                    </Grid>
                                    <Grid item xs={6} >
                                        <Typography style={{ lineHeight: 2 }} noWrap variant='body1'>End Date:</Typography>
                                    </Grid>
                                    <Grid item xs={6}>
                                    {!props.edit ?
                                        <Typography style={{ lineHeight: 2 }} align='right' variant='body1'>{props.userData.employment && props.userData.employment.endDate ? !props.userData.employment.active ? moment(props.userData.employment.endDate).format('MMM D YYYY') : "Still Employed" : '01/01/1990'}</Typography>
                                        :
                                        <TextField
                                        style={{maxWidth:'150px'}}
                                        value={moment(userData.employment.endDate).format('YYYY-MM-DD')}
                                        size="small"
                                        type="date"
                                        onChange={(event) => { updateUserData({ ...userData, 'employment':{ ...userData.employment, 'endDate': event.target.value } }) }}
                                        />
                                        
                                        }
                                    </Grid>
                                    <Grid item xs={12}>
                                        <FormControlLabel
                                            
                                            control={<Checkbox disabled={!props.edit} checked={userData.employment.active} name="active" 
                                            onChange={(event) => { updateUserData({ ...userData, 'employment': {...userData.employment, 'active': event.target.checked } }) }}/>}
                                            label="Active"
                                        />
                                    </Grid>
                                    <Grid item xs={6} >
                                        <Typography style={{ lineHeight: 2 }} noWrap variant='body1'>Type:</Typography>
                                    </Grid>
                                    <Grid item xs={6}>
                                        {!props.edit ?
                                        <Typography style={{ lineHeight: 2 }} align='right' variant='body1'>{props.userData.employment.type}</Typography>
                                        :
                                        <FormControl className={classes.formControl}>
                                                       
                                            <Select
                                                native
                                                value={userData.employment.type}
                                                onChange={(event) => { updateUserData({ ...userData, 'employment': {...userData.employment, 'type': event.target.value} }) }}
                                            >
                                            <option value={"Employee"}>Employee</option>
                                            <option value={"Subcontractor"}>Subcontractor</option>
                                            </Select>
                                        </FormControl>}
                                    </Grid>
                                    {userData.employment.type === 'Subcontractor' ?
                                    <React.Fragment>
                                    <Grid item xs={6} >
                                        <Typography style={{ lineHeight: 2 }} noWrap variant='body1'>Goods and Services Tax #:</Typography>
                                    </Grid>
                                    <Grid item xs={6}>
                                        {!props.edit ? 
                                        <Typography style={{ lineHeight: 2 }} align='right' variant='body1'>{props.userData.employment.gstNum}</Typography>
                                        : <TextField
                                        value={userData.employment.gstNum}
                                        placeholder="GST #"
                                        size="small"
                                        onChange={(event) => { updateUserData({ ...userData, 'employment': {...userData.employment, 'gstNum': event.target.value} }) }}
                                        />}
                                    </Grid>
                                    <Grid item xs={6} >
                                        <Typography style={{ lineHeight: 2 }} noWrap variant='body1'>Workers Compensation #:</Typography>
                                    </Grid>
                                    <Grid item xs={6}>
                                        {!props.edit ? 
                                        <Typography style={{ lineHeight: 2 }} align='right' variant='body1'>{props.userData.employment.wcbNum}</Typography>
                                        : <TextField
                                        value={userData.employment.wcbNum}
                                        placeholder="WCB #"
                                        size="small"
                                        onChange={(event) => { updateUserData({ ...userData, 'employment': {...userData.employment, 'wcbNum': event.target.value} }) }}
                                        />}
                                    </Grid>

                                    </React.Fragment>: null}
                                </Grid>
                            </Grid>
                        </Paper>
                    </Grid> 

                    <Grid container item xs={6} align={"flex-end"}  >
                        <Paper className={classes.paper}>
                            <Grid container spacing={2}>
                                <Typography variant="h5">Work Preferences</Typography>
                            </Grid>
                            <hr></hr>
                            <Grid container spacing={1}>
                                <Grid container>
                                    <Grid item xs={12} >
                                        <FormControlLabel
                                            style={{marginRight:'200px'}}
                                            control={<Checkbox disabled={!props.edit} checked={userData.preferences.days} name="days" 
                                            onChange={(event) => { updateUserData({ ...userData, 'preferences': {...userData.preferences, 'days': event.target.checked } }) }}/>}
                                            label="Days"
                                        />
                                        <FormControlLabel
                                            control={<Checkbox disabled={!props.edit} checked={userData.preferences.spareList} name="spareList" 
                                            onChange={(event) => { updateUserData({ ...userData, 'preferences': {...userData.preferences, 'spareList': event.target.checked } }) }}/>}
                                            label="On Spare List"
                                        />
                                    </Grid>
                                    <Grid item xs={12} >
                                        <FormControlLabel
                                            style={{marginRight:'187px'}}
                                            control={<Checkbox disabled={!props.edit} checked={userData.preferences.nights} name="nights" 
                                            onChange={(event) => { updateUserData({ ...userData, 'preferences': {...userData.preferences, 'nights': event.target.checked } }) }}/>}
                                            label="Nights"
                                        />
                                        <FormControlLabel
                                            control={<Checkbox disabled={!props.edit} checked={userData.preferences.seasonal} name="seasonal" 
                                            onChange={(event) => { updateUserData({ ...userData, 'preferences': {...userData.preferences, 'seasonal': event.target.checked } }) }}/>}
                                            label="Seasonal"
                                        />
                                    </Grid>
                                    <Grid item xs={12} >
                                        <FormControlLabel
                                            style={{marginRight:'161px'}}
                                            control={<Checkbox disabled={!props.edit} checked={userData.preferences.weekends} name="weekends" 
                                            onChange={(event) => { updateUserData({ ...userData, 'preferences': {...userData.preferences, 'weekends': event.target.checked } }) }}/>}
                                            label="Weekends"
                                        />
                                    </Grid>
                                    <Divider style={{margin:'15px'}}></Divider>
                                    <Grid item xs={12} >
                                         
                                        <FormControl component="fieldset">
                                            <FormLabel component="legend"><small>Full Time or Part Time</small></FormLabel>
                                            <RadioGroup aria-label="gender" name="gender1" value={userData.preferences.fullPart} onChange=
                                            {(event) => { updateUserData({ ...userData, 'preferences': {...userData.preferences, 'fullPart': event.target.value } }) }}>
                                                <FormControlLabel value="Full Time" control={<Radio disabled={!props.edit} />} label="Full Time" />
                                                <FormControlLabel value="Part Time" control={<Radio disabled={!props.edit} />} label="Part Time" />
                                            </RadioGroup>
                                        </FormControl>
                                    </Grid>
                                   
                                </Grid>
                            
                            </Grid>
                        </Paper>
                    </Grid> 

                    {props.permissions.includes('View User Employment Info') &&
                        <Grid container item xs={6} >
                            <Paper className={classes.paper} style={{height: '100%',}}>
                                <Grid container spacing={2}>
                                    <Typography variant="h5">Payroll Information</Typography>
                                </Grid>
                                <hr></hr>
                                <Grid container spacing={2}>
                                    <Grid container>
                                        <Grid item xs={6} >
                                            <Typography style={{ lineHeight: 2 }} noWrap variant='body1'>Classification:</Typography>
                                        </Grid>
                                        <Grid item xs={6}>
                                            {!props.edit ?
                                            <Typography style={{ lineHeight: 2 }} align='right' variant='body1'>{props.userData.payroll.class}</Typography>
                                            :<FormControl className={classes.formControl}>
                                                        
                                                <Select
                                                    native
                                                    value={userData.payroll.class}
                                                    onChange={(event) => { updateUserData({ ...userData, 'payroll': {...userData.payroll, 'class': event.target.value} }) }}
                                                >
                                                {classifications.map((classification, index) => {
                                                    return <option key={index} value={classification}>{classification}</option>
                                                }) }
                                                </Select>
                                            </FormControl>}
                                        </Grid>
                                        <Grid item xs={6} >
                                            <Typography style={{ lineHeight: 2 }} noWrap variant='body1'>Payroll Rate:</Typography>
                                        </Grid>
                                        <Grid item xs={6}>
                                            {!props.edit ?
                                            <Typography style={{ lineHeight: 2 }} align='right' variant='body1'>${props.userData.payroll.payRate}</Typography>
                                            : <TextField
                                            style={{maxWidth:'150px'}}
                                            value={userData.payroll.payRate}
                                            size="small"
                                            InputProps={{
                                                startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                            }}
                                            onChange={(event) => { updateUserData({ ...userData, 'payroll':{ ...userData.payroll, 'payRate': event.target.value } }) }}
                                            />}
                                        </Grid>
                                        <Grid item xs={6} >
                                            <Typography style={{ lineHeight: 2 }} noWrap variant='body1'>Vacation Pay Rate <small>(%)</small>:</Typography>
                                        </Grid>
                                        <Grid item xs={6}>
                                            {!props.edit ?
                                            <Typography style={{ lineHeight: 2 }} align='right' variant='body1'>{props.userData.payroll.vacationRate}%</Typography>
                                            : <TextField
                                            style={{maxWidth:'150px'}}
                                            value={userData.payroll.vacationRate}
                                            size="small"
                                            InputProps={{
                                                endAdornment: <InputAdornment position="start">%</InputAdornment>,
                                            }}
                                            onChange={(event) => { updateUserData({ ...userData, 'payroll':{ ...userData.payroll, 'vacationRate': event.target.value } }) }}
                                            />}
                                        </Grid>
                                        <Grid item xs={6} >
                                            <Typography style={{ lineHeight: 2 }} noWrap variant='body1'>Payroll Frequency:</Typography>
                                        </Grid>
                                        <Grid item xs={6}>
                                            {!props.edit ?
                                            <Typography style={{ lineHeight: 2 }} align='right' variant='body1'>{props.userData.payroll.payFrequency}</Typography>
                                            :<FormControl className={classes.formControl}>
                                                        
                                            <Select
                                                native
                                                value={userData.payroll.payFrequency}
                                                onChange={(event) => { updateUserData({ ...userData, 'payroll': {...userData.payroll, 'payFrequency': event.target.value} }) }}
                                            >
                                            <option value={"Weekly"}>Weekly</option>
                                            <option value={"Bi-Weekly"}>Bi-Weekly</option>
                                            <option value={"Monthly"}>Monthly</option>
                                            <option value={"Semi-Monthly"}>Semi-Monthly</option>
                                            </Select>
                                        </FormControl>}
                                        </Grid>
                                        <Grid item xs={12}>
                                        <FormControlLabel
                                            
                                            control={<Checkbox disabled={!props.edit} checked={userData.payroll.getsPremiumRate} name="getsPremiumRate" 
                                            onChange={(event) => { updateUserData({ ...userData, 'payroll': {...userData.payroll, 'getsPremiumRate': event.target.checked } }) }}/>}
                                            label="Gets Premium Trailer Rate"
                                        />
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid container spacing={2} style={{marginTop:'20px'}}>
                                    <Typography variant="h5">Benefits</Typography>
                                </Grid>
                                <hr></hr>
                                <Grid container spacing={2}>
                                    <Grid container>
                                        <Grid item xs={6} >
                                            <Typography style={{ lineHeight: 2 }} noWrap variant='body1'>Insurance:</Typography>
                                        </Grid>
                                        <Grid item xs={6}>
                                            {!props.edit ?
                                            <Typography style={{ lineHeight: 2 }} align='right' variant='body1'>{props.userData.benefits.insurance}</Typography>
                                            :<FormControl className={classes.formControl}>
                                                        
                                            <Select
                                                native
                                                value={userData.benefits.insurance}
                                                onChange={(event) => { updateUserData({ ...userData, 'benefits': {...userData.benefits, 'insurance': event.target.value} }) }}
                                            >
                                            <option value={"Pending"}>Pending</option>
                                            <option value={"Waived"}>Waived</option>
                                            <option value={"Accepted"}>Accepted</option>
                                            <option value={"Canceled"}>Canceled</option>
                                            </Select>
                                        </FormControl>}
                                        </Grid>
                                        <Grid item xs={6} >
                                            <Typography style={{ lineHeight: 2 }} noWrap variant='body1'>Effective Date:</Typography>
                                        </Grid>
                                        <Grid item xs={6}>
                                            {!props.edit ?
                                            <Typography style={{ lineHeight: 2 }} align='right' variant='body1'>{props.userData.benefits && props.userData.benefits.effectiveDate ? moment(props.userData.benefits.effectiveDate).format('MMM D YYYY') : ''}</Typography>
                                            : <TextField
                                            style={{maxWidth:'150px'}}
                                            value={userData.benefits.effectiveDate ? moment(userData.benefits.effectiveDate).format('YYYY-MM-DD') : ''}
                                            size="small"
                                            type="date"
                                            onChange={(event) => { updateUserData({ ...userData, 'benefits':{ ...userData.benefits, 'effectiveDate': event.target.value } }) }}
                                            />}
                                        </Grid>
                                        <Grid item xs={6} >
                                            <Typography style={{ lineHeight: 2 }} noWrap variant='body1'>Premium:</Typography>
                                        </Grid>
                                        <Grid item xs={6}>
                                            {!props.edit ?
                                            <Typography style={{ lineHeight: 2 }} align='right' variant='body1'>${props.userData.benefits.premium}</Typography>
                                            : <TextField
                                            style={{maxWidth:'150px'}}
                                            value={userData.benefits.premium}
                                            size="small"
                                            InputProps={{
                                                startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                            }}
                                            onChange={(event) => { updateUserData({ ...userData, 'benefits':{ ...userData.benefits, 'premium': event.target.value } }) }}
                                            />}
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Paper>
                        </Grid> 
                    }

                    <Grid container item xs={6} align={"flex-end"}>
                        <Paper className={classes.paper}>
                            <Grid container spacing={2}>
                                <Typography variant="h5">Work Skills</Typography>
                            </Grid>
                            <hr></hr>
                            <Grid container spacing={1}>
                                <Grid container>
                                    {skills.map((skill, index) => {
                                        return (
                                        <Grid item xs={6} key={index}>
                                            <FormControlLabel
                                                control={<Checkbox disabled={!props.edit} checked={userData.skills[skill.value]} name={skill.name}
                                                onChange={(event) => { updateUserData({ ...userData, 'skills': {...userData.skills, [skill.value]: event.target.checked } }) }}/>}
                                                label={skill.name}
                                            />
                                        </Grid>   )
                                    })}                         
                                </Grid>
                            </Grid>
                        </Paper>
                    </Grid> 
                </Grid> 

                {props.permissions.includes('View User Employment Info') &&
                    <Grid container item xs={12} style={{marginTop:'20px'}}>
                            <Paper className={classes.paper} style={{height: '100%',}}>
                                <Grid container spacing={2}>
                                    <Typography variant="h5">Payroll Rate</Typography>
                                </Grid>
                                <Grid container spacing={2} style={{marginTop:'30px'}}>
                                        <TableContainer component={Paper}>
                                    <Table className={classes.table} aria-label="simple table" size="small">
                                        <TableHead>
                                        <TableRow>
                                            <TableCell>Name of Rate</TableCell>
                                            <TableCell>Type of Rate</TableCell>
                                            <TableCell align="right">Pay Rate</TableCell>
                                            <TableCell align="right">Per</TableCell>
                                            {props.edit ? <React.Fragment><TableCell align="center">Delete</TableCell><TableCell align="center">Edit</TableCell></React.Fragment>
                                            : null}
                                        </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {props.userData.rates.map((rate, index) => (
                                                <TableRow key={index}>
                                                    <TableCell component="th" scope="row">
                                                        {rate.name}
                                                    </TableCell>
                                                    <TableCell >{rate.typeofrate}</TableCell>
                                                    <TableCell align="right">${rate.rate}</TableCell>
                                                    <TableCell align="right">{rate.per}</TableCell>
                                                    {props.edit ?<React.Fragment>
                                                    <TableCell align="center"><IconButton onClick={() => handleDeleteOpen(rate)} color="secondary"><DeleteForeverIcon/></IconButton></TableCell>
                                                    <TableCell align="center"><IconButton onClick={() => handleEditOpen(rate)} color="secondary"><EditIcon/></IconButton></TableCell></React.Fragment>  : null}
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                                {props.edit ?
                                <Grid item xs={6} style={{margin:'16px'}}>
                                    <Button disabled={!props.edit} variant="contained" onClick={handleClickOpen}  startIcon={<AddIcon/>}>Add Payroll Rate</Button>
                                </Grid> : null}         
                                        </Grid>
                            </Paper>
                    </Grid>                
                }

                <Snackbar
                            open={!_.isEqual(userData, saveState)}
                            message={saving ? 'Saving...' : 'Save Document?'}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'center'
                            }}
                            action={
                                <React.Fragment>
                                    {saving
                                        ? null
                                        : 
                                        <React.Fragment>
                                            <Button variant='text' color='primary' onClick={cancelSave} style={{ marginLeft: '32px', marginRight: '8px' }}>Cancel</Button>
                                            <Button variant='contained' onClick={confirmSave}>Confirm</Button>
                                        </React.Fragment>}
                                </React.Fragment>
                            } />
            </Grid>
        </React.Fragment>
    )
}

export default withRouter(EmploymentTab)