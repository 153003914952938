import React, { useEffect, useState } from 'react';
import { firestore } from '../../../../firebase/firebase'
import { withRouter } from 'react-router-dom';
import CustomerPDF from '../../../components/Reports/Customers/CustomerPDF'
import _ from 'lodash'
import { Skeleton } from '@material-ui/lab';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    content: {
        display: 'flex',
        width: '100%',
        padding: '32px',
        justifyContent: 'center',
        marginTop: '105px',
    },
}));

function CustomerPrint(props) {
    const [customerData, setCustomer] = useState({});
    const [loading, setLoading] = useState([]);
    const [errorMessage, setErrorMessage] = useState("");

    const classes = useStyles();

    useEffect(() => {
        setLoading(true);
   
        //let order = {};

        let path = new URLSearchParams(props.location.search).get("docId");

        firestore.collection('customers').where('__name__', "==", path).get()
            .then(querySnapshot => {
                //process & store timesheet data
                const customerResponse = querySnapshot.docs.map((doc) => {
                    return {
                        ...doc.data(),
                        'docId': doc.id,
                    }
                })

                if (_.isEmpty(customerResponse)) return;

                
                setCustomer(customerResponse[0]);
                setLoading(false);
            })
            .catch(e => {
                setErrorMessage("Unable to Process");
                setLoading(false)
            });
    }, [props.location.search]);

    return (
        loading
            ? 
            <div className={classes.content}>
                <Skeleton variant='rect' width={'63vw'} height={'80vh'} />
            </div>
            : 
            <CustomerPDF
                customerData={customerData}
                errorMessage={errorMessage} />
    )
}

export default withRouter(CustomerPrint)