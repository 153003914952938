import React, {useState, useEffect} from 'react';
import { withRouter } from 'react-router-dom';
import {Divider, Checkbox, TextField, Paper, Grid, Collapse, FormControlLabel, Typography} from '@material-ui/core'
import DatePicker, { DateTimePicker } from '../../UI/DatePicker/DatePicker';
import { generateCardSelect, generateCardInputs } from '../../../utility/utility'
import { makeStyles } from '@material-ui/core';
import moment from 'moment'
import _ from 'lodash'

const useStyles = makeStyles((theme) => ({
    card: {
        padding: theme.spacing(4),
        width: '100%',
    },
}));

const TimesheetInputs = (props) => {
    const classes = useStyles()
    const [usingGateInTime, setUsingGateInTime] = useState(props.usingGateInTime)
    const [usingGateOutTime, setUsingGateOutTime] = useState(props.usingGateOutTime)
    const [error, setError] = useState('')

    let date = props.date < 9999999999 ? moment.utc(props.date * 1000) : moment.utc(props.date)
    let inTime = props.inTime < 9999999999 ? moment(props.inTime * 1000) : moment(props.inTime)
    let outTime = props.inTime < 9999999999 ? moment(props.outTime * 1000) : moment(props.outTime)
    let gateInTime = props.gateInTime < 9999999999 ? moment(props.gateInTime * 1000) : moment(props.gateInTime)
    let gateOutTime = props.gateOutTime < 9999999999 ? moment(props.gateOutTime * 1000) : moment(props.gateOutTime)

    let newInTime = moment(inTime).subtract(15, 'minutes').valueOf()
    let newOutTime = moment(outTime).add(15, 'minutes').valueOf()

    // if gateInTime is after gateOutTime
    useEffect(() => {
        if (gateInTime > gateOutTime) {
            setError('Gate In Time should be before Gate Out Time')
        } else {
            setError('')
        }
    }, [gateInTime, gateOutTime])

    return (
        <Paper className={classes.card}>
            <Grid style={{ alignItems: 'center' }} container spacing={2}>
                {generateCardInputs(
                    'Date',
                    <DatePicker
                        value={date}
                        onChange={(date) => props.updateTimesheetField({ 'date': date.valueOf() })}
                        fullWidth
                    />,
                    props.timesheetLoading
                )}
                {generateCardInputs(
                    'In Time',
                    <DateTimePicker
                        value={props.inTime > 0 ? inTime : null}
                        onChange={(date) => props.updateTimesheetField({ 'inTime': date.valueOf() })}
                    />,
                    props.timesheetLoading
                )}
                {generateCardInputs(
                    'Out Time',
                    <DateTimePicker
                        value={props.outTime > 0 ? outTime : null}
                        onChange={(date) => props.updateTimesheetField({ 'outTime': date.valueOf() })}
                    />,
                    props.timesheetLoading
                )}
                <Grid item xs={12}>
                {generateCardInputs(
                    'Off Hours',
                    <TextField
                        size='small'
                        variant='standard'
                        fullWidth
                        value={
                            props.offHours}
                        onChange={(event) =>
                            props.updateTimesheetField({ 'offHours': event.target.value })
                        }
                    />,
                    props.timesheetLoading
                )}
                </Grid>
                <Grid item xs={6} >
                    <FormControlLabel
                        control={<Checkbox checked={_.isUndefined(props.timesheet.usingGateInTime) ? false : props.timesheet.usingGateInTime} name="usingGateInTime" 
                        onChange={(event) => { 

                            props.updateTimesheetField({ 
                                'usingGateInTime': event.target.checked, 
                                'gateInTime': props.timesheet.gateInTime ? props.timesheet.gateInTime : props.inTime, 
                                //'gateOutTime': props.timesheet.gateOutTime ? props.timesheet.gateOutTime : props.outTime,
                                'gateIn15': newInTime,
                                //'gateOut15': newOutTime
                            
                            });
                             
                            setUsingGateInTime(event.target.checked)
                         }}/>}
                        label="Use Gate In Time"
                    />
                </Grid>
                <Grid item xs={6} >
                    <FormControlLabel
                        control={<Checkbox checked={_.isUndefined(props.timesheet.usingGateOutTime) ? false : props.timesheet.usingGateOutTime} name="usingGateOutTime" 
                        onChange={(event) => { 

                            props.updateTimesheetField({ 
                                'usingGateOutTime': event.target.checked, 
                                //'gateInTime': props.timesheet.gateInTime ? props.timesheet.gateInTime : props.inTime, 
                                'gateOutTime': props.timesheet.gateOutTime ? props.timesheet.gateOutTime : props.outTime,
                                //'gateIn15': newInTime,
                                'gateOut15': newOutTime
                            
                            });
                             
                            setUsingGateOutTime(event.target.checked)
                         }}/>}
                        label="Use Gate Out Time"
                    />
                </Grid>
                <Grid item sm={12}>
                    <Divider />
                </Grid>
                <Grid item xs={12}>
                <Collapse in={usingGateInTime || usingGateOutTime} timeout="auto">
                    {generateCardInputs(
                        'Gate In Time',
                        <DateTimePicker
                            disabled={!usingGateInTime}
                            value={props.gateInTime > 0 ? gateInTime : null}
                            onChange={(date) => {
                                props.updateTimesheetField({ 'gateInTime': date.valueOf(), 'gateIn15': moment(date).subtract(15, 'minutes').valueOf()});

                            }
                        }
                        />,
                        props.timesheetLoading
                    )}
                    {generateCardInputs(
                        'Gate Out Time',
                        <DateTimePicker
                            disabled={!usingGateOutTime}
                            value={props.gateOutTime > 0 ? gateOutTime : null}
                            onChange={(date) => date!== null &&props.updateTimesheetField({ 'gateOutTime': date.valueOf(), 'gateOut15': moment(date).add(15, 'minutes').valueOf()})}
                        />,
                        props.timesheetLoading
                    )}
                    <Grid item sm={12}>
                        <Typography variant='caption' color='error'>{error}</Typography>
                    </Grid>
                    <Grid item sm={12}>
                        <Divider />
                    </Grid>
                </Collapse>
                </Grid>


                <Grid item sm={12}>
                    <Divider />
                </Grid>
                {generateCardSelect('Skipped Lunch', props.skipLunch, props.skippedLunchOptions, props.updateLunch, props.timesheetLoading)}
                {generateCardSelect('Timesheet Status', props.timesheetStatus, props.timesheetStatuses, props.updateStatus, props.timesheetLoading)}
                <Grid item sm={12}>
                    <Divider />
                </Grid>
            </Grid>
        </Paper>
    )
}
export default withRouter(TimesheetInputs)