import React, { useState } from 'react';
import moment from 'moment'
import { Grid, Modal, Paper, IconButton, makeStyles, TextField, Divider, Dialog, DialogTitle, DialogContent } from '@material-ui/core';
import { TableIcons } from '../../../../constants/tableIcons';
import Typography from '@material-ui/core/Typography';
import MaterialTable from 'material-table';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import DeleteIcon from '@material-ui/icons/Delete';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import CancelIcon from '@material-ui/icons/Cancel';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import EditIcon from '@material-ui/icons/Edit';
import JobModal from './Modals/JobModal';
import _ from 'lodash';

const useStyles = makeStyles((theme) => ({
    modal: {
        width: '500px',
        padding: '16px',
        margin: '0 auto',
        marginTop: '50vh',
    },
}));

const JobTable = (props) => {

    const classes = useStyles();

    const [open, setOpen] = useState(false);
    //const [customers] = useState(props.customers)
    const [modalEditData, setModalNoteData] = useState({note: '', jobIndex: '', noteIndex: '', useCase: ''})
    const [jobModal, setJobModal] = useState(false);
    const [jobModalOpenInEdit, setJobModalOpenInEdit] = useState(false);
    // const [tempUnitData, setTempUnitData] = useState();

    // useEffect(() => {
    //     setTempUnitData(props.unitData)
    // }, [props.unitData])

    const closeNoteModal = () => {
        setModalNoteData({note: '', jobIndex: '', noteIndex: '', useCase: ''})
        setOpen(false)
    }

    const openJobModal = (docId, openInEdit) => {
        if(openInEdit){
            setJobModalOpenInEdit(true)
            let jobContainer = _.cloneDeep(props.jobData.filter(job => job.docId === docId)[0]);
            props.setCurrentJob({...jobContainer});
            props.setJobCustomerAutocomplete(jobContainer.customer.toString())
            props.setCurrentCustomer({})
            props.setNewCustomer(false)
        }
        else{
            setJobModalOpenInEdit(false)
            props.setCurrentJob({});
            props.setJobCustomerAutocomplete("")
            props.setCurrentCustomer({})
            props.setNewCustomer(false)
        }
        setJobModal(true);
    }

    const closeJobModal = () => {
        setJobModal(false)
    }

    return (
        <Grid>

            {/* note modal */}
            <Modal
                open={open}
                onClose={() => closeNoteModal()}>
                <Paper className={classes.modal}>
                    <Grid style={{ padding: '16px'}}>
                        <Grid container>
                            <Grid item xs={9}>
                                <Typography style={{ padding: '16px'}}><b>{modalEditData.useCase === 'create' ? 'Create Note:' : modalEditData.useCase === 'edit' ? 'Edit Note:' : 'Delete Note?'} </b></Typography>
                            </Grid>
                            <Grid item xs={3}>
                                <IconButton onClick={() => {if(modalEditData.useCase === 'create'){ 
                                                                props.handleAdminNotes('job', modalEditData.note, modalEditData.jobIndex, 0, modalEditData.useCase)
                                                                closeNoteModal();
                                                            }
                                                            else if(modalEditData.useCase === 'edit'){
                                                                props.handleAdminNotes('job', modalEditData.note, modalEditData.jobIndex, modalEditData.noteIndex, modalEditData.useCase) 
                                                                closeNoteModal();
                                                            }
                                                            else if(modalEditData.useCase === 'delete'){
                                                                props.handleAdminNotes('job', modalEditData.note, modalEditData.jobIndex, modalEditData.noteIndex, modalEditData.useCase) 
                                                                closeNoteModal();
                                                            }  
                                                            }} ><CheckCircleIcon></CheckCircleIcon></IconButton>
                                <IconButton onClick={() => closeNoteModal()}><CancelIcon></CancelIcon></IconButton>
                            </Grid>
                        </Grid>

                        <Grid container>
                            <Grid item xs={12}>
                                {modalEditData.useCase === 'create' || modalEditData.useCase === 'edit'
                                ? <TextField fullWidth multiline={true} defaultValue={modalEditData.note} onChange={(event) => setModalNoteData({...modalEditData, note: event.target.value}) }></TextField>
                                : <Typography>{modalEditData.note}</Typography>
                                }
                            </Grid>
                        </Grid>
                    </Grid>
                </Paper>
            </Modal>

            {/* Job Edit Modal */}
            <Dialog 
                fullWidth
                keepMounted
                open={jobModal}
                onClose={closeJobModal}
            >
                <DialogTitle id="alert-dialog-title">{jobModalOpenInEdit ? "Edit" : "Create"} Job</DialogTitle>

                <DialogContent >
                    <JobModal
                        jobData={props.jobData}
                        currentJob={props.currentJob}
                        closeJobModal={closeJobModal}
                        updateJob={props.updateJob}
                        createJob={props.createJob}
                        updateUnit={props.updateUnit}
                        setCurrentJob={props.setCurrentJob}
                        customers={props.customers}
                        unitData={props.unitData}
                        currentCustomer={props.currentCustomer}
                        setCurrentCustomer={props.setCurrentCustomer}
                        newCustomer={props.newCustomer}
                        jobModalOpenInEdit={jobModalOpenInEdit}
                        createCustomer={props.createCustomer}
                        setNewCustomer={props.setNewCustomer}
                        jobCustomerAutocomplete={props.jobCustomerAutocomplete}
                        setJobCustomerAutocomplete={props.setJobCustomerAutocomplete}
                        batchUpdateUnits={props.batchUpdateUnits}
                    />
                </DialogContent>
                
                {/* <DialogActions>
                    <Button 
                        variant="contained" 
                        onClick={() => { 
                            let jobContainer = {};
                            if(props.newCustomer){
                                jobContainer = {...props.currentJob, customer: props.currentCustomer.name, 
                                                                    address: (props.currentCustomer.streetNumber && props.currentCustomer.streetName) ? 
                                                                    props.currentCustomer.streetNumber + ' ' + props.currentCustomer.streetName : ''
                                 };
                                 props.createCustomer(props.currentCustomer); 
                            }
                            else{
                                jobContainer = props.currentJob;     
                            }

                            props.createJob({...jobContainer}); 
                            closeJobModal();
                        }}
                    >
                        Save
                    </Button> 
                    <Button 
                        variant="contained" 
                        color="secondary" 
                        onClick={() => closeJobModal()}
                    >
                        Cancel
                    </Button>
                </DialogActions> */}
            </Dialog>

            <Grid item sm={12} xs={12}>
                <MaterialTable
                    columns={[
                        { title: 'Customer', field: 'customer',},
                        { title: 'Job Number', field: 'jobNumber', },
                        { title: 'Address', field: 'address',},
                        {
                            title: 'Time In',
                            field: 'timeIn',
                            render: rowData => {
                                let timeIn = rowData.timeIn < 9999999999 ? moment(rowData.timeIn * 1000) : moment(rowData.timeIn)
                                return (< div style={{ width: 180 }}>{timeIn.format('YYYY-MM-DD h:mm a')}</div>)
                            },
                        },
                        {
                            title: 'Time Out',
                            field: 'timeOut',
                            render: (rowData) => {
                                let timeOut = rowData.timeOut < 9999999999 ? moment(rowData.timeOut * 1000) : moment(rowData.timeOut)
                                return (< div style={{ width: 180 }}>{timeOut.format('YYYY-MM-DD h:mm a')}</div>)
                            },
                        },
                        {
                            title: 'Job Time', render: (rowData) => {
                                return ((rowData.timeOut - rowData.timeIn) / 3600000).toFixed(2)
                            }
                        },
                        {
                            title: 'Expanded Time', render: rowData => {
                                return (props.submitted ? Number(rowData.expandedTime ? rowData.expandedTime : props.expandedTimePerJob).toFixed(2) : 0.00)
                            }
                        },
                        {
                            title: 'Total Job Time', render: (rowData) => {
                                if (rowData.timeIn < 9999999999) rowData.timeIn *= 1000
                                if (rowData.timeOut < 9999999999) rowData.timeOut *= 1000
                                return props.submitted ? (((rowData.timeOut - rowData.timeIn) / 3600000) + Number(rowData.expandedTime ? rowData.expandedTime : props.expandedTimePerJob)).toFixed(2) : ''
                            }
                        },
                        { title: 'Units', field: 'units', editable: 'never', render: rowData => //split rowData.unitNums then display each in a typography
                            props.unitData.filter(unit => rowData.units.includes(unit.docId)).map((unit, index) => {
                                return (
                                <React.Fragment>
                                    <small key={index}>
                                        <b>{unit.unitNumber}</b>: {unit.jobTimes && unit.jobTimes.filter((time)=> time.jobId === rowData.docId).length !==0 
                                        ? 
                                        Number(unit.jobTimes.filter((time)=> time.jobId === rowData.docId)[0].hours).toFixed(2) 
                                        : 
                                        ((rowData.timeOut - rowData.timeIn) / 3600000).toFixed(2)}
                                    </small><br/>
                                </React.Fragment>)
                            })
                            },
                        { title: 'Admin Time', field: 'adminTime', editable: 'never', render: rowData => 
                        <React.Fragment>
                        <Typography>{rowData.adminTime ? rowData.adminTime : 0.00}</Typography>
                        <Divider/>
                        {props.unitData.filter(unit => rowData.units.includes(unit.docId)).map((unit, index) => {
                            return (
                                unit.jobTimes && unit.jobTimes.filter((time)=> time.jobId === rowData.docId)[0]?.adminHours &&
                            <React.Fragment>
                                <small key={index}>
                                    <b>{unit.unitNumber}</b>: {unit.jobTimes && unit.jobTimes.filter((time)=> time.jobId === rowData.docId).length !==0 
                                    ? 
                                    unit.jobTimes.filter((time)=> time.jobId === rowData.docId)[0]?.adminHours ? Number(unit.jobTimes.filter((time)=> time.jobId === rowData.docId)[0]?.adminHours).toFixed(2) : null 
                                    : 
                                    null}
                                </small><br/>
                            </React.Fragment>)
                        })}
                        </React.Fragment>
                    
                        },
                        
                    ]}
                    data={props.jobData}
                    title={"Job Details"}
                    isLoading={props.jobsLoading}
                    icons={TableIcons}
                    detailPanel={[
                        rowData => ({
                            tooltip: 'Show Details',
                            icon: () => <ChevronRightIcon />,
                            render: () =>
                                <Grid>
                                <Grid style={{backgroundColor: '#FFBF00', color: 'black', }}>
                                    <Grid container >
                                        <Grid item xs={12}>
                                            <IconButton variant={'contained'} style={{ padding: '16px',}} onClick={(event) => {setModalNoteData({...modalEditData, jobIndex: rowData.tableData.id, useCase: 'create'}); setOpen(true)}} ><AddCircleIcon /></IconButton>
                                            <Typography display="inline" style={{ padding: '16px'}}><b>Admin Notes: </b></Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Divider></Divider>
                                        </Grid>
                                    </Grid>
                                     {rowData.adminNotes?.map((note, index) => {
                                         return(
                                            <Grid container key={index}>
                                                
                                                <Grid item xs={11}>
                                                    <Typography style={{ padding: '16px'}}>{note}</Typography>
                                                </Grid>
                                                <Grid item xs={1}>
                                                    <IconButton onClick={(event) => {setModalNoteData({note: note, jobIndex: rowData.tableData.id, noteIndex: index, useCase: 'edit'}); setOpen(true); }}><EditIcon></EditIcon></IconButton>
                                                    <IconButton onClick={(event) => {setModalNoteData({note: note, jobIndex: rowData.tableData.id, noteIndex: index, useCase: 'delete'}); setOpen(true); }}><DeleteIcon></DeleteIcon></IconButton>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <Divider></Divider>
                                                </Grid>
                                            </Grid>
                                         )
                                     })}
                                    

                                </Grid>
                                <Grid>
                                    <Grid container>
                                        <Grid item xs={1}>
                                            <Typography style={{ padding: '16px'}}><b>Job Notes: </b></Typography>
                                        </Grid>
                                        <Grid item xs={11}>
                                            <Typography style={{ padding: '16px'}}>{rowData.notes}</Typography>
                                        </Grid>

                                    </Grid>
                                </Grid>
                             </Grid>
                        })
                    ]}
                    options={
                    {
                        toolbar: true,
                        paging: true,
                        pageSize: 10,
                        pageSizeOptions: [],
                        actionsColumnIndex: -1,
                        search: false,
                        emptyRowsWhenPaging: false,
                        thirdSortClick: false,
                        rowStyle: {
                            whiteSpace: 'pre-wrap',
                        },
                    }}
                    editable={{
                        //onRowAdd: (newData) => props.createJob(newData),
                        //onRowUpdate: (newData, oldData) => props.updateJob(newData, oldData),
                        onRowDelete: (oldData) => props.deleteJob(oldData),
                    }}
                    actions={[
                        {
                            icon: TableIcons.OpenInNew,
                            tooltip: 'Show Job',
                            onClick: (event, rowData) => props.history.push({
                                pathname: '/dashboard/reporting/timesheets/job',
                                search: "?" + new URLSearchParams({ docId: rowData.docId }).toString(),
                                state: { jobList: props.jobData }
                            })
                        },
                        {
                            icon: TableIcons.Edit,
                            tooltip: 'Edit Job',
                            onClick: (event, rowData) => {
                                openJobModal(rowData.docId, true)
                            }
                        },
                        {
                            icon: TableIcons.Add,
                            tooltip: 'Add Job',
                            isFreeAction: true,
                            onClick: (event, rowData) => {
                                openJobModal(rowData.docId, false)
                            }
                        },
                    ]}
                />
            </Grid>

        </Grid>
    )
}
export default JobTable