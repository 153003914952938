import React from 'react';
import { Button, makeStyles } from '@material-ui/core';
import { PDFViewer, Font, Page, Text, View, Document, StyleSheet } from '@react-pdf/renderer';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { withRouter } from 'react-router-dom';
import moment from 'moment'

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        justifyContent: 'center',
        width: '100%',
    },
    content: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        maxWidth: '1200px',
        margin: 'auto',
    },
    buttonRow: {
        marginBottom: '20px',
        marginTop: '20px',
    },
    checkRow: {
        margin: theme.spacing(1),
        display: 'flex',
        justifyContent: 'space-evenly',
    },
}));

Font.register({
    family: 'Oswald',
    src: 'https://fonts.gstatic.com/s/oswald/v13/Y_TKV6o8WovbUd3m_X9aAA.ttf'
});

// Create styles
const styles = StyleSheet.create({
    page: {
        flexDirection: 'column',
        fontFamily: 'Helvetica',
        padding: 20,
    },
    section: {
        marginBottom: 10,
    },
    dTable: {
        display: "table",
        width: "auto",
        border: '1 solid black',
        marginBottom: 10,
    },
    dTableRow: {
        margin: "auto",
        flexDirection: "row"
    },
    dTableCol: {
        flexGrow: 1,
    },
    dTableCell: {
        margin: "auto",
        marginTop: 5,
        marginBottom: 5,
        fontSize: 10,
    },
    table: {
        border: '1 solid black',
        marginBottom: 10,
        padding: 10,
        flexGrow: 1,
        fontSize: 14,
        flexDirection: 'column',
        justifyContent: 'space-between',
    },
    tableRow: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginTop: 2,
        marginBottom: 2,
    },
    tableText: {
        fontSize: 12,
    },
    tableTextBold: {
        fontSize: 12,
        fontFamily: 'Helvetica-Bold',
        fontWeight: 'bold',
    },
    summary: {
        flexDirection: 'row',
    },
    viewer: {
        width: '100%',
        maxWidth: '1200px',
        height: '85vh',
    },
});

// Create Document Component
const UnitPDF = (props) => {

    const classes = useStyles();

    React.useEffect(() => {
        
        //eslint-disable-next-line
    }, [])

    return (
        <div className={classes.root}>
            <div className={classes.content}>

            <div className={classes.buttonRow}>
                <Button variant="contained" startIcon={<ArrowBackIcon />}
                    onClick={() => props.history.goBack()}>Back</Button>
            </div>
                <PDFViewer style={styles.viewer}>
                    <Document>
                        <Page size="A4" style={styles.page} wrap>

                            <View style={styles.section}>
                                <Text>Unit # {props.unitData.unitNumber}</Text>
                                <Text>{props.userData?.displayName}</Text>
                                <Text>{moment.utc(props.unitData.date).format('YYYY-MM-DD')}</Text>
                            </View>

                            <View style={styles.summary} wrap={false}>
                                <View style={{ ...styles.table, marginRight: 5 }}>
                                    <View style={styles.tableRow}>
                                        <Text style={styles.tableText}>Mileage In</Text>
                                        <Text style={styles.tableText}>{props.unitData.mileageIn}</Text>
                                    </View>
                                    <View style={styles.tableRow}>
                                        <Text style={styles.tableText}>Mileage Out</Text>
                                        <Text style={styles.tableText}>{props.unitData.mileageOut}</Text>
                                    </View>
                                    <View style={styles.tableRow}>
                                        <Text style={styles.tableText}>Fuel Added</Text>
                                        <Text style={styles.tableText}>{props.unitData.fuelAdded ? props.unitData.fuelAdded : 0} {props.unitData.fuelUnitDisplay}</Text>
                                    </View>
                                </View>
                            
                            <View style={{ ...styles.table, marginRight: 5 }}>
                                <View style={styles.tableRow}>
                                    <Text style={styles.tableText}>Trailer ID</Text>
                                    <Text style={styles.tableText}>{props.unitData.trailerID ? props.unitData.trailerID : "N/A"}</Text>
                                </View>
                                <View style={styles.tableRow}>
                                    <Text style={styles.tableText}>Greased </Text>
                                    <Text style={styles.tableText}>{props.unitData.greased ? "Yes" : "No"}</Text>
                                </View>
                                <View style={styles.tableRow}>
                                    <Text style={styles.tableText}>Odometer Units</Text>
                                    <Text style={styles.tableText}>{props.unitData.odoUnits ? props.unitData.odoUnits : "N/A"}</Text>
                                </View>
                            </View>
                        </View>


                                <View style={styles.dTable} wrap={false} key={'vehicleview'}>
                                <View style={styles.section}>
                                    <Text>Pre Trip Items</Text>
                                </View>
                                <View style={styles.dTableRow}>
                                                <View style={{ ...styles.dTableCol, width: "5%" }}>
                                                    <Text style={styles.dTableCell}>Key</Text>
                                                </View>
                                                <View style={{ ...styles.dTableCol, width: "10%" }}>
                                                    <Text style={styles.dTableCell}>Defect</Text>
                                                </View>
                                                <View style={{ ...styles.dTableCol, width: "10%" }}>
                                                    <Text style={styles.dTableCell}>No Defect</Text>
                                                </View>
                                                <View style={{ ...styles.dTableCol, width: "75%" }}>
                                                    <Text style={{ ...styles.dTableCell, width: '100%' }}>Item</Text>
                                                </View>
                                            </View>

                                            <View style={{ backgroundColor: 'black', height: 12 }} />
                                    {props.unitData.preTripItems.map((item, index) => {
                                        return (
                                            <React.Fragment key={'fragment' + index}>
                                                <View style={styles.dTableRow} key={'reportrow' + index}>
                                                    <View style={{ ...styles.dTableCol, width: "5%" }}>
                                                        <Text style={styles.dTableCell}>{item.key}</Text>
                                                    </View>
                                                    <View style={{ ...styles.dTableCol, width: "10%" }}>
                                                        <Text style={styles.dTableCell}>{item.noDefect ? "" : "X"}</Text>
                                                    </View>
                                                    <View style={{ ...styles.dTableCol, width: "10%" }}>
                                                        <Text style={styles.dTableCell}>{item.noDefect ? "X" : ""}</Text>
                                                    </View>
                                                    <View style={{ ...styles.dTableCol, width: "75%" }}>
                                                        <Text style={{ ...styles.dTableCell, width: '100%' }}>{item.item}</Text>
                                                    </View>
                                                </View>

                                                {item.key === 'J'
                                                    ? <View style={{ backgroundColor: 'black', height: 12 }} key='blackbar' />
                                                    : null}
                                            </React.Fragment>
                                        )
                                    })}
                                    
                                </View>
                                <View style={styles.dTable} wrap={false} key={'vehicleview'}>
                                <View style={styles.section}>
                                    <Text>Post Trip Items</Text>
                                </View>
                                <View style={styles.dTableRow}>
                                                <View style={{ ...styles.dTableCol, width: "5%" }}>
                                                    <Text style={styles.dTableCell}>Key</Text>
                                                </View>
                                                <View style={{ ...styles.dTableCol, width: "10%" }}>
                                                    <Text style={styles.dTableCell}>Defect</Text>
                                                </View>
                                                <View style={{ ...styles.dTableCol, width: "10%" }}>
                                                    <Text style={styles.dTableCell}>No Defect</Text>
                                                </View>
                                                <View style={{ ...styles.dTableCol, width: "75%" }}>
                                                    <Text style={{ ...styles.dTableCell, width: '100%' }}>Item</Text>
                                                </View>
                                            </View>

                                            <View style={{ backgroundColor: 'black', height: 12 }} />
                                    {props.unitData.postTripItems.map((item, index) => {
                                        return (
                                            <React.Fragment key={'fragment' + index}>
                                                <View style={styles.dTableRow} key={'reportrow' + index}>
                                                    <View style={{ ...styles.dTableCol, width: "5%" }}>
                                                        <Text style={styles.dTableCell}>{item.key}</Text>
                                                    </View>
                                                    <View style={{ ...styles.dTableCol, width: "10%" }}>
                                                        <Text style={styles.dTableCell}>{item.noDefect ? "" : "X"}</Text>
                                                    </View>
                                                    <View style={{ ...styles.dTableCol, width: "10%" }}>
                                                        <Text style={styles.dTableCell}>{item.noDefect ? "X" : ""}</Text>
                                                    </View>
                                                    <View style={{ ...styles.dTableCol, width: "75%" }}>
                                                        <Text style={{ ...styles.dTableCell, width: '100%' }}>{item.item}</Text>
                                                    </View>
                                                </View>

                                                {item.key === 'J'
                                                    ? <View style={{ backgroundColor: 'black', height: 12 }} key='blackbar' />
                                                    : null}
                                            </React.Fragment>
                                        )
                                    })}
                                    
                                </View>
                        </Page>

                        
                        
                    </Document>
                </PDFViewer>
            </div>
        </div >
    )
};

export default withRouter(UnitPDF);