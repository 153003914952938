import React, { useEffect } from 'react'
import { Checkbox, FormControlLabel, Grid, IconButton } from "@material-ui/core"
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';

const VariantsList = (props) => {

    const [showVariants, setShowVariants] = React.useState(false)

    let affectedVariants = props.product?.variants?.filter(variant => props.coupon.productsAffected.includes(variant.id))
    let allVariants = affectedVariants?.length === props.product?.variants?.length;

    useEffect(() => {
        affectedVariants?.length > 0 && setShowVariants(true)
    }, [affectedVariants])

    return (
        <Grid container>
            <Grid item xs={1} style={{ display: 'flex', alignItems: 'center' }}>
                {!props.product?.variantTypes?.includes("NOVARIANT") &&
                    <IconButton onClick={() => setShowVariants(!showVariants)}>
                        {showVariants ? <KeyboardArrowDownIcon /> : <KeyboardArrowRightIcon />}
                    </IconButton>}
            </Grid>
            <Grid item xs={11} style={{ display: 'flex', alignItems: 'center' }}>
                <FormControlLabel
                    style={{ marginLeft: '8px' }}
                    label={props.product.name}
                    control={
                        <Checkbox
                            disabled={props.coupon.global || props.coupon.category.onlyCategory}
                            checked={!props.coupon.global && allVariants}

                            onChange={() => {
                                let newProductsAffected = [...props.coupon.productsAffected];

                                props.product?.variants?.forEach(variant => {
                                    let index = newProductsAffected.findIndex(id => id === variant.id)
                                    index > -1
                                        ? allVariants && newProductsAffected.splice(index, 1)
                                        : !allVariants && newProductsAffected.push(variant.id)
                                })
                                console.log("novariant", newProductsAffected )
                                props.updateData({ ...props.coupon, 'productsAffected': newProductsAffected })
                            }}
                        />}
                />
            </Grid>

            {showVariants && !props.product?.variantTypes.includes("NOVARIANT") && props.product?.variants?.map((variant, index) => {
                return <React.Fragment>
                    <Grid item xs={2} />
                    <Grid item xs={10}>
                        <FormControlLabel

                            label={Object.entries(variant.variantTypes).map(([key, value]) => `${key}: ${value}`).join(', ')}
                            control={
                                <Checkbox
                                    onChange={() => {
                                        let newProductsAffected = [...props.coupon.productsAffected];

                                        let index = newProductsAffected.findIndex(id => id === variant.id)
                                        index > -1 ? newProductsAffected.splice(index, 1) : newProductsAffected.push(variant.id)

                                        console.log("variant", newProductsAffected )
                                        props.updateData({ ...props.coupon, 'productsAffected': newProductsAffected })
                                    }}
                                    checked={props.coupon.productsAffected.includes(variant.id)}
                                    disabled={props.coupon.global || props.coupon.category.onlyCategory} />}
                        />
                    </Grid>
                </React.Fragment>
            })}
        </Grid>
    )
}

export default VariantsList;