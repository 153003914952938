import React from 'react'
import { Dialog, DialogContent, DialogTitle, Divider, makeStyles } from "@material-ui/core"
import VariantsList from '../ProductManager/VariantsList';
import _ from 'lodash';
import { useProduct } from '../../../providers/ProductContext'

const useStyles = makeStyles((theme) => ({
    dialogCard: {
        padding: theme.spacing(4),
        maxWidth: '650px',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        maxHeight: '700px',
        [theme.breakpoints.down('xs')]: {
            padding: theme.spacing(1),
            maxHeight: '90%',
        }
    },
}));

const ProductSelector = (props) => {

    const classes = useStyles();
    const [productArray] = React.useState(props.initialProducts || []);
    const { products } = useProduct();

    const updateProducts = (product) => {
        if(!productArray.includes(product)){
            productArray.push(product); 
        } else {
            const index = productArray.indexOf(product);
            if (index > -1) {
                productArray.splice(index, 1);
            }
        }

        //hook
        props.setSelectedProducts(productArray)

        //callback
        props.updateSimilarProducts();
    }
    
    return <Dialog
        classes={{
            paper: classes.dialogCard,
        }}
        keepMounted
        open={props.open}
        onClose={props.close}
    >
        <DialogTitle id="alert-dialog-title">Select products:</DialogTitle>

        <div style={{ width: '100%' }}>
            <Divider style={{ marginTop: '8px', marginBottom: '16px' }} />
        </div>

        <DialogContent style={{ width: '100%' }}>
            {products.sort((a, b) => { return a.name > b.name ? 1 : -1 }).map((product, index) => (
                !_.isUndefined(product) && <VariantsList 
                    key={index}
                    productArray={productArray}
                    updateProducts={updateProducts}
                    product={product}
                   />
            ))}
        </DialogContent>
    </Dialog>
}

export default ProductSelector;