import { firestore } from '../../firebase/firebase'
import * as actionTypes from './actionTypes'
import clone from 'lodash/clone';

export const fetchProduct = () => {
    return {
        type: actionTypes.FETCH_PRODUCT,
    };
}

export const fetchProducts = () => {
    return {
        type: actionTypes.FETCH_PRODUCTS,
    };
}

export const fetchFeaturedProducts = () => {
    return {
        type: actionTypes.FETCH_FEATURED_PRODUCTS,
    };
}

export const setCurrentVariant = (variant) => {
    return {
        type: actionTypes.SET_CURRENT_VARIANT,
        variant: variant,
    }
}

export const productSuccess = (product) => {

    let getVariant;

    if (product?.variants !== undefined)
        getVariant = clone(product?.variants[0]);

    return {
        type: actionTypes.GET_PRODUCT,
        product: product,
        variant: getVariant,
        loading: false,
    };
}

export const productsSuccess = (products) => {
    return {
        type: actionTypes.GET_PRODUCTS,
        products: products,
        loading: false,
    };
}

export const featuredProductsSuccess = (productData) => {
    return {
        type: actionTypes.GET_FEATURED_PRODUCTS,
        featuredProducts: productData,
        loading: false,
    };
}

export const incrQty = () => {
    return {
        type: actionTypes.INCR_QTY,
    };
}

export const decrQty = () => {
    return {
        type: actionTypes.DECR_QTY,
    };
}

export const setPrice = (price) => {
    return {
        type: actionTypes.SET_PRICE,
        price: price,
    }
}

export const setShippingMethod = (shippingMethod) => {
    return {
        type: actionTypes.SET_SHIP_METHOD,
        shippingMethod: shippingMethod,
    }
}

export const updateQty = (val) => {
    return {
        type: actionTypes.SET_QTY,
        qty: val,
    }
}

export const setError = (hasError) => {
    return {
        type: actionTypes.SET_ERROR,
        error: hasError,
    }
}

export const equipmentSuccess = (equipment) => {
    return {
        type: actionTypes.GET_EQUIPMENT,
        equipment: equipment,
        equipmentLoading: false,
    };
}

export const fetchEquipment = () => {
    return {
        type: actionTypes.FETCH_EQUIPMENT,
    };
}

//Get all the products
export const getProducts = () => {
    return dispatch => {
        dispatch(fetchProducts());
        firestore.collection('products').get()
            .then(querySnapshot => {
                const products = querySnapshot.docs
                    .filter((doc) => !doc.data().hideOnStore)
                    .map((doc) => {
                        
                        return { 'docId': doc.id, ...doc.data() }
                    }).reverse();

                dispatch(productsSuccess(products));
            });
    }
}

//Get all the equipment
export const getEquipment = () => {
    return dispatch => {
        dispatch(fetchEquipment());
        firestore.collection('equipmentSales').get()
            .then(querySnapshot => {
                const equipment = querySnapshot.docs.map((doc) => {
                    return { 'docId': doc.id, ...doc.data() }
                })
                dispatch(equipmentSuccess(equipment));
            });
    }
}

export const getFeaturedProducts = () => {
    return dispatch => {
        firestore.collection('featuredProducts').get()
            .then(querySnapshot => {
                const featured = []
                querySnapshot.forEach((doc) => {
                    featured.push(doc.data().product);
                })

                featured.forEach(product => {
                    firestore.collection('products').doc(product).get()
                        .then(doc => {
                            const prodData = { ...doc.data(), 'docId': doc.id }
                            dispatch(featuredProductsSuccess(prodData));
                        });
                });
            });
    }
}

export const getProduct = (productId) => {
    return (dispatch, getState) => {
        if (getState().products.products.length > 0) {
            const prodData = getState().products.products.filter((product) => product.docId === productId);
            dispatch(productSuccess(prodData[0]));
        } else {
            firestore.collection('products').doc(productId).get()
                .then(doc => {
                    const prodData = { ...doc.data(), 'docId': doc.id }
                    dispatch(productSuccess(prodData));
                });
        }
    }
}

export const createProduct = (product) => {
    return (dispatch, getState) => {
        firestore.collection('products').doc(product.docId).set(product)
            .then(doc => {
                dispatch(getProducts());
            })
            .catch(function (error) {
                console.log("Unable to create product");
            });
    }
}

export const editProduct = (product) => {
    return (dispatch, getState) => {
        firestore.collection('products').doc(product.docId).set(product)
            .then(doc => {
                //console.log("Product successfully edited");
                dispatch(getProducts());
            })
            .catch(function (error) {
                console.log("Unable to edit product");
            });
    }
}

export const deleteProduct = (productId) => {
    return (dispatch, getState) => {
        firestore.collection('products').doc(productId).delete()
            .then(doc => {
                
                dispatch(getProducts());
            })
            .catch(function (error) {
                console.log("Unable to remove product");
            });
    }
}



