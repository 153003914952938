import React, { useEffect, useState } from 'react';
import { Circle, GoogleMap, Marker, StandaloneSearchBox, useLoadScript, Polygon } from '@react-google-maps/api';
import { Alert } from '@material-ui/lab'
import { Backdrop, Button, Fab, Fade, Grid, Modal, Paper, Typography, useMediaQuery, Select, MenuItem, TextField } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles';
import { useTheme } from '@material-ui/core/styles';
import { useThemes } from '../../../../providers/ThemesContext'
import { DatePicker } from '@material-ui/pickers';
import moment from 'moment';
import { useSettings } from '../../../../providers/SettingsProvider';

const useStyles = makeStyles((theme) => ({

    dateInput: {
        display: 'flex',
        alignItems: 'center',
    },

    root: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: '100%',
        height: '100%',
    },

    grid: {
        justifyContent: 'center',
        width: '100%',
    },
}));

const libraries = ['places'];

export const MobileCartMap = (props) => {
    const [center, setMapCenter] = useState(props.coordinates);
    const [searchBox, setSearchBox] = useState({});
    const [step, setStep] = useState(0);
    const [showModal, setShowModal] = useState(false);
    /* const [showNotices, setShowNotices] = useState(false); */
    const [showAlert, setShowAlert] = useState(false);
    const { setShowHeader, setShowFooter } = useThemes();
    const theme = useTheme();
    const smallScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const onLoad = (ref) => setSearchBox(ref);
    const MuiClasses = useStyles();
    const { settings, datesClosed, zones } = useSettings();

    const mapContainerStyle = {
        width: props.width,
        height: props.height,
    };

    //Google Maps API setup
    const { isLoaded, loadError } = useLoadScript({
        googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
        libraries,
    });

    useEffect(() =>{
        props.setNextAvailableDeliverDate();
        //eslint-disable-next-line
      }, [])

    const today = moment()

    const closedDays = Object.entries(settings.operatingHours).map(([key, value]) => {
      if(value.closed === true){
        switch(key) {
          case 'Monday':
            return 1
          case 'Tuesday':
            return 2
          case 'Wednesday':
            return 3
          case 'Thursday':
            return 4
          case 'Friday':
            return 5
          case 'Saturday':
            return 6
          case 'Sunday':
            return 0
          default:
            console.warn('Dates closed were not generated correctly.')
            return null
        } 
      }
      return undefined
    }).filter(x => x !== undefined)

    useEffect(() => {
        let doc = document.querySelector('meta[name="viewport"]')
        //hacky method to disable browser zoom map to fix users unable to find next button
        doc.setAttribute('content', 'width=device-width, maximum-scale=1.0, initial-scale=1.0')

        return () => { doc.setAttribute('content', 'width=device-width, initial-scale=1.0') }
    }, [])

    useEffect(() => {
        smallScreen && setShowHeader(false)
        setShowFooter(false);
        return () => {
            setShowHeader(true);
            setShowFooter(true)
        };
    }, [smallScreen, setShowHeader, setShowFooter]);

    const handleAlert = () => {
        setShowAlert(true);
        setTimeout(() => setShowAlert(false), 5000)
    }

    //Error checking on googlemaps api
    if (loadError) return "Error";
    if (!isLoaded) return "";

    const handlePickAddress = (props) => {
        setShowModal(false);
        setStep(2);
    }

    const handleModalOpen = () => {
        setMapCenter(props.coordinates);
        setShowModal(true);
    }

    const handleNext = () => {
        switch (step) {
            case 0:
                props.validAddress && setStep(step + 1)
                break;
            case 1:
                props.validLocation && handleModalOpen();
                break;
            case 2:
                props.handleClose()
                break;
            default:
                break;
        }
    }

    const onPlacesChanged = (ref) => {
        let places = searchBox.getPlaces();

        if (places[0]) {
            let newPosition = {
                lat: places[0].geometry.location.lat(),
                lng: places[0].geometry.location.lng(),
            }

            handleAlert()
            setMapCenter(newPosition);
            props.updateCoordinates(newPosition, "INPUT_SEARCHBOX", places[0].formatted_address)
            const address = new window.google.maps.Marker({
                position: newPosition,
              })
            if(checkIfMarkerInPolygons(address, polygonArray)){
                props.updateCoordinates(newPosition, "INPUT_SEARCHBOX", places[0].formatted_address, checkIfMarkerInPolygons(address, polygonArray).name)
            }
        }
    };

    const polygonArray = zones?.zones?.map((zone) => {
        return new window.google.maps.Polygon({
          paths: zone.coordinates,
        });
    });

    const themarker = new window.google.maps.Marker({
        position: props.coordinates,
    });

    const checkIfMarkerInPolygons = (marker, polygons) => {
        const markerPosition = marker.getPosition(); // get the LatLng position of the marker
        if(polygons.length > 0)
        {
        for (let i = 0; i < polygons.length; i++) {
          if (window.google.maps.geometry.poly.containsLocation(markerPosition, polygons[i])) {
            // Marker is inside polygon
            return true && zones.zones[i];
          }
        }
    }
        // Marker is not inside any polygon
        return false;
      };


    const onClick = (...args) => {
        if (args) {
            let newPosition = {
                lat: args[0].latLng.lat(),
                lng: args[0].latLng.lng(),
            }

            handleAlert()
            const circleCenter = new window.google.maps.LatLng(-49.904415, -96.932431 - 180);
            const circleRadius = 20037508.34 - 100000 * 0.7;
            const markerPosition = new window.google.maps.LatLng(newPosition.lat, newPosition.lng);
            const distance = window.google.maps.geometry.spherical.computeDistanceBetween(
              circleCenter,
              markerPosition
            );
      
            if (distance < circleRadius) {
              // set invalid address
              props.updateCoordinates(newPosition, "INPUT_MARKER", "Invalid Address");
            } else {
              props.updateCoordinates(newPosition, "INPUT_MARKER");
            }
    
      
            checkIfMarkerInPolygons(themarker, polygonArray)
        }
    }

    const generateDatesClosed = (date) => {
        //disables days based on shopSettings.shopSettings.operatingHours
        for(let i = 0; i < closedDays.length; i++){
          if(closedDays[i] === moment(date).day()){
            return true
          }
        }
        //gets dates out of shopSettings.datesClosed map
        let datesClosedValues = Object.entries(datesClosed.dates).map(([key]) => {
            return key
        })
        //disables days based on above
        for(let j = 0; j < datesClosedValues.length; j++){
          if(moment(date).format('MM/DD') === moment(datesClosedValues[j]).format('MM/DD')){
            return true
          }
        }
      }

    const newAlert = () => {

        let severity = 'info';
        let msg = 'For updated shipping costs, \'Enter your Address\' or select your location on the map.';
        let variant = 'outlined';

        if (props.alertActive) {
            if (!props.pickupOrder) {
                if (props.alertActive) {
                    variant = 'filled'
                }
                else {
                    variant = 'outlined';
                }
                severity = props.alertSev;
                msg = props.alertMsg;
            }
            else {
                variant = 'filled'
                severity = 'success';
                msg = 'Order is pickup only, delivery location not required.';
            }
        }

        return <Alert variant={variant} severity={severity} style={{ marginBottom: '8px' }}>{msg}</Alert>
    }

    const currentDayOfWeek = today.day();

    let cutoffTime;
  
    if (currentDayOfWeek >= 1 && currentDayOfWeek <= 5) {
      // Monday to Friday: 5:30 PM cutoff time
      cutoffTime = '17:30';
    } else if (currentDayOfWeek === 6) {
      // Saturday: 2:30 PM cutoff time
      cutoffTime = '14:30';
    } else {
      // Sunday and other days: No cutoff time, use the current time
      cutoffTime = today.format('HH:mm');
    }

    let steps = [
        <div style={ props.isCustomOrder 
            ? { padding: '8px', marginTop: '7vh', marginLeft: window.innerWidth > 600 ? '7vh' : '0vh', display: 'flex', flexDirection: 'column'} 
            : { padding: '8px' }}>
            <Typography style={{ marginBottom: '8px', color: 'rgba(50, 50, 50, 1)' }}><strong>Enter your address in the searchbar below:</strong></Typography>
            {step === 0 && <StandaloneSearchBox
                onLoad={onLoad}
                onPlacesChanged={onPlacesChanged}
                bounds={new window.google.maps.LatLngBounds(
                    new window.google.maps.LatLng(49.904415 - 0.2, -96.932431 - 0.2),
                    new window.google.maps.LatLng(49.904415 + 0.2, -96.932431 + 0.2),
                )}>
                <input
                    defaultValue={props.address}
                    type='text'
                    placeholder='Search for Address'
                    style={{
                        boxSizing: `border-box`,
                        border: `1px solid`,
                        width: `100%`,
                        height: `48px`,
                        padding: `0 12px`,
                        borderRadius: `3px`,
                        boxShadow: `0 1px 4px rgba(0, 0, 0, 0.2)`,
                        fontSize: `16px`,
                    }}
                />
            </StandaloneSearchBox>}
        </div>,

        <div style={props.isCustomOrder 
            ?
            {marginTop: '7vh',
            marginLeft: window.innerWidth > 600 ? '7vh' : '0vh'}
            :
            {marginTop: '0vh',}
        }>
            <div style={{ padding: '8px' }}>
                <Typography style={{
                    border: '1px solid black',
                    backgroundColor: 'white',
                    padding: '8px',
                    borderRadius: '8px',
                    marginBottom: '8px',
                }}><strong>Deliver To: </strong>{props.address}</Typography>
                <Typography><strong>Next, tap the screen to mark where you would like the material delivered.</strong></Typography>
            </div>
        <div>

            </div>
        </div>,
        <div style={
            props.isCustomOrder ?
            {backgroundColor: 'white',
            borderRadius: '4px',
            padding: '8px',
            marginTop: '7vh',
            marginLeft: window.innerWidth > 600 ? '7vh' : '0vh',}
            :
            {backgroundColor: 'white',
            borderRadius: '4px',
            padding: '8px',}
        }>
            
            <div style={{ marginTop: '8px', marginBottom: '8px' }}>
                <Typography  variant='h6'>Preferred Delivery Date & Time</Typography>
                <Grid container spacing={2} className={MuiClasses.dateInput}>
                    <Grid item xs={8}>
                    <DatePicker
                        style={{marginTop: '15px'}}
                        value={props.inputs[10].config.value === '' ? moment().format('YYYY-MM-DD') : props.inputs[10].config.value}
                        onChange={(event) => { props.inputChangedHandler(moment(event), 'deliverDate') }}
                        minDate={moment(today).format('MM/DD') in closedDays ? moment(today).add(1, 'day') : moment(today).add(moment(today).format('HH:mm') > cutoffTime ? 1 : 0, 'days')}
                        shouldDisableDate={(date) => {
                            return generateDatesClosed(date)
                    }}
                    ></DatePicker>
                    </Grid>
                    <Grid item xs={2}>
                        <Select
                            value={props.inputs[11].config.value}
                            onChange={(event) => { props.inputChangedHandler(event, 'deliverTime') }}
                            style={{marginTop: '14px'}}
                        >
                            <MenuItem 
                                disabled={
                                    moment(props.inputs[10].config.value).format('YYYY-MM-DD') === moment(today).format('YYYY-MM-DD') &&
                                    moment(today).format('HH') > moment('12', 'HH').format('HH')
                                } 
                                key={'AM'} 
                                value={'AM'}
                            >
                                AM
                            </MenuItem> 

                            <MenuItem 
                                key={'PM'} 
                                value={'PM'}
                            >
                                PM
                            </MenuItem> 
                        </Select>
                        {/* {props.createInput(props.inputs[11])} */}
                    </Grid>
                </Grid>
            </div>
            <Typography variant='h6'>Optional Steps</Typography>
            <Typography>Special Instructions</Typography>
            <TextField
                value={props.inputs[9].config.value}
                fullWidth
                variant={'outlined'}
                onChange={(event) => { props.inputChangedHandler(event, 'notes') }}
                multiline
                minRows={4}
            />
            {/* {props.createInput(props.inputs[9])} */}
        </div>
    ]

    let zonePolygons = zones?.zones?.map((zone, index) => {
        return <>
        <Polygon
            key={index}
            path={zone.coordinates}
            options={{
                fillColor: zone.color,
                fillOpacity: process.env.NODE_ENV === 'development' ? 0.3 : 0,
                strokeColor: zone.color,
                strokeOpacity: process.env.NODE_ENV === 'development' ? 0.8 : 0,
                strokeWeight: 2,
                clickable: false,
                editable: false,
                zIndex: 6
            }}
        /></>
    })

    return <div className={MuiClasses.root} id='root'>
        <div style={{ position: 'fixed', height: `100vh`, width: '100vw', top: 0, left: 0, opacity: .7 }}>
            <GoogleMap
                mapContainerStyle={mapContainerStyle}
                options={{
                    fullscreenControl: false,
                    streetViewControl: false,
                    mapTypeControl: false,
                    rotateControl: false,
                    zoomControl: false,
                    mapTypeId: 'hybrid'
                }}

                zoom={step === 1 ? 20 : 16}
                center={center}
                onClick={(props.validAddress && step === 1) && onClick}
            >
                <Marker
                    position={props.coordinates} />
                <Circle
                    center={{ lat: -49.904415, lng: -96.932431 - 180 }}
                    radius={20037508.34 - 100000 * .70}
                    options={{
                        clickable: false,
                        fillOpacity: .4,
                        fillColor: 'black',
                    }}
                />
                {zonePolygons}
            </GoogleMap>
        </div>

        {!props.validAddress && <div style={{
            position: 'fixed',
            height: `100vh`,
            width: '100vw',
            top: 0,
            left: 0,
            backgroundColor: 'black',
            opacity: .5,
        }}>
        </div>}

        <div style={{
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100vw',
        }}>

            <Paper
                square
                style={{
                    backgroundColor: step === 2 ? 'white' : '#ffc107',
                    justifyContent: 'space-between',

                    lineHeightStep: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                }}>
                {steps[step]}

            </Paper>

            <Fade in={showAlert} mountOnEnter unmountOnExit style={props.isCustomOrder && window.innerWidth > 600 ? {marginLeft: '6vh'} : {marginLeft: '0vh'}}>
                {newAlert()}
            </Fade>

            <div style={{
                backgroundColor: 'rgba(50, 50, 50, 1)',
                position: 'fixed',
                bottom: 0,
                left: 0,
                width: '100vw',
                display: 'flex',
                justifyContent: 'space-between'
            }}>
                <Fab
                    onClick={() => { step === 0 ? props.handleClose() : setStep(step - 1) }}
                    style={
                        props.isCustomOrder &&  window.innerWidth > 600 ?
                        {backgroundColor: '#ffc107',
                        left: 84,
                        bottom: 24,}
                        :
                        {backgroundColor: '#ffc107',
                        left: 16,
                        bottom: 24,}
                    }>Back</Fab>

                {/*  <Button variant='text' size='large' color='primary' onClick={() => setShowNotices(true)}>Notices</Button> */}
                    
                <Fab
                    disabled={(step === 0 && !props.validAddress) || (step === 1 && !props.validLocation) || (step === 2 && !props.inputs[10].config.value) ||
                        (step === 2 && 
                            //props.inputs[10].config.value is today and its past the cutoff time defined above
                            
                            props.inputs[10].config.value === moment(today).format('YYYY-MM-DD') &&
                            moment(today).format('HH:mm') > moment(cutoffTime, 'HH:mm').format('HH:mm')
                            
                            ) }
                    onClick={() => handleNext()}
                    style={ {
                        backgroundColor: (step === 0 && props.validAddress) || (step === 1 && props.validLocation) || (step === 2 && props.inputs[10].config.value)? '#ffc107' : 'grey' ,
                        right: 16,
                        bottom: 24,
                    }}>
                    {step === 2 ? "Done" : "Next"}
                </Fab>
            </div>
        </div>

        <Modal
            open={showModal}
            BackdropComponent={Backdrop}>
            <Fade in={showModal}>
                <Paper
                    square
                    style={{
                        boxSizing: 'border-box',
                        padding: '16px',
                        width: '100%',
                        maxWidth: '100vw',
                        position: 'fixed',
                        bottom: 0,
                        left: 0
                    }}>
                    <Typography style={{ marginBottom: '8px' }}><strong>Please confirm the desired delivery location matches the marker on the map</strong></Typography>
                    <Grid container spacing={2}>
                        <Grid item xs={6}>
                            <Button
                                fullWidth
                                variant='outlined'
                                style={{ margin: '4px' }}
                                onClick={() => setShowModal(false)}>Cancel</Button>
                        </Grid>
                        <Grid item xs={6}>
                            <Button
                                fullWidth
                                variant='contained'
                                style={{ margin: '4px' }}
                                onClick={() => handlePickAddress()}>Confirm</Button>
                        </Grid>
                    </Grid>
                </Paper>
            </Fade>
        </Modal>

        {/* <Modal
            style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
            open={showNotices}
            BackdropComponent={Backdrop}>
            <Fade in={showNotices}>
                <div
                    style={{
                        padding: '16px', maxWidth: '400px', width: '100%'
                    }}>
                    <Alert severity='warning'>Due to springtime road restrictions, there may be a delay in your delivery.</Alert>

                    <Button
                        fullWidth
                        variant='contained'
                        style={{ marginTop: '4px' }}
                        onClick={() => setShowNotices(false)}>Got It</Button>

                </div>
            </Fade>
        </Modal> */}
    </div >
};