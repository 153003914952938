import { Typography, Paper, Grid } from '@material-ui/core'
import { Skeleton } from '@material-ui/lab'
import _ from 'lodash'
import moment from 'moment'
import React from 'react'

const PaymentDetails = (props) => {

    let paymentError = !('payment' in props.order) || _.isEmpty(props.order.payment)

    return (
        props.loading ? <Skeleton></Skeleton> :
            props.order && <Paper elevation={2} style={{ padding: "16px", margin: "8px", color: '#707070', minHeight: 'calc(100% - 16px)' }}>
                {!paymentError
                    ? <Grid container spacing={2}>
                        <Grid container>
                            <Grid item xs={12}>
                                <Typography style={{ lineHeight: 2 }} noWrap variant='h6'>Payment Details</Typography>
                            </Grid>
                            <Grid item xs={6} >
                                <Typography style={{ lineHeight: 2 }} noWrap variant='body1'>Transaction Handler:</Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Typography style={{ lineHeight: 2 }} align='right' variant='body1'>{props.order.payment.paymentHandler ? props.order.payment.paymentHandler : "SQUARE"}</Typography>
                            </Grid>
                            <Grid item xs={6} >
                                <Typography style={{ lineHeight: 2 }} noWrap variant='body1'>Transaction Time:</Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Typography style={{ lineHeight: 2 }} align='right' variant='body1'>{moment(props.order.payment.created_at).add(1, 'hours').format('ddd, MMM D, YYYY, h:mm a')}</Typography>
                            </Grid>
                            <Grid item xs={6} >
                                <Typography style={{ lineHeight: 2 }} noWrap variant='body1'>Payment Method:</Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Typography style={{ lineHeight: 2 }} align='right' variant='body1'>{props.order.payment.card_details?.card?.card_type || 'Undefined'}</Typography>
                            </Grid>
                            <Grid item xs={6} >
                                <Typography style={{ lineHeight: 2 }} noWrap variant='body1'>Last Four Digits:</Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Typography style={{ lineHeight: 2 }} align='right' variant='body1'>{props.order.payment.card_details?.card.last_4 || 'Undefined'}</Typography>
                            </Grid>
                            <Grid item xs={6} >
                                <Typography style={{ lineHeight: 2 }} noWrap variant='body1'>Card Type:</Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Typography style={{ lineHeight: 2 }} align='right' variant='body1'>{props.order.payment.card_details?.card?.card_brand || 'Undefined'}</Typography>
                            </Grid>
                            <Grid item xs={6} >
                                <Typography style={{ lineHeight: 2 }} noWrap variant='body1'>Expiry Date:</Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Typography style={{ lineHeight: 2 }} align='right' variant='body1'>{
                                    props.order?.payment?.paymentHandler
                                        ? (props.order.payment.card_details?.card?.exp_date?.substr(0, 2) || '00') + "/" + (props.order.payment.card_details?.card?.exp_date?.substr(2, 3) || '00')
                                        : (props.order.payment.card_details?.card?.exp_month || '00') + "/" + (props.order.payment.card_details?.card?.exp_year || '00')
                                }</Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                    : <React.Fragment>
                        <Typography variant='h6' style={{ marginBottom: '16px' }}>Payment Details</Typography>
                        <Typography variant='h6' style={{ color: 'red', marginBottom: '8px' }}>WARNING: THIS ORDER IS MISSING A PAYMENT</Typography>
                        <Typography>Check for payment on Square/Converge, customer may need to be refunded. Contact the Development Team for more information.</Typography>
                    </React.Fragment>}
            </Paper >
    )
}
export default PaymentDetails