import React from 'react';
import { Font, Text, View, StyleSheet } from '@react-pdf/renderer';

// const useStyles = makeStyles((theme) => ({
//     root: {
//         display: 'flex',
//         justifyContent: 'center',
//         width: '100%',
//     },
//     content: {
//         display: 'flex',
//         flexDirection: 'column',
//         width: '100%',
//         maxWidth: '1200px',
//         margin: 'auto',
//     },
//     buttonRow: {
//         marginBottom: '20px',
//         marginTop: '20px',
//     },
//     checkRow: {
//         margin: theme.spacing(1),
//         display: 'flex',
//         justifyContent: 'space-evenly',
//     },
// }));

Font.register({
    family: 'Oswald',
    src: 'https://fonts.gstatic.com/s/oswald/v13/Y_TKV6o8WovbUd3m_X9aAA.ttf'
});

// Create styles
const styles = StyleSheet.create({
    page: {
        flexDirection: 'column',
        fontFamily: 'Helvetica',
        padding: 20,
    },
    section: {
        marginBottom: 10,
    },
    dTable: {
        display: "table",
        width: "auto",
        border: '1 solid black',

        marginBottom: 10,
    },
    dTableRow: {
        margin: "auto",
        flexDirection: "row"
    },
    dTableCol: {
        flexGrow: 1,
    },
    dTableCell: {
        margin: "auto",
        marginTop: 5,
        marginBottom: 5,
        fontSize: 10,
    },
    table: {
        border: '1 solid black',
        marginBottom: 10,
        padding: 10,
        flexGrow: 1,
        fontSize: 14,
        flexDirection: 'column',
        justifyContent: 'space-between',
    },
    subTable: {
        marginBottom: 10,
        flexGrow: 1,
        fontSize: 14,
        flexDirection: 'column',
        justifyContent: 'space-between',
    },
    tableRow: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginTop: 2,
        marginBottom: 2,
    },
    tableText: {
        fontSize: 10,
        marginBottom: '1px',
    },
    tableTextBold: {
        fontSize: 10,
        marginBottom: '1px',
        fontFamily: 'Helvetica-Bold',
        fontWeight: 'bold',
        marginTop: '5px'
    },
    tableTextSmall: {
        fontSize: 10,
    },
    tableTextBoldUnderline: {
        fontSize: 12,
        fontFamily: 'Helvetica-Bold',
        fontWeight: 'bold',
        textDecoration: 'underline',
        marginBottom: '10px',
    },
    summary: {
        flexDirection: 'row',
    },
    viewer: {
        width: '100%',
        maxWidth: '1200px',
        height: '85vh',
    },
    checkbox: {
        width: '10px',
        height: '10px',
        background: 'white',
        border: '1 solid black',
    },
    radio: {
        width: '10px',
        height: '10px',
        background: 'white',
        borderRadius: '50%',
        border: '1 solid black',
    },
    userEntered: {
        width: '200px',
        height: '20px',
        background: 'white',
        border: '1 solid black',
    },
    
    
    header: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        margin: '4px',
        borderBottom: '1px solid black',
        marginBottom: '30px',
    },
    portraitTableCell: {
        width: '14.25%',
        minWidth: '14.25%',
        alignItems: 'center',
    },
    landscapeTableCell: {
        width: '9%',
        minWidth: '9%',
        alignItems: 'center',
    },
    portraitTableCellNth2: {
        width: '14.25%',
        minWidth: '14.25%',
        alignItems: 'center',
        backgroundColor: 'grey',
    },
    landscapeTableCellNth2: {
        width: '9%',
        minWidth: '9%',
        alignItems: 'center',
        backgroundColor: 'grey',
    },
    instructionHeader: {
        paddingBottom: '5px',
        fontSize: 12,
        color: 'blue',
    },

    summaryCombinedPortraitTableCell:{
        width: '71.5%', 
        minWidth: '71.5%', 
        alignItems: 'center',
    },
    summaryNotCombinedLandscapeTableCell: {
        width: '20.5%',
        minWidth: '20.5%', 
        alignItems: 'center',
    },
    FFCell: {
        width: '50%',
        minWidth: '50%',
    },
});

const PayrollSummary = (props) => {

    //const classes = useStyles();

    /**
     * @param title - Table Header
     * @param columns - Generates table column header and its internal data. Can pass either an objects key to iterate or a function.
     * @param data - The table data passed (as an array of objects)
     * @param components - React components passed as special rendering for additional rows 
    */

    return (
        <>                  
            <View style={{flexDirection: 'column'}}>
                <View style={{flexDirection: 'row'}}>
                    <View style={{...styles.FFCell, flexDirection: 'column', width: '100%',}}>
                        <Text style={{fontSize: '12px'}}>{props.payrollDetails.shortMessage}</Text>
                    </View>
                    
                    <View style={{...styles.FFCell, flexDirection: 'column', width: '100%',}}>
                        <View style={{border: '1px solid black', flexDirection: 'row', width: '100%', padding: '5px', justifyContent: 'space-between'}}>
                            <Text style={{...styles.tableTextBold, fontSize: '12px',}}>
                                {`Sub-Total`}
                            </Text>
                            <Text style={{...styles.tableTextBold, fontSize: '12px',}}>
                                {`$${props.ticketData.reduce((accumulator, currentValue) => (Number(accumulator) + Number(currentValue.payroll || 0.00)).toFixed(2), 0,)}`}
                            </Text>
                        </View>


                        {/* {props.payrollDetails.includeVacactionPay &&
                            
                                <View style={{flexDirection: 'row', width: '100%', padding: '5px', justifyContent: 'space-between'}}>
                                    <Text style={{...styles.tableTextBold, fontSize: '12px',}}>
                                        {`Vaction Pay Rate 8.16%`}
                                    </Text>
                                    <Text style={{...styles.tableTextBold, fontSize: '12px',}}>
                                        {`$${Number(props.ticketData.reduce((accumulator, currentValue) => (Number(accumulator) + Number(currentValue.payroll)).toFixed(2), 0,) * 0.0816).toFixed(2)}`}
                                    </Text>
                                </View>
                        } */}

                        <View>                       
                            <View style={{flexDirection: 'row', width: '100%', padding: '5px', justifyContent: 'space-between'}}>
                                <Text style={{...styles.tableTextBold, fontSize: '12px',}}>
                                    {`Total`}
                                </Text>
                                <Text style={{...styles.tableTextBold, fontSize: '12px',}}>
                                    {`$${Number(props.ticketData.reduce((accumulator, currentValue) => (Number(accumulator) + Number(currentValue.payroll || 0.00)).toFixed(2), 0,) * 1.0816).toFixed(2)}`}
                                </Text>
                            </View>
                        </View>
                        
                        <View style={{flexDirection: 'row', position: 'relative'}}>
                            <View style={{flexDirection: 'column', border: '1px solid black', backgroundColor: '#d3d3d3', padding: '5px', minWidth: '200px'}}>
                                <View style={{flexDirection: 'row', justifyContent: 'space-between'}}>
                                    <Text style={{...styles.tableTextBold, }}>{`Total Time:`}</Text>
                                    <Text style={{...styles.tableTextBold, }}>{`${props.calcTimeValues(props.ticketData).totalTime}`}</Text>
                                </View>

                                <View style={{flexDirection: 'row', justifyContent: 'space-between'}}>
                                    <Text style={{...styles.tableTextBold, color: 'blue' }}>{`Regular Time:`}</Text>
                                    <Text style={{...styles.tableTextBold, color: 'blue' }}>{`${props.calcTimeValues(props.ticketData).regularTime}`}</Text>
                                </View>
                                <View style={{flexDirection: 'row', justifyContent: 'space-between'}}>
                                    <Text style={{...styles.tableTextBold, }}>{`Overtime > 50 hrs:`}</Text>
                                    <Text style={{...styles.tableTextBold, }}>{`${props.calcTimeValues(props.ticketData).overtime}`}</Text>
                                </View>
                            </View>
                        </View>
                    </View>
                </View>
            </View>
         </>
    )
};

export default PayrollSummary;