import React, { useState } from 'react';
import { makeStyles} from '@material-ui/core';
import { Button, Grid, Paper, Typography, Divider, ButtonGroup } from '@material-ui/core';
import { withRouter } from 'react-router-dom';
import DeleteIcon from '@material-ui/icons/Delete';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { firestore, removeFile } from '../../../firebase/firebase';
import EmailIcon from '@material-ui/icons/Email';
import LocalPhoneIcon from '@material-ui/icons/LocalPhone';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import PaperTitle from '../../components/Orders/OrderPage/PaperTitle';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';


const useStyles = makeStyles((theme) => ({
    btnGroup: {
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
    },
    alias: {
        display: 'flex',
        marginTop: '8px',
        marginBottom: '8px',
        alignItems: 'center',
    },
    card: {
        padding: theme.spacing(4),
        display: 'flex',
        alignItems: 'center',
        height: '100%',
    },
    content: {
        display: 'flex',
        width: '100%',
        flexDirection: 'column',
        maxWidth: '1000px',
        margin: 'auto',
    },
    paper: {
        padding: theme.spacing(2),
        maxWidth: '1000px',
        width: '100%',
        //backgroundColor: 'rgb(252, 252, 249)',
    },
    row: {
        display: 'flex',
        alignContent: 'space-between',
    },
    backButton: {
        marginBottom: '20px',
        marginTop: '20px',
    },
}));


const ResumeCard = (props) => {

    const classes = useStyles();
    let resumeCard = null;

    const [open, setOpen] = useState(false);


    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const titleCase = (str) => {
        str = str.toLowerCase().split(' ');
        for (var i = 0; i < str.length; i++) {
          str[i] = str[i].charAt(0).toUpperCase() + str[i].slice(1); 
        }
        return str.join(' ');
      }
      
    const handleDeleteClick = async () => {
        if(props.resumeData.resumeURL !== "" || props.resumeData.resumeURL !== null)
        {
            await removeFile(props.resumeData.resumeURL);
        }
        if(props.resumeData.coverLetterURL !== "" || props.resumeData.coverLetterURL !== null)
        {
            await removeFile(props.resumeData.coverLetterURL);
        }
        //associaited folder is removed automatically
        firestore.collection('resumes').doc(props.resumeData.docId).delete().then(() => {
            console.log("Resume successfully deleted!");
            props.history.replace({pathname: '/dashboard/resumes'})
        }).catch((error) => {
            console.error("Error removing Resume: ", error);
        });
    }

        resumeCard = (
            <Grid className={classes.content}>
                    <Grid className={classes.backButton}>
                        <Button variant="contained" onClick={() => props.history.goBack()} startIcon={<ArrowBackIcon/>}>Back</Button>
                    </Grid>
                <Paper className={classes.paper}>
                    <Dialog
                        open={open}
                        onClose={handleClose}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                        >
                        <DialogTitle id="alert-dialog-title">{"Delete Resume"}</DialogTitle>
                        <DialogContent>
                            <DialogContentText id="alert-dialog-description">
                                Are you sure you want to delete this Resume?
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleClose} variant="contained" color="secondary">
                                No
                            </Button>
                            <Button onClick={handleDeleteClick} variant="contained" style={{ backgroundColor: 'red', color: 'white' }} autoFocus>
                                Yes
                            </Button>
                        </DialogActions>
                    </Dialog>

                    <Grid container spacing={2}>
                        <Grid item sm={6} xs={4}>
                            <PaperTitle title={props.resumeData.name ? titleCase(props.resumeData?.name) : "Unknown"} width='100px' />
                        </Grid>

                        <Grid item sm={6} xs={6} >
                            <div className={classes.btnGroup}>
                                <ButtonGroup color="primary" variant="contained" style={{margin:'4px'}} >
                                    <Button onClick={() => { window.open(props.resumeData.resumeURL, "_blank")}} disabled={!props.resumeData.resumeURL} startIcon={!props.resumeData.resumeURL ? null:<CloudDownloadIcon/>}>{!props.resumeData.resumeURL ? 'No Resume File' : 'Resume'}</Button>
                                    <Button onClick={() => { window.open(props.resumeData.coverLetterURL, "_blank")}} disabled={!props.resumeData.coverLetterURL} startIcon={!props.resumeData.coverLetterURL ? null: <CloudDownloadIcon/>}>{!props.resumeData.coverLetterURL ? 'No Letter File' : 'Cover Letter'}</Button>
                                </ButtonGroup>
                                <Button variant="contained" color="primary" style={{ color: 'white', backgroundColor: 'red' }}
                                    onClick={handleOpen} startIcon={<DeleteIcon />}>Delete</Button>
                                
                            </div>  
                            
                        </Grid>

                        <Grid item sm={3} xs={4}>
                            <Typography align='left' variant='h6'>Applying for: {props.resumeData.position}</Typography>
                            <Divider></Divider>
                        </Grid>

                        <Grid container item sm={12} xs={12}  style={{ justifyContent: 'flex-start' }}>
                            <Grid item sm={12} xs={12}>
                                <Typography><EmailIcon color='secondary' style={{marginBottom:'-6px', marginRight:'4px'}}/><b>Email:</b> {props.resumeData.email}</Typography>
                            </Grid>
                            <Grid item sm={6} xs={6}>
                                <Typography><LocalPhoneIcon color='secondary' style={{marginBottom:'-6px', marginRight:'4px'}}/><b>Phone:</b> {props.resumeData.phone}</Typography>
                            </Grid>
                        </Grid>
                        
                        <Grid container item spacing={2}  >
                            <Grid item sm={5} style={{marginRight:'40px'}}>
                                <Paper style={{minWidth: '420px', maxWidth:'420px', margin:'5px', padding:'5px'}}>
                                    <Grid item sm={12} >
                                        <PaperTitle title={"Cover Letter"} width='100px' />
                                        <Divider></Divider>
                                        <Typography variant="subtitle1" style={{padding:'10px'}}>{props.resumeData.coverLetter}</Typography>
                                    </Grid>
                                </Paper>
                                </Grid>
                            <Grid item sm={5} >
                                <Paper style={{minWidth: '420px', maxWidth:'420px', margin:'5px', padding:'5px'}}>
                                    <Grid item sm={12} >
                                        <PaperTitle title={"Resume"} width='100px' />
                                        <Divider></Divider>
                                        <Typography variant="subtitle1" style={{padding:'10px'}}>{props.resumeData.resume}</Typography>
                                    </Grid>
                                </Paper>
                            </Grid>
                        </Grid>
                    </Grid>
                </Paper>
            </Grid>
        )
    return (
        <React.Fragment>
            <div>
                {resumeCard}
            </div>
        </React.Fragment>
    )
}

export default withRouter(ResumeCard)