import React from 'react';
import { Button, makeStyles } from '@material-ui/core';
import { PDFViewer, Font, Page, Text, View, Document, StyleSheet } from '@react-pdf/renderer';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { withRouter } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        justifyContent: 'center',
        width: '100%',
    },
    content: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        maxWidth: '1200px',
        margin: 'auto',
    },
    buttonRow: {
        marginBottom: '20px',
        marginTop: '20px',
    },
    checkRow: {
        margin: theme.spacing(1),
        display: 'flex',
        justifyContent: 'space-evenly',
    },
}));

Font.register({
    family: 'Oswald',
    src: 'https://fonts.gstatic.com/s/oswald/v13/Y_TKV6o8WovbUd3m_X9aAA.ttf'
});

// Create styles
const styles = StyleSheet.create({
    page: {
        flexDirection: 'column',
        fontFamily: 'Helvetica',
        padding: 20,
    },
    section: {
        //marginBottom: 10,
        padding: 10
    },
    dTable: {
        display: "table",
        width: "auto",
        border: '1 solid black',

        marginBottom: 10,
    },
    dTableRow: {
        margin: "auto",
        flexDirection: "row"
    },
    dTableCol: {
        flexGrow: 1,
    },
    dTableCell: {
        margin: "auto",
        // marginTop: 5,
        // marginBottom: 5,
        fontSize: 10,
    },
    table: {
        border: '1 solid black',
        padding: 10,
        flexGrow: 1,
        fontSize: 14,
        flexDirection: 'column',
        justifyContent: 'space-between',
    },
    tableRow: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginTop: 2,
        marginBottom: 2,
    },
    tableText: {
        fontSize: 12,
    },
    tableTextSmall: {
        fontSize: 10,
    },
    tableTextBold: {
        fontSize: 12,
        fontFamily: 'Helvetica-Bold',
        fontWeight: 'bold',
    },
    tableTextBoldUnderline: {
        fontSize: 12,
        fontFamily: 'Helvetica-Bold',
        fontWeight: 'bold',
        textDecoration: 'underline'
    },
    summary: {
        flexDirection: 'row',
    },
    viewer: {
        width: '100%',
        maxWidth: '1200px',
        height: '85vh',
    },
    boldUnderlineHeader: {
        fontWeight: 'bold', 
        textDecoration: 'underline',
        fontSize: '14px', 
        marginBottom: '5px'
    }
});

// Create Document Component
const UsersPDF = (props) => {

    const classes = useStyles();

    /**
     * @param title - Table Header
     * @param columns - Generates table column header and its internal data. Can pass either an objects key to iterate or a function.
     * @param data - The table data passed (as an array of objects)
     * @param components - React components passed as special rendering for additional rows 
     */

    //fuck total yard works naming ~
    const blackList = ["Total  Yard works ", "App Review DO NOT DELETE", "Derek Testing Account", "App Store Review", "JAR "]

    const regulerUsersData = props.location.state.userData.filter(x=> !blackList.some((item) => { return x.displayName.toLowerCase().includes(item.toLowerCase())})).map((document) => {
        return (
            <View>
                <Text style={{...styles.tableText}}>{document.displayName}</Text>
            </View>
        )
    })

    const specialCaseUsersData = props.location.state.userData.filter(x=> blackList.some((item) => { return x.displayName.toLowerCase().includes(item.toLowerCase())})).map((document) => {
        return (
            <View>
                <Text style={{...styles.tableText}}>{document.displayName}</Text>
            </View>
        )
    })

    return (
        <div className={classes.root}>
            <div className={classes.content}>

                <div className={classes.buttonRow}>
                    <Button 
                        variant='contained' 
                        onClick={()=> {props.history.goBack()}}
                        startIcon={<ArrowBackIcon />}
                    >
                        Back
                    </Button>
                </div>
            
                <PDFViewer style={styles.viewer}>
                    <Document>
                        <Page size="A4" style={styles.page} wrap>
                            <View style={styles.section}>
                                <View style={{ ...styles.tableRow}}>
                                    <Text style={{...styles.tableText, fontSize: '25px'}}>{props.location.state.status.charAt(0).toUpperCase() + props.location.state.status.slice(1) + " Users"}</Text>
                                </View>
                            </View>

                            <View style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
                                <View style={{padding: '10px', }}>
                                    <View >
                                        <Text style={{...styles.tableText, fontSize: '20px', marginBottom: '5px'}}>Regular Users</Text>
                                    </View>
                                    {regulerUsersData}
                                </View>


                                <View style={{padding: '10px',}}>
                                    <View >
                                        <Text style={{...styles.tableText, fontSize: '20px', marginBottom: '5px'}}>Special Accounts</Text>
                                    </View>
                                    {specialCaseUsersData}
                                </View>
                            </View>
                        </Page>
                    </Document>
                </PDFViewer>
            </div>
        </div>
    )
};

export default withRouter(UsersPDF);