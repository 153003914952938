import React, {useEffect} from 'react';
import { Checkbox, FormControlLabel, makeStyles } from '@material-ui/core';
import { Button, Grid, TextField, Typography, Paper, InputAdornment, Divider, Select, MenuItem, InputLabel, FormControl, Modal } from '@material-ui/core';
import { withRouter } from 'react-router-dom';
import PaperTitle from '../Orders/OrderPage/PaperTitle';
//import _ from 'lodash'
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
//import { v4 as uuidv4 } from 'uuid'
import QRCode from 'qrcode.react';
import CasinoIcon from '@material-ui/icons/Casino';
import moment from 'moment';

import bigLogo from '../../../img/bigLogo.png'
import truck from '../../../img/geeteetruck.png'
import html2canvas from 'html2canvas';
import GetAppIcon from '@material-ui/icons/GetApp';

import { functions, uploadFile } from '../../../firebase/firebase';
import { GiftCardEmail } from './GiftCardEmail';

import EmailIcon from '@material-ui/icons/Email';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import EditIcon from '@material-ui/icons/Edit';

const useStyles = makeStyles((theme) => ({
    btnGroup: {
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
    },
    alias: {
        display: 'flex',
        marginTop: '8px',
        marginBottom: '8px',
        alignItems: 'center',
    },
    card: {
        padding: theme.spacing(2),
        display: 'flex',
        alignItems: 'center',
        height: '100%',
    },
    content: {
        display: 'flex',
        width: '100%',
        flexDirection: 'column',
        maxWidth: '792px',
        margin: 'auto',
    },
    paper: {
        //borderRadius: '0px',
        margin: '0px',
        marginBottom:'20px',
        width: '100%',
        maxWidth: '1000px',
        padding: '16px',
       // backgroundColor: 'rgb(252, 252, 249)',
    },
    row: {
        display: 'flex',
        alignItems: 'center',
    },
    backButton: {
        marginBottom: '20px',
        marginTop: '20px',
    },
}));

const GiftCardsCard = (props) => {

    const classes = useStyles();
    const [settings, setSettings] = React.useState({
        showCode: true,
        cardValue: 'Total Value',
        firstLine: 'Gee Tee Holdings',
        secondLine: 'Digital Gift Card',
        firstFontSize: '30px',
        secondFontSize: '30px',
    })
    const [edit, setEdit] = React.useState(false);
    const [emailAddress, setEmaiLAddress] = React.useState('');
    const [deleteOpen, setDeleteOpen] = React.useState(false);
    const [emailOpen, setEmailOpen] = React.useState(false);
    const [sendingEmail, setSendingEmail] = React.useState(false);
    const sendEmailFunction = functions.httpsCallable('sendPremadeEmail');

    const handleImageDownload = async () => {
        console.log("ATTEMPT DOWNLOAD")
        const element = document.getElementById('print'),
            canvas = await html2canvas(element, {
                backgroundColor: null, // Capture transparent background
                logging: true // Enable logging for debugging
            }),
            data = canvas.toDataURL('image/png'),
            link = document.createElement('a');
    
        link.href = data;
        link.download = `GT_GIFTCARD_${props.giftCard.code}.png`;
    
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    ///function to save image to variable to send in email
    const saveImage = async () => {
        const element = document.getElementById('print'),
            canvas = await html2canvas(element, {
                backgroundColor: null, // Capture transparent background
                logging: true // Enable logging for debugging
            }),
            data = canvas.toDataURL('image/png');
        return data;
    }

    const displayImage = async () => {
       //save image to firebase storage using uploadfile
            const image = await saveImage();
            const blob = await fetch(image).then(r => r.blob());
            const file = new File([blob], `GT_GIFTCARD_${props.giftCard.code}.png`, { type: blob.type });
            const imageUrl = await uploadFile('TempGiftCards/'+props.giftCard.code+'/GeeTeeGiftCard.png', file);

            return imageUrl;
    }

    const getImageFile = async () => {
        //download image file from firebase storage
        const image = await saveImage();
        const blob = await fetch(image).then(r => r.blob());
        const file = new File([blob], `GeeTeeGiftCard.png`, { type: blob.type });
        return file;
    }

    useEffect(() => {
        
    }, [settings, edit, deleteOpen])

    return (
        <Grid className={classes.content}>
            <Modal open={emailOpen} onClose={()=>setEmailOpen(false)}>
                <Paper style={{position:'absolute',top:'50%',left:'50%',transform:'translate(-50%,-50%)',padding:'20px',width:'500px'}}>
                    <Typography variant="h6">Send Gift Card Email</Typography>
                    <TextField
                        variant="outlined"
                        label="Email Address"
                        fullWidth
                        value={emailAddress}
                        onChange={(e)=>setEmaiLAddress(e.target.value)}
                    />
                    <br/>
                    <br/>
                    <Button variant="contained" disabled={sendingEmail} onClick={()=> {
                       
                       setSendingEmail(true);
                       (async () => {
                           try {
                               // Retrieve the image URL
                               const imageUrl = await displayImage();
                               const imageFile = await getImageFile();

                               console.log("Image File:",imageFile)
                       
                               // Send the email with the attached image file
                               const result = await sendEmailFunction({
                                    email: emailAddress,
                                    subject: 'Your Gee Tee Holdings Digital Gift Card',
                                    html: GiftCardEmail({giftCard: props.giftCard, giftCardImage: imageUrl}),
                                    attachments: [
                                        {
                                            filename: 'GeeTeeGiftCard.png',
                                            //content: imageFile,
                                            path: imageUrl.url,
                                            contentType: 'image/png',
                                        }
                                    ]
                               });
                               
                               console.log("Email Sent:", result); // Log the result of sending the email
                               setSendingEmail(false);
                               setEmailOpen(false);
                           } catch (error) {
                               console.error("Error Sending Email:", error); // Log any errors that occur
                               setSendingEmail(false);
                           }
                       })();
                       
                    }}>{sendingEmail ? 'Sending...' : 'Send Email'}</Button>
                    <Button variant="outlined" onClick={()=>setEmailOpen(false)}>Cancel</Button>
                </Paper>
            </Modal>

            <Grid className={classes.backButton}>
                <Button variant="contained" onClick={() => props.history.goBack()} startIcon={<ArrowBackIcon/>}>Back</Button>
            </Grid>
           
            <Paper className={classes.paper}>
                <Grid container >
                    {props.type === 'New' 
                    ?<React.Fragment>
                        <Grid container style={{margin:'10px'}}>
                            <Grid item xs={6}>
                                <TextField 
                                    variant="outlined"
                                    label="Name"
                                    fullWidth
                                    value={props.giftCard.name} 
                                    onChange={(e)=> {
                                        props.updateGiftCard({...props.giftCard, name: e.target.value})
                                    }} 
                                />
                            </Grid>
                        </Grid>
                        <Grid container style={{margin:'10px'}}>
                            <Grid item xs={6}>
                                <TextField 
                                    variant="outlined"
                                    label="Gift Code"
                                    fullWidth
                                    value={props.giftCard.code} 
                                    onChange={(e)=> {
                                        props.updateGiftCard({...props.giftCard, code: e.target.value})
                                    }} 
                                />
                            </Grid>
                            <Grid item xs={6} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                    <Button
                                        variant="contained"
                                        size="large"
                                        startIcon={<CasinoIcon />}
                                        onClick={()=> {
                                            props.updateGiftCard({...props.giftCard, code: props.createCode()})
                                        }}
                                    >
                                        Generate
                                    </Button>
                            </Grid>
                            

                        </Grid>
                        
                        <Grid container style={{margin:'10px'}}>
                            <Grid item xs={6}>
                                <TextField 
                                    variant="outlined"
                                    label="Dollar Value"
                                    fullWidth
                                    value={props.giftCard.totalValue} 
                                    onChange={(e)=> {
                                        props.updateGiftCard({...props.giftCard, totalValue: Number(e.target.value)})
                                    }} 
                                    InputProps={{
                                        type: 'decimal',
                                        min: 0,
                                        startAdornment: <InputAdornment position="start">$</InputAdornment>
                                    
                                    }}
                                />
                            </Grid>
                        </Grid>
                        <Grid container style={{margin:'10px'}}>
                            <Grid item xs={12}>
                                <TextField 
                                    variant="outlined"
                                    label="Description"
                                    multiline
                                    rows={4}
                                    placeholder='Optional Description'
                                    fullWidth
                                    value={props.giftCard.description} 
                                    onChange={(e)=> {
                                        props.updateGiftCard({...props.giftCard, description: e.target.value})
                                    }} 
                                />
                            </Grid>
                        </Grid>
                        <Grid container style={{margin:'10px'}}>
                            <Button variant="contained"
                                disabled={props.giftCard.code === '' || props.giftCard.totalValue === ''}
                                onClick={(e)=>{
                                    props.updateGiftCard({...props.giftCard, currentValue: Number(props.giftCard.totalValue), dateCreated: moment().format('MM/DD/YYYY')});
                                    props.createForm(e, props.giftCard.code);
                                    
                                }}
                            >Create New Gift Card</Button>
                        </Grid>
                        </React.Fragment>
                        
                    :
                    <React.Fragment >
                        <Grid container style={{margin:'0px'}}>
                            <Grid item xs={6}>
                                {!edit?<PaperTitle title={props.giftCard.name}/>:
                                <TextField
                                    variant="outlined"
                                    label="Title"
                                    fullWidth
                                    value={props.giftCard.name}
                                    onChange={(e)=> {
                                        props.setGiftCard({...props.giftCard, name: e.target.value})
                                    }}
                                    disabled={!edit}
                                />}

                            </Grid>

                            <Grid item xs={6} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                <QRCode value={props.giftCard.code} style={{margin:'0px'}}
                                bgColor={"#FFF"}
                                size={128}
                                fgColor={"#000"}
                                includeMargin={false}
                               // imageSettings={{
                               //   src: smalllogo,
                               //   height: 32,
                               //   width: 32,
                               //   excavate: true,
                              //  }}
                                renderAs={"svg"}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Button startIcon={<EmailIcon/>} variant="contained" style={{marginRight:'5px', backgroundColor:'#346beb', color:'white'}} onClick={()=>setEmailOpen(!emailOpen)} >{emailOpen ? 'Cancel' : 'Send in Email'}</Button>
                                <Button startIcon={<EditIcon/>} variant="contained" onClick={()=>setEdit(!edit)} >{edit ? 'Cancel' : 'Edit'}</Button>
                                <Button startIcon={<DeleteForeverIcon/>} variant={deleteOpen?"outlined":"contained"} style={deleteOpen?{marginLeft:'5px'}:{color:'white',backgroundColor:'red',marginLeft:'5px'}} onClick={()=>setDeleteOpen(!deleteOpen)} >{deleteOpen ? 'Cancel' : 'Delete'}</Button>
                            </Grid>
                            {(deleteOpen && props.giftCard.currentValue <= 0.00) || (deleteOpen && props.giftCard.currentValue > props.giftCard.totalValue)?
                            <Grid item xs={12} style={{marginTop:'10px'}}>
                                
                                    <Typography variant="body1">Are you sure? This is permanent.</Typography>
                                    <Divider/>
                                    <Button variant="contained" style={{backgroundColor:'red',color:'white'}} onClick={()=>props.deleteGiftCard(props.giftCard.code)}>Delete It!</Button>
                                    <Button variant="outlined" style={{marginLeft:'5px'}} onClick={()=>setDeleteOpen(false)}>Cancel</Button>
                            </Grid>:null}
                            {deleteOpen && props.giftCard.currentValue > 0.00 && props.giftCard.currentValue < props.giftCard.totalValue ?
                            <Grid item xs={12} style={{marginTop:'10px'}}>
                                
                                    <Typography variant="small">Are you sure? This is permanent. According to Manitoba laws around Gift Cards, they cannot have Expiry Dates.</Typography>
                                    <Typography variant="small">Please only continue if this Gift Card has NOT been given out yet.</Typography>
                                    <Divider/>
                                    <Button variant="contained" style={{backgroundColor:'red',color:'white'}} onClick={()=>props.deleteGiftCard(props.giftCard.code)}>Delete It!</Button>
                                    <Button variant="outlined" style={{marginLeft:'5px'}} onClick={()=>setDeleteOpen(false)}>Cancel</Button>
                            </Grid>:null}
                            {deleteOpen && props.giftCard.currentValue === props.giftCard.totalValue ?
                            <Grid item xs={12} style={{marginTop:'10px'}}>
                                
                                    <Typography variant="small">Are you sure? This is permanent. According to Manitoba laws around Gift Cards, they cannot have Expiry Dates.</Typography>
                                    <Typography variant="small">Please only continue if this Gift Card has NOT been given out yet.</Typography>
                                    <Divider/>
                                    <Button variant="contained" style={{backgroundColor:'red',color:'white'}} onClick={()=>props.deleteGiftCard(props.giftCard.code)}>Delete It!</Button>
                                    <Button variant="outlined" style={{marginLeft:'5px'}} onClick={()=>setDeleteOpen(false)}>Cancel</Button>
                            </Grid>:null}
                        </Grid>
                        <Grid container style={{margin:'10px'}}>
                            <Grid item xs={12}>
                                <Typography variant="body1">Code</Typography>
                                <Typography variant="body1"><b>{props.giftCard.code}</b></Typography>
                            </Grid>
                            <Grid item xs={6}>
                                {!edit?<Typography variant="body1">Dollar Value</Typography>:null}
                                {!edit?<Typography variant="body1"><b>${Number(props.giftCard.currentValue).toFixed(2)}/${Number(props.giftCard.totalValue).toFixed(2)}</b></Typography>:
                                <Grid container style={{marginBottom:'8px',marginTop:'8px'}}>
                                    <Grid item xs={4}>
                                        <TextField 
                                        variant="outlined"
                                        label="Current Value"
                                        type='number'
                                        
                                        value={props.giftCard.currentValue} 
                                        onChange={(e)=> {
                                            props.setGiftCard({...props.giftCard, currentValue: Number(e.target.value)})
                                        }} 
                                        disabled={!edit}
                                        />
                                    </Grid>
                                    <Grid item xs={4}>
                                        <TextField 
                                            variant="outlined"
                                            label="Total Value"
                                            type='number'
                                            
                                            value={props.giftCard.totalValue} 
                                            onChange={(e)=> {
                                                props.setGiftCard({...props.giftCard, totalValue: Number(e.target.value)})
                                            }} 
                                            disabled={!edit}
                                        />
                                    </Grid>
                            </Grid>
                            }
                            </Grid>
                            <Grid item xs={6}>
                                <Typography variant="body1">Date Created</Typography>
                                <Typography variant="body1"><b>{props.giftCard.dateCreated}</b></Typography>
                            </Grid>
                            
                            <Grid item xs={12}>
                                <Divider/>
                                {!edit?<Typography variant="body1">{props.giftCard.description}</Typography>:
                                <TextField 
                                    variant="outlined"
                                    label="Description"
                                    fullWidth
                                    value={props.giftCard.description} 
                                    onChange={(e)=> {
                                        props.setGiftCard({...props.giftCard, description: e.target.value})
                                    }} 
                                    disabled={!edit}
                                />}
                            </Grid>
                        </Grid>
                    <Grid container style={{marginTop:'10px'}}>
                        <Grid item xs={12} style={{marginBottom:'10px'}}>
                            
                        </Grid>
                        <Grid item xs={6} style={{margin:'4px'}}>
                            <TextField
                                variant={'outlined'}
                                label={'First Text Line'}
                                fullWidth
                                value={settings.firstLine}
                                onChange={(e) => setSettings({...settings, firstLine: e.target.value})}
                            />
                        </Grid>
                        <Grid item xs={5} style={{margin:'4px'}}>
                            <FormControlLabel
                                control={<Checkbox checked={settings.showCode} onChange={(e) => setSettings({...settings, showCode: e.target.checked})} />}
                                label="Show Code"
                            />
                        </Grid>
                        <Grid item xs={6} style={{margin:'4px'}}>
                            <TextField
                                variant={'outlined'}
                                label={'Second Text Line'}
                                fullWidth
                                value={settings.secondLine}
                                onChange={(e) => setSettings({...settings, secondLine: e.target.value})}
                            />
                        </Grid>

                        <Grid item xs={5} style={{margin:'4px'}}>
                        <FormControl fullWidth>
                        <InputLabel>Gift Card Value</InputLabel>
                        <Select
                                style={{minWidth:'100%'}}
                                value={settings.cardValue}
                                fullWidth
                                label='Card Value'
                                variant='outlined'
                                onChange={(e)=> {
                                    setSettings({...settings, cardValue: e.target.value})
                                }}
                            >
                                <MenuItem value='Total Value'>Total Value</MenuItem>
                                <MenuItem value='Current Value'>Current Value</MenuItem>
                                <MenuItem value='Both'>Both</MenuItem>
                            </Select>
                        </FormControl>
                        </Grid>

                        <Grid item xs={12} style={{ display: 'flex', justifyContent: 'center', margin:'20px' }}>
                            <div id="print">
                            <Paper
                                style={{
                                position: 'relative',
                                    backgroundImage: `url(${bigLogo})`,
                                    backgroundSize: 'cover',
                                    width: '450px',
                                    height: '300px',
                                    cursor: 'pointer',
                                    borderRadius: '10px',
                                    boxShadow:'0px 0px 0px 0px rgba(0,0,0,0)',
                                    //border: '1px solid #FFBF00'
                                }}
                                
                                >
                                <Grid>
                                    <QRCode value={props.giftCard.code}
                                        bgColor={"#FFBF00"}
                                        size={128}
                                        fgColor={"#000"}
                                        includeMargin={false}
                                        renderAs={"canvas"}
                                        style={{
                                            position: 'absolute',
                                            left: '10px',
                                            top: '10px',
                                        }}
                                        src={truck}
                                        class='card-item__chip'
                                        alt='credit card chip'
                                    />
                                    <Typography
                                    style={{
                                        position: 'absolute',
                                        right: '25px',
                                        top: '0px',
                                        height: '50px',
                                        fontFamily: 'MurrayHill',
                                        fontSize:settings.firstFontSize,
                                        fontWeight: 'bold',
                                        fontStyle: 'italic',
                                        color:'black',
                                    }}
                                    //src='https://raw.githubusercontent.com/muhammederdem/credit-card-form/master/src/assets/images/visa.png'
                                    class='card-item__chip'
                                    alt='credit card chip'
                                    >{settings.firstLine}</Typography>
                                    <Typography
                                    style={{
                                        position: 'absolute',
                                        right: '25px',
                                        top: '25px',
                                        height: '50px',
                                        fontFamily: 'MurrayHill',
                                        fontSize:settings.secondFontSize,
                                        fontWeight: 'bold',
                                        fontStyle: 'italic',
                                        color:'black',
                                    }}
                                    //src='https://raw.githubusercontent.com/muhammederdem/credit-card-form/master/src/assets/images/visa.png'
                                    class='card-item__chip'
                                    alt='credit card chip'
                                    >{settings.secondLine}</Typography>
                                </Grid>
                                <Grid
                                    style={{
                                    height: '100%',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center'
                                    }}
                                >
                                </Grid>

                                <Grid>
                                    <Typography
                                    style={{
                                        color: 'black',
                                        position: 'absolute',
                                        bottom: '10px',
                                        left: '10px',
                                        fontSize: '40px',
                                        fontWeight:'bold',
                                        fontFamily:'Roboto'
                                    }}
                                    >
                                        {settings.cardValue === 'Total Value' ?
                                    '$'+Number(props.giftCard.totalValue).toFixed(2) :
                                        settings.cardValue === 'Current Value' ?
                                    '$'+Number(props.giftCard.currentValue).toFixed(2) :
                                    '$'+Number(props.giftCard.currentValue).toFixed(2)+'/'+Number(props.giftCard.totalValue).toFixed(2)

                                }
                                    </Typography>
                                    <Typography
                                    style={{
                                        color: 'black',
                                        position: 'absolute',
                                        bottom: '90px',
                                        left: '10px',
                                       
                                        fontSize: '18px',
                                        fontWeight:'bold'
                                    }}
                                    >
                                    {settings.showCode ? props.giftCard.code : ''}
                                    </Typography>
                                    <Typography
                                    style={{
                                        color: 'black',
                                        position: 'absolute',
                                        bottom: '2px',
                                        left: '120px',
                                       
                                        fontSize: '18px',
                                        fontWeight:'bold'
                                    }}
                                    >
                                    {'Redeemable Online Only'}
                                    </Typography>
                                </Grid>
                                
                                </Paper>
                                </div>
                        </Grid>
                        <Grid item xs={12} style={{ display: 'flex', justifyContent: 'center', marginTop:'10px' }}>
                            <Button style={{fontWeight:'bold'}} startIcon={<GetAppIcon/>} variant="contained" onClick={handleImageDownload}>Download as Image</Button>
                        </Grid>
                    </Grid>
                    </React.Fragment>
                    } 
        

                </Grid>
            </Paper>




        </Grid>
    )
}

export default withRouter(GiftCardsCard)