import React from 'react';
import { Grid, makeStyles, Button } from '@material-ui/core';
import { withRouter } from 'react-router';
import { useCoupon } from '../../../providers/CouponsProvider'
import { TableIcons } from '../../../constants/tableIcons';
import MaterialTable from '@material-table/core';
import { useAuth } from '../../../providers/AuthContext';
import AddBoxIcon from '@material-ui/icons/AddBox';

const useStyles = makeStyles((theme) => ({

  card: {
    padding: theme.spacing(4),
    width: '100%',
    height: '100%',
  },

  content: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    //padding: theme.spacing(2),
  },

  grid: {
    marginTop: theme.spacing(2),
    maxWidth: '1200px',
  },

  img: {
    width: '64px',
    height: '64px',
    border: '1px solid #0000004a',
    borderRadius: '8px',
  },

  table: {
    display: 'flex',
    justifyContent: 'center',
  },

  tile: {
    width: '300px',
    height: '300px',
  },

  tileContent: {
    display: 'flex',
    justifyContent: 'space-evenly',
    alignItems: 'center',
    flexDirection: 'column',
    width: '100%',
    height: '100%',
    padding: theme.spacing(4),
  },

  tileIcon: {
    color: 'rgba(0, 0, 0, 0.54)',
    width: '100px',
    height: '100px',
  },

  tileText: {
    fontSize: '1.10rem',
  },
  btn: {
    marginBottom: '20px',
    marginTop: '20px',
},
}));

const CouponHome = (props) => {

  const [searchBarValue, setSearchBarValue] = React.useState("couponSearchBarValue" in sessionStorage ? JSON.parse(sessionStorage.getItem("couponSearchBarValue")) : null)

  const { coupons, loading, error } = useCoupon();
  const { roles } = useAuth();

  const classes = useStyles();

  const tileClickHandler = (path, searchParams) => {
    props.history.push({
      pathname: "coupons/" + path,
      search: searchParams && "?" + new URLSearchParams({ docId: searchParams }).toString(),
    })
  };
  return (
    <div className={classes.content}>
      
      {!loading && !error ?

      <Grid>
        <Grid className={classes.btn} >
            <Button variant="contained" disabled={!roles.includes('Create|Update|Delete Products')}
            startIcon={<AddBoxIcon/>} onClick={() => {tileClickHandler('coupon', 'new')}}>Create New Coupon</Button>
        </Grid> 
          
          <Grid item sm={12} xs={12} className={classes.table}>
            <MaterialTable
              icons={TableIcons}
              actions={[
                {
                  icon: TableIcons.OpenInNew,
                  tooltip: 'Show ' + props.title,
                  onClick: (event, rowData) => { tileClickHandler('coupon', rowData.docId) }
                },
              ]}
              columns={[
                { title: 'Code', field: 'code', render: (rowData) => <b>{rowData.code.toUpperCase()}</b> },
                { title: 'Description', field: 'description' },
                { title: 'Discount', render: (rowData) => <p>{(rowData.discountType === "flat" ? "$" : "") + rowData.discount + (rowData.discountType === "percentage" ? "%" : "")} off</p> },
                { title: 'Active', render: (rowData) => rowData.active ? 'Yes' : 'No' },
                { title: 'Variants Affected', render: (rowData) => rowData.global ? 'All' : rowData.category.onlyCategory ? rowData.category.value : rowData.productsAffected ? rowData.productsAffected.length : '0' },
              ]}
              data={coupons}
              title={"Coupon Manger"}
              pathname='/dashboard/coupons/coupon'
              onSearchChange={ (value) => {setSearchBarValue(value);  sessionStorage.setItem("couponSearchBarValue", JSON.stringify(value))}}
              options={{
                toolbar: true,
                paging: true,
                pageSizeOptions: false,
                pageSize: 5,
                actionsColumnIndex: -1,
                search: true,
                emptyRowsWhenPaging: false,
                searchText: searchBarValue,
                rowStyle: {
                  whiteSpace: 'pre-wrap',
                },
              }}
            />
          </Grid>
          
      </Grid>
      : null}
    </div>
  )
}

export default withRouter(CouponHome);