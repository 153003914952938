

import React from 'react';
import { withRouter } from 'react-router-dom';
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import Divider from '@material-ui/core/Divider'
import { makeStyles, Typography } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    card: {
        padding: theme.spacing(4),
        width: '100%',
        height: '100%',
        display: 'flex',
    },
}));

const TimesheetMeta = (props) => {
    const classes = useStyles()
    return (
        <Paper className={classes.card}>

            {props.submitted ?
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        Timesheet Hours
                    </Grid>
                    <Grid item xs={6} style={{ textAlign: 'end' }}>
                        {props.totalTimesheetTime}
                    </Grid>

                    <Grid item xs={6}>
                        Operator Hours
                    </Grid>
                    <Grid item xs={6} style={{ textAlign: 'end' }}>
                        {props.operatorHours}
                    </Grid>

                    <Grid item xs={6}>
                        Job Time
                    </Grid>
                    <Grid item xs={6} style={{ textAlign: 'end' }}>
                        {props.totalJobTime}
                    </Grid>

                    <Grid item xs={6}>
                        Expanded Time
                    </Grid>
                    <Grid item xs={6} style={{ textAlign: 'end' }}>
                        {props.totalExpandedTime}
                    </Grid>

                    <Grid item xs={6}>
                        <b>Total Admin Time &nbsp;&nbsp;   <small style={{fontSize:'12px'}}>(Time put in by Admin)</small></b>
                    </Grid>
                    <Grid item xs={6}  style={{ textAlign: 'end' }}>
                        <b>{props.adminTime}</b>
                    </Grid>

                    <Grid item xs={6}>
                        <b>Total Job Time &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;   <small style={{fontSize:'12px'}}>(NOT Including Admin Time)</small></b>
                    </Grid>
                    <Grid item xs={6} style={{ textAlign: 'end' }}>
                        <b>{(parseFloat(props.totalJobTime) + parseFloat(props.totalExpandedTime)).toFixed(2)}</b>
                    </Grid>
                    <Grid item xs={12}>
                        <Divider />
                    </Grid>


                    {props.jobCount > 0 &&
                        <Grid item xs={6}>
                            Jobs:
                        </Grid>
                    }
                    {props.jobCount > 0 &&
                        <Grid item xs={6} style={{ textAlign: 'end' }}>
                            {props.jobCount}
                        </Grid>
                    }

                    {props.deliveries > 0 &&
                        <Grid item xs={6}>
                            Deliveries:
                        </Grid>
                    }
                    {props.deliveries > 0 &&
                        <Grid item xs={6} style={{ textAlign: 'end' }}>
                            {props.deliveries}
                        </Grid>
                    }
          
                    <Grid item xs={6}>
                        Units:
                    </Grid>
                    <Grid item xs={6} style={{ textAlign: 'end' }}><b>{props.units}</b></Grid>
                    {
                        props.unitData.map((unit) => {
                            
                                let fullHours = 0.00
                                
                                unit.jobTimes && unit.jobTimes.length !==0 ? unit.jobTimes.forEach(time => {
                                    
                                    fullHours = (Number(time.hours) + Number(fullHours))
                                }) : fullHours = Number(props.totalJobTime)
                                return ( <React.Fragment>
                                
                                
                                    
                                <Grid key={unit} item xs={12} style={{ textAlign: 'end' }}><b>Unit {unit.unitNumber}</b> Total Time: {fullHours ? fullHours.toFixed(2) : 0.00}</Grid>
                                </React.Fragment>
                                )
                            })
                            
                        
                    }
                    
                </Grid> :
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Typography>Timesheet Not Submitted</Typography>
                    </Grid>
                </Grid>
            }




        </Paper >
    )
}
export default withRouter(TimesheetMeta)