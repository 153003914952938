import React, { useState, useCallback, useEffect } from 'react';
import { makeStyles } from '@material-ui/core';
import { Button, InputLabel, MenuItem, Grid, Paper, TextField, Typography,
 Select, Divider, FormControl, Snackbar, CircularProgress, Modal, FormControlLabel, Checkbox, LinearProgress, withStyles } from '@material-ui/core';
import { withRouter } from 'react-router-dom';
import { firestore, storage } from '../../../../firebase/firebase'
import { Autocomplete } from '@material-ui/lab';
import moment from 'moment';
import _ from 'lodash'
import PrintIcon from '@material-ui/icons/Print';
import PaperTitle from '../../Orders/OrderPage/PaperTitle';
import { auth } from "../../../../firebase/firebase";
import { useUnitDescription } from '../../../../providers/UnitDescriptionContext'
import UserDocumentTable from '../Tables/UserDocumentTable';
import NewDocReport from '../../PDFs/NewDocReport';
import Dropzone from 'react-dropzone'
import PublishIcon from '@material-ui/icons/Publish';
import ClearIcon from '@material-ui/icons/Clear';
import { KeyboardDatePicker } from "@material-ui/pickers";
import Img from 'react-cool-img';
import GetAppIcon from '@material-ui/icons/GetApp';

// Create styles
const BorderLinearProgress = withStyles((theme) => ({
    root: {
      height: 10,
      borderRadius: 5,
      border: '1px solid white',
    },
    colorPrimary: {
      backgroundColor: 'grey',
    },
    bar: {
      borderRadius: 5,
      backgroundColor: '#FFBF00',
    },
  }))(LinearProgress);

const useStyles = makeStyles((theme) => ({

    btnGroup: {
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
    },

    tabBar: {
        width: '100%',
        maxWidth: '1000px',
        
    },

    tabs:{
        [theme.breakpoints.up("md")]: {
          margin:'auto'
        }
        
    },

    alias: {
        display: 'flex',
        marginTop: '8px',
        marginBottom: '8px',
        alignItems: 'center',
    },

    card: {
        padding: theme.spacing(4),
        display: 'flex',
        alignItems: 'center',
        height: '100%',
    },

    content: {
        display: 'flex',
        justifyContent: 'center',
        width: '100%',
    },

    paper: {
        padding: '20px',
        borderRadius: '0px',
        margin: '10px',
        width: '100%',
        maxWidth: '1000px',
        //backgroundColor: 'rgb(252, 252, 249)',
    },

    row: {
        display: 'flex',
        alignItems: 'center',
    },
}));

const InformationTab = (props) => {
    const classes = useStyles();
    const [userData, setUserData] = useState(props.userData);
    const [error, setError] = useState("");
    const [saving, setSaving] = useState(false);
    const [saveState, setSaveState] = useState(props.saveState);
    const [roles, setRoles] = useState([]);
    const [rolesLoading, setRolesLoading] = useState(true);
    const [open, setOpen] = useState(false);
    const [open1, setOpen1] = useState(false);
    const [open2, setOpen2] = useState(false);
    const [open3, setOpen3] = useState(false);
    const [typedValue, setTypedValue] = useState('')
    const [typedValue1, setTypedValue1] = useState('')
    const [typedValue2, setTypedValue2] = useState('')
    const { units, unitLoading, getUnits } = useUnitDescription();
    const [newFrontPhoto, setNewFrontPhoto] = useState();
    const [newBackPhoto, setNewBackPhoto] = useState();

    const [frontProgress, setFrontProgress] = useState(0);
    const [backProgress, setBackProgress] = useState(0);
    const [uploading, setUploading] = useState(false);
    const [backUploading, setBackUploading] = useState(false);

    let dlnprefix = !_.isUndefined(props.userData) ? props.userData.lastName.substring(0,1).toUpperCase() + props.userData.lastName.substring(1,2).toUpperCase() + "-" 
    + props.userData.lastName.substring(2,3).toUpperCase() + props.userData.lastName.substring(3,4).toUpperCase() + "-" + props.userData.lastName.substring(4,5).toUpperCase() + props.userData.firstName.substring(0,1).toUpperCase() + "-"
    : '00-00-00-'

    let path = new URLSearchParams(props.location.search).get("docId");

    useEffect(() => {
        setRolesLoading(true);
        firestore.collection('roles').get()
          .then(querySnapshot => {
            setRoles(querySnapshot.docs.map((doc) => {
              return {
                ...doc.data(),
                'docId': doc.id,
              }
            }))
            setRolesLoading(false);
          })
          .catch(e => {
            setRolesLoading(false);
            console.log(e.message);
          })
    }, []);

    useEffect(() => {
        getUnits()
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    const updateUserData = useCallback((e) => {
            setUserData(e)
        },
        [],
    );

    const handleFrontUpload = async (acceptedFiles) => {
        const file = acceptedFiles[0];
        const storageRef = storage.ref();
        if (file) {
            setUploading(true)
            setNewFrontPhoto({ file, name: file.name });
            const path = storageRef.child(`DriversLicense/${userData.uuid}_front`);
            const uploadTask = path.put(file);
            uploadTask.on(
                "state_changed",
                (snapshot) => {
                    // Track progress here
                    const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                    setFrontProgress(progress);
                    console.log(`Upload is ${progress}% done`);
                },
                (error) => {
                    // Handle errors here
                    console.error("Error uploading file:", error);
                    setUploading(false)
                },
                async () => {
                    // Upload completed successfully
                    const url = await path.getDownloadURL();
                    setUserData({ ...userData, driversFrontURL: url });
                    //await firestore.collection("users").doc(userData.docId).update({ driversFrontURL: url });
                    setUploading(false)
                    setTimeout(() => { setFrontProgress(0) }, 3000);
                }
            );
        }
    };

      const handleBackUpload = async (acceptedFiles) => {
        const file = acceptedFiles[0];
        const storageRef = storage.ref();
        if (file) {
            setBackUploading(true)
            setNewBackPhoto({ file, name: file.name });
            const path = storageRef.child(`DriversLicense/${userData.uuid}_back`);
            const uploadTask = path.put(file);
            uploadTask.on(
                "state_changed",
                (snapshot) => {
                    // Track progress here
                    const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                    setBackProgress(progress);
                    console.log(`Upload is ${progress}% done`);
                },
                (error) => {
                    // Handle errors here
                    console.error("Error uploading file:", error);
                    setBackUploading(false)
                },
                async () => {
                    // Upload completed successfully
                    const url = await path.getDownloadURL();
                    setUserData({ ...userData, driversBackURL: url });
                    //await firestore.collection("users").doc(userData.docId).update({ driversBackURL: url });
                    setBackUploading(false)
                    setTimeout(() => { setBackProgress(0) }, 3000);
                }
            );
        }
      };


    const confirmSave = async () => {
        props.setUserData(userData);
        props.setSaveState(userData);
        setSaving(true);
        firestore.collection('users').doc(path)
            .update(userData)
            .then(() => {
                auth
                    .updateUser(userData.uuid, {
                        email: userData.email,
                    })
                    .then((userRecord) => {
                        // See the UserRecord reference doc for the contents of userRecord.
                        console.log('Successfully updated user', userRecord.toJSON());
                    })
                    .catch((error) => {
                        console.log('Error updating user:', error);
                    });
                setSaveState(userData);
                setSaving(false)
            })
            .catch((e) => {setError(e); console.log(error);});

    };

    const cancelSave = () => {
        setNewFrontPhoto()
        setNewBackPhoto()
        setUserData(saveState);
    };

    const changeHandler = (event) => {
        updateUserData({ ...userData, [event.target.name]: event.target.value })
    }

    const handleOpen = () => {
        setOpen3(true);
    };

    const handleClose = () => {
        setOpen3(false);
    };

    const substringText = (text) => {
        if(text.length > 20){
            return text.substring(0, 20) + '...'
        } else {
            return text
        }
    }

    return (
        <Grid container spacing={2}>
            <Modal
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
                className={classes.modal}
                onClose={handleClose}
                open={open3}
            >
                <Paper style={{ maxWidth:'600px', maxHeight:'700px', margin: '50px auto auto auto' }}>
                    <NewDocReport employee={userData} frontProgress={frontProgress} backProgress={backProgress} uploading={uploading} rolesLoading={rolesLoading} roles={roles}
                    updateUserData={updateUserData} handleFrontUpload={handleFrontUpload} handleBackUpload={handleBackUpload}/>
                </Paper>

            </Modal>

            <Grid item sm={12} xs={12}>
                <FormControlLabel
                    label="MFA Enabled"
                    style={{marginBottom: '20px'}}
                    control={
                        <Checkbox 
                            variant="contained" 
                            checked={userData.mfaEnabled}
                            onClick={(event) => {
                                updateUserData({ ...userData, mfaEnabled: event.target.checked })
                            }}>
                            
                        </Checkbox>
                    } 
                />
                <FormControlLabel
                    label="Schedule Visible"
                    style={{marginBottom: '20px'}}
                    control={
                        <Checkbox 
                            variant="contained" 
                            checked={userData.viewSchedule}
                            onClick={(event) => {
                                updateUserData({ ...userData, viewSchedule: event.target.checked })
                            }}>
                            
                        </Checkbox>
                    } 
                />

                {props.edit
                    ? <React.Fragment>
                        <Grid container spacing={2}>
                            <Grid item md={10}>
                            {props.userData.displayName
                                ? <TextField
                                    key="displayName"
                                    style={{ marginBottom: '8px' }}
                                    label='Display Name'
                                    name="displayName"
                                    value={userData.displayName}
                                    variant='outlined'
                                    onChange={(event) => { changeHandler(event) }}
                                    inputProps={{ style: { fontSize: '1.4rem' } }} />
                                : <React.Fragment>
                                    <TextField
                                        style={{ marginBottom: '8px' }}
                                        label='First Name'
                                        name={"firstName"}
                                        value={userData.firstName}
                                        variant='outlined'
                                        onChange={(event) => { changeHandler(event) }}
                                        inputProps={{ style: { fontSize: '2.125rem' } }} />
                                    <TextField
                                        label='Last Name'
                                        name={"lastName"}
                                        value={userData.lastName}
                                        variant='outlined'
                                        onChange={(event) => { changeHandler(event) }}
                                        inputProps={{ style: { fontSize: '2.125rem' } }} />
                                </React.Fragment>}
                            </Grid>
                            <Grid item md={4}>
                                <FormControl className={classes.formControl}>    
                                <InputLabel id="demo-controlled-open-select-label">Role</InputLabel>       
                                    <Select
                                        style={{width:'150px'}}
                                        value={userData.role}
                                        variant={"outlined"}
                                        onChange={(event) => { updateUserData({ ...userData, 'role': event.target.value }) }}
                                        label="Role"
                                        defaultValue=''
                                    >
                                        <MenuItem value="">
                                        <em>None</em>
                                    </MenuItem>    
                                     
                                    {roles?.map((role, index) => { return <MenuItem key={index} value={role.title}>{role.title}</MenuItem>  })}
                                    
                                    </Select>
                                </FormControl>
                            </Grid>
                        </Grid>

                        <Grid container spacing={2}>
                            <Paper className={classes.paper} style={{height: '100%', marign:'10px'}}>
                                <Grid container spacing={2}>
                                    <Typography variant="h5">General Info</Typography>
                                </Grid>
                                <hr></hr>
                                <Grid container spacing={2}>
                                    <Grid container>
                                        <Grid item xs={6} >
                                            <Typography style={{ lineHeight: 2 }} noWrap variant='body1'>Department:</Typography>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <FormControl className={classes.formControl}>
                                                
                                                <Select
                                                    native
                                                    value={userData.department}
                                                    onChange={(event) => { updateUserData({ ...userData, 'department': event.target.value }) }}
                                                >
                                                <option value="None" />   
                                                <option value={"Office"}>Office</option>
                                                <option value={"Driver/Trucker"}>Driver/Trucker</option>
                                                <option value={"Shop"}>Shop</option>
                                                <option value={"Construction"}>Construction</option>
                                                <option value={"Grain"}>Grain</option>
                                                </Select>
                                                </FormControl>
                                        </Grid>
                                        <Grid item xs={4} >
                                            <Typography style={{ lineHeight: 2 }} noWrap variant='body1'>Assigned Equipment:</Typography>
                                        </Grid>
                                        <Grid item xs={8}>
                                            <Autocomplete
                                                open={open}
                                                onOpen={() => {
                                                    setOpen(true);
                                                }}
                                                onClose={() => {
                                                    setOpen(false);
                                                }}
                                                loading={unitLoading}
                                                label="Search"
                                                id="autocomplete"
                                                autoComplete
                                                //disabled={!roles.includes('Create|Update|Delete Orders')}
                                                autoHighlight
                                                fullWidth
                                                style={{ maxWidth: '300px', width: '100%' }}
                                                inputValue={typedValue}
                                                onInputChange={(event, newValue) => { setTypedValue(newValue) }}
                                                value={userData?.commonEquipment.fullUnit}
                                                onChange={(event, newValue) => { updateUserData({ ...userData, 'commonEquipment': {...userData.commonEquipment, 'fullUnit':newValue, 'unit': newValue.unitNumber, 'description': newValue.description }}) }}
                                                options={units.map((unit) => unit).sort((a, b) => a > b)}
                                                getOptionLabel={(option) => "Unit #" + option.unitNumber + " - " + option.description}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        style={{ maxWidth: '300px', width: '100%' }}
                                                        label="Equipment"
                                                        //variant="outlined"
                                                        fullWidth
                                                        size='small'
                                                        InputProps={{
                                                            ...params.InputProps,
                                                            endAdornment: (
                                                                <React.Fragment>
                                                                    {unitLoading ? <CircularProgress color="inherit" size={20} /> : null}
                                                                    {params.InputProps.endAdornment}
                                                                </React.Fragment>
                                                            ),
                                                        }}
                                                    />
                                                )}
                                            />
                                        </Grid>
                                        <Grid item xs={2} >
                                            <Typography style={{ lineHeight: 2 }} noWrap variant='body1'>Trailers:</Typography>
                                        </Grid>
                                        <Grid item xs={4}>
                                            <Autocomplete
                                                open={open1}
                                                onOpen={() => {
                                                    setOpen1(true);
                                                }}
                                                onClose={() => {
                                                    setOpen1(false);
                                                }}
                                                loading={unitLoading}
                                                label="Search"
                                                id="autocomplete"
                                                autoComplete
                                                //disabled={!roles.includes('Create|Update|Delete Orders')}
                                                autoHighlight
                                                fullWidth
                                                style={{ maxWidth: '300px', width: '100%' }}
                                                inputValue={typedValue1}
                                                onInputChange={(event, newValue) => { setTypedValue1(newValue) }}
                                                value={userData?.commonEquipment.fullTrailer1}
                                                onChange={(event, newValue) => { updateUserData({ ...userData, 'commonEquipment': {...userData.commonEquipment, 'fullTrailer1':newValue, 'trailer1': newValue.unitNumber, 'description1': newValue.description }}) }}
                                                options={units.map((unit) => unit).sort((a, b) => a > b)}
                                                getOptionLabel={(option) => "Unit #" + option.unitNumber + " - " + option.description}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        style={{ maxWidth: '300px', width: '100%' }}
                                                        label="Trailer 1"
                                                        //variant="outlined"
                                                        fullWidth
                                                        size='small'
                                                        InputProps={{
                                                            ...params.InputProps,
                                                            endAdornment: (
                                                                <React.Fragment>
                                                                    {unitLoading ? <CircularProgress color="inherit" size={20} /> : null}
                                                                    {params.InputProps.endAdornment}
                                                                </React.Fragment>
                                                            ),
                                                        }}
                                                    />
                                                )}
                                            />
                                        </Grid>
                                        <Grid item xs={4}>
                                            <Autocomplete
                                                open={open2}
                                                onOpen={() => {
                                                    setOpen2(true);
                                                }}
                                                onClose={() => {
                                                    setOpen2(false);
                                                }}
                                                loading={unitLoading}
                                                label="Search"
                                                id="autocomplete"
                                                autoComplete
                                                //disabled={!roles.includes('Create|Update|Delete Orders')}
                                                autoHighlight
                                                fullWidth
                                                style={{ maxWidth: '300px', width: '100%' }}
                                                inputValue={typedValue2}
                                                onInputChange={(event, newValue) => { setTypedValue2(newValue) }}
                                                value={userData?.commonEquipment.fullTrailer2}
                                                onChange={(event, newValue) => { updateUserData({ ...userData, 'commonEquipment': {...userData.commonEquipment, 'fullTrailer2':newValue, 'trailer2': newValue.unitNumber, 'description2': newValue.description }}) }}
                                                options={units.map((unit) => unit).sort((a, b) => a > b)}
                                                getOptionLabel={(option) => "Unit #" + option.unitNumber + " - " + option.description}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        style={{ maxWidth: '300px', width: '100%' }}
                                                        label="Trailer 2"
                                                        //variant="outlined"
                                                        fullWidth
                                                        size='small'
                                                        InputProps={{
                                                            ...params.InputProps,
                                                            endAdornment: (
                                                                <React.Fragment>
                                                                    {unitLoading ? <CircularProgress color="inherit" size={20} /> : null}
                                                                    {params.InputProps.endAdornment}
                                                                </React.Fragment>
                                                            ),
                                                        }}
                                                    />
                                                )}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>

                            </Paper>
                        </Grid>

                        {props.permissions.includes('View User Personal Info') ?
                        <> 
                            <Grid container spacing={2}>
                                <Paper className={classes.paper} style={{height: '100%', marign:'10px'}}>
                                    <Grid container spacing={2}>
                                        <Typography variant="h5">Mailing Address</Typography>
                                    </Grid>
                                    <hr></hr>
                                    <Grid container spacing={2}>
                                        <Grid container>
                                            <Grid item xs={6} >
                                                <Typography style={{ lineHeight: 2 }} noWrap variant='body1'>Street Address:</Typography>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Typography style={{ lineHeight: 2}} align='right' variant='body1'>
                                                <TextField
                                                value={userData.address.street}
                                                size="small"
                                                onChange={(event) => { updateUserData({ ...userData, 'address': {...userData.address, 'street': event.target.value} }) }}
                                                />
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={6} >
                                                <Typography style={{ lineHeight:2}} noWrap variant='body1'>City:</Typography>
                                            </Grid>
                                            <Grid item xs={6}>
                                            <Typography style={{ lineHeight: 2}} align='right' variant='body1'>
                                                <TextField
                                                value={userData.address.city}
                                                size="small"
                                                onChange={(event) => { updateUserData({ ...userData, 'address': {...userData.address, 'city': event.target.value} }) }}
                                                />
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={6} >
                                                <Typography style={{ lineHeight: 2 }} noWrap variant='body1'>Province:</Typography>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Typography style={{ lineHeight: 2}} align='right' variant='body1'>
                                                    <FormControl className={classes.formControl}>
                                                
                                                    <Select
                                                        native
                                                        
                                                        value={userData.address.province}
                                                        onChange={(event) => { updateUserData({ ...userData, 'address': {...userData.address, 'province': event.target.value} }) }}
                                                        label="Age"
                                                    >
                                                    <option value={"Manitoba"}>MB</option>
                                                    <option value={"Saskatchewan"}>SK</option>
                                                    <option value={"British Columbia"}>BC</option>
                                                    <option value={"Ontario"}>ON</option>
                                                    <option value={"Alberta"}>AB</option>
                                                    <option value={"Quebec"}>QC</option>
                                                    <option value={"New Brunswick"}>NB</option>
                                                    <option value={"Nova Scotia"}>NS</option>
                                                    <option value={"Prince Edward Island"}>PE</option>
                                                    <option value={"Newfoundland & Labrador"}>NL</option>
                                                    <option value={"Yukon"}>YK</option>
                                                    <option value={"Northwest Territories"}>NT</option>
                                                    <option value={"Nunavut"}>NU</option>
                                                    </Select>
                                                    </FormControl>
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={6} >
                                                <Typography style={{ lineHeight: 2 }} noWrap variant='body1'>Postal Code:</Typography>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Typography style={{ lineHeight: 2}} align='right' variant='body1'>
                                                <TextField
                                                value={userData.address.postalCode}
                                                size="small"
                                                onChange={(event) => { updateUserData({ ...userData, 'address': {...userData.address, 'postalCode': event.target.value} }) }}
                                                />
                                                
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Divider style={{margin:'5px'}}></Divider>
                                </Paper>
                            </Grid>
                        

                            <Grid container spacing={2}>
                                <Paper className={classes.paper} style={{height: '100%', marign:'10px'}}>
                                    <Grid container spacing={2}>
                                        <Typography variant="h5">Personal Info</Typography>
                                    </Grid>
                                    <hr></hr>
                                    <Grid container spacing={2}>
                                        <Grid container>
                                    

                                            <Grid item xs={6} >
                                                <Typography style={{ lineHeight: 2 }} noWrap variant='body1'>Phone #:</Typography>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Typography style={{ lineHeight: 2 }} align='right' variant='body1'>
                                                <TextField
                                                value={userData.phone}
                                                placeholder="(204) 000-0000"
                                                size="small"
                                                onChange={(event) => { updateUserData({ ...userData, 'phone': event.target.value }) }}
                                                />
                                                </Typography>
                                            </Grid>

                                            <Grid item xs={6} >
                                                <Typography style={{ lineHeight: 2 }} noWrap variant='body1'>Email Address:</Typography>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Typography style={{ lineHeight: 2 }} align='right' variant='body1'>
                                                <TextField
                                                value={userData.email}
                                                placeholder="@email.com"
                                                size="small"
                                                onChange={(event) => { updateUserData({ ...userData, 'email': event.target.value }) }}
                                                />
                                                </Typography>
                                            </Grid>

                                            <Grid item xs={6} >
                                                <Typography style={{ lineHeight: 2 }} noWrap variant='body1'>Birthday:</Typography>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Typography style={{ lineHeight: 2 }} align='right' variant='body1'>
                                                <TextField
                                                value={moment(userData.birthday).format('YYYY-MM-DD')}
                                                size="small"
                                                type="date"
                                                onChange={(event) => { updateUserData({ ...userData, 'birthday': event.target.value }) }}
                                                />
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    
                                    <Divider style={{margin:'5px'}}></Divider>
                                    <Grid container spacing={2}>
                                        <Grid container>
                                            <Grid item xs={6} >
                                                <Typography style={{ lineHeight: 2 }} noWrap variant='body1'>Driver's License #:</Typography>
                                            </Grid>
                                            <Grid item xs={6}>
                                            
                                                <Typography style={{ lineHeight: 2 }} align='right' variant='body1'>
                                                <TextField
                                                value={userData.driversLicenseNum}
                                                placeholder={dlnprefix + "000000"}
                                                size="small"
                                                onChange={(event) => { updateUserData({ ...userData, 'driversLicenseNum': event.target.value }) }}
                                                />
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={6} >
                                                <Typography style={{ lineHeight: 2 }} noWrap variant='body1'>License Type:</Typography>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Typography style={{ lineHeight: 2 }} align='right' variant='body1'>
                                                <FormControl className={classes.formControl}>
                                                
                                                    <Select
                                                        native
                                                        value={userData.driversLicenseType}
                                                        onChange={(event) => { updateUserData({ ...userData, 'driversLicenseType': event.target.value }) }}
                                                    >
                                                    <option value="" />   
                                                    <option value={"Class #1 With Air"}>Class #1 With Air</option>
                                                    <option value={"Class #3 With Air"}>Class #3 With Air</option>
                                                    <option value={"Class #3 No Air"}>Class #3 No Air</option>
                                                    <option value={"Class #5"}>Class #5</option>
                                                    <option value={"Motorcycle"}>Motorcycle</option>
                                                    <option value={"Aircraft"}>Aircraft</option>
                                                    </Select>
                                                </FormControl>
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={6} >
                                                <Typography style={{ lineHeight: 2 }} noWrap variant='body1'>Expires On:</Typography>
                                            </Grid>
                                            <Grid item xs={6}>
                                            
                                                <Typography style={{ lineHeight: 2 }} align='right' variant='body1'>
                                                {/*<TextField
                                                value={userData.driversExpDate}
                                                size="small"
                                                onChange={(event) => { updateUserData({ ...userData, 'driversExpDate': event.target.value }) }}
                                                        />*/}
                                                <KeyboardDatePicker
                                                    //label="Expire Date"
                                                    label="YYYY/MM/DD"
                                                    format="yyyy/MM/DD"
                                                    margin="normal"
                                                    value={userData.driversExpDate}
                                                    onChange={(date) => { updateUserData({ ...userData, 'driversExpDate': date }) }}
                                                />
                                                </Typography>
                                            </Grid>
                                            
                                        </Grid>
                                    </Grid>
                                </Paper>
                            </Grid>
                        </>
                        : null}       
                    </React.Fragment>
                    : 
                    <React.Fragment>
                        <div style={{ display: 'flex', marginBottom:'20px' }}>
                            <Grid container spacing={2}>
                                <Grid item md={11}>
                                    <PaperTitle title={props.userData.displayName ? props.userData.displayName : props.userData.firstName + ' ' + props.userData.lastName}/>  
                                </Grid>
                                
                                <Grid item>
                                    <Typography align='left' variant='h5'>{props.userData.role ? props.userData.role : 'None'}</Typography>
                                </Grid>
                            </Grid>
                        </div>       

                        <Grid container spacing={2}>
                            <Paper className={classes.paper} style={{height: '100%', marign:'10px'}}>
                                <Grid container spacing={2}>
                                    <Typography variant="h5">General Info</Typography>
                                </Grid>
                                <hr></hr>
                                <Grid container spacing={1}>
                                    <Grid container>
                                        <Grid item xs={6} >
                                            <Typography style={{ lineHeight: 2 }} noWrap variant='body1'>Department:</Typography>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Typography style={{ lineHeight: 2}} align='right' variant='body1'>{props.userData.department && props.userData.department ? props.userData.department : 'None'}</Typography>
                                        </Grid>
                                        <Grid item xs={6} >
                                            <Typography style={{ lineHeight: 2 }} noWrap variant='body1'>Assigned Equipment:</Typography>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Typography style={{ lineHeight: 2}} align='right' variant='body1'>{props.userData.commonEquipment.unit ? 'Unit #'+props.userData.commonEquipment.unit+ ' - '+props.userData.commonEquipment.description : 'None'}</Typography>
                                        </Grid>
                                        <Grid item xs={6} >
                                            <Typography style={{ lineHeight: 2 }} noWrap variant='body1'>Trailers:</Typography>
                                        </Grid>
                                        <Grid item xs={5}>
                                            <Typography style={{ lineHeight: 2}} align='right' variant='body1'><b>1: </b>{props.userData.commonEquipment.trailer1 ? 'Unit #'+props.userData.commonEquipment.trailer1+ ' - '+props.userData.commonEquipment.description1 : 'None'}</Typography>
                                        </Grid>
                                        <Grid item xs={11}>
                                            <Typography style={{ lineHeight: 2}} align='right' variant='body1'><b>2: </b>{props.userData.commonEquipment.trailer2 ? 'Unit #'+props.userData.commonEquipment.trailer2+ ' - '+props.userData.commonEquipment.description2 : 'None'}</Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Divider style={{margin:'5px'}}></Divider>
                            </Paper>
                        </Grid>

                        {props.permissions.includes('View User Personal Info') ?
                        <>
                            <Grid container spacing={2}>
                                <Paper className={classes.paper} style={{height: '100%', marign:'10px'}}>
                                    <Grid container spacing={2}>
                                        <Typography variant="h5">Mailing Address</Typography>
                                    </Grid>
                                    <hr></hr>
                                    <Grid container spacing={2}>
                                        <Grid container>
                                            <Grid item xs={6} >
                                                <Typography style={{ lineHeight: 2 }} noWrap variant='body1'>Street Address:</Typography>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Typography style={{ lineHeight: 2}} align='right' variant='body1'>{props.userData.address && props.userData.address.street ? props.userData.address.street : '123 Street St.'}</Typography>
                                            </Grid>
                                            <Grid item xs={6} >
                                                <Typography style={{ lineHeight:2}} noWrap variant='body1'>City:</Typography>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Typography style={{ lineHeight: 2}} align='right' variant='body1'>{props.userData.address && props.userData.address.city ? props.userData.address.city : 'Winnipeg'}</Typography>
                                            </Grid>
                                            <Grid item xs={6} >
                                                <Typography style={{ lineHeight: 2 }} noWrap variant='body1'>Province:</Typography>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Typography style={{ lineHeight: 2}} align='right' variant='body1'>{props.userData.address && props.userData.address.province ? props.userData.address.province : 'Manitoba'}</Typography>
                                            </Grid>
                                            <Grid item xs={6} >
                                                <Typography style={{ lineHeight: 2 }} noWrap variant='body1'>Postal Code:</Typography>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Typography style={{ lineHeight: 2}} align='right' variant='body1'>{props.userData.address && props.userData.address.postalCode ? props.userData.address.postalCode : 'R2C 1B2'}</Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Divider style={{margin:'5px'}}></Divider>
                                </Paper>
                            </Grid>
                           

                            <Grid container spacing={2}>
                                <Paper className={classes.paper} style={{height: '100%', marign:'10px'}}>
                                    <Grid container spacing={2}>
                                        <Typography variant="h5">Personal Info</Typography>
                                    </Grid>
                                    <hr></hr>
                                    <Grid container spacing={2}>
                                        <Grid container>


                                            <Grid item xs={6} >
                                                <Typography style={{ lineHeight: 2 }} noWrap variant='body1'>Phone #:</Typography>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Typography style={{ lineHeight: 2 }} align='right' variant='body1'>{props.userData.phone ? props.userData.phone : ''}</Typography>
                                            </Grid>

                                            <Grid item xs={6} >
                                                <Typography style={{ lineHeight: 2 }} noWrap variant='body1'>Email Address:</Typography>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Typography style={{ lineHeight: 2 }} align='right' variant='body1'>{props.userData.email ? props.userData.email : 'Unavailable'}</Typography>
                                            </Grid>

                                            <Grid item xs={6} >
                                                <Typography style={{ lineHeight: 2 }} noWrap variant='body1'>Birthday:</Typography>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Typography style={{ lineHeight: 2 }} align='right' variant='body1'>{props.userData.birthday ? moment(props.userData.birthday).format('MMM D YYYY') : 'Unavailable'}</Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Divider style={{margin:'5px'}}></Divider>
                        
                                    <Grid container spacing={2}>
                                        <Grid container>
                                            <Grid item xs={6} >
                                                <Typography style={{ lineHeight: 2 }} noWrap variant='body1'>Driver's License #:</Typography>
                                            </Grid>
                                            <Grid item xs={6}>
                                            
                                                <Typography style={{ lineHeight: 2 }} align='right' variant='body1'>{props.userData.driversLicenseNum ? props.userData.driversLicenseNum : 'N/A'}</Typography>
                                            </Grid>
                                            <Grid item xs={6} >
                                                <Typography style={{ lineHeight: 2 }} noWrap variant='body1'>License Type:</Typography>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Typography style={{ lineHeight: 2 }} align='right' variant='body1'>{props.userData.driversLicenseType ? props.userData.driversLicenseType : 'N/A'}</Typography>
                                            </Grid>
                                            <Grid item xs={6} >
                                                <Typography style={{ lineHeight: 2 }} noWrap variant='body1'>Expires On:</Typography>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Typography style={{ lineHeight: 2 }} align='right' variant='body1'>{props.userData.driversExpDate ? props.userData.driversExpDate : ''}</Typography>
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={4}>
                                            <Button variant="contained" startIcon={<PrintIcon/>} disabled={!props.userData.driversLicenseNum}
                                            onClick={(e) => props.history.push("/dashboard/users/reporting/printing/abstract/?docId=" + props.userData?.docId)}>
                                            Print Abstract Agreement</Button>
                                        </Grid>
                                            
                                        <Grid item>
                                            <Button variant="contained" startIcon={<PrintIcon/>}
                                            onClick={(e) => props.history.push("/dashboard/users/reporting/printing/abstractpermission/?docId=" + props.userData?.docId)}>
                                            Print Abstract Permission</Button>
                                        </Grid>
                                        
                                        
                                    </Grid>
                                    { !props.userData.driversLicenseNum ?
                                    <Grid container spacing={2}>
                                        <Grid item> <small style={{color:'red'}}>Driver's License Number required for Abstract Agreement</small></Grid>
                                    </Grid> : null}
                                </Paper>

   
                            </Grid>
                        </>
                        : null}

                        <Grid container spacing={2}>
                            <Paper className={classes.paper} style={{height: '100%', marign:'10px'}}>
                                <Grid container spacing={2}>
                                    <Grid container item xs={6}>
                                        <Typography variant="h5">Documents</Typography>
                                    </Grid>
                                    <Grid container item xs={6} justifyContent='flex-end'>
                                        <Button style={{marginBottom:'10px'}} variant="contained" onClick={()=>handleOpen()}>New User Document</Button>
                                    </Grid>
                                    
                                    <UserDocumentTable userData={props.userData}/>
                                </Grid>
                            </Paper>    
                        </Grid>
                    </React.Fragment>
                    }
                </Grid>

                {props.permissions.includes('View User Personal Info') &&
                <>
                    <Grid item xs={12} style={{ display: 'flex', justifyContent: 'center' }}>
                        <Typography variant='h6' style={{marginBottom:'10px'}}><small>Uploading new images will replace current images</small></Typography>
                    </Grid>                    
                        
                    <Grid item xs={6}>
                        <Dropzone accept={{'image/jpeg': ['.jpg', '.jpeg'], 'image/png': ['.png', '.PNG'], 'application/pdf': ['.pdf'],}}
                            onDrop={handleFrontUpload}>
                            {({ getRootProps, getInputProps }) => (
                                <Grid container item xs={12}justifyContent='center' {...getRootProps()}  style={{border:'2px dashed lightGrey', minHeight:'100px'}}>
                                    <input {...getInputProps()} style={{ display: 'none' }} />
            
                                    <PublishIcon style={{color:'grey', marginRight:'20px'}}/>

                                    <Typography variant='h6'>
                                        {newFrontPhoto?.name ? substringText(newFrontPhoto?.name) : "Upload Drivers License Front Image"}
                                    </Typography>
                                </Grid>
                            )}
                        </Dropzone> 
                        {uploading && <Grid item xs={12} style={{marginBottom:'10px'}}>
                            <BorderLinearProgress variant="determinate" value={frontProgress} />
                        </Grid>}
                        {newFrontPhoto?.name ?<Button size='small' variant='contained' style={{ marginLeft: '100px', color:'black' }} onClick={() => {setNewFrontPhoto()}}>
                            <ClearIcon /> Clear Photo
                        </Button>:null}     
                    </Grid>   

                    <Grid item xs={6}>
                        <Dropzone accept={{'image/jpeg': ['.jpg', '.jpeg'], 'image/png': ['.png', '.PNG'], 'application/pdf': ['.pdf'],}}
                            onDrop={handleBackUpload}>
                            {({ getRootProps, getInputProps }) => (
                                <Grid container item xs={12}justifyContent='center' {...getRootProps()}  style={{border:'2px dashed lightGrey', minHeight:'100px'}}>
                                    <input {...getInputProps()} style={{ display: 'none' }} />
            
                                    <PublishIcon style={{color:'grey', marginRight:'20px'}}/>

                                    <Typography variant='h6'>
                                        {newBackPhoto?.name ? substringText(newBackPhoto?.name) : "Upload Drivers License Back Image"}
                                    </Typography>
                                
                                </Grid>
                                
                            )}
                        </Dropzone>    
                        {backUploading && <Grid item xs={12} style={{marginBottom:'10px'}}>
                            <BorderLinearProgress variant="determinate" value={backProgress} />
                        </Grid>}
                        {newBackPhoto?.name ?<Button size='small' variant='contained' style={{ marginLeft: '100px', color:'black' }} onClick={() => {setNewBackPhoto()}}>
                            <ClearIcon /> Clear Photo
                        </Button>:null}                 
                    </Grid>    

                    {userData.driversFrontURL
                        ?   <Grid item xs={6}>
                                <Button startIcon={<GetAppIcon/>} variant='contained' onClick={() => { window.open(userData.driversFrontURL) }}
                                >View Driver's License Front File</Button>
                            </Grid>
                    : null}
                    {userData.driversBackURL
                        ?   <Grid item xs={6} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                <Button startIcon={<GetAppIcon/>} variant='contained' onClick={() => { window.open(userData.driversBackURL) }}
                                >View Driver's License Back File</Button>
                            </Grid>
                    : null}
                    <Grid container>
                    {userData.driversFrontURL
                        ?   <Grid item xs={6}>
                                <Img src={userData.driversFrontURL} style={{ width: '256px', height:'160px'}} />
                            </Grid>
                    : null}
                    {userData.driversBackURL
                        ?   <Grid item xs={6} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                <Img src={userData.driversBackURL} style={{ width: '256px', height:'160px' }} />
                            </Grid>
                    : null}
                    </Grid>
                </>
                }

                <Snackbar
                    open={!_.isEqual(userData, saveState)}
                    message={saving ? 'Saving...' : 'Save Document?'}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center'
                    }}
                    action={
                        <React.Fragment>
                            {saving
                                ? null
                                : <React.Fragment>
                                    <Button variant='text' color='primary' onClick={cancelSave} style={{ marginLeft: '32px', marginRight: '8px' }}>Cancel</Button>
                                    <Button variant='contained' onClick={confirmSave}>Confirm</Button>
                                </React.Fragment>}
                        </React.Fragment>
                    } />
        </Grid>
    )
}
export default withRouter(InformationTab)