import React from 'react';
import { Button, Checkbox, FormControlLabel, makeStyles } from '@material-ui/core';
import { PDFViewer, Font, Page, Text, View, Document, StyleSheet } from '@react-pdf/renderer';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import _ from 'lodash'
import { withRouter } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        justifyContent: 'center',
        width: '100%',
    },
    content: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        maxWidth: '1200px',
        margin: 'auto',
    },
    buttonRow: {
        marginBottom: '20px',
        marginTop: '20px',
    },
    checkRow: {
        margin: theme.spacing(1),
        display: 'flex',
        justifyContent: 'space-evenly',
    },
}));

Font.register({
    family: 'Oswald',
    src: 'https://fonts.gstatic.com/s/oswald/v13/Y_TKV6o8WovbUd3m_X9aAA.ttf'
});

// Create styles
const styles = StyleSheet.create({
    page: {
        flexDirection: 'column',
        fontFamily: 'Helvetica',
        padding: 20,
    },
    section: {
        marginBottom: 10,
    },
    dTable: {
        display: "table",
        width: "auto",
        border: '1 solid black',

        marginBottom: 10,
    },
    dTableRow: {
        margin: "auto",
        flexDirection: "row"
    },
    dTableCol: {
        flexGrow: 1,
    },
    dTableCell: {
        margin: "auto",
        marginTop: 5,
        marginBottom: 5,
        fontSize: 10,
    },
    table: {
        border: '1 solid black',
        marginBottom: 10,
        padding: 10,
        flexGrow: 1,
        fontSize: 14,
        flexDirection: 'column',
        justifyContent: 'space-between',
    },
    subTable: {
        marginBottom: 10,
        flexGrow: 1,
        fontSize: 14,
        flexDirection: 'column',
        justifyContent: 'space-between',
    },
    tableRow: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginTop: 2,
        marginBottom: 2,
    },
    tableText: {
        fontSize: 12,
        marginBottom: '1px',
    },
    tableTextSmall: {
        fontSize: 10,
    },
    tableTextBold: {
        fontSize: 12,
        fontFamily: 'Helvetica-Bold',
        fontWeight: 'bold',
        marginBottom: '10px',
    },
    tableTextBoldUnderline: {
        fontSize: 12,
        fontFamily: 'Helvetica-Bold',
        fontWeight: 'bold',
        textDecoration: 'underline',
        marginBottom: '10px',
    },
    summary: {
        flexDirection: 'row',
    },
    viewer: {
        width: '100%',
        maxWidth: '1200px',
        height: '85vh',
    },
    tireRect: {
        width: '80px',
        height: '50px',
        margin: '0px 5px 5px 5px',
        border: '1 solid black',
        textAlign: 'center',
    },
    spacerRect: {
        width: '80px',
        height: '50px',
        margin: '0px 5px 5px 5px',
    },
    vehicleBackTableRow: {
        flexDirection: "row",
        display: 'flex',
        justifyContent: 'center',
    },
    vehicleFrontTableRow: {
        flexDirection: "row",
        display: 'flex',
        justifyContent: 'flex-start',
    },
    vehicleBackTireContainer: {
        position: 'relative',
        left: '50px',
    },
    vehicleFrontTireContainer: {
        position: 'relative',
        left: '100px',
    },
    divider: {
        borderTop: '1 solid black',
        margin: '10px 0px 5px 0px'
    },
});

// Create Document Component
const EquipmentPDF = (props) => {
    const [showParts, setshowParts] = React.useState(true);
    const [showTires, setshowTires] = React.useState(true);
    const [showWheelsBrakes, setshowWheelsBrakes] = React.useState(true);

    const classes = useStyles();
    /**
     * @param title - Table Header
     * @param columns - Generates table column header and its internal data. Can pass either an objects key to iterate or a function.
     * @param data - The table data passed (as an array of objects)
     * @param components - React components passed as special rendering for additional rows 
     
    const Table = (props) => {

        let columnWidth = 100 / props.columns.length + '%';


        

        return (
            <View style={styles.dTable}>
                <Text style={styles.dTableRow}>{props.title}</Text>

                <View style={styles.dTableRow}>
                    {props.columns.map((column, index) => {
                        return (
                            <View style={{ ...styles.dTableCol, width: columnWidth }} key={index}>
                                <Text style={{ ...styles.dTableCell, fontSize: 12 }}>{column.title}</Text>
                            </View>
                        )
                    })}
                </View>

                {props.data.map((data, index) => {
                    return (
                        <View key={'tablerow' + props.title + index}>
                            <View style={styles.dTableRow} wrap={false}>
                                {props.columns.map((column, index) => {

                                    //Use render function if exists, otherwise render data.
                                    let cellData = column.render ? column.render(data) : data[column.field];

                                    return (
                                        <View style={{ ...styles.dTableCol, width: columnWidth }} key={index}>
                                            <Text style={styles.dTableCell}>{cellData}</Text>
                                        </View>
                                    )
                                })}
                            </View>
                        </View>
                    )
                })}
            </View>
        )
    }*/

    return (
        <div className={classes.root}>
            <div className={classes.content}>

                <div className={classes.buttonRow}>
                    <Button 
                        variant='contained'
                        onClick={()=> { props.history.goBack() }}
                        startIcon={<ArrowBackIcon />}
                     >
                         Back
                     </Button>
                </div>
                
                <div className={classes.checkRow}>
                    <FormControlLabel
                        control={<Checkbox checked={showParts} onChange={() => setshowParts(!showParts)} />}
                        label="Show Parts" />
                    <FormControlLabel
                        control={<Checkbox checked={showTires} onChange={() => setshowTires(!showTires)} />}
                        label="Show Tires" />
                    <FormControlLabel
                        control={<Checkbox checked={showWheelsBrakes} onChange={() => setshowWheelsBrakes(!showWheelsBrakes)} />}
                        label="Show Wheels/Brakes" />
                </div>

                <PDFViewer style={styles.viewer}>
                    <Document>
                        <Page size="A4" style={styles.page} wrap>
                            <View style={{...styles.tableRow, margin:'4px'}}>
                                <Text>{props.equipmentData.description}</Text>
                            </View>

                            <View style={styles.summary} wrap={false}>
                                <View style={{ ...styles.table, marginRight: 0 }}>
                                <Text style={styles.tableTextBoldUnderline}>Unit Data</Text>
                                    <View style={styles.tableRow}>
                                        <Text style={styles.tableText}>Unit Number:</Text>
                                        <Text style={styles.tableText}>{props.equipmentData.unitNumber === '' || _.isUndefined(props.equipmentData.unitNumber) ? "N/A" : props.equipmentData.unitNumber}</Text>
                                    </View>
                                    <View style={styles.tableRow}>
                                        <Text style={styles.tableText}>Odometer Units:</Text>
                                        <Text style={styles.tableText}>{props.equipmentData.odoUnits === '' || _.isUndefined(props.equipmentData.odoUnits) ? "N/A" : props.equipmentData.odoUnits}</Text>
                                    </View>
                                    <View style={styles.tableRow}>
                                        <Text style={styles.tableText}>Year:</Text>
                                        <Text style={styles.tableText}>{props.equipmentData.year === '' || _.isUndefined(props.equipmentData.year) ? "N/A" : props.equipmentData.year}</Text>
                                    </View>
                                    <View style={styles.tableRow}>
                                        <Text style={styles.tableText}>Model:</Text>
                                        <Text style={styles.tableText}>{props.equipmentData.Model === '' || _.isUndefined(props.equipmentData.Model) 
                                                    ? "N/A" : props.equipmentData.Model.charAt(0).toUpperCase() + props.equipmentData.Model.slice(1)}
                                        </Text>
                                    </View>
                                    <View style={styles.tableRow}>
                                        <Text style={styles.tableText}>Equipment Type:</Text>
                                        <Text style={styles.tableText}>{props.equipmentData.equipmentType === '' || _.isUndefined(props.equipmentData.equipmentType) 
                                                    ? "N/A" : props.equipmentData.equipmentType.charAt(0).toUpperCase() + props.equipmentData.equipmentType.slice(1)}
                                        </Text>
                                    </View>
                                    <View style={styles.tableRow}>
                                        <Text style={styles.tableText}>Equipment Features:</Text>
                                        <Text style={styles.tableText}>{props.equipmentData.features === '' || _.isUndefined(props.equipmentData.features) 
                                                    ? "N/A" : props.equipmentData.features.charAt(0).toUpperCase() + props.equipmentData.features.slice(1)}
                                        </Text>
                                    </View>
                                    <View style={styles.tableRow}>
                                        <Text style={styles.tableText}>MNP Number:</Text>
                                        <Text style={styles.tableText}>{props.equipmentData.mnpNum === '' || _.isUndefined(props.equipmentData.mnpNum) ? "N/A" : props.equipmentData.mnpNum}</Text>
                                    </View>
                                    <View style={styles.tableRow}>
                                        <Text style={styles.tableText}>Serial Number:</Text>
                                        <Text style={styles.tableText}>{props.equipmentData.serialNum === '' || _.isUndefined(props.equipmentData.serialNum) ? "N/A" : props.equipmentData.serialNum}</Text>
                                    </View>
                                    <View style={styles.tableRow}>
                                        <Text style={styles.tableText}>Alias Unit Number:</Text>
                                        <Text style={styles.tableText}>{props.equipmentData.aliasUnitNum === '' || _.isUndefined(props.equipmentData.aliasUnitNum) ? "N/A" : props.equipmentData.aliasUnitNum}</Text>
                                    </View>
                                    <View style={styles.tableRow}>
                                        <Text style={styles.tableText}>Tare:</Text>
                                        <Text style={styles.tableText}>{props.equipmentData.tare === '' || _.isUndefined(props.equipmentData.tare) 
                                                    ? "N/A" : props.equipmentData.tare.charAt(0).toUpperCase() + props.equipmentData.tare.slice(1) + "kgs"}
                                        </Text>
                                    </View>
                                    <View style={styles.tableRow}>
                                        <Text style={styles.tableText}>Equipment is Active:</Text>
                                        <Text style={styles.tableText}>{props.equipmentData.isActive === false || _.isUndefined(props.equipmentData.isActive) ? "[ ]"  : "[x]"} </Text>
                                    </View>
                                    <View style={styles.tableRow}>
                                        <Text style={styles.tableText}>Equipment Classification:</Text>
                                        <Text style={styles.tableText}>{props.equipmentData.class === '' || _.isUndefined(props.equipmentData.class) 
                                                    ? "N/A" : props.equipmentData.class.charAt(0).toUpperCase() + props.equipmentData.class.slice(1)}
                                        </Text>
                                    </View>
                                    <View style={styles.tableRow}>
                                        <Text style={styles.tableText}>Equipment is Owned:</Text>
                                        <Text style={styles.tableText}>{props.equipmentData.isOwned === false || _.isUndefined(props.equipmentData.isOwned) ? "[ ]"  : "[x]"} </Text>
                                    </View>
                                    <View style={styles.tableRow}>
                                        <Text style={styles.tableText}>Market Value:</Text>
                                        <Text style={styles.tableText}>{props.equipmentData.value === '' || _.isUndefined(props.equipmentData.value) ? "N/A" : props.equipmentData.value}</Text>
                                    </View>
                                    <View style={styles.tableRow}>
                                        <Text style={styles.tableText}>Purchase Price:</Text>
                                        <Text style={styles.tableText}>{props.equipmentData.purchasePrice === '' || _.isUndefined(props.equipmentData.purchasePrice) ? "N/A" : props.equipmentData.purchasePrice}</Text>
                                    </View>
                                    <View style={styles.tableRow}>
                                        <Text style={styles.tableText}>Insured Value:</Text>
                                        <Text style={styles.tableText}>{props.equipmentData.insuredValue === '' || _.isUndefined(props.equipmentData.insuredValue) ? "N/A" : props.equipmentData.insuredValue}</Text>
                                    </View>
                                    <View style={styles.tableRow}>
                                        <Text style={styles.tableText}>Purchased From:</Text>
                                        <Text style={styles.tableText}>{props.equipmentData.purchasedFrom === '' || _.isUndefined(props.equipmentData.purchasedFrom) 
                                                    ? "N/A" : props.equipmentData.purchasedFrom.charAt(0).toUpperCase() + props.equipmentData.purchasedFrom.slice(1)}
                                        </Text>
                                    </View>
                                    <View style={styles.tableRow}>
                                        <Text style={styles.tableText}>Purchase Date:</Text>
                                        <Text style={styles.tableText}>{props.equipmentData.purchaseDate === '' || _.isUndefined(props.equipmentData.purchaseDate) ? "N/A" : props.equipmentData.purchaseDate}</Text>
                                    </View>
                                    <View style={styles.tableRow}>
                                        <Text style={styles.tableText}>Leased/Financed From:</Text>
                                        <Text style={styles.tableText}>{props.equipmentData.leasedFinancedFrom === '' || _.isUndefined(props.equipmentData.leasedFinancedFrom) 
                                                    ? "N/A" : props.equipmentData.leasedFinancedFrom.charAt(0).toUpperCase() + props.equipmentData.leasedFinancedFrom.slice(1)}
                                        </Text>
                                    </View>
                                    <View style={styles.tableRow}>
                                        <Text style={styles.tableText}>Normal Charge Out Rate per Hour:</Text>
                                        <Text style={styles.tableText}>{props.equipmentData.chargeRate === '' || _.isUndefined(props.equipmentData.chargeRate) ? "N/A" : props.equipmentData.chargeRate}</Text>
                                    </View>
                                </View>
                            </View>

                            {(showParts && props.equipmentData?.engine) || (showParts && props.equipmentData?.transmission) || (showParts && props.equipmentData?.differential) ||
                             (showParts && props.equipmentData?.filters) || (showParts && props.equipmentData?.clutch) || (showParts && props.equipmentData?.seals) ||
                             (showParts && props.equipmentData?.belts) ?

                             <View style={styles.summary} wrap={false}>
                             <View style={{ ...styles.table, marginRight: 0 }}>
                             <Text style={styles.tableTextBoldUnderline}>Parts</Text>
                                 

                                    {showParts && props.equipmentData?.engine ?
                                        <View style={styles.tableRow}>
                                            <View style={styles.subTable}>
                                                <View style={styles.tableRow}>
                                                    <Text style={styles.tableText}>Engine Serial Number:</Text>
                                                    <Text style={styles.tableText}>{props.equipmentData.engine.serialNum === '' || _.isUndefined(props.equipmentData.engine.serialNum) ? "N/A" : props.equipmentData.engine.serialNum}</Text>
                                                </View>
                                                <View style={styles.tableRow}>
                                                    <Text style={styles.tableText}>Engine CPL:</Text>
                                                    <Text style={styles.tableText}>{props.equipmentData.engine.cpl === '' || _.isUndefined(props.equipmentData.engine.cpl) 
                                                        ? "N/A" : props.equipmentData.engine.cpl.charAt(0).toUpperCase() + props.equipmentData.engine.cpl.slice(1)}
                                                    </Text>
                                                </View>
                                                <View style={styles.tableRow}>
                                                    <Text style={styles.tableText}>Engine Model:</Text>
                                                    <Text style={styles.tableText}>{props.equipmentData.engine.model === '' || _.isUndefined(props.equipmentData.engine.model) 
                                                        ? "N/A" : props.equipmentData.engine.model.charAt(0).toUpperCase() + props.equipmentData.engine.model.slice(1)}
                                                    </Text>
                                                </View>
                                                <View style={styles.tableRow}>
                                                    <Text style={styles.tableText}>Engine Oil Type:</Text>
                                                    <Text style={styles.tableText}>{props.equipmentData.engine.oilType === '' || _.isUndefined(props.equipmentData.engine.oilType) 
                                                    ? "N/A" : props.equipmentData.engine.oilType.charAt(0).toUpperCase() + props.equipmentData.engine.oilType.slice(1)}
                                                    </Text>
                                                </View>
                                                <View style={styles.tableRow}>
                                                    <Text style={styles.tableText}>Engine Oil Capacity:</Text>
                                                    <Text style={styles.tableText}>{props.equipmentData.engine.oilCapacity === '' || _.isUndefined(props.equipmentData.engine.oilCapacity) ? "N/A" : props.equipmentData.engine.oilCapacity}</Text>
                                                </View>
                                                <View style={styles.tableRow}>
                                                    <Text style={styles.tableText}>Engine uses Synthetic Oil:</Text>
                                                    <Text style={styles.tableText}>{props.equipmentData.engine.usesSynthetic === false || _.isUndefined(props.equipmentData.engine.usesSynthetic) ? "[ ]" : "[x]"} </Text>
                                                </View>
                                            </View>
                                        </View>
                                    : null}

                                    {showParts && props.equipmentData?.transmission  ?
                                        <View style={styles.tableRow}>
                                            <View style={styles.subTable}>
                                                <View style={styles.tableRow}>
                                                    <Text style={styles.tableText}>Transmission Serial Number:</Text>
                                                    <Text style={styles.tableText}>{props.equipmentData.transmission.serialNum === '' || _.isUndefined(props.equipmentData.transmission.serialNum) ? "N/A" : props.equipmentData.transmission.serialNum}</Text>
                                                </View>
                                                <View style={styles.tableRow}>
                                                    <Text style={styles.tableText}>Transmission Model:</Text>
                                                    <Text style={styles.tableText}>{props.equipmentData.transmission.model === '' || _.isUndefined(props.equipmentData.transmission.model) 
                                                        ? "N/A" : props.equipmentData.transmission.model.charAt(0).toUpperCase() + props.equipmentData.transmission.model.slice(1)}
                                                    </Text>
                                                </View>
                                                <View style={styles.tableRow}>
                                                    <Text style={styles.tableText}>Transmission Oil Type:</Text>
                                                    <Text style={styles.tableText}>{props.equipmentData.transmission.oilType === '' || _.isUndefined(props.equipmentData.transmission.oilType) 
                                                    ? "N/A" : props.equipmentData.transmission.oilType.charAt(0).toUpperCase() + props.equipmentData.transmission.oilType.slice(1)}
                                                    </Text>
                                                </View>
                                                <View style={styles.tableRow}>
                                                    <Text style={styles.tableText}>Transmission Oil Capacity:</Text>
                                                    <Text style={styles.tableText}>{props.equipmentData.transmission.oilCapacity === '' || _.isUndefined(props.equipmentData.transmission.oilCapacity) ? "N/A" : props.equipmentData.transmission.oilCapacity}</Text>
                                                </View>
                                                <View style={styles.tableRow}>
                                                    <Text style={styles.tableText}>Transmission uses Synthetic Oil:</Text>
                                                    <Text style={styles.tableText}>{props.equipmentData.transmission.usesSynthetic === false || _.isUndefined(props.equipmentData.transmission.usesSynthetic) ? "[ ]" : "[x]"} </Text>
                                                </View>
                                            </View>
                                      </View>
                                    : null}

                                    {showParts && props.equipmentData?.differential  ?
                                        <View style={styles.tableRow}>
                                            <View style={styles.subTable}>
                                                <View style={styles.tableRow}>
                                                    <Text style={styles.tableText}>Differential Type:</Text>
                                                    <Text style={styles.tableText}>{props.equipmentData.differential.type === '' || _.isUndefined(props.equipmentData.differential.type) 
                                                        ? "N/A" : props.equipmentData.differential.type.charAt(0).toUpperCase() + props.equipmentData.differential.type.slice(1)}
                                                    </Text>
                                                </View>
                                                <View style={styles.tableRow}>
                                                    <Text style={styles.tableText}>Differential Ratio:</Text>
                                                    <Text style={styles.tableText}>{props.equipmentData.differential.ratio === '' || _.isUndefined(props.equipmentData.differential.ratio) 
                                                        ? "N/A" : props.equipmentData.differential.ratio.charAt(0).toUpperCase() + props.equipmentData.differential.ratio.slice(1)}
                                                    </Text>
                                                </View>
                                                <View style={styles.tableRow}>
                                                    <Text style={styles.tableText}>Differential Oil Type:</Text>
                                                    <Text style={styles.tableText}>{props.equipmentData.differential.oilType === '' || _.isUndefined(props.equipmentData.differential.oilType) 
                                                    ? "N/A" : props.equipmentData.differential.oilType.charAt(0).toUpperCase() + props.equipmentData.differential.oilType.slice(1)}
                                                    </Text>
                                                </View>
                                                <View style={styles.tableRow}>
                                                    <Text style={styles.tableText}>Differential Oil Capacity:</Text>
                                                    <Text style={styles.tableText}>{props.equipmentData.differential.oilCapacity === '' || _.isUndefined(props.equipmentData.differential.oilCapacity) ? "N/A" : props.equipmentData.differential.oilCapacity}</Text>
                                                </View>
                                                <View style={styles.tableRow}>
                                                    <Text style={styles.tableText}>Differential uses Synthetic Oil:</Text>
                                                    <Text style={styles.tableText}>{props.equipmentData.differential.usesSynthetic === false || _.isUndefined(props.equipmentData.differential.usesSynthetic) ? "[ ]" : "[x]"} </Text>
                                                </View>
                                            </View>
                                        </View>
                                    : null}

                                    {showParts && props.equipmentData?.filters  ?
                                        <View style={styles.tableRow}>
                                            <View style={styles.subTable}>
                                                <View style={styles.tableRow}>
                                                    <Text style={styles.tableText}>Air Filter:</Text>
                                                    <Text style={styles.tableText}>{props.equipmentData.filters.airFilter === '' || _.isUndefined(props.equipmentData.filters.airFilter) 
                                                        ? "N/A" : props.equipmentData.filters.airFilter.charAt(0).toUpperCase() + props.equipmentData.filters.airFilter.slice(1)}
                                                    </Text>
                                                </View>
                                                <View style={styles.tableRow}>
                                                    <Text style={styles.tableText}>Water Filter:</Text>
                                                    <Text style={styles.tableText}>{props.equipmentData.filters.waterFilter === '' || _.isUndefined(props.equipmentData.filters.waterFilter) 
                                                        ? "N/A" : props.equipmentData.filters.waterFilter.charAt(0).toUpperCase() + props.equipmentData.filters.waterFilter.slice(1)}
                                                    </Text>
                                                </View>
                                                <View style={styles.tableRow}>
                                                    <Text style={styles.tableText}>Fuel Filter # 1:</Text>
                                                    <Text style={styles.tableText}>{props.equipmentData.filters.fuelFilter1 === '' || _.isUndefined(props.equipmentData.filters.fuelFilter1) 
                                                        ? "N/A" : props.equipmentData.filters.fuelFilter1.charAt(0).toUpperCase() + props.equipmentData.filters.fuelFilter1.slice(1)}
                                                    </Text>
                                                </View>
                                                <View style={styles.tableRow}>
                                                    <Text style={styles.tableText}>Fuel Filter # 2:</Text>
                                                    <Text style={styles.tableText}>{props.equipmentData.filters.fuelFilter2 === '' || _.isUndefined(props.equipmentData.filters.fuelFilter2) 
                                                        ? "N/A" : props.equipmentData.filters.fuelFilter2.charAt(0).toUpperCase() + props.equipmentData.filters.fuelFilter2.slice(1)}
                                                    </Text>
                                                </View>
                                                <View style={styles.tableRow}>
                                                    <Text style={styles.tableText}>Oil Filter # 1:</Text>
                                                    <Text style={styles.tableText}>{props.equipmentData.filters.oilFilter1 === '' || _.isUndefined(props.equipmentData.filters.oilFilter1) 
                                                        ? "N/A" : props.equipmentData.filters.oilFilter1.charAt(0).toUpperCase() + props.equipmentData.filters.oilFilter1.slice(1)}
                                                    </Text>
                                                </View>
                                                <View style={styles.tableRow}>
                                                    <Text style={styles.tableText}>Oil Filter # 2:</Text>
                                                    <Text style={styles.tableText}>{props.equipmentData.filters.oilFilter2 === '' || _.isUndefined(props.equipmentData.filters.oilFilter2) 
                                                        ? "N/A" : props.equipmentData.filters.oilFilter2.charAt(0).toUpperCase() + props.equipmentData.filters.oilFilter2.slice(1)}
                                                    </Text>
                                                </View>
                                            </View>
                                        </View>
                                    : null}

                                    {showParts && props.equipmentData?.clutch  ?
                                        <View style={styles.tableRow}>
                                            <View style={styles.subTable}>
                                            <View style={styles.tableRow}>
                                                    <Text style={styles.tableText}>Clutch Brake:</Text>
                                                    <Text style={styles.tableText}>{props.equipmentData.clutch.clutchBrake === '' || _.isUndefined(props.equipmentData.clutch.clutchBrake) 
                                                        ? "N/A" : props.equipmentData.clutch.clutchBrake.charAt(0).toUpperCase() + props.equipmentData.clutch.clutchBrake.slice(1)}
                                                    </Text>
                                                </View>
                                                <View style={styles.tableRow}>
                                                    <Text style={styles.tableText}>Clutch Type:</Text>
                                                    <Text style={styles.tableText}>{props.equipmentData.clutch.clutchType === '' || _.isUndefined(props.equipmentData.clutch.clutchType) 
                                                        ? "N/A" : props.equipmentData.clutch.clutchType.charAt(0).toUpperCase() + props.equipmentData.clutch.clutchType.slice(1)}
                                                    </Text>
                                                </View>
                                                <View style={styles.tableRow}>
                                                    <Text style={styles.tableText}>Clutch Size:</Text>
                                                    <Text style={styles.tableText}>{props.equipmentData.clutch.clutchSize === '' || _.isUndefined(props.equipmentData.clutch.clutchSize) 
                                                        ? "N/A" : props.equipmentData.clutch.clutchSize.charAt(0).toUpperCase() + props.equipmentData.clutch.clutchSize.slice(1)}
                                                    </Text>
                                                </View>
                                            </View>
                                        </View>
                                    : null}

                                    {showParts && props.equipmentData?.seals  ?
                                        <View style={styles.tableRow}>
                                            <View style={styles.subTable}>
                                                <View style={styles.tableRow}>
                                                    <Text style={styles.tableText}>Nose Cone Seal:</Text>
                                                    <Text style={styles.tableText}>{props.equipmentData.seals.noseConeSeal === '' || _.isUndefined(props.equipmentData.seals.noseConeSeal) 
                                                        ? "N/A" : props.equipmentData.seals.noseConeSeal.charAt(0).toUpperCase() + props.equipmentData.seals.noseConeSeal.slice(1)}
                                                    </Text>
                                                </View>
                                                <View style={styles.tableRow}>
                                                    <Text style={styles.tableText}>Thru/Shaft Seal:</Text>
                                                    <Text style={styles.tableText}>{props.equipmentData.seals.thruShaftSeal === '' || _.isUndefined(props.equipmentData.seals.thruShaftSeal) 
                                                        ? "N/A" : props.equipmentData.seals.thruShaftSeal.charAt(0).toUpperCase() + props.equipmentData.seals.thruShaftSeal.slice(1)}
                                                    </Text>
                                                </View>
                                                <View style={styles.tableRow}>
                                                    <Text style={styles.tableText}>Rear Pinion Seal:</Text>
                                                    <Text style={styles.tableText}>{props.equipmentData.seals.rearPinionSeal === '' || _.isUndefined(props.equipmentData.seals.rearPinionSeal) 
                                                        ? "N/A" : props.equipmentData.seals.rearPinionSeal.charAt(0).toUpperCase() + props.equipmentData.seals.rearPinionSeal.slice(1)}
                                                    </Text>
                                                </View>
                                            </View>
                                        </View>
                                    : null}

                                    {showParts && props.equipmentData?.belts  ?
                                        <View style={styles.tableRow}>
                                            <View style={styles.subTable}>
                                                <View style={styles.tableRow}>
                                                    <Text style={styles.tableText}>Water Pump Belt:</Text>
                                                    <Text style={styles.tableText}>{props.equipmentData.belts.waterPumpBelt === '' || _.isUndefined(props.equipmentData.belts.waterPumpBelt) 
                                                        ? "N/A" : props.equipmentData.belts.waterPumpBelt.charAt(0).toUpperCase() + props.equipmentData.belts.waterPumpBelt.slice(1)}
                                                    </Text>
                                                </View>
                                                <View style={styles.tableRow}>
                                                    <Text style={styles.tableText}>Power Steering Belt:</Text>
                                                    <Text style={styles.tableText}>{props.equipmentData.belts.powerSteeringBelt === '' || _.isUndefined(props.equipmentData.belts.powerSteeringBelt) 
                                                        ? "N/A" : props.equipmentData.belts.powerSteeringBelt.charAt(0).toUpperCase() + props.equipmentData.belts.powerSteeringBelt.slice(1)}
                                                    </Text>
                                                </View>
                                                <View style={styles.tableRow}>
                                                    <Text style={styles.tableText}>Alternator Belt:</Text>
                                                    <Text style={styles.tableText}>{props.equipmentData.belts.alternatorBelt === '' || _.isUndefined(props.equipmentData.belts.alternatorBelt) 
                                                        ? "N/A" : props.equipmentData.belts.alternatorBelt.charAt(0).toUpperCase() + props.equipmentData.belts.alternatorBelt.slice(1)}
                                                    </Text>
                                                </View>
                                                <View style={styles.tableRow}>
                                                    <Text style={styles.tableText}>Fan Belt:</Text>
                                                    <Text style={styles.tableText}>{props.equipmentData.belts.fanBelt === '' || _.isUndefined(props.equipmentData.belts.fanBelt) 
                                                        ? "N/A" : props.equipmentData.belts.fanBelt.charAt(0).toUpperCase() + props.equipmentData.belts.fanBelt.slice(1)}
                                                    </Text>
                                                </View>
                                                <View style={styles.tableRow}>
                                                    <Text style={styles.tableText}>Air Conditioner Belt:</Text>
                                                    <Text style={styles.tableText}>{props.equipmentData.belts.airConditioningBelt === '' || _.isUndefined(props.equipmentData.belts.airConditioningBelt) 
                                                        ? "N/A" : props.equipmentData.belts.airConditioningBelt.charAt(0).toUpperCase() + props.equipmentData.belts.airConditioningBelt.slice(1)}
                                                    </Text>
                                                </View>
                                            </View>
                                        </View>
                                    : null}

                                </View>
                            </View>
                            : null
                            }


                            {(showTires && props.equipmentData?.tires?.vehicle) ?
                                    <View style={styles.summary} wrap={false}>
                                        <View style={{ ...styles.table, marginRight: 0 }}>
                                            <Text style={styles.tableTextBoldUnderline}>Tires</Text>
                                            {/* Account for layouts */}
                                            {((props.equipmentData.equipmentType === "tractor") || (props.equipmentData.equipmentType === "") || ( _.isUndefined(props.equipmentData.equipmentType))) &&
                                                <View>
                                                    <Text style={styles.tableTextBold}>Tractor</Text>
                                                    <View style={styles.vehicleBackTireContainer}>
                                                        <View style={styles.vehicleBackTableRow}>
                                                            <Text style={styles.tireRect}>{props.equipmentData.tires.vehicle.backRightOutterFront ? props.equipmentData.tires.vehicle.backRightOutterFront : ''}</Text>
                                                            <Text style={styles.tireRect}>{props.equipmentData.tires.vehicle.backRightOutterRear ? props.equipmentData.tires.vehicle.backRightOutterRear : ''}</Text>  
                                                        </View>
                                                        <View style={styles.vehicleBackTableRow}>
                                                            <Text style={styles.tireRect}>{props.equipmentData.tires.vehicle.backRightInnerFront ? props.equipmentData.tires.vehicle.backRightInnerFront : ''}</Text>
                                                            <Text style={styles.tireRect}>{props.equipmentData.tires.vehicle.backRightInnerRear ? props.equipmentData.tires.vehicle.backRightInnerRear : ''}</Text>  
                                                        </View>
                                                    </View>
                                                    <View style={styles.vehicleFrontTireContainer}>
                                                        <View style={styles.vehicleFrontTableRow}>
                                                            <Text style={styles.tireRect}>{props.equipmentData.tires.vehicle.frontRight ? props.equipmentData.tires.vehicle.frontRight : ''}</Text>
                                                        </View>
                                                        <View style={styles.vehicleFrontTableRow}>
                                                            <Text style={styles.tireRect}>{props.equipmentData.tires.vehicle.frontLeft ? props.equipmentData.tires.vehicle.frontLeft : ''}</Text>
                                                        </View>
                                                    </View>
                                                    <View style={styles.vehicleBackTireContainer}>
                                                        <View style={styles.vehicleBackTableRow}>
                                                            <Text style={styles.tireRect}>{props.equipmentData.tires.vehicle.backLeftInnerFront ? props.equipmentData.tires.vehicle.backLeftInnerFront : ''}</Text>
                                                            <Text style={styles.tireRect}>{props.equipmentData.tires.vehicle.backLeftInnerRear ? props.equipmentData.tires.vehicle.backLeftInnerRear : ''}</Text>  
                                                        </View>
                                                        <View style={styles.vehicleBackTableRow}>
                                                            <Text style={styles.tireRect}>{props.equipmentData.tires.vehicle.backLeftOutterFront ? props.equipmentData.tires.vehicle.backLeftOutterFront : ''}</Text>
                                                            <Text style={styles.tireRect}>{props.equipmentData.tires.vehicle.backLeftOutterRear ? props.equipmentData.tires.vehicle.backLeftOutterRear : ''}</Text>  
                                                        </View>
                                                    </View>
                                                </View>
                                            }
                                            {(props.equipmentData.equipmentType === "passenger") &&
                                                <View>
                                                    <Text style={styles.tableTextBold}>Passenger</Text>
                                                    <View>
                                                        <View style={styles.vehicleBackTableRow}>
                                                            <Text style={styles.tireRect}>{props.equipmentData.tires.vehicle.rightFront ? props.equipmentData.tires.vehicle.rightFront : ''}</Text>
                                                            <Text style={styles.spacerRect}></Text> 
                                                            <Text style={styles.tireRect}>{props.equipmentData.tires.vehicle.rightRear ? props.equipmentData.tires.vehicle.rightRear : ''}</Text> 
                                                        </View>
                                                    </View>

                                                    {/* Spacer */}
                                                    <View>
                                                        <View style={styles.vehicleFrontTableRow}>
                                                            <Text style={styles.spacerRect}></Text>
                                                        </View>
                                                    </View>

                                                    <View>
                                                        <View style={styles.vehicleBackTableRow}>
                                                            <Text style={styles.tireRect}>{props.equipmentData.tires.vehicle.leftFront ? props.equipmentData.tires.vehicle.leftFront : ''}</Text> 
                                                            <Text style={styles.spacerRect}></Text> 
                                                            <Text style={styles.tireRect}>{props.equipmentData.tires.vehicle.leftRear ? props.equipmentData.tires.vehicle.leftRear : ''}</Text> 
                                                        </View>
                                                    </View>
                                                </View>
                                            }
                                                
                                            {((props.equipmentData.equipmentType === "truck") || (props.equipmentData.equipmentType === "loader")) &&
                                                <View>
                                                    <Text style={styles.tableTextBold}>Placeholder</Text>
                                                    <View style={styles.vehicleBackTireContainer}>
                                                        <View style={styles.vehicleBackTableRow}>
                                                            <Text style={styles.tireRect}>{props.equipmentData.tires.vehicle.backRightOutterFront ? props.equipmentData.tires.vehicle.backRightOutterFront : ''}</Text>
                                                            <Text style={styles.tireRect}>{props.equipmentData.tires.vehicle.backRightOutterRear ? props.equipmentData.tires.vehicle.backRightOutterRear : ''}</Text>  
                                                        </View>
                                                        <View style={styles.vehicleBackTableRow}>
                                                            <Text style={styles.tireRect}>{props.equipmentData.tires.vehicle.backRightInnerFront ? props.equipmentData.tires.vehicle.backRightInnerFront : ''}</Text>
                                                            <Text style={styles.tireRect}>{props.equipmentData.tires.vehicle.backRightInnerRear ? props.equipmentData.tires.vehicle.backRightInnerRear : ''}</Text>  
                                                        </View>
                                                    </View>
                                                    <View style={styles.vehicleFrontTireContainer}>
                                                        <View style={styles.vehicleFrontTableRow}>
                                                            <Text style={styles.tireRect}>{props.equipmentData.tires.vehicle.frontRight ? props.equipmentData.tires.vehicle.frontRight : ''}</Text>
                                                        </View>
                                                        <View style={styles.vehicleFrontTableRow}>
                                                            <Text style={styles.tireRect}>{props.equipmentData.tires.vehicle.frontLeft ? props.equipmentData.tires.vehicle.frontLeft : ''}</Text>
                                                        </View>
                                                    </View>
                                                    <View style={styles.vehicleBackTireContainer}>
                                                        <View style={styles.vehicleBackTableRow}>
                                                            <Text style={styles.tireRect}>{props.equipmentData.tires.vehicle.backLeftInnerFront ? props.equipmentData.tires.vehicle.backLeftInnerFront : ''}</Text>
                                                            <Text style={styles.tireRect}>{props.equipmentData.tires.vehicle.backLeftInnerRear ? props.equipmentData.tires.vehicle.backLeftInnerRear : ''}</Text>  
                                                        </View>
                                                        <View style={styles.vehicleBackTableRow}>
                                                            <Text style={styles.tireRect}>{props.equipmentData.tires.vehicle.backLeftOutterFront ? props.equipmentData.tires.vehicle.backLeftOutterFront : ''}</Text>
                                                            <Text style={styles.tireRect}>{props.equipmentData.tires.vehicle.backLeftOutterRear ? props.equipmentData.tires.vehicle.backLeftOutterRear : ''}</Text>  
                                                        </View>
                                                    </View>
                                                </View>
                                            }

                                            {props.equipmentData.trailerAttached ?
                                            <View style={{ ...styles.subTable, marginRight: 0 }}>
                                                <Text style={styles.divider}></Text>
                                                <Text style={styles.tableTextBold}>Trailer</Text>
                                                <View>
                                                    <View style={styles.vehicleBackTableRow}>
                                                        <Text style={styles.tireRect}>{props.equipmentData.tires.attachment.rightOutterFront ? props.equipmentData.tires.attachment.rightOutterFront : ''}</Text>
                                                        <Text style={styles.tireRect}>{props.equipmentData.tires.attachment.rightOutterMid ? props.equipmentData.tires.attachment.rightOutterMid : ''}</Text> 
                                                        <Text style={styles.tireRect}>{props.equipmentData.tires.attachment.rightOutterRear ? props.equipmentData.tires.attachment.rightOutterRear : ''}</Text> 
                                                    </View>
                                                    <View style={styles.vehicleBackTableRow}>
                                                        <Text style={styles.tireRect}>{props.equipmentData.tires.attachment.rightInnerFront ? props.equipmentData.tires.attachment.rightInnerFront : ''}</Text>
                                                        <Text style={styles.tireRect}>{props.equipmentData.tires.attachment.rightInnerMid ? props.equipmentData.tires.attachment.rightInnerMid : ''}</Text>  
                                                        <Text style={styles.tireRect}>{props.equipmentData.tires.attachment.rightInnerRear ? props.equipmentData.tires.attachment.rightInnerRear : ''}</Text>
                                                    </View>
                                                </View>

                                                {/* Spacer */}
                                                <View>
                                                    <View style={styles.vehicleFrontTableRow}>
                                                        <Text style={styles.spacerRect}></Text>
                                                    </View>
                                                </View>


                                                <View style={styles.vehicleBackTableRow}>
                                                    <Text style={styles.tireRect}>{props.equipmentData.tires.attachment.leftInnerFront ? props.equipmentData.tires.attachment.leftInnerFront : ''}</Text>
                                                    <Text style={styles.tireRect}>{props.equipmentData.tires.attachment.leftInnerMid ? props.equipmentData.tires.attachment.leftInnerMid : ''}</Text> 
                                                    <Text style={styles.tireRect}>{props.equipmentData.tires.attachment.leftInnerRear ? props.equipmentData.tires.attachment.leftInnerRear : ''}</Text> 
                                                </View>
                                                <View style={styles.vehicleBackTableRow}>
                                                    <Text style={styles.tireRect}>{props.equipmentData.tires.attachment.leftOutterFront ? props.equipmentData.tires.attachment.leftOutterFront : ''}</Text>
                                                    <Text style={styles.tireRect}>{props.equipmentData.tires.attachment.leftOutterMid ? props.equipmentData.tires.attachment.leftOutterMid : ''}</Text>  
                                                    <Text style={styles.tireRect}>{props.equipmentData.tires.attachment.leftOutterRear ? props.equipmentData.tires.attachment.leftOutterRear : ''}</Text>
                                                </View>
                                            </View>
                                            : null
                                            }
                                        </View>
                                    </View>
                            : null
                            }

                            {(showWheelsBrakes && props.equipmentData?.wheels) ?
                                <View style={styles.summary} wrap={false}>
                                    <View style={{ ...styles.table, marginRight: 0 }}>
                                        <Text style={styles.tableTextBoldUnderline}>Wheels/Brakes</Text>
                                        <View style={styles.tableRow}>
                                            <View style={styles.subTable}>
                                                <View style={styles.tableRow}>
                                                    <Text style={styles.tableText}>Front Hub Make:</Text>
                                                        <Text style={styles.tableText}>{props.equipmentData.wheels.frontHubMake === '' || _.isUndefined(props.equipmentData.wheels.frontHubMake) 
                                                        ? "N/A" : props.equipmentData.wheels.frontHubMake.charAt(0).toUpperCase() + props.equipmentData.wheels.frontHubMake.slice(1)}
                                                    </Text>
                                                </View>
                                                <View style={styles.tableRow}>
                                                    <Text style={styles.tableText}>Rear Hub Make:</Text>
                                                        <Text style={styles.tableText}>{props.equipmentData.wheels.rearHubMake === '' || _.isUndefined(props.equipmentData.wheels.rearHubMake) 
                                                        ? "N/A" : props.equipmentData.wheels.rearHubMake.charAt(0).toUpperCase() + props.equipmentData.wheels.rearHubMake.slice(1)}
                                                    </Text>
                                                </View>
                                                <View style={styles.tableRow}>
                                                    <Text style={styles.tableText}>Front Hub Model Number:</Text>
                                                        <Text style={styles.tableText}>{props.equipmentData.wheels.frontHubModelNum === '' || _.isUndefined(props.equipmentData.wheels.frontHubModelNum) 
                                                        ? "N/A" : props.equipmentData.wheels.frontHubModelNum.charAt(0).toUpperCase() + props.equipmentData.wheels.frontHubModelNum.slice(1)}
                                                    </Text>
                                                </View>
                                                <View style={styles.tableRow}>
                                                    <Text style={styles.tableText}>Rear Hub Model Number:</Text>
                                                        <Text style={styles.tableText}>{props.equipmentData.wheels.rearHubModelNum === '' || _.isUndefined(props.equipmentData.wheels.rearHubModelNum) 
                                                        ? "N/A" : props.equipmentData.wheels.rearHubModelNum.charAt(0).toUpperCase() + props.equipmentData.wheels.rearHubModelNum.slice(1)}
                                                    </Text>
                                                </View>
                                                <View style={styles.tableRow}>
                                                    <Text style={styles.tableText}>Front Hub Oil Type:</Text>
                                                        <Text style={styles.tableText}>{props.equipmentData.wheels.frontHubOilType === '' || _.isUndefined(props.equipmentData.wheels.frontHubOilType) 
                                                        ? "N/A" : props.equipmentData.wheels.frontHubOilType.charAt(0).toUpperCase() + props.equipmentData.wheels.frontHubOilType.slice(1)}
                                                    </Text>
                                                </View>
                                                <View style={styles.tableRow}>
                                                    <Text style={styles.tableText}>Rear Hub Oil Type:</Text>
                                                        <Text style={styles.tableText}>{props.equipmentData.wheels.rearHubOilType === '' || _.isUndefined(props.equipmentData.wheels.rearHubOilType) 
                                                        ? "N/A" : props.equipmentData.wheels.rearHubOilType.charAt(0).toUpperCase() + props.equipmentData.wheels.rearHubOilType.slice(1)}
                                                    </Text>
                                                </View>
                                                <View style={styles.tableRow}>
                                                    <Text style={styles.tableText}>Front Wheel uses Synthetic Oil:</Text>
                                                    <Text style={styles.tableText}>{props.equipmentData.wheels.frontUsesSynthetic === false || _.isUndefined(props.equipmentData.wheels.frontUsesSynthetic) ? "[ ]" : "[x]"} </Text>
                                                </View>
                                                <View style={styles.tableRow}>
                                                    <Text style={styles.tableText}>Rear Wheel uses Synthetic Oil:</Text>
                                                    <Text style={styles.tableText}>{props.equipmentData.wheels.rearUsesSynthetic === false || _.isUndefined(props.equipmentData.wheels.rearUsesSynthetic) ? "[ ]" : "[x]"} </Text>
                                                </View>
                                                <View style={styles.tableRow}>
                                                    <Text style={styles.tableText}>Front Outter Bearing Number:</Text>
                                                        <Text style={styles.tableText}>{props.equipmentData.wheels.frontOutterBearingNum === '' || _.isUndefined(props.equipmentData.wheels.frontOutterBearingNum) 
                                                        ? "N/A" : props.equipmentData.wheels.frontOutterBearingNum.charAt(0).toUpperCase() + props.equipmentData.wheels.frontOutterBearingNum.slice(1)}
                                                    </Text>
                                                </View>
                                                <View style={styles.tableRow}>
                                                    <Text style={styles.tableText}>Rear Outter Bearing Number:</Text>
                                                        <Text style={styles.tableText}>{props.equipmentData.wheels.rearOutterBearingNum === '' || _.isUndefined(props.equipmentData.wheels.rearOutterBearingNum) 
                                                        ? "N/A" : props.equipmentData.wheels.rearOutterBearingNum.charAt(0).toUpperCase() + props.equipmentData.wheels.rearOutterBearingNum.slice(1)}
                                                    </Text>
                                                </View>
                                                <View style={styles.tableRow}>
                                                    <Text style={styles.tableText}>Front Outter Races Number:</Text>
                                                        <Text style={styles.tableText}>{props.equipmentData.wheels.frontOutterRacesNum === '' || _.isUndefined(props.equipmentData.wheels.frontOutterRacesNum) 
                                                        ? "N/A" : props.equipmentData.wheels.frontOutterRacesNum.charAt(0).toUpperCase() + props.equipmentData.wheels.frontOutterRacesNum.slice(1)}
                                                    </Text>
                                                </View>
                                                <View style={styles.tableRow}>
                                                    <Text style={styles.tableText}>Rear Outter Races Number:</Text>
                                                        <Text style={styles.tableText}>{props.equipmentData.wheels.rearOutterRacesNum === '' || _.isUndefined(props.equipmentData.wheels.rearOutterRacesNum) 
                                                        ? "N/A" : props.equipmentData.wheels.rearOutterRacesNum.charAt(0).toUpperCase() + props.equipmentData.wheels.rearOutterRacesNum.slice(1)}
                                                    </Text>
                                                </View>
                                                <View style={styles.tableRow}>
                                                    <Text style={styles.tableText}>Front Outter Seal Number:</Text>
                                                        <Text style={styles.tableText}>{props.equipmentData.wheels.frontOutterSealNum === '' || _.isUndefined(props.equipmentData.wheels.frontOutterSealNum) 
                                                        ? "N/A" : props.equipmentData.wheels.frontOutterSealNum.charAt(0).toUpperCase() + props.equipmentData.wheels.frontOutterSealNum.slice(1)}
                                                    </Text>
                                                </View>
                                                <View style={styles.tableRow}>
                                                    <Text style={styles.tableText}>Rear Outter Seal Number:</Text>
                                                        <Text style={styles.tableText}>{props.equipmentData.wheels.rearOutterSealNum === '' || _.isUndefined(props.equipmentData.wheels.rearOutterSealNum) 
                                                        ? "N/A" : props.equipmentData.wheels.rearOutterSealNum.charAt(0).toUpperCase() + props.equipmentData.wheels.rearOutterSealNum.slice(1)}
                                                    </Text>
                                                </View>
                                                <View style={styles.tableRow}>
                                                    <Text style={styles.tableText}>Front Inner Bearing Number:</Text>
                                                        <Text style={styles.tableText}>{props.equipmentData.wheels.frontInnerBearingNum === '' || _.isUndefined(props.equipmentData.wheels.frontInnerBearingNum) 
                                                        ? "N/A" : props.equipmentData.wheels.frontInnerBearingNum.charAt(0).toUpperCase() + props.equipmentData.wheels.frontInnerBearingNum.slice(1)}
                                                    </Text>
                                                </View>
                                                <View style={styles.tableRow}>
                                                    <Text style={styles.tableText}>Rear Inner Bearing Number:</Text>
                                                        <Text style={styles.tableText}>{props.equipmentData.wheels.rearInnerBearingNum === '' || _.isUndefined(props.equipmentData.wheels.rearInnerBearingNum) 
                                                        ? "N/A" : props.equipmentData.wheels.rearInnerBearingNum.charAt(0).toUpperCase() + props.equipmentData.wheels.rearInnerBearingNum.slice(1)}
                                                    </Text>
                                                </View>
                                                <View style={styles.tableRow}>
                                                    <Text style={styles.tableText}>Front Inner Races Number:</Text>
                                                        <Text style={styles.tableText}>{props.equipmentData.wheels.frontInnerRacesNum === '' || _.isUndefined(props.equipmentData.wheels.frontInnerRacesNum) 
                                                        ? "N/A" : props.equipmentData.wheels.frontInnerRacesNum.charAt(0).toUpperCase() + props.equipmentData.wheels.frontInnerRacesNum.slice(1)}
                                                    </Text>
                                                </View>
                                                <View style={styles.tableRow}>
                                                    <Text style={styles.tableText}>Rear Inner Races Number:</Text>
                                                        <Text style={styles.tableText}>{props.equipmentData.wheels.rearInnerRacesNum === '' || _.isUndefined(props.equipmentData.wheels.rearInnerRacesNum) 
                                                        ? "N/A" : props.equipmentData.wheels.rearInnerRacesNum.charAt(0).toUpperCase() + props.equipmentData.wheels.rearInnerRacesNum.slice(1)}
                                                    </Text>
                                                </View>
                                                <View style={styles.tableRow}>
                                                    <Text style={styles.tableText}>Front Inner Seal Number:</Text>
                                                        <Text style={styles.tableText}>{props.equipmentData.wheels.frontInnerSealNum === '' || _.isUndefined(props.equipmentData.wheels.frontInnerSealNum) 
                                                        ? "N/A" : props.equipmentData.wheels.frontInnerSealNum.charAt(0).toUpperCase() + props.equipmentData.wheels.frontInnerSealNum.slice(1)}
                                                    </Text>
                                                </View>
                                                <View style={styles.tableRow}>
                                                    <Text style={styles.tableText}>Rear Inner Seal Number:</Text>
                                                        <Text style={styles.tableText}>{props.equipmentData.wheels.rearInnerSealNum === '' || _.isUndefined(props.equipmentData.wheels.rearInnerSealNum) 
                                                        ? "N/A" : props.equipmentData.wheels.rearInnerSealNum.charAt(0).toUpperCase() + props.equipmentData.wheels.rearInnerSealNum.slice(1)}
                                                    </Text>
                                                </View>
                                            </View>
                                        </View>

                                        <View style={styles.subTable}>
                                            <View style={styles.tableRow}>
                                                <Text style={styles.tableText}>Left Slack Adjuster:</Text>
                                                    <Text style={styles.tableText}>{props.equipmentData.brakes.leftSlackAdjustors === '' || _.isUndefined(props.equipmentData.brakes.leftSlackAdjustors) 
                                                    ? "N/A" : props.equipmentData.brakes.leftSlackAdjustors.charAt(0).toUpperCase() + props.equipmentData.brakes.leftSlackAdjustors.slice(1)}
                                                </Text>
                                            </View>
                                            <View style={styles.tableRow}>
                                                <Text style={styles.tableText}>Right Slack Adjuster:</Text>
                                                    <Text style={styles.tableText}>{props.equipmentData.brakes.rightSlackAdjustors === '' || _.isUndefined(props.equipmentData.brakes.rightSlackAdjustors) 
                                                    ? "N/A" : props.equipmentData.brakes.rightSlackAdjustors.charAt(0).toUpperCase() + props.equipmentData.brakes.rightSlackAdjustors.slice(1)}
                                                </Text>
                                            </View>
                                            <View style={styles.tableRow}>
                                                <Text style={styles.tableText}>Left Linings/Hardware:</Text>
                                                    <Text style={styles.tableText}>{props.equipmentData.brakes.leftLiningsHardware === '' || _.isUndefined(props.equipmentData.brakes.leftLiningsHardware) 
                                                    ? "N/A" : props.equipmentData.brakes.leftLiningsHardware.charAt(0).toUpperCase() + props.equipmentData.brakes.leftLiningsHardware.slice(1)}
                                                </Text>
                                            </View>
                                            <View style={styles.tableRow}>
                                                <Text style={styles.tableText}>Right Linings/Hardware:</Text>
                                                    <Text style={styles.tableText}>{props.equipmentData.brakes.rightLiningsHardware === '' || _.isUndefined(props.equipmentData.brakes.rightLiningsHardware) 
                                                    ? "N/A" : props.equipmentData.brakes.rightLiningsHardware.charAt(0).toUpperCase() + props.equipmentData.brakes.rightLiningsHardware.slice(1)}
                                                </Text>
                                            </View>
                                            <View style={styles.tableRow}>
                                                <Text style={styles.tableText}>Left Cams:</Text>
                                                    <Text style={styles.tableText}>{props.equipmentData.brakes.leftCams === '' || _.isUndefined(props.equipmentData.brakes.leftCams) 
                                                    ? "N/A" : props.equipmentData.brakes.leftCams.charAt(0).toUpperCase() + props.equipmentData.brakes.leftCams.slice(1)}
                                                </Text>
                                            </View>
                                            <View style={styles.tableRow}>
                                                <Text style={styles.tableText}>Right Cams:</Text>
                                                    <Text style={styles.tableText}>{props.equipmentData.brakes.rightCams === '' || _.isUndefined(props.equipmentData.brakes.rightCams) 
                                                    ? "N/A" : props.equipmentData.brakes.rightCams.charAt(0).toUpperCase() + props.equipmentData.brakes.rightCams.slice(1)}
                                                </Text>
                                            </View>
                                            <View style={styles.tableRow}>
                                                <Text style={styles.tableText}>Left Cam Bushings:</Text>
                                                    <Text style={styles.tableText}>{props.equipmentData.brakes.leftCamBushings === '' || _.isUndefined(props.equipmentData.brakes.leftCamBushings) 
                                                    ? "N/A" : props.equipmentData.brakes.leftCamBushings.charAt(0).toUpperCase() + props.equipmentData.brakes.leftCamBushings.slice(1)}
                                                </Text>
                                            </View>
                                            <View style={styles.tableRow}>
                                                <Text style={styles.tableText}>Right Cam Bushings:</Text>
                                                    <Text style={styles.tableText}>{props.equipmentData.brakes.rightCamBushings === '' || _.isUndefined(props.equipmentData.brakes.rightCamBushings) 
                                                    ? "N/A" : props.equipmentData.brakes.rightCamBushings.charAt(0).toUpperCase() + props.equipmentData.brakes.rightCamBushings.slice(1)}
                                                </Text>
                                            </View>
                                            <View style={styles.tableRow}>
                                                <Text style={styles.tableText}>Left Drum Number:</Text>
                                                    <Text style={styles.tableText}>{props.equipmentData.brakes.leftDrumNum === '' || _.isUndefined(props.equipmentData.brakes.leftDrumNum) 
                                                    ? "N/A" : props.equipmentData.brakes.leftDrumNum.charAt(0).toUpperCase() + props.equipmentData.brakes.leftDrumNum.slice(1)}
                                                </Text>
                                            </View>
                                            <View style={styles.tableRow}>
                                                <Text style={styles.tableText}>Right Drum Number:</Text>
                                                    <Text style={styles.tableText}>{props.equipmentData.brakes.rightDrumNum === '' || _.isUndefined(props.equipmentData.brakes.rightDrumNum) 
                                                    ? "N/A" : props.equipmentData.brakes.rightDrumNum.charAt(0).toUpperCase() + props.equipmentData.brakes.rightDrumNum.slice(1)}
                                                </Text>
                                            </View>
                                        </View>
                                        <View style={styles.tableRow}>
                                            <View style={styles.subTable}>
                                                <View style={styles.tableRow}>
                                                        <Text style={styles.tableText}>Steering Axle:</Text>
                                                            <Text style={styles.tableText}>{props.equipmentData.brakes.steeringAxle === '' || _.isUndefined(props.equipmentData.brakes.steeringAxle) 
                                                            ? "N/A" : props.equipmentData.brakes.steeringAxle.charAt(0).toUpperCase() + props.equipmentData.brakes.steeringAxle.slice(1)}
                                                        </Text>
                                                </View>
                                                <View style={styles.tableRow}>
                                                        <Text style={styles.tableText}>Rear Axle:</Text>
                                                            <Text style={styles.tableText}>{props.equipmentData.brakes.rearAxle === '' || _.isUndefined(props.equipmentData.brakes.rearAxle) 
                                                            ? "N/A" : props.equipmentData.brakes.rearAxle.charAt(0).toUpperCase() + props.equipmentData.brakes.rearAxle.slice(1)}
                                                        </Text>
                                                </View>
                                            </View>
                                        </View>
                                    </View>
                                </View>
                            : null
                            }
                            
                        </Page>
                    </Document>
                </PDFViewer>
            </div>
        </div>
    )
};

export default withRouter(EquipmentPDF);