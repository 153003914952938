import React, { useState, useEffect } from 'react';
import moment from 'moment'
import MaterialTable from '@material-table/core';
import { TableIcons } from '../../../../constants/tableIcons';
import { firestore } from '../../../../firebase/firebase'
import DeliveryOptions from '../../../components/Reports/Deliveries/DeliveryOptions'
import { Grid, Paper, Typography, IconButton } from '@material-ui/core';
import _ from 'lodash'
import clsx from 'clsx';
import ReportSelect from '../../../components/Reports/ReportSelect'
import { useUser } from '../../../../providers/UserContext'
import { makeStyles, useMediaQuery } from '@material-ui/core';
import InfoIcon from '@material-ui/icons/Info';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import { useTheme } from '@material-ui/core/styles';
import Collapse from '@material-ui/core/Collapse';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import PhotoCameraIcon from '@material-ui/icons/PhotoCamera';
import Tooltip from '@material-ui/core/Tooltip';
import { Skeleton } from '@material-ui/lab';

const useStyles = makeStyles((theme) => ({

    content: {
        padding: '32px',
        paddingRight: '230px',
        overflow: 'auto'
    },

    sidebar: {
        position: 'fixed',
        right: 0,
        top: '64px',
        maxWidth: '256px',
        width: '100%',
        padding: '16px',
        minHeight: '100vh',
        zIndex: '1000',
    },
    expand: {
        position: 'fixed',
        right: 0,
        top: '444px',
        transform: 'rotate(90deg)',
        marginLeft: 'auto',
        transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.shortest,
        }),
    },
    expandOpen: {
        position: 'fixed',
        right: 253,
        top: '444px',
        transform: 'rotate(270deg)',
    },
}));


const DeliveryReport = (props) => {
    const { users, usersLoading, getUsers } = useUser();
    const [timeElapsed, setTimeElapsed] = useState(0);

    useEffect(() => {
        getUsers();
    }, []) // eslint-disable-line react-hooks/exhaustive-deps
    const classes = useStyles();
    const theme = useTheme();
    const smallScreen = useMediaQuery(theme.breakpoints.down('sm'));

    
    const [startDate, setStartDate] = useState("startDate" in sessionStorage ? moment(JSON.parse(sessionStorage.getItem("startDate"))) : moment());
    const [endDate, setEndDate] = useState("endDate" in sessionStorage ? moment(JSON.parse(sessionStorage.getItem("endDate"))) : moment());

    const [deliveryData, setDeliveryData] = useState(JSON.parse(sessionStorage.getItem("deliveryData")) || []);
    const [unitData, setUnitData] = useState(JSON.parse(sessionStorage.getItem("deliveryUnitData")) || []);
    const [errorMessage, setErrorMessage] = useState(null)
    const [loading, setLoading] = useState(false)

    const [autoCompleteValue, setAutoCompleteValue] = useState("deliveryAutoComplete" in sessionStorage ? JSON.parse(sessionStorage.getItem("deliveryAutoComplete")) : "")
    const [searchState, setSearch] = useState({
        type: JSON.parse(sessionStorage.getItem("deliveryOptionsSearchType")) || 'customer',
        value: JSON.parse(sessionStorage.getItem("deliveryOptionsSearchValue")) || '',
    });
    const [searchBarValue, setSearchBarValue] = useState("deliverySearchBarValue" in sessionStorage ? JSON.parse(sessionStorage.getItem("deliverySearchBarValue")) : null)

    const startDateHandler = date => {
        setStartDate(date)
        sessionStorage.setItem("startDate", JSON.stringify(date))
    }
    const endDateHandler = date => {
        setEndDate(date)
        sessionStorage.setItem("endDate", JSON.stringify(date))
    }

    const searchHandler = (event) => {
        setSearch({ ...searchState, [event.target.name]: event.target.value })
        if(event.target.name === "type") sessionStorage.setItem("deliveryOptionsSearchType", JSON.stringify(event.target.value)) 
        if(event.target.name === "value") sessionStorage.setItem("deliveryOptionsSearchValue", JSON.stringify(event.target.value))
    }

    const clearSearch = () => {
        setSearch({ ...searchState, value: '' });
        sessionStorage.removeItem("deliveryOptionsSearchValue");
    }
   
    const deliveryClickHandler = path => {
        props.deliveryClickHandler(path);
    }

    useEffect(() => {



    }, [smallScreen]);

    //Jian's code  
    // const ticketEntryClickHandler = (path) => {   
    //     //check if delivery ID exists in Ticket Entry collections or not  
    //     firestore.collection('ticketEntries').where("deliveryID", "==", path).get()
    //     .then(querySnapshot => {
    //         const ticketnumberexistResponse = querySnapshot.docs.map((doc) => {
    //             return {
    //                 'docId': doc.id,
    //                 deliveryID: doc.deliveryID,
    //             }
    //         }) 
    //         if (ticketnumberexistResponse[0])  {                      
    //             history.push({
    //                 pathname: '/dashboard/ticketentry/edit',
    //                 search: "?" + new URLSearchParams({ docId: ticketnumberexistResponse[0].docId }).toString(),
    //             })                 
    //         }else
    //         {                
    //             history.push({
    //                 pathname: '/dashboard/ticketentry/new',
    //                 search: "?" + new URLSearchParams({ docId: path }).toString(),
    //             }) 
    //         }
    //     })        
    // }
   
    async function dateCompat() {
        try {
            let query = firestore.collection('units');
            let uid = users.find(user => user.displayName === autoCompleteValue)?.uuid;
    
            if (uid) query = query.where('uid', '==', uid);
            
            // Chaining orderBy correctly
            query = query.orderBy('date');
    
            const oldDate = query
                .where("date", ">=", startDate.startOf('day').toDate())
                .where("date", "<=", endDate.endOf('day').toDate())
                .get();
    
            const newDate = query
                .where("date", ">=", startDate.startOf('day').utc().valueOf())
                .where("date", "<=", endDate.endOf('day').utc().valueOf())
                .get();
    
            const [oldDateSnapshot, newDateSnapshot] = await Promise.all([newDate, oldDate]);
    
            const oldDateArray = oldDateSnapshot.docs;
            const newDateArray = newDateSnapshot.docs;
    
            return _.concat(oldDateArray, newDateArray);
        } catch (error) {
            console.error('Error in dateCompat:', error);
            throw error;
        }
    }

    
    const generateHandler = async () => {
        try {
            // Remove session storage items
            var start = window.performance.now();
            const sessionKeys = [
                "timesheetData", "timesheetUnitData", "jobData", "defectData", 
                "unitData", "deliveryData", "deliveryUnitData", "covidData", 
                "checklistData", "photoData", "filteredPhotoData", 
                "REACT_COOL_IMG", "filteredOrderData"
            ];
            sessionKeys.forEach(key => sessionStorage.removeItem(key));
    
            setErrorMessage(null);
    
            if (endDate.isBefore(startDate)) {
                setErrorMessage('Start Date later than End Date!');
                return;
            }
            if (endDate.diff(startDate, 'days') > 365) { // Ensure date diff is in days
                setErrorMessage('Query cannot be more than a one year period.');
                return;
            }
    
            setLoading(true);
    
            console.time('DateCompat Query');
            const querySnapshot = await dateCompat();
            console.timeEnd('DateCompat Query');
    
            if (!querySnapshot) {
                throw new Error("Invalid querySnapshot returned from dateCompat");
            }
    
            const unitResponse = querySnapshot.map(doc => {
                if (typeof doc.data === 'function') {
                    const data = doc.data();
                    return {
                        ...data,
                        docId: doc.id,
                        dateString: new Date(data.date).toISOString().split('T')[0] // Convert milliseconds to "YYYY-MM-DD"
                    };
                } else {
                    console.error('Invalid document in querySnapshot:', doc);
                    return null;
                }
            }).filter(doc => doc !== null);
    
            setUnitData(unitResponse);
    
            const fetchDeliveries = async () => {
                try {
                    console.log(`Fetching deliveries between ${startDate.format("YYYY-MM-DD")} and ${endDate.format("YYYY-MM-DD")}`);
                    const snapshot = await firestore.collection('deliveries')
                        .where("date", ">=", startDate.format("YYYY-MM-DD"))
                        .where("date", "<=", endDate.format("YYYY-MM-DD"))
                        .get();
                    
                    if (!snapshot || !snapshot.docs) {
                        throw new Error("Invalid snapshot returned from fetchDeliveries");
                    }
    
                    return snapshot.docs.map(doc => {
                        if (typeof doc.data === 'function') {
                            const data = doc.data();
                            return {
                                docId: doc.id,
                                ...data,
                            };
                        } else {
                            console.error('Invalid document in fetchDeliveries:', doc);
                            return null;
                        }
                    }).filter(doc => doc !== null);
                } catch (error) {
                    console.error('Error fetching deliveries:', error);
                    throw error;
                }
            };
    
            console.time('Total Delivery Fetch Time');
            const deliverySnapshot = await fetchDeliveries();
            console.timeEnd('Total Delivery Fetch Time');
    
            const deliveryResponse = deliverySnapshot.map(doc => ({
                ...doc,
                docId: doc.docId,
            }));
    
            setDeliveryData(deliveryResponse);
            sessionStorage.setItem("deliveryData", JSON.stringify(deliveryResponse));
            sessionStorage.setItem("deliveryUnitData", JSON.stringify(unitResponse));
            var end = window.performance.now();
            setTimeElapsed(end - start);
    
            setLoading(false);
        } catch (e) {
            handleError(e);
        }
    };
    
    const handleError = (e) => {
        setDeliveryData([]);
        setUnitData([]);
        sessionStorage.removeItem("deliveryData");
        sessionStorage.removeItem("deliveryUnitData");
        setLoading(false);
    
        switch (e.name) {
            case 'QuotaExceededError':
                setErrorMessage("Query size too big for browser storage.");
                console.error(e.message);
                break;
            default:
                setErrorMessage("Unable to process.");
                console.error(e.message);
        }
    };
   
    function mergeData() {
        return deliveryData.map(x => {
            const user = users.find((user) => user.uuid === x.uid)
            x.uid = _.isUndefined(user) ? x.uid : user.displayName;
            const units = unitData.filter(unit => unit.docId === x.units[0]);
            return {
                ...x,
                unitNumber: units.map(unit => unit.unitNumber),
                date: units.map(unit => unit.date < 9999999999 ? unit.date *= 1000 : unit.date),
               
            }
        });
    }


    function filterData() {
        let data = mergeData();
        if (autoCompleteValue !== (""||null) && searchState.type === "employee") {
            
            data=data.filter(x => x.uid === autoCompleteValue)
        }
        if (searchState.value !== "") {
            console.log('NOT EMPTYU')
            switch (searchState.type) {
                case 'customer':
                    data = data.filter(x => x.customer.toLowerCase().includes(searchState.value.toLowerCase()));
                    break;
                case 'ticket':
                    data = data.filter(x => x.ticket.toLowerCase().includes(searchState.value.toLowerCase()));
                    break;
                    
                default:    
                    break;
            }
        } return data;
    }

    const [expanded, setExpanded] = useState(JSON.parse(sessionStorage.getItem("reportExpanded")) || true);
    const handleExpandClick = () => {
        if(expanded)
            setExpanded(false);
        else
            setExpanded(true);    
        sessionStorage.setItem("reportExpanded", JSON.stringify(expanded))
    };
    return (
        <React.Fragment>
            <div className={classes.content}
            style={expanded ? {paddingRight: '260px' } : {paddingRight: smallScreen ? '12px' : '230px' }} >
                {loading === false ?
                <MaterialTable
                    columns={[
                        { title: 'Customer', field: 'customer' },
                        { title: 'Ticket', field: 'ticket' },
                        { title: 'Employee', field: 'uid' },
                        { title: 'Date', field: 'date', render: rowData => {
                            
                            return moment.utc(rowData.date[0]).format('YYYY-MM-DD')
                            } 
                        },
                        { title: 'Delivery To & From', render: rowData => rowData.from + " to " + rowData.to },
                        { title: 'Material', field: 'material' },
                        { title: 'Quantity', field: 'quantity' },
                        { title: 'Invoice Status', render: rowData => {
                            let container = !_.isUndefined(rowData.invoicePayDate) ? "Invoiced" : 'Not Invoiced'
                            return <React.Fragment>{container}</React.Fragment>
                        }},
                        { title: 'Payroll Status', render: rowData => {
                            let container = !_.isUndefined(rowData.operatorPayDate) ? "Payrolled" : 'Not Payrolled'
                            return <React.Fragment>{container}</React.Fragment>
                        }},
                        { title: 'Photo(s)?', width: '1%', field: 'photoURLs', render: rowData => {
                            return rowData.photoURLs.length > 0 ? <Tooltip title="Delivery has a photo!"><PhotoCameraIcon/></Tooltip> : null
                        }},
                    ]}
                    data={filterData()}
                    title='Deliveries'
                    icons={TableIcons}
                    isLoading={loading || usersLoading}
                    onSearchChange={ (value) => {setSearchBarValue(value);  sessionStorage.setItem("deliverySearchBarValue", JSON.stringify(value))}}
                    onRowsPerPageChange={(num) => {sessionStorage.setItem("deliveriesReportRowsPerPage", JSON.parse(num))}}
                    actions={[
                        {
                            icon: TableIcons.OpenInNew,
                            tooltip: 'View Delivery',
                            onClick: (event, rowData) => deliveryClickHandler(rowData['docId']),
                        },
                        // {
                        //     icon: TableIcons.Add,
                        //     tooltip: 'Ticket Entry',
                        //     pathname: 'dashboard/ticketentry/new',
                        //     onClick: (event, rowData) => ticketEntryClickHandler(rowData['docId']),                           
                        // },
                    ]} //Jian's code
                    onPageChange={ (pageNum) => { sessionStorage.setItem("deliveriesReportPageValue", JSON.stringify(pageNum)) }}
                    options={{
                        pageSizeOptions: [5, 25, 100],
                        pageSize: "deliveriesReportRowsPerPage" in sessionStorage ? JSON.parse(sessionStorage.getItem("deliveriesReportRowsPerPage")) : 25,
                        initialPage: "deliveriesReportPageValue" in sessionStorage ? JSON.parse(sessionStorage.getItem("deliveriesReportPageValue")) : 0,
                        toolbar: true,
                        paging: true,
                        columnsButton: true,
                        sorting: true,
                        thirdSortClick: false,
                        searchText: searchBarValue,
                        //actionsColumnIndex: -1,
                    }}
                    detailPanel = {[
                        rowData => ({
                            disabled: rowData.notes ? false : true,
                            tooltip: rowData.notes ? 'Show Delivery Notes' : null,
                            icon: () => rowData.notes ? <InfoIcon /> : null,
                            openIcon: () => rowData.notes ? <InfoOutlinedIcon /> : null,
                            render: () => <React.Fragment>
                            <Grid item xs={6}>
                                <Typography style={{ lineHeight: 1.5, paddingLeft: '15px', paddingRop: '10px' }} variant='h6'>Delivery Notes</Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Typography style={{ lineHeight: 1, padding: '15px' }} variant='body1' align='left'>{rowData.notes}</Typography>
                            </Grid>
                            </React.Fragment>,
                        })
                    ]}
                    onRowClick={(event, rowData, togglePanel) => {
                        if (rowData.notes !== "") {
                          togglePanel();
                        } else {
                          return null;
                        }
                    }}
                />
                : <Skeleton variant='rect' width={'80vw'} height={'175vh'}/>}
                {smallScreen ?
                <div>
                    <IconButton
                        className={clsx(classes.expand, {
                            [classes.expandOpen]: expanded,
                        })}
                        
                        onClick={handleExpandClick}
                        aria-expanded={expanded}
                        aria-label="show more"
                        >
                        <ExpandMoreIcon style={{fontSize: '50px'}} />
                    </IconButton>
                    <Collapse orientation="horizontal" in={expanded} timeout="auto">
                        <Paper className={classes.sidebar}>
                            <ReportSelect
                                reportType={props.reportType}
                                onchange={props.onchange}
                            />
                            <DeliveryOptions
                                timeElapsed={timeElapsed}
                                searchState={searchState} searchHandler={searchHandler}
                                clearSearch={clearSearch}
                                startDate={startDate} startDateHandler={startDateHandler}
                                endDate={endDate} endDateHandler={endDateHandler}
                                searchValue={autoCompleteValue} searchValueChanged={setAutoCompleteValue}
                                generateHandler={generateHandler}
                                errorMsg={errorMessage}
                                loading={loading}
                            />
                        </Paper>
                    </Collapse>  
                </div>
                :
                <div>
                    <IconButton
                        className={clsx(classes.expand, {
                            [classes.expandOpen]: expanded,
                        })}
                        
                        onClick={handleExpandClick}
                        aria-expanded={expanded}
                        aria-label="show more"
                        >
                        <ExpandMoreIcon style={{fontSize: '50px'}} />
                    </IconButton>
                    <Collapse orientation="horizontal" in={expanded} timeout="auto">
                        <Paper className={classes.sidebar}>
                            <ReportSelect
                                reportType={props.reportType}
                                onchange={props.onchange}
                            />
                            <DeliveryOptions
                                timeElapsed={timeElapsed}
                                searchState={searchState} searchHandler={searchHandler}
                                clearSearch={clearSearch}
                                startDate={startDate} startDateHandler={startDateHandler}
                                endDate={endDate} endDateHandler={endDateHandler}
                                searchValue={autoCompleteValue} searchValueChanged={setAutoCompleteValue}
                                generateHandler={generateHandler}
                                errorMsg={errorMessage}
                                loading={loading}
                            />
                        </Paper>
                    </Collapse>  
                </div>
}
            </div>
        </React.Fragment>
    )
}

export default DeliveryReport