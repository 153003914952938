import React, {useState, useEffect} from 'react';
import { makeStyles, Divider, Grid, Button, TextField } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import DatePicker from '../UI/DatePicker/DatePicker';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Autocomplete } from '@material-ui/lab';
import { useUser } from '../../../providers/UserContext'


const useStyles = makeStyles((theme) => ({
    detailsPanel: {
        padding: theme.spacing(2),
        paddingTop: theme.spacing(14),
        height: '100vh',
        right: 0,
        zIndex: 12,
        overflowY: 'auto',
    },

    btnGroup: {
        width: '100%',
    },

    filterItem: {
        marginTop: '4px',
        marginBottom: '4px',
    },

    search: {
        display: 'flex',
        boxShadow: 'inset 0px 0px 2px 2px rgba(207,207,207,0.49)',
        //backgroundColor: '#fafafa',
        borderRadius: '4px',
        alignItems: 'center',
        justifyContent: 'center',
        padding: theme.spacing(2),
    },
}));

const WorkOrderOptions = (props) => {

    const classes = useStyles();
    const { users, userLoading, getUsers } = useUser();
    const [open, setOpen] = useState(false);
    const [typedValue, setTypedValue] = useState('')

    useEffect(() => {
        getUsers()
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <Grid container spacing={2}>

            <Grid item sm={12}>
                <Grid container className={classes.search}>
                    <Grid item sm={12} className={classes.filterItem}>
                        <DatePicker
                            label="Start Date"
                            value={props.startDate}
                            onChange={props.startDateHandler}
                        />
                    </Grid>

                    <Grid item sm={12} className={classes.filterItem}>
                        <DatePicker
                            label="End Date"
                            value={props.endDate}
                            onChange={props.endDateHandler}
                        />
                    </Grid>
                </Grid>
                <Grid container className={classes.search}>
                    <Grid item sm={12} className={classes.filterItem}>
                    <Autocomplete
                                open={open}
                                onOpen={() => {
                                    setOpen(true);
                                }}
                                onClose={() => {
                                    setOpen(false);
                                }}
                                loading={userLoading}
                                label="Search"
                                id="autocomplete"
                                autoComplete
                                autoHighlight
                                inputValue={typedValue}
                                onInputChange={(event, newValue) => { setTypedValue(newValue) }}
                                value={props.searchValue}
                                onChange={(event, newValue) => { props.setSearchValue(newValue); sessionStorage.setItem("deliveryAutoComplete", JSON.stringify(newValue)); }}
                                options={users.filter(x=> x.employment?.active).map((user) => user.displayName ?? '').sort((a, b) => a.toLowerCase() > b.toLowerCase())}
                                getOptionSelected={(option, value) => option?.displayName === value?.displayName}
                                getOptionLabel={(option) => option}
                                renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label="Employee"
                                    variant="outlined"
                                    InputProps={{
                                        ...params.InputProps,
                                        endAdornment: (
                                            <React.Fragment>
                                                {userLoading ? <CircularProgress color="inherit" size={20} /> : null}
                                                {params.InputProps.endAdornment}
                                            </React.Fragment>
                                        ),
                                    }}
                                />
                                )}

                            />
                    </Grid>

                </Grid>
            </Grid>

            <Grid item sm={12}>
                <Divider/>
            </Grid>

            <Grid item sm={12}>
                {(props.errorMsg && <Typography>{props.errorMsg}</Typography>)}
            </Grid>
            <Grid item sm={12} className={classes.btnGroup}>
                <Button variant="contained" fullWidth disabled={props.loading} onClick={props.generateHandler} > Generate </Button>
            </Grid>

            <Grid item sm={12} style={props.progress === 0 || props.progress === 100 ? {visibility: 'hidden'} : {visibility: 'visible'}}>
                <CircularProgress variant="determinate" style={props.progress < 99 ? {color: "orange"} : {color: "green"}} value={props.progress} />
                <small>{props.loadingText}</small>
            </Grid>

        </Grid>
    )
}

export default WorkOrderOptions;