import React, { useState, useEffect, useRef } from 'react';
import WorkOrderForm from '../../components/WorkOrders/WorkOrderForm'
import { firestore, storage } from '../../../firebase/firebase'
import { withRouter } from 'react-router-dom';
import moment from 'moment';
import { useUnitDescription } from '../../../providers/UnitDescriptionContext'
import _ from 'lodash';
import { uploadString, getDownloadURL, uploadBytes } from "firebase/storage";

const WorkOrderNew = (props) => {

    const initialWorkOrder = {
        dateCreated: Number(moment().format('x')),
        dateCompleted: '',

        customer: {
            companyName: 'Gee Tee Holdings',
            contactName: 'Gee Tee',
            contactPhone: '204-444-3069',
            outsideWork: false,
            signedOff: false,
            sigURL: '',
        },

        vehicle: {
            unitNumber: '',
            year: '',
            make: '',
            mileage: '',
            vin: '',
            licensePlate: '',
            cabCardNumber: '',
            PONumber: '',
            safetyDue: false,
            safetyDueDate: null,
            unitDocId: '',
        },

        partsNeeded: [],
        partsAdded: [],
        repairsNeeded: [],
        repairsCompleted: [],

        assignedUser: '',
        timesheetID: '',
        userSignature: '',
        notes: '',
    }

    const initialRepair = {
        description: '',
        timeSpentRepairing: '',
        notes: '',
        techNotes: '',
        completed: false,
    }

    const initialPart = {
        partDescription: '',
        partNumber: '',
        added: false,
        photoUrls: [],
        quantity: 1,
    }

    const userSig = useRef()
    const customerSig = useRef()

    const { units, unitLoading, getUnits } = useUnitDescription();

    const [workOrderForm, setWorkOrderForm] = useState({ ...initialWorkOrder });
    const [repairForm, setRepairForm] = useState({ ...initialRepair });
    const [partForm, setPartForm] = useState({ ...initialPart });
    const [submitting, setSubmitting] = useState(false);
    const [success, setSuccess] = useState(false);
    const [error, setError] = useState("");
    const [unitAutocompleteValue, setUnitAutocompleteValue] = useState({})
    const [itemIndex, setItemIndex] = useState(null)

    useEffect(() => {
        if(units.length <= 0){ getUnits() }
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    const handleAddAdditionalInfo = (type) => {
        if(type === 'New Repair'){
            setWorkOrderForm((prevForm) => ({
                ...prevForm,
                repairsNeeded: [...prevForm.repairsNeeded, { ...repairForm }],
            }));
            setRepairForm({ ...initialRepair });
        }
        else if(type === 'Edit Repair'){
            let repairsNeededContainer = _.cloneDeep(workOrderForm.repairsNeeded)
            repairsNeededContainer.splice(itemIndex, 1, repairForm)

            setWorkOrderForm((prevForm) => ({
                ...prevForm,
                repairsNeeded: [...repairsNeededContainer],
            }));
            setRepairForm({ ...initialRepair });
        }
        else if(type === 'New Part'){
            setWorkOrderForm((prevForm) => ({
                ...prevForm,
                partsNeeded: [...prevForm.partsNeeded, { ...partForm }],
            }));
            setPartForm({ ...initialPart });
        }
        else if(type === 'Edit Part'){
            let partNeededContainer = _.cloneDeep(workOrderForm.partsNeeded)
            partNeededContainer.splice(itemIndex, 1, partForm)

            setWorkOrderForm((prevForm) => ({
                ...prevForm,
                partsNeeded: [...partNeededContainer],
            }));
            setRepairForm({ ...initialPart });
        }
        else{
            console.warn('Error adding additonal info.')
        }
    };

    const handleRemoveAdditionalInfo = (type, index) => {
        if(type === 'Repair'){
            setWorkOrderForm((prevForm) => ({
                ...prevForm,
                repairsNeeded: prevForm.repairsNeeded.filter((repair, i) => i !== index),
            }));
        }
        else if(type === 'Part'){
            setWorkOrderForm((prevForm) => ({
                ...prevForm,
                partsNeeded: prevForm.partsNeeded.filter((part, i) => i !== index),
            }));
        }
        else{
            console.warn('Error removing additonal info.')
        }
    };

    const handleChangeAdditionalInfo = (type, event) => {
        if(type === 'Repair'){
            setRepairForm({ ...repairForm, [event.target.name]: event.target.value });
        }
        else if(type === 'Part'){
            setPartForm({ ...partForm, [event.target.name]: event.target.value });
        }
        else{
            console.warn('Error changing additonal info.')
        }
    };

    const handleChangeNotes = (event) => {
        const { name, value } = event.target;
        setWorkOrderForm((prevForm) => ({
            ...prevForm,
            [name]: value,
        }));
    };

    const handleChange = (event) => {
    const { name, value, checked, type } = event.target;
    const [parentProp, childProp] = name.split('.');

    if (type === 'checkbox') {
        setWorkOrderForm((prevForm) => ({
            ...prevForm,
            [parentProp]: {
            ...prevForm[parentProp],
            [childProp]: checked,
            },
        }));
        } else {
        setWorkOrderForm((prevForm) => ({
            ...prevForm,
            [parentProp]: {
            ...prevForm[parentProp],
            [childProp]: value,
            },
        }));
        }

        //if outside work is unchecked set the company name to Gee Tee Holdings
        if (name === 'customer.outsideWork' && !checked) {
            setWorkOrderForm((prevForm) => ({
                ...prevForm,
                customer: {
                    ...prevForm.customer,
                    companyName: 'Gee Tee Holdings',
                    contactPhone: '204-444-3069',
                    contactName: 'Gee Tee'
                }
            }))
        } else if (name === 'customer.outsideWork' && checked) {
            setWorkOrderForm((prevForm) => ({
                ...prevForm,
                customer: {
                    ...prevForm.customer,
                    companyName: '',
                    contactPhone: '',
                    contactName: '',
                }
            }))
        }
    };

    const handleSafetyDueDateUpdate = (date) => {
        setWorkOrderForm({ ...workOrderForm, vehicle: {...workOrderForm.vehicle, safetyDueDate: moment(date).valueOf()} });
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        createWorkOrder();
        setTimeout(() => { handleResetPage(); }, 3000)
    }

    const handleResetPage = () => {
        setWorkOrderForm(initialWorkOrder)
        setUnitAutocompleteValue({})
        userSig.current && userSig.current.clear()
        customerSig.current && customerSig.current.clear()
        setPartForm({...initialPart})
        setRepairForm({...initialRepair})
        setSuccess(false)
    }

    const checkNewPartPhotos = (partsNeeded) => {
        let returnValue = false
        partsNeeded.forEach((part) => {  if(part.photoUrls?.length > 0) { returnValue = true } })
        return returnValue;
    }

    const createWorkOrder = async () => {
        setSubmitting(true);
        let newWorkOrder = _.cloneDeep(workOrderForm);
        newWorkOrder.partsNeeded.forEach((part) => { part.photoUrls = [] })
        let partsNeededContainer = _.cloneDeep(workOrderForm.partsNeeded)

        //create a new work order in the workOrders collection
        try {
            await firestore.collection('workOrders').add(newWorkOrder)
            .then(async (doc) => {
                newWorkOrder.docId = doc.id;
                const storageRef = storage.ref();
                
                const updateUserSig = new Promise(async (resolve, reject) => {
                    if(!userSig.current.isEmpty() ){
                        const userPhotoRef = storageRef.child('WorkOrders/' + doc.id + '/employeeSignature.png');
                        const userDataURL = await userSig.current.getCanvas().toDataURL()
                        await uploadString(userPhotoRef, userDataURL, 'data_url').then(async (snapshot) => {
                            await getDownloadURL(snapshot.ref).then((url) => {
                                newWorkOrder.userSignature = url;
                                resolve()
                            })
                        }).catch((e) => {
                            setError(e)
                            reject()
                        });
                    }else{ resolve() }
                })

                const updateCustomerSig = new Promise(async (resolve, reject) => {
                    if(!customerSig.current.isEmpty() ){
                        const customerPhotoRef = storageRef.child('WorkOrders/' + doc.id + '/customerSignature.png');
                        const customerDataURL = await customerSig.current.getCanvas().toDataURL()
                        await uploadString(customerPhotoRef, customerDataURL, 'data_url').then(async (snapshot) => {
                            await getDownloadURL(snapshot.ref).then((url) => {
                                newWorkOrder.customer.sigURL = url;
                                newWorkOrder.customer.signedOff = true;
                                resolve()
                            })
                        }).catch((e) => { 
                            setError(e)
                            reject()
                        });
                    }else{ resolve() }
                })

                const updatePartsPhotos = new Promise(async (resolve, reject) => {
                    if(checkNewPartPhotos(partsNeededContainer)){
                        let resoultionCount = [];
                        let resoultionTotal = 0;
                        partsNeededContainer.forEach((part) => { resoultionTotal += part.photoUrls.length })
                        partsNeededContainer.forEach(async (part, index) => {
                            part.photoUrls.forEach(async (file, i) => {
                                const path = storageRef.child(`WorkOrders/${doc.id}/partPhotos/${i}${index}${moment().valueOf()}_${file.name}`);
                                await uploadBytes(path, file).then(async () => {
                                    await getDownloadURL(path).then((url) => {
                                        newWorkOrder.partsNeeded[index].photoUrls.push(url)
                                        resoultionCount.push(url)

                                        if(resoultionCount.length === resoultionTotal){ resolve() }
                                    })
                                }).catch((e) => {
                                    setError(e)
                                    reject()
                                });
                            })
                        })
                    }
                    else{ resolve() }
                })
                
                await Promise.all([ updateUserSig, updateCustomerSig, updatePartsPhotos]) 
                    .then(async () => { 
                        await firestore.collection('workOrders').doc(doc.id).set(newWorkOrder)
                        .then(() =>{
                            setSuccess(true);
                            setSubmitting(false);
                        })
                    }) 
                    .catch((error) => { 
                        console.error(error); 
                        setError(error)
                        setSubmitting(false);
                    });

            })}
            catch (error) {
            console.log(error);
            setError(error);
            setSubmitting(false);
        }
    }

    const handleUnitUpdate = (unit) => {
        setUnitAutocompleteValue(unit)
        let container = _.cloneDeep(workOrderForm)

        container.vehicle = {
            ...container.vehicle,
            unitNumber: '',
            year: '',
            make: '',
            mileage: '',
            vin: '',
            licensePlate: '',
            cabCardNumber: '',
            PONumber: '',
            safetyDue: false,
            safetyDueDate: null,
            unitDocId: '',
        }

        if (unit === null || unit === undefined || unit === ''){
            setWorkOrderForm(container)
        }
        else{
            let fields = ["unitNumber", 'year', 'make', 'licensePlate']      //milage, vin, cabCardNumber, and PONumber not in unitDescriptions?
            fields.forEach((field) => {if(unit[field] && unit[field] !== ''){ container.vehicle[field] = unit[field]} })
            container.vehicle['unitDocId'] = unit.docId
            setWorkOrderForm(container)
        }
    }

    const handleDeletePhoto = (index) => {
        let localPhotoArrayContainer = _.cloneDeep(partForm)
        localPhotoArrayContainer.photoUrls.splice(index, 1)
        setPartForm(localPhotoArrayContainer)
    };

    return (
        <WorkOrderForm
            userForm={workOrderForm}
            repairForm={repairForm}
            partForm={partForm}
            handleChange={handleChange}
            handleSubmit={handleSubmit}
            error={error}
            submitting={submitting}
            success={success}
            handleAddAdditionalInfo={handleAddAdditionalInfo}
            handleRemoveAdditionalInfo={handleRemoveAdditionalInfo}
            handleChangeAdditionalInfo={handleChangeAdditionalInfo}
            handleSafetyDueDateUpdate={handleSafetyDueDateUpdate}
            units={units}
            handleChangeNotes={handleChangeNotes}
            unitLoading={unitLoading}
            handleUnitUpdate={handleUnitUpdate}
            unitAutocompleteValue={unitAutocompleteValue}
            setUnitAutocompleteValue={setUnitAutocompleteValue}
            initialRepair={initialRepair}
            initialPart={initialPart}
            setRepairForm={setRepairForm}
            setPartForm={setPartForm}
            setWorkOrderForm={setWorkOrderForm}
            userSig={userSig}
            customerSig={customerSig}
            itemIndex={itemIndex}
            setItemIndex={setItemIndex}
            handleDeletePhoto={handleDeletePhoto}
        />
    )
}

export default withRouter(WorkOrderNew);