import React from 'react'
import MaterialTable from '@material-table/core';
import { TableIcons } from '../../../../constants/tableIcons';
import {  Grid} from '@material-ui/core';
import { withRouter } from 'react-router-dom';
import _ from 'lodash';

const WorkOrderTable = (props) => {
    return (
    <Grid>
        <MaterialTable
            columns={[
                { title: 'Order #', width:'10%', field: 'orderID',render: rowData => {
                    return <b>{rowData?.orderID ? rowData.orderID : ''}</b> }},
                { title: 'Customer', render: rowData => rowData.customer.companyName},
                { title: 'Unit', render: rowData => rowData.vehicle.unitNumber},
                {
                    title: 'Total Time (Hours)', field: 'totalTime', render: rowData => {
                        let total = 0
                        rowData.repairsNeeded.filter((repair)=>repair.uuid===props.timesheet.uid || (_.isUndefined(repair.uuid) && rowData.assignedUser ===props.timesheet.uid)).forEach(x => {
                            total += Number(x.timeSpentRepairing)
                        })
                        return total
                    }
                },
            ]}
            data={props.workOrderData}
            isLoading={props.workOrderLoading}
            icons={TableIcons}
            renderSummaryRow={({ column, data }) =>
                column.field === "totalTime"
                ? {
                    value: data.reduce((acc, { repairsNeeded }) => {
                        repairsNeeded.filter((repair)=>repair.uuid===props.timesheet.uid || (_.isUndefined(repair.uuid))).forEach(x => {
                            acc += Number(x.timeSpentRepairing)
                        })
                        return acc
                    }, 0),
                    style: { fontWeight:'bold' },
                }
                : undefined
              }
            //pathname='/dashboard/reporting/timesheets/workOrder'
            title={"Work Order Details"}
            options={
                {
                    pageSize: 10,
                    pageSizeOptions: [10, 25, 50, 75, 100],
                    toolbar: true,
                    paging: true,
                    actionsColumnIndex: -1,
                    search: false,
                    emptyRowsWhenPaging: false,
                    thirdSortClick: false,
                    rowStyle: {
                        whiteSpace: 'pre-wrap',
                    },
                }}
            actions={[
                {
                    icon: TableIcons.OpenInNew,
                    tooltip: 'Show Work Order',
                    onClick: (event, rowData) => props.history.push({
                        pathname: '/dashboard/workOrders/form',
                        search: "?" + new URLSearchParams({ docId: rowData.docId }).toString(),
                        state: { workOrderList: props.workOrderData, timesheetID: props.timesheet.docId }
                    })
                },
            ]}
        />
    </Grid>
    )
}
export default withRouter(WorkOrderTable)
