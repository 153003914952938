import React, { useState, useEffect, useCallback } from 'react';
import { withRouter } from 'react-router'
import { firestore } from '../../../firebase/firebase'
import { makeStyles } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import MSDSCard from '../../components/MSDS/MSDSCard';

const useStyles = makeStyles((theme) => ({
    content: {
        display: 'flex', 
        width:'100%', 
        padding: '32px', 
        justifyContent:'center',
        marginTop: '40px',
    },
}));

const MSDS = (props) =>  {

    const classes = useStyles();

    const [msdsData, setMsdsData] = useState({});
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState("");
    const [saving, setSaving] = useState(false);
    const [saveState, setSaveState] = useState({});

    const updateMsdsData = useCallback((e) => {
            setMsdsData(e)
        },
        [],
    );

    const saveMsdsData = () => {
        setSaving(true);
        firestore.collection('msds').doc(msdsData.docId)
            .update(msdsData)
            .then(() => {
                setSaveState(msdsData);
                setSaving(false)
            }).catch((e) => setError(e));
    };

    const cancelSave = () => {
        setMsdsData(saveState);
    };

    useEffect(() => {

        let path = new URLSearchParams(props.location.search).get("docId");

        firestore.collection('msds').where('__name__', "==", path).get()
        .then(querySnapshot => {
            const msdsResponse = querySnapshot.docs.map((doc) => {
                return {
                    ...doc.data(),
                    'docId': doc.id,
                }
            })
            setMsdsData(msdsResponse[0]);
            setSaveState(msdsResponse[0]);
            setLoading(false);
        })
        .catch(e => {
            setError("Safety Data Sheet not found.");
            setLoading(false);
            console.log(e.message);
        })
    }, [props.location.search]);
        
    return (
        <div>
            {loading 
            ? 
            <div className={classes.content}>
                <Skeleton variant='rect' width={'40vw'} height={'45vh'}/>
            </div>
            : 
            <React.Fragment>
                <MSDSCard 
                    error={error}
                    msdsData={msdsData} saveState={saveState} 
                    cancelSave={cancelSave} saveMsdsData={saveMsdsData} 
                    updateMsdsData={updateMsdsData} saving={saving}
                />
            </React.Fragment>
            }
                
        </div>
    )
}

export default withRouter(MSDS);