import React from 'react';
import { Select, FormControl, makeStyles } from '@material-ui/core';
import { REPORT_TYPES } from '../../../constants/reportTypes'

const useStyles = makeStyles((theme) => ({
    formControl: {
        marginBottom: theme.spacing(2),
        width: '100%',
        fontSize: '1.5rem',
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    panel: {
        top: 64,
        right: 0,
        zIndex: 12,
        justifyContent: 'center',
        padding: theme.spacing(2),
    },
}));

const ReportSelect = (props) => {
    const classes = useStyles();
    return (
        <FormControl className={classes.formControl} variant='outlined'>
            <Select
                native
                value={props.reportType}
                onChange={props.onchange}
                inputProps={{
                    name: 'report',
                    id: 'report-type',
                }}
                fullWidth
            >
                <option value={REPORT_TYPES.TIMESHEET}>Timesheets</option>
                <option value={REPORT_TYPES.JOB}>Jobs</option>
                <option value={REPORT_TYPES.DEFECT}>Defects</option>
                <option value={REPORT_TYPES.UNIT}>Units</option>
                <option value={REPORT_TYPES.DELIVERY}>Deliveries</option>
                <option value={REPORT_TYPES.COVID}>Checklists</option>
                <option value={REPORT_TYPES.PHOTOS}>Photos</option>
                <option value={REPORT_TYPES.DOCUMENTS}>Documents</option>
                <option value={REPORT_TYPES.TRAINING}>Trainings</option>
            </Select>
        </FormControl>
    )
}

export default ReportSelect;