import React, { useContext, useState } from "react"

const ThemesContext = React.createContext()

export function useThemes() {
    return useContext(ThemesContext);
}

export function ThemesProvider({ children }) {

    const [showHeader, setShowHeader] = useState(true)
    const [showFooter, setShowFooter] = useState(true);
    const [colorMode, setColorMode] = useState('light');
    const [headerHeight, setHeaderHeight] = React.useState(0);

    const value = {
        showHeader,
        showFooter,
        setShowHeader,
        setShowFooter,
        headerHeight,
        colorMode,
        setColorMode,
        setHeaderHeight,
    }

    return (
        <ThemesContext.Provider value={value}>
            {children}
        </ThemesContext.Provider>
    )
}