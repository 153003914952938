import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router'
import { firestore } from '../../../firebase/firebase'
import { makeStyles, Grid, Button, Snackbar  } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import GiftCardsCard from '../../components/GiftCards/GiftCardsCard';
import _ from 'lodash';

const useStyles = makeStyles((theme) => ({
    content: {
        display: 'flex', 
        width:'100%', 
        padding: '32px', 
        justifyContent:'center',
        marginTop: '40px',
    },
}));

const GiftCard = (props) =>  {

    const classes = useStyles();

    const [giftCard, setGiftCard] = useState({});
    const [saveState, setSaveState] = useState({}); 
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState("");
    const [saving, setSaving] = useState(false);
    
    useEffect(() => {
        let path = new URLSearchParams(props.location.search).get("docId");

        firestore.collection('giftCards').where('code', "==", path).get()
        .then(querySnapshot => {
            const giftCardResponse = querySnapshot.docs.map((doc) => {
                return {
                    ...doc.data(),
                    'docId': doc.id,
                }
            })
            setGiftCard(giftCardResponse[0]);
            setSaveState(giftCardResponse[0]);
            setLoading(false);
        })
        .catch(e => {
            setError("Gift Card not found.");
            setLoading(false);
            console.log(e.message);
        })
    }, [props.location.search]);

    const confirmSave = () => {
        setGiftCard(giftCard);
        setSaveState(giftCard);
        setSaving(true);
        firestore.collection('giftCards').doc(giftCard.code)
            .update(giftCard)
            .then(() => {
                setSaveState(giftCard);
                setSaving(false)
            })
            .catch((e) => {setError(e); console.log(error);});
    };

    const cancelSave = () => {
        setGiftCard(saveState);
    };

    const deleteGiftCard = (code) => {
        firestore.collection('giftCards').doc(code).delete()
            .then(() => {
                props.history.push('/dashboard/giftCards');
            })
            .catch((e) => {setError(e); console.log(error);});
    }
        
    
    return (
        <div>
            {loading || saving
            ? 
            <Grid className={classes.content}>
                <Skeleton variant='rect' width={'52vw'} height={'35vh'}></Skeleton>
            </Grid>
            : 
            <React.Fragment>
                <GiftCardsCard
                    type={'Current'}
                    giftCard={giftCard} setGiftCard={setGiftCard}
                    error={error} deleteGiftCard={deleteGiftCard}
                />
            </React.Fragment>
            }    
                <Snackbar
                    open={!_.isEqual(giftCard, saveState)}
                    message={props.saving ? 'Saving...' : 'Save Document?'}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center'
                    }}
                    action={
                        <React.Fragment>
                            {props.saving
                                ? null
                                : <React.Fragment>
                                    <Button variant='text' color='primary' onClick={cancelSave} style={{ marginLeft: '32px', marginRight: '8px' }}>Cancel</Button>
                                    <Button variant='contained' onClick={confirmSave}>Confirm</Button>
                                </React.Fragment>}
                        </React.Fragment>
                    }
                />
        </div>
    )
}

export default withRouter(GiftCard);