import React, { useState, useEffect, useCallback, useRef } from 'react';
import { Grid, useMediaQuery, makeStyles } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import { withRouter } from 'react-router-dom';
import { firestore, functions } from '../../../firebase/firebase';
import _ from 'lodash';
import moment from 'moment';
import useAsyncError from '../../components/UI/AsyncError/AsyncError';

import CalanderCard from '../../components/Scheduler/CalanderCard';
import DayViewCard from '../../components/Scheduler/DayViewCard';

const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        justifyContent: 'center',
        //width: '75vw',
        margin: 'auto',
    },
}));

const Scheduler = (props) => {
    const theme = useTheme();
    const classes = useStyles();
    const smallScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const throwAsyncError = useAsyncError();
    const sendNotification = functions.httpsCallable('scheduleUpdate');
    const versioningNum = useRef(0);

    //holds the currently displayed document, always up to date
    const [currentDoc, setCurrentDoc] = useState({})
    //loading bool
    const [currentDocLoading, setCurrentDocLoading] = useState(true)
    //holds currently selected date used only when switching back and forth from notes, and inital pull
    const [dateSelected, setDateSelected] = useState(moment().format("YYYY-MM-DD").valueOf())
    //hold either calander or day view type
    const [viewType, setViewType] = useState(JSON.parse(sessionStorage.getItem('scheduleViewType')) || 'Day')
    //hold either schedule or notes for use in day view
    const [tabSelected, setTabSelected] = useState('schedule');
    //menu state anchor
    const [anchorEl, setAnchorEl] = useState(null);

    useEffect(() => {}, [smallScreen]);

    //gets inital data for current doc
    useEffect(() => {
        console.log('inital render pull')
        handleLoadDoc(dateSelected)
        .then((res) => { 
            setCurrentDoc(res) 
            setCurrentDocLoading(false)
        })
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    //takes in date, returns document upon promise resolution
    const handleLoadDoc = (date) => {
        return new Promise(async (resolve) => {
            try{
                await firestore.collection('plannedDeliveries').where('date', '==', date).get()
                .then(async snapshot => {
                    if (!snapshot.empty) {
                        console.log('document exists')
                        let deliveriesContainer = snapshot.docs.map((doc) => {
                            return {
                            ...doc.data(),
                            'docId': doc.id,
                            'title': doc.data().value?.replace(/<[^>]*>/g, ' ').replace(/&nbsp;/g, '\n')   //converts value to plaintext (for displaying in fullcalendar)
                            }
                        })

                        //ERROR CHECKS
                        if(deliveriesContainer.length > 1){ handleDeleteRedundantDocs(deliveriesContainer) }
                        if(typeof deliveriesContainer[0].date !== 'string'){ throw new Error('Follolwing date is not a string:', JSON.stringify(deliveriesContainer[0].date) ) }

                        resolve(deliveriesContainer[0]);

                    } else { 
                        console.log('document does not exist')
                        let newDoc = {
                            value: '',
                            title: '',
                            date: date,
                            sandbox: process.env.NODE_ENV === 'development',
                            versions: [],
                        }

                        await firestore.collection('plannedDeliveries').add(newDoc)
                        .then((querySnapshot) => {
                            newDoc['docId'] = querySnapshot.id
                            resolve(newDoc);
                        })
                    }
                })
            }
            catch(e){
                throwAsyncError(new Error(e.message, e));
            }
        })
    }

    const handleDeleteRedundantDocs = (intakeArray) => {
        const batch = firestore.batch();
        //find the most complete document
        let index = intakeArray.reduce((maxIndex, currentValue, currentIndex, array) => currentValue.value.length > array[maxIndex].value.length ? currentIndex : maxIndex, 0);
        //create list of all others
        let container = intakeArray.filter((x, i) => i !== index)

        container.forEach((doc) => {
            let query = firestore.collection('plannedDeliveries').doc(doc.docId);
            batch.delete(query);
        })

        batch.commit()
        .then(() => { throwAsyncError(new Error('Multiple documents found, deleted extra documents.')); })
        .catch((e) => { throwAsyncError(new Error(e.message, e)); });
    }

    const handleChangeDate = (nextBool) => {
        console.log('handleChangeDate')

        setCurrentDocLoading(true)
        let container = nextBool 
            ? moment(currentDoc.date).add(1, "days").format('YYYY-MM-DD').valueOf() 
            : moment(currentDoc.date).subtract(1, "days").format('YYYY-MM-DD').valueOf()

        setDateSelected(container)

        handleLoadDoc(container).then((res) => {
            setCurrentDoc(res)
            setCurrentDocLoading(false)
        })
    }

    const handleDateClick = (date) => {
        console.log('handleDateClick')

        setCurrentDocLoading(true)
        setViewType('Day')
        sessionStorage.setItem('scheduleViewType', JSON.stringify('Day'))
        setDateSelected(date)

        handleLoadDoc(moment(date).format("YYYY-MM-DD").valueOf()).then((res) => {
            setCurrentDoc(res)
            setCurrentDocLoading(false)
        })
    }

    const handleCalanderClick = () => {
        setViewType('Calendar')
        sessionStorage.setItem('scheduleViewType', JSON.stringify('Calendar'))
    }

    const handleTableChange = (event, newValue) => {
        if(newValue !== tabSelected){
            setCurrentDocLoading(true)
            setTabSelected(newValue)

            handleLoadDoc(newValue === 'notes' ? 'notes' : dateSelected).then((res) => {
                setCurrentDoc(res)
                setCurrentDocLoading(false)
            })
        }
    };

    const handleChangePublished = async () => {
        try{
            //let currentDoc = handleFindExistingDocByDate(selectedDate, plannedDeliveries)
            let publishedContainer = currentDoc.published ? !currentDoc.published : true

            if(publishedContainer){
                sendNotification({
                    published: true, 
                    date: currentDoc.date, 
                    value: currentDoc.value,
                    sandbox: process.env.NODE_ENV === 'development',
                })
            }

            await firestore.collection('plannedDeliveries').doc(currentDoc.docId).set({'published': publishedContainer}, { merge: true })
            .then(() => { setCurrentDoc({...currentDoc, published: publishedContainer}) })
        }
        catch(e){
            throwAsyncError(new Error(e.message, e));
        }
    }

    //handles updating to db
    let debouncedHandleUpdateValue = _.debounce(function (html, currentDoc) {
        console.log('debouncedHandleUpdateValue')
        
        try{
            let container = {...currentDoc, value: html}

            firestore.collection('plannedDeliveries').doc(container.docId).update({
                value: container.value || '',
                sandbox: process.env.NODE_ENV === 'development',
                title: container.title || '',
                versions: container.versions || [],
            })
        }
        catch(e){
            throwAsyncError(new Error(e.message, e));
        }
    }, 350);

    const updateDocCallback = useCallback((html, currentDoc) => {
        console.log('updateDocCallback')
        let container = {
            ...currentDoc, 
            value: html,
            title: html.replace(/<[^>]*>/g, ' ').replace(/&nbsp;/g, '\n')   //converts value to plaintext (for displaying in fullcalendar)
        }

        versioningNum.current++;
        if(versioningNum.current % 25 === 0){ container = hanldeGenerateDocVersion(container) }

        setCurrentDoc(container)
        debouncedHandleUpdateValue(html, currentDoc)
    }, [currentDoc.docId]);  // eslint-disable-line react-hooks/exhaustive-deps


    const handleOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };
    
    const handleClose = () => {
        setAnchorEl(null);
    };

    //takes document and adds a version to the versioning list
    const hanldeGenerateDocVersion = (document) => {
        //console.log('hanldeGenerateDocVersion')
        document.versions = document.versions || [];

        //delete? needed or no
        // if(document.versions.length > 10){
        //     console.log('remove oldest')
        //     let index = document.versions.reduce((smallestDateIndex, currentValue, currentIndex, array) => 
        //         moment(currentValue.date).isSameOrBefore(moment(array[smallestDateIndex].date)) ? currentIndex : smallestDateIndex, 0);
        //     console.log(document.versions[index])
        //     document.versions.splice(index, 1)
        // }

        let newVersion = {time: moment().valueOf(), value: document.value};
        document.versions.push(newVersion);

        return document;
    }

    const handleSwitchVersion = (version) => {
        setCurrentDocLoading(true)
        let container = currentDoc;
        //create new version based doc as is currently
        let currentValue = {value: container.value, time: moment().valueOf()}
        //add current values to version
        container.versions.push(currentValue)
        //switch value to versions value
        container.value = version.value;
        //update value in db
        firestore.collection('plannedDeliveries').doc(container.docId).update({
            value: container.value || '',
            sandbox: process.env.NODE_ENV === 'development',
            title: container.title || '',
            versions: container.versions || [],
        })

        //change current doc to trigger rerender of quillText
        setTimeout(() => { 
            setAnchorEl(null)
            setCurrentDoc(container)
            setCurrentDocLoading(false)
        }, 100);
    }

    return (
        <Grid style={{padding: '10px'}}>
            <Grid container className={classes.container} style={smallScreen ? {width: '90vw'} : {width: '75vw'}}>
                <Grid item xs={12} style={{height: '85vh',}}>
                    {viewType === 'Calendar'
                    ?      
                        <CalanderCard 
                            handleDateClick={handleDateClick}
                            height={smallScreen ? '55vh' : '85vh'}
                            smallScreen={smallScreen}
                        />
                    :
                        <DayViewCard
                            setViewType={setViewType}
                            handleChangeDate={handleChangeDate}
                            smallScreen={smallScreen}
                            currentDocLoading={currentDocLoading}
                            currentDoc={currentDoc}
                            handleChangePublished={handleChangePublished}
                            tabSelected={tabSelected}
                            handleTableChange={handleTableChange}
                            updateDocCallback={updateDocCallback}
                            handleCalanderClick={handleCalanderClick}
                            anchorEl={anchorEl}
                            handleOpen={handleOpen}
                            handleClose={handleClose}
                            handleSwitchVersion={handleSwitchVersion}
                        />
                    }
                </Grid>
            </Grid>
        </Grid>
    )
}

export default withRouter(Scheduler)