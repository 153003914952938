import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import {Grid, Button, Typography, Paper, Modal, Backdrop, Fade} from '@material-ui/core';
import CheckIcon from '@material-ui/icons/Check';
//import CloseIcon from '@material-ui/icons/Close';

function PayrollConfirmationModal(props) {

    const [modalOpen, setModalOpen] = useState(props.payrollDetails.payToOperator)

    return (
        <>
        <Modal
            style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                outline: 0,
            }}
            open={modalOpen}
            onClose={() => { setModalOpen(false) }}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
        }}>
            <Fade in={modalOpen}>
                <Paper style={{ padding: '16px', maxWidth:'700px' }}>
                    <Grid container direction='column' spacing={2}>
                        <Grid item>
                            <Typography align='center'>
                                {props.reportScope !== 'postedOnly' ? "Tickets selected have been marked as PAYROLLED on this DATE" : "Tickets selected have been marked as UNPAID to the OPERATOR"  }
                            </Typography>
                        </Grid>

                        <Grid container justifyContent='flex-end'>
                            <Grid item>
                                {/* <Button
                                    startIcon={<CloseIcon/>} 
                                    style={{color:'white',backgroundColor:'red', marginRight: '10px'}}
                                    variant='contained'
                                    onClick={(e) => { setModalOpen(false) }}
                                >
                                    Cancel
                                </Button> */}

                                <Button
                                    startIcon={<CheckIcon/>}
                                    variant='contained'
                                    onClick={(e) => {
                                        //handleSendOperatorInstructionsEmail()
                                        setModalOpen(false)
                                        props.confirmFunction();
                                    }}
                                >
                                    Confirm
                                </Button>
                            </Grid>
                        </Grid>

                    </Grid>
                </Paper>
            </Fade>
        </Modal>

        </>
    )
}

export default withRouter(PayrollConfirmationModal)