import React, { useState, useEffect } from 'react';
import { Grid, makeStyles, Button, Divider, Typography } from '@material-ui/core';
import { withRouter, useHistory } from 'react-router-dom';
import { firestore } from '../../../firebase/firebase';
import MaterialTable  from '@material-table/core'
import { TableIcons } from '../../../constants/tableIcons';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
//import { useAuth } from '../../../providers/AuthContext';
import moment from 'moment'

const useStyles = makeStyles((theme) => ({
    card: {
      padding: theme.spacing(4),
      width: '100%',
      height: '100%',
    },
    content: {
      display: 'flex',
      justifyContent: 'center',
      width: '100%',
    },
    grid: {
      marginTop: theme.spacing(2),
      maxWidth: '1200px',
    },
    table: {
      width: '100%',
      maxWidth: '900px',
      margin: '16px',
      display: 'flex',
    },
    tile: {
      width: '300px',
      height: '300px',
    },
    tileContent: {
      display: 'flex',
      justifyContent: 'space-evenly',
      alignItems: 'center',
      flexDirection: 'column',
      width: '100%',
      height: '100%',
      padding: theme.spacing(4),
    },
    tileIcon: {
      color: 'rgba(0, 0, 0, 0.54)',
      width: '100px',
      height: '100px',
    },
    tileText: {
      fontSize: '1.10rem',
    },
    btn: {
      marginBottom: '20px',
      marginTop: '4px',
  },
  }));

const TechHome = (props) => {

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  //const { roles } = useAuth();
  const classes = useStyles();
  const history = useHistory();
  const [notifications, setNotifications] = useState([]);

  useEffect(() => {
    firestore.collection('notifications').orderBy('timestamp').get()
      .then(querySnapshot => {
        const safetyResponse = querySnapshot.docs.map((doc) => {
          return {
            ...doc.data(),
            'docId': doc.id,
          }
        })
        setNotifications(safetyResponse);
        setLoading(false);
      })
      .catch(e => {
        setError("Unable to Process");
        setLoading(false);
        console.log(e.message);
      })
  }, []);
  return (
    <div className={classes.content}>
      <Grid container className={classes.table}>
        <Grid item xs={12}>
            <Button variant="contained" size="small" onClick={() => history.push("/dashboard/changelog/logs")}>Change Logs</Button>
        </Grid>

        {!loading && !error
          ? <Grid item xs={12} style={{ width: '100%' ,marginTop:'10px'}}>
           
            <MaterialTable
                style={{}}
                columns={[
                    { title: 'User', field: 'userName' },
                    { title: 'Date', render: rowData => moment(rowData.timestamp.toDate().toString()).format('MMM Do YYYY, h:mm a') },
                    { title: 'Type', field: 'type' }
                  ]}
                data={notifications}
                detailPanel={[
                    rowData => ({
                        tooltip: 'Show Details',
                        icon: () => <ChevronRightIcon />,
                        render: () =>
                            <Grid>
                            <Grid style={{backgroundColor: '#FFBF00', color: 'black', }}>
                                <Grid container >
                                    <Grid item xs={12}>
                                        <Typography variant="h6">Notification Details</Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Divider></Divider>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography variant="body1">{rowData.message}</Typography>
                                        <Typography variant="body1" style={{fontStyle:'italic'}}>Reason: {rowData.reason}</Typography>
                                </Grid>
                            </Grid>
                         </Grid>
                        </Grid>})
                    
                ]}
                title={"Notifications"}
                icons={TableIcons}
                options={'options' in props
                    ? props.options
                    : {
                        toolbar: true,
                        pageSize: 20,
                        pageSizeOptions: false,
                        paging: true,
                        actionsColumnIndex: -1,
                        search: true,
                        emptyRowsWhenPaging: false,
                        thirdSortClick: false,
                        rowStyle: {
                            whiteSpace: 'pre-wrap',
                        },
                    }}
            />

          </Grid>
          : null
        }
      </Grid>
    </div>
  )
}

export default withRouter(TechHome);