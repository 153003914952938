import {
    Button, Grid, makeStyles, Paper, Snackbar, TextField, Typography,  Divider, Modal, Input, FormControlLabel,
    InputAdornment
} from "@material-ui/core"
import React, { useEffect, useState } from "react";
import _ from 'lodash';
import { Circle, GoogleMap, Marker, DrawingManager, useLoadScript, Polygon  } from '@react-google-maps/api';
import DeleteIcon from '@material-ui/icons/Delete';
import { useAuth } from '../../../../providers/AuthContext';

const libraries = ['drawing'];
const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        alignItems: 'center',
        width: '100%',
        flexDirection: 'column',
        padding:'10px',
    },
    paper: {
        width: '100%',
        maxWidth: '400px',
        padding: theme.spacing(4),
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(2),
        marginRight: theme.spacing(1),
    },
    title: {
        marginBottom: theme.spacing(2),
    },
    row: {
        display: 'flex',
        alignItems: 'center',
    },
    modal: {
        width: '250px',
        padding: '16px',
        margin: '0 auto',
        marginTop: '50vh',
        backgroundColor: 'rgb(49, 49, 49)',
        border: '1px solid black'
    },
}));

const ZoneSettingsCard = (props) => {
    const styles = useStyles();
    const [zones, setZones] = useState(props.shopSettings.zones || []);
    const [drawing, setDrawing] = useState(false);
    const [open, setOpen] = useState(false);
    const [opacity, setOpacity] = useState(0.55);
    const { currentUser } = useAuth();
    const [selectedZone, setSelectedZone] = useState(null);

    const { isLoaded, loadError } = useLoadScript({
        googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
        libraries,
    });    


    useEffect(() => {
        
    }, [zones])

    //sets zone on save state for reverting (will also happne on save resulting in double save- find better way****)
    useEffect(() =>{
        if(_.isUndefined(props.saveState.zones)){
            setZones([])
        } else{
            setZones([...props.saveState.zones])
        }
        
    }, [props.saveState, props])

      //Error checking on googlemaps api
    if (loadError) return "Error";
    if (!isLoaded) return "";

    //export zone data as json file
    const exportZones = () => {
        const element = document.createElement("a");
        const file = new Blob([JSON.stringify(zones)], { type: 'text/plain' });
        element.href = URL.createObjectURL(file);
        element.download = "zones.json";
        document.body.appendChild(element); // Required for this to work in FireFox
        element.click();
    }

    //import zone data from json file
    const importZones = (e) => {
        let file = e.target.files[0];
        let reader = new FileReader();
        reader.readAsText(file);
        reader.onload = function (e) {
            let zones = JSON.parse(e.target.result);
            setZones(zones);
            props.setSaveState({ ...props.saveState, zones: zones });
        }
    }

    const onPolygonComplete = (polygon) => {
        const coordinates = polygon.getPath().getArray().map((latLng) => {
            return {
                lat: latLng.lat(),
                lng: latLng.lng()
            }
        })

        let zoneContainer = {
            coordinates: coordinates,
            name: 'Zone ' + (Object.keys(zones).length + 1),
            deliveryFee: 0.00,
            color: '#000000'
        }

        setDrawing(false);
        setZones([...zones, zoneContainer])
        props.setSaveState({ ...props.saveState, zones: [...zones, zoneContainer] });

        polygon.setMap(null);
    }


    const updateZone = (zone, key, value) => {

        
        let updatedZones = _.cloneDeep(zones);
        updatedZones[zone]= { ...updatedZones[zone], [key]: value}
  
        setZones(updatedZones);

        props.setSaveState({ ...props.saveState, zones: [...updatedZones] });
    }

    





    //display zones on map
    let zonePolygons = zones.map((zone, index) => {
        return <>

        <Polygon
            key={index}
            path={zone.coordinates}
            onClick={() => { setSelectedZone(selectedZone === index ? null : index) }}
            options={{
                fillColor: zone.color,
                fillOpacity: opacity,
                strokeColor: selectedZone === index ? 'lightGreen' : zone.color,
                strokeOpacity: 0.8,
                strokeWeight: selectedZone === index ? 4 : 2,
                clickable: true,
                editable: false,
                zIndex: 6
            }}
        /></>
    })

    let googleMapSubComponents;

    switch (drawing) {
        case false: 
            googleMapSubComponents = 
            <DrawingManager 
                onPolygonComplete={onPolygonComplete}
                options={
                    {
                        drawingControlOptions: {
                            position: window.window.google.maps.ControlPosition.TOP_CENTER,
                            drawingModes: ['polygon'],
                            zIndex: 6
                        },

                        polygonOptions: {
                            strokeColor: '#000',
                            zIndex: 5
                        }
                    }
                }
            />

         break;
        default: 
            googleMapSubComponents = <></>
        break;
    }



    let zoneList = zones.map((zone, index) => {
        return (
            <Grid item xs={5} key={index} style={{marginLeft:'40px'}}>
            <Paper className={styles.paper} style={selectedZone === index ? {border: '3px dashed green' } : {}}>
            <Grid item xs={12} key={index}>
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <Typography variant='h6'>Zone #{index+1}</Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Button
                            variant="contained"
                            style={{ float: 'right', paddingRight:'0px', paddingLeft:'10px', backgroundColor: 'red', color: 'white', }}
                            startIcon={<DeleteIcon />}
                            onClick={() => {
                               setOpen(true)
                               setSelectedZone(index)
                            }}
                        />
                    </Grid>
                </Grid>
                <Divider style={{marginBottom:'4px'}}/>
                <Grid container spacing={2}>
                    <Divider style={{marginBottom:'10px'}}/>
                    <Grid item xs={4}>
                        <Typography noWrap variant='body1' sx={{lineHeight: 2 }}>Zone Name: </Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            label="Zone Name"
                            variant="standard"
                            size="small"
                            value={zone.name}
                            onChange={(e) => {
                                updateZone(index, 'name', e.target.value)
                            }}

                        />
                    </Grid>
                   
                    <Grid item xs={4}>
                        <Typography>Zone Color: </Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <label for="head" style={{marginRight:'10px'}}>{zone.color}</label>
                        <input type="color" id="color" name="color"
                            onChange={(e) => {
                                updateZone(index, 'color', e.target.value)
                            }}
                            value={zone.color}
                        />
                    </Grid>
                </Grid>
            </Grid>
            </Paper></Grid> )
        })
                        
                        

    return( <div className={styles.root}>
        <Paper className={styles.paper} style={{maxWidth:'1000px'}}>
        <Modal 
            open={open}
            onClose={() => setOpen(false)}
        >
            <Paper className={styles.paper} style={{margin:'500px'}}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Typography variant='h6'>Delete this Zone?</Typography>
                        <Divider/>
                    </Grid>
                    
                    <Grid item xs={12}>
                        <Typography variant='body1'>This action cannot be undone.</Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Button
                            variant="contained"
                            style={{ float: 'right' }}
                            startIcon={<DeleteIcon />}
                            onClick={() => {
                                let updatedZones = zones;
                                updatedZones.splice(selectedZone, 1);
                                setZones(updatedZones);
                                props.setSaveState({ ...props.saveState, zones: updatedZones });
                                props.setSaveState({ ...props.saveState, zones: [...updatedZones] });
                                setOpen(false);
                                
                            }}
                        >Yes</Button>
                    </Grid>
                    <Grid item xs={6}>
                        <Button
                            variant="contained"
                            style={{ float: 'right' }}
                            onClick={() => {
                                setOpen(false);
                            }}
                        >No</Button>
                    </Grid>
                </Grid>
            </Paper>
        </Modal>
        <Typography variant='h5' className={styles.title}>Zone Settings</Typography>
        
        <Grid>
            <Divider style={{marginBottom:'10px'}}/>
            <Grid container item xs={12}>
                <div style={{width:'1000px', height:'650px', marginBottom:'10px'}}>
                <GoogleMap
                    mapContainerStyle={
                        {
                            width: "100%",
                            height: "100%",
                        }
                    }
                    options={{
                    fullscreenControl: false,
                    streetViewControl: false,
                    mapTypeControl: false,
                    mapTypeId: 'hybrid'
                    }}

                    zoom={9}
                    center={{ lat: 49.901956, lng: -96.932431 }}
                    
                >
                    {googleMapSubComponents}
                    {zonePolygons}
                    <Marker
                        position={props.coordinates} />
                    <Circle
                        center={{ lat: -49.904415, lng: -96.932431 - 180 }}
                        radius={20037508.34 - 100000 * .70}
                        options={{
                            clickable: false,
                            fillOpacity: .4,
                            fillColor: 'black',
                        }}
                    />

                </GoogleMap>
                </div>
            </Grid>
            <Divider sx={{margin:'10px'}}/>
            {currentUser.email === "derekmilleryoung@gmail.com" ?
            <Grid container spacing={2}>
                <Grid item xs={3}>
                    <Typography variant='h6'>Zone List</Typography>
                </Grid>
                <Grid item xs={3}>
                    <Button onClick={()=>exportZones()} variant='contained' color='secondary'>Export Zones</Button>
                </Grid>
                <Grid item xs={6}>
                    <FormControlLabel control={<Input
                        id="replacePDFButton"
                        type='file'
                        accept='.json'
                        onChange={(e) => importZones(e)}
                        required/>} label="Import Zones File" />
                    
                </Grid>
            </Grid>:null}
            <Grid item xs={1}>
                <TextField
                    label="Transparency"
                    variant="standard"
                    size="small"
                    //round to 0 decimal places
                    fullWidth
                    value={Math.round((opacity * 100 + Number.EPSILON) * 100) / 100}
                    onChange={(e) => {
                        if(e.target.value > 100) e.target.value = 100;
                        if(e.target.value < 0) e.target.value = 0;
                        setOpacity(e.target.value / 100)
                    }}
                    type='number'
                    InputProps={{
                        endAdornment: <InputAdornment position="end">%</InputAdornment>,
                        inputProps: { min: 0, max: 100 }
                    }}
                
                ></TextField>
            </Grid>
            <Grid item xs={12}>
            
                <Grid container spacing={2}>
                    {zones.length > 0 ? zoneList
                    
                : <Grid item xs={12}>
                    <Typography variant='body1'>No Zones Currently Set. Use the Toolbar on the Google Map to set up Zones.</Typography>
                </Grid>}
                </Grid>
            </Grid>
        </Grid>

        <Snackbar
            open={!_.isEqual(props.shopSettings, props.saveState)}
            message={props.saving ? 'Saving...' : 'Save Document?'}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center'
            }}
            action={
                <React.Fragment>
                    {props.saving
                        ? null
                        : <React.Fragment>
                            <Button variant='text' color='primary' onClick={props.cancelSave} style={{ marginLeft: '32px', marginRight: '8px' }}>Cancel</Button>
                            <Button variant='contained' onClick={() => props.handleSave()}>Confirm</Button>
                        </React.Fragment>}
                </React.Fragment>
            }
        /></Paper></div>)
}

export default ZoneSettingsCard;