import React, { useState, useEffect, useCallback } from 'react';
import { withRouter } from 'react-router'
import { firestore } from '../../../firebase/firebase'
import ChecklistManager from '../../components/ChecklistManager/ChecklistManager'
import _, { cloneDeep } from 'lodash'
import { makeStyles } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';


const useStyles = makeStyles((theme) => ({
    content: {
        display: 'flex',
        width: '100%',
        padding: '32px',
        justifyContent: 'center'
    },
}));

const Checklist2 = (props) => {

    const classes = useStyles();

    const [checklist, setChecklist] = useState({});
    const [checklistLoading, setChecklistLoading] = useState(true);
    const [error, setError] = useState('');
    const [initialChecklist] = useState({
        title: "",
        description: "",
        discountType: "percent",
        active: true,
        requiresTimesheet: false,
        gathersWeatherData: false,
        items: {},
    })

    //Save and Editing coupon management
    const [saving, setSaving] = useState(false);
    const [saveState, setSaveState] = useState({});

    let path = new URLSearchParams(props.location.search).get("docId");
    
    const updateData = useCallback((e) => {
        setChecklist(e)
    },
        [],
    );

    const saveData = () => {
        setSaving(true);
        if (path === "new")
            firestore.collection('premadeChecklists').add(checklist)
                .then(() => {
                    setSaveState(_.cloneDeep(checklist));
                    setSaving(false)
                    props.history.replace({pathname: '/dashboard/checklists'})
                }).catch((e) => {
                    setSaving(false)
                });
        else
            firestore.collection('premadeChecklists').doc(checklist.docId)
                .update(checklist)
                .then(() => {
                    setSaveState(_.cloneDeep(checklist));
                    setTimeout(() => {
                        setSaving(false)
                    },  100);
                }).catch((e) => {
                    setSaving(false)
                });
    };

    const cancelSave = () => {
        setChecklist(cloneDeep(saveState));
    };

    useEffect(() => {
        setChecklistLoading(true);
        if (path === 'new') {
            setChecklist(cloneDeep(initialChecklist));
            setSaveState(cloneDeep(initialChecklist));
            setChecklistLoading(false);
        }
        else {
            firestore.collection('premadeChecklists').where('__name__', "==", path).get().then((docSnapshot) => {
                const response = docSnapshot.docs.map((doc) => {
                    return {
                        ...doc.data(),
                        'docId': doc.id,
                    }
                })
                setChecklist(response[0])
                setSaveState(response[0]);
                setChecklistLoading(false);
            }).catch(e => {
                setError(e)
                setChecklistLoading(false);
            })
        }

    }, [ path, initialChecklist])

    return (
        <div className={classes.content}>
            {checklistLoading || _.isUndefined(checklist)
                ? 
                    <Skeleton variant='rect' width={'42vw'} height={'100vh'} style={{marginTop: '55px'}}/>
                : 
                    <ChecklistManager
                        checklist={checklist} path={path}
                        saveState={saveState} cancelSave={cancelSave} saving={saving} confirmSave={saveData}
                        updateData={updateData} error={error} saveData={saveData}
                    />
            }
        </div>
    )
}

export default withRouter(Checklist2);