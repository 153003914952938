import React, { useContext, useState } from "react"
import { firestore } from "../firebase/firebase"

const CustomerContext = React.createContext()

export function useCustomer() {
  return useContext(CustomerContext);
}

export function CustomerProvider({ children }) {
  const [customers, setCustomerData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');

  function getCustomers() {
    //Check if customers exist on local storage


    //Query customer collection for all docs
    firestore.collection('customers').get()
      .then(querySnapshot => {
        const customerResponse = querySnapshot.docs.map((doc) => {
          return {
            ...doc.data(),
            'docId': doc.id,
            'fullStreet': doc.data().streetNumber + ' ' + doc.data().streetName,
            'jobNumber': doc.data().jobNumber ? doc.data().jobNumber : '[NO JOB NUMBER]',
          }
        })

        setLoading(false);
        setCustomerData(customerResponse);
      },
        error => {
          setLoading(false);
          setError(error.message);
          console.log(error.message);
        }
      )


    //console.log("Customers exist on local storage")
  }

  const value = {
    customers,
    loading,
    error,
    getCustomers
  }

  return (
    <CustomerContext.Provider value={value}>
      {children}
    </CustomerContext.Provider>
  )
}