import {
    Button, Grid, makeStyles, Paper, Snackbar, TextField, Typography,  Divider, Link, IconButton, Modal, FormControl, FormLabel, FormGroup, FormControlLabel, Checkbox,
} from "@material-ui/core"
import React, { useState } from "react";
import _ from 'lodash';
import { DatePicker, KeyboardTimePicker } from '@material-ui/pickers';
import DeleteIcon from '@material-ui/icons/Delete';
import moment from "moment";

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        alignItems: 'center',
        width: '100%',
        flexDirection: 'column',
    },
    paper: {
        width: '100%',
        maxWidth: '600px',
        padding: theme.spacing(4),
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(2),
    },
    title: {
        marginBottom: theme.spacing(2),
    },
    row: {
        display: 'flex',
        alignItems: 'center',
    },
    modal: {
        width: '250px',
        padding: '16px',
        margin: '0 auto',
        marginTop: '50vh',
        backgroundColor: 'rgb(49, 49, 49)',
        border: '1px solid black'
    },
}));

const BusinessSettingsCard = (props) => {
    const styles = useStyles();
    const [tempClosedDate, setTempClosedDate] = useState({'reason': '', 'date': moment().format('YYYY-MM-DD'), })
    const week = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];
    const [deleteData, setDeleteData] = useState([])
    const [dateConflict, setDateConflict] = useState(false)

    const handleDeleteDate = () => {
        let dateContainer = {...props.saveState[props.documents['datesClosed']].dates};

        for(let i = 0; i < deleteData.length; i++){
            delete dateContainer[deleteData[i]]
        }
        
        props.setSaveState({
            ...props.saveState,
            [props.documents['datesClosed']]: {
                ...props.saveState[props.documents['datesClosed']],
                dates: {...dateContainer}
            }
        })
        setDeleteData([])
    }

    return <form className={styles.root} onSubmit={(event) => props.handleSave(event)}>
        <Paper className={styles.paper}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Typography variant={'h6'}>Operating Hours</Typography>
                    <Divider></Divider>
                </Grid>
                {week.map(day => {
                    return <React.Fragment key={day}>
                        <Grid item xs={2} className={styles.row} style={{ justifyContent: 'flex-end' }}>
                            <Typography>{day}</Typography>
                        </Grid>

                        <Grid item xs={10} className={styles.row}>
                            <Grid container style={{display: 'flex', justifyContent: 'space-around'}}>
                                <KeyboardTimePicker
                                    style={{maxWidth: '140px', margin: '5px'}}
                                    label={'Open Time'}
                                    //format= { smallScreen ? 'hh:mm' : 'LT'}
                                    disabled={props.saveState[props.documents['shopSettings']].operatingHours[day].closed}
                                    value={props.saveState[props.documents['shopSettings']].operatingHours[day].openTime}
                                    onChange={(date) => props.setSaveState({
                                        ...props.saveState,
                                        [props.documents['shopSettings']]: {
                                            ...props.saveState[props.documents['shopSettings']],
                                            'operatingHours': {
                                                ...props.saveState[props.documents['shopSettings']].operatingHours,
                                                [day]: {
                                                    ...props.saveState[props.documents['shopSettings']].operatingHours[day],
                                                    openTime: moment(date).valueOf()
                                                }
                                            }
                                        }
                                    })} 
                                />
                                <KeyboardTimePicker
                                    style={{maxWidth: '140px', margin: '5px'}}
                                    label={'Close Time'}
                                    //format= { smallScreen ? 'hh:mm' : 'LT'}
                                    disabled={props.saveState[props.documents['shopSettings']].operatingHours[day].closed}
                                    value={props.saveState[props.documents['shopSettings']].operatingHours[day].closeTime}
                                    onChange={(date) => props.setSaveState({
                                        ...props.saveState,
                                        [props.documents['shopSettings']]: {
                                            ...props.saveState[props.documents['shopSettings']],
                                            'operatingHours': {
                                                ...props.saveState[props.documents['shopSettings']].operatingHours,
                                                [day]: {
                                                    ...props.saveState[props.documents['shopSettings']].operatingHours[day],
                                                    closeTime: moment(date).valueOf()
                                                }
                                            }
                                        }
                                    })} 
                                />
                                <FormControl component="fieldset">
                                    <FormLabel component="legend" style={{marginTop: '5px'}}><small>Closed</small></FormLabel>
                                    <FormGroup row>
                                        <FormControlLabel
                                            control={<Checkbox
                                                        style={{marginRight: '5px', marginLeft: '5px'}}
                                                        checked={props.saveState[props.documents['shopSettings']].operatingHours[day].closed}
                                                        onChange={(event) => props.setSaveState({
                                                            ...props.saveState,
                                                            [props.documents['shopSettings']]: {
                                                                ...props.saveState[props.documents['shopSettings']],
                                                                'operatingHours': {
                                                                    ...props.saveState[props.documents['shopSettings']].operatingHours,
                                                                    [day]: {
                                                                        ...props.saveState[props.documents['shopSettings']].operatingHours[day],
                                                                        closed: event.target.checked
                                                                    }
                                                                }
                                                            }
                                                        })} 
                                                    />}
                                        />
                                    </FormGroup>
                                </FormControl>
                            </Grid>
                        </Grid>
                        
                    </React.Fragment>
                })}
            </Grid>
        </Paper>

        <Paper className={styles.paper} >
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Typography variant={'h6'}>Dates Closed</Typography>
                    <Divider></Divider>
                </Grid>
                
                <Grid container spacing={1} style={{marginBottom:'10px'}}>
                    <Grid item xs={4}>
                        <TextField label='Reason' 
                            InputLabelProps={{ shrink: true }}
                            placeholder="Reason" 
                            value={tempClosedDate.reason}
                            onChange={(event) => { setTempClosedDate({...tempClosedDate, 'reason': event.target.value}) }}
                            />
                    </Grid>

                    <Grid item xs={4}>
                        <DatePicker
                            label='Date' 
                            InputLabelProps={{ shrink: true }}
                            placeholder="Date" 
                            value={tempClosedDate.date}
                            onChange={(date) => { setTempClosedDate({...tempClosedDate, 'date': moment(date).format('YYYY-MM-DD')}); }}
                        ></DatePicker>

                    </Grid>

                    <Grid item xs={4}>
                        <Button 
                            variant={'contained'} 
                            style={{float: 'right', top:'10px'}} 
                            onClick={(event) => {
                                let dates = Object.keys(props.saveState[props.documents['datesClosed']].dates).map((date) => {
                                    return moment(date).format('MM-DD')
                                })
                                if(dates.includes(moment(tempClosedDate.date).format('MM-DD'))){
                                    setDateConflict(true)
                                }
                                else{
                                    if(tempClosedDate.reason === ''){
                                        props.setSaveState({
                                            ...props.saveState,
                                            [props.documents['datesClosed']]: {
                                                ...props.saveState[props.documents['datesClosed']],
                                                dates: {...props.saveState[props.documents['datesClosed']].dates, [tempClosedDate.date]: 'NA'}
                                            }
                                        }) 
                                    }
                                    else{
                                        props.setSaveState({
                                            ...props.saveState,
                                            [props.documents['datesClosed']]: {
                                                ...props.saveState[props.documents['datesClosed']],
                                                dates: {...props.saveState[props.documents['datesClosed']].dates, [tempClosedDate.date]: tempClosedDate.reason}
                                            }
                                        }) 
                                    }
                                    setTempClosedDate({reason: '', date: moment().format('YYYY-MM-DD')})
                                }
                            }}
                            >
                            Add Date
                        </Button>
                    </Grid>
                </Grid>
            
                {Object.entries(props.saveState[props.documents['datesClosed']].dates).map(([key, value], index) => {
                    return (
                        <Grid container key={index}>
                            <Grid item xs={12}>
                                <Divider></Divider>
                            </Grid>
                            <Grid item xs={5}>
                                <Typography style={{marginTop: '10px'}}>{value}</Typography>
                            </Grid>
                            <Grid item xs={5}>
                                <Typography style={{marginTop: '10px'}}>{moment(key).format('MMMM Do YYYY')}</Typography>
                            </Grid>
                            <Grid item xs={2}>
                                <IconButton style={{float: 'right'}} onClick={() => {setDeleteData([...deleteData, key])}}> <DeleteIcon fontSize='small'/> </IconButton>
                            </Grid>
                        </Grid>
                     )
                    })
                }
                

            </Grid>
        </Paper>

        <Paper className={styles.paper} style={{marginBottom: '75px'}}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Typography variant={'h6'}>Contact Info</Typography>
                    <Divider></Divider>
                </Grid>
                <Grid item xs={3} className={styles.row} style={{ justifyContent: 'flex-end' }}>
                    <Typography>Phone Number:</Typography>
                </Grid>
                <Grid item xs={9} className={styles.row}>
                    <TextField
                        style={{ maxWidth: '200px', marginRight: '22px' }}
                        variant='outlined'
                        size='small'
                        value={props.saveState[props.documents['contactInformation']].storePhone}
                        onChange={(event) => props.setSaveState({
                            ...props.saveState,
                            [props.documents['contactInformation']]: {
                                ...props.saveState[props.documents['contactInformation']],
                                storePhone: event.target.value
                            }
                        })} 
                        />
                </Grid>

                <Grid item xs={3} className={styles.row} style={{ justifyContent: 'flex-end' }}>
                    <Typography>Address:</Typography>
                </Grid>
                <Grid item xs={9} className={styles.row}>
                    <TextField
                        style={{ maxWidth: '200px', marginRight: '22px' }}
                        variant='outlined'
                        size='small'
                        value={props.saveState[props.documents['contactInformation']].location}
                        onChange={(event) => props.setSaveState({
                            ...props.saveState,
                            [props.documents['contactInformation']]: {
                                ...props.saveState[props.documents['contactInformation']],
                                location: event.target.value
                            }
                        })} 
                        />
                </Grid>

                <Grid item xs={3} className={styles.row} style={{ justifyContent: 'flex-end' }}>
                    <Typography>Email:</Typography>
                </Grid>
                <Grid item xs={9} className={styles.row}>
                    <TextField
                        style={{ maxWidth: '200px', marginRight: '22px' }}
                        variant='outlined'
                        size='small'
                        value={props.saveState[props.documents['contactInformation']].email}
                        onChange={(event) => props.setSaveState({
                            ...props.saveState,
                            [props.documents['contactInformation']]: {
                                ...props.saveState[props.documents['contactInformation']],
                                email: event.target.value
                            }
                        })} 
                        />
                </Grid>

                <Grid item xs={12}>
                    <Typography variant={'h6'}>Social Media</Typography>
                    <Divider></Divider>
                </Grid>
                <Grid item xs={3} className={styles.row} style={{ justifyContent: 'flex-end' }}>
                    <Typography>Facebook:</Typography>
                </Grid>
                <Grid item xs={6} className={styles.row}>
                    <TextField
                        style={{ maxWidth: '200px', marginRight: '22px' }}
                        variant='outlined'
                        size='small'
                        value={props.saveState[props.documents['socialInformation']]?.facebook}
                        onChange={(event) => props.setSaveState({
                            ...props.saveState,
                            [props.documents['socialInformation']]: {
                                ...props.saveState[props.documents['socialInformation']],
                                facebook: event.target.value
                            }
                        })} 
                        />
                </Grid>
                {props.saveState[props.documents['socialInformation']].facebook ?
                <Grid item xs={3} className={styles.row} style={{ justifyContent: 'flex-start' }}>
                    <Link style={{color: 'black'}} target="_blank" rel="noopener" href={props.saveState[props.documents['socialInformation']]?.facebook}><small>Test Link</small></Link>
                </Grid>
                : <Grid item xs={3}/>}

                <Grid item xs={3} className={styles.row} style={{ justifyContent: 'flex-end' }}>
                    <Typography>Twitter:</Typography>
                </Grid>
                <Grid item xs={6} className={styles.row}>
                    <TextField
                        style={{ maxWidth: '200px', marginRight: '22px' }}
                        variant='outlined'
                        size='small'
                        value={props.saveState[props.documents['socialInformation']]?.twitter}
                        onChange={(event) => props.setSaveState({
                            ...props.saveState,
                            [props.documents['socialInformation']]: {
                                ...props.saveState[props.documents['socialInformation']],
                                twitter: event.target.value
                            }
                        })} 
                        />
                </Grid>
                {props.saveState[props.documents['socialInformation']].twitter ?
                <Grid item xs={3} className={styles.row} style={{ justifyContent: 'flex-start' }}>
                    <Link style={{color: 'black'}} target="_blank" rel="noopener" href={props.saveState[props.documents['socialInformation']]?.twitter}><small>Test Link</small></Link>
                </Grid>
                : <Grid item xs={3}/>}

                <Grid item xs={3} className={styles.row} style={{ justifyContent: 'flex-end' }}>
                    <Typography>LinkedIn:</Typography>
                </Grid>
                <Grid item xs={6} className={styles.row}>
                    <TextField
                        style={{ maxWidth: '200px', marginRight: '22px' }}
                        variant='outlined'
                        size='small'
                        value={props.saveState[props.documents['socialInformation']]?.linkedIn}
                        onChange={(event) => props.setSaveState({
                            ...props.saveState,
                            [props.documents['socialInformation']]: {
                                ...props.saveState[props.documents['socialInformation']],
                                linkedIn: event.target.value
                            }
                        })} 
                        />
                </Grid>
                {props.saveState[props.documents['socialInformation']].linkedIn ?
                <Grid item xs={3} className={styles.row} style={{ justifyContent: 'flex-start' }}>
                    <Link style={{color: 'black'}} target="_blank" rel="noopener" href={props.saveState[props.documents['socialInformation']]?.linkedIn}><small>Test Link</small></Link>
                </Grid>
                : <Grid item xs={3}/>}

                <Grid item xs={3} className={styles.row} style={{ justifyContent: 'flex-end' }}>
                    <Typography>Instagram:</Typography>
                </Grid>
                <Grid item xs={6} className={styles.row}>
                    <TextField
                        style={{ maxWidth: '200px', marginRight: '22px' }}
                        variant='outlined'
                        size='small'
                        value={props.saveState[props.documents['socialInformation']]?.instagram}
                        onChange={(event) => props.setSaveState({
                            ...props.saveState,
                            [props.documents['socialInformation']]: {
                                ...props.saveState[props.documents['socialInformation']],
                                instagram: event.target.value
                            }
                        })} 
                        />
                </Grid>
                {props.saveState[props.documents['socialInformation']].instagram ?
                <Grid item xs={3} className={styles.row} style={{ justifyContent: 'flex-start' }}>
                    <Link style={{color: 'black'}} target="_blank" rel="noopener" href={props.saveState[props.documents['socialInformation']]?.instagram}><small>Test Link</small></Link>
                </Grid>
                : <Grid item xs={3}/>}

            </Grid>
        </Paper>

        <Modal
            open={dateConflict}
            onClose={() => {setDateConflict(false)} }
        >
            <Paper className={styles.modal}>
                <Grid style={{display: 'flex', justifyContent: 'center'}}>
                    <Typography
                        style={{ color: 'white', margin: '10px', display: 'flex', justifyContent: 'center' }}
                    >
                        Date is already included, replace the reason?</Typography>
                </Grid>
                <Grid style={{display: 'flex', justifyContent: 'center'}}>
                    <Button
                        variant={'contained'}
                        style={{margin: '10px', }}
                        onClick={() => {setDateConflict(false)}}
                    >
                        Cancel
                    </Button>
                    <Button
                        variant={'contained'}
                        style={{margin: '10px', backgroundColor: 'red'}}
                        onClick={() => {
                            //deletes dates where MM/DD are same, but YYYY are not for a proper replace
                            if (!Object.keys(props.saveState[props.documents['datesClosed']].dates).includes(moment(tempClosedDate.date).format('YYYY/MM/DD')) ){
                                let dateContainer = Object.keys(props.saveState[props.documents['datesClosed']].dates).map((date) => {
                                    if(moment(date).format('MM/DD') === moment(tempClosedDate.date).format('MM/DD')){
                                        return date
                                    }
                                    return null
                                })
                                delete props.saveState[props.documents['datesClosed']].dates[dateContainer[0]]
                            }
                            props.setSaveState({
                                ...props.saveState,
                                [props.documents['datesClosed']]: {
                                    ...props.saveState[props.documents['datesClosed']],
                                    dates: {...props.saveState[props.documents['datesClosed']].dates, [tempClosedDate.date]: tempClosedDate.reason}
                                }
                            }) 
                            setTempClosedDate({reason: '', date: moment().format('YYYY-MM-DD')});
                            setDateConflict(false)
                        }}
                    >
                        Confirm
                    </Button>
                </Grid>
            </Paper>
        </Modal>

        <Modal
            open={deleteData.length > 0}
            onClose={() => setDeleteData([]) }
        >
            <Paper className={styles.modal}>
                <Grid style={{display: 'flex', justifyContent: 'center'}}>
                    <Typography
                        style={{ color: 'white', margin: '10px', display: 'flex', justifyContent: 'center' }}
                    >
                        Delete Date?</Typography>
                </Grid>
                <Grid style={{display: 'flex', justifyContent: 'center'}}>
                    <Button
                        variant={'contained'}
                        style={{margin: '10px', }}
                        onClick={() => {setDeleteData([])}}
                    >
                        Cancel
                    </Button>
                    <Button
                        variant={'contained'}
                        style={{margin: '10px', backgroundColor: 'red'}}
                        onClick={() => {handleDeleteDate()}}
                    >
                        Confirm
                    </Button>
                </Grid>
            </Paper>
        </Modal>

        <Snackbar
            open={!_.isEqual(props.shopSettings, props.saveState)}
            message={props.saving ? 'Saving...' : 'Save Document?'}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center'
            }}
            action={
                <React.Fragment>
                    {props.saving
                        ? null
                        : <React.Fragment>
                            <Button variant='text' color='primary' onClick={props.cancelSave} style={{ marginLeft: '32px', marginRight: '8px' }}>Cancel</Button>
                            <Button variant='contained' type='submit'>Confirm</Button>
                        </React.Fragment>}
                </React.Fragment>
            }
        />
    </form>
}

export default BusinessSettingsCard;