import React, {useState, useCallback} from 'react';
//import { makeStyles } from '@material-ui/core';
import { Grid, Paper, Typography, Divider, Checkbox, TextField, Snackbar, Button } from '@material-ui/core';
import { withRouter } from 'react-router'
import { makeStyles } from '@material-ui/core';
import _ from 'lodash'
import { firestore } from '../../../firebase/firebase'

const useStyles = makeStyles((theme) => ({

    content: {
        display: 'flex',
        width: '100%',
        flexDirection: 'column',
        maxWidth: '1000px',
        margin: 'auto',
    },
    paper: {
        borderRadius: '0px',
        margin: '0px',
        marginBottom:'20px',
        width: '100%',
        maxWidth: '1000px',
    },
    header: {
        display: 'flex',
        justifyContent: 'center',
        marginBottom:'10px',
    },
    rlSpacing: {
        paddingRight: theme.spacing(1),
        paddingLeft: theme.spacing(1),
    },
    label: {
        lineHeight: 2,
    },
    checkbox: {
        lineHeight: 2,
        padding:'0px',
        marginTop: '4px'
    },
}));

const PartsTab = (props) => {
    const classes = useStyles();

    const [equipmentData, setEquipmentData] = useState(props.equipmentData);
    const [error, setError] = useState("");
    const [saving, setSaving] = useState(false);
    const [saveState, setSaveState] = useState(props.saveState);

    let path = new URLSearchParams(props.location.search).get("docId");

    const updateEquipmentData = useCallback((e) => {
            setEquipmentData(e)
        },
        [],
    );

    const confirmSave = () => {
        props.setEquipmentData(equipmentData);
        props.setSaveState(equipmentData);
        setSaving(true);
        firestore.collection('unitDescriptions').doc(path)
            .update(equipmentData)
            .then(() => {
                setSaveState(equipmentData);
                setSaving(false)
            })
            .catch((e) => {setError(e); console.log(error);});
    };

    const cancelSave = () => {
        setEquipmentData(saveState);
    };

    return(
        <Grid container>

            <Grid container spacing={2}>
                {/* ENGINE */}
                <Grid item xs={6}>
                    <Paper className={classes.paper}>
                        <Typography variant= 'h5' className={classes.header} >Engine</Typography>
                        <hr></hr>
                        <Grid container className={classes.rlSpacing}>
                            <Grid item xs={8} >
                                <Typography className={classes.label}  variant='body1'>Serial Number:</Typography>
                            </Grid>
                            <Grid item xs={4} >
                                {props.edit 
                                    ?<TextField
                                    size="small"
                                    value={equipmentData.engine ? (equipmentData.engine.serialNum ? equipmentData.engine.serialNum : "") : ""}
                                    onChange={(event) => {equipmentData.engine 
                                        ? updateEquipmentData({...equipmentData, engine: {...equipmentData.engine, 'serialNum': event.target.value}}) 
                                        : updateEquipmentData({...equipmentData, engine: { 'serialNum': event.target.value}}) 
                                    }}
                                    />
                                    :<Typography className={classes.label} align="right" variant='body1'>
                                        {equipmentData.engine ? (equipmentData.engine.serialNum ? equipmentData.engine.serialNum : "") : ""}
                                    </Typography>
                                }
                            </Grid>
                        </Grid>
                        <Divider/>
                        <Grid container className={classes.rlSpacing}>
                            <Grid item xs={8}>
                                <Typography className={classes.label} variant='body1'>CPL:</Typography>
                            </Grid>
                            <Grid item xs={4} >
                                {props.edit 
                                    ? <TextField
                                    size="small"
                                    value={equipmentData.engine ? (equipmentData.engine.cpl ? equipmentData.engine.cpl : "") : ""}
                                    onChange={(event) => {equipmentData.engine 
                                        ? updateEquipmentData({...equipmentData, engine: {...equipmentData.engine, 'cpl': event.target.value}}) 
                                        : updateEquipmentData({...equipmentData, engine: { 'cpl': event.target.value}}) 
                                    }}
                                    />
                                    : <Typography className={classes.label} align="right" variant='body1'>
                                        {equipmentData.engine ? (equipmentData.engine.cpl ? equipmentData.engine.cpl : "") : ""}
                                    </Typography>
                                }
                            </Grid>
                        </Grid>
                        <Divider/>
                        <Grid container className={classes.rlSpacing}>
                            <Grid item xs={8}>
                                <Typography className={classes.label} variant='body1'>Model:</Typography>
                            </Grid>
                            <Grid item xs={4} >
                                {props.edit
                                    ? <TextField
                                    size="small"
                                    value={equipmentData.engine ? (equipmentData.engine.model ? equipmentData.engine.model : "") : ""}
                                    onChange={(event) => {equipmentData.engine 
                                        ? updateEquipmentData({...equipmentData, engine: {...equipmentData.engine, 'model': event.target.value}}) 
                                        : updateEquipmentData({...equipmentData, engine: { 'model': event.target.value}}) 
                                    }}
                                    />
                                    : <Typography className={classes.label} align="right" variant='body1'>
                                        {equipmentData.engine ? (equipmentData.engine.model ? equipmentData.engine.model : "") : ""}
                                    </Typography>
                                }
                            </Grid>
                        </Grid>
                        <Divider/>
                        <Grid container className={classes.rlSpacing}>
                            <Grid item xs={8}>
                                <Typography className={classes.label} variant='body1'>Oil Type:</Typography>
                            </Grid>
                            <Grid item xs={4} >
                                {props.edit
                                    ? <TextField
                                    size="small"
                                    value={equipmentData.engine ? (equipmentData.engine.oilType ? equipmentData.engine.oilType : "") : ""}
                                    onChange={(event) => {equipmentData.engine 
                                        ? updateEquipmentData({...equipmentData, engine: {...equipmentData.engine, 'oilType': event.target.value}}) 
                                        : updateEquipmentData({...equipmentData, engine: { 'oilType': event.target.value}}) 
                                    }}
                                    />
                                    : <Typography className={classes.label} align="right" variant='body1'>
                                        {equipmentData.engine ? (equipmentData.engine.oilType ? equipmentData.engine.oilType : "") : ""}
                                    </Typography>
                                }
                            </Grid>
                        </Grid>
                        <Divider/>
                        <Grid container className={classes.rlSpacing}>
                            <Grid item xs={8}>
                                <Typography className={classes.label} variant='body1'>Oil Capacity (litres):</Typography>
                            </Grid>
                            <Grid item xs={4} >
                                {props.edit
                                    ?<TextField
                                    size="small"
                                    value={equipmentData.engine ? (equipmentData.engine.oilCapacity ? equipmentData.engine.oilCapacity : "") : ""}
                                    onChange={(event) => {equipmentData.engine 
                                        ? updateEquipmentData({...equipmentData, engine: {...equipmentData.engine, 'oilCapacity': event.target.value}}) 
                                        : updateEquipmentData({...equipmentData, engine: { 'oilCapacity': event.target.value}}) 
                                    }}
                                    />
                                    : <Typography className={classes.label} align="right" variant='body1'>
                                        {equipmentData.engine ? (equipmentData.engine.oilCapacity ? equipmentData.engine.oilCapacity : "") : ""}
                                    </Typography>
                                }
                            </Grid>
                        </Grid>
                        <Divider/>
                        <Grid container className={classes.rlSpacing}>
                            <Grid item xs={11}>
                                <Typography className={classes.label} variant='body1'>Uses Synthetic Oil:</Typography>
                            </Grid>
                            <Grid item xs={1}>
                                <Checkbox 
                                className={classes.checkbox} 
                                disabled={props.edit ? false : true}
                                checked={equipmentData.engine ? (equipmentData.engine.usesSynthetic === true ? true : false) : false}
                                onClick={(event) => {equipmentData.engine 
                                    ? updateEquipmentData({...equipmentData, engine: {...equipmentData.engine, 'usesSynthetic': event.target.checked}}) 
                                    : updateEquipmentData({...equipmentData, engine: { 'usesSynthetic': event.target.checked}}) 
                                }}
                                />
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>

                {/* TRANSMISSION */}
                <Grid item xs={6}>
                    <Paper className={classes.paper}>
                        <Typography variant= 'h5' className={classes.header} >Transmission</Typography>
                        <hr></hr>
                        <Grid container className={classes.rlSpacing}>
                            <Grid item xs={8}>
                                <Typography className={classes.label} variant='body1'>Serial Number:</Typography>
                            </Grid>
                            <Grid item xs={4} >
                                {props.edit
                                    ?<TextField
                                    size="small"
                                    value={equipmentData.transmission ? (equipmentData.transmission.serialNum ? equipmentData.transmission.serialNum : "") : ""}
                                    onChange={(event) => {equipmentData.transmission 
                                        ? updateEquipmentData({...equipmentData, transmission: {...equipmentData.transmission, 'serialNum': event.target.value}}) 
                                        : updateEquipmentData({...equipmentData, transmission: { 'serialNum': event.target.value}}) 
                                    }}
                                    />
                                    :<Typography className={classes.label} align="right" variant='body1'>
                                        {equipmentData.transmission ? (equipmentData.transmission.serialNum ? equipmentData.transmission.serialNum : "") : ""}
                                    </Typography>
                                }
                            </Grid>
                        </Grid>
                        <Divider/>
                        <Grid container className={classes.rlSpacing}>
                            <Grid item xs={12}>
                                {/* fills the empty space */}
                                <Typography className={classes.label} style={{height: '32px'}}></Typography>
                            </Grid>
                        </Grid>
                        <Divider/>
                        <Grid container className={classes.rlSpacing}>
                            <Grid item xs={8}>
                                <Typography className={classes.label} variant='body1'>Model:</Typography>
                            </Grid>
                            <Grid item xs={4} >
                                {props.edit
                                    ? <TextField
                                    size="small"
                                    value={equipmentData.transmission ? (equipmentData.transmission.model ? equipmentData.transmission.model : "") : ""}
                                    onChange={(event) => {equipmentData.transmission 
                                        ? updateEquipmentData({...equipmentData, transmission: {...equipmentData.transmission, 'model': event.target.value}}) 
                                        : updateEquipmentData({...equipmentData, transmission: { 'model': event.target.value}}) 
                                    }}
                                    />
                                    : <Typography className={classes.label} align="right" variant='body1'>
                                        {equipmentData.transmission ? (equipmentData.transmission.model ? equipmentData.transmission.model : "") : ""}
                                    </Typography>
                                }
                            </Grid>
                        </Grid>
                        <Divider/>
                        <Grid container className={classes.rlSpacing}>
                            <Grid item xs={8}>
                                <Typography className={classes.label} variant='body1'>Oil Type:</Typography>
                            </Grid>
                            <Grid item xs={4} >
                                {props.edit
                                    ? <TextField
                                    size="small"
                                    value={equipmentData.transmission ? (equipmentData.transmission.oilType ? equipmentData.transmission.oilType : "") : ""}
                                    onChange={(event) => {equipmentData.transmission 
                                        ? updateEquipmentData({...equipmentData, transmission: {...equipmentData.transmission, 'oilType': event.target.value}}) 
                                        : updateEquipmentData({...equipmentData, transmission: { 'oilType': event.target.value}}) 
                                    }}
                                    />
                                    : <Typography className={classes.label} align="right" variant='body1'>
                                        {equipmentData.transmission ? (equipmentData.transmission.oilType ? equipmentData.transmission.oilType : "") : ""}
                                    </Typography>
                                }
                            </Grid>
                        </Grid>
                        <Divider/>
                        <Grid container className={classes.rlSpacing}>
                            <Grid item xs={8}>
                                <Typography className={classes.label} variant='body1'>Oil Capacity (litres):</Typography>
                            </Grid>
                            <Grid item xs={4} >
                                {props.edit
                                    ? <TextField
                                    size="small"
                                    value={equipmentData.transmission ? (equipmentData.transmission.oilCapacity ? equipmentData.transmission.oilCapacity : "") : ""}
                                    onChange={(event) => {equipmentData.transmission 
                                        ? updateEquipmentData({...equipmentData, transmission: {...equipmentData.transmission, 'oilCapacity': event.target.value}}) 
                                        : updateEquipmentData({...equipmentData, transmission: { 'oilCapacity': event.target.value}}) 
                                    }}
                                    />
                                    : <Typography className={classes.label} align="right" variant='body1'>
                                        {equipmentData.transmission ? (equipmentData.transmission.oilCapacity ? equipmentData.transmission.oilCapacity : "") : ""}
                                    </Typography>
                                }
                            </Grid>
                        </Grid>
                        <Divider/>
                        <Grid container className={classes.rlSpacing}>
                            <Grid item xs={11}>
                                <Typography className={classes.label} variant='body1'>Uses Synthetic Oil:</Typography>
                            </Grid>
                            <Grid item xs={1}>
                                <Checkbox 
                                className={classes.checkbox}
                                disabled={props.edit ? false : true}
                                checked={equipmentData.transmission ? (equipmentData.transmission.usesSynthetic === true ? true : false) : false}
                                onClick={(event) => {equipmentData.transmission 
                                    ? updateEquipmentData({...equipmentData, transmission: {...equipmentData.transmission, 'usesSynthetic': event.target.checked}}) 
                                    : updateEquipmentData({...equipmentData, transmission: { 'usesSynthetic': event.target.checked}}) 
                                }}
                                />
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>
            </Grid>

            <Grid container spacing={2} >
                {/* DIFFERENTIAL */}
                <Grid item xs={6}>
                    <Paper className={classes.paper}>
                        <Typography variant= 'h5' className={classes.header} >Differential</Typography>
                        <hr></hr>
                        <Grid container className={classes.rlSpacing}>
                            <Grid item xs={8} >
                                <Typography className={classes.label}  variant='body1'>Type:</Typography>
                            </Grid>
                            <Grid item xs={4} >
                                {props.edit 
                                    ?<TextField
                                    size="small"
                                    value={equipmentData.differential ? (equipmentData.differential.type ? equipmentData.differential.type : "") : ""}
                                    onChange={(event) => {equipmentData.differential 
                                        ? updateEquipmentData({...equipmentData, differential: {...equipmentData.differential, 'type': event.target.value}}) 
                                        : updateEquipmentData({...equipmentData, differential: { 'type': event.target.value}}) 
                                    }}
                                    />
                                    : <Typography className={classes.label} align="right" variant='body1'>
                                        {equipmentData.differential ? (equipmentData.differential.type ? equipmentData.differential.type : "") : ""}
                                    </Typography>
                                }
                            </Grid>
                        </Grid>
                        <Divider/>
                        <Grid container className={classes.rlSpacing}>
                            <Grid item xs={8}>
                                <Typography className={classes.label} variant='body1'>Ratio:</Typography>
                            </Grid>
                            <Grid item xs={4} >
                                {props.edit 
                                ? <TextField
                                size="small"
                                value={equipmentData.differential ? (equipmentData.differential.ratio ? equipmentData.differential.ratio : "") : ""}
                                onChange={(event) => {equipmentData.differential 
                                    ? updateEquipmentData({...equipmentData, differential: {...equipmentData.differential, 'ratio': event.target.value}}) 
                                    : updateEquipmentData({...equipmentData, differential: { 'ratio': event.target.value}}) 
                                }}
                                />
                                : <Typography className={classes.label} align="right" variant='body1'>
                                    {equipmentData.differential ? (equipmentData.differential.ratio ? equipmentData.differential.ratio : "") : ""}
                                  </Typography> 
                                }
                             </Grid>
                        </Grid>
                        <Divider/>
                        <Grid container className={classes.rlSpacing}>
                            <Grid item xs={8}>
                                <Typography className={classes.label} variant='body1'>Make:</Typography>
                            </Grid>
                            <Grid item xs={4} >
                                {props.edit
                                    ?<TextField
                                    size="small"
                                    value={equipmentData.differential ? (equipmentData.differential.make ? equipmentData.differential.make : "") : ""}
                                    onChange={(event) => {equipmentData.differential 
                                        ? updateEquipmentData({...equipmentData, differential: {...equipmentData.differential, 'make': event.target.value}}) 
                                        : updateEquipmentData({...equipmentData, differential: { 'make': event.target.value}}) 
                                    }}
                                    />
                                    : <Typography className={classes.label} align="right" variant='body1'>
                                        {equipmentData.differential ? (equipmentData.differential.make ? equipmentData.differential.make : "") : ""}
                                    </Typography>
                                }
                            </Grid>
                        </Grid>
                        <Divider/>
                        <Grid container className={classes.rlSpacing}>
                            <Grid item xs={8}>
                                <Typography className={classes.label} variant='body1'>Oil Type:</Typography>
                            </Grid>
                            <Grid item xs={4} >
                                {props.edit
                                ? <TextField
                                    size="small"
                                    value={equipmentData.differential ? (equipmentData.differential.oilType ? equipmentData.differential.oilType : "") : ""}
                                    onChange={(event) => {equipmentData.differential 
                                        ? updateEquipmentData({...equipmentData, differential: {...equipmentData.differential, 'oilType': event.target.value}}) 
                                        : updateEquipmentData({...equipmentData, differential: { 'oilType': event.target.value}}) 
                                    }}
                                    />
                                    : <Typography className={classes.label} align="right" variant='body1'>
                                        {equipmentData.differential ? (equipmentData.differential.oilType ? equipmentData.differential.oilType : "") : ""}
                                    </Typography> 
                                }
                            </Grid>
                        </Grid>
                        <Divider/>
                        <Grid container className={classes.rlSpacing}>
                            <Grid item xs={8}>
                                <Typography className={classes.label} variant='body1'>Oil Capcity (litres):</Typography>
                            </Grid>
                            <Grid item xs={4} >
                                {props.edit
                                    ? <TextField
                                    size="small"
                                    value={equipmentData.differential ? (equipmentData.differential.oilCapacity ? equipmentData.differential.oilCapacity : "") : ""}
                                    onChange={(event) => {equipmentData.differential 
                                        ? updateEquipmentData({...equipmentData, differential: {...equipmentData.differential, 'oilCapacity': event.target.value}}) 
                                        : updateEquipmentData({...equipmentData, differential: { 'oilCapacity': event.target.value}}) 
                                    }}
                                    />
                                    : <Typography className={classes.label} align="right" variant='body1'>
                                        {equipmentData.differential ? (equipmentData.differential.oilCapacity ? equipmentData.differential.oilCapacity : "") : ""}
                                    </Typography> 
                                }
                            </Grid>
                        </Grid>
                        <Divider/>
                        <Grid container className={classes.rlSpacing}>
                            <Grid item xs={11}>
                                <Typography className={classes.label} variant='body1'>Uses Synthetic Oil:</Typography>
                            </Grid>
                            <Grid item xs={1}>
                                <Checkbox 
                                className={classes.checkbox} 
                                disabled={props.edit ? false : true}
                                checked={equipmentData.differential ? (equipmentData.differential.usesSynthetic === true ? true : false) : false}
                                onClick={(event) => {equipmentData.differential 
                                    ? updateEquipmentData({...equipmentData, differential: {...equipmentData.differential, 'usesSynthetic': event.target.checked}}) 
                                    : updateEquipmentData({...equipmentData, differential: { 'usesSynthetic': event.target.checked}}) 
                                }}
                                />
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>

                {/* FILTERS */}
                <Grid item xs={6}>
                    <Paper className={classes.paper}>
                        <Typography variant= 'h5' className={classes.header} >Filters</Typography>
                        <hr></hr>
                        <Grid container className={classes.rlSpacing}>
                            <Grid item xs={8}>
                                <Typography className={classes.label} variant='body1'>Air Filter:</Typography>
                            </Grid>
                            <Grid item xs={4} >
                                {props.edit
                                    ? <TextField
                                    size="small"
                                    value={equipmentData.filters ? (equipmentData.filters.airFilter ? equipmentData.filters.airFilter : "") : ""}
                                    onChange={(event) => {equipmentData.filters 
                                        ? updateEquipmentData({...equipmentData, filters: {...equipmentData.filters, 'airFilter': event.target.value}}) 
                                        : updateEquipmentData({...equipmentData, filters: { 'airFilter': event.target.value}}) 
                                    }}
                                    />
                                    : <Typography className={classes.label} align="right" variant='body1'>
                                        {equipmentData.filters ? (equipmentData.filters.airFilter ? equipmentData.filters.airFilter : "") : ""}
                                    </Typography>
                                }
                            </Grid>
                        </Grid>
                        <Divider/>
                        <Grid container className={classes.rlSpacing}>
                            <Grid item xs={8}>
                                <Typography className={classes.label} variant='body1'>Water Filter:</Typography>
                            </Grid>
                            <Grid item xs={4} >
                                {props.edit
                                    ? <TextField
                                    size="small"
                                    value={equipmentData.filters ? (equipmentData.filters.waterFilter ? equipmentData.filters.waterFilter : "") : ""}
                                    onChange={(event) => {equipmentData.filters 
                                        ? updateEquipmentData({...equipmentData, filters: {...equipmentData.filters, 'waterFilter': event.target.value}}) 
                                        : updateEquipmentData({...equipmentData, filters: { 'waterFilter': event.target.value}}) 
                                    }}
                                    />
                                    : <Typography className={classes.label} align="right" variant='body1'>
                                        {equipmentData.filters ? (equipmentData.filters.waterFilter ? equipmentData.filters.waterFilter : "") : ""}
                                    </Typography>
                                }
                            </Grid>
                        </Grid>
                        <Divider/>
                        <Grid container className={classes.rlSpacing}>
                            <Grid item xs={8}>
                                <Typography className={classes.label} variant='body1'>Fuel Filter # 1:</Typography>
                            </Grid>
                            <Grid item xs={4} >
                                {props.edit
                                    ? <TextField
                                    size="small"
                                    value={equipmentData.filters ? (equipmentData.filters.fuelFilter1 ? equipmentData.filters.fuelFilter1 : "") : ""}
                                    onChange={(event) => {equipmentData.filters 
                                        ? updateEquipmentData({...equipmentData, filters: {...equipmentData.filters, 'fuelFilter1': event.target.value}}) 
                                        : updateEquipmentData({...equipmentData, filters: { 'fuelFilter1': event.target.value}}) 
                                    }}
                                    />
                                    : <Typography className={classes.label} align="right" variant='body1'>
                                        {equipmentData.filters ? (equipmentData.filters.fuelFilter1 ? equipmentData.filters.fuelFilter1 : "") : ""}
                                    </Typography>
                                }
                             </Grid>
                        </Grid>
                        <Divider/>
                        <Grid container className={classes.rlSpacing}>
                            <Grid item xs={8}>
                                <Typography className={classes.label} variant='body1'>Fuel Filter # 2:</Typography>
                            </Grid>
                            <Grid item xs={4} >
                                {props.edit
                                    ? <TextField
                                    size="small"
                                    value={equipmentData.filters ? (equipmentData.filters.fuelFilter2 ? equipmentData.filters.fuelFilter2 : "") : ""}
                                    onChange={(event) => {equipmentData.filters 
                                        ? updateEquipmentData({...equipmentData, filters: {...equipmentData.filters, 'fuelFilter2': event.target.value}}) 
                                        : updateEquipmentData({...equipmentData, filters: { 'fuelFilter2': event.target.value}}) 
                                    }}
                                    />
                                    : <Typography className={classes.label} align="right" variant='body1'>
                                        {equipmentData.filters ? (equipmentData.filters.fuelFilter2 ? equipmentData.filters.fuelFilter2 : "") : ""}
                                    </Typography>
                                }
                            </Grid>
                        </Grid>
                        <Divider/>
                        <Grid container className={classes.rlSpacing}>
                            <Grid item xs={8}>
                                <Typography className={classes.label} variant='body1'>Oil Filter # 1:</Typography>
                            </Grid>
                            <Grid item xs={4} >
                                {props.edit
                                    ? <TextField
                                    size="small"
                                    value={equipmentData.filters ? (equipmentData.filters.oilFilter1 ? equipmentData.filters.oilFilter1 : "") : ""}
                                    onChange={(event) => {equipmentData.filters 
                                        ? updateEquipmentData({...equipmentData, filters: {...equipmentData.filters, 'oilFilter1': event.target.value}}) 
                                        : updateEquipmentData({...equipmentData, filters: { 'oilFilter1': event.target.value}}) 
                                    }}
                                    />
                                    : <Typography className={classes.label} align="right" variant='body1'>
                                        {equipmentData.filters ? (equipmentData.filters.oilFilter1 ? equipmentData.filters.oilFilter1 : "") : ""}
                                    </Typography>
                                }
                            </Grid>
                        </Grid>
                        <Divider/>
                        <Grid container className={classes.rlSpacing}>
                            <Grid item xs={8}>
                                <Typography className={classes.label} variant='body1'>Oil Filter # 2:</Typography>
                            </Grid>
                            <Grid item xs={4} >
                                {props.edit
                                    ? <TextField
                                    size="small"
                                    value={equipmentData.filters ? (equipmentData.filters.oilFilter2 ? equipmentData.filters.oilFilter2 : "") : ""}
                                    onChange={(event) => {equipmentData.filters 
                                        ? updateEquipmentData({...equipmentData, filters: {...equipmentData.filters, 'oilFilter2': event.target.value}}) 
                                        : updateEquipmentData({...equipmentData, filters: { 'oilFilter2': event.target.value}}) 
                                    }}
                                    />
                                    : <Typography className={classes.label} align="right" variant='body1'>
                                        {equipmentData.filters ? (equipmentData.filters.oilFilter2 ? equipmentData.filters.oilFilter2 : "") : ""}
                                    </Typography>
                                }
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>
            </Grid>

            <Grid container spacing={2} >
                {/* CLUTCH */}
                <Grid item xs={6}>
                    <Paper className={classes.paper}>
                        <Typography variant= 'h5' className={classes.header} >Clutch</Typography>
                        <hr></hr>
                        <Grid container className={classes.rlSpacing}>
                            <Grid item xs={8} >
                                <Typography className={classes.label}  variant='body1'>Clutch Brake:</Typography>
                            </Grid>
                            <Grid item xs={4} >
                                {props.edit 
                                    ? <TextField
                                    size="small"
                                    value={equipmentData.clutch ? (equipmentData.clutch.clutchBrake ? equipmentData.clutch.clutchBrake : "") : ""}
                                    onChange={(event) => {equipmentData.clutch 
                                        ? updateEquipmentData({...equipmentData, clutch: {...equipmentData.clutch, 'clutchBrake': event.target.value}}) 
                                        : updateEquipmentData({...equipmentData, clutch: { 'clutchBrake': event.target.value}}) 
                                    }}
                                    />
                                    : <Typography className={classes.label} align="right" variant='body1'>
                                        {equipmentData.clutch ? (equipmentData.clutch.clutchBrake ? equipmentData.clutch.clutchBrake : "") : ""}
                                    </Typography>
                                }
                            </Grid>
                        </Grid>
                        <Divider/>
                        <Grid container className={classes.rlSpacing}>
                            <Grid item xs={8}>
                                <Typography className={classes.label} variant='body1'>Clutch Type:</Typography>
                            </Grid>
                            <Grid item xs={4} >
                                {props.edit 
                                    ? <TextField
                                    size="small"
                                    value={equipmentData.clutch ? (equipmentData.clutch.clutchType ? equipmentData.clutch.clutchType : "") : ""}
                                    onChange={(event) => {equipmentData.clutch 
                                        ? updateEquipmentData({...equipmentData, clutch: {...equipmentData.clutch, 'clutchType': event.target.value}}) 
                                        : updateEquipmentData({...equipmentData, clutch: { 'clutchType': event.target.value}}) 
                                    }}
                                    />
                                    : <Typography className={classes.label} align="right" variant='body1'>
                                        {equipmentData.clutch ? (equipmentData.clutch.clutchType ? equipmentData.clutch.clutchType : "") : ""}
                                    </Typography>
                                }
                            </Grid>
                        </Grid>
                        <Divider/>
                        <Grid container className={classes.rlSpacing}>
                            <Grid item xs={8}>
                                <Typography className={classes.label} variant='body1'>Clutch Size:</Typography>
                            </Grid>
                            <Grid item xs={4} >
                                {props.edit 
                                    ? <TextField
                                    size="small"
                                    value={equipmentData.clutch ? (equipmentData.clutch.clutchSize ? equipmentData.clutch.clutchSize : "") : ""}
                                    onChange={(event) => {equipmentData.clutch 
                                        ? updateEquipmentData({...equipmentData, clutch: {...equipmentData.clutch, 'clutchSize': event.target.value}}) 
                                        : updateEquipmentData({...equipmentData, clutch: { 'clutchSize': event.target.value}}) 
                                    }}
                                    />
                                    : <Typography className={classes.label} align="right" variant='body1'>
                                        {equipmentData.clutch ? (equipmentData.clutch.clutchSize ? equipmentData.clutch.clutchSize : "") : ""}
                                    </Typography>
                                }
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>

                {/* SEALS */}
                <Grid item xs={6}>
                    <Paper className={classes.paper}>
                        <Typography variant= 'h5' className={classes.header} >Seals</Typography>
                        <hr></hr>
                        <Grid container className={classes.rlSpacing}>
                            <Grid item xs={8}>
                                <Typography className={classes.label} variant='body1'>Nose Cone Seal:</Typography>
                            </Grid>
                            <Grid item xs={4} >
                                {props.edit
                                    ? <TextField
                                    size="small"
                                    value={equipmentData.seals ? (equipmentData.seals.noseConeSeal ? equipmentData.seals.noseConeSeal : "") : ""}
                                    onChange={(event) => {equipmentData.seals 
                                        ? updateEquipmentData({...equipmentData, seals: {...equipmentData.seals, 'noseConeSeal': event.target.value}}) 
                                        : updateEquipmentData({...equipmentData, seals: { 'noseConeSeal': event.target.value}}) 
                                    }}
                                    />
                                    : <Typography className={classes.label} align="right" variant='body1'>
                                        {equipmentData.seals ? (equipmentData.seals.noseConeSeal ? equipmentData.seals.noseConeSeal : "") : ""}
                                    </Typography>
                                }
                            </Grid>
                        </Grid>
                        <Divider/>
                        <Grid container className={classes.rlSpacing}>
                            <Grid item xs={8}>
                                <Typography className={classes.label} variant='body1'>Thru/Shaft Seal:</Typography>
                            </Grid>
                            <Grid item xs={4} >
                                {props.edit
                                    ? <TextField
                                    size="small"
                                    value={equipmentData.seals ? (equipmentData.seals.thruShaftSeal ? equipmentData.seals.thruShaftSeal : "") : ""}
                                    onChange={(event) => {equipmentData.seals 
                                        ? updateEquipmentData({...equipmentData, seals: {...equipmentData.seals, 'thruShaftSeal': event.target.value}}) 
                                        : updateEquipmentData({...equipmentData, seals: { 'thruShaftSeal': event.target.value}}) 
                                    }}
                                    />
                                    : <Typography className={classes.label} align="right" variant='body1'>
                                        {equipmentData.seals ? (equipmentData.seals.thruShaftSeal ? equipmentData.seals.thruShaftSeal : "") : ""}
                                    </Typography>
                                }
                            </Grid>
                        </Grid>
                        <Divider/>
                        <Grid container className={classes.rlSpacing}>
                            <Grid item xs={8}>
                                <Typography className={classes.label} variant='body1'>Rear Pinion Seal:</Typography>
                            </Grid>
                            <Grid item xs={4} >
                                {props.edit
                                    ? <TextField
                                    size="small"
                                    value={equipmentData.seals ? (equipmentData.seals.rearPinionSeal ? equipmentData.seals.rearPinionSeal : "") : ""}
                                    onChange={(event) => {equipmentData.seals 
                                        ? updateEquipmentData({...equipmentData, seals: {...equipmentData.seals, 'rearPinionSeal': event.target.value}}) 
                                        : updateEquipmentData({...equipmentData, seals: { 'rearPinionSeal': event.target.value}}) 
                                    }}
                                    />
                                    : <Typography className={classes.label} align="right" variant='body1'>
                                        {equipmentData.seals ? (equipmentData.seals.rearPinionSeal ? equipmentData.seals.rearPinionSeal : "") : ""}
                                    </Typography>
                                }
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>
            </Grid>

            <Grid container spacing={2} >
                {/* BELTS */}
                <Grid item xs={12}>
                    <Paper className={classes.paper}>
                        <Typography variant= 'h5' className={classes.header} >Belts</Typography>
                        <hr></hr>        
                        <Grid container>
                            <Grid item xs={6}>

                                <Grid container className={classes.rlSpacing}>
                                    <Grid item xs={8} >
                                        <Typography className={classes.label}  variant='body1'>Water Pump Belt:</Typography>
                                    </Grid>
                                    <Grid item xs={4} >
                                        {props.edit 
                                            ? <TextField
                                            size="small"
                                            value={equipmentData.belts ? (equipmentData.belts.waterPumpBelt ? equipmentData.belts.waterPumpBelt : "") : ""}
                                            onChange={(event) => {equipmentData.belts 
                                                ? updateEquipmentData({...equipmentData, belts: {...equipmentData.belts, 'waterPumpBelt': event.target.value}}) 
                                                : updateEquipmentData({...equipmentData, belts: { 'waterPumpBelt': event.target.value}}) 
                                            }}
                                            />
                                            : <Typography className={classes.label} align="right" variant='body1'>
                                                {equipmentData.belts ? (equipmentData.belts.waterPumpBelt ? equipmentData.belts.waterPumpBelt : "") : ""}
                                            </Typography>
                                        }
                                    </Grid>
                                </Grid>
                                <Divider/>
                                <Grid container className={classes.rlSpacing}>
                                    <Grid item xs={8}>
                                        <Typography className={classes.label} variant='body1'>Power Steering Belt:</Typography>
                                    </Grid>
                                    <Grid item xs={4} >
                                        {props.edit 
                                            ? <TextField
                                            size="small"
                                            value={equipmentData.belts ? (equipmentData.belts.powerSteeringBelt ? equipmentData.belts.powerSteeringBelt : "") : ""}
                                            onChange={(event) => {equipmentData.belts 
                                                ? updateEquipmentData({...equipmentData, belts: {...equipmentData.belts, 'powerSteeringBelt': event.target.value}}) 
                                                : updateEquipmentData({...equipmentData, belts: { 'powerSteeringBelt': event.target.value}}) 
                                            }}
                                            />
                                            : <Typography className={classes.label} align="right" variant='body1'>
                                                {equipmentData.belts ? (equipmentData.belts.powerSteeringBelt ? equipmentData.belts.powerSteeringBelt : "") : ""}
                                            </Typography>
                                        }
                                    </Grid>
                                </Grid>
                                <Divider/>
                                <Grid container className={classes.rlSpacing}>
                                    <Grid item xs={8}>
                                        <Typography className={classes.label} variant='body1'>Alternator Belt:</Typography>
                                    </Grid>
                                    <Grid item xs={4} >
                                        {props.edit 
                                            ? <TextField
                                            size="small"
                                            value={equipmentData.belts ? (equipmentData.belts.alternatorBelt ? equipmentData.belts.alternatorBelt : "") : ""}
                                            onChange={(event) => {equipmentData.belts 
                                                ? updateEquipmentData({...equipmentData, belts: {...equipmentData.belts, 'alternatorBelt': event.target.value}}) 
                                                : updateEquipmentData({...equipmentData, belts: { 'alternatorBelt': event.target.value}}) 
                                            }}
                                            />
                                            : <Typography className={classes.label} align="right" variant='body1'>
                                                {equipmentData.belts ? (equipmentData.belts.alternatorBelt ? equipmentData.belts.alternatorBelt : "") : ""}
                                            </Typography>
                                        }
                                    </Grid>
                                </Grid>

                            </Grid>

                            <Grid item xs={6}>

                                <Grid container className={classes.rlSpacing}>
                                    <Grid item xs={8} >
                                        <Typography className={classes.label}  variant='body1'>Fan Belt:</Typography>
                                    </Grid>
                                    <Grid item xs={4} >
                                        {props.edit 
                                            ? <TextField
                                            size="small"
                                            value={equipmentData.belts ? (equipmentData.belts.fanBelt ? equipmentData.belts.fanBelt : "") : ""}
                                            onChange={(event) => {equipmentData.belts 
                                                ? updateEquipmentData({...equipmentData, belts: {...equipmentData.belts, 'fanBelt': event.target.value}}) 
                                                : updateEquipmentData({...equipmentData, belts: { 'fanBelt': event.target.value}}) 
                                            }}
                                            />
                                            : <Typography className={classes.label} align="right" variant='body1'>
                                                {equipmentData.belts ? (equipmentData.belts.fanBelt ? equipmentData.belts.fanBelt : "") : ""}
                                            </Typography>
                                        }
                                    </Grid>
                                </Grid>
                                <Divider/>
                                <Grid container className={classes.rlSpacing}>
                                    <Grid item xs={8}>
                                        <Typography className={classes.label} variant='body1'>Air Conditioning Belt:</Typography>
                                    </Grid>
                                    <Grid item xs={4} >
                                        {props.edit 
                                        ? <TextField
                                        size="small"
                                        value={equipmentData.belts ? (equipmentData.belts.airConditioningBelt ? equipmentData.belts.airConditioningBelt : "") : ""}
                                        onChange={(event) => {equipmentData.belts 
                                            ? updateEquipmentData({...equipmentData, belts: {...equipmentData.belts, 'airConditioningBelt': event.target.value}}) 
                                            : updateEquipmentData({...equipmentData, belts: { 'airConditioningBelt': event.target.value}}) 
                                        }}
                                        />
                                        : <Typography className={classes.label} align="right" variant='body1'>
                                            {equipmentData.belts ? (equipmentData.belts.airConditioningBelt ? equipmentData.belts.airConditioningBelt : "") : ""}
                                          </Typography>
                                        }
                                    </Grid>
                                </Grid>
                                <Divider/>
                                {/* 
                                <Grid container className={classes.rlSpacing}>
                                    <Grid item xs={8}>
                                        <Typography className={classes.label} variant='body1'>Air Conditioning Belt:</Typography>
                                    </Grid>
                                    {props.edit 
                                    ? <Grid item xs={4} >
                                        <TextField
                                        size="small"
                                        //value={equipmentData.unitNumber}
                                        //onChange={(event) => {}
                                        />
                                    </Grid>
                                    : <Grid item xs={4}>
                                        <Typography className={classes.label} align="right" variant='body1'>Value</Typography>
                                    </Grid>
                                    }
                                </Grid>
                                */}
                            </Grid>
                        </Grid>

                    </Paper>
                </Grid>
            </Grid>
            <Snackbar
                //disableAutoFocus={true}
                //disableEnforceFocus={true}
                open={!_.isEqual(equipmentData, saveState)}
                message={saving ? 'Saving...' : 'Save Document?'}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center'
                }}
                action={
                    <React.Fragment>
                        {saving
                            ? null
                            : <React.Fragment>
                                <Button variant='text' color='primary' onClick={cancelSave} style={{ marginLeft: '32px', marginRight: '8px' }}>Cancel</Button>
                                <Button variant='contained' onClick={confirmSave}>Confirm</Button>
                            </React.Fragment>
                        }
                    </React.Fragment>
                } />
        </Grid>
        
    )
}
export default withRouter(PartsTab)