import React, { useEffect, useState } from 'react';
import { Grid, makeStyles,Button, Paper, Typography, IconButton, TextField, CircularProgress, Divider, Snackbar, Checkbox } from '@material-ui/core';
import { withRouter } from 'react-router';
import { useCustomer } from '../../../providers/CustomerContext';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { Skeleton } from '@material-ui/lab';
import { Autocomplete } from '@material-ui/lab';
import { firestore } from '../../../firebase/firebase';
import AddBoxIcon from '@material-ui/icons/AddBox';
import DeleteIcon from '@material-ui/icons/Delete';
import _ from 'lodash'
import moment from 'moment';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import { KeyboardDatePicker } from '@material-ui/pickers';
import { v4 as uuidv4 } from 'uuid'

const useStyles = makeStyles((theme) => ({
    content: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        maxWidth: '1200px',
        margin: 'auto',
    },
    skeletonContent: {
        display: 'flex',
        justifyContent: 'center',
        width: '100%',
        marginTop: '75px',
    },
    backButton: {
        marginBottom: '20px',
        marginTop: '20px',
    },
    paper: {
        borderRadius: '0px',
        margin: '0px',
        width: '1200px',
        height: 'auto',
    }, 
    table: {
        margin: '15px',
    }, 
    
}));

const CustomerRates = (props) => {

    const classes = useStyles();
    const { customers, getCustomers } = useCustomer();
    
    const [customerJobData, setCustomerJobData] = useState([])

    const [currentCustomerJobData, setCurrentCustomerJobData] = useState({})
    const [saveState, setSaveState] = useState({})

    const [currentCustomer, setCurrentCustomer] = useState({});

    const [open, setOpen] = useState(false);
    const [listOpen, setListOpen] = useState({})

    const [pageLoading, setPageLoading] = useState(true);
    const [saving, setSaving] = useState(false)

    //get initial data
    useEffect(() => {
        getCustomers();

        firestore.collection('customerJobs').get()
        .then(async querySnapshot => {
            const response = (querySnapshot.docs.map((doc) => {
                return {
                  ...doc.data(),
                  docId: doc.id,
                }
              }))
              setCustomerJobData(response)
              setPageLoading(false) 
        })
        .catch((err) => {
            console.warn(err)
        });
        

    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    const handleChangeCustomer = (newValue) => {
        setCurrentCustomer(newValue)
        setListOpen({})

        let currentCustomerContainer = customerJobData.find(x=> x.customerId === newValue.docId)

        if(_.isEmpty(currentCustomerContainer)){
            console.log('customer does not have a doc in customerJobs, creating')
            let newJobContainer = {
                customerId: newValue.docId,
                jobs: []
            }
            firestore.collection('customerJobs').add(newJobContainer)
            .then((docRef) => {
                setCustomerJobData([...customerJobData, {...newJobContainer, docId: docRef.id}])
                setCurrentCustomerJobData({...newJobContainer, docId: docRef.id})
                setSaveState({...newJobContainer, docId: docRef.id})
            })
            .catch((e) => {
                console.warn(e)
            });
        }
        else{
            setCurrentCustomerJobData(currentCustomerContainer)
            setSaveState(currentCustomerContainer)
        }
    }

    //refactor below three into createItem
    const createNewCustomerJob = () =>  {
        let container = _.cloneDeep(currentCustomerJobData)

        let newJob = {
            jobId: uuidv4(),
            jobNumber: '',
            expiryDate: moment().valueOf(),
            description: '',
            jobRates: [ ]
        }
        
        container.jobs = [...container.jobs, newJob]
        setCurrentCustomerJobData(container)
    }

    const createNewJobRate = (jobIndex) => {
        let container = _.cloneDeep(currentCustomerJobData)

        let newJobRate = {
            jobRateId: uuidv4(),
            description: '',
            active: true,
            rateLastUsed: moment().valueOf(),
            notes: '',
            driverInstructions: '',
            map: '',
            salesRates: [ ]
        }

        container.jobs[jobIndex].jobRates = [...container.jobs[jobIndex].jobRates, newJobRate]
        setCurrentCustomerJobData(container)
    }

    const createNewSalesRate = (jobIndex, rateIndex) => {
        let container = _.cloneDeep(currentCustomerJobData)

        let newSalesRate = {
            salesRateId: uuidv4(),
            description: '',
            salesRate: 0.00,
            per: '',
            equipmentClass: '',
            material: '',
            active: true,
        }

        container.jobs[jobIndex].jobRates[rateIndex].salesRates = [...container.jobs[jobIndex].jobRates[rateIndex].salesRates, newSalesRate]
        setCurrentCustomerJobData(container)
    }

    const updateItem = (value, type, field, jobIndex, rateIndex, salesRateIndex) => {
        let container = _.cloneDeep(currentCustomerJobData)
        switch(type) {
            case "customerJob": 
                container.jobs[jobIndex][field] = value;
                break;
            case 'jobRate':
                container.jobs[jobIndex].jobRates[rateIndex][field] = value;
                break;
            case "salesRate":
                container.jobs[jobIndex].jobRates[rateIndex].salesRates[salesRateIndex][field] = value;
                break;
            default:
                console.warn('Customer Job update was unsuccessful')
          } 
          setCurrentCustomerJobData({...container})
    }

    const deleteItem = (type, jobIndex, rateIndex, salesRateIndex) => {
        let container = _.cloneDeep(currentCustomerJobData)
        switch(type) {
            case "customerJob":
                container.jobs.splice(jobIndex, 1)
                break;
            case 'jobRate':
                container.jobs[jobIndex].jobRates.splice(rateIndex, 1)
                break;
            case "salesRate":
                container.jobs[jobIndex].jobRates[rateIndex].salesRates.splice(salesRateIndex, 1)
                break;
            default:
                console.warn('Customer Job delete was unsuccessful')
          } 
          setCurrentCustomerJobData({...container})
    }

    const confirmSave = () => {
        setSaving(true);
        firestore.collection('customerJobs').doc(currentCustomerJobData.docId).update(currentCustomerJobData).then(() => {
            setSaveState(_.cloneDeep(currentCustomerJobData));
            let container = _.cloneDeep(customerJobData)
            container.splice(container.findIndex(x=> x.docId === currentCustomerJobData.docId), 1, currentCustomerJobData)
            setCustomerJobData(container)
            setSaving(false)
        })
        .catch((e) => {
            console.warn(e);
            setSaving(false);
        });
    };

    const cancelSave = () => {
        setCurrentCustomerJobData(_.cloneDeep(saveState))
    };

    const handleExpandList = (jobIndex, rateIndex) => {
        let container = _.cloneDeep(listOpen);

        if(rateIndex === undefined){
            if(!_.isUndefined(container[jobIndex])){ delete container[jobIndex] }
            else{ container[jobIndex] = [] }  
        }
        else{ // rate
            if(container[jobIndex].includes(rateIndex)){ container[jobIndex].splice(container[jobIndex].findIndex(x=> x === rateIndex), 1) }
            else{ container[jobIndex] = [...container[jobIndex], rateIndex] }  
        }
        setListOpen(container)
    }


  return (
    <div>
        {pageLoading
        ? 
        <Grid className={classes.skeletonContent}>
            <Skeleton variant='rect' width={'40vw'} height={'80vh'}/>
        </Grid>
        : 
        <Grid className={classes.content}>
            <Grid className={classes.backButton}>
                <Button onClick={() => props.history.goBack()}
                variant={"contained"} startIcon={<ArrowBackIcon/>}>Back</Button>
            </Grid>
            
            <Paper className={classes.paper}>
                <Grid container>
                    <Grid container>
                        <Grid item xs={7}> 
                            <Typography variant="h6" style={{margin: '20px', }}>{currentCustomer?.name}</Typography>
                        </Grid>

                        <Grid item xs={5}>
                            <Grid container style={{display: 'flex', justifyContent: 'flex-end', marginRight: '50px', marginTop: '10px'}}> 
                                <Grid>
                                    {/* <IconButton 
                                    disabled = {customers.findIndex(customer => {return customer.docId === currentCustomer?.docId}) === 0 || _.isEmpty(currentCustomer)}
                                    onClick={() => handleChangeUserByArrow(-1)}
                                    >
                                        <ArrowBackIcon/>
                                    </IconButton> */}
                                </Grid>

                                <Grid >
                                    <Autocomplete
                                        open={open}
                                        onOpen={() => { setOpen(true); }}
                                        onClose={() => { setOpen(false); }}
                                        loading={pageLoading}
                                        label="Customer"
                                        size='small'
                                        id="autocomplete"
                                        autoComplete
                                        autoHighlight
                                        disableClearable
                                        className={classes.selectRow}
                                        fullWidth
                                        value={currentCustomer}
                                        onChange={(event, newValue) => { 
                                            handleChangeCustomer(newValue)
                                        }}
                                        style={{minWidth: '350px', padding: '10px'}}
                                        options={customers.sort((a, b) => a.name.localeCompare(b.name)) || {}}
                                        getOptionLabel={(option) => option.name ? option.name + ' - ' + option.streetNumber + ' ' + option.streetName : ''  }
                                        getOptionSelected={(option, value) =>  option.name === value.name || {} }
                                        renderOption={(option) =>( 
                                            customers.filter(customer => customer.docId === option.docId).map((customerObj) => {
                                                return(
                                                    <Grid key={option.docId} >
                                                        <Typography>{customerObj.name}</Typography>
                                                        <small>{ customerObj.streetNumber + ' ' + customerObj.streetName}</small>
                                                    </Grid>
                                                )
                                            })
                                        )}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label="Customer"
                                                variant="outlined"
                                                size='small'
                                                InputProps={{
                                                    ...params.InputProps,
                                                    endAdornment: (
                                                        <React.Fragment>
                                                            {props.loading ? <CircularProgress color="inherit" size={20} /> : null}
                                                            {params.InputProps.endAdornment}
                                                        </React.Fragment>
                                                    ),
                                                }}
                                            />
                                        )}
                                    />
                                </Grid>

                                <Grid>
                                {/* <IconButton 
                                    disabled = {customers.findIndex(customer => {return customer.uuid === currentCustomer?.uuid}) === (customers.length - 1) || _.isEmpty(currentCustomer)}
                                    onClick={() => handleChangeUserByArrow(1)}
                                    >
                                        <ArrowForwardIcon/>
                                </IconButton> */}
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid container className={classes.table} style={{marginTop: '25px'}}>
                            <Grid item xs={6}>
                                <Typography style={{fontWeight: 'bold'}}>Customer Job</Typography>
                            </Grid>

                            <Grid item xs={6}>
                                <Grid container style={{display: 'flex', justifyContent: 'flex-end'}}>
                                    <Button 
                                        variant='contained' 
                                        onClick={() => createNewCustomerJob()} 
                                        startIcon={<AddBoxIcon/>} 
                                        disabled={_.isEmpty(currentCustomer)}
                                    >   
                                        Add
                                    </Button>
                                </Grid>
                            </Grid>

                            <Grid item xs={12}>
                                <Grid container style={{marginTop: '20px'}}>
                                    
                                    <Grid item xs={1}>  
                                        {/* open rates button */}
                                    </Grid>
                                    <Grid item xs={3}>
                                        <Typography style={{fontWeight: '500'}}>Job Number</Typography>
                                    </Grid>
                                    <Grid item xs={3}>
                                        <Typography style={{fontWeight: '500'}}>Expiry Date</Typography>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <Typography style={{fontWeight: '500'}}>Description</Typography>
                                    </Grid>
                                    <Grid item xs={1}>
                                        {/* delete button */}
                                    </Grid>
                                </Grid>

                                <Grid container>
                                    {currentCustomerJobData?.jobs?.map((job, jobIndex) => {
                                        return (
                                            <Grid item xs={12} key={job?.jobId + jobIndex}>
                                                <Grid container style={{marginTop: '20px',}}>
                                                    <Grid item xs={1}>
                                                        <IconButton
                                                            onClick={()=> { handleExpandList(jobIndex) }}
                                                        >
                                                            {!_.isUndefined(listOpen[jobIndex]) ? <KeyboardArrowDownIcon/> : <KeyboardArrowRightIcon/> }
                                                        </IconButton>
                                                    </Grid>
                                                    <Grid item xs={3}>
                                                        <TextField
                                                            onChange={(event) => updateItem(event.target.value, 'customerJob', 'jobNumber', jobIndex)} 
                                                            style={{width: '80%'}}
                                                            value={job.jobNumber}
                                                        ></TextField>
                                                    </Grid>
                                                    <Grid item xs={3}>
                                                        <KeyboardDatePicker
                                                            onChange={(value) => updateItem(moment(value).valueOf(), 'customerJob', 'expiryDate', jobIndex)} 
                                                            style={{width: '80%'}}
                                                            value={job.expiryDate}
                                                        ></KeyboardDatePicker>
                                                    </Grid>
                                                    <Grid item xs={4}>
                                                        <TextField
                                                            onChange={(event) => updateItem(event.target.value, 'customerJob', 'description', jobIndex)}  
                                                            style={{width: '80%'}}
                                                            value={job.description}
                                                        ></TextField>
                                                    </Grid>
                                                    <Grid item xs={1}>
                                                        <IconButton 
                                                            onClick={(event) => deleteItem('customerJob', jobIndex)} 
                                                            style={{float:'right', marginRight: '5px', padding: '1px'}}
                                                        >    
                                                            <DeleteIcon />
                                                        </IconButton>
                                                    </Grid>
                                                </Grid>
                                                {!_.isUndefined(listOpen[jobIndex]) && 
                                                    <Grid style={{backgroundColor: '#d3d3d3', paddingRight: '5px', paddingLeft: '5px'}}>
                                                        <Divider style={{marginBottom: '20px'}}/>

                                                        <Grid container>
                                                            <Grid item xs={6}>
                                                                <Typography style={{fontWeight: 'bold'}}>Job Rate</Typography>
                                                            </Grid>

                                                            <Grid item xs={6}>
                                                                <Grid container style={{display: 'flex', justifyContent: 'flex-end'}}>
                                                                    <Button 
                                                                        variant='contained' 
                                                                        onClick={() => createNewJobRate(jobIndex)} 
                                                                        startIcon={<AddBoxIcon/>} 
                                                                        disabled={_.isEmpty(currentCustomer)}
                                                                    >   
                                                                        Add
                                                                    </Button>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>

                                                        <Grid container>
                                                            <Grid item xs={1}>  
                                                            {/* open rates button */}
                                                            </Grid>
                                                            <Grid item xs={2}>
                                                                <Typography style={{fontWeight: '500'}}>Description</Typography>
                                                            </Grid>
                                                            <Grid item xs={3}>
                                                                <Typography style={{fontWeight: '500'}}>Notes</Typography>
                                                            </Grid>
                                                            <Grid item xs={3}>
                                                                <Typography style={{fontWeight: '500'}}>Driver Insturctions</Typography>
                                                            </Grid>
                                                            <Grid item xs={2}>
                                                                <Typography style={{fontWeight: '500'}}>Active</Typography>
                                                            </Grid>
                                                            <Grid item xs={1}>
                                                                {/* delete button */}
                                                            </Grid>
                                                        </Grid>
                                                        {job?.jobRates?.map((rate, rateIndex) => {
                                                            return (
                                                                <Grid item xs={12} key={rate?.jobRateId + rateIndex}>
                                                                    <Grid container style={{marginTop: '20px',}}>
                                                                        <Grid item xs={1}>
                                                                            <IconButton
                                                                                onClick={()=> { handleExpandList(jobIndex, rateIndex) }}
                                                                            >
                                                                                {listOpen[jobIndex].includes(rateIndex) ? <KeyboardArrowDownIcon/> : <KeyboardArrowRightIcon/> }
                                                                            </IconButton>
                                                                        </Grid>
                                                                        <Grid item xs={2}>
                                                                            <TextField
                                                                                onChange={(event) => updateItem(event.target.value, 'jobRate', 'description', jobIndex, rateIndex)} 
                                                                                style={{width: '80%'}}
                                                                                value={rate.description}
                                                                            ></TextField>
                                                                        </Grid>
                                                                        <Grid item xs={3}>
                                                                            <TextField
                                                                                onChange={(event) => updateItem(event.target.value, 'jobRate', 'notes', jobIndex, rateIndex)} 
                                                                                style={{width: '80%'}}
                                                                                value={rate.notes}
                                                                            ></TextField>
                                                                        </Grid>
                                                                        <Grid item xs={3}>
                                                                            <TextField
                                                                                onChange={(event) => updateItem(event.target.value, 'jobRate', 'driverInstructions', jobIndex, rateIndex)}  
                                                                                style={{width: '80%'}}
                                                                                value={rate.driverInstructions}
                                                                            ></TextField>
                                                                        </Grid>
                                                                        <Grid item xs={2}>
                                                                            <Checkbox
                                                                                onChange={(event) => updateItem(event.target.checked, 'jobRate', 'active', jobIndex, rateIndex)}  
                                                                                style={{width: '80%'}}
                                                                                value={rate.active}
                                                                                checked={rate.active}
                                                                            ></Checkbox>
                                                                        </Grid>
                                                                        <Grid item xs={1}>
                                                                            <IconButton 
                                                                                onClick={(event) => deleteItem('jobRate', jobIndex, rateIndex)} 
                                                                                style={{float:'right', marginRight: '5px', padding: '1px'}}
                                                                            >    
                                                                                <DeleteIcon />
                                                                            </IconButton>
                                                                        </Grid>
                                                                    </Grid>
                                                                    {listOpen[jobIndex].includes(rateIndex) && 
                                                                        <Grid style={{backgroundColor: '#ADD8E6', paddingRight: '5px', paddingLeft: '5px', paddingBottom: '5px'}}>
                                                                            <Divider style={{marginBottom: '20px'}}/>
                    
                                                                            <Grid container>
                                                                                <Grid item xs={6}>
                                                                                    <Typography style={{fontWeight: 'bold'}}>Sales Rate</Typography>
                                                                                </Grid>

                                                                                <Grid item xs={6}>
                                                                                    <Grid container style={{display: 'flex', justifyContent: 'flex-end'}}>
                                                                                        <Button 
                                                                                            variant='contained' 
                                                                                            onClick={() => createNewSalesRate(jobIndex, rateIndex)} 
                                                                                            startIcon={<AddBoxIcon/>} 
                                                                                            disabled={_.isEmpty(currentCustomer)}
                                                                                        >   
                                                                                            Add
                                                                                        </Button>
                                                                                    </Grid>
                                                                                </Grid>
                                                                            </Grid>
                    
                                                                            <Grid container>
                                                                                <Grid item xs={1}>  
                                                                                {/* open rates button */}
                                                                                </Grid>
                                                                                <Grid item xs={2}>
                                                                                    <Typography style={{fontWeight: '500'}}>Description</Typography>
                                                                                </Grid>
                                                                                <Grid item xs={2}>
                                                                                    <Typography style={{fontWeight: '500'}}>Sales Rate</Typography>
                                                                                </Grid>
                                                                                <Grid item xs={1}>
                                                                                    <Typography style={{fontWeight: '500'}}>Per</Typography>
                                                                                </Grid>
                                                                                <Grid item xs={2}>
                                                                                    <Typography style={{fontWeight: '500'}}>Equipment Class</Typography>
                                                                                </Grid>
                                                                                <Grid item xs={2}>
                                                                                    <Typography style={{fontWeight: '500'}}>Material</Typography>
                                                                                </Grid>
                                                                                <Grid item xs={1}>
                                                                                    <Typography style={{fontWeight: '500'}}>Active</Typography>
                                                                                </Grid>
                                                                                <Grid item xs={1}>
                                                                                    {/* delete button */}
                                                                                </Grid>
                                                                            </Grid>
                                                                            {rate.salesRates.map((salesRate, salesRateIndex) => {
                                                                                return (
                                                                                    <Grid item xs={12} key={salesRate?.salesRateId + salesRateIndex}>
                                                                                        <Grid container style={{marginTop: '20px', marginBottom: '20px'}}>
                                                                                            <Grid item xs={1}>
                                                                                            </Grid>
                                                                                            <Grid item xs={2}>
                                                                                                <TextField
                                                                                                    onChange={(event) => updateItem(event.target.value, 'salesRate', 'description', jobIndex, rateIndex, salesRateIndex)} 
                                                                                                    style={{width: '80%'}}
                                                                                                    value={salesRate.description}
                                                                                                ></TextField>
                                                                                            </Grid>
                                                                                            <Grid item xs={2}>
                                                                                                <TextField
                                                                                                    onChange={(event) => updateItem(event.target.value,'salesRate', 'salesRate', jobIndex, rateIndex, salesRateIndex)} 
                                                                                                    style={{width: '80%'}}
                                                                                                    value={salesRate.salesRate}
                                                                                                ></TextField>
                                                                                            </Grid>
                                                                                            <Grid item xs={1}>
                                                                                                <TextField
                                                                                                    onChange={(event) => updateItem(event.target.value, 'salesRate', 'per', jobIndex, rateIndex, salesRateIndex)}  
                                                                                                    style={{width: '80%'}}
                                                                                                    value={salesRate.per}
                                                                                                ></TextField>
                                                                                            </Grid>
                                                                                            <Grid item xs={2}>
                                                                                                <TextField
                                                                                                    onChange={(event) => updateItem(event.target.value, 'salesRate', 'equipmentClass', jobIndex, rateIndex, salesRateIndex)}  
                                                                                                    style={{width: '80%'}}
                                                                                                    value={salesRate.equipmentClass}
                                                                                                ></TextField>
                                                                                            </Grid>
                                                                                            <Grid item xs={2}>
                                                                                                <TextField
                                                                                                    onChange={(event) => updateItem(event.target.value, 'salesRate', 'material', jobIndex, rateIndex, salesRateIndex)}  
                                                                                                    style={{width: '80%'}}
                                                                                                    value={salesRate.material}
                                                                                                ></TextField>
                                                                                            </Grid>
                                                                                            <Grid item xs={1}>
                                                                                                <Checkbox
                                                                                                    onChange={(event) => updateItem(event.target.checked, 'salesRate', 'active', jobIndex, rateIndex, salesRateIndex)}  
                                                                                                    style={{width: '80%'}}
                                                                                                    value={salesRate.active}
                                                                                                    checked={salesRate.active}
                                                                                                ></Checkbox>
                                                                                            </Grid>
                                                                                            <Grid item xs={1}>
                                                                                                <IconButton 
                                                                                                    onClick={(event) => deleteItem('salesRate', jobIndex, rateIndex, salesRateIndex)} 
                                                                                                    style={{float:'right', marginRight: '5px', padding: '1px'}}
                                                                                                >    
                                                                                                    <DeleteIcon />
                                                                                                </IconButton>
                                                                                            </Grid>
                                                                                        </Grid>
                                                                                    </Grid>
                                                                                )
                                                                            })}

                                                                            
                                                                        </Grid>

                                                                    }
                                                                        
                                          
                                                                </Grid>
                                                            )
                                                            })
                                                        }
                                                    </Grid>             
                                                    
                                                }

                                                <Divider style={{marginBottom: '10px'}}></Divider>
                                            </Grid>
                                        )
                                        })
                                    }
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Paper>
            <Snackbar
                open={!_.isEqual(currentCustomerJobData, saveState)}
                message={saving ? 'Saving...' : 'Save Document?'}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center'
                }}
                action={
                    <React.Fragment>
                        {saving
                            ? null
                            : <React.Fragment>
                                <Button variant='text' color='primary' onClick={() => cancelSave()} style={{ marginLeft: '32px', marginRight: '8px' }}>Cancel</Button>
                                <Button variant='contained' onClick={() => confirmSave()}>Confirm</Button>
                            </React.Fragment>}
                    </React.Fragment>
                } />
        </Grid> }
    </div>
  )
}

export default withRouter(CustomerRates);