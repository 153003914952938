import React, { useState, useCallback, useMemo } from 'react';
import { makeStyles } from '@material-ui/core';
import {  Grid, Box, Tab, Paper, AppBar, Tabs, IconButton, TextField, } from '@material-ui/core';
import { withRouter } from 'react-router-dom';
import _ from 'lodash';
import SpeakerNotesIcon from '@material-ui/icons/SpeakerNotes';
import AssignmentIcon from '@material-ui/icons/Assignment';
import ContactMailIcon from '@material-ui/icons/ContactMail';
import PrintIcon from '@material-ui/icons/Print';

import GrainOrderIdentifierValues from './GrianOrdersComponents/GrainOrderIdentifierValues';
import GrainOrdersPaymentValues from './GrianOrdersComponents/GrainOrdersPaymentValues';
//import GrainOrderEmailModal from './GrianOrdersComponents/GrainOrderEmailModal';
import GrainOrderLocationAutocompletes from './GrianOrdersComponents/GrainOrderLocationAutocompletes';
import GrainOrderEquipmentAutocompletes from './GrianOrdersComponents/GrainOrderEquipmentAutocompletes';
import GrainOrderPeopleAutocompletes from './GrianOrdersComponents/GrainOrderPeopleAutocompletes';

const useStyles = makeStyles((theme) => ({
    paper: {
        borderRadius: '0px',
        margin: '0px',
        marginBottom:'20px',
        width: '100%',
        //maxWidth: '1400px',
        //backgroundColor: 'rgb(252, 252, 249)',
        justifyContent: 'center',
        alignItems: 'center',
    },
    tabBar: {
        width: '100%',
        //maxWidth: '1400px',
        zIndex: '998'
    },
    loading: {
        display: 'flex',
        width: '100%',
        flexDirection: 'column',
        //maxWidth: '1400px',
        margin: 'auto',
        userSelect: 'none',
        pointerEvents: 'none',
        opacity: 0.5,
    },
    content: {
        display: 'flex',
        width: '100%',
        flexDirection: 'column',
        //maxWidth: '1400px',
        margin: 'auto',
    },
}));

const GrainOrdersCard = (props) => {
    const classes = useStyles();

    const [value, setValue] = useState(JSON.parse(sessionStorage.getItem('grainOrderTab'))|| 2);
    //const [modalOpen, setModalOpen] = useState(null)

    const TabPanel = useCallback((props) => {
        const { children, value, index, ...other } = props;
        return (
            <div
              role="tabpanel"
              hidden={value !== index}
              id={`simple-tabpanel-${index}`}
              aria-labelledby={`simple-tab-${index}`}
              {...other}
            >
              {value === index && (
                <Box style={{margin:'20px'}}>
                  {children}
                </Box>
              )}
            </div>
          );
    }, []);
    
    function a11yProps(index) {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        };
    }
  
    const handleChange = (event, newValue) => {
        setValue(newValue);
        sessionStorage.setItem("grainOrderTab", JSON.stringify(newValue))
    };
    
    // const handleSetModalOpen = useCallback((value) => {
    //     setModalOpen(value)
    // }, []);

    //returns true if it is unique
    const uniqueOrderNumberCheck = (inputValue) => {
        //console.log('check unique')

        if(_.isUndefined(props.globalGrainOrders.find(x=> x.orderNumber === inputValue)) || inputValue === '' ||
                (!_.isUndefined(props.globalGrainOrders.find(x=> x.orderNumber === inputValue)) && 
                props.globalGrainOrders.find(x=> x.orderNumber === inputValue).docId === props.currentGrainOrder.docId)){
            return true
        }
        else{ return false }
    }

    const uniqueValue = useMemo(() => 
        uniqueOrderNumberCheck(props.currentGrainOrder?.orderNumber), 
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [props.currentGrainOrder?.orderNumber]);

    return (
        <Grid className={props.currentGrainOrder ? classes.content : classes.loading }>
        
            {/* DISABLED FOR NOW UNTIL BETTER WAYS OF HANDLING DATA ARE SETUP */}
            {/* <GrainOrderEmailModal 
                emailModal={modalOpen}
                setEmailModal={handleSetModalOpen}
                users={props.users}
                //operator={props.currentGrainOrder?.operator}

                //rowDocIds   docId of current document in array
                rowDocIds={[props.currentGrainOrder.docId]}

                //globalGrainOrders     all up to date grain orders, find better way to hold this lmao
                globalGrainOrders={props.globalGrainOrders.splice(props.globalGrainOrders.findIndex(x=> x.docId === props.currentGrainOrder.docId), 1, props.currentGrainOrder)}

                //stopping this from being memoized propley, not a big deal
                handleSendOperatorInstructionsEmail={props.handleSendOperatorInstructionsEmail}
            />  */}

            <Paper className={classes.paper}>
                <Grid>
                    <AppBar className={classes.tabBar} position="static" elevation={0} >
                        <Tabs 
                            value={value} 
                            onChange={handleChange} 
                            centered={false} variant={"fullWidth"} 
                            scrollButtons={"on"} 
                            style={{margin:'0px'}}
                        >
                        <IconButton 
                            onClick={(e) => { props.handlePrintOrder() }} 
                            title="Print Instructions"
                        >
                            <PrintIcon />
                        </IconButton>

                        <IconButton 
                            //onClick={(e) => { setModalOpen('email') }} 
                            disabled={true}
                            //disabled={_.isUndefined(props.currentGrainOrder?.operator) || props.currentGrainOrder?.operator === ''} 
                            title="Email Instructions"
                        >
                            < ContactMailIcon/>
                        </IconButton>

                        <Tab label={<div><AssignmentIcon style={{verticalAlign: 'middle'}} /> Order Entry </div>} {...a11yProps(2)} />
                        <Tab label={<div><SpeakerNotesIcon style={{verticalAlign: 'middle'}} /> Operator Instructions </div>} {...a11yProps(3)} />
                        </Tabs>
                    </AppBar>
                </Grid>

                <TabPanel value={value} index={2}>
                    <Grid >
                        <GrainOrderIdentifierValues 
                            geeTeeOrderNumber={props.currentGrainOrder?.geeTeeOrderNumber}
                            orderNumber={props.currentGrainOrder?.orderNumber}
                            orderType={props.currentGrainOrder?.orderType}
                            dateCreated={props.currentGrainOrder?.dateCreated}
                            ticketDate={props.currentGrainOrder?.ticketDate}
                            orderStatus={props.currentGrainOrder?.orderStatus}

                            handleSetParentValues={props.handleSetCurrentGrainOrderCallback}

                            uniqueOrderNumberCheck={uniqueValue}

                            orderTypes={props.orderTypes}
                            statuses={props.statuses}
                            docId={props.currentGrainOrder?.docId}
                        />

                        <GrainOrderPeopleAutocompletes 
                            customer={props.currentGrainOrder?.customer}
                            operator={props.currentGrainOrder?.operator}
                            orderPriority={props.currentGrainOrder?.orderPriority}

                            handleSetParentValues={props.handleSetCurrentGrainOrderCallback}

                            loading={props.loading}
                            customers={props.customers}
                            userLoading={props.userLoading}
                            users={props.users}
                            units={props.units}
            
                            priorities={props.priorities}
                            docId={props.currentGrainOrder?.docId}
                        />

                        <GrainOrderEquipmentAutocompletes 
                            equipment={props.currentGrainOrder?.equipment}
                            trailer1={props.currentGrainOrder?.trailer1}
                            trailer2={props.currentGrainOrder?.trailer2}

                            handleSetParentValues={props.handleSetCurrentGrainOrderCallback}

                            users={props.users}
                            unitLoading={props.unitLoading}
                            units={props.units}

                            docId={props.currentGrainOrder?.docId}
                        />

                        <GrainOrderLocationAutocompletes 
                            origin={props.currentGrainOrder?.origin}
                            destination={props.currentGrainOrder?.destination}

                            handleSetParentValues={props.handleSetCurrentGrainOrderCallback}

                            places={props.places}
                            docId={props.currentGrainOrder?.docId}
                        />

                        < GrainOrdersPaymentValues 
                            material={props.currentGrainOrder?.material}
                            cartageSales={props.currentGrainOrder?.cartageSales} 
                            cartageSalesUnits={props.currentGrainOrder?.cartageSalesUnits}
                            ownerPer={props.currentGrainOrder?.ownerPer} 
                            cartageSalesTotal={props.currentGrainOrder?.cartageSalesTotal}
                            percentageFuelSurcharge={props.currentGrainOrder?.percentageFuelSurcharge}
                            dollarFuelSurcharge={props.currentGrainOrder?.dollarFuelSurcharge}
                            linkOperatorToSalesRate={props.currentGrainOrder?.linkOperatorToSalesRate} 
                            operatorBaseMultiplier={props.currentGrainOrder?.operatorBaseMultiplier}
                            operatorRate={props.currentGrainOrder?.operatorRate} 
                            adjustedBase={props.currentGrainOrder?.adjustedBase}
                            operatorUnits={props.currentGrainOrder?.operatorUnits}
                            operatorPer={props.currentGrainOrder?.operatorPer}
                            payroll={props.currentGrainOrder?.payroll} 

                            handleSetParentValues={props.handleSetCurrentGrainOrderCallback}

                            materials={props.materials}
                            per={props.per}
                            docId={props.currentGrainOrder?.docId}
                        />
                    </Grid>
                </TabPanel>

                <TabPanel value={value} index={3}>
                    <Grid >
                        <TextField
                            style={{marginTop: '10px',}}
                            variant='outlined'
                            fullWidth
                            multiline={true}
                            minRows={20}
                            placeholder='Type your instructions here...'
                            value={props.currentGrainOrder?.instructions}
                            onChange={(event) => {
                                props.handleSetCurrentGrainOrderCallback({instructions: event.target.value}, props.currentGrainOrder?.docId) 
                            }}
                        />
                    </Grid>
                </TabPanel>
            </Paper>
            
        </Grid>
    )
}

export default withRouter(GrainOrdersCard)