import React, { useEffect, useState } from 'react';
import { firestore } from '../../../../firebase/firebase'
import { withRouter } from 'react-router-dom';
import OrderSelectedPDF from '../../../components/Reports/Orders/OrderSelectedPDF';
import _ from 'lodash'
import { Skeleton } from '@material-ui/lab';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    content: {
        display: 'flex',
        width: '100%',
        padding: '32px',
        justifyContent: 'center',
        marginTop: '105px',
    },
}));

function OrderSelectedPrint(props) {
    const [orderData, setOrderData] = useState({});
    const [loading, setLoading] = useState([]);
    const [errorMessage, setErrorMessage] = useState("");

    const classes = useStyles();

    useEffect(() => {
        setLoading(true);

        let docIds = props.location.state.data.map(order => { return order.docId });

        firestore.collection('orders').get()
            .then(querySnapshot => {
                //process & store timesheet data
                const orderResponse = querySnapshot.docs.map((doc) => {
                    return {
                        ...doc.data(),
                        'docId': doc.id,
                    }
                })

                let filteredOrders = orderResponse.filter(order => docIds.includes(order.docId)).sort((a, b) => Number(a.order.orderID) > Number(b.order.orderID))

                if (_.isEmpty(filteredOrders)) return;

                setOrderData(filteredOrders);
                setLoading(false);
            })
            .catch(e => {
                setErrorMessage("Unable to Process");
                setLoading(false)
            });
            //eslint-disable-next-line
    }, [props.location.search]);

    return (
        loading
            ? 
            <div className={classes.content}>
                <Skeleton variant='rect' width={'63vw'} height={'80vh'} />
            </div>
            : 
            <OrderSelectedPDF
                orderData={orderData}
                errorMessage={errorMessage} />
    )
}

export default withRouter(OrderSelectedPrint)