import React, { useContext, useState } from "react"

const TimesheetTypeContext = React.createContext()

export function useTimesheetType() {
  return useContext(TimesheetTypeContext);
}

export function TimesheetTypeProvider({ children }) {

  const [timesheetType] = useState(['Office', 'Shop', 'Construction', 'Gravel', 'Grain', 'Snow']);

  const value = {
    timesheetType,
  }

  return (
    <TimesheetTypeContext.Provider value={value}>
      {children}
    </TimesheetTypeContext.Provider>
  )
}