import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import ReactHtmlParser from 'react-html-parser';
import { Button, FormControl, Grid, Paper, Typography, useMediaQuery } from '@material-ui/core'
import { Add, Remove } from '@material-ui/icons';
import classes from './ProductPreview.module.css'
import Input from '@material-ui/core/Input';
import IconButton from '@material-ui/core/IconButton';
import { useTheme } from '@material-ui/core/styles';
import AddShoppingCartIcon from '@material-ui/icons/AddShoppingCart';

const useStyles = makeStyles((theme) => ({
    MuiButton: {
        margin: '8px 0px',
    },

    productMain: {
        display: 'flex',
        flexDirection: 'column',
        minHeight: '400px',
        width: '100%',
        height: '100%',
    },

    smallCard: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        padding: theme.spacing(4),
    },

    gridCenter: {
        display: 'flex',
        justifyContent: 'center',
    },

    imgMissing: {
        width: '100%',
        height: '100%',
        border: '1px solid grey',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },

    imgSwatch: {
        position: 'absolute',
        bottom: '10%',
        right: '10%',
        height: '128px',
        width: '128px',
        objectFit: 'scale-down',
        borderRadius: '128px',
        border: '2px solid gray',

        [theme.breakpoints.down('sm')]: {
            position: 'unset',
            maxHeight: '50%',
            maxWidth: '50%',
            borderRadius: '0px',
            marginTop: '5px'
        },
    },

    paper: {
        width: '100%',
    },

    smallImg: {
        width: '100%',
        maxHeight: '200px',
        objectFit: 'contain',
    },
    expand: {
        transform: 'rotate(0deg)',
        marginLeft: 'auto',
        transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.shortest,
        }),
    },
    expandOpen: {
        transform: 'rotate(180deg)',
    },


}));

const ProductPreview = (props) => {
    const theme = useTheme();
    const smallScreen = useMediaQuery(theme.breakpoints.down('xs'));
    const MuiClasses = useStyles();
    useEffect(() => {

    }, [smallScreen]);
    const variantButtons = () => {
        let baseVTypes = {};
        let buttonArray = [];
        let buttonGroup = {};

        if (!props.loading && props.product.variantTypes[0] !== 'NOVARIANT') {

            //Get base variant types
            props.product.variantTypes.forEach((p) => {
                baseVTypes[p] = [];
            });

            //Place all variant types in their respective arrays
            props.product.variants.forEach((v) => {
                for (const [key, value] of Object.entries(v.variantTypes))
                    for (var i = 0; i < props.product.variantTypes.length; i++) {
                        if (key === props.product.variantTypes[i])
                            baseVTypes[key].push(value)
                    }
            });

            //Iterate over each property and create a <div> for each button group
            for (const property in baseVTypes) {
                baseVTypes[property] = Array.from(new Set(baseVTypes[property]));
                //(baseVTypes[property]).sort();
                buttonGroup[property] = (baseVTypes[property]).map((attr) => {
                    return (
                        <Grid item key={attr}>
                            <Button
                                style={{ height: '100%' }}
                                classes={{ root: MuiClasses.size }}
                                variant={props.product.variantTypes[property] === attr ? 'contained' : 'outlined'}
                                disableRipple
                                disableFocusRipple={true}
                                size={smallScreen ? "small" : "medium"}
                                disabled
                                fullWidth
                                color="default">
                                {attr}
                            </Button>
                        </Grid>)
                })
            }

            for (const baseV in buttonGroup) {
                if (buttonGroup[baseV].length > 0) {
                    buttonArray.push(
                        <div key={baseV} className={classes.TypeLabel}>
                            {baseV}
                        </div>
                    );
                    buttonArray.push(
                        <Grid container key={baseV + 'buttons'} spacing={1}>
                            {buttonGroup[baseV]}
                        </Grid>
                    );
                }
            }
        }
        return (
            <div>
                {buttonArray}
            </div>
        );
    };
   
    let shipButtons = props.product.shipOption.sort().map((option, index) => {
        return (
            <Grid item xs={smallScreen ? 12 : 6} key={index} >
                <Button
                    disabled
                    style={smallScreen ? { margin: '1px', borderRadius: '0px' } : null}
                    variant={option === "Delivery" ? 'contained' : 'outlined'}
                    fullWidth
                >
                    {option}
                </Button>
            </Grid>
        )
    })

    shipButtons = (
        <div>
            <div className={classes.TypeLabel}>
                Shipping Options
            </div>
            <Grid container spacing={1}>
                {shipButtons}
            </Grid>
        </div>
    )


    let image = <div className={classes.ProductImage}>
        <img src={props.product.photoURL} alt="product" style={{ objectFit: 'scale-down', width: '100%' }} />
        {props.product.swatchPhotoURL && <img src={props.product.swatchPhotoURL} alt="product" className={MuiClasses.imgSwatch} />}
    </div>
    const fullCard = (
        <Paper style={{ width: '100%' }}>
            <Grid container className={classes.Container}>
                <Grid item md={6} xs={12} className={classes.Column} style={{ flexBasis: 'auto' }}>

                    {props.product.photoURL
                        ? image
                        : <div className={MuiClasses.imgMissing}><Typography>Missing Image</Typography></div>
                    }

                </Grid>
                <Grid item md={6} xs={12} className={classes.Column} style={{ flexBasis: 'auto' }}>
                    {props.loading ? null :
                        <div className={classes.CardInfo}>

                            {smallScreen ?
                                <div style={props.product?.name.length <= 14 ? { fontSize: '1.2em', marginTop: '10px' } : { fontSize: '1em', marginTop: '10px' }}>
                                    {props.product?.name}
                                    {smallScreen ? props.price.price >= 0 && <Typography style={{ fontSize: '1.2em', fontWeight: 'bold' }}>${(props.product.price.toFixed(2))}</Typography> : null}




                                </div>
                                : <div className={classes.ProductName}>
                                    {props.product?.name}
                                </div>}

                            {smallScreen ?
                                <React.Fragment>

                                    <div className={classes.Description}>
                                        {ReactHtmlParser(props.product.description)}
                                    </div>




                                </React.Fragment>
                                :
                                <React.Fragment>
                                    <div className={classes.Description}>
                                        {ReactHtmlParser(props.product.description)}
                                    </div>
                                    <p style={{ color: 'grey' }}>Actual material may vary from product photos.</p>




                                    {props.product.price >= 0 && <Typography variant="h4">${(props.product.price.toFixed(2))}</Typography>}
                                </React.Fragment>}
                            {props.product.price <= 0
                                ? <div className={classes.Price}>Call for Price</div>
                                : <Grid
                                    variant='outlined'
                                >
                                    {props.product.price
                                        &&
                                        <Button value={props.product.unit} size={smallScreen ? "small" : "medium"}
                                            variant={props.product.unit ? 'contained' : 'outlined'}
                                            disabled
                                            className={MuiClasses.MuiButton} style={{ marginRight: '1%' }}>{props.product.unit}</Button>}

                                    {props.product.priceList
                                        && props.product.priceList.map((price, index) => {
                                            return <Button
                                                value={price.unit}
                                                size={smallScreen ? "small" : "medium"}
                                                disabled
                                                variant={props.product.unit === price.unit ? 'contained' : 'outlined'}
                                                className={MuiClasses.MuiButton} style={{ marginRight: '1%' }} key={index}>
                                                {price.unit}
                                            </Button>
                                            //<MenuItem value={price.unit} key={index} >
                                            //$ {price.price}/{price.unit} ({price.qty})
                                            //</MenuItem>

                                        })}
                                </Grid>
                            }

                            {props.product.price > 0}
                            {variantButtons()}

                            {props.product.price <= 0 ? null :
                                <form className={classes.CardInfo}>
                                    {shipButtons}
                                    {'shippingClass' in props.product && props.product.shippingClass === 'brick' ?
                                        <Typography style={{ color: '#ff3333' }}>*Please allow a few days for the order to process. We will advise you of the day for {props.shippingMethod}.</Typography>
                                        : null}
                                    {!smallScreen ?
                                        <React.Fragment>
                                            <div style={{ display: 'flex', alignItems: 'center', paddingTop: '32px' }}>
                                                <Typography variant='h5'>Quantity </Typography>
                                                <FormControl>
                                                    <Input
                                                        id="qtyInput"
                                                        className={classes.QtyInput}
                                                        variant='outlined'
                                                        value="1.0"
                                                        disabled
                                                        endAdornment={
                                                            <IconButton disabled>
                                                                <Add />
                                                            </IconButton>}
                                                        startAdornment={
                                                            <IconButton disabled>
                                                                <Remove />
                                                            </IconButton>
                                                        }
                                                    />
                                                </FormControl>
                                            </div>
                                        </React.Fragment>
                                        : <React.Fragment>
                                            <div style={{ display: 'flex', marginTop: '12px', marginBottom: '8px', alignItems: 'center', justifyContent: 'space-evenly' }}>
                                                <Typography>Quantity </Typography>
                                                <FormControl>
                                                    <Input
                                                        id="qtyInput"
                                                        style={{ fontSize: '16px', textAlign: 'center', width: '120px' }}
                                                        endAdornment={
                                                            <Button variant="outlined"
                                                                disabled
                                                                style={{ minWidth: '10px', height: '45px', fontSize: '12px', textAlign: 'center', marginBottom: '4px', borderRadius: '0' }}>
                                                                <Add style={{ fontSize: '16px' }} />
                                                            </Button>
                                                        }
                                                        startAdornment={
                                                            <Button variant="outlined"
                                                                disabled
                                                                style={{ minWidth: '10px', height: '45px', fontSize: '12px', textAlign: 'center', marginBottom: '4px', marginRight: '10px', borderRadius: '0' }}>
                                                                <Remove style={{ fontSize: '16px' }} />
                                                            </Button>
                                                        }
                                                    />
                                                </FormControl>

                                            </div>
                                        </React.Fragment>}
                                    <Button
                                        style={{ marginBottom: '16px' }}
                                        type="submit"
                                        className={MuiClasses.MuiButton}
                                        disabled
                                        variant='contained'
                                        startIcon={props.product.inStock ? <AddShoppingCartIcon /> : null}>
                                        {props.product.inStock ? 'Add To Cart' : 'Out of Stock'}
                                    </Button>
                                </form>}
                        </div>
                    }
                </Grid>
            </Grid >

        </Paper >
    );

    return (
        <div className={MuiClasses.productMain}>
            {fullCard}
        </div>
    );
};

export default ProductPreview;

