import React, { useState } from 'react';
//import { firestore } from '../../../../firebase/firebase'
import { withRouter } from 'react-router';
import { CircularProgress, MenuItem } from '@material-ui/core';
import { Grid, Button, Typography, Select} from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import 'date-fns';
import { Autocomplete } from '@material-ui/lab';
import _ from 'lodash';
import AddBoxIcon from '@material-ui/icons/AddBox';

const AddProduct = (props) => {
    const [openProductAutoComplete, setOpenProductAutoComplete] = useState(false)
    const [openVariantAutoComplete, setOpenVariantAutoComplete] = useState(false)
    const [productAutoComplete, setProductAutoComplete] = useState('')
    const [variantAutoComplete, setVariantAutoComplete] = useState('')

    const [variantData, setVariantData] = useState()
    const [variantNames, setVariantNames] = useState()

    const defaultNewProduct = ({
        qty: '',  
        shippingMethod: props.type,

        product: {
            product: '',     //id
            productName: '', //name
            tableData: {},
        },

        variant: { 
        },

        price :{ 
            price: '',
            unit: '',
            qty: '',
        },
    })
    const [newProduct, setNewProduct] = useState(defaultNewProduct)

    const handleProductSelection = (productName) => {
        setProductAutoComplete(productName); 
        setVariantAutoComplete('');

        //generate/initialize data needed for function
        let variantDataContainer = []
        let variantNameContainer = []
        let cartDetails = props.products.filter(x=> x.name === productName)
        //check if product was found and that only one has been found 
        cartDetails.length > 1 && console.warn('Multiple products with the same name returned, using the first.')
        let productIndex = props.products.indexOf(cartDetails[0])

        //set newProduct values
        let newProductContainer = newProduct
        newProductContainer = {...newProductContainer, product: cartDetails[0], price: {price: '', unit: '', qty: ''}, qty: ''}
        setNewProduct(newProductContainer)

        //populate variantData
        props.products[productIndex]?.variants.map((variant) =>{
            return variantDataContainer = [...variantDataContainer, variant]
        })
        setVariantData([...variantDataContainer])

        //populate variantNames
        variantDataContainer.map((variant) => {
            let variantTypesValues = (Object.values(variant.variantTypes).sort())

            let stringContainer = {'name': variantTypesValues.join(' '),
                                    'id': variant.id ,
                                  }
            return variantNameContainer = [...variantNameContainer, stringContainer]
        })
        setVariantNames([...variantNameContainer])
    }
    
    const handleVariantSelection = (variantName) => {
        //return variant names in array
        let varianNamesContainer = [];
        variantNames.map((variant) =>{
            return varianNamesContainer = [...varianNamesContainer, variant.name];
        })

        //bandaid for when clicking away without choosing new variant autocomplete fills previous value (sometimes not part of possible variants)
        if(varianNamesContainer.includes(variantName)){
            setVariantAutoComplete(variantName); 
            //returns array of variant data ?
            let variant = (( variantName !== "" && variantData && variantNames) 
            ? variantData?.filter(variant=> variant.id === variantNames?.filter(variant=> variant.name === variantName)[0].id) 
            : [])

            if(variant.length > 1){
                console.warn("Multiple variants of the same name returned.")
            }
            else if(variant.length === 0){
                console.warn("No variants returned.")
            }
            else if(variant.length === 1) {   
                let newProductContainer = newProduct
                //handles default unit & price
                newProductContainer = {...newProductContainer, price: {unit: variant[0].unit, price: variant[0].price, qty: 1}, variant: variant[0] }
                setNewProduct(newProductContainer) 
            }
        }
        else if(!varianNamesContainer.includes(variantName)){
            setVariantAutoComplete(""); 
            let productContainer = newProduct
            productContainer = {...productContainer, price: {unit: '', price: '', qty: ''}, qty: ''}
            setNewProduct(productContainer) 
        }
    }

    const handleUnitSelect = (event) => {
        //if unit is === as newProduct.variant.unit
        if(event.target.value === newProduct.variant.unit){
            let priceContainer = newProduct.price;
            priceContainer = {...priceContainer, unit: event.target.value, price: newProduct.variant.price, qty: 1}
            setNewProduct({...newProduct, price: priceContainer}) 
        }

        //else if unit is not === newProduct.variant.unit
        else if(event.target.value !== newProduct.variant.unit){
            let priceContainer = newProduct.price;
            
            newProduct.variant?.priceList?.map((priceList, index) => {
                if(event.target.value === priceList.unit){
                    priceContainer = {...priceContainer, unit: priceList.unit, price: priceList.price, qty: priceList.qty ? priceList.qty : 1 }
                    setNewProduct({...newProduct, price: priceContainer}) 
                    return priceContainer
                }
                else{
                    return priceContainer
                }
            })
        }
    }

    const handleAddProducts = () => {
        if(props.cartDetails.length === 0){
            props.setCartDetails([...props.cartDetails, newProduct]) 
        }
        else{
            let item = props.cartDetails.find(x =>
                x.product.docId === newProduct.product.docId &&
                x.variant.id === newProduct.variant.id &&
                x.shippingMethod === newProduct.shippingMethod &&
                x.price.unit === newProduct.price.unit)

            if (item) {
                let itemIndex = props.cartDetails.findIndex(x =>
                    x.product.docId === newProduct.product.docId &&
                    x.variant.id === newProduct.variant.id &&
                    x.shippingMethod === newProduct.shippingMethod &&
                    x.price.unit === newProduct.price.unit)
        
                item.qty = parseFloat(item.qty) + parseFloat(newProduct.qty);
                let cartDetailsContainer = _.cloneDeep(props.cartDetails);
                cartDetailsContainer[itemIndex] = item;
                props.setCartDetails([...cartDetailsContainer])
            }
            else {
                props.setCartDetails([...props.cartDetails, newProduct])
            }
        }

        //if add to cart was passed update redux cart
        if(!_.isUndefined(props.addToCart)){
            let container;
            if(newProduct.variant.inStock === false){
                                container = newProduct.variant;
                                container = {...container, inStock: true};
            }

            props.addToCart(newProduct.product, 
                            newProduct.variant.inStock === false ? container : newProduct.variant,
                            newProduct.qty, newProduct.shippingMethod, newProduct.price);
        }

        //reset component
        setVariantAutoComplete(""); 
        setProductAutoComplete("");
        setNewProduct(defaultNewProduct)
    }

    return(
        <Grid >
            <Grid style={window.innerWidth > 400 ? { display: 'flex', justifyContent: 'space-between',} : { } }>
                <Grid style={{ margin: '10px'}}>
                    <Autocomplete
                        open={openProductAutoComplete}
                        onOpen={() => {
                            setOpenProductAutoComplete(true);
                        }}
                        onClose={() => {
                            setOpenProductAutoComplete(false);
                        }}
                        loading={props.loading}
                        label="Search"
                        id="productAutoComplete"
                        style={{ width: '250px'}}
                        inputValue={productAutoComplete}
                        onInputChange={(event, newValue) => {
                                handleProductSelection(newValue); 
                        }}
                        value={props.products?.name}
                        options={props.products.map((product) => product.name ?? '').sort((a, b) => a.toLowerCase() > b.toLowerCase())}
                        getOptionSelected={(option, value) => option?.name === value?.name}
                        getOptionLabel={(option) => option}
                        clearOnBlur={true}
                        clearOnEscape={true}
                        fullWidth
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                //style={window.innerWidth < 400 ? {width: '100px'} : { width: '200px'}}
                                fullWidth
                                label="Product"
                                variant="outlined"
                                size='small'
                                InputProps={{
                                    ...params.InputProps,
                                    endAdornment: (
                                        <React.Fragment>
                                            {props.loading ? <CircularProgress color="inherit" size={20} /> : null}
                                            {params.InputProps.endAdornment}
                                        </React.Fragment>
                                    ),
                                }}
                            />
                        )}
                    />
                </Grid>

                <Grid style={{ margin: '10px'}}>
                    <Autocomplete
                        open={openVariantAutoComplete}
                        onOpen={() => {
                            setOpenVariantAutoComplete(true);
                        }}
                        onClose={() => {
                            setOpenVariantAutoComplete(false);
                        }}
                        label="Search"
                        id="variantAutoComplete"
                        style={{ width: '250px'}}
                        disabled={_.isUndefined(variantData) || productAutoComplete === ""}
                        inputValue={variantAutoComplete}
                        onInputChange={(event, newValue) => {
                            handleVariantSelection(newValue)
                        }}
                        value={variantNames?.name}
                        options={variantNames ? variantNames.map((variant) => variant.name ?? '').sort((a, b) => a.toLowerCase() > b.toLowerCase()) : [] }
                        getOptionSelected={(option, value) => option?.name === value?.name}
                        getOptionLabel={(option) => option}
                        clearOnBlur={true}
                        clearOnEscape={true}
                        fullWidth
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                //style={window.innerWidth < 400 ? {width: '100px'} : { width: '250px'}}
                                label="Variant"
                                variant="outlined"
                                size='small'
                                InputProps={{
                                    ...params.InputProps,
                                    endAdornment: (
                                        <React.Fragment>
                                            {props.loading ? <CircularProgress color="inherit" size={20} /> : null}
                                            {params.InputProps.endAdornment}
                                        </React.Fragment>
                                    ),
                                }}

                            />
                         )}
                        renderOption={(option, state) =>( 
                            option + variantData.filter(variant => Object.values(variant.variantTypes).sort().join(' ') === option).map((value) => {
                               return !value.inStock ? "(Out of Stock)" : "" 
                            })
                        )}
                    />
                </Grid>            
            </Grid>

            <Grid style={window.innerWidth < 400 ? {margin: '10px'} : { display: 'flex', justifyContent: 'space-around', margin: '10px'}}>
                <Grid style={{ display: 'flex', justifyContent: 'space-around', width: '150px'}}>
                    <Typography style={{lineHeight: 3}}>
                        Unit: &nbsp;
                    </Typography>
                    <Select
                        value={newProduct.price.unit}  
                        disabled={productAutoComplete !== "" && variantAutoComplete !== "" ? false : true}
                        style={{width: '100px', marginBottom: '10px'}}
                        onChange={(event) => { 
                            handleUnitSelect(event)
                        }}
                    >
                        {/* default unit */}
                        <MenuItem value={newProduct.variant?.unit} > {newProduct.variant?.unit} </MenuItem> 

                        {/* added units per priceList */}
                        {newProduct.variant?.priceList?.map((priceList, index) => {
                            return <MenuItem key={index} value={priceList.unit}> {priceList.unit} </MenuItem> 
                            })
                        }   
                    </Select>
                </Grid>
                <Grid style={{width: '140px'}}>
                    <Typography style={{lineHeight: 3}}>
                        Price: &nbsp; 
                        { //newProduct.price?.price === -1 
                           // ? "NA"
                           // : 
                            (productAutoComplete !== "" && variantAutoComplete !== "" && newProduct.price?.price) ? "$" + newProduct.price?.price : "$0.00"
                        }
                    </Typography>
                </Grid>
                <Grid style={{width: '150px'}}>
                    <input
                        style={{lineHeight: 2, position: 'relative', width: '150px'}}
                        type={'number'}
                        disabled={productAutoComplete !== "" && variantAutoComplete !== "" ? false : true}
                        step={newProduct.price?.unit?.toLowerCase() === "bag" || newProduct.price?.unit?.toLowerCase() === "per bag" || newProduct.price?.unit?.toLowerCase() === "perbag" 
                            || newProduct.price?.unit?.toLowerCase() === "perbag " || newProduct.price?.unit?.toLowerCase() === "per bag " ? 1 : newProduct.product?.qtyStep}
                        placeholder={'Quantity'}
                        value={newProduct.qty}
                        onChange={(event) =>{
                            let newProductContainer = newProduct
                            newProductContainer = {...newProductContainer, qty: event.target.value}
                            setNewProduct(newProductContainer) 
                        }}
                    >
                    </input>
                    <Typography>qty step: {newProduct.price?.unit?.toLowerCase() === "bag" || newProduct.price?.unit?.toLowerCase() === "per bag" || newProduct.price?.unit?.toLowerCase() === "perbag" 
                            || newProduct.price?.unit?.toLowerCase() === "perbag " || newProduct.price?.unit?.toLowerCase() === "per bag " ? 1 : newProduct.product?.qtyStep}</Typography>
                </Grid>
                <Grid >
                    <Typography
                     style={{lineHeight: 3, width: '140px'}}
                    >
                        Total: &nbsp; ${(productAutoComplete !== "" && variantAutoComplete !== "" && newProduct.price?.price && newProduct.qty) 
                                ? (newProduct.price?.price * newProduct.qty).toFixed(2)  : "0.00"}
                    </Typography>
                </Grid>
            </Grid>

            <Grid style={{ display: 'flex', justifyContent: 'flex-end', margin: '10px'}}>
                <Grid>
                    <Button variant={'contained'} style={{float: 'right', }} 
                        startIcon={<AddBoxIcon />}
                        disabled={(newProduct.variant?.id === "" || isNaN(newProduct.qty) || newProduct.qty <= 0 || newProduct.qty % newProduct.product?.qtyStep !== 0 ) ? true : false }
                        onClick={() => { 
                            handleAddProducts()
                        }}
                        >Add</Button>
                </Grid>
            </Grid>

        </Grid>

    )
}
export default withRouter(AddProduct);