import * as actionTypes from './actionTypes';
import { functions } from '../../firebase/firebase';

export const getCheckout = () => {
    return {
        type: actionTypes.GET_CHECKOUT,
    }
}

export const updateCheckoutForm = (newForm) => {
    return {
        type: actionTypes.UPDATE_CHECKOUT,
        checkoutForm: newForm,
    }
}

export const validateCheckout = () => {
    return {
        type: actionTypes.VALIDATE_CHECKOUT,
    }
}

export const beginPayProcessing = () => {
    return {
        type: actionTypes.BEGIN_PAYMENT,
        processingPayment: true,
    }
}

export const completePayProcessing = (paymentComplete) => {
    return {
        type: actionTypes.COMPLETE_PAYMENT,
        processingPayment: false,
        paymentComplete: paymentComplete,
    }
}

export const resetCheckoutForm = () => {
    return {
        type: actionTypes.RESET_CHECKOUTFORM,
    }
}


export const setNextAvailableDeliverDate = () => {
    return {
        type: actionTypes.SET_NEXT_AVAILABLE_DELIVERDATE,
    }
}

export const setPaymentFailed = (didPaymentFail) => {
    return {
        type: actionTypes.PAYMENT_FAILED,
        didPaymentFail: didPaymentFail,
    }
}

export const resetCheckout = () => {
    return {
        type: actionTypes.RESET_CHECKOUT,
    }
}

export const generateToken = (amount) => {

    const getToken = functions.httpsCallable('convergeSendPayment');

    return dispatch => {

        dispatch(setDisabled(true))

        getToken({
            sandbox: process.env.NODE_ENV === 'development',
            amount: amount,
        }).then((res) => {
            dispatch(setDisabled(false))
            dispatch(setToken(res.data.message, false))
        }).catch((err) => {
            dispatch(setDisabled(false))
            dispatch(setToken("", true))
        })

        return {
            type: actionTypes.GENERATE_TOKEN
        }
    }
}

export const setDisabled = (val) => {
    return {
        type: actionTypes.SET_DISABLED,
        generateDisabled: val
    }
}

export const setToken = (token, error) => {
    return {
        type: actionTypes.SET_TOKEN,
        token: token,
        error: error
    }
}

export const createPayment = (nonce, buyerVerificationToken, customer, order) => {
    return dispatch => {

        dispatch(beginPayProcessing());
        dispatch(setPaymentFailed(false));

        const sendPayment = functions.httpsCallable('squareSendPayment');

        sendPayment({
            nonce: nonce,
            buyerVerificationToken: buyerVerificationToken,
            customer: customer,
            order: order,
            sandbox: process.env.NODE_ENV === 'development',
        })
            .then(function (result) {
                //const msg = result.data.message;
            })
            .then(() => {
                dispatch(completePayProcessing(true))

            }).catch(function (error) {
                console.log(error.code);
                console.log(error.message);
                console.log(error.details);
                dispatch(completePayProcessing(false));
                dispatch(setPaymentFailed(true));
            });

        return {
            type: actionTypes.CREATE_PAYMENT,
            formSubmitted: true,
        }
    }
}

export const createPaymentConverge = (payment, customer, order) => {

    return dispatch => {
        dispatch(beginPayProcessing());
        dispatch(setPaymentFailed(false));

        const convergeCreateOrder = functions.httpsCallable('convergeCreateOrder');

        convergeCreateOrder({
            payment: payment,
            customer: customer,
            order: order,
            sandbox: process.env.NODE_ENV === 'development',
        })
            .then(function (result) {
                //const msg = result.data.message;
            })
            .then(() => {
                dispatch(completePayProcessing(true))

            }).catch(function (error) {
                console.log(error.code);
                console.log(error.message);
                console.log(error.details);
                dispatch(completePayProcessing(false));
                dispatch(setPaymentFailed(true));
            });

        return {
            type: actionTypes.CREATE_PAYMENT,
            formSubmitted: true,
        }
    }
}