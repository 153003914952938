import React, { memo } from 'react';
import { makeStyles } from '@material-ui/core';
import { Grid, Typography, useTheme, TextField, useMediaQuery, Select, MenuItem, FormControl, InputLabel,} from '@material-ui/core';
import moment from 'moment';
import DatePicker from '../../UI/DatePicker/DatePicker';

const useStyles = makeStyles((theme) => ({
    container: {
        marginBottom: '10px',
        padding: '15px'
    },
    textRow: {
        lineHeight: 2,
        marginBottom: '8px',
    },
    textRowTypography: {
        marginTop: '4px',
        lineHeight: 2,
        marginBottom: '8px',
    },
    selectRow: {
        padding: '5px',
    },
}));

const GrainOrderIdentifierValues = ({ handleSetParentValues, uniqueOrderNumberCheck, orderTypes, statuses, geeTeeOrderNumber, orderNumber, orderType, dateCreated, ticketDate, orderStatus, docId,}) => {

    const classes = useStyles();
    const theme = useTheme();
    const smallScreen = useMediaQuery(theme.breakpoints.down('sm'));

    return (
        <>
            <Grid container className={classes.container}>
                <Grid container>
                    <Grid item md={4} xs={12} >
                        <Grid container >
                            <Typography style={{fontWeight: 600}} className={classes.textRowTypography}>GeeTee Order #: &nbsp;</Typography>
                            <Typography className={classes.textRowTypography}>{geeTeeOrderNumber}</Typography>
                        </Grid>
                    </Grid>
            
                    <Grid item md={4} xs={12} >
                        <Grid container >
                            <Typography style={{fontWeight: 600}} className={classes.textRowTypography}>Order #: &nbsp;</Typography>
                            <TextField 
                                className={classes.textRow}
                                value={orderNumber}
                                label={!uniqueOrderNumberCheck ? 'Must be a unique number' : null}
                                error={!uniqueOrderNumberCheck}
                                onChange={(event) => {
                                    handleSetParentValues({orderNumber: event.target.value}, docId)

                                    //handleSetCurrentGrainOrder({...currentGrainOrder, orderNumber: event.target.value})
                                }}
                            />
                            
                        </Grid>
                    </Grid>

                    <Grid item md={4} xs={12} >
                        <Grid container justifyContent={ smallScreen ? 'flex-start' : 'flex-end' }>
                            <Typography style={{fontWeight: 600}} className={classes.textRowTypography}>Order Type: &nbsp;</Typography>
                                
                            <Select
                                style={{minWidth: '200px', marginBottom: '15px'}}
                                value={orderType || ''}
                                onChange={(event) => {
                                    handleSetParentValues({orderType: event.target.value}, docId)

                                    //handleSetCurrentGrainOrder({...currentGrainOrder, orderType: event.target.value}) 
                                }}
                            >
                                {orderTypes.map((type) => {
                                    return (
                                        <MenuItem key={type} value={type}>{type.charAt(0).toUpperCase() + type.slice(1)}</MenuItem>
                                    )
                                })}
                                
                            </Select>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>

            <Grid container className={classes.container}>
                <Grid item xs={4}>
                    <Typography><small>Data Entry Date</small></Typography>
                    <Typography>{moment(dateCreated).format('YYYY/MM/DD h:mm:ss a')}</Typography>
                </Grid>

                <Grid item xs={4}>
                    <DatePicker
                        label="Ticket Date"
                        value={ticketDate}
                        onChange={(date)=> { 
                            handleSetParentValues({ticketDate: moment(date).valueOf()}, docId)

                            //handleSetCurrentGrainOrder({...currentGrainOrder, ticketDate: moment(date).valueOf()}) 
                        }}
                    />
                </Grid>

                <Grid item xs={4}>
                    <FormControl className={classes.selectRow} style={{marginTop: '3px'}} size='small' fullWidth variant='outlined'>
                    <InputLabel className={classes.selectRow} >Order Status</InputLabel>
                        <Select
                            value={orderStatus || ''}
                            label="Order Status"
                            onChange={(event) => {
                                handleSetParentValues({orderStatus: event.target.value}, docId)

                                //handleSetCurrentGrainOrder({...currentGrainOrder, orderStatus: event.target.value})
                            }}
                        >
                            {statuses.map((status, index) => {
                                return <MenuItem key={'status' + index} value={status}>{status.charAt(0).toUpperCase() + status.slice(1)} Order</MenuItem>
                            })}
                        </Select>
                    </FormControl>
                </Grid>
            </Grid>

        </>

    )
}

export default memo(GrainOrderIdentifierValues)