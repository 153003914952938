import React from 'react';
import { Button, Divider, Drawer, Grid, IconButton, makeStyles, MenuItem, Select, TextField, Typography } from '@material-ui/core';
import { PDFViewer, Font, Page, Text, View, Document, StyleSheet } from '@react-pdf/renderer';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import DeleteIcon from '@material-ui/icons/Delete';
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import Calibri from '../../../fonts/Calibri/calibri.ttf'
import CalibriBold from '../../../fonts/Calibri/calibrib.ttf'
import Brush from '../../../fonts/BRUSHSCI.TTF'

const useStyles = makeStyles((theme) => ({

    root: {
        display: 'flex',
        justifyContent: 'center',
        width: '100%',
    },

    content: {
        display: 'flex',
        alignItems: 'center',
        margin: theme.spacing(2),
        flexGrow: 1,
        width: '100%',
    },

    drawer: {
        width: '350px',
        flexShrink: 0,
    },

    drawerContent: {
        padding: theme.spacing(1),
        marginTop: '64px',
    },

    drawerPaper: {
        width: '350px',
    },

    buttonRow: {
        margin: theme.spacing(1),
    },

    checkRow: {
        margin: theme.spacing(1),
        display: 'flex',
        justifyContent: 'space-evenly',
    },

    newComponent: {
        borderRadius: '8px',
        border: '2px solid #e6e6e6',
        margin: theme.spacing(1),
        padding: theme.spacing(1),
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
    },

    section: {
        backgroundColor: '#e6e6e6',
        display: 'flex',
        alignItems: 'center',
    },

    viewerContainer: {
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        flexGrow: 1,
        width: '100%',
    },
}));

Font.register({
    family: 'Calibri', fonts: [
        { src: Calibri },
        { src: CalibriBold, fontWeight: 700 },
    ]
});
Font.register({ family: 'Brush', src: Brush });

// Create styles
const styles = StyleSheet.create({
    page: {
        fontFamily: 'Calibri',
        flexDirection: 'column',
        padding: 40,
    },

    content: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
    },

    section: {
        border: '1 dotted grey',
        flexDirection: 'row',
        flexWrap: 'wrap',
        marginBottom: 10,
        minHeight: 14,
        padding: 8,
        fontSize: '12pt',
    },

    dTable: {
        display: "table",
        width: "auto",
        border: '1 solid black',

        marginBottom: 10,
    },

    dTableRow: {
        margin: "auto",
        flexDirection: "row"
    },

    dTableCol: {
        flexGrow: 1,
    },

    dTableCell: {
        margin: "auto",
        marginTop: 5,
        marginBottom: 5,
        fontSize: 10,
    },

    input: {
        borderBottom: '1 solid black',
        flexGrow: 1,
        minWidth: 30,
        minHeight: 12,
        marginBottom: 2,
        paddingLeft: 2,
        paddingRight: 2,
    },

    table: {
        border: '1 solid black',
        marginBottom: 10,
        padding: 10,
        flexGrow: 1,
        fontSize: 14,
        flexDirection: 'column',
        justifyContent: 'space-between',
    },

    tableRow: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginTop: 2,
        marginBottom: 2,
    },

    tableText: {
        fontSize: 12,
    },

    textHeader: {
        textAlign: 'center',
        fontFamily: 'Brush',
        fontSize: '26pt',
    },

    textTitle: {
        textAlign: 'center',
        fontFamily: 'Calibri',
        fontWeight: 700,
        fontSize: '14pt',
        margin: 20,
    },

    summary: {
        flexDirection: 'row',
    },

    viewer: {
        width: '100%',
        maxWidth: '90%',
        height: '90vh',
    },
});

const FormBuilder = (props) => {

    const classes = useStyles();

    const [open, setOpen] = React.useState([])

    const emptySection = {
        components: [],
        width: '100%',
    }

    const addSection = () => {
        let newSection = [...props.form.sections];
        newSection.push(emptySection);
        props.updateForm({ ...props.form, 'sections': newSection });

        let newOpenSections = [...open];
        newOpenSections.push(true);
        setOpen(newOpenSections);
    }

    const removeSection = (index) => {
        let sections = [...props.form.sections];
        sections.splice(index, 1);
        props.updateForm({ ...props.form, 'sections': sections });

        let remOpenSections = [...open];
        remOpenSections.splice(index, 1);
        setOpen(remOpenSections);
    }

    const setSectionOpen = (index) => {
        let openSections = [...open];
        openSections[index] = !openSections[index];
        setOpen(openSections);
    }

    const renderComponent = (component) => {

        let element = null;

        switch (component.type) {
            case 'text':
                element = <Text>{component.value}</Text>
                break;
            case 'input':
                element =
                    <React.Fragment>
                        <View>
                            <Text>{component.label}</Text>
                        </View>
                        <View style={styles.input}>
                            <Text>{component.value}</Text>
                        </View>
                    </React.Fragment>
                break;
            default:
                break;
        }

        return element;
    }

    const createComponent = (component, sectionIndex, componentIndex) => {

        let element = null;

        switch (component.type) {
            case 'text':
                element = <TextField
                    label="Enter your text here"
                    variant='outlined'
                    fullWidth size='small'
                    multiline
                    rows={5}
                    value={props.form.sections[sectionIndex].components[componentIndex].value}
                    onChange={(event) => {
                        let sections = [...props.form.sections];
                        sections[sectionIndex].components[componentIndex].value = event.target.value;
                        props.updateForm({ ...props.form, 'sections': sections });
                    }} />
                break;
            case 'input':
                element = <TextField
                    label="Input Label"
                    fullWidth size='small'
                    value={props.form.sections[sectionIndex].components[componentIndex].label}
                    onChange={(event) => {
                        let sections = [...props.form.sections];
                        sections[sectionIndex].components[componentIndex].label = event.target.value;
                        props.updateForm({ ...props.form, 'sections': sections });
                    }} />
                break;
            default:
                break;
        }

        return element;
    }

    return (
        <div className={classes.root}>
            <div className={classes.content}>
                <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
                    <PDFViewer style={styles.viewer}>
                        <Document>
                            <Page size="A4" style={styles.page} wrap>
                                <View>
                                    <Text style={styles.textHeader}>Gee Tee Holdings Inc.</Text>
                                    <Text style={styles.textTitle}>{props.title}</Text>
                                </View>
                                <View style={styles.content}>
                                    {props.form.sections.map((section, index) => {
                                        return (
                                            <View style={{ ...styles.section, width: section.width }}>
                                                {section.components.map((component, index2) => {
                                                    return renderComponent(component);
                                                })}
                                            </View>
                                        )
                                    })}
                                </View>
                            </Page>
                        </Document>
                    </PDFViewer>
                </div>

                <Drawer
                    className={classes.drawer}
                    classes={{ paper: classes.drawerPaper }}
                    anchor='right'
                    variant='permanent'>
                    <div className={classes.drawerContent}>
                        <Grid container spacing={2} style={{ display: 'flex', justifyContent: 'center' }}>
                            <Grid item xs={12}>
                                <Typography variant='h6'>Form Builder</Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography>Title</Typography>
                                <TextField
                                    multiline
                                    variant='outlined'
                                    fullWidth size='small'
                                    onBlurCapture={(event) => props.updateTitle(event.target.value)}
                                />
                            </Grid>

                            <Grid item xs={12}>
                                <Divider />
                            </Grid>

                            {props.form.sections.map((section, index) => {
                                return (
                                    <React.Fragment key={index}>
                                        <Grid item xs={10} className={classes.section} style={{ display: 'flex' }}>
                                            <IconButton size='small' onClick={() => setSectionOpen(index)}>
                                                {open[index] ? <KeyboardArrowDownIcon /> : <KeyboardArrowRightIcon />}
                                            </IconButton>
                                            <Typography>Section {index + 1}</Typography>
                                        </Grid>
                                        <Grid item xs={2} className={classes.section} style={{ display: 'flex', alignItems: 'center' }}>
                                            <IconButton size='small' onClick={(event) => { removeSection(index) }}>
                                                <DeleteIcon />
                                            </IconButton>
                                        </Grid>

                                        {open[index]
                                            ? <React.Fragment>
                                                <Grid item xs={8}>
                                                    <Typography>Width</Typography>
                                                </Grid>
                                                <Grid item xs={4} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                                    <Select
                                                        fullWidth
                                                        value={section.width}
                                                        onChange={(event) => {
                                                            let sections = [...props.form.sections];
                                                            sections[index].width = event.target.value;
                                                            props.updateForm({ ...props.form, 'sections': sections })
                                                        }}>
                                                        <MenuItem value={'25%'}>1/4</MenuItem>
                                                        <MenuItem value={'33.33%'}>1/3</MenuItem>
                                                        <MenuItem value={'50%'}>1/2</MenuItem>
                                                        <MenuItem value={'66.66%'}>2/3</MenuItem>
                                                        <MenuItem value={'100%'}>Full</MenuItem>
                                                    </Select>
                                                </Grid>

                                                {section.components.map((component, index2) => {
                                                    return (
                                                        <Grid container className={classes.newComponent} spacing={2}>
                                                            <Grid item xs={12} className={classes.newComponent}>
                                                                <Typography>Component {index2 + 1}</Typography>
                                                                <IconButton
                                                                    onClick={(event) => {
                                                                        let sections = [...props.form.sections];
                                                                        sections[index].components.splice(index2, 1);
                                                                        props.updateForm({ ...props.form, 'sections': sections })
                                                                    }}
                                                                    size='small'>
                                                                    <RemoveCircleOutlineIcon />
                                                                </IconButton>
                                                            </Grid>
                                                            <Grid item xs={8}>
                                                                <Typography>Type</Typography>
                                                            </Grid>
                                                            <Grid item xs={4} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                                                <Select
                                                                    fullWidth
                                                                    value={component.type}
                                                                    onChange={(event) => {
                                                                        let sections = [...props.form.sections];
                                                                        sections[index].components[index2].type = event.target.value;
                                                                        props.updateForm({ ...props.form, 'sections': sections })
                                                                    }}>
                                                                    <MenuItem value={'text'}>Text</MenuItem>
                                                                    <MenuItem value={'input'}>Input</MenuItem>
                                                                </Select>
                                                            </Grid>
                                                            <Grid item xs={12} style={{ marginTop: '8px' }}>
                                                                {createComponent(component, index, index2)}
                                                            </Grid>
                                                        </Grid>
                                                    )
                                                })}
                                                <Grid item xs={12} className={classes.newComponent}>
                                                    <Typography>New Component</Typography>
                                                    <IconButton
                                                        onClick={(event) => {
                                                            let newSection = [...props.form.sections];
                                                            newSection[index].components.push({ type: 'text', value: '' });
                                                            props.updateForm({ ...props.form, 'sections': newSection })
                                                        }}
                                                        size='small'>
                                                        <AddCircleOutlineIcon />
                                                    </IconButton>


                                                </Grid>
                                            </React.Fragment>
                                            : null}


                                    </React.Fragment>)
                            })}

                            <Grid item xs={12} style={{ display: 'flex', justifyContent: 'center' }}>
                                <IconButton title="New Section" onClick={(event) => { addSection() }}>
                                    <AddCircleOutlineIcon />
                                </IconButton>
                            </Grid>
                            <Grid item xs={12}>
                                <Button fullWidth variant='contained' onClick={props.submit}>Create Form</Button>
                            </Grid>
                        </Grid>
                    </div>
                </Drawer>
            </div>
        </div>
    )
};

export default FormBuilder;