import React, { useState} from 'react'
import { Typography, Grid, TextField, InputAdornment, IconButton, Checkbox, Menu, MenuItem, FormControl, Select} from '@material-ui/core';
import _ from 'lodash';

import SearchIcon from '@material-ui/icons/Search';
import ViewColumnIcon from '@material-ui/icons/ViewColumn';
import RefreshIcon from '@material-ui/icons/Refresh';

const DataGridTableTitle = (props) => {

    const [columnSelectorOpen, setColumnSelectorOpen] = useState(false)

    const handleOpenColumnSelector = (e) => {
        setColumnSelectorOpen(e.currentTarget);
      };
    
    const handleCloseColumnSelector = () => {
        setColumnSelectorOpen(null);
    };

    // let debouncedHandleSearchChange = _.debounce(function (value) {
    //     props.handleSearchChange(value, 'value')
    // }, 400);

    const handleChangeColumnsVisible = (index) => {
        let container = _.cloneDeep(props.columns)
        container[index].active =  !_.isUndefined(container[index].active) ? !container[index].active : false
        props.setColumns(container)
    }

    //console.log('rerendered dataGridTableTitle')

    return (
        <>
            <Grid item lg={4} md={4} xs={12}>
                <Typography variant='h6'>
                    Grain Orders
                    {props.rowsSelected.length > 0 && ` (${props.rowsSelected.length} rows selected)`}
                </Typography>
            </Grid>

            <Grid item lg={8} md={8} xs={12} >
                <Grid container justifyContent='flex-end'>
                    <Grid style={{marginRight: '10px'}}>
                        <FormControl>         
                            <Select
                                value={props.searchValue.column}
                                onChange={(event) => { props.handleSearchChange(event.target.value, 'column') }}
                            >
                                <MenuItem 
                                    key={'all'}
                                    value={'all'}
                                >
                                    All Columns
                                </MenuItem>

                                {props.columns.map((column) => {
                                    return (
                                        <MenuItem 
                                            key={column.field}
                                            value={column.field}
                                        >
                                            {column.title}
                                        </MenuItem>
                                    )  
                                })}
                            </Select>
                        </FormControl>

                        <FormControl style={{marginRight: '5px', marginLeft: '5px'}}>         
                            <Select
                                value={props.searchValue.condition}
                                onChange={(event) => { props.handleSearchChange(event.target.value, 'condition') }}
                            >
                                <MenuItem name='contains' key="contains" value='contains'>Contains</MenuItem>
                                <MenuItem name='doesNotContain' key="doesNotContain" value='doesNotContain'>Doesnt Contain</MenuItem>
                            </Select>
                        </FormControl>

                        <TextField
                            InputProps={{
                                endAdornment: <InputAdornment position="end"><SearchIcon/></InputAdornment>,
                            }}
                            placeholder='Keyword'
                            value={props.searchValue.value}
                            onChange={(e) => { 
                                //debouncedHandleSearchChange(e.target.value);  
                                props.handleSearchChange(e.target.value, 'value');
                                props.page !== 0 && props.setPage(0)
                            }}
                        />
                    </Grid>
                                
                    <Grid>
                        <IconButton
                            size='small'
                            onClick={(e)=> { props.updateSort(props.tabSelected, props.searchValue) }}
                        >
                            <RefreshIcon/>
                        </IconButton>

                        <IconButton
                            size='small'
                            onClick={(e)=> { handleOpenColumnSelector(e) }}
                        >
                            <ViewColumnIcon/>
                        </IconButton>

                        <Menu
                            anchorEl={columnSelectorOpen}
                            keepMounted
                            open={columnSelectorOpen}
                            onClose={handleCloseColumnSelector}
                        >
                            <Typography noWrap style={{fontSize: '12px', fontWeight: '600', padding: '15px'}}>Add or remove columns</Typography>
                            {props.columns.map((column, index) => {
                                return (
                                    <MenuItem 
                                        key={index}
                                        value={index}
                                        onClick={(e) => { handleChangeColumnsVisible(index) }}
                                    >
                                        <Checkbox checked={!_.isUndefined(column?.active) ? column.active : true} />
                                        <Typography style={{marginTop: '5px'}}>{column.title}</Typography>    
                                    </MenuItem>
                                )  
                            })}
                        </Menu>
                    </Grid>
                </Grid>
            </Grid>
        </>
    )
}
  export default DataGridTableTitle;