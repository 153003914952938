import React, { useState, useCallback } from 'react';
import { withRouter } from 'react-router-dom';
import { firestore } from '../../../firebase/firebase';
import { makeStyles } from '@material-ui/core';
import _ from 'lodash';
import moment from 'moment';
import { Skeleton } from '@material-ui/lab'

import PayrollConfirmationModal from './PayrollConfirmationModal';
import PayrollPDF from '../../components/Payroll/PayrollPDF';

const useStyles = makeStyles((theme) => ({
    tableText: {
        fontSize: 14,
        //marginBottom: '1px',
        //padding: '20px',
        paddingRight: '15px',
        paddingLeft: '15px',
    },
    tableTextBold: {
        fontSize: 10,
        //marginBottom: '1px',
        fontFamily: 'Helvetica-Bold',
        fontWeight: 'bold',
        //marginTop: '5px'
    },
    tableTextBoldUnderline: {
        fontSize: 24,
        fontFamily: 'Helvetica-Bold',
        fontWeight: 'bold',
        textDecoration: 'underline',
        paddingRight: '15px',
        paddingLeft: '15px',
        //padding: '15px',
    },
}));

function PayrollPrint(props) {

    const classes = useStyles();

    const [ticketData] = useState(JSON.parse(props.location.state.ticketData))
    const [payrollDetails] = useState(JSON.parse(props.location.state.payrollDetails))
    const [payrollFilter] = useState(JSON.parse(props.location.state.payrollFilter))
    const [users] = useState(JSON.parse(props.location.state.users))

    const handleChangePayrollOnTickets = () => {
        let batch = firestore.batch();

        ticketData.forEach((ticket) => {
            if(_.isEmpty(ticket.operatorPayDate)){
                const docRef = firestore.collection('deliveries').doc(ticket.docId);
                batch.update(docRef, { operatorPayDate: payrollFilter.reportScope !== 'postedOnly' ? moment().valueOf() : null });
            }
        })

        batch.commit()
        .then(() => { console.log('updated tickets') })
        .catch((e) => { console.warn(e) })
    }

    let defaultColumns =[
        {header: 'Date', field: 'date', width: 0},
        {header: 'Description', field: 'notes', width: 0},
        {header: 'Ticket #', field: 'ticket', width: 0},
        {header: 'Hours', field: 'payrollUnits', width: 0},
        {header: 'Rate', field: 'payrollRate', width: 0},
        {header: 'Total', field: 'payroll', width: 0},
    ]

    const [loading, setLoading] = useState(true)
    const [columns, setColumns] = useState(defaultColumns)

    const invisTableCallback = useCallback((el) => {
        if (el !== null) {
            let columnsContainer = _.cloneDeep(columns)
            let columnArray = [...el?.tHead?.children[0]?.children];
            let totalContainer = columnArray.reduce((accumulator, currentValue) => (Number(accumulator) + Number(currentValue.offsetWidth )), 0,)

            for (const [index, property] of columnArray.entries()) {
                if(columnsContainer.length !== columnArray.length){ throw new Error('columns length does not match.') }
                columnsContainer[index].width = Number((property.offsetWidth  / totalContainer) * 100)
            }

            setColumns(columnsContainer)
            setLoading(false)
            el.style.display = 'none';
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            {loading 
            ?
                <Skeleton></Skeleton>
            :
                <>
                    <PayrollConfirmationModal 
                        confirmFunction={handleChangePayrollOnTickets}
                        reportScope={payrollFilter.reportScope}
                        payrollDetails={payrollDetails}
                    />
                
                    <PayrollPDF
                        ticketData={ticketData}
                        payrollDetails={payrollDetails}
                        payrollFilter={payrollFilter}
                        users={users}
                        columns={columns}
                    />
                </>
            }

            {/* invisible table used for css structure    visibility: 'hidden'*/}
            <table style={{width: '1000px', margin: 'auto', visibility: 'hidden',}} ref={invisTableCallback} >
                <thead>
                    <tr>
                        {columns.map((column, columnIndex) => (
                            <th key={columnIndex} className={classes.tableTextBoldUnderline}>
                                {column.header}
                            </th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                {ticketData.map((ticket, ticketIndex) => (
                    <tr key={ticketIndex} >
                    {columns.map((column, columnIndex) => {
                        switch(column.field) {
                            case 'date':
                                return <td className={classes.tableText} key={ticketIndex + columnIndex}> {moment(ticket.date).format('YYYY-MM-DD')} </td>
                            case 'notes':
                                return <td className={classes.tableText} key={ticketIndex + columnIndex}> {ticket.notes} </td>    
                            case 'ticket':
                                return <td className={classes.tableText} key={ticketIndex + columnIndex}> {ticket.ticket} </td>     
                            case 'payrollUnits':
                                return <td className={classes.tableText} key={ticketIndex + columnIndex}> {Number(ticket.payrollUnits || 0).toFixed(2)} </td>
                            case 'payrollRate':
                                return <td className={classes.tableText} key={ticketIndex + columnIndex}> {`$${Number(ticket.payrollRate || 0).toFixed(2)}`} </td>    
                            case 'payroll':
                                return <td className={classes.tableText} key={ticketIndex + columnIndex}> {`$${Number(ticket.payroll || 0).toFixed(2)}`} </td>    
                            default:
                                console.warn('Field not available within render switch.')
                                return null;  
                        }
                    })}
                    </tr>
                ))}

                </tbody>
            </table>

        </>
    )
}

export default withRouter(PayrollPrint)