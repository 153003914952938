import React, { useState, useEffect, useRef } from 'react'
import { List, ListSubheader, ListItem, ListItemText, makeStyles } from '@material-ui/core';
import _ from 'lodash';

const useStyles = makeStyles((theme) => ({
  content: {
      display: 'flex',
      width: '100%',
      flexDirection: 'column',
      maxWidth: '90vw',
      margin: 'auto',
      marginTop: '20px',
  },
  list: {
    borderBottom: '1px solid grey',
    minHeight: '400px', 
    marginBottom: '5px',
    overflow: 'auto',
  },
}));

const MultiSelectList = (props) => {
       
    const classes = useStyles();
    const mouseDownTimer = useRef()
    const firstRowSelection = useRef()

   const [mouseDown, setMouseDown] = useState(false)
   const [selectedRows, setSelectedRows] = useState([])

    //sets up mouse up (for handling adding multiple rows)
    useEffect(() => {
      window.addEventListener('mouseup', handleMouseUpEvent);
      return function cleanup() {
          window.removeEventListener('mouseup', handleMouseUpEvent ) 
      };
      //eslint-disable-next-line
    },[])

    //resets rows selected when listData changes
    useEffect(() => {
      setSelectedRows([])
    }, [props.listData])

    //pass docIds up to parent
    useEffect(() => { !_.isUndefined(props.onRowSelectionChange) && props.onRowSelectionChange(selectedRows) }, [selectedRows])  // eslint-disable-line react-hooks/exhaustive-deps

    //sets the uid used, if no alternativeUniqueID is passed defauls to docId (boiler plate uid used across site)
    const uid = _.isUndefined(props.alternativeUniqueID) ? "docId" : props.alternativeUniqueID

    const handleMouseUpEvent = () => {
        if (mouseDownTimer.current) {
            clearTimeout(mouseDownTimer.current);
            mouseDownTimer.current = null;
            setMouseDown(false);
            //document.body.style.cursor = 'auto';
        }
      };
  
      const handleMouseDownEvent = (e, docId) => {
        if (mouseDownTimer.current) return;
        mouseDownTimer.current = setTimeout(() => {
            firstRowSelection.current = docId
            setSelectedRows([docId])
            setMouseDown(true);
        }, 100);
      };
  
      const handleMouseEnterEvent = (e, docId, index) => {
        if(mouseDown){
            let latestRowSelectedIndex = props.listData.findIndex(x=> x.docId === firstRowSelection.current)
            let smaller = _.clamp(index >= latestRowSelectedIndex ? latestRowSelectedIndex : index, 0, props.listData.length -1);
            let larger = _.clamp(index >= latestRowSelectedIndex ? index : latestRowSelectedIndex, 0, props.listData.length -1);
            let newSelectedRows = props.listData.slice(smaller, larger +1).map((row) => { return row.docId })
        
            setSelectedRows(newSelectedRows)
        }
    }
  
    const handleListClick = (event, docId) => {
      if(event.shiftKey){
        let index = props.listData.findIndex(x=> x.docId === docId)
        let latestRowSelectedIndex = props.listData.findIndex(x=> x.docId === firstRowSelection.current)
        let smaller = _.clamp(index >= latestRowSelectedIndex ? latestRowSelectedIndex : index, 0, props.listData.length -1);
        let larger = _.clamp(index >= latestRowSelectedIndex ? index : latestRowSelectedIndex, 0, props.listData.length -1);
        let newSelectedRows = props.listData.slice(smaller, larger +1).map((row) => { return row.docId })
        setSelectedRows(newSelectedRows)
      }
      else if(event.ctrlKey){
        if(selectedRows.includes(docId)){
            let container = _.cloneDeep(selectedRows)
            container.splice(container.findIndex(x=> x.docId === docId), 1)
            setSelectedRows([...container])
        }
        else{
            setSelectedRows([...selectedRows, docId])
        }
      }
      else{
        setSelectedRows([docId])
        firstRowSelection.current = docId;
      }
    }

    return (
        <>
            <ListSubheader component="div" style={{borderBottom: '1px solid grey',}}>
              {props.header}
            </ListSubheader>

            <List component="nav" disablePadding={true} dense={true} style={props.style} className={classes.list}>
                  {props.listData.map((item, index) => {
                    //get item for display (if needed)
                    let displayData = !_.isEmpty(props.alternativeDisplayData) ? props.alternativeDisplayData.find(x=> x[uid] === item[props.alterativeDisplayUID]) : item;
                    let reducedDisplay = []

                    if(!_.isUndefined(displayData)){
                      //disaply value used for item
                      reducedDisplay = Object.entries(displayData)?.reduce((accumulator, [key, value]) => {
                          if(props.displayFields?.includes(key)){ return (value + ' ' + accumulator) }
                          return accumulator
                      }, '');
                    }

                    return (
                      <ListItem 
                        key={index}
                        selected={selectedRows.includes(item.docId)} 
                        button 
                        onMouseEnter={(e) => { 
                          handleMouseEnterEvent(e, item.docId, index) 
                        }}
                        onMouseDown={(e) => { 
                          handleMouseDownEvent(e, item.docId) 
                        }}
                        onClick={(e)=> {
                          handleListClick(e, item.docId)
                        }}
                      >
                        <ListItemText primary={ reducedDisplay || ''} />
                      </ListItem>
                    )
                  })}
              </List>
        </>
    )
}

export default MultiSelectList;