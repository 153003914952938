import React, { useEffect, useState, useCallback } from 'react';
import firebase from 'firebase/compat/app'
import { firestore } from '../../../../firebase/firebase'
import { withRouter } from 'react-router-dom';
import { useHistory } from "react-router-dom";
import TimesheetHeader from '../../../components/Reports/Timesheets/TimesheetHeader'
import TimesheetInputs from '../../../components/Reports/Timesheets/TimesheetInputs'
import TimesheetMeta from '../../../components/Reports/Timesheets/TimesheetMeta'
import TimeMap from '../../../components/Reports/Timesheets/TimeMap'
import { InfoWindow, Marker } from '@react-google-maps/api';
import _ from 'lodash'
import { makeStyles, Snackbar } from '@material-ui/core';
import Paper from '@material-ui/core/Paper'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import moment from 'moment'
import JobTable from '../../../components/Reports/Timesheets/JobTable';
import UnitTable from '../../../components/Reports/Timesheets/UnitTable';
import ChecklistTable from '../../../components/Reports/Timesheets/ChecklistTable';
import TimesheetDetails from '../../../components/Reports/Timesheets/TimesheetDetails';
import { getCoordinates, hasInCoordinate, hasOutCoordinate } from '../../../utility/utility'
import DeliveryTable from '../../../components/Reports/Timesheets/DeliveryTable';
import { useUser } from '../../../../providers/UserContext'
import Notes from '../../Notes/Notes';
import Skeleton from '@material-ui/lab/Skeleton';
import { functions } from '../../../../firebase/firebase'
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import QRCode from 'qrcode.react';
import TimesheetAdditionalCosts from '../../../components/Reports/Timesheets/TimesheetAdditionalCosts';
import FormTable from '../../../components/Reports/Timesheets/TimesheetForms';
import { TableIcons } from '../../../../constants/tableIcons';
import MaterialTable from '@material-table/core';
import { useCustomer } from '../../../../providers/CustomerContext';
import WorkOrderTable from '../../../components/Reports/Timesheets/WorkOrderTable';

const useStyles = makeStyles((theme) => ({
    content: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        maxWidth: '1400px',
        margin: 'auto',
    },
    paper: {
        padding: theme.spacing(2),
        maxWidth: '1400px',
        width: '100%',
        //backgroundColor: 'rgb(252, 252, 249)',
        justifyContent: 'center',
        alignItems: 'center',
    },
    backButton: {
        //marginBottom: '20px',
        marginTop: '20px',
    },
}))

function Timesheet(props) {
    const { users, usersLoading, getUsers } = useUser();
    const history = useHistory();
    const classes = useStyles()
    const { customers, getCustomers } = useCustomer();

    const [timesheet, setTimesheet] = useState({});
    const [timesheetLoading, setTimesheetLoading] = useState(true);

    const [unitData, setUnitData] = useState([]);
    const [unitsLoading, setUnitsLoading] = useState(true);
    const [currentUnit, setCurrentUnit] = useState({})
    const [currentUnitJobs, setCurrentUnitJobs] = useState([])
    const [currentUnitJobsSaveState, setCurrentUnitJobsSaveState] = useState([])

    const [jobData, setJobData] = useState([]);
    const [jobsLoading, setJobsLoading] = useState(true);
    const [currentJob, setCurrentJob] = useState({})
    const [jobCustomerAutocomplete, setJobCustomerAutocomplete] = useState("")

    const [deliveryData, setDeliveryData] = useState([]);
    const [deliveriesLoading, setDeliveriesLoading] = useState(true);
    const [currentDelivery, setCurrentDelivery] = useState({})
    const [deliveryCustomerAutocomplete, setDeliveryCustomerAutocomplete] = useState("")

    const [workOrderData, setWorkOrderData] = useState([]);
    const [workOrderLoading, setWorkOrderLoading] = useState(true);

    const [newCustomer, setNewCustomer] = useState(false)
    const [currentCustomer, setCurrentCustomer] = useState({})

    const [checklistData, setChecklistData] = useState([]);
    const [checklistsLoading, setChecklistsLoading] = useState(true);
    const [currentChecklist, setCurrentChecklist] = useState({})

    const [formData, setFormData] = useState([]);
    const [formsLoading, setFormsLoading] = useState(true);

    const [covidData, setCovidData] = useState({});
    const [covidLoading, setCovidLoading] = useState(true);

    const [invoiceData, setInvoiceData] = useState([]);
    const [errorMessage, setErrorMessage] = useState("");
    const [saving, setSaving] = useState(false);
    const [coordinateData, showCoordinateData] = React.useState(false);
    const [totalTimesheetTime, setTotalTimesheetTime] = useState(0);
    const [totalJobTime, setTotalJobTime] = useState(0);
    const [operatorHours, setOperatorHours] = useState(0);
    const [totalExpandedTime, setTotalExpandedTime] = useState(0);
    const [expandedTimePerJob, setExpandedTimePerJob] = useState(0);
    const [adminTime, setAdminTime] = useState(0);
    const [submitting, setSubmitting] = useState(false)
    const [unsubmitting, setUnsubmitting] = useState(false)
    const [additionalCostsLoading, setAdditionalCostsLoading] = useState();
    const [timesheetSaveState, setTimesheetSaveState] = useState();
    const [loading, setLoading] = useState(true);
    const [uri, setURI] = useState();
    const [can, setCan] = useState(document.getElementsByTagName("canvas"));

    //const qrRef = useRef()

    useEffect(() => {
        getUsers();
        getCustomers();
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    //setting intial data
    const loadTimesheet = async () => {
        setLoading(true)
        let path = new URLSearchParams(props.location.search).get("docId");
        let workOrderResponse = [];
        firestore.collection('timesheets').doc(path).get()
            .then(documentSnapshot => {
                const timesheetResponse = { ...documentSnapshot.data(), 'docId': documentSnapshot.id }
                if (_.isEmpty(timesheetResponse)) return;
                if (timesheetResponse.timesheetStatus === 'unseen') {
                    firestore.collection('timesheets').doc(path).update({ 'timesheetStatus': 'viewed' }).then(() => {
                        setTimesheet({ ...timesheetResponse, 'timesheetStatus': 'viewed' })
                        setTimesheetSaveState({ ...timesheetResponse, 'timesheetStatus': 'viewed' });
                    })
                } else {
                    setTimesheet(timesheetResponse);
                    setTimesheetSaveState(timesheetResponse);
                }
                firestore.collection('documents').where('timesheetID', '==', timesheetResponse.docId).get()
                    .then(querySnapshot => {
                        const documentResponse = querySnapshot.docs.map((doc) => {
                            return {
                                ...doc.data(),
                                'docId': doc.id,
                            }
                        })
                        setFormData(documentResponse)
                    })
                    .catch(e => {
                        console.log(e.message);
                        setErrorMessage("Unable to Process Forms");
                    })
                    .finally(() => { setFormsLoading(false) })

                firestore.collection('units').where("timesheetID", "==", timesheetResponse.docId).get()
                    .then(querySnapshot => {
                        const unitResponse = querySnapshot.docs.map((doc) => {
                            return {
                                ...doc.data(),
                                'docId': doc.id,
                                'timesheetID'   : doc.data()?.timesheetID,   
                            }
                        })
                        setUnitData(unitResponse)
                    })
                    .catch(e => {
                        setErrorMessage("Unable to Process Units");
                        console.log(e.message);
                    })
                    .finally(() => { setUnitsLoading(false) })

                firestore.collection('jobs').where("timesheetID", "==", timesheetResponse.docId).get()
                    .then(querySnapshot => {
                        const jobResponse = querySnapshot.docs.map((doc) => {
                            return {
                                //...doc.data(),
                                'docId': doc.id,
                                'timesheetID'   : doc.data().timesheetID,   
                                'uid'           : doc.data().uid,
                                'customer'      : doc.data().customer,
                                'units'         : doc.data()?.units,
                                'jobNumber'     : doc.data()?.jobNumber,
                                'address'       : doc.data()?.address,
                                'timeIn'        : doc.data()?.timeIn,
                                'timeOut'       : doc.data()?.timeOut,
                                'adminTime'     : doc.data()?.adminTime,
                                'expandedTime'  : doc.data()?.expandedTime,
                                'adminNotes'    : doc.data()?.adminNotes,
                                'notes'         : doc.data()?.notes,
                            }
                        })
                        setJobData(jobResponse)
                    })
                    .catch(e => {
                        setErrorMessage("Unable to Process Jobs");
                        console.log(e.message);
                    })
                    .finally(() => { setJobsLoading(false) })

                firestore.collection('deliveries').where("timesheetID", "==", timesheetResponse.docId).orderBy('ticket').get()
                    .then(querySnapshot => {
                        const deliveryResponse = querySnapshot.docs.map((doc) => {
                            return {
                                ...doc.data(),
                                'docId': doc.id,
                            }
                        })
                        setDeliveryData(deliveryResponse)
                        //setDeliverySaveState(deliveryResponse)
                    })
                    .catch(e => {
                        setErrorMessage("Unable to Process Deliveries");
                        console.log(e.message);
                    })
                    .finally(() => { setDeliveriesLoading(false) })

                    

                firestore.collection('covid').doc((timesheetResponse.covidId != null && timesheetResponse.covidId.isNotEmpty) ? timesheetResponse.covidId : "failed").get()
                    .then(documentSnapshot => {
                        if (documentSnapshot.exists) {
                            setCovidData({ ...documentSnapshot.data() })
                        } else {
                            setCovidData({ 'error': 'Covid Check Not Found' })
                        }
                    }).catch(e => {
                        setErrorMessage("Unable to Process Covid Protocol");
                        console.log(e.message);
                    })
                    .finally(() => { setCovidLoading(false) })

                firestore.collection('workOrders').where("timesheetID", "==", timesheetResponse.docId).get()
                .then(querySnapshot => {
                    workOrderResponse = querySnapshot.docs.map((doc) => {
                        return {
                            ...doc.data(),
                            'docId': doc.id,
                        }
                    })
                    
                })
                .catch(e => {
                    setErrorMessage("Unable to Process Shop Orders");
                    console.log(e.message);
                })

                firestore.collection('workOrders').where('timesheets', 'array-contains', timesheetResponse.docId).get().then(querySnapshot => {
                    const workOrderResponse2 = querySnapshot.docs.map((doc) => {
                        return {
                            ...doc.data(),
                            'docId': doc.id,
                        }
                    })
                    setWorkOrderData([...workOrderResponse, ...workOrderResponse2])
                })
                .catch(e => {
                    setErrorMessage("Unable to Process Work Orders");
                    console.log(e.message);
                })    
                .finally(() => { setWorkOrderLoading(false) })

            }).catch(e => {
                setErrorMessage("Unable to Process Timesheet");
                console.log(e.message);
            }).finally(() => { 
                

                //setting state with that state within dependancy array usually results in a infinite loop, should refactor to use set up use ref on grid instead
                setCan(document.getElementsByTagName("canvas"));
                if(can.length === 1){
                    setURI(can[0].toDataURL("image/jpeg",0.5));
                    sessionStorage.setItem("uri", JSON.stringify(can[0].toDataURL("image/jpeg",0.5)))
                }
                setLoading(false)
                setTimesheetLoading(false);
            });

            
    }

    useEffect(() => {
        setTimesheetLoading(true)
        setLoading(true)
        setTimesheet({})
        loadTimesheet();
        
                
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [can, props.location.search]
    );

    //calculates data and live updates page
    useEffect(() => {
        if(jobData !== undefined && unitData !== undefined && timesheet !== undefined){
            calculateData()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [timesheet, jobData, totalExpandedTime, unitData]);

    const updateTimesheetField = useCallback(
        (e) => {
            setTimesheet({ ...timesheet, ...e })
        },
        [timesheet]
    );

    const skippedLunchOptions = { 'na': 'Not Skipped', 'pending': 'Skipped'}
    const timesheetStatuses = { 'unseen': 'Unseen', 'viewed': 'Viewed', 'printed': 'Printed' }

    let coordinates = getCoordinates(timesheet);

    let mapElements = [];

    if (timesheet.date < 9999999999) timesheet.date *= 1000
    if (timesheet.inTime < 9999999999) timesheet.inTime *= 1000
    if (timesheet.outTime < 9999999999) timesheet.outTime *= 1000

    if (hasInCoordinate(coordinates)) {
        mapElements.push(<Marker key={0} icon="https://maps.google.com/mapfiles/ms/icons/blue-dot.png" title="Clock In" position={coordinates.inCoordinate} />)
        if (coordinateData) {
            mapElements.push(
                <InfoWindow
                    key={2}
                    position={{ lat: coordinates.inCoordinate.lat + 0.00005, lng: coordinates.inCoordinate.lng }}>
                    <React.Fragment>
                        <p>Timesheet Start: {coordinates.inCoordinate.lat.toFixed(4)},{coordinates.inCoordinate.lng.toFixed(4)}</p>
                        <p>Coord Start Time: {moment(timesheet.inCoordinate?.time).format('MMM D h:mm a')}</p>
                        <p>User Submitted Time: {moment(timesheet.inTime).format('MMM D h:mm a')}</p>
                    </React.Fragment>
                </InfoWindow>)
        }
    }
    if (hasOutCoordinate(coordinates)) {
        mapElements.push(<Marker key={1} icon="https://maps.google.com/mapfiles/ms/icons/red-dot.png" title="Clock Out" position={coordinates.outCoordinate} />)
        if (coordinateData) {
            mapElements.push(
                <InfoWindow
                    key={3}
                    position={{ lat: coordinates.outCoordinate.lat + 0.00005, lng: coordinates.outCoordinate.lng }}>
                    <React.Fragment>
                        <p>Timesheet End: {coordinates.outCoordinate.lat.toFixed(4)},{coordinates.outCoordinate.lng.toFixed(4)}</p>
                        <p>Coord End Time: {moment(timesheet.outCoordinate?.time).format('MMM D h:mm a')}</p>
                        <p>User Submitted Time: {moment(timesheet.outTime).format('MMM D h:mm a')}</p>
                    </React.Fragment>
                </InfoWindow>)
        }
    }

    const updateLunch = (event) => {
        updateTimesheetField({ 'skipLunch': event.target.value })
    }

    const updateStatus = (event) => {
        updateTimesheetField({ 'timesheetStatus': event.target.value })
    }

    const updateType = (event) => {
        updateTimesheetField({ 'timesheetType': event.target.value })
    }
    
    const saveTimesheet = async () => {
        setSaving(true);
        firestore.collection('timesheets').doc(timesheet.docId)
            .update(timesheet)
            .then(() => {
                setTimesheetSaveState({...timesheet});
                setSaving(false);
            }).catch((e) => {setErrorMessage(e); setSaving(false)});
    }
    
    const handleAdminNotes = (type, note, index, noteIndex, useCase ) => {
        //setJobNotesLoading(true)
        if(useCase === 'create'){
            let container = type === 'job' ? jobData[index] : deliveryData[index];
            container.adminNotes = container.adminNotes ? [...container.adminNotes, note] : [note];
            type === 'job' ? updateJob(container) : updateDelivery(container);
        }

        if(useCase === 'edit'){
            let container = type === 'job' ? jobData[index] : deliveryData[index];
            container.adminNotes.splice(noteIndex, 1, note);
            type === 'job' ? updateJob(container) : updateDelivery(container);
        }

        if(useCase === 'delete'){
            let container = type === 'job' ? jobData[index] : deliveryData[index];
            container.adminNotes.splice(noteIndex, 1);
            type === 'job' ? updateJob(container) : updateDelivery(container);
        }

        //setTimeout(() => {
        //    setJobNotesLoading(false)
        //},  1000);
    }

    const batchUpdateUnits = (array) => {
        setUnitsLoading(true)
        let batch = firestore.batch();
        let unitDataContainer = [...unitData];

        array.forEach(unit => {
            let query = firestore.collection('units').doc(unit.docId)
            batch.update(query, unit);
            let unitIndex = unitData.findIndex((unit2) => unit.docId === unit2.docId);
            unitDataContainer[unitIndex] = { ...unit };
        })

        batch.commit().then(() => {
            setUnitData(unitDataContainer);
            setUnitsLoading(false)
        })
    }
    
    function updateUnit(newData, jobs) {
        setUnitsLoading(true)
        if(jobs){ newData = {...newData, adminTime: 0} }
        firestore.collection('units').doc(newData.docId).update(newData).then(() => {
            let newUnitData = [...unitData];
            let unitIndex = unitData.findIndex((unit) => unit.docId === newData.docId);
            newUnitData[unitIndex] = { ...newData };
            setUnitData([...newUnitData]);
            if(jobs){ batchUpdateJobs(jobs); }
            setUnitsLoading(false)
        })
        .catch(e => {
            setUnitsLoading(false)
            console.log(e.message);
        })
    }

    const createChecklist = (data) => {
        let newChecklist = {
            date: moment.now(),
            created: firebase.firestore.FieldValue.serverTimestamp(),
            timesheetID: timesheet.docId,
            uid: timesheet.uid,
            photoURLS: [],
        }

        //merge data with newChecklist
        newChecklist = {...newChecklist, ...data}

        firestore.collection('checklists').add(newChecklist)
        .then((querySnapshot) => {
            let newChecklistData = [...checklistData];
            newChecklist.docId = querySnapshot.id;
            newChecklistData.push(newChecklist);
            setChecklistData(newChecklistData);
            setChecklistsLoading(false)
        })
        .catch(e => {
            setChecklistsLoading(false)
            console.log(e.message);
        })

    }
    
    const createUnit = (data, jobs) =>  {
        setUnitsLoading(true)
        let newUnit = {
            date: moment.now(),
            created: firebase.firestore.FieldValue.serverTimestamp(),
            timesheetID: timesheet.docId,
            uid: timesheet.uid,
            unitNumber: data.unitNumber ? data.unitNumber : 0,
            trailerID: data.trailerID ? data.trailerID : "",
            repairNotes: "",
            preTripItems: [],
            postTripItems: [],
            photoURLS: [],
            odoUnits: "",
            mileageIn: data.mileageIn ? data.mileageIn : 0,
            mileageOut: data.mileageOut ? data.mileageOut : 0,
            greased: data.greased ? data.greased : false,
            fuelUnitDisplay: "",
            fuelAdded: "",
            defectID: "",
        }
        firestore.collection('units').add(newUnit)
        .then((querySnapshot) => {
            newUnit['docId'] = querySnapshot.id
            let unitDataContainer = [...unitData, { ...newUnit }]
            setUnitData(unitDataContainer);
            
            if(jobs){
                jobs.forEach((job) => {
                    let newUnits = job.units;
                    newUnits.push(newUnit.docId)
                    //SHOULD BATCH UPDATE WHEN MORE THAN 1
                    updateJob({...job, units: newUnits}) 
                })
            }
            setUnitsLoading(false)
        })
        .catch(e => {
            setUnitsLoading(false)
            console.log(e.message);
        })
    }

    const deleteUnit = (oldUnit) => {
        setUnitsLoading(true)
        let index = unitData.findIndex(unit => unit.docId === oldUnit.docId)
        return firestore.collection('units').doc(oldUnit.docId).delete().then(() => {
            let unitContainer = _.cloneDeep( unitData );
            unitContainer.splice(index, 1)
            setUnitData(unitContainer)

            jobData.forEach((job) => {
                job.units.forEach((unitDocId, index) => {
                    if(unitDocId === oldUnit.docId){
                        let jobContainer = _.cloneDeep( job );
                        delete jobContainer.unitNums
                        delete jobContainer.unitNumbers
                        jobContainer.units.splice(index, 1);
                        updateJob(jobContainer);
                    }

                })
            })

            deliveryData.forEach((delivery) => {
                delivery.units.forEach((unitDocId) => {
                    if(unitDocId === oldUnit.docId){
                        let deliveryContainer = _.cloneDeep( delivery );
                        deliveryContainer.units.splice(deliveryContainer.units.findIndex((delivery => delivery.docId === deliveryContainer.docId), 1));
                        //updateDelivery(deliveryContainer);
                    }
                })
            })

            setUnitsLoading(false)
        }).catch((e) => {
            setUnitsLoading(false)
            console.log(e);
        });

    }

    const createCustomer = (data) => {
        let newCustomer = {
            city: data.city ? data.city : '',
            name: data.name ? data.name : '',
            postalCode: data.postalCode ? data.postalCode : '',
            province: data.province ? data.province : '',
            streetNum: data.streetNum ? data.streetNum : '',
            streetName: data.streetName ? data.streetName : '',
            isActive: true,
            contacts: [],
            rates: [],
            retailSalesTaxNum: '',
            gstRegNum: '',
            customerType: "Customer",
            deliveryAddress: {
                city: data.city ? data.city : '',
                postalCode: data.postalCode ? data.postalCode : '',
                province: data.province ? data.province : '',
                streetNum: data.streetNum ? data.streetNum : '',
                streetName: data.streetName ? data.streetName : '',
            }
        }
        firestore.collection('customers').add(newCustomer)
        .then(() => {
            getCustomers()
        })
        .catch((e) => { 
            console.log(e.message)
        });
    }

    const batchUpdateJobs = (array) => {
        setJobsLoading(true)
        let batch = firestore.batch();
        let unitJobContainer = [...jobData];

        array.forEach(job => {
            let query = firestore.collection('jobs').doc(job.docId)
            batch.update(query, job);
            let jobIndex = unitJobContainer.findIndex((innerJob) => innerJob.docId === job.docId);
            unitJobContainer[jobIndex] = { ...job };
        })

        batch.commit().then(() => {
           
            setJobData(unitJobContainer);
            setJobsLoading(false)
        })
    }

    //second paramater optional, for when you want admin time to potentially be updated. containers unitData
    function updateJob(newData, unitDataContainer) {
        setJobsLoading(true);
        let unitsToBeUpdated = [];
        // Check for undefined values in newData
        const undefinedValues = [];
        Object.entries(newData).forEach(([key, value]) => {
            //removes appended table data (when notes is open)
            if(key === 'tableData'){ delete newData['tableData'] }

            if (value === undefined) {
                //BANDAID TO ENSURE THERE ARE NO UNDEFINED'S ON UPDATES RATHER THAN SKIPPING UPDATE (undefined's coming from both web and app job creates)
                delete newData[key]
                //undefinedValues.push(key);
            }
        });

        newData.unitNumbers = newData.unitNumbers.filter((item)=> item !== undefined)

        if (undefinedValues.length === 0) {
            if (unitDataContainer && newData.units.length > 0) {
                newData.units.forEach((unitDocId) => {
                    console.log(newData);
                    let container = unitDataContainer.find(unit => unit.docId === unitDocId);
    
                    if (container) {
                        unitsToBeUpdated.push({ ...container, adminTime: 0 });
                    }
                });
            }
    
            if (unitDataContainer) {
                unitDataContainer.forEach(unit => {
                    if (!newData.units.includes(unit.docId) && unit.jobTimes && unit.jobTimes.filter(job => job.jobId === newData.docId).length > 0) {
                        let container = _.cloneDeep(unit);
                        let index = unit.jobTimes.indexOf(unit.jobTimes.find(job => job.jobId === newData.docId));
                        container.jobTimes.splice(index, 1);
                        unitsToBeUpdated.push({ ...container });
                    }
                });
            }

            if (unitsToBeUpdated.length > 0) {
                batchUpdateUnits(unitsToBeUpdated);
            }

            firestore.collection('jobs').doc(newData.docId).update(newData)
                .then(() => {
                    let newJobData = [...jobData];
                    let jobIndex = jobData.findIndex((job) => job.docId === newData.docId);
                    newJobData[jobIndex] = { ...newData };
                    setJobData(newJobData);
                    setJobsLoading(false);
                })
                .catch(e => {
                    setJobsLoading(false);
                    console.log('job failed')

                    console.log(e, e.message);
                });
        } else {
            setJobsLoading(false);
            console.log('Undefined values detected in newData:', undefinedValues);
        }
    }

    const createJob = (data) =>  {
        setJobsLoading(true)
        let unitsToBeUpdated = []
        if(data.units && data.units.length > 0){
            data.units.forEach((unitDocId) => {
                let container = unitData.filter(unit => unit.docId === unitDocId)[0]
                if(container.adminTime !== 0 && container.adminTime !== '' && container.adminTime !== undefined && container.adminTime !== null){
                    unitsToBeUpdated.push({...container, adminTime: 0})
                }
            })
        }
        if(unitsToBeUpdated.length > 0){ batchUpdateUnits(unitsToBeUpdated) }

        let newJob = {
            date: moment.now(),
            timesheetID: timesheet.docId,
            uid: timesheet.uid,
            customer: data.customer ? data.customer : '',
            address: data.address ? data.address : '',
            timeIn: data.timeIn ? data.timeIn : moment().valueOf(),
            timeOut: data.timeOut ? data.timeOut : moment().valueOf(),
            expandedTime: data.expandedTime ? data.expandedTime : '',
            units: data.units ? data.units : [],
            adminTime: data.adminTime ? data.adminTime : '',
            //adminNotes: data.adminNotes ? data.adminNotes : '',
            //notes: data.notes ? data.notes : '',
            jobNumber: data.jobNumber ? data.jobNumber : ''
        }
        firestore.collection('jobs').add(newJob)
        .then((querySnapshot) => {
            newJob['docId'] = querySnapshot.id
            let jobDataContainer = [...jobData, { ...newJob }]
            setJobData(jobDataContainer)
            setJobsLoading(false)
        })
        .catch(e => {
            setJobsLoading(false)
            console.log(e.message);
        })
    }

    const deleteJob = (oldJob) => {
        setJobsLoading(true)
        let index = jobData.findIndex(job => job.docId === oldJob.docId)
        return firestore.collection('jobs').doc(oldJob.docId).delete().then(() => {
            let container = jobData;
            container.splice(index, 1)
            setJobData(container)

            unitData.forEach((unit) => {
                if(unit.jobTimes){
                    unit.jobTimes.forEach((jobTime, index) => {
                        if(oldJob.docId === jobTime.jobId){
                            let unitContainer = _.cloneDeep( unit );
                            unitContainer.jobTimes.splice(index, 1);
                            updateUnit(unitContainer);
                        }
                    })
                }
            })

            setJobsLoading(false)
        }).catch((e) => {
            setJobsLoading(false)
            console.log(e);
        });
    }

    const deleteDelivery = (oldDelivery) => {
        setDeliveriesLoading(true)
        let index = deliveryData.findIndex(delivery => delivery.docId === oldDelivery.docId)
        return firestore.collection('deliveries').doc(oldDelivery.docId).delete().then(() => {
            let container = deliveryData;
            container.splice(index, 1)
            setDeliveryData(container)
            setDeliveriesLoading(false)
        }).catch((e) => {
            setDeliveriesLoading(false)
            console.log(e);
        });
    }

    function updateDelivery(newData) {
        setDeliveriesLoading(true)

        //remove table data if it is present in data (for db only, local requires it)
        let container = _.cloneDeep(newData)
        if(container.tableData){ delete container.tableData;}

        firestore.collection('deliveries').doc(newData.docId).update(container).then(() => {
            let newDeliveryData = [...deliveryData];
            let deliveryIndex = deliveryData.findIndex((delivery) => delivery.docId === newData.docId);
            newDeliveryData[deliveryIndex] = { ...newData };
            setDeliveryData(newDeliveryData);
            setDeliveriesLoading(false)
        })
    }

    const createDelivery = (data) =>  {
        setDeliveriesLoading(true)
        let newDelivery = {
            date: moment.now(),
            timesheetID: timesheet.docId,
            uid: timesheet.uid,
            ticket: data.ticket ? data.ticket : 0,
            customer: data.customer ? data.customer : '',
            from: data.from ? data.from : '',
            to: data.to ? data.to : '',
            material: data.material ? data.material : '',
            units: data.units ? data.units : [],
            quantity: data.quantity ? data.quantity : 0,
        }
        firestore.collection('deliveries').add(newDelivery)
        .then((querySnapshot) => {
            newDelivery['docId'] = querySnapshot.id
            let deliveryDataContainer = [...deliveryData, { ...newDelivery }]
            setDeliveryData(deliveryDataContainer)
            setDeliveriesLoading(false)
        })
        .catch(e => {
            setDeliveriesLoading(false)
            console.log(e.message);
        })
    }

    const confirmSave = () => {
        if(!_.isEqual(timesheet, timesheetSaveState)){
            saveTimesheet();
            console.log("saved timesheet") 
         }
    }

    const cancelSave = () => {
        if(!_.isEqual(timesheet, timesheetSaveState)){
           setTimesheet(timesheetSaveState);
           console.log("reverted timesheet") 
        }
    };

    function viewCovidData() {
        if (_.isEmpty(covidData.docId || _.isEmpty(covidData.docId))) {
            return;
        }
        history.push("/dashboard/reporting/covidProtocol?docId=" + covidData.docId);
    }

    const submitSheet = () => {
        const submit = functions.httpsCallable('submitSheet');
        setSubmitting(true)

        submit({ ...timesheet, 'submitted': true }).then((data) => {
            setTimesheet(data.data)

        })
            .catch(function (error) {
                console.log(error.code);
                console.log(error.message);
                console.log(error.details);
            }).finally(() => {
                setSubmitting(false)
            });
    }

    const unsubmitSheet = () => {
        setUnsubmitting(true)
        firestore.collection('timesheets').doc(timesheet.docId).update({ submitted: false }).then(() => {
            setTimesheet({ ...timesheet, submitted: false })
            setUnsubmitting(false)
        })
    }


    //set to useEffect and state? so we dont do this 6 times a render just 1?
    function mergeJobData() {
        return jobData.map(job => {
            let unitNumbers = job.units.map(jobUnit => unitData.find(unit => unit?.docId === jobUnit)?.unitNumber)
            return { ...job, unitNumbers: unitNumbers }
        })
    }

    const calculateData = () => {
        //Calculates the total time during the shift
        if (timesheet.outTime < 9999999999) timesheet.outTime *= 1000
        if (timesheet.inTime < 9999999999) timesheet.inTime *= 1000
        let totalTimesheetTime = (timesheet.usingGateOutTime? timesheet.gateOut15 : timesheet.outTime) - (timesheet.usingGateInTime ? timesheet.gateIn15 : timesheet.inTime);
        totalTimesheetTime = timesheet.skipLunch === "na" || timesheet.skipLunch === "declined" ? totalTimesheetTime - 1800000 : totalTimesheetTime
        totalTimesheetTime -= timesheet.offHours * 3600000;
        totalTimesheetTime = totalTimesheetTime / 3600000
        totalTimesheetTime = (totalTimesheetTime > 0 ? totalTimesheetTime : 0).toFixed(2);

        //Calculate total job time
        let totalJobTime = 0;
        let totalExpandedTime = 0;
        let adminTime = jobData.adminTime ? jobData.adminTime : 0;

        

        jobData.forEach(job => {
            let unitNumbers = job.units.map(jobUnit => unitData.find(unit => unit?.docId === jobUnit)?.unitNumber)
            job.unitNumbers = unitNumbers;
            job.unitNums = job.unitNums ? job.unitNums : unitNumbers.toString();

        })
        //let unitNums = jobData.unitNumbers ? jobData.unitNumbers.toString() : "";
        //set jobData unitNums 
        //jobData.unitNums = unitNums;
        if (jobData.length > 0)
            totalJobTime = (jobData.map((job) => {
                if (job.timeOut < 9999999999) job.timeOut *= 1000
                if (job.timeIn < 9999999999) job.timeIn *= 1000
                return job.timeOut - job.timeIn
            }).reduce((total, time) => total + time) / 3600000).toFixed(2);

        //Calcluate operator hours
        let operatorHours = totalTimesheetTime - timesheet.offHours;
        operatorHours = operatorHours > 0 ? operatorHours.toFixed(2) : 0;

        //calculate admin Time
        jobData.forEach(job => {
            if (!_.isUndefined(job.adminTime)) {
                adminTime += Number(job.adminTime);
            }
        })
      
        unitData.forEach(unit => {
            if (!_.isUndefined(unit.adminTime)) {
                adminTime += Number(unit.adminTime);
            }
        })

        //Calculate expanded time per job
        let expandedTimePerJob = 0;
        expandedTimePerJob = (totalTimesheetTime - totalJobTime) / (jobData.length);
        expandedTimePerJob = expandedTimePerJob > 0 ? expandedTimePerJob : 0;

        jobData.sort((a, b) => (a.timeIn > b.timeIn) ? 1 : ((b.timeIn > a.timeIn) ? -1 : 0))
        jobData.forEach(job => {
            if (_.isUndefined(job.totalJobTime)) {
                job.jobTime = ((job.timeOut - job.timeIn) / 3600000).toFixed(2)

            }
            if (_.isUndefined(job.expandedTime) || job.expandedTime !== expandedTimePerJob) {
                job.expandedTime = expandedTimePerJob.toFixed(2)
            }
        })
        if (totalJobTime > 0) {
            //totalJobTime = (jobData.map((job) => Number(job.jobTime)).reduce((total, time) => total + time)).toFixed(2);
            //adds all expandedtime within jobData together
            totalExpandedTime = (jobData.map((job) => Number(job.expandedTime)).reduce((total, time) => total + time)).toFixed(2);
        }
        setTotalTimesheetTime(totalTimesheetTime)
        setTotalJobTime(totalJobTime)
        setOperatorHours(operatorHours)
        setTotalExpandedTime(totalExpandedTime)
        setExpandedTimePerJob(Number(expandedTimePerJob).toFixed(2))
        setAdminTime(Number(adminTime).toFixed(2))
        if(timesheet?.grainInvoices) setInvoiceData(timesheet.grainInvoices)
    }

    const goToNextSheet = () => {
        const list = props.location.timesheetList
        const index = list.findIndex(item => item.docId === timesheet?.docId);
        const nextIndex = (index + 1) % list.length;
        props.history.push({
            pathname: '/dashboard/reporting/timesheets',
            search: "?" + new URLSearchParams({ docId: list[nextIndex].docId }).toString(),
            timesheetList: list
        })
    }

    const goToPrevSheet = () => {
        const list = props.location.timesheetList
        const index = list.findIndex(item => item.docId === timesheet?.docId);
        const prevIndex = (index - 1) % list.length;
        props.history.push({
            pathname: '/dashboard/reporting/timesheets',
            search: "?" + new URLSearchParams({ docId: list[prevIndex].docId }).toString(),
            timesheetList: list
        })
    }

    const handleBack = () => {
        if(props.location.timesheetList){
            props.history.push({
                pathname: '/dashboard/approval/timesheets',
            })
        } else {
            props.history.goBack()
        }
    }

    const isSameDate = (inTime, outTime) => {
        const inDate = new Date(inTime);
        const outDate = new Date(outTime);
    
        return (
            inDate.getFullYear() === outDate.getFullYear() &&
            inDate.getMonth() === outDate.getMonth() &&
            inDate.getDate() === outDate.getDate()
        );
    }

    const isTimesheetOnSameDate = () => {
        if (timesheet.inTime < 9999999999) timesheet.inTime *= 1000;
        if (timesheet.outTime < 9999999999) timesheet.outTime *= 1000;

        // if there is no out time assume same date
        if (!timesheet.outTime) return true;

        return isSameDate(timesheet.inTime, timesheet.outTime);
    };
    
    return (
        <Grid className={classes.content}>
            {timesheetLoading || loading
            ?
            <Skeleton variant='rect' width={'73vw'} height={'85vh'} style={{marginTop: '80px'}}></Skeleton>
            :
            <Grid>
                <Grid className={classes.backButton}>
                    <Button variant="contained" startIcon={<ArrowBackIcon />}
                        onClick={() => handleBack()}>Back</Button>
                {props.location?.timesheetList &&
                    <Button variant="contained" startIcon={<ArrowBackIcon />} style={{marginLeft:'5px'}} color='secondary'
                    onClick={() => goToPrevSheet()}>
                        Prev Timesheet
                    </Button>
                } 
                {props.location?.timesheetList &&
                    <Button variant="contained" startIcon={<ArrowForwardIcon />} style={{marginLeft:'5px'}} color='secondary'
                    onClick={() => goToNextSheet()}>
                        Next Timesheet
                    </Button>
                }     
                </Grid>

                
                <Grid>    
                    {timesheetLoading ? null : 
                    <Grid 
                        // ref={qrRef}
                    >
                        <QRCode 
                            style={{ width:'1px',height:'1px', opacity: '0%'}}
                            id="qrcode"
                            value={"http://https://geeteeholdings.com/dashboard/reporting/timesheets?docId=" + timesheet.docId}
                            size={512}
                            bgColor={"#ffffff"}
                            fgColor={"#000000"}
                            level={"L"}
                            includeMargin={true}
                            renderAs={"canvas"}
                        />
                    </Grid>}
                    <Paper className={classes.paper} style={timesheet.approved ? {borderColor:'green', borderStyle: 'dashed', backgroundColor:'lightGreen'} : {}}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <TimesheetHeader
                                    timesheetProps={props} confirmSave={confirmSave} cancelSave={cancelSave}
                                    timesheetData={timesheet}
                                    userData={users.find(user => user.uuid === timesheet.uid)?.displayName}
                                    covidData={covidData}
                                    saving={saving} isTimesheetOnSameDate={isTimesheetOnSameDate} timesheetList={props?.location?.timesheetList}
                                    saveState={timesheetSaveState}
                                    setSaveState={setTimesheetSaveState}
                                    errorMessage={errorMessage}
                                    timesheetLoading={timesheetLoading}
                                    userLoading={usersLoading || !timesheet.uid}
                                    covidLoading={covidLoading}
                                    viewCovidData={viewCovidData}
                                    saveTimesheet={saveTimesheet}
                                    submitSheet={submitSheet}
                                    submitting={submitting}
                                    updateTimesheetField={updateTimesheetField}
                                    unsubmit={unsubmitSheet}
                                    updateType={updateType}
                                    unsubmitting={unsubmitting}
                                    setTimesheetLoading={setTimesheetLoading}
                                    uri={uri}
                                />
                            </Grid>
                            <Grid item md={6} xs={12}>
                                <TimesheetInputs
                                    date={timesheet.date}
                                    inTime={timesheet.inTime}
                                    outTime={timesheet.outTime}
                                    gateInTime={timesheet.gateInTime}
                                    gateOutTime={timesheet.gateOutTime}
                                    offHours={timesheet.offHours}
                                    litres={timesheet.litres}
                                    dollars={timesheet.dollars}
                                    usingGateInTime={timesheet.usingGateInTime}
                                    usingGateOutTime={timesheet.usingGateOutTime}
                                    skipLunch={timesheet.skipLunch ?? ''}
                                    skippedLunchOptions={skippedLunchOptions}
                                    timesheetStatus={timesheet.timesheetStatus ?? ''}
                                    timesheetStatuses={timesheetStatuses}
                                    updateTimesheetField={updateTimesheetField}
                                    updateStatus={updateStatus}
                                    updateLunch={updateLunch}
                                    timesheetLoading={timesheetLoading}
                                    timesheet={timesheet}
                                />
                            </Grid>
                            <Grid item md={6} xs={12}>
                                <TimesheetMeta
                                    totalTimesheetTime={totalTimesheetTime}
                                    totalJobTime={totalJobTime}
                                    operatorHours={operatorHours}
                                    totalExpandedTime={totalExpandedTime}
                                    expandedTimePerJob={expandedTimePerJob}
                                    jobCount={jobData.length}
                                    jobData={mergeJobData()}
                                    unitData={unitData}
                                    deliveries={deliveryData.length}
                                    jobsLoading={jobsLoading}
                                    timesheetLoading={timesheetLoading}
                                    submitted={timesheet?.submitted}
                                    unitsLoading={unitsLoading}
                                    deliveriesLoading={deliveriesLoading}
                                    units={Array.from(new Set(unitData.map(unit => unit.unitNumber))).join(', ')}
                                    updateTimesheetField={updateTimesheetField}
                                    adminTime={adminTime}
                                />
                            </Grid>

                            <Grid item xs={12}>
                                <TimesheetAdditionalCosts
                                    updateTimesheetField={updateTimesheetField}
                                    setAdditionalCostsLoading={setAdditionalCostsLoading}
                                    additionalCostsLoading={additionalCostsLoading}
                                    timesheet={timesheet}
                                />
                            </Grid>

                            {formData.length > 0 ?
                                <Grid item xs={12}>
                                    <FormTable 
                                        formData={formData} 
                                        formsLoading={formsLoading} 
                                    />
                                </Grid>
                            : null}

                            <Grid item xs={12}>
                                {timesheetLoading ?
                                    <Skeleton width='100%' height='200px'/>:
                                    <Notes
                                        notes={timesheet.notes}
                                        timesheet={timesheet}
                                        setTimesheet={setTimesheet}
                                        timesheetLoading={timesheetLoading}
                                        path={firestore.collection('timesheets').doc(timesheet.docId).collection('notes')}
                                        user={timesheet.uid}
                                    />
                                }
                            </Grid>

                            {mapElements.length > 0 ?
                                <Grid item xs={12} style={{ position: 'relative' }}>
                                    <TimeMap
                                        mapElements={mapElements}
                                        coordinateData={coordinateData}
                                        showCoordinateData={() => showCoordinateData(!coordinateData)}
                                    />
                                </Grid>
                            : null}

                            <Grid item xs={12}>
                                <TimesheetDetails
                                    timesheet={timesheet}
                                    history={props.history}
                                    jobData={mergeJobData()}
                                    updateJob={updateJob}
                                    createJob={createJob}
                                    unitData={unitData}
                                    updateUnit={updateUnit}
                                    createUnit={createUnit}
                                    deliveryData={deliveryData}
                                    updateDelivery={updateDelivery}
                                    createDelivery={createDelivery}
                                    currentUnit={currentUnit}
                                    setCurrentUnit={setCurrentUnit}
                                    currentChecklist={currentChecklist}
                                    setCurrentChecklist={setCurrentChecklist}
                                    createChecklist={createChecklist}
                                    currentUnitJobs={currentUnitJobs}
                                    setCurrentUnitJobs={setCurrentUnitJobs}
                                    currentJob={currentJob}
                                    setCurrentJob={setCurrentJob}
                                    currentDelivery={currentDelivery}
                                    setCurrentDelivery={setCurrentDelivery}
                                    customers={customers}
                                    currentCustomer={currentCustomer}
                                    setCurrentCustomer={setCurrentCustomer}
                                    newCustomer={newCustomer}
                                    setNewCustomer={setNewCustomer}
                                    createCustomer={createCustomer}
                                    jobCustomerAutocomplete={jobCustomerAutocomplete}
                                    setJobCustomerAutocomplete={setJobCustomerAutocomplete}
                                    deliveryCustomerAutocomplete={deliveryCustomerAutocomplete}
                                    setDeliveryCustomerAutocomplete={setDeliveryCustomerAutocomplete}
                                    batchUpdateUnits={batchUpdateUnits}
                                />
                            </Grid>

                            {jobData.length > 0 ?
                                <Grid item xs={12}>
                                    <JobTable
                                        jobData={mergeJobData()}
                                        unitData={unitData}
                                        history={props.history}
                                        updateJob={updateJob}
                                        updateUnit={updateUnit}
                                        customers={customers}
                                        expandedTimePerJob={expandedTimePerJob ?? 0}
                                        submitted={timesheet.submitted}
                                        createJob={createJob}
                                        timesheet={timesheet}
                                        deleteJob={deleteJob}
                                        jobsLoading={jobsLoading}
                                        handleAdminNotes={handleAdminNotes}
                                        currentJob={currentJob}
                                        setCurrentJob={setCurrentJob}
                                        currentCustomer={currentCustomer}
                                        setCurrentCustomer={setCurrentCustomer}
                                        newCustomer={newCustomer}
                                        setNewCustomer={setNewCustomer}
                                        createCustomer={createCustomer}
                                        jobCustomerAutocomplete={jobCustomerAutocomplete}
                                        setJobCustomerAutocomplete={setJobCustomerAutocomplete}
                                        batchUpdateUnits={batchUpdateUnits}
                                    />
                                </Grid>
                            : null}

                            {unitData.length > 0 ?
                                <Grid item xs={12}>
                                    <UnitTable 
                                        unitData={unitData} 
                                        updateUnit={updateUnit}
                                        createUnit={createUnit}
                                        history={props.history}
                                        unitsLoading={unitsLoading}
                                        userData={users}
                                        jobData={mergeJobData()}
                                        currentUnit={currentUnit}
                                        setCurrentUnit={setCurrentUnit}
                                        currentUnitJobs={currentUnitJobs}
                                        setCurrentUnitJobs={setCurrentUnitJobs}
                                        currentUnitJobsSaveState={currentUnitJobsSaveState}
                                        setCurrentUnitJobsSaveState={setCurrentUnitJobsSaveState}
                                        batchUpdateUnits={batchUpdateUnits}
                                        deleteUnit={deleteUnit}
                                        currentJob={currentJob}
                                        batchUpdateJobs={batchUpdateJobs}
                                    />
                                </Grid>
                            : null}

                            {workOrderData.length > 0 ?
                                <Grid item xs={12}>
                                    <WorkOrderTable 
                                        timesheet={timesheet} 
                                        workOrderData={workOrderData}
                                        workOrderLoading={workOrderLoading}
                                        />
                                </Grid>
                            : null}

                            {deliveryData.length > 0 ?

                                <Grid item xs={12} >
                                    <DeliveryTable 
                                        customers={customers} 
                                        deliveryData={deliveryData} 
                                        unitData={unitData} 
                                        timesheet={timesheet} 
                                        submitted={timesheet.submitted} 
                                        history={props.history}
                                        updateDelivery={updateDelivery} 
                                        createDelivery={createDelivery} 
                                        deleteDelivery={deleteDelivery}
                                        deliveriesLoading={deliveriesLoading}
                                        currentDelivery={currentDelivery}
                                        setCurrentDelivery={setCurrentDelivery}
                                        currentCustomer={currentCustomer}
                                        setCurrentCustomer={setCurrentCustomer}
                                        newCustomer={newCustomer}
                                        setNewCustomer={setNewCustomer}
                                        createCustomer={createCustomer}
                                        deliveryCustomerAutocomplete={deliveryCustomerAutocomplete}
                                        setDeliveryCustomerAutocomplete={setDeliveryCustomerAutocomplete}    
                                        handleAdminNotes={handleAdminNotes}
                                    />
                                </Grid>
                            : null}

                            {checklistData.length > 0 ?

                            <Grid item xs={12} >
                                <ChecklistTable 
                                    customers={customers} 
                                    checklistData={checklistData} 
                                    timesheet={timesheet} 
                                    submitted={timesheet.submitted} 
                                    history={props.history}
                                    checklistsLoading={checklistsLoading}
                                    currentChecklist={currentChecklist}
                                    setCurrentChecklist={setCurrentChecklist}
                                />
                            </Grid>
                            : null}

                            {/* convert to own component */}
                            {invoiceData.length > 0 ?
                                <Grid item xs={12}>
                                    <MaterialTable
                                    icons={TableIcons}
                                    style={{}}
                                    options={{
                                    toolbar: true,
                                    paging: true,
                                    search: true,
                                    emptyRowsWhenPaging: false,
                                    thirdSortClick: false,
                                    rowStyle: {
                                        whiteSpace: 'pre-wrap',
                                    },
                                }}
                                columns={[
                                    { title: 'Date', field: 'date' },
                                    { title: 'Invoice #', field: 'invoiceNum' },
                                    { title: 'Litres', field: 'litres'},
                                    { title: 'Seller', field: 'seller'},
                                    { title: 'Location', field: 'city', render: rowData => rowData.city + ', ' + rowData.province},
                                ]}
                                renderSummaryRow={({ column, data }) =>
                                    column.field === "invoiceNum"
                                    ? {
                                        value: 'Total Litres: ' + data.reduce((agg, row) => agg + row.litres, 0),
                                        style: { background: "#f3f3f3", fontWeight: 'bold' },

                                        } :
                                    column.field === "litres"
                                    ? {
                                            // reduce the total litres to a single number only if that rows province is MB
                                            value: 'MB Litres: ' +data.reduce((agg, row) => agg + (row.province === 'MB' ? row.litres : 0), 0),
                                        style: { background: "#f3f3f3", fontWeight: 'bold' },

                                        }
                                        :
                                    column.field === "seller"
                                    ? {
                                            // reduce the total litres to a single number only if that rows province is MB
                                            value: 'SK Litres: ' +data.reduce((agg, row) => agg + (row.province === 'SK' ? row.litres : 0), 0),
                                        style: { background: "#f3f3f3", fontWeight: 'bold' },

                                        }
                                        :
                                    column.field === "city"
                                    ? {
                                            // reduce the total litres to a single number only if that rows province is MB
                                            value: 'AB Litres: ' +data.reduce((agg, row) => agg + (row.province === 'AB' ? row.litres : 0), 0),
                                        style: { background: "#f3f3f3", fontWeight: 'bold' },

                                        }
                                    : undefined
                                }
                                data={invoiceData}
                                isLoading={timesheetLoading}
                                title={"Invoice Details"}
                                        />
                                </Grid>
                            : null}

                        </Grid>
                    </Paper>
                    </Grid>

                <Snackbar
                //disableAutoFocus={true}
                //disableEnforceFocus={true}
                open={ (!_.isEqual(timesheet, timesheetSaveState) && (!_.isUndefined(timesheet) && !_.isUndefined(timesheetSaveState)) ) }
                message={saving ? 'Saving...' : 'Save Document?'}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center'
                }}
                action={
                    <React.Fragment>
                        {saving
                            ? null
                            : <React.Fragment>
                                <Button variant='text' color='primary' onClick={cancelSave} style={{ marginLeft: '32px', marginRight: '8px' }}>Cancel</Button>
                                <Button variant='contained' onClick={confirmSave}>Confirm</Button>
                            </React.Fragment>
                        }
                    </React.Fragment>
                } />
            </Grid>
        }
        </Grid >
        
    )

}

export default withRouter(Timesheet)