import React, {useEffect, useState} from 'react';
import { makeStyles } from '@material-ui/core';
import { Grid, IconButton, Paper, Box,
AppBar, Tabs, Tab, useMediaQuery, Button, Checkbox, FormControlLabel } from '@material-ui/core';
import { withRouter } from 'react-router-dom';
import _ from 'lodash'
import PropTypes from 'prop-types';
import EditIcon from '@material-ui/icons/Edit';
import { useTheme } from '@material-ui/core/styles';
// icons
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import DeleteIcon from '@material-ui/icons/Delete';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import ReportIcon from '@material-ui/icons/Report';
import PrintIcon from '@material-ui/icons/Print';
import PersonIcon from '@material-ui/icons/Person';
import WorkIcon from '@material-ui/icons/Work';
import ContactPhoneIcon from '@material-ui/icons/ContactPhone';
import TimerIcon from '@material-ui/icons/Timer';
import CommentIcon from '@material-ui/icons/Comment';
import DateRangeIcon from '@material-ui/icons/DateRange';
import SchoolIcon from '@material-ui/icons/School';
// tabs
import EmploymentTab from './Tabs/EmploymentTab'
import InformationTab from './Tabs/InformationTab'
import ContactsTab from './Tabs/ContactsTab'
import NotesTab from './Tabs/NotesTab'
import SickDaysTab from './Tabs/SickDaysTab'
import TrainingTab from './Tabs/TrainingTab'

import UserTimesheetTable from './Tables/UserTimesheetTable';
import UserWorkOrdersTable from './Tables/UserWorkOrdersTable';
import UserReportTable from './Tables/UserReportTable';
import PaperTitle from '../Orders/OrderPage/PaperTitle';
import { useAuth } from '../../../providers/AuthContext';


const useStyles = makeStyles((theme) => ({
    btnGroup: {
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
    },
    tabBar: {
        width: '100%',
        maxWidth: '1000px',
        zIndex: '998'
    },
    tabs:{
        [theme.breakpoints.up("md")]: {
          margin:'5px'
        }
    },
    alias: {
        display: 'flex',
        marginTop: '8px',
        marginBottom: '8px',
        alignItems: 'center',
    },
    card: {
        padding: theme.spacing(2),
        display: 'flex',
        alignItems: 'center',
        height: '100%',
        maxWidth: '1000px',
    },
    content: {
        display: 'flex',
        width: '100%',
        flexDirection: 'column',
        maxWidth: '1000px',
        margin: 'auto',
    },
    paper: {
        borderRadius: '0px',
        margin: '0px',
        marginBottom:'20px',
        width: '100%',
        maxWidth: '1400px',
        //backgroundColor: 'rgb(252, 252, 249)',
        justifyContent: 'center',
        alignItems: 'center',
    },
    row: {
        display: 'flex',
        alignItems: 'center',
    },
    backButton: {
        marginTop: '20px',
    },
}));

const UserCard = (props) => {
    
    function TabPanel(props) {
        const { children, value, index, ...other } = props;
        
      
        return (
          <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
          >
            {value === index && (
              <Box style={{margin:'20px'}}>
                {children}
              </Box>
            )}
          </div>
        );
    }
      
    TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
    };
    
    function a11yProps(index) {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        };
    }

    const classes = useStyles();
    const theme = useTheme();
    const smallScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const [value, setValue] = React.useState(JSON.parse(sessionStorage.getItem('userTab'))|| 1);
    const [edit, setEdit] = React.useState(false);
    const [deleteOpen, setDeleteOpen] = useState(false);

    const { roles } = useAuth();

    useEffect(() => {

    }, [smallScreen]);

    let userCard = null;

    const handleChange = (event, newValue) => {
        setValue(newValue);
        sessionStorage.setItem("userTab", JSON.stringify(newValue))
    };

    const handleEdit = () => {
        if (edit)
            props.cancelSave();
                                                         
        setEdit(!edit);
    }

    const handleDeleteOpen = () => {
        setDeleteOpen(true);
    }

    const handleDeleteClose = () => {
        setDeleteOpen(false);
    }

    const darkMode = JSON.parse(window.localStorage.getItem('colorMode')) === 'dark' ? true : false;
    

    if (!_.isUndefined(props.userData)) {
        userCard = (
            <Grid className={classes.content}>

            <Grid className={classes.backButton}>
                <Button variant="contained" onClick={() => props.history.goBack()} startIcon={<ArrowBackIcon/>}>Back</Button>
            </Grid>
                
            <Paper className={classes.paper}>
                <Dialog
                    open={deleteOpen}
                    onClose={handleDeleteClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">{"Delete User?"}</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            Are you sure you want to delete this user?
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleDeleteClose} variant="contained" color="secondary">
                            No
                        </Button>
                        <Button onClick={(event) => {props.handleDeleteUser(); handleDeleteClose()}} variant="contained" style={{ backgroundColor: 'red', color: 'white' }} autoFocus>
                            Yes
                        </Button>
                    </DialogActions>
                </Dialog>
                </Paper>

                {edit || props.userData.employment?.active === true ? null :
                <Button style={{marginBottom:'10px', color: 'white', backgroundColor: 'red', float: 'right' }} onClick={() => handleDeleteOpen()}
                variant="contained" size="small" startIcon={<DeleteIcon/>} >Delete User</Button>}
               

                <Paper className={classes.paper}>
                    <div className={classes.content}>
                        <AppBar className={classes.tabBar} position="static" elevation={0} >
                            <Tabs value={value} onChange={handleChange} 
                            variant={smallScreen ? "scrollable" : "scrollable"} 
                            scrollButtons={"on"} 
                            style={{margin:'0px'}}>
                                
                            <IconButton onClick={(e) => props.history.push("/dashboard/users/reporting/printing/?docId=" + props.userData?.docId)} title="Print Employee"><PrintIcon /></IconButton>
                            <Tab value={1} label={<div><PersonIcon style={{verticalAlign: 'middle'}} /> Info </div>} {...a11yProps(0)} />
                            <Tab value={2} label={<div><WorkIcon style={{verticalAlign: 'middle'}} /> Employment </div>} {...a11yProps(1)} />
                            {roles.includes('View User Employment Info') &&  <Tab value={3} label={<div><ContactPhoneIcon style={{verticalAlign: 'middle'}} /> Emergency Contacts </div>}  {...a11yProps(2)} /> }
                            <Tab value={4} label={<div><TimerIcon style={{verticalAlign: 'middle'}} /> Timesheets </div>} {...a11yProps(3)} />
                            <Tab value={5} label={<div><TimerIcon style={{verticalAlign: 'middle'}} /> Work Orders </div>} {...a11yProps(3)} />
                            <Tab value={6} label={<div><SchoolIcon style={{verticalAlign: 'middle'}} /> Training </div>} {...a11yProps(3)} />
                            <Tab value={7} label={<div><CommentIcon style={{verticalAlign: 'middle'}} /> Notes </div>} {...a11yProps(4)} />
                            <Tab value={8} label={<div><ReportIcon style={{verticalAlign: 'middle'}} /> Reports </div>} {...a11yProps(5)} />
                            {roles.includes('View User Employment Info') &&  <Tab value={9} label={<div><DateRangeIcon style={{verticalAlign: 'middle'}} /> Sick Days </div>} {...a11yProps(6)} /> }
                            <IconButton onClick={handleEdit} style={edit ? { color: 'black', } : roles.includes('Create|Update|Delete Employees')  ? {visibility: 'visible'} : {visibility: 'hidden'}} title="Edit Employee"><EditIcon /></IconButton>
                            </Tabs>
                        </AppBar>

                    </div>
                    <Grid container style={{margin:'0px', padding:'10px', backgroundColor:darkMode?'grey':'lightgrey'}}>
                        <PaperTitle title={props.userData.displayName ? props.userData.displayName : props.userData.firstName + ' ' + props.userData.lastName}/>  
                    </Grid>
                    {roles.includes('Create|Update|Delete Employees') ? <FormControlLabel style={{ marginLeft: '10px', size:'20px'}}
                        control={<Checkbox checked={props.userData.employment?.active} onChange={(e) => 
                            props.updateUserData({ ...props.userData, 'employment':{ ...props.userData.employment, 'active': e.target.checked } })
                        } name="active" />}
                        label="Employee is Active"
                    />:null}
                    
                    <TabPanel value={value} index={9}>
                        <SickDaysTab
                        userData={props.userData} saveState={props.saveState} edit={edit}
                        cancelSave={props.cancelSave} confirmSave={props.confirmSave} 
                        updateUserData={props.updateUserData} saving={props.saving}
                        setUserData={props.setUserData} setSaveState={props.setSaveState}
                        />
                    </TabPanel>
                    <TabPanel value={value} index={8}>
                    <Grid container spacing={2}>
                        <Grid item sm={12} xs={12}>
                            <UserReportTable userData={props.userData} />
                        </Grid>
                    </Grid>    
                    </TabPanel>

                    <TabPanel value={value} index={7}>
                        <NotesTab
                        userData={props.userData} saveState={props.saveState} edit={edit}
                        cancelSave={props.cancelSave} confirmSave={props.confirmSave} 
                        updateUserData={props.updateUserData} saving={props.saving}
                        setUserData={props.setUserData} setSaveState={props.setSaveState}
                        />
                    </TabPanel>
                    <TabPanel value={value} index={6}>
                        <TrainingTab
                        userData={props.userData} saveState={props.saveState} edit={edit}
                        cancelSave={props.cancelSave} confirmSave={props.confirmSave} 
                        updateUserData={props.updateUserData} saving={props.saving}
                        setUserData={props.setUserData} setSaveState={props.setSaveState}
                        />
                    </TabPanel>
                    <TabPanel value={value} index={5}>
                    <Grid container spacing={2}>
                        <Grid item sm={12} xs={12}>
                            <UserWorkOrdersTable userData={props.userData} saveState={props.saveState}/>
                        </Grid>
                    </Grid>    
                    </TabPanel>
                    <TabPanel value={value} index={4}>
                    <Grid container spacing={2}>
                        <Grid item sm={12} xs={12}>
                            <UserTimesheetTable userData={props.userData} saveState={props.saveState}/>
                        </Grid>
                    </Grid>    
                    </TabPanel>
                    <TabPanel value={value} index={3}>
                        <ContactsTab
                        userData={props.userData} saveState={props.saveState} edit={edit}
                        cancelSave={props.cancelSave} confirmSave={props.confirmSave} 
                        updateUserData={props.updateUserData} saving={props.saving}
                        setUserData={props.setUserData} setSaveState={props.setSaveState}
                        />
                    </TabPanel>
                    <TabPanel value={value} index={2}>
                        <EmploymentTab
                        userData={props.userData} saveState={props.saveState} edit={edit}
                        cancelSave={props.cancelSave} confirmSave={props.confirmSave} 
                        updateUserData={props.updateUserData} saving={props.saving}
                        setUserData={props.setUserData} setSaveState={props.setSaveState}
                        permissions={roles}
                        />
                    </TabPanel>
                    <TabPanel value={value} index={1}>
                        <InformationTab
                        userData={props.userData} saveState={props.saveState} edit={edit}
                        cancelSave={props.cancelSave} confirmSave={props.confirmSave} 
                        updateUserData={props.updateUserData} saving={props.saving}
                        setUserData={props.setUserData} setSaveState={props.setSaveState}
                        permissions={roles}
                        />
                    </TabPanel>

                </Paper>
            </Grid>
        )
    }

    return (
        <div>
            {userCard}
        </div>
    )
}

export default withRouter(UserCard)