import React, { useState, useEffect, useRef } from 'react';
import {
    Button, Checkbox, Divider, FormControlLabel, Grid,
    InputLabel, Paper, IconButton, TextField, useMediaQuery, Typography,
    makeStyles, Select, MenuItem, Radio
} from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import { firestore } from '../../../firebase/firebase'
import DeleteIcon from '@material-ui/icons/Delete';
import { withRouter } from 'react-router';
import AddBoxIcon from '@material-ui/icons/AddBox';
import PrintIcon from '@material-ui/icons/Print';
import AssignmentIcon from '@material-ui/icons/Assignment';

const useStyles = makeStyles((theme) => ({
    card: {
        padding: theme.spacing(4),
        maxWidth: '800px',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        [theme.breakpoints.down('xs')]: {
            padding: theme.spacing(1),
        }
    },

    dialogCard: {
        padding: theme.spacing(4),
        maxWidth: '650px',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        maxHeight: '700px',
        [theme.breakpoints.down('xs')]: {
            padding: theme.spacing(1),
        }
    },

    image: {
        height: '100%',
        width: '100%',
        objectFit: 'scale-down',
    },

    modal: {
        width: '500px',
        padding: '16px',
        margin: '0 auto',
        marginTop: '50vh',
        backgroundColor: 'rgb(49, 49, 49)',
    },

    modalInput: {
        '& input': {
            color: 'white',
        },
    },

    headerPhoto: {
        height: '100%',
        width: '100%',
    },

    row: {
        display: 'flex',
        alignItems: 'center',
    },

    select: {
        display: 'flex',
        justifyContent: 'center'
    },
    title: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginBottom: '32px',
    },
}));

const ChecklistCard = (props) => {

    const classes = useStyles();
    const theme = useTheme();
    const smallScreen = useMediaQuery(theme.breakpoints.down('sm'));

    const [checklist, setChecklist] = useState({ ...props.checklist });
    const [error, setError] = useState('');
    const [open, setOpen] = React.useState(false);
    const [newCategory, setNewCategory] = useState("");
    //all catgory names stored as {{key: 'value'}, {key: 'value'}} with key being string identifier 
    const [state, setState] = useState({}) //newCategoryName
    //all category styles stored as {{key: 'value'} with key being string identifier
    const [style, setStyle] = useState({}) // newCategoryField
    //item user is interacting with ie 'Checkbox', 'Category', or 'Checklist', as well as category key (name) and checkbox key (name) if applicable. set on open
    const [currentItem, setCurrentItem] = useState({})
    const [currentType, setCurrentType] = useState("Checkbox")
    //used to keep focus on input field
    const [focusField, setFocusField] = useState(0)

    const inputRef = useRef();
    const isMounted = useRef(false)
    
    useEffect(() => {
        if(isMounted.current){
            inputRef.current.focus();
          } else {
           isMounted.current = true;
          }
    }, [focusField])

    useEffect(() => {
        if(newCategory.length === 0) {
            setError("")
        }
    }, [smallScreen, props, newCategory, state, currentType]);

    //opens delete snackbar and passes all needed info into currentItem hook
    const handleOpen = (interactable, categoryKey, checkboxValues) => {
        setCurrentItem({interactable: interactable, categoryKey: categoryKey, checkboxValues: checkboxValues})
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        //setCurrentItem({})
    };

    const HandleDelete = () => {
        if(currentItem.interactable === 'Checkbox'){
            handleDeleteCheckbox(currentItem.categoryKey, currentItem.checkboxValues)
        } else if(currentItem.interactable === 'Category'){
            handleDeleteCategory(currentItem.categoryKey)
        } else if(currentItem.interactable === 'Checklist'){ 
            handleDeleteChecklist()
        }
        handleClose()
    }

    const handleDeleteChecklist = () => {
        firestore.collection('premadeChecklists').doc(props.checklist.docId).delete()
            .then(() => {
                props.history.replace({pathname: '/dashboard/checklists'})
            }).catch((error) => {
                console.error("Error removing checklist: ", error);
            });
    }

    const handleDeleteCategory = (category) => {
        const newChecklist = { ...props.checklist }
        delete newChecklist.items[category]
        props.updateData({...newChecklist})
    }

    const handleDeleteCheckbox = (category, checkbox) => {
        const newChecklist = { ...props.checklist }
        newChecklist.items[category].items.splice(newChecklist.items[category].items.indexOf(checkbox), 1)
        props.updateData({...newChecklist})
    }

    const addCategory = () => {
        if(props.checklist.items[newCategory] !== undefined) {
            setError("Category already exists!")
        } else {
            props.updateData({ ...props.checklist, items: {...props.checklist.items, [newCategory]: {items: [], name: newCategory, valueSelected: newCategory, position: Object.keys(props.checklist.items).length++, type: currentType}} })
            setNewCategory("")
        }
    }

    const addCheckbox = (category) => {
        if(props.checklist.items[category].items[state[category]] !== undefined) {
            setError("Checkbox already exists!")
        } else {
            props.updateData({ ...props.checklist, items: {...props.checklist.items, [category]: {...props.checklist.items[category], items: [...props.checklist.items[category].items, {'name': state[category], 'checked': false, 'enteredText': '', 'valueSelected':state[category], 'style': style[category] ? style[category] : 'default' }]} }})
            delete state[category]
            setState({...state})
        }
    }

    //handles changes to checkbox name
    const handleChange = (category, checkbox, text) => {
        const newChecklist = { ...props.checklist }
        newChecklist.items[category].items[checkbox].name = text
        
        props.updateData({...newChecklist})
    }

    const handleCheckboxChange = (category, checkbox, newStyle) => {
        const newChecklist = { ...props.checklist }
        newChecklist.items[category].items[checkbox].style = newStyle

        props.updateData({...newChecklist})
    }

    //handle changes to category key
    const handleCategoryChange = (category, text) => {
        const newChecklist = { ...props.checklist }
        const prevStyle = style[category]
        const prevState = state[category]

        //updates checklists
        newChecklist.items[category].valueSelected = text 
        newChecklist.items[text] = newChecklist.items[category]
        delete newChecklist.items[category]
        props.updateData({...newChecklist})

        //updates style + category
        delete style[category]
        delete state[category]
        setStyle({...style, [text]: prevStyle})
        setState({...state, [text]: prevState})
    }

    //handle category type change
    const handleCategoryTypeChange = (category, type) => {
        const newChecklist = { ...props.checklist }
        newChecklist.items[category].type = type

        props.updateData({...newChecklist})
    }
    
    //handle category position change
    const handleCategoryPositionChange = (category, position) => {
        const newChecklist = { ...props.checklist }
        for(let item in newChecklist.items) {
            if(newChecklist.items[item].position === position) {
                newChecklist.items[item].position = newChecklist.items[category].position 
                newChecklist.items[category].position = position
                props.updateData({...newChecklist})
            }
        }
        props.updateData({...newChecklist})
    }

    let stuff = Object.keys(props.checklist.items).sort((a, b) => { return props.checklist.items[a].position > props.checklist.items[b].position ? 1 : -1 }).map((key, index) => {
        return (
                <Grid container key={key}>
                    <Paper style={{margin:'10px', padding:'10px', width:'100%', border: '4px solid orange'}}>

                        <Grid container style={{marginBottom: '30px'}}>

                            <Grid item xs={4}>
                                <Grid container>
                                    <Grid item xs={12}>
                                        <small>Name</small>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <input
                                            style={{  
                                                border: 0,
                                                outline: 0,
                                                background: 'transparent',
                                                borderBottom: '1px solid black',
                                                fontSize: '18px',
                                                marginTop: '4px',
                                                padding: '5px',
                                            }}
                                            label="Category Name"
                                            value={key}
                                            ref={inputRef}
                                            onChange={(e) => {
                                                handleCategoryChange(key, e.target.value)
                                                setFocusField(focusField + 1)
                                            }}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>

                            <Grid item xs={4}>
                                <Grid container>
                                    <Grid item xs={12}>
                                        <small>Type</small>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Select
                                            style={{margin: '5px'}}
                                            value={props.checklist.items[key].type}
                                            onChange={(event)=> {
                                                handleCategoryTypeChange(key, event.target.value)
                                            }}
                                        >
                                            <MenuItem value='Checkbox'>Checkbox Category</MenuItem>
                                            <MenuItem value='Radio Button'>Radio Button Category</MenuItem>
                                        </Select>
                                    </Grid>
                                </Grid>
                            </Grid>


                            <Grid item xs={2}>
                                <Grid container>
                                    <Grid item xs={12}>
                                        <small>Order</small>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Select
                                            label="Order"
                                            style={{margin: '5px'}}
                                            value={props.checklist.items[key].position ? props.checklist.items[key].position : 0}
                                            onClick={(event)=> {
                                                handleCategoryPositionChange(key, event.target.value)
                                            }}
                                        >
                                            {Object.keys(props.checklist.items).map((key,index) => {
                                                return (
                                                    <MenuItem key={index} value={index}>{index}</MenuItem>
                                                )
                                            })}
                                        </Select>
                                    </Grid>
                                </Grid>
                            </Grid>


                            <Grid item xs={2} >
                                {props.checklist.docId === undefined ? null :
                                <Button 
                                    onClick={() => {handleOpen('Category', key);}}
                                    variant="contained" 
                                    style={{color:'white', backgroundColor:'red', float: 'right', marginTop: '20px'}}
                                    startIcon={<DeleteIcon/>}
                                >
                                    Delete
                                </Button>
                                }
                            </Grid>
                        </Grid>

                        <Grid container >
                            <Grid item xs={5} style={{marginRight: '20x'}}>
                                <TextField
                                    placeholder={"Add new "+props.checklist.items[key].type}
                                    value={state[key] ? state[key] : ''}
                                    name={key}
                                    style={{margin: '5px', minWidth: '250px'}}
                                    onChange={(event) => {
                                        setState({...state, [key]: event.target.value})
                                    }}
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <Select
                                    style={{float:'right', margin: '5px', minWidth: '250px'}}
                                    value={style[key] ? style[key] : 'default'}
                                    onClick={(event)=> {
                                        setStyle({...style, [key]: event.target.value})
                                        
                                    }}
                                >
                                    <MenuItem value='default'>Default {props.checklist.items[key].type}</MenuItem>
                                    <MenuItem value='userEnteredText'>User Entered Text</MenuItem>
                                    <MenuItem value='photoUpload'>Photo Upload</MenuItem>
                                </Select>
                            </Grid>

                            <Grid item xs={3} >
                                <Button
                                    variant='contained'
                                    startIcon={<AddBoxIcon/>} 
                                    style={{float: 'right'}}
                                    onClick={(event) => {addCheckbox(key)} }
                                >
                                    Add
                                </Button>
                            </Grid>

                        </Grid>

                        <Grid item xs={12} style={{marginTop: '10px', }}>
                            <Grid container spacing={2}>
                                {props.checklist.items[key].items.length <=0  ? (
                                    <Grid item xs={12}>
                                        <small><i>No {props.checklist.items[key].type}s exist in {key}.</i></small>
                                    </Grid>
                                ) :                                             
                                <Grid item md={12} xs={12} >
                                    <Divider/>
                                </Grid>
                                }
                                

                                {props.checklist.items[key].items.map((item, index) => {
                                    return (
                                        <Grid container key={index} style={{margin: '5px',}}>
                                            
                                            <Grid item xs={6}>
                                                <Grid container>
                                                    <Grid item xs={2}>
                                                        {props.checklist.items[key].type === 'Radio Button' ? 
                                                        <Radio style={{ margin: '10px' , paddingTop: '5px'}}/> :
                                                        <Checkbox checked={true} style={{ margin: '10px' , paddingTop: '5px'}}/>}
                                                    </Grid>
                                                    <Grid item xs={10}>
                                                    <TextField
                                                            value={item.name}
                                                            multiline={true}
                                                            onChange={(e) => handleChange(key, index, e.target.value)}
                                                            style={{marginLeft: '10px', minWidth: '250px'}}
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </Grid>

                                            <Grid item xs={5}>
                                                <Select
                                                    onChange={(e) => {handleCheckboxChange(key, index, e.target.value)}}
                                                    style={{minWidth: '200px'}}
                                                    value={item.style}
                                                >
                                                    <MenuItem value='default'>Default {props.checklist.items[key].type}</MenuItem>
                                                    <MenuItem value='userEnteredText'>User Entered Text</MenuItem>
                                                    <MenuItem value='photoUpload'>Photo Upload</MenuItem>
                                                </Select>
                                            </Grid>

                                            <Grid item xs={1} >
                                                {props.checklist.docId === undefined ? null :
                                                <IconButton 
                                                    size="small" 
                                                    onClick={() => {handleOpen('Checkbox', key, item);}} 
                                                    style={{marginTop:'5px', color:'red', float: 'right', marginRight: '15px'  }}
                                                >
                                                    <DeleteIcon sx={{ fontSize: "18px" }} />
                                                </IconButton>
                                                }
                                            </Grid>

                                            <Grid item md={12} xs={12} >
                                                <Divider/>
                                            </Grid>

                                        </Grid>
                                       
                                )})}
                               
                            </Grid>
                        </Grid>
                   
                    </Paper>
                </Grid>
            
        )
    })
    

    return (
        <React.Fragment>  
            <Paper className={classes.card}>
                <Dialog
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">Delete {currentItem.interactable }?</DialogTitle>
                    <DialogActions>
                        <Button onClick={handleClose} variant="text">
                            No
                        </Button>
                        <Button onClick={() => HandleDelete(currentItem)} variant="contained" autoFocus>
                            Yes
                        </Button>
                    </DialogActions>
                </Dialog>
                
                <Grid container spacing={1}>

                    {props.checklist.docId === undefined ?
                    <Grid container spacing={2}>
                        <Grid item sm={12} className={classes.title}>
                            <AssignmentIcon style={{ marginRight: '8px' }} />
                            <Typography variant="h4" align="center">New Checklist</Typography>
                        </Grid>
                    </Grid>
                    : null
                    }

                    <Grid container>
                        <Grid item xs={8}>
                            <InputLabel>Title {props.checklist.docId === undefined ? '*' : null}</InputLabel>
                            <TextField
                                value={props.checklist.title}
                                required
                                variant='outlined'
                                onChange={(event) => {
                                    setChecklist({ ...checklist, 'title': event.target.value })
                                    props.updateData({ ...props.checklist, 'title': event.target.value })
                                }}
                                inputProps={smallScreen ? { style: { fontSize: '16px' } } : { style: { fontSize: '1.125rem' } }} />
                        </Grid>

                        {props.checklist.docId === undefined ? null :
                        <React.Fragment>
                            <Grid item xs={2}>
                                <Button variant="contained" 
                                    onClick={() => { props.history.push("/dashboard/checklists/printing/?docId=" + props.checklist?.docId) }}
                                    startIcon={<PrintIcon/>} 
                                    style={{float: 'right'}}
                                >
                                    Print
                                </Button>
                            </Grid>

                            <Grid item xs={2}>
                                <Button variant="contained" 
                                    onClick={() => {handleOpen('Checklist');}} 
                                    startIcon={<DeleteIcon/>} 
                                    style={{float: 'right', color:'white', backgroundColor:'red'}}
                                >
                                    Delete
                                </Button>
                            </Grid>
                        </React.Fragment>
                        }
                    </Grid>

                    <Grid item md={12} xs={12} style={{ display: 'flex', flexDirection: 'column', marginTop: '16px' }} className={classes.groupedInfo}>
                        <InputLabel>Description {props.checklist.docId === undefined ? '*' : null}</InputLabel>
                        <TextField
                            //label='Description'
                            value={props.checklist.description}
                            required
                            multiline
                            variant='outlined'
                            fullWidth
                            onChange={(event) => {
                                setChecklist({ ...checklist, 'description': event.target.value })
                                props.updateData({ ...props.checklist, 'description': event.target.value })
                            }}
                        />
                    </Grid>

                    <Grid item md={6} xs={6}>
                        <FormControlLabel
                            label="Checklist is Active"
                            control={
                                <Checkbox 
                                    variant="contained" 
                                    checked={props.checklist.active}
                                    onClick={(event) => {
                                        //props.updateData({ ...props.checklist, 'active': !props.checklist.active })
                                        props.updateData({ ...props.checklist, 'active': event.target.checked })
                                    }}>
                                    
                                </Checkbox>
                            } />
                    </Grid>

                    <Grid item md={6} xs={6}>
                        <FormControlLabel
                            label="Checklist Grabs Weather Data"
                            control={
                                <Checkbox 
                                    variant="contained" 
                                    checked={props.checklist.gathersWeatherData}
                                    onClick={(event) => {
                                        props.updateData({ ...props.checklist, 'gathersWeatherData': event.target.checked })
                                    }}
                                >
                                </Checkbox>
                            } />
                    </Grid>

                    <Grid item md={12} xs={12} >
                        <Divider/>
                    </Grid>

                    <Grid container spacing={1} style={{marginTop: '20px', marginBottom: '20px'}}>
                        <Grid xs={6} >
                            <TextField
                                placeholder={"Add new Category"}
                                value={newCategory || ""}
                                onChange={(event) => setNewCategory(event.target.value)}
                                style={{width: '400px',  marginTop: '5px'}}
                                multiline
                                />
                                <Typography style={{color:'red'}}><small>{error}</small></Typography>
                        </Grid>

                        <Grid xs={4}>
                            <Select
                                style={{float:'right', marginRight: '15px', width: '200px', marginTop: '5px'}}
                                value={currentType ? currentType : "Checkbox"}
                                onClick={(event)=> {setCurrentType(event.target.value) }}
                            >
                                <MenuItem value='Checkbox'>Checkbox Category</MenuItem>
                                <MenuItem value='Radio Button'>Radio Button Category</MenuItem>
                            </Select>
                        </Grid>

                        <Grid item xs={2}>
                            <Button
                                variant='contained'
                                style={{float: 'right'}}
                                startIcon={<AddBoxIcon/>} 
                                onClick={() => {addCategory()}}
                            >
                                Add
                            </Button>
                        </Grid>

                    </Grid>
                    
                    {stuff}

                    <Grid container>
                        <Grid item xs={12}>
                        </Grid>
                    </Grid>

                </Grid>
            </Paper >
        </React.Fragment>
    )
}

export default withRouter(ChecklistCard);