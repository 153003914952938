import React from 'react';
import MaterialTable from '@material-table/core';
import { TableIcons } from '../../../../constants/tableIcons';
import { Paper, Typography, Modal, Grid, Divider, Button, Checkbox } from '@material-ui/core';
import { makeStyles } from '@material-ui/core';
import { withRouter } from 'react-router-dom';
import moment from 'moment';
import Img from 'react-cool-img';
import truck from '../../../../img/geeteezoomzoom.gif';
import trucksad from '../../../../img/geeteesad.png';

const useStyles = makeStyles((theme) => ({
    content: {
        padding: '32px',
        paddingRight: '230px',
        overflow: 'auto'
    },
    sidebar: {
        position: 'fixed',
        right: 0,
        top: '64px',
        maxWidth: '256px',
        width: '100%',
        padding: '16px',
        minHeight: '100vh',
        zIndex: '1000',
    },
    expand: {
        position: 'fixed',
        right: 0,
        top: '444px',
        transform: 'rotate(90deg)',
        marginLeft: 'auto',
        transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.shortest,
        }),
    },
    expandOpen: {
        position: 'fixed',
        right: 253,
        top: '444px',
        transform: 'rotate(270deg)',
    },
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        //overflow: "scroll",
    },
    scroll: {
        maxHeight: '800px',
        overflowY: "scroll",
    },
    downloadModal: {
        marginRight: '60px',
        display: 'flex',
        alignItems: 'flex-start',
        justifyContent: 'center',
        paddingBottom: '100px',
        marginTop: '50px',
    },
    paper: {
        backgroundColor: 'rgba(1,1,1,0.9)',
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
    },
    dlpaper: {
        width: '390px',
     
        margin: '5px 5px 5px 5px',
    },
    form: {
        padding: theme.spacing(4),
        width: '600px',
    },
    card: {
        padding: theme.spacing(3),
        display: 'flex',
        height: '100%',
    },
    row: {
        display: 'flex',
        alignItems: 'center',
        wordWrap: 'anywhere',
    },

    weatherTitle: {
        display: 'flex',
        justifyContent: 'center',
        marginBottom: '16px',
    },

    end: {
        justifyContent: 'flex-end',
    },

    temp: {
        justifyContent: 'center',
        border: '4px solid white',
        borderRadius: '64px',
    },

    weatherCard: {
        backgroundColor: 'rgb(59, 98, 177)',
        boxShadow: 'inset 0px 0px 2px 2px rgba(0,0,0,0.3)',
    },
    downloadImg: {
        height: '225px',
        maxWidth: '300px'
    },
}));

const PhotoViewModal = (props) => {
    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 900,
        padding:'16px',
        border: null
      };

    const classes = useStyles();

    let UnitInfo = (  
        <Grid item xs={6}>
            <Paper style={{marginLeft: '50px', padding:'8px', height:'100%', width:'100%'}}>
                <Grid container>
                    <Grid item>
                        <Typography variant="h6" component="h2">
                            Unit #{props.rowSelected?.unitNumber}
                        </Typography>   
                    </Grid>
                </Grid>
                <Divider style={{marginBottom:'10px'}}></Divider>
                <Grid container spacing={1}>
                    <Grid item xs={6}>
                        <Typography  component="h2">
                            Trailer ID:
                        </Typography>   
                    </Grid>
                    <Grid item xs={6}>
                        <Typography  component="h2">
                            {props.rowSelected.trailerID ? props.rowSelected.trailerID : "N/A"}
                        </Typography>   
                    </Grid>
                    <Grid item xs={6}>
                        <Typography  component="h2">
                            Greased:
                        </Typography>   
                    </Grid>
                    <Grid item xs={6}>
                        <Typography  component="h2">
                            {props.rowSelected.greased ? "Yes" : "No"}
                        </Typography>   
                    </Grid>
                    <Grid item xs={6}>
                        <Typography  component="h2">
                            Odometer Units:
                        </Typography>   
                    </Grid>
                    <Grid item xs={6}>
                        <Typography  component="h2">
                            {props.rowSelected.odoUnits ? props.rowSelected.odoUnits : "N/A"}
                        </Typography>   
                    </Grid>
                    <Grid item xs={6}>
                        <Typography  component="h2">
                            Mileage In:
                        </Typography>   
                    </Grid>
                    <Grid item xs={6}>
                        <Typography  component="h2">
                            {props.rowSelected.mileageIn}
                        </Typography>   
                    </Grid>
                    <Grid item xs={6}>
                        <Typography  component="h2">
                            Mileage Out:
                        </Typography>   
                    </Grid>
                    <Grid item xs={6}>
                        <Typography  component="h2">
                            {props.rowSelected.mileageOut}
                        </Typography>   
                    </Grid>
                    <Grid item xs={6}>
                        <Typography  component="h2">
                            Fuel Added:
                        </Typography>   
                    </Grid>
                    <Grid item xs={6}>
                        <Typography  component="h2">
                        {props.rowSelected.fuelAdded ? props.rowSelected.fuelAdded : 0} {props.rowSelected.fuelUnitDisplay}
                        </Typography>   
                    </Grid>
                </Grid>
            </Paper>
        </Grid>
    )
    let ChecklistInfo = (  
        <Grid item xs={6}>
            <Paper style={{marginLeft: '50px', padding:'8px', height:'100%', width:'100%'}}>
                <Grid container>
                    <Grid item>
                        <Typography variant="h6" component="h2">
                            {props.rowSelected?.reason?.toUpperCase()}
                        </Typography>   
                    </Grid>
                </Grid>
                <Divider style={{marginBottom:'10px'}}></Divider>
                <Grid container spacing={1}>
                    <Grid item xs={6}>
                        <Typography  component="h2">
                            Service Reason:
                        </Typography>   
                    </Grid>
                    <Grid item xs={6}>
                        <Typography  component="h2">
                            {props.rowSelected.serviceReason ? props.rowSelected.serviceReason : "N/A"}
                        </Typography>   
                    </Grid>
                    <Grid item xs={6}>
                        <Typography  component="h2">
                            Service Type:
                        </Typography>   
                    </Grid>
                    <Grid item xs={6}>
                        <Typography  component="h2">
                        {props.rowSelected.serviceType ? props.rowSelected.serviceType : "N/A"}
                        </Typography>   
                    </Grid>
                    <Grid item xs={6}>
                        <Typography  component="h2">
                            Sand Amount:
                        </Typography>   
                    </Grid>
                    <Grid item xs={6}>
                        <Typography  component="h2">
                        {props.rowSelected.sandAmount ? props.rowSelected.sandAmount : "N/A"}
                        </Typography>   
                    </Grid>
                    <Grid item xs={12} className={classes.row}>
                        <Typography variant='h6'>Checked Items</Typography>
                    </Grid>
                    
                    {props.checklist.map((item, index) => {
                        return (
                            <Grid key={index} xs={12} item className={classes.row}>
                                
                                <Checkbox disabled checked={item[0]} size="small" />
                                <Typography ><small>{item[1]}</small></Typography>
                            </Grid>)
                    })}
                    {props.rowSelected.current ?
                    <Grid item xs={12}>
                        <Paper elevation={1} className={[classes.card, classes.weatherCard].join(' ')}>
                            <Grid container spacing={3} >
                                <Grid item xs={4} className={classes.row}>
                                    <Grid container>
                                        <Grid item xs={12}>
                                            <Typography style={{ color: 'white' }}>CLOUD: {props.rowSelected?.current.cloud}%</Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Typography style={{ color: 'white' }}>HMD: {props.rowSelected?.current.humidity}%</Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Typography style={{ color: 'white' }}>PCPN: {props.rowSelected?.current.precip_mm}mm</Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>

                                <Grid item xs={4} className={[classes.row, classes.temp].join(' ')}>
                                    <Grid container>
                                        <Grid item xs={12} className={classes.row} style={{ justifyContent: 'center' }}>
                                            <Typography variant='body1' style={{ color: 'white' }}>{props.rowSelected?.current.condition.text}</Typography>
                                        </Grid>
                                        <Grid item xs={12} className={classes.row} style={{ justifyContent: 'center' }}>
                                            <img alt="" src={props.rowSelected.current ? props.rowSelected.current.condition.icon : ""} />
                                            <Typography style={{ color: 'white' }}> &nbsp;{props.rowSelected?.current.temp_c}°C</Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>

                                <Grid item xs={4} className={classes.row}>
                                    <Grid container>
                                        <Grid item xs={12} className={[classes.row, classes.end].join(' ')}>
                                            <Typography  style={{ color: 'white' }}><small>WIND: {props.rowSelected?.current.wind_kph}km/h {props.rowSelected?.current.wind_dir}</small></Typography>
                                        </Grid>
                                        <Grid item xs={12} className={[classes.row, classes.end].join(' ')}>
                                            <Typography  style={{ color: 'white' }}>VSBY: {props.rowSelected?.current.vis_km}km</Typography>
                                        </Grid>
                                        <Grid item xs={12} className={[classes.row, classes.end].join(' ')}>
                                            <Typography  style={{ color: 'white' }}>PRES: {props.rowSelected?.current.pressure_in}Nm</Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} className={classes.row} style={{ justifyContent: 'center' }}>
                                    <Typography style={{ color: 'white' }}><small>Weather Data Created @ {props.rowSelected?.current.last_updated}</small></Typography>
                                </Grid>
                            </Grid>
                        </Paper>
                    </Grid>
                    : null}
                </Grid>
            </Paper>
        </Grid>
    )
    let JobInfo = (  
        <Grid item xs={6}>
            <Paper style={{marginLeft: '50px', padding:'8px', height:'100%', width:'100%'}}>
                <Grid container>
                    <Grid item>
                        <Typography variant="h6" component="h2">
                            {props.rowSelected?.customer} {props.rowSelected?.address}
                        </Typography>   
                    </Grid>
                </Grid>
                <Divider style={{marginBottom:'10px'}}></Divider>
                <Grid container spacing={1}>
                    <Grid item xs={6}>
                        <Typography  component="h2">
                            Job Type:
                        </Typography>   
                    </Grid>
                    <Grid item xs={6}>
                        <Typography  component="h2">
                            {props.rowSelected.jobType ? props.rowSelected.jobType?.toUpperCase() : "N/A"}
                        </Typography>   
                    </Grid>
                    <Grid item xs={6}>
                        <Typography  component="h2">
                            Time In:
                        </Typography>   
                    </Grid>
                    <Grid item xs={6}>
                        <Typography  component="h2">
                        {props.rowSelected.timeIn ? moment(props.rowSelected.timeIn).format('MMM DD YYYY hh:mm a') : "N/A"}
                        </Typography>   
                    </Grid>
                    <Grid item xs={6}>
                        <Typography  component="h2">
                            Time Out:
                        </Typography>   
                    </Grid>
                    <Grid item xs={6}>
                        <Typography  component="h2">
                        {props.rowSelected.timeOut ? moment(props.rowSelected.timeOut).format('MMM DD YYYY hh:mm a') : "N/A"}
                        </Typography>   
                    </Grid>
                    <Grid item xs={6}>
                        <Typography  component="h2">
                            Notes:
                        </Typography>   
                    </Grid>
                    <Grid item xs={6}>
                        <Typography  component="h2">
                        {props.rowSelected.notes ? props.rowSelected.notes : ""}
                        </Typography>   
                    </Grid>
                </Grid>
            </Paper>
        </Grid>
    )
    let DeliveryInfo = (  
        <Grid item xs={6}>
            <Paper style={{marginLeft: '50px', padding:'8px', height:'100%', width:'100%'}}>
                <Grid container>
                    <Grid item>
                        <Typography variant="h6" component="h2">
                            Customer: {props.rowSelected?.customer}
                        </Typography>   
                    </Grid>
                </Grid>
                <Divider style={{marginBottom:'10px'}}></Divider>
                <Grid container spacing={1}>
                    <Grid item xs={6}>
                        <Typography  component="h2">
                            From:
                        </Typography>   
                    </Grid>
                    <Grid item xs={6}>
                        <Typography  component="h2">
                            {props.rowSelected.from ? props.rowSelected.from : "N/A"}
                        </Typography>   
                    </Grid>
                    <Grid item xs={6}>
                        <Typography  component="h2">
                            To:
                        </Typography>   
                    </Grid>
                    <Grid item xs={6}>
                        <Typography  component="h2">
                        {props.rowSelected.to ? props.rowSelected.to : "N/A"}
                        </Typography>   
                    </Grid>
                    <Grid item xs={6}>
                        <Typography  component="h2">
                            Material:
                        </Typography>   
                    </Grid>
                    <Grid item xs={6}>
                        <Typography  component="h2">
                        {props.rowSelected.material ? props.rowSelected.material : "N/A"}
                        </Typography>   
                    </Grid>
                    <Grid item xs={6}>
                        <Typography  component="h2">
                            Quantity:
                        </Typography>   
                    </Grid>
                    <Grid item xs={6}>
                        <Typography  component="h2">
                        {props.rowSelected.quantity ? props.rowSelected.quantity : "N/A"}
                        </Typography>   
                    </Grid>
                    <Grid item xs={6}>
                        <Typography  component="h2">
                            Notes:
                        </Typography>   
                    </Grid>
                    <Grid item xs={6}>
                        <Typography  component="h2">
                        {props.rowSelected.notes ? props.rowSelected.notes : ""}
                        </Typography>   
                    </Grid>
                </Grid>
            </Paper>
        </Grid>
    )
    let DefectInfo = (  
            <Grid item xs={6}>
                <Paper style={{marginLeft: '50px', padding:'8px', height:'100%', width:'100%'}}>
                    <Grid container>
                        <Grid item>
                            <Typography variant="h6" component="h2">
                                Defect Report of Unit #{props.rowSelected?.unitNumber}
                            </Typography>   
                        </Grid>
                    </Grid>
                    <Divider style={{marginBottom:'10px'}}></Divider>
                    <Grid container spacing={1}>
                        <Grid item xs={6}>
                            <Typography  component="h2">
                                Trailer ID:
                            </Typography>   
                        </Grid>
                        <Grid item xs={6}>
                            <Typography  component="h2">
                                {props.rowSelected.trailerID ? props.rowSelected.trailerID : "N/A"}
                            </Typography>   
                        </Grid>
                        <Grid item xs={12}>
                            <Typography  component="h2">
                                Notes
                            </Typography>  
                            <Divider></Divider>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography  component="h2">
                            {props.rowSelected.additionalDetails ? props.rowSelected.additionalDetails : ""}
                            </Typography>   
                        </Grid>
                        <Grid item xs={12}>
                        <MaterialTable
                            columns={[
                                { title: 'Key', field: 'key' },
                                { title: 'Item', field: 'item' },
                                { title: 'Defects', render: rowData => rowData.noDefect ? "No" : "Yes" },
                            ]}

                            data={props.rowSelected.defects}
                            title={'Pre Trip Items'}
                            icons={TableIcons}
                            localization={{
                                header: {
                                    actions: 'Actions'
                                }
                            }}
                            options={{
                                padding: 'dense',
                                toolbar: true,
                                paging: false,
                                actionsColumnIndex: -1,
                                search: false,
                                emptyRowsWhenPaging: false,
                            }}
                        />
                        </Grid>
                    </Grid>
                </Paper>
            </Grid>
        )

    return(
        <React.Fragment>
            <Modal
                open={props.open}
                onClose={props.handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Paper style={style}>
                    <React.Fragment>
                        <Grid container>
                            <Grid container item>
                                <Grid item xs={12}>
                                    <Typography id="modal-modal-title" variant="h6" component="h2">
                                    {props.rowSelected?.origin?.charAt(0).toUpperCase() + props.rowSelected?.origin?.slice(1)} Photo
                                    </Typography>
                                </Grid>
                                <Grid item style={{marginTop:'6px'}}>
                                    <Typography id="modal-modal-description" component="h2">
                                    {props.rowSelected.employee}
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Grid container item>
                                <Grid item xs={7}>
                                    <Typography component="h2">
                                        {props.rowSelected.origin === "checklist" ? moment.unix(props.rowSelected.date).format('dddd, MMMM D, YYYY, h:mm a') : moment(props.rowSelected.date).format('dddd, MMMM D, YYYY, h:mm a')}
                                    </Typography>
                                </Grid>
                                <Grid item xs={2}>
                                    <Button style={{bottom:'15px'}} variant="contained" onClick={() => props.photosClickHandler(props.rowSelected.docId, props.rowSelected.origin)}
                                     >View {props.rowSelected?.origin?.charAt(0).toUpperCase() + props.rowSelected?.origin?.slice(1)}</Button>
                                </Grid>
                                <Grid item xs={3}>
                                    <Button variant="contained" style={{bottom:'15px'}} 
                                        onClick={() =>{props.handleDownloadSingle(props.rowSelected)}} >Download Photo</Button>
                                </Grid>
                            </Grid>
                        </Grid>
                            <Divider style={{margin:'5px'}}></Divider>
                        <Grid container spacing={1}>
                            <Grid container item>
                                <Grid item xs={5}>
                                    <Img
                                        style={{ width: '400px' }}
                                        placeholder={truck}
                                        error={trucksad}
                                        src={Array.isArray(props.rowSelected.photoURLs) ? props.rowSelected.photoURLs[0] : props.rowSelected.photoURLs}
                                        //error={errorImage}
                                        alt="Photo"
                                    />
                                </Grid>
                                
                                {props.rowSelected?.origin === 'unit' ? 
                                    UnitInfo : null}
                                {props.rowSelected?.origin === 'checklist' ? 
                                    ChecklistInfo : null}
                                {props.rowSelected?.origin === 'job' ? 
                                    JobInfo : null}
                                {props.rowSelected?.origin === 'delivery' ? 
                                    DeliveryInfo : null}
                                {props.rowSelected?.origin === 'defect' ? 
                                    DefectInfo : null}
                  
                            </Grid>
                        </Grid>
                    </React.Fragment>
                </Paper>
            </Modal>
        </React.Fragment>
    )

}

export default withRouter(PhotoViewModal)