import React from 'react';
import {  makeStyles, ButtonGroup, Button, Grid, Typography } from '@material-ui/core';
import DatePicker from '../../UI/DatePicker/DatePicker';
import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
import ClearIcon from '@material-ui/icons/Clear';
//import SearchIcon from '@material-ui/icons/Search';


const useStyles = makeStyles((theme) => ({
    btnGroup: {
        width: '100%',
        marginBottom:'5px'
    },

    filterItem: {
        marginTop: '2px',
        marginBottom: '2px',
    },

    search: {
        display: 'flex',
        boxShadow: 'inset 0px 0px 2px 2px rgba(207,207,207,0.49)',
        //backgroundColor: '#fafafa',
        borderRadius: '4px',
        
        padding: theme.spacing(2),
    },
    box: {
        boxShadow: 'inset 0px 0px 2px 2px rgba(207,207,207,0.49)',
        //backgroundColor: '#fafafa',
        borderRadius: '4px',
        marginBottom: '10px',
        padding: theme.spacing(1),
    }
}));

const OrderOptionsMobile = (props) => {

    const classes = useStyles();

    return (
        <Grid container spacing={2}>


            <Grid item sm={12}>
                <Grid container direction={'row'} className={classes.box}>

                    <TextField
                    label={"Customer"}
                    size="small"
                    fullWidth
                    value={props.customerSearch}
                    onChange={(event)=> {
                        props.setCustomerSearch(event.target.value)
                        sessionStorage.setItem("orderCustomerSearch", JSON.stringify(event.target.value))
                    }}
                    name="customer"
                    InputProps={props.customerSearch.length > 0 ? {
                        endAdornment: <IconButton onClick={(event) => {props.setCustomerSearch(''); sessionStorage.removeItem("orderCustomerSearch");}}><ClearIcon /></IconButton>
                    } : null} />

                    <TextField
                    label={"Order #"}
                    size="small"
                    fullWidth
                    value={props.orderNumSearch}
                    onChange={(event)=> {
                        props.setOrderNumSearch(event.target.value)
                        sessionStorage.setItem("orderOrderNumSearch", JSON.stringify(event.target.value))
                    }}
                    name="orderNumber"
                    InputProps={props.customerSearch.length > 0 ? {
                        endAdornment: <IconButton onClick={(event) => {props.setOrderNumSearch(''); sessionStorage.removeItem("orderOrderNumSearch")}}><ClearIcon /></IconButton>
                    } : null} />

                    <TextField
                    label={"Delivery Location"}
                    size="small"
                    fullWidth
                    value={props.deliveryLocationSearch}
                    onChange={(event)=> {
                        props.setDeliveryLocationSearch(event.target.value)
                        sessionStorage.setItem("orderDeliveryLocationSearch", JSON.stringify(event.target.value))
                    }}
                    name="deliveryLocation"
                    InputProps={props.customerSearch.length > 0 ? {
                        endAdornment: <IconButton onClick={(event) => {props.setDeliveryLocationSearch(''); sessionStorage.removeItem("orderDeliveryLocationSearch")}}><ClearIcon /></IconButton>
                    } : null} />

                </Grid>

                <Grid container direction={'row'} style={{marginBottom:'4px'}} >
                    
                        
                    <ButtonGroup fullWidth >
                        <Button value="submit" color={props.dateType === "submit" ? "primary" : "secondary"} 
                        variant={props.dateType === "submit" ? "contained" : "outlined"}
                        size="small"
                        onClick={() => props.dateTypeHandler("submit")}>Submitted</Button>
                        <Button value="prefer" color={props.dateType === "prefer" ? "primary" : "secondary"} 
                        variant={props.dateType === "prefer" ? "contained" : "outlined"}
                        size="small"
                        onClick={() => props.dateTypeHandler("prefer")}>Preferred</Button>
                    </ButtonGroup>
                </Grid>
                <Grid container direction={'row'} wrap='nowrap'>                
                    <DatePicker 
                            value={props.startDate}
                            onChange={props.startDateHandler}
                        />
                    <DatePicker
                        value={props.endDate}
                        onChange={props.endDateHandler}
                    />
                    

                
                </Grid>
            </Grid>

            <Grid item sm={12}>
                {(props.errorMsg && <Typography>{props.errorMsg}</Typography>)}
            </Grid>
            <Grid item sm={12} className={classes.btnGroup}>
                <Button variant="contained" fullWidth disabled={props.loading} size="small" onClick={props.generateHandler} > Generate </Button>
            </Grid>

        </Grid>
    )
}

export default OrderOptionsMobile;