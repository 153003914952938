import { firestore } from '../../firebase/firebase';
import moment from 'moment';

const errorReporter = (error, location) => {
    //do this because fuck session storage CLEAN THAT SHIT OUT
    // :)
    sessionStorage.clear();
    return (
    process.env.NODE_ENV !== 'development' &&

    firestore.collection('crashReports').add({ 
        sandbox: process.env.NODE_ENV === 'development', 
        name: error.name, 
        description: error.message, 
        stack: error.stack, 
        location: location.pathname,
        searchParams: location.search,
        time: moment().format("dddd, MMMM Do YYYY, h:mm:ss a").toString() 
    })
    .then(() => { console.warn('Error report sent.', error) })
    )
};

export default errorReporter