import React, {useState, useEffect} from 'react';
import { makeStyles } from '@material-ui/core';
import moment from 'moment'
import { Button, Grid, Paper, Typography } from '@material-ui/core';
import { withRouter, useHistory } from 'react-router-dom';
import InlineMediaViewer from '../../UI/MediaViewer/InlineMediaViewer'
import _ from 'lodash'
import MaterialTable from '@material-table/core';
import { TableIcons } from '../../../../constants/tableIcons';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import PrintIcon from '@material-ui/icons/Print';
import SearchIcon from '@material-ui/icons/Search';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import Notes from '../../../containers/Notes/Notes';
import { firestore } from '../../../../firebase/firebase';

const useStyles = makeStyles((theme) => ({
    btnGroup: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'flex-end',
    },
    card: {
        padding: theme.spacing(2),
        display: 'flex',
        alignItems: 'center',
        height: '100%',
    },
    content: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        maxWidth: '792px',
        margin: 'auto',
    },
    paper: {
        padding: theme.spacing(2),
        maxWidth: '792px',
        width: '100%',
        //backgroundColor: 'rgb(252, 252, 249)',
        justifyContent: 'center',
        alignItems: 'center',
    },
    row: {
        display: 'flex',
        alignItems: 'center',
        height: '100%',
    },
    backButton: {
        marginBottom: '20px',
        marginTop: '20px',
    },
}));

const UnitCard = (props) => {
    const [user, setUser] = useState('')
    const classes = useStyles();
    const history = useHistory();
    const path = "/dashboard/reporting/units/printing/?docId=" + props.unitData.docId
    const handlePrint = () => {
        //could wait for this to return, but holds up loading, user wont see until they return
        history.push(path)
    }

    let unitCard = null;
    useEffect(() => {
        const user = props.userData.filter(user => user.uuid === props.unitData.uid)
        if (user.length > 0) {
            setUser(user[0])
        }
    }, [props.userData, props.unitData.uid])


    const handleNextUnit = () => {
        let index = props.location.state.unitList.findIndex(x => x.docId === props.unitData.docId)
        if(index < props.location.state.unitList.length - 1){
            props.history.push({
                pathname:`/dashboard/reporting/timesheets/unit`,
                search: `?docId=${props.location.state.unitList[index + 1].docId}`,
                state: {unitList: props.location.state.unitList}
            })
        }
    }

    const handlePrevUnit = () => {
        let index = props.location.state.unitList.findIndex(x => x.docId === props.unitData.docId)
        if(index > 0){
            props.history.push({
                pathname:`/dashboard/reporting/timesheets/unit`,
                search: `?docId=${props.location.state.unitList[index - 1].docId}`,
                state: {unitList: props.location.state.unitList}
            })
        }
    }

    if (!_.isUndefined(props.unitData)) {

        //backward compatibilty for dates. 
        if (props.unitData.date < 9999999999) props.unitData.date *= 1000

        unitCard = (
            <Grid className={classes.content}>
                <Grid className={classes.backButton}>
                    <Button variant="contained" startIcon={<ArrowBackIcon />} onClick={() => 
                    props?.location?.state && props.location.state.unitList.length ? 
                    props.history.push({
                        pathname: '/dashboard/reporting/timesheets',
                        search: "?" + new URLSearchParams({ docId: props.unitData.timesheetID }).toString()})
                    : 
                    props.history.goBack()

                    } >Back</Button>
                    {props?.location?.state && props.location.state.unitList?.length > 1 && <React.Fragment>
                    <Button variant="contained" color="secondary" disabled={
                        props.location.state.unitList.findIndex(x => x.docId === props.unitData.docId) === 0
                    } style={{marginLeft:'5px'}} onClick={() => handlePrevUnit()} startIcon={<ArrowBackIcon/>}>Previous</Button>
                    <Button variant="contained" color="secondary" disabled={
                        props.location.state.unitList.findIndex(x => x.docId === props.unitData.docId) === props.location.state.unitList.length - 1
                    } style={{marginLeft:'5px'}} onClick={() => handleNextUnit()} startIcon={<ArrowForwardIcon/>}>Next</Button>
                    </React.Fragment>
                    }
                </Grid>
                <Paper className={classes.paper}>
                    <Grid container spacing={2}>
                        <Grid item xs={6} style={{ marginBottom: '32px' }}>
                            <Grid container>
                                <Grid item xs={12} className={classes.row}>
                                    <Typography variant='h4'>Unit {props.unitData?.unitNumber}</Typography>
                                </Grid>
                            </Grid>

                            <Typography variant='h6'>{user?.displayName}</Typography>
                            <Typography variant='h6'>{moment.utc(props.unitData.date).format('YYYY-MM-DD')}</Typography>
                        </Grid>

                        <Grid item xs={6} className={classes.btnGroup}>
                            <Button 
                                startIcon={<SearchIcon />}
                                variant='contained' 
                                style={{ marginBottom: '16px' }} 
                                onClick={()=> {
                                    props.history.push({
                                        pathname: '/dashboard/reporting/timesheets',
                                        search: "?" + new URLSearchParams({ docId: props.unitData.timesheetID }).toString(),
                                    }) }
                                }
                            >
                                View Related Timesheet
                            </Button>

                            <Button 
                                startIcon={<PrintIcon />}
                                variant='contained'
                                onClick={handlePrint}
                            >
                                Print Unit
                            </Button>

                            {props.unitData.defectID !== "" ? 
                                <Button 
                                    variant='contained' 
                                    onClick={()=> {
                                        props.history.push({
                                            pathname: '/dashboard/reporting/timesheets/defect',
                                            search: "?" + new URLSearchParams({ docId: props.unitData.defectID }).toString(),
                                        }) }
                                    }
                                >
                                    View Related Defect
                                </Button> 
                            : null}
                        </Grid>

                        <Grid item xs={6}>
                            <Paper className={classes.card}>
                                <Grid container spacing={2}>
                                    <Grid item xs={5} className={classes.row}>
                                        <Typography variant='body1'>Mileage In:</Typography>
                                    </Grid>
                                    <Grid item xs={7} className={classes.row}>
                                        <Typography variant='body1'>{props.unitData.mileageIn}</Typography>
                                    </Grid>
                                    <Grid item xs={5} className={classes.row}>
                                        <Typography variant='body1'>Mileage Out:</Typography>
                                    </Grid>
                                    <Grid item xs={7} className={classes.row}>
                                        <Typography variant='body1'>{props.unitData.mileageOut}</Typography>
                                    </Grid>
                                    <Grid item xs={5} className={classes.row}>
                                        <Typography variant='body1'>Fuel Added</Typography>
                                    </Grid>
                                    <Grid item xs={7} className={classes.row}>
                                        <Typography variant='body1'>{props.unitData.fuelAdded ? props.unitData.fuelAdded : 0} {props.unitData.fuelUnitDisplay}</Typography>
                                    </Grid>
                                </Grid>
                            </Paper>
                        </Grid>

                        

                        <Grid item xs={6}>
                            <Paper className={classes.card}>
                                <Grid container spacing={2}>
                                    <Grid item xs={6} className={classes.row}>
                                        <Typography variant='body1'>Trailer ID:</Typography>
                                    </Grid>
                                    <Grid item xs={6} className={classes.row}>
                                        <Typography variant='body1'>{props.unitData.trailerID ? props.unitData.trailerID : "N/A"}</Typography>
                                    </Grid>
                                    <Grid item xs={6} className={classes.row}>
                                        <Typography variant='body1'>Greased:</Typography>
                                    </Grid>
                                    <Grid item xs={6} className={classes.row}>
                                        <Typography variant='body1'>{props.unitData.greased ? "Yes" : "No"}</Typography>
                                    </Grid>
                                    <Grid item xs={6} className={classes.row}>
                                        <Typography variant='body1'>Odometer Units:</Typography>
                                    </Grid>
                                    <Grid item xs={6} className={classes.row}>
                                        <Typography variant='body1'>{props.unitData.odoUnits ? props.unitData.odoUnits : "N/A"}</Typography>
                                    </Grid>
                                </Grid>
                            </Paper>
                        </Grid>
                        <Grid item xs={12}>
                            <Notes
                                notes={props.unitData.notes}
                                timesheetLoading={props.loading}
                                path={firestore.collection('units').doc(props.unitData.docId).collection('notes')}
                                user={user?.uuid}
                            />
                        </Grid>

                        {props.unitData?.repairNotes && props.unitData?.repairNotes !== "" ?
                            <Grid item xs={12}>
                                <Paper className={classes.card}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12} className={classes.row}>
                                            <Typography variant='body1'><strong>Repair Notes</strong></Typography>
                                        </Grid>
                                        <Grid item xs={12} className={classes.row}>
                                            <Typography>{props.unitData.repairNotes}</Typography>
                                        </Grid>
                                    </Grid>
                                </Paper>
                            </Grid>
                            : null}

                        <Grid item xs={12}>
                            <MaterialTable
                                columns={[
                                    { title: 'Key', field: 'key' },
                                    { title: 'Item', field: 'item' },
                                    { title: 'Defects', render: rowData => rowData.noDefect ? "No" : "Yes" },
                                ]}

                                data={props.unitData.preTripItems}
                                title={'Pre Trip Items'}
                                icons={TableIcons}
                                localization={{
                                    header: {
                                        actions: 'Actions'
                                    }
                                }}
                                options={{
                                    padding: 'dense',
                                    toolbar: true,
                                    paging: false,
                                    actionsColumnIndex: -1,
                                    search: false,
                                    emptyRowsWhenPaging: false,
                                }}
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <MaterialTable
                                columns={[
                                    { title: 'Key', field: 'key' },
                                    { title: 'Item', field: 'item' },
                                    { title: 'Defects', render: rowData => rowData.noDefect ? "No" : "Yes" },
                                ]}

                                data={props.unitData.postTripItems}
                                title={'Post Trip Items'}
                                icons={TableIcons}
                                localization={{
                                    header: {
                                        actions: 'Actions'
                                    }
                                }}
                                options={{
                                    padding: 'dense',
                                    toolbar: true,
                                    paging: false,
                                    actionsColumnIndex: -1,
                                    search: false,
                                    emptyRowsWhenPaging: false,
                                }}
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <InlineMediaViewer photoURLs={props.unitData.photoURLs} />
                        </Grid>
                    </Grid>
                </Paper>
            </Grid>
        )
    }

    return (
        <div>
            {unitCard}
        </div>
    )
}

export default withRouter(UnitCard)