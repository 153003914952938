import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import { Grid, Typography, Button, Dialog, DialogActions, DialogContent, DialogTitle, } from '@material-ui/core'
import UnitModal from './Modals/UnitModal';
import JobModal from './Modals/JobModal';
import DeliveryModal from './Modals/DeliveryModal';
import ChecklistModal from './Modals/ChecklistModal';

const TimesheetHeader = (props) => {
    const [unitModal, setUnitModal] = useState(false);
    const [jobModal, setJobModal] = useState(false);
    const [deliveryModal, setDeliveryModal] = useState(false);
    const [checklistModal, setChecklistModal] = useState(false);
        
    const openUnitModal = () => {
        props.setCurrentUnit({})
        props.setCurrentUnitJobs([])
        setUnitModal(true)
    }

    const closeUnitModal = () => {
        setUnitModal(false)
    }
    
    const openJobModal = () => {
        props.setCurrentJob({});
        props.setCurrentCustomer({})
        props.setJobCustomerAutocomplete("")
        props.setNewCustomer(false)
        setJobModal(true);
    }

    const closeJobModal = () => {
        setJobModal(false);
    }

    const openDeliveryModal = () => {
        props.setCurrentDelivery({})
        props.setCurrentCustomer({})
        props.setDeliveryCustomerAutocomplete("")
        props.setNewCustomer(false)
        setDeliveryModal(true)
    }

    const closeDeliveryModal = () => {
        setDeliveryModal(false)
    }

    const openChecklistModal = () => {
        props.setCurrentChecklist({})
        setChecklistModal(true)
    }

    const closeChecklistModal = () => {
        setChecklistModal(false)
    }


    return (
        <Grid container style={{marginTop: '30px', marginBottom: '30px'}}>
            {/* Unit Modal */}
            <Dialog 
                fullWidth
                keepMounted
                open={unitModal}
                onClose={closeUnitModal}
            >
                <DialogTitle id="alert-dialog-title">Create Unit</DialogTitle>

                <DialogContent>
                    <UnitModal
                        jobData={props.jobData}
                        currentUnit={props.currentUnit}
                        setCurrentUnit={props.setCurrentUnit}
                        currentUnitJobs={props.currentUnitJobs}
                        setCurrentUnitJobs={props.setCurrentUnitJobs}
                    />
                </DialogContent>

                <DialogActions>
                    <Button 
                        variant="contained" 
                        onClick={() => {
                            props.createUnit(props.currentUnit, props.jobData.filter(job=> props.currentUnitJobs.includes(job.docId))); 
                            closeUnitModal();
                        }}
                    >
                        Save
                    </Button>
                    <Button 
                        variant="contained" 
                        color="secondary" 
                        onClick={() => closeUnitModal()}
                    >
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>

            {/* Job Modal */}
            <Dialog 
                fullWidth
                keepMounted
                open={jobModal}
                onClose={closeJobModal}
            >
                <DialogTitle id="alert-dialog-title">Create Job</DialogTitle>

                <DialogContent>
                    <JobModal
                        jobData={props.jobData}
                        currentJob={props.currentJob}
                        setCurrentJob={props.setCurrentJob}
                        closeJobModal={closeJobModal}
                        createJob={props.createJob}
                        jobModalOpenInEdit={false}
                        updateJob={props.updateJob}
                        updateUnit={props.updateUnit}
                        customers={props.customers}
                        unitData={props.unitData}
                        currentCustomer={props.currentCustomer}
                        setCurrentCustomer={props.setCurrentCustomer}
                        newCustomer={props.newCustomer}
                        createCustomer={props.createCustomer}
                        setNewCustomer={props.setNewCustomer}
                        jobCustomerAutocomplete={props.jobCustomerAutocomplete}
                        setJobCustomerAutocomplete={props.setJobCustomerAutocomplete}
                        batchUpdateUnits={props.batchUpdateUnits}
                    />
                </DialogContent>
            </Dialog>

            {/* Delivery Modal */}
            <Dialog 
                fullWidth
                keepMounted
                open={deliveryModal}
                onClose={closeDeliveryModal}
            >
                <DialogTitle id="alert-dialog-title">Create Deliviery</DialogTitle>

                <DialogContent>
                    <DeliveryModal
                        deliveryData={props.deliveryData}
                        currentDelivery={props.currentDelivery}
                        setCurrentDelivery={props.setCurrentDelivery}
                        customers={props.customers}
                        currentCustomer={props.currentCustomer}
                        setCurrentCustomer={props.setCurrentCustomer}
                        newCustomer={props.newCustomer}
                        setNewCustomer={props.setNewCustomer}
                        unitData={props.unitData}
                        deliveryCustomerAutocomplete={props.deliveryCustomerAutocomplete}
                        setDeliveryCustomerAutocomplete={props.setDeliveryCustomerAutocomplete}
                    />
                </DialogContent>

                <DialogActions>
                    <Button 
                        variant="contained" 
                        onClick={() => { 
                            let deliveryContainer = {};
                            if(props.newCustomer){
                                deliveryContainer = {...props.currentDelivery, customer: props.currentCustomer.name };
                                 props.createCustomer(props.currentCustomer); 
                            }
                            else{
                                deliveryContainer = props.currentDelivery;     
                            }

                            props.createDelivery({...deliveryContainer}); 
                            closeDeliveryModal();
                        }}
                    >
                        Save
                    </Button>
                    <Button 
                        variant="contained" 
                        color="secondary" 
                        onClick={() => closeDeliveryModal()}
                    >
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>

        {/* Checklist Modal */}
        <Dialog 
                fullWidth
                keepMounted
                open={checklistModal}
                onClose={closeChecklistModal}
            >
                <DialogTitle id="alert-dialog-title">Add Checklist</DialogTitle>

                <DialogContent>
                    <ChecklistModal
                        currentChecklist={props.currentChecklist}
                        setCurrentChecklist={props.setCurrentChecklist}
                        jobData={props.jobData}
                    />
                </DialogContent>

                <DialogActions>
                    <Button 
                        variant="contained" 
                        onClick={() => { 
                            let checklistContainer = {};
                            checklistContainer = props.currentChecklist;     
                            props.createChecklist({...checklistContainer}); 
                            closeChecklistModal();
                        }}
                    >
                        Save
                    </Button>
                    <Button 
                        variant="contained" 
                        color="secondary" 
                        onClick={() => closeChecklistModal()}
                    >
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>

            <Grid item xs={9}>
                <Typography variant='h6'> Timesheet Details </Typography>
            </Grid>

            <Grid item xs={4}>
                <Grid container style={{display: 'flex'}}>
                    <Grid>
                        <Button 
                            style={{float: 'right', margin:'4px'}}
                            size='small'
                            variant= 'contained'
                            onClick={(e) => { openJobModal() }}
                        >
                            Add Job
                        </Button>
                    </Grid>
                    <Grid >
                        <Button 
                            style={{float: 'right', margin:'4px'}}
                            variant= 'contained'
                            size='small'
                            onClick={(e) => { openUnitModal() }}
                        >
                            Add Unit
                        </Button>
                    </Grid>
                    <Grid >
                        <Button 
                            style={{float: 'right', margin:'4px'}}
                            disabled={props.unitData.length > 0 ? false : true}
                            variant= 'contained'
                            size='small'
                            onClick={(e) => { openDeliveryModal() }}
                        >
                            Add Delivery
                        </Button>
                    </Grid>
                    <Grid >
                        <Button 
                            style={{float: 'right', margin:'4px'}}
                            //disabled={props.unitData.length > 0 ? false : true}
                            variant= 'contained'
                            size='small'
                            onClick={(e) => { openChecklistModal() }}
                        >
                            Add Checklist
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
            

        </Grid>
    )
}
export default withRouter(TimesheetHeader)