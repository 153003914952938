import React, { useContext, useState } from "react"
import { firestore } from "../firebase/firebase"

const FormContext = React.createContext()

export function useForm() {
  return useContext(FormContext);
}

export function FormProvider({ children }) {

  const [forms, setForms] = useState([]);
  const [formsLoading, setFormsLoading] = useState(true);
  const [error, setError] = useState('');

  function chunkArray(array, size) {
    const chunks = [];
    for (let i = 0; i < array.length; i += size) {
      chunks.push(array.slice(i, i + size));
    }
    return chunks;
  }
  
  function getForms() {
    firestore.collection("pdfforms").get()
      .then(querySnapshot => {
        const formResponse = querySnapshot.docs.map(doc => ({
          ...doc.data(),
          'docId': doc.id,
        }));
  
        let formTitles = formResponse.map(form => form.name);
        let formPromises = [];
  
        // Split formTitles into chunks of 10 for `not-in` queries
        const chunks = chunkArray(formTitles, 10);
  
        chunks.forEach(chunk => {
          formPromises.push(
            firestore.collection("documents").where("name", "not-in", chunk).get()
          );
        });
  
        Promise.all(formPromises).then(querySnapshots => {
          querySnapshots.forEach(querySnapshot => {
            querySnapshot.docs.forEach(doc => {
              const docData = { name: doc.data().name };
              if (!formTitles.includes(docData.name)) {
                formResponse.push(docData);
                formTitles.push(docData.name);
              }
            });
          });
  
          setFormsLoading(false);
          setForms(formResponse);
        });
      })
      .catch(error => {
        setFormsLoading(false);
        setError(error.message);
        console.log(error.message);
      });
  }
  
  function getForm(name) {
    return forms?.filter(form => form.name === name)
  }

  const value = {
    forms,
    formsLoading,
    error,
    getForms,
    getForm,
  }

  return (
    <FormContext.Provider value={value}>
      {children}
    </FormContext.Provider>
  )
}