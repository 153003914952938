import React, { useState } from 'react';
import { Grid, makeStyles,  Button, Divider,
 Paper, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle,
 Typography} from '@material-ui/core';
import { withRouter } from 'react-router';
import PaperTitle from '../../../components/Orders/OrderPage/PaperTitle';
import { firestore,  } from '../../../../firebase/firebase'
import moment from 'moment';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import GetAppIcon from '@material-ui/icons/GetApp';

const useStyles = makeStyles((theme) => ({
  content: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    },
  grid: {
    marginTop: theme.spacing(2),
    padding:'10px',
    maxWidth: '500px',
  },
}));

const MemoDrafts = (props) => {
    const classes = useStyles();

    const [docId, setDocId] = useState("");
    const [open, setOpen] = useState(false);

    //function to format string to 30 characters then ...
    const formatString = (string) => {
        if(string.length > 200){
            return string.substring(0, 200) + '...'
        }
        return string
    }

    const deleteDraft = (docId) => {
        firestore.collection('emailDrafts').doc(docId).delete().then(() => {
            console.log("Document successfully deleted!");
            //refresh the drafts
            props.getDrafts();
        }).catch((error) => {
            console.error("Error removing document: ", error);
        });
    }

    const stripHtml = (html) => {
        var doc = new DOMParser().parseFromString(html, 'text/html');
        return doc.body.textContent || "";
    }

  return (
    <Paper className={classes.grid} >
        <Dialog
            open={open}
            onClose={() => setOpen(false)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">{"Delete Draft"}</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    Are you sure you want to delete this draft?
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button variant="contained" onClick={() => setOpen(false)} color="primary">
                    Cancel
                </Button>
                <Button variant="contained" onClick={() => {
                    deleteDraft(docId);
                    setOpen(false);
                }} color="primary" autoFocus startIcon={<DeleteForeverIcon/>}>
                    Delete
                </Button>
            </DialogActions>
        </Dialog>
        <PaperTitle title="Drafts" />
        <Divider style={{margin:'10px'}}/>
        <Grid container spacing={2} style={{
            //make scrollable
            maxHeight: '600px',
            overflowY: 'auto',
            padding: '10px'

        }}>
            {props.draftData.map((draft) => (
                <Grid item xs={12} key={draft.docId} style={{}}>
                    <Typography variant="h6" style={{marginBottom: '10px'}}>{draft.subject}</Typography>
                    <Typography variant="p" style={{margin: '10px', color:'grey'}}>{formatString(stripHtml(draft.content))}</Typography>
                    <Grid item xs={12}>
                        <Typography variant="caption" style={{marginBottom: '10px', marginTop:'15px', color:'grey'}}>Updated {moment(draft.dateUpdated.toDate()).format('MMM DD YYYY, hh:mm a')}</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Button  style={{margin:'5px'}} startIcon={<GetAppIcon/>}
                            variant="contained" color="primary" size="small"
                            onClick={() => {
                                props.setSubject(draft.subject);
                                props.setContent(draft.content);
                                props.setAttachments(draft.attachments);
                                props.setEmailList(draft.emailList);
                                props.setDocId(draft.docId);
                            }}
                        >
                            Load Draft
                        </Button>
                        <Button 
                            variant="contained" color="primary" size="small" style={{color:'white', backgroundColor:'red'}} startIcon={<DeleteForeverIcon/>}
                            onClick={() => {setOpen(true); setDocId(draft.docId)}}
                        >
                            Delete Draft
                        </Button>
                    </Grid>
                    <Divider/>
                </Grid>
            ))}
        </Grid>
    </Paper>
  )
}

export default withRouter(MemoDrafts);