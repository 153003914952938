import React, { useState, useEffect } from 'react';
import moment from 'moment'
import MaterialTable from '@material-table/core';
import { TableIcons } from '../../../../constants/tableIcons';
import { firestore } from '../../../../firebase/firebase'
import ChecklistOptions from '../../../components/Reports/Checklists/ChecklistOptions'
import { Paper, IconButton } from '@material-ui/core';
import ReportSelect from '../../../components/Reports/ReportSelect'
import { useUser } from '../../../../providers/UserContext'
import { withRouter } from 'react-router-dom';
import { makeStyles, useMediaQuery } from '@material-ui/core';
import clsx from 'clsx';
import { useTheme } from '@material-ui/core/styles';
import Collapse from '@material-ui/core/Collapse';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Skeleton } from '@material-ui/lab';


const useStyles = makeStyles((theme) => ({
    content: {
        padding: '32px',
        paddingRight: '230px',
        overflow: 'auto'
    },

    sidebar: {
        position: 'fixed',
        right: 0,
        top: '64px',
        maxWidth: '256px',
        width: '100%',
        padding: '16px',
        zIndex: '1000',
        overflowY: 'auto',
        minHeight: "calc(100vh - 64px)",
        maxHeight: "calc(100vh - 64px)",
    },
    expand: {
        position: 'fixed',
        right: 0,
        top: '444px',
        transform: 'rotate(90deg)',
        marginLeft: 'auto',
        transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.shortest,
        }),
    },
    expandOpen: {
        position: 'fixed',
        right: 253,
        top: '444px',
        transform: 'rotate(270deg)',
    },
}));

const ChecklistReport = (props) => {
    const { users, usersLoading, getUsers } = useUser();
    const classes = useStyles();
    const theme = useTheme();
    const smallScreen = useMediaQuery(theme.breakpoints.down('sm'));

    const [customerValue, setCustomerValue] = useState("checklist_CustomerValue" in sessionStorage ? JSON.parse(sessionStorage.getItem("checklist_CustomerValue")) : "")
    const [addressValue, setAddressValue] = useState("checklist_AddressValue" in sessionStorage ? JSON.parse(sessionStorage.getItem("checklist_AddressValue")) : "")
    const [employeeValue, setEmployeeValue] = useState("checklist_EmployeeValue" in sessionStorage ? JSON.parse(sessionStorage.getItem("checklist_EmployeeValue")) : "")
    const [serviceTypeValue, setServiceTypeValue] = useState("checklist_ServiceTypeValue" in sessionStorage ? JSON.parse(sessionStorage.getItem("checklist_ServiceTypeValue")) : "")


    useEffect(() => {
        getUsers();
    }, []) // eslint-disable-line react-hooks/exhaustive-deps


    const [startDate, setStartDate] = useState("startDate" in sessionStorage ? moment(JSON.parse(sessionStorage.getItem("startDate"))) : moment());
    const [endDate, setEndDate] = useState("endDate" in sessionStorage ? moment(JSON.parse(sessionStorage.getItem("endDate"))) : moment());
    const [checkboxState, setChecked] = React.useState({
        symptoms: false,
    });
    const [searchState, setSearch] = useState({
        type: 'timesheetID',
        value: '',
    });

    const [checklistData, setChecklistData] = useState(JSON.parse(sessionStorage.getItem("checklistData")) || []);
    const [errorMessage, setErrorMessage] = useState(null)
    const [loading, setLoading] = useState(false)
    const [searchBarValue, setSearchBarValue] = useState("checklistSearchBarValue" in sessionStorage ? JSON.parse(sessionStorage.getItem("checklistSearchBarValue")) : null)

    const checkboxHandler = (event) => {
        setChecked({ ...checkboxState, [event.target.name]: event.target.checked });
    };

    const startDateHandler = date => {
        setStartDate(date);
        sessionStorage.setItem("startDate", JSON.stringify(date));
    }
    const endDateHandler = date => {
        setEndDate(date);
        sessionStorage.setItem("endDate", JSON.stringify(date));
    }

    const searchHandler = (event) => {
        setSearch({ ...searchState, [event.target.name]: event.target.value })
    }

    const clearSearch = () => {
        setSearch({ ...searchState, value: '' });
        sessionStorage.removeItem("checklistOptionsSearchValue");
    }

    const checklistClickHandler = path => {
        props.checklistClickHandler(path);
    }
    const [expanded, setExpanded] = useState(JSON.parse(sessionStorage.getItem("reportExpanded")) || true);
    const handleExpandClick = () => {
        if(expanded)
            setExpanded(false);
        else
            setExpanded(true);    
        sessionStorage.setItem("reportExpanded", JSON.stringify(expanded))
    };
    const generateHandler = () => {
        sessionStorage.removeItem("timesheetData");
        sessionStorage.removeItem("timesheetUnitData");
        sessionStorage.removeItem("jobData");
        sessionStorage.removeItem("defectData");
	    sessionStorage.removeItem("unitData");
        sessionStorage.removeItem("defectData");
        sessionStorage.removeItem("deliveryData");
      	sessionStorage.removeItem("deliveryUnitData");
        sessionStorage.removeItem("checklistData");
        sessionStorage.removeItem("photoData");
        sessionStorage.removeItem("filteredPhotoData");
        sessionStorage.removeItem("REACT_COOL_IMG");
        sessionStorage.removeItem("filteredOrderData");
        
        setErrorMessage(null);
        if (endDate < startDate) {
            setErrorMessage('Start Date later than End Date!')
            return
        }
        if(endDate.diff(startDate, 'd') > 365){
            setErrorMessage('Query cannot be more than a one year period.')
            return
        }

        setLoading(true);
        
        let query = firestore.collection('checklists').where("date", ">=", startDate.startOf('day').utc().valueOf()/1000).where("date", "<=", endDate.endOf('day').utc().valueOf()/1000);
        let jobQuery = firestore.collection('jobs').where("date", ">=", startDate.startOf('day').utc().valueOf()).where("date", "<=", endDate.endOf('day').utc().valueOf());

        query.orderBy('date').get()
            .then(querySnapshot => {
                let checklistResponse = querySnapshot.docs.map((doc) => {
                    return {
                        ...doc.data(),
                        'userName': users.find(user => user.uuid === doc.data().uid)?.displayName,
                        'docId': doc.id,
                    }
                })

                jobQuery.orderBy('date').get().then(querySnapshot => {
                    let jobResponse = querySnapshot.docs.map((doc) => {
                        return {
                            ...doc.data(),
                            'uid': doc.data().uid,
                            'userName': users.find(user => user.uuid === doc.data().uid)?.displayName,
                            'docId': doc.id,
                            'customer': doc.data().customer,
                            'address': doc.data().address,
                        }
                    })
                    
                    let response = checklistResponse.map((checklist) => {
                        let job = jobResponse.find(job => job.docId === checklist.jobID && job.uid === checklist.uid);
                        return {
                            ...checklist,
                            'customer': job?.customer,
                            'address': job?.address,
                        }
                    })



                    setChecklistData(response);
                    sessionStorage.setItem("checklistData", JSON.stringify(response));
                    setLoading(false);

                })

            }).catch(e => {

                setChecklistData([]);
                sessionStorage.removeItem("checklistData");
                setLoading(false);
                switch(e.name){
                    case 'QuotaExceededError':
                        setErrorMessage("Query size too big for browser storage.");
                        console.log(e.message);
                        break;
                    default:
                        setErrorMessage("Unable to process.");
                        console.log(e.message);
                }
            });
    }
    const handlePrint = () => {
        props.history.push({
            pathname: '/dashboard/reporting/checklists/printing/table/',
            state: { data: checklistData, checklistData: checklistData, startDate: moment(startDate).format('MMMM Do YYYY'), endDate: moment(endDate).format('MMMM Do YYYY') }
        })
    }

    const handlePrintAll = () => {
        props.history.push({
            pathname: '/dashboard/reporting/checklists/printing/all/',
            state: { data: !loading ? filterData()
                  : checklistData, checklistData: !loading ? filterData()
                 : checklistData, startDate: moment(startDate).format('MMMM Do YYYY'), endDate: moment(endDate).format('MMMM Do YYYY') }
        })
    }

    useEffect(() => {
       
        
    // eslint-disable-line react-hooks/exhaustive-deps       
    }, 
    [smallScreen, customerValue, addressValue]);

    const handleSearchFilters = (type, value) => {
        switch (type) {
            case 'customer':
                setCustomerValue(value);
                sessionStorage.setItem("checklist_CustomerValue", JSON.stringify(value));
                break;
            case 'address':
                setAddressValue(value);
                sessionStorage.setItem("checklist_AddressValue", JSON.stringify(value));
                break;
            case 'employee':
                setEmployeeValue(value);
                sessionStorage.setItem("checklist_EmployeeValue", JSON.stringify(value));
                break;
            case 'serviceType':
                setServiceTypeValue(value);
                sessionStorage.setItem("checklist_ServiceTypeValue", JSON.stringify(value));
                break;
            default:
                break;
        }
        
    }
 
    function filterData() {
        //allow filtering either by customer or address or both or any combination of filters
        let filteredData = checklistData;
        if (customerValue) {
            filteredData = filteredData.filter(checklist => checklist?.customer.toLowerCase().includes(customerValue.toLowerCase()));
        }
        if (addressValue) {
            filteredData = filteredData.filter(checklist => checklist?.address.toLowerCase().includes(addressValue.toLowerCase()));
        }
        if (employeeValue) {
            filteredData = filteredData.filter(checklist => checklist?.userName.toLowerCase().includes(employeeValue.toLowerCase()));
        }
        if (serviceTypeValue) {
            filteredData = filteredData.filter(checklist => checklist?.serviceType.toLowerCase().includes(serviceTypeValue.toLowerCase()));
        }
        return filteredData;
    }
   
    
    return (
        <React.Fragment>
            <div className={classes.content}
            style={expanded ? {paddingRight: '260px' } : {paddingRight: smallScreen ? '12px' : '230px' }} >
                {loading === false ?
                <MaterialTable
                    columns={[
                        { title: 'Service Type', field: 'serviceType' },
                        { title: 'Employee', field: 'userName' },
                        { title: 'Date', field: 'date', render: rowData => moment.unix(rowData.date).format('YYYY-MM-DD h:mm a') },
                        { title: 'Customer', field: 'customer' },
                        { title: 'Address', field: 'address' },

                    ]}
                    data={!loading && filterData()}
                    title='Checklists'
                    icons={TableIcons}
                    isLoading={loading || usersLoading}
                    onSearchChange={ (value) => {setSearchBarValue(value);  sessionStorage.setItem("checklistSearchBarValue", JSON.stringify(value))}}
                    onRowsPerPageChange={(num) => {sessionStorage.setItem("checklistReportRowsPerPage", JSON.parse(num))}}
                    actions={[
                        {
                            icon: TableIcons.OpenInNew,
                            tooltip: 'View Checklist',
                            onClick: (event, rowData) => checklistClickHandler(rowData['docId']),
                        },
                    ]}
                    onPageChange={ (pageNum) => { sessionStorage.setItem("checklistReportPageValue", JSON.stringify(pageNum)) }}
                    options={{
                        pageSizeOptions: [5, 25, 100],
                        pageSize: "checklistReportRowsPerPage" in sessionStorage ? JSON.parse(sessionStorage.getItem("checklistReportRowsPerPage")) : 25,
                        initialPage: "checklistReportPageValue" in sessionStorage ? JSON.parse(sessionStorage.getItem("checklistReportPageValue")) : 0,
                        toolbar: true,
                        paging: true,
                        sorting: true,
                        columnsButton: true,
                        thirdSortClick: false,
                        searchText: searchBarValue,
                        //actionsColumnIndex: -1,
                    }}
                />
                : <Skeleton variant='rect' width={'80vw'} height={'165vh'}/>}

                {smallScreen ?
                <div>
                    <IconButton
                        className={clsx(classes.expand, {
                            [classes.expandOpen]: expanded,
                        })}
                        
                        onClick={handleExpandClick}
                        aria-expanded={expanded}
                        aria-label="show more"
                        >
                        <ExpandMoreIcon style={{fontSize: '50px'}} />
                    </IconButton>
                    <Collapse orientation="horizontal" in={expanded} timeout="auto" unmountOnExit>
                        <Paper className={classes.sidebar}>
                            <ReportSelect
                                reportType={props.reportType}
                                onchange={props.onchange}
                            />
                            <ChecklistOptions
                                checkboxState={checkboxState} handler={checkboxHandler}
                                searchState={searchState} searchHandler={searchHandler}
                                clearSearch={clearSearch}
                                startDate={startDate} startDateHandler={startDateHandler}
                                endDate={endDate} endDateHandler={endDateHandler}
                                generateHandler={generateHandler}
                                errorMsg={errorMessage}
                                loading={loading}
                                handlePrint={handlePrint} handlePrintAll={handlePrintAll}
                                customerValue={customerValue} setCustomerValue={setCustomerValue}
                                handleSearchFilters={handleSearchFilters}
                            />
                        </Paper>
                    </Collapse>    
                </div>
                :
                <div>
                    <IconButton
                        className={clsx(classes.expand, {
                            [classes.expandOpen]: expanded,
                        })}
                        
                        onClick={handleExpandClick}
                        aria-expanded={expanded}
                        aria-label="show more"
                        >
                        <ExpandMoreIcon style={{fontSize: '50px'}} />
                    </IconButton>
                    <Collapse orientation="horizontal" in={expanded} timeout="auto" unmountOnExit>
                        <Paper className={classes.sidebar}>
                            <ReportSelect
                                reportType={props.reportType}
                                onchange={props.onchange}
                            />
                            <ChecklistOptions
                                checkboxState={checkboxState} handler={checkboxHandler}
                                searchState={searchState} searchHandler={searchHandler}
                                clearSearch={clearSearch}
                                startDate={startDate} startDateHandler={startDateHandler}
                                endDate={endDate} endDateHandler={endDateHandler}
                                generateHandler={generateHandler}
                                errorMsg={errorMessage}
                                loading={loading}
                                handlePrint={handlePrint} handlePrintAll={handlePrintAll}
                                customerValue={customerValue} setCustomerValue={setCustomerValue}
                                handleSearchFilters={handleSearchFilters}
                            />
                        </Paper>
                    </Collapse>    
                </div>
                }
            </div>
        </React.Fragment>
    )
}

export default withRouter(ChecklistReport)