import React, { useEffect, useState } from 'react';
import { Grid, makeStyles, Button, useMediaQuery, Paper, Divider, Fade, Modal, Typography, Backdrop, Tab, Box, Card} from '@material-ui/core';
import { withRouter } from 'react-router';
import MaterialTable from '@material-table/core';
import { Skeleton } from '@material-ui/lab';
import { useUser } from '../../../providers/UserContext';
import { TableIcons } from '../../../constants/tableIcons';
import moment from 'moment';
import { firestore } from '../../../firebase/firebase'
import { useTheme } from '@material-ui/core/styles';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import { removeFile } from '../../../firebase/firebase';
import Img from 'react-cool-img';
import { getStorage, ref, getMetadata } from "firebase/storage";
import _ from 'lodash';
import TabContext from '@material-ui/lab/TabContext';
import TabList from '@material-ui/lab/TabList';
import TabPanel from '@material-ui/lab/TabPanel';

const useStyles = makeStyles((theme) => ({

    card: {
        padding: theme.spacing(2),
        width: '100%',
        height: '100%',
    },

    content: {
        padding: '32px',
        paddingRight: '230px',
        overflow: 'auto'
    },

    sidebar: {
        position: 'fixed',
        right: 0,
        top: '64px',
        maxWidth: '256px',
        width: '100%',
        padding: '16px',
        minHeight: '100vh',
        zIndex: '1000',
    },
    expand: {
        position: 'fixed',
        right: 0,
        top: '444px',
        transform: 'rotate(90deg)',
        marginLeft: 'auto',
        transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.shortest,
        }),
    },
    expandOpen: {
        position: 'fixed',
        right: 253,
        top: '444px',
        transform: 'rotate(270deg)',
    },

    grid: {
        marginTop: theme.spacing(2),
        maxWidth: '1000px',
    },

    table: {
        display: 'flex',
        justifyContent: 'center',
    },

    tile: {
        width: '300px',
        height: '300px',
    },

    tileContent: {
        display: 'flex',
        justifyContent: 'space-evenly',
        alignItems: 'center',
        flexDirection: 'column',
        width: '100%',
        height: '100%',
        padding: theme.spacing(4),
    },

    tileIcon: {
        color: 'rgba(0, 0, 0, 0.54)',
        width: '100px',
        height: '100px',
    },

    tileText: {
        fontSize: '1.10rem',
    },
    btn: {
        marginBottom: '20px',
        marginTop: '20px',
    },
}));

const BugReportsHome = (props) => {
    const theme = useTheme();
    const smallScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const classes = useStyles();
    const { users, error, getUsers } = useUser();

    const [loading, setLoading] = useState(false);
    const [bugData, setBugData] = useState([]);
    const [deleteModalOpen, setDeleteModalOpen] = useState(false);
    const [selectedReports, setSelectedReports] = useState([]);
    const [selectedType, setSelectedType] = useState('')
    const [errorData, setErrorData] = useState([])
    const [tabSelected, setTabSelected] = useState("bugReportTabValue" in sessionStorage ? JSON.parse(sessionStorage.getItem("bugReportTabValue")) : 'bugReport');

    useEffect(() => {
    }, [smallScreen]);

    //grabs users when users set is empty
    useEffect(() => {
        //getUsers()
        generateHandler()
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    const getUserDisplayName = (uuid) => {
        let user = _.find(users, {uuid: uuid});
        if (!_.isUndefined(user)) {
            return user.displayName;
        } else {
            return uuid;
        }
    }

    const generateHandler = () => {
        if(users.length <= 0){
            getUsers();
        }
        setLoading(true);

        firestore.collection('crashReports').get().then(querySnapshot => {
            let dataResponse = querySnapshot.docs.map((doc) => {
                return {
                    ...doc.data(),
                    'docId': doc.id,
                }
            })
            setErrorData(dataResponse)
        })

        firestore.collection('bugReports').get().then(querySnapshot => {
            let dataResponse = querySnapshot.docs.map((doc) => {
                return {
                    ...doc.data(),
                    'photos': [],
                    'videos': [],
                }
            })
            //for each bug report, check isVideo on the photoUrls and set the photos and videos arrays
            dataResponse.forEach(async (bugReport, index) => {
                let photos = [];
                let videos = [];
                bugReport.photoUrls.forEach(async (url, index) => {
                    const storage = getStorage();
                    const forestRef = ref(storage, url);
                    // Get metadata properties
                    getMetadata(forestRef)
                    .then((metadata) => {
                        //if metadata.contentType contains video then set isVideo to true
                        if(metadata.contentType.includes('video')){
                            videos.push(url)
                        } else {
                            photos.push(url)
                        }
                    })
                    .catch((error) => {
                        // Uh-oh, an error occurred!
                    });
                })
                dataResponse[index].photos = photos;
                dataResponse[index].videos = videos;
            })
            //for each bug report set displayName using the user uuid

            setBugData(dataResponse)
        })
        .then(() => {
            setLoading(false);
        }).catch(e => {
            setBugData([]);
            setLoading(false);
        });
    }

    const handleTableChange = (event, newValue) => {
        setTabSelected(newValue);
        sessionStorage.setItem("bugReportTabValue", JSON.stringify(newValue));
    };

    const handleDeleteReport = async (array) => {
        setLoading(true);
        let batch = firestore.batch();

        let collection = selectedType === 'bugReport' ? 'bugReports' : 'crashReports'
        
        for(const item of array){
            let query = firestore.collection(collection).doc(item.docId)
            batch.delete(query);

            //delete any images associated with the bug report IF TYPE 'bugReport', no batch remove options
            selectedType === 'bugReport' && item.photoUrls.forEach(async file => {await removeFile(file)})
        }
        
        batch.commit()
            .then(() => { 
                setDeleteModalOpen(false)
                generateHandler()   //so lazy lmao
                setSelectedReports([])
                setSelectedType('')
                setLoading(false);
            })
            .catch(e => {
                console.log(e)
            })
    }

  return (
    <div className={classes.content}>
        {/* delete modal */}
        <Modal
            style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                outline: 0,
            }}
            open={deleteModalOpen}
            onClose={()=> {setDeleteModalOpen(false)}}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }}
        >
            <Fade in={deleteModalOpen}>
                <Paper style={{ padding: '16px' }}>
                    <Grid container direction='column' spacing={2}>
                        <Grid item>
                            <Typography>Are you sure you want to complete these reports & remove them?</Typography>
                        </Grid>

                        <Grid container justifyContent='flex-end'>
                            <Grid item >
                                <Button variant='contained' style={{margin: '5px'}} color="secondary" onClick={() => { setDeleteModalOpen(false) }} > Cancel </Button>
                                <Button variant='contained' style={{margin: '5px'}} onClick={() => handleDeleteReport(selectedReports)}> Confirm </Button>
                            </Grid>
                        </Grid>

                    </Grid>
                </Paper>
            </Fade>
        </Modal>

      {!error ?
        <div className={classes.content} style={{paddingRight: smallScreen ? '12px' : '230px' }}>
            <Grid>
                
                {tabSelected === 'errorReport' &&
                    <Grid container justifyContent='flex-end' style={{marginBottom: '10px'}}>
                        <Button variant='contained' onClick={()=> { setDeleteModalOpen(true); setSelectedReports(errorData) }}>Complete All</Button>
                    </Grid>
                }

                <Card>
                    <Grid item sm={12} xs={12} className={classes.table}>
                    <TabContext value={tabSelected}>
                        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <TabList onChange={handleTableChange}>
                            <Tab label={"Bug Reports"} value="bugReport" />
                            <Tab label={"Error Reports"} value="errorReport" />
                        </TabList>
                        </Box>
                        <TabPanel value="bugReport"></TabPanel>
                        <TabPanel value="errorReport"></TabPanel>
                    </TabContext>
                    </Grid>

                    <MaterialTable
                        columns={
                            tabSelected === 'bugReport' ?
                            [
                                { title: 'User', field: 'user', render: rowData => {
                                    return getUserDisplayName(rowData.user)
                                } },
                                { title: 'Date Submit', field: 'date', type: 'date', render: rowData => {
                                    return moment(rowData.date).format('MM/DD/YYYY')
                                }},
                                { title: 'Description', field: 'description' },
                                { title: 'Platform', field: 'os' },
                                { title: 'Version', field: 'version' },
                                { title: 'Action', render: rowData => {
                                    return <Button variant={"contained"} onClick={() => {setDeleteModalOpen(true); setSelectedReports([rowData]); setSelectedType('bugReport')}}>Complete</Button>
                                }}
                            ]
                            : 
                            [
                                { title: 'Name', field: 'name' },
                                { title: 'Description', field: 'description' },
                                { title: 'Date Triggered', field: 'time',},
                                { title: 'Stack', field: 'stack', render: rowData => {
                                    return rowData.stack.length > 200 ? rowData.stack.substring(0, 200) + '...' :rowData.stack
                                }},
                                { title: 'Action', render: rowData => {
                                    return <Button variant={"contained"} onClick={() => {setDeleteModalOpen(true); setSelectedReports([rowData]); setSelectedType('errorReport')}}>Complete</Button>
                                }}
                            ]
                        }
                        data={tabSelected === 'bugReport' ? bugData : errorData}                  
                        title= {tabSelected === 'bugReport' ? "Bug Reports" : "Error Reports"}
                        isLoading={loading}
                        icons={TableIcons}
                        detailPanel={[
                            rowData => ({
                                tooltip: 'Show Details',
                                icon: () => <small><ArrowForwardIosIcon style={{ fontSize: '12px' }} /></small>,
                                render: () =>
                                    <React.Fragment>
                                        {tabSelected === 'bugReport' ?
                                        <Paper style={{padding: '10px'}}>
                                            <Grid container spacing={2}>
                                                <Grid item xs={12} sm={6}>
                                                    <b>User: </b>{getUserDisplayName(rowData.user)}
                                                </Grid>
                                                <Grid item xs={12} sm={6}>
                                                    <b>Date: </b>{moment(rowData.date).format('MM/DD/YYYY')}
                                                </Grid>
                                                <Grid item xs={12} sm={6}>
                                                    <b>Platform: </b>{rowData.os}
                                                </Grid>
                                                <Grid item xs={12} sm={6}>
                                                    <b>Version: </b>{rowData.version}
                                                </Grid>
                                                <Grid item xs={12} sm={12}>
                                                    <b>Description: </b>{rowData.description}
                                                </Grid>
                                            </Grid>
                                            <Divider/>
                                            <Grid container item xs={12} sm={12}>
                                            {rowData.photos.map((url, index) => (
                                                <Grid item xs={2} key={index} style={{ margin: '10px' }}>
                                            
                                                    <Img
                                                    style={{ width: '128px', height: 'auto' }}
                                                    src={url}
                                                    alt="bug report image"
                                                    cache
                                                    />
                                            
                                                </Grid>
                                            ))}
                                            </Grid>
                                            <Divider/>
                                            <Grid container item xs={12} sm={12}>
                                            {rowData.videos.map((url, index) => (
                                                <Grid item xs={6} key={index} style={{ margin: '10px' }}>
                                                
                                                    <video width="250" height="480" controls>
                                                    <source src={url} type="video/mp4" />
                                                    Your browser does not support the video tag.
                                                    </video>
                                                
                                                </Grid>
                                            ))}
                                            </Grid>

                                        </Paper>
                                        : 
                                        <Paper style={{padding: '10px'}}>
                                            <Typography>{`${rowData.location} ${rowData.searchParams}`}</Typography>
                                            <Typography>{rowData.stack}</Typography>
                                        </Paper>
                                    }
                                    </React.Fragment>,
                                }),
                            ]}
                            options={{
                                toolbar: true,
                                paging: true,
                                pageSize: 20,
                                actionsColumnIndex: -1,
                                search: true,
                                emptyRowsWhenPaging: false,
                                thirdSortClick: false,
                            }}
                        />
                    
                    </Card>
                </Grid>
          </div>
        :
        <Grid >
          <Skeleton variant='rect' width={'45vw'} height={'85vh'} style={{marginTop: '85px'}}/>
        </Grid>
      }  
    </div>
  )
}

export default withRouter(BugReportsHome);