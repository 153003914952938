import React from 'react';
import moment from 'moment';
import { Button, makeStyles } from '@material-ui/core';
import { PDFViewer, Font, Page, Text, View, Document, StyleSheet } from '@react-pdf/renderer';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { withRouter } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        justifyContent: 'center',
        width: '100%',
    },
    content: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        maxWidth: '1200px',
        margin: 'auto',
    },
    buttonRow: {
        marginBottom: '20px',
        marginTop: '20px',
    },
    checkRow: {
        margin: theme.spacing(1),
        display: 'flex',
        justifyContent: 'space-evenly',
    },
}));

Font.register({
    family: 'Oswald',
    src: 'https://fonts.gstatic.com/s/oswald/v13/Y_TKV6o8WovbUd3m_X9aAA.ttf'
});

// Create styles
const styles = StyleSheet.create({
    page: {
        flexDirection: 'column',
        fontFamily: 'Helvetica',
        padding: 20,
    },
    section: {
        //marginBottom: 10,
        padding: 10
    },
    dTable: {
        display: "table",
        width: "auto",
        border: '1 solid black',

        marginBottom: 10,
    },
    dTableRow: {
        margin: "auto",
        flexDirection: "row"
    },
    dTableCol: {
        flexGrow: 1,
    },
    dTableCell: {
        margin: "auto",
        // marginTop: 5,
        // marginBottom: 5,
        fontSize: 10,
    },
    table: {
        border: '1 solid black',
        padding: 10,
        flexGrow: 1,
        fontSize: 14,
        flexDirection: 'column',
        justifyContent: 'space-between',
    },
    tableRow: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginTop: 2,
        marginBottom: 2,
    },
    tableText: {
        fontSize: 12,
    },
    tableTextSmall: {
        fontSize: 10,
    },
    tableTextBold: {
        fontSize: 12,
        fontFamily: 'Helvetica-Bold',
        fontWeight: 'bold',
    },
    tableTextBoldUnderline: {
        fontSize: 12,
        fontFamily: 'Helvetica-Bold',
        fontWeight: 'bold',
        textDecoration: 'underline'
    },
    summary: {
        flexDirection: 'row',
    },
    viewer: {
        width: '100%',
        maxWidth: '1200px',
        height: '85vh',
    },
    boldUnderlineHeader: {
        fontWeight: 'bold', 
        textDecoration: 'underline',
        fontSize: '14px', 
        marginBottom: '5px'
    }
});

// Create Document Component
const GrainPDF = (props) => {

    const classes = useStyles();

    /**
     * @param title - Table Header
     * @param columns - Generates table column header and its internal data. Can pass either an objects key to iterate or a function.
     * @param data - The table data passed (as an array of objects)
     * @param components - React components passed as special rendering for additional rows 
     */

    const grainPrintedData = props.grainData.map((grainDocument) => {
        let today = moment(new Date()).format('MMMM Do, YYYY');
        let currentTime = moment(new Date()).format('LTS');
        let operator = props.users.find(x=> x.docId === grainDocument.operator)
        let customer = props.customers.find(x=> x.docId === grainDocument.customer)
        let equipment = props.units.find(x=> x.docId === grainDocument.equipment)
        let trailer1 = props.units.find(x=> x.docId === grainDocument.trailer1)
        let trailer2 = props.units.find(x=> x.docId === grainDocument.trailer2)


        return (
            <Page size="A4" style={styles.page} wrap>
               <View style={styles.section}>
                    <View style={{...styles.tableRow, alignSelf:'flex-end'}}>
                        <Text style={{...styles.tableText, fontSize: '10px', }}>{today}</Text>
                    </View>
                    <View style={{...styles.tableRow, alignSelf:'flex-end'}}>
                        <Text style={{...styles.tableText, fontSize: '10px', }}>{currentTime}</Text>
                    </View>
                </View>

                <View style={{ ...styles.section, border: '1px solid black', backgroundColor: '#d3d3d3', }}>
                    <View style={{ ...styles.tableRow}}>
                        <Text style={{...styles.tableText, fontSize: '20px'}}>Order Instructions for:</Text>
                        <View style={{...styles.tableRow, alignSelf:'flex-start'}}>
                            <Text style={{...styles.tableText, fontSize: '20px'}}>Order #: </Text>
                            <Text style={{...styles.tableText, fontSize: '20px', minWidth: '220px', backgroundColor: 'white'}}>{grainDocument.orderNumber}</Text>
                        </View>
                    </View>
                    
                    <View style={{...styles.tableRow, alignSelf:'flex-end', }}>
                        <Text style={{...styles.tableText, fontSize: '20px'}}>Date: </Text>
                        <Text style={{...styles.tableText, fontSize: '20px', minWidth: '220px', backgroundColor: 'white'}}>{moment(grainDocument.ticketDate).format('MMMM Do, YYYY')}</Text>
                    </View>
                </View>

                <View style={{ ...styles.section, border: '1px solid black',  }}>
                    <Text style={{...styles.boldUnderlineHeader}}>Description of the Work</Text>
                    <Text style={{...styles.tableText}}>{grainDocument.origin ? grainDocument.origin : ''} to {grainDocument.destination ? grainDocument.destination : ''}</Text>
                </View>

                <View style={{...styles.tableRow, marginTop: 0, marginBottom: 0}}>
                    <View style={{ ...styles.section, border: '1px solid black', width: '100%', height: '100%',}}>
                        <Text style={{...styles.boldUnderlineHeader, alignSelf: 'center',}}>Operator</Text>
                        <Text style={{...styles.tableText}}>{operator && operator.displayName}</Text>
                        <Text style={{...styles.tableText}}>{operator && operator.address.street}</Text>
                        <Text style={{...styles.tableText}}>{operator && `${operator.address.city} ${operator.address.province} ${operator.address.postalCode}`}</Text>
                    </View>
                    <View style={{ ...styles.section, border: '1px solid black', width: '100%', height: '100%'}}>
                        <Text style={{...styles.boldUnderlineHeader, alignSelf: 'center',}}>Customer</Text>
                        <Text style={{...styles.tableText}}>{customer && customer.name}</Text>
                        <Text style={{...styles.tableText}}>{customer && `${customer.streetNumber} ${customer.streetName}`}</Text>
                        <Text style={{...styles.tableText}}>{customer && `${customer.city} ${customer.province} ${customer.postalCode}`}</Text>
                    </View>
                    <View style={{ ...styles.section, border: '1px solid black', width: '100%', height: '100%'}}>
                        <Text style={{...styles.boldUnderlineHeader, alignSelf: 'center',}}>Settlement Details</Text>
                        <View style={{...styles.tableRow, marginTop: 0, marginBottom: 0}}>
                            <Text style={{...styles.tableText}}>Base Amount: </Text>
                            <Text style={{...styles.tableText}}>{grainDocument.adjustedBase && `$ ${grainDocument.adjustedBase}` }</Text>
                        </View>
                        <View style={{...styles.tableRow, marginTop: 0, marginBottom: 0}}>
                            <Text style={{...styles.tableText}}>Operator Rate:</Text>
                            <Text style={{...styles.tableText}}>{grainDocument.operatorRate && (Number(grainDocument.operatorRate) / 100)}</Text>
                        </View>
                        <View style={{...styles.tableRow, marginTop: 0, marginBottom: 0}}>
                            <Text style={{...styles.tableText}}>Settlement: </Text>
                            <Text style={{...styles.tableText}}>{grainDocument.payroll && `$ ${grainDocument.payroll}` }</Text>
                        </View>
                    </View>
                </View>

                <View style={{ ...styles.section, border: '1px solid black',  }}>
                    <View style={{...styles.tableRow,}}>
                        <Text style={{...styles.boldUnderlineHeader}}>Equipment</Text>
                        <Text style={{...styles.tableText}}>{equipment && equipment.unitNumber}</Text>
                        <Text style={{...styles.tableText}}>{trailer1 && trailer1.unitNumber}</Text>
                        <Text style={{...styles.tableText}}>{trailer2 && trailer2.unitNumber}</Text>
                    </View>
                </View>

                <View style={{ ...styles.section, border: '1px solid black',  }}>
                    <Text style={{...styles.boldUnderlineHeader, alignSelf: 'center'}}>Special Instructions</Text>
                    <Text style={{...styles.tableText}}>{grainDocument.instructions}</Text>
                </View>
            </Page>
        )
    })


    return (
        <div className={classes.root}>
            <div className={classes.content}>

                <div className={classes.buttonRow}>
                    <Button 
                        variant='contained' 
                        onClick={()=> {props.history.goBack()}}
                        startIcon={<ArrowBackIcon />}
                    >
                        Back
                    </Button>
                </div>
            
                <PDFViewer style={styles.viewer}>
                    <Document>
                        {grainPrintedData}
                    </Document>
                </PDFViewer>
            </div>
        </div>
    )
};

export default withRouter(GrainPDF);