import React, { useEffect, useState } from 'react';
import { Button, Paper, Typography, Grid, Divider, makeStyles,
} from '@material-ui/core';
import { firestore } from '../../../firebase/firebase';
import { TableIcons } from '../../../constants/tableIcons';
import { withRouter } from "react-router";
import moment from 'moment'
import { useAuth } from '../../../providers/AuthContext'
import { useUser } from '../../../providers/UserContext'
import MaterialTable from '@material-table/core';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { Skeleton } from '@material-ui/lab';
import AppMessages from './AppMessages';

const useStyles = makeStyles((theme) => ({

  card: {
    padding: theme.spacing(4),
    width: '100%',
    height: '100%',
  },
  new: {
    backgroundColor:'green',
    color:'white',
    paddingLeft:'10px',
    paddingRight:'10px',
    borderRadius:'6px',
    margin:'2px',
    fontWeight:'bold'
  },
  change: {
    backgroundColor:'blue',
    color:'white',
    paddingLeft:'10px',
    paddingRight:'10px',
    borderRadius:'6px',
    margin:'2px',
    fontWeight:'bold'
  },
  bug: {
    backgroundColor:'red',
    color:'white',
    paddingLeft:'10px',
    paddingRight:'10px',
    borderRadius:'6px',
    margin:'2px',
    fontWeight:'bold'
  },
  fix: {
    backgroundColor:'dodgerblue',
    color:'white',
    paddingLeft:'10px',
    paddingRight:'10px',
    borderRadius:'6px',
    margin:'2px',
    fontWeight:'bold'
  },
  modal: {
    width: '500px',
    padding: '16px',
    margin: '0 auto',
    marginTop: '50vh',
  },
  table: {
    display: 'flex',
    justifyContent: 'center',
    marginBottom: '2px',
  },
  paper: {
    //borderRadius: '0px',
    margin: '0px',
    width: '100%',
    maxWidth: '90vw',
    padding: '16px',
   // backgroundColor: 'rgb(252, 252, 249)',
  },
  fileInput: {
    '&:hover': {
        cursor: 'pointer',
      },
    display:'flex',
    alignItems: 'center',
    border: '1px solid #bdbdbd',
    borderRadius:'4px',
    margin:'8px',
    marginTop:'16px',
    //backgroundColor: '#f2f2f2',
  },
  fileInputAccept: {
      display:'flex',
      alignItems: 'center',
      border: '0px',
      borderRadius:'4px',
      margin:'8px',
      marginTop:'16px',
      backgroundColor: '#56a84b',
      color:'white',
  },
}));
const Home = (props) => {
  const classes = useStyles();
  const { users, userLoading, getUsers } = useUser();
  const { currentUser, roles } = useAuth();
  const [changelogData, setChangelogData] = useState([]);
  const [currentChangeLog, setCurrentChangeLog] = useState([])
  const [loading, setLoading] = useState(false);
  const [updating, setUpdating] = useState(false);
  //const [error, setError] = useState(null);
  const [unsubmittedSheets, setUnsubmittedSheets] = useState([]);
  const [unsubmittedSheetsForRealsies, setUnsubmittedSheetsForRealsies] = useState([]);
  const [overdueSearchBarValue, setOverdueSearchBarValue] = useState("homeOverdueSearchBarValue" in sessionStorage ? JSON.parse(sessionStorage.getItem("homeOverdueSearchBarValue")) : null)
  const [clockedInSearchBarValue, setClockedInSearchBarValue] = useState("homeClockedInSearchBarValue" in sessionStorage ? JSON.parse(sessionStorage.getItem("homeClockedInSearchBarValue")) : null)

  React.useEffect(() => {
  }, [roles ])

  useEffect(() => {
    getUsers();
  }, []) // eslint-disable-line react-hooks/exhaustive-deps


  useEffect(() => {
    setLoading(true);

      firestore.collection('changelog').orderBy('dateCreated', 'asc').get()
      .then(querySnapshot => {
        const response = querySnapshot.docs.map((doc) => {
          return {
            ...doc.data()
          }
        })
        setChangelogData(response);
        setCurrentChangeLog(response[response.length - 1])
        setLoading(false);
      })
      .catch(e => {
        //setError("Unable to Process");
        setLoading(false);
        console.log(e.message);
      })
  }, []);// eslint-disable-line

  useEffect(() => {
    setLoading(true);
    let yesterday = moment().subtract(1, "days")
    let lastMonth = moment().subtract(1, "months")
    //let today     = moment();
    firestore.collection('timesheets').where('submitted', '==', false).where('inTime', '>=', Number(yesterday)).orderBy('inTime', 'desc').get()
    .then((querySnapshot) => {
      setUnsubmittedSheets(querySnapshot.docs.map((doc) =>
      ({ ...doc.data(), 'userName': users.find(user => user.uuid === doc.data().uid)?.displayName ?? 'Unknown', })));
    })
    .then(firestore.collection('timesheets').where('submitted', '==', false).where('inTime', '<', Number(lastMonth)).orderBy('inTime', 'desc').get()
    .then((querySnapshot) => {
      setUnsubmittedSheetsForRealsies(querySnapshot.docs.map((doc) =>
      ({ ...doc.data(),
        'userName': users.find(user => user.uuid === doc.data().uid)?.displayName ?? 'Unknown',
      })));
    }))
    .finally(() => setLoading(false))
  }, [users, updating]);

  const handleNext = () => {
    setLoading(true)
    let index = changelogData.indexOf(currentChangeLog)
    setCurrentChangeLog(changelogData[index + 1])
    setLoading(false)

  }
  const handlePrevious = () => {
    setLoading(true)
    let index = changelogData.indexOf(currentChangeLog)
    setCurrentChangeLog(changelogData[index - 1])
    setLoading(false)
  }

  

  return (
    <Grid container spacing={2} style={{ padding: '16px', alignItems: 'stretch', maxWidth: '1400px' }}>

      {/* Link Document Modal */}
      <AppMessages/>

      <Grid item xs={12}>
        <Paper style={{ display: 'flex', alignItems: 'flex-start', flexDirection: 'column', maxWidth: '100%', padding: '16px' }}>
          <Grid container style={{ display: 'initial' }}>
            <Grid item xs={6}>
              <Typography variant='h6'>
                What's New?
              </Typography>
            </Grid>
            <Grid item xs={6}>
            <Button variant="outlined" size="large" style={{padding:'0px'}}
            onClick={() => handlePrevious()} disabled={loading || changelogData.indexOf(currentChangeLog) <= 0 }>
                  <ChevronLeftIcon />
              </Button>
              <Button variant="outlined" size="large" style={{padding:'0px'}} disabled={loading || changelogData.indexOf(currentChangeLog) >= changelogData.length -1 }
              onClick={() => handleNext()}>
                  <ChevronRightIcon />
              </Button>
            </Grid>
            <Grid item xs={12}>
              <Typography variant='subtitle2'>
                  Latest Update: <b>{!loading && currentChangeLog?.dateCreated ? moment(currentChangeLog?.dateCreated.toDate()).format('MMMM Do, YYYY') : 'Loading...'}</b>
              </Typography>
              <Divider></Divider>
              <Typography variant='body2'>
              <br></br>

                  {!loading && currentChangeLog?.features ? currentChangeLog.features.map((feature, index) => {
                        return <React.Fragment key={index}><small className={classes.new}>N E W</small>{feature}<br></br></React.Fragment>
                  }): null}
                  {!loading && currentChangeLog?.changes ? currentChangeLog.changes.map((change, index) => {
                      return <React.Fragment key={index}><small className={classes.change}>C H A N G E</small>{change}<br></br></React.Fragment>
                  }): null}
                  {!loading && currentChangeLog?.fixes ? currentChangeLog.fixes.map((fix, index) => {
                      return <React.Fragment key={index}><small className={classes.fix}>F I X</small>{fix}<br></br></React.Fragment>
                  }): null}
                  {!loading && currentChangeLog?.bugs ? currentChangeLog.bugs.map((bug, index) => {
                      return <React.Fragment key={index}><small className={classes.bug}>B U G</small>{bug}<br></br></React.Fragment>
                  }): null}

                  {loading && <React.Fragment><Skeleton></Skeleton><br></br>
                    <Skeleton></Skeleton><br></br>
                    <Skeleton></Skeleton><br></br>
                  </React.Fragment>
                  }

              </Typography>
              <br></br>
              <Divider></Divider>
              <Typography variant='subtitle2'>

                <small>For any issues or problems that appear, or if you have any suggestions please email <b><u>derek@geeteeholdings.com</u></b></small>
              </Typography>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
      {((props.preview && props.roles) ? props.roles.includes('Create|Update|Delete Timesheets') : roles.includes('Create|Update|Delete Timesheets')) ?
      <React.Fragment>
      <Grid item xs={12} md={6} >

        <MaterialTable
          title='Clocked In'
          isLoading={loading || userLoading}
          data={unsubmittedSheets}
          onSearchChange={ (value) => {setClockedInSearchBarValue(value);  sessionStorage.setItem("homeClockedInSearchBarValue", JSON.stringify(value))}}
          columns={[
            { title: 'User', field: 'userName' },
            {
              title: 'Time In', field: 'inTime',
              render: rowData => {
                if (rowData.inTime < 9999999999) rowData.inTime *= 1000
                return moment(rowData.inTime).format('YYYY-MM-DD h:mm a')
              }
            },
          ]}
          actions={[
            {
              icon: TableIcons.OpenInNew,
              tooltip: 'Show Timesheet',
              onClick: (event, rowData) => !props.preview &&
              props.history.push({
                pathname: '/dashboard/reporting/timesheets',
                search: "?" + new URLSearchParams({ docId: rowData.docId }).toString(),
              })
            },
          ]}
          options={{
            paging: true,
            pageSize: 10,
            pageSizeOptions: [10],
            searchText: clockedInSearchBarValue,
          }}

          icons={TableIcons}
        >
        </MaterialTable>


      </Grid>

      <Grid item xs={12} md={6} >
        <MaterialTable
          title='Overdue (1 Month ago or more)'
          isLoading={loading || userLoading}
          data={unsubmittedSheetsForRealsies}
          onSearchChange={ (value) => {setOverdueSearchBarValue(value);  sessionStorage.setItem("homeOverdueSearchBarValue", JSON.stringify(value))}}
          columns={[
            { title: 'User', field: 'userName' },
            {
              title: 'Time In', field: 'inTime',
              render: rowData => {
                if (rowData.inTime < 9999999999) rowData.inTime *= 1000
                return moment(rowData.inTime).format('YYYY-MM-DD h:mm a')
              }
            },
          ]}
          actions={
            currentUser.email === "derekmilleryoung@gmail.com" ?
            [
            {
              icon: TableIcons.OpenInNew,
              tooltip: 'Show Timesheet',
              onClick: (event, rowData) => !props.preview &&
                props.history.push({
                  pathname: '/dashboard/reporting/timesheets',
                  search: "?" + new URLSearchParams({ docId: rowData.docId }).toString(),

                })
            },

            {
              icon: TableIcons.Delete,
              tooltip: 'Delete Timesheet',
              disabled: currentUser.email !== "derekmilleryoung@gmail.com" ,
              onClick: (event, rowData) => !props.preview &&
                  firestore.collection('timesheets').doc(rowData.docId).delete().then(() => {
                  firestore.collection('jobs').where('timesheetID', '==', rowData.docId).get().then((docs) => docs.forEach((doc) => firestore.collection('jobs').doc(doc.id).delete()))
                  firestore.collection('checklists').where('timesheetID', '==', rowData.docId).get().then((docs) => docs.forEach((doc) => firestore.collection('checklists').doc(doc.id).delete()))
                  firestore.collection('deliveries').where('timesheetID', '==', rowData.docId).get().then((docs) => docs.forEach((doc) => firestore.collection('deliveries').doc(doc.id).delete()))
                  firestore.collection('units').where('timesheetID', '==', rowData.docId).get().then((docs) => docs.forEach((doc) => firestore.collection('units').doc(doc.id).delete().then(() => {
                  firestore.collection('defectReports').where('unitId', '==', doc.id).get().then((docs) => docs.forEach((doc) => firestore.collection('defectReports').doc(doc.id).delete()))
                })))
                setUpdating(true)
                setTimeout(() => {setUpdating(false)}, 2 * 1000);
              })
            },
          ]
          :
          [{
            icon: TableIcons.OpenInNew,
            tooltip: 'Show Timesheet',
            onClick: (event, rowData) =>
              !props.preview &&
              props.history.push({
                pathname: '/dashboard/reporting/timesheets',
                search: "?" + new URLSearchParams({ docId: rowData.docId }).toString(),
              })
          },]}
          options={{
            paging: true,
            pageSize: 10,
            pageSizeOptions: [10],
            searchText: overdueSearchBarValue,
          }}

          icons={TableIcons}
        >
        </MaterialTable>

      </Grid>
      </React.Fragment>
       :null}
    </Grid >


  );
}


export default withRouter(Home);