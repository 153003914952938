import React, { useEffect, useState } from 'react';
import { firestore } from '../../../../firebase/firebase'
import { withRouter } from 'react-router-dom';
import TimesheetPDF from '../../../components/Reports/Timesheets/TimesheetPDF'
import _ from 'lodash'
import { Skeleton } from '@material-ui/lab';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    content: {
        display: 'flex',
        width: '100%',
        padding: '32px',
        justifyContent: 'center',
        marginTop: '105px',
    },
}));

function TimesheetPrint(props) {
    const [timesheetData, setTimesheet] = useState({});
    const [checklistData, setChecklistData] = useState([]);
    const [unitData, setUnitData] = useState([]);
    const [jobData, setJobData] = useState([]);
    const [expandedTime, setExpandedTime] = useState("");
    const [deliveryData, setDeliveryData] = useState([]);
    const [userData, setUserData] = useState([]);
    const [loading, setLoading] = useState([]);
    const [errorMessage, setErrorMessage] = useState("");
    const [notes, setNotes] = useState([])
    const [uri] = useState(JSON.parse(sessionStorage.getItem("uri")));
    const [adminTime, setAdminTime] = useState("")

    const classes = useStyles();
    
    useEffect(() => {
        setLoading(true);

        let timesheet = {};

        let path = new URLSearchParams(props.location.search).get("docId");


        firestore.collection('timesheets').where('__name__', "==", path).get()
            .then(querySnapshot => {
                //process & store timesheet data
                const timesheetResponse = querySnapshot.docs.map((doc) => {
                    return {
                        ...doc.data(),
                        'docId': doc.id,
                    }
                })

                if (_.isEmpty(timesheetResponse)) return;

                timesheet = (timesheetResponse[0]);
                setTimesheet(timesheetResponse[0]);
            })
            .then(() => {
                firestore.collection('timesheets').doc(timesheet.docId).collection('notes').get()
                    .then(querySnapshot => {
                        const timesheetNotes = querySnapshot.docs.map((doc) => {
                            return {
                                ...doc.data(),
                                'docId': doc.id,
                            }
                        })

                        setNotes(timesheetNotes);
                    })
            })
            .then(() => { return firestore.collection('units').where("timesheetID", "==", path).get() })
            .then(querySnapshot => {
                const unitResponse = querySnapshot.docs.map((doc) => {
                    return {
                        ...doc.data(),
                        'docId': doc.id,
                    }
                })
                setUnitData(unitResponse);
                
            })
            .then(() => { return firestore.collection('jobs').where("timesheetID", "==", path).get() })
            .then(querySnapshot => {
                const jobResponse = querySnapshot.docs.map((doc) => {
                    return {
                        ...doc.data(),
                        'docId': doc.id,
                    }
                })
                setJobData(jobResponse);
                
                return jobResponse;
            })
            .then(async (jobResponse) => {

                let jobIDs = jobResponse.map((job) => { return job.docId })
                let remainingJobs = jobIDs.length;
                let querySnapshot = [];
                let query;
                let checklists = [];

                //Firestore limits "in, array-contains and array-contains-any queries to array sizes of 10."
                //We create multiple queries for every 10 jobs until all checklists matching jobID have been found
                while (remainingJobs > 0) {
                    const queryLimit = 10;
                    let queryStartPos = remainingJobs - queryLimit >= 0 ? remainingJobs - queryLimit : 0;

                    query = await firestore.collection('checklists').where("jobID", "in", jobIDs.slice(queryStartPos, remainingJobs)).get();
                    querySnapshot.push(query);

                    remainingJobs -= queryLimit;
                }

                querySnapshot.forEach(checklistResponse => {
                    checklists = checklists.concat(checklistResponse.docs.map((doc) => { return { ...doc.data() } }))
                });

                setChecklistData(checklists);

            })

            .then(() => { return firestore.collection('deliveries').where("timesheetID", "==", path).orderBy('ticket').get() })
            .then(querySnapshot => {
                const deliveryResponse = querySnapshot.docs.map((doc) => {
                    return {
                        ...doc.data(),
                        'docId': doc.id,
                    }
                })
                setDeliveryData(deliveryResponse)

            })
            .then(() => { return firestore.collection('users').where("uuid", "==", timesheet.uid).get() })
            .then(querySnapshot => {
                const userResponse = querySnapshot.docs.map((doc) => {
                    return {
                        ...doc.data(),
                        'docId': doc.id,
                    }
                })
                setUserData(userResponse[0]);
                setLoading(false);

            })
            .catch(e => {
                setErrorMessage("Unable to Process");
                setLoading(false)
                console.log(e.message);
            });
    }, [props.location.search]);

    useEffect(() => {
        if (timesheetData && jobData.length > 0) {
            //Calculates the total time during the shift
            if (timesheetData.outTime < 9999999999) timesheetData.outTime *= 1000
            if (timesheetData.inTime < 9999999999) timesheetData.inTime *= 1000
            let totalTimesheetTime = (timesheetData.usingGateOutTime? timesheetData.gateOut15 : timesheetData.outTime) - (timesheetData.usingGateInTime ? timesheetData.gateIn15 : timesheetData.inTime);
            totalTimesheetTime = timesheetData.skipLunch === "na" || timesheetData.skipLunch === "declined" ? totalTimesheetTime - 1800000 : totalTimesheetTime
            totalTimesheetTime -= timesheetData.offHours * 3600000;
            totalTimesheetTime = totalTimesheetTime / 3600000
            totalTimesheetTime = (totalTimesheetTime > 0 ? totalTimesheetTime : 0).toFixed(2);

            //Calculate total job time
            let totalJobTime = 0;
            let totalExpandedTime = 0;
            let adminTime = 0;

            if (jobData.length > 0)
                totalJobTime = (jobData.map((job) => {
                    if (job.timeOut < 9999999999) job.timeOut *= 1000
                    if (job.timeIn < 9999999999) job.timeIn *= 1000
                    return job.timeOut - job.timeIn
                }).reduce((total, time) => total + time) / 3600000).toFixed(2);

            //Calculate expanded time per job
            let expandedTimePerJob = 0;
            expandedTimePerJob = (totalTimesheetTime - totalJobTime) / (jobData.length);
            expandedTimePerJob = expandedTimePerJob > 0 ? expandedTimePerJob : 0;

            //calculate admin Time
            jobData.forEach(job => {
                if (!_.isUndefined(job.adminTime)) {
                    adminTime = ( Number(adminTime) + Number(job.adminTime)).toFixed(2);
                }
            })

            jobData.sort((a, b) => (a.timeIn > b.timeIn) ? 1 : ((b.timeIn > a.timeIn) ? -1 : 0))
            jobData.forEach(job => {
                if (_.isUndefined(job.totalJobTime)) {
                    job.jobTime = ((job.timeOut - job.timeIn) / 3600000).toFixed(2)

                }
                if (_.isUndefined(job.expandedTime)) {
                    job.expandedTime = expandedTimePerJob.toFixed(2)
                }
            })

            if (totalJobTime > 0) {
                //totalJobTime = (jobData.map((job) => Number(job.jobTime)).reduce((total, time) => total + time)).toFixed(2);
                totalExpandedTime = (jobData.map((job) => Number(job.expandedTime)).reduce((total, time) => total + time)).toFixed(2);
            }

            setExpandedTime(totalExpandedTime)
            setAdminTime(adminTime)
        }
    }, [timesheetData, jobData])

    return (
        loading
            ? <div className={classes.content}>
                <Skeleton variant='rect' width={'63vw'} height={'80vh'} />
            </div>
            : <React.Fragment>
            <TimesheetPDF
                checklistData={checklistData}
                deliveryData={deliveryData}
                jobData={jobData}
                timesheetData={timesheetData}
                expandedTime={expandedTime}
                adminTime={adminTime}
                unitData={unitData}
                userData={userData}
                QRCODE={uri}
                errorMessage={errorMessage}
                timesheetNotes={notes} 
                additionalExpandedTime={timesheetData.additionalExpandedTime}
                
                /></React.Fragment>

    )
}

export default withRouter(TimesheetPrint)