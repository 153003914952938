import React from 'react';
import { Button, Grid,  Paper, Modal, Backdrop, Fade, Typography, } from '@material-ui/core';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';

const GrainOrderTicketModal = ({ modalOpen, setModalOpen, handleSendToTickets, rowDocIds, globalGrainOrders, setGlobalGrainOrders }) => {

    return (
        <>
            <Modal
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    outline: 0,
                }}
                open={modalOpen}
                onClose={() => { setModalOpen(false) }}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
            }}>
                <Fade in={modalOpen}>
                    <Paper style={{ padding: '16px', maxWidth:'700px' }}>
                        <Grid container direction='column' spacing={2}>
                            <Grid item>
                                <Typography align='center'>
                                    {/* list tickets and add that you can only do this once per order */}
                                    This will send these entries to INVOICE and mark them as COMPLETE, are you sure?
                                </Typography>
                            </Grid>

                            <Grid item style={{marginBottom: '10px'}}>              
                                {rowDocIds.map((docId) => {
                                    let rowContainer = globalGrainOrders.find(x=> x.docId === docId)
                                    if(rowContainer?.associatedTickets?.length > 0){
                                        return (
                                            <Grid>
                                                <Typography align='center'>
                                                    Grain number {rowContainer.geeTeeOrderNumber} is already associated with {rowContainer.associatedTickets.length} tickets.
                                                </Typography>
                                            </Grid>
                                        )
                                    }
                                    else{
                                        return null
                                    }
                                })}
                            </Grid>

                            <Grid container justifyContent='flex-end' >
                                <Grid item>
                                    <Button
                                        startIcon={<CloseIcon/>} 
                                        style={{color:'white',backgroundColor:'red', marginRight: '10px'}}
                                        variant='contained'
                                        onClick={(e) => { setModalOpen(false) }}
                                    >
                                        Cancel
                                    </Button>

                                    <Button
                                        startIcon={<CheckIcon/>}
                                        variant='contained'
                                        onClick={(e) => {
                                            handleSendToTickets(rowDocIds, globalGrainOrders).then((res) => { setGlobalGrainOrders(res) })
                                            setModalOpen(false)
                                        }}
                                    >
                                        Confirm
                                    </Button>
                                </Grid>
                            </Grid>

                        </Grid>
                    </Paper>
                </Fade>
            </Modal>
        </>

    )
}

export default (GrainOrderTicketModal)