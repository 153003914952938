import { firestore } from '../../firebase/firebase'
import { functions } from '../../firebase/firebase'; 
import * as actionTypes from './actionTypes'

export const begin = () => {
    return {
        type: actionTypes.BEGIN,
        careerLoading: true,
    };
}

export const updateResumeForm = (newResumeForm) => {
    return {
        type: actionTypes.UPDATE_RESUME,
        resumeForm: newResumeForm,
    }
}

export const getCareers = (careerData) => {
    return {
        type: actionTypes.GET_CAREERS,
        careers: careerData,
        careerLoading: false,
    };
}

export const setCareer = (selectedCareer) => {
    return {
        type: actionTypes.SET_CAREER,
        selectedCareer: selectedCareer,
    };
}

export const getCareersDB = (careers = firestore.collection('careers').orderBy('id').get()) => {
    return dispatch => {
        dispatch(begin());
        return careers.then(querySnapshot => {
            const careers = querySnapshot.docs.map((doc) => {
                return { 'docId': doc.id, ...doc.data() }
            })
            dispatch(getCareers(careers));
        }).catch (e => {
            console.log("Error unable to get careers");
        });
        
    }
}

export const submitResume = (successMessage) => {
    return {
        type: actionTypes.SUBMIT_RESUME,
        loading: false,
        successMessage: successMessage,
    }
}

export const submitResumeDB = (formData) => {
    return dispatch => {
        dispatch(begin());

        const resume = functions.httpsCallable('submitResume');

        resume({
            name: formData.resumeData.name,
            email: formData.resumeData.email,
            phone: formData.resumeData.phone,
            coverLetter: formData.resumeData.coverLetter,
            resume: formData.resumeData.resume,
            position: formData.position,
            docId: formData.docId,
            dateCreated: formData.dateCreated,
            resumeURL: formData.resumeURL,
            coverLetterURL: formData.coverLetterURL,
            viewed: formData.viewed,
            sandbox: process.env.NODE_ENV === 'development' ? true : false,
        })
        .then(() => {
            dispatch(submitResume("Resume successfully submitted."));
        }).catch(function(error){
            dispatch(submitResume("Failed to submit resume, please try again."));
            console.log(error.code);
            console.log(error.message);
            console.log(error.details);
        });
    }
    
}