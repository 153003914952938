import React from 'react';
import { makeStyles } from '@material-ui/core';
import { Button, Snackbar } from '@material-ui/core';
import { withRouter } from 'react-router-dom';
import _ from 'lodash'
import ChecklistCard from './ChecklistCard';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

const useStyles = makeStyles((theme) => ({
    content: {
        display: 'flex',
        justifyContent: 'center',
        width: '100%',
    },

    paper: {
        padding: theme.spacing(4),
        maxWidth: '900px',
        width: '100%',
        backgroundColor: 'rgb(252, 252, 249)',
    },
}));

const ChecklistManager = (props) => {

    const classes = useStyles();

    //bandaid that enables save to fields  TODO FIX PROPERLY
    React.useEffect(() => {
        if(props.path !== 'new'){
            props.confirmSave()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleSave = (event) => {
        event.preventDefault();
        props.confirmSave();
    }

    return (!_.isUndefined(props.checklist) &&
        <div className={classes.content}>
            <form onSubmit={handleSave}>
                <Button
                    style={{ marginBottom: '20px' }}
                    variant="contained"
                    color="primary"
                    onClick={() => {props.history.goBack()}}
                    startIcon={<ArrowBackIcon />}>
                    Back
                </Button>
                <ChecklistCard
                    checklist={props.checklist}
                    updateData={props.updateData}
                    saveData={props.saveData}
                    saveState={props.saveState} />
                <Snackbar
                    open={!_.isEqual(props.checklist, props.saveState)}
                    message={props.saving ? 'Saving...' : 'Save Document?'}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center'
                    }}
                    action={
                        <React.Fragment>
                            {props.saving
                                ? null
                                : <React.Fragment>
                                    <Button variant='text' color='primary' onClick={props.cancelSave} style={{ marginLeft: '32px', marginRight: '8px' }}>Cancel</Button>
                                    <Button variant='contained' type='submit'>Confirm</Button>
                                </React.Fragment>}
                        </React.Fragment>
                    }
                />
            </form>
        </div>
    )
}

export default withRouter(ChecklistManager)