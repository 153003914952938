import React from 'react';
import { makeStyles } from '@material-ui/core';
import { Button, Snackbar } from '@material-ui/core';
import { withRouter } from 'react-router-dom';
import _ from 'lodash'
import CouponCard from './CouponCard';

const useStyles = makeStyles((theme) => ({
    content: {
        display: 'flex',
        justifyContent: 'center',
        width: '100%',
    },

    paper: {
        padding: theme.spacing(4),
        maxWidth: '900px',
        width: '100%',
        backgroundColor: 'rgb(252, 252, 249)',
    },
}));

const CouponManager = (props) => {

    const classes = useStyles();

    const handleSave = (event) => {
        event.preventDefault();
        props.confirmSave();
    }
    return (!_.isUndefined(props.coupon) &&
        <div className={classes.content}>
            <form onSubmit={handleSave}>
                <CouponCard
                    categories={props.categories}
                    coupon={props.coupon}
                    updateData={props.updateData}
                    saveState={props.saveState} 
                />
                <Snackbar
                    open={!_.isEqual(props.coupon, props.saveState)}
                    message={props.saving ? 'Saving...' : 'Save Document?'}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center'
                    }}
                    action={
                        <React.Fragment>
                            {props.saving
                                ? null
                                : <React.Fragment>
                                    <Button variant='text' color='primary' onClick={props.cancelSave} style={{ marginLeft: '32px', marginRight: '8px' }}>Cancel</Button>
                                    <Button variant='contained' type='submit'>Confirm</Button>
                                </React.Fragment>}
                        </React.Fragment>
                    }
                />
            </form>
        </div>
    )
}

export default withRouter(CouponManager)