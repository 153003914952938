import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import _ from 'lodash'
import { Skeleton } from '@material-ui/lab';
import { makeStyles } from '@material-ui/core';
import { firestore } from './../../../firebase/firebase'
import WorkOrderPDF from '../../components/WorkOrders/WorkOrderPDF';
import { useUser } from '../../../providers/UserContext';

const useStyles = makeStyles((theme) => ({
    content: {
        display: 'flex',
        width: '100%',
        padding: '32px',
        justifyContent: 'center',
        marginTop: '105px',
    },
}));

function WorkOrderPrint(props) {
    const [workOrderData, setWorkOrderData] = useState({});
    const [loading, setLoading] = useState([]);
    const [errorMessage, setErrorMessage] = useState("");
    const { users, getUsers } = useUser();

    const classes = useStyles();

    useEffect(() => {
        if(users.length <= 0){
            getUsers();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getUserDisplayName = (uuid) => {
        let user = _.find(users, {uuid: uuid});
        if (!_.isUndefined(user)) {
            return user.displayName;
        } else {
            return uuid;
        }
    }

    useEffect(() => {
        let isMounted = true;
    
        setLoading(true);
        let path = new URLSearchParams(props.location.search).get("docId");
    
        firestore.collection('workOrders').where('__name__', "==", path).get()
            .then(querySnapshot => {
                if (!isMounted) return;
    
                const userResponse = querySnapshot.docs.map((doc) => {
                    return {
                        ...doc.data(),
                        'docId': doc.id,
                        'assignedUser': doc.data().assignedUser === "" ? "Unassigned" : getUserDisplayName(doc.data().assignedUser),
                        'secondaryTechs': doc.data().secondaryTechs && doc.data().secondaryTechs.map(tech => getUserDisplayName(tech)),
                        'totalHours': doc.data().repairsNeeded.reduce((acc, repair) => acc + Number(repair.timeSpentRepairing), 0),
                    }
                });
    
                if (_.isEmpty(userResponse)) return;
                setWorkOrderData(userResponse[0]);
                setLoading(false);
            })
            .catch(e => {
                if (!isMounted) return;
                setErrorMessage("Unable to Process");
                setLoading(false);
            });
    
        return () => {
            isMounted = false;
        };
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.location.search]);
    

    return (
        loading
            ? 
            <div className={classes.content}>
                <Skeleton variant='rect' width={'63vw'} height={'80vh'} />
            </div>
            : 
            <WorkOrderPDF
                workOrder={workOrderData}
                errorMessage={errorMessage} 
                getUserDisplayName={getUserDisplayName}
            />
    )
}

export default withRouter(WorkOrderPrint)