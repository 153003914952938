import React from 'react';
import { withRouter } from 'react-router-dom';

import UsersPDF from '../../components/Users/UsersPDF';

function UsersPrint(props) {
    

    return (
            <UsersPDF/>
    )
}

export default withRouter(UsersPrint)