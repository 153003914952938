import React, { useEffect, useState } from 'react';
import { firestore } from '../../../../firebase/firebase'
import { withRouter } from 'react-router-dom';
import CovidCard from '../../../components/Reports/Covid/CovidCard'
import _ from 'lodash'
import { Skeleton } from '@material-ui/lab';

function Covid(props) {
    const [userName, setUserName] = useState({});
    const [loading, setLoading] = useState([]);
    const [covidData, setCovidData] = useState({});
    const [errorMessage, setErrorMessage] = useState("");


    useEffect(() => {
        setLoading(true);


        let path = new URLSearchParams(props.location.search).get("docId");

        firestore.collection('covid').doc(path).get()
            .then(documentSnapshot => {
                const covidResponse = { ...documentSnapshot.data(), 'docId': documentSnapshot.id };

                if (_.isEmpty(covidResponse)) return;

                setCovidData(covidResponse)


                return firestore.collection('users').where('uuid', '==', covidResponse.uid).get()
            })
            .then((querySnapshot) => {
                const userData = querySnapshot.docs.map((doc) => {
                    return doc.data()
                })
                setUserName(userData[0].displayName)

            })
            .catch(e => {
                setErrorMessage("Unable to Process");
                console.log(e);
            }).finally(() => setLoading(false));
    }, [props.location.search]);

    return (
        loading ? <Skeleton variant='rect' width={'100vw'} height={'100vh'} /> :
            <CovidCard
                covidData={covidData}
                userName={userName}
                errorMessage={errorMessage}
            />
    )

}

export default withRouter(Covid)