import React, { useState } from 'react';
import {Button, Divider, Grid, Paper, TextField, Typography, makeStyles, Snackbar, InputAdornment, Modal, Backdrop, Fade} from '@material-ui/core'
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { withRouter } from 'react-router-dom';
import DeleteIcon from '@material-ui/icons/Delete';
import _ from 'lodash';

const useStyles = makeStyles((theme) => ({
    btnGroup:{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-end',
    },
    card: {
        padding: theme.spacing(4),
        width: '100%',
        height: '100%',
    },
    content: {
        maxWidth: '500px',
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        margin: 'auto',
    },
    fileInput: {
        '&:hover': {
            cursor: 'pointer',
          },
        display:'flex',
        alignItems: 'center',
        border: '1px solid #bdbdbd',
        borderRadius:'4px',
        margin:'8px',
        marginTop:'16px',
        //backgroundColor: '#f2f2f2',
    },
    fileInputAccept: {
        display:'flex',
        alignItems: 'center',
        border: '0px',
        borderRadius:'4px',
        margin:'8px',
        marginTop:'16px',
        backgroundColor: '#56a84b',
        color:'white',
    },
    paper: {
        width:'100%',
        maxWidth: '500px',
        padding: theme.spacing(2),
    },
    row:{
        display: 'flex',
        alignItems:'center',
    },
    title: {
        display:'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginBottom: '32px',
    },
    text: {
        color: '#707070',
    },
    backButton: {
        marginBottom: '20px',
        marginTop: '20px',
    },
}));

const RatesCard = (props) => {

    const classes = useStyles();

    const [deleteModalOpen, setDeleteModalOpen] = useState(false)

    return (
        <Grid className={classes.content}>
            {/* delete modal */}
            <Modal
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    outline: 0,
                }}
                open={deleteModalOpen}
                onClose={()=> {setDeleteModalOpen(false)}}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={deleteModalOpen}>
                    <Paper style={{ padding: '16px' }}>
                        <Grid container direction='column' spacing={2}>
                            <Grid item>
                                <Typography>Are you sure you want delete this rate?</Typography>
                            </Grid>

                            <Grid container justifyContent='flex-end'>
                                <Grid item >
                                    <Button variant='contained' style={{margin: '5px'}} color="secondary" onClick={() => { setDeleteModalOpen(false) }} > Cancel </Button>
                                    <Button variant='contained' style={{margin: '5px'}} onClick={() => props.deleteRate() }> Confirm </Button>
                                </Grid>
                            </Grid>

                        </Grid>
                    </Paper>
                </Fade>
            </Modal>

            <Grid container>
                <Grid item xs={6} className={classes.backButton}>
                    <Button 
                        variant="contained" 
                        startIcon={<ArrowBackIcon />}
                        onClick={() => props.history.goBack()} 
                    >
                        Back
                    </Button>
                </Grid>

                {props.path !== 'new' &&
                    <Grid item xs={6} className={classes.backButton}>
                        <Grid container justifyContent='flex-end'>
                            <Button
                                variant='contained'
                                style={{ backgroundColor: 'red', color: 'white' }}
                                startIcon={<DeleteIcon />}
                                onClick={(e) =>{ setDeleteModalOpen(true) }}
                            >
                                Delete Rate
                            </Button>
                        </Grid>
                    </Grid>
                }

            </Grid>

            <form onSubmit={(e) => { props.saveRatesForm(e) }}>
                <Paper className={classes.paper}>
                    <Grid container spacing={2}>
                        <Grid item sm={12} className={classes.title}>
                            <Typography variant="h4" align="center">{props.path === 'new' ? "New Rate Form" : "Rate Form"}</Typography>
                        </Grid>
                        <Grid item sm={12}>
                            <Typography>Rate*</Typography>
                            <TextField 
                                required 
                                type='number'
                                size="small" 
                                fullWidth 
                                variant='outlined' 
                                name="rate" 
                                InputProps={{
                                    startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                  }}
                                onChange={(e) => { props.setRatesFormCallback({...props.ratesForm, rate: e.target.value})}} 
                                value={props.ratesForm.rate}
                            />
                        </Grid>
                        <Grid item sm={12}>
                            <Typography>Rate Name*</Typography>
                            <TextField 
                                required 
                                size="small" 
                                fullWidth 
                                variant='outlined' 
                                name="name" 
                                onChange={(e) => { props.setRatesFormCallback({...props.ratesForm, name: e.target.value})}} 
                                value={props.ratesForm.name}
                            />
                        </Grid>
                        <Grid item sm={12}>
                            <Typography>Rate Description</Typography>
                            <TextField 
                                component="pre" 
                                multiline
                                minRows={3} 
                                size="small" 
                                fullWidth 
                                variant='outlined' 
                                name="description" 
                                onChange={(e) => { props.setRatesFormCallback({...props.ratesForm, description: e.target.value})}} 
                                value={props.ratesForm.description}
                            />
                        </Grid>

                        <Grid item sm={12}>
                            <Divider/>
                        </Grid>

                        <Grid item sm={6} className={classes.row}>
                            <Typography>Date Created</Typography>
                        </Grid>
                        <Grid item sm={6} className={classes.row} style={{justifyContent:'flex-end'}}>
                            <Typography 
                                type='date' 
                                size="small" 
                                variant='outlined' 
                                name="dateUpdated" 
                            >
                                {props.ratesForm.dateCreated}
                            </Typography>
                        </Grid>

                        <Grid item sm={6} className={classes.row}>
                            <Typography>Date Last Updated</Typography>
                        </Grid>
                        <Grid item sm={6} className={classes.row} style={{justifyContent:'flex-end'}}>
                            <Typography 
                                type='date' 
                                size="small" 
                                variant='outlined' 
                                name="dateUpdated" 
                            >
                                {props.ratesForm.dateUpdated}
                            </Typography>
                        </Grid>

                        <Grid item sm={6} className={classes.row}>
                            <Typography>Last Updated By</Typography>
                        </Grid>
                        <Grid item sm={6} className={classes.row} style={{justifyContent:'flex-end'}}>
                            <Typography 
                                type='date' 
                                size="small" 
                                variant='outlined' 
                                name="lastUpdateBy" 
                            >
                                {props.users.find(x=> x.uuid === props.ratesForm.uidLastUpdated)?.displayName || null}
                            </Typography>
                        </Grid>

                        <Grid item sm={12}>
                            <Typography 
                                type='url' 
                                align="center" 
                                style={{color:'red'}}
                            >
                                {props.error}
                            </Typography>
                        </Grid>

                        {props.path === 'new' &&
                            <Grid item sm={12}>
                                <Button 
                                    fullWidth 
                                    variant="contained" 
                                    size="large" 
                                    disabled={props.saving} 
                                    type="submit"
                                >
                                    {props.saving ? "Submitting..." : "Create Rate"}
                                </Button>
                            </Grid>
                        }
                    </Grid>
                </Paper>
            </form>

            {props.path !== 'new' &&
                <Snackbar
                    open={!_.isEqual(props.ratesForm, props.saveState)}
                    message={props.saving ? 'Saving...' : 'Save Document?'}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center'
                    }}
                    action={
                        <React.Fragment>
                            {props.saving
                                ? null
                                : <React.Fragment>
                                    <Button variant='text' color='primary' onClick={props.cancelSave} style={{ marginLeft: '32px', marginRight: '8px' }}>Cancel</Button>
                                    <Button variant='contained' onClick={props.updateRatesForm}>Confirm</Button>
                                </React.Fragment>
                            }
                        </React.Fragment>
                    } 
                />
            }

        </Grid>
    )
}

export default withRouter(RatesCard)