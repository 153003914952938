import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router'
import moment from 'moment'
import { firestore } from '../../../firebase/firebase'
import { makeStyles, Grid, Button } from '@material-ui/core';
import MaterialTable from '@material-table/core';
import { TableIcons } from '../../../constants/tableIcons'
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { Skeleton } from '@material-ui/lab';

const useStyles = makeStyles((theme) => ({
    content: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        maxWidth: '792px',
        margin: 'auto',
    },
    backButton: {
        marginBottom: '20px',
        marginTop: '20px',
    },
    sidebar: {
        position: 'fixed',
        right: 0,
        top: '64px',
        maxWidth: '256px',
        width: '100%',
        padding: '16px',
        zIndex: '1000',
        overflowY: 'auto',
        minHeight: "calc(100vh - 64px)",
        maxHeight: "calc(100vh - 64px)",
    },
}));

const SafetyReport = (props) => {

    const classes = useStyles();
    const [safetyData, setSafetyData] = useState(JSON.parse(sessionStorage.getItem("safetyData")) || []);
    const [errorMessage, setErrorMessage] = useState(null)
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        let unsubscribe = firestore.collection('safety').onSnapshot(
            querySnapshot => {

                const safetyResponse = querySnapshot.docs.map((doc) => {
                    return {
                        ...doc.data(),
                        'docId': doc.id,
                    }
                })
                setSafetyData(safetyResponse);
                setLoading(false);
            },
            error => {
                setErrorMessage("Unable to load safety data.");
                setLoading(false);
                console.log(error.message);
                console.log(errorMessage)
            }
        )
    return () => { unsubscribe(); }
    // eslint-disable-next-line
    }, []);

    return (
        <div className={classes.content}>
            {
                loading
                    ? 
                    <Skeleton variant='rect' width={'51vw'} height={'30vh'}/>
                    : 
                    <Grid>
                        <Grid className={classes.backButton}>
                            <Button variant="contained" startIcon={<ArrowBackIcon />}
                                onClick={() => props.history.goBack()} >Back</Button>
                        </Grid>
                        <MaterialTable
                            data={safetyData}
                            title={"All Safety Data Sheets"}
                            icons={TableIcons}
                            columns={[
                                { title: 'Name', field: 'name' },
                                { title: 'Known As', render: rowData => rowData.aliases.join("\n") },
                                { title: 'Emergency #', field: 'emergencyPhone' },
                                { title: 'Last Updated', render: rowData => moment.unix(rowData.dateUpdated).format('YYYY-MM-DD') },
                            ]}
                            actions={[
                                {
                                    icon: TableIcons.OpenInNew,
                                    tooltip: "Show Safety Data Sheet",
                                    onClick: (event, rowData) => props.history.push({
                                        pathname: '/dashboard/safety/form',
                                        search: "?" + new URLSearchParams({ docId: rowData.docId }).toString(),
                                    })
                                }
                            ]}
                            localization={{
                                header: {
                                    actions: 'Actions'
                                }
                            }}
                            options={{
                                toolbar: true,
                                paging: false,
                                actionsColumnIndex: -1,
                                search: true,
                                emptyRowsWhenPaging: false,
                                rowStyle: {
                                    whiteSpace: 'pre-wrap',
                                },
                            }}
                        />
                    </Grid>
            }
            

        </div>
    )
}

export default withRouter(SafetyReport);