import React from 'react';
import { Button, makeStyles} from '@material-ui/core';
import { PDFViewer, Font, Page, Text, View, Document, StyleSheet } from '@react-pdf/renderer';
import moment from 'moment'
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { withRouter } from 'react-router-dom';


const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        justifyContent: 'center',
        width: '100%',
    },
    content: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        maxWidth: '1200px',
        margin: 'auto',
    },
    buttonRow: {
        marginBottom: '20px',
        marginTop: '20px',
    },
    checkRow: {
        margin: theme.spacing(1),
        display: 'flex',
        justifyContent: 'space-evenly',
    },
}));

Font.register({
    family: 'Oswald',
    src: 'https://fonts.gstatic.com/s/oswald/v13/Y_TKV6o8WovbUd3m_X9aAA.ttf'
});

// Create styles
const styles = StyleSheet.create({
    page: {
        flexDirection: 'column',
        fontFamily: 'Helvetica',
        padding: 20,
    },
    section: {
        marginBottom: 10,
        margin:'0px'
    },
    dTable: {
        display: "table",
        width: "auto",
        border: '1 solid black',

        marginBottom: 10,
    },
    dTableRow: {
        margin: "auto",
        flexDirection: "row"
    },
    dTableCol: {
        flexGrow: 1,
    },
    dTableCell: {
        margin: 3,
        marginTop: 3,
        marginBottom: 3,
        fontSize: 10,
    },
    table: {
        border: '1 solid black',
        marginBottom: 10,
        padding: 10,
        flexGrow: 1,
        fontSize: 12,
        flexDirection: 'column',
        justifyContent: 'space-between',
    },
    tableRow: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginTop: 2,
        marginBottom: 1,
    },
    tableText: {
        fontSize: 12,
    },
    tableTextBold: {
        fontSize: 12,
        fontFamily: 'Helvetica-Bold',
        fontWeight: 'bold',
    },
    tableTextBoldUnderline: {
        fontSize: 12,
        fontFamily: 'Helvetica-Bold',
        fontWeight: 'bold',
        textDecoration: 'underline',
        marginBottom: '10px',
    },
    summary: {
        flexDirection: 'row',
        margin:'0px'
    },
    viewer: {
        width: '100%',
        maxWidth: '1200px',
        height: '85vh',
    },
    checkbox: {
        width: '10px',
        height: '10px',
        background: 'black',
        border: '1 solid black',
      },
      checkboxChecked: {
        width: '10px',
        height: '10px',
        background: 'black',
        border: '5 solid black',
      },
      radio: {
        width: '10px',
        height: '10px',
        background: 'white',
        borderRadius: '50%',
        border: '1 solid black',
      },
      radioFilled: {
        width: '10px',
        height: '10px',
        background: 'white',
        borderRadius: '50%',
        border: '5 solid black',
      },
      userEntered: {
        width: '200px',
        height: '20px',
        background: 'white',
        border: '1 solid black',
      },
});

// Create Document Component
const ChecklistReportPDF = (props) => {

    const classes = useStyles();
    
    


    const checklists = props.checklistData

    
    return (
        <div className={classes.root}>
            <div className={classes.content}>

                <div className={classes.buttonRow}>
                    <Button variant="contained" startIcon={<ArrowBackIcon />}
                        onClick={() => props.history.goBack()}>Back</Button>
                </div>

                <PDFViewer style={styles.viewer}>
                <Document >
                    {checklists.map((checklist, index) => {
                        //get user displayName from users based on checklist.uid
                        let user = props.users.find(user => user.uuid === checklist.uid)?.displayName
                        let checklistStuff = null;
                        if(checklist.checkedItems){
                            switch (checklist.serviceType) {
                                case 'Cleaning':
                                    checklistStuff = (
                                        [
                                            ['pavement' in checklist.checkedItems, 'All snow is cleaned to pavement where reasonably possible'],
                                            ['curb' in checklist.checkedItems, 'All Curbs are exposed'],
                                            ['driveway' in checklist.checkedItems, 'All Driveway approaches are clear'],
                                            ['loadingdock' in checklist.checkedItems, 'All loading docks are clear, if applicable'],
                                            ['piles' in checklist.checkedItems, 'Snow is piled in a designated area, which doesn’t obstruct a roadway, sidewalk, fire hydrant or entrance or exit from building'],
                                            ['damage' in checklist.checkedItems, 'Check this box if damaged occurred during cleaning and take picture',],
                                            ['inaccessible' in checklist.checkedItems, 'Check box if there was somewhere that couldn’t be cleared and take picture of reason'],
                                        ]
                                    )
                                    break;
                                case 'Sanding':
                                    checklistStuff = (
                                        [
                                            ['approaches' in checklist.checkedItems, 'Approaches sanded'],
                                            ['aisles' in checklist.checkedItems, 'Main aisles and walking paths sanded'],
                                            ['docks' in checklist.checkedItems, 'Loading docks sanded if applicable'],
                                            ['damage' in checklist.checkedItems, 'Check box if any damaged occurred during sanding and take pictures'],
                                            ['inaccessible' in checklist.checkedItems, 'Check box if there was somewhere that couldn’t be sanded and take picture of reason'],
                                        ]
                                    )
                                    break;
                                case 'Shovelling':
                                    checklistStuff = (
                                        [
                                            ['pavement' in checklist.checkedItems, 'All snow is cleaned to pavement where reasonably possible'],
                                            ['leeway' in checklist.checkedItems, 'Snow is cleaned at least 1-2 feet from edge of sidewalk'],
                                            ['salted' in checklist.checkedItems, 'If needed sand or other ice melting products applied on icy areas'],
                                            ['emergency' in checklist.checkedItems, 'All emergency exits are clear of snow'],
                                            ['inaccessible' in checklist.checkedItems, 'Check this box If any area was unable to be  cleaned, take picture of area'],
                                        ]
                                    )
                                    break;
                                default: 
                                    break;
                            }
                        }
                        let date = checklist.date > 9999999999 ? moment(checklist?.date) : moment.unix(checklist?.date);
                        return (
                        
                        <Page size="A4" style={styles.page} index={index} wrap>

                            <View style={styles.tableRow}>
                                <Text>Checklist  |  {user}</Text>
                                <Text>{checklist?.customer}</Text>
                            </View>
                            <View style={styles.tableRow}>
                                
                                <Text>{date.format('YYYY-MM-DD h:mm a')}</Text>
                                <Text>{checklist?.address}</Text>
                            </View>
                            
                            <View style={styles.summary} wrap={false}>
                                <View style={{ ...styles.table, marginRight: 5 }}>
                                    <View style={styles.tableRow}>
                                        <Text style={styles.tableText}>Service Reason    </Text>
                                        <Text style={styles.tableTextBold}>{checklist.serviceReason}  </Text>
                                    </View>
                                    <View style={styles.tableRow}>
                                        <Text style={styles.tableText}>Service Type</Text>
                                        <Text style={styles.tableTextBold}>{checklist.serviceType}</Text>
                                    </View>
                                </View>
                                <View style={{ ...styles.table, marginRight: 5,  }}>
                                    <View style={styles.tableRow}>
                                        <Text style={styles.tableText}>Equipment    </Text>
                                        <Text style={styles.tableTextBold}>{Object.values(checklist.equipmentType).join(", ")}    </Text>
                                    </View>
                                </View>
                            </View>

                            <View style={{flexDirection: 'column', }}>
                                
                                

                                {checklist.items ? 
                                Object.entries(checklist.items).map(([key, value], index) => {
                                    
                                    
                                    return ((value.name !== 'Service Reason' && value.name !== 'Equipment') ? <View style={{ ...styles.table, marginRight: 0 }}>
                                    <Text style={{...styles.tableTextBoldUnderline, maxWidth: '300px'}}>{key}</Text>
                                    { value.items.map((checkboxes, index) => {
                                        
                                        return (
                                            <View style={{ ...styles.subTable, marginRight: 0 }} key={checkboxes + index}>
                                                <View style={styles.tableRow}>
                                                
                                                    <Text style={{...styles.tableText, maxWidth: '300px'}}>{checkboxes.name}</Text>
                                                    {checkboxes.style === 'userEnteredText' ? <Text style={styles.userEntered}> {checkboxes.enteredText}</Text> : null}
                                                    {value.type === 'Checkbox' ?
                                                        <Text style={checkboxes.checked ? styles.checkboxChecked : styles.checkbox}></Text> 
                                                        
                                                        :
                                                        <Text style={value?.selectedValue === checkboxes.name ? styles.radioFilled: styles.radio}></Text>}
                                                        
                                                </View>
                                            </View>
                                        ) 
                                    })}
                                    
                                
                                </View>: null)
                                  }  )
                                : null} 

                                { checklist.checkedItems ? 
                                <View style={{ ...styles.table, marginRight: 0 }}>
                                    <Text style={{...styles.tableTextBoldUnderline, maxWidth: '300px'}}>Checked Items</Text>

                                    { checklistStuff.map((item, index) => {
                                        
                                        return (
                                            <View style={{ ...styles.subTable, marginRight: 0 }} key={item + index}>
                                                <View style={styles.tableRow}>
                                                
                                                    <Text style={{...styles.tableText, maxWidth: '300px'}}>{item[1]}</Text>
                                                    
                                                    <Text style={item[0] ? styles.checkboxChecked : styles.checkbox}></Text> 
       
                                                        
                                                </View>
                                            </View>
                                        ) 
                                    })}
                                    
                                    </View> : null}
                                                                    
                               

                            </View>

                            <View style={styles.summary} wrap={false}>
                                <View style={{ ...styles.table}}>
                                    <Text style={{...styles.tableTextBoldUnderline, maxWidth: '300px'}}>Weather Data Created @{checklist?.current?.last_updated}</Text>
                                    <View style={styles.tableRow}>
                                        <Text style={styles.tableText}>Cloud Coverage: {checklist?.current?.cloud}%</Text>
                                        <Text>{checklist?.current?.condition?.text}</Text>
                                        <Text style={styles.tableText}>Wind: {checklist?.current?.wind_kph}km/h {checklist?.current?.wind_dir}</Text>
                                    </View>
                                    <View style={styles.tableRow}>
                                        <Text style={styles.tableText}>Humidity: {checklist?.current?.humidity}%           </Text>
                                        <Text>{checklist?.current?.temp_c}°C</Text>
                                        <Text style={styles.tableText}>Visibility: {checklist?.current?.vis_km}km</Text>
                                    </View>
                                    <View style={styles.tableRow}>
                                        <Text style={styles.tableText}>Precipitation: {checklist?.current?.precip_mm}mm  </Text>
                                        <Text style={styles.tableText}>Pressure: {checklist?.current?.pressure_in}Nm</Text>
                                    </View>
                                    
                                </View>
                            </View>
                              
                            

                        </Page>
                        
                    
                        )

                    })}
                    </Document>
                </PDFViewer>
            </div>
        </div >
    )
};

export default withRouter(ChecklistReportPDF);