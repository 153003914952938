import React, {useState, useEffect} from 'react'
import MaterialTable from 'material-table';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import Notes from '../../../containers/Notes/Notes';
import { firestore } from '../../../../firebase/firebase';
import { TableIcons } from '../../../../constants/tableIcons';
import {  Grid, Typography, Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, TextField, } from '@material-ui/core';
import UnitModal from './Modals/UnitModal';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import _ from 'lodash';

const UnitTable = (props) => {
    const [user, setUser] = useState('')
    const [adminTimeModal, setAdminTimeModal] = useState(false);
    const [unitModal, setUnitModal] = useState(false);
    const [unitModalOpenInEdit, setUnitModalOpenInEdit] = useState(false);
    const [localUnitData, setLocalUnitData] = useState(props.unitData)
    const [localUnitDataSaveState, setLocalUnitDataSaveState] = useState(props.unitData)

    useEffect(() => {
        setLocalUnitData(props.unitData)
        setLocalUnitDataSaveState(props.unitData)
    }, [props.unitData])

    useEffect(() => {
        const user = props.userData.filter(user => user.uuid === props.unitData.uid)
        if (user.length > 0) {
            setUser(user[0])
        }
    }, [props.userData, props.unitData.uid])

    const openAdminTimeModal = () => {
        setLocalUnitData(localUnitDataSaveState);
        setAdminTimeModal(true)
    }

    const closeAdminTimeModal = () => {
        setAdminTimeModal(false)
    }

    const openModal = (docId, openInEdit) => {
        if(openInEdit){
            setUnitModalOpenInEdit(true)
            let unitContainer = _.cloneDeep(props.unitData.filter(unit => unit.docId === docId)[0]);
            props.setCurrentUnit({...unitContainer});
            let jobUnitContainer = props.jobData.filter(job => job.units.includes(docId)).map((job) => { return job.docId });
            props.setCurrentUnitJobs([...jobUnitContainer])
            props.setCurrentUnitJobsSaveState([...jobUnitContainer])
        }
        else{
            setUnitModalOpenInEdit(false)
            props.setCurrentUnit({})
            props.setCurrentUnitJobs([])
            props.setCurrentUnitJobsSaveState([])
        }
        setUnitModal(true);
    }

    const closeModal = () => {
        setUnitModal(false)
    }

    const updateLocalUnit = (newData) => {
        let newUnitData = [...localUnitData];
        let unitIndex = localUnitData.findIndex((unit) => unit.docId === newData.docId);
        newUnitData[unitIndex] = { ...newData };
        setLocalUnitData(newUnitData);
    }

    const saveUnitTime = () => {
        let unitToBeUpdated = [];
        localUnitData.forEach((unit, index) => {
            if(unit.adminTime && unit.adminTime !== props.unitData[index].adminTime){
                unitToBeUpdated.push({...unit, adminTime: unit.adminTime })
            }
            else{
                if(unit.jobTimes){
                    unit.jobTimes.forEach((job, innerIndex) => {
                        if(!props.unitData[index].jobTimes || job.hours !== props.unitData[index].jobTimes[innerIndex].hours){
                            if(unitToBeUpdated.filter(internalUnit => internalUnit.unitNumber === unit.unitNumber).length === 0){
                                unitToBeUpdated.push({...unit, jobTimes: [...unit.jobTimes] })
                            }
                        return;
                        }
                    })
                }
            }
        })
        props.batchUpdateUnits(unitToBeUpdated);
    }

    return (
        <Grid>
            {/* update admin time */}
            <Dialog fullWidth
                keepMounted
                open={adminTimeModal}
                onClose={closeAdminTimeModal}
            >
                <DialogTitle id="alert-dialog-title">Update Unit Hours</DialogTitle>
                <DialogContent>
                    {
                        localUnitData.map((unit) => {
                            return (
                                <Grid container>
                                    <Grid item xs={4}>
                                        <Typography>Unit {unit.unitNumber}: </Typography>
                                    </Grid>
                                
                                    <Grid item xs={8}>
                                    {
                                        props.jobData.filter(job => job.units.includes(unit.docId)).length === 0 
                                        ?
                                            <Grid item xs={12}>
                                                <Typography>Admin Hours</Typography>

                                                <TextField
                                                    value={unit.adminTime ? unit.adminTime : ''}
                                                    size="small"
                                                    placeholder="0.00" 
                                                    variant="outlined"
                                                    onChange={(e) => { 
                                                        e.target.value === undefined 
                                                            ?
                                                            updateLocalUnit({...unit, adminTime: 0 })
                                                            :
                                                            updateLocalUnit({...unit, adminTime: e.target.value });  
                                                    }}
                                                />
                                            </Grid>
                                        :
                                            props.jobData.filter(job => job.units.includes(unit.docId)).map((job) => {
                                                return (
                                                    <Grid item xs={12} style={{ marginBottom: '10px'}}>
                                                        <Typography>{job.customer} - {job.address}</Typography>
                                                        
                                                        <TextField 
                                                            size="small" 
                                                            placeholder="0.00" 
                                                            variant="outlined"
                                                            value={
                                                                unit.jobTimes && unit.jobTimes.filter(time => time.jobId === job.docId).length > 0
                                                                ? 
                                                                unit.jobTimes.filter(time => time.jobId === job.docId)[0].hours 
                                                                : 
                                                                0.00
                                                            } 
                                                            onChange={(e) => {
                                                                let newTimes = unit.jobTimes ? unit.jobTimes : [];
                                                                let time = newTimes.filter(time => time.jobId === job.docId);
                                                                
                                                                if (time.length > 0) {
                                                                    //remove old time
                                                                    newTimes = newTimes.filter(time => time.jobId !== job.docId);
                                                                    //add new time
                                                                    newTimes.push({jobId: job.docId, hours: e.target.value})
                                                                } else {
                                                                    newTimes.push({jobId: job.docId, hours: e.target.value})
                                                                }
                                                                updateLocalUnit({...unit, jobTimes: newTimes }); 
                                                            }}
                                                        />
                                                    </Grid>
                                                )
                                            })
                                    }
                                    </Grid>

                                    <Grid item xs={12}>
                                        <Divider style={{margin:'5px'}}/>
                                    </Grid>
                                </Grid>
                            )
                        })
                    }
                    {/*<Button variant="contained" style={{margin:'5px'}} onClick={() => {props.createUnit("null",job); closeModal();}}>{job.customer} - {job.address}</Button>*/}
                </DialogContent>
                <DialogActions>
                    <Button variant="contained" color="primary" onClick={() => {saveUnitTime(); closeAdminTimeModal()}}>Save</Button>
                    <Button variant="contained" color="secondary" onClick={() => {closeAdminTimeModal()}}>Cancel</Button>
                </DialogActions>
            </Dialog>


            {/* Unit Edit Modal */}
            <Dialog 
                fullWidth
                keepMounted
                open={unitModal}
                onClose={closeModal}
            >
                <DialogTitle id="alert-dialog-title">{unitModalOpenInEdit ? "Edit" : "Create"} Unit</DialogTitle>

                <DialogContent>
                    <UnitModal
                        jobData={props.jobData}
                        currentUnit={props.currentUnit}
                        setCurrentUnit={props.setCurrentUnit}
                        currentUnitJobs={props.currentUnitJobs}
                        setCurrentUnitJobs={props.setCurrentUnitJobs}
                    />
                </DialogContent>

                <DialogActions>
                    <Button 
                        variant="contained" 
                        onClick={() => {
                            if(unitModalOpenInEdit){

                                let jobsToBeUpdates = [];
                                props.jobData.forEach((job) => {

                                    if( (props.currentUnitJobs.includes(job.docId) && !props.currentUnitJobsSaveState.includes(job.docId))
                                    || (!props.currentUnitJobs.includes(job.docId) && props.currentUnitJobsSaveState.includes(job.docId)) ){
                                        
                                        let newJob = _.cloneDeep( job )

                                        if(newJob.units.includes(props.currentUnit.docId)){
                                            newJob.units.splice(newJob.units.findIndex((unitDocId => unitDocId === props.currentUnit.docId)), 1)
                                        }
                                        else{
                                            newJob.units.push(props.currentUnit.docId);
                                        }
                                        jobsToBeUpdates.push(newJob)
                                    }
                                })

                                props.updateUnit(props.currentUnit, jobsToBeUpdates);
                            }
                            else{
                                props.createUnit(props.currentUnit, props.jobData.filter(job=> props.currentUnitJobs.includes(job.docId))); 
                            }
                            closeModal();
                        }}
                    >
                        Save
                    </Button>
                    <Button 
                        variant="contained" 
                        color="secondary" 
                        onClick={() => closeModal()}
                    >
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>


            <MaterialTable
                columns={[
                    { title: 'Unit Number', field: 'unitNumber' },
                    { title: 'Trailer ID', field: 'trailerID'},
                    { title: 'Fuel Added', field: 'fuelAdded'},
                    { title: 'Greased', render: rowData => rowData.greased ? "Yes" : "No",  editable: 'never' },
                    { title: 'Mileage In', field: 'mileageIn'},
                    { title: 'Mileage Out', field: 'mileageOut'},
                    { title: 'Net Mileage', render: rowData => (rowData.mileageOut - rowData.mileageIn).toFixed(2),  editable: 'never' },
                    { title: 'Defects', render: rowData => rowData.defectID !== "" ? "Yes" : "No",  editable: 'never' },
                    { title: 'Admin Time', field: 'adminTime', render: rowData => rowData.adminTime ? rowData.adminTime : 0.00,  editable: 'never' },
                    // { title: 'Admin Time', field: 'adminTime',  editable: 'never', render: rowData => <Button variant="contained" onClick={() => openAdminTimeModal()} size="small">Update Times</Button>},
                ]}
                editable={{
                    //onRowAdd: (newData) => props.createUnit(newData),
                    //onRowUpdate: (newData, oldData) => props.updateUnit(newData, oldData),
                    onRowDelete: (oldData) => props.deleteUnit(oldData),
                }}
                detailPanel={[
                    {
                        tooltip: 'Show Details',
                        icon: () => <ChevronRightIcon />,
                        render: rowData => {
                            return (
                                <div style={{ padding: '10px' }}>
                                    <Notes
                                        notes={rowData.notes}
                                        timesheetLoading={props.loading}
                                        path={firestore.collection('units').doc(rowData.docId).collection('notes')}
                                        user={user?.uuid}
                                    />
                                </div>
                            )
                        }
                    }
                ]}
                data={props.unitData}
                isLoading={props.unitsLoading}
                icons={TableIcons}
                pathname='/dashboard/reporting/timesheets/unit'
                title={"Unit Details"}
                options={
                    {
                        pageSize: 10,
                        pageSizeOptions: [],
                        toolbar: true,
                        paging: true,
                        actionsColumnIndex: -1,
                        search: false,
                        emptyRowsWhenPaging: false,
                        thirdSortClick: false,
                        rowStyle: {
                            whiteSpace: 'pre-wrap',
                        },
                    }}
                actions={[
                    {
                        icon: TableIcons.OpenInNew,
                        tooltip: 'Show Unit',
                        onClick: (event, rowData) => props.history.push({
                            pathname: '/dashboard/reporting/timesheets/unit',
                            search: "?" + new URLSearchParams({ docId: rowData.docId }).toString(),
                            state: { unitList: props.unitData }
                        })
                    },
                    {
                        icon: TableIcons.Edit,
                        tooltip: 'Edit Unit',
                        onClick: (event, rowData) => {
                            openModal(rowData.docId, true)
                        }
                    },
                    {
                        icon: AccessTimeIcon,
                        tooltip: 'Add Unit Hours',
                        isFreeAction: true,
                        onClick: (event, rowData) => {
                            openAdminTimeModal()
                        }
                    },
                    {
                        icon: TableIcons.Add,
                        tooltip: 'Add Unit',
                        isFreeAction: true,
                        onClick: (event, rowData) => {
                            openModal(rowData.docId, false)
                        }
                    },

                ]}
            />
        </Grid>
    )
}
export default UnitTable
