import React, { useState, useEffect } from 'react'
import { Grid, Button, TextField, Typography, Paper, makeStyles, IconButton, Divider } from '@material-ui/core';
import { Save } from '@material-ui/icons';
import { Edit } from '@material-ui/icons';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import _ from 'lodash';
import { firestore } from '../../../firebase/firebase';
import { useUser } from '../../../providers/UserContext';
import { Cancel } from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
    card: {
        padding: theme.spacing(2),
        width: '100%',
    },
    paper: {
        maxWidth: '100%',
        margin: `${theme.spacing(1)}px auto`,
        padding: theme.spacing(2),
    },

}));
const DeliveryNotes = (props) => {
    const classes = useStyles()
    const { getUsers, users } = useUser();

    const [newNoteText, setNewNoteText] = useState("")
    const [addingNote, setAddingNote] = useState(false);
    const [editingNoteText, setEditingNoteText] = useState([])
    const [editingNote, setEditingNote] = useState(false)

    //gets user data if not set, sets id of rowData to openDelevieries to allow for the detail panel to stay open
    useEffect(() => {
        if(users.length <= 0){
            getUsers();
        }
        if(!props.openDeliveries.current.includes(props.rowData.tableData.id)){
            props.openDeliveries.current = [...props.openDeliveries.current, props.rowData.tableData.id]
        }
                
        return () => {
            props.openDeliveries.current.splice(props.openDeliveries.current.indexOf(props.rowData.tableData.id), 1)
        }
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    const addNote = (delivery) => {
        setAddingNote(true)
        let container = _.cloneDeep(delivery)
        delete container['tableData']
        container = {...container, notes: newNoteText}

        firestore.collection('deliveries').doc(delivery.docId).set(container)
        .then(() => {
            props.updateDelivery(container)
            setNewNoteText('')
            setAddingNote(false)
        })
        .catch((error) => {
            console.warn(error)
            setAddingNote(false)
        });
    }

    const editNote = (delivery) => {
        let container = _.cloneDeep(delivery)
        delete container['tableData']
        container = {...container, notes: editingNoteText}

        firestore.collection('deliveries').doc(delivery.docId).set(container)
        .then(() => {
            props.updateDelivery(container)
            setEditingNoteText('')
        })
        .catch((error) => {
            console.warn(error)
        });
    }

    const deleteNote = (delivery) => {
        let container = _.cloneDeep(delivery)
        delete container['notes']
        delete container['tableData']

        firestore.collection('deliveries').doc(delivery.docId).set(container)
        .then(() => {
            props.updateDelivery(container)
        })
        .catch((error) => {
            console.warn(error)
        });
    }

    return (
        <Grid>
            {!props.rowData.notes || props.rowData.notes === '' ?
                <Paper className={classes.card}>
                    <Grid container direction='column' >
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <TextField 
                                    label='New Note' 
                                    disabled={addingNote}
                                    fullWidth 
                                    value={newNoteText} 
                                    onChange={(event) => { setNewNoteText(event.target.value)} }
                                />
                            </Grid>         
                            <Grid item>
                                <Button disabled={addingNote} variant='contained' onClick={(event) => addNote(props.rowData) }>ADD NOTE</Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </Paper>
            :
                <Grid item >
                    <Paper className={classes.paper}>

                        <Grid container direction='row' spacing={1} wrap="nowrap">
                            <Grid container item xs={9}>
                                {editingNote 
                                    ?
                                    <Grid item xs={12} zeroMinWidth>
                                        <TextField 
                                            fullWidth 
                                            multiline 
                                            variant='outlined'
                                            style={{ marginTop: '5px' }}
                                            size='small' 
                                            value={editingNoteText} 
                                            onChange={(event) => setEditingNoteText(event.target.value)}>
                                        </TextField>
                                    </Grid>
                                    :
                                    <Grid item xs={2} className={classes.message}>
                                        <Typography style={{color:'grey'}}>Operator Notes</Typography>
                                        <Divider />
                                        <Typography 
                                            style={{ wordWrap: 'break-word', marginTop: '15px' }}>
                                                { props.rowData?.notes || "<No Text>"}
                                            </Typography>
                                    </Grid>
                                }
                            </Grid>

                            <Grid container item direction='row' xs={3} justifyContent='flex-end'>
                                {!editingNote && editNote &&
                                    < Grid item >
                                        <IconButton onClick={() => {
                                            setEditingNote(!editingNote)
                                            setEditingNoteText(props.rowData.notes)
                                        }}>
                                            <Edit />
                                        </IconButton>
                                    </Grid>
                                }
                                {editNote && editingNote &&
                                    <Grid item>
                                        <IconButton onClick={() => {
                                            setEditingNote(!editingNote)
                                            editNote(props.rowData)
                                        }}>
                                            <Save />
                                        </IconButton>
                                    </Grid>
                                }
                                {editNote && editingNote &&
                                    <Grid item>
                                        <IconButton onClick={() => setEditingNote(!editingNote)}>
                                            <Cancel />
                                        </IconButton>
                                    </Grid>

                                }
                                {deleteNote &&
                                    <Grid item xs={2}>
                                        <IconButton onClick={() => deleteNote(props.rowData)}><DeleteOutlineIcon /></IconButton>
                                    </Grid>
                                }
                            </Grid>
                        </Grid>
                    
                    </Paper >
                </Grid>
            }
        </Grid>
    )
}
export default (DeliveryNotes)
