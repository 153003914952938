import React from 'react';
import { Grid, Select, MenuItem, Typography} from '@material-ui/core';
import { withRouter } from 'react-router'
import { makeStyles } from '@material-ui/core';  

const useStyles = makeStyles((theme) => ({

    content: {
        display: 'flex',
        width: '100%',
        flexDirection: 'column',
        maxWidth: '1000px',
        margin: 'auto',
    },
    rlSpacing: {
        paddingRight: theme.spacing(1),
        paddingLeft: theme.spacing(1),
    },
    layoutRL: {
       height: '22px',
    },
    layoutSideVehicle: {
        position: 'relative',
        justifyContent: 'center',
    },
    layoutFrontVehicle: {
        position: 'relative',
    },
    layoutRect: {
        display: 'flex',
        width: '80px',
        height: '50px',
        margin: '0px 5px 5px 5px',
    },
}));

const TrailerLayout = (props) => {

    const classes = useStyles();
                                
    return(
        <Grid>
            <Grid container style={{justifyContent: 'center'}} >
                <Grid >
                    {/* rightOutterFront */}
                    <Select
                        id="rightOutterFront"
                        onChange={(event) => props.equipmentData.tires 
                            ? props.equipmentData.tires.attachment 
                                ? props.updateEquipmentData({...props.equipmentData, tires: {...props.equipmentData.tires, attachment : {...props.equipmentData.tires.attachment, 'rightOutterFront':  event.target.value} }})
                                : props.updateEquipmentData({...props.equipmentData, tires: {...props.equipmentData.tires, attachment : { 'rightOutterFront':  event.target.value} }})
                            :  props.updateEquipmentData({...props.equipmentData, tires: {attachment : { 'rightOutterFront':  event.target.value} }})
                        }
                        className={classes.layoutRect}
                        style={{backgroundColor: props.assignColor("attachment","rightOutterFront")}}
                        disabled={props.edit ? false : true}
                        value={props.equipmentData.tires ? (props.equipmentData.tires.attachment ? (props.equipmentData.tires.attachment.rightOutterFront ? props.equipmentData.tires.attachment.rightOutterFront : "") : ""): ""}
                    >
                        <MenuItem name='poor' key="poor" value='poor' className={classes.layoutRect} style={{background: 'red'}}></MenuItem>
                        <MenuItem name='fair' key="fair" value='fair' className={classes.layoutRect} style={{background: '#00D8D8'}}></MenuItem>
                        <MenuItem name='good' key="good" value='good' className={classes.layoutRect} style={{background: 'yellow'}}></MenuItem>
                        <MenuItem name='verygood' key="verygood" value='verygood' className={classes.layoutRect} style={{background: '#4DED30'}}></MenuItem>
                    </Select>
                </Grid>
                <Grid >
                    {/* rightOutterMid */}
                    <Select
                        id="rightOutterMid"
                        onChange={(event) => props.equipmentData.tires 
                            ? props.equipmentData.tires.attachment 
                                ? props.updateEquipmentData({...props.equipmentData, tires: {...props.equipmentData.tires, attachment : {...props.equipmentData.tires.attachment, 'rightOutterMid':  event.target.value} }})
                                : props.updateEquipmentData({...props.equipmentData, tires: {...props.equipmentData.tires, attachment : { 'rightOutterMid':  event.target.value} }})
                            :  props.updateEquipmentData({...props.equipmentData, tires: {attachment : { 'rightOutterMid':  event.target.value} }})
                        }
                        className={classes.layoutRect}
                        style={{backgroundColor: props.assignColor("attachment","rightOutterMid")}}
                        disabled={props.edit ? false : true}
                        value={props.equipmentData.tires ? (props.equipmentData.tires.attachment ? (props.equipmentData.tires.attachment.rightOutterMid ? props.equipmentData.tires.attachment.rightOutterMid : "") : ""): ""}
                    >
                        <MenuItem name='poor' key="poor" value='poor' className={classes.layoutRect} style={{background: 'red'}}></MenuItem>
                        <MenuItem name='fair' key="fair" value='fair' className={classes.layoutRect} style={{background: '#00D8D8'}}></MenuItem>
                        <MenuItem name='good' key="good" value='good' className={classes.layoutRect} style={{background: 'yellow'}}></MenuItem>
                        <MenuItem name='verygood' key="verygood" value='verygood' className={classes.layoutRect} style={{background: '#4DED30'}}></MenuItem>
                    </Select>
                </Grid>
                <Grid >
                    {/* rightOutterRear */}
                    <Select
                        id="rightOutterRear"
                        onChange={(event) => props.equipmentData.tires 
                            ? props.equipmentData.tires.attachment 
                                ? props.updateEquipmentData({...props.equipmentData, tires: {...props.equipmentData.tires, attachment : {...props.equipmentData.tires.attachment, 'rightOutterRear':  event.target.value} }})
                                : props.updateEquipmentData({...props.equipmentData, tires: {...props.equipmentData.tires, attachment : { 'rightOutterRear':  event.target.value} }})
                            :  props.updateEquipmentData({...props.equipmentData, tires: {attachment : { 'rightOutterRear':  event.target.value} }})
                        }
                        className={classes.layoutRect}
                        style={{backgroundColor: props.assignColor("attachment","rightOutterRear")}}
                        disabled={props.edit ? false : true}
                        value={props.equipmentData.tires ? (props.equipmentData.tires.attachment ? (props.equipmentData.tires.attachment.rightOutterRear ? props.equipmentData.tires.attachment.rightOutterRear : "") : ""): ""}
                    >
                        <MenuItem name='poor' key="poor" value='poor' className={classes.layoutRect} style={{background: 'red'}}></MenuItem>
                        <MenuItem name='fair' key="fair" value='fair' className={classes.layoutRect} style={{background: '#00D8D8'}}></MenuItem>
                        <MenuItem name='good' key="good" value='good' className={classes.layoutRect} style={{background: 'yellow'}}></MenuItem>
                        <MenuItem name='verygood' key="verygood" value='verygood' className={classes.layoutRect} style={{background: '#4DED30'}}></MenuItem>
                    </Select>
                </Grid>
            </Grid>

            <Grid container style={{justifyContent: 'center'}}>
                <Grid >
                    {/* rightInnerFront */}
                    <Select
                        id="rightInnerFront"
                        onChange={(event) => props.equipmentData.tires 
                            ? props.equipmentData.tires.attachment 
                                ? props.updateEquipmentData({...props.equipmentData, tires: {...props.equipmentData.tires, attachment : {...props.equipmentData.tires.attachment, 'rightInnerFront':  event.target.value} }})
                                : props.updateEquipmentData({...props.equipmentData, tires: {...props.equipmentData.tires, attachment : { 'rightInnerFront':  event.target.value} }})
                            :  props.updateEquipmentData({...props.equipmentData, tires: {attachment : { 'rightInnerFront':  event.target.value} }})
                        }
                        className={classes.layoutRect}
                        style={{backgroundColor: props.assignColor("attachment","rightInnerFront")}}
                        disabled={props.edit ? false : true}
                        value={props.equipmentData.tires ? (props.equipmentData.tires.attachment ? (props.equipmentData.tires.attachment.rightInnerFront ? props.equipmentData.tires.attachment.rightInnerFront : "") : ""): ""}
                    >
                        <MenuItem name='poor' key="poor" value='poor' className={classes.layoutRect} style={{background: 'red'}}></MenuItem>
                        <MenuItem name='fair' key="fair" value='fair' className={classes.layoutRect} style={{background: '#00D8D8'}}></MenuItem>
                        <MenuItem name='good' key="good" value='good' className={classes.layoutRect} style={{background: 'yellow'}}></MenuItem>
                        <MenuItem name='verygood' key="verygood" value='verygood' className={classes.layoutRect} style={{background: '#4DED30'}}></MenuItem>
                    </Select>
                </Grid>
                <Grid >
                    {/* rightInnerMid */}
                    <Select
                        id="rightInnerMid"
                        onChange={(event) => props.equipmentData.tires 
                            ? props.equipmentData.tires.attachment 
                                ? props.updateEquipmentData({...props.equipmentData, tires: {...props.equipmentData.tires, attachment : {...props.equipmentData.tires.attachment, 'rightInnerMid':  event.target.value} }})
                                : props.updateEquipmentData({...props.equipmentData, tires: {...props.equipmentData.tires, attachment : { 'rightInnerMid':  event.target.value} }})
                            :  props.updateEquipmentData({...props.equipmentData, tires: {attachment : { 'rightInnerMid':  event.target.value} }})
                        }
                        className={classes.layoutRect}
                        style={{backgroundColor: props.assignColor("attachment","rightInnerMid")}}
                        disabled={props.edit ? false : true}
                        value={props.equipmentData.tires ? (props.equipmentData.tires.attachment ? (props.equipmentData.tires.attachment.rightInnerMid ? props.equipmentData.tires.attachment.rightInnerMid : "") : ""): ""}
                    >
                        <MenuItem name='poor' key="poor" value='poor' className={classes.layoutRect} style={{background: 'red'}}></MenuItem>
                        <MenuItem name='fair' key="fair" value='fair' className={classes.layoutRect} style={{background: '#00D8D8'}}></MenuItem>
                        <MenuItem name='good' key="good" value='good' className={classes.layoutRect} style={{background: 'yellow'}}></MenuItem>
                        <MenuItem name='verygood' key="verygood" value='verygood' className={classes.layoutRect} style={{background: '#4DED30'}}></MenuItem>
                    </Select>
                </Grid>
                <Grid >
                    {/* rightInnerRear */}
                    <Select
                        id="rightInnerRear"
                        onChange={(event) => props.equipmentData.tires 
                            ? props.equipmentData.tires.attachment 
                                ? props.updateEquipmentData({...props.equipmentData, tires: {...props.equipmentData.tires, attachment : {...props.equipmentData.tires.attachment, 'rightInnerRear':  event.target.value} }})
                                : props.updateEquipmentData({...props.equipmentData, tires: {...props.equipmentData.tires, attachment : { 'rightInnerRear':  event.target.value} }})
                            :  props.updateEquipmentData({...props.equipmentData, tires: {attachment : { 'rightInnerRear':  event.target.value} }})
                        }
                        className={classes.layoutRect}
                        style={{backgroundColor: props.assignColor("attachment","rightInnerRear")}}
                        disabled={props.edit ? false : true}
                        value={props.equipmentData.tires ? (props.equipmentData.tires.attachment ? (props.equipmentData.tires.attachment.rightInnerRear ? props.equipmentData.tires.attachment.rightInnerRear : "") : ""): ""}
                    >
                        <MenuItem name='poor' key="poor" value='poor' className={classes.layoutRect} style={{background: 'red'}}></MenuItem>
                        <MenuItem name='fair' key="fair" value='fair' className={classes.layoutRect} style={{background: '#00D8D8'}}></MenuItem>
                        <MenuItem name='good' key="good" value='good' className={classes.layoutRect} style={{background: 'yellow'}}></MenuItem>
                        <MenuItem name='verygood' key="verygood" value='verygood' className={classes.layoutRect} style={{background: '#4DED30'}}></MenuItem>
                    </Select>
                </Grid>
            </Grid>
            
            {/* veritcal spacers */}
            <Grid container>
                <Typography className={classes.layoutRect}></Typography>
            </Grid>
            <Grid container>
                <Typography className={classes.layoutRect}></Typography>
            </Grid>

            <Grid container style={{justifyContent: 'center'}}>
                <Grid >
                    {/* leftInnerFront */}
                    <Select
                        id="leftInnerFront"
                        onChange={(event) => props.equipmentData.tires 
                            ? props.equipmentData.tires.attachment 
                                ? props.updateEquipmentData({...props.equipmentData, tires: {...props.equipmentData.tires, attachment : {...props.equipmentData.tires.attachment, 'leftInnerFront':  event.target.value} }})
                                : props.updateEquipmentData({...props.equipmentData, tires: {...props.equipmentData.tires, attachment : { 'leftInnerFront':  event.target.value} }})
                            :  props.updateEquipmentData({...props.equipmentData, tires: {attachment : { 'leftInnerFront':  event.target.value} }})
                        }
                        className={classes.layoutRect}
                        style={{backgroundColor: props.assignColor("attachment","leftInnerFront")}}
                        disabled={props.edit ? false : true}
                        value={props.equipmentData.tires ? (props.equipmentData.tires.attachment ? (props.equipmentData.tires.attachment.leftInnerFront ? props.equipmentData.tires.attachment.leftInnerFront : "") : ""): ""}
                    >
                        <MenuItem name='poor' key="poor" value='poor' className={classes.layoutRect} style={{background: 'red'}}></MenuItem>
                        <MenuItem name='fair' key="fair" value='fair' className={classes.layoutRect} style={{background: '#00D8D8'}}></MenuItem>
                        <MenuItem name='good' key="good" value='good' className={classes.layoutRect} style={{background: 'yellow'}}></MenuItem>
                        <MenuItem name='verygood' key="verygood" value='verygood' className={classes.layoutRect} style={{background: '#4DED30'}}></MenuItem>
                    </Select>
                </Grid>
                <Grid >
                    {/* leftInnerMid */}
                    <Select
                        id="leftInnerMid"
                        onChange={(event) => props.equipmentData.tires 
                            ? props.equipmentData.tires.attachment 
                                ? props.updateEquipmentData({...props.equipmentData, tires: {...props.equipmentData.tires, attachment : {...props.equipmentData.tires.attachment, 'leftInnerMid':  event.target.value} }})
                                : props.updateEquipmentData({...props.equipmentData, tires: {...props.equipmentData.tires, attachment : { 'leftInnerMid':  event.target.value} }})
                            :  props.updateEquipmentData({...props.equipmentData, tires: {attachment : { 'leftInnerMid':  event.target.value} }})
                        }
                        className={classes.layoutRect}
                        style={{backgroundColor: props.assignColor("attachment","leftInnerMid")}}
                        disabled={props.edit ? false : true}
                        value={props.equipmentData.tires ? (props.equipmentData.tires.attachment ? (props.equipmentData.tires.attachment.leftInnerMid ? props.equipmentData.tires.attachment.leftInnerMid : "") : ""): ""}
                    >
                        <MenuItem name='poor' key="poor" value='poor' className={classes.layoutRect} style={{background: 'red'}}></MenuItem>
                        <MenuItem name='fair' key="fair" value='fair' className={classes.layoutRect} style={{background: '#00D8D8'}}></MenuItem>
                        <MenuItem name='good' key="good" value='good' className={classes.layoutRect} style={{background: 'yellow'}}></MenuItem>
                        <MenuItem name='verygood' key="verygood" value='verygood' className={classes.layoutRect} style={{background: '#4DED30'}}></MenuItem>
                    </Select>
                </Grid>
                <Grid >
                    {/* leftInnerRear */}
                    <Select
                        id="leftInnerRear"
                        onChange={(event) => props.equipmentData.tires 
                            ? props.equipmentData.tires.attachment 
                                ? props.updateEquipmentData({...props.equipmentData, tires: {...props.equipmentData.tires, attachment : {...props.equipmentData.tires.attachment, 'leftInnerRear':  event.target.value} }})
                                : props.updateEquipmentData({...props.equipmentData, tires: {...props.equipmentData.tires, attachment : { 'leftInnerRear':  event.target.value} }})
                            :  props.updateEquipmentData({...props.equipmentData, tires: {attachment : { 'leftInnerRear':  event.target.value} }})
                        }
                        className={classes.layoutRect}
                        style={{backgroundColor: props.assignColor("attachment","leftInnerRear")}}
                        disabled={props.edit ? false : true}
                        value={props.equipmentData.tires ? (props.equipmentData.tires.attachment ? (props.equipmentData.tires.attachment.leftInnerRear ? props.equipmentData.tires.attachment.leftInnerRear : "") : ""): ""}
                    >
                        <MenuItem name='poor' key="poor" value='poor' className={classes.layoutRect} style={{background: 'red'}}></MenuItem>
                        <MenuItem name='fair' key="fair" value='fair' className={classes.layoutRect} style={{background: '#00D8D8'}}></MenuItem>
                        <MenuItem name='good' key="good" value='good' className={classes.layoutRect} style={{background: 'yellow'}}></MenuItem>
                        <MenuItem name='verygood' key="verygood" value='verygood' className={classes.layoutRect} style={{background: '#4DED30'}}></MenuItem>
                    </Select>
                </Grid>
            </Grid>

            <Grid container style={{justifyContent: 'center'}}>
                <Grid >
                    {/* leftOutterFront */}
                    <Select
                        id="leftOutterFront"
                        onChange={(event) => props.equipmentData.tires 
                            ? props.equipmentData.tires.attachment 
                                ? props.updateEquipmentData({...props.equipmentData, tires: {...props.equipmentData.tires, attachment : {...props.equipmentData.tires.attachment, 'leftOutterFront':  event.target.value} }})
                                : props.updateEquipmentData({...props.equipmentData, tires: {...props.equipmentData.tires, attachment : { 'leftOutterFront':  event.target.value} }})
                            :  props.updateEquipmentData({...props.equipmentData, tires: {attachment : { 'leftOutterFront':  event.target.value} }})
                        } 
                        className={classes.layoutRect}
                        style={{backgroundColor: props.assignColor("attachment","leftOutterFront")}}
                        disabled={props.edit ? false : true}
                        value={props.equipmentData.tires ? (props.equipmentData.tires.attachment ? (props.equipmentData.tires.attachment.leftOutterFront ? props.equipmentData.tires.attachment.leftOutterFront : "") : ""): ""}
                    >
                        <MenuItem name='poor' key="poor" value='poor' className={classes.layoutRect} style={{background: 'red'}}></MenuItem>
                        <MenuItem name='fair' key="fair" value='fair' className={classes.layoutRect} style={{background: '#00D8D8'}}></MenuItem>
                        <MenuItem name='good' key="good" value='good' className={classes.layoutRect} style={{background: 'yellow'}}></MenuItem>
                        <MenuItem name='verygood' key="verygood" value='verygood' className={classes.layoutRect} style={{background: '#4DED30'}}></MenuItem>
                    </Select>
                </Grid>
                <Grid >
                    {/* leftOutterMid */}
                    <Select
                        id="leftOutterMid"
                        onChange={(event) => props.equipmentData.tires 
                            ? props.equipmentData.tires.attachment 
                                ? props.updateEquipmentData({...props.equipmentData, tires: {...props.equipmentData.tires, attachment : {...props.equipmentData.tires.attachment, 'leftOutterMid':  event.target.value} }})
                                : props.updateEquipmentData({...props.equipmentData, tires: {...props.equipmentData.tires, attachment : { 'leftOutterMid':  event.target.value} }})
                            :  props.updateEquipmentData({...props.equipmentData, tires: {attachment : { 'leftOutterMid':  event.target.value} }})
                        } 
                        className={classes.layoutRect}
                        style={{backgroundColor: props.assignColor("attachment","leftOutterMid")}}
                        disabled={props.edit ? false : true}
                        value={props.equipmentData.tires ? (props.equipmentData.tires.attachment ? (props.equipmentData.tires.attachment.leftOutterMid ? props.equipmentData.tires.attachment.leftOutterMid : "") : ""): ""}
                    >
                        <MenuItem name='poor' key="poor" value='poor' className={classes.layoutRect} style={{background: 'red'}}></MenuItem>
                        <MenuItem name='fair' key="fair" value='fair' className={classes.layoutRect} style={{background: '#00D8D8'}}></MenuItem>
                        <MenuItem name='good' key="good" value='good' className={classes.layoutRect} style={{background: 'yellow'}}></MenuItem>
                        <MenuItem name='verygood' key="verygood" value='verygood' className={classes.layoutRect} style={{background: '#4DED30'}}></MenuItem>
                    </Select>
                </Grid>
                <Grid >
                    {/* leftOutterRear */}
                    <Select
                        id="leftOutterRear"
                        onChange={(event) => props.equipmentData.tires 
                            ? props.equipmentData.tires.attachment 
                                ? props.updateEquipmentData({...props.equipmentData, tires: {...props.equipmentData.tires, attachment : {...props.equipmentData.tires.attachment, 'leftOutterRear':  event.target.value} }})
                                : props.updateEquipmentData({...props.equipmentData, tires: {...props.equipmentData.tires, attachment : { 'leftOutterRear':  event.target.value} }})
                            :  props.updateEquipmentData({...props.equipmentData, tires: {attachment : { 'leftOutterRear':  event.target.value} }})
                        } 
                        className={classes.layoutRect}
                        style={{backgroundColor: props.assignColor("attachment","leftOutterRear")}}
                        disabled={props.edit ? false : true}
                        value={props.equipmentData.tires ? (props.equipmentData.tires.attachment ? (props.equipmentData.tires.attachment.leftOutterRear ? props.equipmentData.tires.attachment.leftOutterRear : "") : ""): ""}
                    >
                        <MenuItem name='poor' key="poor" value='poor' className={classes.layoutRect} style={{background: 'red'}}></MenuItem>
                        <MenuItem name='fair' key="fair" value='fair' className={classes.layoutRect} style={{background: '#00D8D8'}}></MenuItem>
                        <MenuItem name='good' key="good" value='good' className={classes.layoutRect} style={{background: 'yellow'}}></MenuItem>
                        <MenuItem name='verygood' key="verygood" value='verygood' className={classes.layoutRect} style={{background: '#4DED30'}}></MenuItem>
                    </Select>
                </Grid>
            </Grid>
        </Grid>
    )
}

export default withRouter(TrailerLayout)


