import React, { useState, useEffect } from 'react';
import { firestore, removeFile } from '../../../../firebase/firebase'
import { withRouter } from 'react-router-dom';
import DocumentCard from '../../../components/Reports/Documents/DocumentCard'
import { useUser } from '../../../../providers/UserContext';
import { Paper, Grid, makeStyles } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';

const useStyles = makeStyles((theme) => ({
    content: {
        display: 'flex',
        width: '100%',
        padding: '32px',
        justifyContent: 'center',
        marginTop: '42px',
    },
}));

const Document = (props) => {
    const [documentData, setDocumentData] = useState({});
    const [documentLoading, setDocumentLoading] = useState(true);
    const [errorMessage, setError] = useState("");

    const { loading, getUsers, getUser } = useUser();

    const classes = useStyles();

    useEffect(() => {
        getUsers();
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    //listener to the doc for changes
    useEffect(() => {
        const path = new URLSearchParams(props.location.search).get("docId");

        let unsubscribe = firestore.collection('documents').doc(path).onSnapshot((doc) => {
            if(doc.exists){
                setDocumentData({
                    ...doc.data(),
                    'docId': doc.id,
                    'dateSubmit' : doc.data().date.toDate().toDateString(),
                    'URL': doc.data().pdfURL.toString().substr(0, doc.data().pdfURL.indexOf('.pdf')+4),
                })
                setDocumentLoading(false);
            }else{
                setError("Document does not exist");
                setDocumentLoading(false);
            }
        })
        return () => { unsubscribe(); }
        //eslint-disable-next-line
    }, []);

    useEffect(() => {
        const path = new URLSearchParams(props.location.search).get("docId");


        firestore.collection('documents').where('__name__', "==", path).get()
        .then(querySnapshot => {
            const docResponse = querySnapshot.docs.map((doc) => {
                return {
                    ...doc.data(),
                    'docId': doc.id,
                    'dateSubmit' : doc.data().date.toDate().toDateString(),
                    'URL': doc.data().pdfURL.toString().substr(0, doc.data().pdfURL.indexOf('.pdf')+4),
                }
            })
            setDocumentData(docResponse[0]);
            setDocumentLoading(false);
        })
        .catch(e => {
            setError(e.message);
            setDocumentLoading(false);
            console.log(e.message);
        })
     

    }, [props.location.search]);

    const removePDF = async (pdfURL) => {

        let query = await removeFile(pdfURL);

        return query.fileDeleted;
    }

    const deleteDocument = async () => {
        await removePDF(documentData.pdfURL);
        //for each url in documentData.photoUrls remove the file from storage
        if(documentData.photoUrls){
            documentData.photoUrls.forEach(async (url) => {
                await removePDF(url);
            })
        }
        firestore.collection('documents').doc(documentData.docId).delete()
        .then(() => {
            props.history.goBack()
        })
        .catch(e => {
            setError(e.message);
            setDocumentLoading(false);
            console.log(e.message);
        })

        var docData = JSON.parse(sessionStorage.getItem("documentData"));
        docData.splice(docData.indexOf(documentData), 1);
        sessionStorage.setItem("documentData", JSON.stringify(docData));



    }

    const userData = getUser(documentData.uid);

    return (
        <React.Fragment>
            { errorMessage && <Paper>{errorMessage}</Paper>}
            {(loading || documentLoading ) 
            ? 
            <Grid className={classes.content}>
                <Skeleton variant='rect' width={'40vw'} height={'55vh'} />
            </Grid>
            : 
            <DocumentCard documentData={documentData} userData={userData} deleteDocument={deleteDocument} />}
        </React.Fragment>
    )

}

export default withRouter(Document)