import React, {useState, useCallback} from 'react';
import { Grid, Paper, Typography, Select, MenuItem, Checkbox, Snackbar, Button, TextField } from '@material-ui/core';
import { withRouter } from 'react-router'
import { makeStyles } from '@material-ui/core';
import _ from 'lodash'
import { firestore } from '../../../firebase/firebase'

//Tire Layouts
import TractorLayout from './TireLayouts/TractorLayout';
import TrailerLayout from './TireLayouts/TrailerLayout';
import PassengerLayout from './TireLayouts/PassengerLayout';

const useStyles = makeStyles((theme) => ({

    content: {
        display: 'flex',
        width: '100%',
        flexDirection: 'column',
        maxWidth: '1000px',
        margin: 'auto',
    },
    paper: {
        borderRadius: '0px',
        margin: '0px',
        marginBottom:'5px',
        width: '100%',
        maxWidth: '1000px',
        position: 'relative'
    },
    header: {
        display: 'flex',
        justifyContent: 'center',
        marginBottom:'5px',
        //fontWeight: '500',
    },
    rlSpacing: {
        paddingRight: theme.spacing(1),
        paddingLeft: theme.spacing(1),
    },
    legendLabel: {
        lineHeight: 1.5,
    },
    legendRect: {
        display: 'flex',
        width: '30px',
        height: '20px',
    },
    optionsLabel: {
        lineHeight: 3,
    },
    layoutRLTags: {
       height: '22px',
    },
    layoutSideVehicle: {
        position: 'relative',
        justifyContent: 'center',
    },
    layoutFrontVehicle: {
        position: 'relative',
    },
    layoutRect: {
        display: 'flex',
        width: '80px',
        height: '50px',
        margin: '0px 5px 5px 5px',
    },
}));

const TireTab = (props) => {
    const classes = useStyles();

    const [equipmentData, setEquipmentData] = useState(props.equipmentData);
    const [error, setError] = useState("");
    const [saving, setSaving] = useState(false);
    const [saveState, setSaveState] = useState(props.saveState);

    let path = new URLSearchParams(props.location.search).get("docId");

    const updateEquipmentData = useCallback((e) => {
            setEquipmentData(e)
        },
        [],
    );

    React.useEffect(() => {
        
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    const confirmSave = () => {
        props.setEquipmentData(equipmentData);
        props.setSaveState(equipmentData);
        setSaving(true);
        firestore.collection('unitDescriptions').doc(path)
            .update(equipmentData)
            .then(() => {
                setSaveState(equipmentData);
                setSaving(false)
            })
            .catch((e) => {setError(e); console.log(error);});
    };

    const cancelSave = () => {
        setEquipmentData(saveState);
    };

    const assignColor = (type, identifier) => {
        if(equipmentData.tires){
            if(equipmentData.tires.vehicle && type === 'vehicle'){
                if(equipmentData.tires[type][identifier] === "poor"){
                    return 'red';
                }
                else if(equipmentData.tires[type][identifier] === "fair"){
                    return '#00D8D8';
                }
                else if(equipmentData.tires[type][identifier] === "good"){
                    return 'yellow';
                }
                else if(equipmentData.tires[type][identifier] === "verygood"){
                    return '#4DED30';
                }
                else{
                    return '#4DED30';
                }
            }
            if(equipmentData.tires.attachment && type === 'attachment'){
                if(equipmentData.tires[type][identifier] === "poor"){
                    return 'red';
                }
                else if(equipmentData.tires[type][identifier] === "fair"){
                    return '#00D8D8';
                }
                else if(equipmentData.tires[type][identifier] === "good"){
                    return 'yellow';
                }
                else if(equipmentData.tires[type][identifier] === "verygood"){
                    return '#4DED30';
                }
                else{
                    return '#4DED30';
                }
            }
            else{
                return '#4DED30';
            }
        }
        else{
            return '#4DED30';
        }
    }

    const handleEquipTypeChange = (type) => {

        if((type !== equipmentData.equipmentType)){

            if((type === "tractor" && equipmentData.equipmentType === "") || (type === "tractor" && _.isUndefined(equipmentData.equipmentType))){
                    
                    updateEquipmentData({
                        ...equipmentData,
                        'equipmentType': type,
                    })
            }
            else{
                
                    updateEquipmentData({
                        ...equipmentData,
                        'equipmentType': type,
                        tires: {
                        ...equipmentData.tires,
                            vehicle: {}
                        }
                    })
            }
        }  
    }
    

    return(
        <Grid container spacing={2}>

            {/* LEGEND/OPTIONS/LAYOUT */}
            <Grid item xs={12}>
                <Grid className={classes.rlSpacing} style={{justifyContent: 'center'}} container spacing={2}>
                    {/* LEGEND */}
                    <Grid item xs={6} className={classes.rlSpacing} style={{maxWidth: '300px'}}>
                        <Paper className={classes.paper}>
                            <Typography className={classes.header} variant= 'h5'>Tire % Remaining</Typography>
                            <hr></hr>
                            <Grid className={classes.rlSpacing} container spacing={2}>
                                <Grid item xs={5}>
                                    <Typography className={classes.legendLabel} noWrap >{"< 25%"}</Typography>
                                </Grid>
                                <Grid item xs={2}>
                                    <Typography className={classes.legendRect} style={{backgroundColor: 'red'}}></Typography>
                                </Grid>
                                <Grid item xs={5}>
                                    <Typography className={classes.legendLabel} noWrap style={{float: 'right'}}>Poor</Typography>
                                </Grid>
                            </Grid>
                            <Grid className={classes.rlSpacing} container spacing={2}>
                                <Grid item xs={5}>
                                    <Typography className={classes.legendLabel} noWrap >25% - 50%</Typography>
                                </Grid>
                                <Grid item xs={2}>
                                    <Typography className={classes.legendRect} style={{backgroundColor: '#00D8D8'}}></Typography>
                                </Grid>
                                <Grid item xs={5}>
                                    <Typography className={classes.legendLabel} noWrap style={{float: 'right'}} >Fair</Typography>
                                </Grid>
                            </Grid>
                            <Grid className={classes.rlSpacing} container spacing={2}>
                                <Grid item xs={5}>
                                    <Typography className={classes.legendLabel} noWrap >51% - 75%</Typography>
                                </Grid>
                                <Grid item xs={3}>
                                    <Typography className={classes.legendRect} style={{backgroundColor: 'yellow'}}></Typography>
                                </Grid>
                                <Grid item xs={4}>
                                    <Typography className={classes.legendLabel} noWrap style={{float: 'right'}}>Good</Typography>
                                </Grid>
                            </Grid>
                            <Grid className={classes.rlSpacing} container spacing={2}>
                                <Grid item xs={5}>
                                    <Typography className={classes.legendLabel} noWrap >76% - 100%</Typography>
                                </Grid>
                                <Grid item xs={2}>
                                    <Typography className={classes.legendRect} style={{backgroundColor: '#4DED30'}}></Typography>
                                </Grid>
                                <Grid item xs={5}>
                                    <Typography className={classes.legendLabel} noWrap style={{float: 'right'}}>Very Good</Typography>
                                </Grid>
                            </Grid>
                        </Paper>
                    </Grid>

                    {/* OPTIONS */}
                    <Grid item xs={6} style={{maxWidth: '300px'}}>
                        <Paper className={classes.paper}>
                            <Typography className={classes.header} variant= 'h5'>Options</Typography>
                            <hr></hr>
                            <Grid container>
                                <Grid item xs={7} className={classes.rlSpacing}>
                                    <Typography style={{lineHeight: 2}} noWrap>Equipment Type:</Typography>
                                </Grid>
                                <Grid item xs={5} className={classes.rlSpacing}>
                                    {props.edit
                                    ? <Select
                                        autoWidth={true}
                                        value={equipmentData.equipmentType}
                                        //changing equipment data too close together, second will overwrite first
                                        onChange={(event) => {handleEquipTypeChange(event.target.value)}} 
                                        style={{float: 'right'}}
                                    >
                                        <MenuItem name='tractor' key="tractor" value='tractor'>
                                            Tractor
                                        </MenuItem>
                                        <MenuItem name='passenger' key="passenger" value='passenger'>
                                            Passenger
                                        </MenuItem>
                                        <MenuItem name='truck' key="truck" value='truck'>
                                            Truck
                                        </MenuItem>
                                        <MenuItem name='loader' key="loader" value='loader'>
                                            Loader
                                        </MenuItem>
                                    </Select>
                                    : <Typography noWrap align="right" style={{marginTop: '5px'}} >{equipmentData.equipmentType.charAt(0).toUpperCase() + equipmentData.equipmentType.slice(1)}</Typography>
                                    }
                                </Grid>

                                <Grid item xs={9}  className={classes.rlSpacing}>
                                    <Typography className={classes.optionsLabel} noWrap>Trailer Attatched:</Typography>
                                </Grid>
                                <Grid item xs={3} className={classes.rlSpacing}>
                                    <Checkbox 
                                    style={{marginLeft: '15px'}}
                                    disabled={props.edit ? false : true}
                                    checked={equipmentData.trailerAttached ? true : false}
                                    onChange={(event) => { updateEquipmentData({ ...equipmentData, 'trailerAttached':  event.target.checked }) }}
                                    />
                                </Grid>
                            </Grid>
                        </Paper>
                        <Grid container>
                            <Grid item xs={6} className={classes.rlSpacing}>
                                <Typography noWrap style={{marginTop: '5px'}}>Inspecation Date:</Typography>
                            </Grid>
                            <Grid item xs={6} className={classes.rlSpacing}>
                                {props.edit
                                    ?<TextField
                                    size="small"
                                    value={equipmentData.tires ? (equipmentData.tires.inspectionDate ? equipmentData.tires.inspectionDate : "") : ""}
                                    onChange={(event) => {equipmentData.tires 
                                        ? updateEquipmentData({...equipmentData, tires: {...equipmentData.tires, 'inspectionDate': event.target.value}}) 
                                        : updateEquipmentData({...equipmentData, tires: { 'inspectionDate': event.target.value}}) 
                                    }}
                                    />
                                    : <Typography noWrap style={{marginTop: '5px'}} align="right">
                                        {equipmentData.tires ? (equipmentData.tires.inspectionDate ? equipmentData.tires.inspectionDate : "") : ""}
                                      </Typography>
                                }
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>

                {/* WITH TRAILER LAYOUT */}
                <Grid item xs={12} className={classes.rlSpacing} >

                {equipmentData.trailerAttached ?
                    <Grid container spacing={2}>
                        <Grid item xs={6}>
                            <Paper className={classes.paper} style={{marginTop: '10px'}}>
                                <Typography className={classes.header} variant= 'h5'>{equipmentData.equipmentType ? equipmentData.equipmentType.charAt(0).toUpperCase() + equipmentData.equipmentType.slice(1) : "Tractor"}</Typography>
                                <hr></hr>
                                <Grid item xs={12} className={classes.layoutRLTags}>
                                    <Typography noWrap className={classes.rlSpacing}>R</Typography>
                                </Grid>

                                {/* conditional case statement to determine layout */}
                                {( (equipmentData.equipmentType === "tractor" || equipmentData.equipmentType === "" || _.isUndefined(equipmentData.equipmentType)) &&
                                <TractorLayout
                                    equipmentData={equipmentData} saveState={props.saveState} edit={props.edit}
                                    cancelSave={props.cancelSave} confirmSave={props.confirmSave} 
                                    updateEquipmentData={updateEquipmentData} saving={saving}
                                    setSaveState={props.setSaveState} setEquipmentData={props.setEquipmentData}
                                    assignColor={assignColor} 
                                /> )
                                || 
                                ( (equipmentData.equipmentType === "passenger") &&
                                <PassengerLayout
                                equipmentData={equipmentData} saveState={props.saveState} edit={props.edit}
                                cancelSave={props.cancelSave} confirmSave={props.confirmSave} 
                                updateEquipmentData={updateEquipmentData} saving={saving}
                                setSaveState={props.setSaveState} setEquipmentData={props.setEquipmentData}
                                assignColor={assignColor} 
                                /> )
                                || 
                                ( (equipmentData.equipmentType === "truck" || equipmentData.equipmentType === "loader") &&
                                <TractorLayout
                                    equipmentData={equipmentData} saveState={props.saveState} edit={props.edit}
                                    cancelSave={props.cancelSave} confirmSave={props.confirmSave} 
                                    updateEquipmentData={updateEquipmentData} saving={saving}
                                    setSaveState={props.setSaveState} setEquipmentData={props.setEquipmentData}
                                    assignColor={assignColor} 
                                /> )
                                }

                            <Grid item xs={12} className={classes.layoutRLTags}>
                                <Typography noWrap className={classes.rlSpacing}>L</Typography>
                            </Grid>                        
                            </Paper>
                        </Grid>
                        <Grid item xs={6} className={classes.rlSpacing}>
                            <Paper className={classes.paper} style={{marginTop: '10px'}}>
                                <Typography className={classes.header} variant= 'h5'>Trailer</Typography>
                                <hr></hr>
                                <Grid item xs={12} className={classes.layoutRLTags}>
                                    <Typography noWrap className={classes.rlSpacing}></Typography>
                                </Grid>

                                <TrailerLayout
                                    equipmentData={equipmentData} saveState={props.saveState} edit={props.edit}
                                    cancelSave={props.cancelSave} confirmSave={props.confirmSave} 
                                    updateEquipmentData={updateEquipmentData} saving={saving}
                                    setSaveState={props.setSaveState} setEquipmentData={props.setEquipmentData}
                                    assignColor={assignColor} 
                                />

                                <Grid item xs={12} className={classes.layoutRLTags}>
                                    <Typography noWrap className={classes.rlSpacing}></Typography>
                                </Grid>
                            </Paper>
                        </Grid>
                    </Grid>
                :
                /* WITHOUT TRAILER LAYOUT */
                <Grid container spacing={2} >
                    {/* spacer */}
                    <Grid item xs={3}></Grid>
                    <Grid item xs={6} >
                        <Paper className={classes.paper} style={{marginTop: '10px'}}>
                            <Typography className={classes.header} variant= 'h5'>{equipmentData.equipmentType ? equipmentData.equipmentType.charAt(0).toUpperCase() + equipmentData.equipmentType.slice(1) : "Tractor"}</Typography>
                            <hr></hr>
                            <Grid item xs={12} className={classes.layoutRLTags}>
                                <Typography noWrap className={classes.rlSpacing}>R</Typography>
                            </Grid>

                            {/* conditional case statement to determine layout */}
                            {( (equipmentData.equipmentType === "tractor" || equipmentData.equipmentType === "" || _.isUndefined(equipmentData.equipmentType)) &&
                            <TractorLayout
                                equipmentData={equipmentData} saveState={props.saveState} edit={props.edit}
                                cancelSave={props.cancelSave} confirmSave={props.confirmSave} 
                                updateEquipmentData={updateEquipmentData} saving={saving}
                                setSaveState={props.setSaveState} setEquipmentData={props.setEquipmentData}
                                assignColor={assignColor} 
                            /> )
                            || 
                            ( (equipmentData.equipmentType === "passenger") &&
                            <PassengerLayout
                            equipmentData={equipmentData} saveState={props.saveState} edit={props.edit}
                            cancelSave={props.cancelSave} confirmSave={props.confirmSave} 
                            updateEquipmentData={updateEquipmentData} saving={saving}
                            setSaveState={props.setSaveState} setEquipmentData={props.setEquipmentData}
                            assignColor={assignColor} 
                            /> )
                            || 
                            ( (equipmentData.equipmentType === "truck" || equipmentData.equipmentType === "loader") &&
                            <TractorLayout
                                equipmentData={equipmentData} saveState={props.saveState} edit={props.edit}
                                cancelSave={props.cancelSave} confirmSave={props.confirmSave} 
                                updateEquipmentData={updateEquipmentData} saving={saving}
                                setSaveState={props.setSaveState} setEquipmentData={props.setEquipmentData}
                                assignColor={assignColor} 
                            /> )
                            }

                            <Grid item xs={12} className={classes.layoutRLTags}>
                                <Typography noWrap className={classes.rlSpacing}>L</Typography>
                            </Grid>                        
                        </Paper>
                    </Grid>
                    {/* spacer */}
                    <Grid item xs={3}></Grid>
                </Grid>
                }
                </Grid>
            </Grid>
            <Snackbar
                //disableAutoFocus={true}
                //disableEnforceFocus={true}
                open={!_.isEqual(equipmentData, saveState)}
                message={saving ? 'Saving...' : 'Save Document?'}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center'
                }}
                action={
                    <React.Fragment>
                        {saving
                            ? null
                            : <React.Fragment>
                                <Button variant='text' color='primary' onClick={cancelSave} style={{ marginLeft: '32px', marginRight: '8px' }}>Cancel</Button>
                                <Button variant='contained' onClick={confirmSave}>Confirm</Button>
                            </React.Fragment>
                        }
                    </React.Fragment>
                } />
        </Grid>

    )
}
export default withRouter(TireTab)