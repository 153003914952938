import React, { useEffect, useState } from 'react';
import { Card, Grid, makeStyles, Tab, Box, Snackbar, Button,
Dialog, DialogActions, DialogTitle, DialogContent, DialogContentText } from '@material-ui/core';
import { withRouter } from 'react-router';
import MaterialTable from '@material-table/core';
import TabContext from '@material-ui/lab/TabContext';
import TabList from '@material-ui/lab/TabList';
import TabPanel from '@material-ui/lab/TabPanel';
import { Alert, Skeleton } from '@material-ui/lab';
import Slide from '@material-ui/core/Slide';
import _ from 'lodash';
import { useUser } from '../../../providers/UserContext';
import { useAuth } from '../../../providers/AuthContext';
import AddBoxIcon from '@material-ui/icons/AddBox';
import { TableIcons } from '../../../constants/tableIcons';
import { functions } from '../../../firebase/firebase'
import PrintIcon from '@material-ui/icons/Print';

function SlideTransition(props) {
  return <Slide {...props} direction="up" />;
}
const useStyles = makeStyles((theme) => ({

  card: {
    padding: theme.spacing(2),
    width: '100%',
    height: '100%',
  },

  content: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
},

  grid: {
    marginTop: theme.spacing(2),
    maxWidth: '1000px',
  },

  table: {
    display: 'flex',
    justifyContent: 'center',
    minWidth:'1000px',
    maxWidth:'1000px'
  },

  tile: {
    width: '300px',
    height: '300px',
  },

  tileContent: {
    display: 'flex',
    justifyContent: 'space-evenly',
    alignItems: 'center',
    flexDirection: 'column',
    width: '100%',
    height: '100%',
    padding: theme.spacing(4),
  },

  tileIcon: {
    color: 'rgba(0, 0, 0, 0.54)',
    width: '100px',
    height: '100px',
  },

  tileText: {
    fontSize: '1.10rem',
  },
  btn: {
    marginBottom: '20px',
    marginTop: '20px',
},
}));

const UserHome = (props) => {

  const [tabSelected, setTabSelected] = useState("userTabValue" in sessionStorage ? JSON.parse(sessionStorage.getItem("userTabValue")) : 'active');
  const [open, setOpen] = React.useState(true);
  const [userData, setUserData] = useState("userDataUserHome" in sessionStorage ? JSON.parse(sessionStorage.getItem("userDataUserHome")) : []);

  const [pageLoading, setPageLoading] = useState(true)

  const classes = useStyles();
  const tableRef = React.useRef();
  const { users, error, getUsers } = useUser();
  const { roles } = useAuth();
  //const { resetPassword } = useAuth();

  const [email, setEmail] = useState("");
  const [sendingEmail, setSendingEmail] = useState(false);
  const [resetOpen, setResetOpen] = React.useState(false);

  //grabs users when users set is empty
  useEffect(() => {
    if(!users.length > 0){
      getUsers()
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setPageLoading(true) 
    
    if(users.length > 0){
      if(tabSelected === 'active'){
        let container = users.filter((user) => ( _.isUndefined(user.employment) || user.employment.active === true ))
        sessionStorage.setItem("userDataUserHome", JSON.stringify(container));
        setUserData(container)
      } else{
        let container = users.filter((user) => user.employment?.active === false)
        sessionStorage.setItem("userDataUserHome", JSON.stringify(container));
        setUserData(container)
      }
      setPageLoading(false) 
    }
  }, [users, tabSelected])

  const openReset = (email) => {
    setResetOpen(true);
    setEmail(email);
  };

  const closeReset = () => {
    setResetOpen(false);
    setEmail('');
  };

  const handleOnSearchChange = (value) => { 
    sessionStorage.setItem("userSearchBarValue", JSON.stringify(value));
  }

  const handleOnPageChange = (pageNum) => {
    sessionStorage.setItem("userPageValue", JSON.stringify(pageNum));
  }

  const tileClickHandler = (path) => {
    props.history.push({
      pathname: 'users/' + path,
    })
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  const handleTableChange = (event, newValue) => {
    setTabSelected(newValue);
    sessionStorage.setItem("userTabValue", JSON.stringify(newValue));
    tableRef.current.onPageChange({}, 0);
    sessionStorage.removeItem("userPageValue");
  };

  const sendResetEmail = (email) => {
    setSendingEmail(true);
    const sendEmail = functions.httpsCallable('sendPasswordEmail');
    sendEmail({
        email: email,
    })
      .then(() => {
        setResetOpen(false)
        setSendingEmail(false);
        console.log('Email sent to '+ email)
        // ..
      })
      .catch((error) => {
        console.log(error)
        // ..
      });
  }

  const handlePrintEmployees = () => {
    props.history.push({
      pathname: '/dashboard/users/printing',
      state: { userData: userData, status: tabSelected },
    })
  }

  return (
    <div className={classes.content}>
      {!pageLoading && !error ?
        <div >
          {props.location.state?.extraData?.alert === 'DELETED' ?
              <Snackbar open={open} autoHideDuration={4500} onClose={handleClose} TransitionComponent={SlideTransition}>
                  <Alert onClose={handleClose} style={{backgroundColor:'green', color:'white'}} severity="success" sx={{ width: '100%' }}>
                    Archived Employee deleted successfully!
                  </Alert>
              </Snackbar>
          :null}
          
          <Dialog 
                open={resetOpen} 
                onClose={closeReset}
            >
                <DialogTitle>Send Reset Password Email</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Are you sure you want to send a reset password email?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button disabled={sendingEmail} onClick={() => sendResetEmail(email)} variant="contained" color="primary" autoFocus>
                        {sendingEmail ? 'Sending...' : 'Send'}
                    </Button>
                    <Button disabled={sendingEmail} onClick={closeReset} variant="contained" color="secondary">
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>

          <Grid>

            <Grid container >
            <Grid item xs={12} className={classes.btn}>
                <Button variant='contained' onClick={() => {handlePrintEmployees()}} startIcon={<PrintIcon/>} style={{marginRight: '10px'}}>
                  Print
                </Button>
 
                <Button variant='contained' onClick={() => tileClickHandler('new')} startIcon={<AddBoxIcon/>} 
                        style={roles.includes('Create|Update|Delete Equipment')  ? {visibility: 'visible'} : {visibility: 'hidden'}}>
                  Create New Employee
                </Button>
              </Grid>
            </Grid>

            <Grid item sm={12} xs={12} className={classes.table}>
              <Card>
                <Grid item sm={12} xs={12} className={classes.table}>
                  <TabContext value={tabSelected}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider'}}>
                      <TabList onChange={handleTableChange}>
                        <Tab label={"Active Users ("+ users.filter((user) => ( _.isUndefined(user.employment) || user.employment.active === true )).length +')'} value="active" />
                        <Tab label={"Inactive Users ("+ users.filter(user => user.employment?.active === false).length + ')'} value="archived" />
                      </TabList>
                    </Box>
                    <TabPanel value="active"></TabPanel>
                    <TabPanel value="archived"></TabPanel>
                  </TabContext>
                </Grid>

                  <Grid item sm={12} xs={12} className={classes.table}>
                    <MaterialTable
                      tableRef={tableRef}
                      columns={[
                        { title: 'Name', field: 'displayName' },
                        { title: 'Email', field: 'email' },
                        { title: 'Department', field: 'department' , render: rowData => {
                          return rowData.department ? rowData.department : 'None'
                        }},
                        { title: 'Role', field: 'role', render: rowData => {
                          return rowData.role ? rowData.role : 'None'
                        }},
                        {title: 'Send Reset Email', field: 'displayName', render: rowData => {
                          return <Button size="small"
                            disabled={rowData.employment?.active === false}
                          variant='contained' onClick={() => openReset(rowData.email)}>Reset Password</Button>
                        }}
                      ]}
                      data={userData}                  
                      style={{minWidth: '1000px'}}
                      title= {tabSelected.charAt(0).toUpperCase() + tabSelected.slice(1) + " Employee"}
                      onSearchChange={ (value) => {handleOnSearchChange(value) }}
                      onPageChange={ (pageNum) => {handleOnPageChange(pageNum) }}
                      isLoading={pageLoading}
                      actions={[
                        rowData => ({
                          icon: TableIcons.OpenInNew,
                          tooltip: 'Show ' + tabSelected.charAt(0).toUpperCase() + tabSelected.slice(1) + " Employee",
                          onClick: (event, rowData) => {
                            props.history.push({
                              pathname: '/dashboard/users/form',
                              search: "?" + new URLSearchParams({ docId: rowData.docId }).toString(),
                            })
                          }
                        })
                      ]}
                      options={{
                        toolbar: true,
                        paging: true,
                        initialPage: "userPageValue" in sessionStorage ? JSON.parse(sessionStorage.getItem("userPageValue")) : 0,
                        //pageSizeOptions: false,
                        pageSize: 10,
                        actionsColumnIndex: -1,
                        search: true,
                        emptyRowsWhenPaging: false,
                        thirdSortClick: false,
                        searchText: "userSearchBarValue" in sessionStorage ? JSON.parse(sessionStorage.getItem("userSearchBarValue")) : null,
                        rowStyle: {
                            whiteSpace: 'pre-wrap',
                        },
                      }}
                    />
                  </Grid>
                </Card>
              </Grid>
            </Grid>
          </div>
        :
        <Grid >
          <Skeleton variant='rect' width={'45vw'} height={'85vh'} style={{marginTop: '85px'}}/>
        </Grid>
      }
    </div>
  )
}

export default withRouter(UserHome);