import React, {useState, useEffect, useCallback} from 'react';
import { Grid, makeStyles, Paper, TableCell, TableBody, TableContainer, Table, TableHead, TableRow, Snackbar,
    Button, TextField, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, IconButton, Typography,
    Checkbox, FormControl, FormControlLabel } from '@material-ui/core';
import { withRouter } from 'react-router-dom';
import OpenInBrowserIcon from '@material-ui/icons/OpenInBrowser';
import AddIcon from '@material-ui/icons/Add';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import EditIcon from '@material-ui/icons/Edit';
import { firestore } from '../../../../firebase/firebase'
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import _ from 'lodash'

const useStyles = makeStyles((theme) => ({

    btnGroup: {
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
    },
    tabBar: {
        width: '100%',
        maxWidth: '1000px',
    },
    tabs:{
        [theme.breakpoints.up("md")]: {
          margin:'auto'
        }
    },
    alias: {
        display: 'flex',
        marginTop: '8px',
        marginBottom: '8px',
        alignItems: 'center',
    },
    card: {
        padding: theme.spacing(4),
        display: 'flex',
        alignItems: 'center',
        height: '100%',
    },
    content: {
        display: 'flex',
        justifyContent: 'center',
        width: '100%',
    },
    paper: {
        padding: '20px',
        borderRadius: '0px',
        margin: '10px',
        width: '100%',
        maxWidth: '1000px',
        backgroundColor: 'rgb(252, 252, 249)',
    },
    row: {
        display: 'flex',
        alignItems: 'center',
    },
}));

const CommunicationsTab = (props) => {
    const classes = useStyles();

    const [open, setOpen] = React.useState({open: false, editing: false, index: null});
    const [deleteOpen, setDeleteOpen] = React.useState({open: false, index: null});
    const [customerData, setCustomerData] = useState(props.customerData);
    const defaultContact = {name: '', phone: '', description: '', email: '', invoiceContact: false, ccToInvoice: false}
    const [contactInfo, setContactInfo] = React.useState(defaultContact)
    const [saveState, setSaveState] = useState(props.saveState);
    const [error, setError] = useState("");
    const [saving, setSaving] = useState(false);

    useEffect(() => {
        setCustomerData(props.customerData)
        //eslint-disable-next-line
    }, [props.customerData])

    const handleClickOpen = () => {
        setContactInfo(defaultContact)
        setOpen({open: true, editing: false, index: null});
    };
  
    const handleClose = () => {
        setOpen({...open, open: false,});
    };

    const handleEditOpen = (contact, index) => {
        setContactInfo(contact)
        setOpen({open: true, editing: true, index: index});
    };

    const handleDeleteOpen = (contact, index) => {
        setDeleteOpen({open: true, index: index});
        setContactInfo(contact)
    };
    
    const handleDeleteClose = () => {
        setDeleteOpen({open: false, index: null});
    };

    const handleSubmit = (event) => {
        let newContacts = _.cloneDeep(props.customerData.contacts)
        if(contactInfo.invoiceContact){ newContacts = handleChangeInvoiceContact(newContacts) }
        setContactInfo({ ...contactInfo, 'id': newContacts.length })
        newContacts.push(contactInfo)
        props.updateCustomerData({ ...props.customerData, contacts: newContacts })

        setOpen({open: false, editing: false, index: null})
        setContactInfo(defaultContact)
    }

    const handleDelete = (event) => {
        const newContacts = props.customerData.contacts
        newContacts.splice(deleteOpen.index, 1)
        props.updateCustomerData({ ...props.customerData, contacts: newContacts })

        firestore.collection('customers').doc(props.customerData.docId)
        .update(props.customerData)

        setDeleteOpen({open: false, index: null})
        setContactInfo(defaultContact)
    }

    const handleEdit = (event) => {
        let newContacts = _.cloneDeep(props.customerData.contacts)
        if(contactInfo.invoiceContact){ newContacts = handleChangeInvoiceContact(newContacts) }
        newContacts.splice(open.index, 1, contactInfo)
        props.updateCustomerData({ ...props.customerData, contacts: newContacts })

        setOpen({open: false, editing: false, index: null})
        setContactInfo(defaultContact)
    }
    
    const updateCustomerData = useCallback((e) => {
            setCustomerData(e)
        },
        [],
    );

    const changeHandler = (event) => {
        if (event.target.name.includes('.')) {
            //substring to get the first part of the name
            let option1 = event.target.name.substring(0, event.target.name.indexOf('.'))
            //substring to get the second part of the name
            let option2 = event.target.name.substring(event.target.name.indexOf('.') + 1)
            
            //updateCustomerData using the spread operator to keep the other values
            updateCustomerData({ ...customerData, [option1]: { ...customerData[option1], [option2]: event.target.value } })
        }
        else{
            updateCustomerData({ ...customerData, [event.target.name]: event.target.value })
        }
        
    }
    const confirmSave = () => {
        props.setCustomerData(customerData);
        props.setSaveState(customerData);
        setSaving(true);
        firestore.collection('customers').doc(props.customerData.docId)
            .update(customerData)
            .then(() => {
                setSaveState(customerData);
                setSaving(false)
            })
            .catch((e) => {setError(e); console.log(error);});
    };

    const cancelSave = () => {
        setCustomerData(saveState);
    };

    const handleChangeInvoiceContact = (contacts) => {
        contacts.forEach((contact) => {
            if(contact.invoiceContact){ contact.invoiceContact = false; }
        })
        return contacts
    }

    return (
        <Grid container spacing={4} >
            {/* delete contact modal */}
            <Dialog open={deleteOpen.open} onClose={handleClose}>
                <DialogTitle>Delete Contact</DialogTitle>
                <DialogContent>
                <Typography>Are you sure you want to delete contact: {contactInfo.name}?</Typography>
                    <DialogContentText>
                        This is NOT reversable!
                    </DialogContentText>
                    
                    <DialogActions>
                        <Button variant="contained" color="secondary" size="small" onClick={handleDeleteClose}>
                            Cancel
                        </Button>
                        <Button variant="contained" color="primary" size="small" onClick={handleDelete}>
                            Delete
                        </Button>
                    </DialogActions>
                </DialogContent>
            </Dialog>

            {/* contact modal */}
            <Dialog open={open.open} onClose={handleClose}>
                <DialogTitle>{!open.editing ? "Add" : "Edit"} Contact</DialogTitle>
                <DialogContent>
                <DialogContentText>
                {!open.editing ? "Please enter the details of the new contact." : "Edit the current contact."}
                </DialogContentText>
                <TextField
                    margin="dense"
                    id="name"
                    label="Name"
                    fullWidth
                    value={contactInfo.name}
                    error={contactInfo.name === ''}
                    helperText={contactInfo.name === '' ? "Contact requires a name": ''}
                    onChange={(event) => { setContactInfo({ ...contactInfo, 'name': event.target.value }) }}
                />
                <Grid container spacing={2}> 
                    <Grid item xs={6}> 
                        <TextField
                            margin="dense"
                            id="phone"
                            label="Phone #"
                            value={contactInfo.phone}
                            type="tel"
                            onChange={(event) => { setContactInfo({ ...contactInfo, 'phone': event.target.value }) }}
                        />
                    </Grid>
                    <Grid item xs={6}> 
                        <TextField
                            margin="dense"
                            id="phonedesc"
                            label="Phone Description"
                            value={contactInfo.description}
                            type="tel"
                            onChange={(event) => { setContactInfo({ ...contactInfo, 'description': event.target.value }) }}
                        />
                    </Grid>       
                </Grid>
                <TextField
                    margin="dense"
                    id="email"
                    label="Email Address"
                    type="email"
                    value={contactInfo.email}
                    fullWidth
                    onChange={(event) => { setContactInfo({ ...contactInfo, 'email': event.target.value }) }}
                />

                <Grid container spacing={2}> 
                    <Grid item xs={6}>
                        <FormControl>
                            <FormControlLabel 
                                control={
                                    <Checkbox 
                                        checked={contactInfo.invoiceContact}
                                        onChange={(event) => { setContactInfo({ ...contactInfo, 'invoiceContact': event.target.checked }) }}
                                    />
                                } 
                                label="Invoice Contact" 
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={6}>
                        <FormControl>
                            <FormControlLabel 
                                control={
                                    <Checkbox 
                                        checked={contactInfo.ccToInvoice}
                                        onChange={(event) => { setContactInfo({ ...contactInfo, 'ccToInvoice': event.target.checked }) }}
                                    />
                                } 
                                label="CC to Invoice" 
                            />
                        </FormControl>
                    </Grid>
                </Grid>

                </DialogContent>
                <DialogActions>
                <Button variant="contained" color="secondary" size="small" onClick={handleClose}>
                    Cancel
                </Button>
                <Button disabled={contactInfo.name === ''} variant="contained" color="primary" size="small" onClick={!open.editing ? handleSubmit : handleEdit}>
                    Submit
                </Button>
                </DialogActions>
            </Dialog>

            <Grid container item justifyContent="center" spacing={2}>
                {props.edit ? 
                <TextField
                    style={{ marginBottom: '8px', width:'400px' }}
                    label='Web Site'
                    value={customerData.website ? customerData.website : ''}
                    variant='outlined'
                    name='website'
                    size="small"
                    onChange={(event) => { changeHandler(event) }}
                    inputProps={{ style: { fontSize: '1rem' } }} />
                :<Button variant="contained" target="_blank" href={props.customerData.website ? props.customerData.website : "#"} disabled={!props.customerData.website} startIcon={<OpenInBrowserIcon/>}>Visit {props.customerData.name} Web Site</Button>}
                
            </Grid>
            <Grid container item spacing={1} direction="row" wrap='nowrap'>
                    <Grid container justifyContent="flex-start">
                    <TextField
                        disabled={!props.edit}
                        label="GST Registration #"
                        name='gstRegNum'
                        value={customerData.gstRegNum}
                        onChange={(event) => { changeHandler(event) }}
                        variant="outlined"
                        style={{width:'400px'}}
                    />
                    </Grid>
                    <Grid container justifyContent="flex-end">
                    <TextField
                        disabled={!props.edit}
                        label="Retail Sales Tax #"
                        name='retailSalesTaxNum'
                        value={customerData.retailSalesTaxNum}
                        onChange={(event) => { changeHandler(event) }}
                        variant="outlined"
                        fullWidth
                    />
                    </Grid>
            </Grid>
            <Grid container style={{margin: '15px'}}>
                <TableContainer component={Paper}>
                    <Table className={classes.table} aria-label="simple table">
                        <TableHead>
                        <TableRow>
                            <TableCell>Contact</TableCell>
                            <TableCell align="right">Phone Number</TableCell>
                            <TableCell align="right">Phone Description</TableCell>
                            <TableCell align="right">Email Address</TableCell>
                            <TableCell align="right">Invoice Contact</TableCell>
                            <TableCell align="right">CC to Invoice</TableCell>
                            {props.edit ? <React.Fragment><TableCell align="center">Delete</TableCell><TableCell align="center">Edit</TableCell></React.Fragment>
                            : null}
                        </TableRow>
                        </TableHead>
                        <TableBody>
                        {props.customerData.contacts.map((contact, index) => (
                            <TableRow key={index}>
                                <TableCell component="th" scope="row">
                                    {contact.name}
                                </TableCell>
                                <TableCell align="right">{contact.phone}</TableCell>
                                <TableCell align="right">{contact.description}</TableCell>
                                <TableCell align="right">{contact.email}</TableCell>
                                <TableCell align="center">
                                    <IconButton disabled color="secondary">{ contact.invoiceContact ? <CheckIcon/> : <CloseIcon/> }</IconButton>
                                </TableCell>
                                <TableCell align="center">
                                    <IconButton disabled color="secondary">{ contact.ccToInvoice ? <CheckIcon/> : <CloseIcon/> }</IconButton>
                                </TableCell>
                                {props.edit ?<React.Fragment>
                                <TableCell align="center">
                                    <IconButton onClick={() => handleDeleteOpen(contact, index)} color="secondary"><DeleteForeverIcon/></IconButton>
                                    </TableCell>
                                <TableCell align="center">
                                    <IconButton onClick={() => handleEditOpen(contact, index)} color="secondary"><EditIcon/></IconButton>
                                    </TableCell></React.Fragment>  
                            : null}
                            </TableRow>
                        ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            {props.edit ?
                <Grid item xs={6} style={{margin:'16px'}}>
                    <Button disabled={!props.edit} variant="contained" onClick={handleClickOpen} startIcon={<AddIcon/>}>Add Contact</Button>
                </Grid> : null}

                 <Snackbar
                open={!_.isEqual(customerData, saveState)}
                message={saving ? 'Saving...' : 'Save Document?'}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center'
                }}
                action={
                    <React.Fragment>
                        {props.saving
                            ? null
                            : <React.Fragment>
                                <Button variant='text' color='primary' onClick={cancelSave} style={{ marginLeft: '32px', marginRight: '8px' }}>Cancel</Button>
                                <Button variant='contained' onClick={confirmSave}>Confirm</Button>
                            </React.Fragment>}
                    </React.Fragment>
                } /> 
            </Grid>
            
            
        </Grid>
    )
}
export default withRouter(CommunicationsTab)