import React, { useState, useEffect, useRef, memo } from 'react'
import { Typography,  Paper, TextField, InputAdornment, IconButton, 
     Popper, Grow, ClickAwayListener, Table, TableBody, TableHead, TableCell, TableRow, makeStyles  } from '@material-ui/core';
import _ from 'lodash';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const useStyles = makeStyles((theme) => ({
    headerColumns: {
        margin: '0px',
        marginBottom:'20px',
        width: '100%',
        maxWidth: '1200px',
        padding: '16px',
    },
    rowColumns: {
     
    },
}));

const InlineSelector = (props) => {
    const classes = useStyles();

    let internalObject = (props.suggestions.data.length > 0 && typeof props.suggestions.data[0] === 'object') ? props.suggestions.data.find(x => x.docId === props.value) : props.value
    let suggestions = props.suggestions.data

    const textFieldRef = useRef()
    const valueSaveState = useRef(typeof internalObject === 'object' ? internalObject[props.suggestions.displayFields[0]] : props.value)

    const [display, setDisplay] = useState(typeof internalObject === 'object' ? internalObject[props.suggestions.displayFields[0]] : internalObject)
    const [selection, setSelection] = useState()
    const [anchorEl, setAnchorEl] = useState(null);   

    useEffect(() => {

        let existingObj = suggestions.find(x=> x.docId === props.value)
        if(!_.isUndefined(existingObj)){ 
            setDisplay( existingObj[props.suggestions.displayFields[0]] ) 
        }
        else{ 
            //console.log('props.value', props.value, internalObject, props.field)
            let container = _.cloneDeep(props.value)
            setDisplay(container) 
        }
    }, [props.value]) // eslint-disable-line react-hooks/exhaustive-deps


    useEffect(() => {
        if (!selection) return;
        const {start, end} = selection;
        //batshit way to reach input inside textfeild
        textFieldRef.current.children[0].children[0].setSelectionRange(start, end);
      }, [selection]) // eslint-disable-line react-hooks/exhaustive-deps


    const onBlur = (e) => {
        let returnValue = typeof internalObject === 'object' ? internalObject.docId : internalObject
        !_.isUndefined(props.onBlur) && props.onBlur(e, returnValue)
    }

    const onChange = (e) => {    
        let newValue = e.target.value
        let internalReturnValue = e.target.value
        let displayField = props.suggestions.displayFields[0]

        let type = typeof suggestions[0] === 'object' ? 'object' : 'value';
        let possibleSuggestions;

        //filters the suggestions based on newValue
        if(type === 'object'){  possibleSuggestions = suggestions.filter((suggestion) => {  return suggestion[displayField].substring(0, newValue.length).toLowerCase().indexOf(newValue.toLowerCase()) > -1  }) }
        else{  possibleSuggestions = suggestions.filter((suggestion) => { return suggestion.substring(0, newValue.length).toLowerCase().includes(newValue.toLowerCase()) })  }
        
        //if backspacing
        if(newValue.length <= valueSaveState.current?.length ){
            let possibility = suggestions.find(x=> x[displayField] === newValue)
            
            if(!_.isUndefined(possibility)){
                //set all things to possiblity
                setDisplay(type === 'object' ? possibility[displayField] : possibility)
                valueSaveState.current = newValue
                internalReturnValue = type === 'object'  ? possibility.docId : possibility
                internalObject = possibility;
                newValue = possibility
            }
            else{
                //e.target.value = newValue;
                setDisplay(newValue)
                internalObject = newValue
                valueSaveState.current = newValue
                internalReturnValue = newValue
            }
        }
        //typing
        else{
            if(possibleSuggestions.length > 0){
                setDisplay(type === 'object' ? possibleSuggestions[0][displayField] : possibleSuggestions[0])
        
                type === 'object' 
                ? setSelection({start: newValue.length, end: possibleSuggestions[0][displayField].length})
                :  setSelection({start: newValue.length, end: possibleSuggestions[0].length})

                valueSaveState.current = newValue
                internalReturnValue = type === 'object'  ? possibleSuggestions[0].docId : possibleSuggestions[0]
                internalObject = possibleSuggestions[0];
                newValue = possibleSuggestions[0]
            }
            else{
                setDisplay(newValue)
                //e.target.value = newValue;
                internalObject = newValue
                valueSaveState.current = newValue
                internalReturnValue = newValue
            }
        }

        !_.isUndefined(props.onChange) && props.onChange(e, internalReturnValue)
    };

    const handleClickTable = (e, suggestion) => {

        //textFieldRef.current.children[0].children[0] is access to the input within the textfield
        const internalDisplayValue = typeof suggestion === 'object' ? suggestion[props.suggestions.displayFields[0]] : suggestion
        const internalReturnValue = typeof suggestion === 'object' ? suggestion.docId : suggestion;

        setDisplay(internalDisplayValue)
        valueSaveState.current = internalDisplayValue;

        !_.isUndefined(props.onTableClick) && props.onTableClick(e, internalReturnValue)
        setAnchorEl(null);
    }

    return (
        <>
            <TextField 
                {...props}
                ref={textFieldRef}
                onChange={onChange}
                onBlur={onBlur}
                onDoubleClick={props.onDoubleClick}
                id={props.id}
                value={display || ''}
                error={props.error}
                disabled={props.disabled}

                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            
                            <IconButton 
                                size='small'
                                style={{padding: 0}}
                                onClick={(e) => { 
                                    !props.disabled && setAnchorEl(textFieldRef.current); 
                                }}
                            > 
                                {Boolean(anchorEl) ? <ExpandLessIcon/> : <ExpandMoreIcon />} 
                            </IconButton>
                        </InputAdornment>
                    )
                    }}
            />
            {props.error && <Typography style={{fontSize: '12px', color: '#F44336'}}>Value is not a listed option</Typography> }

            {!props.disabled &&
                <Popper open={Boolean(anchorEl)} anchorEl={anchorEl} role={undefined} transition disablePortal style={{zIndex: 100}}>
                    {({ TransitionProps, placement }) => (
                    <Grow 
                        {...TransitionProps}  
                        style={{ 
                            transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom', 
                            minWidth: textFieldRef.current?.getBoundingClientRect().width,
                            maxHeight: Number(window.screen.height) / 3.5, 
                            overflowY: 'auto',
                            zIndex: 100,
                        }} 
                    >
                        <Paper 
                            style={{
                                maxHeight: 
                                Number(window.screen.height) / 3.5, 
                                overflowY: 'auto', 
                                width: 'inherit',
                                zIndex: 100,
                            }}
                        >
                            <ClickAwayListener onClickAway={() => { setAnchorEl(null) }}>
                                <Table stickyHeader>
                                    {/* {tContents} */}

                                    <TableHead>
                                        <TableRow className={classes.headerColumns}>
                                            {props.suggestions.displayFields.map((field, fieldIndex) => {
                                                return (
                                                    <TableCell 
                                                        style={(props.suggestions.displayFields.length -1) === fieldIndex
                                                            ?{padding: '5px', backgroundColor: 'white', borderBottom: '1px solid black', }
                                                            :{padding: '5px', backgroundColor: 'white', borderBottom: '1px solid black', borderRight: '1px solid black' }
                                                        }
                                                    >
                                                        <Typography>{field}</Typography>
                                                    </TableCell>
                                                )
                                            })}
                                        </TableRow>
                                    </TableHead>
                            
                                    <TableBody style={{ overflowX: 'scroll', overflowY: 'scroll'}}>
                                    {suggestions.map((suggestion, index) => {   
                                        return (
                                            <TableRow 
                                                className={'openAutoCompleteRow'}
                                                onClick={(e) => {  handleClickTable(e, suggestion) }}
                                            >
                                                {typeof suggestion === 'object'
                                                ?
                                                    props.suggestions.displayFields.map((field, fieldIndex2) => {
                                                        return (
                                                            <TableCell 
                                                                style={(props.suggestions.displayFields.length -1) === fieldIndex2
                                                                    ?{padding: '5px', }
                                                                    :{padding: '5px', borderRight: '1px solid black' }
                                                                }
                                                            >
                                                                <Typography>{suggestion[field]}</Typography>
                                                            </TableCell>
                                                        )
                                                    })
                                                :
                                                    <TableCell  style={{padding: '5px', }} >
                                                        <Typography>{suggestion}</Typography> 
                                                    </TableCell>
                                                }
                                            </TableRow>
                                            )
                                        })}
                                    </TableBody>
                                </Table>
                            </ClickAwayListener>
                        </Paper>
                    </Grow>
                    )}
                </Popper>
            }
        </>
    );
    }
  export default memo(InlineSelector);