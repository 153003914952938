import React, { useContext, useState, useEffect } from "react"
import { firestore } from "../firebase/firebase"

const ProductContext = React.createContext();

export function useProduct() {
  return useContext(ProductContext);
}

export function ProductProvider({ children }) {

  const [products, setProductData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');

  useEffect(() => {
    const unsubscribe = firestore.collection("products").onSnapshot(querySnapshot => {
      const productResponse = querySnapshot.docs.map(doc => ({
        ...doc.data(),
        docId: doc.id,
        tags: doc.data().tags || [],
      }));
      setProductData(productResponse);
      setLoading(false);
    }, error => {
      setLoading(false);
      setError(error.message);
      console.error(error.message);
    });
  
    return () => {
      unsubscribe();
    };
  }, []);

  const value = {
    products,
    loading,
    error,
  }

  return (
    <ProductContext.Provider value={value}>
      {children}
    </ProductContext.Provider>
  )
}