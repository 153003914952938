import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core';
import {
    Button, Checkbox, Divider, FormControlLabel, Grid, Modal,
    InputLabel, Paper, MenuItem, Select, TextField, useMediaQuery, Typography,
} from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import { firestore } from '../../../firebase/firebase'
import { useProduct } from '../../../providers/ProductContext'
import ProductSelector from './ProductSelector';
import { useAuth } from '../../../providers/AuthContext';
import { withRouter } from 'react-router-dom';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import DeleteIcon from '@material-ui/icons/Delete';

const useStyles = makeStyles((theme) => ({
    card: {
        padding: theme.spacing(4),
        maxWidth: '500px',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        [theme.breakpoints.down('xs')]: {
            padding: theme.spacing(1),
        }
    },

    dialogCard: {
        padding: theme.spacing(4),
        maxWidth: '650px',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        maxHeight: '700px',
        [theme.breakpoints.down('xs')]: {
            padding: theme.spacing(1),
        }
    },

    image: {
        height: '100%',
        width: '100%',
        objectFit: 'scale-down',
    },

    modal: {
        width: '500px',
        padding: '16px',
        margin: '0 auto',
        marginTop: '50vh',
    },

    modalInput: {
        '& input': {
            color: 'white',
        },
    },

    headerPhoto: {
        height: '100%',
        width: '100%',
    },

    row: {
        display: 'flex',
        alignItems: 'center',
    },

    select: {
        display: 'flex',
        justifyContent: 'center'
    }
}));

const CouponCard = (props) => {

    const classes = useStyles();
    const theme = useTheme();
    const smallScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const [coupon, setCoupon] = useState({ ...props.coupon });
    const [open, setOpen] = React.useState(false);
    const [openProducts, setOpenProducts] = React.useState(false);
    const { products } = useProduct();
    const productsArray = []
    const includeArray = []
    const { roles } = useAuth();

    const [alertModal, setAlertModal] = useState(false)

    const handleOpenProducts = () => {
        setOpenProducts(true);
    };

    const handleCloseProducts = () => {
        setOpenProducts(false);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleDeleteClick = () => {
        firestore.collection('coupons').doc(props.coupon.docId).delete()
        .then(() => {
            console.log("Coupon successfully deleted!");
            props.history.replace({pathname: '/dashboard/coupons',})
        }).catch((error) => {
            console.error("Error removing coupon: ", error);
        });
    }

    // const blurHandler = () => {
    //     props.updateData(Object.assign({}, props.coupon, coupon))
    // }

    useEffect(() => {
    }, [smallScreen, props]);

    products.map(product => {
        return product?.variants?.map((variant, index) => {
            let productGist = {
                'name': product.name,
                'variantId': variant.id,
                'identifier': product?.variantTypes?.[0] === "NOVARIANT" ? product.name : product.name + " " + JSON.stringify(variant.variantTypes, null, 2)
            }
            return productsArray.push(productGist)
        })

    })
    props.coupon.productsAffected?.forEach(product => {
        includeArray.push(product)
    })

    return (
        <Grid>
            {/* delete modal */}
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">Delete Coupon?</DialogTitle>
                <DialogActions>
                    <Button onClick={handleClose} variant="text" style={{color:'white', backgroundColor:'red',}}>
                        No
                    </Button>
                    <Button onClick={handleDeleteClick} variant="contained" autoFocus>
                        Yes
                    </Button>
                </DialogActions>
            </Dialog>


            {/* alert modal */}
            <Modal
                open={alertModal}
                onClose={() => setAlertModal(false)}>
                <Paper className={classes.modal}>
                    <Typography>The Coupon needs a coupon code to be activated.</Typography>

                    <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '12px' }}>
                        <Button variant='contained' onClick={() => setAlertModal(false)}>Okay</Button>
                    </div>
                </Paper>
            </Modal>

            <Grid container>
                <Grid item xs={6}>
                    <Button
                        style={{ marginBottom: '20px' }}
                        variant="contained"
                        color="primary"
                        onClick={() => {props.history.goBack()}}
                        startIcon={<ArrowBackIcon />}>
                        Back
                    </Button>           
                </Grid>
                <Grid item xs={6}>
                    <Grid container justifyContent='flex-end'>
                        <Button
                            variant="contained"
                            style={{color:'white', backgroundColor:'red', float: 'right', marginBottom: '20px' }}
                            onClick={() => {setOpen(true)}}
                            startIcon={<DeleteIcon />}>
                            Delete
                        </Button>  
                    </Grid>
                </Grid>
            </Grid>

            <Paper className={classes.card}>
                {products && coupon &&
                    <ProductSelector
                        categories={props.categories}
                        open={openProducts}
                        close={handleCloseProducts}
                        products={products}
                        coupon={props.coupon}
                        updateData={props.updateData} 
                        smallScreen={smallScreen}
                    />
                }
                
                <Grid container spacing={2}>
                    <Grid item md={8} xs={12}>
                        <InputLabel>Discount Code</InputLabel>
                        <TextField
                            value={props.coupon.code}
                            //required
                            disabled={!roles.includes('Create|Update|Delete Products')}
                            variant='outlined'
                            onChange={(event) => {
                                setCoupon({ ...coupon, 'code': event.target.value.toLowerCase() })
                                props.updateData({ ...props.coupon, 'code': event.target.value.toLowerCase() })
                            }}
                            //onBlur={() => blurHandler()}
                            inputProps={smallScreen ? { style: { fontSize: '16px' } } : { style: { fontSize: '1.125rem' } }} />
                    </Grid>

                    <Grid container item md={4} xs={12} style={{ alignItems: 'center' }}>
                        <Grid item xs={8}>
                            <InputLabel>Amount</InputLabel>
                            <TextField
                                type='number'
                                disabled={!roles.includes('Create|Update|Delete Products')}
                                error={isNaN(Number.parseInt(props.coupon.discount))}
                                variant='outlined'
                                value={props.coupon.discount}
                                //required
                                inputProps={{
                                    step: 1
                                }}
                                onChange={(event) => {
                                    setCoupon({ ...coupon, 'discount': parseInt(event.target.value) })
                                    props.updateData({ ...props.coupon, 'discount': event.target.value })
                                }}
                                //onBlur={() => blurHandler()}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <InputLabel>Type</InputLabel>
                            <Select
                                variant='outlined'
                                style={{ maxWidth: '50px', display: 'flex', justifyContent: 'center' }}
                                //required
                                disabled={!roles.includes('Create|Update|Delete Products')}
                                value={props.coupon.discountType}
                                inputProps={{ className: classes.select }}
                                label="Discount Type"
                                onChange={event => {
                                    setCoupon({ ...coupon, 'discountType': event.target.value })
                                    props.updateData({ ...props.coupon, 'discountType': event.target.value })
                                }}
                                //onBlur={() => blurHandler()}
                            >
                                <MenuItem name='flat' key="flat" style={{ marginBottom: '8px' }} value='flat'>
                                    $
                                </MenuItem>
                                <MenuItem name='percentage' key="percentage" style={{ marginBottom: '8px' }} value='percentage'>
                                    %
                                </MenuItem>
                                <Divider />
                            </Select>
                        </Grid>
                    </Grid>

                    <Grid item md={12} xs={12} style={{ display: 'flex', flexDirection: 'column' }} className={classes.groupedInfo}>
                        <TextField
                            style={{ marginTop: '16px' }}
                            label='Description'
                            value={props.coupon.description}
                            //required
                            disabled={!roles.includes('Create|Update|Delete Products')}
                            multiline
                            variant='outlined'
                            fullWidth
                            onChange={(event) => {
                                setCoupon({ ...coupon, 'description': event.target.value })
                                props.updateData({ ...props.coupon, 'description': event.target.value })
                            }}
                            //onBlur={() => blurHandler()} 
                        />
                    </Grid>

                    <Grid item md={6} xs={6}>
                        <FormControlLabel
                            label="Active"
                            disabled={!roles.includes('Create|Update|Delete Products')}
                            control={
                                <Checkbox variant="contained" color={props.coupon.active ? "secondary" : "primary"}
                                    fullWidth
                                    checked={props.coupon.active}
                                    onClick={(e) => {
                                        //validation here
                                        if(e.target.checked && props.coupon.code === ''){
                                            setAlertModal(true)
                                        }
                                        else{
                                            props.updateData({ ...props.coupon, 'active': !props.coupon.active })
                                        }
                                    }}>
                                    {props.coupon.active ? 'Deactivate' : 'Activate'}
                                </Checkbox>
                            } />
                    </Grid>

                    <Grid item md={12} xs={12} >
                        <div style={{ display: 'flex', border: '1px solid rgba(0, 0, 0, 0.23)', borderRadius: '8px', alignItems: 'center', justifyContent: 'space-between', paddingRight: '8px' }}>
                            <Button variant="contained" color="primary" disabled={!roles.includes('Create|Update|Delete Products')}
                                style={{
                                    borderTopLeftRadius: '8px',
                                    borderBottomLeftRadius: '8px',
                                    borderTopRightRadius: '0px',
                                    borderBottomRightRadius: '0px'
                                }}
                                onClick={() => {
                                    handleOpenProducts()
                                }}>
                                Affected Products
                            </Button>
                            {!props.coupon.global
                                ? !props.coupon.category.onlyCategory 
                                    ?
                                    <Typography><small>This coupon affects {props.coupon.productsAffected.length} variants</small></Typography>
                                    :
                                    <Typography><small>This coupon affects variants from {props.coupon.category.value} </small></Typography>
                                : <Typography><small>This coupon affects ALL products</small></Typography>}
                        </div>
                    </Grid>

                    {/* <Grid item md={6} xs={6} style={{ position: 'relative' }}>
                        <Button variant="outlined"
                            fullWidth
                            onClick={handleClickOpen} startIcon={<DeleteIcon />}
                            disabled={props.coupon.docId === "fourWayMix" || props.coupon.docId === "screenedSandyLoam" || props.coupon.docId === "clayFill"}>

                            Delete Coupon
                        </Button>
                    </Grid> */}
                </Grid>
            </Paper >
        </Grid>
    )
}

export default withRouter(CouponCard);