import React, { useEffect, useState } from 'react';
import { Circle, GoogleMap, Marker, StandaloneSearchBox, useLoadScript, Polygon } from '@react-google-maps/api';
import { Alert } from '@material-ui/lab'
import { Backdrop, Button, Divider, Fade, Grid, Modal, Paper, Typography, useMediaQuery, FormControl, Select, MenuItem, TextField } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles';
import { useTheme } from '@material-ui/core/styles';
import { useThemes } from '../../../providers/ThemesContext'
import { DatePicker } from '@material-ui/pickers';
import moment from 'moment';
import { useSettings } from '../../../providers/SettingsProvider';
import 'moment-timezone';

const useStyles = makeStyles((theme) => ({

  dateInput: {
    display: 'flex',
    alignItems: 'center',
  },

  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    height: '100%',
  },

  grid: {
    justifyContent: 'center',
    width: '100%',
  },
}));

const libraries = ['places'];

export const CartMap = (props) => {
  const [center, setMapCenter] = useState(props.coordinates);
  const [searchBox, setSearchBox] = useState({});
  const [step, setStep] = useState(0);
  const [showModal, setShowModal] = useState(false);
  const { headerHeight, setShowHeader, setShowFooter } = useThemes();
  const theme = useTheme();
  const smallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const onLoad = (ref) => setSearchBox(ref);
  const MuiClasses = useStyles();
  const { settings, datesClosed, zones } = useSettings();
  

  const mapContainerStyle = {
    width: props.width,
    height: props.height,
  };

  //Google Maps API setup
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
    libraries,
  });

  useEffect(() =>{
    props.setNextAvailableDeliverDate();
    //eslint-disable-next-line
  }, [])

  var today = moment().tz('America/Winnipeg')

  const closedDays = Object.entries(settings.operatingHours).map(([key, value]) => {
    if(value.closed === true){
      switch(key) {
        case 'Monday':
          return 1
        case 'Tuesday':
          return 2
        case 'Wednesday':
          return 3
        case 'Thursday':
          return 4
        case 'Friday':
          return 5
        case 'Saturday':
          return 6
        case 'Sunday':
          return 0
        default:
          console.warn('Dates closed were not generated correctly.')
          return null
      } 
    }
    return undefined
  }).filter(x => x !== undefined)

  let sidePanelPos = (window.innerWidth / 2) - 944;
  sidePanelPos = sidePanelPos > 0 ? sidePanelPos : 0;

  useEffect(() => {
    setShowFooter(false);
    return () => {
      setShowHeader(true);
      setShowFooter(true)
    };
  }, [smallScreen, setShowHeader, setShowFooter]);

  //Error checking on googlemaps api
  if (loadError) return "Error";
  if (!isLoaded) return "";

  const handlePickAddress = (props) => {
    setShowModal(false);
    setStep(1);
  }

  const onPlacesChanged = (ref) => {
    let places = searchBox.getPlaces();

    if (places[0]) {
      let newPosition = {
        lat: places[0].geometry.location.lat(),
        lng: places[0].geometry.location.lng(),
      }

      setMapCenter(newPosition);

      props.updateCoordinates(newPosition, "INPUT_SEARCHBOX", places[0].formatted_address)
      const address = new window.google.maps.Marker({
        position: newPosition,
      })
      if(checkIfMarkerInPolygons(address, polygonArray)){
        props.updateCoordinates(newPosition, "INPUT_SEARCHBOX", places[0].formatted_address, checkIfMarkerInPolygons(address, polygonArray).name)
      }
    }
  };

  const polygonArray = zones?.zones?.map((zone) => {
    return new window.google.maps.Polygon({
      paths: zone.coordinates,
    });
  });
  const themarker = new window.google.maps.Marker({
    position: props.coordinates,
  });
  const checkIfMarkerInPolygons = (marker, polygons) => {
    const markerPosition = marker.getPosition(); // get the LatLng position of the marker
    if(polygons && polygons?.length > 0)
    {
      for (let i = 0; i < polygons.length; i++) {
      if (window.google.maps.geometry.poly.containsLocation(markerPosition, polygons[i])) {
        // Marker is inside polygon
        return true && zones.zones[i];
      }
    }
  }
    // Marker is not inside any polygon
    return false;
  };

  let zonePolygons = zones?.zones?.map((zone, index) => {
    return <>
    <Polygon
        key={index}
        path={zone.coordinates}
        options={{
            fillColor: zone.color,
            fillOpacity: process.env.NODE_ENV === 'development' ? 0.3 : 0,
            strokeColor: zone.color,
            strokeOpacity: process.env.NODE_ENV === 'development' ? 0.8 : 0,
            strokeWeight: 2,
            clickable: false,
            editable: false,
            zIndex: 6
        }}
    /></>
})

  const onClick = (...args) => {
    if (isLoaded) {
      if (args) {
        let newPosition = {
          lat: args[0].latLng.lat(),
          lng: args[0].latLng.lng(),
        };
  
        const circleCenter = new window.google.maps.LatLng(-49.904415, -96.932431 - 180);
        const circleRadius = 20037508.34 - 100000 * 0.7;
        const markerPosition = new window.google.maps.LatLng(newPosition.lat, newPosition.lng);
        const distance = window.google.maps.geometry.spherical.computeDistanceBetween(
          circleCenter,
          markerPosition
        );
  
        if (distance < circleRadius) {
          // set invalid address
          props.updateCoordinates(newPosition, "INPUT_MARKER", "Invalid Address");
        } else {
          props.updateCoordinates(newPosition, "INPUT_MARKER");
        }

  
        checkIfMarkerInPolygons(themarker, polygonArray)

      }
    }
  };

  const generateDatesClosed = (date) => {
    //disables days based on shopSettings.shopSettings.operatingHours
    for(let i = 0; i < closedDays.length; i++){
      if(closedDays[i] === moment(date).day()){
        return true
      }
    }
    //gets dates out of shopSettings.datesClosed map
    let datesClosedValues = Object.entries(datesClosed.dates).map(([key]) => {
        return key
    })
    //disables days based on above
    for(let j = 0; j < datesClosedValues.length; j++){
      if(moment(date).format('MM/DD') === moment(datesClosedValues[j]).format('MM/DD')){
        return true
      }
    }
  }

  const generateAlert = () => {

    let severity = 'info';
    let msg = 'For updated shipping costs, \'Enter your Address\' or select your location on the map.';
    let variant = 'outlined';

    if (props.alertActive) {
      if (!props.pickupOrder) {
        if (props.alertActive) {
          variant = 'filled'
        }
        else {
          variant = 'outlined';
        }
        severity = props.alertSev;
        msg = props.alertMsg;
      }
      else {
        variant = 'filled'
        severity = 'success';
        msg = 'Order is pickup only, delivery location not required.';
      }
    }

    return (<Alert variant={variant} severity={severity} style={{ marginBottom: '8px' }}>{msg}</Alert>)
  }

  const currentDayOfWeek = today.day();

  let cutoffTime;

  if (currentDayOfWeek >= 1 && currentDayOfWeek <= 5) {
    // Monday to Friday: 5:30 PM cutoff time
    cutoffTime = '17:30';
  } else if (currentDayOfWeek === 6) {
    // Saturday: 2:30 PM cutoff time
    cutoffTime = '14:30';
  } else {
    // Sunday and other days: No cutoff time, use the current time
    cutoffTime = today.format('HH:mm');
  }

  const isDateDisabled = (date) => {
    // Check if the day is disabled based on closedDays
    for (let i = 0; i < closedDays.length; i++) {
      if (closedDays[i] === moment(date).day()) {
        return true;
      }
    }
  
    // Check if the date is part of the disabled dates
    const datesClosedValues = Object.keys(datesClosed.dates);
    for (let j = 0; j < datesClosedValues.length; j++) {
      if (moment(date).format('MM/DD') === moment(datesClosedValues[j]).format('MM/DD')) {
        return true;
      }
    }
  
    return false;
  };

  const minDate = () => {
    const currentDayOfWeek = today.day();
    let cutoffTime;
  
    // Define cutoff time based on the day of the week
    if (currentDayOfWeek >= 1 && currentDayOfWeek <= 5) {
      cutoffTime = moment('17:30', 'HH:mm');
    } else if (currentDayOfWeek === 6) {
      cutoffTime = moment('14:30', 'HH:mm');
    } else {
      cutoffTime = moment(); // No cutoff on Sunday, so use current time
    }
  
    // Get the current time as a moment object
    const currentTime = moment();
    // Check if today is before the cutoff time and not disabled
    if (currentTime.isBefore(cutoffTime) && !isDateDisabled(today)) {
      return today; // Today is valid
    } else {
      // Otherwise, find the next available date that is not disabled
      let nextDate = moment(today).add(1, 'days');
      while (isDateDisabled(nextDate)) {
        nextDate = nextDate.add(1, 'days');
      }
      return nextDate;
    }
  };

  let steps = [
    <div style={{ padding: '8px', lineHeightStep: '100%', display: 'flex', flexDirection: 'column', marginTop: '16px' }}>
      <Typography><strong>Instructions</strong></Typography>
      <Divider />
      <Typography style={{ marginTop: '4px', marginBottom: '4px' }}>1. Enter your desired shipping location in the 'Enter your Address' bar.</Typography>
      <Typography style={{ marginTop: '4px', marginBottom: '4px' }}>2. After entering an address, pinpoint the material dropoff location by clicking on the map.</Typography>
      <Typography style={{ marginTop: '4px', marginBottom: '4px' }}>The cost of shipping will be calculated via the dropoff location.</Typography>
    </div>,
    <div style={{
      marginTop: '32px',
      marginBottom: '8px'
    }}>

      <div style={{ marginTop: '8px', marginBottom: '8px' }}>
        <FormControl>
          <Typography  variant='h6'>Preferred Delivery Date & Time</Typography>
          <Grid container spacing={2} className={MuiClasses.dateInput}>
            <Grid item xs={9}>
              <DatePicker
                style={{marginTop: '15px'}}
                value={props.inputs[10].config.value === '' ? moment().format('YYYY-MM-DD') : props.inputs[10].config.value}
                onChange={(event) => { props.inputChangedHandler(moment(event), 'deliverDate') }}
                minDate={minDate()}
                shouldDisableDate={(date) => {
                  return generateDatesClosed(date)
                }}

              ></DatePicker>
              {/* props.createInput(props.inputs[10]) */}
            </Grid>
            <Grid item xs={2}>
              <Select
                    value={props.inputs[11].config.value}
                    onChange={(event) => { props.inputChangedHandler(event, 'deliverTime') }}
                    style={{marginTop: '14px'}}
                >
                  <MenuItem 
                    disabled={
                        moment(props.inputs[10].config.value).format('YYYY-MM-DD') === moment(today).format('YYYY-MM-DD') &&
                        moment(today).format('HH') > moment('12', 'HH').format('HH')
                    } 
                    key={'AM'} 
                    value={'AM'}
                  >
                    AM
                  </MenuItem> 

                  <MenuItem 
                    key={'PM'} 
                    value={'PM'}
                  >
                    PM
                  </MenuItem> 
                </Select>
              {/* {props.createInput(props.inputs[11])} */}
            </Grid>
          </Grid>
        </FormControl>
      </div>
      <Typography variant='h6'>Optional Steps</Typography>
      <Typography>Special Instructions</Typography>
        <TextField
          value={props.inputs[9].config.value}
          fullWidth
          variant={'outlined'}
          onChange={(event) => { props.inputChangedHandler(event, 'notes') }}
          multiline
          minRows={4}
        />
      {/* {props.createInput(props.inputs[9])} */}
    </div>
  ]



  return <div className={MuiClasses.root}>
    <div style={{ position: 'absolute', height: `calc(100vh - ${headerHeight}px)`, width: '100%', left: 0, opacity: .7 }}>
      <GoogleMap
        mapContainerStyle={mapContainerStyle}
        options={{
          fullscreenControl: false,
          streetViewControl: false,
          mapTypeControl: false,
          mapTypeId: 'hybrid'
        }}

        zoom={17}
        center={center}
        onClick={(props.validAddress && step === 0) && onClick}
      >
        {zonePolygons}
        <Marker
          position={props.coordinates} />
        <Circle
          center={{ lat: -49.904415, lng: -96.932431 - 180 }}
          radius={20037508.34 - 100000 * .70}
          options={{
            clickable: false,
            fillOpacity: .4,
            fillColor: 'black',
          }}
        />
        

      </GoogleMap>
    </div>

    {!props.validAddress && <div style={{
      position: 'fixed',
      height: `calc(100vh - ${headerHeight}px)`,
      width: '100vw',
      top: headerHeight,
      left: 0,
      backgroundColor: 'black',
      opacity: .5,
    }}>
    </div>}

    <div style={step === 1 ? 
    {
      position: 'fixed',
      right: 0,
      left: 0,
      top: 200,
      marginLeft: 'auto',
      marginRight: 'auto', 
      maxWidth: '600px',
    }
    
    : {
      position: 'fixed',
      right: sidePanelPos + 64,
      top: 128,
      maxWidth: '350px',
    }}>
      {/* <Alert severity='warning'>Due to springtime road restrictions, there may be a delay in your delivery.</Alert> */}

      <Paper style={{
        justifyContent: 'space-between',
        padding: '8px',
        lineHeightStep: '100%',
        display: 'flex',
        flexDirection: 'column',
        marginTop: '8px',
      }}>

        <div>
          {generateAlert()}
          {step === 0 && <StandaloneSearchBox
            onLoad={onLoad}
            onPlacesChanged={onPlacesChanged}
            bounds={new window.google.maps.LatLngBounds(
              new window.google.maps.LatLng(49.904415 - 0.2, -96.932431 - 0.2),
              new window.google.maps.LatLng(49.904415 + 0.2, -96.932431 + 0.2),
            )}>
            <input
              type='text'
              placeholder='Enter your Address'
              style={{
                boxSizing: `border-box`,
                border: `1px solid`,
                width: `100%`,
                height: `48px`,
                padding: `0 12px`,
                borderRadius: `3px`,
                boxShadow: `0 1px 4px rgba(0, 0, 0, 0.2)`,
                fontSize: `14px`,
              }}
            />
          </StandaloneSearchBox>}
          {props.address && <Typography style={{ marginTop: '8px' }}><strong>Location:</strong>{props.address}</Typography>}
        </div>

        {steps[step]}

        <Grid container spacing={1} style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Grid item xs={6}>
            <Button
              variant='outlined'
              fullWidth
              onClick={() => { step === 0 ? props.handleClose() : setStep(0) }}>
              {step === 0 ? "Return to Cart" : "Re-enter Address"}
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Button
              disabled={!props.validAddress || !props.validLocation || (step === 1 && !props.inputs[10].config.value) || (step === 1 && 
                //props.inputs[10].config.value is today and its past the cutoff time defined above
                
                props.inputs[10].config.value === moment(today).format('YYYY-MM-DD') &&
                moment(today).format('HH:mm') > moment(cutoffTime, 'HH:mm').format('HH:mm')
                
                )}
              variant='contained'
              fullWidth
              onClick={() => { step === 0 ? setShowModal(true) : props.handleClose() }}>
              {step === 0 ? "Next" : "Go To Cart"}
            </Button>
          </Grid>
        </Grid>
      </Paper>
    </div>

    <Modal
      style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '30vh' }}
      open={showModal}
      BackdropComponent={Backdrop}>
      <Fade in={showModal}>
        <Paper style={{ padding: '16px', maxWidth: '400px', width: '100%' }}>
          <Typography variant='h6' style={{ marginBottom: '8px' }}>Please confirm the desired delivery location matches the marker on the map</Typography>
          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Button variant='contained' style={{ margin: '4px' }} onClick={() => handlePickAddress()}>Confirm</Button>
            <Button variant='outlined' style={{ margin: '4px' }} onClick={() => setShowModal(false)}>Cancel</Button>
          </div>
        </Paper>
      </Fade>
    </Modal>
  </div >
};