import React, { useState, useEffect } from 'react';
import {Button, Divider, IconButton, Grid, Paper, TextField, Typography, makeStyles, Checkbox, FormControlLabel,
Dialog, DialogActions, DialogContent, DialogTitle, CircularProgress} from '@material-ui/core'
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import { withRouter } from 'react-router-dom';
import DatePicker from '../UI/DatePicker/DatePicker';
import { Autocomplete } from '@material-ui/lab';
import { useUser } from '../../../providers/UserContext';
import _ from 'lodash';
import SignatureCanvas from "react-signature-canvas";
import Dropzone from 'react-dropzone'
import PublishIcon from '@material-ui/icons/Publish';
import CloseIcon from '@material-ui/icons/Close';
import CheckIcon from '@material-ui/icons/Check';

const useStyles = makeStyles((theme) => ({
    btnGroup:{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-end',
    },
    card: {
        width: '100%',
        height: '100%',
        padding: theme.spacing(2),
        display: 'flex',
        alignItems: 'center',
    },
    content: {
        maxWidth: '1000px',
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        margin: 'auto',
    },
    fileInput: {
        display:'flex',
        alignItems: 'center',
        border: '1px solid #bdbdbd',
        borderRadius:'4px',
        margin:'8px',
        marginTop:'16px',
        backgroundColor: '#f2f2f2',
    },
    fileInputAccept: {
        display:'flex',
        alignItems: 'center',
        border: '0px',
        borderRadius:'4px',
        margin:'8px',
        marginTop:'16px',
        backgroundColor: '#56a84b',
        color:'white',
    },
    image: {
        width:'100%',
        height: '200px',
        objectFit: 'contain',
    },
    photoBack: {
        display: 'flex',
        height:'200px',
        maxHeight: '200px',
        width:'100%',
        backgroundColor: 'rgba(0,0,0,.05)'
    },
    photoTile: {
        display:'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height:'100%',
    },
    paper: {
        width:'100%',
        maxWidth: '1000px',
        padding: theme.spacing(2),
    },
    row:{
        display: 'flex',
        alignItems:'center',
    },
    title: {
        display:'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginBottom: '32px',
    },
    text: {
        color: '#707070',
    },
    backButton: {
        marginBottom: '20px',
        marginTop: '20px',
    },
    sig: {
        border: '1px solid black',
        width: '100%',
        minHeight: '250px',
    },
}));

const WorkOrderForm = (props) => {

    const classes=useStyles();
    const { users, userLoading, getUsers } = useUser();

    const [repairDialogOpen, setRepairDialogOpen] = useState(false);
    const [dialogType, setDialogType] = useState('')
    //const [unitAutocompleteOpen, setUnitAutocompleteOpen] = useState(false)
    const [techAutocompleteOpen, setTechAutocompleteOpen] = useState(false)
    const [deleteIndex, setDeleteIndex] = useState(null)

    useEffect(() => {
        if(users.length <= 0){
            getUsers();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleRepairDialogOpen = (dialogType, repair, index) => {
        if(_.isUndefined(repair) && _.isUndefined(index)){
            props.setRepairForm({...props.initialRepair})
            props.setPartForm({...props.initialPart})
            setRepairDialogOpen(true);
            setDialogType(dialogType)
            props.setItemIndex(null)
            setDeleteIndex(null)
        }else{
            props.setRepairForm({...repair})
            props.setPartForm({...repair})
            setRepairDialogOpen(true);
            setDialogType(dialogType)
            props.setItemIndex(index)
            setDeleteIndex(null)
        }
    };

    const handleRepairDialogClose = () => {
        setRepairDialogOpen(false);
    };

    //function to display user displayname instead of uuid
    const getUserDisplayName = (uuid) => {
        let user = _.find(users, {uuid: uuid});
        if (!_.isUndefined(user)) {
            return user.displayName;
        } else {
            return uuid;
        }
    }

    return (
        <Grid className={classes.content}>
            <Dialog
                open={repairDialogOpen}
                onClose={handleRepairDialogClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{dialogType}</DialogTitle>
                <DialogContent>
                    <Grid container spacing={2}>

                        {(dialogType === 'Edit Repair' || dialogType ===  'New Repair')
                        ?
                        <React.Fragment>
                            <Grid item sm={12}>
                                <Typography>Hours</Typography>
                                <TextField
                                    name="timeSpentRepairing"
                                    value={props.repairForm.timeSpentRepairing}
                                    onChange={(event)=> { props.handleChangeAdditionalInfo('Repair', event) }}
                                    fullWidth
                                    variant="outlined"
                                    size="small"
                                />
                            </Grid>
                            <Grid item sm={12}>
                                <Typography>Description</Typography>
                                <TextField
                                    name="description"
                                    multiline
                                    minRows={4}
                                    value={props.repairForm.description}
                                    onChange={(event)=> { props.handleChangeAdditionalInfo('Repair', event) }}
                                    fullWidth
                                    variant="outlined"
                                    size="small"
                                />
                            </Grid>
                            <Grid item sm={12}>
                                <Typography>General Notes</Typography>
                                <TextField
                                    name="notes"
                                    multiline
                                    minRows={4}
                                    value={props.repairForm.notes}
                                    onChange={(event)=> { props.handleChangeAdditionalInfo('Repair', event) }}
                                    fullWidth
                                    variant="outlined"
                                    size="small"
                                />
                            </Grid>
                            <Grid item sm={12}>
                                <Typography>Tech Notes</Typography>
                                <TextField
                                    name="techNotes"
                                    multiline
                                    minRows={4}
                                    value={props.repairForm.techNotes}
                                    onChange={(event)=> { props.handleChangeAdditionalInfo('Repair', event) }}
                                    fullWidth
                                    variant="outlined"
                                    size="small"
                                />
                            </Grid>
                        </React.Fragment>
                        :   //dialogType === New Part or Edit Part
                        <React.Fragment>
                            <Grid item sm={6}>
                                <Typography>Part #</Typography>
                                <TextField
                                    name="partNumber"
                                    value={props.partForm.partNumber}
                                    onChange={(event)=> { props.handleChangeAdditionalInfo('Part', event) }}
                                    fullWidth
                                    variant="outlined"
                                    size="small"
                                />
                            </Grid>
                            <Grid item sm={6}>
                                <Typography>Quantity</Typography>
                                <TextField
                                    name="quantity"
                                    value={props.partForm.quantity}
                                    onChange={(event)=> { props.handleChangeAdditionalInfo('Part', event) }}
                                    fullWidth
                                    type="number"
                                    variant="outlined"
                                    size="small"
                                />
                            </Grid>
                            <Grid item sm={12}>
                                <Typography>Part Description</Typography>
                                <TextField
                                    name="partDescription"
                                    multiline
                                    minRows={4}
                                    value={props.partForm.partDescription}
                                    onChange={(event)=> { props.handleChangeAdditionalInfo('Part', event) }}
                                    fullWidth
                                    variant="outlined"
                                    size="small"
                                />
                            </Grid>
                            <Grid item sm={12}>
                                <Typography>Part Photos</Typography>

                                <Dropzone 
                                    accept={{'image/jpeg': ['.jpg', '.jpeg'], 'image/png': ['.png', '.PNG'], 'application/pdf': ['.pdf'],}}
                                    onDrop={(fileArray) => {
                                        let photoArrayContainer = _.cloneDeep(props.partForm);
                                        fileArray.forEach((file) => { photoArrayContainer.photoUrls.push(file) })
                                        props.setPartForm(photoArrayContainer)
                                    }}
                                >
                                    {({ getRootProps, getInputProps }) => (
                                        <Grid container item xs={12}justifyContent='center' {...getRootProps()}  style={{border:'2px dashed lightGrey', minHeight:'100px'}}>
                                            <input {...getInputProps()} style={{ display: 'none' }} />
                    
                                            <PublishIcon style={{color:'grey', marginRight:'20px'}}/>

                                            <Typography variant='h6'>
                                                {"Upload Part Photos"}
                                            </Typography>
                                        
                                        </Grid> 
                                    )}
                                </Dropzone> 

                                {/* show localphotos here */}
                                {props.partForm?.photoUrls?.length > 0 ? 
                                <React.Fragment>
                                    <Typography >Photos Included</Typography>

                                    <Grid>   
                                        {props.partForm?.photoUrls?.length > 0 ?
                                            <Grid container spacing={2}>

                                                {props.partForm?.photoUrls?.map((photo, index) => (
                                                    <Grid item key={photo + index}>
                                                        { deleteIndex === index ?
                                                        <Grid container style={{position: 'relative', border: '1px solid grey', height: '100px', width: '100px'}}>
                                                            <Grid > 
                                                                <Typography>Are you sure?</Typography>
                                                            </Grid>

                                                            <Grid container>
                                                                <Grid item xs={6}>
                                                                    <IconButton>
                                                                        <CheckIcon 
                                                                            style={{color: 'green'}}
                                                                            onClick={() => { props.handleDeletePhoto(index); setDeleteIndex(null) }} 
                                                                        />
                                                                    </IconButton>
                                                                </Grid>

                                                                <Grid item xs={6} style={{position: 'absolute', right: 0}}>                             
                                                                    <IconButton>
                                                                        <CloseIcon 
                                                                            style={{color: 'red'}}
                                                                            onClick={() => { setDeleteIndex(null) }} 
                                                                        />
                                                                    </IconButton>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                        :
                                                        <Grid container style={{position: 'relative'}}>
                                                            <img 
                                                                src={URL.createObjectURL(photo)} 
                                                                alt={photo.name} 
                                                                style={{ height: "100px", width:'100px' }} 
                                                            />

                                                            <IconButton size='small' style={{position: 'absolute', top: 0, right: 0}}>
                                                                <CloseIcon 
                                                                    style={{color: 'red'}}
                                                                    onClick={() => { setDeleteIndex(index) }} 
                                                                />
                                                            </IconButton>
                                                        </Grid>
                                                        }
                                                    </Grid>
                                                ))}
                                            </Grid> : null}
                                            

                                    </Grid>
                                </React.Fragment> 
                                : null}

                            </Grid>
                            
                        </React.Fragment>
                        }
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button
                        variant='contained'
                        disabled={
                            (dialogType === 'Edit Repair' || dialogType === 'New Repair')
                            ? ((props.repairForm.description === '') && (props.repairForm.timeSpentRepairing === '') && (props.repairForm.notes === '') && (props.repairForm.techNotes === ''))
                            : (props.partForm.partDescription === '')
                        }
                        onClick={() => {
                            props.handleAddAdditionalInfo(dialogType);
                            handleRepairDialogClose();
                        }}
                        color="primary"
                    >
                        Save
                    </Button>
                    <Button
                        variant='contained'
                        onClick={handleRepairDialogClose}
                        color="secondary"
                    >
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>

            <Grid className={classes.backButton}>
                <Button variant="contained" startIcon={<ArrowBackIcon />}
                    onClick={() => props.history.goBack()} >Back</Button>
            </Grid>

            <form onSubmit={props.handleSubmit}>
                <Paper className={classes.paper}>
                    <Grid container spacing={2}>
                        <Grid item sm={12} className={classes.title}>

                            <Typography variant="h4" align="center">New Work Order</Typography>
                        </Grid>
                        <Grid item sm={12}>
                            <Typography variant="h6" align="left">Customer Information</Typography>
                            <Divider/>
                        </Grid>
                        <Grid item sm={12}>
                            <FormControlLabel
                                label="Outside Work"
                                style={{marginTop: '15px'}}
                                control={
                                    <Checkbox
                                        name="customer.outsideWork"
                                        checked={props.userForm.customer.outsideWork}
                                        onChange={props.handleChange}
                                    />
                                } />
                        </Grid>
                        <Grid item sm={12}>
                            <Typography>Company Name</Typography>
                            <TextField size="small" fullWidth variant='outlined' name="customer.companyName" onChange={props.handleChange} value={props.userForm.customer.companyName}/>
                        </Grid>
                        <Grid item sm={6}>
                            <Typography>Contact Name</Typography>
                            <TextField size="small" fullWidth variant='outlined' name="customer.contactName" onChange={props.handleChange} value={props.userForm.customer.contactName}/>
                        </Grid>
                        <Grid item sm={6}>
                            <Typography>Contact Phone Number</Typography>
                            <TextField size="small" fullWidth variant='outlined' name="customer.contactPhone" onChange={props.handleChange} value={props.userForm.customer.contactPhone}/>
                        </Grid>
                        <Grid item sm={12}>
                            <Divider style={{marginBottom:'20px'}}/>
                        </Grid>

                        <Grid item sm={12}>
                            <Typography variant="h6" align="left">Vehicle Information</Typography>
                            <Divider/>
                        </Grid>

                        <Grid item sm={4}>
                            <Typography>Unit #</Typography>
                            <TextField size="small" fullWidth variant='outlined' name="vehicle.unitNumber" onChange={props.handleChange} value={props.userForm.vehicle.unitNumber}/>

                            {/*<Autocomplete
                                open={unitAutocompleteOpen}
                                onOpen={() => {
                                    setUnitAutocompleteOpen(true);
                                }}
                                onClose={() => {
                                    setUnitAutocompleteOpen(false);
                                }}
                                loading={props.unitLoading}
                                label="Search"
                                id="autocomplete"
                                autoComplete
                                autoHighlight
                                value={props.unitAutocompleteValue}
                                onChange={(event, newValue) => {
                                    props.handleUnitUpdate(newValue)
                                }}
                                options={props.units}
                                renderOption={(unit) =>(
                                    <Grid >
                                        <Typography>{'#' + unit.unitNumber + ' ' + unit.description}</Typography>
                                    </Grid>
                                )}
                                getOptionSelected={(option, value) => option.unitNumber === value.unitNumber || {}}
                                getOptionLabel={(option) => option.unitNumber ? option.unitNumber : '' }
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        //label="Select Unit"
                                        variant="outlined"
                                        //required
                                        size="small"
                                        name="unitName"
                                        fullWidth
                                        InputProps={{
                                            ...params.InputProps,
                                            endAdornment: (
                                                <React.Fragment>
                                                    {props.unitLoading ? <CircularProgress color="inherit" size={20} /> : null}
                                                    {params.InputProps.endAdornment}
                                                </React.Fragment>
                                            ),
                                        }}
                                    />
                                )}
                            />*/}
                        </Grid>
                        <Grid item sm={4}>
                            <Typography>Vehicle Make</Typography>
                            <TextField size="small" fullWidth variant='outlined' name="vehicle.make" onChange={props.handleChange} value={props.userForm.vehicle.make}/>
                        </Grid>
                        <Grid item sm={4}>
                            <Typography>Vehicle Year</Typography>
                            <TextField size="small" fullWidth variant='outlined' name="vehicle.year" onChange={props.handleChange} value={props.userForm.vehicle.year}/>
                        </Grid>

                        <Grid item sm={4}>
                            <Typography>VIN #</Typography>
                            <TextField size="small" fullWidth variant='outlined' name="vehicle.vin" onChange={props.handleChange} value={props.userForm.vehicle.vin}/>
                        </Grid>
                        <Grid item sm={4}>
                            <Typography>Mileage</Typography>
                            <TextField size="small" fullWidth variant='outlined' name="vehicle.mileage" onChange={props.handleChange} value={props.userForm.vehicle.mileage}/>
                        </Grid>
                        <Grid item sm={4}>
                            <Typography>License Plate</Typography>
                            <TextField size="small" fullWidth variant='outlined' name="vehicle.licensePlate" onChange={props.handleChange} value={props.userForm.vehicle.licensePlate}/>
                        </Grid>

                        <Grid item sm={4}>
                            <Typography>Cab Card #</Typography>
                            <TextField size="small" fullWidth variant='outlined' name="vehicle.cabCardNumber" onChange={props.handleChange} value={props.userForm.vehicle.cabCardNumber}/>
                        </Grid>
                        <Grid item sm={4}>
                            <Typography>PO #</Typography>
                            <TextField size="small" fullWidth variant='outlined' name="vehicle.PONumber" onChange={props.handleChange} value={props.userForm.vehicle.PONumber}/>
                        </Grid>
                        <Grid item sm={4}>
                            <Typography>Safety Due</Typography>
                            <DatePicker name="vehicle.safetyDueDate" onChange={(event) => { props.handleSafetyDueDateUpdate(event) }} value={props.userForm.vehicle.safetyDueDate} ></DatePicker>
                        </Grid>

                        <Grid item sm={12}>
                            <Divider style={{marginBottom:'2px'}}/>
                        </Grid>
                        <Grid item sm={12}>
                            <Typography variant="h6" align="left">Notes</Typography>
                        </Grid>
                        <Grid item sm={12}>
                            <TextField
                                name="notes"
                                multiline
                                minRows={6}
                                value={props.userForm.notes}
                                onChange={props.handleChangeNotes}
                                fullWidth
                                variant="outlined"
                                size="small"
                            />
                        </Grid>

                        <Grid item sm={12}>
                            <Divider style={{marginBottom:'10px'}}/>
                        </Grid>
                        <Grid item sm={12}>
                            <Typography variant="h6" align="left">Assign Tech</Typography>
                            <Divider/>
                        </Grid>

                        <Grid item sm={12} style={{marginBottom: '20px'}}>
                            <Autocomplete
                                open={techAutocompleteOpen}
                                onOpen={() => {
                                    setTechAutocompleteOpen(true);
                                }}
                                onClose={() => {
                                    setTechAutocompleteOpen(false);
                                }}
                                loading={userLoading}
                                label="Search"
                                id="autocomplete"
                                autoComplete
                                autoHighlight
                                //disabled={props.userForm.customer.outsideWork && !props.userForm.customer.signedOff}
                                disableClearable
                                style={{ maxWidth: '300px', width: '100%' }}
                                //inputValue={typedValue}
                                //onInputChange={(event, newValue) => { setTypedValue(newValue) }}
                                value={getUserDisplayName(props.userForm.assignedUser)}
                                onChange={(event, newValue) => { props.setWorkOrderForm({ ...props.userForm, 'assignedUser': newValue }) }}
                                options={users.filter(x=> x.employment?.active).map((user) => user.uuid ?? '').sort((a, b) => getUserDisplayName(a).toLowerCase() > getUserDisplayName(b).toLowerCase())}
                                getOptionSelected={(option, value) => option?.uuid === value?.uuid}
                                getOptionLabel={(option) => getUserDisplayName(option)}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        style={{ maxWidth: '300px', width: '100%' }}
                                        //label="Shop Tech"
                                        variant="outlined"
                                        size='small'
                                        InputProps={{
                                            ...params.InputProps,
                                            endAdornment: (
                                                <React.Fragment>
                                                    {userLoading ? <CircularProgress color="inherit" size={20} /> : null}
                                                    {params.InputProps.endAdornment}
                                                </React.Fragment>
                                            ),
                                        }}
                                    />
                                )}
                            />
                        </Grid>

                        <Grid container>
                            <Grid item xs={6}>
                                <Typography variant='h6'>Customer Signature</Typography>
                                <SignatureCanvas
                                    ref={props.customerSig}
                                    canvasProps={{className: classes.sig}}
                                    clearOnResize={false}
                                    onEnd={()=> {
                                        //props.updateWorkOrderData({ ...props.workOrderData, customer: {...props.workOrderData.customer, sigURL: props.sig.current.getTrimmedCanvas().toDataURL()}})
                                    }}
                                />
                                <Grid container justifyContent='flex-end'>
                                    <Button
                                        variant='contained'
                                        size='small'
                                        onClick={()=> { props.customerSig.current.clear() }}
                                    >
                                        Clear
                                    </Button>
                                </Grid>
                            </Grid>

                            <Grid item xs={6}>
                                <Typography variant='h6'>User Signature</Typography>
                                <SignatureCanvas
                                    ref={props.userSig}
                                    canvasProps={{className: classes.sig}}
                                    clearOnResize={false}
                                    onEnd={()=> {
                                        //props.updateWorkOrderData({ ...props.workOrderData, customer: {...props.workOrderData.customer, sigURL: props.sig.current.getTrimmedCanvas().toDataURL()}})
                                    }}
                                />
                                <Grid container justifyContent='flex-end'>
                                    <Button
                                        variant='contained'
                                        size='small'
                                        onClick={()=> { props.userSig.current.clear() }}
                                    >
                                        Clear
                                    </Button>
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid item sm={12}>
                            <Typography variant="h6" align="left">Repairs Needed *</Typography>
                            <Divider style={{marginBottom:'10px'}}/>
                            <Button
                                variant="contained"
                                color="primary"
                                size="small"
                                //startIcon={<AddIcon />}
                                onClick={() => handleRepairDialogOpen('New Repair')}
                                style={{marginBottom:'20px'}}

                            >Add Repair</Button>
                        </Grid>

                        <Grid item sm={12}>
                            <Grid container spacing={2}>
                                <Grid item sm={1}>
                                    <Typography><small><b>#</b></small></Typography>
                                </Grid>
                                <Grid item sm={1}>
                                    <Typography><small><b>Hours</b></small></Typography>
                                </Grid>
                                <Grid item sm={2}>
                                    <Typography><small><b> Description</b></small></Typography>
                                </Grid>
                                <Grid item sm={3}>
                                    <Typography><small><b>General Notes</b></small></Typography>
                                </Grid>
                                <Grid item sm={3}>
                                    <Typography><small><b>Tech Notes</b></small></Typography>
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid item sm={12}>
                            {
                                props.userForm.repairsNeeded.map((repair, index) => {
                                    return (
                                        <Grid container spacing={2} key={index}>
                                            <Grid item sm={1}>
                                                <Typography noWrap style={{textOverflow: 'ellipsis'}}>{index+1}</Typography>
                                            </Grid>
                                            <Grid item sm={1}>
                                                <Typography noWrap style={{textOverflow: 'ellipsis'}}>{repair.timeSpentRepairing}</Typography>
                                            </Grid>
                                            <Grid item sm={2}>
                                                <Typography noWrap style={{textOverflow: 'ellipsis'}}>{repair.description}</Typography>
                                            </Grid>
                                            <Grid item sm={3}>
                                                <Typography noWrap style={{textOverflow: 'ellipsis'}}>{repair.notes}</Typography>
                                            </Grid>
                                            <Grid item sm={3}>
                                                <Typography noWrap style={{textOverflow: 'ellipsis'}}>{repair.techNotes}</Typography>
                                            </Grid>
                                            <Grid container justifyContent='flex-end' sm={1}>
                                                <IconButton
                                                    onClick={() => { handleRepairDialogOpen('Edit Repair', repair, index)}}
                                                >
                                                    <EditIcon/>
                                                </IconButton>
                                            </Grid>
                                            <Grid container justifyContent='flex-end' sm={1}>
                                                <IconButton
                                                    onClick={() => props.handleRemoveAdditionalInfo('Repair', index)}
                                                >
                                                    <DeleteIcon/>
                                                </IconButton>
                                            </Grid>
                                        </Grid>
                                    )
                            }
                        )}
                        </Grid>
                        
                        <Grid item sm={12}>
                            <Typography variant="h6" align="left">Parts Needed</Typography>
                            <Divider style={{marginBottom:'10px'}}/>
                            <Button
                                variant="contained"
                                color="primary"
                                size="small"
                                //startIcon={<AddIcon />}
                                onClick={() => handleRepairDialogOpen('New Part')}
                                style={{marginBottom:'20px'}}

                            >Add Part Request</Button>
                        </Grid>

                        <Grid item sm={12}>
                            <Grid container spacing={2}>
                                <Grid item sm={2}>
                                    <Typography><small><b>Part #</b></small></Typography>
                                </Grid>
                                <Grid item sm={3}>
                                    <Typography><small><b>Part Description</b></small></Typography>
                                </Grid>
                                <Grid item sm={2}>
                                    <Typography><small><b>Qty</b></small></Typography>
                                </Grid>
                                <Grid item sm={3}>
                                    <Typography><small><b>Photo #</b></small></Typography>
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid item sm={12}>
                            {
                                props.userForm.partsNeeded.map((part, index) => {
                                    return (
                                        <Grid container spacing={2} key={index}>
                                            <Grid item sm={2}>
                                                <Typography>{part.partNumber}</Typography>
                                            </Grid>
                                            <Grid item sm={3}>
                                                <Typography>{part.partDescription}</Typography>
                                            </Grid>
                                            <Grid item sm={2}>
                                                <Typography>{part.quantity}</Typography>
                                            </Grid>
                                            <Grid item sm={3}>
                                                <Typography>{part.photoUrls.length}</Typography>
                                            </Grid>
                                            <Grid container justifyContent='flex-end' sm={1}>
                                                <IconButton
                                                    onClick={() => { handleRepairDialogOpen('Edit Part', part, index)}}
                                                >
                                                    <EditIcon/>
                                                </IconButton>
                                            </Grid>
                                            <Grid container justifyContent='flex-end' sm={1}>
                                                <IconButton
                                                onClick={() => props.handleRemoveAdditionalInfo('Part',index)}><DeleteIcon/></IconButton>
                                            </Grid>
                                        </Grid>
                                    )
                            }
                        )}
                        </Grid>
                            

                        <Grid item sm={12}>
                            <Divider style={{marginBottom:'20px'}}/>
                        </Grid>
                        <Grid item sm={12}>
                            <small>All fields marked with * are required.</small>
                            <Divider/>
                        </Grid>
                        <Grid item sm={12}>
                            <Typography type='url' align="center" style={{color:'red'}}>{props.error}</Typography>
                        </Grid>

                        <Grid item sm={12}>
                            <Button fullWidth variant="contained" size="large" disabled={props.submitting || props.error || props.success || props.userForm.repairsNeeded.length === 0}
                            style={props.success ? {color:'white', backgroundColor:'green'} : {}}
                            type="submit">{props.submitting ? "Submitting..." : props.success ? "Work Order Successfully Created!" : "Create Work Order"} </Button>
                        </Grid>
                    </Grid>
                </Paper>
            </form>
        </Grid>
    )
}

export default withRouter(WorkOrderForm);