import React, { useState, useEffect, useRef } from 'react';
import { withRouter } from 'react-router';
import { Checkbox, Divider, Modal, makeStyles, } from '@material-ui/core';
import { Paper, Grid, Button, Typography, IconButton, InputAdornment, Input, Switch, Select, MenuItem, useTheme, useMediaQuery } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import TextField from '@material-ui/core/TextField';
import PaperTitle from '../../../components/Orders/OrderPage/PaperTitle'
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import 'date-fns';
import moment from 'moment'
import { useUser } from '../../../../providers/UserContext'
import { useUnitDescription } from '../../../../providers/UnitDescriptionContext'
import { useProduct } from '../../../../providers/ProductContext';
import AddProduct from './AddProduct';
import DeleteIcon from '@material-ui/icons/Delete';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import { connect } from 'react-redux';
import * as actionCreators from '../../../../store/actions/index';
import { functions } from '../../../../firebase/firebase';
import Skeleton from '@material-ui/lab/Skeleton';
import { useCoupon } from '../../../../providers/CouponsProvider';
import _ from 'lodash';
import { useAuth } from '../../../../providers/AuthContext'
import { firestore } from '../../../../firebase/firebase';
import { useLoadScript } from '@react-google-maps/api';

import ProcessingDialog from '../../../../Public/components/Checkout/ProcessingDialog/ProcessingDialog';
import CheckoutComplete from '../../../../Public/components/Checkout/CheckoutComplete/CheckoutComplete';
import { CartMap } from '../../../../Public/components/Cart/CartMap';
import { MobileCartMap } from '../../../../Public/components/Cart/Mobile/MobileCartMap';

const useStyles = makeStyles((theme) => ({
    deleteModal: {
        width: '250px',
        padding: '16px',
        margin: '0 auto',
        marginTop: '50vh',
        //backgroundColor: 'rgb(49, 49, 49)',
        //border: '1px solid black'
    },
    loadDraftModal: {
        minWidth: '375px',
        maxWidth: '500px',
        minHeight: '600px',
        padding: '16px',
        margin: '0 auto',
        marginTop: '10vh',
    },
    root: {
        display: 'flex',
        justifyContent: 'center',
        "& .MuiFormLabel-root": {
            color: "grey",
        },
        "& .MuiInputBase-input": {
            color: "black",
        },
    },

    container: {
        maxWidth: '1200px',
        display: 'flex',
        justifyContent: 'center',
        margin: theme.spacing(2),
        [theme.breakpoints.down('xs')]: {
            alignItems: 'center',
            margin: '0px',
        },
    },

    paper: {
        display: 'flex',
        justifyContent: 'center',
        padding: theme.spacing(4),
    },

    checkout: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        padding: theme.spacing(3),
        width: '250px',
    }
}));

const OrderNew = (props) => {

    const classes = useStyles();
    const { getUsers } = useUser();
    const { getUnits } = useUnitDescription();
    const { coupons } = useCoupon();
    const { products, loading } = useProduct();
    const { calcShipping, setSubtotal, getTax, history, getSales, resetCart, resetCheckoutForm, paymentComplete } = props
    const isMounted = useRef(false)
    const theme = useTheme();
    const smallScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const { currentUser } = useAuth();
    const { isLoaded } = useLoadScript({ googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY, });
    
    const [checkoutError] = useState([])
    const [deleteData, setDeleteData] = useState([])
    const [validationMessages, setValidationMessages] = useState([])
    const [submitLoading, setSubmitLoading] = useState(true)
    const [processing, setProcessing] = useState(false)
    const [showMap, setShowMap] = useState(false)
    const [couponApplied, setCouponApplied] = useState(_.cloneDeep(props.coupons.inputCoupon.code) || 'noCoupon')
    const [cartDetails, setCartDetails] = useState(_.cloneDeep(props.cart)) 
    const [cartFeeDetails, setCartFeeDetails] = useState(_.cloneDeep(props.cartFees))
    const [showAddProducts, setShowAddProducts] = useState(JSON.parse(sessionStorage.getItem("customShowAddProducts")) || false)
    const [customDiscount, setCustomDiscount] = useState(JSON.parse(sessionStorage.getItem("customDiscountNewOrder")) || "")
    const [customDelivery, setCustomDelivery] = useState(JSON.parse(sessionStorage.getItem("customDeliveryNewOrder")) || "")
    const [customTotal, setCustomTotal] = useState(JSON.parse(sessionStorage.getItem("customTotalNewOrder")) || "")
    const [discountTypePercent, setDiscountTypePercent] = useState(JSON.parse(sessionStorage.getItem("discountTypeNewOrder")) || false)

    const [draftData, setDraftData] = useState([])
    const [filteredUsersDrafts, setFilteredUsersDrafts] = useState([])
    const [draftId, setDraftId] = useState(!_.isUndefined(props?.location?.state?.draftId) ? props.location.state.draftId 
                                : "draftIdNewOrder" in sessionStorage ? JSON.parse(sessionStorage.getItem("draftIdNewOrder")) : undefined)
    const [loadDraftModal, setLoadDraftModal] = useState(false)
    const [deletingDraft, setDeletingDraft] = useState({})
    const [draftSearch, setDraftSearch] = useState('')

    let Map = smallScreen ? <MobileCartMap /> : <CartMap />;

    let defaultCustomerDetails = ({
        givenName: "",
        familyName: "",
        email: "",
        phone: "",
        addressLines: [""],
        city: "",
        province: "MB",
        postalCode: "",
        country: "Canada",
    })
    const [customerDetails, setCustomerDetails] = useState(JSON.parse(sessionStorage.getItem("cutsomerDetailsNewOrder")) || defaultCustomerDetails)

    const formElementsArray = [];
    for (let key in props.checkoutForm) {
        formElementsArray.push({
            id: key,
            config: props.checkoutForm[key]
        });
    }

    useEffect(() => {
    }, [smallScreen])

    // grabs data on inital load- reset page on unmount 
    useEffect(() => {
        getTax()
        getSales(['orderOnlineSale'], false) 
        getUsers()
        getUnits()
        firestore.collection('orderDrafts').get()
        .then(querySnapshot => {
            const response = querySnapshot.docs.map((doc) => {
                return {
                    ...doc.data(),
                    'docId': doc.id,
                }
            })
            setDraftData(response)
        
            //sets form, if applicable
            if(draftId !== undefined){ handleLoadDraft(draftId, response) }
            else{ setSubmitLoading(false) }
        })
        .catch((e) => {
            setSubmitLoading(false)
            console.warn(e)
        });

        return () => {
            resetPage()
        }
        // eslint-disable-next-line
    }, [])

    //calcs redux fees on cart & address change, doesnt run on first render
    useEffect(() => {
        if(isMounted.current){
            calcShipping()
            setSubtotal()
        } else {
           isMounted.current = true;
        }
        // eslint-disable-next-line
    }, [cartDetails, props.cart, props.customerAddress, props.giftCards]) 

    //redux cart fees are calculated and we then update local cart fees 
    useEffect(() => {
        setCartFeeDetails(props.cartFees)
        // eslint-disable-next-line
    }, [props.cartFees])

    useEffect(() => {
        const lightbox = document.createElement('script');
        lightbox.src = process.env.NODE_ENV === 'development' 
            ? "https://api.demo.convergepay.com/hosted-payments/PayWithConverge.js" 
            : "https://api.convergepay.com/hosted-payments/PayWithConverge.js"
        lightbox.async = true;
        console.log(lightbox.src)
        document.body.appendChild(lightbox)
        return () => { document.body.removeChild(lightbox) }
         // eslint-disable-next-line
    }, [])

    // eslint-disable-next-line
    useEffect(() => { return () => props.setToken("", false) }, [])

    useEffect(() => {
        if (paymentComplete) {
            console.log('payment complete')
            //setDisableCheckout(false);
            resetPage();
            sessionStorage.setItem('cartStep', JSON.stringify(0));
            history.push({
                pathname: '/dashboard/store/orders',
            })
        }
         // eslint-disable-next-line
    }, [paymentComplete, resetCart, history])

    useEffect(() => {
        var callback = {
            onError: function (error) { console.log("error", error); setProcessing(false) },
            onCancelled: function () { console.log("cancelled", ""); setProcessing(false)},
            onDeclined: function (response) { console.log("declined", JSON.stringify(response, null, '\t')); setProcessing(false) },
            onApproval: function (response) { onSuccessfulPayment(response); setProcessing(false) }
        };
        if (props.token) { window.PayWithConverge.open({ ssl_txn_auth_token: props.token }, callback) }
        // eslint-disable-next-line
    }, [props.token])

    //filter for seach in load draft modal
    useEffect(() => {
        setFilteredUsersDrafts(draftData.filter(draft => { 
            let fullName =  `${draft.customerDetails.familyName} ${draft.customerDetails.givenName}`
            return (fullName.toLowerCase().includes(draftSearch.toLowerCase()) )
        }))
    }, [draftSearch, draftData])  // eslint-disable-line react-hooks/exhaustive-deps

    const calcActualTotal = () => {
        let customSubtotalContainer = customTotal !== "" ? Number(customTotal) : (Number(cartFeeDetails.subTotal) || Number(0))
        let customDeliveryContainer = customDelivery !== "" ? Number(customDelivery) : Number(cartFeeDetails.shipping)
        let customDiscountContainer = customDiscount !== "" ? !discountTypePercent  ? Number(customDiscount) : ((Number(customSubtotalContainer) * (Number(customDiscount) / Number(100))) ) : Number(cartFeeDetails.coupons)   
        let palletFeeContainer = Number(cartFeeDetails.palletFee?.totalFee || Number(0))

        return Number(Number(customSubtotalContainer)
                + (customTotal !== "" ? Number(0) : Number(cartFeeDetails.cartTaxes.GST) || 0) 
                + (customTotal !== "" ? Number(0) : Number(cartFeeDetails.cartTaxes.PST) || 0)
                + (customTotal !== "" ? Number(0) : Number(cartFeeDetails.cartTaxes['Carbon Fees']) || 0)
                + Number(customDeliveryContainer) 
                + Number(palletFeeContainer) 
                - Number(customDiscountContainer)).toFixed(2)
    }

    const inputChangedHandler = (event, inputIdentifier) => {
        const updatedCheckoutForm = {
            ...props.checkoutForm
        };
        const updatedFormElement = {
            ...updatedCheckoutForm[inputIdentifier]
        };

        //Update changed value and add to reducer
        if(inputIdentifier === 'deliverDate'){
            updatedFormElement.value = moment(event).format('YYYY-MM-DD')
        }
        else{
            updatedFormElement.value = event.target.value;
        }
      
        updatedCheckoutForm[inputIdentifier] = updatedFormElement;

        props.updateCheckoutForm(updatedCheckoutForm);
    }

    const createInput = (data) => {
        return (
            <Input
                key={data.id}
                elementType={data.config.elementType}
                elementConfig={data.config.elementConfig}
                value={data.config.value}
                deliverDateValue={props.checkoutForm['deliverDate'].value}
                variant='outlined'
                changed={(event) => inputChangedHandler(event, data.id)} 
            />
        )
    }

    //const setDistance = (coordinates, inputType, address) => { props.setDistance(coordinates, inputType, address); }

    const handleShowAddProducts = () => {
        let container = !showAddProducts
        sessionStorage.setItem('customShowAddProducts', JSON.stringify(container));
        setShowAddProducts(container)
    }

    const onSuccessfulPayment = (response, noResponse) => {
        let payment = 
        noResponse 
        ? 
        {
            paymentHandler: "CONVERGE",
            amount_money: {
                amount: 0.00,
                currency: "CAD"
            },
            approved_money: {
                amount: 0.00,
                currency: "CAD"
            },
            card_details: {
                card: {}
            },
            //subtract one hour to account for the shitty bandaid which adds an hour in orderTable.js
            created_at: moment().subtract(1, 'hours').format('MM/DD/YYYY hh:mm:ss a'),
            id: 'test',
        } 
        : 
        {
            paymentHandler: "CONVERGE",
            amount_money: {
                amount: response.ssl_amount,
                currency: "CAD"
            },
            approved_money: {
                amount: response.ssl_amount,
                currency: "CAD"
            },
            card_details: {
                card: {
                    card_brand: response.ssl_card_short_description,
                    card_type: response.ssl_card_type,
                    exp_date: response.ssl_exp_date,
                    last_4: response.ssl_card_number,
                }
            },
            created_at: response.ssl_txn_time,
            id: response.ssl_txn_id,
        }

        hanldeSubmitOrder(payment)
        setDeleteData([])
    }
    
    const generateToken = (event) => {
        event.preventDefault();

        setValidationMessages([])
        let totalContainer = calcActualTotal()

        if(isNaN(totalContainer)){
            setValidationMessages(["Total must be a number"])
        }
        else if (!props.generateDisabled) {
            setProcessing(true)
            props.generateToken(Number(totalContainer).toFixed(2));
        }
    }

    const handleFieldChange = (event, field, hook) => {
        switch(hook) {
            case 'customerDetails':
                if(field === 'addressLines'){
                    let container = customerDetails.addressLines
                    container.splice(0, 1, event.target.value)
                    setCustomerDetails({ ...customerDetails, addressLines: container })
                    sessionStorage.setItem('cutsomerDetailsNewOrder', JSON.stringify({ ...customerDetails, addressLines: container }))
                }
                else{
                    setCustomerDetails({...customerDetails, [field]: event.target.value})
                    sessionStorage.setItem('cutsomerDetailsNewOrder', JSON.stringify({...customerDetails, [field]: event.target.value}))
                }
            break;

            case 'cartFeeDetails':
                if(field === 'total'){
                    setCartFeeDetails({...cartFeeDetails, [field]: Number(parseFloat(event.target.value))})
                    sessionStorage.setItem('cartFeeDetailsNewOrder', JSON.stringify({...cartFeeDetails, [field]: Number(parseFloat(event.target.value))}))
                }
            break;

            default:
              console.warn('Error editing field.')
          } 
    }

    const createPaymentConverge = (payment, customer, order) => {
        const convergeCreateOrder = functions.httpsCallable('convergeCreateOrder');
        convergeCreateOrder({
            payment: payment,
            customer: customer,
            order: order,
            sandbox: process.env.NODE_ENV === 'development',
        })
        return {
            formSubmitted: true,
        }
    }

    const handleDeleteProduct = () => {
        let productContainer = _.cloneDeep(cartDetails);

        for(let i = 0; i < deleteData.length; i++){
            let index = deleteData[i]
            props.removeFromCart(index);
            productContainer.splice(index, 1);
        }

        setDeleteData([])
        setCartDetails([...productContainer])
    }

    const resetPage = () => {
        resetCart()
        resetCheckoutForm()

        sessionStorage.removeItem("cutsomerDetailsNewOrder");
        sessionStorage.removeItem("customTotalNewOrder");
        sessionStorage.removeItem("customDeliveryNewOrder");
        sessionStorage.removeItem("customDiscountNewOrder");
        sessionStorage.removeItem("customShowAddProducts");
        sessionStorage.removeItem("draftIdNewOrder");
        sessionStorage.removeItem("discountTypeNewOrder");

        setCouponApplied('noCoupon')
        setCustomerDetails(defaultCustomerDetails)
        setCartDetails([])
        setValidationMessages([])
        setCustomTotal("")
        setCustomDiscount("")
        setCustomDelivery("")
        setShowAddProducts(false)
        getSales(['orderOnlineSale'], false) 
        setDraftId(undefined)
        setDiscountTypePercent(false)
        setShowAddProducts(false)
    }

    const hanldeSubmitOrder = async (payment) => {
        let customerContainer = {...customerDetails, destination: {coordinates: {lat: props.customerLocation.lat, lng: props.customerLocation.lng}, location: props.customerAddress }}

        let orderContainer = {deliverDate: props.checkoutForm.deliverDate.value , deliverTime: props.checkoutForm.deliverTime.value, notes: props.checkoutForm.notes.value }

        let productContainer = cartDetails
        for(let i = 0; i < cartDetails.length; i++){
            let container = cartDetails[i]
            let productName = cartDetails[i].product.name
            let productId = cartDetails[i].product.docId
            let tableData = cartDetails[i].product.tableData
            container = {...container, product: productId, productName: productName, tableData: tableData}
            productContainer.splice(i, 1, container)
        }
        let totalContainer = calcActualTotal()
        let deliveryContainer = customDelivery !== "" ? Number(customDelivery) : Number(cartFeeDetails.shipping)
        let couponAppliedContainer = coupons[coupons.findIndex(coupon => coupon.code === couponApplied)] ? coupons[coupons.findIndex(coupon => coupon.code === couponApplied)] : {}
        let salesApplicableContainer = props.coupons.sales ? props.coupons.sales.map(sale => {return sale.docId}) : [];
        let subtotalContainer = customTotal !== "" ? Number(customTotal) : Number(props.cartFees.subTotal)
        let couponContainer = customDiscount !== "" ? !discountTypePercent ? Number(customDiscount) : Number(subtotalContainer) * (Number(customDiscount) / Number(100)) :  Number(cartFeeDetails.coupons)
        let discSubTotalContainer = (Number(subtotalContainer) - Number(couponContainer))
        let fuelSurchargeContainer = customDelivery !== "" ? Number(0) : Number(props.cartFees.fuelSurcharge || 0)
        let cartTaxesContainer = customTotal !== "" ? {} : props.cartFees.cartTaxes
        let shippingWithoutFuelSurchargeContainer = customDelivery !== "" ? Number(0) : Number(props.cartFees.shippingWithoutFuelSurcharge || 0)
        let cartFeesContainer = {...props.cartFees, total: totalContainer, shipping: deliveryContainer, fuelSurcharge: fuelSurchargeContainer, coupons: couponContainer, shippingWithoutFuelSurcharge: shippingWithoutFuelSurchargeContainer, 
                                couponCode: couponAppliedContainer, salesApplicable: salesApplicableContainer, appliedDiscountCodes: props.appliedDiscountCodes, subTotal: subtotalContainer, discSubTotal: discSubTotalContainer, cartTaxes: cartTaxesContainer}

        orderContainer = {...orderContainer, status: 'PROCESSING', fees: cartFeesContainer, products: productContainer}

        console.log('orderContainer', orderContainer)
        
        setSubmitLoading(true)
        handleDeleteDraft(draftId)
        createPaymentConverge(payment, customerContainer, orderContainer)
        setTimeout(() => {
            resetPage()
            setSubmitLoading(false)
        }, 1000)
    }

    const handleSaveDraft = () => {
        setProcessing(true)
        let container = {
            orderDetails: {deliverDate: props.checkoutForm.deliverDate.value, deliverTime: props.checkoutForm.deliverTime.value, notes: props.checkoutForm.notes.value },
            deliveryDetails: props.customerAddress !== "" 
            ? {destination: {coordinates: {lat: props.customerLocation.lat, lng: props.customerLocation.lng}, location: props.customerAddress }}
            : {},
            customerDetails: customerDetails,
            showAddProducts: showAddProducts,
            discountTypePercent: discountTypePercent,
            customTotal: customTotal,
            customDelivery: customDelivery,
            customDiscount: customDiscount,
            cartDetails: cartDetails,
            uid: currentUser.uid,
            createdAt: moment().format('YYYY-MM-DD').valueOf()
        }

        if(draftId === undefined){
            firestore.collection('orderDrafts').add(container)
            .then(function(docRef) {
                setDraftId(docRef.id)
                let userDraftsContainer = _.cloneDeep(draftData)
                userDraftsContainer.push({...container, docId: docRef.id})
                setDraftData(userDraftsContainer)
                setTimeout(() => { setProcessing(false)  }, 200)
            })
            .catch((e) => {
                console.warn(e)
                setTimeout(() => { setProcessing(false)  }, 200)
            });
        }
        else{
            firestore.collection('orderDrafts').doc(draftId).set(container, {merge: true})
                .then(() => {
                    let userDraftsContainer = _.cloneDeep(draftData)
                    userDraftsContainer.splice(draftData.findIndex(draft => draft.docId === draftId), 1, {...container, docId: draftId})
                    setDraftData([...userDraftsContainer])
                    setTimeout(() => { setProcessing(false)  }, 200)
                })
            .catch((e) => {
                console.warn(e)
                setTimeout(() => { setProcessing(false)  }, 200)
            });
        }
    }

    const handleLoadDraft = async (docId, drafts) => {
        setSubmitLoading(true)
        resetPage()

        let draft = drafts.filter(draft => draft.docId === docId)[0]
        setDraftId(docId)
        sessionStorage.setItem('draftIdNewOrder', JSON.stringify(docId));
        setCustomTotal(draft.customTotal)
        sessionStorage.setItem('customTotalNewOrder', JSON.stringify(draft.customTotal));
        setCustomDelivery(draft.customDelivery)
        sessionStorage.setItem('customDeliveryNewOrder', JSON.stringify(draft.customDelivery));
        setCustomDiscount(draft.customDiscount)
        sessionStorage.setItem('customDiscountNewOrder', JSON.stringify(draft.customDiscount));
        setDiscountTypePercent(draft.discountTypePercent)
        sessionStorage.setItem('discountTypeNewOrder', JSON.stringify(draft.discountTypePercent));
        setShowAddProducts(draft.showAddProducts)
        sessionStorage.setItem('customShowAddProducts', JSON.stringify(draft.showAddProducts));
        setCustomerDetails(draft.customerDetails)
        sessionStorage.setItem('cutsomerDetailsNewOrder', JSON.stringify(draft.customerDetails));
        setCartDetails(draft.cartDetails)

        draft.cartDetails.forEach(async product => {
            if(!_.isUndefined(props.addToCart)){
                let container;
                if(product.variant.inStock === false){ container = product.variant;container = {...container, inStock: true}; }
                props.addToCart(product.product, product.variant.inStock === false ? container : product.variant, product.qty, product.shippingMethod, product.price);
            }
        });

        if(!_.isEmpty(draft.deliveryDetails)){ 
            props.setDistance(draft.deliveryDetails.destination.coordinates, "INPUT_SEARCHBOX", draft.deliveryDetails.destination.location) 
        }

        props.updateCheckoutForm({
            ...props.checkoutForm,
            deliverDate: {value:  moment(draft.orderDetails.deliverDate).format('YYYY-MM-DD')},
            deliverTime: {value: draft.orderDetails.deliverTime},
            notes: {value: draft.orderDetails.notes }
       });
       
       setTimeout(() => { setSubmitLoading(false) }, 500)
    }

    const handleDeleteDraft = (docId) => {
        firestore.collection('orderDrafts').doc(docId).delete()
        .then(() => {
            let userDraftsContainer = _.cloneDeep(draftData)
            userDraftsContainer.splice(draftData.findIndex(draft => draft.docId === docId), 1)
            setDraftData([...userDraftsContainer])
            setDeletingDraft({})
        })
        .catch((e) => {
            console.warn(e)
        });
    }

    const setGiftCard = (event) => {
        event.preventDefault()
        props.setGiftCard(event.target[0].value.toUpperCase())
    }

    let payForm = null;
    //let completeForm = null;

    payForm = (
        <Grid container >
            {(customTotal === "" ? cartFeeDetails.total : customTotal) > 50000 && <Grid item xs={12}>
                <Typography style={{ color: 'red' }} align='center'>
                    Caution: Orders over $50,000 currently cannot be processed online.
                </Typography>
            </Grid>}
            <Grid item xs={12} sm={12} md={12} lg={12}>
                <form 
                    onSubmit={(event) => { 
                        if(Number(props.cartFees.total.toFixed(2) - Number(props.cartFees.giftCardApplied).toFixed(2)) > 0){
                            generateToken(event) 
                        }else if(Number(props.cartFees.total.toFixed(2) - Number(props.cartFees.giftCardApplied).toFixed(2)) === 0){
                            onSuccessfulPayment(null, true)
                        }else{
                            throw new Error('Total is a negative number!');
                        }
                    }}
                >
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Button
                                disabled={(customerDetails.firstName === '' || customerDetails.lastName === '' 
                                    || customerDetails.email === '' || customerDetails.phone === '' || customerDetails.addressLines[0] === '' 
                                    || customerDetails.city === '' || customerDetails.country === '' || customerDetails.postalCode === '' 
                                    || customerDetails.province === '') || processing }
                                variant='contained'
                                fullWidth
                                size='large'
                                type='submit'>
                                {processing ? "Processing..." : "Pay & Submit Order"}
                            </Button>
                        </Grid>
                    </Grid>

                    {checkoutError.map((msg) => {
                        return(
                            <Typography style={{ color: 'red' }}>{msg} **</Typography>
                        )
                    })}

                    <Typography style={{ color: 'red' }}>{props.paymentFailed ? "There was an error regarding your payment, please try again." : null}</Typography>
                </form>

            </Grid>
        </Grid>
    )

    return (
        (showMap && isLoaded) ?
            <Grid container>
                <Paper>
                    {
                    React.cloneElement(Map,
                        {
                            setNextAvailableDeliverDate: props.setNextAvailableDeliverDate,
                            isCustomOrder: smallScreen ? true : false,
                            alertActive: props.alertActive,
                            alertMsg: props.alertMsg,
                            alertSev: props.alertSev,
                            validLocation: props.validLocation,
                            validAddress: props.validAddress,
                            validDeliveryDate: props.validDeliveryDate,
                            address: props.customerAddress,
                            searchboxCoordinates: props.searchboxCoordinates,
                            coordinates: props.customerLocation,
                            width: "100%",
                            height: "100%",
                            pickupOrder: props.pickupOrder,
                            error: props.error,
                            errorMsg: props.errorMsg,
                            inputChangedHandler: inputChangedHandler,
                            inputs: formElementsArray,
                            createInput: createInput,
                            updateCoordinates: props.setDistance,
                    
                            handleBack: () => setShowMap(false),
                            handleClose: () => setShowMap(false),
                        })
                    }
                </Paper>
            </Grid>
        :
        submitLoading 
        ? 
        <Grid container style={{ justifyContent: 'center' }}>
            <Skeleton variant='rect' width={'46vw'} height={'250vh'} style={{marginTop: '65px', borderRadius: '5px', alignItems: 'center'}}></Skeleton> 
        </Grid>
        : 
        <Grid container style={{ justifyContent: 'center' }}>
            <Grid item xl={6} lg={12} xs={12}>
                <Button variant={'contained'} style={{ marginLeft: '15px', marginTop: '15px' }} startIcon={<KeyboardBackspaceIcon />} onClick={() => props.history.goBack()}>
                    Back</Button>
                <Paper style={{ padding: '16px', margin: '16px', color: '#707070', width:'100%', marginBottom: '125px' }}>
                    <Grid container spacing={2}>
                        <Grid container >
                            <Grid item xs={5}>
                                <PaperTitle loading={false} title={draftId !== undefined ? "New Order (Quote)" : "New Order"} width='100px' />
                            </Grid>

                            <Grid item xs={7}>
                                <Grid container justifyContent='flex-end' >
                                    <Button
                                        variant='contained'
                                        onClick={(e)=> { resetPage() }}
                                        style={smallScreen ? {marginRight: '0px', width: "125px"} : {marginRight: '15px',  width: "125px"}}
                                        size='small'
                                        disabled={processing}
                                    >
                                         New Order
                                    </Button>

                                    <Button
                                        variant='contained'
                                        onClick={(e)=> { setLoadDraftModal(true) }}
                                        style={smallScreen ? {marginRight: '0px', width: "125px"} : {marginRight: '15px', width: "125px"}}
                                        size='small'
                                        disabled={processing}
                                    >
                                         Load Quote
                                    </Button>
                             
                                    <Button
                                        variant='contained'
                                        onClick={(e)=> { handleSaveDraft() }}
                                        style={{ width: "125px"}}
                                        size='small'
                                        disabled={processing}
                                    >   {processing
                                        ? "Saving Quote"
                                        : draftId !== undefined ? "Update Quote" : "Save Quote"
                                        }
                                    </Button>
                                </Grid>
                            </Grid>
                        </Grid>
                    

                        <Grid item md={6} xs={12}>
                            <Paper elevation={2} style={{ padding: "16px", margin: "4px", color: '#707070', height:'100%' , width:'100%'  }}>
                                <Grid container spacing={2}>

                                    <Grid container>
                                        <Grid item xs={12}>
                                            <Typography style={{ lineHeight: 3 }} noWrap variant='h6'>Customer Details</Typography>
                                        </Grid>
                                        <Grid item xs={5} >
                                            <Typography style={{ lineHeight: 3, marginTop: '8px' }} noWrap variant='body1'>Name:</Typography>
                                        </Grid>
                                        <Grid item xs={7}>
                                            <TextField style={{ lineHeight: 3, width: '225px', marginRight:'10px', }} 
                                                align='right' label="First Name" 
                                                required
                                                value={customerDetails.givenName}
                                                onChange={(event) => {
                                                    handleFieldChange(event, 'givenName', 'customerDetails');
                                                }}
                                            ></TextField>
                                            <TextField 
                                                style={{ lineHeight: 3, width: '225px', marginTop: '8px', marginBottom: '8px'  }} align='right' label="Last Name"
                                                value={customerDetails.familyName}
                                                required
                                                onChange={(event) => {
                                                    handleFieldChange(event, 'familyName', 'customerDetails')
                                                }}
                                            ></TextField>
                                        </Grid>

                                        <Grid item xs={5} >
                                            <Typography style={{ lineHeight: 3, marginTop: '8px' }} noWrap variant='body1'>Email:</Typography>
                                        </Grid>
                                        <Grid item xs={7}>
                                            <TextField  style={{ lineHeight: 3, width: '225px', }} 
                                                align='right' label="Email Address"
                                                required
                                                value={customerDetails.email}
                                                onChange={(event) => {
                                                    handleFieldChange(event, 'email', 'customerDetails')
                                                }}
                                            ></TextField>
                                        </Grid>

                                        <Grid item xs={5} >
                                            <Typography style={{ lineHeight: 3, marginTop: '8px' }} noWrap variant='body1'>Phone:</Typography>
                                        </Grid>
                                        <Grid item xs={7}>
                                            <TextField  style={{ lineHeight: 3, width: '225px',  }} 
                                                align='right' label="Phone Number"
                                                value={customerDetails.phone}
                                                required
                                                onChange={(event) => {
                                                    handleFieldChange(event, 'phone', 'customerDetails')
                                                }}
                                            ></TextField>
                                        </Grid>

                                        <Grid item xs={5} >
                                            <Typography style={{ lineHeight: 3, marginTop: '8px' }} noWrap variant='body1'>Address:</Typography>
                                        </Grid>
                                        <Grid item xs={7}>

                                        <Grid item xs={12}>
                                            <TextField style={{ lineHeight: 3, width: '225px' }} 
                                                align='right' label="Street Address"
                                                value={customerDetails.addressLines[0]}
                                                required
                                                onChange={(event) => {
                                                    handleFieldChange(event, 'addressLines', 'customerDetails')
                                                    //deliveryCheckbox && handleFieldChange(event, 'address', 'deliveryDetails')
                                                }}
                                                // onBlur={(event) => {
                                                //     deliveryCheckbox && handleSetDistance()
                                                // }}
                                            ></TextField>
                                        </Grid>
                                        
                                            <Grid container>
                                                <Grid item xs={9} >
                                                    <TextField style={{ lineHeight: 3, width: '164px', marginTop: '8px', }}
                                                        align='right' label="City"
                                                        value={customerDetails.city}
                                                        required
                                                        onChange={(event) => {
                                                            handleFieldChange(event, 'city', 'customerDetails')
                                                            //deliveryCheckbox && handleFieldChange(event, 'city', 'deliveryDetails')
                                                        }}
                                                        // onBlur={(event) => {
                                                        //     deliveryCheckbox && handleSetDistance()
                                                        // }}
                                                    ></TextField>
                                                </Grid>
                                                <Grid item xs={3}>
                                                    <Select
                                                    style={{ lineHeight: 2.15, width: '54px', marginTop: '8px', }}
                                                        value={customerDetails.province}
                                                        required
                                                        onChange={(event) => {
                                                            handleFieldChange(event, 'province', 'customerDetails')
                                                            //deliveryCheckbox && handleFieldChange(event, 'province', 'deliveryDetails')
                                                        }}
                                                        // onBlur={(event) => {
                                                        //     deliveryCheckbox && handleSetDistance()
                                                        // }}
                                                    >
                                                        <MenuItem value='MB'>MB</MenuItem>
                                                        <MenuItem value='AB'>AB</MenuItem>
                                                        <MenuItem value='ON'>ON</MenuItem>
                                                        <MenuItem value='QC'>QC</MenuItem>
                                                        <MenuItem value='SK'>SK</MenuItem>
                                                        <MenuItem value='BC'>BC</MenuItem>
                                                        <MenuItem value='YT'>YT</MenuItem>
                                                        <MenuItem value='NT'>NT</MenuItem>
                                                        <MenuItem value='NU'>NU</MenuItem>
                                                        <MenuItem value='NB'>NB</MenuItem>
                                                        <MenuItem value='NS'>NS</MenuItem>
                                                        <MenuItem value='PE'>PE</MenuItem>
                                                        <MenuItem value='NL'>NL</MenuItem>
                                                    </Select>
                                                </Grid>
                                            </Grid>
                                                
                                            <Grid item xs={12}>
                                                <TextField style={{ lineHeight: 3, width: '225px', marginTop: '8px', }}
                                                    align='right' label="Postal Code"
                                                    value={customerDetails.postalCode}
                                                    required
                                                    onChange={(event) => {
                                                        handleFieldChange(event, 'postalCode', 'customerDetails')
                                                        //deliveryCheckbox && handleFieldChange(event, 'postalCode', 'deliveryDetails')
                                                    }}
                                                    // onBlur={(event) => {
                                                    //     deliveryCheckbox && handleSetDistance()
                                                    // }}
                                                ></TextField>
                                            </Grid>

                                            <Grid item xs={12}>
                                                <TextField style={{ lineHeight: 3, width: '225px', marginTop: '8px', }} 
                                                    align='right' label="Country"
                                                    value={customerDetails.country}
                                                    required
                                                    onChange={(event) => {
                                                        handleFieldChange(event, 'country', 'customerDetails')
                                                        //deliveryCheckbox && handleFieldChange(event, 'country', 'deliveryDetails')
                                                    }}
                                                    // onBlur={(event) => {
                                                    //     deliveryCheckbox && handleSetDistance()
                                                    // }}
                                                ></TextField>
                                            </Grid>

                                        </Grid>

                                    </Grid>
                                </Grid>
                            </Paper>
                        </Grid>

                        <Grid item md={6} xs={12}>
                            <Paper elevation={2} style={{ padding: "16px", margin: "4px", color: '#707070', height:'100%', width:'100%',  }}>
                                <Grid container spacing={2}>
                                    <Grid container>
                                        <Grid item xs={12}>
                                            <Typography style={{ lineHeight: 3 }} noWrap variant='h6'>Delivery Details</Typography>
                                        </Grid>
                                    </Grid>

                                    <Grid container>
                                        <Grid item xs={6}> 
                                            <Typography
                                                style={{ lineHeight: 3 }}
                                            >
                                                Delivery Details:
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={6}> 
                                            <Button
                                                variant='contained'
                                                style={{ marginTop: '5px', minWidth: '75px'}}
                                                onClick={()=> { setShowMap(true) }}
                                            >
                                                {props.customerAddress ? 'Change' : 'Add'} 
                                            </Button>
                                        </Grid>
                                    </Grid>

                                    <Grid container>
                                        <Grid item>
                                            <Typography style={{ lineHeight: 3, color: 'green' }}><small>{props.customerAddress ? `Delivering to ${props.customerAddress}` : null}</small></Typography>
                                        </Grid>
                                    </Grid>

                                    <Grid container>
                                        <Grid item>
                                            <Typography style={{ lineHeight: 1, color: 'green' }}>
                                                <small>{props.customerAddress && props.checkoutForm.deliverDate && props.checkoutForm.deliverTime ?  `On ${props.checkoutForm.deliverDate.value} in the ${props.checkoutForm.deliverTime.value}` : null}</small>
                                            </Typography>
                                        </Grid>
                                    </Grid>

                                </Grid>
                            </Paper>
                        </Grid>

                        <Grid item md={12} xs={12}>
                            <Paper elevation={2} style={{ padding: "16px", margin: "4px", color: '#707070', width:'100%' }}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <Typography style={{ lineHeight: 2 }} noWrap variant='h6'>Order Details</Typography>
                                    </Grid>
                                </Grid>

                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <TextField  style={{ lineHeight: 3, width:'100%' }} 
                                            label="Notes"
                                            multiline
                                            placeholder='Enter any notes here...'
                                            minRows={3}
                                            maxRows={6}
                                            variant="outlined"
                                            value={props.checkoutForm.notes.value } 
                                            onChange={(event) => { inputChangedHandler(event, 'notes') }}
                                        >
                                        </TextField>
                                    </Grid>
                                </Grid>

                                <Grid container spacing={2}>
                                    <Grid item xs={3}>
                                        <Typography style={{marginTop: '12px'}}>Add Products </Typography>
                                    </Grid>
                                    <Grid item xs={9}>
                                        <Checkbox
                                            checked={showAddProducts}
                                            onClick={() => { handleShowAddProducts() }}
                                        ></Checkbox>
                                    </Grid>
                                </Grid>

                            </Paper>
                        </Grid>

                        { showAddProducts ?
                        <Grid item md={12} xs={12}>
                            <Grid  item md={12} xs={12} style={{marginBottom: '20px'}}>
                                <Paper elevation={2} style={{ padding: "16px", margin: "4px", color: '#707070', width:'100%' }}>
                                    
                                    <Grid container spacing={2}>
                                        <Grid item xs={12}>
                                            <Typography style={{ lineHeight: 2 }} noWrap variant='h6'>Delivery Items</Typography>
                                        </Grid>

                                        <Grid item xs={12}>
                                            <AddProduct 
                                                products={products}
                                                loading={loading}
                                                type={'Delivery'}
                                                cartDetails={cartDetails}
                                                setCartDetails={setCartDetails}
                                                addToCart={props.addToCart}
                                            >
                                            </AddProduct>
                                        </Grid>

                                        <Grid item xs={12}>
                                            {cartDetails.filter( product => product.shippingMethod === 'Delivery').length > 0 ?
                                            <Grid>
                                            <Divider/>
                                                <Grid container style={{margin: '10px'}}>
                                                    <Grid item xs={2}>
                                                        <Typography style={{fontWeight: '550', fontSize: '16px'}} >Product</Typography>
                                                    </Grid>
                                                    <Grid item xs={2}>
                                                        <Typography style={{fontWeight: '550', fontSize: '16px'}}>Type</Typography>
                                                    </Grid>
                                                    <Grid item xs={2}>
                                                        <Typography style={{fontWeight: '550', fontSize: '16px'}}>Quantity</Typography>
                                                    </Grid>
                                                    <Grid item xs={2}>
                                                        <Typography style={{fontWeight: '550', fontSize: '16px'}}>Unit</Typography>
                                                    </Grid>
                                                    <Grid item xs={2}>
                                                        <Typography style={{fontWeight: '550', fontSize: '16px'}}>Unit Price</Typography>
                                                    </Grid>
                                                    <Grid item xs={2}>
                                                        <Typography style={{fontWeight: '550', fontSize: '16px'}}>Total Price</Typography>
                                                    </Grid>
                                                    <Grid item xs={2}/>
                                                </Grid>
                                            </Grid>
                                            : null
                                            }

                                            {cartDetails.filter( product => product.shippingMethod === 'Delivery').map((delivery, index) => {
                                                return(
                                                    <Grid container key={index} style={{margin: '10px'}}>
                                                        <Grid item xs={2}>
                                                            <Typography style={{fontSize: '14px'}}>{delivery.product.name}</Typography>
                                                        </Grid>
                                                        <Grid item xs={2}>
                                                            <Typography style={{fontSize: '14px'}}>{Object.values(delivery.variant.variantTypes).sort().join(' ')}</Typography>
                                                        </Grid>
                                                        <Grid item xs={2}>
                                                            <Typography style={{fontSize: '14px'}}>{delivery.qty}</Typography>
                                                        </Grid>
                                                        <Grid item xs={2}>
                                                            <Typography style={{fontSize: '14px'}}>{delivery.price?.unit}</Typography>
                                                        </Grid>
                                                        <Grid item xs={2}>
                                                            <Typography style={{fontSize: '14px'}}>${delivery.price?.price}</Typography>
                                                        </Grid>
                                                        <Grid item xs={1}>
                                                            <Typography style={{fontSize: '14px'}}>
                                                                ${((delivery.price?.price) * (delivery.qty)).toFixed(2)}
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={1}>
                                                            <IconButton 
                                                                style={{position: 'relative', bottom: '10px'}}
                                                                onClick={(event) => { 
                                                                    let deleteContainer = deleteData
                                                                    deleteContainer.push(cartDetails.indexOf(delivery))
                                                                    setDeleteData([...deleteContainer])
                                                                }}>
                                                                <DeleteIcon fontSize='small'/>
                                                            </IconButton>
                                                        </Grid>
                                                    </Grid>
                                                )
                                            })}
                                        </Grid>
                                        
                                    </Grid>
                                </Paper>
                            </Grid>

                            <Grid  item md={12} xs={12}>
                                <Paper elevation={2} style={{ padding: "16px", margin: "4px", color: '#707070', width:'100%' }}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12}>
                                            <Typography style={{ lineHeight: 2 }} noWrap variant='h6'>Pickup Items</Typography>
                                        </Grid>

                                        <Grid item xs={12}>
                                            <AddProduct 
                                                products={products}
                                                loading={loading}
                                                type={'Pickup'}
                                                cartDetails={cartDetails}
                                                setCartDetails={setCartDetails}
                                                addToCart={props.addToCart}
                                            >
                                            </AddProduct>
                                        </Grid>

                                        <Grid item xs={12}>
                                            {cartDetails.filter( product => product.shippingMethod === 'Pickup').length > 0 ?
                                            <Grid>
                                            <Divider/>
                                                <Grid container style={{margin: '10px'}}>
                                                    <Grid item xs={2}>
                                                        <Typography style={{fontWeight: '550', fontSize: '16px'}} >Product</Typography>
                                                    </Grid>
                                                    <Grid item xs={2}>
                                                        <Typography style={{fontWeight: '550', fontSize: '16px'}}>Type</Typography>
                                                    </Grid>
                                                    <Grid item xs={2}>
                                                        <Typography style={{fontWeight: '550', fontSize: '16px'}}>Quantity</Typography>
                                                    </Grid>
                                                    <Grid item xs={2}>
                                                        <Typography style={{fontWeight: '550', fontSize: '16px'}}>Unit</Typography>
                                                    </Grid>
                                                    <Grid item xs={2}>
                                                        <Typography style={{fontWeight: '550', fontSize: '16px'}}>Unit Price</Typography>
                                                    </Grid>
                                                    <Grid item xs={2}>
                                                        <Typography style={{fontWeight: '550', fontSize: '16px'}}>Total Price</Typography>
                                                    </Grid>
                                                    <Grid item xs={2}/>
                                                </Grid>
                                            </Grid>
                                            : null
                                            }
                                                
                                            {cartDetails.filter( product => product.shippingMethod === 'Pickup').map((pickup, index) => {
                                                return(
                                                    <Grid container key={index} style={{margin: '10px'}}>
                                                        <Grid item xs={2}>
                                                            <Typography style={{fontSize: '14px'}}>{pickup.product.name}</Typography>
                                                        </Grid>
                                                        <Grid item xs={2}>
                                                            <Typography style={{fontSize: '14px'}}>{Object.values(pickup.variant?.variantTypes).sort().join(' ')}</Typography>
                                                        </Grid>
                                                        <Grid item xs={2}>
                                                            <Typography style={{fontSize: '14px'}}>{pickup.qty}</Typography>
                                                        </Grid>
                                                        <Grid item xs={2}>
                                                            <Typography style={{fontSize: '14px'}}>{pickup.price?.unit}</Typography>
                                                        </Grid>
                                                        <Grid item xs={2}>
                                                            <Typography style={{fontSize: '14px'}}>${pickup.price?.price}</Typography>
                                                        </Grid>
                                                        <Grid item xs={1}>
                                                            <Typography style={{fontSize: '14px'}}>
                                                                ${((pickup.price?.price) * (pickup.qty)).toFixed(2)}
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={1}>
                                                            <IconButton 
                                                                style={{position: 'relative', bottom: '10px'}}
                                                                onClick={(event) => { 
                                                                    let deleteContainer = deleteData
                                                                    deleteContainer.push(cartDetails.indexOf(pickup))
                                                                    setDeleteData([...deleteContainer])
                                                                }}>
                                                                <DeleteIcon fontSize='small'/>
                                                            </IconButton>
                                                        </Grid>
                                                    </Grid>
                                                )
                                            })}
                                        </Grid>
                                    </Grid>
                                </Paper>
                            </Grid>
                        </Grid>
                        : null
                        }

                        <Grid item md={6} xs={12}>
                            <Paper>
                                <form onSubmit={setGiftCard}>
                                    <Grid container>
                                        <Grid item sm={12} md={9}>
                                            <TextField
                                                style={{margin: '5px'}}
                                                error={!_.isEmpty(props.giftCards.cardError)}
                                                helperText={props.giftCards.cardError ? "Gift Card not found" : ""}
                                                variant='outlined'
                                                label='Enter your Gift Card # here'
                                                size='small'
                                                fullWidth
                                            />
                                        </Grid>
                                        <Grid item sm={12} md={3}>
                                            <Grid container justifyContent='flex-end' >
                                                <Button 
                                                    style={{margin: '5px'}} 
                                                    variant='contained' 
                                                    type='submit' 
                                                    value='Submit' 
                                                >
                                                    Add
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </form>
                            </Paper>
                        </Grid>

                        <Grid item md={6} xs={12}>
                            <Paper >
                                {(!_.isEmpty(props.giftCards) && props.giftCards.cardList.length > 0) &&
                                    <div className={classes.couponActive}>
                                        <Alert>
                                            {props.giftCards.cardList?.map((card) => {
                                                return(
                                                    <React.Fragment>
                                                        <Typography>{card?.code?.toUpperCase()} - ${card?.currentValue?.toFixed(2)}</Typography>
                                                    </React.Fragment>
                                                )
                                            })}
                                        </Alert>
                                    </div>
                                }
                            </Paper>
                        </Grid>

                        <Grid item md={6} xs={12}>
            
                            <Paper elevation={2} style={{ padding: "16px", margin: "8px", color: '#707070' }}>
                                <Typography variant='h6' >Costs</Typography>

                                <Grid container spacing={2} >
                                    <Grid direction='column' item xs={5}>
                                        <Grid>
                                            {(cartFeeDetails.subTotal > 0 && customTotal === "") && <Typography style={{ lineHeight: 2 }} variant='body1'>Material:</Typography>}
                                            {(cartFeeDetails.coupons > 0 && customDiscount === "") && <Typography style={{ lineHeight: 2 }} variant='body1'>Discount:</Typography>}
                                            {(cartFeeDetails.shippingWithoutFuelSurcharge > 0 && customDelivery === "") && <Typography style={{ lineHeight: 2 }} variant='body1'>Shipping:</Typography>}
                                            {(cartFeeDetails.fuelSurcharge > 0 && customDelivery === "") && <Typography style={{ lineHeight: 2}} variant='body1'>Fuel Surcharge:</Typography>}
                                            {(cartFeeDetails.cartTaxes['Carbon Fees'] > 0 && customTotal === "") && <Typography style={{ lineHeight: 2 }} variant='body1'>Carbon Fee:</Typography>}
                                            {(cartFeeDetails.cartTaxes['GST'] > 0 && customTotal === "") && <Typography style={{ lineHeight: 2 }} variant='body1'>GST:</Typography>}
                                            {(cartFeeDetails.cartTaxes['PST'] > 0 && customTotal === "") && <Typography style={{ lineHeight: 2 }} variant='body1'>PST:</Typography>}
                                            {cartFeeDetails.palletFee && <Typography style={{ lineHeight: 2 }} variant='body1'>Pallet Fee:</Typography>}

                                            {/* <Typography style={{ lineHeight: 2 }} variant='body1'>Estimated Total:</Typography> */}

                                        </Grid>
                                        <Grid style={{marginTop: '20px'}}>
                                            <Typography style={{ lineHeight: 2.5, fontWeight: 'bold'  }} variant='body1'>Custom Total:</Typography>
                                            <Typography style={{ lineHeight: 2.5, fontWeight: 'bold'  }} variant='body1'>Custom Shipping:</Typography>
                                            <Typography style={{ lineHeight: 2.5, fontWeight: 'bold'  }} variant='body1'>Custom Discount:</Typography>
                                            {props.cartFees.giftCardApplied > 0 &&
                                                <React.Fragment>
                                                    <Typography style={{ lineHeight: 2.5, fontWeight: 'bold', marginTop: '20px'}} variant='body1'>Gift Cards Applied:</Typography>
                                                    <Typography style={{ lineHeight: 2.5, fontWeight: 'bold',}} variant='body1'>Gift Cards Remains:</Typography>
                                                </React.Fragment>
                                            }
                                            <Typography style={{ lineHeight: 2.5, fontWeight: 'bold', marginTop: '20px'}} variant='body1'>Total:</Typography>
                                        </Grid>
                                    </Grid>
                                                      
                                    <Grid direction='column' item xs={7}> 
                                        <Grid>
                                            {(cartFeeDetails.subTotal > 0 && customTotal === "") && <Typography align={"right"} style={{ lineHeight: 2 }} variant='body1'>${cartFeeDetails.subTotal.toFixed(2)}</Typography>} {/* Material */}
                                            {(cartFeeDetails.coupons > 0 && customDiscount === "") && <Typography align={"right"} style={{ lineHeight: 2 }} variant='body1'>${cartFeeDetails.coupons.toFixed(2)}</Typography>} {/* Discounts */}
                                            {(cartFeeDetails.shippingWithoutFuelSurcharge > 0 && customDelivery === "") && <Typography align={"right"} style={{ lineHeight: 2 }} variant='body1'>${(cartFeeDetails.shippingWithoutFuelSurcharge).toFixed(2)}</Typography>} {/* Shipping */}
                                            {(cartFeeDetails.fuelSurcharge > 0 && customDelivery === "") && <Typography align={"right"} style={{ lineHeight: 2 }} variant='body1'>${cartFeeDetails.fuelSurcharge.toFixed(2)}</Typography>}
                                            {(cartFeeDetails.cartTaxes['Carbon Fees'] > 0 && customTotal === "") && <Typography align={"right"} style={{ lineHeight: 2 }} variant='body1'>${cartFeeDetails.cartTaxes['Carbon Fees'].toFixed(2)}</Typography>} {/* Carbonfee */}
                                            {(cartFeeDetails.cartTaxes['GST'] > 0 && customTotal === "") && <Typography align={"right"} style={{ lineHeight: 2 }} variant='body1'>${cartFeeDetails.cartTaxes?.GST.toFixed(2)}</Typography>} {/* GST */}
                                            {(cartFeeDetails.cartTaxes['PST'] > 0 && customTotal === "") && <Typography align={"right"} style={{ lineHeight: 2 }} variant='body1'>${cartFeeDetails.cartTaxes?.PST.toFixed(2)}</Typography>} {/* PST */}
                                            {cartFeeDetails.palletFee && <Typography align={"right"} style={{ lineHeight: 2 }} variant='body1'>${ cartFeeDetails.palletFee.totalFee.toFixed(2)}</Typography>} {/* Palletfee */}
                                        </Grid>
                                        
                                        <Grid container style={{marginTop: '20px'}} >
                                            <Grid container justifyContent="flex-end">
                                                <TextField  
                                                    style={{width: '105px',}}
                                                    variant='outlined'
                                                    InputProps={{
                                                        startAdornment: <InputAdornment position="start">$</InputAdornment>
                                                    }}
                                                    size='small' 
                                                    placeholder='0.00'
                                                    value={customTotal}
                                                    onChange={(event) => {
                                                        sessionStorage.setItem('customTotalNewOrder', JSON.stringify(event.target.value));
                                                        setCustomTotal(event.target.value)
                                                    }}
                                                />
                                            </Grid>
                                            <Grid container justifyContent="flex-end">
                                                <TextField   
                                                    style={{width: '105px', }}
                                                    variant='outlined'
                                                    InputProps={{
                                                        startAdornment: <InputAdornment position="start">$</InputAdornment>
                                                    }}
                                                    size='small'
                                                    placeholder='0.00'
                                                    value={customDelivery}
                                                    onChange={(event) => {
                                                        sessionStorage.setItem('customDeliveryNewOrder', JSON.stringify(event.target.value));
                                                        setCustomDelivery(event.target.value)
                                                    }}
                                                />
                                            <Grid container direction="row" justifyContent="flex-end">
                                                    <Typography style={{marginTop: '5px'}}><small>$</small></Typography>
                                                    <Switch 
                                                        style={{color: 'whitesmoke'}}
                                                        checked={discountTypePercent}
                                                        onClick={(event)=> { 
                                                            setDiscountTypePercent(event.target.checked)
                                                            setCustomDiscount('');  
                                                            sessionStorage.setItem('customDiscountNewOrder', JSON.stringify(''));
                                                            sessionStorage.setItem('discountTypeNewOrder', JSON.stringify(event.target.checked));
                                                        }}
                                                    />
                                                    <Typography style={{marginTop: '5px', marginRight: '2px'}}><small>%</small></Typography>
                                                    <TextField  
                                                        style={{width: '105px'}}
                                                        variant='outlined'
                                                        InputProps={{
                                                            startAdornment: <InputAdornment position="start">{!discountTypePercent ? '$' : '%'}</InputAdornment>
                                                        }}
                                                        size='small'
                                                        placeholder={!discountTypePercent ? '0.00' : '0'}
                                                        value={customDiscount}
                                                        onChange={(event) => {
                                                            sessionStorage.setItem('customDiscountNewOrder', JSON.stringify(event.target.value));
                                                            setCustomDiscount(event.target.value)
                                                        }}
                                                    />
                                            </Grid>

                                            {props.cartFees.giftCardApplied > 0 ?
                                                <React.Fragment>
                                                    <Grid container justifyContent="flex-end">
                                                        <Typography 
                                                            style={{ fontWeight: 'bold', lineHeight: 2.5, marginTop: '25px'}} 
                                                            variant='body1'
                                                            placeholder='0.00'
                                                        >
                                                                ${props.cartFees.giftCardApplied && props.cartFees.giftCardApplied.toFixed(2)}
                                                        </Typography>
                                                    </Grid>

                                                    <Grid container justifyContent="flex-end">
                                                        <Typography 
                                                            style={{ fontWeight: 'bold', lineHeight: 2.5,}} 
                                                            variant='body1'
                                                            placeholder='0.00'
                                                        >
                                                                ${props.cartFees.giftCardRemains && props.cartFees.giftCardRemains.toFixed(2)}
                                                        </Typography>
                                                    </Grid>
                                       
                                                    <Grid container justifyContent="flex-end">
                                                        <del>
                                                            <Typography 
                                                                style={{ lineHeight: 2.5, marginTop: '25px', marginRight: '5px'}} 
                                                                variant='body1'
                                                                placeholder='0.00'
                                                            >
                                                                    ${calcActualTotal()}
                                                            </Typography>
                                                        </del>

                                                        <Typography 
                                                            style={{ fontWeight: 'bold', lineHeight: 2.5, marginTop: '25px' }} 
                                                            variant='body1'
                                                            placeholder='0.00'
                                                        >
                                                                ${Number(calcActualTotal() - props.cartFees.giftCardApplied).toFixed(2)}
                                                        </Typography>

                                                    </Grid>
                                                </React.Fragment>
                                            :
                                                <Grid container justifyContent="flex-end">
                                                    <Typography 
                                                        style={{ fontWeight: 'bold', marginTop: '25px' }} 
                                                        variant='body1'
                                                        placeholder='0.00'
                                                    >
                                                            ${calcActualTotal()}
                                                    </Typography>
                                                </Grid>
                                            }
                                            
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>

                                { validationMessages.map((validation, index) => {
                                    return (
                                        <Grid key={index}>
                                            <Typography
                                            style={{bottom: ((index + 1) * 16) + 'px', fontSize: '12px', marginLeft: '25px', color: 'red'}}
                                            align={'right'}
                                            >
                                            {validation}
                                            </Typography>
                                        </Grid>
                                    )}
                                )} 
                            </Paper>
                        </Grid>
                    
                        <Grid item md={6} xs={12}>
                            <Paper elevation={2} style={{ padding: "16px", margin: "8px", color: '#707070' }}>
                                <Typography variant='h6' >Legend</Typography>
                                <Typography >Custom Total:</Typography>
                                <Typography style={{marginLeft: '5px'}}><small>Material</small></Typography>
                                <Typography style={{marginLeft: '5px'}}><small>Carbon Fee</small></Typography>
                                <Typography style={{marginLeft: '5px'}}><small>GST</small></Typography>
                                <Typography style={{marginLeft: '5px'}}><small>PST</small></Typography>

                                <Typography >Custom Shipping:</Typography>
                                <Typography style={{marginLeft: '5px'}}><small>Shipping</small></Typography>
                                <Typography style={{marginLeft: '5px'}}><small>Fuel Surcharge</small></Typography>

                                <Typography >Custom Discount:</Typography>
                                <Typography style={{marginLeft: '5px'}}><small>Discount</small></Typography>

                            </Paper>

                            <Grid >
                                {props.paymentComplete && (cartFeeDetails.shipping === 0) ? <CheckoutComplete /> : payForm}
                                <ProcessingDialog open={props.processingPayment} />
                            </Grid>
                        </Grid>

                    </Grid>
                </Paper>
            </Grid>

            {/* delete product modal */}
            <Modal
                open={deleteData.length > 0}
                onClose={() => setDeleteData([]) }
            >
                <Paper className={classes.deleteModal}>
                    <Grid style={{display: 'flex', justifyContent: 'center'}}>
                        <Typography
                         style={{ margin: '10px', display: 'flex', justifyContent: 'center' }}
                        >
                            Delete Product?</Typography>
                    </Grid>
                    <Grid style={{display: 'flex', justifyContent: 'center'}}>
                        <Button
                            variant={'contained'}
                            style={{margin: '10px', }}
                            onClick={() => {setDeleteData([])}}
                        >
                            Cancel
                        </Button>
                        <Button
                            variant={'contained'}
                            style={{margin: '10px', backgroundColor: 'red'}}
                            onClick={() => {handleDeleteProduct()}}
                        >
                            Confirm
                        </Button>
                    </Grid>
                </Paper>
            </Modal>

            {/* load draft modal */}
            <Modal
                open={loadDraftModal}
                onClose={() => { 
                    setDeletingDraft({});
                    setLoadDraftModal(false);
                 }}
            >
                <Paper className={classes.loadDraftModal}>
                    <Grid container>
                        <Grid item xs={6}>
                            <Typography variant='h6'> Quotes </Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <Grid container justifyContent='flex-end'>
                                <TextField 
                                    value={draftSearch} 
                                    placeholder='Search'
                                    onChange={(e)=> { setDraftSearch(e.target.value) }}
                                />
                            </Grid>
                        </Grid>
                    </Grid>

                    <Divider style={{marginTop: '10px', marginBottom: '10px'}}/>

                    <Grid container style={{overflowY: 'auto', maxHeight: '500px' }}>
                        {filteredUsersDrafts.map((draft, index) => {
                            return (
                            <Grid container key={index}>
                            {
                                deletingDraft.docId === draft.docId 
                                ?
                                <React.Fragment >
                                    <Grid item xs={8} >
                                        <Typography style={{marginTop: '5px'}}>Are you sure you want to delete this row?</Typography>
                                    </Grid>

                                    <Grid container justifyContent='flex-end' xs={4}>
                                        <Button
                                            size='small'
                                            onClick={(e)=>{
                                                handleDeleteDraft(draft.docId)
                                             }}
                                            variant='contained'
                                            style={{backgroundColor: 'red', color: 'white', marginBottom: '5px' }}
                                        >
                                            Yes
                                        </Button>
                                        <Button
                                            size='small'
                                            onClick={(e)=>{ setDeletingDraft({}) }}
                                            variant='contained'
                                            style={{ marginBottom: '5px' }}
                                        >
                                            No
                                        </Button>
                                    </Grid>
                                </React.Fragment>
                                :
                                <React.Fragment>
                                    <Grid item xs={5}>
                                        <Typography noWrap style={{marginTop: '10px', overflowX: 'hidden', textOverflow: 'ellipsis'}}>
                                            {`${draft.customerDetails.givenName} ${draft.customerDetails.familyName}`}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={3}>
                                        <Typography style={{marginTop: '10px', marginLeft: '10px'}}>
                                            {draft.createdAt}
                                        </Typography>
                                    </Grid>

                                    <Grid item xs={4}>
                                        <Grid container justifyContent='flex-end'>
                                            <IconButton 
                                                size="small" 
                                                onClick={() => { 
                                                    setLoadDraftModal(false)
                                                    handleLoadDraft(draft.docId, draftData) 
                                                }} 
                                                style={{marginRight: '15px', marginTop: '2px' }}
                                            >
                                                <OpenInNewIcon sx={{ fontSize: "18px" }} />
                                            </IconButton>

                                            <IconButton 
                                                size="small" 
                                                onClick={() => { setDeletingDraft(draft) }} 
                                                style={{color:'red', marginRight: '15px', marginTop: '2px'   }}
                                            >
                                                <DeleteIcon sx={{ fontSize: "18px" }} />
                                            </IconButton>
                                        </Grid>
                                    </Grid>
                                </React.Fragment>
                            }
                            </Grid>
                            )
                        })
                        }
                    </Grid>

                </Paper>
            </Modal>
            
        </Grid>
    )
}

//Map slice of global state to props
const mapStateToProps = (state) => {
    return {
        // state refers to global state/ redux state (acts somewhat like singleton) 
        // "products" is the name given in index.js to the state of our reducer
        // the props: featuredProducts and loading come from the reducer productsReducer.js
        cart: state.cart.cart, 
        cartFees: state.cart.cartFees,
        checkoutForm: state.checkout.checkoutForm, 
        coupons: state.cart.coupons, 
        appliedDiscountCodes: state.cart.appliedDiscountCodes,
        couponError: state.cart.couponError, 
        customerAddress: state.cart.customerAddress,
        customerLocation: state.cart.customerLocation,
        searchboxCoordinates: state.cart.searchboxCoordinates,
        distance: state.cart.distance,
        validLocation: state.cart.validLocation,
        validAddress: state.cart.validAddress, 
        validDeliveryDate: state.cart.validDeliveryDate,
        alertActive: state.cart.alertActive,
        alertMsg: state.cart.alertMsg,
        alertSev: state.cart.alertSev, 
        taxesLoading: state.cart.taxesLoading, 
        error: state.cart.error,
        errorMsg: state.cart.errorMsg,
        loading: state.checkout.loading,
        formSubmitted: state.checkout.formSubmitted,
        formValid: state.checkout.formValid,
        processingPayment: state.checkout.processingPayment,
        paymentComplete: state.checkout.paymentComplete,
        paymentFailed: state.checkout.paymentFailed,
        token: state.checkout.token,
        generateDisabled: state.checkout.generateDisabled,
        giftCards: state.cart.giftCards,
    };
}


//map our dispatch functions to props
const mapDispatchToProps = dispatch => {
    return {
        setSubtotal: () => dispatch(actionCreators.setSubtotal()),
        calcShipping: () => dispatch(actionCreators.calcShipping()),
        getTax: () => dispatch(actionCreators.getTax()),
        getSales: (saleList, include) => dispatch(actionCreators.getSales(saleList, include)),
        setDistance: (coordinates, inputType, address) => dispatch(actionCreators.setDistance(coordinates, inputType, address)),
        addToCart: (product, currentVariant, qty, shippingMethod, price) => dispatch(actionCreators.addToCart(product, currentVariant, qty, shippingMethod, price)),
        resetCart: () => dispatch(actionCreators.resetCart()),
        removeFromCart: (index) => dispatch(actionCreators.removeFromCart(index)),
        setCoupon: (couponCode) => dispatch(actionCreators.setCoupon(couponCode)),
        //removeCoupon: () => dispatch(actionCreators.removeCoupon()),

        //createPaymentConverge: (payment, customer, order) => dispatch(actionCreators.createPaymentConverge(payment, customer, order)),
        setPaymentFailed: (didPaymentFail) => dispatch(actionCreators.setPaymentFailed(didPaymentFail)),
        getCheckout: () => dispatch(actionCreators.getCheckout()),
        updateCheckoutForm: (formData) => dispatch(actionCreators.updateCheckoutForm(formData)),
        validateCheckout: () => dispatch(actionCreators.validateCheckout()),
        resetCheckoutForm: () => dispatch(actionCreators.resetCheckoutForm()),
        generateToken: (amount) => dispatch(actionCreators.generateToken(amount)),
        setToken: (token, valid) => dispatch(actionCreators.setToken(token, valid)),
        setNextAvailableDeliverDate: () => dispatch(actionCreators.setNextAvailableDeliverDate()),
        setGiftCard: (giftCardCode) => dispatch(actionCreators.setGiftCard(giftCardCode)),
    }
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(OrderNew));