import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../utility'

const initialState = {
    users: [],
    loading: false,
    added: false,
    userName: '',
    email: '',
    admin: false,
    open: false,
    user: null,
}

const usersReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.BEGIN:
            return updateObject(state, { loading: action.loading });
        case actionTypes.GET_USERS:
            return updateObject(state, { users: action.users, loading: action.loading });
        case actionTypes.ADD_USER:
            //TODO: ADD USER
            return updateObject(state, state);
        case actionTypes.UPDATE_USERNAME:
            return updateObject(state, { userName: action.userName });
        case actionTypes.UPDATE_EMAIL:
            return updateObject(state, { email: action.email });
        case actionTypes.UPDATE_ADMIN:
            return updateObject(state, { admin: true });
        case actionTypes.SET_OPEN:
            return updateObject(state, { open: action.open });
        case actionTypes.SET_USER:
            return updateObject(state, { user: action.user });
        default:
            return state;
    }
};

export default usersReducer;