import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import TimesheetTotalPDF from '../../../components/Reports/Timesheets/TimesheetTotalPDF'
import { Skeleton } from '@material-ui/lab';
import { useUser } from '../../../../providers/UserContext'
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    content: {
        display: 'flex',
        width: '100%',
        padding: '32px',
        justifyContent: 'center',
        marginTop: '40px',
    },
}));

function TimesheetTotalPrint(props) {
    const [timesheetData, setTimesheet] = useState({});
    const [dataLoading, setDataLoading] = useState(true);
    const [fullData, setFullData] = useState({})
    const {getUsers, users, usersLoading} = useUser();
    const [data, setData] = useState(JSON.parse(sessionStorage.getItem("userData")) || {})
    //const [savedData, setSavedData] = useState(JSON.parse(sessionStorage.getItem("savedData")) || {})

    const classes = useStyles();

    var userData = []
    const roundToTwo = (num) => {
        return +(Math.round(num + "e+2")  + "e-2");
    }

    useEffect(() => {
            getUsers();
      }, []) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        let data2 = props.location.state.data.sort((a, b) => { return a?.uid > b?.uid ? 1 : -1 })
        //data2.length > 0 ?? setSavedData(data2)
        data2.length > 0 ?? sessionStorage.setItem("savedData", JSON.stringify(data2))
        setTimesheet(data2)
        setFullData(props.location.state)
        
        handler(data2).then(()=>{
            setDataLoading(false)
        })
        
        
    // eslint-disable-next-line
    }, []);

    async function handler(input) {
        
        //console.log(timesheetData)
        users.map((user) => {
            var hours = 0.00
            input.filter(timesheet => timesheet.uid === user.displayName).map((sheet) => {
                return hours += roundToTwo(parseFloat(sheet.operatorHours));
            })
            return userData.push({ 'user': user.displayName, 'totalHours': Number(hours.toFixed(2)).toFixed(2)  })
        })

        setData(userData.sort((a, b) => { return a?.user?.toLowerCase() > b?.user?.toLowerCase() ? 1 : -1 }).filter(user => user.totalHours > 0))
        //setData(data.filter(user => user.totalHours > 0))
        sessionStorage.setItem("userData", JSON.stringify(userData))
    }

    return (
        dataLoading || usersLoading
            ? 
            <div className={classes.content}>
                <Skeleton variant='rect' width={'63vw'} height={'80vh'} />
            </div>
            : 
            <TimesheetTotalPDF
                timesheetData={timesheetData}
                fullData={fullData} userData={data}/>
    )
}

export default withRouter(TimesheetTotalPrint)