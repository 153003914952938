import React from 'react';
import { Button, Grid, Paper, makeStyles, TextField, Typography } from '@material-ui/core';
import _ from 'lodash';

const useStyles = makeStyles((theme) => ({

    root: {
        display: 'flex',
        justifyContent: 'center',
        width:'100%',
    },

    content: {
        display:'flex',
        justifyContent: 'center',
        margin: theme.spacing(2),
        flexGrow: 1,
        width:'100%',
    },

    header: {
        fontWeight:'700',
        fontSize: '1.25em',
        margin: '16px',
    },

    input: {
        flexGrow: 1,
        minWidth: 30,
        minHeight: 12,
        marginBottom: 2,
        paddingLeft: 2,
        paddingRight: 2,
    },

    paper: {
        display:'flex',
        flexDirection: 'column',
        alignItems: 'center',
        maxWidth: '800px',
        width: '100%',
        padding: '32px',
    },

    section: {
        display:'flex',
        alignContent: 'flex-end',
        flexWrap: 'wrap',
        marginBottom: 10,
        minHeight: 14,
        padding: 8,
        fontSize: '12pt',
        whiteSpace: 'pre-line',
    },

    title: {
        fontFamily: 'Brush Script MT',
        fontSize: '3em',
    },
}));

const FormFillable = (props) => {

    const classes = useStyles();

    const createComponent = (component, sectionIndex, componentIndex) => {
    
        let element = null;

        switch(component.type){
            case 'text':
                element = <Typography style={{display:'flex', alignItems:'flex-end'}}>{component.value}</Typography>
                break;
            case 'input':
                element = (
                    <React.Fragment>
                        <Typography style={{display:'flex', alignItems:'flex-end'}}>{component.label}</Typography>
                        <TextField 
                            value={props.formData.sections[sectionIndex].components[componentIndex].value}
                            onChange={(event)=>{
                                let sections = [...props.formData.sections];
                                sections[sectionIndex].components[componentIndex].value = event.target.value;
                                props.updateForm({ ...props.formData, 'sections': sections});
                            }}
                            required
                            className={classes.input}
                            size='small' />
                    </React.Fragment>
                )
                break;
            default:
                break;
        }

        return element;
    }

    const getWidth = (width) => {
        let retWidth = 12;

        switch (width) {
            case '25%':
                retWidth = 3;
                break;
            case '33.33%':
                retWidth = 4;
                break;
            case '50%':
                retWidth = 6;
                break;
            case '66.66%':
                retWidth = 8;
                break;
            case '100%':
                retWidth = 12;
                break;
            default:
                retWidth = 12;
                break;
        }

        return retWidth;
    }

    return (
        <div className={classes.root}>
            <div className={classes.content}>
                {!_.isEmpty(props.formData)
                    ?   <form onSubmit={props.handleSubmit}>
                            <Paper className={classes.paper}>
                                <Typography className={classes.title}>Gee Tee Holdings Inc.</Typography>
                                <Typography className={classes.header}>{props.formData?.title}</Typography>

                                <Grid container style={{display:'flex', flexDirection:'row'}}>
                                    {props.formData.sections !== undefined 
                                    ?   props.formData?.sections.map((section, sectionIndex) => {
                                            return (
                                                <Grid key={sectionIndex} item xs={12} sm={getWidth(section.width)} className={classes.section}>
                                                    {section.components !== undefined 
                                                    ?   section?.components.map((component, componentIndex) => {
                                                            return(
                                                                <React.Fragment key={'component'+componentIndex}>
                                                                    {createComponent(component, sectionIndex, componentIndex)}
                                                                </React.Fragment>
                                                            )
                                                        })
                                                    :   null}
                                                </Grid>
                                            )
                                        })
                                    :   null}
                                    <Grid item xs={12} style={{display:'flex', justifyContent: 'center', marginTop: '16px'}}>
                                        <Button type='submit' variant='contained' disabled={props.submitting}>Submit Form</Button>
                                    </Grid>
                                </Grid>
                            </Paper>
                        </form>
                    : null
                }
            </div>
        </div>
    )
};

export default FormFillable;