import React, { useState } from 'react';
import Aux from '../../../hoc/AuxComponent';
import CustomerForm from '../../components/Reports/Customers/CustomerForm'
import { firestore } from '../../../firebase/firebase'
import { withRouter } from 'react-router-dom';

const CustomerNew = (props) => {

  const initialCustomer = {
    city: { value: '', valid: true },
    email: { value: '', valid: true },
    name: { value: '', valid: true },
    address: { value: [''], valid: true },
    postalCode: { value: '', valid: true },
    province: { value: 'MB', valid: true },
    website: { value: '', valid: true },
    streetName: { value: '', valid: true },
    streetNumber: { value: '', valid: true },
    jobNumber: { value: '', valid: true },
    isActive: { value: true, valid: true },
    isParent: { value: false, valid: true },
    contacts: { value: [] }
  }

  const [customer, setCustomer] = useState(initialCustomer);
  const [submitting, setSubmitting] = useState(false);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState(false);

  //Handles onChange of CustomerForm Inputs
  const textHandler = (event) => {
    switch (event.target.name) {
      //Handle value as array 
      case "address":
        //Dont call splice in the hook set method to conform to hook rules
        let address = customer.address.value;
        address.splice(event.target.id, 1, event.target.value)
        setCustomer({ ...customer, address: { ...customer["address"], value: address } });
        break;
      default:
        setCustomer({ ...customer, [event.target.name]: { ...customer[event.target.name], value: event.target.value } });
        break;
    }
  }

  const handleSubmit = (event) => {
    event.preventDefault();
    createCustomer();
  }

  const addAddress = () => {
    let address = customer.address['value'];
    address.push('');
    setCustomer({ ...customer, address: { ...customer['address'], value: address } })
  }

  const removeAddress = (index) => {
    let address = customer.address['value'];
    address.splice(index, 1)
    setCustomer({ ...customer, address: { ...customer['address'], value: address } })
  }

  const createCustomer = () => {
    let newCustomer = {}

    for (const [key, value] of Object.entries(customer)) {
      newCustomer[key] = value.value;
    }

    newCustomer.archived = false;

    setSubmitting(true);
    firestore.collection('customers').add(newCustomer)
      .then(() => {
        setCustomer(initialCustomer);
        setSubmitting(false);
        setError('');
        setSuccess(true);
        //timeout to allow success message to show
        setTimeout(() => {
          setSuccess(false);
        }
          , 3000)
      }).catch((e) => {
        setSubmitting(false);
        setError("Unable to create customer, please try again");
      });
  }

  return (
    <Aux>
      <CustomerForm
        customer={customer} 
        setCustomer={setCustomer}
        textHandler={textHandler}
        handleSubmit={handleSubmit} 
        submitting={submitting}
        addAddress={addAddress} 
        removeAddress={removeAddress}
        error={error} 
        success={success} 
      />
    </Aux>
  )
}

export default withRouter(CustomerNew);