import React, { useState, useEffect } from 'react';
import { Grid } from '@material-ui/core';
import { withRouter } from 'react-router';
import { firestore } from '../../../firebase/firebase';
import Skeleton from '@material-ui/lab/Skeleton';
import { useUser } from '../../../providers/UserContext';
import _ from 'lodash';
import moment from 'moment';

import PayrollCard from '../../components/Payroll/PayrollCard';

const Payroll = (props) => {

    //const classes = useStyles();

    const { getUsers, users } = useUser();
  
    const [ticketEntryData, setTicketEntryData] = useState([])
    const [internalLoading, setInternalLoading] = useState(false);

    const defaultCurrentPayroll = {
      includeDollarAmount: true,
      includePayrollUnits: true,
      includeVacactionPay: true,
      reportDetail: 'detail',
      hideLabourDistribution: false,
      payToOperator: false,
      shortMessage: '',
    }
    const [currentPayroll, setCurrentPayroll] = useState("currentPayroll" in sessionStorage ? JSON.parse(sessionStorage.getItem("currentPayroll")) : defaultCurrentPayroll)

    const defaultPayrollFilter = {
      reportScope: 'all',
      startDate: moment().subtract(1, 'week'),
      endDate: moment(),
      findByTicketNumber: false,
    }
    const [payrollFilter, setPayrollFilter] = useState("payrollFilter" in sessionStorage ? JSON.parse(sessionStorage.getItem("payrollFilter")) : defaultPayrollFilter)

    const [selectedRow, setSelectedRow] = useState([]) 
    const [filteredListData, setFilteredListData] = useState([])

    useEffect(() => {
      if(users.length === 0){ getUsers() }

      firestore.collection('deliveries')
      .where("payrollVerified", "==", true)
      .where("date", ">=", moment(payrollFilter.startDate).format("YYYY-MM-DD"))
      .where("date", "<=", moment(payrollFilter.endDate).format("YYYY-MM-DD")).get()
      .then(querySnapshot => {
          const response = querySnapshot.docs.map((doc) => {
          return {
              ...doc.data(),
              'docId': doc.id,     
          }
          })
          setTicketEntryData(response);
          setInternalLoading(false);                  
      })
      .catch(e => {
          setInternalLoading(false);
          console.log(e.message);
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.location.search]);


    //filters ticket data for list
    useEffect(() => {
      let container = _.cloneDeep(ticketEntryData)
      // dates filter
      //container = container.filter(x=> moment(x.ticketDate).isBetween(payrollFilter.startDate, payrollFilter.endDate, 'days', true))

      //field filter - ensure field is present in ticket

      // report scope filter
      container = container.filter(x=> payrollFilter.reportScope === 'postedOnly' ? x.operatorPayDate !== null : payrollFilter.reportScope === 'unpostedOnly' ? x.operatorPayDate === null : x)

      // unique operators filter
      if(!payrollFilter.findByTicketNumber){
        container = [...container.reduce((map, obj) => map.has(obj.uid) ? map : map.set(obj.uid, obj), new Map()).values()]; 
     }

      setFilteredListData(container)
    }, [ticketEntryData, payrollFilter])


    const handlePreviewPDF = () => {
      let ticketDataContainer = []

      if(payrollFilter.findByTicketNumber ){
        ticketDataContainer = selectedRow.map((docId) => {return ticketEntryData.find(x=> x.docId === docId) })
      }
      else{
        selectedRow.forEach((row) => {
          let currentUser = ticketEntryData.find(x=> x.docId === row)?.uid
          let newData = ticketEntryData.filter(x=> x.uid === currentUser)
          ticketDataContainer = ticketDataContainer.concat(newData)
        })
      }

      let usersContainer = ticketDataContainer.map((ticket) => { return users.find(x=> x.uuid === ticket.uid) })

      props.history.push({
        pathname: "/dashboard/payroll/printing/",
        state: { 
          ticketData: JSON.stringify(ticketDataContainer), 
          payrollDetails: JSON.stringify(currentPayroll), 
          payrollFilter: JSON.stringify(payrollFilter),
          users: JSON.stringify(usersContainer),
        },
      });
    }

  return (
    <Grid>
        {internalLoading 
        ?
            <Skeleton></Skeleton>
        :      
            <Grid>
                <PayrollCard 
                  users={users}
                  internalLoading={internalLoading}
                  currentPayroll={currentPayroll}
                  setCurrentPayroll={setCurrentPayroll}

                  payrollFilter={payrollFilter}
                  setPayrollFilter={setPayrollFilter}
                  ticketEntryData={ticketEntryData}
                  selectedRow={selectedRow}
                  setSelectedRow={setSelectedRow}

                  filteredListData={filteredListData}
                  handlePreviewPDF={handlePreviewPDF}
                />
            </Grid>
        }

    </Grid>
  )
}

export default withRouter(Payroll);