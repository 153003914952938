import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import ChecklistAllPDF from '../../../components/Reports/Checklists/ChecklistAllPDF'
import { Skeleton } from '@material-ui/lab';
import { makeStyles } from '@material-ui/core';
import { useUser } from '../../../../providers/UserContext';

const useStyles = makeStyles((theme) => ({
    content: {
        display: 'flex',
        width: '100%',
        padding: '32px',
        justifyContent: 'center',
        marginTop: '40px',
    },
}));

function ChecklistAllPrint(props) {
    const [checklistData, setChecklist] = useState({});
    const [loading, setLoading] = useState([]);
    const [fullData, setFullData] = useState({})
    const { users, getUsers, userLoading } = useUser();

    const classes = useStyles();

    useEffect(() => {
        setLoading(true);
     
        let data = props.location.state.data//.sort((a, b) => { return a?.timesheet?.[0]?.timesheetID < b?.timesheet?.[0]?.timesheetID ? 1 : -1 })
        setChecklist(data)
        setFullData(props.location.state)
        setLoading(false)
        
    }, [props.location.state]);


    useEffect(() => {
        getUsers()
    }, []) // eslint-disable-line react-hooks/exhaustive-deps
    

    return (
        loading || userLoading
            ? 
            <div className={classes.content}>
                <Skeleton variant='rect' width={'63vw'} height={'80vh'} />
            </div>
            : 
            <React.Fragment>
                <ChecklistAllPDF
                checklistData={checklistData}
                startDate={props.location.state.startDate}
                endDate={props.location.state.endDate} users={users}
                fullData={fullData} />
            </React.Fragment>
    )
}

export default withRouter(ChecklistAllPrint)