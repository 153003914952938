import React, { useState, useCallback } from 'react';
import PDFForm from '../../components/PDFs/PDFForm'
import { firestore } from '../../../firebase/firebase'
import { storage } from '../../../firebase/firebase'
import { withRouter } from 'react-router';

const PDFNew = (props) => {

    const initialSafetyForm = {
        name: '',
        description: '',
        aliases: [''],
        dateCreated: '',
        dateUpdated: '',
        requiresTimesheet: false,
        requiresJob: false,
        sendsEmail: false,
        appearsInEmployeePage: true,
        hasPhotos: false,
        emails: [],
        gathersWeatherData: false,
    }

    const [safetyForm, setSafetyForm] = useState(initialSafetyForm);
    const [safetyPDF, setSafetyPDF] = useState({});
    const [submitting, setSubmitting] = useState(false);
    const [error, setError] = useState("");

    //handle checking of checkboxes
    const handleCheckboxChange = (e) => {
        setSafetyForm({
            ...safetyForm,
            [e.target.name]: e.target.checked
        })
    }

    const updateSafetyData = useCallback((e) => {setSafetyForm(e)},[],);
    const handleChange = (event) => {
        switch (event.target.name) {
            //Handle value as array 
            case "aliases":
                //Dont call splice in the hook set method to conform to hook rules
                let aliases = safetyForm.aliases;
                aliases.splice(event.target.id, 1, event.target.value)
                setSafetyForm({ ...safetyForm, aliases: aliases });
                break;
            default:
                setSafetyForm({ ...safetyForm, [event.target.name]: event.target.value });
                break;
        }
    }

    const addAlias = () => {
        let aliases = safetyForm.aliases;
        aliases.push('');
        setSafetyForm({ ...safetyForm, aliases: aliases });
    }

    const removeAlias = (index) => {
        let aliases = safetyForm.aliases;
        aliases.splice(index, 1);
        setSafetyForm({ ...safetyForm, aliases: aliases });
    }

    const handleFileUpload = (acceptedFiles) => {
        const file = acceptedFiles[0];
        if (file) {
            setSafetyPDF(file);
            setSafetyForm({ ...safetyForm, pdfURL: file.name});
        }
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        createSafetyForm();
    }


    const createSafetyForm = async () => {
        try {
            setSubmitting(true);
    
            // Clone safetyForm to avoid direct mutations
            let newSafetyForm = { ...safetyForm };
    
            newSafetyForm.dateCreated = Math.floor(Date.now() / 1000); // Ensure dateCreated is an integer
            newSafetyForm.archived = false;
    
            // Create reference to .pdf
            const storageRef = storage.ref();
            let docId = firestore.collection('pdfforms').doc().id;
            const pdfRef = storageRef.child('PDF/' + newSafetyForm.name + '.pdf');
    
            // Upload PDF and get the download URL
            const snapshot = await pdfRef.put(safetyPDF);
            const url = await snapshot.ref.getDownloadURL();
    
            newSafetyForm.pdfURL = url;
            newSafetyForm.docId = docId;
            newSafetyForm.dateUpdated = Math.floor(new Date().getTime() / 1000);
    
            // Save the form data in Firestore
            await firestore.collection('pdfforms').doc(docId).set(newSafetyForm);
    
            // Redirect to the dashboard
            props.history.replace({pathname: '/dashboard/pdfforms/'})
        } catch (error) {
            setSubmitting(false);
            console.error("Error creating PDF form: ", error);
            setError("Unable to create PDF form, please try again");
        }
    };

    //substring text to use ... if too long
    const substringText = (text) => {
        if(text.length > 20){
            return text.substring(0, 20) + '...'
        } else {
            return text
        }
    }

    return (
        <React.Fragment>
            <PDFForm
                safetyForm={safetyForm} safetyPDF={safetyPDF} setSafetyPDF={setSafetyPDF}
                addAlias={addAlias} removeAlias={removeAlias}
                handleChange={handleChange} handleCheckboxChange={handleCheckboxChange}
                handleSubmit={handleSubmit} updateSafetyData={updateSafetyData}
                handleFileUpload={handleFileUpload}
                submitting={submitting} error={error} 
                substringText={substringText}
            />
        </React.Fragment>
    )
}

export default withRouter(PDFNew);