import React, { useState, useEffect, memo } from 'react';
import { makeStyles,  } from '@material-ui/core';
import _ from 'lodash';

import InlineSelector from '../CellComponents/InlineSelector';

const useStyles = makeStyles((theme) => ({
    columnTitles: {
        fontSize: '14px',
    },
    rowContent: {
        width: '90%',
        fontSize: '14px',
        fontWeight: 600,
        // padding: '5px',
        // margin: '5px'
    },
}));

const DataGridTableAutocomplete = ({data, id, field, options, docId, type, handleCellUpdateCallback, cellClickCallback, handleDblClickCallback, disabled, style, errorable}) => {
    const classes = useStyles();

    const checkIfNotOption = (value, options) => {
        let returnValue = false;
        let object = type !== 'select' ? options.data.find(x=> x === value) : options.data.find(x=> x.docId === value)
        if(_.isUndefined(object)){ returnValue = true; }
        return returnValue;
    }

    const [error, setError] = useState(((data !== '' && !_.isUndefined(data))) ? checkIfNotOption(data, options) : false)

    useEffect(() => {
        setError(((data !== '' && !_.isUndefined(data))) ? checkIfNotOption(data, options) : false)
    }, [data])  // eslint-disable-line react-hooks/exhaustive-deps

    let valueContainer = data;

    //console.log('autocomplete rerender')


    // let debouncedHandleCellChange = _.debounce(function (value) {
    //     let valueContainer2 = !_.isUndefined(value) ? value : '';
    //     valueContainer = valueContainer2
    //     handleCellUpdateCallback(valueContainer2, field, docId)
    // }, 1000);
    
    //console.log('DataGridTableAutocomplete rendered',)

    return(
        <>
            <InlineSelector
                inputProps={{ className: classes.rowContent }}
                className={classes.rowContent}
                suggestions={options}
                type={type}
                disabled={disabled}
                docId={docId}
                //filterdropdown={false}      //bool to filter results from drop down on text change
                //inlinepredictivetext={true} //bool to turn in line predictive text on or off
                value={valueContainer || ''}
                id={id}
                field={field}
                error={errorable ? error : false}
                onDoubleClick={()=> {
                    handleDblClickCallback(field, data)
                }}
                //used to get and retin focus on render
                // onClick={() => { 
                //     cellClickCallback(`${docId} ${field}`) 
                // }}
                //used to update internal value and update db without updating parent values
                onChange={(e, newValue) => { 
                    let valueContainer2 = !_.isUndefined(newValue) ? newValue : '';
                    valueContainer = valueContainer2

                    if(newValue.length > 0 && checkIfNotOption(newValue, options)){ setError(true)}
                    else{setError(false)}

                    handleCellUpdateCallback(valueContainer2, field, docId)

                    //debouncedHandleCellChange(newValue)
                }}
                //used for clicking table, updates parent values right away
                onTableClick={(e, newValue) => { 
                    //console.log('onTableClick')
                    handleCellUpdateCallback(newValue, field, docId)  
                }}
            />
        </>
    )
}

export default memo(DataGridTableAutocomplete);