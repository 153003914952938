import React, { useState, useEffect } from 'react';
import { Divider, makeStyles} from '@material-ui/core';
import moment from 'moment'
import { Button, Grid, Paper, Snackbar, TextField, Typography, Checkbox, FormControlLabel,
Chip } from '@material-ui/core';
import { KeyboardDatePicker } from '@material-ui/pickers'
import { withRouter } from 'react-router-dom';
import _ from 'lodash';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import FindInPageIcon from '@material-ui/icons/FindInPage';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { firestore, removeFile } from '../../../firebase/firebase';
import { FindReplace } from '@material-ui/icons';
import { storage } from '../../../firebase/firebase';
import { useAuth } from '../../../providers/AuthContext';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import Dropzone from 'react-dropzone'
import PublishIcon from '@material-ui/icons/Publish';

const useStyles = makeStyles((theme) => ({
    btnGroup: {
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
    },
    alias: {
        display: 'flex',
        marginTop: '8px',
        marginBottom: '8px',
        alignItems: 'center',
    },
    card: {
        padding: theme.spacing(2),
        display: 'flex',
        alignItems: 'center',
        height: '100%',
    },
    content: {
        display: 'flex',
        width: '100%',
        flexDirection: 'column',
        maxWidth: '792px',
        margin: 'auto',
    },
    paper: {
        padding: theme.spacing(2),
        maxWidth: '792px',
        width: '100%',
        //backgroundColor: 'rgb(252, 252, 249)',
    },
    row: {
        display: 'flex',
        alignItems: 'center',
    },
    backButton: {
        marginBottom: '20px',
        marginTop: '20px',
    },
    fileInput: {
        '&:hover': {
            cursor: 'pointer',
          },
        display:'flex',
        alignItems: 'center',
        border: '1px solid #bdbdbd',
        borderRadius:'4px',
        margin:'8px',
        marginTop:'16px',
        //backgroundColor: '#f2f2f2',
        minWidth: '150px',
        minHeight: '50px',
    },
    fileInputAccept: {
        display:'flex',
        alignItems: 'center',
        border: '0px',
        borderRadius:'4px',
        margin:'8px',
        marginTop:'16px',
        backgroundColor: '#56a84b',
        color:'white',
        minWidth: '150px',
        minHeight: '50px',
    },
}));

const PDFCard = (props) => {

    const classes = useStyles();

    const [edit, setEdit] = useState(false);
    const [open, setOpen] = useState(false);
    const [replacing, setReplacing] = useState(false);      //modal open
    const [replacingPDF, setReplacingPDF] = useState(0)     //replace pdf modal submit (0 false, 1 true, -1 confirmation)
    const [pdfData, setPDFData] = useState({});
    const [newEmail, setNewEmail] = React.useState("");
    const [emails, setEmails] = React.useState(props.safetyData.emails || []);
    const [error, setError] = React.useState("");


    const addEmail = () => {
        //props.updateSafetyData({ ...props.safetyData, 'emails': emails })
        if(props.safetyData?.emails.includes(newEmail.toLowerCase())){
            setError("Email already exists!")
        } else {
            emails.push(newEmail.toLowerCase())
            setNewEmail("")
            props.updateSafetyData({ ...props.safetyData, 'emails': emails })
            props.saveSafetyData();
        }
        
    }

    const deleteEmail = (email) => {
        //setTags(props.product.tags)
        const index = emails.indexOf(email);
        if (index > -1) {
            emails.splice(index, 1);
        }
        props.updateSafetyData({ ...props.safetyData, 'emails': emails })
        props.saveSafetyData();

    }

    useEffect(() => {
        if(newEmail === "")
            setError("")
    }, [props.safetyForm, setEmails, newEmail])
    
    const { roles } = useAuth();

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    let safetyCard = null;

    const handleEdit = () => {
        if (edit)
            props.cancelSave();

        setEdit(!edit);
    }

    const removePDF = async (pdfURL) => {

        let query = await removeFile(pdfURL);

        return query.fileDeleted;
    }

    const handleDeleteClick = async () => {
        await removePDF(props.safetyData.pdfURL);
        firestore.collection('pdfforms').doc(props.safetyData.docId).delete().then(() => {
            console.log("PDF Form successfully deleted!");
            props.history.replace({pathname: '/dashboard/pdfforms'})
        }).catch((error) => {
            console.error("Error removing PDF Form: ", error);
        });
    }

    const handleReplaceClick = async () => {
        setReplacingPDF(1)
        //delete pdf
        removePDF(props.safetyData.pdfURL);

        //ref storage location of pdfs
        const storageRef = storage.ref();
        //let docId = firestore.collection('pdfforms').doc().id;
        const pdfRef = storageRef.child('PDF/' + props.safetyData.name + '.pdf');

        //pdf upload 
        pdfRef.put(pdfData).then((ref) => {
            pdfRef.getDownloadURL().then((url) => {
                //update pdfURL
                props.updateSafetyData({...props.safetyData, 'pdfURL': url.toString()});
                //update save state
                props.setSaveState({...props.saveState, 'pdfURL': url.toString()});
                setReplacingPDF(-1)

                setTimeout(() => { 
                    setReplacing(false); 
                    setPDFData({})
                    setReplacingPDF(0)
                }, 1000)

            })
        });
    }

    const replacingOpen = () => {
        setReplacing(true);
    };

    const replacingClose = () => {
        setReplacing(false);
    };

    const handleUpload = (file) => {
        setPDFData(file[0]);
    };

    const substringText = (text) => {
        if(text.length > 20){
            return text.substring(0, 20) + '...'
        } else {
            return text
        }
    }

    if (!_.isUndefined(props.safetyData)) {
        
        safetyCard = (
            <Grid className={classes.content}>
                <Grid className={classes.backButton} container>
                    <Grid item xs={1}>
                        <Button variant="contained" onClick={() => props.history.goBack()} startIcon={<ArrowBackIcon/>}>Back</Button>
                    </Grid>

                    <Grid item xs={11}>
                        <Grid container justifyContent='flex-end'>
                            <Button 
                                variant="contained" 
                                disabled={!roles.includes('Create|Update|Delete Forms')}
                                style={edit ? { backgroundColor: 'green', marginRight: '5px' } : {marginRight: '5px'}}
                                startIcon={<EditIcon/>} 
                                onClick={handleEdit}
                            >
                                Edit
                            </Button>

                            <Button 
                                variant="contained" 
                                style={{marginRight: '5px'}} 
                                startIcon={<FindInPageIcon/>} 
                                onClick={() => { window.open(props.safetyData.pdfURL, "_blank")}}
                            >
                                View In Browser
                            </Button>

                            <Button 
                                variant="contained" 
                                style={{marginRight: '5px'}} 
                                startIcon={<FindReplace/>} 
                                disabled={!roles.includes('Create|Update|Delete Forms')}
                                onClick={replacingOpen}
                            >
                                Replace PDF
                            </Button>

                            <Button 
                                variant="contained" 
                                style={{marginRight: '5px'}} 
                                startIcon={<DeleteIcon/>}
                                disabled={!roles.includes('Create|Update|Delete Forms')}
                                onClick={handleClickOpen}
                            >
                                Delete
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>

                <Paper className={classes.paper}>
                    {/* Delete Dialog */}
                    <Dialog
                        open={open}
                        onClose={handleClose}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                    >
                        <DialogTitle id="alert-dialog-title">{"Delete PDF Form?"}</DialogTitle>
                        <DialogContent>
                            <DialogContentText id="alert-dialog-description">
                                Are you sure you want to delete this PDF form?
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleClose} variant="contained" color="secondary">
                                No
                            </Button>
                            <Button onClick={handleDeleteClick} variant="contained" style={{ backgroundColor: 'red', color: 'white' }} autoFocus>
                                Yes
                            </Button>
                        </DialogActions>
                    </Dialog>

                    {/* Replace Dialog */}
                    <Dialog
                        open={replacing}
                        onClose={replacingClose}
                        aria-labelledby="replace-dialog-title"
                        aria-describedby="replace-dialog-description"
                        
                    >
                        <DialogTitle id="replace-dialog-title">{"Replace PDF With:"}</DialogTitle>
                        <DialogContent style={{minWidth: '500px'}}>
                            <Grid container className={pdfData?.name ? classes.fileInputAccept : classes.fileInput}>
                                <Dropzone accept={{'application/pdf': ['.pdf', '.PDF']}} onDrop={handleUpload}>
                                    {({ getRootProps, getInputProps }) => (
                                        <Grid container item xs={12}justifyContent='center' {...getRootProps()} >
                                            <input {...getInputProps()} style={{ display: 'none' }} />
                    
                                            <PublishIcon style={{color:'grey', marginRight:'20px'}}/>

                                            <Typography variant='h6'>
                                                {pdfData?.name ? substringText(pdfData?.name) : "Upload Document (.pdf)"}
                                            </Typography>
                                        </Grid>
                                    )}
                                </Dropzone>
                            </Grid>
                        </DialogContent>
                        <DialogActions>
                            <Grid container justifyContent='flex-end'> 
                                <Button onClick={replacingClose} variant="contained" color="secondary" style={{marginRight: '5px'}}>
                                    Back
                                </Button>
                                <Button onClick={handleReplaceClick} variant="contained" style={{ backgroundColor: 'red', color: 'white' }} autoFocus>
                                    {replacingPDF === 0 ? "Submit" : replacingPDF === 1 ? "Submitting..." : "Sumbitted!"}
                                </Button>
                            </Grid>
                        </DialogActions>
                    </Dialog>

                    <Grid container spacing={2}>
                        <Grid item sm={6} xs={4}>
                            {edit ?
                                <React.Fragment>
                                    <TextField
                                        label='Product Name'
                                        value={props.safetyData?.name}
                                        variant='outlined'
                                        onChange={(event) => { props.updateSafetyData({ ...props.safetyData, 'name': event.target.value }) }}
                                        inputProps={{ style: { fontSize: '2.125rem' } }} />

                                </React.Fragment>
                                :
                                <React.Fragment>
                                    <Typography align='left' variant='h5'>{props.safetyData?.name}</Typography>
                                    
                                </React.Fragment>
                            }
                        </Grid>

                        <Grid item sm={6} xs={8} >
                            <div className={classes.btnGroup} style={{ marginBottom: '32px' }}>
                                {/* <IconButton onClick={handleEdit} disabled={!roles.includes('Create|Update|Delete Forms')} style={edit ? { color: 'black' } : {}} title="Edit Sheet"><EditIcon /></IconButton>
                                <IconButton onClick={() => { window.open(props.safetyData.pdfURL, "_blank")}} title="View Sheet in Browser"><FindInPageIcon /></IconButton>
                                <IconButton onClick={replacingOpen} disabled={!roles.includes('Create|Update|Delete Forms')} title="Replace Sheet"><FindReplace /></IconButton>
                                <IconButton onClick={handleClickOpen} disabled={!roles.includes('Create|Update|Delete Forms')} title="Delete Sheet"><DeleteIcon /></IconButton> */}
                            </div>   
                        </Grid>

                        <Grid item sm={6} xs={12}>
                            <Paper className={classes.card}>
                                <Grid container spacing={2}>

                                    <Grid item sm={5} className={classes.row}>
                                        <Typography variant='body1'>Form Created</Typography>
                                    </Grid>
                                    <Grid item sm={7} className={classes.row} style={{ justifyContent: 'flex-end' }}>
                                        {edit
                                            ? <KeyboardDatePicker
                                                format='YYYY/MM/DD'
                                                value={moment.unix(props.safetyData.dateCreated)}
                                                onChange={(date) => { props.updateSafetyData({ ...props.safetyData, 'dateCreated': date.unix() }) }}
                                                fullWidth />
                                            : <Typography>{moment(props.safetyData.dateCreated * 1000).format('YYYY/MM/DD')}</Typography>
                                        }
                                    </Grid>
                                </Grid>
                            </Paper>
                        </Grid>

                        <Grid item sm={6} xs={12}>
                            <Paper className={classes.card}>
                                <Grid container spacing={2}>
                                    <Grid item sm={5} className={classes.row}>
                                        <Typography variant='body1'>Form Updated</Typography>
                                        </Grid>
                                        <Grid item sm={7} className={classes.row} style={{ justifyContent: 'flex-end' }}>
                                            <Typography>{ props.safetyData.dateUpdated < 9999999999 ?
                                                moment(props.safetyData.dateUpdated * 1000).format('YYYY/MM/DD')
                                                :
                                                moment(props.safetyData.dateUpdated).format('YYYY/MM/DD') }
                                            </Typography>
                                    </Grid>
                                </Grid>
                            </Paper>
                        </Grid>



                        <Grid item sm={12} xs={12}>
                            <Paper className={classes.card}>
                                <Grid container spacing={2}>
                                    {!edit
                                        ? <Grid item sm={12}>
                                            <Typography variant='h6'>Description</Typography>
                                        </Grid>
                                        : null}

                                    <Grid item sm={12}>
                                        {edit
                                            ? <TextField variant='outlined'
                                                label='Description'
                                                multiline rows={5}
                                                fullWidth
                                                value={props.safetyData.description}
                                                onChange={(event) => { props.updateSafetyData({ ...props.safetyData, 'description': event.target.value }) }} />
                                            : <Typography style={{ whiteSpace: 'pre-wrap' }}>{props.safetyData.description}</Typography>
                                        }
                                    </Grid>
                                </Grid>
                            </Paper>
                        </Grid>

                        <Grid item sm={12} xs={12}>
                            <Paper className={classes.card}>
                                <Grid container spacing={2}>
                              
                                         <Grid item sm={12}>
                                            <Typography variant='h6'>Features</Typography>
                                        </Grid>

                                        <Grid item sm={12}>
                                            <FormControlLabel
                                                control={
                                                <Checkbox
                                                    checked={props.safetyData.gathersWeatherData}
                                                    onChange={props.handleCheckboxChange}
                                                    name="gathersWeatherData"
                                                    color="primary"
                                                />
                                                }
                                                label="Form Grabs Weather Data"
                                            />
                                        </Grid>

                                        <Grid item sm={12}>
                        <FormControlLabel
                            control={
                            <Checkbox
                                checked={props.safetyData.hasPhotos}
                                onChange={props.handleCheckboxChange}
                                name="hasPhotos"
                                color="primary"
                            />
                            }
                            label="Allow Photo(s) to be attached?"
                        />
                        </Grid>
                                    
                        <Grid item sm={12}>
                            <FormControlLabel
                                control={
                                <Checkbox
                                    checked={props.safetyData.appearsInEmployeePage}
                                    onChange={props.handleCheckboxChange}
                                    name="appearsInEmployeePage"
                                    color="primary"
                                />
                                }
                                label="Store submit forms in Employee page?"
                            />
                        </Grid>

                        <Grid item sm={12}>
                        <FormControlLabel
                            control={
                            <Checkbox
                                checked={props.safetyData.invisibleInApp ? props.safetyData.invisibleInApp : false}
                                onChange={props.handleCheckboxChange}
                                name="invisibleInApp"
                                color="primary"
                            />
                            }
                            label="Form invisible in app?"
                        />
                        </Grid>

                        <Grid item sm={12}>
                            <FormControlLabel
                                control={
                                <Checkbox
                                    checked={props.safetyData.sendsEmail}
                                    onChange={props.handleCheckboxChange}
                                    name="sendsEmail"
                                    color="primary"
                                />
                                }
                                label="Send Email(s) on submit?"
                            />
                            <Divider/>
                            <TextField
                                placeholder={"Add new Email"}
                                disabled={!props.safetyData.sendsEmail}
                                value={newEmail || ""}
                                onChange={(event) => setNewEmail(event.target.value)}
                                onKeyPress={(ev) => {
                                    if (ev.key === 'Enter' && newEmail !== "") {
                                        addEmail()
                                        ev.preventDefault();
                                    }
                                }}
                            />
                                        {error &&
                            <p style={{color:"red"}}><small>{error}</small></p>}
                            <Typography></Typography>
                            {props.safetyData?.emails && props.safetyData?.emails.length > 0 ? props.safetyData.emails.map((email, index) =>  {
                                return (
                                    <Chip
                                    key={index}
                                    size="small"
                                    style={{padding:'4px', margin:'4px'}}
                                    label={email}
                                    disabled={!props.safetyData.sendsEmail}
                                    onDelete={(event) => deleteEmail(email)}
                                    color="secondary"
                                    />
                                )
                            })
                            : <p><small>No emails available.</small></p>}
                                    </Grid>
                                </Grid>
                            </Paper>
                        </Grid>

                        <Grid item xs={12}>
                            <Grid item xs={12} style={{display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop:'50px'}}>
                                <iframe
                                    src={props.safetyData.pdfURL}
                                    title="PDF Viewer"
                                    width="1000px"
                                    height="800px"
                                />
                            </Grid>
                        </Grid>
                    </Grid>

                <Snackbar
                open={!_.isEqual(props.safetyData, props.saveState)}
                message={props.saving ? 'Saving...' : 'Save Document?'}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center'
                }}
                action={
                    <React.Fragment>
                        {props.saving
                            ? null
                            : <React.Fragment>
                                <Button variant='text' color='primary' onClick={props.cancelSave} style={{ marginLeft: '32px', marginRight: '8px' }}>Cancel</Button>
                                <Button variant='contained' onClick={props.saveSafetyData}>Confirm</Button>
                            </React.Fragment>
                        }
                    </React.Fragment>
                } />
                </Paper>
            </Grid>
        )
    }

    return (
            <div className={classes.content}>
                {safetyCard}
            </div>
    )
}

export default withRouter(PDFCard)