import React from 'react'
import { KeyboardDatePicker, KeyboardDateTimePicker } from '@material-ui/pickers';

const DatePicker = (props) => {
    return (
        <KeyboardDatePicker
            label={props.label}
            value={props.value}
            onChange={props.onChange}
            autoOk
            format="yyyy/MM/DD"
        />
    )
}

export const DateTimePicker = (props) => {
    return (
        <KeyboardDateTimePicker
            label={props.label}
            value={props.value}
            onChange={props.onChange}
            autoOk
            format="yyyy/MM/DD h:mm a"
        />
    )
}

export default DatePicker;