import React, { useEffect } from 'react';
import { withRouter } from 'react-router'
import { makeStyles } from '@material-ui/core';
import MaterialTable from '@material-table/core';
import { TableIcons } from '../../../constants/tableIcons'
import { Skeleton } from '@material-ui/lab';


import { useCustomer } from '../../../providers/CustomerContext'

const useStyles = makeStyles((theme) => ({
    content: {
        display: 'flex',
        width: '100%',
        padding: '32px',
        justifyContent: 'center'
    },

    sidebar: {
        position: 'fixed',
        right: 0,
        top: '64px',
        maxWidth: '256px',
        width: '100%',
        padding: '16px',
        zIndex: '1000',
        overflowY: 'auto',
        minHeight: "calc(100vh - 64px)",
        maxHeight: "calc(100vh - 64px)",
    },
}));

const CustomerReport = (props) => {

    const { customers, loading, getCustomers } = useCustomer();
    const classes = useStyles();

    useEffect(() => { getCustomers() }, [getCustomers]);

    return (
        <div className={classes.content}>
            {
                loading || !customers
                    ? <Skeleton variant='rect' width={'100vw'} height={'100vh'}/>
                    : <MaterialTable
                        data={customers}
                        title={"All Customers"}
                        icons={TableIcons}
                        columns={[
                            { title: 'Name', field: 'name' },
                            { title: 'Address', render: rowData => rowData.addressLines ? rowData.addressLines.join(', ') : rowData.streetNumber + ' ' + rowData.streetName },
                        ]}
                        actions={[
                            {
                                icon: TableIcons.OpenInNew,
                                tooltip: "Show Customer",
                                onClick: (event, rowData) => props.history.push({
                                    pathname: '/dashboard/customers/form',
                                    search: "?" + new URLSearchParams({ docId: rowData.docId }).toString(),
                                })
                            }
                        ]}
                        localization={{
                            header: {
                                actions: 'Actions'
                            }
                        }}
                        options={{
                            toolbar: true,
                            paging: false,
                            actionsColumnIndex: -1,
                            search: true,
                            emptyRowsWhenPaging: false,
                            rowStyle: {
                                whiteSpace: 'pre-wrap',
                            },
                        }}
                    />
            }
        </div>
    )
}

export default withRouter(CustomerReport);