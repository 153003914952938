import React, { useContext, useEffect, useState } from "react";
import { firestore } from "../firebase/firebase";
import _ from "lodash";

const HRSettingsContext = React.createContext();

export function useHRSettings() {
  return useContext(HRSettingsContext);
}

export function HRSettingsProvider({ children }) {
  const [settings, setSettings] = useState({}); 
  const [types, setTypes] = useState([]); // Store types here
  const [subtypes, setSubtypes] = useState([]); // Store subtypes here
  const [loading, setLoading] = useState(true);
  const [hrLoading, setHrLoading] = useState(true);

  useEffect(() => {
    // Set up Firestore real-time listener
    const unsubscribe = firestore.collection("humanResourcesSettings").onSnapshot(
      (querySnapshot) => {
        const response = querySnapshot.docs.map((doc) => ({
          ...doc.data(),
          docId: doc.id,
        }));

        // Initialize documents object to track index of "settings"
        let documents = {
          settings: "",
        };

        // Find the index of the 'settings' document and set the settings
        for (let i = 0; i < _.size(response); i++) {
          for (const [key] of Object.entries(documents)) {
            if (response[i].docId.toString() === [key].toString()) {
              documents = { ...documents, [key]: i };
            }
          }
        }

        // Set the settings data
        const settingsData = response[0];
        setSettings(settingsData);
        
        // Also set types and subtypes
        setTypes(settingsData?.types || []);
        setSubtypes(settingsData?.subtypes || []);
        
        setLoading(false);
        setHrLoading(false);
      },
      (error) => {
        console.warn("Error fetching data: ", error);
        setLoading(false);
        setHrLoading(false);
      }
    );

    // Clean up the listener on component unmount
    return () => unsubscribe();
  }, []);

  const value = {
    setSettings,
    settings,
    types,
    subtypes,
    loading,
    hrLoading,
  };

  return (
    <HRSettingsContext.Provider value={value}>
      {children}
    </HRSettingsContext.Provider>
  );
}
