import React, { useState, useEffect } from 'react';
import { Button, Grid,  Paper, Modal, Backdrop, Fade, Typography, makeStyles, TextField} from '@material-ui/core';
import _ from 'lodash';
import ReactQuill from 'react-quill';

const useStyles = makeStyles((theme) => ({
    content: {
        display: 'flex',
        width: '100%',
        flexDirection: 'column',
        maxWidth: '90vw',
        margin: 'auto',
        marginTop: '20px',
    },
    paper: {
        //borderRadius: '0px',
        margin: '0px',
        width: '100%',
        maxWidth: '90vw',
        padding: '16px',
       // backgroundColor: 'rgb(252, 252, 249)',
    },
    table: {
        display: 'flex',
        justifyContent: 'center',
    },
    createButton: {
        marginBottom: '20px',
        marginTop: '20px',
    },
    modal: {
        minWidth: '500px',
        minHeight: '400px',
        width: '40vw',
        height: '50vh',
    },
}));

const GrainOrderEmailModal = ({ emailModal, setEmailModal, users, rowDocIds, globalGrainOrders, handleSendOperatorInstructionsEmail }) => {
    const classes = useStyles();

    const [emailContents, setEmailContents] = useState({
        validTickets: [],      // [{recipient: x, subject: [], message: '', docIds: []}]
        nullTickets: [],
        emailIndex: 0,          // used to track which email is being created at any given time 
        proccessingState: 0,   // 0 false, 1 true, -1 pending, 2 completed
        cloudCallback: {},
    })

    // ensures all values are proper when opening the modal
    useEffect(() => {
        let nullTicketsContainer = [];
        let validTicketsContainer = [];  

        rowDocIds.map((docId) => { return globalGrainOrders.find(x=> x.docId === docId)}).forEach((row) => {
            let checkValue = _.find(users, {docId: row.operator})?.email;

            if(!_.isUndefined(checkValue)){
                let ticketIndex = validTicketsContainer.findIndex(x=> x.recipient === checkValue)
                
                if(ticketIndex === -1){
                    validTicketsContainer.push({recipient: checkValue, subject: [row.geeTeeOrderNumber], message: '', docIds: [row.docId]})
                }
                else{
                    validTicketsContainer[ticketIndex] = {
                        ...validTicketsContainer[ticketIndex], 
                        subject: [...validTicketsContainer[ticketIndex]?.subject || [], row.geeTeeOrderNumber], 
                        docIds: [...validTicketsContainer[ticketIndex]?.docIds || [], row.docId], 
                    }
                }   
            }
            else{
                nullTicketsContainer.push(row.geeTeeOrderNumber)
            }
        })

        validTicketsContainer.forEach((ticket) => {
            let newSubject = `Instructions for order numbers: ${ticket.subject.join(", ")}`;
            ticket.subject = newSubject;
        })
        
        setEmailContents({
            validTickets: validTicketsContainer,
            nullTickets: nullTicketsContainer,
            emailIndex: 0, 
            proccessingState: 0,
            cloudCallback: {},
        })

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [emailModal])

    const emailStep = (
        <>
            <Grid container>
                <Typography style={{fontWeight: 'bold'}}>
                    Recipient: 
                </Typography>
            </Grid>

            <Grid container style={{marginBottom: '10px'}}>
                <Typography >
                    {emailContents.validTickets[emailContents.emailIndex]?.recipient}
                </Typography>
            </Grid>

            {emailContents.nullTickets.length > 0 &&
                <Grid container spacing={2} style={{marginBottom: '10px'}}>
                    <Grid item >
                        <Typography style={{color: 'red'}}><small>{`ticket numbers: ${emailContents.nullTickets.join(", ")} have no associated emails`}</small></Typography>
                    </Grid>
                </Grid>
            }

            <Grid item xs={12} className={classes.table}>
                <TextField
                    id="subject"
                    label="Subject"
                    size="small"
                    fullWidth
                    variant="outlined"
                    value={emailContents.validTickets[emailContents.emailIndex]?.subject}
                    onChange={(e) => {
                        let emailContentsContainer = emailContents;
                        emailContentsContainer.validTickets[emailContents.emailIndex] = {...emailContentsContainer.validTickets[emailContents.emailIndex], subject: e.target.value}
                        setEmailContents({...emailContentsContainer})
                    }}
                />
            </Grid>
            <Grid item xs={12} className={classes.table}>
                <ReactQuill 
                    style={{ width: '100%',}}
                    theme="snow" 
                    placeholder='Additional Instructional Message'
                    value={emailContents.validTickets[emailContents.emailIndex]?.message}
                    preserveWhitespace={false}
                    onChange={(html, delta, source) => {
                        if (source === 'user') {
                            let emailContentsContainer = emailContents;
                            emailContentsContainer.validTickets[emailContents.emailIndex] = {...emailContentsContainer.validTickets[emailContents.emailIndex], message: html}
                            setEmailContents({...emailContentsContainer})
                        }
                    }}
                />
            </Grid>
        </>
    )

    const confrimationStep = (
        <>
            <Grid container >
                {emailContents.validTickets?.length > 0 ?
                    emailContents.validTickets.map((ticket, index) => {
                        return (
                            <Grid key={index + ticket.recipient} container justifyContent='center'>
                                <Typography
                                    style={emailContents.cloudCallback[ticket.recipient] === true 
                                        ? {color: 'green'} 
                                        : emailContents.cloudCallback[ticket.recipient] === false ? {color: 'red'} : {} 
                                    }
                                >
                                    {ticket.recipient}
                                </Typography>
                            </Grid>
                        )
                    })
                : null
                }
            </Grid>
        </>
    )

    return (
        <>
            <Modal
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    outline: 0,
                }}
                open={emailModal}
                onClose={() => { setEmailModal(false) }}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}>
                <Fade in={emailModal}>
                    <Paper className={classes.modal} style={{position: 'relative'}} >
                        <Grid container >

                            {/* title section */}
                            <Grid container justifyContent='center' style={{padding: '16px'}}>
                                <Typography style={{fontSize: '20px', fontWeight: 'bold'}}>{emailContents.proccessingState === 0 ? "Email" : "Completion"}</Typography>
                            </Grid>

                            {/* content section */}
                            <Grid container style={{padding: '16px'}}>
                                {emailContents.proccessingState === 0 && emailStep}
                                {emailContents.proccessingState !== 0 && confrimationStep}
                            </Grid>

                            {/* button section */}
                            <Grid container style={{padding: '16px', position: 'absolute', right: 0, bottom: 0, }}>
                                <Grid item xs={6}>
                                    <Button
                                        variant='contained'
                                        disabled={emailContents.proccessingState !== 0 || emailContents.emailIndex === 0}
                                        onClick={(e) => { setEmailContents({...emailContents, emailIndex: emailContents.emailIndex -1}) }}
                                    >
                                        Back
                                    </Button>
                                </Grid>

                                <Grid item xs={6} >
                                    <Grid container justifyContent='flex-end' >
                                        <Button
                                            //startIcon={<CheckIcon/>}
                                            variant='contained'
                                            style={emailContents.proccessingState === 1 ? {backgroundColor: 'green', color: 'white'} : {}}
                                            disabled={
                                                _.isEmpty(emailContents.validTickets) || 
                                                emailContents.proccessingState === -1 ||
                                                emailContents.proccessingState === 1
                                            } 
                                            onClick={(e) => {

                                                if(emailContents.emailIndex + 1 !== emailContents.validTickets.length){
                                                    setEmailContents({...emailContents, emailIndex: emailContents.emailIndex +1})
                                                    console.log('next email')
                                                }
                                                else{
                                                    if(emailContents.proccessingState !== 2){
                                                        setEmailContents({...emailContents, proccessingState: -1})
                                                        console.log('finish email func')

                                                        handleSendOperatorInstructionsEmail(emailContents.validTickets, globalGrainOrders).then((res) => {
                                                            setEmailContents({...emailContents, proccessingState: 1, cloudCallback: res.data})
        
                                                            setTimeout(() => {
                                                                setEmailContents((prevValues) => { return {...prevValues, proccessingState: 2} })
                                                            }, 1000);
                                                        })
                                                    }
                                                    else{   // proccessing state === 2 (finished)
                                                        setEmailModal(false)
                                                    }
                                                }
                                            }}
                                        >
                                            {emailContents.emailIndex + 1 !== emailContents.validTickets.length && "Next"}
                                            {(emailContents.emailIndex + 1 === emailContents.validTickets.length && emailContents.proccessingState === 0) && "Send"}
                                            {(emailContents.emailIndex + 1 === emailContents.validTickets.length && emailContents.proccessingState === 1) && "Finished"}
                                            {(emailContents.emailIndex + 1 === emailContents.validTickets.length && emailContents.proccessingState === 2) && "Close"}
                                            {(emailContents.emailIndex + 1 === emailContents.validTickets.length && emailContents.proccessingState === -1) && "Sending"}
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Paper>
                </Fade>
            </Modal>
        </>

    )
}

export default (GrainOrderEmailModal)