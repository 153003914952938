import React, { useState, memo } from 'react';
import { makeStyles } from '@material-ui/core';
import { Grid, Typography, TextField, Divider, Checkbox, InputAdornment, FormControlLabel} from '@material-ui/core';
import _ from 'lodash';
import { Autocomplete } from '@material-ui/lab';

const useStyles = makeStyles((theme) => ({
    paper: {
        borderRadius: '0px',
        margin: '0px',
        marginBottom:'20px',
        width: '100%',
        //maxWidth: '1400px',
        //backgroundColor: 'rgb(252, 252, 249)',
        justifyContent: 'center',
        alignItems: 'center',
    },
    container: {
        marginBottom: '10px',
        padding: '15px'
    },
    textRow: {
        lineHeight: 2,
        marginBottom: '8px',
    },
    textRowTypography: {
        marginTop: '4px',
        lineHeight: 2,
        marginBottom: '8px',
    },
    selectRow: {
        padding: '5px',
    },
    table: {
        display: 'flex',
        justifyContent: 'center',
    },
    tabBar: {
        width: '100%',
        //maxWidth: '1400px',
        zIndex: '998'
    },
    loading: {
        display: 'flex',
        width: '100%',
        flexDirection: 'column',
        //maxWidth: '1400px',
        margin: 'auto',
        userSelect: 'none',
        pointerEvents: 'none',
        opacity: 0.5,
    },
    content: {
        display: 'flex',
        width: '100%',
        flexDirection: 'column',
        //maxWidth: '1400px',
        margin: 'auto',
    },
}));

const GrainOrderPaymentValues = ({ handleSetParentValues, materials, per, material, cartageSales, cartageSalesUnits, ownerPer, cartageSalesTotal, 
    linkOperatorToSalesRate, operatorBaseMultiplier, operatorRate, adjustedBase, operatorUnits, operatorPer, payroll, docId, percentageFuelSurcharge, dollarFuelSurcharge, 
 }) => {
    const classes = useStyles();

    const [paymentAutocompletes, setPaymentAutocompletes] = useState({
        material: {open: false},
        operatorPer: {open: false},
        ownerPer: {open: false},
    })

    return (
        <>
            <Grid container spacing={5} className={classes.container}>
                <Grid item xs={12} md={4}>
                    <Grid container justifyContent='center'> 
                        <Typography style={{fontWeight: 600}} className={classes.textRow}>Material</Typography>
                    </Grid>
                    <Divider className={classes.textRow}/>
                    <Grid container>
                        <Grid item xs={6}>
                            <Typography className={classes.textRowTypography}>Material:</Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <Autocomplete
                                open={paymentAutocompletes.material.open}
                                onOpen={() => { setPaymentAutocompletes((previousValues) => { return {...previousValues, material: {...previousValues.material, open: true}} }) }}
                                onClose={() => { setPaymentAutocompletes((previousValues) => { return {...previousValues, material: {...previousValues.material, open: false}} }) }}
                                id="materialAutocomplete"
                                //className={classes.selectRow}
                                autoComplete
                                autoHighlight
                                fullWidth
                                freeSolo={true}
                                size='small'
                                onInputChange={(event, newValue, reason) => { 
                                    if(reason === 'input') {
                                        handleSetParentValues({material: newValue}, docId)
                                        //handleSetCurrentGrainOrder({...currentGrainOrder, material: newValue}) 
                                    }
                                }}
                                value={material || null}
                                onChange={(event, newValue) => { 
                                    handleSetParentValues({material: newValue}, docId)
                                    //handleSetCurrentGrainOrder({...currentGrainOrder, material: newValue})
                                }}
                                options={materials.map((material) => material ?? '').sort((a, b) => a.toLowerCase() > b.toLowerCase())}
                                getOptionSelected={(option, value) => option === value}
                                getOptionLabel={(option) => option}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        //variant="outlined"
                                        InputProps={{
                                            ...params.InputProps,
                                            endAdornment: (
                                                <React.Fragment>
                                                    {params.InputProps.endAdornment}
                                                </React.Fragment>
                                            ),
                                        }}
                                    />
                                )}
                            />
                        </Grid>
                    </Grid>

                    <Grid container>
                        <Grid item xs={6}>
                            <Typography className={classes.textRowTypography}>Fuel Surcharge :</Typography>
                        </Grid>
                        <Grid item xs={6} >
                            <TextField 
                                className={classes.textRow}
                                value={percentageFuelSurcharge}
                                InputProps={{
                                    startAdornment: <InputAdornment position="start">%</InputAdornment>,
                                }}
                                onChange={(event) => {
                                    handleSetParentValues({percentageFuelSurcharge: event.target.value}, docId)
                                    //handleSetCurrentGrainOrder({...currentGrainOrder, percentageFuelSurcharge: event.target.value})
                                }}
                                onBlur={(event) => {
                                    let dollarSurchargeValue = Number(Number(event.target.value / 100)  * Number(cartageSalesTotal || 0.00)).toFixed(2)

                                    handleSetParentValues({percentageFuelSurcharge: Number(event.target.value).toFixed(3), dollarFuelSurcharge: dollarSurchargeValue}, docId)

                                    // handleSetCurrentGrainOrder({...currentGrainOrder, percentageFuelSurcharge: Number(event.target.value).toFixed(3), 
                                    //     dollarFuelSurcharge: dollarSurchargeValue})
                                }}
                            />
                        </Grid>

                        <Grid item xs={6} className={classes.textRowTypography}>
                        </Grid>
                        <Grid item xs={6}>
                            <Typography
                            className={classes.textRow}
                            style={{borderBottom: '1px solid grey'}}
                            fullWidth
                            >
                                $&nbsp;&nbsp;&nbsp;{dollarFuelSurcharge}
                            </Typography>
                        </Grid>
    
                    </Grid>
                </Grid>

                <Grid item xs={12} md={4}>
                    <Grid container justifyContent='center'> 
                        <Typography style={{fontWeight: 600}} className={classes.textRowTypography}>Cartage</Typography>
                    </Grid>
                    <Divider className={classes.textRow}/>

                    <Grid container>
                        <Grid item xs={6}>
                            <Typography className={classes.textRowTypography}>Cartage Sales:</Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <TextField 
                                fullWidth 
                                className={classes.textRow}
                                InputProps={{
                                    startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                }}
                                value={cartageSales}
                                onChange={(event) => {
                                    handleSetParentValues({cartageSales: event.target.value}, docId)
                                    //handleSetCurrentGrainOrder({...currentGrainOrder, cartageSales: event.target.value}) 
                                }}
                                onBlur={(event) => {
                                    let adjustedBaseContainer = adjustedBase || 0.00;
                                    let payrollContainer = payroll || 0.00;
                                    let cartageSalesTotalContainer = cartageSalesTotal || 0.00;
                                    let dollarFuelSurchargeContainer = dollarFuelSurcharge || 0.00;

                                    if(!_.isUndefined(linkOperatorToSalesRate) && linkOperatorToSalesRate){
                                        adjustedBaseContainer = Number((Number(event.target.value)) * (Number((operatorBaseMultiplier || 100.00) / 100))).toFixed(2)
                                        //console.log('adjustedBaseContainer', adjustedBaseContainer)

                                        payrollContainer = Number( adjustedBaseContainer * (operatorUnits || 0) * ((operatorRate || 0))).toFixed(2)
                                        //console.log('payrollContainer', payrollContainer)
                                    }

                                    dollarFuelSurchargeContainer = Number(Number((percentageFuelSurcharge || 0.000) / 100)  * Number(Number(event.target.value) * Number((cartageSalesUnits || 0)))).toFixed(2)
                                    //console.log('dollarFuelSurchargeContainer', dollarFuelSurchargeContainer)
                                    
                                    cartageSalesTotalContainer = Number((Number(event.target.value) * Number((cartageSalesUnits || 0)))).toFixed(2)
                                    //console.log('cartageSalesTotal', cartageSalesTotal)

                                    handleSetParentValues({cartageSales: Number(event.target.value).toFixed(2), cartageSalesTotal: cartageSalesTotalContainer,
                                        adjustedBase: adjustedBaseContainer, payroll: payrollContainer, dollarFuelSurcharge: dollarFuelSurchargeContainer}, docId)
                                    // handleSetCurrentGrainOrder({...currentGrainOrder, cartageSales: Number(event.target.value).toFixed(2),
                                    //     cartageSalesTotal: cartageSalesTotal, adjustedBase: adjustedBase, payroll: payroll, dollarFuelSurcharge: dollarFuelSurcharge}) 
                                }}
                            />
                        </Grid>
                    </Grid>

                    <Grid container>
                        <Grid item xs={6}>
                            <Typography className={classes.textRowTypography}>Cartage Sales Units:</Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <TextField 
                                fullWidth 
                                className={classes.textRow}
                                value={cartageSalesUnits}
                                onChange={(event) => {
                                    handleSetParentValues({cartageSalesUnits: event.target.value}, docId)
                                    //handleSetCurrentGrainOrder({...currentGrainOrder, cartageSalesUnits: event.target.value}) 
                                }}
                                onBlur={(event) => {
                                    let operatorUnitsContainer = operatorUnits || 0;
                                    let payrollContainer = payroll || 0.00;
                                    let dollarFuelSurchargeContainer = dollarFuelSurcharge || 0.00;

                                    if(!_.isUndefined(linkOperatorToSalesRate) && linkOperatorToSalesRate){
                                        operatorUnitsContainer = Number(event.target.value)
                                        payrollContainer = Number((adjustedBase || 0.00) * Number(event.target.value) * ((operatorRate || 0))).toFixed(2)
                                    }

                                    dollarFuelSurchargeContainer = Number(Number((percentageFuelSurcharge || 0.000) / 100)  * Number(Number((cartageSales || 0.00)) * Number(event.target.value))).toFixed(2)
                                    
                                    handleSetParentValues({cartageSalesTotal: Number(Number(event.target.value) * Number((cartageSales|| 0.00))).toFixed(2),
                                        operatorUnits: operatorUnitsContainer, payroll: payrollContainer, dollarFuelSurcharge: dollarFuelSurchargeContainer}, docId)
                                    // handleSetCurrentGrainOrder({...currentGrainOrder, cartageSalesTotal: Number(Number(event.target.value) * Number((cartageSales|| 0.00))).toFixed(2), 
                                    //     operatorUnits: operatorUnits, payroll: payroll, dollarFuelSurcharge: dollarFuelSurcharge}) 
                                }}
                            />
                        </Grid>
                    </Grid>

                    <Grid container>
                        <Grid item xs={6}>
                            <Typography className={classes.textRowTypography}>Cartage Per:</Typography>
                        </Grid>
                        <Grid item xs={6}>

                            <Autocomplete
                                open={paymentAutocompletes.ownerPer.open}
                                onOpen={() => { setPaymentAutocompletes((previousValues) => { return {...previousValues, ownerPer: {...previousValues.ownerPer, open: true}} }) }}
                                onClose={() => { setPaymentAutocompletes((previousValues) => { return {...previousValues, ownerPer: {...previousValues.ownerPer, open: false}} }) }}
                                label="Search"
                                id="ownerPerAutocomplete"
                                //className={classes.selectRow}
                                autoComplete
                                autoHighlight
                                disableClearable
                                fullWidth
                                size='small'
                                freeSolo={true}
                                inputValue={ownerPer || ''}
                                onInputChange={(event, newValue, reason) => { 
                                    if(reason === 'input') {
                                        handleSetParentValues({ownerPer: newValue}, docId)
                                        //handleSetCurrentGrainOrder({...currentGrainOrder, ownerPer: newValue})
                                    } 
                                }}
                                value={ownerPer || ''}
                                onChange={(event, newValue) => {
                                    handleSetParentValues({ownerPer: newValue}, docId)
                                    //handleSetCurrentGrainOrder({...currentGrainOrder, ownerPer: newValue}) 
                                }}
                                options={per.map((per) => per ?? '').sort((a, b) => a.toLowerCase() > b.toLowerCase())}
                                getOptionSelected={(option, value) => option === value}
                                getOptionLabel={(option) => option}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        //label="Destination (To)"
                                        //variant="outlined"
                                        InputProps={{
                                            ...params.InputProps,
                                            endAdornment: (
                                                <React.Fragment>
                                                    {params.InputProps.endAdornment}
                                                </React.Fragment>
                                            ),
                                        }}
                                    />
                                )}
                            />
                        </Grid>
                    </Grid>

                    <Grid container>
                        <Grid item xs={6}>
                            <Typography className={classes.textRowTypography}>Cartage Sales:</Typography>
                        </Grid>
                        <Grid item xs={6} >

                            <Typography
                                className={classes.textRow}
                                style={{borderBottom: '1px solid grey'}}
                                fullWidth
                            >
                                $&nbsp;&nbsp;&nbsp;{cartageSalesTotal}
                            </Typography>

                        </Grid>
                    </Grid>

                    <Grid container>
                        <Grid item xs={10}>
                            <Typography className={classes.textRowTypography}><small>Link Operator Rate to the Sales Rate:</small></Typography>
                        </Grid>
                        <Grid item xs={2}>
                            <Grid container justifyContent='flex-end'>
                            <FormControlLabel
                                control={
                                    <Checkbox 
                                        className={classes.textRow} 
                                        checked={linkOperatorToSalesRate}   
                                        onChange={(event) => {
                                            handleSetParentValues({linkOperatorToSalesRate: event.target.checked}, docId)
                                            //handleSetCurrentGrainOrder({ ...currentGrainOrder, linkOperatorToSalesRate: event.target.checked })
                                        }}
                                    />
                                }
                            />
                            </Grid>
                        </Grid>
                    </Grid>

                    {(!_.isUndefined(linkOperatorToSalesRate) && linkOperatorToSalesRate) &&
                    <Grid container>
                        <Grid item xs={6}>
                            <Typography className={classes.textRowTypography}>Operator Base Multiplier:</Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <TextField 
                                fullWidth 
                                className={classes.textRow}
                                InputProps={{
                                    startAdornment: <InputAdornment position="start">%</InputAdornment>,
                                }}
                                value={operatorBaseMultiplier}
                                onChange={(event) => {
                                    handleSetParentValues({operatorBaseMultiplier: event.target.value}, docId)
                                    //handleSetCurrentGrainOrder({...currentGrainOrder, operatorBaseMultiplier: event.target.value}) 
                                }}
                                onBlur={(event) => {
                                    let adjustedBase = Number((Number((cartageSales || 0.00))) * (Number(event.target.value / 100))).toFixed(2)

                                    let payroll = Number((Number(event.target.value / 100) * Number((cartageSales|| 0.00)) * Number((operatorUnits || 0))) * ((operatorRate || 0))).toFixed(2)

                                    handleSetParentValues({operatorBaseMultiplier: Number(event.target.value).toFixed(2), adjustedBase: adjustedBase, payroll: payroll}, docId)
                                    // handleSetCurrentGrainOrder({...currentGrainOrder, operatorBaseMultiplier: Number(event.target.value).toFixed(2),
                                    //     adjustedBase: adjustedBase, payroll: payroll})
                                }}
                            />
                        </Grid>
                    </Grid>
                    }
                </Grid>

                <Grid item xs={12} md={4}>
                    <Grid container justifyContent='center'> 
                        <Typography style={{fontWeight: 600}} className={classes.textRow}>Operator</Typography>
                    </Grid>
                    <Divider className={classes.textRow}/>

                    <Grid container>
                        <Grid item xs={6}>
                            <Typography className={classes.textRowTypography}>Payroll Rate:</Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <TextField 
                                fullWidth 
                                className={classes.textRow}
                                
                                value={operatorRate}
                                InputProps={{
                                    startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                }}
                                onChange={(event) => {
                                    handleSetParentValues({operatorRate: event.target.value}, docId)
                                    //handleSetCurrentGrainOrder({...currentGrainOrder, operatorRate: event.target.value}) 
                                }}
                                onBlur={(event) => {
                                    let payroll = Number(Number((adjustedBase || 0.00)) * Number((operatorUnits || 0)) * (Number(event.target.value))).toFixed(2)

                                    handleSetParentValues({operatorRate: Number(event.target.value).toFixed(2), payroll: payroll}, docId)
                                    //handleSetCurrentGrainOrder({...currentGrainOrder, operatorRate: Number(event.target.value).toFixed(2), payroll: payroll})
                                }}
                            />
                        </Grid>
                    </Grid>

                    <Grid container>
                        <Grid item xs={6}>
                            <Typography className={classes.textRowTypography}>Adjusted Base:</Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <TextField 
                                fullWidth 
                                className={classes.textRow}
                                value={adjustedBase}
                                InputProps={{
                                    startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                }}
                                onChange={(event) => {
                                    handleSetParentValues({adjustedBase: event.target.value}, docId)
                                    //handleSetCurrentGrainOrder({...currentGrainOrder, adjustedBase: event.target.value}) 
                                }}
                                onBlur={(event) => {
                                    let payrollContainer = payroll || 0.00;
                                    let adjustedBaseContainer = adjustedBase || 0.00;

                                    if(!_.isUndefined(linkOperatorToSalesRate) && linkOperatorToSalesRate){
                                        adjustedBaseContainer = Number((Number(cartageSales|| 0.00)) * (Number((operatorBaseMultiplier || 100.00)) / 100)).toFixed(2)
                                        payrollContainer = Number(Number(adjustedBaseContainer) * Number((operatorUnits || 0)) * (Number((operatorRate || 0)))).toFixed(2)
                                    }else{
                                        adjustedBaseContainer = Number(event.target.value).toFixed(2)
                                        payrollContainer = Number((Number(adjustedBaseContainer) * Number((operatorUnits || 0))) * Number((operatorRate || 0))).toFixed(2)
                                    }

                                    handleSetParentValues({adjustedBase: adjustedBaseContainer, payroll: payrollContainer}, docId)
                                    //handleSetCurrentGrainOrder({...currentGrainOrder, adjustedBase: adjustedBase, payroll: payroll})
                                }}
                            />
                        </Grid>
                    </Grid>

                    <Grid container>
                        <Grid item xs={6}>
                            <Typography className={classes.textRowTypography}>Payroll Units:</Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <TextField 
                                fullWidth 
                                className={classes.textRow}
                                value={operatorUnits}
                                onChange={(event) => {
                                    handleSetParentValues({operatorUnits: event.target.value}, docId)
                                    //handleSetCurrentGrainOrder({...currentGrainOrder, operatorUnits: event.target.value}) 
                                }}
                                onBlur={(event) => {
                                    let payrollContainer = payroll || 0.00;
                                    let operatorUnits = adjustedBase || 0.00;

                                    if(!_.isUndefined(linkOperatorToSalesRate) && linkOperatorToSalesRate){
                                        operatorUnits = Number((cartageSalesUnits || 0))
                                        payrollContainer = Number((Number((adjustedBase || 0.00)) * Number(operatorUnits)) * (Number((operatorRate || 0)))).toFixed(2)
                                    }else{
                                        operatorUnits = Number(event.target.value)
                                        payrollContainer = Number((Number((adjustedBase|| 0.00)) * Number(operatorUnits)) * (Number((operatorRate || 0)))).toFixed(2)
                                    }

                                    handleSetParentValues({operatorUnits: operatorUnits, payroll: payrollContainer}, docId)
                                    //handleSetCurrentGrainOrder({...currentGrainOrder, operatorUnits: operatorUnits, payroll: payroll})
                                }}
                            />
                        </Grid>
                    </Grid>

                    <Grid container>
                        <Grid item xs={6}>
                            <Typography className={classes.textRowTypography}>Payroll Per:</Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <Autocomplete
                                open={paymentAutocompletes.operatorPer.open}
                                onOpen={() => { setPaymentAutocompletes((previousValues) => { return {...previousValues, operatorPer: {...previousValues.operatorPer, open: true}} }) }}
                                onClose={() => { setPaymentAutocompletes((previousValues) => { return {...previousValues, operatorPer: {...previousValues.operatorPer, open: false}} }) }}
                                label="Search"
                                id="operatorPerAutocomplete"
                                //className={classes.selectRow}
                                autoComplete
                                autoHighlight
                                disableClearable
                                fullWidth
                                size='small'
                                freeSolo={true}
                                inputValue={operatorPer || ''}
                                onInputChange={(event, newValue, reason) => { 
                                    if(reason === 'input') { 
                                        handleSetParentValues({operatorPer: newValue}, docId)
                                        //handleSetCurrentGrainOrder({...currentGrainOrder, operatorPer: newValue}) 
                                    }
                                }}
                                value={operatorPer || ''}
                                onChange={(event, newValue) => {
                                    handleSetParentValues({operatorPer: newValue}, docId)
                                    //handleSetCurrentGrainOrder({...currentGrainOrder, operatorPer: newValue}) 
                                }}
                                options={per.map((per) => per ?? '').sort((a, b) => a.toLowerCase() > b.toLowerCase())}
                                getOptionSelected={(option, value) => option === value}
                                getOptionLabel={(option) => option}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        //label="Destination (To)"
                                        //variant="outlined"
                                        InputProps={{
                                            ...params.InputProps,
                                            endAdornment: (
                                                <React.Fragment>
                                                    {params.InputProps.endAdornment}
                                                </React.Fragment>
                                            ),
                                        }}
                                    />
                                )}
                            />
                        </Grid>
                    </Grid>

                    <Grid container>
                        <Grid item xs={6}>
                            <Typography className={classes.textRowTypography}>Payroll:</Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <Typography
                                className={classes.textRow}
                                style={{borderBottom: '1px solid grey'}}
                                fullWidth
                            >
                                $&nbsp;&nbsp;&nbsp;{payroll}
                            </Typography>
                        </Grid>
                    </Grid>

                </Grid>
            </Grid>
        </>

    )
}

export default memo(GrainOrderPaymentValues)