import * as actionTypes from '../actions/actionTypes'
import { updateObject } from '../utility'

const initialState = {
    orders: [],
    loading: true,
    errorMsg: null,
}

const ordersReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.ORDERS_FETCH:
            return updateObject(state, { loading: true });
        case actionTypes.ORDERS_FAIL:
            return updateObject(state, { loading: false, errorMsg: action.error });
        case actionTypes.ORDERS_SUCCESS:
            return updateObject(state, { loading: false, orders: action.orders });
        default:
            return state;
    }
}

export default ordersReducer;

