import firebase from 'firebase/compat/app';
import "firebase/compat/auth";
import "firebase/compat/firestore";
import "firebase/compat/functions";
import "firebase/compat/storage";
import _ from 'lodash';

// Initialize Firebase
const firebaseConfig = {
  apiKey: "AIzaSyBxuXyOspu3CdtRj7ErkqEO3-dBH3h2zI8",
  authDomain: "geetee-49199.firebaseapp.com",
  databaseURL: "https://geetee-49199.firebaseio.com",
  projectId: "geetee-49199",
  storageBucket: "geetee-49199.appspot.com",
  messagingSenderId: "149781920236"
  //appId: "XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX" 
};

firebase.initializeApp(firebaseConfig)

if (process.env.NODE_ENV === 'development') {
  firebase.firestore().useEmulator("localhost", 8080);
  firebase.functions().useEmulator("localhost", 5001);
}

//process.env.REACT_APP_SANDBOX_MODE === 'true' && setupEmulator();

// Initialize Cloud Firestore through Firebase
export const auth = firebase.auth();
export const firestore = firebase.firestore();
export const functions = firebase.functions();
export const storage = firebase.storage();

export const uploadFile = async (path, file) => {

  let storageRef = firebase.storage().ref();
  let fileRef = storageRef.child(path);

  let fileURL = {
    url: '',
    error: false,
    errorMsg: '',
  };

  if (file instanceof File) {
    let urlResponse = '';

    try {
      let putResponse = await fileRef.put(file);

      if (putResponse.text !== '')
        urlResponse = await fileRef.getDownloadURL();
      if (urlResponse !== '')
        fileURL = {
          ...fileURL,
          url: urlResponse,
        }
    }
    catch (error) {
      fileURL = {
        ...fileURL,
        error: true,
        errorMsg: error.message,
      }
    }
  }

  return fileURL;
}

export const removeFile = async (fileURL) => {

  let query = {
    fileDeleted: false,
    error: false,
    errorMsg: '',
  }

  if (_.isString(fileURL) && fileURL !== "") {
    try {

      let fileURLRef = firebase.storage().refFromURL(fileURL);

      await fileURLRef.delete();

      query = {
        ...query,
        fileDeleted: true
      }

    }
    catch (error) {
      query = {
        ...query,
        error: true,
        errorMsg: error.message,
      }
    }
  }

  return query;
}

export default auth;