import React, { useState, useEffect } from 'react';
import moment from 'moment'
import MaterialTable from '@material-table/core';
import { firestore } from '../../../firebase/firebase';
import { useMediaQuery, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button } from '@material-ui/core';
import { TableIcons } from '../../../constants/tableIcons';
//not used, users should be loaded by now from reporting component
import { makeStyles } from '@material-ui/core';
import { useUser } from '../../../providers/UserContext';
import { useTheme } from '@material-ui/core/styles';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import { withRouter} from 'react-router-dom';
import { useAuth } from '../../../providers/AuthContext';
import { CheckBox, CheckBoxOutlineBlank } from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
    content: {
        width: '100%'
    },
}));

const GrainSheets = (props) => {
    const { getUsers, users } = useUser();
    //can be used to get users from database, should be neccessary. 
    const { roles } = useAuth();
    const classes = useStyles();
    const theme = useTheme();
    const smallScreen = useMediaQuery(theme.breakpoints.down('sm'));
    useEffect(() => {

    }, [smallScreen]);


    const [searchBarValue, setSearchBarValue] = useState("grainApprovalSearchBarValue" in sessionStorage 
        ? JSON.parse(sessionStorage.getItem("grainApprovalSearchBarValue")) : null)
    const [timesheetData, setTimesheetData] = useState(JSON.parse(sessionStorage.getItem("approveTimesheetData")) || []);
    const [checkLoading, setCheckLoading] = React.useState(false);
    const [approveOpen, setApproveOpen] = React.useState(false);
    const [timedoc, setTimedoc] = React.useState('')
    const [timeapproved, setTimeapproved] = React.useState(false);
    const [timeID, setTimeID] = React.useState('')
    const handleClickApproveOpen = (docId, apr, ID) => {
        setApproveOpen(true);
        setTimedoc(docId);
        setTimeapproved(apr);
        setTimeID(ID);
    };

    const handleClickApproveClose = () => {
        setApproveOpen(false);
        setTimedoc('');
        setTimeapproved(false);
        setTimeID('');
    };

    const handleApproveClick = () => {
        setApproveOpen(false);
        setCheckLoading(true)
        timesheetData[timesheetData.findIndex((obj => obj.docId === timedoc))].approved = true
        timesheetData[timesheetData.findIndex((obj => obj.docId === timedoc))].payrolled = false
        firestore.collection('timesheets').doc(timedoc)
        .update({
            'approved': true,
            'payrolled': false,
        })
        .then(() => {
            setCheckLoading(false)
        })
        .catch((e) => {console.log(e);});
    }

    const handleUnapproveClick = () => {
        setApproveOpen(false);
        setCheckLoading(true)
        timesheetData[timesheetData.findIndex((obj => obj.docId === timedoc))].approved = false
        firestore.collection('timesheets').doc(timedoc)
        .update({
            'approved': false,
        })
        .then(() => {
            setCheckLoading(false)
        })
        .catch((e) => {console.log(e);});
        //generateHandler()
    }
    useEffect(() => {

        if(users.length <= 0){
            getUsers();
        }
        let unsubsribe = firestore.collection('timesheets').where('approved', '==', false).where('timesheetID', '!=', 0).where('timesheetType', '==', 'Grain').orderBy('timesheetID').onSnapshot(querySnapshot => {
            //process & store timesheet data
            let timesheetResponse = querySnapshot.docs.map((doc) => {

                let date = doc.data().date
                let inTime = doc.data().usingGateInTime ? doc.data().gateIn15 : doc.data().inTime
                let outTime = doc.data().usingGateOutTime ? doc.data().gateOut15 : doc.data().outTime
                if (inTime < 9999999999) inTime *= 1000
                if (outTime < 9999999999) outTime *= 1000
                if (date < 9999999999) date *= 1000
                var oHours = outTime - inTime
                oHours -= ((doc.data().skipLunch === "na" || doc.data().skipLunch === 'declined') ? 1800000 : 0)
                oHours = oHours / 3600000;
                oHours -= doc.data().offHours;
                oHours = Math.max(oHours.toFixed(2), 0);
                return {
                    ...doc.data(),
                    'docId': doc.id,
                    'date': moment.utc(date).format('YYYY-MM-DD'),
                    'timesheetID': doc.data().timesheetID === 0 ? 'Unsubmitted' : doc.data().timesheetID,
                    'operatorHours': oHours.toFixed(2),
                    'uid': users.find(user => user.uuid === doc.data().uid)?.displayName,
                }
            })
            setTimesheetData(timesheetResponse)
            sessionStorage.setItem("approveTimesheetData", JSON.stringify(timesheetResponse))
        })

        return () => { unsubsribe(); }
    }, [checkLoading]) // eslint-disable-line react-hooks/exhaustive-deps


    const timesheetClickHandler = (path) => {
        props.history.push({
            pathname: '/dashboard/reporting/timesheets',
            search: "?" + new URLSearchParams({ docId: path }).toString(),
            timesheetList: timesheetData
            .sort((a, b) => (a.timesheetID > b.timesheetID) ? -1 : 1)
        })
    }
    return (
                <div className={classes.content}>
                    <Dialog 
                        open={approveOpen}
                        onClose={handleClickApproveClose}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                    >
                        <DialogTitle id="alert-dialog-title">{timeapproved ? "Unapprove Timesheet #"+timeID+' ?' : "Approve Timesheet #"+timeID+' ?'}</DialogTitle>
                        <DialogContent>
                            <DialogContentText id="alert-dialog-description">
                                Are you sure you want to {timeapproved ? 'unapprove' : 'approve'} this timesheet?
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={timeapproved ? () => handleUnapproveClick()  : () => handleApproveClick()} variant="contained" color="primary" autoFocus>
                                {timeapproved ? 'Unapprove' : 'Approve'}
                            </Button>
                            <Button onClick={handleClickApproveClose} variant="contained" color="secondary">
                                Cancel
                            </Button>
                        </DialogActions>
                    </Dialog>
                    <MaterialTable

                        columns={[
                            { title: 'Approve', field:'approved', render: rowData => {
                                return (rowData.timesheetType === 'Grain' && roles.includes('Approve Grain Timesheets')) ?
                                rowData.timesheetID === "Unsubmitted" ? null : rowData.approved ? 
                                <Button onClick={() => handleClickApproveOpen(rowData.docId, rowData.approved, rowData.timesheetID)}
                                size={"small"} color="secondary" variant={"contained"}>Unapprove</Button> :

                                <Button onClick={() => handleClickApproveOpen(rowData.docId, rowData.approved, rowData.timesheetID)}
                                size={"small"} style={{backgroundColor:'green', color:'white'}} variant={"contained"}>Approve</Button>

                                : null
                            } },
                            { title: 'Timesheet', field: 'timesheetID', defaultSort: 'desc' },
                            { title: 'Employee', field: 'uid', },
                            { title: 'Date', field: 'date' },
                            { title: 'Hours', field: 'operatorHours' },
                        ]}
                        data={timesheetData}
                        title={`Grain Timesheets`}
                        //isLoading={loading}
                        icons={TableIcons}
                        onSearchChange={ (value) => {setSearchBarValue(value);  sessionStorage.setItem("grainApprovalSearchBarValue", JSON.stringify(value))}}
                        onRowsPerPageChange={(num) => {sessionStorage.setItem("grainApprovalRowsPerPage", JSON.parse(num))}}
                        actions={[
                            {
                                icon: OpenInNewIcon,
                                tooltip: 'View Timesheet',
                                onClick: (event, rowData) => timesheetClickHandler(rowData['docId']),
                            },
                            rowData => ({
                                icon: () => props.checkedSheets.includes(rowData?.docId) ? <CheckBox/> : <CheckBoxOutlineBlank/>,
                                tooltip: 'Select Timesheet',
                                onClick: (event, rowData) => {
                                    if(props.checkedSheets.includes(rowData.docId)){
                                        //remove from checked sheets
                                        props.setCheckedSheets(props.checkedSheets.filter(sheet => sheet !== rowData.docId))
                                        sessionStorage.setItem('approvalCheckedSheets', JSON.stringify(props.checkedSheets.filter(sheet => sheet !== rowData.docId)))
                                    } else {
                                        props.setCheckedSheets([...props.checkedSheets, rowData.docId])
                                        sessionStorage.setItem('approvalCheckedSheets',  JSON.stringify([...props.checkedSheets, rowData.docId]))
                                    }
                                }
                            }),
                        ]}
                        onPageChange={ (pageNum) => { sessionStorage.setItem("grainApprovalPageValue", JSON.stringify(pageNum)) }}
                        options={{
                            pageSize: "grainApprovalRowsPerPage" in sessionStorage ? JSON.parse(sessionStorage.getItem("grainApprovalRowsPerPage")) : 25,
                            initialPage: "grainApprovalPageValue" in sessionStorage ? JSON.parse(sessionStorage.getItem("grainApprovalPageValue")) : 0,
                            pageSizeOptions: [15, 25, 30,],
                            searchText: searchBarValue,
                            doubleHorizontalScroll: true,
                            toolbar: true,
                            columnsButton: true,
                            paging: true,
                            sorting: true,
                            thirdSortClick: false,
                            rowStyle: rowData => {
                                if (rowData.timesheetID === "Unsubmitted") {
                                    return { backgroundColor: '#fff2f4' };
                                }
                                if (rowData.approved === true) {
                                    return { backgroundColor: 'lightGreen' };
                                }

                                return {};
                            }
                        }}
                    />
        
                </div>
    )
}

export default withRouter(GrainSheets)