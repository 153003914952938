import React from 'react';
import { Button, makeStyles } from '@material-ui/core';
import { PDFViewer, Font, Page, Text, View, Document, StyleSheet } from '@react-pdf/renderer';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { withRouter } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        justifyContent: 'center',
        width: '100%',
    },
    content: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        maxWidth: '1200px',
        margin: 'auto',
    },
    buttonRow: {
        marginBottom: '20px',
        marginTop: '20px',
    },
    checkRow: {
        margin: theme.spacing(1),
        display: 'flex',
        justifyContent: 'space-evenly',
    },
}));

Font.register({
    family: 'Oswald',
    src: 'https://fonts.gstatic.com/s/oswald/v13/Y_TKV6o8WovbUd3m_X9aAA.ttf'
});

// Create styles
const styles = StyleSheet.create({
    page: {
        flexDirection: 'column',
        fontFamily: 'Helvetica',
        padding: 20,
        justifyContent: 'left',
    },
    section: {
        marginBottom: 5,
        justifyContent: 'left',
    },
    dTable: {
        display: "table",
        width: "auto",
        border: '1 solid black',
        justifyContent: 'left',
        marginBottom: 10,
    },
    dTableRow: {
        margin: "auto",
        flexDirection: "row",
        justifyContent: 'left',
    },
    dTableCol: {
        flexGrow: 1,
        justifyContent: 'left',
    },
    dTableCell: {
        marginLeft:2,
        marginTop: 2,
        marginBottom: 2,
        fontSize: 10,
        justifyContent: 'start'
    },
    table: {
        border: '1 solid black',
        marginBottom: 10,
        padding: 10,
        flexGrow: 1,
        fontSize: 14,
        flexDirection: 'column',
        justifyContent: 'left',
    },
    tableRow: {
        flexDirection: 'row',
        justifyContent: 'left',
        marginTop: 2,
        marginBottom: 2,
    },
    tableText: {
        fontSize: 12,
        justifyContent: 'left',
    },
    tableTextBold: {
        fontSize: 12,
        fontFamily: 'Helvetica-Bold',
        fontWeight: 'bold',
    },
    summary: {
        flexDirection: 'row',
    },
    viewer: {
        width: '100%',
        maxWidth: '1200px',
        height: '85vh',
    },
});

// Create Document Component
const TimesheetTotalPDF = (props) => {

    const classes = useStyles();
    
    /**
     * @param title - Table Header
     * @param columns - Generates table column header and its internal data. Can pass either an objects key to iterate or a function.
     * @param data - The table data passed (as an array of objects)
     * @param components - React components passed as special rendering for additional rows 
     */
    const Table = (props) => {

        let columnWidth = 100 / props.columns.length + '%';

 
        return (
            <View style={styles.dTable}>
                <Text style={styles.dTableRow}>{props.title}</Text>

                <View style={styles.dTableRow}>
                    {props.columns.map((column, index) => {
                        return (
                            <View style={{ ...styles.dTableCol, width: columnWidth }} key={index}>
                                <Text style={{ ...styles.dTableCell, fontSize: 10 }}>{column.title}</Text>
                            </View>
                        )
                    })}
                </View>

                {props.data.map((data, index) => {
                    return (
                        <View key={'tablerow' + props.title + index}>
                            <View style={styles.dTableRow} wrap={false}>
                                {props.columns.map((column, index) => {

                                    //Use render function if exists, otherwise render data.
                                    let cellData = column.render ? column.render(data) : data[column.field];

                                    return (
                                        <View style={{ ...styles.dTableCol, width: columnWidth }} key={index}>
                                            <Text style={{...styles.dTableCell }}>{cellData}</Text>
                                        </View>
                                    )
                                })}
                            </View>
                        </View>
                    )
                })}
            </View>
        )
    }

    return (
        <div className={classes.root}>
            <div className={classes.content}>

                <div className={classes.buttonRow}>
                    <Button variant="contained" startIcon={<ArrowBackIcon />}
                        onClick={() => props.history.goBack()}>Back</Button>
                </div>
                
                <PDFViewer style={styles.viewer}>
                    <Document>
                        <Page size="A4" style={styles.page} wrap>

                            <View style={styles.section}>
                                <Text>Employee Hours  |  {props.fullData.startDate} - {props.fullData.endDate}</Text>
                            </View>

    
                            <Table
                                    columns={[
                                        { title: 'EMPLOYEE', field: 'user' },
                                        { title: 'TOTAL HOURS', field: 'totalHours' },

                                    ]}
                                    data={props.userData}
                                    title={''} />


                        </Page>
                        
                    </Document>
                </PDFViewer>
            </div>
        </div >
    )
};

export default withRouter(TimesheetTotalPDF);