import React from 'react';
import { makeStyles, Checkbox, FormLabel, FormControl, FormGroup, FormControlLabel } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import DatePicker from '../../UI/DatePicker/DatePicker';
import Select from '@material-ui/core/Select';
import IconButton from '@material-ui/core/IconButton';
import ClearIcon from '@material-ui/icons/Clear';
import CircularProgress from '@material-ui/core/CircularProgress';

const useStyles = makeStyles((theme) => ({
    detailsPanel: {
        padding: theme.spacing(2),
        paddingTop: theme.spacing(14),
        height: '100vh',
        right: 0,
        zIndex: 12,
        overflowY: 'auto',
    },

    btnGroup: {
        width: '100%',
    },

    filterItem: {
        marginTop: '4px',
        marginBottom: '4px',
    },

    search: {
        display: 'flex',
        boxShadow: 'inset 0px 0px 2px 2px rgba(207,207,207,0.49)',
        //backgroundColor: '#fafafa',
        borderRadius: '4px',
        alignItems: 'center',
        justifyContent: 'center',
        padding: theme.spacing(2),
    },
}));

const JobOptions = (props) => {

    const classes = useStyles();

    return (
        <Grid container spacing={2}>
            <Grid item sm={12}>
                <Grid container className={classes.search}>
                    <Grid item sm={5}>
                        <Typography>Search By</Typography>
                    </Grid>
                    <Grid item sm={7}>
                        <Select
                            name="type"
                            value={props.searchState.type}
                            onChange={props.searchHandler}
                            native>
                            <option value="customer">Customer</option>
                            <option value="address">Address</option>
                            <option value="type">Type</option>
                        </Select>
                    </Grid>
                    {props.searchState.type === 'type' ?
                        <Grid item sm={12}>
                            <Select
                                name="jobType"
                                value={props.jobTypeState.jobType}
                                onChange={props.jobTypeHandler}
                                native>
                                <option value="snow">Snow</option>
                                <option value="other">Other</option>
                            </Select>
                        </Grid> : props.searchState.type === 'photo' ? null :
                        <Grid item sm={12}>
                            <TextField
                                label="Search"
                                size="small"
                                value={props.searchState.value}
                                onChange={props.searchHandler}
                                name="value"
                                InputProps={props.searchState.value ? {
                                    endAdornment: <IconButton onClick={props.clearSearch}><ClearIcon /></IconButton>
                                } : null} />
                        </Grid>
                    }
                </Grid>
            </Grid>

            <Grid item sm={12}>
                <Grid container className={classes.search}>
                    <Grid item sm={12} className={classes.filterItem}>
                        <DatePicker
                            label="Start Date"
                            value={props.startDate}
                            onChange={props.startDateHandler}
                        />
                    </Grid>

                    <Grid item sm={12} className={classes.filterItem}>
                        <DatePicker
                            label="End Date"
                            value={props.endDate}
                            onChange={props.endDateHandler}
                        />
                    </Grid>
                    <Grid item sm={12} className={classes.filterItem}>
                        <FormControl component="fieldset" className={classes.formControl}>
                            <FormLabel component="legend" className={classes.formLabel}>Show Only:</FormLabel>
                            <FormGroup row>
                                <FormControlLabel
                                    control={<Checkbox checked={props.checkboxState.photos} onChange={props.checkboxHandler} name="photos" />}
                                    label="Jobs with Photos"
                                />
                            </FormGroup>
                        </FormControl>
                    </Grid>
                </Grid>
            </Grid>

            <Grid item sm={12}>
                {(props.errorMsg && <Typography>{props.errorMsg}</Typography>)}
            </Grid>
            <Grid item sm={12} className={classes.btnGroup}>
                <Button variant="contained" fullWidth disabled={props.loading} onClick={props.generateHandler} > Generate </Button>
            </Grid>
            <Grid item sm={12} className={classes.btnGroup}>
                <Button variant="contained" fullWidth disabled={props.loading} onClick={props.handlePrint} > Print Table </Button>
            </Grid>

            <Grid item sm={12} style={props.progress === 0 || props.progress === 100 ? {visibility: 'hidden'} : {visibility: 'visible'}}>
                <CircularProgress variant="determinate" style={props.progress < 99 ? {color: "orange"} : {color: "green"}} value={props.progress} />
                <small>{props.loadingText}</small>
            </Grid>

        </Grid>
    )
}

export default JobOptions;